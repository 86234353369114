import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let ReportApi = {
    createReport: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/fao/report`,
            method: 'POST',
            body: payload
        }, callback);
    }
}
export default ReportApi
