import React, {Component} from "react";
import {withSnackbar} from "notistack";
import PropTypes from "prop-types"
import NumberFormat from 'react-number-format';
import {
    Card, CardActions, CardContent,
    CardHeader, Typography, Button, Grid
} from "@material-ui/core";

import PublicOrderApi from "../../../../services/ob-core/public-order-service";
import history from "../../../../history";
import {Redirect} from "react-router-dom";
import { withRouter } from "react-router";
import {toast} from "react-toastify";
const business = [
    {
        type: "COMMERCE_SERVICE",
        name: 'Thương mại - Dịch vụ - Đầu tư tài chính'
    },
    {
        type: "MANUFACTURING_MACHINING",
        name: 'Sản xuất - Gia công',
    },
    {
        type: "BUILDING_INSTALLATION",
        name: 'Xây dựng - Lắp đặt - Đầu tư bất động sản'
    }
]

class ConfirmService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };

    componentDidMount() {
        // console.log("ạkòhnạkònkoádjdkpfsà>>>>>", this.props)
    }

    onSubmitAdvanced = () => {
        let {info} = this.props
        this.props.showLoading()
        let payload = {
            price: 0,
            servicePlanId: info.servicePlanId
        }
        PublicOrderApi.createAdvance(payload, res => {
            if (res.success) {
                this.props.hideLoading()
                toast.success("Đăng ký dịch vụ thành công!")
                this.props.history.push("/customer/list-ordered-service")
            }
            else {
                toast.error("Đăng ký dịch vụ không thành công! Vui lòng thử lại")
                this.props.hideLoading()
            }
        })
    }

    onSubmit = () => {
        let {info} = this.props
        let payload = []
        this.props.showLoading()

        // if (info.servicePlanId == 3) {
        //     payload = {
        //         business: info.business,
        //         servicePlanId: info.servicePlanId,
        //         socialInsuranceConstraintId: info.socialInsuranceConstraintId,
        //         supervisorId: info.supervisorId
        //     }
        // } else {
            payload = {
                business: info.business,
                numberOfDocumentsConstraintId: info.numberOfDocumentsConstraintId || 0,
                numberOfEmployeesConstraintId: info.numberOfEmployeesConstraintId || 0,
                numberOfInvoicesConstraintId: info.numberOfInvoicesConstraintId || 0,
                numberOfMonths: info.numberOfMonths || 0,
                servicePlanId: info.servicePlanId,
                socialInsuranceConstraintId: info.socialInsuranceConstraintId || 0,
                serviceQuotationId: info.socialInsuranceConstraintId || 0,
                supervisorId: info.supervisorId
            }
        // }
        PublicOrderApi.create(payload, res => {
            if (res.success) {
                // this.props.enqueueSnackbar('Đăng ký dịch vụ thành công!', {
                //     anchorOrigin: {
                //         vertical: "top",
                //         horizontal: "right"
                //     },
                //     variant: 'success',
                // });
                this.props.hideLoading()
                toast.success("Đăng ký dịch vụ thành công!")
                this.props.history.push("/customer/list-ordered-service")
            }
            else {
                toast.error("Đăng ký dịch vụ không thành công! Vui lòng thử lại")
                this.props.hideLoading()
                // this.props.enqueueSnackbar('Đăng ký dịch vụ không thành công!', {
                //     anchorOrigin: {
                //         vertical: "top",
                //         horizontal: "right"
                //     },
                //     variant: 'error',
                // });
            }
        })
    }

    switchBusiness = (item) => {
        switch (item) {
            case("COMMERCE_SERVICE"): return "Thương mại - Dịch vụ - Đầu tư tài chính"
            case("MANUFACTURING_MACHINING"): return 'Sản xuất - Gia công'
            case("BUILDING_INSTALLATION"): return 'Xây dựng - Lắp đặt - Đầu tư bất động sản'
        }
    }

    render() {
        let {info, extraInfo} = this.props
        return (
            <div>
                <div className="">
                    <CardContent>
                        <div style={{textAlign: "center"}}>
                            <h3>Xác nhận đặt dịch vụ</h3>
                            <h4>Vui lòng kiểm tra lại các thông tin đã lựa chọn. </h4>
                            <h4>Hệ thống sẽ liên hệ trong thời gian 24 tiếng sau khi xác nhận đặt dịch vụ</h4>
                        </div>
                    </CardContent>
                </div>
                <div className="mb-3">
                    <CardHeader
                        title="Thông tin đơn đặt"
                    />
                    <CardContent style={{fontSize: 16}}>
                        {/*<Grid>*/}
                        {/*    <span>Tên khách hàng:&nbsp;&nbsp;</span>*/}
                        {/*    <span><b>Nguyễn Văn A</b></span>*/}
                        {/*</Grid>*/}

                        {this.props.selectedServiceType == "BASIC" && <div style={{marginTop: 7}}>
                            <span>Tên Supervisor:&nbsp;&nbsp;</span>
                            <span><b>{extraInfo.supervisorName}</b></span>
                        </div>}

                        <div style={{marginTop: 7}}>
                            <span>Dịch vụ:&nbsp;&nbsp;</span>
                            <span><b>{extraInfo.servicePlanName}</b></span>
                        </div>

                        {/*<Grid style={{marginTop: 7}}>*/}
                        {/*    <span>Gói dịch vụ:&nbsp;&nbsp;</span>*/}
                        {/*    <span><b>Công ty thương mại</b></span>*/}
                        {/*</Grid>*/}

                        {this.props.selectedServiceType == "BASIC" && <div style={{marginTop: 7}}>
                            <span>Gói dịch vụ:&nbsp;&nbsp;</span>
                            <span><b>{this.switchBusiness(info.business)}</b></span>
                        </div>}

                        {this.props.selectedServiceType == "BASIC" && <div style={{marginTop: 7}}>
                            <span>Số tiền (VNĐ):&nbsp;&nbsp;</span>
                            <span>
                                <b>
                                    <NumberFormat
                                        value={extraInfo.price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </b>
                            </span>
                        </div>}

                        <div className="row justify-flex-end" style={{marginTop: 20}}>
                            <button type="button"
                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                    onClick={() => {
                                        if(this.props.selectedServiceType == "BASIC"){
                                            this.props.nextPage("choose-supervisor")
                                        } else {
                                            this.props.nextPage("choose-service")
                                        }
                                    }}
                            >
                                Quay lại
                            </button>

                            <button
                                type="button"
                                // disabled={!this.state.selectedService}
                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                onClick={() => {
                                    if (this.props.selectedServiceType == "BASIC"){
                                        this.onSubmit()
                                    }else{
                                        this.onSubmitAdvanced()
                                    }

                                }}
                            >
                                Xác nhận
                            </button>
                        </div>
                    </CardContent>
                </div>
            </div>
        );
    }

}

export default withRouter(ConfirmService)
