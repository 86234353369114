export const printTypeOptions = [
  {
    label: "Tách biệt tài sản và nguồn vốn",
    value: 1
  },
  {
    label: "Không",
    value: 0
  }
];
export const reportFormOptions200 = [
  {
    label: "Báo cáo theo thông tư 200",
    value: "V20GLTC13"
  },
  {
    label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
    value: "V20GLTC14"
  },
  {
    label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
    value: "V20GLTC15"
  }
];
export const reportFormOptions133 = [
  {
    label: "Bảng cân đối kế toán – năm",
    value: "v20GLTC1"
  },
  {
    label: "Bảng cân đối kế toán giữa niên độ (Dạng đầy đủ)",
    value: "V20GLTC11"
  },
  {
    label: "Bảng cân đối kế toán giữa niên độ (Dạng tóm lược)",
    value: "V20GLTC12"
  },
  {
    label: "Báo cáo theo thông tư 133 (Mẫu 01a)",
    value: "V20GLTC16"
  },
  {
    label: "Báo cáo theo thông tư 133 (Mẫu 01b)",
    value: "V20GLTC17"
  }
];
export const reportFormOptions = [
  {
    label: "Bảng cân đối kế toán – năm",
    value: "v20GLTC1"
  },
  {
    label: "Bảng cân đối kế toán giữa niên độ (Dạng đầy đủ)",
    value: "V20GLTC11"
  },
  {
    label: "Bảng cân đối kế toán giữa niên độ (Dạng tóm lược)",
    value: "V20GLTC12"
  },
  {
    label: "Báo cáo theo thông tư 200",
    value: "V20GLTC13"
  },
  {
    label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
    value: "V20GLTC14"
  },
  {
    label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
    value: "V20GLTC15"
  },
  {
    label: "Báo cáo theo thông tư 133 (Mẫu 01a)",
    value: "V20GLTC16"
  },
  {
    label: "Báo cáo theo thông tư 133 (Mẫu 01b)",
    value: "V20GLTC17"
  }
];

export const reportCodeOptions = [
  {
    label: "Mẫu chuẩn - năm",
    value: "10"
  },
  {
    label: "Mẫu chuẩn - giữa niên độ",
    value: "20"
  },
  {
    label: "Mẫu chuẩn - bán niên",
    value: "30"
  }
];
