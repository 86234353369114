import React from 'react'
import Pagination from '../../../../components/Pagination'
import TableHeader from '../../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import Autosuggestion from "../../../../components/Form/Autosuggestion";
import ModalAddEditCategory from "../../../../components/Modal/modalAddEditCategory";
import PublicNewsCategory from "../../../../services/ob-core/public-news-category";
import ModalDeleteCategory from "../../../../components/Modal/modalDeleteCategory";
import {forEach} from "react-bootstrap/ElementChildren";

const dateFormat = 'DD-MM-YYYY';

class PostCategoryManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDetailAdvance: false,
            showEditAdvance: false,

            page: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: '',

            sort: '',

            categoryType: "",
            categoryType_temp: "",
            listData: [],
            categoryList: [],
            itemSelected: "",
            modalType: "",
            modalAddEditCategory: false,
            modalDelete: false,
            showLoading: false,
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên danh mục',
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'path',
                    name: 'Đường dẫn',
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Vị trí",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false
                },

            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getData()
        })

    }

    onSearch = () => {
        this.setState(
            {
                categoryType: this.state.categoryType_temp,
                pageNumber: 0,
                pageSize: 10,
            }, () => {this.getData()}
        )
    }

    getData = () => {
        let {pageSize, pageNumber, categoryType_temp} = this.state
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            categoryType: categoryType_temp,
        }

        PublicNewsCategory.getList(payload, res => {
            if (res.success) {

                this.setState({
                    listData: this.reformatData(res.data),
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
            }
        })
    }
    reformatData = (listData) => {
        let listCate = [];
        listData.forEach(cateInfo => {
            if (cateInfo.parentId == 0){
                listCate.push(cateInfo);
                listData.forEach(subCate => {
                    if (subCate.parentId != 0 && subCate.parentId == cateInfo.id){
                        subCate.name = "->"+subCate.name;
                        listCate.push(subCate);
                        listData.forEach(inSubCate => {
                            if (inSubCate.parentId != 0 && inSubCate.parentId == subCate.id){
                                inSubCate.name = "--->"+inSubCate.name;
                                listCate.push(inSubCate);
                                listData.forEach(inInSubCate => {
                                    if (inInSubCate.parentId != 0 && inInSubCate.parentId == inSubCate.id){
                                        inInSubCate.name = "---->"+inInSubCate.name;
                                        listCate.push(inInSubCate);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return listCate;
    }
    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getData()
            }
        );
    }

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
        }
    };


    render() {
        let {pageNumber, pageSize, accountBalance} = this.state;
        let indexCate    = 0;
        let indexSubCate = 0;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="post-category" parentClass='home-management'/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý danh mục bài viết"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            {/*<div className='col-4 pl-0'>*/}
                                            {/*    <label className="label-item">Loại gói dịch vụ</label>*/}
                                            {/*    <input*/}
                                            {/*        maxLength={20} type="text"*/}
                                            {/*        className="form-control shadow-none"*/}
                                            {/*        placeholder="chọn gói dịch vụ"*/}
                                            {/*        value={this.state.categoryType_temp}*/}
                                            {/*        onKeyUp={(e) => {*/}
                                            {/*            if (e.key === 'Enter') this.searchListService()*/}
                                            {/*        }}*/}
                                            {/*        onChange={(e) => {*/}
                                            {/*            this.setState({*/}
                                            {/*                categoryType_temp: e.target.value*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            <div className='col-4 p-0'>
                                                {/* <label className="label-item">Danh mục</label> */}
                                                {/* <Autosuggestion
                                                    // label="Tên Khách hàng"
                                                    // value={this.state.dataOrder.supervisorName}
                                                    placeholder="Nhập tên danh mục"
                                                    type="text"
                                                    data={this.state.categoryList}
                                                    onSelect={(item) => {
                                                        this.setState({selectedSupervisor: item})
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({supervisorName: val, selectedSupervisor: null})
                                                        setTimeout(() => {
                                                            this.getListSupervisor()
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.categoryList}
                                                    errorContent="Vui lòng chọn danh mục"
                                                /> */}
                                                {/* <input
                                                    placeholder="Nhập tên danh mục..."
                                                    type="text"
                                                    className="form-control shadow-none font-14"
                                                    // style={{width: "100%", height: 35, padding: 8}}
                                                    value={this.state.categoryType_temp}
                                                    maxLength={10}
                                                    onKeyUp={e => {
                                                        if (e.key === "Enter") this.onSearch();
                                                    }}
                                                    onChange={val =>
                                                        this.setState({categoryType_temp: val.target.value})
                                                    }
                                                /> */}
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                                {/* <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.onSearch()
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                categoryType: "",
                                                                categoryType_temp: "",
                                                            },
                                                                () => this.getData())
                                                        }}
                                                >Refresh
                                                </button> */}
                                                <button
                                                    type="button" className="btn border-1-s btn-primary btn-sm"
                                                    style={{height: 35,}}
                                                    onClick={() => this.setState({
                                                        modalAddEditCategory: true,
                                                        modalType: "new",
                                                        itemSelected: null
                                                    })}
                                                >
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListService())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {/*{row.contractCode}*/}
                                                        {row.parentId ==0 ? <b>{row.name}</b> : row.name}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.path}
                                                    </td>
                                                    <td style={{textAlign: 'center',}}>
                                                        {row.position}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button className="btn btn-action"
                                                                title='Chỉnh sửa'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={()=> this.setState({
                                                                    modalAddEditCategory:true,
                                                                    modalType: "edit",
                                                                    itemSelected: row
                                                                })}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>

                                                        <button className="btn btn-action"
                                                                title='Xóa'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        itemSelected: row.id,
                                                                        modalDelete: true
                                                                    })
                                                                }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                {/* <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/*{this.state.showModalAccept && (*/}
                {/*    <ModalAccept*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        contractId={this.state.contractId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalAccept: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{this.state.showModalReject && (*/}
                {/*    <ModalReject*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalReject: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {this.state.modalAddEditCategory && <ModalAddEditCategory show={this.state.modalAddEditCategory}
                                                                          modalType={this.state.modalType}
                                                                          item={this.state.itemSelected}
                                                                          onRefresh={() => {this.setState({modalAddEditCategory: false})
                                                                              this.getData()
                                                                          }}
                                                                          onHide={()=> this.setState({modalAddEditCategory: false})}
                />}

                {this.state.modalDelete && <ModalDeleteCategory show={this.state.modalDelete}
                                                                item={this.state.itemSelected}
                                                                onRefresh={() => {this.setState({modalDelete: false})
                                                                    this.getData()
                                                                }}
                                                                onHide={()=> this.setState({modalDelete: false})}
                />}

            </div>
        )
    }

}

export default PostCategoryManagement
