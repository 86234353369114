import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import reducer from '../src/store/reducer/index'
import { SnackbarProvider } from 'notistack';
// import { useHistory } from "react-router-dom";
// let history = useHistory();
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
const store = createStore(
    reducer,
);

window.OBPubConfig = {
    activeMenu: '',
    loading: false,
    preLoader: false,
    // menu: {
    //     active: ''
    // },
};
localStorage.setItem('ob_cl_language', 'vi');

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function(registration) {
            // console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function(err) {
            // console.log("Service worker registration failed, error:", err);
        });
}

ReactDOM.render(
    <Provider store = {store}>
        <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={10}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <HashRouter history={history}>
                <App />
            </HashRouter>
        </SnackbarProvider>
    </Provider>,
  document.querySelector('#root'),
);

// ReactDOM.render(<App />, document.getElementById('root'))
