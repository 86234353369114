import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import "../Modal.css"
import Cookies from "js-cookie";
import {withSnackbar} from "notistack";
import FormInput from "../../Form/input";
import NumberFormat from "react-number-format";

class ModalAddJob extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            jobName: null,
            numberOfReport: null,
            jobNameError: false,
        };
    }

    componentDidMount() {

    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const {name, numberOfReport} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">
                            <div>
                                <h1 className="text-center" style={{fontSize: 20,}}><b>THÊM MỚI JOB</b></h1>
                            </div>

                            <div className='col-12 row'>
                                <div className="col-5">
                                    <span className="text-black-50">Tên job<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <input
                                        maxLength={50} type="text" className="form-control shadow-none"
                                        value={name}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                if(this.state.name == null || this.state.numberOfReport == null || this.state.receiveMoney == null || this.state.name == "" || this.state.numberOfReport == "" || this.state.receiveMoney == "" ) {

                                                } else {
                                                    this.props.addJob(this.state.name, this.state.numberOfReport, this.state.receiveMoney)
                                                }
                                            }
                                        }}
                                        onChange={(val) => {
                                            this.setState({
                                                name: val.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 row mt-2'>
                                <div className="col-5">
                                    <span className="text-black-50">Số lượng báo cáo<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <input
                                        maxLength={2} type="text" className="form-control shadow-none"
                                        value={numberOfReport}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                if(this.state.name == null || this.state.numberOfReport == null || this.state.receiveMoney == null || this.state.name == "" || this.state.numberOfReport == "" || this.state.receiveMoney == "" ) {

                                                } else {
                                                    this.props.addJob(this.state.name, this.state.numberOfReport, this.state.receiveMoney)
                                                }
                                            }
                                        }}
                                        onChange={(val) => {
                                            this.setState({
                                                numberOfReport: val.target.value.replace(/[^0-9]/g,'')
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 row mt-2'>
                                <div className="col-5">
                                    <span className="text-black-50">Số tiền<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <NumberFormat
                                        value={this.state.receiveMoney}
                                        // displayType={"text"}
                                        thousandSeparator={true}
                                        className="form-control shadow-none" 
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                if(this.state.name == null || this.state.numberOfReport == null || this.state.receiveMoney == null || this.state.name == "" || this.state.numberOfReport == "" || this.state.receiveMoney == "" ) {

                                                } else {
                                                    this.props.addJob(this.state.name, this.state.numberOfReport, this.state.receiveMoney)
                                                }
                                            }
                                        }}
                                        // suffix={"VND"}
                                        onChange={(val) => {
                                            this.setState({
                                                receiveMoney: val.target.value
                                            })
                                        }}
                                    />
                                    {/*<input*/}
                                    {/*    maxLength={50} type="text" className="form-control shadow-none"*/}
                                    {/*    value={this.state.receiveMoney}*/}
                                    {/*    onChange={(val) => {*/}
                                    {/*        this.setState({*/}
                                    {/*            receiveMoney: val.target.value*/}
                                    {/*        })*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>
                            </div>

                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    disabled={this.state.name == null || this.state.numberOfReport == null || this.state.receiveMoney == null || this.state.name == "" || this.state.numberOfReport == "" || this.state.receiveMoney == "" }
                                    onClick={() => {
                                        this.props.addJob(this.state.name, this.state.numberOfReport, this.state.receiveMoney)

                                    }}
                                >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAddJob);

