import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import TableHeader from "../../../../components/TableHeader";
import NumberFormat from "react-number-format";
import ModalCreateBill from "../../../../components/Modal/admin/modalCreateBill";
import ModalCreateCertification from "../../../../components/Modal/admin/modalCreateCertification";
import ModalCreateEmployee from "../../../../components/Modal/admin/modalCreateEmployee";
import ModalCreateTermNumber from "../../../../components/Modal/admin/modalCreateTermNumber";
import AdminServicePlan from "../../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";


class manufacturing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateBill: false,
            showCreateCertification: false,
            showCreateHuman: false,
            showCreateTerm: false,
            data_DEPENDING_TYPE_manufacturing_bill: [],
            data_DEPENDING_TYPE_manufacturing_certification: [],
            data_DEPENDING_TYPE_manufacturing_human: [],
            data_DEPENDING_TYPE_manufacturing_term: [],
            header_DEPENDING_TYPE_bill: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countBill',
                    name: "Số lượng hóa đơn",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_certification: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countCertify',
                    name: "Số lượng chứng từ",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_human: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countHuman',
                    name: "Số lượng nhân sự",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_term: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countTerm',
                    name: "Số lượng kỳ (tháng)",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
        };
    }


    componentWillMount() {
        this.setState({servicePlanId: this.props.id}, () => this.getDetail())
    }

    getDetail = () => {
        let payload = {
            id: this.state.servicePlanId
        };
        AdminServicePlan.getDetail(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data
                })
                if(res.data?.priceType === 'PERMANENT') {
                    this.setState({
                        data_PERMANENT: res.data.serviceQuotationBaseResponseList
                    })
                } else if (res.data?.priceType === 'TYPES_OF_BUSINESS') {
                    this.setState({
                        data_TYPES_OF_BUSINESS_manufacturing: res.data.serviceQuotationBaseResponseList.filter(item => item.business === 'MANUFACTURING_MACHINING'),
                    }, () => {
                        this.setState({
                            data_DEPENDING_TYPE_manufacturing_bill: this.state.data_TYPES_OF_BUSINESS_manufacturing ? this.state.data_TYPES_OF_BUSINESS_manufacturing.filter(item => item.type === 'INVOICE') : [],
                            data_DEPENDING_TYPE_manufacturing_certification: this.state.data_TYPES_OF_BUSINESS_manufacturing ? this.state.data_TYPES_OF_BUSINESS_manufacturing.filter(item => item.type === 'DOCUMENT') : [],
                            data_DEPENDING_TYPE_manufacturing_human: this.state.data_TYPES_OF_BUSINESS_manufacturing ? this.state.data_TYPES_OF_BUSINESS_manufacturing.filter(item => item.type === 'EMPLOYEE') : [],
                            data_DEPENDING_TYPE_manufacturing_term: this.state.data_TYPES_OF_BUSINESS_manufacturing ? this.state.data_TYPES_OF_BUSINESS_manufacturing.filter(item => item.type === 'NEGOTIATE') : [],
                        })
                    })
                }
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "DISABLED":
                return <h6><span className="badge badge-warning">Vô hiệu hóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    render() {
        return (
            <div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Hóa đơn</label>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_bill}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_manufacturing_bill || this.state.data_DEPENDING_TYPE_manufacturing_bill.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_manufacturing_bill.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.requirementConstraint || 'Không có dữ liệu'}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        <td style={{textAlign: 'center',}}>
                                            {this.renderStatus(row.status)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Chứng từ</label>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_bill}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_manufacturing_certification || this.state.data_DEPENDING_TYPE_manufacturing_certification.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_manufacturing_certification.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.requirementConstraint || 'Không có dữ liệu'}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        <td style={{textAlign: 'center',}}>
                                            {this.renderStatus(row.status)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Nhân sự</label>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_human}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_manufacturing_human || this.state.data_DEPENDING_TYPE_manufacturing_human.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_manufacturing_human.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.requirementConstraint || 'Không có dữ liệu'}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        <td style={{textAlign: 'center',}}>
                                            {this.renderStatus(row.status)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">*/}
                {/*        <label className='label-item'>Số kỳ</label>*/}
                {/*    </div>*/}
                {/*    <div className="table-wrapper">*/}
                {/*        <table className="table table-striped table-data table-bordered">*/}
                {/*            <thead>*/}
                {/*            <TableHeader header={this.state.header_DEPENDING_TYPE_term}*/}
                {/*                         clickSort={(data) => {*/}
                {/*                         }}/>*/}
                {/*            </thead>*/}
                {/*            {!this.state.data_DEPENDING_TYPE_manufacturing_term || this.state.data_DEPENDING_TYPE_manufacturing_term.length == 0*/}
                {/*                ? <tbody>*/}
                {/*                <tr>*/}
                {/*                    <td colSpan={4}>*/}
                {/*                        <div className='text-center'>Chưa có dữ liệu</div>*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*                : <tbody>*/}
                {/*                {this.state.data_DEPENDING_TYPE_manufacturing_term.map((row, index) => (*/}
                {/*                    <tr key={row.id} style={{height: 50}}>*/}
                {/*                        <td style={{textAlign: 'center'}}>{index + 1}</td>*/}
                {/*                        <td style={{textAlign: 'center',}}>*/}
                {/*                            {row.countTerm}*/}
                {/*                        </td>*/}
                {/*                        <td style={{textAlign: 'right'}}>*/}
                {/*                            <NumberFormat*/}
                {/*                                value={row.price}*/}
                {/*                                displayType={"text"}*/}
                {/*                                thousandSeparator={true}*/}
                {/*                                suffix={" VND"}*/}
                {/*                            />*/}
                {/*                        </td>*/}
                {/*                        <td style={{textAlign: 'center',}}>*/}
                {/*                            {this.renderStatus(row.status)}*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                ))}*/}
                {/*                </tbody>}*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        );
    }
}

export default manufacturing
