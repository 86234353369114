import React, {Component} from "react";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import PageHeader from "../../../components/PageHeader";
import {toast} from "react-toastify";



class OrderAdvanceService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            showLoading: false,

        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {

        })
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getListOrderOnSearch();
            }
        );
    };

    render() {
        const {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="order-advance-service"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Đăng ký dịch vụ nâng cao"/>

                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">

                                    </div>
                                    <div className="pagination-right justify-flex-end">
                                        {/*<Pagination*/}
                                        {/*    activePage={this.state.pageNumber + 1}*/}
                                        {/*    itemsCountPerPage={this.state.pageSize}*/}
                                        {/*    totalItemsCount={this.state.totalItem}*/}
                                        {/*    changeHandler={page => this.handleChangePage(page)}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default OrderAdvanceService
