
import { stringify } from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let DashboardCore = {
    getAccumulated: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/accumulated`,
            method: 'GET'
        }, callback)
    },
    dashBoardSalesInYear: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/sales-in-year`,
            method: 'GET'
        }, callback)
    },
    getAccumulatedToNow: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/accumulated-to-now`,
            method: 'GET'
        }, callback)
    },
    dashBoardSalesToNow: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/sales-to-now`,
            method: 'GET'
        }, callback)
    },
    getContractOrder: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/contract-order`,
            method: 'GET'
        }, callback)
    },
    getRevenue: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/revenue`,
            method: 'GET'
        }, callback)
    },
    getSaleByMonth: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/sales/${payload.month}`,
            method: 'GET'
        }, callback)
    },
    adminGetRevenue: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/dash-board/revenue-for-admin`,
            method: 'GET'
        }, callback)
    },
};
export default DashboardCore
