import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import "../Modal.css"
import Cookies from "js-cookie";
import {withSnackbar} from "notistack";
import FormInput from "../../Form/input";
import NumberFormat from "react-number-format";
import moment from "moment";
import DatePicker from "react-datepicker";

class ModalAddPayment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentTime: '',
            jobName: '',
            jobNameError: false,
            paymentDate: null,
            paymentDateErr: false,
        };
    }

    componentDidMount() {

    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        let {paymentTime} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body class='add-new-payment'>
                        <div className="modal-container">
                            <div>
                                <h1 className="text-center" style={{fontSize: 20,}}><b>Thêm mới lần thanh toán</b></h1>
                            </div>
                            <div className='col-12 row'>
                                <div className="col-5 mt-2">
                                    <span className="text-black-50">Hạn thanh toán<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <DatePicker
                                        className={this.state.paymentDateErr?"w-auto form-control border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input":"w-auto form-control react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.paymentDate === null ? null :new Date(this.state.paymentDate)}
                                        // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                        // minDate={new Date()}
                                        minDate={new Date()}
                                        isClearable
                                        // value={this.state.dateIssue}
                                        dateFormat="dd-MM-yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    paymentDate: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                    paymentDateErr:false
                                                })
                                            }
                                        }
                                    />
                                    {this.state.paymentDateErr?
                                        <div className="row">
                                            <label className="col-12 font-err mb-0">Vui lòng chon ngày !</label>
                                        </div>:<div/>}
                                </div>
                            </div>
                            <div className='col-12 row mt-2'>
                                <div className="col-5">
                                    <span className="text-black-50">Số tiền<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <NumberFormat
                                        value={this.state.sendMoney}
                                        // displayType={"text"}
                                        thousandSeparator={true}
                                        className="form-control shadow-none"
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                if(this.state.paymentDate && this.state.sendMoney != 0 && this.state.sendMoney != null)
                                                this.state.paymentDate?this.props.addPayment(this.state.paymentDate, this.state.sendMoney):this.setState({paymentDateErr:true})
                                            }
                                        }}
                                        // suffix={"VND"}
                                        onChange={(val) => {
                                            this.setState({
                                                sendMoney: val.target.value
                                            })
                                        }}
                                    />
                                    {/*<input*/}
                                    {/*    maxLength={50} type="text" className="form-control shadow-none"*/}
                                    {/*    value={this.state.receiveMoney}*/}
                                    {/*    onChange={(val) => {*/}
                                    {/*        this.setState({*/}
                                    {/*            receiveMoney: val.target.value*/}
                                    {/*        })*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            {/*<div className='col-12 row mt-2'>*/}
                            {/*    <div className="col-4">*/}
                            {/*        <span className="text-black-50">Số lần thanh toán<span style={{color: 'red'}}>*</span></span>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-8">*/}
                            {/*        <input*/}
                            {/*            maxLength={2} type="text" className="form-control shadow-none"*/}
                            {/*            value={paymentTime}*/}
                            {/*            onChange={(val) => {*/}
                            {/*                this.setState({*/}
                            {/*                    paymentTime: val.target.value*/}
                            {/*                })*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    disabled={this.state.paymentDate==null || this.state.sendMoney == 0|| this.state.sendMoney == null}
                                    onClick={() => {
                                        this.state.paymentDate?this.props.addPayment(this.state.paymentDate, this.state.sendMoney):this.setState({paymentDateErr:true})
                                    }}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Đồng ý
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAddPayment);

