import React from 'react';
import './style.css';
import '../style.css';
import Convert from "../../../../utils/convertUrlPra";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {changeAlias} from "../../../../utils/common";
import NumberFormat from "react-number-format";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";

import PublicServiceQuotation from "../../../../services/ob-core/public-service-quotation";
import OrderServiceBaseApi from "../../../../services/ob-core/order-service-base";
import Cookies from "js-cookie";
import ModalLogin from "../../../../components/Modal/ModalLogin";
import ModalSignup from "../../../../components/Modal/ModalSignup";
import ModalSignupMember from "../../../../components/Modal/ModalSignupMember";
import ModalOtp from "../../../../components/Modal/ModalOtp";
import ModalForgotPassword from "../../../../components/Modal/modalForgotPassword";
import SidebarMobile from "../../../../components/SidebarMobile";
import Tooltip from "@material-ui/core/Tooltip";
import CommentApi from "../../../../services/ob-core/comment";

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: 0,
            priceType: '',
            allService: [],
            selectData: [],
            numInvoice: [],
            numDoc: [],
            numEmp: [],
            numTerm: [],
            selectedPlan: null,
            totalPrice: [],
            oldPrice: [],
            totalPrice1: 0,
            business: '',
            selectedIndex: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let params = Convert.urlParams(this.props.location.search);
        this.setState({
            serviceId: params.s?.split("-")[0],
            priceType: params?.t,
            showLoading: true
        }, () => {
            if (this.state.priceType === "permanent")
                this.getByServicePlanId();
            else {
                this.getAllServiceQuotation('COMMERCE_SERVICE');
                this.getAllServiceQuotation('MANUFACTURING_MACHINING');
                this.getAllServiceQuotation('BUILDING_INSTALLATION');
            }
        })
    }
    getByServicePlanId = () => {
        let payload = {
            id: this.state.serviceId
        }
        PublicServiceQuotation.getByServicePlan(payload, res => {
            if (res.success) {
                this.setState({
                    planPrice: res.data,
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false
                })
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!" + res.message)
            }
        })
    }

    getAllServiceQuotation = (business) => {
        let payload = {
            business: business,
            servicePlanId: this.state.serviceId,
        }
        PublicServiceQuotation.getByServicePlanAndBusiness(payload, res => {
            if (res.success) {
                let selectData = this.state.selectData
                if (business === 'COMMERCE_SERVICE') selectData[0] = {
                    invoice: res.data.filter((_item) => _item.type === "INVOICE"),
                    document: res.data.filter((_item) => _item.type === "DOCUMENT"),
                    employee: res.data.filter((_item) => _item.type === "EMPLOYEE"),
                }
                else if (business === 'MANUFACTURING_MACHINING') selectData[1] = {
                    invoice: res.data.filter((_item) => _item.type === "INVOICE"),
                    document: res.data.filter((_item) => _item.type === "DOCUMENT"),
                    employee: res.data.filter((_item) => _item.type === "EMPLOYEE"),
                }
                else if (business === 'BUILDING_INSTALLATION') selectData[2] = {
                    invoice: res.data.filter((_item) => _item.type === "INVOICE"),
                    document: res.data.filter((_item) => _item.type === "DOCUMENT"),
                    employee: res.data.filter((_item) => _item.type === "EMPLOYEE"),
                }
                let allService = this.state.allService;
                allService = allService.concat(res.data);
                this.setState({
                    allService,
                    selectData,
                    showLoading: false
                });
            } else {
                this.setState({
                    showLoading: false
                })
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!" + res.message)
            }
        });
    };

    calculatePrice = (index) => {
        let {numDoc, numEmp, numInvoice, numTerm} = this.state;
        if ((numDoc.length === 0 || numDoc.length > index) && (numEmp.length === 0 || numEmp.length > index) && numInvoice.length > index && numTerm.length > index){
            let payload = {
                numberOfDocumentsConstraintId: numDoc[index] || 0,
                numberOfEmployeesConstraintId: numEmp[index] || 0,
                numberOfInvoicesConstraintId: numInvoice[index] || 0,
                numberOfMonths: numTerm[index],
                servicePlanId: this.state.serviceId
            }
            OrderServiceBaseApi.calculatePrice(payload, res => {
                if (res.success) {
                    let _totalPrice       = this.state.totalPrice;
                    let _oldPrice         = this.state.oldPrice;
                    _totalPrice[index]    = res.data.deal;
                    _oldPrice[index]      = res.data.price;
                    console.log(this.state.totalPrice)
                    console.log(_totalPrice)
                    console.log(res.data.deal)
                    console.log(res.data.price)
                    this.setState({
                        totalPrice: _totalPrice,
                        oldPrice: _oldPrice,
                        showLoading: false
                    }, ()=>{
                        console.log(index)
                        console.log(res.data.deal)
                        console.log(this.state.totalPrice)
                        console.log(this.state.totalPrice[0])
                    })
                } else {
                    toast.error("Đã xảy ra lỗi khi tính giá cước!");
                }
            })
        }
    }
    getListStartEnd(listCheck) {
        if (listCheck.length >0){
            let objCheck = listCheck[0].requirementConstraint.split("-");
            objCheck[0] = parseInt(objCheck[0].replace(/ /g,''));
            objCheck[1] = parseInt(objCheck[1].replace(/ /g,''));
            return objCheck;
        }
        return [0, 0];
    }
    renderNewsUrl = (value) => {
        let title = changeAlias(value);
        return title.replace(/ /g, "-")
    }
    //haovc
    showModalForgot = () => {
        this.setState({
            modalLogin: false,
            modalForgotPassword: true
        })
    }

    onCloseModalForgotPassword = () => {
        this.setState({
            modalForgotPassword: false
        })
    }

    toggleSidebarMobile = () => {
        let sidebar = this.state.sidebarMobile
        this.setState({
            sidebarMobile: !sidebar
        })
    }
    showModalForgotPassword3 = (token, phone) => {
        this.setState({
            modalForgotPassword: false,
            modalForgotPassword3: true,
            tokenForgotPass: token,
            phoneNumberForgot: phone
        })
    }
    onCloseModalForgotPassword3 = (open2) => {
        this.setState({
            modalForgotPassword3: false,
        })
        if(open2) {
            this.setState({
                modalForgotPassword2: true,
            })
        }
        // toast.success("Chúng tôi đã gửi đường dẫn vào email của bạn. Vui lòng kiểm tra email!")
    }

    saveOtp = (tokenForgotPass, otp) => {
        this.setState({
            tokenForgotPass,
            otp,
        })
    }

    onCloseModalForgotPassword2 = () => {
        this.setState({
            modalForgotPassword2: false,
        })
    }
    onOpenModalLogin = (event) => {
        event.preventDefault();
        this.setState({modalLogin: true});
    };
    modalLoginClose = () => this.setState({modalLogin: false});
    onOpenModalSignup = (event) => {
        // event.preventDefault();
        this.setState({modalSignup: true, modalLogin: false});
    };
    onOpenModalSignupMember = (event) => {
        // event.preventDefault();
        this.setState({modalSignupMember: true, modalLogin: false});
    };
    onOpenModalOtp = (event) => {
        // event.preventDefault();
        this.setState({modalOtp: true});
    };
    modalOtpClose = () => this.setState({modalOtp: false});
    modalSignupClose = () => this.setState({modalSignup: false});

    setSignupInfo = (signupInfo) => {
        this.setState({signupInfo}, () => {
            this.modalSignupClose();
            this.onOpenModalOtp()
        })
    }
    //end
    render() {
        const {selectData, planPrice, selectedIndex} = this.state;
        let token = Cookies.get("ob_cl_token");
        let soHoaDon  = "Số hóa đơn là lượng Hóa đơn do người bán, hoặc người mua lập, ghi nhận thông tin bán hàng, mua hàng hóa, dịch vụ theo quy định của pháp luật. ( bao gồm hóa đơn đầu ra, hóa đơn đầu vào, hồ sơ hải quan xuất, nhập khẩu. )";
        let soChungTu = "Chứng từ kế toán là những giấy tờ và vật mang tin phản ánh nghiệp vụ kinh tế, tài chính phát sinh đã hoàn thành, làm căn cứ ghi sổ kế toán như các chứng từ ngân hàng/ chứng từ thu chi, bảo hiểm, giấy nộp tiền vào ngân sách nhà nước, khế ước vay, hợp đồng thuê nhà, thuê tài sản, thông báo nộp và phạt của cơ quan Nhà nước và các hồ sơ khác ( nếu có) mà không bao gồm hóa đơn mua vào bán ra được quy định ở trên.\n";
        let soNhanSu  = "Số lượng người lao động được kê khai sử dụng của doanh nghiệp (cả nộp và chưa nộp bảo hiểm)";
        let soKy      = "Thời gian tính phí. Tối thiểu 6 tháng và theo niên độ năm";

        return (
            <div className='stepper-wrapper container'>
                <div className='stepper-router'>
                    <a href=''>
                        Trang chủ / <strong>Đăng ký dịch vụ</strong>
                    </a>
                </div>
                {this.state.showLoading &&
                <div className="content-loading col-12 ml-3 align-items-center"
                     style={{display: 'block'}}>
                    <div className="spinner-grow text-info"/>
                </div>
                }
                <ul className="step-top d-flex align-items-center justify-content-center">
                    <li className="step step-1">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                1
                            </span>
                        </div>
                        <span className="text">
                            Chọn dịch vụ
                        </span>
                    </li>
                    <li className="step step-2 active">
                        <div className="step-number">
                            2
                            <span className="sub-number">
                                2
                            </span>
                        </div>
                        <span className="text">
                            Chọn gói cước
                        </span>
                    </li>
                    <li className="step step-3">
                        <div className="step-number">
                            3
                            <span className="sub-number">
                                3
                            </span>
                        </div>
                        <span className="text">
                            Chọn supervisor
                        </span>
                    </li>
                    <li className="step step-4">
                        <div className="step-number">
                            4
                            <span className="sub-number">
                                4
                            </span>
                        </div>
                        <span className="text">
                            Xác nhận
                        </span>
                    </li>
                </ul>
                <h2 className='stepper-title step-title-large app-separator'>2. Chọn gói cước</h2>
                <div className='stepper-content max-1480 app-separator'>
                    {this.state.priceType !== 'permanent'
                        ? <div className='row'>
                            <div className='col-md-4 mb-3'>
                                <div className={this.state.selectedIndex === 0 ? 'selected step2-item' : 'step2-item'}>
                                    <div className='step2-item-content text-left'>
                                        <div className='step2-item-content-title mb-4'>
                                            Thương mại-dịch vụ<br/>đầu tư tài chính
                                        </div>
                                        <div className='step2-form-content'>
                                            <form>
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soHoaDon} </span>} >
                                                        <div className='col-sm-5'>Số hóa đơn</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numInvoice = this.state.numInvoice
                                                                    numInvoice[0] = val.target.value
                                                                    this.setState({
                                                                        numInvoice,
                                                                    });
                                                                    this.calculatePrice(0);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL hóa đơn</option>
                                                                {selectData[0]?.invoice?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}
                                                                            name={_item.requirementConstraint}>{_item.requirementConstraint=== "0 -      0"?"Không phát sinh hóa đơn":"Từ "+_item.requirementConstraint +" hóa đơn"}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectData[0]?.document && selectData[0]?.document.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soChungTu} </span>} >
                                                        <div className='col-sm-5'>Số chứng từ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numDoc = this.state.numDoc
                                                                    numDoc[0] = val.target.value
                                                                    this.setState({
                                                                        numDoc,
                                                                    });
                                                                    this.calculatePrice(0);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL chứng từ</option>
                                                                {selectData[0]?.document?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}
                                                                            key={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {selectData[0]?.employee && selectData[0]?.employee.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soNhanSu} </span>} >
                                                        <div className='col-sm-5'>Nhân sự</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numEmp = this.state.numEmp
                                                                    numEmp[0] = val.target.value
                                                                    this.setState({
                                                                        employeeSelected: parseInt(val.target.value),
                                                                        numEmp,
                                                                    });
                                                                    this.calculatePrice(0);
                                                                }}
                                                            >
                                                                <option value={0}>SL nhân sự</option>
                                                                {selectData[0]?.employee?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}
                                                                            key={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soKy} </span>} >
                                                        <div className='col-sm-5'>Số kỳ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numTerm = this.state.numTerm;
                                                                    numTerm[0] = val.target.value
                                                                    this.setState({
                                                                        numTerm
                                                                    });
                                                                    this.calculatePrice(0);
                                                                }}
                                                            >
                                                                <option value={0}>Số Kỳ</option>
                                                                <option value={1}>1 tháng</option>
                                                                <option value={6}>6 tháng</option>
                                                                <option value={12}>12 tháng</option>
                                                                <option value={18}>18 tháng</option>
                                                                <option value={24}>2 năm</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='payment-counting mt-4 text-center'>
                                                <p
                                                    className='color-main text-bold cursor-pointer'
                                                    onClick={() => this.calculatePrice(0)}
                                                >
                                                    Tính giá cước 1
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        style={{
                                                            textDecorationLine: "line-through",
                                                            color: "#e80020",
                                                            fontWeight: "bold",
                                                            fontSize: 17
                                                        }}
                                                        value={this.state.oldPrice[0] || ""}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        value={this.state.totalPrice[0] || 0}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='step2-btn-buy bg-green color-main-dark cursor-pointer'
                                        onClick={() => {
                                            let disabled= !this.state.totalPrice[0]
                                            if(!disabled){
                                                this.setState({
                                                    business: 'COMMERCE_SERVICE',
                                                    selectedIndex: 0
                                                })
                                            }

                                        }}
                                    >
                                        {this.state.selectedIndex !== 0 ?
                                            <span className='w-100 d-block'>
                                                Đăng ký
                                            </span>
                                            : <CheckCircleTwoToneIcon className=""
                                                                      fontSize="large"
                                                                      style={{color: "green"}}/>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <div className={this.state.selectedIndex === 1 ? 'selected step2-item' : 'step2-item'}>
                                    <div className='step2-item-content text-left'>
                                        <div className='step2-item-content-title mb-4'>
                                            Sản xuất - gia công<br/> <br/>
                                        </div>
                                        <div className='step2-form-content'>
                                            <form>
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soHoaDon} </span>} >
                                                        <div className='col-sm-5'>Số hóa đơn</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numInvoice = this.state.numInvoice
                                                                    numInvoice[1] = val.target.value
                                                                    this.setState({
                                                                        numInvoice,
                                                                    });
                                                                    this.calculatePrice(1);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL hóa đơn</option>
                                                                {selectData[1]?.invoice?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}
                                                                            name={_item.requirementConstraint}>{_item.requirementConstraint=== "0 -      0"?"Không phát sinh hóa đơn":"Từ "+_item.requirementConstraint +" hóa đơn"}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectData[1]?.document && selectData[1]?.document.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soChungTu} </span>} >
                                                        <div className='col-sm-5'>Số chứng từ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numDoc = this.state.numDoc
                                                                    numDoc[1] = val.target.value
                                                                    this.setState({
                                                                        numDoc,
                                                                    });
                                                                    this.calculatePrice(1);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL chứng từ</option>
                                                                {selectData[1]?.document?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {selectData[1]?.employee && selectData[1]?.employee.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soNhanSu} </span>} >
                                                        <div className='col-sm-5'>Nhân sự</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numEmp = this.state.numEmp
                                                                    numEmp[1] = val.target.value
                                                                    this.setState({
                                                                        employeeSelected: parseInt(val.target.value),
                                                                        numEmp,
                                                                    });
                                                                    this.calculatePrice(1);
                                                                }}
                                                            >
                                                                <option value={0}>SL nhân sự</option>
                                                                {selectData[1]?.employee?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soKy} </span>} >
                                                        <div className='col-sm-5'>Số kỳ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numTerm = this.state.numTerm;
                                                                    numTerm[1] = val.target.value
                                                                    this.setState({
                                                                        numTerm
                                                                    });
                                                                    this.calculatePrice(1);
                                                                }}
                                                            >
                                                                <option value={0}>Số Kỳ</option>
                                                                <option value={1}>1 tháng</option>
                                                                <option value={6}>6 tháng</option>
                                                                <option value={12}>12 tháng</option>
                                                                <option value={18}>18 tháng</option>
                                                                <option value={24}>2 năm</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='payment-counting mt-4 text-center'>
                                                <p
                                                    className='color-main text-bold cursor-pointer'
                                                    onClick={() => this.calculatePrice(1)}
                                                >
                                                    Tính giá cước
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        style={{
                                                            textDecorationLine: "line-through",
                                                            color: "#e80020",
                                                            fontWeight: "bold",
                                                            fontSize: 17
                                                        }}
                                                        value={this.state.oldPrice[1] || ""}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        value={this.state.totalPrice[1] || 0}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='step2-btn-buy bg-green color-main-dark cursor-pointer'
                                        disabled={this.state.totalPrice[1] == 0}

                                        onClick={() => {
                                            let disabled= !this.state.totalPrice[1]
                                            if(!disabled) {
                                                this.setState({
                                                    business: 'MANUFACTURING_MACHINING',
                                                    selectedIndex: 1
                                                })
                                            }
                                        }}
                                    >
                                        {this.state.selectedIndex !== 1 ?
                                            <span className='w-100 d-block'>
                                                Đăng ký
                                            </span>
                                            : <CheckCircleTwoToneIcon className=""
                                                                      fontSize="large"
                                                                      style={{color: "green"}}/>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <div className={this.state.selectedIndex === 2 ? 'selected step2-item' : 'step2-item'}>
                                    <div className='step2-item-content text-left'>
                                        <div className='step2-item-content-title mb-4'>
                                            Xây dựng - lắp đặt <br/> đầu tư bất động sản
                                        </div>
                                        <div className='step2-form-content'>
                                            <form>
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soHoaDon} </span>} >
                                                        <div className='col-sm-5'>Số hóa đơn</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numInvoice = this.state.numInvoice
                                                                    numInvoice[2] = val.target.value
                                                                    this.setState({
                                                                        numInvoice,
                                                                    });
                                                                    this.calculatePrice(2);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL hóa đơn</option>
                                                                {selectData[2]?.invoice?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}
                                                                            name={_item.requirementConstraint}>{_item.requirementConstraint=== "0 -    0"?"Không phát sinh hóa đơn":"Từ "+_item.requirementConstraint +" hóa đơn"}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectData[2]?.document && selectData[2]?.document.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soChungTu} </span>} >
                                                        <div className='col-sm-5'>Số chứng từ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numDoc = this.state.numDoc
                                                                    numDoc[2] = val.target.value
                                                                    this.setState({
                                                                        numDoc,
                                                                    });
                                                                    this.calculatePrice(2);
                                                                }}
                                                            >
                                                                <option value={0} hidden>SL chứng từ</option>
                                                                {selectData[2]?.document?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {selectData[2]?.employee && selectData[2]?.employee.length > 0 &&
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soNhanSu} </span>} >
                                                        <div className='col-sm-5'>Nhân sự</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numEmp = this.state.numEmp
                                                                    numEmp[2] = val.target.value
                                                                    this.setState({
                                                                        employeeSelected: parseInt(val.target.value),
                                                                        numEmp,
                                                                    });
                                                                    this.calculatePrice(2);
                                                                }}
                                                            >
                                                                <option value={0}>SL nhân sự</option>
                                                                {selectData[2]?.employee?.map((_item, index) => {
                                                                    return (
                                                                        <option
                                                                            value={_item.id}>{_item.requirementConstraint}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className='row align-items-center mb-2'>
                                                    <Tooltip arrow placement="bottom" title={<span style={{ color: "white", margin: 20, lineHeight: "22px", fontFamily: 'Montserrat', fontSize: "14px"}} > {soKy} </span>} >
                                                        <div className='col-sm-5'>Số kỳ</div>
                                                    </Tooltip>
                                                    <div className='col-sm-7'>
                                                        <div className='form-group mb-0'>
                                                            <select
                                                                className='form-control'
                                                                onChange={(val) => {
                                                                    let numTerm = this.state.numTerm;
                                                                    numTerm[2] = val.target.value
                                                                    this.setState({
                                                                        numTerm
                                                                    });
                                                                    this.calculatePrice(2);
                                                                }}
                                                            >
                                                                <option value={0}>Số Kỳ</option>
                                                                <option value={1}>1 tháng</option>
                                                                <option value={6}>6 tháng</option>
                                                                <option value={12}>12 tháng</option>
                                                                <option value={18}>18 tháng</option>
                                                                <option value={24}>2 năm</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='payment-counting mt-4 text-center'>
                                                <p
                                                    className='color-main text-bold cursor-pointer'
                                                    onClick={() => this.calculatePrice(2)}
                                                >
                                                    Tính giá cước
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        style={{
                                                            textDecorationLine: "line-through",
                                                            color: "#e80020",
                                                            fontWeight: "bold",
                                                            fontSize: 17
                                                        }}
                                                        value={this.state.oldPrice[2] || ""}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                                <p>
                                                    <NumberFormat
                                                        value={this.state.totalPrice[2] || 0}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" đ"}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='step2-btn-buy bg-green color-main-dark cursor-pointer'
                                        disabled={!this.state.totalPrice[2]}
                                        onClick={() => {
                                            let disabled= !this.state.totalPrice[2]
                                            if(!disabled) {
                                                this.setState({
                                                    business: 'BUILDING_INSTALLATION',
                                                    selectedIndex: 2
                                                })
                                            }
                                        }}
                                    >
                                        {this.state.selectedIndex !== 2 ?
                                            <span
                                                className='w-100 d-block'
                                            >
                                                Đăng ký
                                            </span>
                                            : <CheckCircleTwoToneIcon className=""
                                                                      fontSize="large"
                                                                      style={{color: "green"}}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className='row'>
                            {planPrice?.map((item, index) => <div className='col-md-3 mb-3'>
                                <div
                                    className={item.id === this.state.selectedPlan ? 'selected step2-item' : 'step2-item'}>
                                    <div className='step2-item-content text-left'>
                                        {/*<div className='step2-item-content-title mb-4'>*/}
                                        {/*    Thông tin - dịch vụ*/}
                                        {/*</div>*/}
                                        <div className='step2-form-content1'>
                                            <form>
                                                {item.requirementConstraint}
                                            </form>
                                            <div className='payment-counting mt-4 text-center'>
                                                <p className='color-main text-bold'>Giá gói cước</p>
                                                <NumberFormat
                                                    value={item?.price}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    suffix={" đ"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='step2-btn-buy bg-green color-main-dark cursor-pointer'>
                                        {this.state.selectedPlan !== item.id ?
                                            <span
                                                className='w-100 d-block'
                                                onClick={() => this.setState({
                                                    selectedPlan: item.id,
                                                    totalPrice1: item.price
                                                })}
                                            >
                                                Đăng ký
                                            </span>
                                            : <CheckCircleTwoToneIcon className=""
                                                                      fontSize="large"
                                                                      style={{color: "green"}}/>}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    }
                </div>
                <div className='btn-redirect btn-bottom btn-step2'>
                    <Link to={{pathname: '/home/select-plan-step-1'}}>
                        <button className='btn btn-cancel-stepper'>Hủy</button>
                    </Link>
                    <Link
                        to={(selectedIndex != null || this.state.selectedPlan) && token? {
                            pathname: "/home/select-plan-step-3",
                            search: this.state.priceType !== 'permanent'
                                ? `${this.props.location.search}&b=${this.state.business}&numI=${this.state.numInvoice[selectedIndex]}${this.state.numDoc[selectedIndex] ? '&numD=' + this.state.numDoc[selectedIndex] : ''}${this.state.numEmp[selectedIndex] ? '&numE=' + this.state.numEmp[selectedIndex] : ''}&numT=${this.state.numTerm[selectedIndex]}&p=${this.state.totalPrice[selectedIndex]}&op=${this.state.oldPrice[selectedIndex]}`
                                : `${this.props.location.search}&plan=${this.state.selectedPlan}&p=${this.state.totalPrice1}`
                            ,
                            state: {
                                fromDashboard: true,
                            }
                        } : {}}
                        style={{color: "#000000"}}
                    >
                        <button
                            className='btn btn-next-stepper'

                            disabled={this.state.selectedIndex == null && !this.state.selectedPlan && (!this.state.totalPrice[0] ||!this.state.totalPrice[1]||!this.state.totalPrice[2])}

                            onClick={() => {
                                if(!token){
                                    this.setState({modalLogin: true});
                                }
                            }}
                        >
                            Tiếp theo
                        </button>
                    </Link>
                </div>

                {this.state.modalLogin && <ModalLogin show={this.state.modalLogin} onHide={this.modalLoginClose}
                                                      onOpenModalSignup={this.onOpenModalSignup}
                                                      showModalForgot={this.showModalForgot}/>}
                {this.state.modalSignup &&
                <ModalSignup show={this.state.modalSignup} onHide={this.modalSignupClose} setInfo={this.setSignupInfo}
                             showOtp={this.onOpenModalOtp}/>}
                {this.state.modalSignupMember &&
                <ModalSignupMember show={this.state.modalSignupMember} onHide={this.modalSignupMemberClose}/>}
                {this.state.modalOtp &&
                <ModalOtp show={this.state.modalOtp} onHide={this.modalOtpClose} signupInfo={this.state.signupInfo}/>}
                {this.state.modalForgotPassword && <ModalForgotPassword show={this.state.modalForgotPassword}
                                                                        setData={this.showModalForgotPassword3}
                                                                        onHideModal={this.onCloseModalForgotPassword}/>}
                <SidebarMobile show={this.state.sidebarMobile}
                               toggleSidebarMobile={this.toggleSidebarMobile}
                               onOpenModalLogin={this.onOpenModalLogin}
                               onOpenModalSignup={this.onOpenModalSignup}/>
            </div>
        );
    }
}

export default Step2;
