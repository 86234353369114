import React from 'react';
import {withSnackbar, SnackbarProvider} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";
import Utils from '../../utils/utils'

import {
    Modal,
} from 'react-bootstrap';

import PublicOrderApi from "../../services/ob-core/public-order-service";
import PublicContract from "../../services/ob-core/public-contract";

class ModalCancelJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailId: '',
            reasonReject: '',
            listData: {}

        };
    }

    componentDidMount() {
        this.setState({
            reasonReject: ''
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                reasonReject: '', detailId: this.props.data?.id
            });
        }
    }


    reject = () => {
        if (this.state.reasonReject === '') {
            this.props.enqueueSnackbar('Bạn chưa nhập lý do hủy Job', {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                },
                variant: 'error',
            });
        } else {

            let payload = {
                id: this.props.selectedOrder.id,
                content: this.state.reasonReject
            };
            console.log('abcd>>>>>>:', payload);
            PublicContract.cancelJob(payload, res => {
                if (res.success === true) {
                    console.log('cancel>>>>>>:', res);
                    this.props.enqueueSnackbar('Hủy Job thành công', {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        variant: 'success',
                    });
                    this.setState({
                        listData: res.content
                    })
                    this.props.onRefresh()
                } else {
                    console.log('toang>>>>>', res)
                }
            });
        }
    }


    render() {
        let {listData, pageNumber, header} = this.state;
        let self = this;
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 16,}}><b>Bạn cần nhập lý do trước khi hủy Job</b></h1>
                        </div>

                        <div>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập lý do'}
                                maxLength={200}
                                value={this.state.reasonReject}
                                onChange={(e) => {
                                    self.setState({reasonReject: e.target.value.trimLeft()})
                                }}
                            />
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s background-btn w-60 type-button-fix btn btn-primary"
                                        onClick={this.reject}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s width-btn type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                           Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withSnackbar(ModalCancelJob)
