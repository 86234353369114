import React from 'react';
import {stringify} from 'qs';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import ServicePlan from "../../customer/service-plan";

import ModalDetailOrder from "../../../components/Modal/modalDetailOrder";
import ModalDetailOrderAdvanced from "../../../components/Modal/modalDetailOrderAdvanced";
import ModalAssignOrder from "../../../components/Modal/modalAssignOrder";
import ModalAssignAccountant from "../../../components/Modal/modalAssignAccountant";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../services/ob-user/supervisor";
import PublicOrderApi from "../../../services/ob-core/public-order-service";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import ModalAcceptOrder from "../../../components/Modal/modalAcceptOrder";
import ModalResendOrder from "../../../components/Modal/modalResendOrder";
import {toast} from "react-toastify";
import CallCenterApi from "../../../services/ob-user/call-center";

class AdvanceOrdersManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            pageNumberSup: 0,
            pageSizeSup: 10,
            totalItem: null,
            orderCode: null,
            sort: "cmnCreatedAt=desc",
            status: "",
            status_temp: '',
            listCustomerId: [],
            list: [],
            listSupervisorId: [],
            listCallCenterId: [],
            modalDetailOrder: false,
            modalAssignOrder: false,
            selectedId: '',
            supIdFilter: '',
            selectedCustomer: null,
            supervisorName: '',
            customerName: '',
            showResend: false,
            supervisorSuggestions: [],
            customer_temp: '',
            customerList: [],
            servicePlan: [],
            servicePlanId: null,
            servicePlanId_temp: null,
            showConfirm: false,
            clearSearchOder: false,
            selectedOrder: "",
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã Order",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false
                },
                //
                // {
                //     id: "value",
                //     name: "Giá trị hợp đồng (VND)",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "supervisorId",
                    name: "Người phụ trách",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "callCenterId",
                    name: "Call Center",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "serviceId",
                    name: "Gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian gửi yêu cầu",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: true
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false
                }
            ]
        }
    }

    componentDidMount() {
        this.getOrderList();
        // this.getListSupervisor()
        this.getServicePlan()
    }

    onhandleSearch = () => {
        let {code_temp, status_temp, servicePlanId_temp, selectedCustomer, customerId_temp} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0,
                // servicePlanId: servicePlanId_temp,
                customerId: selectedCustomer ? selectedCustomer.id : customerId_temp
            },
            () => {
                this.getOrderList();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                orderType: "ADVANCE",
                status: "",
                status_temp: "",
                servicePlanId: null,
                servicePlanId_temp: '',
                selectedCustomer: null,
                customerName: '',
                customerID: null,
            },
            () => this.onhandleSearch()
        );
    }


    getOrderList = () => {
        let self = this;
        let payload = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            orderType: "ADVANCE",
            sort: this.state.sort,
            status: this.state.status,
            customerId: this.state.customerId,
            // servicePlanId: this.state.servicePlanId,
        }
        PublicOrderApi.ccSearchByServicePlanIdAndCustomer(payload, res => {
            if (res.success) {
                let listCustomerId = [];
                let listSupervisorId = [];
                let listCallCenterId = [];
                let data = res.data.content
                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listSupervisorId.push(item.supervisorId);
                    listCallCenterId.push(item.callCenterId);
                });
                this.setState({
                    list: res.data.content,
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                    listSupervisorId,
                    listCallCenterId
                }, () => {
                    if (data.length > 0) {
                        self.getListCustomerByIds();
                        self.getListSupervisorByIds();
                        self.getListCallCenterByIds();
                    }
                })
            } else {

            }
        }).catch(err => {

        })
    }

    getListCustomerByIds = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                    item.customerPhone = filter[0]?.phone;
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    getListSupervisorByIds = () => {
        let {listSupervisorId, listData} = this.state;
        let payload = {
            ids: listSupervisorId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorName = filter[0]?.fullName;
                });
                this.setState({
                    listData, list: res.data
                });
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        });
    }

    getListCallCenterByIds = () => {
        let {listCallCenterId, listData} = this.state;
        let payload = {
            ids: listCallCenterId
        };
        CallCenterApi.getNamesByListIds(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.callCenterId);
                    item.callCenterName = filter[0]?.fullName;
                });
                this.setState({
                    listData, list: res.data
                });
            }
        });
    }


    showModalAssignOrder = item => {
        let listFilter = this.state.list.filter(val => val.fullName === item.supervisorId);
        this.setState({selectedId: item.id, supIdFilter: listFilter[0].id, modalAssignOrder: true});
    };


    renderStatus(status) {
        switch (status) {
            case "WAITING_CALL_CENTER_CONFIRM":
                return <h5><span className="badge badge-secondary">Chờ Call Center xác nhận</span></h5>;
            case "WAITING_FOR_CONFIRMATION":
                return <h5><span className="badge badge-dark">Chờ Supervisor xác nhận</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-primary">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h5><span className="badge badge-warning">Đã từ chối</span></h5>;
            case "CONFIRMED":
                return <h6><span className="badge badge-success">Đã xác nhận</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CREATED_CONTRACT":
                return <h6><span className="badge badge-info">Đã tạo hợp đồng</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getOrderList();
            }
        );
    }

    showModalDetailOrder = () => {
        this.setState({modalDetailOrder: true})
    }

    getListCustomer = () => {
        let payload = {
            fullName: this.state.customerName,
            pageNumber: 0,
            pageSize: 10,
        }
        SecureCustomerApi.callCenterGetListCustomer(payload, res => {
            if (res.success) {
                this.setState({customerList: res.data.content})
            }
        })
    }

    onChangeSupervisor = (event, {newValue}) => {
        this.setState({
            customer_temp: newValue
        });
    };

    getServicePlan = () => {
        let payload = {
            type: 'ADVANCED',

        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({servicePlan: res.data})
            }
        })
    }

    showModalAcceptOrder = (row) => {
        this.setState({showConfirm: true, selectedOrder: row})
    }

    render() {
        let {listData, pageNumber, header, supervisorSuggestions, customer_temp, servicePlan} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="advance-orders-management"/>*/}
                        <Sidebar activeClass="advance-orders-management" parentClass="orders"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Nâng cao"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">
                                                <label className="label-item">Tên khách hàng</label>
                                                <Autosuggestion
                                                    label="Tên Khách hàng"
                                                    placeholder="Nhập tên khách hàng"
                                                    type="text"
                                                    data={this.state.customerList}
                                                    clearSearch={this.state.clearSearchOder}
                                                    onSelect={(item) => this.setState({selectedCustomer: item})}
                                                    onChange={(val) => {
                                                        this.setState({customerName: val, selectedCustomer: null, clearSearchOder: false})
                                                        setTimeout(() => {
                                                            this.getListCustomer()
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.customerList}
                                                    errorContent="Vui lòng chọn một khách hàng"
                                                />
                                            </div>
                                            {/*<div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">*/}
                                            {/*    <label className="label-item">Gói dịch vụ</label>*/}

                                            {/*    <select*/}
                                            {/*        value={this.state.servicePlanId_temp}*/}
                                            {/*        // defaultValue={this.state.servicePlanId_temp || this.state.servicePlanId}*/}
                                            {/*        onChange={val =>*/}
                                            {/*            this.setState({servicePlanId_temp: val.target.value})*/}
                                            {/*        }*/}
                                            {/*        className="form-control kt-input pd-4-input shadow-none">*/}
                                            {/*        <option value="">Tất cả</option>*/}
                                            {/*        {servicePlan.map(_item => {*/}
                                            {/*            return (*/}
                                            {/*                <option value={_item.id}>{_item.servicePlanName}</option>*/}
                                            {/*            )*/}
                                            {/*        })}*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className='mt-2 align-text-bottom pl-0 button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onhandleSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.setState({
                                                            customerName: '',
                                                            selectedCustomer: null,
                                                            clearSearchOder: true,
                                                        }, () => this.onhandleSearch())}
                                                >Refresh
                                                </button>
                                                {/*<Link to="/partner/create-contract">*/}
                                                {/*    <button*/}
                                                {/*        type="button"*/}
                                                {/*        className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                {/*        onClick={() => {*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        Thêm mới*/}
                                                {/*    </button>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    if (data.sortAsc == null) {
                                                        a = "cmnCreatedAt=desc";
                                                    } else {
                                                        a = `${data.sortId}=${
                                                            data.sortAsc ? "asc" : "desc"
                                                        }`;
                                                    }
                                                    this.setState(
                                                        {
                                                            sort: a
                                                        },
                                                        () => this.getOrderList()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData ||
                                            this.state.listData.length == 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={12}>
                                                        <div className="text-center">
                                                            Không có dữ liệu
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData &&
                                                listData.length > 0 &&
                                                listData?.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            {row.code}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row?.customerName}
                                                        </td>
                                                        {/*<td style={{textAlign: "right"}}>*/}
                                                        {/*    {" "}*/}
                                                        {/*    {row.dealPrice == '-1' ? "Thỏa thuận" :*/}
                                                        {/*        <NumberFormat*/}
                                                        {/*            value={row.dealPrice}*/}
                                                        {/*            displayType={"text"}*/}
                                                        {/*            thousandSeparator={true}*/}
                                                        {/*        />}*/}

                                                        {/*</td>*/}
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.supervisorName}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.callCenterName || 'Chưa có dữ liệu'}
                                                        </td>
                                                        {/*<td style={{textAlign: "center"}}>*/}
                                                        {/*    {this.renderStatus(row.status)}*/}
                                                        {/*</td>*/}
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.servicePlanName}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row.cmnCreated ? moment(row.cmnCreated).format("DD-MM-YYYY HH:mm") : ''}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {this.renderStatus(row.status)}
                                                        </td>

                                                        <td style={{textAlign: "center"}}>
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xem chi tiết"
                                                                onClick={() => {
                                                                    this.setState({selectedOrder: row}, () => {
                                                                        this.showModalDetailOrder()
                                                                    })
                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                            {/*<button*/}
                                                            {/*    className="btn btn-action"*/}
                                                            {/*    title="Đánh dấu đã gọi"*/}
                                                            {/*    // disabled={row.status != "REJECTED"}*/}
                                                            {/*    onClick={() => this.setState({*/}
                                                            {/*        itemSelected: row,*/}
                                                            {/*        showContact: true*/}
                                                            {/*    })}*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-headphone-alt"/>*/}
                                                            {/*</button>*/}
                                                            {/*<button*/}
                                                            {/*    className="btn btn-action"*/}
                                                            {/*    title="Gửi lại"*/}
                                                            {/*    // disabled={row.status != "REJECTED"}*/}
                                                            {/*    onClick={() => this.setState({*/}
                                                            {/*        itemSelected: row,*/}
                                                            {/*        showResend: true*/}
                                                            {/*    })}*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-loop"/>*/}
                                                            {/*</button>*/}
                                                            {/*<button*/}
                                                            {/*    className="btn btn-action"*/}
                                                            {/*    title="Xác nhận"*/}
                                                            {/*    // disabled={row.status != "REJECTED"}*/}
                                                            {/*    onClick={() => this.setState({*/}
                                                            {/*        itemSelected: row,*/}
                                                            {/*        showConfirm: true*/}
                                                            {/*    })}*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-check-box"/>*/}
                                                            {/*</button>*/}
                                                            {/*<button*/}
                                                            {/*    className="btn btn-action"*/}
                                                            {/*    title="Hủy order"*/}
                                                            {/*    // disabled={row.status != "REJECTED"}*/}
                                                            {/*    onClick={() => this.setState({*/}
                                                            {/*        itemSelected: row,*/}
                                                            {/*        showReject: true*/}
                                                            {/*    })}*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-close"/>*/}
                                                            {/*</button>*/}

                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalDetailOrder && <ModalDetailOrderAdvanced data={this.state.selectedOrder}
                                                                          onHide={() => this.setState({modalDetailOrder: false})}
                                                                          onRefresh={() => this.onhandleSearch()}/>}
                {this.state.showConfirm && <ModalAcceptOrder selectedOrder={this.state.selectedOrder}
                                                             onRefresh={() => this.onhandleSearch()}
                                                             onHide={() => this.setState({showConfirm: false})}/>}
                {this.state.showResend && <ModalResendOrder show={this.state.showResend}
                                                            onHide={() => this.setState({showResend: false})}
                                                            order={this.state.itemSelected}
                                                            onRefresh={() => this.getOrderList()}
                />}
            </div>
        );
    }

}

export default AdvanceOrdersManagement
