import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let AdminSMSManagement = {
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/sms-history/get-list-sms?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/sms-history/delete/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    resend: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/sms-history/resend/${payload.id}`,
            method: 'GET'
        }, callback)
    }
}
export default AdminSMSManagement