import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";

import Link from "../Link";
import {
    Modal,
} from 'react-bootstrap';

import Dropzone from "react-dropzone";
import lang from "../lang";
import {forceLogout} from "../../utils/authority";

class ModalWarningCompleteInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };

        // let role = window.OBPubConfig.user
        let type = window.OBPubConfig.user.type
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    {/*<div className="btn-close">*/}
                    {/*    <button type="button" className="close" onClick={this.props.onHide}>*/}
                    {/*        <span aria-hidden="true">&times;</span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <Modal.Body>
                        <div className="modal-container">
                            <h1 className="text-center">THÔNG BÁO</h1>
                            <div className="mt-4">
                                <div className='row kt-margin-b-20'>
                                    <h5 className="line-height-30">Bạn vui lòng hoàn thiện thông tin cá nhân trước khi
                                        sử dụng hệ thống, nhấn <b>Đồng ý</b> để tiếp tục đi đến trang hoàn thiện thông
                                        tin</h5>
                                </div>

                            </div>
                            <div container item xs={12}>
                                <div item xs={12}>
                                    <div className="align-center mt-3 mb-2">
                                        <Link to={"/" + type.toLowerCase() + "/complete-profile"}>
                                            <button
                                                type="button"
                                                className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                                onClick={this.props.onHide}
                                                // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                            >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                            </button>
                                        </Link>
                                        <button
                                            type="button"
                                            className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                            // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                            onClick={() => {
                                                forceLogout();
                                                window.location.reload()
                                                // this.props.onHide()
                                            }}
                                        >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalWarningCompleteInfo);

