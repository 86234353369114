import { stringify } from 'qs';
import {URL_API} from "../url.config";
import {request, requestLogin} from "../utils/apiUtils";
import axios from "axios";

const Authentication  = {
    login: (payload, callback) => {
        return requestLogin({
            url: `${URL_API}/api/one-book-auth/public/user/login`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    getUserDetail: (callback) => {
        return request({
            url:`${URL_API}/api/one-book-auth/secure/user/detail`,
            method: 'GET'
        }, callback);
    },
    downloadTest: (callback) => {
        return request({
            url:`http://192.168.1.169:9001/api/one-book-resource/secure/file/download-document/3`,
            method: 'GET'
        }, callback);
    },

    forgotPasswordGetOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-auth/public/user/forgot-password/otp/${payload.phone}`,
            method: 'GET',
        }, callback);
    },

    forgotPasswordUpdateNew: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-auth/public/user/forgot-password/update-new`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    forgotPasswordVerifyOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-auth/public/user/forgot-password/verify-otp`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    loginAxios:  (payload, callback) => {
        return axios.post(`${URL_API}/api/one-book-auth/user/login`, stringify(payload));
    },

    getOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/pubilc/otp/${payload.phone}`,
            method: 'GET',
        }, callback);
    },

    verifyOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/otp/verify`,
            method: 'POST',
            body: payload
        }, callback);
    },

    registerCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/customer/create`,
            method: 'POST',
            body: payload
        }, callback);
    },

    changePassword: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-auth/secure/user/change-password`,
            method: 'POST',
            body: payload
        }, callback);
    },
    switchUserType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action/switch-user-type`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    getAllUserType: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action/get-all-types`,
            method: 'GET'
        }, callback);
    },
    getIndexUserType: (name) =>{
        let listType = ["ADMIN", "PARTNER", "SUPERVISOR", "ACCOUNTANT", "CUSTOMER", "CALL_CENTER"];
        return listType.indexOf(name);
    }
}

export default Authentication

