import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let NotificationApi = {
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/notification/get-list?${stringify(payload)}`,
            method: "GET"
        }, callback)
    },

};

export default NotificationApi
