/**
 * Created by WebStorm.
 * User: LongP
 * Date: 23/03/2020
 * Time: 9:10
 **/
import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";
import Utils from '../../utils/utils'

import {
    Modal,
} from 'react-bootstrap';

import PublicReportContractApi from "../../services/ob-core/public-report-contract";

class ModalConfirmReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {

        }
    }

    onConfirm = () => {
        let payload = {
            id: this.props.id
        }
        PublicReportContractApi.supervisorConfirm(payload, res => {
            if (res.success) {
                this.props.enqueueSnackbar('Xác nhận báo cáo thành công !', {
                    variant: 'success',
                });
                this.props.onRefresh();
                this.props.onHide();
            } else {
                return this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }

    onPartnerConfirm = () => {
        let payload = {
            id: this.props.id
        }
        PublicReportContractApi.partnerConfirmReportData(payload, res => {
            if (res.success) {
                this.props.enqueueSnackbar('Xác nhận báo cáo thành công !', {
                    variant: 'success',
                });
                this.props.onRefresh();
                this.props.onHide();
            } else {
                return this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }



    render() {
        let userType = localStorage.getItem("ob_cl_userType")
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 18,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div>
                                    <h1 className="text-center" style={{fontSize: 14,}}><b>Bạn có chắc chắn xác nhận
                                        duyệt báo cáo này không ?</b></h1>
                                </div>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={userType == "PARTNER" ? this.onPartnerConfirm : this.onConfirm}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Huỷ bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalConfirmReport);
