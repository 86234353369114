import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';

class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            separator: "/",
            title:'OneBook',
            fontSize: 13,
            color: '#C6C6C6',
            router: [],
        }
    }

    componentDidMount() {
        let {title} = this.props;
        document.title = !title ? "OneBook" : (title+" - OneBook");
    }

    handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    };

    render() {
        let {fontSize,color} = this.state;
        let {title,separator,titleStyle,titleClassName,routerEnable,router} = this.props;
        return (
            <div>
                <h4 className="d-flex justify-content-start">
                    {!title ? "OneBook" : title}
                </h4>
                {routerEnable && <div className="d-flex mb-4 row align-items-center">
                    <Link style={!titleStyle ? {fontSize} : titleStyle} color="inherit" to={{pathname: 'profile',}}>{'Trang chủ'}</Link>
                    <span style={{fontSize,color}}  className="ml-1 mr-1">{!separator ? "/" : separator}</span>
                    {router && router.map((obj,index)=>{
                        return(
                        <div className={!titleClassName ? "d-flex align-items-center" : titleClassName}>
                                <Link style={!titleStyle ? {fontSize} : titleStyle} color="inherit" to={{
                                    pathname: obj.pathname,
                                }}>
                                    {obj.name}
                                </Link>
                                <span style={{fontSize,color}}  className="ml-1 mr-1">
                                    {!separator ? "/" : separator}
                                </span>
                            </div>
                        )
                    })}
                    <Typography color="textPrimary" style={{fontSize}}>{title}</Typography>
                </div>
                }
            </div>
        );
    }
}

export default PageHeader;
