import React from "react";
import './style.css';
import Link from "../../../components/Link";
import moment from "moment";
import NotificationApi from "../../../services/ob-user/notification";
import Pagination from "../../../components/Pagination";

class HomeNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: [],
            pageSize: 10,
            pageNumber: 0
        }
    }

    componentDidMount() {
        console.log("xxxxxxxxxxxxxxx");
        this.getNotification();
    }
    getNotification = () => {
        let payload = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
        }
        NotificationApi.getList(payload, res => {
            if (res.success){
                localStorage.setItem("ob_cl_notification", JSON.stringify(res.data.content))
                this.setState({
                    notification: res.data.content,
                    totalItem   : res.data.totalElements
                })

            }
        })
    }
    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.getNotification())
    };
    render() {
        return (
            <div className="home-notifications">
                <div>
                    <div className="container">
                        <div className='row notifications'>
                            <div className='col-md-3'>
                                <aside className="sidebar">
                                    <h4 className="heading-primary">Danh sách thông báo</h4>
                                    <ul className="nav nav-list">
                                        <li className='active'>
                                            <Link
                                                title="Tất cả thông  báo"
                                            >
                                                Tất cả thông báo
                                            </Link>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                            <div className='col-md-9'>
                                <h2>Tất cả thông báo</h2>
                                <ul className="list list-icons list-primary list-borders list-icons-lg">
                                    <li>
                                        <ul className="list list-icons list-primary list-borders list-icons-lg">
                                            {this.state.notification.length > 0 ? this.state.notification.map((_item , index) => (
                                                    <li className="notification-item">
                                                        <div>
                                                            <span className="notification-title"><b>{_item.title}</b></span>
                                                            <p className="mb-0 notification-content mt-1" style={{fontSize: 13}}>
                                                                {_item.body}
                                                            </p>
                                                            <div style={{textAlign: "right", color: "gray", fontSize: 13}}>
                                                                <span>{moment(_item.cmnCreatedAt).format("DD-MM-YYYY HH:mm")}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )) :
                                                <div>
                                                    <span>Bạn không có thông báo nào</span>
                                                </div>}

                                        </ul>
                                    </li>
                                    <div className="pagination-right justify-flex-end mb-3 mt-3">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default HomeNotifications
