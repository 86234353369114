import {SHOW_NOTIFICATION, CLEAN_NOTIFICATION, SHOW_LOADING, HIDE_LOADING} from '../actions/actionType';

export const showNotification = (object) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            message: object?.message ? object?.message : '',
            status: object?.status ? object?.status : 'success',
        },
        time: object?.time ? object?.time : 3000,
    };
};

export const cleanNotification = () => {
    return {
        type: CLEAN_NOTIFICATION
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING
    };
};

export const hideLoading = () => {
    return {
        type: HIDE_LOADING
    };
};

