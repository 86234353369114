import React, {Component} from "react";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import Pagination from "../../../../components/Pagination";
import {checkPermission} from "../../../../utils/common";
import ModalHideNews from "../../../../components/Modal/modalHideNews";
import ModalDeleteNews from "../../../../components/Modal/modalDeleteNews";

import SecureNews from "../../../../services/ob-core/secure-news";
import PublicNewsCategory from "../../../../services/ob-core/public-news-category";

class PostManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
            showDelete: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            data: [],
            listCategory: [],
            itemSelected: {},
            title: null,
            title_temp: null,
            status: '',
            categoryId: '',
            startDate_temp: null,
            endDate_temp: null,
            showLoading: false,
        }
    }

    componentDidMount() {
        this.getListCategory()
        this.setState({showLoading: true}, () => {
            this.getData()
        })
    }

    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: res.data
            })
        })
    }

    getData = () => {
        let {pageSize, pageNumber, startDate_temp, endDate_temp, title, title_temp, categoryId, status} = this.state;
        let payload = {
            pageNumber,
            pageSize,
            startDate: startDate_temp,
            endDate: endDate_temp,
            title: title_temp,
            categoryId,
            status: status
        }
        if (!startDate_temp) delete payload.startDate
        if (!endDate_temp) delete payload.endDate

        SecureNews.getListNews(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false
                })
                toast.error('Đã xảy ra lỗi lấy dữ liệu. Vui lòng tải lại trang !')
            }
        })
    }

    renderCategory = (idCategory) => {
        return this.state.listCategory?.filter(item => item.id === idCategory)[0]?.name
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getData();
            }
        );
    };

    render() {
        const {pageNumber, pageSize, totalItem, data} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="post-management" parentClass='home-management'/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách bài viết"/>
                                    <div className="row">
                                        <div className="col-4 pl-0">
                                            <label className="label-item">Tiêu đề</label>
                                            <input
                                                maxLength={100} type="text"
                                                className="form-control shadow-none"
                                                placeholder="Tiêu đề bài viết"
                                                value={this.state.title_temp}
                                                onChange={(event) => {
                                                    this.setState({
                                                        title_temp: event.target.value
                                                    })
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        this.getData()
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label className="label-item">Danh mục</label>
                                            <select className="form-control shadow-none"
                                                    value={this.state.categoryId}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            categoryId: val.target.value
                                                        })
                                                    }}
                                            >
                                                <option value=''>Tất cả</option>
                                                {this.state.listCategory.map((item, index) => <option
                                                    value={item.id}>{item.name}</option>)}

                                            </select>
                                        </div>
                                        <div className="col-4 pr-0">
                                            <label className="label-item">Trạng thái</label>
                                            <select className="form-control shadow-none"
                                                    value={this.state.status}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            status: val.target.value
                                                        })
                                                    }}
                                            >
                                                <option value=''>Tất cả</option>
                                                <option value={true}>Đang hiển thị</option>
                                                <option value={false}>Đã ẩn</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 pl-0">
                                            <label className="label-item">Từ ngày</label>
                                            <DatePicker
                                                className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                placeholderText="Từ ngày"
                                                selected={this.state.startDate_temp === null ? null : new Date(this.state.startDate_temp)}
                                                maxDate={new Date()}
                                                minDate={new Date('2000/01/01')}
                                                dateFormat="dd-MM-yyyy"
                                                isClearable={this.state.startDate_temp !== null}
                                                // selectsStart
                                                showMonthDropdown
                                                showYearDropdown
                                                onChange={(date) => {
                                                    this.setState({
                                                        startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                    })
                                                }}
                                                startDate={new Date(this.state.startDate_temp)}
                                                endDate={new Date(this.state.endDate_temp)}
                                            />
                                        </div>
                                        <div className="col-6 pr-0">
                                            <label className="label-item">Đến ngày</label>
                                            <DatePicker
                                                className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                placeholderText="Đến ngày"
                                                selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                                selectsEnd
                                                showMonthDropdown
                                                showYearDropdown
                                                disabled={this.state.startDate_temp === null}
                                                isClearable={this.state.endDate_temp !== null}
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => {
                                                    this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})
                                                }}
                                                startDate={new Date(this.state.startDate_temp)}
                                                endDate={new Date(this.state.endDate_temp)}
                                                minDate={this.state.startDate_temp === null ? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='mt-2 mb-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                        <div className="button-filter">
                                            <button
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={() => {
                                                    this.setState({
                                                        pageNumber: 0
                                                    }, () => this.getData())
                                                }}
                                            >
                                                Tìm kiếm
                                            </button>
                                            <button
                                                type="button"
                                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                onClick={() => {
                                                    this.setState({
                                                        startDate_temp: null,
                                                        endDate_temp: null,
                                                        title_temp: '',
                                                        title: '',
                                                        status: '',
                                                        categoryId: '',
                                                    }, () => this.getData())
                                                }}
                                            >
                                                Refresh
                                            </button>
                                            <Link to={{
                                                pathname: "/admin/editor",
                                                search: `?type=new`,
                                            }}>
                                                {checkPermission("create_post") &&
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                >
                                                    Thêm mới
                                                </button>
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-0 mb-3">
                                    <ul className="table-wrapper">
                                        {this.state.data.map((item, index) => (
                                            <li className="mb-3 news-item p-2">
                                                <div className="media mb-0 mt-1">
                                                    <img className="mr-2"
                                                         src={item.image}
                                                         alt="" width="180"/>
                                                    <div className="media-body">
                                                        <div>
                                                            {checkPermission("edit_post") ? <Link to={{
                                                                pathname: "/admin/editor",
                                                                search: `?postId=${item.id}&type=edit`,
                                                            }}><span
                                                                className="mt-0 mb-1 news-title">{item.title}</span>
                                                            </Link> : <span
                                                                className="mt-0 mb-1 news-title">{item.title}</span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <p className="descript text-black-50 text-sm">{item.description}</p>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="footer-news-item row justify-space-between">
                                                    <div className="col-md-4">
                                                        <span>Thời gian tạo: </span><span
                                                        className="time-post">{moment(item.cmnCreatedAt).format("DD-MM-YYYY HH:mm:ss")}</span>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span>Danh mục: </span><span>{this.renderCategory(item.categoryId)}</span>
                                                    </div>
                                                    <div className="controller-box align-right col-md-4">
                                                        {checkPermission("edit_post") &&
                                                        <Link to={{
                                                            pathname: "/admin/editor",
                                                            search: `?postId=${item.id}&type=edit`,
                                                        }}>
                                                            <button
                                                                className="btn button btn-sm button-rounded mr-2 icon-circle"
                                                                title="Chỉnh sửa bài viết"
                                                            >
                                                                <span
                                                                    className="fas fa-pencil-alt"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        <button
                                                            className="btn button btn-sm button-rounded mr-2 icon-circle"
                                                            title={item.hide ? "Hiện bài viết" : "Ẩn bài viết"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: item,
                                                                    showHide: true
                                                                })
                                                            }}
                                                        >
                                                            <span
                                                                className={item.hide ? "fas fa-eye" : "fas fa-eye-slash"}/>
                                                        </button>
                                                        {checkPermission("lock_post") &&
                                                        <button
                                                            className="btn button btn-sm button-rounded icon-circle"
                                                            title={"Xóa bài viết"}
                                                            onClick={() => this.setState({
                                                                itemSelected: item,
                                                                showDelete: true
                                                            })}
                                                        >
                                                            <span className="fas fa-trash"/>
                                                        </button>
                                                        }
                                                    </div>
                                                </div>

                                            </li>
                                        ))
                                        }
                                    </ul>
                                    <div className="pagination-right justify-flex-end">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showHide && <ModalHideNews show={this.state.showHide}
                                                       onHide={() => this.setState({showHide: false})}
                                                       onRefresh={() => this.getData()}
                                                       data={this.state.itemSelected}
                />}
                {this.state.showDelete && <ModalDeleteNews show={this.state.showDelete}
                                                           onHide={() => this.setState({showDelete: false})}
                                                           onRefresh={() => this.getData()}
                                                           data={this.state.itemSelected}
                />}
            </div>
        );
    }

}

export default PostManagement
