import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let EmailTypeApi = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-type/get-all`,
            method: 'GET'
        }, callback)
    },
    getEmailTypeBody: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-type/get-email-type-body/${payload.type}`,
            method: 'GET',
        }, callback)
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-type/update`,
            method: 'POST',
            body: payload
        }, callback)
    },
}
export default EmailTypeApi;
