// import React, {Component} from "react";
// import "./index.css"
// import {
//     Card,
//     Grid,
//     Paper,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TablePagination,
//     TableRow,
//     TextField
// } from "@material-ui/core";
// import TableHeader from "../../../../components/TableHeader";
// // import FileManagementApi from "../../../services/fileManagementApi"
// import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
// import moment from "moment";
// import Sidebar from "../../../../components/Sidebar";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import PageHeader from "../../../../components/PageHeader";
// import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import Pagination from "../../../../components/Pagination";
//
// function createData(stt, ngayThang, maHopDong, tenBaoCao, loaiBaoCao) {
//     return {stt, ngayThang, maHopDong, tenBaoCao, loaiBaoCao};
// }
//
// class DocumentFM extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             type_temp_search: '',
//             type_temp: '',
//             fileId: "",
//             page: 0,
//             pageSize: 10,
//             data: [],
//             totalItem: 3,
//             totalPage: 0,
//             rows: [
//                 createData('1', '02/02/2020', 'HD1155', 'Báo cáo tháng 1', 'Báo cáo thuế'),
//                 createData('2', '01/03/2020', 'HD8548', 'Báo cáo tháng 2', 'Báo cáo tài chính'),
//                 createData('3', '01/09/2020', 'HD8797', 'Báo cáo tháng 3', 'Tờ khai thuế'),
//             ],
//             date1: "",
//             date2: "",
//
//
//             //header table
//             header: [
//                 {
//                     id: '123',
//                     name: "STT",
//                     style: {textAlign: "center"},
//                     sort: false,
//                 },
//                 {
//                     id: '123',
//                     name: "Thời gian",
//                     style: {minWidth: 130, textAlign: "center"},
//                     sort: true,
//                 },
//                 {
//                     id: '123',
//                     name: "Mã hợp đồng",
//                     style: {minWidth: 130, textAlign: "center"},
//                     sort: true,
//                 },
//                 {
//                     id: '123',
//                     name: "Tên báo cáo",
//                     style: {minWidth: 130, textAlign: "center"},
//                     sort: true,
//                 },
//                 {
//                     id: '123',
//                     name: "Loại báo cáo",
//                     style: {minWidth: 140, textAlign: "center"},
//                     sort: true,
//                 },
//                 {
//                     id: '123',
//                     name: "Thao tác",
//                     style: {minWidth: 110, textAlign: "center"},
//                     sort: false,
//                 }
//             ],
//         }
//     }
//
//     componentWillMount() {
//         // this.getListFile()
//         this.setState({
//             date1: new Date().toISOString().substring(0, 10),
//             date2: new Date().toISOString().substring(0, 10)
//         })
//     }
//
//
//     //HANDLE_BUTTON*******************************************************************************
//
//
//     onHandleClearText() {
//         this.setState(
//             {
//                 type_temp: "",
//                 type_temp_search: "",
//                 startDate_temp: null,
//                 endDate_temp: null,
//             },
//             // () => this.onhandleSearch()
//         );
//     }
//
//     handleChangePage = page => {
//         this.setState(
//             {
//                 pageNumber: page - 1
//             },
//             () => {
//                 // this.getListContractFromAPI();
//             }
//         );
//     };
//
//     handleDateChangeRaw = (e) => {
//         e.preventDefault();
//     }
//
//     onhandleSearch = () => {
//         let {code_temp, status_temp} = this.state;
//
//         this.setState(
//             {
//                 code: code_temp,
//                 status: status_temp,
//                 pageNumber: 0
//             },
//             () => {
//                 // this.getListFile();
//             }
//         );
//     };
//
//     // getListFile () {
//     //     let {
//     //         page,
//     //         pageSize,
//     //         fileId
//     //     } = this.state
//     //     let payload = {
//     //         pageNumber: page,
//     //         pageSize: pageSize,
//     //         id: fileId
//     //     }
//     //     console.log("payloadTT>>>>", payload)
//     //     FileManagementApi.getListFile((payload, res) => {
//     //         if (res.success) {
//     //             console.log("res>>>>>", res)
//     //             this.setState({
//     //                 data: res.data.content,
//     //                 totalItem: res.data.totalElements,
//     //                 totalPage: Math.round(res.data.totalElements / this.state.pageSize)
//     //             })
//     //         } else {
//     //             console.log("err>>>>>>>>", res)
//     //         }
//     //     })
//     // }
//
//     render() {
//         let {rows, date1, date2, header, totalItem, page, pageSize} = this.state;
//         return (
//                 <div>
//                     <div className="container">
//                         <div className="row">
//                             <Sidebar activeClass="files-management"/>
//                             <div className="card border col-md-10 tab-content">
//                                 <div variant="outlined" className='p-3 mb-4'>
//                                     <PageHeader title="Danh sách báo cáo" routerEnable={true}/>
//                                     <form className="kt-form kt-form--fit kt-margin-b-20">
//                                         <div className="row kt-margin-b-20">
//                                             <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-3 d-flex'>
//                                                 <label className="label-item col-3 pr-0 pl-0 pt-2 mt-1">Mã hợp đồng</label>
//                                                 <input
//                                                     className="form-control font-14 shadow-none col-9 hi-25 mt-1"
//                                                     placeholder="HD08784757"
//                                                     type="text"
//                                                     maxLength={20}
//                                                     value={this.state.code_temp}
//                                                     onKeyUp={(e) => {
//                                                         if(e.key === 'Enter') this.onhandleSearch()
//                                                     }}
//                                                     onChange={val => {
//                                                         // debugger
//                                                         this.setState({
//                                                             code_temp: val.target.value.trimLeft()
//                                                         });
//                                                     }}
//                                                     // value={this.state.oldpassword}
//                                                     // onChange={(e) => {this.setState({oldpassword: e.target.value.trim()})}}
//                                                 />
//                                             </div>
//                                             <div className='col-xs-12 col-sm-12 col-md-6 p-0 d-flex'>
//                                                 <label className="label-item col-2 pr-0 pl-0 pt-2 marginRight mt-1">Thời gian</label>
//                                                 <div className='d-inline-flex h-date col-xs-10 col-sm-9'>
//                                                     <DatePicker
//                                                         className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container"
//                                                         placeholderText="Từ ngày"
//                                                         onChangeRaw={this.handleDateChangeRaw}
//                                                         selected={this.state.startDate_temp? new Date(this.state.startDate_temp) : null}
//                                                         // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
//                                                         maxDate={new Date()}
//                                                         minDate={new Date('2000/01/01')}
//                                                         // value={this.state.dateIssue}
//                                                         dateFormat="dd-MM-yyyy"
//                                                         isClearable
//                                                         fixedHeight
//                                                         // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
//                                                         selectsStart
//                                                         showYearDropdown
//                                                         onChange={
//                                                             (date) => {
//                                                                 this.setState({
//                                                                     startDate_temp: !date ? null : moment(date).format("YYYY-MM-DD"),
//                                                                 })
//                                                             }
//                                                         }
//                                                         // startDate={new Date(this.state.startDate_temp)}
//                                                         // endDate={new Date(this.state.endDate_temp)}
//                                                     />
//                                                     <DatePicker
//                                                         className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container"
//                                                         placeholderText="Đến ngày"
//                                                         onChangeRaw={this.handleDateChangeRaw}
//                                                         selected={this.state.endDate_temp? new Date(this.state.endDate_temp) : null}
//                                                         // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
//                                                         selectsEnd
//                                                         showYearDropdown
//                                                         disabled={!this.state.startDate_temp}
//                                                         isClearable={this.state.endDate_temp}
//                                                         fixedHeight
//                                                         dateFormat="dd-MM-yyyy"
//                                                         onChange={
//                                                             (date) => {
//                                                                 this.setState({
//                                                                     endDate_temp: !date ? null : moment(date).format("YYYY-MM-DD"),
//                                                                 })
//                                                             }
//                                                         }
//                                                         startDate={new Date(this.state.startDate_temp)}
//                                                         endDate={new Date(this.state.endDate_temp)}
//                                                         minDate={this.state.startDate_temp? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
//                                                     />
//                                                 </div>
//                                             </div>
//                                             <div className='col-xs-12 col-sm-12 p-0'>
//                                                 <div>
//                                                     <label className='label-item mb-4'></label>
//                                                 </div>
//                                                 <button
//                                                     type="button"
//                                                     className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
//                                                     style={{height: 35}}
//                                                     onClick={() => {
//                                                         // this.onhandleSearch();
//                                                     }}
//                                                 >
//                                                     Tìm kiếm
//                                                 </button>
//                                                 <button
//                                                     type="button" className="btn border-1-s btn-light btn-sm"
//                                                     style={{height: 35}}
//                                                     onClick={() => {
//                                                         this.onHandleClearText();
//                                                     }}
//                                                 >
//                                                     Refresh
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>
//                                 <div style={{
//                                     width: '100%',
//                                     marginTop: 15,
//                                     borderRadius: 0,
//                                     overflowX: 'auto',
//                                 }}>
//                                     <table className="table table-striped table-data table-bordered">
//                                         <thead>
//                                         <TableHeader header={header} clickSort={(data) => {
//                                             console.log(data);
//                                         }}/>
//                                         </thead>
//                                         <tbody>
//                                         {rows.map(row => (
//                                             <tr key={row.name}>
//                                                 <td align="center">{row.stt}</td>
//                                                 <td align="left">{moment(row.ngayThang).format("DD-MM-YYYY")}</td>
//                                                 <td align="left" style={{minWidth:130}}>
//                                                     <Link
//                                                     // onClick = {() =>  Utils.addOrUpdateUrlParam("contractId", row.id)}
//                                                     to={{
//                                                         pathname: "/customer/contracts-detail",
//                                                         search: "?contractId=1",
//                                                         state: {
//                                                             fromDashboard: true,
//                                                             contractId: row.contractId
//                                                         }
//                                                     }}
//                                                     style={{color: "blue"}}
//                                                 >
//                                                     {row.maHopDong}
//                                                 </Link></td>
//                                                 <td align="left">
//
//                                                         {row.tenBaoCao}
//
//                                                 </td>
//                                                 <td align="center">{row.loaiBaoCao}</td>
//                                                 <td align="center">
//                                                     <button className="btn btn-action"
//                                                             title='Tải bảo cáo'
//                                                             style={{cursor: 'pointer'}}
//                                                         // onClick={()=> window.OBPubConfig.loading = true}
//                                                     >
//                                                         <i className="ti-download"/>
//                                                     </button>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                         </tbody>
//                                     </table>
//                                     <div className="pagination-right">
//                                         <Pagination
//                                             activePage={this.state.page + 1}
//                                             itemsCountPerPage={this.state.pageSize}
//                                             totalItemsCount={totalItem}
//                                             changeHandler={page =>
//                                                 this.handleChangePage(page)}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//         );
//     }
//
// }
//
// export default DocumentFM

import React, {Component} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import NumberFormat from "react-number-format";

import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import TableHeader from "../../../../components/TableHeader";
import Pagination from "../../../../components/Pagination";
import Button from "@material-ui/core/Button";

import PublicReportContractApi from "../../../../services/ob-core/public-report-contract";

const dateFormat = 'DD-MM-YYYY'

const renderStatus = function (status) {
    switch (status) {
        case 'APPROVED':
            return 'Đã duyệt';
        case 'AWAITING_APPROVAL':
            return 'Chờ duyệt';
        default:
            return 'Bị từ chối';
    }
};

const renderType = function (type) {
    switch (type) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        default:
            return 'Báo cáo tài chính';
    }
};

class ReportManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemSelected: {},
            data: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            cmnCreatedAt: null,
            contractCode: '',
            contractCode_temp: '',
            status: '',

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'index',
                    name: "Thời gian",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'code',
                    name: "Mã hợp đồng",
                    style: {minWidth: 140, textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên báo cáo",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'type',
                    name: "Loại báo cáo",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentDidMount() {
        this.getListReportContract()
    }

    getListReportContract = () => {
        let {
            pageNumber,
            pageSize,
            status,
            contractCode
        } = this.state;

        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // contractId: code,
            status: status,
            contractCode: contractCode
        }
        PublicReportContractApi.searchByContractIdAndDateAndStatus(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize)
                })
            }
        })
    }

    onhandleSearch = () => {
        let {contractCode_temp, status_temp} = this.state;
        this.setState(
            {
                contractCode: contractCode_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListReportContract();
            }
        );
    };
    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListReportContract();
            }
        );
    };

    render() {
        const {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="list-ordered-service"/>
                        <div className="mb-4 col-md-9 tab-content">
                            <div className='card border p-3 pb-2 mb-3 shadow-none'>
                                <PageHeader routerEnable={true} title="Danh sách báo cáo"/>
                                <form className="mt-2 kt-form kt-form--fit kt-margin-b-20">
                                    <div className="row kt-margin-b-20">
                                        <div className='col-xs-12 col-sm-12 col-md-6 pr-sm-0 pr-md-3 p-0'>
                                            <label className="label-item">Mã hợp đồng</label>
                                            <input
                                                maxLength={20} type="text"
                                                className="form-control shadow-none"
                                                placeholder="Nhập vào mã hợp đồng..."
                                                value={this.state.contractCode_temp}
                                                onKeyUp={(e) => {
                                                    if (e.key === 'Enter') this.getListReportContract()
                                                }}
                                                onChange={(event) => {
                                                    this.setState({
                                                        contractCode_temp: event.target.value.trim()
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-6 p-0 '>
                                            <label className="label-item">Thời gian</label>
                                            <select
                                                className="form-control kt-input pd-4-input shadow-none"
                                                value={this.state.cmnCreatedAt}
                                                onChange={(e) => {
                                                    this.setState({
                                                        cmnCreatedAt: e.target.value
                                                    })
                                                }}
                                            >
                                                <option value='' hidden>Tháng</option>
                                                <option value='1'>1</option>
                                                <option value='2'>2</option>
                                                <option value='3'>3</option>
                                                <option value='4'>4</option>
                                                <option value='5'>5</option>
                                                <option value='6'>6</option>
                                                <option value='7'>7</option>
                                                <option value='8'>8</option>
                                                <option value='9'>9</option>
                                                <option value='10'>10</option>
                                                <option value='11'>11</option>
                                                <option value='12'>12</option>
                                            </select>
                                        </div>
                                        {/*<div className='col-xs-12 col-sm-12 col-md-4 text-right pr-0'>*/}
                                        {/*    <div>*/}
                                        {/*        <label className='label-item mb-4'></label>*/}
                                        {/*    </div>*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                        {/*        style={{height: 35}}*/}
                                        {/*        onClick={() => this.getListReportContract()}*/}
                                        {/*    >*/}
                                        {/*        Tìm kiếm*/}
                                        {/*    </button>*/}
                                        {/*    /!*&nbsp;&nbsp;*!/*/}
                                        {/*    <button type="button" className="btn border-1-s btn-light btn-sm"*/}
                                        {/*            style={{height: 35}}*/}
                                        {/*            onClick={() => {*/}
                                        {/*                this.setState({*/}
                                        {/*                    code: '',*/}
                                        {/*                    status: '',*/}
                                        {/*                    sort: 'id=asc'*/}
                                        {/*                }, () => {*/}
                                        {/*                    this.getListReportContract()*/}
                                        {/*                })*/}
                                        {/*            }}*/}
                                        {/*    >Refresh*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                        <div className='col-xs-12 col-sm-12 col-md-4 text-left p-0 mt-3'>
                                            <Button
                                                style={{textTransform: 'initial', marginRight: 10}}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.onhandleSearch()
                                                }}
                                            >
                                                Tìm kiếm
                                            </Button>
                                            <Button
                                                className="mr-3"
                                                style={{textTransform: 'initial'}}
                                                variant="outlined"
                                                onClick={() =>
                                                    this.setState({
                                                            status_temp: '',
                                                            cmnCreatedAt: ''
                                                        }, () => this.onhandleSearch()
                                                    )
                                                }
                                            >
                                                Refresh
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card table shadow-none">
                                <table
                                    className="table table-striped table-data table-responsive">
                                    <table className='table'>
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = null
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListReportContract())
                                        }}/>
                                        </thead>
                                        {!this.state.data || this.state.data.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.data.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'center'}}>{row.cmnCreatedAt ? moment(row.cmnCreatedAt).format(dateFormat) : ''}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                    <span style={{paddingLeft: 50}}>

                                                        {row.contractCode}

                                                    </span>
                                                    </td>
                                                    <td style={{textAlign: 'left', paddingLeft: 50}}>{row.name}</td>
                                                    <td style={{
                                                        textAlign: 'left',
                                                        paddingLeft: 50
                                                    }}>{renderType(row.type)}</td>


                                                    <td style={{textAlign: 'center', fontSize: 18}}>

                                                        <button className="btn btn-action"
                                                                title='Tải xuống'
                                                                style={{cursor: 'pointer'}}>
                                                            <i className="ti-download"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </table>
                                <div style={{height: 40}} className="pagination-right pr-2">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={page => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default ReportManagement
