import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let SecureContract = {
    searchByCodeAndStatusBySupervisorId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/search-by-code-and-status-by-supervisor-id?${stringify(payload)}`,
            method: "GET"
        }, callback)
    },
    
    contractCodeToCustomerId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/contract-code-to-customer-id?contractCode=${payload.code}`,
            method: "GET"
        }, callback)
    },
    deleteOrderedService: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/contract/delete-ordered-service`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback)
    },
}

export default SecureContract
