/**
 * Created by WebStorm.
 * User:
 * Create:
 * Time:
 *
 * User: Le.Cong.Hai
 * Update: 24/03/2020
 * Time: 9:00
 */

import React, {Component} from "react";
import {withSnackbar} from "notistack";
import moment from "moment";
import '../info.css';

import NumberFormat from "react-number-format";
import TableHeader from "../../../../components/TableHeader";
import CommentInDetail from "../../../common/comment/commentInDetail";
import {Stepper, Step, StepLabel, StepContent, StepIcon} from '@material-ui/core';
import ModalRatingAccountant from "../../../../components/Modal/modalRatingAccountant";
import ModalUpdatePayment from "../../../../components/Modal/modalUpdatePayment";
import UploadFile from "../../../../components/Modal/modalUploadFile";
import UploadFileCustomerId from "../../../../components/Modal/modalUploadFileCustomerId";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import AccountantApi from "../../../../services/ob-user/accountant";
import {toast} from "react-toastify";
import {checkPermission} from "../../../../utils/common";
import FilesApi from "../../../../services/resource/files";

const formatDate = "DD-MM-YYYY";

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: false,
            showUpdatePayment: false,
            itemSelected: {},
            needPayment: null,
            selectPayment: null,
            code: "",
            status: "",
            mess: "",
            value: "",
            type: "",
            files: [],
            paymentStatus: "",
            supervisorId: "",
            paymentCycle: "",
            servicePlanId: "",
            customerId: "",
            startDate: "",
            accountantId: "",
            paid: "",
            unpaid: "",
            customerName: "",
            servicePlanName: "",
            supervisorName: '',
            id: null,
            contractId: null,
            listTimeLine: [],
            listAccId: [],
            data: [],
            steps: [],
            steps2: [],
            dataJob: [],
            dataFile: [],
            modalUploadFile: false,
            listMessage: [
                {
                    name: "Admin",
                    mess: "Bạn cần thanh toán đúng hạn!"
                },
                {
                    name: "Thạch",
                    mess: "Mình sẽ thanh toán tất cả trong một lần"
                },
                {
                    name: "Admin",
                    mess: "Cảm ơn bạn rất nhiều."
                },
                {
                    name: "Thạch",
                    mess: "Tôi rất thích One Book For"
                }
            ],
            headerJob: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên Job",
                    style: {minWidth: 240, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'numberOfReports',
                    name: "Số lượng báo cáo",
                    style: {minWidth: 140, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'implementer',
                    name: "Người thực hiện",
                    style: {minWidth: 160, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 100, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'receiveMoney',
                    name: "Số tiền",
                    style: {minWidth: 160, textAlign: "right"},
                    sort: false,
                },
            ],
            headerFile: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Dữ liệu",
                    style: {minWidth: 240, textAlign: "center"},
                    sort: false,
                },
                // {
                //     id: 'file',
                //     name: "File",
                //     style: {minWidth: 120, textAlign: "center"},
                //     sort: false,
                // },
                {
                    id: 'file',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
            ]
        };
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng";
        // this.getListByContractFromAPI();
        // this.getDetailFromAPI();
        this.getDetailContractFromAPI();
    }

    //******************************************************************************************************************

    getDetailContractFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.value
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                let listAccId = [];
                let dataTemp = res.data.jobs
                dataTemp.map(item => {
                    listAccId.push(item.assignerId);
                });
                this.setState({
                    data: res.data,
                    listTimeLine: res.data.paymentTimelines,
                    dataJob: res.data.jobs,
                    dataFile: res.data.contractDocuments,
                    supervisorId: res.data.supervisorId,
                    listAccId
                }, () => {
                    if (res.data.supervisorId) {
                        this.getNameSupervisor()
                    }
                    if (dataTemp.length > 0) {
                        this.getListAccountantByIds()
                    }
                });
            } else {
                toast.error('Đã xảy ra lỗi. Vui lòng tải lại trang !')
            }
        });
    }

    getNameSupervisor = () => {
        let {supervisorId} = this.state;
        let ids = [];
        ids.push(supervisorId);
        if (supervisorId !== '') {
            let payload = {
                ids: ids
            };
            SupervisorApi.getNamesByListId(payload, res => {
                // console.log('supName: ', res)
                if (res.success && res.data && res.data.length !== 0) {
                    this.setState({
                        supervisorName: res.data[0].fullName
                    })
                }
            })
        }
    }


    getListAccountantByIds = () => {
        let {dataJob, listAccId} = this.state;
        let payload = {
            ids: listAccId
        };

        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let dataTemp = res.data;
                dataJob.map(item => {
                    let filter = dataTemp.filter(x => x.id == item.assignerId);
                    item.assignerId = filter[0]?.fullName;
                });
                this.setState({
                    dataJob
                });
            } else {

            }
        });
    }

    importFile = (_file) => {
        let dataFile = this.state.dataFile;
        dataFile.push(_file)
        this.setState({dataFile, modalUploadFile: false, filesTableErr:false})
    }

    //******************************************************************************************************************

    renderStatusSteper(data) {
        switch (data.assignStatus) {
            case "CANCELED":
                return null;
            case "WAIT_SIGN":
                return 0;
            case "SIGNED":
                return 1;
            case "NO_ASSIGN":
                return 2;
            case "WAITING_FOR_CONFIRMATION":
                return 3;
            case "REJECTED":
                return 4;
            case "PROCESSING":
                return 4;
            case "LIQUIDATED":
                return 5;
            case "CLOSED":
                return 6;
            default:
                return;
        }
    }

    renderStatus(assignStatus) {
        switch (assignStatus) {
            case "WAIT_SIGN":
                return "Chờ ký";
            case "SIGNED":
                return "Đã ký";
            case "PROCESSING":
                return "Kế toán xác nhận";
            case "NO_ASSIGN":
                return "Chưa assign";
            case "WAITING_FOR_CONFIRMATION":
                return "Chờ kế toán xác nhận";
            // case "REJECTED":
            //     return "Kế toán từ chối";
            case "LIQUIDATED":
                return 'Thanh lý';
            case "CANCELED":
                return "Đã hủy;"
            case "CLOSED":
                return "Đã đóng";
            default:
                return;
        }
    }

    renderStatusJob = (status) => {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-dark">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return '';
        }
    }

    renderPaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return " Đã thanh toán";
            case "UNPAID":
                return "Chưa thanh toán";
            case "PAYMENT_TERM":
                return "Đến hạn thanh toán";
            case "PARTIALLY_PAID":
                return "Thanh toán";

            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return "Hợp đồng đăng kí mới";
            case "EXTENSION":
                return "Hợp đồng gia hạn";

            default:
                return;
        }
    }

    renderPaymentCycle(paymentCycle) {
        switch (paymentCycle) {
            case "PAYMENT_1_TIMES":
                return 0;
            case "PAYMENT_2_TIMES":
                return 1;

            default:
                return;
        }
    }

    renderActiveStep(listTimeLine) {
        return listTimeLine.filter(value => value.paymentStatus === "PAID").length
    }

    handleUpdatePayment(index, listTimeLine, row) {
        let need = listTimeLine.filter(value => value.paymentStatus === "PAID").length + 1;
        // let need = 1;
        console.log(index)
        if (index + 1 >= need) {
            this.setState({
                needPayment: need,
                selectPayment: index + 1,
                itemSelected: row.id,
                listTimeLine: listTimeLine,
            }, () => this.setState({showUpdatePayment: true}))
        }

    }

    addDocuments = (data, fileName) => {
        let payload = {
            id: this.props.value,
            body: {
                uploadedContractDocuments: [
                    {
                        contentType: data[0].contentType,
                        destination: data[0].destination,
                        name: fileName,
                        storageName: data[0].storageName
                    }
                ]
            }
        }
        PublicContractApi.addContractDocument(payload, res => {
            if (res.success) {
                this.setState({ modalUploadFile: false }, () => {
                    toast.success("Upload file thành công!")
                    setTimeout(() => {
                        this.getDetailContractFromAPI()
                    }, 300);

                })
            } else {
                toast.error("Upload file không thành công, vui lòng thử lại!")
            }
        })
    }

    // addDocuments = (res, file) => {
    //     // console.log("check check>?>>?>>>>>>", res, file);
    //     let payload = {
    //         id: this.props.value,
    //         body: {
    //             uploadedContractDocuments: [
    //                 {
    //                     contentType: file.type,
    //                     name: file.name,
    //                     storageName: res
    //                 }
    //             ]
    //         }

    //     }
    //     PublicContractApi.addContractDocument(payload, res => {

    //     })
    // }

    downloadFile = (item) => {
        let payload = {
            id: item.id
        }
        FilesApi.downloadContractDocument2(payload, res => {
            if (res.success){
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${item.name}.${res.data.extension}`
                a.click();
            }
        })
    }

    //******************************************************************************************************************

    render() {
        let {
            activeStep,
            steps,
            steps2,
            activeStep2,
            rows,
            listMessage,
            id,
            data,
            listStatus,
            code,
            status,
            listTimeLine
        } = this.state;
        return (
            <div style={{padding: 0}}>
                <div style={{fontSize: 20}}>TÌNH TRẠNG HỢP ĐỒNG</div>
                <div className="row">
                    <div className="row d-flex" style={{marginLeft: 0, width: 90}}>
                        <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                            <div
                                className="align-self-end mt-10"
                                style={{fontSize: 14, marginTop: 80, width: "100%"}}
                            >
                            </div>
                            <div
                                className="align-self-start"
                                style={{fontSize: 14, width: "100%"}}
                            >
                            </div>
                        </div>
                        <div
                            className="align-self-end mt-10"
                            style={{fontSize: 12, marginTop: 54, width: "100%"}}
                        >

                        </div>
                        <div
                            className="align-self-start"
                            style={{fontSize: 12, width: "100%"}}
                        >

                        </div>
                    </div>
                    <Stepper
                        alternativeLabel
                        className="d-flex col-10 justify-content-center mr-5"
                        activeStep={this.renderStatusSteper(data)}
                    >
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chờ kí</div>
                            </StepLabel>
                        </Step>
                        <Step style={{ width: 0 }}>
                            <StepLabel className="d-flex">
                                <div>Đã ký</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chưa assign</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chờ kế toán xác nhận</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                {data.assignStatus === "REJECTED" ? <div>Kế toán từ chối</div> :
                                    <div>Đang thực hiện</div>}
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Thanh lý hợp đồng</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đã đóng</div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                {/*{data.status != "WAIT_SIGN" ? (*/}
                <div>
                    <div style={{fontSize: 20}}>TÌNH TRẠNG THANH TOÁN</div>
                    <div className="row">
                        <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                            <div
                                className="align-self-end mt-10"
                                style={{
                                    fontSize: 14,
                                    marginTop: 0,
                                    width: "100%",
                                    minWidth: 110
                                }}
                            >
                                Hạn thanh toán
                            </div>
                            <div
                                className="align-self-start"
                                style={{fontSize: 14, width: "100%"}}
                            >
                                Thực tế
                            </div>
                        </div>
                        <Stepper
                            alternativeLabel
                            className="d-flex col-10 justify-content-center"
                            // activeStep={this.renderPaymentCycle(data.paymentCycle)}
                            // activeStep={this.renderActiveStep(listTimeLine)}
                            activeStep={this.renderActiveStep(listTimeLine)}
                        >
                            {listTimeLine.map((row, index) => (
                                <Step style={{width: 0}}>
                                    <StepLabel
                                        className={index >= this.renderActiveStep(listTimeLine) ? "d-flex cursor-pointer" : 'd-flex'}
                                        title={index >= this.renderActiveStep(listTimeLine) ? "Xác nhận thanh toán" : ""}
                                        onClick={() => {
                                            if (checkPermission("change_payment_status")) this.handleUpdatePayment(index, listTimeLine, row)
                                        }}
                                    >
                                        {row.paymentStatus === "PAYMENT_TERM" ? (
                                            <div style={{color: "red"}}>
                                                {moment(row.paymentTerm).format(formatDate)}
                                            </div>
                                        ) : (
                                            <div>{moment(row.paymentTerm).format(formatDate)}</div>
                                        )}
                                        {row.paymentStatus !== "PAYMENT_TERM" ? (
                                            <div>{row.paymentReal ? moment(row.paymentReal).format(formatDate) :
                                                <div>&nbsp;&nbsp;</div>}</div>
                                        ) : (
                                            ''
                                        )}
                                        <NumberFormat
                                            value={row.paymentAmount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={" VND"}
                                        />
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
                {/*// ) : (*/}
                {/*//     ""*/}
                {/*// )}*/}
                <div style={{fontSize: 20}}>
                    <span>THÔNG TIN CHI TIẾT HỢP ĐỒNG </span>
                </div>
                <div style={{fontSize: 15, padding: 15}}>
                    <div className="row">
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Tên khách hàng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                          <span style={{fontWeight: "bold"}}>
                            {data.customerName}
                          </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Gói dịch vụ</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{fontWeight: "bold"}}>{data.servicePlanName}</span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Giá trị hợp đồng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <NumberFormat
                                style={{fontWeight: "bold"}}
                                value={data.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={" VND"}
                            />
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Ngày bắt đầu</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{fontWeight: "bold"}}>
                                {moment(data.startDate).format(formatDate)}
                            </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Kế toán thực hiện</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{fontWeight: "bold"}}>
                                {data.accountantName}
                            </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Người phụ trách</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{fontWeight: "bold"}}>
                                {this.state.supervisorName}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className='col-md-12 p-0 mt-2 mb-2' style={{fontSize: 20}}>
                        <span>THÔNG TIN JOB </span>
                    </div>
                    <div className='col-md-12' style={{overflow: 'auto'}}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerJob} clickSort={(data) => {
                            }}/>
                            </thead>
                            {!this.state.dataJob || this.state.dataJob.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.dataJob.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                        <td style={{textAlign: 'center'}}>{row.numberOfReports}</td>
                                        <td style={{textAlign: 'left'}}>{row.assignerId}</td>
                                        <td style={{textAlign: 'center'}}>{this.renderStatusJob(row.status)}</td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.receiveMoney}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className='col-md-12 p-0 mt-2 mb-2 justify-content-left row' style={{fontSize: 20}}>
                        <span>FILE Hợp Đồng</span>
                        {(checkPermission("update_data_file")) &&
                        <button className="btn btn-primary mr-3" onClick={() => {
                            this.setState({modalUploadFile: true})
                        }}>Tải hợp đồng</button>
                        }
                    </div>
                    <div className='col-md-12' style={{overflow: 'auto'}}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerFile} clickSort={(data) => {
                            }}/>
                            </thead>
                            {!this.state.dataFile || this.state.dataFile.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.dataFile.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                        {/* <td style={{textAlign: 'center'}}>{row.name}</td> */}
                                        <td style={{textAlign: 'center'}}>
                                            {(checkPermission("download_data_file")) &&
                                            <button
                                                className="btn btn-action"
                                                title="Tải xuống"
                                                onClick={() => this.downloadFile(row)}
                                            >
                                                <i className="ti-download"/>
                                            </button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                {checkPermission("comment") &&                           
                <div className="row">
                    <div className="col-6 pl-0 mb-2" style={{fontSize: 20}}>
                        COMMENT
                    </div>
                    <div className="col-6 d-flex justify-content-end"></div>
                </div>
                }
                {checkPermission("comment") &&
                <div className='pl-3 pr-3'>
                    <CommentInDetail contractId={this.props.value}/>
                </div>
                }
                {data.status === "LIQUIDATED" || data.status === "CLOSED" ? (
                    <div
                        className="row mt-4 pt-3"
                        style={{
                            borderTop: "1px dotted #516f7782"
                        }}
                    >
                        <div className="col-sm-6 col-md-6 p-0 ">
                            <span> ĐÁNH GIÁ KẾ TOÁN</span>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <button
                                className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                                style={{height: 37, marginLeft: 10}}
                                onClick={() => this.setState({showRate: true})}
                            >
                                Đánh giá
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>&nbsp;&nbsp;&nbsp;</div>
                )}

                {this.state.showRate && (
                    <ModalRatingAccountant
                        contract={this.state.data}
                        onHide={() => this.setState({showRate: false})}
                    />
                )}
                {this.state.showUpdatePayment && (
                    <ModalUpdatePayment
                        needPayment={this.state.needPayment}
                        selectPayment={this.state.selectPayment}
                        listTimeLine={this.state.listTimeLine}
                        id={this.state.itemSelected}
                        onRefresh={() => this.setState({ showUpdatePayment: false }, () => this.getDetailContractFromAPI())}
                        onHide={() => this.setState({showUpdatePayment: false})}
                    />
                )}
                {this.state.modalUploadFile &&
                <UploadFileCustomerId type="inContract" show={this.state.modalUploadFile} customerId={this.props.customerId} onClose={this.importFile} onHide={() => this.setState({modalUploadFile: false})}
                            uploadSuccess={this.addDocuments}/>
                }
            </div>
        );
    }
}

export default Information
