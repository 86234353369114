import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let WalletApi = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/partner/mywallet/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    adminCreate: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/wallet/admin-onebook-create`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    partnerSearchByContractAndType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/wallet/partner-search-paging-by-contract-and-type?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    adminSearchByContractAndType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/wallet/admin-search-paging-by-contract-and-type?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    showWallet: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/my-wallet`,
            method: 'GET'
        }, callback)
    },
    rechageWalletPartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/wallet/admin-onebook-recharge-wallet-of-partner`,
            method: "POST",
            body: payload
        }, callback)
    }
}
export default WalletApi
