import React, {Component} from "react";
import moment from "moment";

import {
    Paper,
    TablePagination
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Sidebar from "../../../components/Sidebar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import ModalResendOrder from "../../../components/Modal/modalResendOrder";

import PublicOrder from "../../../services/ob-core/public-order-service";
import PublicServicePlan from "../../../services/ob-core/public-service-plan";
import SupervisorApi from "../../../services/ob-user/supervisor";
import {toast} from "react-toastify";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ModalCreateBranch from "../../../components/Modal/modalCreateBranch";
import ModalDeleteOrderedService from "../../../components/Modal/modalDeleteOrderedService";


const renderStatus = function (status) {
    switch (status) {
        case "WAITING_CALL_CENTER_CONFIRM":
            return <span className="badge badge-secondary">Chờ Call Center xác nhận</span>;
        case "WAITING_FOR_CONFIRMATION":
            return <span className="badge badge-warning">Chờ Supervisor xác nhận</span>;
        case "CONFIRMED":
            return <span className="badge badge-success">Đã xác nhận</span>;
        case "CANCELED":
            return <span className="badge badge-danger">Đã hủy</span>;
        case "CREATED_CONTRACT":
            return <span className="badge badge-info">Đã tạo hợp đồng</span>;
        default:
            return '';
    }
};

const status = [
    {
        value: "WAITING_CALL_CENTER_CONFIRM",
        name: "Chờ Call Center xác nhận",
    },
    {
        value: "WAITING_FOR_CONFIRMATION",
        name: "Chờ Supervisor xác nhận",
    },
    {
        value: "CONFIRMED",
        name: "Supervisor đã xác nhận",
    },
    {
        value: "CREATED_CONTRACT",
        name: "Đã tạo hợp đồng",
    },
    {
        value: "CANCELED",
        name: "Đã hủy",
    },
]

class OrderedService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResend: false,
            showDelete: false,
            status: '',
            status1: "",
            data: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            sort: 'id',
            descending: 'DESC',
            listSupId: [],
            listServicePlan: [],
            servicePlanSearch: "",
            showLoading: false,
            servicePlanId: '',
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'servicePlan',
                    name: "Loại dịch vụ",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'supervisor',
                    name: "Tên Supervisor",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'dealPrice',
                    name: "Giá dịch vụ (VNĐ)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreated',
                    name: "Ngày đặt dịch vụ",
                    // style: {minWidth: 150, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                }
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getAllServicePlan();
            this.getListOrderOnSearch()
        })
    }

    getListOrderOnSearch() {
        this.setState({showDelete: false});
        let {pageNumber, pageSize, status, sort, descending, servicePlanId} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            status,
            sort,
            descending,
            servicePlanId: servicePlanId
        };
        PublicOrder.customerSearchByServicePlanIdAndStatus(payload, res => {
            if (res.success) {
                let listSupId = [];
                let data = res.data.content
                data.map(item => {
                    listSupId.push(item.supervisorId);
                });
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize),
                    listSupId
                }, () => {
                    if (data.length > 0) this.getListNameSupByIds()
                    else this.setState({showLoading: false})
                })
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    getAllServicePlan() {
        PublicServicePlan.getAll(res => {
            if (res.success) {
                this.setState({
                    listServicePlan: res.data,
                })
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    getListNameSupByIds = () => {
        let {listSupId, data} = this.state;
        let payload = {
            ids: listSupId
        };

        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let dataRes = res.data;
                data.map(item => {
                    let filter = dataRes.filter(x => x.id == item.supervisorId);
                    item.supervisor = filter[0]?.fullName;
                });
                this.setState({
                    data,
                    showLoading: false,
                });
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        });
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getListOrderOnSearch();
            }
        );
    };

    render() {
        const {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="list-ordered-service" parentClass="orders"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Dịch vụ đã đăng ký"/>
                                    <form className="kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className="pl-0 col-xs-12 col-6 kt-margin-b-10-tablet-and-mobile">
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.listServicePlan}
                                                    getOptionLabel={option => option.servicePlanName}
                                                    style={{width: '100%'}}
                                                    inputValue={this.state.servicePlanSearch}
                                                    renderInput={params => {
                                                        // if(params.inputProps.value !== '')
                                                        // console.log(params)
                                                        return <TextField
                                                            className='mt-4'
                                                            label="Gói dịch vụ"
                                                            placeholder='Dịch vụ kế toán cơ bản'
                                                            {...params}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                style: {
                                                                    fontSize: 18,
                                                                    backgroundColor: "#FFF",
                                                                    paddingRight: 5
                                                                }
                                                            }}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    }}
                                                    onInputChange={(event) => {
                                                        // debugger
                                                        if (event === null || event.target.value === undefined || event.target.value === "") {
                                                            this.setState({
                                                                servicePlanId: '',
                                                                servicePlanSearch: ''
                                                            })
                                                        } else {
                                                            this.setState({
                                                                servicePlanSearch: event?.target.value
                                                            })
                                                        }
                                                    }}
                                                    // disableClearable={true}
                                                    onChange={(e, val) => {
                                                        this.setState({
                                                            servicePlanSearch: val?.servicePlanName,
                                                            servicePlanId: val?.id
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="pr-0 col-xs-12 col-6 kt-margin-b-10-tablet-and-mobile">
                                                <InputLabel >Trạng thái</InputLabel>
                                                <Select
                                                    style={{width: '100%', height: "48%"}}
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    variant="outlined"
                                                    place="Trạng thái"
                                                    value={this.state.status1}
                                                    onChange={(val) => this.setState({
                                                        status: val.target.value,
                                                        status1: val.target.value
                                                        // idPartner: val.id
                                                    })}
                                                >
                                                    <MenuItem value="">Tất cả
                                                    </MenuItem>
                                                    {status.map((item, index) =>
                                                        <MenuItem value={item.value}>{item.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.getListOrderOnSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                sort: "id",
                                                                descending: 'DESC',
                                                                status: '',
                                                                status1: '',
                                                                servicePlanSearch: '',
                                                                servicePlanId: ''
                                                            }, () => this.getListOrderOnSearch())
                                                        }}
                                                >Tải lại
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader header={this.state.header} clickSort={(data) => {
                                                let a = "";
                                                let descend = "";
                                                if (data.sortAsc == null) {
                                                    a = "id";
                                                    descend = 'DESC'
                                                } else {
                                                    a = data.sortId;
                                                    descend = data.sortAsc ? 'ASC' : 'DESC'
                                                }
                                                this.setState(
                                                    {
                                                        sort: a,
                                                        descending: descend
                                                    },() => this.getListOrderOnSearch())
                                            }}/>
                                            </thead>
                                            {!this.state.data || this.state.data.length === 0
                                                ?
                                                <tbody>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="text-center">
                                                            Không có dữ liệu
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                {this.state.data.map((row, index) => (
                                                    <tr key={row.name} style={{height: 50}}>
                                                        <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                        <td style={{textAlign: 'left'}}>{row.servicePlanName}</td>
                                                        <td style={{textAlign: 'left'}}>{row.supervisor}</td>
                                                        <td style={{textAlign: 'right'}}>
                                                            {row.dealPrice == -1
                                                                ? 'Thỏa thuận'
                                                                : <NumberFormat
                                                                    value={row.dealPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={"đ"}
                                                                />}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {row.cmnCreated ? moment(row.cmnCreated).format("DD-MM-YYYY HH:mm") : ''}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>{renderStatus(row.status)}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {row.status == "WAITING_CALL_CENTER_CONFIRM" ?
                                                                <button
                                                                    className="btn btn-action p-2"
                                                                    title="Xóa"
                                                                    disabled={row.status === "CANCELED"}
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row.id}, () => this.setState({showDelete: true}))
                                                                    }}
                                                                >
                                                                    <i className="ti-trash"/>

                                                                </button>
                                                                : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    <div className="pagination-right justify-flex-end">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showResend && <ModalResendOrder show={this.state.showResend}
                                                            onHide={() => this.setState({showResend: false})}
                                                            order={this.state.itemSelected}
                                                            onRefresh={() => this.getListOrderOnSearch()}
                />}
                {this.state.showDelete && <ModalDeleteOrderedService show={this.state.showDelete}
                                                             onHide={() => this.setState({showDelete: false})}
                                                             order={this.state.itemSelected}
                                                             onRefresh={() => this.getListOrderOnSearch()}
                />}
            </div>
        );
    }

}

export default OrderedService
