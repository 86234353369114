import React, {Component} from "react";
import {
    Paper,
    TablePagination,
    TextField
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import TableHeader from "../../../../components/TableHeader";
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import moment from "moment";
import Sidebar from "../../../../components/Sidebar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PageHeader from "../../../../components/PageHeader";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import FilesApi from "../../../../services/resource/files";
import UploadFile from "../../../../components/Modal/modalUploadFile";

function createData(stt, ngayThang, maHopDong, chungTu, loaiChungTu, trangThai) {
    return {stt, ngayThang, maHopDong, chungTu, loaiChungTu, trangThai};
}

const type_paper = [
    {
        value: "BILL",
        label: "Hóa đơn"
    },
    {
        value: "RECEIPT",
        label: "Phiếu thu"
    },
    {
        value: "PAY",
        label: "Phiếu chi"
    },
]

class FilesManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_temp_search: '',
            type_temp: '',
            fileId: "",
            page: 0,
            pageSize: 10,
            data: [],
            totalItem: 3,
            totalPage: 0,
            rows: [
                createData('1', '02/02/2020', 'HD1155', 'Hoá đơn 01', 'Hóa đơn', 'Chưa xử lý'),
                createData('2', '01/03/2020', 'HD8548', 'Phiếu thu 02', 'Phiếu thu', 'Hợp lệ'),
                createData('3', '01/09/2020', 'HD8797', 'Phiếu chi 03', 'Phiếu chi', 'Không hợp lệ'),
            ],
            date1: "",
            date2: "",
            modalUploadFile: false,
            //header table
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Thời gian",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Mã hợp đồng",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: true,
                },
                {
                    id: '123',
                    name: "Chứng từ",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: true,
                },
                {
                    id: '123',
                    name: "Loại chứng từ",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: true,
                },
                {
                    id: '123',
                    name: "Trạng thái",
                    style: {minWidth: 110, textAlign: "center"},
                    sort: true,
                },
                {
                    id: '123',
                    name: "Hành động",
                    style: {minWidth: 110, textAlign: "center"},
                    sort: false,
                }
            ],
        }
    }

    componentWillMount() {
        // this.getListFile()
        this.setState({
            date1: new Date().toISOString().substring(0, 10),
            date2: new Date().toISOString().substring(0, 10)
        })
    }

    //HANDLE_BUTTON*******************************************************************************

    onHandleClearText() {
        this.setState(
            {
                type_temp: "",
                type_temp_search: "",
                date1: new Date().toISOString().substring(0, 10),
                date2: new Date().toISOString().substring(0, 10)
            },
            // () => this.onhandleSearch()
        );
    }

    // getListFile () {
    //     let {
    //         page,
    //         pageSize,
    //         fileId
    //     } = this.state
    //     let payload = {
    //         pageNumber: page,
    //         pageSize: pageSize,
    //         id: fileId
    //     }
    //     console.log("payloadTT>>>>", payload)
    //     FileManagementApi.getListFile((payload, res) => {
    //         if (res.success) {
    //             console.log("res>>>>>", res)
    //             this.setState({
    //                 data: res.data.content,
    //                 totalItem: res.data.totalElements,
    //                 totalPage: Math.round(res.data.totalElements / this.state.pageSize)
    //             })
    //         } else {
    //             console.log("err>>>>>>>>", res)
    //         }
    //     })
    // }

    downloadFile = (item) => {
        let payload = {
            id: item.id
        }
        FilesApi.downloadFileDocument(payload)
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                // this.getListContractFromAPI();
            }
        );
    };

    uploadDocument = () => {

    }

    uploadSuccess = () => {
        this.setState({modalUploadFile: false}, () => {

        })
    }

    render() {
        let {rows, date1, date2, header, totalItem, page, pageSize} = this.state;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <div className="container">
                        <div className="row">
                            <Sidebar activeClass="files-management-doc"/>
                            <div className="mb-4 col-md-10 tab-content">
                                <div className="card col-12">
                                    <div className="pt-3 mb-2">
                                        <PageHeader title="Danh sách hóa đơn chứng từ" routerEnable={true}/>
                                        <form className="kt-form kt-form--fit kt-margin-b-20">
                                            <div className="row kt-margin-b-20">
                                                <div className="pl-0 col-lg-4 kt-margin-b-10-tablet-and-mobile mt-4">
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable={true}
                                                        options={type_paper}
                                                        inputValue={this.state.type_temp_search}
                                                        getOptionLabel={option => option.label}
                                                        style={{width: "100%"}}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Loại chứng từ"
                                                                placeholder="Tất cả"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                        onChange={(val, c) => {
                                                            this.setState({
                                                                type_temp: c?.value,
                                                                type_temp_search: c?.label
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="pl-0 col-lg-4 kt-margin-b-10-tablet-and-mobile mt-4">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Từ ngày"
                                                        type="date"
                                                        value={date1}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {fontSize: 18, backgroundColor: "#FFF", paddingRight: 5}
                                                        }}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                date1: val.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="pl-0 col-lg-4 kt-margin-b-10-tablet-and-mobile mt-4">
                                                    <TextField
                                                        variant="outlined"
                                                        size='small'
                                                        label="Đến ngày"
                                                        type="date"
                                                        fullWidth
                                                        value={date2}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {fontSize: 18, backgroundColor: "#FFF", paddingRight: 5}
                                                        }}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                date2: val.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/*<div className="kt-separator kt-separator--md kt-separator--dashed"></div>*/}
                                            <div className='col-xs-12 col-sm-12 col-md-6 text-left p-0 mt-3'>
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => this.onhandleSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button" className="btn mr-2 border-1-s btn-light btn-sm"
                                                        style={{height: 35}}
                                                        onClick={() => {
                                                            this.setState({
                                                                contractCode_temp: '',
                                                                status_temp: '',

                                                            }, () => {
                                                                this.onhandleSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => this.setState({modalUploadFile: true})}
                                                >
                                                    Upload file
                                                </button>
                                            </div>
                                        {/*<div className="pl-0 row d-flex m-3 justify-content-sm-center justify-content-md-start">*/}
                                        {/*    <Button*/}
                                        {/*        style={{textTransform: 'initial', marginRight: 10}}*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="primary"*/}
                                        {/*        onClick={() => {*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Tìm kiếm*/}
                                        {/*    </Button>*/}

                                        {/*    <Button*/}
                                        {/*        className="mr-3"*/}
                                        {/*        style={{textTransform: 'initial'}}*/}
                                        {/*        variant="outlined"*/}
                                        {/*        onClick={() => {*/}
                                        {/*            this.onHandleClearText()*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Refresh*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </form>
                                    </div>

                                    <div className="p-0 mb-3">
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data">
                                                <thead>
                                                <TableHeader header={header} clickSort={(data) => {
                                                    console.log(data);
                                                }}/>
                                                </thead>
                                                <tbody>
                                                {rows.map(row => (
                                                    <tr key={row.name}>
                                                        <td >{row.stt}</td>
                                                        <td >{moment(row.ngayThang).format("DD-MM-YYYY")}</td>
                                                        <td className="text-left">
                                                            <Link to={{
                                                                pathname: "/customer/contracts-detail",
                                                                search: `?contractId=${row.id}&code=${row.code}`,
                                                                state: {fromDashboard: true, contractId: 1}
                                                            }}
                                                                  style={{color: "#67b1ee"}}>
                                                                {row.code}
                                                            </Link>
                                                        </td>
                                                        <td className="text-left">{row.chungTu}</td>
                                                        <td >{row.loaiChungTu}</td>
                                                        <td>{row.trangThai}</td>
                                                        <td >
                                                            <button
                                                                className="btn btn-action"
                                                                title="Tải xuống"
                                                                onClick={() => this.downloadFile(row)}
                                                            >
                                                                <i className="ti-download"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/*<div className="align-right">*/}
                                        {/*    <Button*/}
                                        {/*        style={{textTransform: 'initial', marginRight: 20}}*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="primary"*/}
                                        {/*        onClick={() => {}}*/}
                                        {/*    >*/}
                                        {/*        Upload chứng từ*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                        <div className="justify-flex-end pagination-right">
                                            <Pagination
                                                activePage={this.state.pageNumber + 1}
                                                itemsCountPerPage={this.state.pageSize}
                                                totalItemsCount={this.state.totalItem}
                                                changeHandler={page => this.handleChangePage(page)}
                                            />
                                        </div>

                                        {/*<TablePagination*/}
                                        {/*    rowsPerPageOptions={[10, 20, 30, 40]}*/}
                                        {/*    component="div"*/}
                                        {/*    count={totalItem}*/}
                                        {/*    page={page}*/}
                                        {/*    rowsPerPage={pageSize}*/}
                                        {/*    labelRowsPerPage={'Số kết quả trên trang'}*/}
                                        {/*    // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                                        {/*    // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                                        {/*    labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}*/}
                                        {/*    onChangePage={(event, newPage) => {*/}
                                        {/*        // this.setState({page: newPage}, () => {*/}
                                        {/*        //     this.getListSupOnSearch();*/}
                                        {/*        // });*/}
                                        {/*    }}*/}
                                        {/*    onChangeRowsPerPage={(event) => {*/}
                                        {/*        // this.setState({*/}
                                        {/*        //     pageSize: event.target.value,*/}
                                        {/*        //     page: page*/}
                                        {/*        // }, () => {*/}
                                        {/*        //     this.getListSupOnSearch()*/}
                                        {/*        // });*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.modalUploadFile && <UploadFile uploadSuccess={this.uploadSuccess}/>}
                </div>
            </MuiPickersUtilsProvider>
        );
    }

}

export default FilesManagement
