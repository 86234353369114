import React from 'react'
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import ModalDelPartner from "../../../components/Modal/modalAdminDelPartnerList";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import PartnerApi from '../../../services/ob-user/partner'
import WalletApi from "../../../services/ob-core/wallet";
import ModalAddSupervisor from "../../../components/Modal/modalAddSupervisor";
import ModalAddPartner from "../../../components/Modal/modalAddPartner";
import ModalResetPass from '../../../components/Modal/admin/modalResetPassAdminPartner'
import ModalDeleteSupervisor from "../../../components/Modal/modalDeleteSupervisor";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {checkPermission} from "../../../utils/common";

const dateFormat = 'DD-MM-YYYY';

class PartnerManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            showModalAdd: false,
            showModalDel: false,
            showModalReset: false,
            fullName: '',
            tax: '',
            listData: [],
            countSupervisor: '',
            pageNumber: 0,
            pageSize: 10,
            totalItem: 50,
            status: "",
            status_temp: "",
            phone: '',
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên Partner",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'value',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'supervisor',
                    name: "Mã số thuế",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'Supervisor',
                    name: "Số Supervisor",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 200, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }


    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListPartnerFromAPI()
        })

    }

    //****************************************************************************************************************

    searchListPartnerFromAPI = () => {
        let {fullName, phone, tax, countSupervisor, status, sort, pageNumber, pageSize} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            fullName: fullName,
            phone: phone,
            tax: tax,
            countSupervisor: countSupervisor,
            status: status,
            sort: sort
            // sort: ,
            // descending:
        }
        PartnerApi.adminGetListPartner(payload, res => {
            // console.log('ListPartner>>>>>>>>>>>>>>>>:', res)
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    countSupervisor: res.data.content.countSupervisor,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra, vui long tải lại trang!"))
            }
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListPartnerFromAPI()
            }
        );
    }

    //Button***********************************************************************************************************

    onSearch = () => {
        this.setState({
            status: this.state.status_temp
        }, () => this.searchListPartnerFromAPI())
    }

    //****************************************************************************************************************

    handleShowModalDel = (data) => {
        console.log('here', data.id)
        this.setState({
            showModalDel: true,
            id: data.id
        })
    }

    handleShowModalAdd = (data) => {
        this.setState({
            showModalAdd: true,
        })
    }

    //****************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-secondary">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-secondary">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    }

//*********************************************************************************************************************

    render() {
        let {pageNumber, pageSize} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="partner-management" parentClass="employee-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*<Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Partner"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="col-6 pl-0">
                                            <label className="label-item">Trạng thái</label>
                                            <select
                                                value={this.state.status_temp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        status_temp: e.target.value
                                                    })
                                                }}
                                                className="form-control kt-input pd-4-input shadow-none"
                                            >
                                                <option value=''>Tất cả</option>
                                                <option value='ACTIVE'>Đang hoạt động</option>
                                                <option value='LOCK'>Đã khóa</option>
                                                <option value='CANCELED'>Đã xóa</option>
                                                <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option>
                                            </select>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            {/*<div>*/}
                                            {/*    <label className='label-item mb-4'></label>*/}
                                            {/*</div>*/}
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.setState({
                                                            status_temp: ''
                                                        }, () => this.onSearch())}
                                                >Refresh
                                                </button>
                                                {checkPermission("add_new_partner") &&
                                                <button type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() =>
                                                            this.handleShowModalAdd()}
                                                >Thêm mới
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*        <Card.Body className="kt-portlet__body">*/}
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số Partner: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        {/*<Table striped bordered hover responsive>*/}
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListPartnerFromAPI())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>

                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{row.transactionContent}*/}
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.tax}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.countSupervisor}
                                                    </td>

                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{this.renderType(row.transactionType)}*/}
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'right',}}>
                                                        {checkPermission('view_partner_information') &&
                                                        <Link
                                                            to={{
                                                                pathname: "./partner-detail",
                                                                search: `?partnerId=${row.id}`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    partnerId: row.id,
                                                                }
                                                            }}
                                                            style={{color: "#000000"}}
                                                        >
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xem chi tiết"
                                                                onClick={() => {

                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission('edit_partner') &&
                                                        <Link
                                                            to={{
                                                                pathname: "./edit-partner",
                                                                search: `?partnerId=${row.id}`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    partnerId: row.id,
                                                                }
                                                            }}
                                                            style={{color: "#000000"}}
                                                        >
                                                            <button
                                                                className="btn btn-action"
                                                                title="Sửa"
                                                                disabled={row.status === 'CANCELED'}
                                                            >
                                                                <i className="ti-pencil-alt"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission('reset_partner_account_password') &&
                                                        <button
                                                            // onClick={() => this.handleShowModalDel(data)}
                                                            className="btn btn-action"
                                                            title="Reset"
                                                            disabled={row.status === 'CANCELED'}
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row.id,
                                                                    nameUser: row.fullName
                                                                }, () => this.setState({showModalReset: true}))
                                                            }}
                                                        >
                                                            <i className="ti-reload"/>
                                                        </button>
                                                        }
                                                        {checkPermission('delete_partner_account') &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xóa"
                                                            disabled={row.status === 'CANCELED'}
                                                            onClick={() => {
                                                                if (row.countSupervisor == 0) {
                                                                    this.handleShowModalDel(row)
                                                                } else {
                                                                    return (toast.error("Tài khoản Partner còn Supervisor và Accountant đang phụ trách, không thể thực hiện thao tác xóa", {
                                                                        position: toast.POSITION.TOP_RIGHT,
                                                                        autoClose: 4000
                                                                    }))
                                                                }
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*</Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDelPartner show={this.state.showModalDel}
                                 id={this.state.id}
                                 onHide={() => this.setState({showModalDel: false})}
                                 onRefresh={() => this.searchListPartnerFromAPI()}
                />
                <ModalAddPartner show={this.state.showModalAdd}
                                 id={this.state.id}
                                 onHide={() => this.setState({showModalAdd: false})}
                                 onRefresh={() => this.searchListPartnerFromAPI()}
                />

                <ModalResetPass
                    show={this.state.showModalReset}
                    onHide={() => this.setState({showModalReset: false})}
                    id={this.state.itemSelected}
                    name={this.state.nameUser}
                />
            </div>
        )
    }
}

export default PartnerManagement
