import React from 'react';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import ServicePlan from "../../customer/service-plan";

// import ModalDetailOrder from "../../../components/Modal/modalDetailOrder";
import ModalAcceptOrderCallCenter from "../../../components/Modal/modalAcceptOrderCallCenter";
import ModalResendOrder from "../../../components/Modal/modalResendOrder";
import ModalRejectOrderCallCenter from "../../../components/Modal/modalRejectOrderCallCenter"
import ModalContactCallCenter from "../../../components/Modal/modalContactCallCenter"
import ModalDetailOrderCallCenter from "../../../components/Modal/modalDetailOrderCallCenter";
import ModalDetailOrderAdvanced from "../../../components/Modal/modalDetailOrderAdvanced";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../services/ob-user/supervisor";
import PublicOrderApi from "../../../services/ob-core/public-order-service";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import CallCenterApi from "../../../services/ob-user/call-center";

class NewOrdersManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDetailOrder: false,
            modalAssignOrder: false,
            showConfirm: false,
            showResend: false,
            showReject: false,
            showContact: false,
            showAdvance: false,
            typeActionAdvance: 'detail',
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            orderCode: null,
            sort: "cmnCreatedAt=desc",
            status: "WAITING_CALL_CENTER_CONFIRM",
            status_temp: '',
            listCustomerId: [],
            listCallCenterId: [],
            list: [],
            listSupervisorId: [],
            selectedId: '',
            supIdFilter: '',
            selectedSupervisor: null,
            supervisorName: '',
            supervisorSuggestions: [],
            supervisor_temp: '',
            supervisorList: [],
            servicePlan: [],
            servicePlanId: null,
            servicePlanId_temp: null,
            clearSearchOrd: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã Order",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false
                },
                //
                // {
                //     id: "value",
                //     name: "Giá trị hợp đồng (VND)",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "supervisorId",
                    name: "Người phụ trách",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "callCenterId",
                    name: "Call Center",
                    style: {textAlign: "center"},
                    sort: false
                },
                // {
                //     id: "supervisorStatus",
                //     name: "Trạng thái Supervisor xử lý",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "serviceId",
                    name: "Gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian gửi yêu cầu",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: true
                },
                {
                    id: "status",
                    name: "Số lần gọi",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 180, textAlign: "center"},
                    sort: false
                }
            ]
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getOrderList();
            // this.getListSupervisor()
            this.getServicePlan()
        })
    }

    onHandleSearch = () => {
        let {code_temp, status_temp, servicePlanId_temp, selectedSupervisor, supervisorId_temp, supervisorName} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0,
                servicePlanId: servicePlanId_temp,
                supervisorId: selectedSupervisor ? selectedSupervisor.id : supervisorId_temp
            },
            () => {
                this.getOrderList();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                orderCode: "",
                // status: "",
                // status_temp: "",
                servicePlanId: null,
                servicePlanId_temp: '',
                selectedSupervisor: null,
                supervisorName: '',
                supervisorId: null,
                clearSearchOrd: true,
            },
            () => this.onHandleSearch()
        );
    }

    getOrderList = () => {
        let self = this;
        let payload = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            orderCode: this.state.orderCode,
            sort: this.state.sort,
            status: this.state.status,
            // status: "WAITING_CALL_CENTER_CONFIRM",
            supervisorId: this.state.supervisorId,
            servicePlanId: this.state.servicePlanId,
        }
        PublicOrderApi.ccSearchByServicePlanIdAndCustomer(payload, res => {
            if (res.success) {
                let listCustomerId = [];
                let listSupervisorId = [];
                let listCallCenterId = [];
                let data = res.data.content
                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listSupervisorId.push(item.supervisorId);
                    listCallCenterId.push(item.callCenterId);
                });
                this.setState({
                    list: res.data.content,
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                    listSupervisorId,
                    listCallCenterId,
                    showLoading: false
                }, () => {
                    if (data.length > 0) {
                        self.getListCustomerByIds();
                        self.getListSupervisorByIds();
                        self.getListCallCenterByIds()
                    }
                })
            } else {

            }
        }).catch(err => {

        })
    }

    getListCustomerByIds = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };
        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                    item.customerPhone = filter[0]?.phone;
                });
                this.setState({
                    listData
                });
            }
        });
    }

    getListSupervisorByIds = () => {
        let {listSupervisorId, listData} = this.state;
        let payload = {
            ids: listSupervisorId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorName = filter[0]?.fullName;
                });
                this.setState({
                    listData, list: res.data
                });
            }
        });
    }

    getListCallCenterByIds = () => {
        let {listCallCenterId, listData} = this.state;
        let payload = {
            ids: listCallCenterId
        };
        CallCenterApi.getNamesByListIds(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.callCenterId);
                    item.callCenterName = filter[0]?.fullName;
                });
                this.setState({
                    listData, list: res.data
                });
            }
        });
    }

    renderStatus(status) {
        switch (status) {
            case "WAITING_FOR_CONFIRMATION":
                return <h5><span className="badge badge-secondary">Chờ Supervisor xác nhận</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-primary">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h5><span className="badge badge-warning">Đã từ chối</span></h5>;
            case "CONFIRMED":
                return <h6><span className="badge badge-success">Đã xác nhận</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CREATED_CONTRACT":
                return <h6><span className="badge badge-info">Đã tạo hợp đồng</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getOrderList();
            }
        );
    }

    showModalDetailOrder = () => {
        if (this.state.itemSelected.orderType === 'BASIC')
            this.setState({modalDetailOrder: true})
        else if (this.state.itemSelected.orderType === 'ADVANCE')
            this.setState({
                showAdvance: true,
                typeActionAdvance: 'detail'
            })
    }

    getListSupervisor = () => {
        let payload = {
            fullName: this.state.supervisorName,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                this.setState({supervisorList: res.data.content})
            }
        })
    }

    getServicePlan = () => {
        let payload = {
            type: 'BASIC',
        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({servicePlan: res.data})
            }
        })
    }

    render() {
        let {listData, pageNumber, header, supervisorSuggestions, supervisor_temp, servicePlan} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="new-orders"/>*/}
                        <Sidebar activeClass="new-orders" parentClass="orders"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Order mới"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">
                                                <label className="label-item">Người phụ trách</label>
                                                <Autosuggestion
                                                    label="Người phụ trách"
                                                    placeholder="Nhập tên người phụ trách"
                                                    type="text"
                                                    clearSearch={this.state.clearSearchOrd}
                                                    data={this.state.supervisorList}
                                                    onSelect={(item) => this.setState({selectedSupervisor: item})}
                                                    onChange={(val) => {
                                                        this.setState({supervisorName: val, selectedSupervisor: null, clearSearchOrd: false})
                                                        setTimeout(() => {
                                                            this.getListSupervisor()
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.supervisorList}
                                                    errorContent="Vui lòng chọn một Supervisor"
                                                />
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">
                                                <label className="label-item">Gói dịch vụ</label>

                                                <select
                                                    value={this.state.servicePlanId_temp}
                                                    // defaultValue={this.state.servicePlanId_temp || this.state.servicePlanId}
                                                    onChange={val =>
                                                        this.setState({servicePlanId_temp: val.target.value})
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    {servicePlan.map(_item => {
                                                        return (
                                                            <option value={_item.id}>{_item.servicePlanName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            {/*<div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-0">*/}
                                            {/*    <label className="label-item">Trạng thái</label>*/}
                                            {/*    <select*/}
                                            {/*        // value={this.state.status_temp}*/}
                                            {/*        value={this.state.status_temp}*/}
                                            {/*        onChange={val =>*/}
                                            {/*            this.setState({status_temp: val.target.value})*/}
                                            {/*        }*/}
                                            {/*        className="form-control kt-input pd-4-input shadow-none">*/}
                                            {/*        <option value="">Tất cả</option>*/}
                                            {/*        <option value="WAITING_FOR_CONFIRMATION">Chờ xác nhận</option>*/}
                                            {/*        <option value="CONFIRMED">Đã xác nhận</option>*/}
                                            {/*        /!*<option value="CREATED_CONTRACT">Đã tạo hợp đồng</option>*!/*/}
                                            {/*        /!*<option value="PROCESSING">Đang xử lý</option>*!/*/}
                                            {/*        <option value="REJECTED">Đã từ chối</option>*/}
                                            {/*        <option value="CANCELED">Đã hủy</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className='mt-2 align-text-bottom pl-0 button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onHandleSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.setState({
                                                            supervisorName: '',
                                                            pageNumber: 0
                                                        }, () => this.onHandleClearText())}
                                                >
                                                    Refresh
                                                </button>
                                                {/*<Link to="/partner/create-contract">*/}
                                                {/*    <button*/}
                                                {/*        type="button"*/}
                                                {/*        className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                {/*        onClick={() => {*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        Thêm mới*/}
                                                {/*    </button>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    if (data.sortAsc == null) {
                                                        a = "cmnCreatedAt=desc";
                                                    } else {
                                                        a = `${data.sortId}=${
                                                            data.sortAsc ? "asc" : "desc"
                                                        }`;
                                                    }
                                                    this.setState(
                                                        {
                                                            sort: a
                                                        },
                                                        () => this.getOrderList()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData ||
                                            this.state.listData.length == 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <div className="text-center">
                                                            Không có dữ liệu
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData &&
                                                listData.length > 0 &&
                                                listData?.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            {row.code}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row?.customerName}
                                                        </td>
                                                        {/*<td style={{textAlign: "right"}}>*/}
                                                        {/*    {" "}*/}
                                                        {/*    {row.dealPrice == '-1' ? "Thỏa thuận" :*/}
                                                        {/*        <NumberFormat*/}
                                                        {/*            value={row.dealPrice}*/}
                                                        {/*            displayType={"text"}*/}
                                                        {/*            thousandSeparator={true}*/}
                                                        {/*        />}*/}

                                                        {/*</td>*/}
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.supervisorName || 'Chưa có dữ liệu'}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.callCenterName || 'Chưa có dữ liệu'}
                                                        </td>
                                                        {/*<td style={{textAlign: "center"}}>*/}
                                                        {/*    {this.renderStatus(row.status)}*/}
                                                        {/*</td>*/}
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.servicePlanName}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {row.cmnCreated ? moment(row.cmnCreated).format("DD-MM-YYYY HH:mm") : ''}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {/*{this.renderStatus(row.status)}*/}
                                                            {row.callCustomer}/3
                                                        </td>

                                                        <td className='p-1' style={{textAlign: "center"}}>
                                                            <button
                                                                className="btn btn-action p-2"
                                                                title="Xem chi tiết"
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row}, () => {
                                                                        this.showModalDetailOrder()
                                                                    })
                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                            <button
                                                                className="btn btn-action p-2"
                                                                title="Đánh dấu đã gọi"
                                                                disabled={row.callCustomer === 3}
                                                                onClick={() => this.setState({
                                                                    itemSelected: row,
                                                                    showContact: true
                                                                })}
                                                            >
                                                                <i className="ti-headphone-alt"/>
                                                            </button>
                                                            {row.typeOrder === 'ADVANCE' && row.status === 'WAITING_CALL_CENTER_CONFIRM' && !row.supervisorId || !row.dealPrice
                                                                ? <button
                                                                    className="btn btn-action p-2"
                                                                    title="Cập nhật thông tin"
                                                                    onClick={() => this.setState({
                                                                        itemSelected: row,
                                                                        showAdvance: true,
                                                                        typeActionAdvance: 'edit'
                                                                    })}
                                                                >
                                                                    <i className="ti-pencil-alt"/>
                                                                </button>
                                                                : <button
                                                                    className="btn btn-action p-2"
                                                                    title="Gửi lại"
                                                                    disabled={row.callCustomer == 0}
                                                                    onClick={() => this.setState({
                                                                        itemSelected: row,
                                                                        showResend: true
                                                                    })}
                                                                >
                                                                    <i className="ti-loop"/>
                                                                </button>
                                                            }
                                                            <button
                                                                className="btn btn-action p-2"
                                                                title="Xác nhận"
                                                                /*disabled={row.typeOrder === 'ADVANCE' && row.status === 'WAITING_CALL_CENTER_CONFIRM' && !row.supervisorId || !row.dealPrice}*/
                                                                disabled={row.callCustomer == 0}
                                                                onClick={() => this.setState({
                                                                    itemSelected: row,
                                                                    showConfirm: true
                                                                })}
                                                            >
                                                                <i className="ti-check-box"/>
                                                            </button>
                                                            <button
                                                                className="btn btn-action p-2"
                                                                title="Hủy order"
                                                                disabled={row.callCustomer == 0}
                                                                onClick={() => this.setState({
                                                                    itemSelected: row,
                                                                    showReject: true
                                                                })}
                                                            >
                                                                <i className="ti-close"/>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalDetailOrder && <ModalDetailOrderCallCenter show={this.state.modalDetailOrder}
                                                                            data={this.state.itemSelected}
                                                                            onRefresh={() => this.onHandleSearch()}
                                                                            onHide={() => this.setState({modalDetailOrder: false})}/>}
                {this.state.showConfirm && <ModalAcceptOrderCallCenter selectedOrder={this.state.itemSelected}
                                                                       onRefresh={() => this.onHandleSearch()}
                                                                       onHide={() => this.setState({showConfirm: false})}/>}
                {this.state.showResend && <ModalResendOrder show={this.state.showResend}
                                                            order={this.state.itemSelected}
                                                            onHide={() => this.setState({showResend: false})}
                                                            onRefresh={() => this.getOrderList()}/>}
                {this.state.showReject && <ModalRejectOrderCallCenter show={this.state.showReject}
                                                                      selectedOrder={this.state.itemSelected}
                                                                      onRefresh={() => this.onHandleSearch()}
                                                                      onHide={() => this.setState({showReject: false})}/>}
                {this.state.showContact && <ModalContactCallCenter show={this.state.showContact}
                                                                   selectedOrder={this.state.itemSelected}
                                                                   onRefresh={() => this.onHandleSearch()}
                                                                   onHide={() => this.setState({showContact: false})}/>}
                {this.state.showAdvance && <ModalDetailOrderAdvanced show={this.state.showAdvance}
                                                                     data={this.state.itemSelected}
                                                                     typeAction={this.state.typeActionAdvance}
                                                                     onHide={() => this.setState({showAdvance: false})}
                                                                     onRefresh={() => this.onHandleSearch()}/>}
            </div>
        );
    }

}

export default NewOrdersManagement
