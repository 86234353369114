import React from 'react'
import {toast} from "react-toastify";
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import EmailTypeDataApi from "../../../../services/ob-core/email-type-data";
import SMSTypeDataApi from "../../../../services/ob-core/sms-type-data";
import EmailTypeApi from "../../../../services/ob-core/email-type";
import SMSTypeApi from "../../../../services/ob-core/sms-type";


class SmsConfig extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            itemSelected: {},
            type: [],
            typeData: [],
            // subject: "",
            // content: "",
            idSMS: "",
            smsContent: null,
            smsSubject: null,
            showLoading: false,

        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {

            this.getListType()
        })
    }

    getListType = () => {
        SMSTypeApi.getAllSMS(res => {
            if (res.success) {
                this.setState({type: res.data, showLoading: false}, () => console.log("check>>>"))
            }
        })
    }

    selectType = (val) => {
        this.setState({selectedType: val, selectedTypeData: null}, () => {
            this.getTypeData();
            this.getTypeContent()
        })
    }

    selectTypeData = (val) => {
        let filter = this.state.typeData?.filter((item) => item.data == val)
        // console.log("check filter>>>>>", filter)
        this.setState({selectedTypeData: filter[0]}, () => {

        })
    }

    getTypeData = () => {
        let payload = {
            type: this.state.selectedType
        }
        SMSTypeDataApi.getListTypeData(payload, res => {
            if (res.success) {
                this.setState({typeData: res.data})
            }
        })
    }

    getTypeContent = () => {
        let payload = {
            type: this.state.selectedType
        }
        SMSTypeApi.getSMSTypeBody(payload, res => {
            if (res.success) {
                this.setState({
                    smsContent: res.data.body,
                    idSMS: res.data.id,
                    smsSubject: res.data.subject
                }, () => console.log("content>>>", this.state.smsContent))
            }
        })
    }

    onSave = () => {
        let payload = {
            body: this.state.content || this.state.smsContent,
            id: this.state.idSMS,
            // subject: this.state.subject || this.state.smsSubject
        }
        SMSTypeApi.updateSMS(payload, res => {
            if (res.success) {
                toast.success("Cập nhật thành công")
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        })
    }

    render() {
        let {pageNumber, pageSize, totalItem} = this.state;
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="sms-config" parentClass="config-system"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Cấu hình SMS"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Loại SMS</label>
                                                <select className="form-control kt-input pd-4-input shadow-none"
                                                        defaultValue={0}
                                                        onChange={(val) => {
                                                            this.selectType(val.target.value)
                                                            // console.log("type>>>", val.target.value)
                                                            // this.setState({
                                                            //     selectedType: val.target.value
                                                            // })
                                                        }}
                                                >
                                                    <option value={0} disabled>Chọn một loại SMS</option>
                                                    {this.state.type?.map(item => (
                                                        <option value={item.type}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-6 pl-0 row">
                                                <label className="label-item">Label</label>
                                                <select className="form-control kt-input pd-4-input shadow-none"
                                                        defaultValue={0}
                                                        onChange={(val) => {
                                                            this.selectTypeData(val.target.value)
                                                            // this.setState({
                                                            //     selectedType: val.target.value
                                                            // })
                                                        }}
                                                >
                                                    <option value={0} disabled>Chọn một label</option>
                                                    {this.state.typeData?.map(item => (
                                                        <option value={item.data}>{item.data}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Giải thích label</label>
                                                <div className="align-items-center pl-2 d-flex" style={{height: 35, border: "1px solid #ced4da", borderRadius: ".25rem", backgroundColor: '#ced4da'}}>
                                                    <span>{this.state.selectedTypeData?.data} - {this.state.selectedTypeData?.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <label className="label-item">Nội dung SMS</label>
                                    <textarea name="" id="" rows="10" className="form-control" value={this.state.onEdit ? this.state.content : this.state.smsContent} maxLength={2000}
                                              onChange={(val)=>{
                                                  this.setState({content: val.target.value, onEdit: true})
                                              }}/>
                                </div>
                                <div
                                    className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                    <div className="button-filter">
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            onClick={() => {
                                                this.onSave()
                                            }}
                                        >
                                            Lưu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SmsConfig
