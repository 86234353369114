
import { stringify } from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let UserFile = {
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/user-file/delete/${payload.id}`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
    supervisorUploadCert: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-user/secure/user-file/supervisor-upload-certificate`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/user-file/get-all`,
            method: 'GET'
        }, callback)
    },
}
export default UserFile
