import React, { Component } from "react";
import Basic from './Basic/index';
import Advance from './Advance/index'
import {Button, Card, CardContent, Grid, NativeSelect, Paper} from "@material-ui/core";
import {Link} from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";

const fakedata = [
    {
        name: 'Thương mại dịch vụ',
    },
    {
        name: 'Thương mại dịch vụ',
    },
    {
        name: 'Thương mại dịch vụ',
    },
];

class ServicePlan extends Component{
    constructor(props) {
        super(props);
        this.state = {
            switch: true,
            selected: 1,
        }
    }
    handleClick1 = () => {
        this.setState({switch : false })
    }
    handleClick2 = () => {
        this.setState({switch : true  })
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="service-plan" />
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2 pb-3">
                                    <PageHeader routerEnable={true} title={this.state.selected == 1?'Dịch vụ cơ bản':'Dịch vụ nâng cao' }/>
                                    <div className='mt-2 mb-2 d-flex justify-content-end'>
                                        {this.state.selected == 1?
                                            <button
                                                type="button"
                                                className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={()=> {
                                                    this.setState({selected: 2});
                                                    document.title = 'Gói dịch vụ nâng cao';
                                                }}
                                            >
                                                Gói dịch vụ nâng cao
                                            </button>
                                        :
                                            <button
                                                type="button"
                                                className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={()=> {
                                                    this.setState({selected: 1});
                                                    document.title = 'Gói dịch vụ cơ bản';
                                                }}
                                            >
                                                Gói dịch vụ cơ bản
                                            </button>
                                        }
                                    </div>

                                        {this.state.selected == 1 ?
                                            <Basic data ={fakedata}/>
                                            :
                                            <Advance data ={fakedata}/>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ServicePlan
