import React from 'react';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import {toast} from "react-toastify";

class ModalRequireLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='m-2 text-center' style={{fontSize: 16}}>
                            <label>Vui lòng đăng nhập để tiếp tục thao tác !</label>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đóng
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalRequireLogin;

