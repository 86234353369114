import React from "react";
import PublicNews from "../../../services/ob-core/public-news";
import './style.css';
import Convert from "../../../utils/convertUrlPra";
import PublicNewsCategory from "../../../services/ob-core/public-news-category";
import Link from "../../../components/Link";
import DocViewer, { PDFRenderer} from "react-doc-viewer";
class HomeDocumentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: '',
            categoryName: '',
            listCategory: [],
            data: []
        }
    }

    componentWillMount() {
        this.getListCategory();
        let params = Convert.urlParams(this.props.location.search);
        this.setState({newsId: params.i, path: params.doc}, () =>{
            this.getDetail()
        })
    }
    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: this.reformatData(res.data),
                showLoading: false
            })
        })
    }
    reformatData = (listData) => {
        let listCate = [];
        listData.forEach(cateInfo => {
            if (cateInfo.parentId == 0 && cateInfo.type == 2){
                cateInfo.subCate = [];
                listData.forEach(subCate => {
                    if (subCate.parentId != 0 && subCate.parentId == cateInfo.id){
                        subCate.subCate = [];
                        listData.forEach(inSubCate => {
                            if (inSubCate.parentId != 0 && inSubCate.parentId == subCate.id){
                                inSubCate.subCate = [];
                                listData.forEach(inInSubCate => {
                                    if (inInSubCate.parentId != 0 && inInSubCate.parentId == inSubCate.id){
                                        inSubCate.subCate.push(inInSubCate);
                                    }
                                });
                                subCate.subCate.push(inSubCate);
                            }
                        });
                        cateInfo.subCate.push(subCate);
                    }
                });
                listCate.push(cateInfo);
            }
        });
        return listCate;
    }
    getDetail = () => {
        let payload = {
            id: this.state.newsId
        }
        PublicNews.getDetail(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data
                })
            }
        })
    }

    render() {
        const {data, dataCategory} = this.state;
        return (
            <div className="home-documents">
                <div>
                    <div className="container">
                        <div className='row documents'>
                            <div className='col-md-3'>
                                <aside className="sidebar">
                                    <h4 className="heading-primary">Danh mục văn bản</h4>
                                    <ul className="nav nav-list">
                                        {this.state.listCategory.length > 0 && this.state.listCategory.map(cateInfo => (
                                            <li className={cateInfo.path === this.state.path? 'active': ''}>
                                                <Link
                                                    title={cateInfo.name}
                                                    to={{
                                                        pathname: '/home/documents',
                                                        search: `?doc=${cateInfo.path}`,
                                                        state: {
                                                            fromDashboard: true,
                                                            category: cateInfo.path,
                                                        }
                                                    }}
                                                >
                                                    {cateInfo.name}
                                                </Link>
                                                {cateInfo.subCate != undefined && cateInfo.subCate.length > 0 &&
                                                <ul>
                                                    {cateInfo.subCate.map(subCate => (
                                                        <li className={subCate.path === this.state.path? 'active': ''}>
                                                            <Link
                                                                title={subCate.name}
                                                                to={{
                                                                    pathname: '/home/documents',
                                                                    search: `?doc=${subCate.path}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        category: subCate.path,
                                                                    }
                                                                }}
                                                            >
                                                                {subCate.name}
                                                            </Link>
                                                            {cateInfo.subCate != undefined && subCate.subCate.length > 0 &&
                                                            <ul>
                                                                {subCate.subCate.map(inSubCate => (
                                                                    <li className={inSubCate.path === this.state.path? 'active current': this.state.path}>
                                                                        <Link
                                                                            title={inSubCate.name}
                                                                            to={{
                                                                                pathname: '/home/documents',
                                                                                search: `?doc=${inSubCate.path}`,
                                                                                state: {
                                                                                    fromDashboard: true,
                                                                                    category: inSubCate.path,
                                                                                }
                                                                            }}
                                                                        >
                                                                            {inSubCate.name}
                                                                        </Link>
                                                                        {cateInfo.subCate != undefined && inSubCate.subCate.length > 0 &&
                                                                        <ul>
                                                                            {inSubCate.subCate.map(inInSubCate => (
                                                                                <li className={inInSubCate.path === this.state.path? 'active current': ''}>
                                                                                    <Link
                                                                                        title={inInSubCate.name}
                                                                                        to={{
                                                                                            pathname: '/home/documents',
                                                                                            search: `?doc=${inInSubCate.path}`,
                                                                                            state: {
                                                                                                fromDashboard: true,
                                                                                                category: inInSubCate.path,
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {inInSubCate.name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                        }
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            }
                                                        </li>
                                                    ))}
                                                </ul>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            </div>
                            <div className='col-md-9'>
                                <div className='article-wrapper'>
                                    <div className='container'>
                                        <h2 className='color-main text-left text-bold'>
                                            {data?.title}
                                        </h2>
                                        <div className='row'>
                                            {data?.newsType < 2?
                                                <div className='art-content col-md-12 mt-4'>
                                                    <div dangerouslySetInnerHTML={{ __html: data?.body }} />
                                                </div>
                                                :
                                                (data?.linkFile != "" && data?.linkFile != "_") &&
                                                <div className='art-content col-md-12 mt-4'>
                                                    <div dangerouslySetInnerHTML={{ __html: data?.description }} />
                                                    <a href={data?.linkFile} target="_blank">Xem chi tiết</a>
                                                    <DocViewer
                                                        pluginRenderers={[PDFRenderer]}
                                                        documents={[
                                                            { uri: data?.linkFile},
                                                        ]} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default HomeDocumentDetail
