import {SHOW_NOTIFICATION, CLEAN_NOTIFICATION, SHOW_LOADING, HIDE_LOADING} from '../actions/actionType';
import DefaultState from '../reducer/defaultState';
import {combineReducers} from "redux";

// const loading =
const reducer = (state = DefaultState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION :
            return {
                ...state,
                ['Notification']: action.notification,
                ['TimeNotification']: action?.notification?.message ? 3000 : action.time,
                ['ShowNotification']: !!action?.notification?.message,
            };
        case CLEAN_NOTIFICATION :
            return {
                ...state,
                ['ShowNotification']: false,
                ['TimeNotification']: 3000,
            };
        case SHOW_LOADING :
            return {
                ...state,
                ShowLoading: true,
                isFetching: true,
            };
        case HIDE_LOADING :
            return {
                ...state,
                ShowLoading: false,
                isFetching: false,
            };
        default:
            return state
    }
};
export default combineReducers({
    reducer
});
