import React, { Component } from "react";
import './index.css';
import {
    Card,
    Breadcrumbs,
    RadioGroup,
    TextField,
    Grid,
    Radio,
    Link,
    Icon,
    CardContent,
    InputAdornment,
    CircularProgress,
    Button,
} from '@material-ui/core';
import DatePicker from "react-datepicker/es";
import Utils from "../../../utils/utils";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import Sidebar from "../../../components/Sidebar";
import Dropzone from 'react-dropzone';
import PageHeader from "../../../components/PageHeader";
import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar"
import moment from 'moment';
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import { createHashHistory } from 'history'
import ProvinceApi from "../../../services/ob-user/province";
import { toast } from "react-toastify";
import AdminOneBookApi from "../../../services/ob-user/one-book-controller";
export const history = createHashHistory()
const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderAreaId = function (areaId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === areaId)[0] ? _province?.filter(item => item.id === areaId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

const renderArea = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};


function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
function renderPosition(status) {
    switch (status) {
        case "CUSTOMER":
            return "Khách hàng";
        case "PARTNER":
            return "Admin Partner";
        case "ACCOUNTANT":
            return "Kế toán";

        default:
            return;
    }
}


class CompleteProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: true,
            inEditing: false,
            data: {},
            listProvince: [],
            listDistrict: [],
            AreaSup: [],

            modalUploadAvatar: false,
            fullName: "",
            sex: "",
            provinceId: 0,
            districtId: 0,
            address: "",
            areaId: 0,
            identify: "",
            dateIssued: "",
            placeIssued: "",
            urlImage: "",
            email: "",
            phone: "",


            fullNameError: false,
            sexError: false,
            provinceIdError: false,
            districtIdError: false,
            emailError: false,
            addressError: false,
            areaIdError: false,
            identifyError: false,
            dateIssuedError: false,
            placeIssuedError: false,
            updated: false,
            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,
        }
    }

    componentWillMount() {
        // this.getAccountInfo();


        this.getSupportArea();
    }



    handleShowModalForgotPassword = () => {
        this.setState({ showModalChangePassword: true });
    };

    onCloseModalForgotPassword = () => {
        this.setState({ showModalChangePassword: false });
    }

    // getAccountInfo() {
    //     getProfile(res => {
    //         if (res.success === true) {
    //             console.log("success>>>>>>", res)
    //             this.setState({
    //                 data: res.data,
    //                 fullName: res.data.fullName,
    //                 sex: res.data.sex,
    //                 provinceId: res.data.provinceId,
    //                 districtId: res.data.districtId,
    //                 address: res.data.address,
    //                 areaId: res.data.areaId,
    //                 identify: res.data.identify,
    //                 dateIssued: res.data.dateIssued,
    //                 placeIssued: res.data.placeIssued,
    //                 urlImage: res.data.urlImage,
    //             })
    //         } else {
    //             console.log("err >>>>>>", res)
    //         }
    //     })
    // }
    getSupportArea() {
        ProvinceApi.supportArea(res => {
            if (res.success === true) {
                this.setState({
                    AreaSup: res.data.content
                })
            } else {

            }
        })
    }


    handleChangeTax(val) {
        // console.log(val)
        this.setState({ inEditing: true })
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({ tax: onlyNums }, () => {
                this.state.tax.length != 0 ? this.setState({ taxError: false }) : this.setState({ taxError: true });
                this.state.tax.length >= 10 ? this.setState({ taxError: false }) : this.setState({ taxError: true })
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({ tax: onlyNums });
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({ tax: onlyNums });
        }
    };

    handleChangePhone(val) {
        this.setState({ inEditing: true })
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({ value: onlyNums, representativePhone: onlyNums }, () => {
                this.state.representativePhone.length != 0 ? this.setState({ representativePhoneError: false }) : this.setState({ representativePhoneError: true })
            });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({ value: number, error: false, representativePhone: onlyNums });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({ value: number, error: false, representativePhone: onlyNums });
        }
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({ inEditing: true })
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({ identify: onlyNums }, () => {
                this.state.identify.length != 0 ? this.setState({ identifyError: false }) : this.setState({ identifyError: true });
                this.state.identify.length >= 9 ? this.setState({ identifyError: false }) : this.setState({ identifyError: true })
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({ identify: onlyNums });
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({ identify: onlyNums });
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"))

        this.setState({ userStatus: userInfo?.status, email: userInfo?.email, fullName: userInfo.fullName, phone: userInfo.phone })

    }

    handleSave() {
        let {
            fullName, sex, provinceId, districtId, address, areaId,
            identify, dateIssued, placeIssue, email, phone
        } = this.state;
        if (fullName === '') {
            this.setState({ fullNameError: true })
            console.log("check1>>>>")
        } else
            if (sex === '') {
                console.log("check>>>>2")
                this.setState({ sexError: true })
            } else
                if (provinceId === '') {
                    console.log("check>>>>3")
                    this.setState({ provinceIdError: true })
                } else
                    if (districtId === '') {
                        console.log("check>>>>4")
                        this.setState({ districtIdError: true })
                    } else
                        if (address === '') {
                            console.log("check>>>>5")
                            this.setState({ addressError: true })
                        } else
                            if (identify === '') {
                                console.log("check>>>>6")
                                this.setState({ identifyError: true })
                            } else
                                if (placeIssue === '') {
                                    console.log("check>>>>7")
                                    this.setState({ placeIssueError: true })
                                } else if (email === '') {
                                    console.log("check>>>>8")
                                    this.setState({ email: true })
                                } else
                                    if (dateIssued === '') {
                                        console.log("check>>>>9")
                                        this.setState({ dateIssuedError: true })
                                    } else {
                                        let payload = {
                                            body: {
                                                fullName: fullName ? fullName : '',
                                                sex: sex ? sex : '',
                                                email: email ? email : '',
                                                provinceId: provinceId ? provinceId : '',
                                                districtId: districtId ? districtId : '',
                                                address: address ? address : '',
                                                areaId: areaId ? areaId : '',
                                                identify: identify ? identify : '',
                                                dateIssued: dateIssued ? dateIssued : 0,
                                                placeIssued: placeIssue ? placeIssue : '',
                                                phone: phone,
                                                tax: "1"
                                            }
                                        };
                                        console.log("check>>>>")
                                        AdminOneBookApi.updateUser(payload, res => {
                                            if (res.success) {
                                                // console.log('payload lưu: ', payload);
                                                this.setState({ updated: true }, () => {
                                                    toast.success("Thông tin đã được cập nhật thành công !")
                                                })
                                                // history.push("/confirm-email")
                                            } else {
                                                toast.error("Đã cps lỗi xảy ra!")
                                            }
                                        })

                                    }
    }

    tick = () => {
        // reset when reaching 100%
        let { progress } = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleReset() {
        this.setState({
            sex: '',
            provinceId: 0,
            districtId: 0,
            address: '',
            areaId: 0,
            identify: '',
            dateIssued: '',
            placeIssued: '',
        })
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderPlaceSupport = (areaId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.areaId == areaId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    modalUploadAvatarClose = () => {
        this.setState({ modalUploadAvatar: false });
    }
    showModalUploadAvatar = () => {
        this.setState({ modalUploadAvatar: true })
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault()
    }

    render() {
        const {
            data, fullName, phone, email, sex, job,
            provinceId, districtId, address, areaId, identify,
            dateIssued, placeIssued, urlImage
        } = this.state;
        let jobDetail = localStorage.getItem("ob_cl_userType");
        let info = JSON.parse(localStorage.getItem("ob_cl_userInfo"));
        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="profile"/>*/}
                        <div className=" tab-content min-w100">
                            <Card variant="outlined" className='p-2 mb-5 complete-profile-wrapper'>
                                {this.state.updated || this.state.userStatus == "WAIT_CONFIRM_EMAIL" ?
                                    <CardContent>
                                        <div
                                            className='kt-margin-b-20 flex-column justify-content-center align-center p-3'
                                            style={{ background: '#F1CDCB' }}>
                                            <h6 className="line-height-30">ONEBOOK đã gửi một email xác thực thông tin
                                                đến hòm thư {this.state.emailSaved} của bạn. Bạn hãy truy cập vào Email để xác nhận thông tin và
                                                tiếp tục sử dụng hệ thống.</h6>
                                            <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                                        </div>
                                    </CardContent> :
                                    <CardContent>
                                        <PageHeader
                                            title='THÔNG TIN CÁ NHÂN' />
                                        <div className='col-12 mt-3'>
                                            <div className='justify-center img-logo-wrapper' style={{ textAlign: 'center', backgroundImage: info.urlImage ? `url(${info.urlImage}` : `url("/images/no-avatar-png.png")` }}>
                                            </div>
                                                <div>
                                                    <span className="fas fa-camera change-logo-icon" title="Tải lên ảnh đại diện" onClick={() => this.setState({ modalUploadAvatar: true })} />
                                                </div>
                                        </div>

                                        {/* <div className='text-center mb-4' style={{ height: 120 }}>
                                            <span
                                                className='img-thumbnail'
                                                style={{
                                                    width: 130,
                                                    height: 130,
                                                    overflow: 'hidden',
                                                    display: 'inline-block',
                                                    padding: 2
                                                }}
                                            >
                                                <img
                                                    // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}
                                                    src={data.urlImage ? data.urlImage : "https://www.w3schools.com/howto/img_avatar.png"}
                                                    className='mh-100 mw-100'
                                                />
                                            </span>

                                            <Dropzone onDrop={acceptedFiles => console.log("img>>>", acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <span title='Thay đổi logo' className='change-avt-1'>
                                                        <span {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <CameraAltOutlinedIcon />
                                                        </span>
                                                    </span>
                                                )}
                                            </Dropzone>

                                        </div> */}
                                        <div className='row kt-margin-b-20 mb-4 mt-4 font-14'>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50 m-1">Họ và tên<span
                                                    className={'color-red d-inline'}>*</span></label>
                                                {/*<label className="text-black-50">Họ và tên {this.state.onEdit &&*/}
                                                {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {/*{info?.fullName}*/}
                                                <TextField
                                                    fullWidth
                                                    variant={"outlined"}
                                                    InputProps={{ style: { height: 28 } }}
                                                    value={fullName}
                                                    onChange={(val) => {
                                                        if (placeIssued.length < 50) this.setState({
                                                            fullName: val.target.value,
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.fullName.length != 0 ? this.setState({ fullNameError: false }) : this.setState({ fullNameError: true })
                                                        })
                                                    }}
                                                    inputProps={{ maxLength: 50 }}
                                                    error={this.state.fullNameError}
                                                    helperText={this.state.fullNameError && 'Vui lòng nhập tên người đại diện !!'}

                                                />
                                            </div>

                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50 m-1">Giới tính
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                {/*<label className="text-black-50">Giới tính {this.state.onEdit &&*/}
                                                {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <div className='d-flex col-12'>
                                                    <div className="form-check col-6 ">
                                                        <input className="form-check-input" type="radio"
                                                            name="femaleRadios" id="female" value="FEMALE"
                                                            onClick={(val) => {
                                                                this.setState({
                                                                    sex: val.target.value,
                                                                    inEditing: true
                                                                })
                                                            }}
                                                            checked={this.state.sex === 'FEMALE'}
                                                        />
                                                        <label className="form-check-label"
                                                            htmlFor="exampleRadios1">
                                                            Nữ
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-6">
                                                        <input className="form-check-input" type="radio"
                                                            name="maleRadios" id="male" value="MALE"
                                                            onClick={(val) => {
                                                                this.setState({
                                                                    sex: val.target.value,
                                                                    inEditing: true
                                                                })
                                                            }}
                                                            checked={this.state.sex === 'MALE'}
                                                        />
                                                        <label className="form-check-label"
                                                            htmlFor="exampleRadios1">
                                                            Nam
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">

                                                <label className="text-black-50 m-1">CMT/CCCD{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>}</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <input type="text"
                                                        className={this.state.identifyError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                        // placeholder="Nhập số CMND/CCCD"
                                                        value={this.state.identify}
                                                        maxLength={12}
                                                        onChange={(val) => this.setState({
                                                            identify: val.target.value.trim().replace(/[^0-9]/g, ''),
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.identify.length === 0 || this.state.identify.length < 9 ? this.setState({
                                                                identifyError: true,
                                                                btnSaveStatus: false
                                                            }) : this.setState({
                                                                identifyError: false,
                                                                btnSaveStatus: true
                                                            })
                                                        })}
                                                    />
                                                    : info?.identify ? info?.identify : ''
                                                }
                                                {/* <TextField
                                                    fullWidth
                                                    variant={"outlined"}
                                                    InputProps={{style: {height: 28}}}
                                                    value={identify}
                                                    inputProps={{maxLength:12}}
                                                    onChange={(val) => {
                                                        // debugger
                                                        // console.log(val)
                                                        this.handleChangeIdentify(val)
                                                    }}
                                                    error={this.state.identifyError}
                                                    helperText={this.state.identifyError && 'Vui lòng nhập số CMT/CCCD !!'}

                                                /> */}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">

                                                <label className="text-black-50 m-1">Ngày cấp{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>}</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <DatePicker
                                                        className={this.state.dateIssuedError ? "w-autoerr react-datepicker-wrapper" : "w-auto react-datepicker-wrapper"}
                                                        // onChangeRaw={this.handleDateChangeRaw}
                                                        selected={this.state.dateIssued ? new Date(this.state.dateIssued) : null}
                                                        // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                        maxDate={new Date()}
                                                        // value={this.state.dateIssue}
                                                        dateFormat="dd-MM-yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        onChange={
                                                            (date) => {
                                                                let value = moment(date).format("YYYY-MM-DD").toString();
                                                                try {
                                                                    this.setState({
                                                                        dateIssued: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                    })
                                                                } catch (e) {

                                                                }
                                                            }
                                                        }
                                                    />
                                                    : info?.dateIssued ? moment(info?.dateIssued).format("DD-MM-YYYY") : ''
                                                }
                                                {this.state.dateIssuedError ?
                                                    <span className="pl-1 color-red size-font-err-date">Vui lòng nhập ngày cấp !</span> :
                                                    <div />}

                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50 m-1">Nơi cấp{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>}</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <input type="text"
                                                        className={this.state.placeIssueError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                        // placeholder="Nhập nơi cấp"
                                                        value={this.state.placeIssue}
                                                        // aria-label="Username"
                                                        maxLength={50}
                                                        // aria-describedby="basic-addon1"
                                                        onChange={(val) => this.setState({
                                                            placeIssue: val.target.value.trimLeft(),
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.placeIssue.length === 0 ? this.setState({
                                                                placeIssueError: true,
                                                                btnSaveStatus: false
                                                            }) : this.setState({
                                                                placeIssueError: false,
                                                                btnSaveStatus: true
                                                            })
                                                        })}

                                                    />
                                                    : info?.placeIssued ? info?.placeIssued : ''
                                                }
                                                {/* <TextField
                                                    fullWidth
                                                    variant={"outlined"}
                                                    InputProps={{style: {height: 28}}}
                                                    value={placeIssued}
                                                    inputProps={{maxLength:50}}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            placeIssued: val.target.value,
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.placeIssued.length != 0 ? this.setState({placeIssuedError: false}) : this.setState({placeIssuedError: true})
                                                        })
                                                    }}
                                                    error={this.state.placeIssuedError}
                                                    helperText={this.state.placeIssuedError && 'Vui lòng nhập nơi cấp  !!'}
                                                    maxLength={50}
                                                /> */}

                                            </div>
                                            {/* <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                                                <label className="text-black-50 m-1">Vị trí công việc</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                                {this.state.onEdit
                                                    ? <p className="mt-1">{jobDetail ? renderPosition(jobDetail) : ''}</p>
                                                    : <span>{jobDetail ? renderPosition(jobDetail) : ''}</span>}
                                            </div> */}
                                            
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                                                <label className="text-black-50 m-1">Email{this.state.onEdit &&
                                                    <span className={'color-red d-inline'}>*</span>} </label>
                                                {/* {this.state.onEdit === true
                                                    ? <label className="text-black-50 m-1">Email</label>
                                                    : <label className="text-black-50">Email</label>
                                                } */}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                                {/* {info?.email} */}
                                                {this.state.onEdit ?
                                                    <input type="email"
                                                        className={this.state.emailError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                        placeholder="Nhập email"
                                                        value={email}
                                                        maxLength={100}
                                                        onChange={(val) => {
                                                            if (Utils.checkEmailRegex(val.target.value)) {
                                                                this.setState({
                                                                    email: val.target.value.trim(),
                                                                    emailError: true,
                                                                    btnSaveStatus: false
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    email: val.target.value.trim(),
                                                                    emailError: false,
                                                                    inEditing: true,
                                                                    btnSaveStatus: true
                                                                })
                                                            }
                                                        }
                                                        }
                                                    />
                                                    : info?.email}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">

                                                <label className="text-black-50 m-1">Tỉnh/Thành Phố<span
                                                    className={'color-red d-inline'}>*</span></label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={provinceId}
                                                    defaultValue={0}
                                                    onChange={(e) => {
                                                        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                        let id = 0;
                                                        id = _district?.filter(item => item.provinceId == e.target.value)[0].id;
                                                        this.setState({
                                                            provinceId: e.target.value,
                                                            districtId: id,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >
                                                    <option value={0}>Chọn Tỉnh/Thành Phố</option>
                                                    {renderProvince()}
                                                </select>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">

                                                <label className="text-black-50 m-1">Quận/Huyện{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>} </label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <select
                                                        className="form-control kt-input pd-4-input hei-input"
                                                        value={districtId}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                districtId: e.target.value,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        <option value={0} >Chọn Quận/Huyện</option>
                                                        {this.renderDistrict(provinceId ? provinceId : data.provinceId)}
                                                    </select>
                                                    : info?.districtId ? renderDistrictId(info?.districtId) : ''
                                                }
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">

                                                <label className="text-black-50 m-1">Địa chỉ{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>}</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <input type="text"
                                                        className={this.state.addressError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                        placeholder="Nhập địa chỉ"
                                                        value={address}
                                                        maxLength={100}
                                                        onChange={(val) => this.setState({
                                                            address: val.target.value.trimLeft(),
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.address.length === 0 ? this.setState({
                                                                addressError: true,
                                                                btnSaveStatus: false
                                                            }) : this.setState({
                                                                addressError: false,
                                                                btnSaveStatus: true
                                                            })
                                                        })}
                                                    />
                                                    : info?.address ? info?.address : ''
                                                }
                                                {/* <TextField
                                                    fullWidth
                                                    variant={"outlined"}
                                                    InputProps={{ style: { height: 28 } }}
                                                    value={address}
                                                    inputProps={{ maxLength: 100 }}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            address: val.target.value,
                                                            inEditing: true
                                                        }, () => {
                                                            this.state.address.length != 0 ? this.setState({ addressError: false }) : this.setState({ addressError: true })
                                                        })
                                                    }}
                                                    error={this.state.addressError}
                                                    helperText={this.state.addressError && 'Vui lòng nhập địa chỉ !!'}
                                                /> */}

                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50 m-1">Khu vực hỗ trợ{this.state.onEdit && <span
                                                    className={'color-red d-inline'}>*</span>} </label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <select
                                                        className="form-control kt-input pd-4-input hei-input"
                                                        value={areaId}
                                                        defaultValue={0}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                areaId: e.target.value,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        <option value={0}>Chọn Tỉnh/Thành Phố</option>
                                                        {this.state.AreaSup?.map((item) => (<option value={item.id}>{item.name}</option>))}
                                                    </select>
                                                    : info?.areaId ? info?.areaId : ''
                                                }

                                            </div>
                                            <div
                                                className="pl-0 pb-1 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">

                                                <label className="text-black-50 m-1">Mã số thuế</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-1 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                                <span>{info?.tax}</span>
                                            </div>

                                            <div
                                                className="pl-0 pb-1 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">

                                                <label className="text-black-50 m-1">Số điện thoại</label>

                                            </div>
                                            <div
                                                className="pl-0 pb-1 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                                <span>{info?.phone}</span>
                                            </div>
                                        </div>

                                        <Grid container spacing={2} justify={"flex-center"}>
                                            <Grid item xs={12} sm={12} md={12} className='text-center'>
                                                {this.state.loading &&
                                                    <Button variant="outlined" color="primary"
                                                        className='mr-3'
                                                        style={{ textTransform: 'initial' }}
                                                    >
                                                        <CircularProgress size={20} variant="determinate"
                                                            value={this.state.progress} />
                                                    </Button>}
                                                {!this.state.loading &&
                                                    <Button
                                                        // disabled={!this.state.inEditing}
                                                        variant="contained"
                                                        color="primary"
                                                        className='mr-3'
                                                        style={{ textTransform: 'initial' }}
                                                        onClick={() => this.handleSave()}
                                                    >
                                                        Lưu
                                                </Button>}
                                                <Button variant="outlined" style={{ textTransform: 'initial' }}
                                                    onClick={() => this.handleReset()}
                                                >
                                                    Reset
                                                </Button>
                                            </Grid>
                                        </Grid>


                                        {/*{this.state.confirmCancel && <Dialog*/}
                                        {/*    open={true}*/}
                                        {/*    onClose={() => this.setState({confirmCancel: false})}*/}
                                        {/*>*/}
                                        {/*    <DialogTitle>Thông tin đã thay đổi, bạn có chắc muốn hủy ?</DialogTitle>*/}
                                        {/*    <DialogActions>*/}
                                        {/*        <Button*/}
                                        {/*            style={{textTransform: 'initial'}}*/}
                                        {/*            color="primary"*/}
                                        {/*            onClick={() => this.setState({*/}
                                        {/*                confirmCancel: false,*/}
                                        {/*                inEditing: false*/}
                                        {/*            }, () => this.handleCancel())}*/}
                                        {/*        >*/}
                                        {/*            Có*/}
                                        {/*        </Button>*/}
                                        {/*        <Button*/}
                                        {/*            color="primary"*/}
                                        {/*            style={{textTransform: 'initial'}}*/}
                                        {/*            onClick={() => this.setState({confirmCancel: false})}*/}
                                        {/*        >*/}
                                        {/*            Không*/}
                                        {/*        </Button>*/}
                                        {/*    </DialogActions>*/}
                                        {/*</Dialog>}*/}
                                    </CardContent>
                                }
                            </Card>
                            <ModalUploadAvatar show={this.state.modalUploadAvatar} onHide={this.modalUploadAvatarClose} />
                            <ModalChangePassword show={this.state.showModalChangePassword}
                                onHideModal={this.onCloseModalForgotPassword} />
                            <ModalConfirmEdit show={this.state.confirmCancel}
                                onHide={() => this.setState({ confirmCancel: false })}
                                handleCancel={
                                    () => this.setState({
                                        confirmCancel: false,
                                        inEditing: false
                                    }, () => this.handleCancel())
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompleteProfile
