import React from 'react'
import { Link, Switch, Route, Redirect } from "react-router-dom";
import HomePage from "./homepage";
import AboutUs from "./about-us";
import ListSupervisor from "./supervisors";
import HomeDocuments from "./documents";
import HomeDocumentDetail from "./documents-detail";
import HomeNotifications from "./notification";
import HomeNews from "./news";
import DetailSupervisor from "./supervisor-landing-page";
import AccountantRegister from "./accountant-register";
import PartnerList from "./partner-list";
import ArticleDetail from "./news/detail";
import Step1 from "./service-plan/step1";
import Step2 from "./service-plan/step2";
import Step3 from "./service-plan/step3";
import Step4 from "./service-plan/step4";
import DieuKhoan from "../dieukhoan";
import KeToan from "./service/ketoan";
import Thue from "./service/thue";
import TCDN from "./service/tcdn";
import HTBC from "./service/htbc";

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {
    }

    render() {
        return(
            <Switch>
                {/*<Route path="/home" component={HomePage}/>*/}
                <Route path="/home/about-us" component={AboutUs} />
                <Route path="/home/supervisor" component={ListSupervisor} />
                <Route path="/home/partner" component={PartnerList} />
                <Route path="/home/supervisor-page" component={DetailSupervisor} />
                <Route path="/home/news" component={HomeNews} />
                <Route path="/home/detail" component={ArticleDetail} />
                <Route path="/home/join-with-us" component={AccountantRegister} />
                <Route path="/home/select-plan-step-1" component={Step1} />
                <Route path="/home/select-plan-step-2" component={Step2} />
                <Route path="/home/select-plan-step-3" component={Step3} />
                <Route path="/home/select-plan-step-4" component={Step4} />
                <Route path="/home/dich-vu-ke-toan" component={KeToan} />
                <Route path="/home/dich-vu-thue" component={Thue} />
                <Route path="/home/tu-van-tai-chinh-doanh-nghiep" component={TCDN} />
                <Route path="/home/soat-xet-va-hoan-thien-bao-cao-tai-chinh" component={HTBC} />
                <Route path="/home/document-detail" component={HomeDocumentDetail} />
                <Route path="/home/documents" component={HomeDocuments} />
                <Route path="/home/portal/notification" component={HomeNotifications} />
            </Switch>
        )
    }
}

export default Home
