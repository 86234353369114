import React from 'react'
import {Button, Card, Form, Table} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import AdminUserManagement from '../../../services/ob-core/admin-user-management'
import RoleApi from '../../../services/ob-core/admin-role'
import Autosuggestion from "../../../components/Form/Autosuggestion";
import {toast} from "react-toastify";

import ModalAddNew from '../../../components/Modal/modalCreateUser'
import ModalBlock from '../../../components/Modal/modalBlockUser'
import ModalUnblock from '../../../components/Modal/admin/modalUnblockAdminUser'
import ModalDel from '../../../components/Modal/modalDelUser'
import ModalDetail from '../../../components/Modal/modalDetailUser'
import ModalUpdate from '../../../components/Modal/modalUpdateUser'
import ModalResetPass from '../../../components/Modal/modalResetPassUser'
import SecureCustomerApi from "../../../services/ob-user/secure-customer";

class UserManagement extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            page:0,
            pageNumber: 0,

            pageSize: 10,
            totalItem:'',

            listData: [],
            fullName: "",
            clear: false,
            sort: '',
            descending: 'DESC',

            role:'',
            status:'',
            role_temp:'',
            status_temp:'',

            itemSelected:'',
            nameUser:'',

            showModalAddNew: false,
            showModalBlock: false,
            showModalUnblock: false,
            showModalEdit: false,
            showModalDelete: false,
            showModalDetail:false,
            showModalUpdate:false,
            showModalReset:false,
            showLoading: false,

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'full_name',
                    name: "Tên User",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: true,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'position',
                    name: "Chức vụ",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'email',
                    name: "Email",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    // id: 'beforeTransaction',
                    name: "Thao tác",
                    style: {minWidth: 200, textAlign: "center"},
                    sort: false
                }
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: false}, () => {
            this.getList();
            // this.getListRole()
        })
    }

    getList = () => {
        let {pageNumber, pageSize, status, role, sort, descending} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            fullName: this.state.fullName,
            // descending: descending
        };
        SecureCustomerApi.adminCustomerSearch(payload, res => {
            if(res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                })
            }
        })
    }

    // getListRole = () => {
    //     let{page, pageSize, roleSearch} = this.state;
    //     let payload = {
    //         pageNumber: page,
    //         pageSize: pageSize,
    //         roleName: roleSearch,
    //     };
    //     SecureCustomerApi.adminCustomerSearch(payload, res => {
    //         if(res.success) {
    //             this.setState({
    //                 listRole: res.data.content,
    //             })
    //         }
    //     })
    // }

    onSearch = () => {
        this.setState({
                fullName: this.state.fullName,
                pageNumber: 0,
            },
            () => {this.getList()}
        )
    }

    onRefresh = () => {
        this.setState({
            fullName: "",
            clear:true,
            pageNumber: 0,
        }, ()=> this.onSearch())
    }

    renderPermission = (item) => {
        switch (item) {
            case 1:
                return 'Admin';
            case 2:
                return 'Partner';
            case 3:
                return 'Supervisor';
            case 4:
                return 'Accountant';
            case 5:
                return 'Customer';
        }
    }

    renderStatus = (item) => {
        switch (item) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getList()
            }
        );
    }


    render() {
        let {pageNumber, pageSize}  = this.state
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="user-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý User"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Họ và tên</label>
                                                {/*<Autosuggestion*/}
                                                {/*    // label="Supervisor"*/}
                                                {/*    className="form-control kt-input pd-4-input shadow-none"*/}
                                                {/*    placeholder="Nhập họ tên"*/}
                                                {/*    // value={this.state.roleSearch}*/}
                                                {/*    type="text"*/}
                                                {/*    clearSearch={this.state.clear}*/}
                                                {/*    data={this.state.listRole}*/}
                                                {/*    onSelect={(item) => this.setState({role_temp: item.id})}*/}
                                                {/*    onChange={(val) => {*/}
                                                {/*        this.setState({roleSearch: val, clear: false})*/}
                                                {/*        setTimeout(() => {*/}
                                                {/*            this.getListRole()*/}
                                                {/*        }, 300);*/}
                                                {/*    }}*/}
                                                {/*    // checkError={!this.state.supervisorList}*/}
                                                {/*    // errorContent="Vui lòng chọn một Supervisor"*/}
                                                {/*/>*/}
                                                <input type="text"
                                                       className={"form-control kt-input pd-4-input shadow-none"}
                                                       placeholder='Nhập tên user'
                                                       value={this.state.fullName}
                                                       maxLength={20}
                                                       onChange={(val) => {
                                                          this.setState({
                                                              fullName: val.target.value
                                                          })
                                                       }}
                                                />
                                            </div>

                                        </div>
                                        <div className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >Refresh
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({showModalAddNew:true})
                                                        }}
                                                >
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                {/*        <Table striped bordered hover>*/}
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = "";
                                            let descend = "";
                                            if (data.sortAsc == null) {
                                                // a = "id=asc";
                                                a = "";
                                                // descend = 'DESC'
                                            } else {
                                                a = data.sortAsc ? "fullName=asc" : "fullName=desc";
                                                // descend = data.sortAsc ? 'ASC' : 'DESC'
                                            }
                                            this.setState(
                                                {
                                                    sort: a,
                                                    descending: descend
                                                }, () => this.getList())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            :
                                            <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.position}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.email || "Không có dữ liệu"}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td className='p-1' style={{textAlign: 'center'}}>
                                                        <button className="btn btn-action p-2" title="Chi tiết"
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row.id}, () => this.setState({showModalDetail: true}))
                                                                }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action p-2"
                                                            disabled={row.isAdminCustomer}
                                                            title={row.status === "LOCK" ? "Mở khóa" : "Khóa" }
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => {
                                                                    if (row.status === "LOCK") {
                                                                        this.setState({showModalUnblock: true})
                                                                    } else {
                                                                        this.setState({showModalBlock: true})
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <i className={row.status === "LOCK" ? "ti-unlock" :"ti-lock"}/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action p-2"
                                                            disabled={row.isAdminCustomer}
                                                            title="Xóa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showModalDelete: true}))
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>

                                                        </button>
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title="Sửa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showModalUpdate: true}))
                                                            }}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title="Đổi mật khẩu"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id, nameUser: row.fullName}, () => this.setState({showModalReset: true}))
                                                            }}
                                                        >
                                                            <i className="ti-reload"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*</Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <ModalAddNew
                    show={this.state.showModalAddNew}
                    onRefresh={() => this.onSearch()}
                    onHide={() => this.setState({showModalAddNew:false})}
                />
                <ModalUnblock
                    show={this.state.showModalUnblock}
                    onHide={() => this.setState({showModalUnblock:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalBlock
                    show={this.state.showModalBlock}
                    onHide={() => this.setState({showModalBlock:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalDel
                    show={this.state.showModalDelete}
                    onHide={() => this.setState({showModalDelete:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalDetail
                    show={this.state.showModalDetail}
                    onHide={() => this.setState({showModalDetail:false})}
                    id={this.state.itemSelected}
                />
                {this.state.showModalUpdate && <ModalUpdate
                    show={this.state.showModalUpdate}
                    onHide={() => this.setState({showModalUpdate:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />}
                <ModalResetPass
                    show={this.state.showModalReset}
                    onHide={() => this.setState({showModalReset:false})}
                    onRefresh={() => this.onRefresh()}
                    id={this.state.itemSelected}
                    name={this.state.nameUser}
                />
            </div>
        )
    }

}

export default UserManagement
