import React from 'react';
import '../style.css';
import './style.css';
import {Link} from "react-router-dom";
import Convert from "../../../../utils/convertUrlPra";
import NumberFormat from "react-number-format";
import PublicSupervisorApi from "../../../../services/ob-user/public-supervisor";
import PublicServicePlan from "../../../../services/ob-core/public-service-plan";
import AdminServiceQuotation from "../../../../services/ob-core/admin-service-quotation";
import PublicServiceQuotation from "../../../../services/ob-core/public-service-quotation";
import PublicOrderApi from "../../../../services/ob-core/public-order-service";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import FormInput from "../../../../components/Form/input";
import DatePicker from "react-datepicker";
import moment from "moment";
import Utils from "../../../../utils/utils";
import OrderServiceBaseApi from "../../../../services/ob-core/order-service-base";
import Step2 from "../step2";

class Step4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            supervisorId: 0,
            listProgram: [],
            dataSupervisor: [],
            serviceId: 0,
            quotationId: 0,
            dataServicePlan: {},
            dataServiceQuotation: {},
            business: '',
            priceType: '',
            type: '',
            userInfo: '',
            requirementConstraint: '',
            showNotify: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let params = Convert.urlParams(this.props.location.search);
        if (params.numT === "1"){
            params.numT = "6";
            params.p    = (params?.p * 1)*(params.numT*1)
        }
        this.setState({
            data: params,
            serviceId: params.s?.split("-")[0] * 1,
        }, () =>{
            this.calculatePrice();
        });
        var userInfo = JSON.parse(localStorage.getItem('ob_cl_userInfo')) ||"";
        this.setState({
            userInfo : userInfo
        });
        if(params.epyt === 'BASIC') {
            this.setState({
                business: params?.b,
                totalPrice: params?.p * 1,
                totalMonth: params?.numT * 1,
                supervisorId: params.sup?.split("-")[0] * 1,
                priceType: params?.t,
                type: params?.epyt,
                quotationId: params?.plan*1 || 0,
                showLoading: true
            }, () => {
                this.getNameSupervisor();
                this.getService();
                this.getServiceQuotation();
            })
        } else if(params.epyt === 'ADVANCED') {
            this.setState({
                serviceId: params.s?.split("-")[0] * 1,
                type: params?.epyt,
                showLoading: true
            }, () => {
                this.getService();
            })
        }

    }
    calculatePrice = () => {
        let {data} = this.state;
        let payload = {
            numberOfDocumentsConstraintId: data?.numD*1 || 0,
            numberOfEmployeesConstraintId: data?.numE*1 || 0,
            numberOfInvoicesConstraintId: data?.numI*1 || 0,
            numberOfMonths: data?.numT*1 || 0,
            servicePlanId: this.state.serviceId
        }
        OrderServiceBaseApi.calculatePrice(payload, res => {
            if (res.success) {
                this.setState({
                    totalPrice: res.data.deal,
                    oldPrice: res.data.price,
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi khi tính giá cước!");
            }
        })
    }
    renderDiscountType = (statusType) => {
        switch (statusType) {
            case 1:
                return 'Giảm %';
            case 2:
                return 'Giảm giá trị';
            default:
                return '';
        }
    };
    getNameSupervisor = () => {
        let payload = {
            ids: [this.state.supervisorId]
        }
        PublicSupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                this.setState({
                    dataSupervisor: res.data.list,
                    showLoading: false
                })
            }
        })
    }

    getService = () => {
        PublicServicePlan.getAll(res => {
            if (res.success) {
                let data = res.data;
                let servicePlan = data.filter(item => item.id === this.state.serviceId)[0]
                this.setState({
                    dataServicePlan: servicePlan,
                    showLoading: false
                })
            }
        })
    }

    getServiceQuotation = () => {
        let payload = {
            id: this.state.serviceId
        }
        PublicServiceQuotation.getByServicePlan(payload, res => {
            if (res.success) {
                let data = res.data
                let dataServiceQuotation
                if(this.state.quotationId) {
                    dataServiceQuotation = data.filter(item => item.id === this.state.quotationId)[0]
                }
                this.setState({
                    dataServiceQuotation,
                    showLoading: false
                })
            }
        })
    }

    onSubmit = () => {
        window.scrollTo(0, 0);
        let {type, serviceId, data, business, quotationId, supervisorId} = this.state;
        this.setState({
            showLoading: true
        })
        if(type === 'ADVANCED'){
            let payload = {
                price: 0,
                servicePlanId: serviceId
            }
            PublicOrderApi.createAdvance(payload, res => {
                if (res.success) {
                    this.setState({
                        showNotify: true,
                        showLoading: false
                    })
                    toast.success("Đăng ký dịch vụ thành công!")
                }
                else {
                    this.setState({
                        showLoading: false
                    })
                    toast.error("Đăng ký dịch vụ không thành công! Vui lòng thử lại")
                }
            })
        } else if (type === 'BASIC'){
           let payload = {
                business: business,
                numberOfDocumentsConstraintId: data?.numD*1 || 0,
                numberOfEmployeesConstraintId: data?.numE*1 || 0,
                numberOfInvoicesConstraintId: data?.numI*1 || 0,
                numberOfMonths: data?.numT*1 || 0,
                servicePlanId: serviceId,
                socialInsuranceConstraintId: data?.plan*1 || 0,
                serviceQuotationId: quotationId || 0,
                supervisorId: supervisorId
            }
            PublicOrderApi.create(payload, res => {
                if (res.success) {
                    this.setState({
                        showNotify: true,
                        showLoading: false
                    })
                    toast.success("Đăng ký dịch vụ thành công!")
                }
                else {
                    this.setState({
                        showLoading: false
                    })
                    toast.error("Đăng ký dịch vụ không thành công! Vui lòng thử lại")
                }
            })
        }
    }

    renderBusiness = (business) => {
        switch (business) {
            case 'COMMERCE_SERVICE':
                return '- Thương mại \n- Dịch vụ \n- Đầu tư tài chính';
            case 'MANUFACTURING_MACHINING':
                return '- Sản xuất \n- Gia công';
            case 'BUILDING_INSTALLATION':
                return '- Xây dựng \n- Lắp đặt \n- Đầu tư bất động sản';
            case '':
                return '';
        }
    }

    render() {
        const {dataSupervisor, dataServicePlan, business, type, dataServiceQuotation, userInfo} = this.state;
        return (
            <div className='stepper-wrapper container'>
                <div className='stepper-router'>
                    <a href=''>
                        Trang chủ / <strong>Đăng ký dịch vụ</strong>
                    </a>
                </div>
                {this.state.showLoading &&
                <div className="content-loading col-12 ml-3 align-items-center"
                     style={{display: 'block'}}>
                    <div className="spinner-grow text-info"/>
                </div>
                }
                <ul className="step-top d-flex align-items-center justify-content-center">
                    <li className="step step-1">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                1
                            </span>
                        </div>
                        <span className="text">
                            Chọn dịch vụ
                        </span>
                    </li>
                    <li className="step step-2">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                2
                            </span>
                        </div>
                        <span className="text">
                            Chọn gói cước
                        </span>
                    </li>
                    <li className="step step-3">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                3
                            </span>
                        </div>
                        <span className="text">
                            Chọn supervisor
                        </span>
                    </li>
                    <li className="step step-4 active">
                        <div className="step-number">
                            4
                            <span className="sub-number">
                                4
                            </span>
                        </div>
                        <span className="text">
                            Xác nhận
                        </span>
                    </li>
                </ul>
                <h2 className='stepper-title step-title-large app-separator'>{type === 'BASIC' ? '4. Xác nhận' : '2. Xác nhận'}</h2>
                <div className="col-12">
                    <h3 className="stepper-subtitle text-center font-26 fontW-bold">Xác nhận đặt dịch vụ</h3>
                    <p className="text-center font-20 mt-3">Vui lòng kiểm tra lại các thông tin đã lựa chọn. <br/>Hệ thống sẽ liên hệ trong thời gian 24 tiếng sau khi xác nhận đặt dịch vụ.</p>
                </div>
                <div className='stepper-content app-separator max-1088'>
                    {/*<div className='step4-confirm-text text-center'>*/}
                    {/*    <h5 className='text-bold'>Xác nhận đặt dịch vụ</h5>*/}
                    {/*    <p className='mb-0 mt-3'>*/}
                    {/*        Vui lòng kiểm tra lại các thông tin đã lựa chọn.*/}
                    {/*    </p>*/}
                    {/*    <p>*/}
                    {/*        Hệ thống sẽ liên hệ trong thời gian 24 tiếng sau khi xác nhận đặt*/}
                    {/*        dịch vụ.*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className='step4-confirm-show my-5 box-shadow-secondary'>
                        <div className='step4-form-title text-center'>CHI TIẾT HỢP ĐỒNG</div>
                        <div className='step4-form-content'>
                            <div className="ct-payment-contract">
                                <div className="contract-title font-26 mb-3" style={{ fontSize: 20, fontWeight: 600 }}>
                                    <span className="font-24">THÔNG TIN CÔNG TY </span>
                                    <div className="font-16 ml-4 mr-4">
                                        <div className="row">
                                            <div className='row mt-3 col-6'>
                                                <div  className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">Tên công ty</span>
                                                </div>
                                                <div className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    <span>{userInfo.companyName}</span>
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div  className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Mã số thuế</span>
                                                </div>
                                                <div className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.tax}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">Email</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    {userInfo.companyEmail}
                                                </div>
                                            </div>

                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Số điện thoại</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.companyPhone}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-md-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">Địa chỉ</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    {userInfo.companyAddress}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-md-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Website</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.website}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }} className="ct-report-info">
                                <div className="contract-title font-26 mb-3" style={{ fontSize: 20, fontWeight: 600 }}>
                                    <span className="font-24">THÔNG TIN NGƯỜI ĐẠI DIỆN </span>
                                    <div className="font-16 ml-4 mr-4">
                                        <div className="row">
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Người đại diện</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.fullName}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">Chức vụ</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    {userInfo.position}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Giới tính</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.sex === "FEMALE"?"Nữ": "Nam"}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">CMND/CCCD</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    {userInfo.identify}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Số điện thoại</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.phone}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                            <span className="text-black-50">Ngày cấp</span>
                                                </div>

                                                <div className="pl-0 pb-2 col-9 kt-margin-b-10-tablet-and-mobile">
                                                    <span>{moment(userInfo.dateIssued).format("DD-MM-YYYY")}</span>
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                    <span className="text-black-50">Email</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                    {userInfo.email}
                                                </div>
                                            </div>
                                            <div className='row mt-3 col-6'>
                                                <div
                                                    className=" pl-0 col-md-3 col-lg-3 col-sm-3">
                                                    <span className="text-black-50">Nơi cấp</span>
                                                </div>
                                                <div
                                                    className=" pl-0 col-md-9 col-lg-9 col-sm-9">
                                                    {userInfo.placeIssued}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 20, fontWeight: 600 }} className="ct-contract-info">
                                <div className="contract-title font-24 mb-3" style={{ fontSize: 20 }}>
                                    <span className="font-24">CHI TIẾT ĐƠN HÀNG </span>
                                </div>
                                {dataSupervisor[0] &&
                                <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Tên Người Phụ Trách</div>
                                    <div
                                        className='col-5 text-left'>{dataSupervisor[0]?.fullName || 'Không có dữ liệu'}</div>
                                </div>}
                                <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Dịch vụ</div>
                                    <div className='col-5 text-left'>{dataServicePlan.servicePlanName}</div>
                                </div>
                                {type === 'BASIC' && (business || dataServiceQuotation) &&
                                <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Gói dịch vụ</div>
                                    <div  className='col-5 text-left' dangerouslySetInnerHTML={{__html: business ? this.renderBusiness(business).replaceAll("\n", "</br>") : dataServiceQuotation?.requirementConstraint.replaceAll("\n", "</br>")}}/>
                                </div>}
                                {this.state.totalPrice !== 0 &&
                                <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Số tiền (VNĐ)/1 tháng</div>
                                    <div className='col-5 text-left'>
                                        <NumberFormat
                                            value={this.state.oldPrice/this.state.totalMonth}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                </div>}
                                {this.state.totalPrice !== 0 && <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Số kỳ đăng ký</div>
                                    <div className='col-5 text-left'>
                                        {this.state.totalMonth + " tháng"}
                                    </div>
                                </div>}

                                {this.state.totalPrice !==0 && this.state.oldPrice !== 0 && <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Tổng tiền</div>
                                    <div className='col-5 text-left'>
                                        <NumberFormat
                                            style={{
                                                textDecorationLine: "line-through",
                                                color: "#e80020",
                                                fontWeight: "bold",
                                                fontSize: 17
                                            }}
                                            value={this.state.oldPrice || ""}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={" đ"}
                                        />
                                    </div>
                                </div>}
                                {this.state.listProgram.length > 0 &&
                                <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Giảm giá</div>
                                    <div className='col-5 text-left'>
                                        <NumberFormat
                                            style={{
                                                color: "#007bff",
                                                fontWeight: "bold",
                                                fontSize: 17
                                            }}
                                            value={this.state.listProgram[0].discountValue || ""}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={this.state.listProgram[0].discountType ==1?" %": " đ"}
                                        />
                                    </div>
                                </div>}
                                {this.state.totalPrice !== 0 && <div className='row mb-3 justify-content-md-center font-24'>
                                    <div className='col-5 text-left color-main fontW-semibold'>Thanh toán</div>
                                    <div className='col-5 text-left'>
                                        <NumberFormat
                                            value={this.state.totalPrice || ""}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={" đ"}
                                        />
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
                <div className='btn-redirect btn-bottom btn-step4'>
                    <Link
                        to={{
                            pathname: type === 'BASIC' ? '/home/select-plan-step-3' : '/home/select-plan-step-1',
                            search: `${this.props.location.search}`
                        }}
                    >
                        <button className='btn btn-cancel-stepper'>Hủy</button>
                    </Link>
                    <button
                        className='btn btn-next-stepper'
                        onClick={() => this.onSubmit()}
                    >
                        Xác nhận
                    </button>
                </div>

                {this.state.showNotify &&
                <Modal show={true} keyboard={false} backdrop='static' dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div className='mt-12 mb-12 text-center'>
                            <div>
                                <div style={{fontSize: 20, padding: 8}}>THÔNG TIN THANH TOÁN</div>
                                <div style={{fontSize: 15}}>
                                    <div className='row'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-12 col-lg-12 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black"><b>Onebook</b> xin chân thành cảm ơn <b>Quý khách</b></label>
                                            <label className="text-black">đã tin tưởng và sử dụng dịch vụ.</label>
                                            <label className="text-black">Quý khách vui lòng chuyển khoản tiền tới số tài khoản bên dưới để thanh toán dịch vụ</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black">Tổng số tiền</label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {/*<span style={{fontWeight: "bold"}}> {listData.customerName}</span>*/}
                                            <span
                                                style={{fontWeight: "bold"}}>
                                                <NumberFormat
                                                    style={{
                                                        color: "#e80020",
                                                        fontWeight: "bold",
                                                        fontSize: 17
                                                    }}
                                                    value={this.state.totalPrice}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    suffix={" đ"}
                                                />
                                            </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black">Số tài khoản </label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {/*<span style={{fontWeight: "bold"}}> {listData.customerName}</span>*/}
                                            <span
                                                style={{fontWeight: "bold"}}> 09123659201</span>
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black">Ngân hàng  </label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        <span
                                                            style={{fontWeight: "bold"}}>Ngân hàng TMCP Tiên Phong</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black">Chi nhánh </label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                       <span
                                                           style={{fontWeight: "bold"}}>CN Thăng Long</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black">Tổng đài hỗ trợ </label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                       <span
                                                           style={{fontWeight: "bold"}}>1900000001</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{float: "left"}} className='text-center cursor-pointer'>
                            <Link
                                to={{
                                    pathname: '/customer/list-ordered-service',
                                }}
                            >
                                <button
                                    className='btn btn-can-tu-van color-white bg-gray'
                                    onClick={() =>  this.props.history.push("/customer/list-ordered-service")}
                                >
                                    Cần tư vấn thêm
                                </button>
                            </Link>
                        </div>
                        <div style={{float: "right"}} className='text-center cursor-pointer'>
                            <Link
                                to={{
                                    pathname: '/customer/list-ordered-service',
                                }}
                            >
                                <button
                                    className='btn btn-da-chuyen-khoan color-white bg-green'
                                    onClick={() =>  this.props.history.push("/customer/list-ordered-service")}
                                >
                                    Đã chuyển khoản
                                </button>
                            </Link>
                        </div>
                    </Modal.Body>
                </Modal>}
            </div>
        );
    }
}

export default Step4;
