import React from 'react';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import Utils from '../../../utils/utils';

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function onlyNumber(value) {
    return  Utils.filterNumberOnly(value);
}

class modalEditEmployee extends React.Component {
    constructor(props) {
        super(props);
        let listData = props.data.requirementConstraint.split(" - ");
        this.state = {
            showCreate: true,
            type: props.data.type,
            commerceId: props.data.commerceId,
            manufacturingId: props.data.manufacturingId,
            buildingId: props.data.buildingId,
            servicePlanId: props.data.servicePlanId,
            start: listData[0].replace(/ /g,''),
            end: listData[1].replace(/ /g,''),
            price: '',
            commercePrice: props.data.commercePrice,
            manufacturingPrice: props.data.manufacturingPrice,
            buildingPrice: props.data.buildingPrice,
        };
    }

    onSubmit = () => {
        let {start, end, commercePrice, manufacturingPrice, buildingPrice, commerceId, manufacturingId, buildingId, servicePlanId} = this.state;
        let list =  [
            {
                business: "COMMERCE_SERVICE",
                price: commercePrice,
                requirementConstraint: `${start} - ${end}`,
                status: "DISABLED",
                type: "EMPLOYEE",
                servicePlanId: servicePlanId,
                id: commerceId,
            },
            {
                business: "MANUFACTURING_MACHINING",
                price: manufacturingPrice,
                requirementConstraint: `${start} - ${end}`,
                status: "DISABLED",
                type: "EMPLOYEE",
                servicePlanId: servicePlanId,
                id: manufacturingId,
            },
            {
                business: "BUILDING_INSTALLATION",
                price: buildingPrice,
                requirementConstraint: `${start} - ${end}`,
                status: "DISABLED",
                type: "EMPLOYEE",
                servicePlanId: servicePlanId,
                id: buildingId,
            },
        ]

        let item = {
            business: "COMMERCE_SERVICE",
            commercePrice: commercePrice,
            manufacturingPrice: manufacturingPrice,
            buildingPrice: buildingPrice,
            commerceId: commerceId,
            manufacturingId: manufacturingId,
            buildingId: buildingId,
            servicePlanId: servicePlanId,
            requirementConstraint: `${start} - ${end}`,
            type: "EMPLOYEE"
        };
        this.props.create(list, item)
        this.setState({start: '', end: '', commercePrice: '', manufacturingPrice: '', buildingPrice: '', commerceId: '', manufacturingId: '', buildingId: '', servicePlanId: ''})
    }

    render() {
        const modalProp = {
            show: this.props.show,
            // onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>SỬA NHÂN SỰ</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-2 pl-0">
                                    <label className="col-5 label-item pl-0">Số lượng nhân sự</label>
                                    <input
                                        maxLength={3} type="text"
                                        className="form-control shadow-none"
                                        value={this.state.start}
                                        onChange={(event) => {
                                            this.setState({
                                                start: onlyNumber(event.target.value)
                                            })
                                        }}
                                    />
                                    <label className="col-1 label-item pl-1">đến</label>
                                    <input
                                        maxLength={3} type="text"
                                        className="form-control shadow-none"
                                        value={this.state.end}
                                        onChange={(event) => {
                                            this.setState({
                                                end: onlyNumber(event.target.value)
                                            })
                                        }}
                                    />
                                </div>
                                <label className="col-5 label-item pl-0">Giá gói cước (VNĐ)</label>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-5 label-item pl-0">Thương mại</label>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className="form-control shadow-none"
                                        value={formatMoney(this.state.commercePrice)}
                                        onChange={(event) => {
                                            this.setState({
                                                commercePrice: onlyNumber(event.target.value.trim())
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-5 label-item pl-0">Sản xuất</label>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className="form-control shadow-none"
                                        value={formatMoney(this.state.manufacturingPrice)}
                                        onChange={(event) => {
                                            this.setState({
                                                manufacturingPrice: onlyNumber(event.target.value.trim())
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-5 label-item pl-0">Xây dựng</label>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className="form-control shadow-none"
                                        value={formatMoney(this.state.buildingPrice)}
                                        onChange={(event) => {
                                            this.setState({
                                                buildingPrice: onlyNumber(event.target.value.trim())
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    disabled={this.state.start.length === 0 || this.state.end.length === 0 || this.state.price.commercePrice === 0 || this.state.price.manufacturingPrice === 0 || this.state.price.buildingPrice === 0}
                                    onClick={() => this.onSubmit()}
                                >
                                    Đồng ý
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                    Hủy bỏ
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default modalEditEmployee