import React from "react";
import {Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import PublicFilesApi from "../../services/resource/public-files";
import FilesApi from "../../services/resource/files";
import {toast} from "react-toastify";
import SupervisorApi from "../../services/ob-user/supervisor";

class ModalSetTopSupervisor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
    }

    componentDidMount() {
        console.log("chekc props>>>>", this.props.selectedSupervisor);
    }

    onDrop = acceptedFiles => {
        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        })
    }

    uploadFileServer = (e) => {
        if(this.state.files.length > 0){
            // e.preventDefault();
            this.setState({messageError: false});
            let payload = {
                file: this.state.files
            }
            FilesApi.uploadPublicFile(payload, res => {
                this.setState({fileUploaded: res.data}, ()=> {
                    this.updateImage()
                })

            } ).catch(error => console.log("upload error>>>", error))
        } else {
            toast.error("Vui lòng chọn ảnh trước khi upload!")
        }
    }

    updateImage = () => {
        let payload = {
            image: this.state.fileUploaded.storageName,
            userId: this.props.selectedSupervisor.id
        }
        SupervisorApi.updateHighlightSupImg(payload, res => {
            if (res.success){
                if(this.props.modalType === 'highlight') {
                    this.setHighlight()
                } else {
                    this.props.onRefresh()
                    toast.success("Cập nhật ảnh Supervisor nổi bật thành công!")
                }
            }
        })
    }

    setHighlight = () => {
        let payload = {
            id: this.props.selectedSupervisor.id
        }
        SupervisorApi.setHighlight(payload, res => {
            if (res.success){
                this.props.onRefresh()
                toast.success("Cập nhật Supervisor nổi bật thành công!")
            }
        })
    }

    setUnHighlight = () => {
        let payload = {
            id: this.props.selectedSupervisor.id
        }
        SupervisorApi.setUnHighlight(payload, res => {
            if (res.success){
                this.props.onRefresh()
                toast.success("Gỡ Supervisor nổi bật thành công!")
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='m-2'>
                            <label>{this.props.modalType === "highlight" ? "Để đánh dấu nổi bật cho Supervisor, vui lòng tải lên ảnh đại diện để hiển thị ra Trang chủ" : this.props.modalType === "edit" ? "Chọn ảnh nổi bật mới sẽ hiển thị ra Trang chủ" : "Bạn có muốn bỏ đánh dấu nổi bật cho Supervisor này không?"}</label>
                        </div>
                        {this.props.modalType === "highlight" &&
                        <div className="mt-4">
                            <div className="form-group mx-0 mb-4">
                                <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                    Vui lòng chỉ sử dụng ảnh nền trong suốt, có định dạng PNG.
                                </div>
                                <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                                    {({getRootProps, getInputProps}) => (
                                        <section className="container-upload">
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                {files.length > 0 ? <div className="align-center">
                                                    <div className="img-wh120-wrapper"><img
                                                        src={this.state.files[0].preview} alt="" className="img-h120"/>
                                                    </div>
                                                    <ul>{files}</ul>
                                                </div> : <p>Chọn file ảnh</p>}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        }
                        {this.props.modalType === "edit" &&
                        <div className="mt-4">
                            <div className="form-group mx-0 mb-4">
                                <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                    Vui lòng chỉ sử dụng ảnh nền trong suốt, có định dạng PNG.
                                </div>
                                <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                                    {({getRootProps, getInputProps}) => (
                                        <section className="container-upload">
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                {files.length > 0 ? <div className="align-center">
                                                    <div className="img-wh120-wrapper"><img
                                                        src={this.state.files[0].preview} alt="" className="img-h120"/>
                                                    </div>
                                                    <ul>{files}</ul>
                                                </div> : <p>Chọn file ảnh</p>}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        }
                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            if(this.props.modalType === "highlight") {
                                                this.uploadFileServer()
                                            } else if(this.props.modalType === "unset") {
                                                this.setUnHighlight()
                                            } else {
                                                this.uploadFileServer()
                                            }
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalSetTopSupervisor
