import React, {Component} from "react";
import {withSnackbar} from "notistack";

import NumberFormat from "react-number-format";
import {
    Grid, Card, CardActions, CardHeader, NativeSelect,
    CardContent, CardMedia, Button, Typography, InputLabel
} from "@material-ui/core";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";

// import SelectPlan from "../../../utils/modal/SelectPlan";

import PublicRequirementConstraintApi from "../../../../services/ob-core/public-requirement-constraint";
import PublicServiceQuotationApi from "../../../../services/ob-core/public-service-quotation";
import {toast} from "react-toastify";
import AdminServiceQuotation from "../../../../services/ob-core/admin-service-quotation";
import PublicOrder from "../../../../services/ob-core/public-order-service";

const business = [
    {
        type: "COMMERCE_SERVICE",
        name: 'Thương mại - Dịch vụ - Đầu tư tài chính'
    },
    {
        type: "MANUFACTURING_MACHINING",
        name: 'Sản xuất - Gia công',
    },
    {
        type: "BUILDING_INSTALLATION",
        name: 'Xây dựng - Lắp đặt - Đầu tư bất động sản'
    }
]


class ChoosePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: null,
            business: "COMMERCE_SERVICE",
            planPrice: [],
            servicePlanQuotationId: null,
            requirementConstraint: [],
            selectData: [],
            selectedBusiness: null,
            billSelected: 0,
            documentSelected: 0,
            periodSelected: 0,
            employeeSelected: 0,
            selectedPlan: null,
            totalPrice: 0,
        };
    }

    componentWillMount = () => {
        this.props.showLoading()
        console.log(this.props);
        this.setState({serviceId: this.props.serviceId}, () => {
            if (this.props.servicePriceType == "PERMANENT") this.getByServicePlanId()
            else this.getAllServiceQuotation()
            // this.getAllPricePlan();
            // this.getRequirementConstraintOnMonth();
        })

    };


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.serviceId != this.props.serviceId) {
            this.setState({serviceId: this.props.serviceId}, () => {
                // this.getAllPricePlan()
            })

        }
    }

    selectBusiness = (value) => {
        this.props.showLoading()
        this.setState({business: value}, ()=> {
            this.getAllServiceQuotation()
        })
    }

    getByServicePlanId = () => {
        let payload = {
            id: this.props.serviceId
        }
        AdminServiceQuotation.getByServicePlan(payload, res => {
            if (res.success) {
                this.setState({planPrice: res.data})
                this.props.hideLoading()
            }else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!" + res.message)
            }
        })
    }


    getAllServiceQuotation = (callback) => {
        let payload = {
            business: this.state.business,
            servicePlanId: this.state.serviceId,
        }
        AdminServiceQuotation.getByServicePlanAndBusiness(payload, res => {
            if (res.success) {
                // console.log("response getAllServiceQuotation>>>>>>>", res);
                let selectData = {
                    invoice: res.data.filter((_item) => _item.type == "INVOICE"),
                    document: res.data.filter((_item) => _item.type == "DOCUMENT"),
                    employee: res.data.filter((_item) => _item.type == "EMPLOYEE"),
                }
                this.setState({listAllServices: res.data, selectData});
                this.props.hideLoading()
                // callback(res.data)
                // return res.data
            }else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!" + res.message)
                // callback(null)
            }
        });
    };

    calculatePrice = () => {
        let {business, numberOfDocumentsConstraintId, numberOfEmployeesConstraintId, numberOfInvoicesConstraintId, numberOfMonths, totalPrice} = this.state
        let payload  = {
            numberOfDocumentsConstraintId: numberOfDocumentsConstraintId || 0,
            numberOfEmployeesConstraintId: numberOfEmployeesConstraintId || 0,
            numberOfInvoicesConstraintId: numberOfInvoicesConstraintId || 0,
            numberOfMonths: numberOfMonths
        }
        PublicOrder.calculatePrice(payload, res => {
            if (res.success) {
                this.props.setPlanInfo(business, numberOfDocumentsConstraintId, numberOfEmployeesConstraintId, numberOfInvoicesConstraintId, numberOfMonths, res.data)
                this.setState({totalPrice: res.data})
                this.props.hideLoading()
            }else {
                toast.error("Đã xảy ra lỗi khi tính giá cước! " + res.message)
            }
        })
    }


    render() {
        let {planPrice, selectData, listAllServices} = this.state;
        let {serviceId} = this.state
        let self = this;
        return (
            <div>
                <h4 style={{marginBottom: 10}}>2. Chọn gói cước</h4>

                <div className="justify-space-between">
                    <div className=' justify-space-evenly'>
                        {this.props.servicePriceType != "PERMANENT" &&
                            <div className="row">
                                <div className="col-3">
                                    <span className=''>
                                        Loại hình công ty:
                                    </span>
                                </div>
                                <div className="col-3">
                                    <input className="form-check-input" type="radio"
                                           name="commerceRadio" id="commerce" value="COMMERCE_SERVICE"
                                           onClick={(val) => {
                                               this.selectBusiness(val.target.value)
                                               // this.setState({
                                               //     business: val.target.value,
                                               // })
                                           }}
                                           checked={this.state.business === 'COMMERCE_SERVICE'}
                                    />
                                    <label className="form-check-label"
                                           htmlFor="exampleRadios1">
                                        Thương mại - Dịch vụ - Đầu tư tài chính
                                    </label>
                                </div>
                                <div className="col-3">
                                    <input className="form-check-input" type="radio"
                                           name="manufacturingRadio" id="manufacturing" value="MANUFACTURING_MACHINING"
                                           onClick={(val) => {
                                               this.selectBusiness(val.target.value)
                                               // this.setState({
                                               //     business: val.target.value,
                                               // })
                                           }}
                                           checked={this.state.business === 'MANUFACTURING_MACHINING'}
                                    />
                                    <label className="form-check-label"
                                           htmlFor="exampleRadios1">
                                        Sản xuất - Gia công

                                    </label>
                                </div>
                                <div className="col-3">
                                    <input className="form-check-input" type="radio"
                                           name="buildingRadio" id="building" value="BUILDING_INSTALLATION"
                                           onClick={(val) => {
                                               this.selectBusiness(val.target.value)
                                           }}
                                           checked={this.state.business === 'BUILDING_INSTALLATION'}
                                    />
                                    <label className="form-check-label"
                                           htmlFor="exampleRadios1">
                                        Xây dựng - Lắp đặt - Đầu tư bất động sản
                                    </label>
                                </div>

                                {/*<select*/}
                                {/*    value={this.state.business}*/}
                                {/*    onChange={(val) => this.setState({business: val.target.value})}*/}
                                {/*    className="form-control kt-input pd-4-input ">*/}
                                {/*    <option value="COMMERCE_SERVICE">Thương mại - Dịch vụ - Đầu tư tài chính</option>*/}
                                {/*    <option value="MANUFACTURING_MACHINING">Sản xuất - Gia công</option>*/}
                                {/*    <option value="BUILDING_INSTALLATION">Xây dựng - Lắp đặt - Đầu tư bất động sản*/}
                                {/*    </option>*/}
                                {/*</select>*/}
                            </div>
                            }
                        {/*{serviceId != 3 ?*/}
                        {/*    listAllServices?.map((item, index) => {*/}
                        {/*        return (*/}
                        {this.props.servicePriceType != "PERMANENT" ?
                                    <div className='col-12  pt-4'>
                                        <div className="justify-center">
                                            <div className="service-plan-div">
                                                {/*<div className='card border-card-plan'>*/}
                                                    {/*<div*/}
                                                    {/*    className='d-flex justify-content-center font-weight-bold mt-2 mb-2'>*/}
                                                    {/*    {item.businessName.toUpperCase()}*/}
                                                    {/*</div>*/}
                                                    <div className="pb-4">
                                                        <div className='pt-2 justify-content-center'>
                                                            <div className='row align-items-center'>
                                                                <div className="col-6 p-0 row">
                                                                    <div className="col-3 p-0">
                                                                        <span className='width-info'>
                                                                            Số kỳ:
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <select
                                                                            className="form-control kt-input pd-4-input shadow-none align-center"
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    // selectedBusiness: item.businessName,
                                                                                    // periodSelected: parseInt(val.target.value),
                                                                                    numberOfMonths: val.target.value
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value={0}>Số kỳ</option>
                                                                            <option value={1}>1 tháng</option>
                                                                            <option value={2}>2 tháng</option>
                                                                            <option value={3}>3 tháng</option>
                                                                            <option value={4}>4 tháng</option>
                                                                            <option value={5}>5 tháng</option>
                                                                            <option value={6}>6 tháng</option>
                                                                            <option value={7}>7 tháng</option>
                                                                            <option value={8}>8 tháng</option>
                                                                            <option value={9}>9 tháng</option>
                                                                            <option value={10}>10 tháng</option>
                                                                            <option value={11}>11 tháng</option>
                                                                            <option value={12}>12 tháng</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-0 row">
                                                                    <div className="col-3 p-0">
                                                                        <span className='pr-3 width-info'>
                                                                            Số hóa đơn (/tháng):
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <select
                                                                            className="form-control kt-input pd-4-input shadow-none align-center"
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    // selectedBusiness: item.businessName,
                                                                                    // billSelected: parseInt(val.target.value),
                                                                                    numberOfInvoicesConstraintId: val.target.value,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value={0}>SL hóa đơn</option>
                                                                            {selectData?.invoice?.map((_item, index) => {
                                                                                return (
                                                                                    <option
                                                                                        value={_item.id}
                                                                                        name={_item.requirementConstraint}>{_item.requirementConstraint}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {selectData.document && selectData?.document.length > 0 &&
                                                            <div className='row align-items-center mt-2'>
                                                                <div className="col-6 p-0 row">
                                                                    <div className="col-3 p-0">
                                                                        <span className='pr-3 width-info'>
                                                                            Số chứng từ (/tháng):
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <select
                                                                            className="form-control kt-input pd-4-input shadow-none align-center"
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    // selectedBusiness: item.businessName,
                                                                                    // documentSelected: parseInt(val.target.value)
                                                                                    numberOfDocumentsConstraintId: val.target.value
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value={0}>SL chứng từ</option>
                                                                            {selectData?.document?.map((_item, index) => {
                                                                                return (
                                                                                    <option
                                                                                        value={_item.id}>{_item.requirementConstraint}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-0 row">
                                                                    <div className="col-3 p-0">
                                                                        <span className='pr-2 width-info'>
                                                                            Nhân sự:
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-8 p-0">
                                                                        <select
                                                                            className="form-control kt-input pd-4-input shadow-none align-center"
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    // selectedBusiness: item.businessName,
                                                                                    employeeSelected: parseInt(val.target.value),
                                                                                    numberOfEmployeesConstraintId: val.target.value
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value={0}>SL nhân sự</option>
                                                                            {selectData?.employee?.map((_item, index) => {
                                                                                return (
                                                                                    <option
                                                                                        value={_item.id}>{_item.requirementConstraint}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="d-flex mt-4">
                                                            <div className="col-6 d-flex p-0">
                                                                <span>Giá cước:&nbsp;&nbsp; </span>
                                                                <NumberFormat
                                                                    value={this.state?.totalPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    suffix={"đ"}
                                                                />
                                                            </div>
                                                            <div className="col-6 align-right ">
                                                                <button className="btn btn-sm btn-success" onClick={() => {
                                                                    this.setState({
                                                                        // selectedBusiness: item.businessType
                                                                    }, () => {
                                                                        this.props.showLoading()
                                                                        this.calculatePrice()
                                                                    })
                                                                }}>Tính giá cước 1
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                        <span className="mt-3"><b>

                                                        </b></span>
                                                        </div>
                                                        <div className='d-flex justify-content-center mt-3'>

                                                        {/*    {this.state.selectedPlan != item.planLocalId ?*/}
                                                        {/*        <button*/}
                                                        {/*            type="button"*/}
                                                        {/*            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                        {/*            // disabled={item.price == null}*/}
                                                        {/*            onClick={() => this.setState({*/}
                                                        {/*                // selectedPlan: item.planLocalId*/}
                                                        {/*            }, () => {*/}
                                                        {/*                this.props.setPlanInfo(this.state.selectedBusiness, this.state.billSelected, this.state.documentSelected, this.state.employeeSelected, this.state.periodSelected, item.price)*/}
                                                        {/*            })}>*/}
                                                        {/*            Đăng ký*/}
                                                        {/*        </button> : <CheckCircleTwoToneIcon fontSize="large"*/}
                                                        {/*                                            style={{color: "green"}}/>}*/}
                                                        </div>
                                                    </div>
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                             :
                            <div className='row justify-space-evenly'>
                                {planPrice?.map((item, index) => {
                                    return (
                                        <div className='col-md-3 col-xs-12 pt-4'>
                                            <div className="justify-center">
                                                <div className="service-plan-div">
                                                    <div className='card border-card-plan'>
                                                        <div
                                                            className='d-flex justify-content-center font-weight-bold mt-2 mb-2 p-3'
                                                            style={{minHeight: 100}}>
                                                            {item.requirementConstraint}
                                                        </div>
                                                        <div className=' justify-content-center' style={{minHeight: 100}}>
                                                            {/*<div className="d-flex justify-content-center">*/}
                                                            {/*    <span><b>Giá gói cước</b></span>*/}
                                                            {/*</div>*/}
                                                            <div className="d-flex justify-content-center flex-column align-items-center">
                                                                <span><b>Giá gói cước</b></span>
                                                                <span><b>
                                                                    <NumberFormat
                                                                        value={item?.price}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        suffix={"đ"}
                                                                    />
                                                                </b></span>

                                                            </div>
                                                            <div className='d-flex justify-content-center mt-3'>
                                                                {this.state.selectedPlan != item.id ?
                                                                    <button
                                                                        type="button"
                                                                        className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                                        onClick={() => this.setState({selectedPlan: item.id, totalPrice: item.price}, () => {
                                                                            this.props.setInsuranceConstraint(item.id, item.price)
                                                                        })}
                                                                    >
                                                                        Chọn
                                                                    </button>
                                                                    : <CheckCircleTwoToneIcon className=""
                                                                                              fontSize="large"
                                                                                              style={{color: "green"}}/>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                <div className="row justify-flex-end" style={{ marginTop: 20, marginBottom: 30, alignSelf:'center'}}>
                    <button type="button"
                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                            onClick={() => {
                                this.props.nextPage("choose-service");
                            }}
                    >
                        Hủy bỏ
                    </button>

                    <button
                        type="button"
                        disabled={this.state.totalPrice == 0}
                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                        onClick={() => {
                            this.props.nextPage("choose-supervisor");
                        }}
                    >
                        Tiếp theo
                    </button>
                </div>
            </div>
        );
    }
}

export default withSnackbar(ChoosePlan)
