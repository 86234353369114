import React, {Component} from "react";

import Pagination from "../../../components/Pagination";

import Sidebar from "../../../components/Sidebar";
import NumberFormat from "react-number-format";
import moment from "moment";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import DatePicker from "react-datepicker";
import lang from "../contracts-management/lang";
import {Link} from "react-router-dom";
import ModalSupConfirmReport from '../../../components/Modal/modalSupConfirmReport';
import ModalSupRejectReport from '../../../components/Modal/modalSupRejectReport';
import ModalDetailReport from "../../../components/Modal/modalDetailReport";

import PublicReportContractApi from "../../../services/ob-core/public-report-contract";
import SecureContractApi from "../../../services/ob-core/secure-contract";
import AccountantApi from "../../../services/ob-user/accountant";
import FilesApi from "../../../services/resource/files";

const renderType = function (value) {
    switch (value) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
};

class ReportManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailReason: false,
            showBlock: false,
            showDelete: false,
            showConfirm: false,
            showReject: false,
            itemSelected: {},
            listData: [],
            data: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,

            status: "",
            contractCode: "",
            contractCode_temp: "",
            startDate: "",
            endDate: "",
            startDate_temp: null,
            endDate_temp: null,

            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    // style: {minWidth: 60, textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    // style: {minWidth: 140, textAlign: "center"},
                    sort: false
                },
                {
                    id: "name",
                    name: "Tên báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 120, textAlign: "center"},
                    sort: false
                },
                {
                    id: "type",
                    name: "Loại báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 125, textAlign: "center"},
                    sort: false
                },

                {
                    id: "numOfCustomer",
                    name: "Ngày lập báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 160, textAlign: "center"},
                    sort: false
                },

                {
                    id: "numOfCustomer",
                    name: "Ngày duyệt báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 180, textAlign: "center"},
                    sort: false
                },
                {
                    id: "numOfCustomer",
                    name: "Kế toán thực hiện",
                    style: {textAlign: "center"},
                    // style: {minWidth: 160, textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 200, textAlign: "center"},
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        // this.getAllServicePlan();
        this.getListReport();
        this.onhandleSearch();
    }

    getListReport() {
        let {
            contractCode,
            pageNumber,
            pageSize,
            status,
            // startDate_temp,
            // endDate_temp,
            // descending,
            // value
        } = this.state;

        let payload = {
            // contractId: console.log("checkID>>>>>>>>>>>>>>>>>>>", this.props.value),
            // startDate: startDate_temp,
            // endDate: endDate_temp,
            pageNumber: pageNumber,
            pageSize: pageSize,
            status: status,
            contractCode: contractCode
        };
        // console.log("CHECK_ABC", payload);

        PublicReportContractApi.searchByContractIdAndDateAndStatus(payload, res => {
            if (res.success === true) {
                // console.log("listReportSuccess>>>>>>>>>>>>>", res);
                let data = res.data.content;
                let listAccountId = [];

                data.map(item => {
                    listAccountId.push(item.accountantId);
                });
                // console.log("listAccountId>>>>>>>>>>>>>", res, listAccountId);
                this.setState(
                    {
                        listData: res.data.content,
                        totalItem: res.data.totalElements,
                        totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                        listAccountId
                    },
                    () => {
                        if (data.length > 0) this.getListAccountantByIdFromAPI();
                    }
                );
                // let data = res.data;
                // listData.map(item => {
                //     let filter = data.filter(x => x.id == item.accountantId)
                //     item.accountantId = filter[0]?.fullName
                // });
                //
                // this.setState({
                //         listData
                //     }
                // )
                // this.setState({data: res.data.content})
            } else {
            }
        });
    }

    getListAccountantByIdFromAPI() {
        let {listAccountId, listData} = this.state;

        let payload = {
            ids: listAccountId
        };

        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                // console.log("listAccountant:", res);
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.accountantId);
                    item.accountantId = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                // console.log("toang>>>>>", res);
            }
        });
    }

    handleBlock = item => {
        this.setState({
            itemSelected: item,
            showBlock: true
        });
    };
    handleDelete = item => {
        this.setState({
            itemSelected: item,
            showDelete: true
        });
    };

    onhandleSearch = () => {
        // console.log("here");
        let {status_temp, contractCode_temp} = this.state;
        this.setState(
            {
                contractCode: contractCode_temp.trim(),
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListReport();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                contractCode_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onhandleSearch()
        );
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListReport();
            }
        );
    };

    renderStatus(status) {
        switch (status) {
            case "AWAITING_APPROVAL":
                return <span className="badge badge-secondary">Chờ duyệt</span>;
            case "APPROVED":
                return <span className="badge badge-success">Đã duyệt</span>;
            case "REJECTED":
                return <span className="badge badge-warning">Bị từ chối</span>;
            default:
                return;
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListReport();
            }
        );
    };

    downloadReport = () => {
        let payload = {
            code: this.state.selectedFile.contractCode
        }
        SecureContractApi.contractCodeToCustomerId(payload, res => {
            if(res.success){
                let payload1 = {
                    fileId: this.state.selectedFile.id,
                    customerId: res?.data
                }
                FilesApi.downloadReportFileByIdCus(payload1, res1 => {
                    if (res1.success) {
                        // console.log(res.data)
                        let a = document.createElement("a");
                        a.href = "data:application/octet-stream/png;base64," + res1.data.content;
                        a.download = `${this.state.selectedFile.name}.${res1.data.extension}`
                        a.click();
                    }
                })
            }
        }) 
    }

    // ------------------------------------------------------------------------------------------------------

    render() {
        const {
            page,
            pageSize,
            totalItem,
            listAccountId,
            listData,
            pageNumber
        } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="report-management"/>
                        <div className="col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách báo cáo"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    placeholder="HD01011999"
                                                    name="Code"
                                                    type="text"
                                                    className="form-control font-14 shadow-none"
                                                    style={{width: "100%", height: 35}}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    value={this.state.contractCode_temp}
                                                    maxLength={20}
                                                    onChange={val =>
                                                        this.setState(
                                                            {contractCode_temp: val.target.value.trim()}
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="col-6 pl-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({status_temp: val.target.value}, () =>
                                                            console.log(this.state.status_temp)
                                                        )
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                >
                                                    <option value="">Tất cả</option>
                                                    <option value="AWAITING_APPROVAL">Chờ duyệt</option>
                                                    <option value="APPROVED">Đã duyệt</option>
                                                    <option value="REJECTED">Bị từ chối</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-2 align-text-bottom pl-0 button-filter-wp">
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.onhandleSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.onHandleClearText()}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>
                                    <table className="table table-bordered table-striped table-data">
                                        <thead>
                                        <TableHeader
                                            header={this.state.header}
                                            clickSort={data => {
                                            }}
                                        />
                                        </thead>
                                        {!this.state.listData || this.state.listData.length === 0 ? (
                                            <tbody>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="text-center">Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                            {this.state.listData?.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: "center"}}>
                                                        {this.state.pageNumber * this.state.pageSize +
                                                        index +
                                                        1}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {row.contractCode}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        <span style={{textAlign: "left"}}>
                                                          {row.name}
                                                        </span>
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        <span style={{textAlign: "left"}}>
                                                          {renderType(row.reportTypeName)}
                                                          {/*{row.reportTypeName || 'Chưa có dữ liệu'}*/}
                                                        </span>
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        <span style={{textAlign: "center"}}>
                                                          {row.cmnCreatedAt
                                                              ? moment(row.cmnCreatedAt).format("DD-MM-YYYY")
                                                              : ""}
                                                        </span>
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        <span style={{textAlign: "center"}}>
                                                          {row.acceptAt
                                                              ? moment(row.acceptAt).format("DD-MM-YYYY")
                                                              : ""}
                                                        </span>
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {row.accountantId}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: "center", fontSize: 18}}>
                                                        {/*<button className="btn btn-action"*/}
                                                        {/*        disabled={row.status !== 'REJECTED'}*/}
                                                        {/*        title='Xem chi tiết'*/}
                                                        {/*        onClick={() => this.setState({*/}
                                                        {/*            itemSelected: row,*/}
                                                        {/*            showDetailReason: true*/}
                                                        {/*        })}*/}
                                                        {/*        style={{cursor: 'pointer'}}*/}
                                                        {/*>*/}
                                                        {/*    <i className="ti-book"/>*/}
                                                        {/*</button>*/}
                                                        <button
                                                            disabled={row.status !== "AWAITING_APPROVAL"}
                                                            style={{
                                                                color: row.status === "AWAITING_APPROVAL" ? 'initial' : '#ccc',
                                                                cursor: row.status === "AWAITING_APPROVAL" ? 'pointer' : 'unset'
                                                            }}
                                                            className="btn btn-action"
                                                            title="Xác nhận"
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row.id
                                                                }, () => this.setState({showConfirm: true}))
                                                            }}
                                                        >
                                                            <i className="ti-check-box"/>
                                                        </button>
                                                        <button
                                                            disabled={row.status !== "AWAITING_APPROVAL"}
                                                            style={{
                                                                color: row.status === "AWAITING_APPROVAL" ? 'initial' : '#ccc',
                                                                cursor: row.status === "AWAITING_APPROVAL" ? 'pointer' : 'unset'
                                                            }}
                                                            className="btn btn-action"
                                                            title="Từ chối"
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row.id
                                                                }, () => this.setState({showReject: true}))
                                                            }}
                                                        >
                                                            <i className="ti-close"/>
                                                        </button>
                                                        <button className="btn btn-action"
                                                                title='Tải xuống'
                                                                style={{cursor: 'pointer'}} onClick={() => {
                                                            this.setState({selectedFile: row}, () => {
                                                                this.downloadReport()
                                                            })
                                                        }}>
                                                            <i className="ti-download"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end m-2 font-14">
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showDetailReason && <ModalDetailReport show={this.state.showDetailReason}
                                                                   data={this.state.itemSelected}
                                                                   onHide={() => this.setState({
                                                                       showDetailReason: false
                                                                   })}
                />}
                {this.state.showConfirm && <ModalSupConfirmReport id={this.state.itemSelected}
                                                                  onRefresh={() => this.onhandleSearch()}
                                                                  onHide={() => this.setState({showConfirm: false})}/>}
                {this.state.showReject && <ModalSupRejectReport id={this.state.itemSelected}
                                                                onRefresh={() => this.onhandleSearch()}
                                                                onHide={() => this.setState({showReject: false})}/>}
            </div>
        );
    }
}

export default ReportManagement;
