import React from 'react';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';
import moment from "moment";

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};
const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn quận huyện'})
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};
class ModalDetailCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            id: null,
            onTab: 1,
            listData: [],
            code: '',
        };
    }

    componentDidMount() {
        this.setState({listData: this.props.data})
    }

    renderBusiness(business) {
        switch (business) {
            case "COMMERCE_SERVICE":
                return "Thương mại";
            case "MANUFACTURING_MACHINING":
                return "Sản xuất";
            case "BUILDING_INSTALLATION":
                return "Xây dựng";
            default:
                return;
        }
    }

    renderScale(scale) {
        switch (scale) {
            case "SMALL":
                return "Nhỏ";
            case "MEDIUM":
                return "Vừa";
            case "EXTRASMALL":
                return "Siêu nhỏ";
            default:
                return;
        }
    }

    render() {
        let {listData} = this.state;
        let {data} = this.props
        const modalProp = {
            show: true,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-large">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>Chi tiết khách hàng</b></h1>
                        </div>
                        <div className='p-0'>
                            {/*<div className='text-center'>*/}
                            {/*    <img*/}
                            {/*        src={listData.urlImage}*/}
                            {/*        className='img-supervisor cursor-pointer'*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*_________________________________________________Company Information_____________________________________________*/}

                            <div className='row'>
                                <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin doanh nghiệp</span>
                                <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Tên doanh nghiệp</span>
                                    </div>
                                    <div
                                        className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                        {listData.companyName ? listData.companyName : 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Mã số thuế</span>
                                    </div>
                                    <div
                                        className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                        {listData.tax ? listData.tax : 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Email</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.email || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Lĩnh vực hoạt động</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {this.renderBusiness(listData.business) || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Số điện thoại</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.phone || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Quy mô hoạt động</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {this.renderScale(listData.scale) || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Tỉnh/Thành phố</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {renderProvinceId(listData.provinceId) || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Địa chỉ</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.address || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Quận/huyện</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {renderDistrictId(listData.districtId) || 'Chưa có dữ liệu'}
                                    </div>
                                </div>
                            </div>

                            {/*______________________________________________Customer Information__________________________________________*/}

                            <div className='row mt-5'>
                                <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin tài khoản</span>
                                <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Nguời đại diện</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.fullName || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Giới tính</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.sex === 'MALE' ? 'Nam' : 'Nữ'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Số điện thoại</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile d-flex">
                                        {listData.phone ? listData.phone : 'Không có SĐT'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>CMT/CCCD</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.identify || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Chức vụ</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.position || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Nơi cấp</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.placeIssued || 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Ngày cấp</span>
                                    </div>
                                    <div
                                        className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {listData.dateIssued ? moment(listData.dateIssued).format("DD-MM-YYYY") : 'Chưa có dữ liệu'}
                                    </div>
                                </div>
                            </div>
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="border-1-s type-button-fix btn btn-light"
                                    onClick={() => {
                                        this.props.onHide()
                                    }}
                                >
                                    Đóng
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalDetailCustomer;

