import React, {Component} from "react";
import './index.css';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

import lang from './lang';
import {
    Card,
    Breadcrumbs,
    RadioGroup,
    TextField,
    Grid,
    Button,
    Radio,
    CardContent,
    CircularProgress,
    FormControlLabel,
    FormControl,
    FormHelperText
} from '@material-ui/core';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker/es";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import CardHeader from "@material-ui/core/CardHeader";
import {toast} from "react-toastify";
import {checkPermission} from "../../../utils/common";
import Authentication from "../../../services/auth";
import {forceLogout} from "../../../utils/authority";
import TableHeader from "../../../components/TableHeader";

const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


const renderScale = function (scale) {
    switch (scale) {
        case 'MEDIUM':
            return 'Vừa';
        case 'SMALL':
            return 'Nhỏ';
        default:
            return 'Siêu nhỏ';
    }
};


const renderBusiness = function (business) {
    switch (business) {
        case 'COMMERCE_SERVICE':
            return 'Thương mại - Dịch vụ - Đầu tư tài chính';
        case 'MANUFACTURING_MACHINING':
            return 'Sản xuất - Gia công';
        default:
            return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
    }
};

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : [];
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            data: {},
            listProvince: JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [],
            provinceSearch: '',
            listDistrict: [],
            allUserTypes: [],
            currentUserType: localStorage.getItem("ob_cl_userType"),
            companyAddress: "",
            identify: "",
            companyPhone: '',
            companyEmail: "",
            business: "",
            scale: "",
            revenue: "",
            fullName: "",
            position: "",
            phone: "",
            placeIssued: "",
            dateIssued: "",
            tax: "",
            sex: "",
            circulars: "",
            provinceId: 0,
            districtId: 0,

            companyAddressError: false,
            identifyError: false,
            companyEmailError: false,
            businessError: false,
            scaleError: false,
            revenueError: false,
            fullNameError: false,
            positionError: false,
            companyPhoneError: false,
            placeIssuedError: false,
            taxError: false,
            dateIssuedError: false,
            sexError: false,
            provinceIdError: false,
            districtIdError: false,
            btnSaveStatus: true,
            showLoading: false,

            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,
            modalUploadAvatar: false,
            accounts: [
                {
                    id: '1',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Loại tài khoản",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getAccountInfo();
            this.getAllUserTypes();
        })

    }


    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }
    getAllUserTypes = () => {
        Authentication.getAllUserType(res => {
            if (res.success) {
                this.setState({allUserTypes: res.data, showLoading: false});
            }
        })
    }
    getAccountInfo() {
        SecureCustomerApi.detail(res => {
            if (res.success) {
                this.setState({
                    data: res.data,
                    companyAddress: res.data.companyAddress || "",
                    tax: res.data.tax || "",
                    identify: res.data.identify || "",
                    companyEmail: res.data.companyEmail || "",
                    companyPhone: res.data.companyPhone || "",
                    companyName: res.data.companyName || "",
                    business: res.data.business || "",
                    scale: res.data.scale || "",
                    revenue: res.data.revenue || "",
                    fullName: res.data.fullName || "",
                    position: res.data.position || "",
                    phone: res.data.phone || "",
                    email: res.data.email || "",
                    circulars: res.data.circulars || "",
                    placeIssued: res.data.placeIssued || "",
                    dateIssued: res.data.dateIssued || "",
                    sex: res.data.sex || "FEMALE",
                    provinceId: res.data.provinceId || 0,
                    provinceSearch: renderProvinceId(res.data.provinceId) || '',
                    districtId: res.data.districtId || 0,
                    districtSearch: renderDistrictId(this.state.data.districtId) || '',
                    showLoading: false,
                })
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    handleChangeTax(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({
                    taxError: false,
                    inEditing: true
                }) : this.setState({taxError: true, inEditing: false});
                this.state.tax.length >= 10 ? this.setState({
                    taxError: false,
                    inEditing: true
                }) : this.setState({taxError: true, inEditing: false})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangePhone(val) {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({companyPhone: onlyNums}, () => {
                this.state.companyPhone.length !== 0 ? this.setState({
                    companyPhoneError: false,
                    inEditing: true
                }) : this.setState({companyPhoneError: true, inEditing: false})
            });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({companyPhone: number, companyPhoneError: false, inEditing: true});
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({companyPhone: number, companyPhoneError: false, inEditing: true});
        }
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({
                    identifyError: false,
                    inEditing: true
                }) : this.setState({identifyError: true, inEditing: false});
                this.state.identify.length >= 9 ? this.setState({
                    identifyError: false,
                    inEditing: true
                }) : this.setState({identifyError: true, inEditing: false})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin doanh nghiệp - OneBook';
    }

    // uploadAvatarSuccess = (data) => {
    //     let payload = {
    //         imageName: data.storageName
    //     }
    //     SecureCustomerApi.uploadAvatar(payload, res => {
    //         if (res.success) {
    //             toast.success("Cập nhật ảnh đại diện thành công !")
    //             this.handleSave()
    //         } else {
    //             toast.error('Cập nhật không thành công, vui lòng thử lại sau !')
    //         }
    //     })
    // }
    switchUserType = (userType) => {
        let payload = {
            type: Authentication.getIndexUserType(userType)
        }
        Authentication.switchUserType(payload, res => {
            if (res.success){
                forceLogout();
                toast.success("Chuyển thành công! \nVui lòng đăng nhập lại để tiếp tục!");
            }
            else {
                toast.error("Chuyển tài khoản thất bại");
            }
        })
    }
    handleSave() {
        let ready = true;
        let {circulars, companyAddress, identify, business, scale, companyEmail, revenue, fullName, position, companyName, companyPhone, placeIssued, tax, dateIssued, sex, email, provinceId, districtId, phone} = this.state;
        if (companyAddress === '' || companyAddress === null) {
            ready = false;
            this.setState({companyAddressError: true})
        }
        if (tax === '' || tax === null) {
            ready = false;
            this.setState({taxError: true})
        }
        if (identify === '' || identify === null) {
            ready = false;
            this.setState({identifyError: true})
        }
        if (companyEmail === '' || !validateEmail(companyEmail) || companyEmail === null) {
            ready = false;
            this.setState({companyEmailError: true})
        }
        if (email === '' || !validateEmail(email) || email === null) {
            ready = false;
            this.setState({emailError: true})
        }
        // if (revenue === '') {
        //     ready = false;
        //     this.setState({revenueError: true})
        // }
        if (fullName === '' || fullName === null) {
            ready = false;
            this.setState({fullNameError: true})
        }
        if (position === '' || position === null) {
            ready = false;
            this.setState({positionError: true})
        }
        if (companyPhone === '' || companyPhone === null) {
            ready = false;
            this.setState({companyPhoneError: true})
        }
        if (placeIssued === '' || placeIssued === null) {
            ready = false;
            this.setState({placeIssuedError: true})
        }
        if (dateIssued === '' || dateIssued === null) {
            ready = false;
            this.setState({dateIssuedError: true})
        }
        if (sex === '' || sex === null) {
            ready = false;
            this.setState({sexError: true})
        }
        if (provinceId === '' || provinceId === null) {
            ready = false;
            this.setState({provinceIdError: true})
        }
        if (districtId === '' || districtId === null) {
            ready = false;
            this.setState({districtIdError: true})
        }
        if (ready) {
            let payload = {};
            if(this.state.data.isAdminCustomer) {
                payload = {
                    body: {
                        circulars: circulars ? circulars : '',
                        address: companyAddress ? companyAddress : '',
                        business: business ? business : 'COMMERCE_SERVICE',
                        companyEmail: companyEmail ? companyEmail : '',
                        identify: identify ? identify : '',
                        position: position ? position : '',
                        companyPhone: companyPhone ? companyPhone : '',
                        companyName: companyName ? companyName : '',
                        fullName: fullName ? fullName : '',
                        phone: phone ? phone : '',
                        email: email ? email : '',
                        scale: scale ? scale : 'MEDIUM',
                        placeIssued: placeIssued ? placeIssued : '',
                        dateIssued: dateIssued ? dateIssued : '',
                        tax: tax ? tax : '',
                        sex: sex ? sex : '',
                        provinceId: provinceId ? provinceId : 0,
                        districtId: districtId ? districtId : 0,
                    }
                }
            }else {
                payload = {
                    body: {
                        circulars: circulars ? circulars : '',
                        email: email ? email : '',
                        identify: identify ? identify : '',
                        position: position ? position : '',
                        fullName: fullName ? fullName : '',
                        phone: phone ? phone : '',
                        placeIssued: placeIssued ? placeIssued : '',
                        dateIssued: dateIssued ? dateIssued : '',
                        sex: sex ? sex : '',
                    }
                }
            }
            // console.log('payload lưu: ', payload);
            this.setState({loading: true}, () => {
                let timer = setInterval(() => {
                    this.tick()
                }, 10);
                setTimeout(() => {
                    clearInterval(timer);
                    this.setState({
                        loading: false,
                    }, () => {
                        if(this.state.data.isAdminCustomer) {
                            SecureCustomerApi.adminCustomerUpdateInfo(payload, res => {
                                if (res.success) {
                                    toast.success('Thông tin đã được cập nhật thành công !')
                                    this.setState({
                                        onEdit: false,
                                        inEditing: false,
                                    }, () => this.getAccountInfo());
                                } else {
                                    // console.log('err: ', res);
                                    toast.error('Cập nhật không thành công, vui lòng nhập lại !')
                                }
                            })
                        } else {
                            SecureCustomerApi.update(payload, res => {
                                if (res.success) {
                                    toast.success('Thông tin đã được cập nhật thành công !')
                                    this.setState({
                                        onEdit: false,
                                        inEditing: false,
                                    }, () => this.getAccountInfo());
                                } else {
                                    // console.log('err: ', res);
                                    toast.error('Cập nhật không thành công, vui lòng nhập lại !')
                                }
                            })
                        }
                    });
                }, 3000);
            });
        }
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({onEdit: false}, () => {
                this.setState({
                    companyAddress: this.state.data ? this.state.data.companyAddress : "",
                    identify: this.state.data ? this.state.data.identify : "",
                    companyEmail: this.state.data ? this.state.data.companyEmail : "",
                    companyPhone: this.state.data ? this.state.data.companyPhone : "",
                    business: this.state.data ? this.state.data.business : "",
                    scale: this.state.data ? this.state.data.scale : "",
                    revenue: this.state.data ? this.state.data.revenue : "",
                    fullName: this.state.data ? this.state.data.fullName : "",
                    position: this.state.data ? this.state.data.position : "",
                    phone: this.state.data ? this.state.data.phone : "",
                    placeIssued: this.state.data ? this.state.data.placeIssued : "",
                    tax: this.state.data ? this.state.data.tax : "",
                    email: this.state.data ? this.state.data.email : "",
                    dateIssued: this.state.data ? this.state.data.dateIssued : new Date (),
                    sex: this.state.data ? this.state.data.sex : "FEMALE",
                    provinceId: this.state.data ? this.state.data.provinceId : 0,
                    districtId: this.state.data ? this.state.data.districtId : 0,

                    companyAddressError: false,
                    identifyError: false,
                    companyEmailError: false,
                    revenueError: false,
                    fullNameError: false,
                    positionError: false,
                    companyPhoneError: false,
                    placeIssuedError: false,
                    taxError: false,
                    dateIssuedError: false,
                    sexError: false,
                    provinceIdError: false,
                    districtIdError: false,
                })
            })
        }
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderScale = (business) => {
        let val1 = [
            {value: 'MEDIUM', name: 'Vừa (Nhân sự <100, doanh thu < 100)'},
            {value: 'SMALL', name: 'Nhỏ (Nhân sự <50, doanh thu < 50)'},
            {value: 'EXTRASMALL', name: 'Siêu nhỏ (Nhân sự <10, doanh thu < 3)'},
        ];
        let val2 = [
            {value: 'MEDIUM', name: 'Vừa (Nhân sự <200, doanh thu < 100)'},
            {value: 'SMALL', name: 'Nhỏ (Nhân sự <100, doanh thu <20)'},
            {value: 'EXTRASMALL', name: 'Siêu nhỏ (Nhân sự <10, doanh thu < 3)'},
        ];

        if (business == 'COMMERCE_SERVICE') {
            return val1.map((item, index) => <option
                value={item.value}>{item.name}</option>)
        } else {
            return val2.map((item, index) => <option
                value={item.value}>{item.name}</option>)
        }
    }

    renderCirculars = (circulars) => {
        switch (circulars) {
            case "200":
                return "Thông tư 200/2014/TT-BTC"
            case "133":
                return "Thông tư 133/2016/TT-BTC"
        }
    }

    render() {
        const {companyAddress, identify, companyEmail, business, scale, revenue, companyPhone, fullName, position, phone, data, tax, placeIssued, dateIssued, sex, provinceId, districtId, circulars} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="profile"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <Card variant="outlined" className='mb-4'>
                                <CardHeader
                                    title={this.state.onEdit ? lang.title.editCompany : lang.title.infoCompany}/>
                                <CardContent>
                                    <div className="">
                                        {/*<div className='justify-center img-logo-wrapper' style={{textAlign: 'center', backgroundImage: data.urlImage ? `url(${data.urlImage}` : `url("/images/no-avatar-png.png")`}}>*/}
                                        {/*    <span className="fas fa-camera change-avt-icon" title="Tải lên ảnh đại diện" onClick={()=> this.setState({modalUploadAvatar: true})}/>*/}
                                        {/*</div>*/}
                                        {/*<Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>*/}
                                        {/*    {({getRootProps, getInputProps}) => (*/}
                                        {/*        <span title='Thay đổi logo' className='change-avt'>*/}
                                        {/*                <span {...getRootProps()}>*/}
                                        {/*                    <input {...getInputProps()} />*/}
                                        {/*                    <CameraAltOutlinedIcon/>*/}
                                        {/*                </span>*/}
                                        {/*            </span>*/}
                                        {/*    )}*/}
                                        {/*</Dropzone>*/}

                                        <div className='justify-center img-logo-wrapper' style={{
                                            textAlign: 'center',
                                            backgroundImage: data.urlImage ? `url(${data.urlImage}` : `url("/images/no-avatar-png.png")`
                                        }}>
                                        </div>
                                        {(checkPermission("change_logo")) &&
                                        <div>
                                                <span className="fas fa-camera change-logo-icon"
                                                      title="Tải lên ảnh đại diện"
                                                      onClick={() => this.setState({modalUploadAvatar: true})}/>
                                        </div>
                                        }
                                    </div>

                                    {/*<PageHeader*/}
                                    {/*    title={this.state.onEdit ? lang.title.editCompany : lang.title.infoCompany}/>*/}
                                    <div className="mt-4 font-14">
                                        <div className='row kt-margin-b-20'>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">{lang.label.fullName}</label>
                                            </div>
                                            <div
                                                title={data?.companyName?.toUpperCase()}
                                                className="pl-0 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {data?.companyName?.toUpperCase()}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Mã số thuế </label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit
                                                    ? (data.tax === "" || data.tax === null
                                                        ? <input type="text"
                                                                 className={this.state.taxError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                                 placeholder='Hoàn thiện mã số thuế'
                                                                 disabled={!data.isAdminCustomer}
                                                                 value={this.state.tax}
                                                                 maxLength={14}
                                                                 onChange={(val) => {
                                                                     if (val.target.value.length > 14 || val.target.value.length < 10) {
                                                                         this.setState({taxError: true})
                                                                     }
                                                                     this.handleChangeTax(val)

                                                                 }}
                                                        />
                                                        : data.tax)
                                                    : data.tax
                                                }
                                                {this.state.taxError &&
                                                <div className="invalid-feedback d-block">
                                                    Mã số thuế từ 10-14 ký tự!
                                                </div>}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Email</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {/*{data.companyEmail ? data.companyEmail : ''}*/}
                                                {this.state.onEdit
                                                        ? <input type="text"
                                                                 className={this.state.companyEmailError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                                 placeholder='Hoàn thiện email'
                                                                 value={this.state.companyEmail}
                                                                 disabled={!data.isAdminCustomer}
                                                                 maxLength={100}
                                                                 onChange={(val) => this.setState({
                                                                     companyEmail: val.target.value.trimLeft(),
                                                                     inEditing: true
                                                                 }, () => {
                                                                     this.state.companyEmail.length === 0 ? this.setState({
                                                                         companyEmailError: true,
                                                                         btnSaveStatus: false
                                                                     }) : this.setState({
                                                                         companyEmailError: false,
                                                                         // btnSaveStatus: true
                                                                     })
                                                                 })}
                                                        />
                                                    : data.companyEmail
                                                }
                                                {this.state.companyEmailError &&
                                                <div className="invalid-feedback d-block">
                                                    Vui lòng nhập Email !
                                                </div>}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Lĩnh vực hoạt
                                                    động{this.state.onEdit &&
                                                    <span className={'color-red d-inline'}>*</span>}</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <select
                                                        // inputProps={{style: {paddingLeft: 6}}}
                                                        className="form-control kt-input pd-4-input hei-input my-select"
                                                        defaultValue={"COMMERCE_SERVICE"}
                                                        value={business}
                                                        disabled={!data.isAdminCustomer}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                business: e.target.value,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        <option value="COMMERCE_SERVICE">Thương mại - Dịch vụ - Đầu tư
                                                            tài chính
                                                        </option>
                                                        <option value="MANUFACTURING_MACHINING">Sản xuất - Gia công
                                                        </option>
                                                        <option value="BUILDING_INSTALLATION">Xây dựng - Lắp đặt - Đầu
                                                            tư bất động sản
                                                        </option>
                                                    </select>
                                                    : data.business ? renderBusiness(data.business) : ''
                                                }
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">
                                                    Số điện thoại
                                                </label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {data.phone ? data.phone : 'Không có SĐT'}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Quy mô hoạt động {this.state.onEdit &&
                                                <span className={'color-red d-inline'}>*</span>}</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <select
                                                        className="form-control kt-input pd-4-input hei-input my-select"
                                                        value={scale}
                                                        disabled={!data.isAdminCustomer}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                scale: e.target.value,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        {this.renderScale(business)}
                                                    </select>
                                                    : data.scale ? renderScale(data.scale) : ''
                                                }
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Tỉnh/Thành phố {this.state.onEdit &&
                                                <span className={'color-red d-inline'}>*</span>}</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?

                                                    // <Autocomplete
                                                    //     size="small"
                                                    //     options={this.state.listProvince}
                                                    //     getOptionLabel={option => option.name}
                                                    //     style={{width: '100%'}}
                                                    //     inputValue={this.state.provinceSearch}
                                                    //     renderInput={(params) => {
                                                    //         params.InputProps.style = {height: 30, padding: 0, paddingLeft: 4}
                                                    //         return <TextField
                                                    //             {...params}
                                                    //             InputLabelProps={{
                                                    //                 shrink: true,
                                                    //             }}
                                                    //             variant="outlined"
                                                    //             fullWidth
                                                    //         />
                                                    //     }}
                                                    //     onInputChange={(event) => {
                                                    //         if (event === null || event.target.value === undefined) {
                                                    //             this.setState({
                                                    //                 provinceSearch: ''
                                                    //             })
                                                    //         } else {
                                                    //             this.setState({
                                                    //                 provinceSearch: event?.target.value
                                                    //             })
                                                    //         }
                                                    //     }}
                                                    //     onChange={(e, val) => {
                                                    //         this.setState({
                                                    //             provinceId: val?.id,
                                                    //             provinceSearch: val?.name
                                                    //             // idPartner: val.id
                                                    //         })
                                                    //     }}
                                                    // />

                                                    <select
                                                        className="form-control kt-input pd-4-input hei-input"
                                                        value={provinceId}
                                                        defaultValue={0}
                                                        onChange={(e) => {
                                                            let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                            let id = 0;
                                                            id = _district?.filter(item => item.provinceId == e.target.value)[0].id;
                                                            this.setState({
                                                                provinceId: e.target.value,
                                                                districtId: id,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        <option value={0} disabled>Tỉnh/Thành phố</option>
                                                        {renderProvince()}
                                                    </select>
                                                    : data.provinceId ? renderProvinceId(data.provinceId) : ''
                                                }
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Quận/Huyện {this.state.onEdit &&
                                                <span className={'color-red d-inline'}>*</span>}</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <select
                                                        className="form-control kt-input pd-4-input hei-input"
                                                        value={districtId}
                                                        disabled={!data.isAdminCustomer}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                districtId: e.target.value,
                                                                inEditing: true
                                                            })
                                                        }}
                                                    >
                                                        <option value="">Quận/Huyện</option>
                                                        {this.renderDistrict(provinceId)}
                                                    </select>
                                                    : data.districtId ? renderDistrictId(data.districtId) : ''
                                                }
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Địa chỉ {this.state.onEdit &&
                                                <span className={'color-red d-inline'}>*</span>}</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {this.state.onEdit ?
                                                    <input type="text"
                                                           className={this.state.companyAddressError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                           placeholder='Nhập địa chỉ'
                                                           value={companyAddress}
                                                           disabled={!data.isAdminCustomer}
                                                           maxLength={255}
                                                           onChange={(val) => this.setState({
                                                               companyAddress: val.target.value.trimLeft(),
                                                               inEditing: true
                                                           }, () => {
                                                               this.state.companyAddress.length === 0 ? this.setState({
                                                                   companyAddressError: true,
                                                                   btnSaveStatus: false
                                                               }) : this.setState({
                                                                   companyAddressError: false,
                                                                   // btnSaveStatus: true
                                                               })
                                                           })}
                                                    />
                                                    : data.companyAddress ? data.companyAddress : ''
                                                }
                                                {this.state.companyAddressError &&
                                                <div className="invalid-feedback d-block">
                                                    Vui lòng nhập địa chỉ !
                                                </div>}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <label className="text-black-50">Thông tư:</label>
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                {data.circulars ? this.renderCirculars(data.circulars) : ''}
                                            </div>
                                            <div
                                                className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className='mb-5'>
                                <CardHeader title={lang.title.infoRepresentative}/>
                                {/*<span>{lang.title.infoRepresentative}</span>*/}
                                {/*    <span> CÁ NHÂN</span>*/}
                                {/*</CardHeader>*/}
                                <CardContent>
                                    {/*<PageHeader title={lang.title.infoRepresentative}/>*/}
                                    <div className='row kt-margin-b-20 mb-4 mt-4 font-14'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Người đại diện {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</label>
                                        </div>
                                        <div
                                            className="input-group pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.fullNameError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Người đại diện'
                                                       value={fullName}
                                                       maxLength={100}
                                                       onChange={(val) => this.setState({
                                                           fullName: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.fullName.length === 0 ? this.setState({
                                                               fullNameError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               fullNameError: false,
                                                               // btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : data.fullName ? data.fullName.toUpperCase() : ''
                                            }
                                            {this.state.fullNameError &&
                                            <div className="invalid-feedback d-block">
                                                Vui lòng nhập người đại diện !
                                            </div>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Giới tính {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <FormControl component="fieldset" className='m-0'>
                                                    <RadioGroup aria-label="Gender" className='d-inline '
                                                                value={sex ? sex : "FEMALE"}
                                                                onChange={(val) => this.setState({
                                                                    sex: val.target.value,
                                                                    inEditing: true
                                                                })
                                                                }
                                                    >
                                                        <FormControlLabel value="FEMALE" control={<StyledRadio/>}
                                                                          label="Nữ"/>
                                                        <FormControlLabel value="MALE" control={<StyledRadio/>}
                                                                          label="Nam"/>
                                                    </RadioGroup>
                                                </FormControl>
                                                : data.sex ? data.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Số điện thoại</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.phone ? data.phone : 'Không có SĐT'}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Chức vụ {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.positionError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Chức vụ'
                                                       value={position}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           position: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.position.length == 0 ? this.setState({
                                                               positionError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               positionError: false,
                                                               // btnSaveStatus: true
                                                           })
                                                       })}

                                                />
                                                : data.position ? data.position : ''
                                            }
                                            {this.state.positionError && <div className="invalid-feedback d-block">
                                                Vui lòng nhập chức vụ !
                                            </div>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">
                                                CMT/CCCD {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}
                                            </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.identifyError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='CMT/CCCD'
                                                       value={identify}
                                                       maxLength={12}
                                                       onChange={(val) => {
                                                           this.handleChangeIdentify(val)
                                                       }}
                                                />
                                                : data.identify ? data.identify : ''
                                            }
                                            {this.state.identifyError && <div className="invalid-feedback d-block">
                                                Vui lòng nhập CMT/CCCD !
                                            </div>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">
                                                Ngày cấp{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}
                                            </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <DatePicker
                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                    className={this.state.dateIssuedError ? "border-input-error w-auto h-100 kt-input pd-4-input hei-input form-control" : "w-auto h-100 kt-input pd-4-input hei-input form-control"}
                                                    // className={"w-auto h-100 kt-input pd-4-input hei-input form-control"}
                                                    selected={this.state.dateIssued ? new Date(this.state.dateIssued) : ""}
                                                    placeholderText="Ngày cấp"
                                                    maxDate={new Date()}
                                                    dateFormat="dd-MM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChangeRaw={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    // showMonthYearDropdown
                                                    onSelect={(val) => {
                                                        this.setState({
                                                            dateIssued: val,
                                                            inEditing: true,
                                                            dateIssuedError: false
                                                        }, () => console.log("dateIISS?>>>>>", this.state.dateIssued))
                                                    }}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            dateIssued: moment(date).format("YYYY-MM-DD"),
                                                            inEditing: true
                                                        })
                                                    }}
                                                />
                                                : data.dateIssued ? moment(data.dateIssued).format(formatDate) : ''
                                            }
                                            {this.state.dateIssuedError && <div className="invalid-feedback d-block">
                                                Vui lòng chọn ngày cấp !
                                            </div>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">
                                                Nơi cấp {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}
                                            </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.placeIssuedError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nơi cấp'
                                                       value={placeIssued}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           placeIssued: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.placeIssued.length === 0 ? this.setState({
                                                               placeIssuedError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               placeIssuedError: false,
                                                               // btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : data.placeIssued ? data.placeIssued : ''
                                            }
                                            {this.state.placeIssuedError && <div className="invalid-feedback d-block">
                                                Vui lòng nhập nơi cấp !
                                            </div>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Email</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {/*{data.companyEmail ? data.companyEmail : ''}*/}
                                            {this.state.onEdit
                                                ? <input type="text"
                                                         className={this.state.emailError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                         placeholder='Nhập email'
                                                         value={this.state.email}
                                                         disabled={!data.isAdminCustomer}
                                                         maxLength={100}
                                                         onChange={(val) => this.setState({
                                                             email: val.target.value.trimLeft(),
                                                             inEditing: true
                                                         }, () => {
                                                             this.state.email.length === 0 ? this.setState({
                                                                 emailError: true,
                                                                 btnSaveStatus: false
                                                             }) : this.setState({
                                                                 emailError: false,
                                                                 // btnSaveStatus: true
                                                             })
                                                         })}
                                                />
                                                : data.email
                                            }
                                            {this.state.emailError &&
                                            <div className="invalid-feedback d-block">
                                                Vui lòng nhập Email !
                                            </div>}
                                        </div>
                                    </div>
                                    {this.state.onEdit ? <Grid container spacing={2} justify={"flex-center"}>
                                            <Grid item xs={12} sm={12} md={12} className='text-center'>
                                                {this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                >
                                                    <CircularProgress size={20} variant="determinate"
                                                                      value={this.state.progress}/>
                                                </button>}
                                                {/*<Button variant="outlined" color="primary"*/}
                                                {/*        className='mr-3'*/}
                                                {/*        style={{textTransform: 'initial'}}*/}
                                                {/*>*/}
                                                {/*    <CircularProgress size={20} variant="determinate"*/}
                                                {/*                      value={this.state.progress}/>*/}
                                                {/*</Button>*/}

                                                {!this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.handleSave()}
                                                    disabled={!this.state.inEditing}
                                                >
                                                    Lưu
                                                </button>
                                                }
                                                {/*{!this.state.loading &&*/}
                                                {/*<Button disabled={!this.state.inEditing} variant="contained" color="primary"*/}
                                                {/*        className='mr-3'*/}
                                                {/*        style={{textTransform: 'initial'}}*/}
                                                {/*        onClick={() => this.handleSave()}*/}
                                                {/*>*/}
                                                {/*    Lưu*/}
                                                {/*</Button>}*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.handleCancel()}
                                                >
                                                    Hủy
                                                </button>
                                                {/*<Button variant="outlined" style={{textTransform: 'initial'}}*/}
                                                {/*        onClick={() => this.handleCancel()}*/}
                                                {/*>*/}
                                                {/*    Hủy*/}
                                                {/*</Button>*/}
                                            </Grid>
                                        </Grid>
                                        : <Grid container spacing={3} justify={"flex-center"}>
                                            <Grid item xs={12} sm={12} md={12} className='text-center'
                                                  style={{textTransform: 'initial'}}
                                            >
                                                {(checkPermission("edit_profile")) &&
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                                >
                                                    Chỉnh sửa thông tin
                                                </button>
                                                }
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.handleShowModalForgotPassword()}
                                                >
                                                    Đổi mật khẩu
                                                </button>
                                                {/*<Button*/}
                                                {/*    disabled={!this.state.btnSaveStatus}*/}
                                                {/*    variant="contained"*/}
                                                {/*    className='rounded mr-md-3 mr-sm-3 mr-lg-3 mb-1' color='primary'*/}
                                                {/*    style={{textTransform: 'initial'}}*/}
                                                {/*    onClick={() => this.setState({onEdit: !this.state.onEdit})}*/}
                                                {/*>*/}
                                                {/*    Chỉnh sửa thông tin*/}
                                                {/*</Button>*/}
                                                {/*<Button variant="outlined" className='rounded mb-1'*/}
                                                {/*        style={{textTransform: 'initial'}}*/}
                                                {/*        onClick={() => this.handleShowModalForgotPassword()}*/}
                                                {/*>*/}
                                                {/*    <a className='ml-3 mr-3'>Đổi mật khẩu</a>*/}
                                                {/*</Button>*/}
                                            </Grid>
                                        </Grid>
                                    }
                                    {/*{this.state.confirmCancel && <Dialog*/}
                                    {/*    open={true}*/}
                                    {/*    onClose={() => this.setState({confirmCancel: false})}*/}
                                    {/*>*/}
                                    {/*    <DialogTitle>Thông tin đã thay đổi, bạn có chắc muốn hủy ?</DialogTitle>*/}
                                    {/*    <DialogActions>*/}
                                    {/*        <Button*/}
                                    {/*            style={{textTransform: 'initial'}}*/}
                                    {/*            color="primary"*/}
                                    {/*            onClick={() => this.setState({*/}
                                    {/*                confirmCancel: false,*/}
                                    {/*                inEditing: false*/}
                                    {/*            }, () => this.handleCancel())}*/}
                                    {/*        >*/}
                                    {/*            Có*/}
                                    {/*        </Button>*/}
                                    {/*        <Button*/}
                                    {/*            color="primary"*/}
                                    {/*            style={{textTransform: 'initial'}}*/}
                                    {/*            onClick={() => this.setState({confirmCancel: false})}*/}
                                    {/*        >*/}
                                    {/*            Không*/}
                                    {/*        </Button>*/}
                                    {/*    </DialogActions>*/}
                                    {/*</Dialog>}*/}
                                </CardContent>
                            </Card>
                            {this.state.allUserTypes.length > 1 &&
                            <div variant="outlined" className='pb-2 mb-5 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Quản lý tài khoản</span>
                                </div>
                                <div className="table-wrapper col-12">
                                    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">
                                        <thead>
                                        <TableHeader header={this.state.accounts} clickSort={(data) => {
                                        }}/>
                                        </thead>
                                        <tbody>
                                        {this.state.allUserTypes.length !== 0
                                            ? this.state.allUserTypes.map((item, index) =>
                                                <tr>
                                                    <th scope="row" className='text-center'>{index + 1}</th>
                                                    <td className='text-center'>{item.userType}</td>
                                                    <td className='text-center'>
                                                        <div>
                                                            {this.state.currentUserType != item.userType &&
                                                            <Button variant="contained" color="primary"
                                                                    className='mr-3 btn-primary'
                                                                    style={{textTransform: 'initial'}}
                                                                    onClick={() => this.switchUserType(item.userType)}
                                                            >
                                                                Chuyển tài khoản
                                                            </Button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                            : <tr>
                                                <td colSpan={4}>
                                                    <div><span><h6>Không có dữ liệu</h6></span></div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                            <ModalChangePassword show={this.state.showModalChangePassword}
                                                 onHideModal={this.onCloseModalForgotPassword}/>
                            <ModalConfirmEdit show={this.state.confirmCancel}
                                              onHide={() => this.setState({confirmCancel: false})}
                                              handleCancel={
                                                  () => this.setState({
                                                      confirmCancel: false,
                                                      inEditing: false
                                                  }, () => this.handleCancel())
                                              }
                            />
                            {this.state.modalUploadAvatar && <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                                                                onHide={() => this.setState({modalUploadAvatar: false})}
                                                                                uploadSuccess={(data) => this.setState({avatarUploaded: data})}
                                                                                updateSuccess={() => {
                                                                                    this.setState({modalUploadAvatar: false})
                                                                                    this.getAccountInfo()
                                                                                }}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile
