import React from 'react';
import PropTypes from 'prop-types';
import TopHeader from '../TopHeader';
import Footer from '../Footer';
import {connect} from 'react-redux';
import HeaderBeforeLogin from "../HeaderBeforeLogin";
import ModalLogin from "../Modal/ModalLogin";
import ModalSignup from "../Modal/ModalSignup";
import ModalSignupMember from "../Modal/ModalSignupMember";
import Cookies from 'js-cookie';
import HeaderAfterLogin from "../HeaderAfterLogin";
import ModalOtp from "../Modal/ModalOtp";
import ModalWarningConfirmEmail from "../Modal/modalWarningConfirmEmail";
import ModalWarningCompleteInfo from "../Modal/modalWarningCompleteInfo";
import {createHashHistory} from 'history'
import ModalForgotPassword from "../Modal/modalForgotPassword";
import ModalForgotPassword3 from "../Modal/modalForgotPassword3";
import ModalForgotPassword2 from "../Modal/modalForgotPassword2";
import PublicNewsCategory from "../../services/ob-core/public-news-category";
import ModalLockAccount from "../Modal/modalLockAccount";
import SidebarMobile from "../SidebarMobile";

export const history = createHashHistory()

class Layout extends React.Component {
    static propTypes = {
        isFetching: PropTypes.bool,
        isCommonFetching: PropTypes.bool,
    };

    constructor(...args) {
        super(...args);
        this.state = {
            contractInfo: "",
            modalLogin: false,
            modalSignup: false,
            modalSignUpMember: false,
            haveToken: false,
            signupInfo: null,
            modalWarningInfo: false,
            listCategory: [],
            modalLockAccount: false,
            sidebarMobile: false,
        };
    }

    componentDidMount() {
        let token = Cookies.get('ob_cl_token');
        if (token)
            this.setState({haveToken: true})
        else {
            //haovc remove
            // history.push("/");
        }
        // else window.location = '/'
        this.getListCategory()
    }

    logout = () => {
        // let url =  window.location.hash.split('/');
        // if (url.length == 2 && !url[1]){
        //     console.log("nó chạy vô đây nè>>>>, done")
        // }else{
        // forceLogout()
        // return history.push("/");
        // console.log("check url22@@@>>>>>>>>", url)
        // }
        // console.log("check url22@@@>>>>>>>>", url)
        // if (url[2] && (url[2] == "complete-profile" || url[2] == "confirm-email")){
    }

    setSignupInfo = (signupInfo) => {
        this.setState({signupInfo}, () => {
            this.modalSignupClose();
            this.onOpenModalOtp()
        })
    }

    onOpenModalLogin = (event) => {
        event.preventDefault();
        this.setState({modalLogin: true});
    };
    modalLoginClose = () => this.setState({modalLogin: false});
    onOpenModalSignup = (event) => {
        // event.preventDefault();
        this.setState({modalSignup: true, modalLogin: false});
    };
    onOpenModalSignupMember = (event) => {
        // event.preventDefault();
        this.setState({modalSignupMember: true, modalLogin: false});
    };
    onOpenModalOtp = (event) => {
        // event.preventDefault();
        this.setState({modalOtp: true});
    };
    modalSignupClose = () => this.setState({modalSignup: false});
    modalSignupMemberClose = () => this.setState({modalSignupMember: false});
    modalOtpClose = () => this.setState({modalOtp: false});
    showModalLockAccount = (listData) => this.setState({modalLockAccount: true, contractInfo: listData});
    closeModalLockAccount = () => this.setState({modalLockAccount: false});
    modalWarningInfoClose = () => this.setState({modalWarningInfo: false});
    showModalWarningInfo = () => {
        this.setState({modalWarningInfo: true});
    }
    modalWarningEmailClose = () => this.setState({modalWarningEmail: false});
    showModalWarningEmail = () => {
        this.setState({modalWarningEmail: true});
    }

    showModalForgotPassword3 = (token, phone) => {
        this.setState({
            modalForgotPassword: false,
            modalForgotPassword3: true,
            tokenForgotPass: token,
            phoneNumberForgot: phone
        })
    }
    onCloseModalForgotPassword3 = (open2) => {
        this.setState({
            modalForgotPassword3: false,
        })
        if(open2) {
            this.setState({
                modalForgotPassword2: true,
            })
        }
        // toast.success("Chúng tôi đã gửi đường dẫn vào email của bạn. Vui lòng kiểm tra email!")
    }

    saveOtp = (tokenForgotPass, otp) => {
        this.setState({
            tokenForgotPass,
            otp,
        })
    }

    onCloseModalForgotPassword2 = () => {
        this.setState({
            modalForgotPassword2: false,
        })
    }
    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: this.reformatData(res.data)
            })
            localStorage.setItem("ob_cl_news_cat", JSON.stringify(res.data))
        })
    }
    reformatData = (listData) => {
        let listCate = [];
        listData.forEach(cateInfo => {
            if (cateInfo.parentId == 0){
                listCate.push(cateInfo);
            }
        });
        return listCate;
    }
    renderLoading = () => {
        return (
            <div className="spinner">
                <div className="rect1"/>
                <div className="rect2"/>
                <div className="rect3"/>
                <div className="rect4"/>
                <div className="rect5"/>
            </div>
        )
    }

    showModalForgot = () => {
        this.setState({
            modalLogin: false,
            modalForgotPassword: true
        })
        console.log("showModalForgot")
    }

    onCloseModalForgotPassword = () => {
        this.setState({
            modalForgotPassword: false
        })
    }

    toggleSidebarMobile = () => {
        let sidebar = this.state.sidebarMobile
        this.setState({
            sidebarMobile: !sidebar
        })
    }


    render() {
        let token = Cookies.get('ob_cl_token');
        if (!token) this.logout()
        return (
            <div id="mainContainer">
                <div className="loading"
                     style={this.props.isFetching || this.props.isCommonFetching ? {display: 'block'} : {display: 'none'}}>
                    <div className="lds-dual-ring"></div>
                </div>
                {/*<PreLoader style={window.OBPubConfig.preLoader ? {display: 'block'} : {display: 'none'}}/>*/}
                <TopHeader showModalLockAccount={this.showModalLockAccount} closeModalLockAccount={this.closeModalLockAccount}/>
                {/*{this.props.isAuthenticated && (<HeaderAfterLogin/>)}*/}
                {/*{!this.props.isAuthenticated && (<HeaderBeforeLogin onOpenModalLogin={this.onOpenModalLogin}/>)}*/}
                {/*<HeaderBeforeLogin onOpenModalLogin={this.onOpenModalLogin}/>*/}
                {this.state.haveToken ? <HeaderAfterLogin listCategory={this.state.listCategory}
                                                          showModalWarningInfo={this.showModalWarningInfo}
                                                          showModalWarningEmail={this.showModalWarningEmail} toggleSidebarMobile={this.toggleSidebarMobile}/> :
                    <HeaderBeforeLogin listCategory={this.state.listCategory} onOpenModalLogin={this.onOpenModalLogin}
                                       onOpenModalSignup={this.onOpenModalSignup}
                                       onOpenModalSignupMember={this.onOpenModalSignupMember}  toggleSidebarMobile={this.toggleSidebarMobile}/>}
                {/*<div className='container row'>*/}
                {/*{this.state.haveToken && <Sidebar/>}*/}
                {/*<div className={this.state.haveToken ? "col-md-9" : "col-md-12"}>*/}
                <div className="content-wrapper">
                    {this.props.children}
                </div>
                {/*</div>*/}
                <Footer/>
                {this.state.modalLogin && <ModalLogin show={this.state.modalLogin} onHide={this.modalLoginClose}
                                                      onOpenModalSignup={this.onOpenModalSignup}
                                                      showModalForgot={this.showModalForgot}/>}
                {this.state.modalSignup &&
                <ModalSignup show={this.state.modalSignup} onHide={this.modalSignupClose} setInfo={this.setSignupInfo}
                             showOtp={this.onOpenModalOtp}/>}
                {this.state.modalSignupMember &&
                <ModalSignupMember show={this.state.modalSignupMember} onHide={this.modalSignupMemberClose}/>}
                {this.state.modalOtp &&
                <ModalOtp show={this.state.modalOtp} onHide={this.modalOtpClose} signupInfo={this.state.signupInfo}/>}
                {this.state.modalWarningEmail && <ModalWarningConfirmEmail onHide={this.modalWarningEmailClose}/>}
                {this.state.modalWarningInfo && <ModalWarningCompleteInfo onHide={this.modalWarningInfoClose}/>}
                {this.state.modalLockAccount && <ModalLockAccount onHide={this.modalLockAccount} contractInfo = {this.state.contractInfo}/>}
                {this.state.modalForgotPassword && <ModalForgotPassword show={this.state.modalForgotPassword}
                                                                        setData={this.showModalForgotPassword3}
                                                                        onHideModal={this.onCloseModalForgotPassword}/>}
                {this.state.modalForgotPassword3 && <ModalForgotPassword3 show={this.state.modalForgotPassword3}
                                                                          onHideModal={(open2) => {
                                                                              this.onCloseModalForgotPassword3(open2);
                                                                          }}
                                                                          saveOtp={(tokenForgotPass, otp) => {
                                                                              this.saveOtp(tokenForgotPass, otp)
                                                                          }}
                                                                          token={this.state.tokenForgotPass}
                                                                          name={this.state.phoneNumberForgot}
                />}
                {this.state.modalForgotPassword2 && <ModalForgotPassword2 show={this.state.modalForgotPassword2}
                                                                          onHideModal={() => {
                                                                              this.onCloseModalForgotPassword2();
                                                                          }}
                                                                          otp={this.state.otp}
                                                                          token={this.state.tokenForgotPass}
                                                                          name={this.state.phoneNumberForgot}
                />}
                <SidebarMobile show={this.state.sidebarMobile}
                               toggleSidebarMobile={this.toggleSidebarMobile}
                               onOpenModalLogin={this.onOpenModalLogin}
                               onOpenModalSignup={this.onOpenModalSignup}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // isAuthenticated: state.auth.isAuthenticated,
        isFetching: state.reducer.isFetching,
        // isCommonFetching: state.common.isFetching,
    };
}

export default connect(mapStateToProps)(Layout);
