import React, { Component } from "react";
import {
    Card,
    Button,
    CardContent,
} from '@material-ui/core';
import '../index.css'
// import SelectPlan from "../../../../utils/modal/SelectPlan"
import {Route, Redirect, Router, Link }  from "react-router-dom";

import PublicServicePlanApi from "../../../../services/ob-core/public-service-plan";

const FakeList = [
    {
        img: 'https://material-ui.com/static/images/grid-list/burgers.jpg',
        name: 'KẾ TOÁN THUẾ',
        des:'well meaning and kindly.'
    },
    {
        img: 'https://material-ui.com/static/images/grid-list/burgers.jpg',
        name: 'KẾ TOÁN TRỌN GÓI',
        des:'well meaning and kindly.'
    },
]

class ServicePlan_Advance extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showSelectModal: false,
            listRealm : [],
            id: '',
        }
    }

    componentDidMount() {
        this.getList()
    }

    handleShowModalSelect = () => {
        this.setState({showSelectModal: true})
    };

    SelectModalClose = () => {
        this.setState({showSelectModal: false});
    };

    getList = () => {
        let payload = {
            type: 'ADVANCED'
        };
        PublicServicePlanApi.getByType(payload, res => {
            this.setState({listRealm : res.data})
        })
    };


    render() {
        return (
            <div>
                <div className="col-md-20">
                    {this.state.listRealm.map ((item, index) => {
                        return(
                            <Card className='mt-3 mb-3 border-card-plan'>
                                <CardContent>
                                    <div className='font-weight-bold mb-3'>
                                        {item.servicePlanName}
                                    </div>
                                    <div  dangerouslySetInnerHTML={{ __html: item.content}}/>
                                    <div className='mt-2 mb-2 d-flex justify-content-end'>
                                        <Button
                                            // onClick={()=> this.setState({selected: 2})}
                                            color='primary' variant="contained">
                                            Đăng ký
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        );
                    })}
                </div>

            </div>
        );
    }

}

export default ServicePlan_Advance
