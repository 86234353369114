import React from 'react'
import Pagination from '../../../../components/Pagination'
import TableHeader from '../../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import Autosuggestion from "../../../../components/Form/Autosuggestion";
import ModalAddEditCategory from "../../../../components/Modal/modalAddEditCategory";
import PublicNewsCategory from "../../../../services/ob-core/public-news-category";
import ModalDeleteCategory from "../../../../components/Modal/modalDeleteCategory";
import PublicNews from "../../../../services/ob-core/public-news";
import {toast} from "react-toastify";
import ModalUnHighlightNews from "../../../../components/Modal/admin/modalUnHighlightNews";
import SecureNews from "../../../../services/ob-core/secure-news";

const dateFormat = 'DD-MM-YYYY';

class HotPostManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDetailAdvance: false,
            showEditAdvance: false,

            page: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: '',

            sort: '',
            newsName:"",
            hideNews: "",
            listData: [],
            categoryList: [],
            itemSelected: "",
            modalType: "",
            modalAddEditCategory: false,
            modalUnHighlight: false,
            showLoading: false,
            listNews: [],
            selectedNews: "",
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên bài viết',
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'path',
                    name: 'Danh mục',
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center", minWidth: 120},
                    sort: false
                },

            ],
        }
    }

    componentDidMount() {
        let category = JSON.parse(localStorage.getItem("ob_cl_news_cat"))
        this.setState({showLoading: true, category}, () => {
            this.getData()
        })

    }

    // onSearch = () => {
    //     this.setState({
    //         categoryType: this.state.categoryType_temp,
    //     }, () => {})
    // }

    getData = () => {
        PublicNews.getListHighlight( res => {
            if (res.success) {
                this.setState({
                    showLoading: false,
                    listData: res.data,
                })
            } else {
                toast.error("Đã có lỗi xảy ra!")
            }
        })
    }

    getListNews = () => {
        let {pageSize, pageNumber, startDate_temp, endDate_temp, title, title_temp, categoryId, status, newsName} = this.state;
        let payload = {
            pageNumber,
            pageSize,
            startDate: startDate_temp,
            endDate: endDate_temp,
            title: newsName,
            categoryId,
            status: status
        }
        if (!startDate_temp) delete payload.startDate
        if (!endDate_temp) delete payload.endDate

        SecureNews.getListNews(payload, res => {
            if (res.success) {
                this.setState({
                    listNews: res.data.content,
                },() => console.log("listNew>>", res.data.content))
            } else {
                toast.error('Đã xảy ra lỗi lấy dữ liệu. Vui lòng tải lại trang !')
            }
        })
    }

    renderCategory = (val) => {
        let category = JSON.parse(localStorage.getItem("ob_cl_news_cat"))
        let filter = category.filter((item) => item.id == val)
        if (filter.length > 0) return filter[0].name
    }

    onSelectNews = (selectedNews) => {
        if(this.state.listData.length >= 5) {
            toast.error("Chỉ được tối đa 5 bài viết nổi bật. Vui lòng xóa bớt để thêm mới !")
        } else if (selectedNews.id === "") {
            toast.error("Phải chọn bài viết trước !")
        } else if (selectedNews.hide) {
            toast.error("Bài viết đã bị ẩn vui lòng chọn bài viết khác !")
        } else {
            this.setState({
                modalUnHighlight: true,
                modalType: "highlight",
                itemSelected: selectedNews.id
            })
        }
    }



    render() {
        let {pageNumber, pageSize, accountBalance} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="hot-post-management" parentClass='home-management'/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý bài viết nổi bật"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className='col-4 p-0'>
                                                <label className="label-item">Thêm mới tin nổi bật</label>
                                                <Autosuggestion
                                                    // label="Tên Khách hàng"
                                                    // value={this.state.newsName}
                                                    placeholder="Nhập tên bài viết"
                                                    type="text"
                                                    data={this.state.listNews}
                                                    onSelect={(item) => {
                                                        this.onSelectNews(item)
                                                    }}
                                                    onChange={(val) => {
                                                        console.log("check",val);
                                                        this.setState({newsName: val, selectedNews: null})
                                                        setTimeout(() => {
                                                            this.getListNews()
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.listNews}
                                                    errorContent="Vui lòng chọn danh mục"
                                                />
                                            </div>
                                            {/*<div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>*/}
                                            {/*    <button*/}
                                            {/*        type="button" className="btn border-1-s btn-primary btn-sm"*/}
                                            {/*        style={{height: 35}}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            */}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Thêm mới*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListService())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {/*{row.contractCode}*/}
                                                        {row.title}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {this.renderCategory(row.categoryId)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button type="button" className="btn border-1-s btn-primary btn-sm"
                                                                title='Bỏ nổi bật'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        itemSelected: row.id,
                                                                        modalUnHighlight: true,
                                                                        modalType: "unHighlight"
                                                                    })
                                                                }}
                                                        >
                                                            Bỏ nổi bật
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                {/*<div className="pagination-right">*/}
                                {/*    <Pagination*/}
                                {/*        activePage={this.state.pageNumber + 1}*/}
                                {/*        itemsCountPerPage={this.state.pageSize}*/}
                                {/*        totalItemsCount={this.state.totalItem}*/}
                                {/*        changeHandler={(page) => this.handleChangePage(page)}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*{this.state.showModalAccept && (*/}
                {/*    <ModalAccept*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        contractId={this.state.contractId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalAccept: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{this.state.showModalReject && (*/}
                {/*    <ModalReject*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalReject: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {this.state.modalUnHighlight && <ModalUnHighlightNews show={this.state.modalUnHighlight}
                                                                          modalType={this.state.modalType}
                                                                          item={this.state.itemSelected}
                                                                          onRefresh={() => {this.setState({modalUnHighlight: false})
                                                                              this.getData()
                                                                          }}
                                                                            data={this.state.itemSelected}
                                                                          onHide={()=> this.setState({modalUnHighlight: false})}
                />}

            </div>
        )
    }

}

export default HotPostManagement
