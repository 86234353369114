import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import DocumentApi from "../../services/ob-core/document";

class ModalRejectData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showReject: true,
        };
    }

    componentDidMount() {

    }

    onSubmit = () => {
        let payload = {
            id: this.props.data.id,
            reason: this.state.reason,
        };
        DocumentApi.invalidDocument(payload, res => {
            if (!res.success) {
                return (toast.error("Thao tác thất bại. Vui lòng thử lại!"))
            } else {
                this.props.onRefresh();
                this.props.onHide();
                return (toast.success(`Đã từ chối duyệt "${this.props.data.name}" !`))
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='col justify-content-center mt-3 mb-3'>
                            <label className='font-size-content'>Vui lòng nhập lý từ chối duyệt
                                "{this.props.data.name}"</label>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập vào lý do từ chối duyệt'}
                                maxLength={250}
                                value={this.state.reason}
                                onChange={(event) => {
                                    this.setState({reason: event.target.value})
                                }}
                            />
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    disabled={this.state.reason?.length === 0}
                                    onClick={() => this.onSubmit()}
                                >
                                    Đồng ý
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn "
                                    onClick={this.props.onHide}
                                >
                                    Hủy bỏ
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalRejectData
