/**
 * Created by WebStorm.
 * User:
 * Create:
 * Time:
 *
 * User: Le.Cong.Hai
 * Update: 24/03/2020
 * Time: 12:00
 */

import React, {Component} from "react";
import moment from "moment";
import {toast} from 'react-toastify'

import NumberFormat from "react-number-format";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Grid from "@material-ui/core/Grid";
import CommentInDetail from "../../../common/comment/commentInDetail";
import TableHeader from "../../../../components/TableHeader";

import ModalRatingAccountant from "../../../../components/Modal/modalRatingAccountant";
import ModalRatingSupervisor from "../../../../components/Modal/modalRatingSupervisor";

import PublicPaymentApi from "../../../../services/ob-core/public-payment";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import FilesApi from "../../../../services/resource/files";
import AccountantApi from "../../../../services/ob-user/accountant";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import Rating from "react-rating";
import {checkPermission} from "../../../../utils/common";

const formatDate = "DD-MM-YYYY";

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRateAcc: false,
            showRateSup: false,
            id: null,
            contractId: null,
            dataContract: [],
            listTimeLine: [],
            activeStep: 2,
            activeStep2: 1,
            code: "",
            status: "",
            dataFile: [],
            listMessage: [
                {
                    name: "Admin",
                    mess: "Bạn cần thanh toán đúng hạn!"
                },
                {
                    name: "Thạch",
                    mess: "Mình sẽ thanh toán tất cả trong một lần"
                },
                {
                    name: "Admin",
                    mess: "Cảm ơn bạn rất nhiều."
                },
                {
                    name: "Thạch",
                    mess: "Tôi rất thích One Book For"
                }
            ],
            headerFile: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Dữ liệu",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'file',
                    name: "Loại file",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
            ]
        };
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng";
        this.getDetailFromAPI();
    }

    //******************************************************************************************************************

    getDetailFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.value
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                this.props.setContractStatus(res.data.assignStatus)
                this.setState({
                    dataContract: res.data,
                    listTimeLine: res.data.paymentTimelines,
                }, () => {
                    if (res.data) {
                        this.getNameAccountant()
                        this.getNameSupervisor()
                    }
                });
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }

    getNameAccountant = () => {
        let payload = {
            id: this.state.dataContract.accountantId
        };
        AccountantApi.getNameById(payload, res => {
            if (res.success) {
                let data = this.state.dataContract;
                data.accountantName = res.data
                this.setState({dataContract: data})
            }
        })
    }

    getNameSupervisor = () => {
        let payload = {
            id: this.state.dataContract.supervisorId,
        }
        SupervisorApi.getNameById(payload, res => {
            if (res.success) {
                let data = this.state.dataContract;
                data.supervisorName = res.data
                this.setState({dataContract: data})
            }
        })
    }

    //******************************************************************************************************************

    renderStatusSteper(status) {
        switch (status) {
            case "WAIT_SIGN":
            case 'SIGNED':
                return 1;
            case "NO_ASSIGN":
            case "WAITING_FOR_CONFIRMATION":
            case "REJECTED":
            case "PROCESSING":
                return 2;
            case "LIQUIDATION":
                return 3;
            case "CLOSED":
                return 4;

            default:
                return 0;
        }
    }

    renderStatus(status) {
        switch (status) {
            case "WAIT_SIGN":
                return "Chờ ký";
            case "SIGNED":
                return "Đã ký";
            case "PROCESSING":
                return "Đang thực hiện";
            case "LIQUIDATION":
                return "Đã thanh lý";
            case "CLOSED":
                return "Đóng hợp đồng";
            default:
                return;
        }
    }

    renderPaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return " Đã thanh toán";
            case "UNPAID":
                return "Chưa thanh toán";
            case "PAYMENT_TERM":
                return "Đến hạn thanh toán";

            default:
                return;
        }
    }

    renderActiveStep(listTimeLine) {
        return listTimeLine.filter(value => value.paymentStatus === "PAID").length
    }


    downloadFile = (item) => {
        let payload = {
            id: item.id
        }
        FilesApi.downloadContractDocument2(payload, res => {
            if (res.success) {
                // console.log(res.data)
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${this.state.selectedFile.name}.${res.data.extension}`
                a.click();
            }
        })
    }

    renderScoreRating = (score) => {
        switch (score) {
            case ("ONE_STAR"):
                return 1;
            case ("TWO_STAR"):
                return 2;
            case ("THREE_STAR"):
                return 3;
            case ("FOUR_STAR"):
                return 4;
            case ("FIVE_STAR"):
                return 5;
        }
    }

    //******************************************************************************************************************

    render() {
        let {
            activeStep,
            listTimeLine,
            rows,
            listMessage,
            id,
            dataContract,
            listStatus,
            code,
            status
        } = this.state;
        return (
            <div style={{padding: 0}}>

                <div style={{fontSize: 20}}>TÌNH TRẠNG HỢP ĐỒNG</div>
                {checkPermission("view_status_contract") &&
                <div className="row">
                    <div className="row d-flex" style={{marginLeft: 0, width: 90}}>
                        <div
                            className="align-self-end mt-10"
                            style={{fontSize: 12, marginTop: 54, width: "100%"}}
                        ></div>
                        <div
                            className="align-self-start"
                            style={{fontSize: 12, width: "100%"}}
                        ></div>
                    </div>
                    <Stepper
                        alternativeLabel
                        className="d-flex col-10 justify-content-center"
                        activeStep={this.renderStatusSteper(dataContract.status)}
                    >
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đăng kí thành công</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                {dataContract.assignStatus === "SIGNED" ?<div>Đã kí</div>: <div>Chờ kí</div>}
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đang thực hiện</div>
                            </StepLabel>
                        </Step>

                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Thanh lý hợp đồng</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đã đóng</div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                }
                {/*{dataContract.status == !"WAIT_SIGN" ? (*/}
                <div style={{fontSize: 20}}>THÔNG TIN TÀI KHOẢN THANH TOÁN</div>
                <div style={{fontSize: 15, padding: 15}}>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">
                                Số tài khoản
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>00000000000000000000</span>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">
                                Ngân hàng
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>Teckcomebank</span>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">Chi nhánh</span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}> Hà Nội </span>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">Hỗ trợ thanh toán</span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}> 090011223344 </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <span className="text-black"><span style={{fontWeight: "bold", color: "red"}}> *Lưu ý: </span>Vui lòng chuyển khoản tiền vào số tài khoản trên trước <span style={{fontWeight: "bold"}}> "Hạn thanh toán" </span>  để sử dụng dịch vụ chất lượng nhất.</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <span className="text-black">Hệ thống sẽ tự động cập nhật trạng thái ngay sau khi nhận được tiền thanh toán!</span>
                        </Grid>
                    </Grid>
                </div>
                {checkPermission("view_payment_contract") &&
                <div>
                    <div style={{fontSize: 20}}>TÌNH TRẠNG THANH TOÁN</div>

                    <div className="row">
                        <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                            <div
                                className="align-self-end mt-10"
                                style={{
                                    fontSize: 14,
                                    marginTop: 60,
                                    width: "100%",
                                    minWidth: 110
                                }}
                            >
                                Hạn thanh toán
                            </div>
                            <div
                                className="align-self-start"
                                style={{fontSize: 14, width: "100%"}}
                            >
                                Thực tế
                            </div>
                        </div>
                        <Stepper
                            alternativeLabel
                            className="d-flex col-10 justify-content-center"
                            activeStep={this.renderActiveStep(listTimeLine)}
                        >
                            {listTimeLine.map(row => (
                                <Step style={{width: 0}}>
                                    <StepLabel className="d-flex">
                                        <NumberFormat
                                            value={row.paymentAmount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={" VND"}
                                        />
                                        {row.paymentStatus == "PAYMENT_TERM" ? (
                                            <div style={{color: "red"}}>
                                                {moment(row.paymentTerm).format(formatDate)}
                                            </div>
                                        ) : (
                                            <div>{moment(row.paymentTerm).format(formatDate)}</div>
                                        )}
                                        {row.paymentReal ? (
                                            <div>{moment(row.paymentReal).format(formatDate)}</div>
                                        ) : (
                                            <div>&nbsp;&nbsp;</div>
                                        )}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
                }
                {/*) : (*/}
                {/*    ''*/}
                {/*)}*/}
                <div style={{fontSize: 20}}>THÔNG TIN CHI TIẾT HỢP ĐỒNG</div>
                <div style={{fontSize: 15, padding: 15}}>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">
                                Tên hợp đồng
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>{dataContract.name}</span>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">
                                Tên khách hàng
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>{dataContract.customerName}</span>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">Giá trị hợp đồng</span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>
                                <NumberFormat
                                    value={dataContract.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={"đ"}
                                />
                            </span>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">Loại hợp đồng
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span
                                style={{fontWeight: "bold"}}>{dataContract.type == "NEW" ? "Hợp đồng mới" : "Hợp đồng gia hạn"}</span>
                        </Grid>

                        {/*<Grid item xs={12} sm={4} md={4}>*/}
                        {/*    <span className="text-black">*/}
                        {/*        Kế toán xử lí*/}
                        {/*    </span>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={7} md={7}>*/}
                        {/*    <span style={{fontWeight: "bold"}}>{dataContract.accountantName || "Chưa có kế toán"}</span>*/}
                        {/*</Grid>*/}


                        <Grid item xs={12} sm={4} md={4}>
                            <span className="text-black">
                                Loại dịch vụ
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <span style={{fontWeight: "bold"}}>{dataContract.servicePlanName}</span>
                        </Grid>
                    </Grid>
                </div>

                <div className="row mb-3">
                    <div className='col-md-12 p-0 mt-2 mb-2' style={{fontSize: 20}}>
                        <span>FILE Hợp Đồng</span>
                    </div>
                    <div className='col-md-12' style={{overflow: 'auto'}}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerFile} clickSort={(data) => {
                            }}/>
                            </thead>
                            {this.state.dataContract.contractDocuments?.length > 0
                                ?
                                <tbody>
                                {this.state.dataContract.contractDocuments.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                        <td style={{textAlign: 'center'}}>{row.contentType}</td>
                                        <td style={{textAlign: 'center'}}>
                                            <button
                                                className="btn btn-action"
                                                title="Tải xuống"
                                                onClick={() => this.downloadFile(row)}
                                            >
                                                <i className="ti-download"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                : <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>}
                        </table>
                    </div>
                </div>


                <div className="row ">
                    <div className="col-6 pl-0 mb-2" style={{fontSize: 20}}>
                        COMMENT
                    </div>
                    <div className="col-6 d-flex justify-content-end"></div>
                </div>
                <div className='pl-3 pr-3'>
                    <CommentInDetail contractId={this.props.idContract}/>
                </div>

                {dataContract.status === "LIQUIDATED" || dataContract.status === "CLOSED" ? (
                    <div>
                        <div
                            className="row mt-4 pt-3"
                            style={{
                                borderTop: "1px dotted #516f7782"
                            }}
                        >
                            <div className="col-sm-4 col-md-4 p-0 ">
                                <span> ĐÁNH GIÁ KẾ TOÁN</span>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <Rating
                                    start={0}
                                    stop={5}
                                    initialRating={dataContract.reviewAccountantScore ? this.renderScoreRating(dataContract.reviewAccountantScore) : 0}
                                    emptySymbol={
                                        <i
                                            style={{fontSize: 20, color: '#1b90bd'}}
                                            className="far fa-star m-1"
                                        />
                                    }
                                    fullSymbol={
                                        <i
                                            style={{fontSize: 20, color: "#1b90bd"}}
                                            className="fas fa-star m-1"
                                        />
                                    }
                                    readonly
                                    // onChange={(val) => {
                                    //     this.renderRating(val)
                                    //     // this.setState({rate: val})
                                    // }}
                                />
                                {/*dataContract.reviewAccountantComment*/}
                                {/*dataContract.reviewSupervisorComment*/}
                                {/*dataContract.reviewSupervisorScore*/}
                            </div>
                            <div className="col-4">
                                {dataContract.reviewAccountantComment ?
                                    <div>
                                        <p>"{dataContract.reviewAccountantComment}"</p>
                                    </div> :
                                    <button
                                        className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                                        style={{height: 37, marginLeft: 10}}
                                        onClick={() => this.setState({showRateAcc: true})}
                                    >
                                        Đánh giá
                                    </button>
                                }
                            </div>
                        </div>
                        <div
                            className="row mt-4 pt-3"
                            style={{
                                borderTop: "1px dotted #516f7782"
                            }}
                        >
                            <div className="col-sm-4 col-md-4 p-0 ">
                                <span> ĐÁNH GIÁ SUPERVISOR</span>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <Rating
                                    start={0}
                                    stop={5}
                                    readonly
                                    initialRating={dataContract.reviewSupervisorScore ? this.renderScoreRating(dataContract.reviewSupervisorScore) : 0}
                                    emptySymbol={
                                        <i
                                            style={{fontSize: 20, color: '#1b90bd'}}
                                            className="far fa-star m-1"
                                        />
                                    }
                                    fullSymbol={
                                        <i
                                            style={{fontSize: 20, color: "#1b90bd"}}
                                            className="fas fa-star m-1"
                                        />
                                    }
                                />
                            </div>
                            <div className="col-sm-4 col-md-4">
                                {dataContract.reviewSupervisorScore ?
                                    <div>
                                        <p><i>"{dataContract.reviewSupervisorComment}"</i></p>
                                    </div> :
                                    <button
                                        className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                                        style={{height: 37, marginLeft: 10}}
                                        onClick={() => this.setState({showRateSup: true})}
                                    >
                                        Đánh giá
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                ) : (
                    ""
                )}
                {/*<div className="row" style={{ fontSize: 20 }}>*/}
                {/*  <div className="col-6 pl-0 " style={{ fontSize: 20 }}>*/}
                {/*    <TextField*/}
                {/*      id="outlined-basic"*/}
                {/*      value={this.state.mess}*/}
                {/*      onChange={e => this.setState({ mess: e.target.value })}*/}
                {/*      size={"small"}*/}
                {/*      style={{ height: 30, width: "100%" }}*/}
                {/*      placeholder="Bình luận"*/}
                {/*      variant="outlined"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="col-6 d-flex justify-content-start">*/}
                {/*    <Button*/}
                {/*      variant="contained"*/}
                {/*      onClick={() => {*/}
                {/*        let { listMessage, mess } = this.state;*/}
                {/*        listMessage.unshift({*/}
                {/*          name: "Thạch",*/}
                {/*          mess: mess*/}
                {/*        });*/}
                {/*        this.setState({ listMessage, mess: "" });*/}
                {/*      }}*/}
                {/*      style={{ height: 37, marginLeft: 10 }}*/}
                {/*      color="primary"*/}
                {/*    >*/}
                {/*      Bình luận*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*      disabled={true}*/}
                {/*      style={{ hieght: 37, marginLeft: 10 }}*/}
                {/*      variant="contained"*/}
                {/*      color="primary"*/}
                {/*    >*/}
                {/*      Đánh giá*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*/!*<div style={{height:200,overflowY:'scroll',overflowX:'hidden'}}>*!/*/}
                {/*<div>*/}
                {/*  {listMessage.map(obj => (*/}
                {/*    <div className="d-flex ml-1 mt-1 row justify-content-start">*/}
                {/*      <div className="m-1 font-weight-bold">{obj.name + ":"}</div>*/}
                {/*      <div className="m-1">{obj.mess}</div>*/}
                {/*    </div>*/}
                {/*  ))}*/}
                {/*</div>*/}
                {this.state.showRateAcc && <ModalRatingAccountant
                    contract={this.state.dataContract}
                    onHide={() => this.setState({showRateAcc: false})}
                    onRefresh={() => {
                        this.setState({showRateAcc: false})
                        this.getDetailFromAPI()
                    }}
                />}
                {this.state.showRateSup && <ModalRatingSupervisor
                    contract={this.state.dataContract}
                    onRefresh={() => {
                        this.setState({showRateSup: false})
                        this.getDetailFromAPI()
                    }}
                    onHide={() => this.setState({showRateSup: false})}
                />}

            </div>
        );
    }
}

export default Information;
