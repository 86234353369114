export default {
    header: {

    },
    footer: {

    },
    menu: {
        guest: 'Khách',
        profile: 'Thông tin cá nhân',
        listBrandname: 'Danh sách Brandname',
        listPhoneNumber: 'Danh sách số điện thoại',
        listRequest: 'Danh sách yêu cầu',
        createRequest: 'Gửi yêu cầu',
        logout: 'Đăng xuất'
    }
}
