import React from 'react';
import {toast} from 'react-toastify';
import {
    Modal,
} from 'react-bootstrap';
import UserWaitingApi from "../../services/ob-user/user-waiting";

class ModalInfoSignupMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: this.props.show ? this.props.show : true,
            data: this.props.data ? this.props.data : {},
            files: this.props.data ? this.props.data.certificate : '',

        };
    }

    renderProvinceId = (provinceId) => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
        return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
    };

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_CONFIRM':
                return <h6><span className="badge badge-secondary">Chờ xử lý</span></h6>;
            case "CREATE_SUB_ACCOUNT":
                return <h6><span className="badge badge-warning">Tài khoản dự bị</span></h6>;
            case "CALL_CENTER_RECEIVE":
                return <h6><span className="badge badge-success">Call Center đã nhận</span></h6>;
            case "CREATE_OFFICIAL_ACCOUNT":
                return <h6><span className="badge badge-primary">Chờ tạo tài khoản chính thức</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            default:
                return '';
        }
    };

    renderPosition = (status) => {
        switch (status) {
            case 'Accountant':
                return <label>Kế toán</label>;
            case "Supervisor":
                return <label>Supervisor</label>;
            default:
                return;
        }
    };

    handling = () => {
        let {data} = this.state;
        let payload = {
            id: data.id
        }
        // console.log(payload)
        UserWaitingApi.receiveApplication(payload, res => {
            if (res.success) {
                toast.success(`Nhận xử lý tài khoản mới "${this.state.data.fullName}" thành công !`)
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error('Đã xảy ra lỗi. Nhận xử lý không thành công !')
            }
        })
    }

    render() {
        const modalProp = {
            show: this.state.show, onHide: this.props.onHide, keyboard: false, backdrop: 'static',
        };
        let userType = localStorage.getItem("ob_cl_userType");
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-medium">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="loginmodal-container">
                            <h1 className="text-center">Thông tin đăng ký tài khoản {this.state.type}</h1>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Họ và tên</label>
                                <span className="col-7">{this.state.data.fullName || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Số điện thoại</label>
                                <span className="col-7">{this.state.data.phone || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Email</label>
                                <span className="col-7">{this.state.data.email || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Tỉnh/Thành phố</label>
                                <span className="col-7">{this.renderProvinceId(this.state.data.provinceId)}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Kinh nghiệm làm việc</label>
                                <span
                                    className="col-7">{this.state.data.exp_work ? `"${this.state.data.exp_work}"` : 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Đơn xin gia nhập</label>
                                <span
                                    className="col-7">{this.state.data.applicationToJoin ? `"${this.state.data.applicationToJoin}"` : 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Khu vực muốn tham gia</label>
                                <span className="col-7">{this.renderProvinceId(this.state.data.areaId)}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Chứng chỉ</label>
                                <div className='col-7 m-0 pl-0'>
                                    {/*{this.state.files?.map((item, index) => <span>{item.name}</span>)}*/}
                                    {this.state.files ?
                                        <span className="col-7 cursor-pointer text-primary">{this.state.files}</span> :
                                        <span className='col-7'>Chưa có dữ liệu</span>}
                                </div>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Vị trí muốn tham gia</label>
                                <div className='col-7'>
                                    <label className="form-check-label">
                                        {this.renderPosition(this.state.data.job)}
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Call Center phụ trách</label>
                                <span className="col-7">{this.state.data.callCenterName || 'Chưa có'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Trạng thái</label>
                                <span className="col-7">{this.renderStatus(this.state.data.status)}</span>
                            </div>

                            {!this.state.callCenterName && userType === 'CALL_CENTER' &&
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    disabled={this.state.data.status !== 'WAIT_CONFIRM'}
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary shadow-none"
                                    onClick={() => {
                                        this.handling()
                                    }}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Nhận xử lý
                                    </span>
                                </button>
                            </div>}
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default ModalInfoSignupMember
