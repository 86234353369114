import React from 'react';
import {withSnackbar} from "notistack";
import Utils from '../../utils/utils'
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import Dropzone from "react-dropzone";

import SupervisorApi from "../../services/ob-user/supervisor";
import FilesApi from "../../services/resource/files";
import {toast} from "react-toastify";

class ModalAddSupervisor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            contract: '',
            maxAccountant: '',
            maxContract: '',
            id: '',

            signupInfo: null,

            nameErr: false,
            phoneErr: false,
            emailErr: false,
            maxcontractErr: false,
            maxAccountantErr: false,

            files: [],
            fileNameFile: '',
            fullNameFile: '',
            FileSave: [],

            btnStatus: false,
        };
    }

    componentWillMount() {
        this.getInfoUser();
    }

    componentDidMount() {
        this.setState({
            name: '',
            phone: '',
            email: '',
            contract: '',
            files: [],
            maxAccountant: '',
            maxContract: '',
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            maxcontractErr: false,
            maxAccountantErr: false,
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                name: '',
                phone: '',
                email: '',
                contract: '',
                files: [],
                maxAccountant: '',
                maxContract: '',
                nameErr: false,
                phoneErr: false,
                emailErr: false,
                maxcontractErr: false,
                maxAccountantErr: false,
                fileError: false,
                btnStatus: false,
            });
        }
    }

    uploadFile = () => {
        console.log("file upload>>>>>>", this.state.files);
        let payload = {
            file: this.state.files,
        }

        FilesApi.uploadSecureFile(payload, res => {
            if (res.success) {
                this.setState({
                    fileNameFile: this.state.files[0].name,
                    FileSave: res.data,
                }, () => {
                    //     // this.props.onHide()
                    toast.success('Upload thành công!')
                })
            }

            // console.log("upload secure response>>>>>>>>>", res);
        }).catch(error => console.log("upload error>>>", error))

    }

    getInfoUser() {
        let data = JSON.parse(localStorage.getItem('ob_cl_userInfo'));
        // console.log('CHECKKKK', data);
        this.setState({data}, () => {
            this.setState({id: data.id})
        });
    };

    onSubmit = () => {
        if (this.state.name.length == 0 || this.state.phone.length == 0 || this.state.email.length == 0 || this.state.maxContract.length == 0 || this.state.maxAccountant.length == 0) {
            this.setState({
                nameErr: this.state.name.length == 0,
                phoneErr: this.state.phone.length == 0,
                emailErr: this.state.email.length == 0,
                maxcontractErr: this.state.maxContract.length == 0,
                maxAccountantErr: this.state.maxAccountant.length == 0
            });
        }else if (this.state.fileNameFile == '' || this.state.urlFile == '') {
            toast.error('Vui lòng tải file chứng chỉ !')
            this.setState({
                fileError: true
            });
        } else {
            this.addAccountant()
        }
    }

    addAccountant = () => {
        let payload = {
            // experience: 0,
            fileNameFile: this.state.fileNameFile,
            fullName: this.state.name,
            fullNameFile: "Chứng chỉ hành nghề",
            maxAccountant: this.state.maxAccountant,
            maxContract: this.state.maxContract,
            partnerId: this.state.id,
            password: '12345678@',
            phone: this.state.phone,
            email: this.state.email,
            storageName: this.state.FileSave[0].storageName,
            destination: this.state.FileSave[0].destination,
        };
        console.log('CHECK_PAYLOAD', payload)
        SupervisorApi.partnerCreate(payload, res => {
            if (res.errorCode == "One-Book-Auth.E000003") {
                toast.error(res.message[0])
            } else if (res.errorCode == "One-Book-User.E000020") {
                toast.error(res.message[0])
            } else if (res.success) {
                toast.success('Tạo mới Supervisor thành công !')
                // this.props.onHide();
                this.props.onRefresh();
            } else {
                toast.error('Tạo mới không thành công, vui lòng thử lại !')
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const files = this.state.files?.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body className="modal-create-new-sup">
                        <div className="modal-container ">
                            <div>
                                <h1 className="text-center" style={{fontSize: 20,}}><b>TẠO MỚI SUPERVISOR</b></h1>
                            </div>

                            <div>
                                <div className="row">
                                    <label className="col-6 mt-2">Họ tên
                                        <span className={'color-red d-inline'}>*</span></label>
                                    <input
                                        className={this.state.nameErr ? "inputPassword w-100 col-6 border-input-error kt-input pd-4-input hei-input" : "inputPassword w-100 col-6 kt-input pd-4-input hei-input"}
                                        placeholder="Nhập vào họ tên"
                                        maxLength={50}
                                        value={this.state.name}
                                        onChange={(e) => this.setState({
                                                name: e.target.value.trimLeft()
                                            },
                                            () => {
                                                this.state.name.length == 0 ? this.setState({
                                                    nameErr: true,
                                                    btnStatus: true
                                                }) : this.setState({nameErr: false, btnStatus: false})
                                            }
                                        )}
                                    />
                                </div>
                                {this.state.nameErr ? <div className="row">
                                    <label className="col-6 mb-0"></label>
                                    <label className="col-6 font-err mb-0">Tên đang để trống !</label>
                                </div> : <div/>}

                                <div className={this.state.phoneErr ? "row mt-2" : "row mt-3"}>
                                    <label className="col-6 mt-2">Số điện thoại
                                        <span className={'color-red d-inline'}>*</span> </label>
                                    <input
                                        className={this.state.phoneErr ? "inputPassword w-100 col-6 border-input-error kt-input pd-4-input hei-input" : "inputPassword w-100 col-6 kt-input pd-4-input hei-input"}
                                        placeholder="Nhập vào SĐT"
                                        fullWidth
                                        maxLength={11}
                                        value={this.state.phone}
                                        onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                            () => {
                                                this.state.phone.length < 10 || this.state.phone.length == 0 ? this.setState({
                                                    phoneErr: true,
                                                    btnStatus: true
                                                }) : this.setState({phoneErr: false, btnStatus: false})
                                            }
                                        )}
                                    />
                                </div>
                                {this.state.phoneErr ? <div className="row">
                                    <label className="col-6 mb-0"></label>
                                    <label className="col-6 font-err mb-0">SĐT không đúng hoặc đang để trống !</label>
                                </div> : <div/>}

                                <div className={this.state.emailErr ? "row mt-2" : "row mt-3"}>
                                    <label className="col-6 mt-2">Email
                                        <span className={'color-red d-inline'}>*</span></label>
                                    <input
                                        className={this.state.emailErr ? "inputPassword w-100 col-6 border-input-error kt-input pd-4-input hei-input" : "inputPassword w-100 col-6 kt-input pd-4-input hei-input"}
                                        placeholder='Nhập Email'
                                        fullWidth
                                        maxLength={100}
                                        value={this.state.email}
                                        onChange={(e) => {
                                            if (Utils.checkEmailRegex(e.target.value.trim())) {
                                                // khoong dudng dinh dang
                                                this.setState({
                                                    email: e.target.value.trim(),
                                                    emailErr: true,
                                                    btnStatus: true
                                                })
                                            } else {
                                                this.setState({
                                                    email: e.target.value.trim(),
                                                    emailErr: false,
                                                    btnStatus: false
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                {this.state.emailErr ? <div className="row">
                                    <label className="col-6 mb-0"></label>
                                    <label className="col-6 font-err mb-0">Email không đúng hoặc đang để trống !</label>
                                </div> : <div/>}

                                <div className={this.state.maxAccountantErr ? "row mt-2" : "row mt-3"}>
                                    <label className="col-6 mt-2">Số kế toán phụ trách
                                        <span className={'color-red d-inline'}>*</span></label>
                                    <input
                                        type="email"
                                        className={this.state.maxAccountantErr ? "inputPassword w-100 col-6 border-input-error kt-input pd-4-input hei-input" : "inputPassword w-100 col-6 kt-input pd-4-input hei-input"}
                                        placeholder='Nhập số kế toán'
                                        fullWidth
                                        maxLength={2}
                                        value={this.state.maxAccountant}
                                        onChange={(e) => this.setState({maxAccountant: e.target.value.trim().replace(/[^0-9]/g, '')},
                                            () => {
                                                this.state.maxAccountant.length == 0
                                                || this.state.maxAccountant == '00'
                                                || this.state.maxAccountant == '0' ? this.setState({
                                                    maxAccountantErr: true,
                                                    btnStatus: true
                                                }) : this.setState({maxAccountantErr: false, btnStatus: false})
                                            })}
                                    />
                                </div>
                                {this.state.maxAccountantErr ? <div className="row">
                                    <label className="col-6 mb-0"></label>
                                    <label className="col-6 font-err mb-0">Số kế toán không đúng hoặc đang để trống
                                        !</label>
                                </div> : <div/>}

                                <div className={this.state.maxcontractErr ? "row mt-2" : "row mt-3"}>
                                    <label className="col-6 mt-2">Số hợp đồng được thực hiện
                                        <span className={'color-red d-inline'}>*</span></label>
                                    <input
                                        className={this.state.maxcontractErr ? " w-100 col-6 border-input-error kt-input pd-4-input hei-input" : "inputPassword w-100 col-6 kt-input pd-4-input hei-input"}
                                        placeholder='Số hợp đồng'
                                        fullWidth
                                        maxLength={3}
                                        value={this.state.maxContract}
                                        onChange={(e) => this.setState({maxContract: e.target.value.trim().replace(/[^0-9]/g, '')},
                                            () => {
                                                this.state.maxContract.length == 0
                                                || this.state.maxContract == '00'
                                                || this.state.maxContract == '0' ? this.setState({
                                                    maxcontractErr: true,
                                                    btnStatus: true
                                                }) : this.setState({maxcontractErr: false, btnStatus: false})
                                            })}
                                    />
                                </div>

                                {this.state.maxcontractErr ? <div className="row">
                                    <label className="col-6 mb-0"></label>
                                    <label className="col-6 font-err mb-0">Số hợp đồng không đúng hoặc đang để trống
                                        !</label>
                                </div> : ''}

                                <div className="row">
                                    <label className="col-6 mt-3">Mật khẩu</label>
                                    <label className="col-6 mt-3">12345678@</label>
                                </div>

                                <div className="row">
                                    <label className="col-6 mt-3">Chứng chỉ hành nghề<span
                                        className={'color-red d-inline'}>*</span></label>
                                    {/*<label className="col-5 mt-3 color-blue" onClick={()=>{}}>Upload</label>*/}
                                    <div className="w-auto">
                                        <div className="form-group mx-0 mb-4">
                                            <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                                Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.
                                            </div>
                                            <Dropzone accept="image/*, application/pdf"
                                                      onDrop={acceptedFiles => {
                                                          console.log(acceptedFiles)
                                                          this.setState({files: acceptedFiles})
                                                          // this.setState({
                                                          //     filesCertificate: acceptedFiles.map(file => Object.assign(file, {
                                                          //         preview: URL.createObjectURL(file)
                                                          //     }))
                                                          // })
                                                      }}
                                                      multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files && files.length > 0 ? <div className="align-center">
                                                                <ul>{files}</ul>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <div className="align-right mt-3 mb-2">
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                                    disabled={this.state.files.length === 0}
                                                    onClick={this.uploadFile}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Upload
                                                </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div container item xs={12}>
                                <div item xs={12}>
                                    <div className="align-right mt-3 mb-2">
                                        <button
                                            type="button"
                                            className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                            disabled={this.state.btnStatus}
                                            onClick={this.onSubmit}
                                        >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-4 col-2 border-1-s w-50 type-button-fix btn btn-light"
                                            // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                            onClick={this.props.onHide}
                                        >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAddSupervisor;
