import React from 'react';
import logo_1 from '../../../../assets/images/logo_1.png';
import a3 from '../../../../assets/images/about/a3.png';
import money from '../../../../assets/images/service/money.png';
import develop from '../../../../assets/images/service/develop.png';
import coOperate from '../../../../assets/images/service/co-operate.png';
import './style.css';

class KeToan extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='about-us-wrapper'>
        <div className='ab-content container'>
          <div className='row bg-white ab-block'>
            <div className='col-sm-12 text-part flex-wrap'>
              <div className='col-sm-1'>
              </div>
              <div className='col-sm-11'>
                <div className='ab-content-title'>
                  Dịch vụ kế toán
                </div>
                <div className='ab-content text-left'>
                  <p className='custom-text'>
                    Để các nhà lãnh đạo doanh nghiệp yên tâm chỉ cần tập trung vào việc điều hành và phát triển
                    kinh doanh, công việc kế toán hãy để hệ thống SAS lo” với chi phí hợp lý, tối thiểu hóa bộ máy
                    nhân sự cho doanh nghiệp, tránh các rủi ro nhân sự kế toán nghỉ việc chưa có người thay thế,
                    nền tảng của chúng tôi sẽ đáp ứng các yêu cầu này của khách hàng ở mọi lúc và mọi nơi.
                    Các dịch vụ kế toán của chúng tôi bao gồm:
                  </p>
                  <p>
                    <ul className='list-op black'>
                      <li className='op1'><span class="dot"></span>Khách hàng vào nền tảng, tìm hiểu dịch vụ, kê khai thông tin doanh nghiệp, xác định
                        mức phí dịch vụ phù hợp, lựa trọn tổ chức và cá nhân phục vụ, đặt cọc hoặc tạm ứng
                        phí dịch vụ theo qui định</li>
                      <li className='op1'><span class="dot"></span>Khách hàng vào nền tảng, tìm hiểu dịch vụ, kê khai thông tin doanh nghiệp, xác định
                        mức phí dịch vụ phù hợp, lựa trọn tổ chức và cá nhân phục vụ, đặt cọc hoặc tạm ứng
                        phí dịch vụ theo qui định</li>
                    </ul>
                  </p>
                  <p>
                    <ul className='list-op blue'>
                      <li className='op1'><span className="dot"></span>Khách hàng có thể yêu cầu kế toán viên phục vụ đến văn phòng của khách hàng để xem
                        chứng từ và thao tác nghiệp vụ tại địa chỉ đã đăng ký và được chấp thuận
                      </li>
                      <li className='op1'><span className="dot"></span>Khách hàng có thể yêu cầu kế toán viên phục vụ đến văn phòng của khách hàng để xem
                        chứng từ và thao tác nghiệp vụ tại địa chỉ đã đăng ký và được chấp thuận
                      </li>
                    </ul>
                  </p>
                  <p>
                    <ul className='list-op black'>
                      <li className='op1'><span className="dot"></span>Khách hàng cũng có thể mang chứng từ đến địa điểm đã đăng ký và được chấp thuận,
                        sau khi cập nhật kế toán xong thì mang về (nếu khách hàng đăng ký phục vụ hình thức này)
                      </li>
                      <li className='op1'><span className="dot"></span>Các chuyên gia kế toán bậc cao sẽ soát xét lại toàn bộ các nghiệp vụ kế toán do các
                        kế toán viên soạn lập để đảm bảo các nghiệp vụ đó không còn những sai soát trọng yếu
                      </li>
                    </ul>
                  </p>
                  <p>
                    <ul className='list-op blue'>
                      <li className='op1'><span className="dot"></span>Định kỳ, các báo cáo tiêu chuẩn được tự động gửi đến khách hàng và kho dữ liệu riêng
                        của khách hàng trên hệ thống đồng thời với gửi qua email hoặc hình thức khác theo thỏa thuận
                      </li>
                      <li className='op1'><span className="dot"></span>Khách hàng có thể tự chiết xuất, in các báo cáo kế toán, các bản kê khai thuế ký và nộp
                        bên liên quan. Các báo cáo kế toán cơ bản được tự động lập và gửi cho khách hàng bao gồm:
                        Bảng cân đối kế toán; Bảng cân đối phát sinh các tài khoản; Báo cáo kết quả kinh doanh;
                        Báo cáo lưu chuyển tiền tệ; Báo cáo tình hình công nợ phải thu; Báo cáo tình hình công nợ phải trả;
                        Báo cáo tổng hợp nhập xuất tồn kho hàng hóa; Báo cáo tình hình thanh toán với
                        ngân sách Nhà nước; (Các báo cáo có bổ sung trong quá trình nâng cấp hệ thống nếu có)
                      </li>
                      <li className='op1'><span className="dot"></span>Các báo cáo kế toán tiêu chuẩn sẽ được hệ thống tự thiết lập theo yêu cầu của khách hàng tại
                        bất cứ thời điểm nào, tại bất cứ đâu có thể kết nối internet và báo cáo trực tiếp cho khách hàng.
                      </li>
                      <li className='op1'><span className="dot"></span>Trường hợp khách hàng đăng ký thêm dịch vụ ký báo cáo kế toán, ký và nộp
                        các báo cáo thuế trên SAS và đã chỉ định người phục vụ thì người phuc vụ
                        trên và khách hàng phải trả thêm phí dịch vụ soát xét và ký báo cáo theo qui
                        định; Hệ thống dữ liệu kế toán của khách hàng trong thời gian sử dụng dịch
                        vụ của hệ thống sẽ được lưu giữ trên hệ thống miễn phí và được tiếp tục lưu
                        giữ miễn phí 01 năm kể từ ngày chấm dứt dịch vụ trừ trường hợp khách hàng
                        yêu cầu không lưu giữ tiếp.
                      </li>
                    </ul>
                  </p>
                  <p className='custom-text'>
                    Ngoài dịch vụ kế toán cơ bản, khách hàng có thể yêu cầu thêm các dịch vụ nâng cao khác như:
                  </p>
                  <div className='row bg-white list-advanced'>
                    <div  className='col-sm-4'>
                      <img src={money} className='mr-3'/>
                      <p className='custom-text'>
                        Dịch in đóng sổ kế toán bằng
                        giấy;
                        Dịch vụ cung cấp thêm các báo
                        cáo phân tích các chỉ số tài chính
                        cơ bản;
                        Dịch vụ lưu trữ dữ liệu và Báo
                        cáo kế toán sau khi kết thúc dịch
                        vụ trên 01 năm;
                        Dịch vụ thuê phần mềm kế toán
                        và lưu trữ dữ liệu kế toán, không
                        sử dụng dịch vụ kế toán mà tự bố
                        trí nhân viên của mình thực hiện
                        các công tác kế toán trên hệ
                        thống;
                      </p>
                    </div>
                    <div  className='col-sm-4'>
                      <img src={coOperate} className='mr-3'/>
                      <p className='custom-text'>
                        Trợ giúp lập các báo cáo tài
                        chính, báo cáo kế toán quản trị
                        đặc thù;
                        Dịch vụ chuyển đổi báo cáo tài
                        chính theo chuẩn mực trình bày
                        báo cáo tài chính quốc tế (ISRF)
                        hoặc theo chế độ kế toán đặc thù
                        khác.
                      </p>
                    </div>
                    <div  className='col-sm-4'>
                      <img src={develop} className='mr-3'/>
                      <p className='custom-text'>
                        Tư vấn xây dựng hệ thống báo
                        cáo kế toán quản trị
                        Tư vấn xây dựng hệ thống tài
                        khoản kế toán chi tiết áp dụng cụ
                        thể tại doanh nghiệp;
                        Tư vấn hướng dẫn các quy trình
                        hạch toán các nghiệp vụ chủ yếu;
                        Tư vấn xây dựng hệ thống mẫu
                        biểu chứng từ và hướng dẫn
                        trình tự luân chuyển chứng từ;
                        Tư vấn lựa chọn hệ thống phần
                        mềm kế toán thích hợp
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KeToan;
