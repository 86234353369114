import {stringify} from 'qs';
import {URL_API} from "../../url.config";
import {request, requestDownload, requestUpload, requestUploadPublic} from "../../utils/apiUtils";
import axios from "axios";

const PublicFilesApi = {
    uploadPublicFile: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('files', item)
        })
        return requestUploadPublic({
            url: `${URL_API}/api/one-book-resource/public/upload-file`,
            method: 'POST',
            body: data,
        }, callback);
    },
}

export default PublicFilesApi

