import React, {Component} from 'react';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";

import Information from './Information';
import HistoryUpdate from './HistoryUpdate';
import ContractInformation from './ContractInformation'
import {Link} from "react-router-dom";
import Convert from "../../../../utils/convertUrlPra";

class AccountantDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onTab: 1,
            idAccountant: this.props.location.state ? this.props.location.state.accountantId : '',
            accountantId: null,
            supervisorId: null,
        };
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        this.setState({
            accountantId: params.accountantId,
            supervisorId: profile.id
        })
    }

    componentDidMount() {
        window.OBPubConfig.loading = false
        // console.log("accountantId>>>>>>>", this.state.accountantId)
        // console.log(this.props)
        // console.log(this.props.location.state.accountantId)
    }


    render() {
        const {reason, onTab} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="accountant-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className='card p-3 mb-4'>
                                {/*<PageHeader routerEnable={true} title="Chi tiết kế toán"/>*/}
                                <span>
                                    <Link to={"/supervisor/accountant-management"}>
                                        <i className="fas fa-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                <h4 className='text-center mb-4' style={{fontSize: 20}}>Thông tin chi tiết kế toán</h4>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={onTab === 1 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-home-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-home" onClick={() => this.setState({onTab: 1})}
                                           aria-selected="true">Thông tin chung</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={onTab === 3 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-contact-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-contact" onClick={() => this.setState({onTab: 3})}
                                           aria-selected="false">Thông tin hợp đồng</a>
                                    </li>
                                </ul>
                                {/*<nav className="nav nav-tabs justify-content-center" role="tablist">*/}
                                {/*    <a*/}
                                {/*        className={onTab === 1 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}*/}
                                {/*        onClick={() => this.setState({onTab: 1})}*/}
                                {/*    >*/}
                                {/*        Thông tin chung*/}
                                {/*    </a>*/}
                                {/*    <a*/}
                                {/*        className={onTab === 3 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}*/}
                                {/*        onClick={() => this.setState({onTab: 3})}*/}
                                {/*    >*/}
                                {/*        Thông tin hợp đồng*/}
                                {/*    </a>*/}
                                {/*</nav>*/}
                                <div className="tab-content">
                                    <div
                                        className={onTab === 1 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <Information {...this.props} accountantId={this.state.accountantId}/>
                                    </div>
                                    {/*<div*/}
                                    {/*    className={onTab === 2 ? "fade tab-pane active show" : "fade tab-pane"}*/}
                                    {/*>*/}
                                    {/*    <HistoryUpdate idAccountant={this.state.idAccountant}/>*/}
                                    {/*</div>*/}
                                    <div
                                        className={onTab === 3 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ContractInformation {...this.props} accountantId={this.state.accountantId} supervisorId={this.state.supervisorId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AccountantDetail
