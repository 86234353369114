import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"

import Dropzone from "react-dropzone";
import {
    Modal,
} from 'react-bootstrap';

import FilesApi from "../../services/resource/files";

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            showUpload: true,
            files: [],
            filesCertificate: [],
            url: '',
            fileName: '',
        };
    }

    componentDidMount() {

    }

    uploadReport = () => {

        // console.log("file upload>>>>>>",this.state.files);
        let payload = {
            file: this.state.files,
            contractId: this.props.contractId
        }

        FilesApi.uploadReportFile(payload, res => {
            if (res.success) {
                this.setState({
                    files: []
                }, () => {
                    this.props.reload()
                    // this.props.enqueueSnackbar('Upload thành công!', {
                    //     variant: 'success',
                    // });
                })
            }

        }).catch(error => console.log("upload error>>>", error))

    }

    uploadFile = () => {
        let payload = {
            file: this.state.files,
        }

        FilesApi.uploadSecureFile(payload, res => {
            if (res.success) {
                this.props.uploadSuccess(res.data[0], payload.file[0], this.state.name);
                this.setState({
                    files: []
                }, () => {
                    this.props.onHide();
                })
            }
        }).catch(error => console.log("upload error>>>", error))

    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        const {name, filesCertificate} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">
                            <div className="tab-content">
                                <div className="tab-pane active">
                                    <h1 className="text-center">Upload File</h1>

                                    <div className="row">
                                        <label className="col-4 mt-2">Tên file
                                            <span className={'color-red d-inline'}>*</span></label>
                                        <input
                                            className="inputPassword w-100 col-8 kt-input pd-4-input hei-input"
                                            placeholder="Nhập vào tên file"
                                            maxLength={50}
                                            value={this.state.name}
                                            onChange={
                                                (e) => this.setState({name: e.target.value.trimLeft()}
                                            )}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <div className="form-group mx-0 mb-4">
                                            <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                                Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.
                                            </div>
                                            <Dropzone accept="image/*, application/pdf, .doc, .docx"
                                                      onDrop={acceptedFiles => {
                                                          console.log(acceptedFiles)
                                                          this.setState({files: acceptedFiles})
                                                      }}
                                                      multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files.length > 0 ? <div className="align-center">
                                                                <ul>{files}</ul>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                        </div>
                                    </div>
                                    <div container item xs={12}>
                                        <div item xs={12}>
                                            <div className="align-center mt-3 mb-2">
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                                    disabled={this.state.files.length == 0}
                                                    onClick={() => {
                                                        if (this.props.type == "report") {
                                                            this.uploadReport()
                                                        } else {
                                                            this.uploadFile()
                                                        }

                                                    }}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                                    onClick={this.props.onHide}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(UploadFile);

