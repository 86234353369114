import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import TableHeader from "../../../../components/TableHeader";
import NumberFormat from "react-number-format";
import ModalCreateBill from "../../../../components/Modal/admin/modalCreateBill";
import ModalCreateCertification from "../../../../components/Modal/admin/modalCreateCertification";
import ModalCreateEmployee from "../../../../components/Modal/admin/modalCreateEmployee";
import ModalCreateTermNumber from "../../../../components/Modal/admin/modalCreateTermNumber";


class build extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateBill: false,
            showCreateCertification: false,
            showCreateHuman: false,
            showCreateTerm: false,
            data_DEPENDING_TYPE_build_bill: [],
            data_DEPENDING_TYPE_build_certification: [],
            data_DEPENDING_TYPE_build_human: [],
            data_DEPENDING_TYPE_build_term: [],
            header_DEPENDING_TYPE_bill: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countBill',
                    name: "Số lượng hóa đơn",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_certification: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countCertify',
                    name: "Số lượng chứng từ",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_human: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countHuman',
                    name: "Số lượng nhân sự",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header_DEPENDING_TYPE_term: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countTerm',
                    name: "Số lượng kỳ (tháng)",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
        };
    }

    onSubmit = () => {
        let {} = this.state;
        let payload = {};

    }

    renderStatus = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'Đang hoạt động';
            case 'LOCK':
                return 'Đã khóa';
            default:
                return '';
        }
    };

    createBill = (type, item) => {
        if (type === 'build') {
            let lst = this.state.data_DEPENDING_TYPE_build_bill;
            lst.push(item);
            this.setState({data_DEPENDING_TYPE_build_bill: lst})
        }
    }
    createCertification = (type, item) => {
        if (type === 'build') {
            let lst = this.state.data_DEPENDING_TYPE_build_certification;
            lst.push(item);
            this.setState({data_DEPENDING_TYPE_build_certification: lst})
        }
    }
    createHuman = (type, item) => {
        if (type === 'build') {
            let lst = this.state.data_DEPENDING_TYPE_build_human;
            lst.push(item);
            this.setState({data_DEPENDING_TYPE_build_human: lst})
        }
    }
    createTerm = (type, item) => {
        if (type === 'build') {
            let lst = this.state.data_DEPENDING_TYPE_build_term;
            lst.push(item);
            this.setState({data_DEPENDING_TYPE_build_term: lst})
        }
    }


    render() {
        return (
            <div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Hóa đơn</label>
                        <button
                            type="button"
                            className="border-1-s background-btn type-button-fix btn btn-primary"
                            onClick={() => {
                                this.setState({showCreateBill: true})
                            }}
                        >
                            Thêm mới
                        </button>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_bill}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_build_bill || this.state.data_DEPENDING_TYPE_build_bill.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_build_bill.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.countBill}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Chứng từ</label>
                        <button
                            type="button"
                            className="border-1-s background-btn type-button-fix btn btn-primary"
                            onClick={() => {
                                this.setState({showCreateCertification: true})
                            }}
                        >
                            Thêm mới
                        </button>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_bill}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_build_certification || this.state.data_DEPENDING_TYPE_build_certification.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_build_certification.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.countCertify}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                <div>
                    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                        <label>Nhân sự</label>
                        <button
                            type="button"
                            className="border-1-s background-btn type-button-fix btn btn-primary"
                            onClick={() => {
                                this.setState({showCreateHuman: true})
                            }}
                        >
                            Thêm mới
                        </button>
                    </div>
                    <div className="table-wrapper">
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.header_DEPENDING_TYPE_human}
                                         clickSort={(data) => {
                                         }}/>
                            </thead>
                            {!this.state.data_DEPENDING_TYPE_build_human || this.state.data_DEPENDING_TYPE_build_human.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.data_DEPENDING_TYPE_build_human.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'center',}}>
                                            {row.countHuman}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">*/}
                {/*        <label className='label-item'>Số kỳ</label>*/}
                {/*        <button*/}
                {/*            type="button"*/}
                {/*            className="border-1-s background-btn type-button-fix btn btn-primary"*/}
                {/*            onClick={() => {*/}
                {/*                this.setState({showCreateTerm: true})*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Thêm mới*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    <div className="table-wrapper">*/}
                {/*        <table className="table table-striped table-data table-bordered">*/}
                {/*            <thead>*/}
                {/*            <TableHeader header={this.state.header_DEPENDING_TYPE_term}*/}
                {/*                         clickSort={(data) => {*/}
                {/*                         }}/>*/}
                {/*            </thead>*/}
                {/*            {!this.state.data_DEPENDING_TYPE_build_term || this.state.data_DEPENDING_TYPE_build_term.length == 0*/}
                {/*                ? <tbody>*/}
                {/*                <tr>*/}
                {/*                    <td colSpan={3}>*/}
                {/*                        <div className='text-center'>Chưa có dữ liệu</div>*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*                : <tbody>*/}
                {/*                {this.state.data_DEPENDING_TYPE_build_term.map((row, index) => (*/}
                {/*                    <tr key={row.id} style={{height: 50}}>*/}
                {/*                        <td style={{textAlign: 'center'}}>{index + 1}</td>*/}
                {/*                        <td style={{textAlign: 'center',}}>*/}
                {/*                            {row.countTerm}*/}
                {/*                        </td>*/}
                {/*                        <td style={{textAlign: 'right'}}>*/}
                {/*                            <NumberFormat*/}
                {/*                                value={row.price}*/}
                {/*                                displayType={"text"}*/}
                {/*                                thousandSeparator={true}*/}
                {/*                                suffix={" VND"}*/}
                {/*                            />*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                ))}*/}
                {/*                </tbody>}*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}


                {this.state.showCreateBill && <ModalCreateBill
                    show={this.state.showCreateBill}
                    type='build'
                    onHide={() => this.setState({showCreateBill: false})}
                    create={(type, item) => this.createBill(type, item)}
                    // onRefresh={() => this.()}
                />}
                {this.state.showCreateCertification && <ModalCreateCertification
                    show={this.state.showCreateCertification}
                    type='build'
                    onHide={() => this.setState({showCreateCertification: false})}
                    create={(type, item) => this.createCertification(type, item)}
                    // onRefresh={() => this.()}
                />}
                {this.state.showCreateHuman && <ModalCreateEmployee
                    show={this.state.showCreateHuman}
                    type='build'
                    onHide={() => this.setState({showCreateHuman: false})}
                    create={(type, item) => this.createHuman(type, item)}
                    // onRefresh={() => this.()}
                />}
                {/*{this.state.showCreateTerm && <ModalCreateTermNumber*/}
                {/*    show={this.state.showCreateTerm}*/}
                {/*    type='build'*/}
                {/*    onHide={() => this.setState({showCreateTerm: false})}*/}
                {/*    create={(type, item) => this.createTerm(type, item)}*/}
                {/*    // onRefresh={() => this.()}*/}
                {/*/>}*/}
            </div>
        );
    }
}

export default build
