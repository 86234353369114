import React from 'react'
import {toast} from "react-toastify";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import ModalAddEditPermission from "../../../components/Modal/modalAddEditPermission";


import RoleApi from "../../../services/ob-user/role";
import ModalDeleteRole from "../../../components/Modal/modalDeleteRole";
import { checkPermission } from '../../../utils/common';

class PermissionManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDetail: false,
            showBlock: false,
            showUnblock: false,
            showDelete: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            itemSelected: {},
            listData: [],
            sort: '',
            roleName: '',
            showLoading: false,
            modalAddEditPermission: false,
            modalDelete: false,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center", width: 100},
                    sort: false,
                },
                {
                    id: 'nameRole',
                    name: "Tên nhóm quyền",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center", width: 280},
                    sort: false,
                },
            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getData()
        })
    }

    getData = () => {
        let {pageNumber, pageSize, sort, roleName} = this.state
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // sort: sort,
            roleName: roleName,
        }
        RoleApi.getListByName(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false,
                })
            } else {
                this.setState({
                    showLoading: false,
                })
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        })
    }

    onRefresh = () => {
        this.setState(
            {
                roleName: '',
            },
            () => this.getData()
        );
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getData()
            }
        );
    }

    render() {
        let {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="permission-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý nhóm quyền"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Nhóm quyền</label>
                                                <input
                                                    className="form-control shadow-none font-14"
                                                    placeholder='Nhập vào tên nhóm quyền'
                                                    maxLength={20}
                                                    value={this.state.roleName}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            roleName: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({pageNumber: 0}, () => this.getData())
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >
                                                    Refresh
                                                </button>
                                                {checkPermission("add_role") &&
                                                <button type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                modalAddEditPermission: true,
                                                                modalType: 'new',
                                                                itemSelected: null,
                                                            })
                                                        }}
                                                >
                                                    Thêm mới
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getData())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length === 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.nameRole || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {checkPermission("view_detail_role") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem chi tiết"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showDetail: true})
                                                            }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>}
                                                        {/*<button*/}
                                                        {/*    className="btn btn-action"*/}
                                                        {/*    title={row.status === "ACTIVE" ? "Khóa" : "Mở khóa"}*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        if(row.status === "ACTIVE")*/}
                                                        {/*            this.setState({itemSelected: row, showBlock: true})*/}
                                                        {/*        else this.setState({itemSelected: row, showUnblock: true})*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <i className={row.status === "ACTIVE" ? "ti-lock" : "ti-unlock"}/>*/}
                                                        {/*</button>*/}
                                                        {/*<button*/}
                                                        {/*    className="btn btn-action"*/}
                                                        {/*    title="Xóa"*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        this.setState({itemSelected: row, showDelete: true})*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <i className="ti-trash"/>*/}
                                                        {/*</button>*/}
                                                        {checkPermission("update_role") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Sửa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, modalAddEditPermission: true, modalType: "edit"})
                                                            }}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>}
                                                        {checkPermission("delete_role") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, modalDelete: true})
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalAddEditPermission &&
                <ModalAddEditPermission show={this.state.modalAddEditPermission} type={this.state.modalType}
                                        item={this.state.itemSelected} onRefresh={() => {
                                            this.setState({modalAddEditPermission: false})
                                            this.getData()
                                        }}
                                        onHide={() => this.setState({modalAddEditPermission: false})}
                />}
                {this.state.modalDelete && <ModalDeleteRole id={this.state.itemSelected.id} onRefresh={() => {
                    this.setState({modalDelete: false})
                    this.getData()
                }}
                                                            onHide={()=> this.setState({modalDelete: false})}
                />}
            </div>
        )
    }

}

export default PermissionManagement
