import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let OrderServiceBaseApi = {
    calculatePrice: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/order-service/get-price`,
            method: 'POST',
            body: payload,
        }, callback);
    },
}
export default OrderServiceBaseApi
