import React from 'react';
import Utils from '../../utils/utils'
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';
import Dropzone from "react-dropzone";

import SupervisorApi from "../../services/ob-user/supervisor";
import FilesApi from "../../services/resource/files";
import Autosuggestion from "../Form/Autosuggestion";
import PartnerApi from "../../services/ob-user/partner";
import SubUserApi from "../../services/ob-user/sub-user";
import {toast} from "react-toastify";

class ModalAdminCreateNewAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            id: '',
            job: this.props.data ? this.props.data.job : '',
            areaId: this.props.data ? this.props.data.areaId : 0,
            maxAccountant: '',
            maxContract: '',
            selectedPartner: null,
            selectedSupervisor: null,
            maxAccountantErr: false,
            maxContractErr: false,
            partnerErr: false,
            supervisorErr: false,


            files: [],
            fileNameFile: '',
            fullNameFile: '',
            FileSave: [],

            btnStatus: true,
        };
    }

    componentWillMount() {
    }

    getListPartner = () => {
        let payload = {
            fullName: this.state.partner,
            pageNumber: 0,
            pageSize: 10,
        }
        PartnerApi.searchByPartnerNameAndAddress(payload, res => {
            if (res.success) {
                this.setState({
                    listPartner: res.data.content
                })
            }
        })
    }

    getListSup = () => {
        let payload = {
            fullName: this.state.supervisorName,
            partnerId: this.state.selectedPartner.id,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                this.setState({listSup: res.data.content})
            }
        })
    }

    onSubmit = () => {
        let {selectedPartner, selectedSupervisor, maxContract, maxAccountant, areaId} = this.state;
        let {data} = this.props;
        let ready = true;
        let payload = {
            id: data.id
        };
        if (selectedPartner === null) {
            this.setState({partnerErr: true})
            ready = false;
        }
        if (maxContract.length === 0 || maxContract * 1 === 0) {
            this.setState({maxContractErr: true})
            ready = false;
        }
        if (data.job === 'Supervisor') {
            if (maxAccountant.length === 0 || maxAccountant * 1 === 0) {
                this.setState({maxAccountantErr: true})
                ready = false;
            }
            if(ready) {
                payload.body = {
                    areaId: data.areaId,
                    email: data.email,
                    fullName: data.fullName,
                    job: 'Supervisor',
                    maxAccountant: maxAccountant * 1,
                    maxContract: maxContract * 1,
                    partnerId: selectedPartner?.id,
                    phone: data.phone,
                    provinceId: data.provinceId,
                    sex: data.sex,
                    supervisorId: 0
                }
            }
        } else if (data.job === 'Accountant') {
            if (selectedSupervisor === null) {
                this.setState({supervisorErr: true})
                ready = false;
            }
            if(ready) {
                payload.body = {
                    areaId: data.areaId,
                    email: data.email,
                    fullName: data.fullName,
                    job: 'Accountant',
                    maxAccountant: 0,
                    maxContract: maxContract * 1,
                    partnerId: selectedPartner?.id,
                    phone: data.phone,
                    provinceId: data.provinceId,
                    sex: data.sex,
                    supervisorId: selectedSupervisor?.id
                }
            }
        }
        if (ready) {
            SubUserApi.createOfficialUser(payload, res => {
                if(res.success) {
                    toast.success(`Tạo tài khoản ${data.fullName} thành công !`)
                    this.props.onRefresh()
                    this.props.onHide()
                } else {
                    toast.error('Tạo tài khoản thất bại. Vui lòng thử lại !')
                }
            })
        }

    }

    renderProvinceId = (provinceId) => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
        return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
    };

    renderPosition = (status) => {
        switch (status) {
            case 'Accountant':
                return <label>Kế toán</label>;
            case "Supervisor":
                return <label>Supervisor</label>;
            default:
                return;
        }
    };

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const files = this.state.files?.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body className="modal-create-new-sup">
                        <div className="modal-container ">
                            <div>
                                <h1 className="text-center" style={{fontSize: 20,}}><b>TẠO TÀI KHOẢN MỚI</b></h1>
                            </div>

                            <div>
                                <div className="row">
                                    <label className="col-5 mt-2">Họ và tên</label>
                                    <input
                                        className={"inputPassword w-100 col-7 kt-input pd-4-input hei-input"}
                                        disabled
                                        value={this.props.data.fullName}
                                        title={this.props.data.fullName}
                                    />
                                </div>

                                <div className={"row mt-3"}>
                                    <label className="col-5 mt-2">Số điện thoại</label>
                                    <input
                                        className={"inputPassword w-100 col-7 kt-input pd-4-input hei-input"}
                                        disabled
                                        value={this.props.data.phone}
                                        title={this.props.data.phone}
                                    />
                                </div>

                                <div className={"row mt-3"}>
                                    <label className="col-5 mt-2">Email</label>
                                    <input
                                        className={"inputPassword w-100 col-7 kt-input pd-4-input hei-input"}
                                        disabled
                                        value={this.props.data.email}
                                        title={this.props.data.email}
                                    />
                                </div>

                                <div className="row">
                                    <label className="col-5 mt-3">Vị trí</label>
                                    <label className="col-7 mt-3 p-0">{this.renderPosition(this.props.data.job)}</label>
                                </div>

                                <div className="row">
                                    <label className="col-5 mt-3">Partner</label>
                                    <label className="col-7 mt-3 p-0">
                                        <Autosuggestion
                                            label="Partner"
                                            placeholder="Nhập tên Partner"
                                            type="text"
                                            value={this.state.partner}
                                            data={this.state.listPartner}
                                            onSelect={(item) => {
                                                this.setState({selectedPartner: item}, () => {
                                                    if (this.state.selectedPartner === null) this.setState({partnerErr: true})
                                                    else this.setState({partnerErr: false})
                                                })
                                            }}
                                            onChange={(val) => {
                                                this.setState({partner: val, selectedPartner: null}, () => {
                                                    if (this.state.selectedPartner === null) this.setState({partnerErr: true})
                                                    else this.setState({partnerErr: false})
                                                })
                                                setTimeout(() => {
                                                    this.getListPartner()
                                                }, 300);
                                            }}
                                        />
                                    </label>
                                </div>
                                {this.state.partnerErr ? <div className="row">
                                    <label className="col-5 mb-0"></label>
                                    <label className="col-7 font-err p-0 m-0">Vui lòng chọn Partner !</label>
                                </div> : <div/>}

                                {this.props.data.job === 'Accountant' && <div className="row">
                                    <label className="col-5 mt-3">Supervisor</label>
                                    <label className="col-7 mt-3 p-0"
                                           title={this.state.selectedPartner ? '' : 'Vui lòng chọn Partner trước !'}>
                                        <Autosuggestion
                                            label="Người phụ trách"
                                            placeholder="Nhập tên người phụ trách"
                                            type="text"
                                            disabled={!this.state.selectedPartner}
                                            data={this.state.listSup}
                                            onSelect={(item) => this.setState({
                                                supId: item.id,
                                                selectedSupervisor: item
                                            }, () => {
                                                if (this.state.selectedSupervisor === null) this.setState({supervisorErr: true})
                                                else this.setState({supervisorErr: false})
                                            })}
                                            onChange={(val) => {
                                                this.setState({supervisorName: val, selectedSupervisor: null}, () => {
                                                    if (this.state.selectedSupervisor === null) this.setState({supervisorErr: true})
                                                    else this.setState({supervisorErr: false})
                                                })
                                                setTimeout(() => {
                                                    this.getListSup()
                                                }, 300);
                                            }}
                                        />
                                    </label>
                                </div>}
                                {this.state.supervisorErr ? <div className="row">
                                    <label className="col-5 mb-0"></label>
                                    <label className="col-7 font-err p-0 m-0">Vui lòng chọn người phụ trách chuyên môn!</label>
                                </div> : <div/>}

                                <div className="row">
                                    <label className="col-5 mt-3">Số lượng hợp đồng</label>
                                    <input
                                        className="form-control shadow-none w-100 col-7 mt-3"
                                        placeholder='Nhập số lượng hợp đồng'
                                        maxLength={3}
                                        value={this.state.maxContract}
                                        onChange={(e) => {
                                            this.setState({
                                                maxContract: Utils.filterNumberOnly(e.target.value)
                                            }, () => {
                                                if (this.state.maxContract.length == 0 || this.state.maxContract * 1 == 0) this.setState({maxContractErr: true})
                                                else this.setState({maxContractErr: false})
                                            })
                                        }}
                                    />
                                </div>
                                {this.state.maxContractErr ? <div className="row">
                                    <label className="col-5 mb-0"></label>
                                    <label className="col-7 font-err p-0 m-0 mt-1">Vui lòng nhập số lượng hợp đồng
                                        !</label>
                                </div> : <div/>}

                                {this.props.data.job === 'Supervisor' && <div className="row">
                                    <label className="col-5 mt-3">Số lượng kế toán</label>
                                    <input
                                        className="form-control shadow-none w-100 col-7 mt-3"
                                        placeholder='Nhập số lượng kế toán'
                                        maxLength={3}
                                        value={this.state.maxAccountant}
                                        onChange={(e) => {
                                            this.setState({
                                                maxAccountant: Utils.filterNumberOnly(e.target.value)
                                            }, () => {
                                                if (this.state.maxAccountant.length == 0 || this.state.maxAccountant * 1 == 0) this.setState({maxAccountantErr: true})
                                                else this.setState({maxAccountantErr: false})
                                            })
                                        }}
                                    />
                                </div>}
                                {this.state.maxAccountantErr ? <div className="row">
                                    <label className="col-5 mb-0"></label>
                                    <label className="col-7 font-err p-0 m-0 mt-1">Vui lòng nhập số lượng kế toán
                                        !</label>
                                </div> : <div/>}

                                {this.props.data.job !== 'Accountant' && <div className="row">
                                    <label className="col-5 mt-3">Chứng chỉ</label>
                                    <div className='col-7 mt-3 p-0'>
                                        {this.props.data.certificate ? <span title={this.props.data.certificate}
                                                                             className="cursor-pointer text-primary">{this.props.data.certificate}</span> :
                                            <span>Chưa có dữ liệu</span>}
                                    </div>
                                </div>}
                                <div className="row">
                                    <label className="col-5 mt-3">Khu vực tham gia</label>
                                    <label
                                        className="col-7 mt-3 p-0">{this.renderProvinceId(this.props.data.areaId)}</label>
                                </div>
                                <div className="row">
                                    <label className="col-5 mt-3">Mật khẩu</label>
                                    <label className="col-7 mt-3 p-0">12345678@</label>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12'>
                                    <div className="align-center mt-3 mb-2">
                                        <button
                                            type="button"
                                            className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                            onClick={this.onSubmit}
                                        >
                                            <span className='d-flex justify-content-center'>
                                                Tạo
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-4 col-2 border-1-s w-50 type-button-fix btn btn-light"
                                            onClick={this.props.onHide}
                                        >
                                            <span className='d-flex justify-content-center'>
                                                Hủy
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalAdminCreateNewAccount;
