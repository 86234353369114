import React, {Component} from "react";
import clsx from 'clsx';
import '../../../assets/css/Supervisor.css';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Grid,
    Button,
    Radio,
    CircularProgress,
} from '@material-ui/core';

import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import TableHeader from "../../../components/TableHeader";
import UploadFile from "../../../components/Modal/modalUploadFile";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar"
import ModalConfirmDeleteCert from "../../../components/Modal/modalConfirmDeleteCert";
// import ModalCropImage from "../../../components/Modal/ModalCropImage"

import SupervisorApi from "../../../services/ob-user/supervisor";
import PartnerApi from "../../../services/ob-user/partner";
import FilesApi from "../../../services/resource/files";
import UserFile from "../../../services/ob-user/user-file";
import ProvinceApi from "../../../services/ob-user/province";
import {toast} from "react-toastify";
import Authentication from "../../../services/auth";
import {forceLogout} from "../../../utils/authority";
const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


// const renderBusiness = function (business) {
//     switch (business) {
//         case 'COMMERCE_SERVICE':
//             return 'Thương mại - Dịch vụ';
//         case 'MANUFACTURING_MACHINING':
//             return 'Sản xuất - Gia công';
//         default:
//             return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
//     }
// };

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn quận huyện'})
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn thành phố'})
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};


function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}


class ProfileSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            showUpload: false,
            dataSupervisor: {},
            listProvince: [],
            listDistrict: [],
            AreaSup: [],
            files: [],
            filesCertificate: [],
            allUserTypes: [],
            currentUserType: localStorage.getItem("ob_cl_userType"),
            modalConfirmDeleteCert: false,
            fileId: null,
            address: '',
            provinceId: 0,
            districtId: 0,
            gender: 'FEMALE',
            identifycode: '',
            dateIssue: '',
            placeIssue: '',
            placeSupportId: '',
            phoneNum: '',
            email: '',
            job: '',
            expyear: '',
            name: '',
            avatar: '',
            description: "",
            certificate: "",
            field: [],
            modalUploadAvatar: false,
            showLoading: false,
            addressError: false,
            identifyError: false,
            nameError: false,
            placeIssuedError: false,
            dateIssuedError: false,
            sexError: false,
            expError: false,
            descriptionError: false,
            certificateError: false,
            provinceIdError: false,
            districtIdError: false,
            btnSaveStatus: false,

            partnerDetail: {},
            selectedFile:'',

            showConfirmModal: false,


            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,

            fieldSupport: [
                {
                    id: 0,
                   name: "Thương mại - Dịch vụ",
                   checked: false
                },
                {
                    id: 1,
                    name: "Sản xuất",
                    checked: false
                },
                {
                    id: 2,
                    name: "Xây dựng",
                    checked: false
                },
            ],

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Bằng cấp chứng chỉ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File bằng cấp chứng chỉ",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
            accounts: [
                {
                    id: '1',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Loại tài khoản",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getSupervisorInfo();
            this.getAllUserTypes();
            this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
        })

    }

    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }

    getSupportArea() {
        ProvinceApi.supportArea(res => {
            if (res.success) {
                this.setState({
                    AreaSup: res.data.content
                }, () => this.getFile())
            } else {

            }
        })
    }
    getDetailPartner(id){
        let payload = {
            id: id
        }
        PartnerApi.getDetail(payload, res =>{
            if(res.success){
                this.setState({
                    partnerDetail: res.data
                })
            }
        })
    }


    getSupervisorInfo() {
        SupervisorApi.detailSupervisor(res => {
            if (res.success) {
                this.setState({
                        dataSupervisor: res.data,
                        address: res.data.address,
                        provinceId: res.data.provinceId,
                        districtId: res.data.districtId,
                        gender: res.data.sex,
                        identifycode: res.data.identify,
                        dateIssue: res.data.dateIssued,
                        placeIssue: res.data.placeIssued,
                        placeSupportId: res.data.areaId,
                        job: res.data.job,
                        expyear: res.data.experience,
                        name: res.data.fullName,
                        email: res.data.email,
                        description: res.data.description,
                        certificate: res.data.certificate,
                        field: res.data.fieldSupport != null ? res.data.fieldSupport.split(",") : []
                    }, () => this.getSupportArea()
                );
                this.getDetailPartner(res.data.partnerId);
            } else {

            }
        })
    };

    getFile = () => {
        UserFile.getAll(res => {
            if (res.success) {
                this.setState({filesCertificate: res.data, showLoading: false})
            }
        })
    }

    handleChangeTax(val) {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({taxError: false}) : this.setState({taxError: true});
                this.state.tax.length >= 10 ? this.setState({taxError: false}) : this.setState({taxError: true})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangeexpyear(val) {
        this.setState({inEditing: true});
        const onlyNums = val.target.value.replace(/[^0-9]/g, '').trim();
        this.setState({expyear: onlyNums})
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({identifyError: false}) : this.setState({identifyError: true});
                this.state.identify.length >= 9 ? this.setState({identifyError: false}) : this.setState({identifyError: true})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }
    getAllUserTypes = () => {
        Authentication.getAllUserType(res => {
            if (res.success) {
                this.setState({allUserTypes: res.data, showLoading: false});
            }
        })
    }
    switchUserType = (userType) => {
        let payload = {
            type: Authentication.getIndexUserType(userType)
        }
        Authentication.switchUserType(payload, res => {
            if (res.success){
                forceLogout();
                toast.success("Chuyển thành công! \nVui lòng đăng nhập lại để tiếp tục!");
            }
            else {
                toast.error("Chuyển tài khoản thất bại");
            }
        })
    }
    handleSave() {
        let {
            address,
            provinceId,
            districtId,
            gender,
            identifycode,
            dateIssue,
            placeIssue,
            placeSupportId,
            phoneNum,
            email,
            job,
            expyear,
            name,
            avatar,
            description,
            certificate,
            field
        } = this.state;
        // if (address === '') {
        //     this.setState({addressError: true})
        // }
        // if (tax === '') {
        //     this.setState({taxError: true})
        // }
        // if (identify === '') {
        //     this.setState({identifyError: true})
        // }
        // if (email === '') {
        //     this.setState({emailError: true})
        // }
        // if (revenue === '') {
        //     this.setState({revenueError: true})
        // }
        // if (representative === '') {
        //     this.setState({representativeError: true})
        // }
        // if (position === '') {
        //     this.setState({positionError: true})
        // }
        // if (representativePhone === '') {
        //     this.setState({representativePhoneError: true})
        // }
        // if (placeIssued === '') {
        //     this.setState({placeIssuedError: true})
        // }
        // if (dateIssued === '') {
        //     this.setState({dateIssuedError: true})
        // }
        // if (sex === '') {
        //     this.setState({sexError: true})
        // }
        // if (provinceId === '') {
        //     this.setState({provinceIdError: true})
        // }
        // if (districtId === '') {
        //     this.setState({districtIdError: true})
        // } else {
        let payload = {
            address: address,
            areaId: placeSupportId,
            dateIssued: dateIssue,
            districtId: districtId,
            experience: expyear,
            fieldSupport: field.toString(),
            fullName: name,
            identify: identifycode,
            placeIssued: placeIssue,
            provinceId: provinceId,
            sex: gender,
            // urlImage: avatar,
            description: description,
            certificate: certificate,
        }
        this.setState({loading: true}, () => {
            let timer = setInterval(() => {
                this.tick()
            }, 10);
            setTimeout(() => {
                clearInterval(timer);
                this.setState({
                    loading: false,
                }, () => {
                    SupervisorApi.updateSupervisor(payload, res => {
                        if (res.success) {
                            toast.success("Thông tin đã được cập nhật thành công !")
                            this.setState({
                                onEdit: false,
                                inEditing: false,
                                btnSaveStatus: false
                            }, () => this.getSupervisorInfo());
                        } else {
                            // console.log('err: ', res);
                            toast.error("Cập nhật không thành công, vui lòng thử lại sau !")
                        }
                    })
                });
            }, 3000);
        });
        // }
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({onEdit: false},
                () => {
                    this.setState({
                        address: this.state.dataSupervisor ? this.state.dataSupervisor.address : '',
                        provinceId: this.state.dataSupervisor ? this.state.dataSupervisor.provinceId : '',
                        districtId: this.state.dataSupervisor ? this.state.dataSupervisor.districtId : '',
                        gender: this.state.dataSupervisor.sex,
                        dateIssue: this.state.dataSupervisor.dateIssued,
                        identifycode: this.state.dataSupervisor ? this.state.dataSupervisor.identify : '',
                        placeIssue: this.state.dataSupervisor ? this.state.dataSupervisor.placeIssued : '',
                        placeSupportId: this.state.dataSupervisor ? this.state.dataSupervisor.areaId : '',
                        job: this.state.dataSupervisor ? this.state.dataSupervisor.job : '',
                        expyear: this.state.dataSupervisor ? this.state.dataSupervisor.experience : '',
                        name: this.state.dataSupervisor ? this.state.dataSupervisor.fullName : '',
                        description: this.state.dataSupervisor ? this.state.dataSupervisor.description : '',
                        certificate: this.state.dataSupervisor ? this.state.dataSupervisor.certificate : '',

                        addressError: false,
                        identifyError: false,
                        nameError: false,
                        placeIssueError: false,
                        dateIssuedError: false,
                        sexError: false,
                        expError: false,
                        provinceIdError: false,
                        districtIdError: false,
                        descriptionError: false,
                        certificateError: false,
                        btnSaveStatus: false,
                    })
                }
            )
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    onChangeDistrict = (e) => {
        this.setState({
            districtId: e.target.value,
            inEditing: true
        })
    };

    modalUploadAvatarClose = () => {
        this.setState({modalUploadAvatar: false});
    }
    modalCropClose = () => {
        this.setState({showModalCrop: false});
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }

    onDeleteCert = (item) => {
        this.setState({fileId: item.id, modalConfirmDeleteCert: true})
    }

    uploadCert = (res, file, fileName) => {
        let payload = {
            fileName: file.name,
            fullName: fileName,
            // url: res
            destination: res.destination,
            storageName: res.storageName
        }
        FilesApi.supervisorUploadCertificate(payload, res => {
            if (res.success) {
                toast.success("Cập nhật chứng chỉ thành công!")
            } else {
                toast.error("Cập nhật chứng chỉ không thành công!")
            }
        })
    }

    downloadCert = (item) => {
        let payload = {
            fileId: item.id
        }
        FilesApi.downloadUserFile(payload)
    }

    onCheckSupport = (item) => {
        let {field} = this.state
        if (field.find(name => name === item.name) === item.name) {
            return true
        } else {
            return false
        }
    }

    onHandleChooseSupport = (val, item) => {
        let {field, fieldSupport} = this.state
        if(val.target.checked) {
            field.push(val.target.value)
        }else{
            field.splice(field.indexOf(val.target.value),1)
        }
        fieldSupport.map(_item => {
            if (_item.name == item.name){
                _item.checked = val.target.checked
            }
        })
        this.setState({
            field: field,
            fieldSupport,
            inEditing: true
        })

    }

    render() {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataSupervisor, partnerDetail, tax, placeIssued, dateIssued, sex, provinceId, districtId} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="profile"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className='mb-4 pb-2 card border-color'>
                                {/*<CardContent>*/}
                                {/*    <div className='d-flex justify-content-center col-12 mt-3' style={{height: 120}} >*/}
                                {/*        <span className='size-text-header'>*/}
                                {/*            {this.state.onEdit ? 'Chỉnh sửa thông tin cá nhân' : 'Thông tin cá nhân'}*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*//**********************************************Thong tin doanh nghiep****************************************************************************************************/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin doanh nghiệp</span>
                                </div>
                                <div className='d-flex justify-content-center col-12 mt-3'>
                                    {/*<div className='d-flex justify-content-start col-12 mt-3' style={{height: 120}}>*/}
                                    {/*    <div className='col-4 d-flex ml-2'>*/}
                                    {/*        <span className='text-header-profile'>Thông tin doanh nghiệp</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='col-4'>*/}
                                    <div style={{width: "250px"}}>
                                        <img
                                            // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}
                                            src={dataSupervisor.urlImagePartner ? dataSupervisor?.urlImagePartner : "http://ecorau.com/images/logo_mail.png"}
                                            className='mh-100 mw-100 m-2'
                                        />
                                    </div>
                                    {/*</div>*/}
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tên doanh nghiệp</span>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {partnerDetail?.fullName?.toUpperCase()}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mã số thuế</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {dataSupervisor.tax}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <span className="text-black-50">
                                                    Số điện thoại
                                                </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataSupervisor.phonePartner}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataSupervisor.emailPartner}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {renderProvinceId(dataSupervisor.provinceIdPartner)}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {renderDistrictId(dataSupervisor.provinceIdPartner)}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataSupervisor.addressPartner}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/********************************Thong tin ca nhan****************************************************/}
                            <div variant="outlined" className='mb-4 pb-2 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin cá nhân</span>
                                </div>
                                <div className='d-flex justify-content-center col-12 mt-3 kt-margin-b-20'>
                                    <div className='col-4 d-flex ml-2'>
                                        {/*<span className='text-header-profile'>Thông tin cá nhân</span>*/}
                                    </div>
                                    <div className='justify-center img-ava-wrapper' style={{textAlign: 'center', backgroundImage: dataSupervisor.urlImage ? `url(${dataSupervisor.urlImage}` : `url("/images/no-avatar-png.png")`}}>
                                        <span className="fas fa-camera change-avt-icon" title="Tải lên ảnh đại diện" onClick={this.showModalUploadAvatar}/>


                                        {/*<img*/}
                                        {/*    // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}*/}
                                        {/*    // src={files.length !== 0 ? files[0].preview : dataSupervisor.urlImage}*/}
                                        {/*    src={dataSupervisor.urlImage || '/images/no-avatar-png.png'}*/}
                                        {/*    className='img-supervisor cursor-pointer'*/}
                                        {/*    onClick={this.state.onEdit ? this.showModalUploadAvatar : ''}*/}
                                        {/*/>*/}
                                        {/*{this.state.onEdit*/}
                                        {/*    ? <Dropzone*/}
                                        {/*        getUploadParams={() => ({url: 'https://httpbin.org/post'})} // test*/}
                                        {/*        accept="image/*"*/}
                                        {/*        onDrop={acceptedFiles => {*/}
                                        {/*            console.log(acceptedFiles)*/}
                                        {/*            this.setState({*/}
                                        {/*                files: acceptedFiles.map(file => Object.assign(file, {*/}
                                        {/*                    preview: URL.createObjectURL(file)*/}
                                        {/*                }))*/}
                                        {/*            })*/}
                                        {/*            // const uploaders = files.map(file => {*/}
                                        {/*            //     const formData = new FormData()*/}
                                        {/*            //     formData.append('file', file);*/}
                                        {/*            //*/}
                                        {/*            //     return axios.post('https://httpbin.org/post', formData, {*/}
                                        {/*            //         headers: { 'X-Requested-With': 'XMLHttpRequest' },*/}
                                        {/*            //     })*/}
                                        {/*            // })*/}

                                        {/*            // axios.all(uploaders).then(() => {*/}
                                        {/*            //     // remove files once they've all been uploaded*/}
                                        {/*            //     this.setState({ files: [] })*/}
                                        {/*            // })*/}
                                        {/*        }*/}

                                        {/*        }*/}
                                        {/*    >*/}
                                        {/*        {({getRootProps, getInputProps}) => (*/}
                                        {/*            <span title='Thay đổi logo' className='change-avt'>*/}
                                        {/*                <span {...getRootProps()}>*/}
                                        {/*                    <input {...getInputProps()} />*/}
                                        {/*                    <CameraAltOutlinedIcon/>*/}
                                        {/*                </span>*/}
                                        {/*            </span>*/}
                                        {/*        )}*/}
                                        {/*    </Dropzone>*/}
                                        {/*    : <div/>*/}
                                        {/*}*/}
                                    </div>
                                    <div className='col-4 pr-0'>
                                        {this.state.onEdit ?
                                            <div/> :
                                            <div className='d-flex justify-content-end'>
                                                <Button variant="contained"
                                                        className='rounded mb-1 btn-primary'
                                                        color='primary'
                                                    // disabled={this.state.dataSupervisor.length == 0?true:false}
                                                        style={{textTransform: 'initial'}}
                                                        onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                                >
                                                    Chỉnh sửa thông tin
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Họ và tên{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.nameError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nhập họ và tên'
                                                       value={this.state.name}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           name: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.name.length == 0 ? this.setState({
                                                               nameError: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({nameError: false, btnSaveStatus: false})
                                                       })}
                                                />

                                                : dataSupervisor.fullName ? dataSupervisor.fullName.toUpperCase() : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Vị trí công việc</span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {dataSupervisor.job}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">
                                                Số điện thoại
                                            </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {profile?.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span
                                                className="text-black-50">Số năm kinh nghiệm{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.expError ? "form-control border-input-error kt-input pd-4-input hei-input " : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập số năm"
                                                       value={this.state.expyear}
                                                       maxLength={2}
                                                    // aria-label="Username"
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           expyear: val.target.value.trim().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.expyear.length === 0 || this.state.expyear == '00' || this.state.expyear == '0' ? this.setState({
                                                               expError: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({expError: false, btnSaveStatus: false})
                                                       })}
                                                />
                                                : dataSupervisor.experience ? dataSupervisor.experience : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {dataSupervisor?.email}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Khu vực hỗ trợ {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={this.state.placeSupportId}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            placeSupportId: e.target.value,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >
                                                    {this.state.AreaSup?.map((item) => (
                                                        <option value={item.id}>{item.name}</option>))}
                                                </select>
                                                : dataSupervisor.areaId ? renderProvinceId(dataSupervisor.areaId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Chúng chỉ/Bằng{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <textarea
                                                    placeholder="Nhập miêu tả"
                                                    value={this.state.description}
                                                    rows={3}
                                                    className={this.state.descriptionError ? "form-control border-input-error shadow-none p-2" : "form-control shadow-none p-2"}
                                                    style={{resize: 'none', fontSize: 14}}
                                                    maxLength={500}
                                                    onChange={(e) => {
                                                        this.setState({description: e.target.value}, () => {
                                                            this.state.description.length === 0 ? this.setState({
                                                                descriptionError: true,
                                                                btnSaveStatus: true
                                                            }) : this.setState({
                                                                descriptionError: false,
                                                                btnSaveStatus: false
                                                            })
                                                        })
                                                    }}
                                                />

                                                : dataSupervisor.description ? dataSupervisor.description : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mô tả kinh nghiệm{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <textarea
                                                    placeholder="Nhập kinh nghiệm làm việc"
                                                    value={this.state.certificate}
                                                    rows={3}
                                                    className={this.state.certificateError ? "form-control border-input-error shadow-none p-2" : "form-control shadow-none p-2"}
                                                    style={{resize: 'none', fontSize: 14}}
                                                    maxLength={500}
                                                    onChange={(e) => {
                                                        this.setState({certificate: e.target.value}, () => {
                                                            this.state.certificate.length === 0 ? this.setState({
                                                                certificateError: true,
                                                                btnSaveStatus: true
                                                            }) : this.setState({
                                                                certificateError: false,
                                                                btnSaveStatus: false
                                                            })
                                                        })
                                                    }}
                                                />

                                                : dataSupervisor.certificate ? dataSupervisor.certificate : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <div className='d-flex col-12'>
                                                    <div className="form-check col-6 ">
                                                        <input className="form-check-input" type="radio"
                                                               name="femaleRadios" id="female" value="FEMALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       gender: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.gender === 'FEMALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nữ
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-6">
                                                        <input className="form-check-input" type="radio"
                                                               name="maleRadios" id="male" value="MALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       gender: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.gender === 'MALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nam
                                                        </label>
                                                    </div>
                                                </div>
                                                : dataSupervisor.sex ? dataSupervisor.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.identifyError ? "form-control  border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập số CMND/CCCD"
                                                       value={this.state.identifycode}
                                                    // aria-label="Username"
                                                       maxLength={12}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           identifycode: val.target.value.trim().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.identifycode.length === 0 || this.state.identifycode.length <= 8 ? this.setState({
                                                               identifyError: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               identifyError: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                                : dataSupervisor.identify ? dataSupervisor.identify : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={provinceId}
                                                    onChange={(e) => {
                                                        if (e.target.value != null) {
                                                            let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                            let _disfilter = _district?.filter(item => item.provinceId == e.target.value);
                                                            this.setState({
                                                                provinceId: e.target.value,
                                                                districtId: _disfilter[0]?.id,
                                                                inEditing: true
                                                            })
                                                        }
                                                    }}
                                                    // onSelect={() => this.onChangeDistrict}
                                                >
                                                    {renderProvince()}
                                                </select>
                                                : dataSupervisor.provinceId ? renderProvinceId(dataSupervisor.provinceId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-sm-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <DatePicker
                                                    className="w-auto react-datepicker-wrapper kt-input pd-4-input hei-input"
                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                    selected={this.state.dateIssue === '' ? new Date() : new Date(this.state.dateIssue)}
                                                    // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                    maxDate={new Date()}
                                                    // value={this.state.dateIssue}
                                                    dateFormat="dd-MM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={
                                                        (date) => {
                                                            let value = moment(date).format("YYYY-MM-DD").toString();
                                                            try {
                                                                this.setState({
                                                                    dateIssue: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                    inEditing: true
                                                                })
                                                            } catch (e) {

                                                            }
                                                        }
                                                    }
                                                />
                                                : dataSupervisor.dateIssued ? moment(dataSupervisor.dateIssued).format("DD-MM-YYYY") : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={districtId}
                                                    onChange={this.onChangeDistrict}
                                                >
                                                    {this.renderDistrict(provinceId)}
                                                </select>
                                                : dataSupervisor.districtId ? renderDistrictId(dataSupervisor.districtId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.placeIssueError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập nơi cấp"
                                                       value={this.state.placeIssue}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           placeIssue: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.placeIssue.length === 0 ? this.setState({
                                                               placeIssueError: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               placeIssueError: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                                : dataSupervisor.placeIssued ? dataSupervisor.placeIssued : ''
                                            }
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.addressError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập địa chỉ"
                                                       value={address}
                                                       maxLength={100}
                                                    // aria-label="Username"
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           address: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.address.length === 0 ? this.setState({
                                                               addressError: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               addressError: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                                : dataSupervisor.address ? dataSupervisor.address : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Lĩnh vực {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <div className='col-12'>

                                                    {this.state.fieldSupport.map(item => {
                                                        return (
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       value={item.name}
                                                                       onClick={(val) => {
                                                                           this.onHandleChooseSupport(val, item)}}
                                                                       checked={this.onCheckSupport(item)}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="exampleRadios1">
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}

                                                    {/*<div className="form-check ">*/}
                                                    {/*    <input className="form-check-input" type="checkbox"*/}
                                                    {/*           value="Thương mại - Dịch vụ - Đầu tư tài chính"*/}
                                                    {/*           onClick={(val) => {*/}
                                                    {/*               this.onSelect(val)}}*/}
                                                    {/*           // checked={this.state.field.find(item => item === "Thương mại - Dịch vụ - Đầu tư tài chính") === 'Thương mại - Dịch vụ - Đầu tư tài chính'}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="form-check-label"*/}
                                                    {/*           htmlFor="exampleRadios1">*/}
                                                    {/*        Thương mai - dịch vụ*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="form-check">*/}
                                                    {/*    <input className="form-check-input" type="checkbox"*/}
                                                    {/*           value="2"*/}
                                                    {/*           onClick={(val) => {*/}
                                                    {/*               this.onSelect(val)}}*/}
                                                    {/*           checked={this.state.field === '2'}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="form-check-label"*/}
                                                    {/*           htmlFor="exampleRadios1">*/}
                                                    {/*        Sản xuất*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="form-check">*/}
                                                    {/*    <input className="form-check-input" type="checkbox"*/}
                                                    {/*           value="3"*/}
                                                    {/*           onClick={(val) => {*/}
                                                    {/*               this.onSelect(val)}}*/}
                                                    {/*           checked={this.state.field === '3'}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="form-check-label"*/}
                                                    {/*           htmlFor="exampleRadios1">*/}
                                                    {/*        Xây dựng*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                </div>
                                                : (dataSupervisor.fieldSupport ? dataSupervisor.fieldSupport : '')
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mật khẩu</span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2" : "pl-0"}>
                                            **********
                                        </div>
                                        <div
                                            className="pb-2 col-sm-2 change-password"
                                            onClick={() => this.handleShowModalForgotPassword()}
                                        >
                                            Đổi mật khẩu
                                        </div>

                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>

                                {this.state.onEdit ? <Grid container spacing={2} justify={"flex-center"}>
                                        <Grid item xs={12} sm={12} md={12} className='text-center'>
                                            {this.state.loading &&
                                            <Button variant="outlined" color="primary"
                                                    className='mr-3'
                                                    style={{textTransform: 'initial'}}
                                            >
                                                <CircularProgress size={20} variant="determinate"
                                                                  value={this.state.progress}/>
                                            </Button>}
                                            {!this.state.loading &&
                                            <Button disabled={this.state.btnSaveStatus} variant="contained" color="primary"
                                                    className='mr-3 btn-primary'
                                                    style={{textTransform: 'initial'}}
                                                    onClick={() => this.handleSave()}
                                            >
                                                Lưu
                                            </Button>}
                                            <Button variant="outlined" style={{textTransform: 'initial'}}
                                                    onClick={() => this.handleCancel()}
                                            >
                                                Hủy
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    : <div/>
                                }

                                {/*</CardContent>*/}
                            </div>

                            {/*********************************************************************************************************************************??*/}
                            <div variant="outlined" className='pb-2 mb-5 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Hồ sơ cá nhân</span>
                                </div>
                                    <div className='d-flex col-12 mt-3 justify-content-end'>
                                        <Button className="btn btn-primary"
                                                variant="contained"
                                                color='primary'
                                                onClick={() => this.setState({showUpload: true})}>
                                            Upload
                                        </Button>
                                    </div>
                                <div className="table-wrapper col-12">
                                    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">
                                    <thead>
                                    <TableHeader header={this.state.header} clickSort={(data) => {
                                    }}/>
                                    </thead>
                                    <tbody>
                                    {this.state.filesCertificate.length !== 0
                                        ? this.state.filesCertificate.map((item, index) =>
                                            <tr>
                                                <th scope="row" className='text-center'>{index + 1}</th>
                                                <td className='text-center'>{item.fullName}</td>
                                                <td className='text-center'>{item.fileName}</td>
                                                <td className='text-center'>
                                                    <div>
                                                        <button className="btn" title="Xóa chứng chỉ"
                                                                onClick={() => this.onDeleteCert(item)}>
                                                            <i className='ti-trash'/>
                                                        </button>

                                                        <button className="btn btn-action"
                                                                title='Tải xuống'
                                                                onClick={() => {
                                                                    // this.setState({selectedFile: item}, () => {
                                                                        this.downloadCert(item)
                                                                    // })
                                                                }}
                                                                style={{cursor: 'pointer'}}>
                                                            <i className="ti-download"/>
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                        : <tr>
                                            <td colSpan={4}>
                                                <div><span><h6>Không có dữ liệu</h6></span></div>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            {this.state.allUserTypes.length > 1 &&
                            <div variant="outlined" className='pb-2 mb-5 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Quản lý tài khoản</span>
                                </div>
                                <div className="table-wrapper col-12">
                                    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">
                                        <thead>
                                        <TableHeader header={this.state.accounts} clickSort={(data) => {
                                        }}/>
                                        </thead>
                                        <tbody>
                                        {this.state.allUserTypes.length !== 0
                                            ? this.state.allUserTypes.map((item, index) =>
                                                <tr>
                                                    <th scope="row" className='text-center'>{index + 1}</th>
                                                    <td className='text-center'>{item.userType}</td>
                                                    <td className='text-center'>
                                                        <div>
                                                            {this.state.currentUserType != item.userType &&
                                                            <Button disabled={this.state.btnSaveStatus}
                                                                    variant="contained" color="primary"
                                                                    className='mr-3 btn-primary'
                                                                    style={{textTransform: 'initial'}}
                                                                    onClick={() => this.switchUserType(item.userType)}
                                                            >
                                                                Chuyển tài khoản
                                                            </Button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                            : <tr>
                                                <td colSpan={4}>
                                                    <div><span><h6>Không có dữ liệu</h6></span></div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <ModalChangePassword show={this.state.showModalChangePassword}
                                         onHideModal={this.onCloseModalForgotPassword}/>
                    <ModalConfirmEdit show={this.state.confirmCancel}
                                      onHide={() => this.setState({confirmCancel: false})}
                                      handleCancel={
                                          () => this.setState({
                                              confirmCancel: false,
                                              inEditing: false
                                          }, () => this.handleCancel())
                                      }
                    />
                    {this.state.showUpload && <UploadFile show={this.state.showUpload}
                                                          uploadSuccess={this.uploadCert}
                                                          onHide={() => this.setState({showUpload: false}, () => this.getFile())}/>}
                    <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                       onHide={this.modalUploadAvatarClose}
                                       updateSuccess={(item) => {
                                           this.setState({modalUploadAvatar: false, avatar: item})
                                           this.getSupervisorInfo()
                                       }}/>
                    <ModalConfirmDeleteCert show={this.state.modalConfirmDeleteCert}
                                            onRefresh={() => this.setState({modalConfirmDeleteCert: false}, ()=> this.getFile())}
                                            onHide={() => this.setState({modalConfirmDeleteCert: false})}
                                            fileId={this.state.fileId}
                    />
                    {/*<ModalCropImage show={this.state.showModalCrop} onHide={this.modalCropClose}*/}
                    {/*                   customerId={1}/>*/}
                </div>
            </div>
        );
    }
}


export default ProfileSupervisor
