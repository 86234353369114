import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import AccountantApi from "../../services/ob-user/accountant";

class UnblockAccountant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showBlock: true,
            signupInfo: null
        };
    }

    componentDidMount() {
        this.setState({oldpassword: '', newpassword: '', repassword: '',});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({oldpassword: '', newpassword: '', repassword: ''});
        }
    }

    onSubmit = () => {
        if (this.state.oldpassword.length < 6 || this.state.newpassword.length < 6 || this.state.repassword.length < 6) {
            this.props.enqueueSnackbar('Mật khẩu không được nhỏ hơn 6 kí tự !', {
                variant: 'error',
            });
        } else if (this.state.newpassword != this.state.repassword) {
            this.props.enqueueSnackbar('Nhập lại mật khẩu chưa chính xác !', {
                variant: 'error',
            });
        } else if (this.state.oldpassword == this.state.newpassword) {
            this.props.enqueueSnackbar('Mật khẩu mới không được giống mật khẩu cũ !', {
                variant: 'error',
            });
        } else {
            this.blockAccountant()
        }
    }

    unblockAccountant = () => {
        let payload = {
            id: this.props.id,
        }
        AccountantApi.unblockSupervisor(payload, res => {
            if (res.errorCode === "One-Book-User.E000017") {
                this.props.enqueueSnackbar(res.message[0], {
                    variant: 'Error',
                });
                // this.props.onHideModal();
            } else if (res.success) {
                this.props.enqueueSnackbar('Mở khoá tài khoản thành công !', {
                    variant: 'success',
                });
                this.props.onHide();
                this.props.onRefresh();
            } else {
                return this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>MỞ KHÓA TÀI KHOẢN</b></h1>
                        </div>

                        <div>
                            <label>Bạn có muốn mở khóa tài khoản kế toán này ?</label>
                            {/*<textarea*/}
                            {/*    rows={5}*/}
                            {/*    className="form-control shadow-none"*/}
                            {/*    style={{resize: 'none'}}*/}
                            {/*    placeholder={'Nhập vào lý do khóa tài khoản kế toán'}*/}
                            {/*    maxLength={250}*/}
                            {/*    value={this.state.reason}*/}
                            {/*    onChange={(event) => {*/}
                            {/*        this.setState({reason: event.target.value})*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={() => this.unblockAccountant()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(UnblockAccountant);

