import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import AdminServicePlan from '../../../services/ob-core/admin-service-plan'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class modalLockPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showBlock: true,
            signupInfo: null
        };
    }

    componentDidMount() {
        this.setState({oldpassword: '', newpassword: '', repassword: '',});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({oldpassword: '', newpassword: '', repassword: ''});
        }
    }

    // onSubmit = () => {
    //     if (this.state.oldpassword.length < 6 || this.state.newpassword.length < 6 || this.state.repassword.length < 6) {
    //         this.props.enqueueSnackbar('Mật khẩu không được nhỏ hơn 6 kí tự !', {
    //             variant: 'error',
    //         });
    //     } else if (this.state.newpassword != this.state.repassword) {
    //         this.props.enqueueSnackbar('Nhập lại mật khẩu chưa chính xác !', {
    //             variant: 'error',
    //         });
    //     } else if (this.state.oldpassword == this.state.newpassword) {
    //         this.props.enqueueSnackbar('Mật khẩu mới không được giống mật khẩu cũ !', {
    //             variant: 'error',
    //         });
    //     } else {
    //         this.blockAccountant()
    //     }
    // }
    //
    block = () => {
        let payload = {
            id: this.props.id,
            // body:{
            //     reason:this.state.reason,
            // }
        };
        AdminServicePlan.block(payload, res => {
            if (res.errorCode === "One-Book-User.E000017") {
                toast.success(res.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                // this.props.onHideModal();
            } else if (res.success) {
                toast.success('Khoá dịch vụ thành công !', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                this.props.onHide();
                this.props.onRefresh()
            } else {
                toast.success('Có lỗi xảy ra !', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='d-flex justify-content-center mt-3 mb-3'>
                            <label className='font-size-content'>Bạn có chắc chắn khoá gói dịch vụ này không ?</label>
                            {/*<textarea*/}
                            {/*    rows={5}*/}
                            {/*    className="form-control shadow-none"*/}
                            {/*    style={{resize: 'none'}}*/}
                            {/*    placeholder={'Nhập vào lý do khóa tài khoản kế toán'}*/}
                            {/*    maxLength={250}*/}
                            {/*    value={this.state.reason}*/}
                            {/*    onChange={(event) => {*/}
                            {/*       this.setState({reason: event.target.value})*/}
                            {/*    }}*/}
                            {/* />*/}
                        </div>


                            <div className=" align-right col-12">
                                <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={() => this.block()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


// export default withSnackbar(modalLockPlan);
export  default  modalLockPlan
