import React from 'react';
import {toast} from "react-toastify";
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';
import CallCenterApi from "../../../services/ob-user/call-center";

class ModalBlockCallCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showBlock: true,
        };
    }

    onBlock = () => {
        let payload = {
            id: this.props.data.id
        }
        debugger
        let userType = localStorage.getItem("ob_cl_userType")
        CallCenterApi.block(payload, res => {
            if (res.success) {
                toast.success(`Khóa tài khoản Call Center "${this.props.data.fullName}" thành công!`)
                this.props.onRefresh();
                this.props.onHide();
            } else {
                toast.error("Thao tác thất bại. Vui lòng thử lại!")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='m-2'>
                            <label>Bạn có chắc chắn muốn khoá Call Center "{this.props.data.fullName}" không ?</label>
                        </div>
                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => this.onBlock()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalBlockCallCenter;

