import React from 'react';
// import {withSnackbar} from "notistack";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../services/ob-user/supervisor";
import BranchApi from "../../services/ob-user/branch";
import PublicContractApi from "../../services/ob-core/public-contract";
import DocumentApi from "../../services/ob-core/document";
import {DOCUMENT_TYPES} from "../../services/ob-core/document";

toast.configure()

class ModalAddFileToContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showAdd: true,
            // id: this.props.data.id,
            contractId: '',
            listContract: [],
            code: "",
            docTypes: DOCUMENT_TYPES,
            docType: DOCUMENT_TYPES[0].key
        };


    }

    componentDidMount() {
        this.getListContract()
    }

    getListContract() {
        let {code, status} = this.state;
        let payload = {
            code: code,
            // status: status,
            pageNumber: 0,
            pageSize: 15,
        };
        PublicContractApi.searchPaging(payload, res => {
            if (res.success) {
                let data = res.data.content;
                this.setState({
                    listContract: data,
                    contractId: data ? data[0].id : "",
                    totalItem: res.data.totalElements,
                    showLoading: false,
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng thử lại sau!" + res.message)
            }
        });
    }

    onSubmit = () => {
        let file = this.props.files[0]
        let {contractId, docType} = this.state
        let payload = {
            contractId: contractId,
            destination: file.destination,
            name: file.name,
            type: docType,
            url: file.storageName,
        }
        console.log("check payload>>>>>", payload)
        DocumentApi.create(payload, res => {
            if (res.success) {
                this.setState({modalUploadFile: false}, () => {
                    toast.success("Gán file vào hợp đồng thành công!")
                    // setTimeout(() => {
                    //     this.getListDataContractFromAPI()
                    // }, 600);
                    if(this.props.onHide){
                        this.props.onHide();
                    }
                })
            } else {
                toast.error("Thao tác không thành công, vui lòng thử lại!")
            }

        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>CẬP NHẬT FILE VÀO HỢP ĐỒNG</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                    <label className="col-5 label-item pl-0">Tên hợp đồng</label>
                                    <select
                                        value={this.state.contractId}
                                        onChange={val => {
                                            console.log(val.target.value)
                                            this.setState({contractId: val.target.value})
                                        }

                                        }
                                        className="form-control kt-input pd-4-input shadow-none">
                                        {this.state.listContract.map((item, index) =>
                                            <option value={item.id}>[{item.code}] {item.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                    <label className="col-5 label-item pl-0">Loại Tài liệu</label>
                                    <select
                                        value={this.state.docType}
                                        onChange={val => {
                                            console.log(val.target.value)
                                            this.setState({docType: val.target.value})
                                        }

                                        }
                                        className="form-control kt-input pd-4-input shadow-none">
                                        {this.state.docTypes.map((item, index) =>
                                            <option value={item.key}>{item.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    // disabled={this.state.reason?.length == 0}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalAddFileToContract
