import React from 'react';
import {stringify} from 'qs';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import Dropzone from "react-dropzone";
import FilesApi from "../../../services/resource/files";
import {toast} from "react-toastify";
import ModalAddFileToContract from "../../../components/Modal/modalAddFileToContract";
class UploadFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            filesUploaded: [],
            modalAddFileToContract: false,
        }
    }

    componentDidMount() {
    }

    uploadFile = () => {
        let payload = {
            file: this.state.files,
        }

        FilesApi.uploadSecureFile(payload, res => {
            if (res.success) {
                this.setState({
                    files: [],
                    filesUploaded: res.data,
                    modalAddFileToContract: true,
                }, () => {
                    toast.success("Upload file thành công")
                    // this.props.onHide();
                    // this.props.enqueueSnackbar('Upload thành công!', {
                    //     variant: 'success',
                    // });
                })
            }

            // console.log("upload secure response>>>>>>>>>", res);
        }).catch(error => console.log("upload error>>>", error))

    }


    render() {
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="upload-files"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Order"/>
                                </div>
                                <div className="p-0 mb-3">
                                    <Dropzone accept="image/*, application/pdf"
                                              onDrop={acceptedFiles => {
                                                  console.log(acceptedFiles)
                                                  this.setState({files: acceptedFiles, filesUpload: acceptedFiles})
                                                  // this.setState({
                                                  //     filesCertificate: acceptedFiles.map(file => Object.assign(file, {
                                                  //         preview: URL.createObjectURL(file)
                                                  //     }))
                                                  // })
                                              }}
                                              multiple={false}>
                                        {({getRootProps, getInputProps}) => (
                                            <section className="container-upload">
                                                <div {...getRootProps({className: 'dropzone h-350 justify-content-center'})}>
                                                    <input {...getInputProps()} />
                                                    {files.length > 0 ? <div className="align-center">
                                                        <ul>{files}</ul>
                                                    </div> : <div className="align-center justify-content-center">
                                                        <span className="fas fa-upload font-size-xxl mb-3"/>
                                                        <p>Kéo tập tin vào đây hoặc nhấn để chọn tập tin upload</p>
                                                    </div>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.name?.length == 0 || this.state.files.length == 0}
                                        onClick={() => {
                                            this.uploadFile()
                                        }}
                                    >
                                                <span className='d-flex justify-content-center'>
                                                    Tải lên
                                                </span>
                                    </button>
                                    {/*<button*/}
                                    {/*    type="button"*/}
                                    {/*    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"*/}
                                    {/*    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }*/}
                                    {/*    onClick={this.props.onHide}*/}
                                    {/*>*/}
                                    {/*            <span className='d-flex justify-content-center'>*/}
                                    {/*                Hủy*/}
                                    {/*            </span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalAddFileToContract && <ModalAddFileToContract files={this.state.filesUploaded} onHide={() => this.setState({modalAddFileToContract: false})}/>}
            </div>
        );
    }

}

export default UploadFiles
