import React, {Component} from "react";

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Rating from '@material-ui/lab/Rating';
import Grid from "@material-ui/core/Grid";
import "./index.css";
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import {TablePagination} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SupervisorApi from "../../../../services/ob-user/supervisor";
import PartnerApi from "../../../../services/ob-user/partner";
import Pagination from "../../../../components/Pagination";
import {URL_IMG_PUB} from "../../../../url.config";

class ChooseSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 9,
            address: '',
            idAddress: '',
            areaId: "",
            data: [],
            onSearching: false,
            totalItem: 0,
            totalPage: 0,
            listAllPartner: [],
            supervisorTop: [],
            idRadio:"",
            supervisorName: '',
            selectedSupervisorName: '',
            nextButton: true,
            listProvince: JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [],
        }
    }

    componentDidMount() {
        this.props.showLoading()
        // this.getAllPartner();
        this.getTopSup();
        // this.getListSupervisor();
    };

    getListSupervisor() {
        let {
            page,
            pageSize,
            supervisorName,
            idAddress,
            address,
            sort
        } = this.state;

        if (supervisorName == '' && address == '') {
            this.setState({
                onSearching: false
            })
        } else {
            this.setState({
                onSearching: true
            })
        }
        let payload = {
            pageNumber: page,
            pageSize,
            fullName: supervisorName,
            areaId: idAddress,
            sort
        }
        SupervisorApi.searchByFullNameAndAddress(payload, (res) => {
            console.log('res: ', res);
            if (res.success) {
                this.setState({
                    data: this.state.onSearching ? res.data.content : res.data.content.filter(element => element.id != this.state.supervisorTop[0].id
                        && element.id != this.state.supervisorTop[1].id
                        && element.id != this.state.supervisorTop[2].id
                    ),
                    // totalItem: this.state.onSearching ? res.data.totalElements : res.data.totalElements - 3,
                    totalItem: res.data.totalElements,
                    totalPage: this.state.onSearching ? Math.round((res.data.totalElements) / this.state.pageSize) : Math.round((res.data.totalElements - 3) / this.state.pageSize)
                }, () => this.props.hideLoading());
            }
        })
    };

    getTopSup() {
        let payload = {
            pageNumber: 0,
            pageSize: 1000,
        }
        SupervisorApi.searchByFullNameAndAddress(payload, (res) => {
            if (res.success) {
                this.setState({
                    supervisorTop: res.data.content.sort(function (a, b) {
                        return b.vote - a.vote;
                    }).slice(0, 3)

                }, () => {
                    this.getListSupervisor()
                });
            }
        })
    }

    handleChangeRadio(item) {
        this.setState({
            idRadio: item.id,
            selectedSupervisorName: item.fullName,
        }, () => {
            console.log("idRa>>>>>", item, this.state.idRadio, this.state.selectedSupervisorName)
            this.setState({
                nextButton: false
            })
        })
    }

    getAllPartner() {
        let {
            page,
            pageSize,
        } = this.state;
        let payload = {
            pageNumber: page,
            pageSize,
        }
        PartnerApi.searchByPartnerNameAndAddress(payload, (res) => {
            if (res.success) {
                this.setState({
                    listAllPartner: res.data.content,
                });
            }
        })
    };

    handleChangePage = (page) => {
        this.setState(
            {
                page: page - 1
            },
            () => {
                this.getListSupervisor();
            }
        );
    }

    render() {
        const {data, page, pageSize, totalItem, supervisorName, listProvince, address, supervisorTop, idAddress} = this.state;
        let self = this;
        return (
            <div>
                <div className='p-0-24 mb-5'>
                    <div>
                        <div className="form-Search">
                            <div className="row" container spacing={2}>
                                <div className="col-xs-12 col-sm-4 col-md-4" item xs={12} sm={4} md={4}>
                                    <div className="kt-margin-b-10-tablet-and-mobile">
                                        <TextField
                                            className='mt-4'
                                            label="Tên Supervisor "
                                            fullWidth
                                            size='small'
                                            value={supervisorName}
                                            variant={"outlined"}
                                            // InputProps={{style: {height: 28}}}
                                            InputLabelProps={{
                                                shrink: true,
                                                style:{fontSize: 18, backgroundColor: "#FFF", paddingRight: 5}
                                            }}
                                            placeholder='E.g: Nguyễn Văn An'
                                            onChange={(val) => {
                                                this.setState({supervisorName: val.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                                {/*<Grid item xs={12} sm={4} md={4}>*/}
                                {/*    <div className="kt-margin-b-10-tablet-and-mobile">*/}
                                {/*        <Autocomplete*/}
                                {/*            size="small"*/}
                                {/*            options={listProvince}*/}
                                {/*            getOptionLabel={option => option.name}*/}
                                {/*            style={{width: '100%'}}*/}
                                {/*            placeholder='E.g: Thành phố Hà Nội'*/}
                                {/*            inputValue={address}*/}
                                {/*            renderInput={params => (*/}
                                {/*                <TextField*/}
                                {/*                    className='mt-4'*/}
                                {/*                    label="Khu vực"*/}
                                {/*                    {...params}*/}
                                {/*                    InputLabelProps={{*/}
                                {/*                        shrink: true,*/}
                                {/*                        style:{fontSize: 18, backgroundColor: "#FFF", paddingRight: 5}*/}
                                {/*                    }}*/}
                                {/*                    variant="outlined"*/}
                                {/*                    fullWidth*/}
                                {/*                />*/}
                                {/*            )}*/}
                                {/*            onInputChange={(val) => {*/}
                                {/*                this.setState({*/}
                                {/*                    address: val.target.value*/}
                                {/*                })*/}
                                {/*            }}*/}
                                {/*            disableClearable={true}*/}
                                {/*            onChange={(c, val) => {*/}
                                {/*                this.setState({*/}
                                {/*                    address: val.name,*/}
                                {/*                    idAddress: val.id*/}
                                {/*                })*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </div>*/}

                                {/*</Grid>*/}
                                <div className="col-xs-12 col-sm-4 col-md-4 mt-4">
                                    <button
                                        type="button"
                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                        onClick={() => {
                                            this.getListSupervisor()
                                        }}
                                    >
                                        Tìm kiếm
                                    </button>
                                    {/*&nbsp;&nbsp;*/}
                                    <button type="button"
                                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                            onClick={() => {
                                                this.setState({
                                                    supervisorName: '',
                                                    address: '',
                                                    idPartner: ''
                                                }, () => {
                                                    this.getListSupervisor()
                                                })
                                            }}
                                    >Refresh
                                    </button>
                                </div>
                                {/*<Grid item xs={12} sm={4} md={4} className=' mt-4'>*/}
                                {/*    <Button*/}
                                {/*        className='mr-3 buttonSearch'*/}
                                {/*        variant="outlined"*/}
                                {/*        color="default"*/}
                                {/*        onClick={() => {*/}
                                {/*            this.setState({*/}
                                {/*                supervisorName: '',*/}
                                {/*                address: '',*/}
                                {/*                idPartner: ''*/}
                                {/*            }, () => {*/}
                                {/*                this.getListSupervisor()*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Refresh*/}
                                {/*    </Button>*/}
                                {/*    <Button*/}
                                {/*        className='buttonSearch'*/}
                                {/*        variant="contained"*/}
                                {/*        color="primary"*/}
                                {/*        onClick={() => {*/}
                                {/*            this.getListSupervisor()*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Tìm kiếm*/}
                                {/*    </Button>*/}
                                {/*</Grid>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div variant="outlined">
                    <div>
                        <h5 className="d-flex mt-4 mb-3 d-none text-uppercase font-weight-bold">
                            Supervisor ưu tiên
                        </h5>
                        <div>
                            <div className="row">
                                {supervisorTop.map((item, index) => {
                                    return (
                                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                            <div onClick={() => this.handleChangeRadio(item)} className={item.id == this.state.idRadio ? 'supervisor-card-item card p-2 item-selected' : 'supervisor-card-item card p-2 item-unselect priority-supervisor'}>
                                                <div className="justify-center col-12 text-center supervisor-img-wrapper">
                                                    <div className='align-middle' style={{cursor: 'pointer'}} onClick={() => this.handleChangeRadio(item)}>
                                                        <Radio
                                                            checked={item.id == this.state.idRadio}
                                                            onChange={() => this.handleChangeRadio(item)}
                                                        />
                                                    </div>
                                                    <div className='text-center'>
                                                    <img
                                                        // onClick={() => this.handleChangeRadio(item)}
                                                        // src={`media/users/100_${item.id}.jpg`}
                                                        src={item.urlImage ? item.urlImage : '/images/no-avatar-png.png'}
                                                        className='m-3 supervisor-img-avt'
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                    </div>
                                                </div>

                                                <div className='col-12 pl-2'>
                                                    <div className='pt-2 justify-center text-center'>
                                                        <h6
                                                            className='text-uppercase font-weight-bold'
                                                            style={{cursor: 'pointer'}}
                                                            // onClick={() => this.handleShowDetail(item)}
                                                        >
                                                            {item.fullName}
                                                        </h6>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <label className='text-black-50 font-14'>Đánh
                                                            giá:&nbsp;</label>
                                                        <Rating size="small" defaultValue={item.vote}
                                                                precision={0.5}
                                                                readOnly/>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Lĩnh
                                                            vực hỗ
                                                            trợ:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.fieldSupport || "N/A"}
                                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Số năm
                                                            kinh
                                                            nghiệm:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.experience || "N/A"}
                                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Thuộc
                                                            công
                                                            ty:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.partnerName || item.partnerName}
                                                                        </span>
                                                    </div>
                                                    {/*<div>*/}
                                                    {/*    <label className='text-black-50 font-14'>Khu vực*/}
                                                    {/*        hỗ*/}
                                                    {/*        trợ:&nbsp;</label>*/}
                                                    {/*    <span*/}
                                                    {/*        className='font-14'*/}
                                                    {/*    >*/}
                                                    {/*                        /!*{item.address}*!/*/}
                                                    {/*        Hà Nội*/}
                                                    {/*                    </span>*/}
                                                    {/*</div>*/}
                                                    <div>
                                                        <label className='text-black-50 font-14'>Địa chỉ:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                            {item.address || "N/A"}
                                                            {/*ketoan.text*/}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>


                    <div>
                        <h5 className="d-flex mt-4 mb-3 d-none text-uppercase font-weight-bold">
                            Danh sách Supervisor
                        </h5>

                        <div>
                            <Grid container spacing={1}>
                                {data.map((item, index) => {
                                    return (
                                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                            <div onClick={() => this.handleChangeRadio(item)} className={item.id == this.state.idRadio ? 'supervisor-card-item card p-2 item-selected' : 'supervisor-card-item card p-2 item-unselect'}>
                                                <div className="justify-center col-12 text-center supervisor-img-wrapper">
                                                    <div className='align-middle' style={{cursor: 'pointer'}} onClick={() => this.handleChangeRadio(item)}>
                                                        <Radio
                                                            checked={item.id == this.state.idRadio}
                                                            onChange={() => this.handleChangeRadio(item)}
                                                        />
                                                    </div>
                                                    <div className='text-center'>
                                                        <img
                                                            // onClick={() => this.handleChangeRadio(item)}
                                                            // src={`media/users/100_${item.id}.jpg`}
                                                            src={item.urlImage ? item.urlImage : '/images/no-avatar-png.png'}
                                                            className='m-3 supervisor-img-avt'
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-12 pl-2'>
                                                    <div className='pt-2 justify-center text-center'>
                                                        <h6
                                                            className='text-uppercase font-weight-bold'
                                                            style={{cursor: 'pointer'}}
                                                            // onClick={() => this.handleShowDetail(item)}
                                                        >
                                                            {item.fullName}
                                                        </h6>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <label className='text-black-50 font-14'>Đánh
                                                            giá:&nbsp;</label>
                                                        <Rating size="small" defaultValue={item.vote}
                                                                precision={0.5}
                                                                readOnly/>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Lĩnh
                                                            vực hỗ
                                                            trợ:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.fieldSupport || "N/A"}
                                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Số năm
                                                            kinh
                                                            nghiệm:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.experience || "N/A"}
                                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label className='text-black-50 font-14'>Thuộc
                                                            công
                                                            ty:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                                            {item.partnerName || "N/A"}
                                                                        </span>
                                                    </div>
                                                    {/*<div>*/}
                                                    {/*    <label className='text-black-50 font-14'>Khu vực*/}
                                                    {/*        hỗ*/}
                                                    {/*        trợ:&nbsp;</label>*/}
                                                    {/*    <span*/}
                                                    {/*        className='font-14'*/}
                                                    {/*    >*/}
                                                    {/*                        /!*{item.address}*!/*/}
                                                    {/*        Hà Nội*/}
                                                    {/*                    </span>*/}
                                                    {/*</div>*/}
                                                    <div>
                                                        <label className='text-black-50 font-14'>Địa chỉ:&nbsp;</label>
                                                        <span
                                                            className='font-14'
                                                        >
                                                            {item.address || "N/A"}
                                                            {/*ketoan.text*/}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Grid>
                        </div>
                    </div>
                    <div className="mt-2 mb-2" style={{marginRight: "0.75rem"}}>
                        <div className="pagination-right justify-flex-end mt-4">
                            <Pagination
                                activePage={this.state.page + 1}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalItem}
                                changeHandler={page => this.handleChangePage(page)}
                            />
                        </div>
                        {/*<Grid item xs={12} sm={12} md={12} className='text-right mt-4'>*/}
                        {/*    <TablePagination*/}
                        {/*        rowsPerPageOptions={[3, 10, 20, 30, 40]}*/}
                        {/*        component="div"*/}
                        {/*        count={totalItem}*/}
                        {/*        page={page}*/}
                        {/*        rowsPerPage={pageSize}*/}
                        {/*        labelRowsPerPage={'Số kết quả trên trang'}*/}
                        {/*        // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                        {/*        // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                        {/*        labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}*/}
                        {/*        onChangePage={(event, newPage) => {*/}
                        {/*            this.setState({page: newPage}, () => {*/}
                        {/*                this.getListSupervisor();*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*        onChangeRowsPerPage={(event) => {*/}
                        {/*            this.setState({*/}
                        {/*                pageSize: event.target.value,*/}
                        {/*                page: page*/}
                        {/*            }, () => {*/}
                        {/*                this.getListSupervisor()*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </div>
                    <Grid container direction="row" justify="flex-end" style={{marginBottom: 20}}>
                        <button type="button"
                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                onClick={() => {
                                    this.props.nextPage("choose-plan")
                                }}
                        >
                            Quay lại
                        </button>

                        <button
                            type="button"
                            // disabled={!this.state.selectedService}
                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                            onClick={() => {
                                // console.log(">>>>>>>>",self.state.idRadio, self.state.selectedSupervisorName)
                                this.props.chooseSupervisor(self.state.idRadio, self.state.selectedSupervisorName)
                                this.props.nextPage("confirm-service")
                            }}
                        >
                            Tiếp theo
                        </button>

                    </Grid>
                </div>
            </div>
        );
    }
}

export default ChooseSupervisor;
