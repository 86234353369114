import React from 'react';
import {withSnackbar, SnackbarProvider} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";
import Utils from  '../../utils/utils'

import {
    Modal,
} from 'react-bootstrap';

import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import ModalForgotPassword2 from "../Modal/modalForgotPassword2";

import PublicOrderApi from "../../services/ob-core/public-order-service";

class ModalAcceptOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailId:'',
            name:'',
            phone:'',
            email:'',
            contract:'',
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        };
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                name:'',
                phone:'',
                email:'',
                contract:'',
                nameErr: false,
                phoneErr: false,
                emailErr: false,
                contractErr: false,
                btnStatus: false,
            });
        }
    }

    accept = () => {
        let payload = {
            id: this.props.selectedOrder.id
        };
        PublicOrderApi.acceptOrder(payload, res => {
            if (res.success === true) {
                console.log('AcceptSuccess>>>>>>:', res)

                this.props.enqueueSnackbar('Xác nhận Order thành công', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'success',
                });
                    this.props.onRefresh()
            } else {
                console.log('toang>>>>>', res)
            }
        });
    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
            acceptId: this.props.acceptId
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:18,}}><b>Bạn có chắc chắn nhận Order {this.props.selectedOrder.code} không ?</b></h1>
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s background-btn type-button-fix btn btn-primary"
                                        onClick={this.accept}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s width-btn type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAcceptOrder)
