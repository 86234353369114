import React from 'react';
import {toast} from "react-toastify";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import NumberFormat from "react-number-format";
import ModalCreateBranch from "../../../components/Modal/modalCreateBranch";
import ModalDeleteBranch from "../../../components/Modal/modalDeleteBranch";

import BranchApi from "../../../services/ob-user/branch";
import { checkPermission } from '../../../utils/common';

class CompanyOrganizationalChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDelete: false,
            itemSelected: {},
            data: [],
            partner: {}
        }
    }

    componentDidMount() {
        this.setState({
            showLoading: true
        }, () => this.getListBranch())
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
    }

    getListBranch = () => {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        let payload = {
            id: profile.id
        }
        BranchApi.getListById(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data,
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    render() {
        const {data} = this.state;
        return (
            <div className="container">
                <div className="row">
                    <Sidebar activeClass="company-organizational-chart" parentClass="staff-management-partner"/>
                    <div className="mb-4 col-md-10 tab-content">
                        {this.state.showLoading &&
                        <div className="content-loading col-12 ml-3 align-items-center"
                             style={{display: 'block'}}>
                            <div className="spinner-grow text-info"/>
                        </div>
                        }
                        <div className="card col-12">
                            <div className="pt-3 mb-2">
                                <PageHeader routerEnable={true} title="Sơ đồ công ty"/>
                                <h2 className='font-weight-bold text-center m-2 mb-3'
                                    style={{fontSize: 24}}>DANH SÁCH CHI NHÁNH</h2>
                                <div className='mb-5'>
                                    <div className="row kt-margin-b-20 mb-2 p-4">
                                        {data.length === 0 ? 'Chưa có dữ liệu' : data.map((item, index) => (
                                            <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 pl-3 pr-3 pt-0 pb-2'
                                                 style={{minWidth: 200}}>
                                                {(checkPermission("delete_branch")) &&
                                                <span
                                                    className='icon-delete-branch'
                                                    title='Xóa chi nhánh'
                                                    onClick={() => {
                                                        this.setState({itemSelected: item}, () => this.setState({showDelete: true}))
                                                    }}
                                                >
                                                    <i className='ti-close'/>
                                                </span>
                                                }

                                                <div className='border-1-s p-2 pt-4 pb-4 h-100 w-100'
                                                     style={{borderRadius: 6}}>
                                                    <h2 className='font-weight-bold text-center'
                                                        style={{fontSize: 18}}>Chi nhánh</h2>
                                                    <h2 className='font-weight-bold text-center m-2 mb-3'
                                                        style={{fontSize: 18, minHeight: 42}}>{item.branchName}</h2>
                                                    <div className='d-flex row'>
                                                        <span className="label-item text-black-50 col-lg-9 col-md-10 mb-2">S/lg Supervisor</span>
                                                        <span className='col-lg-3 col-md-3 pl-1 pr-1'>{item.countSupervisor}</span>
                                                        <span className="label-item text-black-50 col-lg-9 col-md-10 ">S/lg kế toán</span>
                                                        <span className='col-lg-3 col-md-1 pl-1 pr-1'>{item.countAccountant}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}

                                        <div className='col-xs-12 col-sm-6 col-md-3 col-lg-3  pl-4 pr-4 pt-0 pb-2'>
                                            {(checkPermission("create_branch")) &&
                                            <button
                                                className='border-1-s p-3 h-100 cursor-pointer type-button-fix btn btn-light shadow-none w-100'
                                                style={{borderRadius: 6, backgroundColor: '#fff'}}
                                                onClick={() => {
                                                    this.setState({showCreate: true})
                                                }}
                                            >
                                                <h2 className='text-center m-2 mb-3'
                                                    style={{fontSize: 20}}>Thêm chi nhánh</h2>
                                                <div className='overflow-hidden d-block'>
                                                    <img className='d-block m-auto' style={{height: 50}}
                                                         src='images/icon-plus.png'/>
                                                    {/*<img className='d-block m-auto' style={{height: 50}} src='images/icon-plus-bold.png'/>*/}
                                                    {/*<img className='d-block m-auto' style={{height: 50}} src='https://pngimage.net/wp-content/uploads/2018/06/icon-plus-png-7.png'/>*/}
                                                </div>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showCreate && <ModalCreateBranch show={this.state.showCreate}
                                                             onHide={() => this.setState({showCreate: false})}
                                                             onRefresh={() => this.getListBranch()}
                />}
                {this.state.showDelete && <ModalDeleteBranch show={this.state.showDelete}
                                                             data={this.state.itemSelected}
                                                             onHide={() => this.setState({showDelete: false})}
                                                             onRefresh={() => this.getListBranch()}
                />}

            </div>
        );
    }
}

export default CompanyOrganizationalChart
