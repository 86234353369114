import React from 'react';
import {Star, StarFill} from 'react-bootstrap-icons';
import advanceSearch from '../../../assets/images/search/interface icpn.png';
import '../homepage/style.css';
import './style.css';
import '../supervisors/style.css';
import Link from "../../../components/Link";
import PublicSupervisorApi from "../../../services/ob-user/public-supervisor";
import PublicPartnerApi from "../../../services/ob-user/public-partner";
import Pagination from "../../../components/Pagination";

class PartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            sortSelect: "fullName=asc",
            sortBy: "",
            showLoading: false
        }
    }

    componentWillMount() {
        this.searchPartner()
    }

    searchPartner = () => {
        this.setState({
            showLoading: true
        })
        let payload = {
            pageNumber: 0,
            pageSize: 10,
            status: "ACTIVE",
            sort: this.state.sortSelect
        }
        PublicPartnerApi.searchByFullNameAndAddress(payload, res => {
            if (res.success) {
                this.setState({
                    partnerData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                })
            }
        })
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.searchPartner()
            }
        );
    }

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    render() {
        let {partnerData, totalItem, sortBy} = this.state
        return (
            <div className='list-sup-wrapper'>
                <div className='banner-search'>
                    <div className='search-panel container'>
                        <div className='row bg-white box-shadow-main search-content list-sup-search-content'>
                            <div className='col-md-7 pd-30 d-flex justify-content-center vertical-align-cente'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <h4 className='title color-main-dark mb-3'>
                                        Bắt Đầu Tìm Kiếm
                                    </h4>
                                    <div className='row selection'>
                                        <div className='col-12 p-0'>
                                            <div className='search'>
                                                <div className='form-group d-flex'>
                                                    <select
                                                        className='form-control'
                                                        placeholder='Chọn khu vực'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                areaId: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option disabled selected value='Chọn khu vực'>
                                                            Chọn khu vực
                                                        </option>
                                                        {this.renderSupportArea()}
                                                    </select>
                                                    <button
                                                        title='Tìm kiếm'
                                                        className='btn btn-sm ml-2'
                                                        style={{
                                                            width: 160,
                                                            border: '1px solid #ccc'
                                                        }}
                                                        onClick={() => {
                                                            this.searchPartner()
                                                        }}
                                                    >
                                                        <i className='fas fa-search color-main mr-1'/>
                                                        Tìm kiếm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<button className='advance-search color-main'>*/}
                                    {/*    <img*/}
                                    {/*        src={advanceSearch}*/}
                                    {/*        alt='advance-search'*/}
                                    {/*        className='img-small mr-3'*/}
                                    {/*    />*/}
                                    {/*    <span>Tìm kiếm nâng cao</span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className='col-md-5 pd-30 box-shadow-main'>
                                <h3 className='secondary-title mb-4 text-center'>
                                    <span className='color-main-dark'>Bạn Là</span>
                                    <span className='color-main'> Kế Toán ?</span>
                                </h3>
                                <Link to="/home/join-with-us">
                                    <div className='join-onebook color-white bg-green'>
                                        <span>Tham Gia</span>
                                        <span>ONEBOOK</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='search-table container bg-white app-separator'>
                    <div className='d-flex justify-content-between mb-5 flex-wrap'>
                        <div className='sup-count mb-2'><span style={{fontSize: "1.5rem"}}>{totalItem} ĐỐI TÁC</span>
                        </div>
                        <div className='sorter d-flex'>
                            <div className='form-group mr-4'>
                                <select
                                    className='form-control'
                                    id='exampleFormControlSelect3'
                                    placeholder='Sắp xếp theo'
                                    onChange={(val) => {
                                            this.setState({
                                                sortBy: val.target.value
                                            }, () => this.searchPartner())
                                    }}
                                >
                                    <option disabled selected value=''>
                                        Sắp xếp theo
                                    </option>
                                    <option value='fullName'>Tên</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <select
                                    className='form-control'
                                    id='exampleFormControlSelect4'
                                    onChange={(val) => {
                                        if (sortBy === "fullName") {
                                            this.setState({
                                                sortSelect: val.target.value
                                            }, () => this.searchPartner())
                                        }

                                    }}
                                >
                                    <option selected value='fullName=asc'>
                                        Tăng dần
                                    </option>
                                    <option value='fullName=desc'>Giảm dần</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {partnerData.map((item, index) => {
                        return (
                            <div className='list-sup-content app-separator'>
                                <div className='list-sup-item border-homepage mb-3'>
                                    <div className='row'>
                                        <div className='col-md-3 col-xl-2 pdy-15px avt-part'>
                                            <img src={item.urlImage} alt='avt' className='supervisor-avt'/>
                                        </div>
                                        <div className='col-md-9 col-xl-10'>
                                            <div
                                                className='partner-summary d-flex justify-content-between align-items-center flex-wrap'>
                                                <h4 className='color-main text-left text-bold' style={{paddingTop: 8}}>
                                                    {item.fullName}
                                                </h4>
                                                <div className='stars'>
                                                    <StarFill color='yellow'/>
                                                    <StarFill color='yellow'/>
                                                    <StarFill color='yellow'/>
                                                    <StarFill color='yellow'/>
                                                    <StarFill color='yellow'/>
                                                    {/*<Star />*/}
                                                    {/*<Star />*/}
                                                    {/*<Star />*/}
                                                    {/*<Star />*/}
                                                </div>
                                                <div className='rating-count'>0 Đánh giá</div>
                                            </div>
                                            <div className='partner-description'>
                                                <h5 className='color-main text-bold'>Dịch vụ hỗ trợ</h5>
                                                <ul>
                                                  <li>Soát xét báo cáo</li>
                                                  <li>Lập báo cáo tài chính</li>
                                                </ul>
                                                <h5 className='color-main text-bold'>Lĩnh vực hỗ trợ</h5>
                                                <ul>
                                                  <li>Thương mại</li>
                                                  <li>Dịch vụ</li>
                                                  <li>Sản xuất</li>
                                                </ul>
                                                <h5 className='color-main text-bold'>Địa chỉ</h5>
                                                <span className="font-14">{item.address}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="pagination-right">
                        {this.state.pageSize<this.state.totalItem && <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItem}
                            changeHandler={(page) => this.handleChangePage(page)}
                        />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PartnerList;
