import React, {Component} from "react";
import {toast} from "react-toastify";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import ModalConfirmReport from "../../../components/Modal/modalConfirmReport";
import ModalSupRejectReport from '../../../components/Modal/modalSupRejectReport';

import SupervisorApi from "../../../services/ob-user/supervisor";
import AccountantApi from "../../../services/ob-user/accountant";
import PublicReportContractApi from "../../../services/ob-core/public-report-contract";
import SecureContractApi from "../../../services/ob-core/secure-contract";
import ModalSupConfirmReport from "../../../components/Modal/modalSupConfirmReport";
import FilesApi from "../../../services/resource/files";

const dateFormat = 'DD-MM-YYYY'

const renderStatus = function (status) {
    switch (status) {
        case 'APPROVED':
            return <h6><span className="badge badge-success">Đã duyệt</span></h6>;
        case 'AWAITING_APPROVAL':
            return <h5><span className="badge badge-secondary">Chờ duyệt</span></h5>;
        case 'REJECTED':
            return <h5><span className="badge badge-warning">Bị từ chối</span></h5>;
        default:
            return;
    }
};

const renderType = function (type) {
    switch (type) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
};


class ReportManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            showReject: false,
            itemSelected: {},
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            contractCode: '',
            contractCode_temp: '',
            status: '',
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center", maxWidth: 40, minWidth: 40},
                    sort: false,
                },
                {
                    id: 'contract_code',
                    name: "Mã hợp đồng",
                    style: {textAlign: "left", overflow: 'hidden'},
                    // style: {minWidth: 140, textAlign: "left", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 140, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'type',
                    name: "Loại báo cáo",
                    // style: {textAlign: "center"},
                    style: {minWidth: 140, textAlign: "left"},
                    sort: true,
                },
                {
                    id: 'cmn_created_at',
                    name: "Ngày lập báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 170, textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'accept_at',
                    name: "Ngày duyệt báo cáo",
                    style: {textAlign: "center"},
                    // style: {minWidth: 200, textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'accountant_id',
                    name: "Kế toán thực hiện",
                    style: {textAlign: "center"},
                    // style: {minWidth: 200, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'supervisor_id',
                    name: "Supervisor theo dõi",
                    style: {textAlign: "center"},
                    // style: {minWidth: 200, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    // style: {minWidth: 120, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    // style: {textAlign: "center"},
                    style: {minWidth: 120, textAlign: "center"},
                    sort: false,
                },
            ],
        };
    }


    componentDidMount() {
        this.getListReportContract()
    }

    //
    getListReportContract = () => {
        let {
            pageNumber,
            pageSize,
            contractCode,
            status,
            sort,
            descending
        } = this.state;

        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // contractId: code,
            status: status,
            contractCode: contractCode,

            sort: sort,
            descending: descending

        }
        PublicReportContractApi.searchByContractIdAndDateAndStatus(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listAccountId = [];
                let listSupervisorId = [];
                //accountantId
                data.map(item => {
                    listAccountId.push(item.accountantId);
                });
                // console.log("listAccountId>>>>>>>>>>>>>", res, listAccountId);
                //supervisorId
                data.map(item => {
                    listSupervisorId.push(item.supervisorId);
                });
                // console.log("listSupervisorId>>>>>>>>>>>>>", res, listSupervisorId);
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize),
                    listAccountId,
                    listSupervisorId
                }, () => {
                    if (data.length > 0) {
                        this.getListAccountantByIdFromAPI();
                        this.getListSupervisorByIdFromAPI();
                    }
                })
            } else {
                // console.log('Fail>>>>>>>>>>>>>>>>>>>>>>>')
                toast.error("Đã có lỗi xảy ra  !")
            }

        })
    }

    getListAccountantByIdFromAPI() {
        let {listAccountId, listData} = this.state;

        let payload = {
            ids: listAccountId
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.accountantId);
                    item.accountantId = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {
                // console.log("toang>>>>>", res);
            }
        });
    }

    getListSupervisorByIdFromAPI() {
        let {listSupervisorId, listData} = this.state;

        let payload = {
            ids: listSupervisorId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorId = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {
                // console.log("sádasd>>>>>", res);
            }
        });
    }

    // --------------------------------------------------------------------------------------------------------

    handleChangePage = pageNumber => {
        this.setState(
            {
                pageNumber: pageNumber - 1
            },
            () => {
                this.getListReportContract();
            }
        );
    };

    onhandleSearch = () => {
        let {contractCode_temp, status_temp} = this.state;

        this.setState(
            {
                contractCode: contractCode_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListReportContract();
            }
        );
    };


    showModalAssignAccountant = item => {
        this.setState({selectedContract: item.id, modalAssignAccountant: true});
    };

    downloadReport = () => {
        let payload = {
            code: this.state.selectedFile.contractCode
        }
        SecureContractApi.contractCodeToCustomerId(payload, res => {
            if(res.success){
                let payload1 = {
                    fileId: this.state.selectedFile.id,
                    customerId: res?.data
                }
                FilesApi.downloadReportFileByIdCus(payload1, res1 => {
                    if (res1.success) {
                        // console.log(res.data)
                        let a = document.createElement("a");
                        a.href = "data:application/octet-stream/png;base64," + res1.data.content;
                        a.download = `${this.state.selectedFile.name}.${res1.data.extension}`
                        a.click();
                    }
                })
            }
        }) 
    }

    // ------------------------------------------------------------------------------------------------------

    render() {
        const {page, pageSize, totalItem, pageNumber} = this.state;
        let self = this;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="report-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title='Danh sách báo cáo'/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-6  p-0 pr-md-3'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    maxLength={20} type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    value={this.state.contractCode_temp}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') this.getListReportContract()
                                                    }}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            contractCode_temp: event.target.value.trim()
                                                        }, console.log(this.state.contractCode_temp))
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-sm-0 pr-0'>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status_temp: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='APPROVED'>Đã duyệt</option>
                                                    <option value='AWAITING_APPROVAL'>Chờ duyệt</option>
                                                    <option value='REJECTED'>Bị từ chối</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 text-left p-0 mt-3'>
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => this.onhandleSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button" className="btn border-1-s btn-light btn-sm"
                                                        style={{height: 35}}
                                                        onClick={() => {
                                                            this.setState({
                                                                contractCode_temp: '',
                                                                status_temp: '',

                                                            }, () => {
                                                                this.onhandleSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>

                                    <div className="table-wrapper">
                                        <table
                                            className="table table-striped table-data table-bordered"
                                        >
                                            <thead>
                                            <TableHeader header={this.state.header} clickSort={(data) => {
                                                console.log(data);
                                                let a = '';
                                                if (data.sortAsc == null) {
                                                    a = 'status'

                                                } else {
                                                    a = `${data.sortId}=${data.sortAsc}`;
                                                }
                                                // console.log(a);
                                                this.setState({
                                                    sort: a
                                                }, () => this.getListReportContract())
                                            }}/>
                                            </thead>
                                            {!this.state.listData || this.state.listData.length == 0
                                                ? <tbody>
                                                <tr>
                                                    <td colSpan={10}>
                                                        <div className='text-center'>Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                : <tbody>
                                                {this.state.listData.map((row, index) => (
                                                    <tr key={row.id} style={{height: 50}}>
                                                        <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                        <td style={{textAlign: 'left',}}>
                                                            {row.contractCode}
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>{row.name}</td>

                                                        <td style={{textAlign: 'left'}}>{renderType(row.reportTypeName)}</td>
                                                        <td style={{textAlign: 'center'}}>{row.cmnCreatedAt ? moment(row.cmnCreatedAt).format(dateFormat) : ''}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {row.acceptAt ? moment(row.acceptAt).format(dateFormat) : ''}
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>{row.accountantId}</td>
                                                        <td style={{textAlign: 'left'}}>{row.supervisorId}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {renderStatus(row.status)}
                                                        </td>
                                                        <td style={{textAlign: 'center', fontSize: 18, padding: 0}}>
                                                            <button
                                                                disabled={row.status !== "APPROVED"}
                                                                style={{
                                                                    color: row.status === "APPROVED" ? 'initial' : '#ccc',
                                                                    cursor: row.status === "APPROVED" ? 'pointer' : 'unset'
                                                                }}
                                                                className="btn btn-action p-2"
                                                                title="Xác nhận"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        itemSelected: row.id
                                                                    }, () => this.setState({showConfirm: true}))
                                                                }}
                                                            >
                                                                <i className="ti-check-box"/>
                                                            </button>
                                                            <button
                                                                disabled={row.status !== "APPROVED"}
                                                                style={{
                                                                    color: row.status === "APPROVED" ? 'initial' : '#ccc',
                                                                    cursor: row.status === "APPROVED" ? 'pointer' : 'unset'
                                                                }}
                                                                className="btn btn-action p-2"
                                                                title="Từ chối"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        itemSelected: row.id
                                                                    }, () => this.setState({showReject: true}))
                                                                }}
                                                            >
                                                                <i className="ti-close"/>
                                                            </button>
                                                            <button
                                                                className="btn btn-action p-2"
                                                                title='Tải xuống'
                                                                style={{cursor: 'pointer'}} onClick={() => {
                                                                this.setState({selectedFile: row}, () => {
                                                                    this.downloadReport()
                                                                })
                                                            }}>
                                                                <i className="ti-download"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>}
                                        </table>

                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showConfirm && <ModalSupConfirmReport id={this.state.itemSelected}
                                                                  onRefresh={() => this.onhandleSearch()}
                                                                  onHide={() => this.setState({showConfirm: false})}/>}
                {this.state.showReject && <ModalSupRejectReport id={this.state.itemSelected}
                                                                onRefresh={() => this.onhandleSearch()}
                                                                onHide={() => this.setState({showReject: false})}/>}
            </div>
        );
    }
}

export default ReportManagement;
