import React, {Component} from "react";
import {
    TextField,
} from '@material-ui/core';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";

import SecureCustomerApi from "../../../../services/ob-user/secure-customer";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import Convert from "../../../../utils/convertUrlPra";

const formatDate = "DD-MM-YYYY";

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn quận huyện'})
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn thành phố'})
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class EditCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinceId: 0,
            districtId: 0,
            business: "",
            dateIssued: null,

            companyEmail: "",
            companyPhone: "",

            placeIssued: "",
            position: "",

            fullName: "",
            phone: "",
            scale: "",
            status: "",
            tax: "",
            fastCode: "",
            urlImage: "",
            circulars: "",
            showRate: false,
            itemSelected: {},
            maxContract: "",
            maxAccountant: "",
            customerName: "",
            companyAddress: "",
            companyName: "",
            identify: "",
            sex: "",
            listData: [],
            customerId: null,
            onEdit: true,
            maxContractErr: false,
            maxAccountantErr: false,
            btnSaveStatus: false,
            businessErr: false,
            districtIdErr: false,
            companyEmailErr: false,
            companyPhoneErr: false,
            placeIssuedErr: false,
            positionErr: false,
            provinceIdErr: false,
            fullNameErr: false,
            phoneErr: false,
            scaleErr: false,
            statusErr: false,
            taxErr: false,
            fastCodeErr: false,
            urlImageErr: false,
            companyAddressErr: false,
            companyNameErr: false,
            identifyErr: false,
            sexErr: false,

        };
    }

    componentDidMount() {
        document.title = "Chi tiết Customer";
        this.getDetailFromAPI();
    }

    //******************************************************************************************************************

    getDetailFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.customerId
        };
        SecureCustomerApi.getDetailCustomer(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data,
                    companyName: res.data.companyName,
                    tax: res.data.tax,
                    companyEmail: res.data.companyEmail,
                    fastCode: res.data.fastCode
                });
            } else {
                toast.error("Đã có lỗi xảy ra!")
            }
        });
    }

    renderCirculars = (circulars) => {
        switch (circulars) {
            case "200":
                return "Thông tư 200/2014/TT-BTC"
            case "133":
                return "Thông tư 133/2016/TT-BTC"
        }
    }

    handleSubmit() {
        let {listData} = this.state
        let payload = {
            id: this.props.customerId,
            body: {
                tax: this.state.tax,
                fastCode: this.state.fastCode,
                companyName: this.state.companyName,
                circulars: this.state.circulars,
            }
        }
        console.log(payload)
        SecureCustomerApi.adminOneBookUpdateCustomer(payload, res => {
            if (res.success) {
                toast.success('Thông tin đã được cập nhật thành công !')
                this.props.change()
            } else {
                toast.error('Cập nhật không thành công, vui lòng thử lại !')
            }
        })
    }


    renderBusiness(business) {
        switch (business) {
            case "COMMERCE_SERVICE":
                return "Thương mại";
            case "MANUFACTURING_MACHINING":
                return "Sản xuất";
            case "BUILDING_INSTALLATION":
                return "Xây dựng";
            default:
                return;
        }
    }

    renderScale(scale) {
        switch (scale) {
            case "SMALL":
                return "Nhỏ";
            case "MEDIUM":
                return "Vừa";
            case "EXTRASMALL  ":
                return "Siêu nhỏ";

            default:
                return;
        }
    }

    handleChangeTax(val) {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({
                    taxError: false,
                    btnSaveStatus: false,
                    inEditing: true
                }) : this.setState({taxError: true, btnSaveStatus: true, inEditing: false});
                this.state.tax.length >= 10 ? this.setState({
                    taxError: false,
                    btnSaveStatu: false,
                    inEditing: true
                }) : this.setState({taxError: true, btnSaveStatus: true, inEditing: false})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    //******************************************************************************************************************


    render() {
        let {
            listData,
        } = this.state;
        return (
            <div className='p-0'>
                <div className='text-center'>
                    <img
                        src={listData.urlImage}
                        className='img-supervisor cursor-pointer'
                        // onClick={this.state.onEdit?this.showModalUploadAvatar:''}
                    />
                </div>

                {/*_________________________________________________Company Information_____________________________________________*/}

                <div className='row'>

                    <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin doanh nghiệp</span>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                            <span>Tên doanh nghiệp{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                        </div>
                        <div
                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                            {this.state.onEdit ?
                                <input type="text"
                                       className={this.state.companyNameErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                       placeholder='Nhập tên...'
                                       value={this.state.companyName}
                                       maxLength={50}
                                       onChange={(val) => this.setState({
                                           companyName: val.target.value.trimLeft(),
                                           inEditing: true
                                       }, () => {
                                           this.state.companyName.length == 0 ? this.setState({
                                               companyNameErr: true,
                                               btnSaveStatus: true
                                           }) : this.setState({companyNameErr: false, btnSaveStatus: false})
                                       })}
                                />

                                : listData.companyName ? listData.companyName : ''
                            }
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                            <span>Mã số thuế{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                        </div>
                        <div
                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                            {this.state.onEdit ?
                                <input type="text"
                                       className={this.state.taxErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                       placeholder='Nhập mã số...'
                                       value={this.state.tax}
                                       maxLength={50}
                                       onChange={(val) => this.handleChangeTax(val)}
                                />

                                : listData.tax ? listData.tax : ''
                            }
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                            <span>Mã đơn vị cơ sở(Fast){this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                        </div>

                        <div
                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                            {this.state.onEdit ?
                                <input type="text"
                                       className={this.state.fastCodeErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                       placeholder='Nhập mã đơn vị cơ sở'
                                       value={this.state.fastCode}
                                       maxLength={50}
                                       onChange={(val) => this.setState({
                                           fastCode: val.target.value.trimLeft(),
                                           inEditing: true
                                       }, () => {
                                           this.state.fastCode.length == 0 ? this.setState({
                                               fastCodeErr: true,
                                               btnSaveStatus: true
                                           }) : this.setState({fastCodeErr: false, btnSaveStatus: false})
                                       })}
                                />

                                : listData.fastCode ? listData.fastCode : ''
                            }
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile text-black-50">
                            <label>Email </label>
                        </div>
                        <div
                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                            {/* {this.state.onEdit ?
                                <TextField
                                    fullWidth
                                    variant={"outlined"}
                                    InputProps={{style: {height: 30}}}
                                    maxLength={150}
                                    value={this.state.companyEmail}
                                    onChange={(val) => this.setState({
                                        companyEmail: val.target.value.trim(),
                                        inEditing: true
                                    }, () => {
                                        this.state.companyEmail.length != 0 ? this.setState({companyEmailError: false, btnSaveStatus: false}) : this.setState({companyEmailError: true, btnSaveStatus: true})
                                    })}

                                    error={this.state.companyEmailError}
                                    helperText={this.state.companyEmailError && 'Vui lòng nhập email !!'}
                                />
                                : listData.companyEmail ? listData.companyEmail : ''
                            } */}
                            {listData.companyEmail}
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Lĩnh vực hoạt động</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {this.renderBusiness(listData.business)}
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Số điện thoại</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.companyPhone}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Quy mô hoạt động</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {this.renderScale(listData.scale)}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Tỉnh/Thành phố</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.placeIssued}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Địa chỉ</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.companyAddress}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Quận huyện</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {renderDistrictId(listData.districtId)}
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Thông tư</span>
                        </div>
                        <div
                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                            <select
                                className="form-control kt-input pd-4-input hei-input"
                                value={listData.circulars}
                                onChange={(e) => {
                                    listData.circulars = e.target.value;
                                    this.setState({
                                        circulars: e.target.value,
                                        inEditing: true
                                    })
                                }}
                            >
                                <option value="200">Thông tư 200/2014/TT-BTC</option>
                                <option value="133">Thông tư 133/2016/TT-BTC </option>
                            </select>
                        </div>
                    </div>
                </div>
                {/*______________________________________________Supervisor Information__________________________________________*/}

                <div className='row mt-5'>

                    <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin tài khoản</span>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Nguời đại diện</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.fullName}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Giới tính</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.sex === 'MALE' && 'Nam'}
                            {listData.sex === 'FEMALE' && 'Nữ'}
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Quận/Huyện</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {renderDistrictId(listData.districtId)}
                        </div>
                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Chức vụ</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.position}
                        </div>


                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>CMT/CCCD</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.identify}
                        </div>

                        <div
                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                            <span>Ngày cấp</span>
                        </div>
                        <div
                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                            {listData.dateIssued ? moment(listData.dateIssued).format("DD-MM-YYYY") : ""}
                        </div>

                    </div>

                    {this.state.onEdit ?
                        <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                            <button
                                style={{cursor: this.state.btnSaveStatus ? 'pointer' : 'unset'}}
                                disabled={this.state.btnSaveStatus}
                                className='btn btn-primary col-sm-3 col-md-3 mr-4'
                                onClick={() => this.handleSubmit()}
                                title="Lưu"
                            >
                                <span>Lưu</span>
                            </button>
                            <button
                                className='btn btn-light col-sm-3 col-md-3 border-1-s'
                                onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                title="Hủy"
                            >
                                <span>Hủy</span>
                            </button>
                        </div>
                        : <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                            <button
                                className='btn btn-primary col-sm-3 col-md-3 mr-4'
                                onClick={() => this.setState({
                                    onEdit: !this.state.onEdit,
                                    maxContract: listData.maxContract,
                                    maxAccountant: listData.maxAccountant,
                                    maxContractErr: false,
                                    maxAccountantErr: false,

                                })}
                                title="Chỉnh sửa "
                            >
                                <span>Chỉnh sửa</span>
                            </button>
                        </div>}


                </div>
            </div>


        );
    }
}


export default EditCustomer
