import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let ReportContractType = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract-type/get-all`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
}
export default ReportContractType
