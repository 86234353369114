import React, {Component} from "react";
import "./index.css"
import {Carousel} from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";

class PartnerCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="partner-company"/>
                        <div className="col-md-10 tab-content">
                            <div className="card col-12 p-3">
                            <div className="d-flex">
                                <img
                                    // src={item.urlImg}
                                    src={'images/Logo_UHY.png'}
                                    className="logo nav-item"
                                />
                                <div className="title-1 nav-item">
                                    {/*<h3 >Công ty TNHH Kiểm toán và Tư vấn UHY ACA</h3>*/}
                                    <h2 >CÔNG TY TNHH KIỂM TOÁN VÀ TƯ VẤN UHY ACA</h2>
                                </div>
                            </div>

                            {/*----------Tầm nhìn------------*/}

                            <div>
                                <img
                                    src={'images/banner.jpg'}
                                    className="banner-1"
                                />
                            </div>
                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="title-2">Tầm nhìn:</div>
                                        <div>
                                            Trở thành Trung tâm đào tạo Kế toán – Kiểm toán – Tư vấn – Tài chính – Ngân
                                            hàng hàng đầu tại Việt Nam, có vai trò nòng cốt trong đào tạo Kế toán viên,
                                            Kiểm toán viên quốc tế, các nhân viên chuyên nghiệp, sánh vai cùng các Trung
                                            tâm Đào tạo trong khu vực và trên thế giới.
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <img
                                            className="banner"
                                            src={'images/vision.jpg'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*----------Sứ mệnh------------*/}

                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-6">
                                        <img
                                            className="banner"
                                            src={'images/mission.jpg'}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <div className="title-2">Sứ mệnh:</div>
                                        <div>
                                            Đồng hành cùng sứ mệnh của Công ty Kiểm toán và Tư vấn UHY ACA “ Vì sự phát
                                            triển của khách hàng, vì sự thành công và hạnh phúc của nhân viên, vì niềm
                                            tin của xã hội"
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----------Giá trị cốt lõi------------*/}

                            <div className="container">
                                <div className="title-3 mt-5 col-12 text-center">Giá trị cốt lõi</div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-4">
                                        <b>Tín:</b> Đặt chữ tín lên hàng đầu và  bảo vệ chữ tín như bảo
                                        vệ danh dự của chính mình.
                                    </div>
                                    <div className="col-4">
                                        <b>Tâm:</b> Lấy chữ tâm là một trong những nền tảng quan trọng
                                        của việc xây dựng phát triển kinh doanh lâu dài.
                                    </div>
                                    <div className="col-4">
                                        <b>Trí:</b> Coi sáng tạo là sức sống, là đòn bẩy phát triển,
                                        nhằm tạo ra giá trị khác biệt và bản sắc riêng.
                                    </div>
                                </div>
                            </div>

                            {/*----------Đội ngũ nhân viên------------*/}

                            <div className="container mt-5 ">
                                <div className="title-3 mt-5 col-12 text-center">Đội ngũ nhân viên</div>
                                <div className="row mt-3 d-flex justify-content-between">
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/staff.png'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            <div className="name-staff">Majo George</div>
                                            <div>Trường Đại Học Quốc tế RMIT, Việt Nam</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/teacher.jpg'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            <div className="name-staff">Hà Thị Lục</div>
                                            <div>Công ty Dịch vụ Kiểm toán và Tư vấn UHY (UHY LTD)</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/staff.png'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            <div className="name-staff">Nguyễn Trung Thành</div>
                                            <div>MBA, Đại Học Hawaii, USA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----------Đới tác chiến lược------------*/}

                            <div className="container">
                                <div className="title-3 mt-5 col-12 text-center">Đối tác chiến lược</div>
                                <div className="row mt-3 d-flex justify-content-between">
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/partner.jpg'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            {/*<div className="name-staff">Majo George</div>*/}
                                            <div>Đại học Anh Quốc</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/partner.jpg'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            {/*<div className="name-staff">Majo George</div>*/}
                                            <div>Đại học Thương Mại Hà Nội</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div>
                                            <img
                                                src={'images/partner.jpg'}
                                                className="banner"
                                            />
                                        </div>
                                        <div className="staff">
                                            {/*<div className="name-staff">Majo George</div>*/}
                                            <div>Ngân hàng Tiên Phong Bank</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----------Thông tin liên hệ------------*/}

                            <div className="container">
                                <div className="title-3 mt-5 col-12 text-center">Thông tin liên hệ</div>
                                <div className="row mt-3 mb-5 d-flex justify-content-between">
                                    <div className="col-6">
                                        <div className="footer-name">Văn phòng công ty UHY tại Hà Nội</div>
                                        <div>Tầng 19, Charmvit Tower, 117 Trần Duy Hưng, Hà Nội</div>
                                        <div>ĐT : 04 3555 2088 / Fax: 04 3555 2089</div>
                                        <div>Email: training@uhyvietnam.com.vn</div>
                                        <div>Web site: Http://www.uhyaca.vn</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="footer-name">Văn phòng chi nhánh TP Hồ Chí Minh</div>
                                        <div>46 Trương Quyền, Phường 6, Quận 3, TP HCM</div>
                                        <div>ĐT : 08 3820 4899 / Fax: 08 3820 4909</div>
                                        <div>Email: infohcm@uhyvietnam.com.vn</div>
                                        <div>Web site: Http://www.uhyaca.vn</div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PartnerCompany;
