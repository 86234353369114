import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
// import {phoneNumber, deletePhoneNumber} from "../../services/phoneNumberApi";
import Cookies from "js-cookie";
import {withSnackbar} from "notistack";
import ModalForgotPassword2 from "../Modal/modalForgotPassword2";
import ModalForgotPassword3 from "../Modal/modalForgotPassword3";
import Authentication from "../../services/auth";
import {toast} from "react-toastify";

class ModalForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            showForgotPassword2: false,
            token: ''
        };
    }
    componentDidMount() {
        this.setState({name:'',});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({name:'',});
        }
    }

    handleShowModalForgotPassword2 = () => {
        this.setState({showForgotPassword2: true});
        this.props.onHideModal();
    };

    onCloseModalForgotPassword2 = ()=>{
        this.setState({showForgotPassword2: false});
    }

    handleShowModalForgotPassword3 = () => {
        this.setState({showForgotPassword3: true});
        this.props.onHideModal();
    };

    onCloseModalForgotPassword3 = () => {
        this.setState({showForgotPassword3: false});
    };

    onSubmit =()=>{
        if(this.state.name ===''){
            toast.error("Bạn chưa nhập tên đăng nhập")
        }else {
            let payload={
                name :this.state.name,
            }
            this.getOtp()
            // this.handleShowModalForgotPassword2()
        }
    }

    getOtp = () => {
        let payload = {
            phone: this.state.name
        }
        Authentication.forgotPasswordGetOtp(payload, res => {
            if (res.success) {
                // this.handleShowModalForgotPassword3();
                this.setState({token: res.data})
                this.props.setData(res.data, this.state.name)
            }
            else {
                return toast.error("Tên đăng nhập không tồn tại !")
            }
        })
    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHideModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:22,fontWeight:'bold'}}>Lấy lại mật khẩu</h1>
                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:14,}}>Vui lòng cung cấp tên tài khoản đăng nhập để lấy lại mật khẩu</h1>
                        </div>
                        <Grid container spacing={1} item xs={12} >
                            <TextField
                                className="mr-1 ml-1"
                                id="outlined-margin-dense"
                                placeholder='Nhập tên đăng nhập'
                                margin="dense"
                                fullWidth
                                rows='1'
                                rowsMax="1"
                                value={this.state.name}
                                size="small"
                                inputProps={{ maxlength: 255 }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {this.setState({name: e.target.value.trim()})}}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} >
                            <Grid item xs={12}>
                                <div className="align-center mt-2 mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-original btn-1st w-100 type-button-fix"
                                        disabled={this.state.name?.length == 0}
                                        // href="/#"
                                        onClick={this.onSubmit}
                                    >
                                          <span>
                                            Xác nhận
                                            <i className="flaticon-right"/>
                                          </span>
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
                {/*<ModalForgotPassword2 show={this.state.showForgotPassword2} onHideModal={this.onCloseModalForgotPassword2} />*/}
                {/*<ModalForgotPassword3 show={this.state.showForgotPassword3}*/}
                {/*                      onHideModal={() => {*/}
                {/*                          this.onCloseModalForgotPassword3();*/}
                {/*                      }}*/}
                {/*                      token={this.state.token}*/}
                {/*                      name={this.state.name}*/}
                {/*/>*/}
            </div>
        );
    }
}


export default ModalForgotPassword;
