import React from 'react';
import Sidebar from "../../../components/Sidebar";
import Link from "../../../components/Link";
import Convert from "../../../utils/convertUrlPra";
import CommonApi from "../../../services/ob-user/common";

class ConfirmEmail extends React.Component{
    constructor(props) {
        super(props);
        this.state =  {
            token: null,
            confirmFail: false,
            sentConfirm: true,
            confirmStatus: null
        }
    }

    componentWillMount() {


    }

    componentDidMount() {
        let params = Convert.urlParams(this.props.location.search);
        console.log("check params url>>>>>>>>>",params);
        if (params.token) this.setState({token: params.token, confirmStatus: "SENT"}, () =>  this.confirmEmail())
    }

    confirmEmail = () => {
        let userType = localStorage.getItem("ob_cl_userType").toLowerCase()
        let payload = {
            type: userType,
            body: {
                token: this.state.token
            }
        }
        CommonApi.confirmEmail(payload, res => {
            if (res.success) {
                console.log("check confirm email>>>>>>>", res)
                // this.setState({confirmFail: false, sentConfirm: false})
                this.setState({confirmStatus: 'SUCCESS'})
            } else{
                this.setState({confirmStatus: 'FAIL'})
            }
        })
    }
    render() {
        return(
                <div className="tab-content justify-content-center align-center confirm-email-wrapper">
                    {!this.state.token &&
                    <div className='kt-margin-b-20 flex-column justify-content-center align-center p-3' style={{background: '#F1CDCB'}}>
                        <h6 className="line-height-30">ONEBOOK đã gửi một email xác thực thông tin đến hòm thư của bạn. Bạn hãy truy cập vào Email để xác nhận thông tin và tiếp tục sử dụng hệ thống.</h6>
                        <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                    </div>
                    }
                    {(this.state.token && this.state.confirmStatus == "SENT") &&
                    <div className='kt-margin-b-20 flex-column justify-content-center align-center p-3'
                         style={{background: '#0da9d38f'}}>
                        <h6 className="line-height-30">Đang xác thực, vui lòng chờ trong giây lát.</h6>
                        <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                    </div>}
                    {(this.state.token && this.state.confirmStatus == "FAIL") &&
                    <div className='kt-margin-b-20 flex-column justify-content-center align-center p-3'
                         style={{background: '#F1CDCB'}}>
                        <h6 className="line-height-30">Đã xảy ra lỗi trong quá trình xác thực, vui lòng thử lại</h6>
                        <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                    </div>
                    }
                    {(this.state.token && this.state.confirmStatus == "SUCCESS") &&
                        <div className='kt-margin-b-20 align-center p-3'
                            // style={{background: '#a8ce4aa1'}}
                        >
                            <h6 className="line-height-30">Bạn đã xác thực Email thành công! Nhấn vào nút <b>Trở
                                về</b> để về màn hình chính</h6>
                            <Link to="/" className="mt-4 background-btn type-button-fix btn btn-success">
                                Trở về</Link>
                        </div>
                    }
                </div>
        )
    }

}
export default ConfirmEmail
