import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import {toast} from 'react-toastify';
import Dropzone from "react-dropzone";
import FilesApi from "../../services/resource/files";
import UserWaitingApi from "../../services/ob-user/user-waiting";

class ModalUpdateSignupMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: this.props.show ? this.props.show : true,
            data: this.props.data ? this.props.data : {},
            areaId: this.props.data ? this.props.data.areaId : 1,
            provinceId: this.props.data ? this.props.data.provinceId : 1,
            position: this.props.data ? this.props.data.job : 'Accountant',
            certificate: this.props.data ? this.props.data.certificate : '',
            files: [],
            fileNameFile: '',
            fullNameFile: '',
            FileSave: [],
            showUpload: false,
            areaErr: false,
            positionErr: false,

        };
    }

    uploadFile = () => {
        let payload = {
            file: this.state.files,
        }
        FilesApi.uploadSecureFile(payload, res => {
            if (res.success) {
                this.setState({
                    fileNameFile: this.state.files[0].name,
                    FileSave: res.data,
                }, () => {
                    toast.success('Upload thành công !')
                })
            } else {
                toast.error("Upload xảy ra lỗi. Vui lòng thử lại !")
            }
        })
    }

    onSubmit = () => {
        let ready = true;

        if (this.state.position === '') {
            this.setState({
                positionErr: true,
            })
            ready = false;
        }
            // if (this.state.position === 'Supervisor' && this.state.files.length === 0) {
            //     this.setState({
            //         requireUpdate: true
            //     })
            //     ready = false;
        // }
        else {
            this.setState({
                requireUpdate: false
            })
        }

        if (ready) {
            let {data, position, areaId, provinceId} = this.state;
            let payload = {};
            let ready = true;
            payload.id = data.id;
            if (position === 'Supervisor') {
                if(this.props.data.job === 'Accountant') {
                    if (this.state.fileNameFile || this.state.fullNameFile || this.state.storageName || this.state.destination) {
                        payload.body = {
                            provinceId: provinceId,
                            areaId: areaId,
                            professionalAccountant: true,
                            fileNameFile: this.state.fileNameFile,
                            fullNameFile: this.state.fullNameFile ? this.state.fullNameFile : "Chứng chỉ hành nghề",
                            storageName: this.state.FileSave[0].storageName,
                            destination: this.state.FileSave[0].destination,
                        }
                    } else {
                        ready = false;
                        toast.error('Vui lòng upload chứng chỉ !')
                    }
                } else {
                    if (this.state.fileNameFile === '' || this.state.FileSave.length === 0) {
                        payload.body = {
                            provinceId: provinceId,
                            areaId: areaId,
                            professionalAccountant: true,
                        }
                    } else {
                        payload.body = {
                            provinceId: provinceId,
                            areaId: areaId,
                            professionalAccountant: true,
                            fileNameFile: this.state.fileNameFile,
                            fullNameFile: this.state.fullNameFile ? this.state.fullNameFile : "Chứng chỉ hành nghề",
                            storageName: this.state.FileSave[0].storageName,
                            destination: this.state.FileSave[0].destination,
                        }
                    }
                }
            } else {
                payload.body = {
                    provinceId: provinceId,
                    areaId: areaId,
                    professionalAccountant: false,
                }
            }
            // if (!this.state.FileSave.length || !this.state.files.length) {
            //     delete payload.body.fileNameFile
            //     delete payload.body.fullNameFile
            //     delete payload.body.storageName
            //     delete payload.body.destination
            // }
            if(ready) {
                UserWaitingApi.callCenterUpdate(payload, res => {
                    if (res.success) {
                        toast.success('Cập nhật thông tin thành công !')
                        this.props.onRefresh()
                        this.props.onHide()
                    } else {
                        toast.error('Đã xảy ra lỗi. Vui lòng thử lại sau !')
                    }
                })
            }
        }
    }

    renderProvince = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
    };

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderProvinceId = (provinceId) => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
        return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
    };

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_CONFIRM':
                return <h6><span className="badge badge-secondary">Chờ xử lý</span></h6>;
            case "CREATE_SUB_ACCOUNT":
                return <h6><span className="badge badge-warning">Tài khoản dự bị</span></h6>;
            case "CALL_CENTER_RECEIVE":
                return <h6><span className="badge badge-success">Call Center đã nhận</span></h6>;
            case "CREATE_OFFICIAL_ACCOUNT":
                return <h6><span className="badge badge-primary">Chờ tạo tài khoản chính thức</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            default:
                return '';
        }
    };

    render() {
        const {files, fileNameFile, fullNameFile, FileSave} = this.state;
        const modalProp = {
            show: this.state.show, onHide: this.props.onHide, keyboard: false, backdrop: 'static',
        };
        return (
            <div className='modal-signup-member'>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-medium"
                       onExited={this.onExited}>
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="loginmodal-container">
                            <h1 className="text-center">Cập nhật thông tin tài khoản</h1>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Họ và tên</label>
                                <span className="col-7">{this.state.data.fullName || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Số điện thoại</label>
                                <span className="col-7">{this.state.data.phone || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Email</label>
                                <span className="col-7">{this.state.data.email || 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Tỉnh/Thành phố</label>
                                <span className="col-7">{this.renderProvinceId(this.state.data.provinceId)}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Kinh nghiệm làm việc</label>
                                <span
                                    className="col-7">{this.state.data.exp_work ? `"${this.state.data.exp_work}"` : 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Lý do gia nhập</label>
                                <span
                                    className="col-7">{this.state.data.applicationToJoin ? `"${this.state.data.applicationToJoin}"` : 'Chưa có dữ liệu'}</span>
                            </div>
                            <div className="row mt-1 mb-1">
                                <label className="col-5 text-black-50">Khu vực muốn tham gia</label>
                                {/*<span>{this.renderProvinceId(this.state.areaId)}</span>*/}
                                <select
                                    className="inputPassword form-control w-100 col-7"
                                    value={this.state.areaId}
                                    onChange={(e) => {
                                        this.setState({
                                            areaId: e.target.value*1,
                                            areaErr: false
                                        })
                                    }}
                                >
                                    {this.renderSupportArea()}
                                </select>
                            </div>

                            <div className="row mt-1 mb-1">
                                <label className="col-5 text-black-50">Vị trí muốn tham gia</label>
                                <select
                                    value={this.state.position}
                                    onChange={(e) => {
                                        this.setState({
                                            position: e.target.value
                                        })
                                    }}
                                    className="inputPassword form-control w-100 col-7">
                                    <option value='Supervisor'>Supervisor</option>
                                    <option value='Accountant'>Kế toán</option>
                                </select>
                                {this.state.positionErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng chọn vị trí !</span> :
                                    <div/>}
                            </div>

                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Call Center phụ trách</label>
                                <span className="col-7">{this.state.data.callCenterName || 'Chưa có'}</span>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 text-black-50">Trạng thái</label>
                                <span className="col-7">{this.renderStatus(this.state.data.status)}</span>
                            </div>

                            {this.state.position === 'Supervisor' && <div className="row mt-1">
                                <label className="col-5 text-black-50">Chứng chỉ</label>
                                <div className='col-7 m-0 pr-0 d-inline-flex'>
                                    {/*{!this.state.files && <button*/}
                                    {/*    type="button"*/}
                                    {/*    className="btn btn-primary type-button-fix btn-sm shadow-none mr-2"*/}
                                    {/*    onClick={() => {*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    Thêm*/}
                                    {/*</button>}*/}
                                    {this.state.showUpload &&
                                    <span className='d-flex' style={{verticalAlign: 'top'}}>
                                        <span
                                            style={{width: 202, overflow: 'hidden', height: 65}}
                                            className='d-inline-flex'
                                            title='Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.'
                                        >
                                            <Dropzone
                                                class='p-2'
                                                accept="image/*, application/pdf"
                                                onDrop={acceptedFiles => {
                                                    console.log(acceptedFiles)
                                                    this.setState({files: acceptedFiles})
                                                }}
                                                multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files && files.length > 0 ? <div className="align-center">
                                                                {/*<ul>{files}</ul>*/}
                                                                <span>{files[0].name}</span>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                    </span>
                                    <span className='pl-1 d-inline-block'>
                                        <input
                                            className="form-control shadow-none w-100"
                                            style={{fontSize: 12}}
                                            disabled={this.state.FileSave.length !== 0}
                                            title='Tên mặc định: "Chứng chỉ hành nghề"'
                                            placeholder='Nhập tên file'
                                            maxLength={100}
                                            value={this.state.fullNameFile}
                                            onChange={(e) => {
                                                this.setState({
                                                    fullNameFile: e.target.value
                                                })
                                            }}
                                        />
                                        <button
                                            type="button"
                                            // disabled={this.state.files.length === 0 || this.state.FileSave.length !== 0}
                                            className="btn btn-primary type-button-fix btn-sm shadow-none mt-1"
                                            style={{verticalAlign: 'top'}}
                                            onClick={() => {
                                                this.uploadFile()
                                            }}
                                        >
                                            Upload
                                        </button>
                                        <button
                                            type="button"
                                            // disabled={this.state.files.length === 0 || this.state.FileSave.length !== 0}
                                            className="btn btn-light type-button-fix btn-sm shadow-none mt-1 ml-1"
                                            style={{verticalAlign: 'top'}}
                                            onClick={() => {
                                                this.setState({showUpload: false})
                                            }}
                                        >
                                            Hủy
                                        </button>
                                        {this.state.FileSave.length !== 0 &&
                                        <i title='Đã upload thành công' className='color-green ti-widget-alt'/>}
                                    </span>
                                    </span>
                                    }
                                    {/*{this.state.files?.map((item, index) => <span>{item.name}</span>)}*/}
                                    {this.state.certificate && !this.state.showUpload ?
                                        <span
                                            className="cursor-pointer text-primary">{this.state.certificate}</span> : ''}
                                    {!this.state.showUpload && <button
                                        type="button"
                                        title='Thay đổi'
                                        className="btn btn-action btn-sm shadow-none ml-2 pt-0"
                                        onClick={() => {
                                            this.setState({showUpload: true})
                                        }}
                                    >
                                        <i title='Thay đổi' className='ti-pencil-alt'/>
                                    </button>}
                                </div>
                                {this.state.requireUpdate &&
                                <div className="invalid-feedback d-block text-right size-font-err-date">
                                    Vui lòng nhập Update chứng chỉ nếu bạn là Kế toán nhiều năm kinh nghiệm !
                                </div>}
                            </div>}
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary shadow-none "
                                    onClick={() => this.onSubmit()}
                                >
                                    <span className='d-flex justify-content-center'>
                                         Cập nhật
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none "
                                    onClick={this.props.onHide}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Hủy bỏ
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default ModalUpdateSignupMember
