import React from "react";
import { withSnackbar } from "notistack";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import DatePicker from "react-datepicker";
import ReportApi from "../../../services/ob-core/report";
import { getUserInfo } from "../../../utils/authority";
import {
  printTypeOptions,
  reportFormOptions200,
  reportFormOptions133,
  reportCodeOptions
} from "./mockdata";

class AccountingBalanceSheet extends React.Component {
  constructor(props) {
    super(props);
    let userInfo  = JSON.parse(localStorage.getItem("ob_cl_userInfo"));
    let reportFormOptions = userInfo.circulars=== "200"?reportFormOptions200: reportFormOptions133;
    this.state = {
      reportCode: _.get(reportFormOptions, "[0].value"),
      reportCodeOptions: reportFormOptions,
      dateFrom: null,
      dateTo: null,
      dateToPrev: null,
      dateFromPrev: null,
      printType: _.get(printTypeOptions, "[0].value"),
      printTypeOptions: printTypeOptions,
      reportForm: _.get(reportCodeOptions, "[0].value"),
      reportFormOptions: reportCodeOptions,
      loading: false,
      unit: ""
    };
  }

  isInvalidForm() {
    const {
      dateTo,
      reportCode,
      reportForm
    } = this.state;
    return (
      dateTo === null ||
      reportCode === null ||
      reportForm === null
    );
  }

  onSubmit() {
    const { printType, unit, reportCode, reportForm } = this.state;
    let dateTo = moment(this.state.dateTo).format("yyyyMMDD");
    let year = moment(this.state.dateTo).year();
    let month = moment(this.state.dateTo).month();
    let quarter = Math.floor(month / 3);
    let mailTo = _.get(getUserInfo(), "email");
    let language = "v";

    const payload = {
      id: 100,
      data: {
        dateTo,
        quarter,
        year,
        printType,
        unit,
        reportCode,
        reportForm,
        language,
        mailTo,
        mailCC: "",
        mailBCC: ""
      }
    };
    this.setState({ loading: true });
    ReportApi.createReport(payload, res => {
      this.setState({ loading: false });
      if (res.success === true) {
        const resData = JSON.parse(res.data);
        if (_.get(resData, "Success") !== "0") {
          this.notify("success", "Tạo báo cáo thành công!");
        } else {
          this.notify("error", resData.Message);
        }
      } else {
        this.notify(
          "error",
          "Tạo báo cáo không thành công, vui lòng thử lại sau !"
        );
      }
    });
  }

  notify(variant, message) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      variant
    });
  }

  componentDidMount() {
    // this.setState({showLoading: true}, () => {
    //     this.searchListPartnerFromAPI()
    // })
  }

  //****************************************************************************************************************

  //*********************************************************************************************************************

  render() {
    const {
      dateTo,
      printTypeOptions,
      reportFormOptions,
      reportCodeOptions,
      unit,
      loading
    } = this.state;
    return (
      <div className="container">
        <div
          className="loading"
          style={loading ? { display: "block" } : { display: "none" }}
        >
          <div className="lds-dual-ring"></div>
        </div>
        <div className="row">
          <Sidebar activeClass="balance" parentClass="report-management" />
          <div className="mb-4 col-md-10 tab-content">
            <div>
              <PageHeader routerEnable={false} title="Bảng cân đối kế toán" />
            </div>
            <div className="mt-3 mb-3" style={{ padding: "0 18px" }}>
              <div className="row">
                <div className="col-12 d-flex mb-2 pl-0">
                  <label className="col-2 label-item pl-0">
                    Mẫu báo cáo<span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="form-control kt-input pd-4-input shadow-none mb-1"
                    onChange={e => {
                      this.setState({
                        reportCode: e.target.value
                      });
                    }}
                  >
                    {reportCodeOptions?.map(item => {
                      return item.value === this.props.data?.reportCode ? (
                        <option
                          key={item.value}
                          disabled
                          style={{ backgroundColor: "#cbcbcb" }}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-12 d-flex mb-2 pl-0">
                  <label className="col-2 label-item pl-0">
                    Biểu mẫu<span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="form-control kt-input pd-4-input shadow-none"
                    onChange={e => {
                      console.log(
                        "reportFormOptions",
                        _.get(reportFormOptions, "[0].value")
                      ),
                        this.setState({
                          reportForm: e.target.value
                        });
                    }}
                  >
                    {reportFormOptions?.map(item => {
                      return item.value === this.props.data?.reportForm ? (
                        <option
                          key={item.value}
                          disabled
                          style={{ backgroundColor: "#cbcbcb" }}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-12 d-flex mb-2 pl-0">
                  <label className="col-2 label-item pl-0">
                    Kiểu in<span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="form-control kt-input pd-4-input shadow-none"
                    onChange={e => {
                      this.setState({
                        printType: e.target.value
                      });
                    }}
                  >
                    {printTypeOptions?.map(item => {
                      return item.value === this.props.data?.printType ? (
                        <option
                          key={item.value}
                          disabled
                          style={{ backgroundColor: "#cbcbcb" }}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/*<div className="col-12 d-flex mb-2 pl-0">*/}
                {/*  <label className="col-2 label-item pl-0">*/}
                {/*    Mã đơn vị cơ sở <span style={{ color: "red" }}> *</span>*/}
                {/*  </label>*/}
                {/*  <input*/}
                {/*    className="inputPassword w-100"*/}
                {/*    style={{*/}
                {/*      border: "1px solid #ced4da",*/}
                {/*      padding: "0 10px",*/}
                {/*      color: "#495057"*/}
                {/*    }}*/}
                {/*    placeholder="Mã đơn vị cơ sở"*/}
                {/*    maxLength={50}*/}
                {/*    value={unit}*/}
                {/*    onChange={e =>*/}
                {/*      this.setState({*/}
                {/*        unit: e.target.value*/}
                {/*      })*/}
                {/*    }*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
            <fieldset
              className="area-content-modal mb-3"
              style={{ width: "100%" }}
            >
              <legend
                className="font-14 "
                style={{ paddingLeft: 20 }}
                style={{ width: 60, paddingLeft: 9 }}
              >
                Kỳ này
              </legend>
              <div className="row" style={{ padding: "0 9px" }}>
                <div className="col-12 d-flex mb-2 p-0">
                  {/*<div className="col-2 d-flex mb-2 pl-0">*/}
                  {/*  <label className="label-item " htmlFor="dateFrom">*/}
                  {/*    Từ ngày*/}
                  {/*    <span style={{ color: "red" }}> *</span>*/}
                  {/*  </label>*/}
                  {/*</div>*/}
                  {/*<div className="col-4 d-flex mb-2 pl-0">*/}
                  {/*  <DatePicker*/}
                  {/*    dropdownMode="select"*/}
                  {/*    id="dateFrom"*/}
                  {/*    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"*/}
                  {/*    placeholderText="Từ ngày"*/}
                  {/*    // onChangeRaw={this.handleDateChangeRaw}*/}
                  {/*    selected={dateFrom === null ? "" : new Date(dateFrom)}*/}
                  {/*    // value={dateFrom}*/}
                  {/*    maxDate={new Date()}*/}
                  {/*    // minDate={new Date()}*/}
                  {/*    // value={dateIssue}*/}
                  {/*    dateFormat={"dd-MM-yyyy"}*/}
                  {/*    isClearable={dateFrom === null ? false : true}*/}
                  {/*    // fixedHeight*/}
                  {/*    // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}*/}
                  {/*    selectsStart*/}
                  {/*    showMonthDropdown*/}
                  {/*    showYearDropdown*/}
                  {/*    onChange={date => {*/}
                  {/*      this.setState({*/}
                  {/*        dateFrom: date === null ? null : date*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div
                    className="col-2 d-flex mb-2 "
                    style={{ paddingLeft: 20 }}
                  >
                    <label className="label-item " htmlFor="dateTo">
                      Tới ngày
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-4 d-flex mb-2 pl-0">
                    <DatePicker
                      dropdownMode="select"
                      id="dateTo"
                      className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                      placeholderText="Đến ngày"
                      // onChangeRaw={this.handleDateChangeRaw}
                      selected={dateTo === null ? "" : new Date(dateTo)}
                      // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                      selectsEnd
                      showMonthDropdown
                      showYearDropdown
                      isClearable={dateTo === null ? false : true}
                      // fixedHeight
                      dateFormat={"dd-MM-yyyy"}
                      onChange={date => {
                        this.setState({
                          dateTo: date === null ? null : date
                        });
                      }}
                      endDate={new Date(dateTo)}
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            {/*<fieldset*/}
            {/*  className="area-content-modal mb-4"*/}
            {/*  style={{ width: "100%" }}*/}
            {/*>*/}
            {/*  <legend*/}
            {/*    className="font-14 "*/}
            {/*    style={{ paddingLeft: 20 }}*/}
            {/*    style={{ width: 70, paddingLeft: 9 }}*/}
            {/*  >*/}
            {/*    Kỳ trước*/}
            {/*  </legend>*/}

            {/*  <div className="row" style={{ padding: "0 9px" }}>*/}
            {/*    <div className="col-12 d-flex mb-2 p-0">*/}
            {/*      <div className="col-2 d-flex mb-2 pl-0">*/}
            {/*        <label className="label-item " htmlFor="dateFromPrev">*/}
            {/*          Từ ngày*/}
            {/*          <span style={{ color: "red" }}> *</span>*/}
            {/*        </label>*/}
            {/*      </div>*/}
            {/*      <div className="col-4 d-flex mb-2 pl-0">*/}
            {/*        <DatePicker*/}
            {/*          dropdownMode="select"*/}
            {/*          id="dateFromPrev"*/}
            {/*          className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"*/}
            {/*          placeholderText="Từ ngày"*/}
            {/*          // onChangeRaw={this.handleDateChangeRaw}*/}
            {/*          selected={*/}
            {/*            dateFromPrev === null ? "" : new Date(dateFromPrev)*/}
            {/*          }*/}
            {/*          // value={dateFromPrev}*/}
            {/*          maxDate={new Date()}*/}
            {/*          // minDate={new Date()}*/}
            {/*          // value={dateIssue}*/}
            {/*          dateFormat="dd-MM-yyyy"*/}
            {/*          isClearable={dateFromPrev === null ? false : true}*/}
            {/*          // fixedHeight*/}
            {/*          // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}*/}
            {/*          selectsStart*/}
            {/*          showMonthDropdown*/}
            {/*          showYearDropdown*/}
            {/*          onChange={date => {*/}
            {/*            this.setState({*/}
            {/*              dateFromPrev: date === null ? null : date*/}
            {/*            });*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div*/}
            {/*        className="col-2 d-flex mb-2 "*/}
            {/*        style={{ paddingLeft: 20 }}*/}
            {/*      >*/}
            {/*        <label className="label-item " htmlFor="dateToPrev">*/}
            {/*          Tới ngày*/}
            {/*          <span style={{ color: "red" }}> *</span>*/}
            {/*        </label>*/}
            {/*      </div>*/}
            {/*      <div className="col-4 d-flex mb-2 pl-0">*/}
            {/*        <DatePicker*/}
            {/*          dropdownMode="select"*/}
            {/*          id="dateToPrev"*/}
            {/*          className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"*/}
            {/*          placeholderText="Đến ngày"*/}
            {/*          // onChangeRaw={this.handleDateChangeRaw}*/}
            {/*          selected={dateToPrev === null ? "" : new Date(dateToPrev)}*/}
            {/*          // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}*/}
            {/*          selectsEnd*/}
            {/*          showMonthDropdown*/}
            {/*          showYearDropdown*/}
            {/*          disabled={dateFromPrev === null ? true : false}*/}
            {/*          isClearable={dateToPrev === null ? false : true}*/}
            {/*          // fixedHeight*/}
            {/*          dateFormat="dd-MM-yyyy"*/}
            {/*          onChange={date => {*/}
            {/*            this.setState({*/}
            {/*              dateToPrev: date === null ? null : date*/}
            {/*            });*/}
            {/*          }}*/}
            {/*          startDate={new Date(dateFromPrev)}*/}
            {/*          endDate={new Date(dateToPrev)}*/}
            {/*          minDate={*/}
            {/*            dateFromPrev === null*/}
            {/*              ? new Date()*/}
            {/*              : new Date(dateFromPrev)*/}
            {/*          }*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</fieldset>*/}
            <div>
              <div className="d-flex justify-content-end mt-3 mb-2">
                <button
                  type="button"
                  className="ml-4 col-3 border-1-s background-btn type-button-fix btn shadow-none"
                  style={{
                    backgroundColor: "#169bd5",
                    borderColor: "#169bd5",
                    color: "white"
                  }}
                  // disabled={this.state.start.length === 0 || this.state.end.length === 0 || this.state.price.commercePrice === 0 || this.state.price.manufacturingPrice === 0 || this.state.price.buildingPrice === 0}
                  disabled={this.isInvalidForm()}
                  onClick={() => this.onSubmit()}
                >
                  Khởi tạo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(AccountingBalanceSheet);
