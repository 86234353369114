import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

import TableHeader from "../../../../../../components/TableHeader";
import NumberFormat from "react-number-format";
import Pagination from "../../../../../../components/Pagination";

import AccountantApi from "../../../../../../services/ob-user/accountant";
import SecureCustomerApi from "../../../../../../services/ob-user/secure-customer";
import PublicContractApi from "../../../../../../services/ob-core/public-contract";

const renderStatus = function (status) {
    switch (status) {
        case "WAIT_SIGN":
            return <h5><span className="badge badge-dark">Chờ ký</span></h5>;
        case "PROCESSING":
            return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
        case "NO_ASSIGN":
            return <h6><span className="badge badge-secondary">Chưa assign</span></h6>;
        case "WAITING_FOR_CONFIRMATION":
            return <h6><span className="badge badge-dark">Chờ kế toán xác nhận</span></h6>;
        case "REJECTED":
            return <h6><span className="badge badge-danger">Kế toán từ chối</span></h6>;
        case "LIQUIDATED":
            return <h6><span className="badge badge-info">Thanh lý</span></h6>;
        case "CANCELED":
            return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
        case "CLOSED":
            return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
        default:
            return;
    }
};

class ContractInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemSelected: {},
            idSelected: this.props.location.state ? this.props.location.state.accountantId : '',
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            dataAcc: {},
            sort: 'id=asc',
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'code',
                    name: "Mã hợp đồng",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'customerId',
                    name: "Tên khách hàng",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'dealPrice',
                    name: "Giá trị hợp đồng",
                    style: {minWidth: 80, textAlign: "center", paddingRight: 14},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
            ]
        };
    }

    //*****************************************************************************************************************

    componentDidMount() {
        this.getDetail()
        this.getContractListOfAccountantFromAPI()
    }

    getDetail = () => {
        let payload = {
            id: this.props.accountantId
        };
        AccountantApi.getDetailAccountantById(payload, res => {
            if (res.success) {
                this.setState({
                    dataAcc: res.data
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    };

    getContractListOfAccountantFromAPI = () => {
        let {pageNumber, pageSize} = this.state;

        let payload = {
            accountantId: this.props.accountantId,
            supervisorId: this.props.supervisorId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        PublicContractApi.findByAccountantId(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listCustomerId = [];
                data.map(item => {
                    listCustomerId.push(item.customerId);
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    listCustomerId
                }, () => this.getListCustomerByIdFromAPI())
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    };

    getListCustomerByIdFromAPI = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };
        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerId = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {
                // toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getContractListOfAccountantFromAPI();
            }
        );
    };

    //*****************************************************************************************************************

    render() {
        const {dataAcc, page, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className='row kt-margin-b-20 mt-4'>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-6 col-lg-6 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Số hợp đồng được phụ trách</label>
                    </div>
                    <div
                        className="pl-0 col-md-4 col-lg-4 col-sm-10 kt-margin-b-10-tablet-and-mobile">
                        {dataAcc.maxContract ? dataAcc.maxContract : 'Chưa có dữ liệu'}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-6 col-lg-6 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Số hợp đồng đang phụ trách</label>
                    </div>
                    <div
                        className="pl-0 col-md-6 col-lg-6 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {dataAcc.countContractActive ? dataAcc.countContractActive : 'Chưa có dữ liệu'}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-6 col-lg-6 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Tổng số hợp đồng đã thực hiện</label>
                    </div>
                    <div
                        className="pl-0 col-md-6 col-lg-6 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {dataAcc.countContract ? dataAcc.countContract : 'Chưa có dữ liệu'}
                    </div>
                </div>
                <div
                    className='w-100 mb-4 mt-4'
                    style={{
                        overflowX: 'auto',
                    }}
                >
                    <table
                        className="table table-striped table-data table-bordered"
                    >
                        <thead>
                        <TableHeader
                            header={this.state.header}
                            clickSort={data => {
                                console.log(data);
                                let a = "";
                                if (data.sortAsc == null) {
                                    a = "id=asc";
                                } else {
                                    a = `${data.sortId}=${
                                        data.sortAsc ? "asc" : "desc"
                                    }`;
                                }
                                console.log(a);
                                this.setState(
                                    {
                                        sort: a
                                    },
                                    () => this.getListContractFromAPI()
                                );
                            }}
                        />
                        </thead>
                        {!this.state.listData ||
                        this.state.listData.length == 0 ? (
                            <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <div className="text-center">
                                        Không có dữ liệu
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody>
                            {this.state.listData.map((row, index) => (
                                <tr key={row.id} style={{height: 50}}>
                                    <td style={{textAlign: 'center'}}>{index + 1}</td>
                                    <td style={{textAlign: 'left',}}>
                                        {row.code}
                                    </td>
                                    <td style={{textAlign: 'left'}}>
                                        {row.customerId}
                                    </td>
                                    <td style={{textAlign: 'right', paddingRight: 14}}>
                                        <NumberFormat
                                            value={row.value}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={" VND"}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>{renderStatus(row.assignStatus)}</td>
                                    <td style={{textAlign: 'center', fontSize: 18}}>
                                        {
                                            row.status === 'ACTIVE'
                                                ? <span>
                                                <button className="btn btn-action"
                                                        title='Xem chi tiết'
                                                        style={{cursor: 'pointer'}}
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: "/partner/contracts-detail",
                                                            search: `?contractId=${row.id}`,
                                                            state: {
                                                                fromDashboard: true,
                                                                contractId: row.id
                                                            }
                                                        }}
                                                    >
                                                         <i className="ti-book"/>
                                                    </Link>
                                                </button>
                                                <button className="btn btn-action"
                                                        title='Sửa'
                                                        style={{cursor: 'pointer'}}
                                                >
                                                <i className="ti-pencil-alt"/>
                                                </button>
                                                <button className="btn btn-action"
                                                        title='Khóa'
                                                        style={{cursor: 'pointer'}}
                                                >
                                                <i className="ti-lock"/>
                                                </button>
                                                    {/*<i*/}
                                                    {/*    className="flaticon-delete"*/}
                                                    {/*    style={{cursor: 'pointer'}}*/}
                                                    {/*    onClick={() => this.handleDelete(row)}*/}
                                                    {/*/>*/}

                                                </span>
                                                : <span>
                                                <button className="btn btn-action"
                                                        title='Xem chi tiết'
                                                        style={{cursor: 'pointer'}}
                                                ><Link
                                                    to={{
                                                        pathname: "/partner/contracts-detail",
                                                        search: `?contractId=${row.id}`,
                                                        state: {
                                                            fromDashboard: true,
                                                            contractId: row.id
                                                        }
                                                    }}
                                                >
                                                         <i className="ti-book"/>
                                                    </Link>
                                                </button>
                                                <button
                                                    disabled
                                                    className="btn btn-action"
                                                    title='Sửa'
                                                    style={{cursor: 'unset'}}
                                                >
                                                <i className="ti-pencil-alt"/>
                                                </button>
                                                <button
                                                    disabled
                                                    className="btn btn-action"
                                                    title='Khóa'
                                                    style={{cursor: 'unset'}}
                                                >
                                                <i className="ti-lock"/>
                                                </button>


                                                </span>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>)}
                    </table>

                </div>
                <div className="pagination-right">
                    <Pagination
                        activePage={this.state.pageNumber + 1}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={this.state.totalItem}
                        changeHandler={page => this.handleChangePage(page)}
                    />
                </div>
            </div>
        );
    }
}

export default ContractInformation
