import React from 'react';
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import "./Modal.css"

class ModalNotifySignUpMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
    }

    render() {
        const modalProp = {
            show: true,
            // onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div className='mt-3 mb-3 text-center'>
                            Bạn đã đăng ký tài khoản thành công.
                            <br/>
                            Hệ thống sẽ liên hệ với bạn trong thời gian sớm
                            nhất.
                        </div>
                        <div className='text-center'>
                            <a style={{textDecoration: 'underline', color: '#83c6db'}}
                               onClick={() => window.location.reload(false)}><i className='ti-arrow-left'/> Về trang chủ</a>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalNotifySignUpMember
