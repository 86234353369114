import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicOrder = {
    checkOrderCallCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/check-order-call-customer/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    cancel: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/cancel/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    callCenterGetDetailById: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/call-center-get-detail/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    callCenterAcceptOrder: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/call-center-accept/${payload.id}`,
            method: 'POST',
            // body: payload.body,
        }, callback);
    },
    acceptOrder: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/accept/${payload.id}`,
            method: 'POST',
            // body: payload.body,
        }, callback);
    },
    assignOrder: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/assign/${payload.id}?supervisorId=${payload.supervisorId}`,
            method: 'POST',
            // body: payload.body,
        }, callback);
    },
    reassignOrder: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/reassign/${payload.id}?supervisorId=${payload.supervisorId}`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    create: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    createAdvance: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/create-advance`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    getDetailById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/get-detail-by-id/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    reject: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/order-service/reject/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    supervisorSearchByCodeAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/search-paging-by-code-and-status?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    searchByServicePlanAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/search-paging-by-service-plan-name-and-status?${stringify(payload)}`,
            // url: `${URL_API}/api/one-book-core/secure/order-service/search-paging-by-service-plan-name-and-status?status=${payload.status?payload.status:''}&sort=${payload.sort?payload.sort:''}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&servicePlanName=`,
            method: 'GET',
        }, callback);
    },
    searchBySupervisorIdServicePlanIdAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/search-paging-by-supervisor-id-service-plan-id-and-status?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    adminSearch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/order-service/search-paging-by-partner-id-service-plan-id-and-status?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },

    ccSearchByServicePlanIdAndCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/call-center-search-paging-by-service-plan-id-and-customer?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    supSearchByServicePlanIdAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/sup-search-paging-by-service-plan-id-and-status?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    customerSearchByServicePlanIdAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/customer-search-paging-by-service-plan-id-and-status?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    calculatePrice: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/get-price`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    callCenterPickOrder: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/call-center-pick-order/${payload.id}`,
            method: "POST",
            body: payload.body
        }, callback)
    },
    assignAdvance: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/order-service/assign-advance/${payload.id}`,
            method: "POST",
            body: payload.body
        }, callback)
    }
}
export default PublicOrder
