import axios from "axios";
import { saveAs } from 'file-saver';
import {forceLogout, getAccessToken} from "./authority";
import {toast} from "react-toastify";
let lang = "vi";


let token = getAccessToken();

export const request = (request, callback) =>{
    let config = {
        method: request.method,
        headers: token ?  {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8",
            "Accept-Language": lang
        } : {
            "Content-Type": "application/json; charset=UTF-8",
            "Accept-Language": lang
        },
        body: JSON.stringify(request.body)
        // body: request.body
    }
    return fetch(request?.url,config)
        .then(async response => {
            if (response?.status == 401) {
                await forceLogout();
            }
            // else if (response?.status == 500) {
            //     return {"success":true,"data": null,"errorCode":null,"message":null,"refCode":null}
            // }
            // console.log("check response fetch>>>>", response)
            return response.json()
        })
        .then((res) => {
            callback(res);
        })
        .catch((error) => {
            // callback(error.json())
        })
};


export const requestLogin = (request, callback) =>{
    let config = {
        method: request.method,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Accept-Language": lang
        },
        body: JSON.stringify(request.body)
        // body: request.body
    }
    return fetch(request?.url,config)
        .then(async response => {
            if (response?.status == 401) {
                toast.error("Sai tên đăng nhập hoặc mật khẩu!")
            }
            return response.json()
        })
        .then((res) => {
            callback(res);
        })
        .catch((error) => {
            toast.error("Sai tên đăng nhập hoặc mật khẩu!")
            // callback(error.json())
        })
};

export const requestUploadPublic = (request, callback) =>{
    let config = {
        method: request.method,
        headers: {
            "Accept-Language": lang
        },
        // body: JSON.stringify(request.body)
        body: request.body
    }
    return fetch(request?.url,config)
        .then(async response => {
            if (response?.status == 401) {
                // await forceLogout();
            }
            return response.json()
        })
        .then((res) => {
            callback(res);
        });
};

export const requestUpload = (request, callback) =>{
    let config = {
        method: request.method,
        headers: {
            "Authorization": "Bearer " + token,
            // "Content-Type": "multipart/form-data",
            "Accept-Language": lang
        },
        // body: JSON.stringify(request.body)
        body: request.body
    }
    return fetch(request?.url,config)
        .then(async response => {
            if (response?.status == 401) {
                await forceLogout();
            }
            return response.json()
        })
        .then((res) => {
            callback(res);
        });
};

export const requestDownload = (request, callback) =>{
    let config = {
        method: request.method,
        headers: {
            "Authorization": "Bearer " + token,
            // "Content-Type": "multipart/form-data",
            "Accept-Language": lang
        },
        // body: JSON.stringify(request.body)
        body: request.body
    }
    return fetch(request?.url,config)
        .then(async response => {
            if (response?.status == 401) {
                await forceLogout();
            }
            else if (response?.status == 500) {
                return response.json()
            }
            else if (response?.status == 400) {
                return response.json()
            }
            else if (response?.status == 404) {
                toast.error("File không tồn tại hoặc đã bị mất, vui lòng thử lại!")
                return response.json()
            }
            else return response.blob()
        })
        .then((res) => {
            // if (!res.success) {
            //     return toast.error("Download không thành công, vui lòng thử lại!")
            // }  else {
            if (res) {
                const fileURL = URL.createObjectURL(res);
                saveAs(fileURL);
            }
            // }
            // const fileURL = URL.createObjectURL(res);
            // // window.open(fileURL)
            // saveAs(fileURL);
            // callback(fileURL);
        })
        .catch(error => {
            if(error === 404) return toast.error("File không tồn tại hoặc đã bị mất, vui lòng thử lại!")
            return toast.error("Download không thành công, vui lòng thử lại!")
        })
};

export const requestv2 = (request, callback) =>{
    let config = {
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json; charset=UTF-8"
        }
    }
    // return axios.get(request?.url, request?.config).then((res) => {
    return axios.get(request?.url, config).then((res) => {
        // if (thrown?.response?.status == 401) {
        //TODO: call dispatch logout
        // await forceLogout();
        // }
        callback(res.data);
    }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            // console.log('First request canceled', thrown?.message);
        } else {
            // handle error
            if (thrown?.response?.status == 401) {
                //TODO: call dispatch logout
                forceLogout();
            }
        }
    });
};
