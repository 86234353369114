import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let AdminUserManagement = {
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/get-list-user?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/create-user`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    block: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/block-user/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    unblock: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/unblock-user/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/delete-user`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback)
    },
    detail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/detail/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/update-user/${payload.id}`,
            method: 'POST',
            body: {
                ...payload.body,
            },
        }, callback)
    },

}
export default AdminUserManagement
