import React from "react";
import PublicNews from "../../../services/ob-core/public-news";
import './style.css';
import Convert from "../../../utils/convertUrlPra";
import Link from "../../../components/Link";
import PublicNewsCategory from "../../../services/ob-core/public-news-category";
import imgNews from "../../../assets/images/news/img.png";
import Pagination from "../../../components/Pagination";
import {forEach} from "react-bootstrap/ElementChildren";

class HomeDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: '',
            categoryName: '',
            path: '',
            listCategory: [],
            data: [],
            pageSize: 50,
            pageNumber: 0,
            dataCategory: JSON.parse(localStorage.getItem("ob_cl_news_cat"))
        }
    }

    componentWillMount() {
        this.getListCategory();
        this.unlisten = this.props.history.listen((location, action) => {
            this.getIdByPath()
        });
        let params = Convert.urlParams(this.props.location.search);
        this.setState({path: params.doc}, () =>{
            this.getIdByPath()
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let oldPath = Convert.urlParams(this.props.location.search);
        let newPath = Convert.urlParams(nextProps.location.search);
        if (oldPath.doc !== newPath.doc) {
            this.setState({path: newPath.doc}, () => {
                this.getIdByPath()
            })
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getIdByPath = () => {
        let allCate = [];
        let filter = this.state.dataCategory.filter((item) => item.path == this.state.path)
        let filter1 = this.state.dataCategory.filter((item) => item.parentId == filter[0].id)
        allCate.push(filter[0].id);
        filter1.forEach(itemPath => {
            allCate.push(itemPath.id);
            let filterX = this.state.dataCategory.filter((item) => item.parentId == itemPath.id)
            filterX.forEach(xxx => {
                allCate.push(xxx.id);
            });
        });
        if (filter.length > 0) {
            this.setState({
                categoryId: allCate.toString(),
                categoryName: filter[0].name,
            }, () => {
                this.getDataNews()
            })
        }
    }

    getDataNews = () => {
        let payload = {
            categoryId: this.state.categoryId,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            status: false
        }
        PublicNews.getListByCategory(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements
                })
            } else {

            }
        })
    }

    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: this.reformatData(res.data),
                showLoading: false
            })
        })
    }
    reformatData = (listData) => {
        let listCate = [];
        listData.forEach(cateInfo => {
            if (cateInfo.parentId == 0 && cateInfo.type == 2){
                cateInfo.subCate = [];
                listData.forEach(subCate => {
                    if (subCate.parentId != 0 && subCate.parentId == cateInfo.id){
                        subCate.subCate = [];
                        listData.forEach(inSubCate => {
                            if (inSubCate.parentId != 0 && inSubCate.parentId == subCate.id){
                                inSubCate.subCate = [];
                                listData.forEach(inInSubCate => {
                                    if (inInSubCate.parentId != 0 && inInSubCate.parentId == inSubCate.id){
                                        inSubCate.subCate.push(inInSubCate);
                                    }
                                });
                                subCate.subCate.push(inSubCate);
                            }
                        });
                        cateInfo.subCate.push(subCate);
                    }
                });
                listCate.push(cateInfo);
            }
        });
        console.log(listCate);
        return listCate;
    }
    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.getDataNews())
    };


    render() {
        const {data, dataCategory} = this.state;
        return (
            <div className="home-documents">
                <div>
                    <div className="container">
                        <div className='row documents'>
                            <div className='col-md-3'>
                                <aside className="sidebar">
                                    <h4 className="heading-primary">Danh mục văn bản</h4>
                                    <ul className="nav nav-list">
                                        {this.state.listCategory.length > 0 && this.state.listCategory.map(cateInfo => (
                                            <li className={cateInfo.path === this.state.path? 'active': ''}>
                                                <Link
                                                    title={cateInfo.name}
                                                    to={{
                                                        pathname: '/home/documents',
                                                        search: `?doc=${cateInfo.path}`,
                                                        state: {
                                                            fromDashboard: true,
                                                            category: cateInfo.path,
                                                        }
                                                    }}
                                                >
                                                    {cateInfo.name}
                                                </Link>
                                                {cateInfo.subCate != undefined && cateInfo.subCate.length > 0 &&
                                                <ul>
                                                    {cateInfo.subCate.map(subCate => (
                                                        <li className={subCate.path === this.state.path? 'active': ''}>
                                                            <Link
                                                                title={subCate.name}
                                                                to={{
                                                                    pathname: '/home/documents',
                                                                    search: `?doc=${subCate.path}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        category: subCate.path,
                                                                    }
                                                                }}
                                                            >
                                                                {subCate.name}
                                                            </Link>
                                                            {cateInfo.subCate != undefined && subCate.subCate.length > 0 &&
                                                            <ul>
                                                                {subCate.subCate.map(inSubCate => (
                                                                    <li className={inSubCate.path === this.state.path? 'active current': this.state.path}>
                                                                        <Link
                                                                            title={inSubCate.name}
                                                                            to={{
                                                                                pathname: '/home/documents',
                                                                                search: `?doc=${inSubCate.path}`,
                                                                                state: {
                                                                                    fromDashboard: true,
                                                                                    category: inSubCate.path,
                                                                                }
                                                                            }}
                                                                        >
                                                                            {inSubCate.name}
                                                                        </Link>
                                                                        {cateInfo.subCate != undefined && inSubCate.subCate.length > 0 &&
                                                                        <ul>
                                                                            {inSubCate.subCate.map(inInSubCate => (
                                                                                <li className={inInSubCate.path === this.state.path? 'active current': ''}>
                                                                                    <Link
                                                                                        title={inInSubCate.name}
                                                                                        to={{
                                                                                            pathname: '/home/documents',
                                                                                            search: `?doc=${inInSubCate.path}`,
                                                                                            state: {
                                                                                                fromDashboard: true,
                                                                                                category: inInSubCate.path,
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {inInSubCate.name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                        }
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            }
                                                        </li>
                                                    ))}
                                                </ul>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            </div>
                            <div className='col-md-9'>
                                <h2>{this.state.categoryName}</h2>
                                <ul className="list list-icons list-primary list-borders list-icons-lg">
                                    <li>
                                        <ul className="list list-icons list-primary list-borders list-icons-lg">
                                            {data.length > 0 ? data?.map((item, index) => (
                                                    <li><i className="fa fa-file"></i>
                                                        <Link to={`/home/document-detail?doc=${this.state.path}&i=${item.id}&t=${item.url}`} title={item.title}>
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                )) :
                                                <div>
                                                    <span>Chưa có bài viết nào trong thư mục này</span>
                                                </div>}
                                        </ul>
                                    </li>
                                </ul>
                                <div className="pagination-right justify-flex-end mb-12 mt-12">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={page => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default HomeDocuments
