import React from 'react'
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import TableHeader from "../../../../components/TableHeader";
import NumberFormat from "react-number-format";
import Pagination from "../../../../components/Pagination";
import {Link} from "react-router-dom";
import lang from "../../../partner/contracts-management/lang";
import SecureContract from "../../../../services/ob-core/secure-contract";
import SecureCustomerApi from "../../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import ModalActiveContract from "../../../../components/Modal/admin/modalActiveContract"
import PartnerApi from "../../../../services/ob-user/partner";
import {toast} from "react-toastify";
import {checkPermission} from "../../../../utils/common";
import moment from "moment";

// const fakeData = [
//     {id: 1, code: 1, customerId: 1, value: 300000, supervisorId: 1, accountantId: 1, status: "WAIT_SIGN"}
// ]

class ListContract extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            listData: [],
            code: null,
            status: "",
            sort: "",
            descending: 'DESC',
            code_temp: "",
            status_temp: "",
            status_temp_search: "",

            selectedContract: "",
            modalActiveContract: false,
            showLoading: false,

            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center", minWidth: 40, maxWidth: 40},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian tạo",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "value",
                    name: "Giá trị hợp đồng (VND)",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "supervisorId",
                    name: "Supervisor xử lý",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "partner",
                    name: "Partner",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false
                },
                // {
                //     id: "",
                //     name: "Thao tác",
                //     style: {minWidth: 80, textAlign: "center"},
                //     sort: false
                // }
            ]
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListContract();
        })
    }

    showModalActiveContract = item => {
        this.setState({ selectedContract: item.id, modalActiveContract: true });
    };

    onSearch = () => {
        let {code_temp, status_temp} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListContract();
            }
        );
    };

    getListContract() {
        let {code, status, pageNumber, pageSize, sort, contractId, descending} = this.state;
        let payload = {
            code: code,
            status: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            supervisorId: this.props.supervisorId
        };
        SecureContract.searchByCodeAndStatusBySupervisorId(payload, res => {
            if (!res.success) {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra, vui long tải lại trang!"))
            } else {
                let data = res.data?.content;
                let listPartnerId = [];
                let listCustomerId = [];
                let listSupervisorId = [];
                //customerId

                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listSupervisorId.push(item.supervisorId);
                    listPartnerId.push(item.partnerId);
                });
                this.setState(
                    {
                        listData: res.data?.content,
                        totalItem: res.data?.totalElements,
                        listCustomerId,
                        listSupervisorId,
                        listPartnerId,
                        showLoading: false
                    },
                    () => {
                        if (data && data.length > 0){
                            this.getListCustomerById();
                            this.getListSupervisorById();
                            this.getListPartnerById();
                        } else {
                            this.setState({
                                showLoading: false
                            })
                        }
                    }
                );
            }
        });
    }

    getListCustomerById = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                toast.error('Lỗi!' + res.message)
            }
        });
    }

    getListSupervisorById() {
        let {listSupervisorId, listData} = this.state;

        let payload = {
            ids: listSupervisorId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                toast.error('Lỗi!' + res.message)
            }
        });
    }
    getListPartnerById() {
        let {listPartnerId, listData} = this.state;

        let payload = {
            ids: listPartnerId
        };
        PartnerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.partnerId);
                    item.partnerName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                }, () => console.log("check>>>", listData));
            } else {
                toast.error('Lỗi!' + res.message)
            }
        });
    }

    onRefresh() {
        this.setState(
            {
                sort: "",
                // descending: 'DESC',
                code_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onSearch()
        );
    }

    renderStatus(assignStatus) {
        switch (assignStatus) {
            case "WAIT_SIGN":
                return <h5><span className="badge badge-dark">Chờ ký</span></h5>;
            case "SIGNED":
                return <h6><span className="badge badge-secondary">Đã ký</span></h6>;
            case "NO_ASSIGN":
                return <h6><span className="badge badge-secondary">Chưa assign</span></h6>;
            case "WAITING_FOR_CONFIRMATION":
                return <h6><span className="badge badge-dark">Chờ kế toán xác nhận</span></h6>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h6><span className="badge badge-danger">Kế toán từ chối</span></h6>;
            case "LIQUIDATED":
                return <h6><span className="badge badge-info">Thanh lý</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return lang.type.new;
            case "EXTENSION":
                return lang.type.extenstion;

            default:
                return;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            }, () => {
                this.getListContract()
            }
        );
    }

    render() {
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <div>
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="col-12">
                                <div >
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Mã hợp đồng</label>

                                                <input
                                                    placeholder="Nhập vào mã hợp đồng..."
                                                    name="Code"
                                                    type="text"
                                                    className="form-control shadow-none font-14"
                                                    // style={{width: "100%", height: 35, padding: 8}}
                                                    value={this.state.code_temp}
                                                    maxLength={20}
                                                    onKeyUp={e => {
                                                        if (e.key === "Enter") this.onSearch();
                                                    }}
                                                    onChange={val =>
                                                        this.setState({code_temp: val.target.value.trim().toLocaleLowerCase()})
                                                    }
                                                />
                                            </div>

                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({status_temp: val.target.value}, () =>
                                                            console.log(this.state.status_temp)
                                                        )
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    <option value="WAIT_SIGN">Chờ ký</option>
                                                    {/*<option value="SIGNED">Đã ký</option>*/}
                                                    <option value="NO_ASSIGN">Chưa assign</option>
                                                    <option value="WAITING_FOR_CONFIRMATION">Chờ kế toán xác nhận</option>
                                                    <option value="PROCESSING">Đang thực hiện</option>
                                                    <option value="REJECTED">Kế toán từ chối</option>
                                                    <option value="LIQUIDATED">Thanh lý</option>
                                                    <option value="CLOSED">Đã đóng</option>
                                                    <option value="CANCELED">Đã hủy</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số Hợp đồng: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            // console.log(data);
                                            let a = "";
                                            let descend = "";
                                            if (data.sortAsc == null) {
                                                // a = "id=asc";
                                                a = "id";
                                                descend = 'DESC'
                                            } else {
                                                a = data.sortId;
                                                descend = data.sortAsc ? 'ASC' : 'DESC'
                                            }
                                            this.setState(
                                                {
                                                    sort: a,
                                                    descending: descend
                                                }, () => this.getListContract())
                                        }}/>
                                        </thead>
                                        {this.state.listData.length === 0 ? (
                                            <tbody>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="text-center">
                                                        Không có dữ liệu
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                            {this.state.listData?.map((row, index) => (
                                                <tr style={{height: 50}}>
                                                    <td style={{textAlign: "center"}} scope="row">
                                                        {" "}
                                                        {this.state.pageNumber * this.state.pageSize +
                                                        index +
                                                        1}
                                                    </td>
                                                    <td style={{textAlign: "left"}} scope="row">
                                                        {row.code}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {row.cmnCreatedAt ? moment(row.cmnCreatedAt).format('DD-MM-YYYY') : 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        {/*{row.customerName}*/}
                                                        {row.customerName || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: "right"}}>
                                                        {" "}
                                                        <NumberFormat
                                                            value={row.value}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            // suffix={" VND"}
                                                        />
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        {row.supervisorName}
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        {row.partnerName}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {this.renderStatus(row.assignStatus)}
                                                    </td>

                                                    {/*<td className='p-1' style={{textAlign: "center"}}>*/}
                                                    {/*    <Link*/}
                                                    {/*        to={{*/}
                                                    {/*            pathname: "./contract-detail",*/}
                                                    {/*            search: `?contractId=${row.id}&code=${row.code}`,*/}
                                                    {/*            state: {*/}
                                                    {/*                fromDashboard: true,*/}
                                                    {/*                contractId: row.contractId,*/}
                                                    {/*                code: row.code*/}
                                                    {/*            }*/}
                                                    {/*        }}*/}
                                                    {/*        style={{color: "#000000"}}*/}
                                                    {/*    >*/}
                                                    {/*        <button*/}
                                                    {/*            className="btn btn-action p-2"*/}
                                                    {/*            title="Xem chi tiết"*/}
                                                    {/*        >*/}
                                                    {/*            <i className="ti-book"/>*/}
                                                    {/*        </button>*/}
                                                    {/*    </Link>*/}
                                                    {/*    {checkPermission("active_contract") &&*/}
                                                    {/*    <button*/}
                                                    {/*        className="btn btn-action p-2"*/}
                                                    {/*        title="Kích hoạt hợp đồng"*/}
                                                    {/*        onClick={() =>*/}
                                                    {/*            this.showModalActiveContract(row)*/}
                                                    {/*        }*/}
                                                    {/*        disabled={*/}
                                                    {/*            row.assignStatus != "SIGNED"*/}
                                                    {/*        }*/}
                                                    {/*        style={{*/}
                                                    {/*            cursor: row.assignStatus == "SIGNED" ? "pointer" : "unset"*/}
                                                    {/*        }}*/}
                                                    {/*    >*/}
                                                    {/*        <i className="ti-check-box"/>*/}
                                                    {/*    </button>*/}
                                                    {/*    }*/}
                                                    {/*</td>*/}
                                                </tr>
                                            ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalActiveContract
                    show={this.state.modalActiveContract}
                    reload={() => this.setState({ modalActiveContract: false }, ()=> this.getListContract())}
                    onHide={() => this.setState({ modalActiveContract: false })}
                    contractId={this.state.selectedContract}
                />
            </div>

        )

    }

}

export default ListContract
