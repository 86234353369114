import React from 'react';
import '../homepage/style.css';
import './style.css';
import {StarFill, Star, ChevronRight} from 'react-bootstrap-icons';
import Link from "../../../components/Link";
import PublicSupervisorApi from "../../../services/ob-user/public-supervisor";
import Pagination from "../../../components/Pagination";
import {changeAlias} from "../../../utils/common";
import Convert from "../../../utils/convertUrlPra";

class ListSupervisor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            areaId: '',
            fullName: null,
            supervisorData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            sort: '',
            sortBy: '',
            sortAsc: '',
        }
    }

    componentWillMount() {
        if (this.props.location.search) {
            let params = Convert.urlParams(this.props.location.search);
            if (params?.areaId) {
                this.setState({
                    areaId: params?.areaId
                })
            } else {
                this.setState({
                    areaId: ''
                })
            }
        }
        this.setState({
            showLoading: true
        }, () => this.searchSupervisor())
    }

    componentDidMount() {
    }

    searchSupervisor = () => {
        let {address, areaId, fullName, pageNumber, pageSize, sortBy, sortAsc} = this.state;
        let sort = sortBy && sortAsc ? `${sortBy}=${sortAsc}` : '';
        let payload = {
            areaId: areaId,
            pageNumber,
            pageSize,
            sort
        }
        if (sort === '') {
            delete payload.sort
        }
        PublicSupervisorApi.searchByFullNameAndAddress(payload, res => {
            if (res.success) {
                console.log(res.data)
                this.setState({
                    supervisorData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
                console.log(res.data.content)
            }
        })
    }

    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.searchSupervisor())
    };

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderNewsUrl = (value) => {
        let title = changeAlias(value);
        return title.replace(/ /g, "-")
    }

    render() {
        const {supervisorData, areaId} = this.state
        return (
            <div className='list-sup-wrapper'>
                <div className='banner-search'>
                    <div className='search-panel container'>
                        <div className='row bg-white box-shadow-main search-content list-sup-search-content'>
                            <div className='col-md-7 pd-30 d-flex justify-content-center vertical-align-center'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <h4 className='title color-main-dark mb-3'>
                                        Bắt Đầu Tìm Kiếm
                                    </h4>
                                    <div className='row selection'>
                                        <div className='col-12 p-0'>
                                            <div className='search'>
                                                <div className='form-group d-flex'>
                                                    <select
                                                        value={areaId}
                                                        className='form-control'
                                                        placeholder='Chọn khu vực'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                areaId: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option disabled selected value=''>
                                                            Chọn khu vực
                                                        </option>
                                                        {this.renderSupportArea()}
                                                    </select>
                                                    <button
                                                        title='Tìm kiếm'
                                                        className='btn btn-sm ml-2'
                                                        style={{
                                                            width: 160,
                                                            border: '1px solid #ccc'
                                                        }}
                                                        onClick={() => {
                                                            this.searchSupervisor()
                                                        }}
                                                    >
                                                        <i className='fas fa-search color-main mr-1'/>
                                                        Tìm kiếm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<button className='advance-search color-main'>*/}
                                    {/*    <img*/}
                                    {/*        src={advanceSearch}*/}
                                    {/*        alt='advance-search'*/}
                                    {/*        className='img-small mr-3'*/}
                                    {/*    />*/}
                                    {/*    <span>Tìm kiếm nâng cao</span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className='col-md-5 pd-30 box-shadow-main'>
                                {/*<h3 className='secondary-title mb-4 text-center'>*/}
                                {/*    <span className='color-main-dark'>Bạn Là</span>*/}
                                {/*    <span className='color-main'> Kế Toán ?</span>*/}
                                {/*</h3>*/}
                                {/*<Link to="/home/join-with-us">*/}
                                {/*    <div className='join-onebook color-white bg-green'>*/}
                                {/*        <span>Tham Gia</span>*/}
                                {/*        <span>ONEBOOK</span>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='search-table container bg-white app-separator'>
                    <div className='d-flex justify-content-between mb-5 flex-wrap'>
                        <div className='sup-count mb-2'><span style={{fontSize: "1.5rem"}}>{this.state.totalItem} CHUYÊN GIA</span>
                        </div>
                        <div className='sorter d-flex'>
                            <div className='form-group mr-4'>
                                <select
                                    value={this.state.sortBy}
                                    className='form-control'
                                    id='exampleFormControlSelect3'
                                    placeholder='Sắp xếp theo'
                                    onChange={(e) => {
                                        this.setState({
                                            sortBy: e.target.value
                                        }, () => this.searchSupervisor())
                                    }}
                                >
                                    <option disabled value=''>
                                        Sắp xếp theo
                                    </option>
                                    <option value='fullName'>Tên</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <select
                                    value={this.state.sortAsc}
                                    className='form-control'
                                    id='exampleFormControlSelect4'
                                    onChange={(e) => {
                                        this.setState({
                                            sortAsc: e.target.value
                                        }, () => this.searchSupervisor())
                                    }}
                                >
                                    <option value='asc'>
                                        Tăng dần
                                    </option>
                                    <option value='desc'>Giảm dần</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='list-sup-content app-separator'>
                        {supervisorData?.map(item => (
                            <div className='list-sup-item border-homepage mb-3'>
                                <div className='row'>
                                    <div className='col-md-8 d-flex align-items-center flex-wrap'>
                                        <div className='col-sm-3 pdy-15px'>
                                            <div className='supervisor-avt'>
                                                <img src={item.urlImage} alt='avt' className='h-100'/>
                                            </div>
                                        </div>
                                        <div className='col-sm-9'>
                                            <h4 className='color-main text-left mt-2 text-bold'>
                                                <Link
                                                    to={{
                                                        pathname: "/home/supervisor-page",
                                                        // search: `?s=${item.id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                        search: `?s=${item.id}-${this.renderNewsUrl(item.fullName)}`,
                                                        state: {
                                                            fromDashboard: true,
                                                            supervisor: item.id,
                                                        }
                                                    }}
                                                    style={{
                                                        color: '#0c7fe2'
                                                    }}
                                                >
                                                    {item.fullName}
                                                </Link>
                                            </h4>
                                            <div className='sup-rating d-flex justify-content-between mb-3'>
                                                <div className='stars'>
                                                    {item.vote >= 1 ? <StarFill color='yellow'/> : <Star/>}
                                                    {item.vote >= 2 ? <StarFill color='yellow'/> : <Star/>}
                                                    {item.vote >= 3 ? <StarFill color='yellow'/> : <Star/>}
                                                    {item.vote >= 4 ? <StarFill color='yellow'/> : <Star/>}
                                                    {item.vote >= 5 ? <StarFill color='yellow'/> : <Star/>}
                                                </div>
                                                {/*<div className='rating-count'>0 Đánh giá</div>*/}
                                            </div>
                                            <div className='sup-desc text-left'>
                                                <p style={{'white-space': 'pre-wrap'}}>{item.certificate}</p> {/*newline \n*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 text-left pr-0'>
                                        <div className='homepage-supervisor-item pd-15px'>
                                            <h5 className='color-main text-bold'>Công ty quản lý</h5>
                                            <ul>
                                                <li>{item.partnerName}</li>
                                            </ul>
                                            <h5 className='color-main text-bold'>Khu vực hỗ trợ</h5>
                                            <ul>
                                                <li>{item.areaName}</li>
                                            </ul>
                                            <h5 className='color-main text-bold'>Lĩnh vực hỗ trợ</h5>
                                            <ul>
                                                {item.fieldSupport?.split(',').map(item => (
                                                    <li>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="pagination-right mb-4 pd-4">
                        <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItem}
                            changeHandler={(page) => this.handleChangePage(page)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ListSupervisor;
