
import React from 'react';
import "./Modal.css"
import {withSnackbar} from "notistack";
import Cookies from "js-cookie";
import Utils from '../../utils/utils'

import {
    Modal,
} from 'react-bootstrap';
import PublicContract from "../../services/ob-core/public-contract";
import {toast} from "react-toastify";

class ModalCheckContractSigned extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {

        }
    }

    handleConfirm() {
        let payload = {
            id: this.props.contractId
        }
        PublicContract.signedContract(payload, res => {
            if (res.success){
                toast.success("Chuyển hợp đồng sang đã ký thành công!")
                this.props.onRefresh()
            } else{
                toast.error("Thao tác thất bại. Vui lòng thử lại! " + res.message)
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='m-1 text-center'>
                            <span className=""><b>Bạn có chắc chắn muốn chuyển trạng thái hợp đồng <b>"{this.props.contractCode}"</b> sang đã ký không?</b></span>
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s background-btn type-button-fix btn btn-primary btn-sm"
                                        onClick={() => this.handleConfirm()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s type-button-fix btn btn-light btn-sm"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đóng
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalCheckContractSigned;
