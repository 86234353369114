import {stringify} from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let CallCenterApi = {
    getNameById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/get-name-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getNamesByListIds: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/get-names-by-ids?ids=${param}`,
            method: 'GET'
        }, callback)
    },
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/delete`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    resetPassword: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/reset-password`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/update`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    updateById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/update/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    block: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/block/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    unBlock: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/unblock/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/get-list?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    getDetail: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/detail`,
            method: 'GET',
        }, callback);
    },
    getDetailById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/detail/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/call-center/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },


}

export default CallCenterApi
