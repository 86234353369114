import React from 'react'
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import moment from 'moment';

import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Autosuggestion from '../../../components/Form/Autosuggestion';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalDetailCustomer from "../../../components/Modal/modalDetailCustomer";
import ModalUpdateCustomer from "../../../components/Modal/modalUpdateCustomer";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import CompanyApi from "../../../services/ob-user/company";

const dateFormat = 'DD-MM-YYYY';

class CustomerManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetail: false,
            showEdit: false,
            selectedPartner: {},
            selectedCustomer: {},
            listData: [],
            listCustomer: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 50,
            fullName: "",
            fullName_temp: "",
            itemSelected: {},

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'tax',
                    name: "Mã số thuế",
                    style: {textAlign: "center"},
                    sort: false,
                },

                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },

                {
                    id: '',
                    name: "Thao tác",
                    style: {minWidth: 160, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListCustomerFromAPI()
        })
    }

    searchListCustomerFromAPI = () => {
        let {pageNumber, pageSize, fullName, sort, companyId} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            companyId,
            // fullName: fullName,
            sort: sort,
        }
        SecureCustomerApi.callCenterGetListCustomer(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false

                })
            } else {
                toast.error('Đã xảy ra lỗi lấy dữ liệu. Vui lòng tải lại trang !')
            }
        })
    }

    getCustomer = () => {
        let {fullName, fullName_temp, selectedCustomer} = this.state;
        let payload = {
            pageNumber: 0,
            pageSize: 10,
            companyName: fullName_temp,
        }
        CompanyApi.autocompleteGetCompanyName(payload, res => {
            if (res.success) {
                this.setState({
                    listDataSearch: res.data.content,
                })
            }
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListCustomerFromAPI()
            }
        );
    }

    //Button***********************************************************************************************************

    onSearch = () => {
        let {fullName_temp, selectedCustomer} = this.state;
        this.setState(
            {
                // fullName: fullName_temp,
                companyId: selectedCustomer?.id,
                pageNumber: 0
            },
            () => {
                this.searchListCustomerFromAPI();
            }
        );
    }

    onRefresh = (e) => {
        this.setState(
            {
                fullName_temp: "",
                pageNumber: 0
            },
            () => this.onSearch()
        );
    }

    //****************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    }

//*********************************************************************************************************************

    render() {
        let {pageNumber, pageSize} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="customer-management"/>*/}
                        <Sidebar activeClass="customer-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý khách hàng"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Tên khách hàng</label>
                                                <Autosuggestion
                                                    label=""
                                                    placeholder="Nhập tên khách hàng"
                                                    type="text"
                                                    value={this.state.fullName_temp}
                                                    data={this.state.listDataSearch}
                                                    onSelect={(item, val) => {
                                                        this.setState({selectedCustomer: item}, () => console.log(this.state.selectedCustomer))
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            fullName_temp: val,
                                                            selectedCustomer: null
                                                        }, () => this.getCustomer())
                                                        // this.setState({ value:val.target.value,selectedCustomer: null})
                                                    }}
                                                    // checkError={!this.state.listDataSearch}
                                                    errorContent="Vui lòng chọn một khách hàng"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.setState({
                                                            pageNumber: 0,
                                                            fullName: '',
                                                            fullName_temp: '',
                                                            selectedCustomer: null
                                                        }, () => this.onSearch())}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = 'status'
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListCustomerFromAPI())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{row.transactionContent}*/}
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.tax}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*<Link*/}
                                                        {/*    to={{*/}
                                                        {/*        pathname: "./customer-details",*/}
                                                        {/*        search: `?customerId=${row.id}`,*/}
                                                        {/*        state: {*/}
                                                        {/*            fromDashboard: true,*/}
                                                        {/*            customerId: row.customerId,*/}
                                                        {/*        }*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <button*/}
                                                        {/*        className="btn btn-action"*/}
                                                        {/*        title="Xem chi tiết"*/}
                                                        {/*        onClick={() => {*/}

                                                        {/*        }}*/}
                                                        {/*    >*/}
                                                        {/*        <i className="ti-book"/>*/}
                                                        {/*    </button>*/}
                                                        {/*</Link>*/}
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem chi tiết"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showDetail: true})
                                                            }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Cập nhật thông tin"
                                                            disabled={row.status !== 'WAIT_COMPLETE_INFO'}
                                                            onClick={() => {
                                                                if (row.status === 'WAIT_COMPLETE_INFO')
                                                                    this.setState({itemSelected: row, showEdit: true})
                                                            }}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showDetail && <ModalDetailCustomer show={this.state.showDetail}
                                                               onHide={() => this.setState({showDetail: false})}
                                                               data={this.state.itemSelected}

                />}
                {this.state.showEdit && <ModalUpdateCustomer show={this.state.showEdit}
                                                             onHide={() => this.setState({showEdit: false})}
                                                             data={this.state.itemSelected}

                />}
            </div>
        )
    }
}

export default CustomerManagement
