import React, {Component} from "react";
import moment from 'moment';

import TableHeader from "../../../../components/TableHeader";
import Pagination from "../../../../components/Pagination";

import DocumentApi from "../../../../services/ob-core/document";
import ContractLog from "../../../../services/ob-core/contract-log";
import SecureCustomerApi from "../../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import PartnerApi from "../../../../services/ob-user/partner";
import {toast} from "react-toastify";
import AccountantApi from "../../../../services/ob-user/accountant";
import CallCenterApi from "../../../../services/ob-user/call-center";

class ListHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_temp: '',
            type_temp_search: '',
            status_temp_search: "",
            status_temp: "",
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            listData: [],
            listPartner: [],
            listSup: [],
            listCus: [],
            listAcc: [],
            listCall: [],
            sort: "",
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Thời gian",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Nội dung",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Người thực hiện",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Vai trò",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng"
        this.getListDataContractFromAPI();
    }

    getListDataContractFromAPI() {
        let {pageNumber, pageSize, sort} = this.state;
        let payload = {
            contractId: this.props.contractId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
        };
        ContractLog.searchByContractId(payload, res => {
            if (res.success) {
                let data = res.data.content
                let listIdAcc = [], listIdSup = [], listIdPartner = [], listIdCus = [], listIdCall = []
                data.map(item => {
                    if (item.userType === 'CUSTOMER') listIdCus.push(item.userId)
                    else if (item.userType === 'PARTNER') listIdPartner.push(item.userId)
                    else if (item.userType === 'SUPERVISOR') listIdSup.push(item.userId)
                    else if (item.userType === 'ACCOUNTANT') listIdAcc.push(item.userId)
                    else if (item.userType === 'CALL_CENTER') listIdCall.push(item.userId)
                })

                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listIdAcc, listIdSup, listIdPartner, listIdCus, listIdCall
                }, () => {
                    if (listIdSup.length > 0) this.getListSupervisorByIds()
                    if (listIdCus.length > 0) this.getListCustomerByIds()
                    if (listIdPartner.length > 0) this.getListPartnerById()
                    if (listIdAcc.length > 0) this.getListAccountantByIds()
                    if (listIdCall.length > 0) this.getListCallCenterByIds()
                });
            }
        });
    }

    getListCustomerByIds = () => {
        let {listIdCus, listData} = this.state;
        let payload = {
            ids: listIdCus
        };
        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                this.setState({
                    listCus: data
                });
            }
        });
    }

    getListSupervisorByIds = () => {
        let {listIdSup, listData} = this.state;
        let payload = {
            ids: listIdSup
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                this.setState({
                    listSup: data
                });
            }
        });
    }

    getListPartnerById = () => {
        let {listIdPartner, listData} = this.state;

        let payload = {
            ids: listIdPartner
        };
        PartnerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                this.setState({
                    listPartner: data
                });
            }
        });
    }

    getListAccountantByIds = () => {
        let {listIdAcc, listData} = this.state;
        let payload = {
            ids: listIdAcc
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success) {
                let data = res.data;
                this.setState({
                    listAcc: data
                });
            }
        });
    }

    getListCallCenterByIds = () => {
        let {listIdCall, listData} = this.state;
        let payload = {
            ids: listIdCall
        };
        CallCenterApi.getNamesByListIds(payload, res => {
            if (res.success) {
                let data = res.data;
                this.setState({
                    listCall: data
                });
            }
        });
    }

    renderUserName = (item) => {
        let {listPartner, listSup, listCus, listAcc, listCall} = this.state
        if (item.userType === 'CUSTOMER') return listCus.filter(val => val.id === item.userId)[0]?.fullName
        else if (item.userType === 'SUPERVISOR') return listSup.filter(val => val.id === item.userId)[0]?.fullName
        else if (item.userType === 'PARTNER') return listPartner.filter(val => val.id === item.userId)[0]?.fullName
        else if (item.userType === 'CALL_CENTER') return listCall.filter(val => val.id === item.userId)[0]?.fullName
        else if (item.userType === 'ACCOUNTANT') return listAcc.filter(val => val.id === item.userId)[0]?.fullName
        else if (item.userType === 'ADMIN') return 'Admin OneBook'
    }

    renderContent = (val) => {
        switch (val) {
            case "Create new contract":
                return "Tạo mới hợp đồng";
            case "Admin active contract":
                return "Admin kích hoạt hợp đồng";
            case "Assign to accountant":
                return "Assign kế toán";
            case "Accept contract":
                return "Chấp nhận hợp đồng";
            case "Reject contract":
                return "Từ chối hợp đồng";
        }
    }

    renderUserType = (val) => {
        switch (val) {
            case "PARTNER":
                return "Partner";
            case "SUPERVISOR":
                return "Supervisor";
            case "ACCOUNTANT":
                return "Kế toán";
            case "CUSTOMER":
                return "Khách hàng";
            case "ADMIN":
                return "Admin";
            case "CALL_CENTER":
                return "Call Center";
            default:
                return '';
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListDataContractFromAPI();
            }
        );
    }

    render() {
        let {header, listData} = this.state;
        return (
            <div>
                <div>
                    <table className="table table-striped table-data table-bordered">
                        <thead>
                        <TableHeader
                            header={header}
                            clickSort={data => {
                                console.log(data);
                                let a = "";
                                if (data.sortAsc == null) a = "";
                                else a = `${data.sortId}=${data.sortAsc ? "asc" : "desc"}`;
                                this.setState({
                                        sort: a
                                    },() => this.getListDataContractFromAPI()
                                );
                            }}
                        />
                        </thead>
                        {!this.state.listData || this.state.listData.length == 0
                            ? <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <div className='text-center'>Không có dữ liệu</div>
                                </td>
                            </tr>
                            </tbody>
                            : <tbody>
                            {listData &&
                            listData.length > 0 &&
                            listData?.map((row, index) => (
                                <tr style={{height: 50}}>
                                    <td style={{textAlign: "center"}} scope="row">
                                        {" "}
                                        {this.state.pageNumber * this.state.pageSize +
                                        index +
                                        1}
                                    </td>
                                    <td style={{textAlign: "center"}} scope="row">
                                        {moment(row.cmnCreatedAt).format("DD-MM-YYYY")}
                                    </td>
                                    <td style={{textAlign: "center"}}>{this.renderContent(row.content)}</td>
                                    <td style={{textAlign: "center"}}>
                                        {" "}
                                        {this.renderUserName(row) || 'Chưa có dữ liệu'}
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        {this.renderUserType(row.userType)}
                                    </td>

                                </tr>
                            ))}
                            </tbody>}
                    </table>
                    <div style={{height: 40}} className="pagination-right mt-3">
                        <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItem}
                            changeHandler={(page) => this.handleChangePage(page)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ListHistory
