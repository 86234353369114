const MenuItems = {
    CallCenter: [
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            icon: "fas fa-user font-18 icon-side-bar",
            url: '/call-center/profile',
            permission: "",
            // children: [
            //
            // ]
        },
        {
            title: "Quản lý Order",
            activeClass: "orders",
            icon: "fas fa-file-invoice font-18 icon-side-bar",
            permission: "view_order_list",
            // url: '/call-center/profile',
            children: [
                {
                    title: "Danh sách Order mới",
                    activeClass: "new-orders",
                    parentClass: "orders",
                    icon: "caret",
                    url: '/call-center/new-orders',
                    permission: "view_order_list",
                },
                {
                    title: "Danh sách cơ bản",
                    activeClass: "orders-management",
                    parentClass: "orders",
                    icon: "caret",
                    url: '/call-center/orders-management',
                    permission: "view_order_list",
                },
                {
                    title: "Danh sách nâng cao",
                    activeClass: "advance-orders-management",
                    parentClass: "orders",
                    icon: "caret",
                    url: '/call-center/advance-orders-management',
                    permission: "view_order_list",
                },
            ]
        },
        {
            title: "Quản lý khách hàng",
            activeClass: "customer-management",
            icon: "fas fa-users font-18 icon-side-bar",
            url: '/call-center/customer-management',
            permission: "",
        },
        {
            title: "Quản lý Supervisor",
            activeClass: "supervisor-management",
            icon: "fas fa-user-tie font-18 icon-side-bar",
            url: '/call-center/supervisor-management',
            permission: "",
        },
        {
            title: "Quản lý đăng ký mới",
            activeClass: "new-signup-management",
            icon: "fas fa-user-plus font-18 icon-side-bar",
            url: '/call-center/new-signup-management',
            permission: "management_new_accounts_registered_to_the_platform",
        },

    ],
    Customer: [
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            icon: "ti-id-badge font-20 icon-side-bar",
            url: '/customer/profile',
            permission: "",
        },
        {
            title: "Đăng ký dịch vụ",
            activeClass: "orders",
            icon: "ti-archive font-20 icon-side-bar",
            permission: "register_service_order",
            children: [
                {
                    title: "Đăng ký",
                    activeClass: "order-service",
                    parentClass: "orders",
                    icon: "caret",
                    url: '/home/select-plan-step-1',
                    permission: "register_service_order",
                },
                {
                    title: "Dịch vụ đã đăng ký",
                    activeClass: "list-ordered-service",
                    parentClass: "orders",
                    icon: "caret",
                    url: '/customer/list-ordered-service',
                    permission: "register_service_order",
                },
            ]
        },
        {
            title: "Quản lý hợp đồng",
            activeClass: "contracts-management",
            icon: "ti-write font-20 icon-side-bar",
            url: '/customer/contracts-management',
            permission: "",
        },
        {
            title: "Quản lý báo cáo",
            activeClass: "report-management",
            icon: "ti-receipt font-20 icon-side-bar",
            children: [
                {
                    title: "Bảng cân đối kế toán ",
                    activeClass: "balance",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/balance',
                    // permission: "list_partner_account",
                },
                {
                    title: "Báo cáo kết quả sản xuất kinh doanh",
                    activeClass: "business-results",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/business-results',
                    // permission: "list_partner_account",
                },
                {
                    title: "Báo cáo lưu chuyển tiền tệ trực tiếp",
                    activeClass: "direct-cash",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/direct-cash',
                    // permission: "list_partner_account",
                },
                {
                    title: "Báo cáo lưu chuyển tiền tệ gián tiếp",
                    activeClass: "indirect-cash",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/indirect-cash',
                    // permission: "list_partner_account",
                },
                {
                    title: "Tổng hợp NXT ",
                    activeClass: "report-summary",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/report-summary',
                    // permission: "list_partner_account",
                },
                {
                    title: "Báo cáo công nợ phải thu",
                    activeClass: "report-receivable",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/cong-no-phai-thu',
                    // permission: "list_partner_account",
                },
                {
                    title: "Báo cáo công nợ phải trả",
                    activeClass: "report-pay-debt",
                    parentClass: "report-management",
                    icon: "flaticon-profile",
                    url: '/customer/cong-no-phai-tra',
                    // permission: "list_partner_account",
                }
            ]
        },
        {
            title: "Quản lý thanh toán",
            activeClass: "payment-management",
            icon: "ti-money font-20 icon-side-bar",
            url: '/customer/payment-management',
            permission: "list_payment",
        },
        // {
        //     title: "Quản lý phân quyền",
        //     activeClass: "permission-management",
        //     icon: "ti-bookmark-alt font-20 icon-side-bar",
        //     url: '/customer/permission-management',
        //     permission: "view_role",
        // },
        // {
        //     title: "Quản lý User",
        //     activeClass: "user-management",
        //     icon: "flaticon-group",
        //     url: '/customer/user-management',
        //     permission: "list_admin_user_account",
        // },
    ],
    Accountant: [
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            icon: "ti-id-badge font-20 icon-side-bar",
            url: '/accountant/profile',
        },
        {
            title: "Quản lý hợp đồng",
            activeClass: "contracts-management",
            icon: "ti-write font-20 icon-side-bar",
            url: '/accountant/contracts-management',
        },
        {
            title: "Quản lý doanh thu",
            activeClass: "revenue-management",
            icon: "ti-bar-chart-alt font-20 icon-side-bar",
            url: '/accountant/revenue-management',
        },
        {
            title: "Quản lý Job",
            activeClass: "jobs-management",
            icon: "ti-direction-alt font-20 icon-side-bar",
            url: '/accountant/jobs-management',
        },
    ],
    Supervisor: [
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            icon: "ti-id-badge font-20 icon-side-bar",
            url: '/supervisor/profile',
        },
        {
            title: "Quản lý kế toán",
            activeClass: "accountant-management",
            icon: "flaticon-group",
            url: '/supervisor/accountant-management',
        },
        {
            title: "Quản lý hợp đồng",
            activeClass: "contracts-management",
            icon: "ti-write font-20 icon-side-bar",
            url: '/supervisor/contracts-management',
        },
        {
            title: "Quản lý Job",
            activeClass: "jobs-management",
            icon: "ti-direction-alt font-20 icon-side-bar",
            url: '/supervisor/jobs-management',
        },
        {
            title: "Quản lý doanh thu",
            activeClass: "revenue-management",
            icon: "ti-bar-chart-alt font-20 icon-side-bar",
            url: '/supervisor/revenue-management',
        },
        {
            title: "Quản lý order",
            activeClass: "orders-management",
            icon: "ti-layers font-20 icon-side-bar",
            url: '/supervisor/orders-management',
        },
        {
            title: "Quản lý báo cáo",
            activeClass: "report-management",
            icon: "ti-receipt font-20 icon-side-bar",
            url: '/supervisor/report-management',
        },
    ],
    Partner: [
        {
            title: "Dashboard",
            activeClass: "dashboard",
            icon: "fas fa-tachometer-alt font-18 icon-side-bar",
            url: '/partner/dashboard',
            permission: "",
        },
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            icon: "fas fa-user font-18 icon-side-bar",
            url: '/partner/profile',
            permission: "",
        },
        {
            title: "Quản lý nhân sự ",
            activeClass: "staff-management-partner",
            icon: "fas fa-user-friends font-18 icon-side-bar",
            permission: "",
            children: [
                {
                    title: "Sơ đồ công ty",
                    activeClass: "company-organizational-chart",
                    parentClass: "staff-management-partner",
                    icon: "caret",
                    url: '/partner/company-organizational-chart',
                    permission: "",
                },
                {
                    title: "Quản lý Supervisor",
                    activeClass: "supervisor-management",
                    parentClass: "staff-management-partner",
                    icon: "caret",
                    url: '/partner/supervisor-management',
                    permission: "",
                },
                // {
                //     title: "Admin User",
                //     activeClass: "admin-management",
                //     parentClass: "staff-management-partner",
                //     icon: "caret",
                //     url: '/partner/admin-management',
                //     permission: "list_admin_user_account",
                // },
            ]
        },
        {
            title: "Quản lý hợp đồng",
            activeClass: "contracts-management",
            icon: "fas fa-file-contract font-18 icon-side-bar",
            url: '/partner/contracts-management',
            permission: "",
        },

        {
            title: "Quản lý doanh thu ",
            activeClass: "revenue-management",
            icon: "fas fa-chart-bar font-18 icon-side-bar",
            permission: "",
            children: [
                {
                    title: "Quản lý doanh thu Partner",
                    activeClass: "partner-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/partner/revenue-management',
                    permission: "list_revenue_partner",
                },
                {
                    title: "Quản lý doanh thu Supervisor",
                    activeClass: "supervisor-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/partner/supervisor-revenue-management',
                    permission: "list_revenue_supervisor",
                },
                {
                    title: "Quản lý doanh thu Kế toán",
                    activeClass: "accountant-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/partner/accountant-revenue-management',
                    permission: "list_revenue_accountant",
                },
            ]
        },
        {
            title: "Quản lý ví",
            activeClass: "wallet-management",
            icon: "fas fa-wallet font-18 icon-side-bar",
            url: '/partner/wallet-management',
            permission: "list_partner_wallet",
        },
        {
            title: "Quản lý Order",
            activeClass: "orders-management",
            icon: "fas fa-file-invoice font-18 icon-side-bar",
            url: '/partner/orders-management',
            permission: "view_order_list",
        },
        {
            title: "Quản lý báo cáo",
            activeClass: "report-management",
            icon: "fas fa-file-alt font-18 icon-side-bar",
            url: '/partner/report-management',
            permission: "",
        },
        // {
        //     title: "Quản lý đăng ký tài khoản",
        //     activeClass: "new-signup-management",
        //     icon: "flaticon-group",
        //     url: '/partner/new-signup-management',
        //     permission: "management_new_account_register",
        // },
        {
            title: "Quản lý phân quyền",
            activeClass: "permission-management",
            icon: "fas fa-user-shield font-18 icon-side-bar",
            url: '/partner/permission-management',
            permission: "view_role",
        },
        {
            title: "Quản lý quá hạn thanh toán",
            activeClass: "overdue-payment-management",
            icon: "fas fa-exclamation-triangle font-18 icon-side-bar",
            url: '/partner/overdue-payment-management',
            permission: "",
        },
    ],
    Admin: [
        {
            title: "Dashboard",
            activeClass: "dashboard",
            // icon: "ti-dashboard",
            icon: "fas fa-tachometer-alt font-18 icon-side-bar",
            url: '/admin/dashboard',
            permission: "",
        },
        {
            title: "Thông tin cá nhân",
            activeClass: "profile",
            // icon: "flaticon-group",
            icon: "fas fa-user font-18 icon-side-bar",
            url: '/admin/profile',
            permission: "",
        },
        {
            title: "Gói dịch vụ ",
            activeClass: "service-plan-management",
            // icon: "ti-package",
            icon: "fas fa-archive font-18 icon-side-bar",
            url: '/admin/service-plan-management',
            permission: "view_service_plan_list",
        },
        {
            title: "Quản lý chương trình ",
            activeClass: "program-management",
            // icon: "ti-package",
            icon: "fas fa-archive font-18 icon-side-bar",
            url: '/admin/program-management',
            permission: "view_program_list",
        },
        {
            title: "Quản lý hợp đồng ",
            activeClass: "contract-management",
            // icon: "flaticon-profile",
            icon: "fas fa-file-contract font-18 icon-side-bar",
            url: '/admin/contract-management',
            permission: "view_contract_detail ",
        },
        // {
        //     title: "Quản lý báo cáo",
        //     activeClass: "report-management",
        //     icon: "ti-receipt font-20 icon-side-bar",
        //     children: [
        //         {
        //             title: "Bảng cân đối kế toán ",
        //             activeClass: "balance",
        //             parentClass: "report-management",
        //             icon: "flaticon-profile",
        //             url: '/admin/balance',
        //             // permission: "list_partner_account",
        //         },
        //         {
        //             title: "Báo cáo kết quả sản xuất kinh doanh ",
        //             activeClass: "business-results",
        //             parentClass: "report-management",
        //             icon: "flaticon-profile",
        //             url: '/admin/business-results',
        //             // permission: "list_partner_account",
        //         },
        //         {
        //             title: "Báo cáo lưu chuyển tiền tệ trực tiếp ",
        //             activeClass: "direct-cash",
        //             parentClass: "report-management",
        //             icon: "flaticon-profile",
        //             url: '/admin/direct-cash',
        //             // permission: "list_partner_account",
        //         },
        //         {
        //             title: "Báo cáo lưu chuyển tiền tệ gián tiếp ",
        //             activeClass: "indirect-cash",
        //             parentClass: "report-management",
        //             icon: "flaticon-profile",
        //             url: '/admin/indirect-cash',
        //             // permission: "list_partner_account",
        //         },
        //         {
        //             title: "Tổng hợp NXT ",
        //             activeClass: "report-summary",
        //             parentClass: "report-management",
        //             icon: "flaticon-profile",
        //             url: '/admin/report-summary',
        //             // permission: "list_partner_account",
        //         },
        //     ]
        // },
        {
            title: "Quản lý Order ",
            activeClass: "order-management",
            // icon: "flaticon-profile",
            icon: "fas fa-file-invoice font-18 icon-side-bar",
            url: '/admin/order-management',
            permission: "view_order_list",
        },
        {
            title: "Quản lý khách hàng ",
            activeClass: "customer-management",
            // icon: "flaticon-profile",
            icon: "fas fa-users font-18 icon-side-bar",
            url: '/admin/customer-management',
            permission: "",
        },
        {
            title: "Quản lý nhân sự ",
            activeClass: "employee-management",
            // icon: "flaticon-profile",
            icon: "fas fa-user-friends font-18 icon-side-bar",
            permission: "",
            children: [
                {
                    title: "Partner ",
                    activeClass: "partner-management",
                    parentClass: "employee-management",
                    icon: "flaticon-profile",
                    url: '/admin/partner-management',
                    // permission: "list_partner_account",
                },
                {
                    title: "Supervisor ",
                    activeClass: "supervisor-management",
                    parentClass: "employee-management",
                    icon: "flaticon-profile",
                    url: '/admin/supervisor-management',
                    // permission: "list_supervisor_account",
                },
                // {
                //     title: "Accountant ",
                //     activeClass: "accountant-management",
                //     parentClass: "employee-management",
                //     icon: "flaticon-profile",
                //     url: '/admin/supervisor-management',
                //     permission: "list_accountant_account",
                // },
                {
                    title: "Admin User ",
                    activeClass: "user-management",
                    parentClass: "employee-management",
                    icon: "flaticon-group",
                    url: '/admin/user-management',
                    // permission: "list_admin_user_account",
                },
                {
                    title: "Call Center ",
                    activeClass: "call-center-management",
                    parentClass: "employee-management",
                    icon: "flaticon-group",
                    url: '/admin/call-center-management',
                    permission: "list_call_center_account",
                },
            ]
        },
        {
            title: "Quản lý doanh thu ",
            activeClass: "revenue-management",
            // icon: "ti-bar-chart-alt",
            icon: "fas fa-chart-bar font-18 icon-side-bar",
            permission: "view_order_list",
            children: [
                {
                    title: "Doanh thu OneBook",
                    activeClass: "ob-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/admin/revenue-management',
                    permission: "",
                },
                {
                    title: "Doanh thu Partner",
                    activeClass: "partner-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/admin/partner-revenue-management',
                    permission: "",
                },
                {
                    title: "Doanh thu Supervisor",
                    activeClass: "supervisor-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/admin/supervisor-revenue-management',
                    permission: "",
                },
                {
                    title: "Doanh thu Kế toán",
                    activeClass: "accountant-revenue-management",
                    parentClass: "revenue-management",
                    icon: "caret",
                    url: '/admin/accountant-revenue-management',
                    permission: "",
                },
            ]
        },
        {
            title: "Quản lý ví tiền ",
            activeClass: "wallet",
            // icon: "flaticon-group",
            icon: "fas fa-wallet font-18 icon-side-bar",
            permission: "list_wallet",
            children: [
                {
                    title: "Ví Onebook",
                    activeClass: "wallet-management",
                    parentClass: "wallet",
                    icon: "flaticon-group",
                    url: '/admin/wallet-management',
                    permission: "",
                },
                {
                    title: "Ví Partner ",
                    activeClass: "wallet",
                    icon: "flaticon-group",
                    url: '/admin/partner-wallet-management',
                    permission: "list_partner_wallet",
                },
            ]
        },
        {
            title: "Quản lý đăng ký mới",
            activeClass: "new-signup-management",
            icon: "fas fa-user-plus font-18 icon-side-bar",
            url: '/admin/new-signup-management',
            permission: "management_new_accounts_registered_to_the_platform",
        },
        {
            title: "Quản lý email ",
            activeClass: "email-management",
            icon: "fas fa-mail-bulk font-18 icon-side-bar",
            permission: "management_email",
            children: [
                {
                    title: "Quản lý danh sách Email",
                    activeClass: "error-email-management",
                    parentClass: "email-management",
                    icon: "caret",
                    url: '/admin/error-email-management',
                    permission: "",
                },
            ]
        },
        {
            title: "Quản lý SMS ",
            activeClass: "sms-management",
            icon: "fas fa-sms font-18 icon-side-bar",
            url: '/admin/sms-management',
            permission: "management_sms",
        },
        {
            title: "Quản lý phân quyền ",
            activeClass: "permission-management",
            icon: "fas fa-user-shield font-18 icon-side-bar",
            url: '/admin/permission-management',
            permission: "",
        },
        {
            title: "Quản lý Homepage ",
            activeClass: "home-management",
            icon: "fas fa-home font-18 icon-side-bar",
            // permission: "list_wallet",
            children: [
                {
                    title: "Quản lý danh mục bài viết",
                    activeClass: "post-category",
                    parentClass: "home-management",
                    icon: "flaticon-group",
                    url: '/admin/post-category',
                    permission: "",
                },
                {
                    title: "Quản lý bài viết",
                    activeClass: "post-management",
                    parentClass: "home-management",
                    icon: "flaticon-group",
                    url: '/admin/post-management',
                    permission: "",
                },
                {
                    title: "Quản lý bài viết nổi bật",
                    activeClass: "hot-post-management",
                    parentClass: "home-management",
                    icon: "flaticon-group",
                    url: '/admin/hot-post-management',
                    permission: "",
                },
                {
                    title: "Quản lý Supervisor nổi bật",
                    activeClass: "top-supervisor-management",
                    parentClass: "home-management",
                    icon: "flaticon-group",
                    url: '/admin/top-supervisor-management',
                    permission: "",
                },
            ]
        },
        {
            title: "Cấu hình hệ thống ",
            activeClass: "config-system",
            icon: "fas fa-cogs font-18 icon-side-bar",
            permission: "",
            // hide: true,
            children: [
                {
                    title: "Cấu hình Email",
                    activeClass: "email-config",
                    parentClass: "config-system",
                    icon: "caret",
                    url: '/admin/email-configuration',
                    permission: "",
                },
                {
                    title: "Cấu hình SMS",
                    activeClass: "sms-config",
                    parentClass: "config-system",
                    icon: "caret",
                    url: '/admin/sms-configuration',
                    permission: "",
                },
                {
                    title: "Cấu hình quá hạn",
                    activeClass: "overdue-config",
                    parentClass: "config-system",
                    icon: "caret",
                    url: '/admin/overdue-configuration',
                    permission: "",
                    hide: true,
                },
            ]
        },
    ],
}

export default MenuItems


