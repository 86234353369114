import React from 'react';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import Autosuggestion from "../../../../components/Form/Autosuggestion";
import TableHeader from "../../../../components/TableHeader";
import ModalUnHighlightNews from "../../../../components/Modal/admin/modalUnHighlightNews";
import PublicSupervisorApi from "../../../../services/ob-user/public-supervisor";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import {toast} from "react-toastify";
import ModalSetTopSupervisor from "../../../../components/Modal/modalSetTopSupervisor";

class TopSupervisor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 10,
            pageNumber: 0,
            supervisorList: [],
            modalSetTopSup: false,
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: 'Họ và tên',
                    style: {textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'partnerName',
                    name: 'Partner',
                    style: {textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'branchName',
                    name: 'Chi nhánh',
                    style: {textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center", minWidth: 120},
                    sort: false
                },

            ],
        }
    }

    componentWillMount() {
        this.getListTopSup()
    }

    getListTopSup = () => {

        PublicSupervisorApi.getSupervisorHighlight( res => {
            if (res.success){
                this.setState({data: res.data})
            }
        })
    }

    searchSupervisor = (val) => {
        let payload = {
            fullName: val,
            status: "ACTIVE"
        }
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if(res.success) {
                this.setState({supervisorList: res.data.content})
            }
        })
    }

    onSelectSupervisor = (val) => {
        if(this.state.data.length >= 5) {
            toast.error("Chỉ được tối đa 5 Supervisor nổi bật. Vui lòng xóa bớt để thêm mới !")
        } else if (val.id === "") {
            toast.error("Phải chọn người phụ trách chuyên môn trước !")
        } else if (val.highlight) {
            toast.error("Người phụ trách chuyên môn này đã được đánh dấu nổi bật, vui lòng chọn người phụ trách chuyên môn khác!")
        } else {
            this.setState({
                modalSetTopSup: true,
                modalType: "highlight",
                selectedSupervisor: val
            })
        }
    }

    render() {
        let {pageNumber, pageSize} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="top-supervisor-management" parentClass='home-management'/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Supervisor nổi bật"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className='col-4 p-0'>
                                                <label className="label-item">Thêm mới người phụ trách nổi bật</label>
                                                <Autosuggestion
                                                    placeholder="Nhập tên người phụ trách"
                                                    type="text"
                                                    data={this.state.supervisorList}
                                                    onSelect={(item) => {
                                                        this.onSelectSupervisor(item)
                                                    }}
                                                    onChange={(val) => {
                                                        console.log("check",val);
                                                        this.setState({supervisorName: val, selectedSupervisor: null})
                                                        setTimeout(() => {
                                                            this.searchSupervisor(val)
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.supervisorList}
                                                    errorContent="Vui lòng chọn một Supervisor"
                                                />
                                            </div>
                                            {/*<div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>*/}
                                            {/*    <button*/}
                                            {/*        type="button" className="btn border-1-s btn-primary btn-sm"*/}
                                            {/*        style={{height: 35}}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            */}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Thêm mới*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListService())
                                        }}/>
                                        </thead>
                                        {!this.state.data || this.state.data.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.data.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {/*{row.contractCode}*/}
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.partnerName}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.branchName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button type="button" className="mx-1 btn border-1-s btn-primary btn-sm"
                                                                title='Bỏ nổi bật'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectedSupervisor: row,
                                                                        modalSetTopSup: true,
                                                                        modalType: "unset"
                                                                    })
                                                                }}
                                                        >
                                                            Bỏ nổi bật
                                                        </button>
                                                        <button type="button" className="mx-1 btn border-1-s btn-info btn-sm"
                                                                title='Thay đổi ảnh nổi bật'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectedSupervisor: row,
                                                                        modalSetTopSup: true,
                                                                        modalType: "edit"
                                                                    })
                                                                }}
                                                        >
                                                            Thay đổi ảnh
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalSetTopSup && <ModalSetTopSupervisor show={this.state.modalSetTopSup}
                                                                      modalType={this.state.modalType}
                                                                     selectedSupervisor={this.state.selectedSupervisor}
                                                                      onRefresh={() => {this.setState({modalSetTopSup: false})
                                                                          this.getListTopSup()
                                                                      }}
                                                                      data={this.state.itemSelected}
                                                                      onHide={()=> this.setState({modalSetTopSup: false, supervisorName: ''})}
                />}

            </div>
        );
    }

}

export default TopSupervisor
