import React from 'react'
import {toast} from "react-toastify";
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";


class OverdueConfig extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDetail: false,
            showBlock: false,
            showUnblock: false,
            showDelete: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            itemSelected: {},
            listData: [],
            sort: '',
            number: 0,
            showLoading: false,
        }
    }

    componentWillMount() {
        // this.setState({showLoading: true}, () => {
        //
        // })
    }


    render() {
        let {pageNumber, pageSize, totalItem} = this.state;
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="overdue-config" parentClass="config-system"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Cấu hình thông báo quá hạn"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Loại thông báo</label>
                                                <select className="form-control kt-input pd-4-input shadow-none"
                                                        defaultValue={0}
                                                        onChange={(val) => {
                                                            // this.setState({
                                                            //     jobId: val.target.value
                                                            // })
                                                        }}
                                                >
                                                    <option value={0} disabled>Chọn một loại thông báo</option>
                                                    <option value={1}>Chọn một loại thông báo</option>
                                                    <option value={2} disabled>Chọn một loại thông báo</option>
                                                    {/*{this.props.jobs?.map(item => {*/}
                                                    {/*    return(*/}
                                                    {/*        <option value={item.id}>{item.name}</option>*/}
                                                    {/*    )*/}
                                                    {/*})}*/}
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Số ngày</label>
                                                <input
                                                    className="form-control shadow-none font-14"
                                                    placeholder='Số ngày'
                                                    maxLength={40}
                                                    value={this.state.number}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            number: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                    <div className="button-filter">
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            onClick={() => {
                                                // this.setState({pageNumber: 0}, () => this.getData())
                                            }}
                                        >
                                            Lưu
                                        </button>
                                        <button type="button"
                                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                onClick={() => this.onRefresh()}
                                        >
                                            Hủy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default OverdueConfig
