import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicPayment = {
    findByContractId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/payment/get-list-by-contract/${payload.contractId}`,
            method: 'GET'
        }, callback)
    },
    updatePaymentCycle: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/payment/update-payment-cycle/${payload.contractId}`,
            method: 'POST',
            body: payload.body
        }, callback)
    },
}
export default PublicPayment
