import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
// import admin from '../../../services/ob-core/admin-ob-reset-pass'
import {
    Modal,
} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecureCustomerApi from "../../services/ob-user/secure-customer";

class modalResetPassUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password:'',
            repassword:'',
            errPass: false,
            errRepass: false,
        };
    }

    componentDidMount() {
        this.setState({password: '', repassword: '', errPass: false, errRepass: false});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({password: '', repassword: '', errPass: false, errRepass: false});
        }
    }

    reset = () => {
        let payload = {
            id: this.props.id,
            password: this.state.password,
        };
        SecureCustomerApi.adminCustomerResetPasswordUser(payload, res => {
            if (res.success) {
                toast.success("Reset mật khẩu thành công!")
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>RESET MẬT KHẨU</b></h1>
                        </div>

                        <div className='justify-content-center mt-1 mb-3 ml-3 mr-3'>

                            <div className="row">
                                <label className="col-5 mt-2">Tên User </label>
                                <label className="col-7 mt-2">{this.props.name}</label>
                            </div>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Mật khẩu mới: </label>
                                <input
                                    type="password"
                                    className={this.state.errPass ? "inputPassword w-100 col-7 border-input-error " : "inputPassword w-100 col-7 "}
                                    placeholder="Nhập mật khẩu mới"
                                    fullWidth
                                    maxLength={20}
                                    value={this.state.password}
                                    onChange={(e) => this.setState({
                                            password: e.target.value.trim(),
                                            errNewPass: false
                                        },
                                        () => {
                                            this.state.password.length == 0 || this.state.password.length < 6 ? this.setState({errPass: true}) : this.setState({errPass: false})
                                        }
                                    )}
                                />
                            </div>

                            {this.state.errPass ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Mật khẩu đang trống hoặc ít hơn 6 kí tự !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhập lại mật khẩu: </label>
                                <input
                                    type="password"
                                    className={this.state.errRepass ? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7 "}
                                    placeholder='Xác nhận mật khẩu mới'
                                    fullWidth
                                    maxLength={20}
                                    value={this.state.repassword}
                                    onChange={(e) => this.setState({
                                            repassword: e.target.value.trim(),
                                            errRePass: false
                                        },
                                        () => {
                                            this.state.repassword.length == 0 || this.state.repassword !== this.state.password ? this.setState({errRepass: true}):this.setState({errRepass: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errRepass ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Nhập lại mật khẩu không đúng !</label>
                            </div> :<div/>}
                        </div>


                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    disabled={this.state.password?.length == 0 || this.state.password?.length < 6 || this.state.repassword !== this.state.password }
                                    onClick={() => this.reset()}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


// export default withSnackbar(modalLockPlan);
export  default  modalResetPassUser
