import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import AdminServicePlanApi from "../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";

class modalCreateAdvanceServicePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: true,
            id: this.props.data ? this.props.data.id : '',
            typeAction: this.props.typeAction ? this.props.typeAction : 'create',
            typeService: this.props.data ? this.props.data.type : '',
            name: this.props.data ? this.props.data.servicePlanName : '',
            description: this.props.data ? this.props.data.content : '',
        };
    }

    onSubmit = () => {
        let {name, description, typeAction} = this.state;
        if (typeAction === 'create') {
            let payload = {
                content: description,
                priceType: 'NEGOTIATE',
                status: 'ACTIVE',
                servicePlanName: name,
                type: 'ADVANCED'
            };
            AdminServicePlanApi.createAdvance(payload, res => {
                if (res.success) {
                    toast.success(`Thêm mới gói dịch vụ nâng cao "${name}" thành công !`)
                    this.props.onRefresh();
                    this.props.hideCreate();
                } else {
                    toast.error(`Thêm mới gói dịch vụ nâng cao "${name}" thất bại !`)
                }
            })
        } else {
            let payload = {
                id: 1,
                body: {
                    content: description,
                    id: this.state.id,
                    servicePlanName: name,
                }
            };
            AdminServicePlanApi.updateAdvanced(payload, res => {
                if (res.success) {
                    toast.success('Cập nhật thông tin gói dịch vụ thành công !')
                    this.props.onRefresh();
                    this.props.hideCreate();
                } else {
                    toast.error('Thao tác cập nhật thất bại. Vui lòng thử lại sau !')
                }
            })
        }

    }

    render() {
        const modalProp = {
            show: true,
            // onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-small">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={() => {
                                // this.props.onHide()
                                this.props.hideCreate()
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}>
                                <b>{this.props.typeAction === 'edit' ? 'CHỈNH SỬA GÓI DỊCH VỤ NÂNG CAO' : 'THÊM MỚI GÓI DỊCH VỤ NÂNG CAO'}</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0">Tên dịch vụ</label>
                                    <input
                                        maxLength={100} type="text"
                                        className="form-control shadow-none"
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({
                                                name: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0">Mô tả dịch vụ</label>
                                    {/*<input*/}
                                    {/*    maxLength={1000} type="text"*/}
                                    {/*    className="form-control shadow-none"*/}
                                    {/*    value={this.state.description}*/}
                                    {/*    onChange={(event) => {*/}
                                    {/*        this.setState({*/}
                                    {/*            description: event.target.value*/}
                                    {/*        })*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <div className="form-control w-100 p-0">
                                        <textarea
                                            value={this.state.description}
                                            rows={5}
                                            className="form-control border-0 shadow-none"
                                            style={{resize: 'none'}}
                                            maxLength={1000}
                                            onChange={(e) => {
                                                this.setState({description: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    disabled={this.state.name.length === 0 || this.state.description.length === 0}
                                    onClick={() => this.onSubmit()}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Đồng ý
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={() => {
                                        this.props.onHide()
                                        this.props.hideCreate()
                                    }}
                                >
                                    <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                    </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default modalCreateAdvanceServicePlan
