import React from "react";
import {Modal} from "react-bootstrap";
import Link from "../Link";
import {forceLogout} from "../../utils/authority";
import NumberFormat from "react-number-format";
import moment from 'moment';
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
class ModalLockAccount extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            contractInfo: props.contractInfo
        }
        console.log( "xxxxxxxxxxx");
        console.log( this.state.contractInfo);
    }
    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div className="modal-container">
                            <h1 className="text-center">THÔNG BÁO</h1>
                            <div className="mt-4">
                                <div className='row kt-margin-b-20'>
                                    {this.state.contractInfo.length > 0 &&
                                    <div>
                                        <div>
                                            <div style={{fontSize: 20, padding: 8}}>THÔNG TIN HỢP ĐỒNG</div>
                                            <div style={{fontSize: 15}}>
                                                <div className='row'>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Mã hợp đồng </label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        {/*<span style={{fontWeight: "bold"}}> {listData.customerName}</span>*/}
                                                        <span
                                                            style={{fontWeight: "bold"}}> {this.state.contractInfo[0].contractCode}</span>
                                                    </div>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Giá trị hợp đồng</label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        <NumberFormat
                                                            style={{fontWeight: "bold"}}
                                                            value={this.state.contractInfo[0].total}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            suffix={" VND"}
                                                        />
                                                    </div>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Đã thanh toán</label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        <NumberFormat
                                                            style={{fontWeight: "bold"}}
                                                            value={this.state.contractInfo[0].totalPaid}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            suffix={" VND"}
                                                        />
                                                    </div>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Còn nợ</label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        <NumberFormat
                                                            style={{fontWeight: "bold"}}
                                                            value={this.state.contractInfo[0].totalUnpaid}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            suffix={" VND"}
                                                        />
                                                    </div>

                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Ngày bắt đầu</label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                    <span
                                                        style={{fontWeight: "bold"}}>{moment(this.state.contractInfo[0].startDate).format("DD-MM-YYYY")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontSize: 15}}>
                                                <div className='row'>
                                                    <div style={{fontSize: 20, padding: 8}}>HẠN THANH TOÁN</div>
                                                    <Stepper
                                                        alternativeLabel
                                                        className="d-flex col-10 justify-content-center"
                                                        activeStep="PAYMENT_1_TIMES"
                                                    >
                                                        {this.state.contractInfo.map(row => (
                                                            <Step style={{width: 0}}>
                                                                <StepLabel className="d-flex">
                                                                    <NumberFormat
                                                                        value={row.paid}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        suffix={" VND"}
                                                                    />
                                                                    {row.paymentReal != null?
                                                                        <div>{moment(row.paymentReal).format("DD-MM-YYYY")}</div>
                                                                        :
                                                                        <div style={{color: "red"}}>
                                                                            <div>{moment(row.date).format("DD-MM-YYYY")}</div>
                                                                        </div>
                                                                    }

                                                                </StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{fontSize: 20, padding: 8}}>THÔNG TIN THANH TOÁN</div>
                                            <div style={{fontSize: 15}}>
                                                <div className='row'>

                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Số tài khoản </label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        {/*<span style={{fontWeight: "bold"}}> {listData.customerName}</span>*/}
                                                        <span
                                                            style={{fontWeight: "bold"}}> 09123659201</span>
                                                    </div>

                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Ngân hàng  </label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                        <span
                                                            style={{fontWeight: "bold"}}>Ngân hàng TMCP Tiên Phong</span>
                                                    </div>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Chi nhánh </label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                       <span
                                                           style={{fontWeight: "bold"}}>CN Thăng Long</span>
                                                    </div>
                                                    <div
                                                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                        <label className="text-black">Tổng đài hỗ trợ </label>
                                                    </div>
                                                    <div
                                                        className="pl-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                                       <span
                                                           style={{fontWeight: "bold"}}>1900000001</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <h5 className="line-height-30">Tài khoản sẽ được mở ngay sau khi thanh toán hợp đồng.<br/> Bạn vui lòng thanh toán các hợp đồng còn nợ để tiếp tục
                                        sử dụng hệ thống.</h5>
                                </div>
                            </div>
                            <div container item xs={12}>
                                <div item xs={12}>
                                    <div className="align-center mt-3 mb-2">
                                        <button
                                            type="button"
                                            className="border-1-s w-50 type-button-fix btn btn-warning"
                                            onClick={() => {
                                                forceLogout();
                                                window.location.reload()
                                            }}
                                        >
                                                <span className='d-flex justify-content-center'>
                                                    Đăng xuất
                                                </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalLockAccount
