import {stringify} from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";


export const DOCUMENT_TYPES = [
    {
        "title": "Hóa đơn",
        "key": "BILL",
        "value": 0
    },
    {
        "title": "Phiếu thu",
        "key": "RECEIPT_VOUCHER",
        "value": 1
    },
    {
        "title": "Phiếu chi",
        "key": "PAYMENT_VOUCHER",
        "value": 2
    }
];

let DocumentApi = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/document/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    findByContractId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/document/get-file-url/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getListbyContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/document/get-list-by-contract?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    validDocument: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/document/valid-document/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    invalidDocument: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/document/invalid-document`,
            method: 'POST',
            body: payload
        }, callback)
    },
}
export default DocumentApi
