import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicServicePlan = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-plan/get-all`,
            method: 'GET'
        }, callback)
    },
    getByType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-plan/get-by-type?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
}
export default PublicServicePlan
