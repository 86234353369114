import React, {Component} from "react";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

import DatePicker from "react-datepicker";
import '../../../assets/css/Supervisor.css';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'

import {
    Grid,
    Button,
    Radio,
    CircularProgress, CardContent,
} from '@material-ui/core';

import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import AddAccountant from "../../../components/Modal/modalAddAccountant";
import UploadFile from "../../../components/Modal/modalUploadFile";
import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar"
import ModalConfirmDeleteCert from "../../../components/Modal/modalConfirmDeleteCert";
import Utils from "../../../utils/utils";
// import ModalCropImage from "../../../components/Modal/ModalCropImage"

import PartnerApi from "../../../services/ob-user/partner";
import SupervisorApi from "../../../services/ob-user/supervisor";
import {toast} from "react-toastify";

const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


const renderSex = function (value) {
    switch (value) {
        case '1':
            return 'MALE';
        case '0':
            return 'Nhỏ';
        default:
            return 'Siêu nhỏ';
    }
};


// const renderBusiness = function (business) {
//     switch (business) {
//         case 'COMMERCE_SERVICE':
//             return 'Thương mại - Dịch vụ';
//         case 'MANUFACTURING_MACHINING':
//             return 'Sản xuất - Gia công';
//         default:
//             return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
//     }
// };

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};
const getinfoUser = function(){
    let data = JSON.parse(localStorage.getItem('ob_cl_userInfo'))
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

const scale = [
    {
        value: "MEDIUM",
        name: "Vừa",
    },
    {
        value: "EXTRASMALL",
        name: "Siêu nhỏ",
    },
    {
        value: "SMALL",
        name: "Nhỏ",
    },

]


class CompleteProfilePartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            onEdit: false,
            inEditing: false,
            showUpload: false,
            dataSupervisor: {},
            listProvince: [],
            listDistrict: [],
            files: [],
            filesCertificate: [],
            modalConfirmDeleteCert: false,
            fileId: null,

            address: '',
            provinceId: 0,
            districtId: 0,
            taxcode: '',
            phone: '',
            email: '',
            website:'',

            fullname:'',
            gender: '',
            identify: '',
            dateIssue: '',
            placeIssue: '',
            position:'',
            emailinfo:'',

            name: '',
            modalUploadAvatar: false,
            update: false,

            provinceErr: false,
            districtErr: false,
            addressErr: false,
            taxcodeErr: false,
            phoneErr: false,
            emailErr: false,
            websiteErr:false,
            fullnameErr:false,
            identifyErr: false,
            dateIssueErr:false,
            placeIssueErr: false,
            positionErr:false,
            emailinfoErr:false,

            showConfirmModal: false,


            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Bằng cấp chứng chỉ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File bằng cấp chứng chỉ",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        // this.getSupervisorInfo();
        this.getInfoUser();
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
    }

    getInfoUser (){
        let data = JSON.parse(localStorage.getItem('ob_cl_userInfo'));
        console.log('CHECKKKK', data);
        this.setState({data},() =>{
            this.setState({emailinfo: data.representativeEmail})
        });
    };

    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }

    getSupervisorInfo() {
        SupervisorApi.detailSupervisor(res => {
            if (res.success === true) {
                this.setState({
                        dataSupervisor: res.data
                    },
                    () => {
                        this.setState({
                            address: this.state.dataSupervisor.address,
                            provinceId: this.state.dataSupervisor.provinceId,
                            districtId: this.state.dataSupervisor.districtId,
                            gender: this.state.dataSupervisor.sex,
                            identifycode: this.state.dataSupervisor.identify,
                            dateIssue: this.state.dataSupervisor.dateIssued,
                            placeIssue: this.state.dataSupervisor.placeIssued,
                            placeSupportId: this.state.dataSupervisor.areaId,
                            job: this.state.dataSupervisor.job,
                            expyear: this.state.dataSupervisor.experience,
                            name: this.state.dataSupervisor.fullName,
                            email: this.state.dataSupervisor.email,
                        })
                    }
                )
            } else {

            }
        })
    };

    handleChangeTax(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({taxError: false}) : this.setState({taxError: true});
                this.state.tax.length >= 10 ? this.setState({taxError: false}) : this.setState({taxError: true})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangeexpyear(val) {
        this.setState({inEditing: true});
        const onlyNums = val.target.value.replace(/[^0-9]/g, '').trim();
        this.setState({expyear: onlyNums})
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({identifyError: false}) : this.setState({identifyError: true});
                this.state.identify.length >= 9 ? this.setState({identifyError: false}) : this.setState({identifyError: true})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        let userInfo  = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        this.setState({userStatus: userInfo.status})
        document.title = 'Hoàn thiện thông tin cá nhân - OneBook';
    }

    handleSave() {
        let {
            address,
            provinceId,
            districtId,
            taxcode,
            phone,
            email,
            website,
            fullname,
            gender,
            identify,
            dateIssue,
            placeIssue,
            position,
            emailinfo,
        } = this.state;
        if (address === '') {
            this.setState({addressErr: true})
        }
        if (phone === '') {
            this.setState({phoneErr: true})
        }
        if (website === '') {
            this.setState({websiteErr: true})
        }
        if (position === '') {
            this.setState({positionErr: true})
        }
        if (emailinfo === '') {
            this.setState({emailinfoErr: true})
        }
        if (identify === '') {
            this.setState({identifyErr: true})
        }
        if (email === '') {
            this.setState({emailErr: true})
        }
        if (fullname === '') {
            this.setState({fullnameErr: true})
        }
        if (placeIssue === '') {
            this.setState({placeIssueErr: true})
        }
        if (dateIssue === '') {
            this.setState({dateIssueErr: true})
        }
        if (gender === '') {
            this.setState({genderErr: true})
        }
        if (provinceId === 0 || provinceId === '0') {
            this.setState({provinceErr: true})
        }
        if (districtId === 0 || districtId === undefined  ) {
            this.setState({districtErr: true})
        }if (taxcode === '') {
            this.setState({taxcodeErr: true})
        } else {
            let payload = {
                address: address,
                dateIssued: dateIssue,
                districtId: districtId,
                email: email,
                identify: identify,
                job: "chưa có dữ liệu",
                phone: phone,
                placeIssued: placeIssue,
                position: position,
                provinceId: provinceId,
                representative: fullname,
                representativeEmail: emailinfo,
                sex: gender,
                tax: taxcode,
                // urlImage: "string",
                website: website
            };
            // console.log('payload lưu: ', payload);
            this.setState({loading: true}, () => {
                let timer = setInterval(() => {
                    this.tick()
                }, 10);
                setTimeout(() => {
                    clearInterval(timer);
                    this.setState({
                        loading: false,
                    }, () => {
                        PartnerApi.updatePartner(payload, res => {
                            if (res.success) {
                                this.setState({update:true}, () => {
                                    toast.success("Thông tin đã được cập nhật thành công !")
                                });
                            } else {
                                // console.log('err: ', res);
                                toast.error("Cập nhật không thành công, vui lòng thử lại sau !")
                            }
                        })
                    });
                }, 3000);
            });
        }
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleReset() {
        this.setState({
            address: '',
            provinceId: 0,
            districtId: 0,
            taxcode: '',
            phone: '',
            email: '',
            website:'',

            fullname:'',
            gender: '',
            identify: '',
            dateIssue: '',
            placeIssue: '',
            position:'',
            emailinfo:'',
        })
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    onChangeDistrict = (e) => {
        this.setState({
            districtId: e.target.value,
            districtErr:false,
            inEditing: true
        })
    };

    modalUploadAvatarClose = () => {
        this.setState({modalUploadAvatar: false});
    }
    modalCropClose = () => {
        this.setState({showModalCrop: false});
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }

    onDeleteCert = (item) => {
        this.setState({fileId: item.id, modalConfirmDeleteCert: true})
    }

    render() {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataSupervisor, tax, placeIssued, dateIssued, sex, provinceId, districtId} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="profile"/>*/}
                        {this.state.update || this.state.userStatus == "WAIT_CONFIRM_EMAIL"?
                            <div className="tab-content col-12 min-w100">
                                <div variant="outlined" className='mb-4 p-2 card border-color'>
                                        <div
                                            className='kt-margin-b-20 flex-column justify-content-center align-center p-3'
                                            style={{background: '#F1CDCB'}}>
                                            <h6 className="line-height-30">ONEBOOK đã gửi một email xác thực thông tin
                                                đến hòm thư của bạn. Bạn hãy truy cập vào Email để xác nhận thông tin và
                                                tiếp tục sử dụng hệ thống.</h6>
                                            <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                                        </div>
                                </div>
                            </div>:
                            <div className="tab-content">
                            <div variant="outlined" className='mb-4 p-2 card border-color'>
                                {/*<CardContent>*/}
                                {/*    <div className='d-flex justify-content-center col-12 mt-3' style={{height: 120}} >*/}
                                {/*        <span className='size-text-header'>*/}
                                {/*            {this.state.onEdit ? 'Chỉnh sửa thông tin cá nhân' : 'Thông tin cá nhân'}*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*//**********************************************Thong tin doanh nghiep****************************************************************************************************/}
                                <div className='d-flex justify-content-start col-12 mt-3' style={{height: 120}}>
                                    <div className='col-4 d-block ml-2'>
                                        <span className='text-header-profile'>Thông tin doanh nghiệp</span>
                                        <div>
                                            <label style={{color: 'red', fontSize: 13}}>Thông tin cá nhân sẽ không bị công
                                                khai</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <img
                                            src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}
                                            // src={data.urlImagePartner ? data.urlImagePartner : "http://ecorau.com/images/logo_mail.png"}
                                            className='mh-100 mw-100 m-2'
                                        />
                                    </div>
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tên doanh nghiệp</span>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {this.state.data.fullName}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mã số thuế
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                                <input type="text"
                                                       className={this.state.taxcodeErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập mã số thuế"
                                                       value={this.state.taxcode}
                                                    // aria-label="Username"
                                                       maxLength={14}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           taxcode: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.taxcode.length === 0 || this.state.taxcode.length < 10 ? this.setState({
                                                               taxcodeErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               taxcodeErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />

                                            {this.state.taxcodeErr?<span className="pl-1 color-red size-font-err-date">Mã số thuế đang để trống hoặc sai định dạng !</span>:<div/>}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <span className="text-black-50">
                                                    Số điện thoại
                                                <span className={'color-red d-inline'}>*</span>
                                                </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.phoneErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập SĐT"
                                                       value={this.state.phone}
                                                    // aria-label="Username"
                                                       maxLength={11}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           phone: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.phone.length === 0 || this.state.phone.length < 10 ? this.setState({
                                                               phoneErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               phoneErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                            {this.state.phoneErr?<span className="pl-1 color-red size-font-err-date">SĐT đang để trống hoặc sai định dạng !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email
                                            <span className={'color-red d-inline'}>*</span> </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.emailErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập email"
                                                       value={this.state.email}
                                                    // aria-label="Username"
                                                       maxLength={100}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => {
                                                               if (Utils.checkEmailRegex(val.target.value.trim())) {
                                                                   this.setState({
                                                                       email: val.target.value.trim(),
                                                                       emailErr: true,
                                                                       btnSaveStatus: true
                                                                   })
                                                               }else{
                                                                   this.setState({
                                                                       email: val.target.value.trim(),
                                                                       emailErr: false,
                                                                       inEditing: true,
                                                                       btnSaveStatus: false
                                                                   })
                                                               }
                                                            }
                                                       }
                                                />
                                            {this.state.emailErr?<span className="pl-1 color-red size-font-err-date">Email đang để trống hoặc sai định dạng !</span>:<div/>}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <select
                                                    className= {this.state.provinceErr ?"form-control border-input-error kt-input pd-4-input hei-input":"form-control kt-input pd-4-input hei-input"}
                                                    value={provinceId}
                                                    defaultValue={0}
                                                    onChange={(e) => {
                                                        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                        let _disfilter = _district?.filter(item => item.provinceId == e.target.value);
                                                        this.setState({
                                                            provinceId: e.target.value,
                                                            provinceErr:false,
                                                            districtErr:false,
                                                            districtId: _disfilter[0]?.id,
                                                            inEditing: true
                                                        })
                                                    }}
                                                    // onSelect={() => this.onChangeDistrict}
                                                >
                                                    <option value={0}>Chọn Tỉnh/Thành Phố</option>
                                                    {renderProvince()}
                                                </select>
                                            {this.state.provinceErr ?<span className="pl-1 color-red size-font-err-date">Bạn chưa chọn Tỉnh/Thành phố !</span>:<div/>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <select
                                                    className={this.state.districtErr?"form-control border-input-error kt-input pd-4-input hei-input":"form-control kt-input pd-4-input hei-input"}
                                                    value={districtId}
                                                    onChange={this.onChangeDistrict}
                                                >
                                                    {this.renderDistrict(provinceId)}
                                                </select>
                                            {this.state.districtErr ?<span className="pl-1 color-red size-font-err-date">Bạn chưa chọn Quận/huyện !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.addressErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập địa chỉ"
                                                       value={address}
                                                       maxLength={100}
                                                    // aria-label="Username"
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           address: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.address.length === 0 ? this.setState({
                                                               addressErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               addressErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                            {this.state.addressErr ?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập địa chỉ !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Website
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.websiteErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập tên website"
                                                       value={this.state.website}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => {
                                                           if (Utils.checkWebsiteRegex(val.target.value.trim())) {
                                                               this.setState({
                                                                   website: val.target.value.trim(),
                                                                   websiteErr: true,
                                                                   btnSaveStatus: true
                                                               })
                                                           }else{
                                                               this.setState({
                                                                   website: val.target.value.trim(),
                                                                   websiteErr: false,
                                                                   inEditing: true,
                                                                   btnSaveStatus: false
                                                               })
                                                           }
                                                       }
                                                       }
                                                />
                                            {this.state.websiteErr ?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập địa chỉ website !</span>:<div/>}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/********************************Thong tin ca nhan****************************************************/}
                            <div variant="outlined" className='mb-4 p-2 card border-color'>
                                <div className='d-flex justify-content-center col-12 mt-3 kt-margin-b-20'>
                                    <div className='col-4 d-flex ml-2'>
                                        <span className='text-header-profile'>Thông tin cá nhân</span>
                                    </div>
                                    <div className='col-4'>

                                    </div>
                                    <div className='col-4'>

                                    </div>
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Họ và tên
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.fullnameErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nhập họ và tên'
                                                       value={this.state.fullname}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           fullname: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.fullname.length == 0 ? this.setState({
                                                               fullnameErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({fullnameErr: false, btnSaveStatus: false})
                                                       })}
                                                />
                                            {this.state.fullnameErr?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập tên !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <div className='d-flex col-12 '>
                                                    <div className="form-check col-6 ">
                                                        <input className="form-check-input" type="radio"
                                                               name="femaleRadios" id="female" value="FEMALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       gender: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.gender === 'FEMALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nữ
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-6">
                                                        <input className="form-check-input" type="radio"
                                                               name="maleRadios" id="male" value="MALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       gender: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.gender === 'MALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nam
                                                        </label>
                                                    </div>
                                                </div>

                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.identifyErr ? "form-control  border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập số CMND/CCCD"
                                                       value={this.state.identify}
                                                    // aria-label="Username"
                                                       maxLength={12}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           identify: val.target.value.trim().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.identify.length === 0 || this.state.identify.length < 9 ? this.setState({
                                                               identifyErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               identifyErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                            {this.state.identifyErr?<span className="pl-1 color-red size-font-err-date">Số CMND sai hoặc đang để trống !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-sm-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <DatePicker
                                                    className={this.state.dateIssueErr?"w-auto border-input-error  react-datepicker-wrapper kt-input pd-4-input hei-input":"w-auto react-datepicker-wrapper kt-input pd-4-input hei-input"}
                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                    selected={this.state.dateIssue?new Date(this.state.dateIssue):null}
                                                    // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                    maxDate={new Date()}
                                                    // value={this.state.dateIssue}
                                                    dateFormat="dd-MM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={
                                                        (date) => {
                                                            let value = moment(date).format("YYYY-MM-DD").toString();
                                                            try {
                                                                this.setState({
                                                                    dateIssue: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                    dateIssueErr:false
                                                                })
                                                            }catch (e) {

                                                            }

                                                        }
                                                    }
                                                />
                                            {this.state.dateIssueErr?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập ngày cấp !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.placeIssueErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập nơi cấp"
                                                       value={this.state.placeIssue}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           placeIssue: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.placeIssue.length === 0 ? this.setState({
                                                               placeIssueErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               placeIssueErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                            {this.state.placeIssueErr?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập nơi cấp !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Chức vụ
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">

                                                <input type="text"
                                                       className={this.state.positionErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập chức vụ"
                                                       value={this.state.position}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           position: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.position.length === 0 ? this.setState({
                                                               positionErr: true,
                                                               btnSaveStatus: true
                                                           }) : this.setState({
                                                               positionErr: false,
                                                               btnSaveStatus: false
                                                           })
                                                       })}
                                                />
                                            {this.state.positionErr?<span className="pl-1 color-red size-font-err-date">Bạn chưa nhập vị trí !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">
                                                Số điện thoại
                                            </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ?"pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile":"pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {profile.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email
                                            <span className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className='pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile'>

                                                <input type="text"
                                                       className={this.state.emailinfoErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập email"
                                                       value={this.state.emailinfo}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) =>{
                                                           if (Utils.checkEmailRegex(val.target.value.trim())) {
                                                               this.setState({
                                                                   emailinfo: val.target.value.trim(),
                                                                   emailinfoErr: true,
                                                                   btnSaveStatus: true
                                                               })
                                                           }else{
                                                               this.setState({
                                                                   emailinfo: val.target.value.trim(),
                                                                   emailinfoErr: false,
                                                                   inEditing: true,
                                                                   btnSaveStatus: false
                                                               })
                                                           }
                                                       }
                                                       }
                                                />
                                            {this.state.emailinfoErr?<span className="pl-1 color-red size-font-err-date">Email đang để trống hoặc sai định dạng !</span>:<div/>}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>

                                <Grid container spacing={2} justify={"flex-center"}>
                                    <Grid item xs={12} sm={12} md={12} className='text-center'>
                                        {this.state.loading &&
                                        <Button variant="outlined" color="primary"
                                                className='mr-3'
                                                style={{textTransform: 'initial'}}
                                        >
                                            <CircularProgress size={20} variant="determinate"
                                                              value={this.state.progress}/>
                                        </Button>}
                                        {!this.state.loading &&
                                        <Button disabled={this.state.btnSaveStatus} variant="contained" color="primary"
                                                className='mr-3'
                                                style={{textTransform: 'initial'}}
                                                onClick={() => this.handleSave()}
                                        >
                                            Lưu
                                        </Button>}
                                        <Button variant="outlined" style={{textTransform: 'initial'}}
                                                onClick={() => this.handleReset()}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>

                                {/*</CardContent>*/}
                            </div>

                            {/*********************************************************************************************************************************??*/}
                            {/*<div variant="outlined" className='p-2 mb-5 card border-color'>*/}
                            {/*    /!*<CardContent>*!/*/}
                            {/*    <div className='d-flex justify-content-center col-12 mt-3' style={{height: 120}}>*/}
                            {/*        <div className='col-8 d-flex ml-2'>*/}
                            {/*            <span className='text-header-profile'>Hồ sơ cá nhân</span>*/}
                            {/*        </div>*/}
                            {/*        <div className='col-4'>*/}
                            {/*            <div className='d-flex justify-content-end'>*/}
                            {/*                /!*<Dropzone*!/*/}
                            {/*                /!*    getUploadParams={() => ({url: 'https://httpbin.org/post'})} // test*!/*/}
                            {/*                /!*    accept="image/*"*!/*/}
                            {/*                /!*    onDrop={acceptedFiles => {*!/*/}
                            {/*                /!*        console.log(acceptedFiles)*!/*/}
                            {/*                /!*        this.setState({*!/*/}
                            {/*                /!*            filesCertificate: acceptedFiles.map(file => Object.assign(file, {*!/*/}
                            {/*                /!*                preview: URL.createObjectURL(file)*!/*/}
                            {/*                /!*            }))*!/*/}
                            {/*                /!*        })*!/*/}
                            {/*                /!*    }*!/*/}
                            {/*                /!*    }*!/*/}
                            {/*                /!*>*!/*/}
                            {/*                /!*    {({getRootProps, getInputProps}) => (*!/*/}
                            {/*                /!*        <span title='Upload file'>*!/*/}
                            {/*                /!*            <span {...getRootProps()}>*!/*/}
                            {/*                /!*                <input {...getInputProps()} />*!/*/}
                            {/*                <button className="btn btn-primary" onClick={() => this.setState({showUpload: true})}>*/}
                            {/*                    Upload*/}
                            {/*                </button>*/}
                            {/*                /!*            </span>*!/*/}
                            {/*                /!*        </span>*!/*/}
                            {/*                /!*    )}*!/*/}
                            {/*                /!*</Dropzone>*!/*/}

                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">*/}
                            {/*        <thead>*/}
                            {/*        <TableHeader header={this.state.header} clickSort={(data) => {*/}
                            {/*        }}/>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        {this.state.filesCertificate.length !== 0*/}
                            {/*            ? this.state.filesCertificate.map((item, index) =>*/}
                            {/*                <tr>*/}
                            {/*                    <th scope="row" className='text-center'>1</th>*/}
                            {/*                    <td className='text-center'>{item.name}</td>*/}
                            {/*                    <td className='text-center'>{item.name}</td>*/}
                            {/*                    <td className='text-center'>*/}
                            {/*                        <div>*/}
                            {/*                            <button className="btn" title="Xóa chứng chỉ"*/}
                            {/*                                    onClick={() => this.onDeleteCert(item)}>*/}
                            {/*                                <i className='ti-trash'/>*/}
                            {/*                            </button>*/}
                            {/*                        </div>*/}
                            {/*                    </td>*/}
                            {/*                </tr>*/}
                            {/*            )*/}

                            {/*            : <tr>*/}
                            {/*                <td colSpan={4}><div><span><h6>Không có dữ liệu</h6></span></div></td>*/}

                            {/*                /!*<th scope="row" className='text-center'>1</th>*!/*/}
                            {/*                /!*<td className='text-center'>Chứng chỉ thuế</td>*!/*/}
                            {/*                /!*<td className='text-center'>anh</td>*!/*/}
                            {/*                /!*<td className='text-center'>*!/*/}
                            {/*                /!*    <div>*!/*/}
                            {/*                /!*        <button className="btn" title="Xóa chứng chỉ"*!/*/}
                            {/*                /!*                onClick={this.onDeleteCert}>*!/*/}
                            {/*                /!*            <i className='ti-trash'/>*!/*/}
                            {/*                /!*        </button>*!/*/}
                            {/*                /!*    </div>*!/*/}
                            {/*                /!*</td>*!/*/}
                            {/*            </tr>*/}

                            {/*        }*/}

                            {/*        </tbody>*/}
                            {/*    </table>*/}


                            {/*    /!*{this.state.confirmCancel &&*!/*/}
                            {/*    /!*<Dialog*!/*/}
                            {/*    /!*    open={true}*!/*/}
                            {/*    /!*    onClose={() => this.setState({confirmCancel: false})}*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*    <DialogTitle>Thông tin đã thay đổi, bạn có chắc muốn hủy ?</DialogTitle>*!/*/}
                            {/*    /!*    <DialogActions>*!/*/}
                            {/*    /!*        <Button*!/*/}
                            {/*    /!*            style={{textTransform: 'initial'}}*!/*/}
                            {/*    /!*            color="primary"*!/*/}
                            {/*    /!*            onClick={() => this.setState({*!/*/}
                            {/*    /!*                confirmCancel: false,*!/*/}
                            {/*    /!*                inEditing: false*!/*/}
                            {/*    /!*            }, () => this.handleCancel())}*!/*/}
                            {/*    /!*        >*!/*/}
                            {/*    /!*            Có*!/*/}
                            {/*    /!*        </Button>*!/*/}
                            {/*    /!*        <Button*!/*/}
                            {/*    /!*            color="primary"*!/*/}
                            {/*    /!*            style={{textTransform: 'initial'}}*!/*/}
                            {/*    /!*            onClick={() => this.setState({confirmCancel: false})}*!/*/}
                            {/*    /!*        >*!/*/}
                            {/*    /!*            Không*!/*/}
                            {/*    /!*        </Button>*!/*/}
                            {/*    /!*    </DialogActions>*!/*/}
                            {/*    /!*</Dialog>}*!/*/}
                            {/*    /!*</CardContent>*!/*/}
                            {/*</div>*/}
                            <ModalChangePassword show={this.state.showModalChangePassword}
                                                 onHideModal={this.onCloseModalForgotPassword}/>
                            <ModalConfirmEdit show={this.state.confirmCancel}
                                              onHide={() => this.setState({confirmCancel: false})}
                                              handleCancel={
                                                  () => this.setState({
                                                      confirmCancel: false,
                                                      inEditing: false
                                                  }, () => this.handleCancel())
                                              }
                            />
                            {this.state.showUpload && <UploadFile show={this.state.showUpload}
                                                                  onHide={() => this.setState({showUpload: false})}/>}
                        </div>}
                    </div>
                </div>
                <ModalUploadAvatar show={this.state.modalUploadAvatar} onHide={this.modalUploadAvatarClose}/>
                <ModalConfirmDeleteCert show={this.state.modalConfirmDeleteCert}
                                        onHide={() => this.setState({modalConfirmDeleteCert: false})}
                                        fileId={this.state.fileId}/>
                {/*<ModalCropImage show={this.state.showModalCrop} onHide={this.modalCropClose}*/}
                {/*                   customerId={1}/>*/}
            </div>
        );
    }
}

export default CompleteProfilePartner
