import React, { Component } from "react";
import { useDropzone } from 'react-dropzone'
import { Link } from "react-router-dom";
import moment from 'moment';

import Dropzone from 'react-dropzone';
import TableHeader from "../../../../components/TableHeader";
import NumberFormat from "react-number-format";
import lang from "../../contracts-management/lang";
import Pagination from "../../../../components/Pagination";

import DocumentApi from "../../../../services/ob-core/document";
import UploadContractDoc from "../../../../components/Modal/modalUploadContractDoc";
import { toast } from "react-toastify";
import PublicContract from "../../../../services/ob-core/public-contract";
import FilesApi from "../../../../services/resource/files";
import { saveAs } from 'file-saver';
import ModalImportFileToContract from "../../../../components/Modal/modalImportFileToContract";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import { checkPermission } from "../../../../utils/common";

const formatDate = 'DD-MM-YYYY';

class ListData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_temp: '',
      type_temp_search: '',
      status_temp_search: "",
      status_temp: "",
      assignStatus: "",
      modalUploadFile: false,
      pageNumber: 0,
      pageSize: 10,
      totalItem: 0,
      listData: [],
      header: [
        {
          id: '123',
          name: "STT",
          style: { textAlign: "center" },
          sort: false,
        },
        {
          id: '123',
          name: "Thời gian",
          style: { minWidth: 120, textAlign: "center" },
          sort: true,
        },
        {
          id: '123',
          name: "Tên chứng từ",
          style: { minWidth: 150, textAlign: "center" },
          sort: false,
        },
        {
          id: '123',
          name: "Loại chứng từ",
          style: { minWidth: 150, textAlign: "center" },
          sort: false,
        },
        {
          id: '123',
          name: "Trạng thái",
          style: { minWidth: 130, textAlign: "center" },
          sort: false,
        },
        {
          id: '',
          name: "Thao tác",
          style: { minWidth: 100, textAlign: "center" },
          sort: false,
        }

      ],
    }
  }


  getListDataContractFromAPI() {
    let { contractId, pageNumber, pageSize, sort, status, type } = this.state;
    let payload = {
      contractId: this.props.value,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sort: sort,
      status: status,
      type: type
      // customerId:customer
    };
    DocumentApi.getListbyContract(payload, res => {
      if (res.success) {
        this.setState({
          listData: res.data.content,
          totalItem: res.data.totalElements,
          // totalPage: Math.round(res.data.totalElements / this.state.pageSize)

        });
      } else {

      }
    });
  }


  onhandleSearch = () => {
    let { type_temp, status_temp } = this.state;

    this.setState(
      {
        type: type_temp,
        status: status_temp,
        pageNumber: 0
      },
      () => {
        this.getListDataContractFromAPI();
      }
    );
  };

  onHandleClearText = () => {
    this.setState({
      type_temp: '',
      status_temp: '',
    })
  }

  getStatusAssign() {
    let myDate = new Date();
    let payload = {
      id: this.props.value
    };
    PublicContractApi.getMoreDetail(payload, res => {
      if (res.success) {
        this.setState({
          assignStatus: res.data.assignStatus
        }, () => console.log("check>>", this.state.assignStatus));
      } else {
        toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
      }
    });
  }


  componentDidMount() {
    document.title = "Chi tiết hợp đồng"
    this.getStatusAssign()
    this.getListDataContractFromAPI();
    // this.onhandleSearch();
    // console.log("chẹucọadfkljákodjáod>>>>>", this.props.contractStatus)
  }

  renderStatus(status) {
    switch (status) {
      case "NOT_YET_PROCESS":
        return <h5><span className="badge badge-secondary">Chưa xử lý</span></h5>;
      case "VALID":
        return <h6><span className="badge badge-success">Hợp lệ</span></h6>;
      case "INVALID":
        return <h6><span className="badge badge-danger">Không hợp lệ</span></h6>;
      default:
        return '';
    }
  }

  renderTypePaper(type) {
    switch (type) {
      case "RECEIPT_VOUCHER":
        return 'Phiếu thu';
      case "PAYMENT_VOUCHER":
        return 'Phiếu chi';
      case "1":
        return 'Giấy báo nợ (Ủy nhiệm chi)';
      case "2":
        return 'Giấy báo có';
      case "3":
        return 'Phiếu kế toán';
      case "4":
        return 'Phiếu nhập kho';
      case "5":
        return 'Phiếu xuất kho';
      case "BILL":
        return 'Hóa đơn';
      default:
        return '';
    }
  }


  handleChangePage = (page) => {
    this.setState(
      {
        pageNumber: page - 1,
      },
      () => {
        this.getListDataContractFromAPI();
      }
    );
  }

  uploadSuccess = (data, fileName, fileType) => {
    let payload = {
      contractId: this.props.value,
      destination: data[0].destination,
      name: fileName,
      type: fileType,
      url: data[0].storageName,
      //
      // id: this.props.value,
      // body: {
      //   uploadedContractDocuments: [
      //     {
      //       contentType: data[0].contentType,
      //       destination: data[0].destination,
      //       name: fileName,
      //       storageName: data[0].storageName
      //     }
      //   ]
      // }
    }
    DocumentApi.create(payload, res => {
      if (res.success) {
        this.setState({ modalUploadFile: false }, () => {
          toast.success("Upload file thành công!")
          setTimeout(() => {
            this.getListDataContractFromAPI()
          }, 600);

        })
      } else {
        toast.error("Upload file không thành công, vui lòng thử lại!")
      }
    })
  }

  downloadFile = (item) => {
    let payload = {
      id: item.id
    }
    FilesApi.downloadFileDocument(payload, res => {
      if (res.success) {
          // console.log(res.data)
          let a = document.createElement("a");
          a.href = "data:application/octet-stream/png;base64," + res.data.content;
          a.download = `${this.state.selectedFile.name}.${res.data.extension}`
          a.click();
      }
  })
  }

  render() {
    let { rows, pageNumber, pageSize, totalItem, header, listData } = this.state;
    return (
      <div>
        {(this.props.contractStatus != "CLOSED" && this.props.contractStatus != "LIQUIDATION") &&
          <div className=' mb-2 pb-2'>
            {(checkPermission("upload_certification")) &&
              <button className="btn btn-primary mr-3"
                onClick={() => this.setState({ modalUploadFile: true })}
                disabled={this.state.assignStatus === "WAIT_SIGN"}
              >
                Upload chứng từ
                </button>
            }
            < button className="btn btn-primary mr-3"
              onClick={() => this.setState({ modalImportFileToContract: true })}
              disabled={this.state.assignStatus === "WAIT_SIGN"}>
              Import chứng từ
              </button>

          </div>}
        <div
          className='table'>
          <table className="table table-striped table-data table-bordered table-responsive">
            <table className='table'>
              <thead>
                <TableHeader
                  header={header}
                  clickSort={data => {
                    let a = "";
                    if (data.sortAsc == null) {

                    } else {
                      a = `${data.sortId}=${data.sortAsc ? "asc" : "desc"}`;
                    }
                    this.setState(
                      {
                        sort: a
                      },
                      () => this.getListDataContractFromAPI()
                    );
                  }}
                />
              </thead>
              {!this.state.listData || this.state.listData.length == 0
                ? <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className='text-center'>Không có dữ liệu</div>
                    </td>
                  </tr>
                </tbody>
                : <tbody>
                  {listData &&
                    listData.length > 0 &&
                    listData?.map((row, index) => (
                      <tr style={{ height: 50 }}>
                        <td style={{ textAlign: "center" }} scope="row">
                          {" "}
                          {this.state.pageNumber * this.state.pageSize +
                            index +
                            1}
                        </td>
                        <td style={{ textAlign: "center" }} scope="row">

                          {moment(row.cmnCreatedAt).format(formatDate)}
                          {/*</Link>*/}
                        </td>
                        <td style={{ textAlign: "center" }}>{row.name}</td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {this.renderTypePaper(row.type)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {this.renderStatus(row.status)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {/*<button*/}
                          {/*    className="btn btn-action"*/}
                          {/*    title="Xem chi tiết"*/}
                          {/*    // onClick={() => this.handleShowModalDetail(id, name)}*/}
                          {/*>*/}
                          {/*  <Link*/}
                          {/*      // onClick = {() =>  Utils.addOrUpdateUrlParam("contractId", row.id)}*/}
                          {/*      to={{*/}
                          {/*        pathname: "./contracts-detail",*/}
                          {/*        search: "?contractId",*/}
                          {/*        state: {*/}
                          {/*          fromDashboard: true,*/}
                          {/*          contractId: row.contractId*/}
                          {/*        }*/}
                          {/*      }}*/}
                          {/*      style={{color: "#000000"}}*/}
                          {/*  >*/}
                          {/*    <i className="ti-book"/>*/}
                          {/*  </Link>*/}

                          {/*</button>*/}
                          {(checkPermission("download_certification")) &&
                            <button
                              className="btn btn-action"
                              title="Tải xuống"
                              onClick={() => this.downloadFile(row)}
                            >
                              <i className="ti-download" />
                            </button>
                          }
                        </td>
                      </tr>
                    ))}
                </tbody>}
            </table>

          </table>
          <div style={{ height: 40 }}>
            <div className="pagination-right">
              <Pagination
                activePage={this.state.pageNumber + 1}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={this.state.totalItem}
                changeHandler={(page) => this.handleChangePage(page)}
              />
            </div>
          </div>

        </div>
        {this.state.modalUploadFile && <UploadContractDoc uploadSuccess={this.uploadSuccess} onClose={() => {}} onHide={() => { this.setState({ modalUploadFile: false }) }} />}
        {this.state.modalImportFileToContract && <ModalImportFileToContract onHide={() => { this.setState({ modalImportFileToContract: false }) }} />}
      </div>
    );
  }
}

export default ListData
