import React from 'react';
import {toast} from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import ModalDeleteSMS from "../../../components/Modal/admin/modalDeleteSMS";
import AdminSMSManagement from "../../../services/ob-core/admin-sms-management";
import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SMSTypeApi from "../../../services/ob-core/sms-type";

class SMSManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDelete: false,
            body: '',
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            itemSelected: {},
            listData: [],
            listTypeSMS: [],
            sort: "id",
            descending: 'DESC',
            type: '',
            startDate: '',
            endDate: '',
            startDate_temp: null,
            endDate_temp: null,
            showLoading: false,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                // {
                //     id: 'subject',
                //     name: "Chủ đề",
                //     style: {textAlign: "center"},
                //     sort: false,
                // },
                {
                    id: 'type',
                    name: "Loại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'toOne',
                    name: "Người nhận",
                    style: {textAlign: "center "},
                    sort: false,
                },
                {
                    id: 'sms',
                    name: "SMS",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'date',
                    name: "Thời gian",
                    style: {textAlign: "center"},
                    sort: false,
                },
                // {
                //     id: 'status',
                //     name: "Trạng thái",
                //     style: {textAlign: "center", minWidth: 80},
                //     sort: false,
                // },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center", minWidth: 100},
                    sort: false,
                },
            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: false}, () => {
            this.getData()
        })
    }

    getData = () => {
        let {pageNumber, pageSize, sort, startDate, endDate, descending} = this.state
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // sort: sort,
            // descending: descending,
            startDate: startDate,
            endDate: endDate,
            type: this.state.type
        }
        AdminSMSManagement.getList(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false,
                }, () => this.getAllTypeSMS())
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        })
    }

    getAllTypeSMS = () => {
        SMSTypeApi.getAllSMS(res => {
            if(res.success) {
                this.setState({
                    listTypeSMS: res.data
                })
            } else {
                toast.error("Đã có lỗi xảy ra khi lấy loại SMS!")
            }
        })
    }

    onSearch = () => {
        let {startDate_temp, endDate_temp, type} = this.state;
        this.setState({
            startDate: startDate_temp,
            endDate: endDate_temp,
            type: type,
            pageNumber: 0
        }, () => this.getData())
    }

    onRefresh = () => {
        this.setState(
            {
                // sort: "id",
                // descending: 'DESC',
                startDate_temp: null,
                endDate_temp: null,
                type: "",
                pageNumber: 0
            },
            () => this.onSearch()
        );
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getData()
            }
        );
    }

    renderType = (type) => {
        switch (type) {
            case 'CREATE_CUSTOMER_OTP':
                return 'Mã OTP tạo mới khách hàng';
            case 'RESET_PASSWORD_OTP':
                return 'Mã OTP reset mật khẩu';
            case 'GET_OTP':
                return 'Lấy Mã OTP';
            default:
                return '';
        }
    }

    render() {
        let {pageNumber, pageSize, totalItem, listTypeSMS} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="sms-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý SMS"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Từ ngày</label>
                                                <DatePicker
                                                    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Từ ngày"
                                                    selected={this.state.startDate_temp === null ? null : new Date(this.state.startDate_temp)}
                                                    maxDate={new Date()}
                                                    minDate={new Date('2000/01/01')}
                                                    dateFormat="dd-MM-yyyy"
                                                    isClearable={this.state.startDate_temp !== null}
                                                    // selectsStart
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => {
                                                        this.setState({
                                                            startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                        })
                                                    }}
                                                    startDate={new Date(this.state.startDate_temp)}
                                                    endDate={new Date(this.state.endDate_temp)}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Đến ngày</label>
                                                <DatePicker
                                                    className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Đến ngày"
                                                    selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                                    selectsEnd
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    disabled={this.state.startDate_temp === null}
                                                    isClearable={this.state.endDate_temp !== null}
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => {
                                                        this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})
                                                    }}
                                                    startDate={new Date(this.state.startDate_temp)}
                                                    endDate={new Date(this.state.endDate_temp)}
                                                    maxDate={new Date()}
                                                    minDate={this.state.startDate_temp === null ? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Loại SMS</label>
                                                <select
                                                    value={this.state.type}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            type: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    {listTypeSMS.map((item, index) =>
                                                        <option value={item.type}>{item.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            {/* <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value={true}>Thành công</option>
                                                    <option value={false}>Lỗi</option>
                                                </select>
                                            </div> */}
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = "";
                                            let descend = "";
                                            if (data.sortAsc == null) {
                                                // a = "id=asc";
                                                a = "id";
                                                descend = 'DESC'
                                            } else {
                                                a = data.sortId;
                                                descend = data.sortAsc ? 'ASC' : 'DESC'
                                            }
                                            this.setState(
                                                {
                                                    sort: a,
                                                    descending: descend
                                                }, () => this.onSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {this.renderType(row.type) || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.toOne || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.body || 'Chưa có dữ liệu'}
                                                    </td>
                                                    {/* <td style={{textAlign: 'left'}}>
                                                        {row.toOne || 'Chưa có dữ liệu'}
                                                    </td> */}
                                                    <td style={{textAlign: 'center'}}>
                                                        {moment(row.cmnCreatedAt).format("DD-MM-YYYY HH:mm")}
                                                    </td>
                                                    {/*<td style={{textAlign: 'center'}}>*/}
                                                    {/*    {row.status*/}
                                                    {/*        ?*/}
                                                    {/*        <h6><span className="badge badge-success">Thành công</span>*/}
                                                    {/*        </h6>*/}
                                                    {/*        : <h6><span className="badge badge-danger">Lỗi</span></h6>*/}
                                                    {/*        || 'Chưa có dữ liệu'}*/}
                                                    {/*</td>*/}
                                                    <td style={{textAlign: 'center'}}>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Gửi lại"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showResend: true})
                                                            }}
                                                        >
                                                            <i className="ti-loop"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showDelete: true})
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showDelete && <ModalDeleteSMS show={this.state.showDelete}
                                                            data={this.state.itemSelected}
                                                            onHide={() => this.setState({showDelete: false})}
                                                            onRefresh={() => this.getData()}
                />}
            </div>
        )
    }

}

export default SMSManagement
