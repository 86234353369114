export const reportFormOptions = [
  {
    label: "Mẫu in chỉ có số lượng",
    value: "10"
  },
  {
    label: "Mẫu in số lượng và giá trị",
    value: "20"
  }
];
export const groupByOptions = [
  {
    label: "Không phân nhóm",
    value: ""
  },
  {
    label: "Loại vật tư",
    value: "0"
  },
  {
    label: "Tài khoản vật tư",
    value: "2"
  }
];
export const includeTransfersOptions = [
  {
    label: "Không tính phát sinh điều chuyển kho",
    value: "0"
  },
  {
    label: "Tính phát sinh điều chuyển kho",
    value: "1"
  }
];
export const itemsPrintedOptions = [
  {
    label: "Chỉ in vật tư còn tồn kho",
    value: "0"
  },
  {
    label: "Chỉ in các vật tư không còn tồn kho",
    value: "1"
  },
  {
    label: "In tất cả",
    value: "2"
  }
];
export const quantityFieldsOptions = [
  {
    label: "Không in tổng số lượng",
    value: "0"
  },
  {
    label: "In tổng số lượng",
    value: "1"
  }
];
