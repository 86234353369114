import React from 'react'
import {toast} from "react-toastify";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import PublicContractApi from "../../../services/ob-core/public-contract";


class OverduePaymentManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlock: '',
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            itemSelected: {},
            listData: [],
            listCustomer: [],
            listCustomerId: [],
            sort: '',
            descending: 'DESC',
            customerId: '',
            fullName: "",
            fullName_temp: "",
            selectedCustomer: {},
            code: '',
            showLoading: false,
            clearSearchOver: false,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center", width: 100},
                    sort: false,
                },
                {
                    id: 'customerName',
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'code',
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'paymentDueDate',
                    name: "Ngày hết hạn",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'extDays',
                    name: "Số ngày quá hạn",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center", width: 100},
                    sort: false,
                },
            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getData()
        })
    }

    getData = () => {
        let {pageNumber, pageSize, sort, code, customerId, descending, selectedCustomer} = this.state
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // sort: sort,
            // descending: descending,
            code: code,
            customerId: selectedCustomer?.id
        }
        PublicContractApi.searchPeriod(payload, res => {
            if (res.success) {
                let data = res.data?.content;
                let listCustomerId = [];
                data.map(item => {
                    listCustomerId.push(item.customerId);
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                    showLoading: false,
                }, () => {
                    if (data && data.length > 0) {
                        this.getListCustomerById()
                    }
                })
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        })
    }


    getListCustomerById = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id === item.customerId);
                    item.customerName = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            }
        });
    }


    searchListCustomer = () => {
        let {fullName, fullName_temp} = this.state;
        let payload = {
            pageNumber: 0,
            pageSize: 10,
            fullName: fullName_temp,
        }
        SecureCustomerApi.getCustomer(payload, res => {
            if (res.success) {
                this.setState({
                    listCustomer: res.data.content,
                })
            }
        })
    }

    onRefresh = () => {
        this.setState(
            {
                code: '',
                customerId: '',
                fullName: "",
                fullName_temp: "",
                selectedCustomer: {},
                clearSearchOver: true,
            },
            () => this.getData()
        );
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getData()
            }
        );
    }

    render() {
        let {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="overdue-payment-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý danh sách quá hạn thanh toán"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Tên khách hàng</label>
                                                <Autosuggestion
                                                    label=""
                                                    placeholder="Nhập tên khách hàng"
                                                    type="text"
                                                    value={this.state.fullName_temp}
                                                    data={this.state.listCustomer}
                                                    clearSearch={this.state.clearSearchOver}
                                                    onSelect={(item, val) => {
                                                        this.setState({selectedCustomer: item}, () => console.log(this.state.selectedCustomer))
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            fullName_temp: val,
                                                            selectedCustomer: null,
                                                            clearSearchOver: false
                                                        }, () => this.searchListCustomer())
                                                    }}
                                                    checkError={!this.state.listCustomer}
                                                    errorContent="Vui lòng chọn một khách hàng"
                                                />
                                            </div>
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    className="form-control shadow-none font-14"
                                                    placeholder='Nhập vào mã hợp đồng'
                                                    maxLength={20}
                                                    value={this.state.code}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            code: e.target.value.trim()
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            fullName: this.state.fullName_temp,
                                                            pageNumber: 0
                                                        }, () => this.getData())
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader
                                            header={this.state.header}
                                            clickSort={data => {
                                                console.log(data);
                                                let a = "";
                                                let descend = "";
                                                if (data.sortAsc == null) {
                                                    // a = "id=asc";
                                                    a = "id";
                                                    descend = 'DESC'
                                                } else {
                                                    a = data.sortId;
                                                    descend = data.sortAsc ? 'ASC' : 'DESC'
                                                }
                                                this.setState(
                                                    {
                                                        sort: a,
                                                        descending: descend
                                                    },
                                                    () => this.getData()
                                                );
                                            }}
                                        />
                                        </thead>
                                        {!this.state.listData || this.state.listData.length === 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.customerName || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.code || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center',}}>
                                                        {row.paymentDueDate ? moment(row.paymentDueDate).format('DD-MM-YYYY') : 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center',}}>
                                                        {row.extDays || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center',}}>
                                                        <button
                                                            disabled={row.extDays < 30}
                                                            className="btn btn-action"
                                                            title="Khóa hợp đồng"
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row,
                                                                    showBlock: true
                                                                })
                                                            }}
                                                        >
                                                            <i className="ti-lock"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OverduePaymentManagement;
