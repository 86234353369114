import React from 'react';
import { stringify } from 'qs';
import moment from "moment";

import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import ServicePlan from "../../customer/service-plan";

import ModalDetailOrder from "../../../components/Modal/modalDetailOrder";
import ModalAssignOrder from "../../../components/Modal/modalAssignOrder";
import ModalAssignAccountant from "../../../components/Modal/modalAssignAccountant";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../services/ob-user/supervisor";
import PublicOrderApi from "../../../services/ob-core/public-order-service";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import { checkPermission } from '../../../utils/common';

class OrdersManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            orderCode: null,
            sort: "cmnCreatedAt=desc",
            status: "",
            status_temp: '',
            listCustomerId: [],
            list: [],
            listSupervisorId: [],
            modalDetailOrder: false,
            modalAssignOrder: false,
            selectedId: '',
            supIdFilter: '',
            selectedSupervisor: null,
            supervisorName: '',

            supervisorSuggestions: [],
            supervisor_temp: '',
            supervisorList: [],
            servicePlan: [],
            servicePlanId: null,
            clearSearchOrd: false,
            servicePlanId_temp: null,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã Order",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: { textAlign: "center" },
                    sort: false
                },
                //
                // {
                //     id: "value",
                //     name: "Giá trị hợp đồng (VND)",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "supervisorId",
                    name: "Người phụ trách",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "servicePlanName",
                    name: "Gói dịch vụ",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian gửi yêu cầu",
                    style: { textAlign: "center" },
                    sort: true
                },

                {
                    id: "status",
                    name: "Trạng thái",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: { minWidth: 120, textAlign: "center" },
                    sort: false
                }
            ]
        }
    }

    componentDidMount() {
        this.getOrderList();
        // this.getListSupervisor()
        this.getServicePlan()
    }

    onhandleSearch = () => {
        let { code_temp, status_temp, servicePlanId_temp, selectedSupervisor, supervisorId_temp } = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0,
                servicePlanId: servicePlanId_temp,
                supervisorId: selectedSupervisor ? selectedSupervisor.id : supervisorId_temp
            },
            () => {
                this.getOrderList();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                orderCode: "",
                status: "",
                status_temp: "",
                servicePlanId: null,
                servicePlanId_temp: '',
                selectedSupervisor: null,
                clearSearchOrd: true,
                supervisorName: '',
                supervisorId: null,
            },
            () => this.onhandleSearch()
        );
    }

    getOrderList = () => {
        let self = this;
        let payload = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            orderCode: this.state.orderCode,
            sort: this.state.sort,
            status: this.state.status,
            supervisorId: this.state.supervisorId,
            servicePlanId: this.state.servicePlanId,
        }
        PublicOrderApi.searchBySupervisorIdServicePlanIdAndStatus(payload, res => {
            if (res.success) {
                let listCustomerId = [];
                let listSupervisorId = [];
                let data = res.data.content
                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listSupervisorId.push(item.supervisorId);
                });
                this.setState({
                    list: res.data.content,
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                    listSupervisorId
                }, () => {
                    if (data.length > 0) {
                        self.getListCustomerByIds();
                        self.getListSupervisorByIds()
                        console.log("check>>>>>", this.state.list)
                    }
                })
            } else {

            }
        }).catch(err => {

        })
    }

    getListCustomerByIds = () => {
        let { listCustomerId, listData } = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                    item.customerPhone = filter[0]?.phone;
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    getListSupervisorByIds() {
        let { listSupervisorId, listData } = this.state;

        let payload = {
            ids: listSupervisorId
        };
        // console.log('sdfsdf',payload)
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                // console.log("successListSupervisor>>>>>>>>>>>>", res);
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorName = filter[0]?.fullName;
                });
                this.setState({
                    listData, list: res.data
                });
            } else {
                console.log("sádasd>>>>>", res);
            }
        });
    }

    showModalAssignOrder = item => {
        let listFilter = this.state.list.filter(val => val.fullName === item.supervisorName);
        this.setState({ selectedId: item.id, supIdFilter: listFilter[0].id, modalAssignOrder: true });
        console.log("check>>>>", item)
    };


    renderStatus(status) {
        switch (status) {
            case "WAITING_FOR_CONFIRMATION":
                return <h5><span className="badge badge-secondary">Chờ Supervisor xác nhận</span></h5>;
            case "WAITING_CALL_CENTER_CONFIRM":
                return <h5><span className="badge badge-secondary">Chờ Call Center xác nhận</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-primary">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h5><span className="badge badge-warning">Đã từ chối</span></h5>;
            case "CONFIRMED":
                return <h6><span className="badge badge-success">Supervisor đã xác nhận</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CREATED_CONTRACT":
                return <h6><span className="badge badge-info">Đã tạo hợp đồng</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getOrderList();
            }
        );
    }

    showModalDetailOrder = () => {
        this.setState({ modalDetailOrder: true })
    }

    getListSupervisor = () => {
        let payload = {
            fullName: this.state.supervisorName,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.searchByFullNameAndStatus(payload, res => {
            if (res.success) {
                this.setState({ supervisorList: res.data.content })
            }
        })
    }

    onChangeSupervisor = (event, { newValue }) => {
        this.setState({
            supervisor_temp: newValue
        });
    };

    getServicePlan = () => {
        let payload = {
            type: 'BASIC',
        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({ servicePlan: res.data }, () => {
                    let _ser = this.state.servicePlan
                    PublicServicePlanApi.getByType({ type: 'ADVANCED' }, res1 => {
                        if (res1.success) {
                            res1.data.map(item => _ser.push(item))
                            this.setState({ servicePlan: _ser })
                        }
                    })
                })
            }
        })
    }

    render() {
        let { listData, pageNumber, header, supervisorSuggestions, supervisor_temp, servicePlan } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="orders-management" />
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Order" />
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3">
                                                <label className="label-item">Người phụ trách</label>
                                                <Autosuggestion
                                                    label="Người phụ trách"
                                                    placeholder="Nhập tên người phụ trách"
                                                    type="text"
                                                    clearSearch={this.state.clearSearchOrd}
                                                    data={this.state.supervisorList}
                                                    onSelect={(item) => this.setState({ selectedSupervisor: item })}
                                                    onChange={(val) => {
                                                        this.setState({ supervisorName: val, selectedSupervisor: null, clearSearchOrd: false })
                                                        setTimeout(() => {
                                                            this.getListSupervisor()
                                                        }, 300);
                                                    }}
                                                    checkError={!this.state.supervisorList}
                                                    errorContent="Vui lòng chọn một Supervisor"
                                                />
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3">
                                                <label className="label-item">Gói dịch vụ</label>

                                                <select
                                                    value={this.state.servicePlanId_temp}
                                                    // defaultValue={this.state.servicePlanId_temp || this.state.servicePlanId}
                                                    onChange={val =>
                                                        this.setState({ servicePlanId_temp: val.target.value })
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    {servicePlan.map(_item => {
                                                        return (
                                                        <option value={_item.id}>{_item.servicePlanName} {_item.type == "ADVANCED" ? "(Nâng cao)" : "(Cơ bản)"}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    // value={this.state.status_temp}
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({ status_temp: val.target.value })
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    <option value="WAITING_FOR_CONFIRMATION">Chờ Supervisor xác nhận</option>
                                                    <option value="WAITING_CALL_CENTER_CONFIRM">Chờ Call Center xác nhận</option>
                                                    <option value="CONFIRMED">Supervisor đã xác nhận</option>
                                                    <option value="CREATED_CONTRACT">Đã tạo hợp đồng</option>
                                                    {/*<option value="PROCESSING">Đang xử lý</option>*/}
                                                    <option value="REJECTED">Đã từ chối</option>
                                                    <option value="CANCELED">Đã hủy</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 align-text-bottom pl-0 button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onhandleSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.setState({
                                                        supervisorName: '',
                                                        servicePlanId_temp: '',
                                                        status_temp: ''
                                                    }, () => this.onHandleClearText())}
                                                >Refresh
                                                </button>
                                                {/*<Link to="/partner/create-contract">*/}
                                                {/*    <button*/}
                                                {/*        type="button"*/}
                                                {/*        className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                {/*        onClick={() => {*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        Thêm mới*/}
                                                {/*    </button>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                                <TableHeader
                                                    header={header}
                                                    clickSort={data => {
                                                        console.log(data);
                                                        let a = "";
                                                        if (data.sortAsc == null) {
                                                            a = "cmnCreatedAt=desc";
                                                        } else {
                                                            a = `${data.sortId}=${
                                                                data.sortAsc ? "asc" : "desc"
                                                                }`;
                                                        }
                                                        console.log(a);
                                                        this.setState(
                                                            {
                                                                sort: a
                                                            },
                                                            () => this.getOrderList()
                                                        );
                                                    }}
                                                />
                                            </thead>
                                            {!this.state.listData ||
                                                this.state.listData.length == 0 ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <div className="text-center">
                                                                    Không có dữ liệu
                                                        </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {listData &&
                                                            listData.length > 0 &&
                                                            listData?.map((row, index) => (
                                                                <tr style={{ height: 50 }}>
                                                                    <td style={{ textAlign: "center" }} scope="row">
                                                                        {" "}
                                                                        {this.state.pageNumber * this.state.pageSize +
                                                                            index +
                                                                            1}
                                                                    </td>
                                                                    <td style={{ textAlign: "left" }} scope="row">
                                                                        {row.code}
                                                                    </td>
                                                                    <td style={{ textAlign: "left" }}>
                                                                        {row?.customerName}
                                                                    </td>
                                                                    {/*<td style={{textAlign: "right"}}>*/}
                                                                    {/*    {" "}*/}
                                                                    {/*    {row.dealPrice == '-1' ? "Thỏa thuận" :*/}
                                                                    {/*        <NumberFormat*/}
                                                                    {/*            value={row.dealPrice}*/}
                                                                    {/*            displayType={"text"}*/}
                                                                    {/*            thousandSeparator={true}*/}
                                                                    {/*        />}*/}

                                                                    {/*</td>*/}
                                                                    <td className='pl-4' style={{ textAlign: "left" }}>
                                                                        {row.supervisorName}
                                                                    </td>
                                                                    <td className='pl-4' style={{ textAlign: "left" }}>
                                                                        {row.servicePlanName}
                                                                    </td>
                                                                    <td className='pl-4' style={{ textAlign: "left" }}>
                                                                        {/*{moment(row.cmnCreatedAt).format("DD-MM-YYYY")}*/}
                                                                        {row.cmnCreated ? moment(row.cmnCreated).format("DD-MM-YYYY HH:mm") : ''}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {this.renderStatus(row.status)}
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {checkPermission("view_detail_order") &&
                                                                            <button
                                                                                className="btn btn-action"
                                                                                title="Xem chi tiết"
                                                                                onClick={() => {
                                                                                    this.setState({ selectedOrder: row }, () => {
                                                                                        this.showModalDetailOrder()
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i className="ti-book" />
                                                                            </button>}
                                                                        <Link
                                                                            to={row.status == "CONFIRMED" ? `/partner/create-contract?${stringify(row)}` : '#'}>
                                                                            {(checkPermission("add_new_contract")) &&
                                                                                <button
                                                                                    className="btn btn-action"
                                                                                    title="Tạo hợp đồng"
                                                                                    disabled={
                                                                                        row.status != "CONFIRMED"
                                                                                    }
                                                                                // style={{
                                                                                //     cursor:
                                                                                //         row.status === "REJECTED" ||
                                                                                //         row.status === "WAIT_SIGN"
                                                                                //             ? "pointer"
                                                                                //             : "unset"
                                                                                // }}
                                                                                >
                                                                                    <i className="ti-plus" />

                                                                                </button>
                                                                            }
                                                                        </Link>
                                                                        {/*<button*/}
                                                                        {/*    className="btn btn-action"*/}
                                                                        {/*    title="Assign order"*/}
                                                                        {/*    onClick={() =>*/}
                                                                        {/*        this.showModalAssignOrder(row)*/}
                                                                        {/*    }*/}
                                                                        {/*    disabled={*/}
                                                                        {/*        row.status != "CONFIRMED"*/}
                                                                        {/*    }*/}
                                                                        {/*    // style={{*/}
                                                                        {/*    //     cursor:*/}
                                                                        {/*    //         row.status === "REJECTED" ||*/}
                                                                        {/*    //         row.status === "WAIT_SIGN"*/}
                                                                        {/*    //             ? "pointer"*/}
                                                                        {/*    //             : "unset"*/}
                                                                        {/*    // }}*/}
                                                                        {/*>*/}
                                                                        {/*    <i className="ti-location-arrow"/>*/}
                                                                        {/*</button>*/}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAssignOrder show={this.state.modalAssignOrder}
                    onHide={() => this.setState({ modalAssignOrder: false }, () => this.getOrderList())}
                    id={this.state.selectedId}
                    supIdFilter={this.state.supIdFilter}
                />
                {this.state.modalDetailOrder && <ModalDetailOrder data={this.state.selectedOrder}
                    onHide={() => this.setState({ modalDetailOrder: false })} />}
            </div>
        );
    }

}

export default OrdersManagement
