import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicNewsCategory = {
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/category/get-list`,
            method: 'GET',
        }, callback)
    },

}
export default PublicNewsCategory
