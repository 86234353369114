import React, {Component} from "react";
import "./index.css";

import {Link, Router} from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader/index";
import NumberFormat from "react-number-format";
import ModalAccept from "./modalAccept";
import ModalReject from "./modalReject";
import Pagination from "../../../components/Pagination";
import DatePicker from "react-datepicker";
import lang from "../../customer/contracts-management/lang";

import ContractApi from '../../../services/ob-core/public-contract';
import {toast} from "react-toastify";
import {TextField} from "@material-ui/core";
import moment from "moment";

class ContractsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            status_temp: "",
            status_temp_search: "",
            code: "",
            code_temp: "",
            pageNumber: 0,
            itemId: "",
            sort: 'id',
            descending: 'DESC',
            contractId: "",
            showModalAccept: false,
            showModalReject: false,
            page: 0,
            pageSize: 10,
            totalItem: 0,
            listData: [],
            showLoading: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian tạo",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "name",
                    name: "Tên hợp đồng",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "value",
                    name: "Giá dịch vụ (VND)",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 110, textAlign: "center"},
                    sort: false
                }
            ]
        };
    }

    handleAccept = (itemId, contractId) => {
        this.setState({
            itemId: itemId,
            contractId: contractId,
            showModalAccept: true
        });
    };

    handleReject = itemId => {
        this.setState({
            itemId: itemId,
            showModalReject: true
        });
    };

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getListContractFromAPI();
        })

    }

    //******************************************************************************************************************

    getListContractFromAPI() {
        let {code, status, pageNumber, pageSize, sort, descending} = this.state;
        let payload = {
            code: code,
            assignContractStatus: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            descending: descending
        };
        ContractApi.searchPaging(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false,
                });
            } else {
                toast.error("Đã xảy ra lỗi khi lấy dữ liệu, vui lòng tải lại trang!")
                // console.log(">>>>>>>>>>>>>>>>>>>>>>fail", res);
            }
        })
    }

    //******************************************************************************************************************

    onhandleSearch = () => {
        let {code_temp, status_temp} = this.state;

        this.setState(
            {
                code: code_temp.toLowerCase(),
                status: status_temp,
                pageNumber: 0,
                showLoading: true,
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                sort: "id",
                descending: 'DESC',
                code_temp: "",
                status_temp: ""
            },
            () => this.onhandleSearch()
        );
    }

    //******************************************************************************************************************

    renderStatus(row) {
        // console.log("check>>", row.id)
        switch (row.assignStatus) {
            case "WAIT_SIGN":
                return <h5><span className="badge badge-dark">Chờ ký</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "NO_ASSIGN":
                return <h6><span className="badge badge-secondary">Chưa assign</span></h6>;
            case "WAITING_FOR_CONFIRMATION":
                return <h6><span className="badge badge-dark">Chờ kế toán xác nhận</span></h6>;
            case "REJECTED":
                return <h6><span className="badge badge-danger">Kế toán từ chối</span></h6>;
            case "LIQUIDATED":
                return <h6><span className="badge badge-info">Thanh lý</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };

    render() {
        let {
            header,
            listData,
            pageNumber,
            pageSize,
            totalItem,
            page
        } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Quản lý hợp đồng"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className='col-xs-12 col-sm-12 col-md-6 pl-0'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    className="form-control font-14 shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    type="text"
                                                    maxLength={20}
                                                    value={this.state.code_temp}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    onChange={val => {
                                                        this.setState({
                                                            code_temp: val.target.value.trimLeft()
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 pr-0'>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState(
                                                            {status_temp: val.target.value},
                                                            () => console.log(this.state.state_temp)
                                                        )
                                                    }
                                                >
                                                    <option value="">Tất cả</option>
                                                    {/*<option value="WAIT_SIGN">Chờ ký</option>*/}
                                                    {/*<option value="NO_ASSIGN">Chưa assign</option>*/}
                                                    <option value="WAITING_FOR_CONFIRMATION">Chờ kế toán xác nhận
                                                    </option>
                                                    <option value="PROCESSING">Đang thực hiện</option>
                                                    {/*<option value="REJECTED">Kế toán từ chối</option>*/}
                                                    <option value="LIQUIDATED">Thanh lý</option>
                                                    <option value="CLOSED">Đã đóng</option>
                                                    {/*<option value="CANCELED">Đã hủy</option>*/}
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-4 text-left pl-0 mt-3'>

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onhandleSearch();
                                                    }}

                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button
                                                    type="button" className="btn border-1-s btn-light btn-sm"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onHandleClearText();
                                                    }}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="text-align-right mb-2">Tổng số hợp đồng: {this.state.totalItem}</div>
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    console.log(data);
                                                    let a = "";
                                                    let descend = "";
                                                    if (data.sortAsc == null) {
                                                        // a = "id=asc";
                                                        a = "id";
                                                        descend = 'DESC'
                                                    } else {
                                                        a = data.sortId;
                                                        descend = data.sortAsc ? 'ASC' : 'DESC'
                                                    }
                                                    this.setState(
                                                        {
                                                            sort: a,
                                                            descending: descend
                                                        },
                                                        () => this.getListContractFromAPI()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData || this.state.listData.length === 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="text-center">Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td>
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td className="text-left" style={{minWidth: 150}}>
                                                            {row.code}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {row.cmnCreatedAt ? moment(row.cmnCreatedAt).format('DD-MM-YYYY') : 'Chưa có dữ liệu'}
                                                        </td>
                                                        <td className="text-left" style={{minWidth: 330}}>
                                                            {row.name}
                                                        </td>
                                                        <td className="text-right" style={{minWidth: 100}}>
                                                            <NumberFormat
                                                                value={row.value}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                // suffix={' VND'}
                                                            />
                                                        </td>
                                                        <td className="text-center" style={{minWidth: 160}}>
                                                            {this.renderStatus(row)}
                                                        </td>
                                                        <td style={{minWidth: 150}}>

                                                            <Link
                                                                to={{
                                                                    pathname: "/accountant/contracts-detail",
                                                                    search: `?contractId=${row.id}&code=${row.code}&customerId=${row.customerId}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        contractId: row.contractId,
                                                                        customerId: row.customerId,
                                                                        code: row.code,
                                                                    }
                                                                }}
                                                                style={{color: "#000000"}}
                                                            >

                                                                <button className="btn btn-action"
                                                                        title='Xem chi tiết'
                                                                        style={{cursor: 'pointer'}}
                                                                    // onClick={()=> window.OBPubConfig.loading = true}
                                                                >
                                                                    <i className="ti-book"/>
                                                                </button>
                                                            </Link>

                                                            <button className="btn btn-action"
                                                                // disabled={row.status === "WAIT_SIGN" ? false : true}
                                                                    disabled={row.assignStatus != "WAITING_FOR_CONFIRMATION"}
                                                                    title='Chấp nhận'
                                                                    onClick={() => this.handleAccept(row.id, row.conId)}
                                                                    style={{cursor: row.status === "WAITING_FOR_CONFIRMATION" ? 'pointer' : 'unset'}}>
                                                                <i className="ti-check-box"/>
                                                            </button>

                                                            <button className="btn btn-action"
                                                                    disabled={row.assignStatus != "WAITING_FOR_CONFIRMATION"}
                                                                    title='Từ chối'
                                                                    onClick={() => this.handleReject(row.id)}
                                                                    style={{cursor: row.status === "WAITING_FOR_CONFIRMATION" ? 'pointer' : 'unset'}}>
                                                                <i className="ti-close"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showModalAccept && (
                    <ModalAccept
                        itemId={this.state.itemId}
                        contractId={this.state.contractId}
                        onHide={() => {
                            this.setState({showModalAccept: false});
                        }}
                        onRefresh={() => {
                            this.setState({showModalAccept: false});
                            this.getListContractFromAPI()
                        }}
                    />
                )}
                {this.state.showModalReject && (
                    <ModalReject
                        itemId={this.state.itemId}
                        onHide={() => {
                            this.setState({showModalReject: false});
                            this.getListContractFromAPI()
                        }}
                        onRefresh={() => {
                            this.setState({showModalReject: false});
                            this.getListContractFromAPI()
                        }}
                    />
                )}
            </div>
        );
    }
}

export default ContractsManagement;
