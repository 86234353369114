import React, {Component} from "react";
import clsx from "clsx";

import {Link} from "react-router-dom";

import CustomerInformation from "./customer-information";
import EditCustomer from "./customer-edit";
import Sidebar from "../../../components/Sidebar";
import Convert from "../../../utils/convertUrlPra";


class CustomerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            customerId: '',
            idContract: '',
            id: null,
            onTab: 1,
            listData: [],
            code: '',
            onEdit: 0,
        }
    }

    componentDidMount() {
        let params = Convert.urlParams(this.props.location.search);
        console.log("check params>>>>", params)
        this.setState({customerId: params.customerId, onEdit: params.edit})
    }

    handleChangeEdit = () => {
        this.setState({
            onEdit: 0
        })
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {
        let {value, onTab, listData} = this.state;
        console.log(this.props.location.state);
        const id = this.props.location.state?.id;
        const customerId = this.props.location.state?.customerId;
        // const code = this.props.location.state?.code;

        let params = Convert.urlParams(this.props.location.search);
        console.log("param>>>>>", params)
        let self = this
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="customer-management"/>
                        <div className="col-md-10 tab-content">
                            <div className='card' style={{padding: 15}}>
                                <span>
                                    <Link to={"/admin/customer-management"}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>

                                <div className="">
                                        {this.state.onEdit == 1?
                                        <EditCustomer customerId={params.customerId} change={this.handleChangeEdit}/>
                                        : <CustomerInformation value={params.customerId}/>
                                        }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default CustomerDetail
