import React, {Component} from "react";
import "./index.css";
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import TableHeader from "../../../components/TableHeader/index";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import ModalAssignAccountant from "../../../components/Modal/modalAssignAccountant";
import lang from "./lang";

import SupervisorApi from "../../../services/ob-user/supervisor";
import AccountantApi from "../../../services/ob-user/accountant";
import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import PublicContractApi from "../../../services/ob-core/public-contract";
import {toast} from "react-toastify";

class ContractsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: "",
            contractId: "",
            sku_contract: "",
            status_temp: "",
            code_temp: "",
            listCustomer: [],
            listData: [],
            listId: [],
            pageNumber: 0,
            pageSize: 10,
            page: 0,
            code: "",
            status: "",
            sort: "id",
            descending: 'DESC',
            totalItem: 0,
            status_temp_search: "",
            accountantId: "",
            customerId: "",
            modalAssignAccountant: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center", minWidth: 40, maxWidth: 40},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    // style: {minWidth: 140, textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian tạo",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    // style: {minWidth: 150, textAlign: "center"},
                    sort: false
                },
                {
                    id: "value",
                    name: "Giá trị hợp đồng (VND)",
                    style: {textAlign: "center"},
                    // style: {minWidth: 160, textAlign: "center"},
                    sort: true
                },
                {
                    id: "supervisorId",
                    name: "Supervisor xử lý",
                    style: {textAlign: "center"},
                    // style: {minWidth: 150, textAlign: "center"},
                    sort: false
                },
                {
                    id: "accountantId",
                    name: "Kế toán phụ trách",
                    style: {textAlign: "center"},
                    // style: {minWidth: 170, textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    // style: {minWidth: 120, textAlign: "center"},
                    sort: true
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        this.setState({showLoading: true},
            () => this.getListContractFromAPI()
        )
    }

    // ---------------------------------------------------------------------------------------------------------

    getListContractFromAPI() {
        let {code, status, pageNumber, pageSize, sort, contractId, descending} = this.state;
        let payload = {
            descending: descending,
            code: code,
            assignContractStatus: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort
        };
        PublicContractApi.searchPaging(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listAccountId = [];
                let listCustomerId = [];
                let listSupervisorId = [];
                //customerId
                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listAccountId.push(item.accountantId);
                    listSupervisorId.push(item.supervisorId);
                });
                this.setState(
                    {
                        listData: res.data.content,
                        totalItem: res.data.totalElements,
                        totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                        listCustomer: listCustomerId,
                        listAccountId,
                        listCustomerId,
                        listSupervisorId,
                        showLoading: false
                    },
                    () => {
                        if (data.length > 0) {
                            this.getListCustomerByIdFromAPI();
                            this.getListAccountantByIdFromAPI();
                            this.getListSupervisorByIdFromAPI();
                        }
                    }
                );
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }

        });
    }

    getListAccountantByIdFromAPI() {
        let {listAccountId, listData} = this.state;

        let payload = {
            ids: listAccountId
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.accountantId);
                    item.accountantName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        });
    }


    getListSupervisorByIdFromAPI() {
        let {listSupervisorId, listData} = this.state;

        let payload = {
            ids: listSupervisorId
        };
        // console.log('sdfsdf',payload)
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                // console.log("successListSupervisor>>>>>>>>>>>>", res);
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId);
                    item.supervisorName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                // console.log("sádasd>>>>>", res);
            }
        });
    }


    getListCustomerByIdFromAPI = () => {
        let {listCustomer, listData} = this.state;
        let payload = {
            ids: listCustomer
        };
        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                // console.log("listcustomer>>>>>>:", res);
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                });

                this.setState({
                    listData
                });
            } else {
                // console.log("toang>>>>>", res);
            }
        });
    }

    //BUTTON ---------------------------------------------------------------------------------------------------------

    onhandleSearch = () => {
        let {code_temp, status_temp} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                sort: "id",
                descending: 'DESC',
                code_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onhandleSearch()
        );
    }

    // --------------------------------------------------------------------------------------------------------

    renderStatus(row) {
        switch (row.assignStatus) {
            case "WAIT_SIGN":
                return <h5><span className="badge badge-dark">Chờ ký</span></h5>;
            case "SIGNED":
                return <h6><span className="badge badge-secondary">Đã ký</span></h6>;
            case "NO_ASSIGN":
                return <h6><span className="badge badge-secondary">Chưa assign</span></h6>;
            case "WAITING_FOR_CONFIRMATION":
                return <h6><span className="badge badge-dark">Chờ kế toán xác nhận</span></h6>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h6><span className="badge badge-danger">Kế toán từ chối</span></h6>;
            case "LIQUIDATED":
                return <h6><span className="badge badge-info">Thanh lý</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    // renderStatus(row) {
    //     switch (row.status) {
    //         case "WAIT_SIGN":
    //             return <span className="badge badge-light">Chờ ký</span>;
    //         case "PROCESSING":
    //             if (row.assignStatus == "NO_ASSIGN") return <span className="badge badge-secondary">Chưa assign</span>
    //             else if (row.assignStatus == "WAITING_FOR_CONFIRMATION") return <span className="badge badge-light">Chờ kế toán xác nhận</span>
    //             else if (row.assignStatus == "REJECTED") return <span className="badge badge-danger">Kế toán từ chối</span>
    //             else return <span className="badge badge-success">Đang xử lý</span>;
    //         case "NO_ASSIGN":
    //             return <span className="badge badge-secondary">Chưa assign</span>;
    //         case "WAITING_FOR_CONFIRMATION":
    //             return <span className="badge badge-light">Chờ xác nhận</span>;
    //         case "REJECTED":
    //             return <span className="badge badge-danger">Từ chối</span>;
    //         case "LIQUIDATED":
    //             return <span className="badge badge-info">Thanh lý</span>;
    //         case "CANCELED":
    //             return <span className="badge badge-danger">Đã hủy</span>;
    //         case "CLOSED":
    //             return <span className="badge badge-warning">Đã đóng</span>;
    //         default:
    //             return;
    //     }
    // }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return lang.type.new;
            case "EXTENSION":
                return lang.type.extenstion;

            default:
                return;
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };

    showModalAssignAccountant = item => {
        this.setState({selectedContract: item.id, modalAssignAccountant: true});
    };

    // ------------------------------------------------------------------------------------------------------

    render() {
        let {listData, pageNumber, header} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title={lang.title}/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">
                                                <label className="label-item">Mã hợp đồng</label>

                                                <input
                                                    placeholder="Nhập vào mã hợp đồng..."
                                                    name="Code"
                                                    type="text"
                                                    className="form-control shadow-none font-14"
                                                    style={{width: "100%", height: 35, padding: 8}}
                                                    value={this.state.code_temp}
                                                    maxLength={10}
                                                    onKeyUp={e => {
                                                        if (e.key === "Enter") this.onhandleSearch();
                                                    }}
                                                    onChange={val =>
                                                        this.setState({code_temp: val.target.value.trim().toLocaleLowerCase()})
                                                    }
                                                />
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-sm-0 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({status_temp: val.target.value}, () =>
                                                            console.log(this.state.status_temp)
                                                        )
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    <option value="WAIT_SIGN">Chờ ký</option>
                                                    <option value="SIGNED">Đã ký</option>
                                                    <option value="NO_ASSIGN">Chưa assign</option>
                                                    <option value="WAITING_FOR_CONFIRMATION">Chờ kế toán xác nhận
                                                    </option>
                                                    <option value="PROCESSING">Đang thực hiện</option>
                                                    <option value="REJECTED">Kế toán từ chối</option>
                                                    <option value="LIQUIDATED">Thanh lý</option>
                                                    <option value="CLOSED">Đã đóng</option>
                                                    <option value="CANCELED">Đã hủy</option>
                                                </select>
                                            </div>
                                            <div
                                                className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                                <div className="button-filter">
                                                    <button
                                                        type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() =>
                                                            this.onhandleSearch()
                                                        }
                                                    >
                                                        Tìm kiếm
                                                    </button>
                                                    <button type="button"
                                                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                            onClick={() => this.onHandleClearText()}
                                                    >Refresh
                                                    </button>
                                                    {/*<Link to="/partner/create-contract">*/}
                                                    {/*    <button*/}
                                                    {/*        type="button"*/}
                                                    {/*        className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*        }}*/}
                                                    {/*    >*/}
                                                    {/*        Thêm mới*/}
                                                    {/*    </button>*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="text-align-right mb-2">Tổng số hợp đồng: {this.state.totalItem}</div>
                                    <div className="table-wrapper">
                                        <table
                                            className="table table-striped table-data table-responsive table-bordered">
                                            <table className="table">
                                                <thead>
                                                <TableHeader
                                                    header={header}
                                                    clickSort={data => {
                                                        let a = "";
                                                        let descend = "";
                                                        if (data.sortAsc == null) {
                                                            // a = "id=asc";
                                                            a = "id";
                                                            descend = 'DESC'
                                                        } else {
                                                            a = data.sortId;
                                                            descend = data.sortAsc ? 'ASC' : 'DESC'
                                                        }
                                                        this.setState(
                                                            {
                                                                sort: a,
                                                                descending: descend
                                                            },
                                                            () => this.getListContractFromAPI()
                                                        );
                                                    }}
                                                />
                                                </thead>
                                                {!this.state.listData ||
                                                this.state.listData.length == 0 ? (
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <div className="text-center">
                                                                Không có dữ liệu
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                    {listData &&
                                                    listData.length > 0 &&
                                                    listData?.map((row, index) => (
                                                        <tr style={{height: 50}}>
                                                            <td style={{textAlign: "center"}} scope="row">
                                                                {" "}
                                                                {this.state.pageNumber * this.state.pageSize +
                                                                index +
                                                                1}
                                                            </td>
                                                            <td style={{textAlign: "left"}} scope="row">
                                                                {row.code}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {row.cmnCreatedAt ? moment(row.cmnCreatedAt).format('DD-MM-YYYY') : 'Chưa có dữ liệu'}
                                                            </td>
                                                            <td style={{textAlign: "left"}}>
                                                                {/*{row?.customerName}*/}
                                                                {row.companyName || 'Chưa có dữ liệu'}
                                                            </td>
                                                            <td style={{textAlign: "right"}}>
                                                                {" "}
                                                                <NumberFormat
                                                                    value={row.value}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: "left"}}>
                                                                {row?.supervisorName}
                                                            </td>
                                                            <td style={{textAlign: "left"}}>
                                                                {row?.accountantName}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {this.renderStatus(row)}
                                                            </td>

                                                            <td style={{textAlign: "center"}}>
                                                                <Link
                                                                    to={{
                                                                        pathname: "./contracts-detail",
                                                                        search: `?contractId=${row.id}&code=${row.code}&customerId=${row.customerId}`,
                                                                        state: {
                                                                            fromDashboard: true,
                                                                            contractId: row.contractId,
                                                                            customerId: row.customerId,
                                                                            code: row.code,
                                                                        }
                                                                    }}
                                                                    style={{color: "#000000"}}
                                                                >
                                                                    <button
                                                                        className="btn btn-action"
                                                                        title="Xem chi tiết"
                                                                    >
                                                                        <i className="ti-book"/>
                                                                    </button>
                                                                </Link>
                                                                {/*<button*/}
                                                                {/*    className="btn btn-action"*/}
                                                                {/*    title="Assign"*/}
                                                                {/*    onClick={() =>*/}
                                                                {/*        this.showModalAssignAccountant(row)*/}
                                                                {/*    }*/}
                                                                {/*    disabled={*/}
                                                                {/*        row.status === "REJECTED" ||*/}
                                                                {/*        row.status === "WAIT_SIGN"*/}
                                                                {/*            ? false*/}
                                                                {/*            : true*/}
                                                                {/*    }*/}
                                                                {/*    style={{*/}
                                                                {/*        cursor:*/}
                                                                {/*            row.status === "REJECTED" ||*/}
                                                                {/*            row.status === "WAIT_SIGN"*/}
                                                                {/*                ? "pointer"*/}
                                                                {/*                : "unset"*/}
                                                                {/*    }}*/}
                                                                {/*>*/}
                                                                {/*    <i className="ti-location-arrow"/>*/}
                                                                {/*</button>*/}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                )}
                                            </table>
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<ModalAssignAccountant*/}
                {/*    show={this.state.modalAssignAccountant}*/}
                {/*    onHide={() => this.setState({modalAssignAccountant: false})}*/}
                {/*    contractId={this.state.selectedContract}*/}
                {/*/>*/}
                {/*modalAssignAccountant*/}
            </div>
        );
    }
}

export default ContractsManagement

