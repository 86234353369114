import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let EmailTypeDataApi = {
    getTypeData: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-type-data/get-data/${payload.type}`,
            method: 'GET'
        }, callback)
    },
    getListTypeData: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-type-data/get-list-type-data/${payload.type}`,
            method: 'GET',
        }, callback)
    },
}
export default EmailTypeDataApi;
