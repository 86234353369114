import React, { Suspense, lazy } from "react";
import {Link, Switch, Route, Redirect} from "react-router-dom";
import ContractManagement from './contract-management'
import ContractManagementDetail from '../common/contract-detail-admin-ob'
import CustomerManagement from './customer-management'
import EditCustomer from './customer-details/customer-edit'
import OrderManagement from './order-management'
import PartnerManagement from './partner-management'
import PartnerDetail from './partner-management/partnerDetail';
import EditPartner from './partner-management/edit';
import PermissionManagement from './permission-management'
import RevenueManagement from './revenue-management'
import PartnerRevenueManagement from "./revenue-management/partner-revenue-management";
import AccountantRevenueManagement from "./revenue-management/accountant-revenue-management";
import SupervisorRevenueManagement from "./revenue-management/supervisor-revenue-management";
import ServicePlanManagement from './service-plan-management'

import CreateServicePlan from "./service-plan-management/create-service-plan";
import DetailServicePlan from "./service-plan-management/detail-service-plan";
import EditServicePlan from "./service-plan-management/edit-service-plan";

import ProgramManagement from './program'
import CreateProgram from "./program/create";
import DetailProgram from "./program/detail";
import EditProgram from "./program/edit";

import SupervisorManagement from './supervisor-management'
import SupervisorDetail from './supervisor-management/supervisor-detail'
import DetailAccountant from './supervisor-management/supervisor-detail/accountant/accountant-detail'
import UserManagement from './user-management'
import WalletManagement from './wallet-management'
import PartnerWalletManagement from "./partner-walllet-management";
import ErrorEmailManagement from "./email-management/error-email-management";
import EmailManagement from "./email-management/email-statistic-report";
import CallCenterManagement from "./call-center-management";
import RatingManagement from "./rating-management";
import CustomerDetail from "./customer-details";
import CustomerInformation from "./customer-details/customer-information";
import CompleteProfile from './complete-profile'
import PostManagement from "./homepage-management/post-management";
import PostEditor from "./homepage-management/post-editor";
import SMSManagement from "./sms-management";
import NewSignUpManagement from "./new-signup-management";
import EmailConfig from "./system-config/email-config";
import SmsConfig from "./system-config/sms-config";
import OverdueConfig from "./system-config/overdue-config";
import PostCategoryManagement from "./homepage-management/post-category-management";
import {checkPermission} from "../../utils/common";
import Dashboard from "./dashboard";
import Profile from "./profile";
import HotPostManagement from "./homepage-management/hot-post-management";
import TopSupervisor from "./homepage-management/top-supervisor";
import AccountingBalanceSheet from "../accountant/accounting-balance-sheet";
import ReportBusinessResults from "../accountant/report-business-results";
import DirectCashFlowStatement from "../accountant/direct-cash-flow-statement";
import IndirectCashFlowStatement from "../accountant/indirect-cash-flow-statement";
import ReportSummary from "../accountant/report-summary";

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isAadmin: userType == "ADMIN"})
    }

    render() {
        return (
            <Switch>
                {/*{!this.state.isAadmin && <Redirect to={"/"}/>}*/}
                <Route path="/admin/dashboard" component={Dashboard} />
                <Route path="/admin/profile" component={Profile}/>
                <Route path="/admin/complete-profile" component={CompleteProfile} />
                <Route path="/admin/contract-management" component={ContractManagement} />
                {checkPermission("view_contract_detail ") && <Route path="/admin/contract-detail" component={ContractManagementDetail} />}
                <Route path="/admin/customer-management" component={CustomerManagement} />
                {checkPermission("view_customer_detail") && <Route path="/admin/customer-details/customer-edit" component={EditCustomer} />}
                {checkPermission("view_order_list") && <Route path="/admin/order-management" component={OrderManagement} />}
                <Route path="/admin/partner-management" component={PartnerManagement} />
                {checkPermission("view_partner_information") && <Route path="/admin/partner-detail" component={PartnerDetail} />}
                {checkPermission("edit_partner") && <Route path="/admin/edit-partner" component={EditPartner} />}
                <Route path="/admin/permission-management" component={PermissionManagement} />
                {checkPermission("list_revenue") && <Route path="/admin/revenue-management" component={RevenueManagement} />}
                {checkPermission("list_revenue") && <Route path="/admin/partner-revenue-management" component={PartnerRevenueManagement} />}
                {checkPermission("list_revenue") && <Route path="/admin/accountant-revenue-management" component={AccountantRevenueManagement} />}
                {checkPermission("list_revenue") && <Route path="/admin/supervisor-revenue-management" component={SupervisorRevenueManagement} />}
                {checkPermission("view_service_plan_list") && <Route path="/admin/service-plan-management" component={ServicePlanManagement} />}
                {checkPermission("create_new_service_plan") && <Route path="/admin/create-service-plan" component={CreateServicePlan} />}
                <Route path="/admin/detail-service-plan" component={DetailServicePlan} />
                {checkPermission("edit_service_plan") && <Route path="/admin/edit-service-plan" component={EditServicePlan} />}

                {checkPermission("view_program_list") && <Route path="/admin/program-management" component={ProgramManagement} />}
                {checkPermission("create_new_program") && <Route path="/admin/create-program" component={CreateProgram} />}
                {checkPermission("detail_program") &&<Route path="/admin/detail-program" component={DetailProgram} />}
                {checkPermission("edit_program") && <Route path="/admin/edit-program" component={EditProgram} />}

                <Route path="/admin/supervisor-management" component={SupervisorManagement} />
                {checkPermission("view_supervisor_account_detail") && <Route path="/admin/supervisor-detail" component={SupervisorDetail} />}
                {checkPermission("edit_accountant_account") && <Route path="/admin/accountant-detail" component={DetailAccountant}/>}
                <Route path="/admin/user-management" component={UserManagement} />
                {checkPermission("list_wallet") && <Route path="/admin/wallet-management" component={WalletManagement} />}
                {checkPermission("list_partner_wallet") && <Route path="/admin/partner-wallet-management" component={PartnerWalletManagement} />}
                <Route path="/admin/error-email-management" component={ErrorEmailManagement} />
                {checkPermission("management_email") && <Route path="/admin/email-management" component={EmailManagement} />}
                {checkPermission("list_call_center_account") && <Route path="/admin/call-center-management" component={CallCenterManagement} />}
                {checkPermission("list_rating") && <Route path="/admin/rating-management" component={RatingManagement} />}
                {checkPermission("view_customer_detail") && <Route path="/admin/customer-details" component={CustomerDetail} />}
                {/*<Route path="/admin/customer-details/customer-information" component={CustomerInformation} />*/}
                {checkPermission("list_post") && <Route path="/admin/post-management" component={PostManagement} />}
                <Route path="/admin/post-category" component={PostCategoryManagement} />
                <Route path="/admin/hot-post-management" component={HotPostManagement} />
                <Route path="/admin/top-supervisor-management" component={TopSupervisor} />
                {checkPermission("edit_post") && <Route path="/admin/editor" component={PostEditor} />}
                {checkPermission("management_sms") && <Route path="/admin/sms-management" component={SMSManagement} />}
                <Route path="/admin/new-signup-management" component={NewSignUpManagement} />
                {checkPermission("email_configuration") && <Route path="/admin/email-configuration" component={EmailConfig} />}
                {checkPermission("sms_configuration") && <Route path="/admin/sms-configuration" component={SmsConfig} />}
                <Route path="/admin/overdue-configuration" component={OverdueConfig} />
            </Switch>
        )
    }
}

export default Admin
