import React, {Component} from "react";
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone';
import TableHeader from "../../../../components/TableHeader";
import {withSnackbar} from "notistack";
import moment from 'moment';
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import lang from "../../contracts-management/lang";
import Pagination from "../../../../components/Pagination";

import DocumentApi from "../../../../services/ob-core/document";
import FilesApi from "../../../../services/resource/files";
import ModalApproveData from "../../../../components/Modal/modalApproveData";
import ModalRejectData from "../../../../components/Modal/modalRejectData";

const formatDate = 'DD-MM-YYYY';

class ListData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showApprove: false,
            sort: '',
            type_temp: '',
            type_temp_search: '',
            status_temp_search: "",
            status_temp: "",
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            listData: [],
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreatedAt',
                    name: "Thời gian",
                    style: {minWidth: 120, textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'name',
                    name: "Tên chứng từ",
                    style: {minWidth: 150, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'type',
                    name: "Loại chứng từ",
                    style: {minWidth: 150, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '',
                    name: "Thao tác",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                }

            ],
        }
    }


    getListDataContractFromAPI() {
        let {contractId, pageNumber, pageSize, sort, status, type} = this.state;
        let payload = {
            contractId: this.props.value,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            status: status,
            type: type

            // customerId:customer
        };
        // console.log("listData>>>>>>>", payload);
        DocumentApi.getListbyContract(payload, res => {

            if (!res.success) {
                if (res.status === '401') {
                    this.props.enqueueSnackbar('Unauthorized !', {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        variant: 'error',
                    });
                } else if (res.status === '403') {
                    this.props.enqueueSnackbar('Forbidden !', {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        variant: 'error',
                    });
                } else if (res.status === '404') {
                    this.props.enqueueSnackbar('Not Found  !', {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        variant: 'error',
                    });
                } else {
                    this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        variant: 'error',
                    });
                }
            } else {
                if (res.success === true) {
                    // console.log('>>>>>>>>>>>>>>fail', res)
                    // let data = res.data.content;
                    //   let listId = [];
                    // data?.map(item =>{
                    //     listId.push(item.customerId)
                    //
                    // })
                    // console.log('no hope',customerId);
                    this.setState({
                        listData: res.data.content,
                        totalItem: res.data.totalElements,
                        totalPage: Math.round(res.data.totalElements / this.state.pageSize)

                    });
                } else {

                }
            }

        });
    }


    //***************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "NOT_YET_PROCESS":
                return <h5><span className="badge badge-secondary">Chưa xử lý</span></h5>;
            case "VALID":
                return <h6><span className="badge badge-success">Hợp lệ</span></h6>;
            case "INVALID":
                return <h6><span className="badge badge-danger">Không hợp lệ</span></h6>;
            default:
                return;
        }
    }

    onhandleSearch = () => {
        let {type_temp, status_temp} = this.state;

        this.setState(
            {
                type: type_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListDataContractFromAPI();
            }
        );
    };

    onHandleClearText = () => {
        this.setState({
            type_temp: '',
            status_temp: '',
        }, () => this.onhandleSearch())
    }


    //***************************************************************************************************************


    componentDidMount() {
        document.title = "Chi tiết hợp đồng"
        this.getListDataContractFromAPI();

    }


    //***************************************************************************************************************


    renderStatus(status) {
        switch (status) {
            case "NOT_YET_PROCESS":
                return 'Chưa xử lý';
            case "VALID":
                return 'Hợp lệ';
            case "INVALID":
                return 'Không hợp lệ';
            default:
                return;
        }
    }


    renderTypePaper(type) {
        switch (type) {
            case "BILL":
                return 'Hóa đơn';
            case "RECEIPT_VOUCHER":
                return 'Phiếu thu';
            case "PAYMENT_VOUCHER ":
                return 'Phiếu chi';
            default:
                return;
        }
    }


    //***************************************************************************************************************


    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListDataContractFromAPI();
            }
        );
    }

    downloadFile = (item) => {
        let payload = {
            id: item.id
        }
        FilesApi.downloadFileDocument(payload, res => {
            if (res.success){
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${item.name}.${res.data.extension}`
                a.click();
            }
        })
    }


    //***************************************************************************************************************


    render() {
        let {pageNumber, pageSize, totalItem, header, listData} = this.state;
        return (
            <div>
                <div className=' mb-2 pb-2'>
                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                        <div className="row kt-margin-b-20">
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0  pr-md-3'>
                                <label className="label-item">Loại chứng từ</label>
                                <select
                                    value={this.state.type_temp}
                                    onChange={(val) => this.setState({type_temp: val.target.value}, () => console.log(this.state.type_temp))}
                                    className="form-control kt-input pd-4-input ">
                                    <option value="" hidden>Tất cả</option>
                                    <option value="BILL">Hóa đơn</option>
                                    <option value="RECEIPT_VOUCHER">Phiếu thu
                                    </option>
                                    <option value="PAYMENT_VOUCHER">Phiếu chi
                                    </option>
                                </select>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0'>
                                <label className="label-item">Trạng thái</label>
                                <select
                                    value={this.state.status_temp}
                                    placeholder={'fsdfsdfsfd'}
                                    onChange={(val) => this.setState({status_temp: val.target.value}, () => console.log(this.state.status_temp))}

                                    className="form-control kt-input pd-4-input ">
                                    <option value="" hidden>Tất cả</option>
                                    <option value="NOT_YET_PROCESS">Chưa xử lý</option>
                                    <option value="VALID">Hợp lệ
                                    </option>
                                    <option value="INVALID">Không hợp lệ
                                    </option>
                                </select>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-4 text-left p-0 mt-3'>

                                <button
                                    style={{height: 36}}
                                    type="button"
                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                    onClick={() => this.onhandleSearch()}
                                    color="primary"
                                >
                                    Tìm kiếm
                                </button>

                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s  mr-3 type-button-fix btn btn-light btn-sm"
                                    onClick={() => this.onHandleClearText()}
                                >
                                    Refresh
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
                <div
                    className='table'>
                    <table className="table table-striped table-data table-bordered table-responsive">
                        <table className='table'>
                            <thead>
                            <TableHeader
                                header={header}
                                clickSort={data => {
                                    console.log(data);
                                    let a = "";
                                    if (data.sortAsc == null) {
                                        a = null
                                    } else {
                                        a = `${data.sortId}=${data.sortAsc ? "asc" : "desc"}`;
                                    }
                                    // console.log(a);
                                    this.setState(
                                        {
                                            sort: a
                                        },
                                        () => this.getListDataContractFromAPI()
                                    );
                                }}
                            />
                            </thead>
                            {!this.state.listData || this.state.listData.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>Không có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {listData &&
                                listData.length > 0 &&
                                listData?.map((row, index) => (
                                    <tr style={{height: 50}}>
                                        <td style={{textAlign: "center"}} scope="row">
                                            {" "}
                                            {this.state.pageNumber * this.state.pageSize +
                                            index +
                                            1}
                                        </td>
                                        <td style={{textAlign: "center"}} scope="row">

                                            {moment(row.cmnCreatedAt).format(formatDate)}
                                            {/*</Link>*/}
                                        </td>
                                        <td style={{textAlign: "left", paddingLeft: 45}}>{row.name}</td>
                                        <td style={{textAlign: "center"}}>
                                            {" "}
                                            {this.renderTypePaper(row.type)}
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            {this.renderStatus(row.status)}
                                        </td>

                                        <td style={{textAlign: "center"}}>
                                            {/*<button*/}
                                            {/*    className="btn btn-action"*/}
                                            {/*    title="Xem chi tiết"*/}
                                            {/*    // onClick={() => this.handleShowModalDetail(id, name)}*/}
                                            {/*>*/}
                                            {/*    <Link*/}
                                            {/*        // onClick = {() =>  Utils.addOrUpdateUrlParam("contractId", row.id)}*/}
                                            {/*        to={{*/}
                                            {/*            pathname: "./contracts-detail",*/}
                                            {/*            search: "?contractId",*/}
                                            {/*            state: {*/}
                                            {/*                fromDashboard: true,*/}
                                            {/*                contractId: row.contractId*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*        style={{color: "#000000"}}*/}
                                            {/*    >*/}
                                            {/*        <i className="ti-book"/>*/}
                                            {/*    </Link>*/}

                                            {/*</button>*/}
                                            <button
                                                className="btn btn-action"
                                                title="Duyệt hóa đơn"
                                                disabled={
                                                    row.status !== "NOT_YET_PROCESS"
                                                }
                                                onClick={() =>
                                                    this.setState({itemSelected: row}, () => this.setState({showApprove: true}))
                                                }
                                            >
                                                <i className="ti-check-box"/>
                                            </button>
                                            <button
                                                className="btn btn-action"
                                                title="Từ chối duyệt"
                                                disabled={
                                                    row.status !== "NOT_YET_PROCESS"
                                                }
                                                onClick={() =>
                                                    this.setState({itemSelected: row}, () => this.setState({showReject: true}))
                                                }
                                            >
                                                <i className="ti-close"/>
                                            </button>
                                            <button
                                                className="btn btn-action"
                                                title="Tải xuống"
                                                onClick={() => this.downloadFile(row)}
                                            >
                                                <i className="ti-download"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>

                    </table>
                    <div style={{height: 40}} className="mt-2">
                        <div className="pagination-right">
                            <Pagination
                                activePage={this.state.pageNumber}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalItem}
                                changeHandler={(page) => this.handleChangePage(page)}
                            />
                        </div>
                    </div>

                </div>

                {this.state.showApprove && <ModalApproveData show={this.state.showApprove}
                                                             data={this.state.itemSelected}
                                                             onRefresh={() => this.getListDataContractFromAPI()}
                                                             onHide={() => this.setState({showApprove: false})}/>}
                {this.state.showReject && <ModalRejectData show={this.state.showReject}
                                                           data={this.state.itemSelected}
                                                           onRefresh={() => this.getListDataContractFromAPI()}
                                                           onHide={() => this.setState({showReject: false})}/>}
            </div>
        );
    }
}

export default withSnackbar(ListData)
