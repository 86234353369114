import React, {Component} from "react";
import {Stepper, Step, Typography, StepLabel, Grid, Paper} from "@material-ui/core"
import ChooseService from "./choose-service";
import ChooseSupervisor from "./choose-supervisor";
import ChoosePlan from "./choose-plan/index"
import ConfirmService from "./confirm-service";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import Spinner from "react-bootstrap/Spinner";

class SelectService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page1: true,
            page2: false,
            page3: false,
            page4: false,
            pageNum: 0,
            steps: ["Chọn dịch vụ", "Chọn gói cước", "Chọn người phụ trách chuyên môn", "Xác nhận"],
            idService: "",
            idPlan: "",
            supervisorId: "",
            businessType: null,
            businessName: null,
            numberOfDocumentsConstraintId: 0,
            numberOfEmployeesConstraintId: 0,
            numberOfInvoicesConstraintId: 0,
            numberOfMonths: 0,
            servicePlanId: 0,
            socialInsuranceConstraintId: 0,
            supervisorName: null,
            servicePlanName: null,
            price: null,
            showLoading: false,
            selectedServiceType: 'BASIC',
            screen: "choose-service"
        }
    }

    componentDidMount() {
        // console.log("order service did mount log>>>>>>>>>", this.props)
        // this.setState({})
    }

    getInfoForSubmit = () => {
        let {business, numberOfDocumentsConstraintId, numberOfEmployeesConstraintId, numberOfInvoicesConstraintId, numberOfMonths, idService, socialInsuranceConstraintId, supervisorId} = this.state;
        return {
            business: business,
            numberOfDocumentsConstraintId: numberOfDocumentsConstraintId,
            numberOfEmployeesConstraintId: numberOfEmployeesConstraintId,
            numberOfInvoicesConstraintId: numberOfInvoicesConstraintId,
            numberOfMonths: numberOfMonths,
            servicePlanId: idService,
            socialInsuranceConstraintId: socialInsuranceConstraintId,
            supervisorId: supervisorId
        }
    }

    setPlanInfo = (business, numberOfInvoicesConstraintId, numberOfDocumentsConstraintId, numberOfEmployeesConstraintId, numberOfMonths, price) => {
        this.setState({
            business: business,
            numberOfDocumentsConstraintId: numberOfDocumentsConstraintId,
            numberOfEmployeesConstraintId: numberOfEmployeesConstraintId,
            numberOfInvoicesConstraintId: numberOfInvoicesConstraintId,
            numberOfMonths: numberOfMonths,
            price: price
        })
    }

    resetData = () => {

    }

    setInsuranceConstraint = (id, price) => {
        this.setState({socialInsuranceConstraintId: id, price: price})
    }
    changePage = (value) => {
        this.setState({screen: value})
        if(this.state.selectedServiceType == "ADVANCED"){
            switch (value) {
                case "choose-service":
                    return this.setState({
                        page1: true,
                        page2: false,
                        page3: false,
                        page4: false,
                        pageNum: 0
                    })
                case "confirm-service":
                    return this.setState({
                        page1: false,
                        page2: false,
                        page3: false,
                        page4: true,
                        pageNum: 3
                    })
                }
        } else {
            switch (value) {
                case "choose-service":
                    return this.setState({
                        page1: true,
                        page2: false,
                        page3: false,
                        page4: false,
                        pageNum: 0
                    })
                case "choose-plan":
                    return this.setState({
                        page1: false,
                        page2: true,
                        page3: false,
                        page4: false,
                        pageNum: 1
                    })
                case "choose-supervisor":
                    return this.setState({
                        page1: false,
                        page2: false,
                        page3: true,
                        page4: false,
                        pageNum: 2
                    })
                case "confirm-service":
                    return this.setState({
                        page1: false,
                        page2: false,
                        page3: false,
                        page4: true,
                        pageNum: 3
                    })
            }
        }
    }

    chooseService = (id, name, priceType) => {
        this.setState({
            idService: id,
            servicePlanName: name,
            servicePriceType: priceType
        })
    }

    choosePlan = (id) => {
        this.setState({
            idPlan: id
        })
    }

    chooseSupervisor = (id, name) => {
        console.log(">>>>>>>>>>>>><<<<<>>>><<<<<>:>>>><<<>>>>",id, name);
        this.setState({
            supervisorId: id,
            supervisorName: name
        })
    }

    isStepOptional = step => {
        return step === 1;
    };

    handleBack = () => {
        if (this.state.pageNum === 1) {
            this.changePage("page0")
        }
        if (this.state.pageNum === 2) {
            this.changePage("page1")
        }
    }


    render() {
        let {page1, page2, page3, pageNum, steps} = this.state
        let {business, numberOfDocumentsConstraintId, numberOfEmployeesConstraintId, numberOfInvoicesConstraintId, numberOfMonths, idService, socialInsuranceConstraintId, supervisorId, businessName,supervisorName, servicePlanName, price} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="order-service" parentClass="orders"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                                <div className="content-loading col-12 ml-3 align-items-center"
                                                                style={{display: 'block'}}>
                                    {/*<div role="status" className="spinner-border">*/}
                                    {/*    <span className="sr-only">Loading...</span>*/}
                                    {/*</div>*/}
                                    <div className="spinner-grow text-info"/>
                                </div>
                            }

                            <div className="card col-12">
                                {/*<Grid className="titlePage">*/}
                                {/*    Đăng ký dịch vụ*/}
                                {/*</Grid>*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Đăng ký dịch vụ"/>
                                </div>
                                {this.state.screen == "choose-service" &&
                                <div className='mt-3 mb-3 row'>
                                    <label className=''>
                                        Loại dịch vụ
                                    </label>
                                    <div className="d-flex justify-content-center align-center ml-3 mb-4">
                                        <div className="form-check mr-4"
                                             onClick={() => {
                                                 this.setState({selectedServiceType: 'BASIC'})
                                             }}>
                                            <input className="form-check-input" type="radio"
                                                   checked={this.state.selectedServiceType === 'BASIC'}/>
                                            <label className="form-check-label">
                                                Dịch vụ cơ bản
                                            </label>
                                        </div>
                                        <div className="form-check mr-4"
                                             onClick={() => this.setState({selectedServiceType: 'ADVANCED'})}>
                                            <input className="form-check-input" type="radio"
                                                   checked={this.state.selectedServiceType === 'ADVANCED'}/>
                                            <label className="form-check-label">
                                                Dịch vụ nâng cao
                                            </label>
                                        </div>
                                    </div>
                                </div>}
                                <Stepper
                                    style={{marginBottom: 10}}
                                    activeStep={pageNum}
                                    alternativeLabel
                                >
                                    {/*{steps.map(label => (*/}
                                    {/*    <Step key={label}>*/}
                                    {/*        <StepLabel>{label}</StepLabel>*/}
                                    {/*    </Step>*/}
                                    {/*))}*/}
                                    <Step key={0}>
                                        <StepLabel>Chọn dịch vụ</StepLabel>
                                    </Step>
                                    {this.state.selectedServiceType == "BASIC" && <Step key={1}>
                                        <StepLabel>Chọn gói cước</StepLabel>
                                    </Step>}
                                    {this.state.selectedServiceType == "BASIC" && <Step key={2}>
                                        <StepLabel>Chọn người phụ trách chuyên môn</StepLabel>
                                    </Step>}
                                    <Step key={3}>
                                        <StepLabel>Xác nhận</StepLabel>
                                    </Step>
                                </Stepper>
                                {/*<div className={this.state.page1 ? "" : "hide-div"}>*/}
                                {/*    <ChooseService*/}
                                {/*        nextPage={this.changePage}*/}
                                {/*        chooseService={this.chooseService}*/}
                                {/*        choosePlan={this.choosePlan}*/}
                                {/*        idService={this.state.idService}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {this.state.page1 && <ChooseService
                                    nextPage={this.changePage}
                                    chooseService={this.chooseService}
                                    selectedServiceType={this.state.selectedServiceType}
                                    choosePlan={this.choosePlan}
                                    serviceId={this.state.idService}
                                    showLoading={()=> this.setState({showLoading: true})}
                                    hideLoading={()=> this.setState({showLoading: false})}
                                />}
                                {this.state.page2 && this.state.selectedServiceType == "BASIC" && <ChoosePlan
                                    nextPage={this.changePage}
                                    serviceId={this.state.idService}
                                    choosePlan={this.choosePlan}
                                    idPlan={this.state.idPlan}
                                    servicePriceType={this.state.servicePriceType}
                                    setPlanInfo={this.setPlanInfo}
                                    setInsuranceConstraint={this.setInsuranceConstraint}
                                    showLoading={()=> this.setState({showLoading: true})}
                                    hideLoading={()=> this.setState({showLoading: false})}
                                />}
                                {this.state.page3 && this.state.selectedServiceType == "BASIC" && <ChooseSupervisor
                                    nextPage={this.changePage}
                                    chooseSupervisor={this.chooseSupervisor}
                                    supervisorId={this.state.supervisorId}
                                    showLoading={()=> this.setState({showLoading: true})}
                                    hideLoading={()=> this.setState({showLoading: false})}
                                />}
                                {this.state.page4 && <ConfirmService
                                    nextPage={this.changePage}
                                    showLoading={()=> this.setState({showLoading: true})}
                                    hideLoading={()=> this.setState({showLoading: false})}
                                    selectedServiceType={this.state.selectedServiceType}
                                    info={{
                                        business: business,
                                        numberOfDocumentsConstraintId: numberOfDocumentsConstraintId,
                                        numberOfEmployeesConstraintId: numberOfEmployeesConstraintId,
                                        numberOfInvoicesConstraintId: numberOfInvoicesConstraintId,
                                        numberOfMonths: numberOfMonths,
                                        servicePlanId: idService,
                                        socialInsuranceConstraintId: socialInsuranceConstraintId,
                                        supervisorId: supervisorId
                                    }}
                                    extraInfo={{
                                        businessName: businessName,
                                        supervisorName: supervisorName,
                                        servicePlanName: servicePlanName,
                                        price: price
                                    }}/>}
                                {/*<div className={this.state.page2 ? "" : "hide-div"}>*/}
                                {/*    <ChoosePlan*/}
                                {/*        nextPage={this.changePage}*/}
                                {/*        idService={this.state.idService}*/}
                                {/*        choosePlan={this.choosePlan}*/}
                                {/*        idPlan={this.state.idPlan}*/}
                                {/*        setPlanInfo={this.setPlanInfo}*/}
                                {/*        setInsuranceConstraint={this.setInsuranceConstraint}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={this.state.page3 ? "" : "hide-div"}>*/}
                                {/*    <ChooseSupervisor*/}
                                {/*        nextPage={this.changePage}*/}
                                {/*        chooseSupervisor={this.chooseSupervisor}*/}
                                {/*        supervisorId={this.state.supervisorId}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className={this.state.page4 ? "" : "hide-div"}>*/}
                                {/*    <ConfirmService nextPage={this.changePage}*/}
                                {/*                    info={{*/}
                                {/*                        business: business,*/}
                                {/*                        numberOfDocumentsConstraintId: numberOfDocumentsConstraintId,*/}
                                {/*                        numberOfEmployeesConstraintId: numberOfEmployeesConstraintId,*/}
                                {/*                        numberOfInvoicesConstraintId: numberOfInvoicesConstraintId,*/}
                                {/*                        numberOfMonths: numberOfMonths,*/}
                                {/*                        servicePlanId: idService,*/}
                                {/*                        socialInsuranceConstraintId: socialInsuranceConstraintId,*/}
                                {/*                        supervisorId: supervisorId*/}
                                {/*                    }}*/}
                                {/*                    extraInfo={{*/}
                                {/*                        businessName: businessName,*/}
                                {/*                        supervisorName: supervisorName,*/}
                                {/*                        servicePlanName: servicePlanName,*/}
                                {/*                        price: price*/}
                                {/*                    }}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SelectService
