import React from 'react';
import {withSnackbar} from "notistack";
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {toast} from 'react-toastify';
import {Modal, Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import {URL_API} from "../../url.config";
import {getAccessToken} from "../../utils/authority";
// import {uploadFileAvatar, changeAvatar} from '../../actions/profile';

import FilesApi from "../../services/resource/files";
import SupervisorApi from "../../services/ob-user/supervisor";
import AccountantApi from "../../services/ob-user/accountant";
import PartnerApi from "../../services/ob-user/partner";
import SecureCustomerApi from "../../services/ob-user/secure-customer";
import CallCenterApi from "../../services/ob-user/call-center";
import AdminOneBookApi from "../../services/ob-user/one-book-controller";

class ModalChangeAvatar extends React.Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func,
    isUploadDone: PropTypes.bool,
    isUpdate: PropTypes.bool,
    customerId: PropTypes.number,
  }

  static defaultProps = {
    isFetching: false,
    dispatch: null,
    isUploadDone: false,
    isUpdate: false,
    customerId: null,
  }

  constructor(...args) {
    super(...args);
    this.state = {
      files: [],
      customerId: null,
      customerAvatarUrl: '',
      fileName: '',
      fileUploaded: null,
    };
    this.uploadFileServer = this.uploadFileServer.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.state.customerId = this.props.customerId;

  }

  componentWillUpdate(nextProps) {
    this.state.customerId = nextProps.customerId;
    if ((nextProps.uploadInfo && nextProps.uploadInfo.code != 200) || (nextProps.avatarInfo && nextProps.avatarInfo.code != 200)) {
      this.state.messageError = true;
      return;
    }
    if (nextProps.isUploadDone !== this.props.isUploadDone && nextProps.uploadInfo && nextProps.uploadInfo.code === 200) {
      this.state.customerAvatarUrl = nextProps.uploadInfo.data.fileDownloadUri;
      this.dispatchChangeAvatar();
      return;
    }
    if (nextProps.isUpdate !== this.props.isUpdate && nextProps.avatarInfo && nextProps.avatarInfo.code === 200) {
      this.props.onHide();
    }
  }

  // onDrop = (files) => {
  //   console.log("file>>>>>>>>", files);
  //   this.setState({files: files,messageError : false});
  // };

  onDrop = acceptedFiles => {
    this.setState({
      files: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    })
  }

  onShow = () => {
    this.setState({messageError : false});
  };

  closeModal = (e) => {
    e.preventDefault();
    this.props.onHide();
  }
  uploadFileServer = (e, id) => {
    if(this.state.files.length > 0){
      e.preventDefault();
      this.setState({messageError: false});
      let payload = {
          file: this.state.files
      }

      FilesApi.uploadPublicFile(payload, res => {
          this.setState({fileUploaded: res.data}, ()=> {
            // this.updateAvatar()
            // return this.props.uploadSuccess(res.data)
            this.selectUserType()
        })

      } ).catch(error => console.log("upload error>>>", error))


      // let token = getAccessToken();
      // const data = new FormData()
      // data.append('files', this.state.files[0], this.state.files[0].path)
      // // data.append('name', 'avatar');
      // fetch(`${URL_API}/api/one-book-resource/secure/file/upload-public-file`, {
      //   method: 'POST',
      //   headers: {
      //     "Authorization": "Bearer " + token,
      //     "Content-Type": "multipart/form-data",
      //     // "Content-Type": "application/json;charset=UTF-8",
      //   },
      //   body: data // This is your file object
      // }).then(
      //     response => response.json() // if the response is a JSON object
      // ).then(
      //     success => console.log(success) // Handle the success response object
      // ).catch(
      //     error => console.log(error) // Handle the error response object
      // );
      //
      // this.props.dispatch(uploadFileAvatar(this.state.files, data => {
      //   if (data.code !== 200) {
      //     toast.error("Lỗi upload file!")
      //   } else {
      //     this.setState({customerAvatarUrl: data.data.fileDownloadUri}, () => {
      //       this.dispatchChangeAvatar();
      //     })
      //   }
      // }));
    } else {
      toast.error("Vui lòng chọn ảnh trước khi upload!")
    }
  }

  selectUserType = () => {
    let type = localStorage.getItem("ob_cl_userType")
    // console.log("check type???>>>>>>>", type)
    switch (type) {
      case ("CUSTOMER"): {
        return this.updateAvatar(SecureCustomerApi)
      }
      case ("ACCOUNTANT"): {
        return this.updateAvatar(AccountantApi)
      }
      case ("SUPERVISOR"): {
        return this.updateAvatar(SupervisorApi)
      }
      case ("PARTNER"): {
        return this.updateAvatar(PartnerApi)
      }
      case ("ADMIN"): {
        return this.updateAvatar(AdminOneBookApi)
      }
      case ("CALL_CENTER"): {
        return this.updateAvatar(CallCenterApi)
      }
    }
  }

  updateAvatar = (userType) => {
    // console.log('checkckckc',userType);
    let props = this.props
    let payload = {
      imageName: this.state.fileUploaded.storageName
    }
    userType.uploadAvatar(payload, res => {
        if (res.success) {
          this.setState({files: []})
          toast.success('Cập nhật ảnh đại diện thành công !')
          props.updateSuccess(this.state.fileName)
        } else {
          toast.error('Cập nhật không thành công, vui lòng thử lại sau !')
        }
      })
  }


  render() {
    const modalProp = {show: this.props.show, onHide: this.props.onHide};
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name}-{file.size} bytes
      </li>
    ));
    return (
      <div>
        <Modal {...modalProp} dialogClassName="modal-dialog-centered" onShow={this.onShow}>
          <div className="btn-close">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={this.closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            {this.state.messageError === true && <Alert variant="danger">Có lỗi xảy ra! Xin vui lòng kiểm tra</Alert>}
            <div className="modal-container">

              <div className="tab-content">
                <div className="tab-pane active">
                  <h1 className="text-center">Thay ảnh đại diện</h1>
                  <div className="mt-4">
                    <div className="form-group mx-0 mb-4">
                      <div className="alert alert-info my-3 text-center text-sm" role="alert">
                        Vui lòng chỉ sử dụng ảnh vuông, có định dạng JPEG, PNG, TIF.
                      </div>
                      <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                        {({getRootProps, getInputProps}) => (
                          <section className="container-upload">
                            <div {...getRootProps({className: 'dropzone'})}>
                              <input {...getInputProps()} />
                              {files.length > 0 ? <div className="align-center">
                                <div className="img-wh120-wrapper"> <img src={this.state.files[0].preview} alt="" className="img-h120"/></div>
                                <ul>{files}</ul>
                              </div> : <p>Chọn file ảnh</p>}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <button className="btn btn-original btn-1st w-100" onClick={this.uploadFileServer}>
                      <span>
                        Lưu
                        <i className="flaticon-right"/>
                      </span>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     isUploadDone: state.profile.isUploadDone,
//     uploadInfo: state.profile.uploadInfo,
//     avatarInfo: state.profile.avatarInfo,
//     isUpdate: state.profile.isUpdate,
//   };
// }

// export default connect(mapStateToProps)(ModalChangeAvatar);
export default withSnackbar(ModalChangeAvatar);
