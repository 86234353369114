import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let RoleApi = {

    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/get-list-by-name?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
}
export default RoleApi
