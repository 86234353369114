import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import "./Modal.css";
import Cookies from "js-cookie";
import {withSnackbar} from "notistack";
import Dropzone from "react-dropzone";

import PublicContractApi from "../../services/ob-core/public-contract";
import FilesApi from "../../services/resource/files";
import {toast} from "react-toastify";
import Autosuggestion from "../Form/Autosuggestion";
import ReportContractType from "../../services/ob-core/report-contract-type";
import Convert from "../../utils/convertUrlPra";
import PropTypes from "prop-types";

class UploadReportFile extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            showUpload: true,
            files: [],
            filesCertificate: [],
            url: '',
            fileName: '',
            reportName: '',
            reportType: null,
            contentType: '',
            listJob: [],
            jobName: "",
            jobNameSearch: "",
            errJobName: false,
            clear: false,
            jobId: null,
            showLoading:false
        };
    }

    componentDidMount() {
        this.getReportType()
        let params = Convert.urlParams(window.location?.href.split('?')[1]);
        // console.log('check>>>>',params);
        this.setState({
            data: params,
        })
    }

    uploadReport = () => {
        let {data} = this.state

        if (!this.state.reportName || !this.state.reportType) {
        //TODO: reportType current is empty
        // if (!this.state.reportName) {
            return toast.error("Vui lòng nhập đầy đủ thông tin!")
            // return this.props.enqueueSnackbar("Vui lòng nhập đầy đủ thông tin! " , {
            //     variant: 'error',
            // });
        }
        if (this.state.files) {
            let payload = {
                file: this.state.files,
                // contractId: this.props.contractId,
                customerId: this.props.customerId ? this.props.customerId : data.customerId
            }
            this.setState({isLoading:true});
            FilesApi.uploadReportFile(payload, res => {
                if (res.success) {
                    this.setState({
                        fileName: res.data.storageName,
                        contentType: res.data.contentType
                    }, ()=> {
                        this.createContractReport(res.data)
                    })
                }else {
                    this.setState({isLoading:false});
                    toast.error("Upload không thành công! " + res.message)
                }
            } ).catch(error => {
                toast.error("Đã xảy ra lỗi trong quá trình upload, vui lòng thử lại! ")
                this.setState({isLoading:false});

                // this.props.enqueueSnackbar("Đã xảy ra lỗi trong quá trình upload, vui lòng thử lại! " , {
                //     variant: 'error',
                // });
                // console.log("upload error>>>", error)
            })
        } else {
            toast.error("Vui lòng chọn file để tải lên! ")
            // this.props.enqueueSnackbar("Vui lòng chọn file để tải lên! " , {
            //     variant: 'error',
            // });
        }


    }

    resetState = () => {
        this.setState({
            files: [],
            contentType: '',
            contractId: '',
            reportName: '',
            storageName: '',
            type: '',
            listType: []
        })
    }

    getReportType = () => {
        ReportContractType.getAll(res => {
            if (res.success) {
                this.setState({listType: res.data})
            } else{
                toast.error("Đã xảy ra lỗi khi lấy loại báo cáo. Vui lòng thử lại ! " + res.message)
            }
        })
    }

    createContractReport = (data) => {
        let payload = {
            jobId: this.state.jobId,
            destination: data.destination,
            // name: this.state.reportName,
            contentType: data.contentType,
            contractId: this.props.contractId,
            reportName: this.state.reportName,
            storageName: data.storageName,
            reportType: this.state.reportType
        }
        PublicContractApi.createContractReport(payload, res => {
            this.setState({isLoading:false});
            if (res.success){
                this.resetState()
                // this.props.enqueueSnackbar('Upload báo cáo thành công!', {
                //     variant: 'success',
                // });
                toast.success("Upload báo cáo thành công!")
                this.props.onHide()
                this.props.reload()
            } else if (res.errorCode === "One-Book-Core.E000035") {
                toast.error("Số lượng báo cáo đã đạt tối đa")
            } else {
                // this.props.enqueueSnackbar("Upload không thành công! " + res.message, {
                //     variant: 'error',
                // });
                toast.error("Upload không thành công! " + res.message)
            }
        }).catch(error => {
            this.setState({isLoading:false});
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        const {name, filesCertificate} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">
                            {/*<div>*/}
                            {/*    <h1 className="text-center" style={{fontSize: 20,}}><b>UPLOAD FILE</b></h1>*/}
                            {/*</div>*/}

                            {/*<div className='row kt-margin-b-20'>*/}
                            {/*<div className='col-xs-4 col-sm-4 col-md-4 p-0 mb-3'>*/}
                            {/*    <label className='label-item'>*/}
                            {/*        Tên file*/}
                            {/*        <span style={{color: 'red'}}>*</span>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div className='col-xs-8 col-sm-8 col-md-8 p-0 mb-2'>*/}
                            {/*    <input*/}
                            {/*        maxLength={50} type="text" className="form-control shadow-none"*/}
                            {/*        value={name}*/}
                            {/*        onChange={(val) => {*/}
                            {/*            this.setState({*/}
                            {/*                name: val.target.value*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className="tab-content">*/}
                            {/*    <div className="tab-pane active">*/}
                                    <h1 className="text-center">Thêm mới báo cáo</h1>

                                    <div className="mt-4">
                                        <div className='row kt-margin-b-20'>
                                            <div className='col-xs-4 col-sm-4 col-md-4 p-0 mb-3'>
                                                <label className='label-item'>
                                                    Tên file
                                                    <span style={{color: 'red'}}>*</span>
                                                </label>
                                            </div>
                                            <div className='col-xs-8 col-sm-8 col-md-8 p-0 mb-2'>
                                                <input
                                                    maxLength={50} type="text" className="form-control shadow-none"
                                                    value={this.state.reportName}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            reportName: val.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row kt-margin-b-20'>
                                            <div className='col-xs-4 col-sm-4 col-md-4 p-0 mb-3'>
                                                <label className='label-item'>
                                                    Loại báo cáo
                                                    <span style={{color: 'red'}}>*</span>
                                                </label>
                                            </div>
                                            <div className='col-xs-8 col-sm-8 col-md-8 p-0 mb-2'>
                                                <select className="form-control kt-input pd-4-input shadow-none"
                                                        defaultValue={0}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                reportType: val.target.value
                                                            })
                                                        }}
                                                >
                                                    <option value={0} disabled>Chọn một loại báo cáo</option>
                                                    {/*<option value="TAX_REPORT">Báo cáo thuế hàng tháng</option>*/}
                                                    {/*<option value="FINANCIAL_REPORT">Báo cáo tài chính</option>*/}
                                                    {/*<option value="TAX_RETURN">Báo cáo thuế thu nhập cá nhân hàng tháng</option>*/}
                                                    {/*<option value="TAX_RETURN">Báo cáo quyết toán thuế</option>*/}
                                                    {this.state.listType?.map(item => (
                                                        <option value={item.id}>{item.reportTypeName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row kt-margin-b-20'>
                                            <div className='col-xs-4 col-sm-4 col-md-4 p-0 mb-3'>
                                                <label className='label-item'>
                                                    Tên job
                                                    <span style={{color: 'red'}}>*</span>
                                                </label>
                                            </div>
                                            <div className='col-xs-8 col-sm-8 col-md-8 p-0 mb-2'>
                                                <select className="form-control kt-input pd-4-input shadow-none"
                                                        defaultValue={0}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                jobId: val.target.value
                                                            })
                                                        }}
                                                >
                                                    <option value={0} disabled>Chọn Job</option>
                                                    {this.props.jobs?.map(item => {
                                                        return(
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {/*<Autosuggestion*/}
                                                {/*    // label="Supervisor"*/}
                                                {/*    className="inputSuggest2 pd-4-input shadow-none"*/}
                                                {/*    placeholder="Nhập Job"*/}
                                                {/*    // value={this.state.roleSearch}*/}
                                                {/*    type="text"*/}
                                                {/*    // clearSearch={this.state.clear}*/}
                                                {/*    data={this.state.listJob}*/}
                                                {/*    onSelect={(item) => this.setState({jobName: item.id, errJobName: false})}*/}
                                                {/*    onChange={(val) => {*/}
                                                {/*        if(val === '' || this.state.listJob.length === 0){*/}
                                                {/*            this.setState({jobNameSearch: val, jobName: ''});*/}
                                                {/*            setTimeout(() => {*/}
                                                {/*                // this.getListRole()*/}
                                                {/*            }, 300);*/}
                                                {/*        }else{*/}
                                                {/*            this.setState({jobNameSearch: val, clear: false});*/}
                                                {/*            setTimeout(() => {*/}
                                                {/*                // this.getListRole()*/}
                                                {/*            }, 300);*/}
                                                {/*        }*/}

                                                {/*    }}*/}
                                                {/*    // checkError={this.state.errRole}*/}
                                                {/*    // errorContent="Vui lòng chọn một Supervisor"*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                        <div className="form-group mx-0 mb-4">
                                            <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                                Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.
                                            </div>
                                            <Dropzone accept="image/*, application/pdf"
                                                      onDrop={acceptedFiles => {
                                                          console.log(acceptedFiles)
                                                          this.setState({files: acceptedFiles})
                                                          // this.setState({
                                                          //     filesCertificate: acceptedFiles.map(file => Object.assign(file, {
                                                          //         preview: URL.createObjectURL(file)
                                                          //     }))
                                                          // })
                                                      }}
                                                      multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files.length > 0 ? <div className="align-center">
                                                                <ul>{files}</ul>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                        </div>
                                    </div>
                                    {this.state.isLoading &&
                                    <div className="content-loading col-12 ml-3 align-items-center"
                                         style={{display: 'block'}}>
                                        <div className="spinner-grow text-info"/>
                                    </div>
                                    }
                                    <div container item xs={12}>
                                        <div item xs={12}>
                                            <div className="align-center mt-3 mb-2">
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                                    onClick={() => {
                                                            this.uploadReport()
                                                    }}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                                    onClick={()=>{
                                                        this.resetState()
                                                        this.props.onHide()
                                                    }}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default UploadReportFile;

