import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let ProvinceApi = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/province/get-all`,
            method: 'GET'
        }, callback)
    },
    searchByStatusProvince: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/province/get-list-province-by-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    supportArea: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/province/get-list-province-by-status?status=ACTIVE`,
            method: 'GET',
        }, callback);
    }
};
export default ProvinceApi
