import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let ActionApi = {

    getActionGroup: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action/get-action-group`,
            method: 'GET'
        }, callback)
    },
    getListByGroupName: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action/get-list-by-group-name?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getListByName: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action/get-list-by-name?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
}
export default ActionApi
