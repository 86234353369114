import React from 'react';
// import {withSnackbar} from "notistack";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../services/ob-user/supervisor";
import BranchApi from "../../services/ob-user/branch";

toast.configure()

class ModalRemoveSupFromBranch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showDelete: true,
            id: this.props.supervisor.id,
            branchId: this.props.supervisor.branchId
        };
    }


    onSubmit = () => {
        let {id, branchId} = this.state;
        let payload = {
            branchId: branchId,
            supervisorId: id
        };
        BranchApi.deleteSupervisorFromBranch(payload, res => {
            if (!res.success) {
                return toast.error("Thao tác thất bại. Vui lòng thử lại!")
            } else {
                this.props.onRefresh();
                // this.props.onHide();
                return (toast.success(`Xóa tài khoản supervisor ${this.props.supervisor.fullName || ''} khỏi chi nhánh ${this.props.supervisor.branchName || ''} thành công!`))
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>XÓA SUPERVISOR KHỎI CHI NHÁNH</b></h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <label className='font-size-content'>
                                Bạn có chắc muốn xóa tài khoản Supervisor {this.props.supervisor.fullName || ''} khỏi chi nhánh {this.props.supervisor.branchName || ''} không ?
                            </label>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    // disabled={this.state.reason?.length == 0}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Đồng ý
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalRemoveSupFromBranch
