import React, {Component} from "react";
import clsx from "clsx";

import {Link} from "react-router-dom";
import Information from "../contracts-detail/Information";
import ListData from "../contracts-detail/ListData";
import ListReport from "../contracts-detail/ListReport";
import Sidebar from "../../../components/Sidebar";
import Convert from "../../../utils/convertUrlPra";
import PublicContractApi from "../../../services/ob-core/public-contract";

// import ContractInformation from "../accountant-management/accountant-detail/ContractInformation";

class ContractsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            contractId: '',
            idContract: '',
            id: '',
            onTab: 1,
            listData: [],
            jobs: []
        }
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({contractId: params.contractId})
    }

    componentDidMount() {
        this.getDetailContractFromAPI()
    }
    getDetailContractFromAPI() {
        let payload = {
            id: this.state.contractId
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data,
                });
            }
        });
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {
        let {value, onTab} = this.state;
        console.log(this.props.location.state);
        const id = this.props.location.state?.id;
        const contractId = this.props.location.state?.contractId;

        let params = Convert.urlParams(this.props.location.search);
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content">
                            <div className='card' style={{padding: 15}}>
                                <span>
                                    <Link to={"/accountant/contracts-management"}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                <h4 style={{fontSize: 20}} className='text-center mb-4'>Thông tin chi tiết hợp đồng {params.code}</h4>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={onTab === 1 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"}
                                           id="pills-home-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-home"
                                           onClick={() => this.setState({onTab: 1})}
                                           aria-selected="true">Thông tin chung</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={onTab === 2 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"}
                                           id="pills-profile-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-profile"
                                           onClick={() => this.setState({onTab: 2})}
                                           aria-selected="false">Danh sách dữ liệu</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={onTab === 3 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"}
                                           id="pills-contact-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-contact"
                                           onClick={() => this.setState({onTab: 3})}
                                           aria-selected="false">Danh sách báo cáo</a>
                                    </li>
                                </ul>
                                {/*<nav className="nav nav-tabs justify-content-center" role="tablist">*/}
                                {/*    <a*/}
                                {/*        className={onTab === 1 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}*/}
                                {/*        onClick={() => this.setState({onTab: 1})}*/}
                                {/*    >*/}
                                {/*        Thông tin chung*/}
                                {/*    </a>*/}
                                {/*    <a*/}
                                {/*        className={onTab === 2 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}*/}
                                {/*        onClick={() => this.setState({onTab: 2})}*/}
                                {/*    >*/}
                                {/*        Danh sách dữ liệu*/}
                                {/*    </a>*/}
                                {/*    <a*/}
                                {/*        className={onTab === 3 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}*/}
                                {/*        onClick={() => this.setState({onTab: 3})}*/}
                                {/*    >*/}
                                {/*        Danh sách báo cáo*/}
                                {/*    </a>*/}
                                {/*</nav>*/}

                                {/*<Tabs*/}
                                {/*    value={value}*/}
                                {/*    onChange={this.handleChange}*/}
                                {/*    indicatorColor="primary"*/}
                                {/*    textColor="primary"*/}
                                {/*    centered*/}
                                {/*>*/}
                                {/*    <Tab label="THÔNG TIN CHUNG"/>*/}
                                {/*    <Tab label="DANH SÁCH DỮ LIỆU"/>*/}
                                {/*    <Tab label="DANH SÁCH BÁO CÁO"/>*/}
                                {/*</Tabs>*/}

                                <div className="tab-content">
                                    <div
                                        className={onTab === 1 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <Information value={params.contractId} setListJob={(jobs)=> {
                                            this.setState({jobs: jobs})
                                        }}/>
                                    </div>
                                    <div
                                        className={onTab === 2 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ListData customerId={this.state.listData.customerId} value={params.contractId}/>
                                    </div>
                                    <div
                                        className={onTab === 3 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ListReport customerId={this.state.listData.customerId} value={params.contractId} jobs={this.state.jobs} contract={this.state.listData}/>
                                    </div>
                                </div>

                                {/*<Typography*/}
                                {/*    component="div"*/}
                                {/*    role="tabpanel"*/}
                                {/*    hidden={value !== 0}*/}
                                {/*>*/}
                                {/*    {value === 0 && <Box p={3}><InformationSup value={params.contractId}/></Box>}*/}
                                {/*</Typography>*/}
                                {/*<Typography*/}
                                {/*    component="div"*/}
                                {/*    role="tabpanel"*/}
                                {/*    hidden={value !== 1}*/}
                                {/*>*/}
                                {/*    {value === 1 && <Box p={3}><ListData value={params.contractId}/></Box>}*/}
                                {/*</Typography>*/}
                                {/*<Typography*/}
                                {/*    component="div"*/}
                                {/*    role="tabpanel"*/}
                                {/*    hidden={value !== 2}*/}
                                {/*>*/}
                                {/*    {value === 2 && <Box p={3}><ListReport value={params.contractId}/></Box>}*/}
                                {/*</Typography>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractsDetail
