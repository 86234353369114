import React, {Component} from "react";
import moment from "moment";
import "./index.css";

import {Link, Router} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import AccountantApi from "../../../services/ob-user/accountant";
import SupervisorApi from "../../../services/ob-user/supervisor";
import JobApi from "../../../services/ob-core/public-job";
import ModalCloseJob from "../../../components/Modal/modalCloseJob";
import {toast} from "react-toastify";
import ModalAssignAccountant from "../../../components/Modal/modalAssignAccountant";

const dateFormat = 'DD-MM-YYYY'

class JobsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractCode: '',
            status: "UNFULFILLED",
            contractCode_temp: "",
            status_temp: "UNFULFILLED",
            name: "",
            sort: '',
            name_temp: "",
            pageNumber: 0,
            itemId: "",
            contractId: "",
            jobId: "",
            showModalAccept: false,
            showModalReject: false,
            page: 0,
            pageSize: 10,
            totalItem: 0,
            listData: [],
            modalCloseJob: false,
            modalAssignJob: false,
            selectedJob: [],
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "contractCode",
                    name: "Mã hợp đồng",
                    // style: {minWidth: 140, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "name",
                    name: "Tên job",
                    // style: {minWidth: 100, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "assignerId",
                    name: "Kế toán thực hiện",
                    // style: {minWidth: 180, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "doneDate",
                    name: "Ngày hoàn thiện",
                    // style: {minWidth: 165, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "countDone",
                    name: "Số lượng báo cáo",
                    // style: {minWidth: 165, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "receiveMoney",
                    name: "Giá trị Job (VNĐ)",
                    // style: {minWidth: 120, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    // style: {minWidth: 120, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center", cursor: 'unset'},
                    sort: false
                }
            ]
        };
    }

    handleAccept = (itemId, contractId) => {
        this.setState({
            itemId: itemId,
            contractId: contractId,
            showModalAccept: true
        });
    };

    handleReject = itemId => {
        this.setState({
            itemId: itemId,
            showModalReject: true
        });
    };

    componentDidMount() {
        this.getListJobFromAPI();
    }

    //******************************************************************************************************************

    getListJobFromAPI() {
        let {contractCode, status_temp, pageNumber, pageSize, sort,} = this.state;
        let payload = {
            contractCode: contractCode,
            status: status_temp,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,

        };

        JobApi.searchByContractCodeAndStatus(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listAccountId = [];
                let listSupId = [];
                //accountantId
                data.map(item => {
                    if (item.isSupervisor){
                        if (item.status !== "UNFULFILLED") {
                            listSupId.push(item.assignerId);
                        }
                    }else {
                        listAccountId.push(item.assignerId);
                    }
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    listAccountId,
                    listSupId,
                }, () => {
                    if (listSupId.length > 0)
                        this.getListSupByIdFromAPI();
                    if (listAccountId.length > 0)
                        this.getListAccountantByIdFromAPI();
                });
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        });
    }

    getListSupByIdFromAPI() {
        let {listSupId} = this.state;

        let payload = {
            ids: listSupId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                let {listData} = this.state;
                // listData.map(item => {
                //     let filter = data.filter(x => x.id == item.assignerId);
                //     item.accountantName = filter[0]?.fullName;
                // });
                listData.map(item => {
                    data.map(account => {
                        if(account.id == item.assignerId){
                            item.accountantName = account.fullName;
                        }
                    });
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    getListAccountantByIdFromAPI() {
        let {listAccountId} = this.state;
        let payload = {
            ids: listAccountId
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let data = res.data;
                let {listData} = this.state;
                listData.map(item => {
                    data.map(account => {
                        if(account.id == item.assignerId){
                            item.accountantName = account.fullName;
                        }
                    });
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    //******************************************************************************************************************

    onhandleSearch = () => {
        let {contractCode_temp, status_temp} = this.state;

        this.setState(
            {
                contractCode: contractCode_temp.trim(),
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListJobFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                contractCode_temp: "",
                status_temp: "UNFULFILLED"
            },
            () => this.onhandleSearch()
        );
    }

    //******************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-secondary">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <span className="badge badge-primary">Đang xử lý</span>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListJobFromAPI();
            }
        );
    };
    showModalAssignAccountant = item => {
        console.log(item);
        this.setState({jobId: item.id, selectedContract: item.contractId, itemSelected: item, modalAssignAccountant: true});
    };

    render() {
        let {
            header,
            listData,
            pageNumber,
            pageSize,
            totalItem,
            page
        } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="jobs-management"/>
                        <div className="col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Job"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-3'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    className="form-control font-14 shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    type="text"
                                                    maxLength={20}
                                                    value={this.state.contractCode_temp}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    onChange={val => {
                                                        // debugger
                                                        this.setState({
                                                            contractCode_temp: val.target.value.trimLeft()
                                                        });
                                                    }}
                                                    // value={this.state.oldpassword}
                                                    // onChange={(e) => {this.setState({oldpassword: e.target.value.trim()})}}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 '>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    defaultValue={this.state.status}
                                                    onChange={val =>
                                                        this.setState(
                                                            {status_temp: val.target.value},
                                                            () => console.log(this.state.state_temp)
                                                        )
                                                    }
                                                >
                                                    <option value="">Tất cả</option>
                                                    <option value="PROCESSING">Đang xử lý</option>
                                                    <option value="UNFULFILLED">Chờ xử lý</option>
                                                    <option value="CLOSED">Đã đóng</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-4 text-left p-0 mt-3'>

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onhandleSearch();
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button
                                                    type="button" className="btn border-1-s mr-3 btn-light btn-sm"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onHandleClearText();
                                                    }}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    if (data.sortAsc == null) {
                                                        a = null;
                                                    } else {
                                                        a = `${data.sortId}=${
                                                            data.sortAsc ? "asc" : "desc"
                                                        }`;
                                                    }
                                                    console.log(a);
                                                    this.setState(
                                                        {
                                                            sort: a
                                                        },
                                                        () => this.getListJobFromAPI()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData || this.state.listData.length == 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <div className="text-center">Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td>
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.contractCode}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.name}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.accountantName}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.doneDate ? moment(row.doneDate).format(dateFormat) : "Chưa có thông tin"}
                                                        </td>
                                                        <td className="text-center">
                                                            {row.countDone}/{row.numberOfReports}
                                                        </td>

                                                        <td className="text-right">
                                                            <NumberFormat
                                                                value={row.receiveMoney}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            {this.renderStatus(row.status)}
                                                        </td>
                                                        <td style={{minWidth: 150}}>
                                                            <Link
                                                                to={{
                                                                    pathname: "./contracts-detail",
                                                                    search: `?contractId=${row.contractId}&code=${row.contractCode}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        contractId: row.contractId,
                                                                        contractCode:row.contractCode
                                                                    }
                                                                }}
                                                                className="cursor-pointer"
                                                                style={{fontSize: 20}}
                                                            >
                                                                <button className="btn btn-action"
                                                                        title='Xem chi tiết'
                                                                        style={{cursor: 'pointer'}}
                                                                    // onClick={()=> window.OBPubConfig.loading = true}
                                                                >
                                                                    <i className="ti-book"/>
                                                                </button>
                                                            </Link>
                                                            <button
                                                                className="btn btn-action"
                                                                title="Assign"
                                                                onClick={() =>
                                                                    this.showModalAssignAccountant(row)
                                                                }
                                                                disabled={row.status == "CLOSED"}
                                                                style={{cursor: row.status == "CLOSED" ? "pointer" : "unset"}}
                                                            >
                                                                <i className="ti-location-arrow"/>
                                                            </button>
                                                            <button className="btn btn-action"
                                                                    title='Đóng Job'
                                                                    disabled={row.status != "PROCESSING" || row.countDone != row.numberOfReports}
                                                                    onClick={()=> {
                                                                        this.setState({selectedJob: row, modalCloseJob: true})
                                                                    }}
                                                                    style={{cursor: 'pointer'}}>
                                                                <i className="ti-check-box"/>
                                                            </button>
                                                            {/*<button className="btn btn-action"*/}
                                                            {/*        title='Assign Job'*/}
                                                            {/*        // disabled={row.status != "PROCESSING" || row.countDone != row.numberOfReports}*/}
                                                            {/*        onClick={()=> {*/}
                                                            {/*            this.setState({selectedContract: row.id,selectedJob: row, modalAssignAccountant: true})*/}
                                                            {/*        }}*/}
                                                            {/*        style={{cursor: 'pointer'}}>*/}
                                                            {/*    <i className="ti-plus"/>*/}
                                                            {/*</button>*/}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalCloseJob && <ModalCloseJob job={this.state.selectedJob}
                                                            onHide={()=> this.setState({modalCloseJob: false})}
                                                            onRefresh={()=> this.setState({modalCloseJob: false}, () => this.getListJobFromAPI())}
                />}
                {this.state.modalAssignAccountant &&
                <ModalAssignAccountant
                    show={this.state.modalAssignAccountant}
                    onHide={() => this.setState({modalAssignAccountant: false})}
                    onSuccess={() => this.setState({modalAssignAccountant: false}, () => this.getListJobFromAPI())}
                    data={this.state.selectedJob}
                    contractId={this.state.selectedContract}
                    jobId={this.state.jobId}
                />}
            </div>
        );
    }
}

export default JobsManagement

