import React from 'react';
import './style.css';
import { ClockFill, EyeFill, ChatDotsFill } from 'react-bootstrap-icons';
import logo from '../../../../assets/images/logo.png';
import Convert from "../../../../utils/convertUrlPra";
import PublicNews from "../../../../services/ob-core/public-news";
import moment from "moment";
class ArticleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentWillMount() {
    let params = Convert.urlParams(this.props.location.search);
    this.setState({newsId: params.i}, () =>{
      this.getDetail()
    })
  }

  getDetail = () => {
    let payload = {
      id: this.state.newsId
    }
    PublicNews.getDetail(payload, res => {
      if (res.success) {
        this.setState({
          data: res.data
        })
      }
    })
  }


  render() {
    let {data}  = this.state
    return (
      <div className='article-wrapper'>
        <div className='container ob-article'>
          <h3 className='color-main text-left text-bold'>
            {data?.title}
          </h3>
          <div className='row'>
            <div className='col-md-8'>
              <div className='art-time'>
                <div className=''>
                  <span><b>{data?.createByName} &nbsp;&nbsp;&nbsp;</b></span>
                  <ClockFill />
                  <span className='ml-2'>{moment(data?.cmnCreatedAt).format("DD-MM-YYYY HH:mm")}</span>
                </div>
                {/*<div className='ml-3'>*/}
                {/*  <EyeFill />*/}
                {/*  <span className='ml-2'>260</span>*/}
                {/*</div>*/}
                {/*<div className='ml-3'>*/}
                {/*  <ChatDotsFill />*/}
                {/*  <span className='ml-2'>0</span>*/}
                {/*</div>*/}
              </div>
              <div className='art-image mt-3'>
                <img src={data?.image} className='img-fluid bg-grey' />
              </div>
              {/* anh co text ben duoi */}
              {/* <div className='img-with-text'>
                <img src={logo} className='img-fluid' />
                <div className='text-layer'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div> */}
              <div className='art-content mt-3'>
                <div dangerouslySetInnerHTML={{ __html: data?.body }} />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='selection-articles'>
                <div className='art-side-title mb-3 bg-main color-white'>
                  Bài viết chọn lọc
                </div>
                <div className='art-side-post mb-3'>
                  <img src='' className='img-fluid img-art-side' />
                  <div className='art-side-name text-left mt-2'>
                    Hướng dẫn chi tiết làm báo cáo
                  </div>
                </div>
                <div className='art-side-post mb-3'>
                  <img src='' className='img-fluid img-art-side' />
                  <div className='art-side-name text-left mt-2'>
                    Hướng dẫn chi tiết làm báo cáo
                  </div>
                </div>
              </div>
              <div className='most-view-articles mt-4'>
                <div className='art-side-title mb-3 bg-main color-white'>
                  Xem nhiều nhất
                </div>
                <div className='art-side-post mb-3'>
                  <img src='' className='img-fluid img-art-side' />
                  <div className='art-side-name text-left mt-2'>
                    Hướng dẫn chi tiết làm báo cáo
                  </div>
                </div>
                <div className='art-side-post mb-3'>
                  <img src='' className='img-fluid img-art-side' />
                  <div className='art-side-name text-left mt-2'>
                    Hướng dẫn chi tiết làm báo cáo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='comments mt-5'>
            <h4 className='comment-title color-main text-bold'>Bình luận</h4>
            <div className='comment-content mt-3'>
              <form>
                <div className='col-md-8 pd-0'>
                  <textarea
                    placeholder='Bạn nghĩ gì về bài viết này?'
                    className='form-group w-100'
                  />
                </div>
                <div className='col-12 comment-input-infor'>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='form-control'
                      placeholder='Nhập tên'
                    />
                  </div>
                  <div class='form-group'>
                    <input
                      type='email'
                      class='form-control'
                      placeholder='Nhập email'
                    />
                  </div>
                  <div class='form-check'>
                    <input
                      class='form-check-input'
                      type='checkbox'
                      value=''
                      id='defaultCheck1'
                    />
                    <label class='form-check-label' for='defaultCheck1'>
                      Lưu thông tin
                    </label>
                  </div>
                </div>
                <button className='bg-gradient-main color-white btn btn-send-comment mt-3'>
                  Gửi bình luận
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleDetail;
