import React from 'react';
// import {withSnackbar} from "notistack";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../services/ob-user/supervisor";
import BranchApi from "../../services/ob-user/branch";
import PublicContractApi from "../../services/ob-core/public-contract";
import DocumentApi from "../../services/ob-core/document";
import UserFile from "../../services/ob-user/user-file";

toast.configure()

class ModalImportFileToContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showAdd: true,
            // id: this.props.data.id,
            fileId: '',
            listFiles: [],
            code: "",
        };
    }

    componentDidMount() {
        this.getListFile()
    }

    getListFile() {
        let {code, status} = this.state;

        UserFile.getAll(res => {
            if (res.success) {
                let data = res.data.content;
                this.setState({
                    listFiles: res.data,
                    // totalItem: res.data.totalElements,
                    showLoading: false,
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng thử lại sau!" + res.message)
            }
        });
    }

    onSubmit = () => {
        let payload = {
            // contractId: this.props.value,
            // destination: data[0].destination,
            // name: fileName,
            // type: fileType,
            // url: data[0].storageName,
        }
        DocumentApi.create(payload, res => {
            if (res.success){
                this.setState({modalUploadFile: false}, ()=> {
                    toast.success("Gán file vào hợp đồng thành công!")
                    setTimeout(() => {
                        this.getListDataContractFromAPI()
                    }, 600);

                })
            }else{
                toast.error("Thao tác không thành công, vui lòng thử lại!")
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>CẬP NHẬT FILE VÀO HỢP ĐỒNG</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                    <label className="col-5 label-item pl-0">Tên file</label>
                                    <select
                                        value={this.state.fileId}
                                        onChange={val =>{
                                            console.log(val.target.value)
                                            this.setState({fileId: val.target.value})
                                        }

                                        }
                                        className="form-control kt-input pd-4-input shadow-none">
                                        {this.state.listFiles.map((item, index) =>
                                            <option value={item.id} >{item.fullName}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    // disabled={this.state.reason?.length == 0}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalImportFileToContract
