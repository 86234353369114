import React from 'react';
import {Modal} from "react-bootstrap";
import {withSnackbar} from "notistack";

import AccountantApi from "../../services/ob-user/accountant";
import PublicContractApi from "../../services/ob-core/public-contract";
import {toast} from "react-toastify";

class ModalAssignAccountant extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            listAccountant: [],
            selectedAccountant: null,
            listSup:[]
        }
    }

    componentWillMount() {
        this.getListAccountant();
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        this.setState({selectedAccountant: null, userInfo})
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                selectedAccountant: null
            });
        }
    }

    getListAccountant = () => {
        let payload = {
            status: "ACTIVE",
            // sort: 'id=ASC',
        };
        AccountantApi.searchByNameStatusAndSupervisorId(payload, res => {
            if (res.success) {
                this.setState({
                    listAccountant: res.data.content
                })
            }
        })
    }

    confirm = () => {
        if (this.state.selectedAccountant != 0) {
            let payload = {
                contractId  : this.props.contractId,
                jobId       : this.props.jobId,
                assignerId  : this.state.selectedAccountant,
                // supervisorAdmitted: true
            };
            PublicContractApi.assignToAccountant(payload, res => {
                if (res.success) {
                    this.props.onSuccess();
                    toast.success("Assign thành công!")
                } else {
                    return toast.error('Assign không thành công!' + res.message[0])
                }
            })
        } else{
            toast.error('Vui lòng chọn một kế toán trước khi xác nhận!')
        }

    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return(
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>ASSIGN HỢP ĐỒNG</b></h1>
                        </div>
                        {/*<div className="align-center">*/}
                        {/*    <h6>Bạn có chắc chắn xóa tập tin này không?</h6>*/}
                        {/*</div>*/}
                        <div className="row justify-content-between">
                            <label htmlFor="">Chọn một người thực hiện</label>
                            <select
                                className="form-control kt-input pd-4-input shadow-none"
                                value={this.state.selectedAccountant}
                                defaultValue={0}
                                onChange={(e) => {
                                    this.setState({
                                        selectedAccountant: e.target.value
                                    })
                                }}
                            >
                                <option value={0} disabled>Chọn một kế toán</option>
                                <option value={this.state.userInfo.id}>[SUPERVISOR] - {this.state.userInfo.fullName}</option>
                                {this.state.listAccountant?.map(item => {
                                    return (
                                        item.id === this.props.data.accountantId ? <option disabled style={{backgroundColor: '#cbcbcb'}} value={item.id}>{item.fullName}</option> : <option value={item.id}>{item.fullName}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.selectedAccountant == null}
                                        onClick={this.confirm}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

export default ModalAssignAccountant
