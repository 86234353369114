import React from 'react';
import {Modal} from "react-bootstrap";
import {withSnackbar} from "notistack";
import {toast} from "react-toastify";
import EmailHistoryApi from "../../../services/ob-core/email-history"

class modalResendEmail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    resendEmail = () => {
        let payload = {
            id: this.props.data
        }
        EmailHistoryApi.resendErrorEmail(payload, res => {
            if (res.success) {
                toast.success("Gửi lại email thành công !")
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        })

    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return(
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-container">

                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className="align-center">
                            <h6>Bạn có chắc chắn muốn gửi lại email này không?</h6>
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.resendEmail}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

export default withSnackbar(modalResendEmail)
