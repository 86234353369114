import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PublicRequirementConstraint = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/requirement-constraint-on-month/get-all`,
            method: 'GET'
        }, callback)
    },
}
export default PublicRequirementConstraint
