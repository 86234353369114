import React from 'react'
import "./style.css"
class GenericNotFound extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>:(</h1>
                    </div>
                    <h2>404 - Page not found</h2>
                    <p>Trang bạn đang tìm kiếm có thể đã bị xóa, bị thay đổi hoặc tạm thời không có.</p>
                    <a href="#">về trang chủ</a>
                </div>
            </div>
        );
    }
}

export default GenericNotFound
