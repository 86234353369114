import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let AdminServicePlan = {
    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/detail/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    createAdvance: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/create-advance`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/edit-service-plan`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    updateAdvanced: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/edit-service-plan-advanced`,
            method: 'POST',
            body: payload.body,
        }, callback)
    },
    searchByType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/search-by-type?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    active: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/active/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    block: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/block/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    unblock: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/unblock/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-plan/delete`,
            method: 'POST',
            body: payload,
        }, callback);
    },
}
export default AdminServicePlan
