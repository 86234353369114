import React from "react";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import DatePicker from "react-datepicker";
import { getUserInfo } from "../../../utils/authority";
import ReportApi from "../../../services/ob-core/report";
import { reportFormOptions, reportCodeOptions, reportCodeOptions200, reportCodeOptions133} from "./mockdata";
import { withSnackbar } from "notistack";

class ReportBusinessResults extends React.Component {
  constructor(props) {
    super(props);
    let userInfo  = JSON.parse(localStorage.getItem("ob_cl_userInfo"));
    let reportCodeOptions = userInfo.circulars=== "200"?reportCodeOptions200: reportCodeOptions133;
    this.state = {
      reportForm: _.get(reportFormOptions, "[0].value"),
      reportCode: _.get(reportCodeOptions, "[0].value"),
      dateFrom: null,
      dateTo: null,
      dateToPrev: null,
      dateFromPrev: null,
      reportCodeOptions: reportCodeOptions,
      reportFormOptions: reportFormOptions,
      loading: false,
      unit: ""
    };
  }

  onSubmit() {
    const { unit, reportCode, reportForm } = this.state;
    let dateFrom = moment(this.state.dateFrom).format("yyyyMMDD");
    let dateFromPrev = moment(this.state.dateFromPrev).format("yyyyMMDD");
    let dateToPrev = moment(this.state.dateToPrev).format("yyyyMMDD");
    let dateTo = moment(this.state.dateTo).format("yyyyMMDD");
    let mailTo = _.get(getUserInfo(), "email");
    let language = "v";

    const payload = {
      id: 200,
      data: {
        dateFrom,
        dateTo,
        dateFromPrev,
        dateToPrev,
        unit,
        reportCode,
        reportForm,
        language,
        mailTo,
        mailCC: "",
        mailBCC: ""
      }
    };

    this.setState({ loading: true });
    ReportApi.createReport(payload, res => {
      console.log(res);
      this.setState({ loading: false });
      if (res.success === true) {
        const resData = JSON.parse(res.data);
        if (_.get(resData, "Success") !== "0") {
          this.notify("success", "Tạo báo cáo thành công!");
        } else {
          this.notify("error", resData.Message);
        }
      } else {
        this.notify("error", "Tạo báo cáo không thành công, vui lòng thử lại sau !");
      }
    });
  }

  notify(variant, message) {
    this.props.enqueueSnackbar(
      message,
      {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        variant
      }
    );
  }

  componentDidMount() {
    // this.setState({showLoading: true}, () => {
    //     this.searchListPartnerFromAPI()
    // })
  }

  isInvalidForm() {
    const {
      dateFrom,
      dateTo,
      dateFromPrev,
      dateToPrev,
      reportCode,
      reportForm,
      unit
    } = this.state;
    console.log(this.state);
    return (
      dateFrom === null ||
      dateTo === null ||
      dateFromPrev === null ||
      dateToPrev === null ||
      reportCode === null ||
      reportForm === null
    );
  }

  //****************************************************************************************************************

  //*********************************************************************************************************************

  render() {
    const {
      dateFrom,
      dateTo,
      dateFromPrev,
      dateToPrev,
      reportFormOptions,
      reportCodeOptions,
      loading
    } = this.state;
    return (
      <div className="container">
        <div
          className="loading"
          style={loading ? { display: "block" } : { display: "none" }}
        >
          <div className="lds-dual-ring"></div>
        </div>
        <div className="row">
          <Sidebar
            activeClass="business-results"
            parentClass="report-management"
          />
          <div className="mb-4 col-md-10 tab-content">
            <div>
              <PageHeader
                routerEnable={false}
                title="Báo cáo kết quả sản xuất kinh doanh"
              />
            </div>
            <div className="mt-3 mb-3" style={{ padding: "0 18px" }}>
              <div className="row">
                <div className="col-12 d-flex mb-2 pl-0">
                  <label className="col-2 label-item pl-0">
                    Mẫu báo cáo <span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="form-control kt-input pd-4-input shadow-none mb-1"
                    onChange={e => {
                      this.setState({
                        reportCode: e.target.value
                      });
                    }}
                  >
                    <option value={0} disabled>
                      Vui lòng chọn 1 mẫu báo cáo
                    </option>
                    {reportCodeOptions?.map(item => {
                      return item.value === this.props.data?.reportCode ? (
                        <option
                          disabled
                          style={{ backgroundColor: "#cbcbcb" }}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option value={item.value}>{item.label}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-12 d-flex mb-2 pl-0">
                  <label className="col-2 label-item pl-0">
                    Biểu mẫu <span style={{ color: "red" }}> *</span>
                  </label>
                  <select
                    className="form-control kt-input pd-4-input shadow-none"
                    onChange={e => {
                      this.setState({
                        reportForm: e.target.value
                      });
                    }}
                  >
                    <option value={0} disabled>
                      Vui lòng chọn 1 biểu mẫu
                    </option>
                    {reportFormOptions?.map(item => {
                      return item.value === this.props.data?.reportForm ? (
                        <option
                          disabled
                          style={{ backgroundColor: "#cbcbcb" }}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option value={item.value}>{item.label}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/*<div className="col-12 d-flex mb-2 pl-0">*/}
              {/*  <label className="col-2 label-item pl-0">*/}
              {/*    Mã đơn vị cơ sở <span style={{ color: "red" }}> *</span>*/}
              {/*  </label>*/}
              {/*  <input*/}
              {/*    className="inputPassword w-100"*/}
              {/*    style={{*/}
              {/*      border: "1px solid #ced4da",*/}
              {/*      padding: "0 10px",*/}
              {/*      color: "#495057"*/}
              {/*    }}*/}
              {/*    placeholder="Mã đơn vị cơ sở"*/}
              {/*    maxLength={50}*/}
              {/*    onChange={e =>*/}
              {/*      this.setState({*/}
              {/*        unit: e.target.value*/}
              {/*      })*/}
              {/*    }*/}
              {/*  />*/}
              {/*</div>*/}
            </div>

            <fieldset
              className="area-content-modal mb-3"
              style={{ width: "100%" }}
            >
              <legend
                className="font-14 "
                style={{ paddingLeft: 20 }}
                style={{ width: 60, paddingLeft: 9 }}
              >
                Kỳ này
              </legend>
              <div className="row" style={{ padding: "0 9px" }}>
                <div className="col-12 d-flex mb-2 p-0">
                  <div className="col-2 d-flex mb-2 pl-0">
                    <label className="label-item " htmlFor="dateFrom">
                      Từ ngày
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-4 d-flex mb-2 pl-0">
                    <DatePicker
                      dropdownMode="select"
                      id="dateFrom"
                      className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                      placeholderText="Từ ngày"
                      // onChangeRaw={this.handleDateChangeRaw}
                      selected={dateFrom === null ? "" : new Date(dateFrom)}
                      // value={dateFrom}
                      maxDate={new Date()}
                      // minDate={new Date()}
                      // value={dateIssue}
                      dateFormat="dd-MM-yyyy"
                      isClearable={dateFrom === null ? false : true}
                      // fixedHeight
                      // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                      selectsStart
                      showMonthDropdown
                      showYearDropdown
                      onChange={date => {
                        this.setState({
                          dateFrom: date === null ? null : date
                        });
                      }}
                    />
                  </div>
                  <div
                    className="col-2 d-flex mb-2 "
                    style={{ paddingLeft: 20 }}
                  >
                    <label className="label-item " htmlFor="dateTo">
                      Tới ngày
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-4 d-flex mb-2 pl-0">
                    <DatePicker
                      dropdownMode="select"
                      id="dateTo"
                      className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                      placeholderText="Đến ngày"
                      // onChangeRaw={this.handleDateChangeRaw}
                      selected={dateTo === null ? "" : new Date(dateTo)}
                      // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                      selectsEnd
                      showMonthDropdown
                      showYearDropdown
                      disabled={dateFrom === null ? true : false}
                      isClearable={dateTo === null ? false : true}
                      // fixedHeight
                      dateFormat="dd-MM-yyyy"
                      onChange={date => {
                        this.setState({
                          dateTo: date === null ? null : date
                        });
                      }}
                      startDate={new Date(dateFrom)}
                      endDate={new Date(dateTo)}
                      minDate={
                        dateFrom === null ? new Date() : new Date(dateFrom)
                      }
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset
              className="area-content-modal mb-4"
              style={{ width: "100%" }}
            >
              <legend
                className="font-14 "
                style={{ paddingLeft: 20 }}
                style={{ width: 70, paddingLeft: 9 }}
              >
                Kỳ trước
              </legend>

              <div className="row" style={{ padding: "0 9px" }}>
                <div className="col-12 d-flex mb-2 p-0">
                  <div className="col-2 d-flex mb-2 pl-0">
                    <label className="label-item " htmlFor="dateFromPrev">
                      Từ ngày
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-4 d-flex mb-2 pl-0">
                    <DatePicker
                      dropdownMode="select"
                      id="dateFromPrev"
                      className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                      placeholderText="Từ ngày"
                      // onChangeRaw={this.handleDateChangeRaw}
                      selected={
                        dateFromPrev === null ? "" : new Date(dateFromPrev)
                      }
                      // value={dateFromPrev}
                      maxDate={new Date()}
                      // minDate={new Date()}
                      // value={dateIssue}
                      dateFormat="dd-MM-yyyy"
                      isClearable={dateFromPrev === null ? false : true}
                      // fixedHeight
                      // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                      selectsStart
                      showMonthDropdown
                      showYearDropdown
                      onChange={date => {
                        this.setState({
                          dateFromPrev: date === null ? null : date
                        });
                      }}
                    />
                  </div>
                  <div
                    className="col-2 d-flex mb-2 "
                    style={{ paddingLeft: 20 }}
                  >
                    <label className="label-item " htmlFor="dateToPrev">
                      Tới ngày
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-4 d-flex mb-2 pl-0">
                    <DatePicker
                      dropdownMode="select"
                      id="dateToPrev"
                      className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                      placeholderText="Đến ngày"
                      // onChangeRaw={this.handleDateChangeRaw}
                      selected={dateToPrev === null ? "" : new Date(dateToPrev)}
                      // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                      selectsEnd
                      showMonthDropdown
                      showYearDropdown
                      disabled={dateFromPrev === null ? true : false}
                      isClearable={dateToPrev === null ? false : true}
                      // fixedHeight
                      dateFormat="dd-MM-yyyy"
                      onChange={date => {
                        this.setState({
                          dateToPrev: date === null ? null : date
                        });
                      }}
                      startDate={new Date(dateFromPrev)}
                      endDate={new Date(dateToPrev)}
                      minDate={
                        dateFromPrev === null
                          ? new Date()
                          : new Date(dateFromPrev)
                      }
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div>
              <div className="d-flex justify-content-end mt-3 mb-2">
                <button
                  type="button"
                  className="ml-4 col-3 border-1-s background-btn type-button-fix btn shadow-none"
                  style={{
                    backgroundColor: "#169bd5",
                    borderColor: "#169bd5",
                    color: "white"
                  }}
                  // disabled={this.state.start.length === 0 || this.state.end.length === 0 || this.state.price.commercePrice === 0 || this.state.price.manufacturingPrice === 0 || this.state.price.buildingPrice === 0}
                  disabled={this.isInvalidForm()}
                  onClick={() => this.onSubmit()}
                >
                  Khởi tạo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(ReportBusinessResults);
