import React from "react";
import SupervisorApi from "../../../services/ob-user/supervisor";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import FilesApi from "../../../services/resource/files";

class ModalSupervisorDetailWaitActive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: [],
            listCerti: [],
            selectedFile: {}
        }
    }

    componentWillMount() {
        this.getDetail()
    }

    getDetail = () => {
        let payload = {
            id: this.props.supervisorId
        }
        SupervisorApi.adminOneBookGetSupervisorDetail(payload, res => {
            if (res.success) {
                this.setState({
                    detail: res.data,
                    listCerti: res.data.listUserFile
                })
            } else {
                toast.error("Đã có lỗi trong quá trình lấy dữ liệu. Vui lòng thử lại sau!")
            }
        })
    }

    onActive = () => {
        let payload = {
            id: this.props.supervisorId
        }
        SupervisorApi.adminOneBookActiveSupervisor(payload, res => {
            if (res.success) {
                toast.success("Kích hoạt tài khoản thành công")
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi trong quá trình lấy dữ liệu. Vui lòng thử lại sau!")
            }
        })
    }

    onReject = () => {

    }


    download = () => {
        let payload = {
            fileId: this.state.selectedFile.id
        }
        FilesApi.downloadUserFile(payload, res => {
            if (res.success) {
                // console.log(res.data)
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${this.state.selectedFile.name}.${res.data.extension}`
                a.click();
            }
        })
    }


    render() {
        let {detail} = this.state;
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>CHI TIẾT SUPERVISOR</b></h1>
                        </div>

                        <div className='m-2'>
                            <div className="row">
                                <span className="col-7">Họ và tên: </span>
                                <span className="col-5">{detail.fullName}</span>
                            </div>
                            <div className="row mt-2">
                                <span className="col-7">Số điện thoại: </span>
                                <span className="col-5">{detail.phone}</span>
                            </div>
                            <div className="row mt-2">
                                <span className="col-7">Email: </span>
                                <span className="col-5" title={detail.email}>{detail.email}</span>
                            </div>
                            <div className="row mt-2">
                                <span className="col-7">Số kế toán được phụ trách: </span>
                                <span className="col-5">{detail.maxAccountant}</span>
                            </div>
                            <div className="row mt-2">
                                <span className="col-7">Số hợp đồng được thực hiện: </span>
                                <span className="col-5">{detail.maxContract}</span>
                            </div>
                            <div className="row mt-2">
                                <span className="col-7">Chứng chỉ hành nghề: </span>
                                <span className="col-5">
                                    {this.state.listCerti.map((item, index) => (
                                        <a
                                            style={{color: '#1c90ab'}}
                                            title={`Tải xuống chứng chỉ "${item.fullName}"`}
                                            onClick={() => this.setState({selectedFile: item}, () => this.download())}
                                        >
                                            {item.fullName}
                                        </a>
                                    ))}
                                </span>
                            </div>
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={() => this.onActive()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 btn btn-warning"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={() => this.onReject()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Từ chối
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 btn btn-secondary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đóng
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ModalSupervisorDetailWaitActive
