import React, {Component} from "react";
import {Link} from 'react-router-dom'
import {Grid, Paper, TablePagination} from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Information from "../contracts-detail/Information";
import ListData from "../contracts-detail/ListData";
import ListReport from "../contracts-detail/ListReport";
import Sidebar from "../../../components/Sidebar";
import Convert from "../../../utils/convertUrlPra";

class ContractsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            contractId: '',
            idContract: '',
            id: '',
            contractStatus: ""
        }
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    setContractStatus = (status) => {
        this.setState({contractStatus: status})
    }

    render() {
        let {value} = this.state;
        const id = this.props.location.state?.id;
        const contractId = this.props.location.state?.contractId;
        let params = Convert.urlParams(this.props.location.search);
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content">
                            <Paper style={{padding: 15}}>
                            <span>
                                <Link to={"/customer/contracts-management"}>
                                    <i className="ti-arrow-left"/>&nbsp;
                                    Quay lại
                                </Link>
                            </span>
                                <h4 className='text-center mb-4' style={{fontSize: 18}}>Thông tin chi tiết hợp
                                    đồng {params.code}</h4>
                                <Tabs
                                    value={value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="THÔNG TIN CHUNG"/>
                                    <Tab label="DANH SÁCH DỮ LIỆU"/>
                                    <Tab label="DANH SÁCH BÁO CÁO"/>
                                </Tabs>
                                <Typography
                                    component="div"
                                    role="tabpanel"
                                    hidden={value !== 0}
                                >
                                    {value === 0 && <Box p={3}><Information value={params.contractId} setContractStatus={this.setContractStatus}
                                                                            idContract={params.contractId}/></Box>}
                                </Typography>
                                <Typography
                                    component="div"
                                    role="tabpanel"
                                    hidden={value !== 1}
                                >
                                    {value === 1 && <Box p={3}><ListData value={params.contractId} contractStatus={this.state.contractStatus}/></Box>}
                                </Typography>
                                <Typography
                                    component="div"
                                    role="tabpanel"
                                    hidden={value !== 2}
                                >
                                    {value === 2 && <Box p={3}><ListReport value={params.contractId} code={params.code}/></Box>}
                                </Typography>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractsDetail
