import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let ActionRoleApi = {
    getListByRoleId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action-role/get-list-by-role-id/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    getPermission: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action-role/get-permission`,
            method: 'GET',
        }, callback)
    },
    addList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action-role/add-list/${payload.id}`,
            method: 'POST',
            body: payload.body
        }, callback)
    },
    removeList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/action-role/remove-list/${payload.id}`,
            method: "POST",
            body: payload.body
        }, callback)
    }
}
export default ActionRoleApi;
