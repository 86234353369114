/**
 * Created by WebStorm.
 * User:
 * Date:
 * Time:
 *
 * User: Le.Cong.Hai
 * Update: 23/03/2020
 * Time: 11:00
 *
 *
 * * User: Nguyen.Ngoc.Thinh
 * Update: 26/03/2020
 * Time: 11:00
 *
 * User: Le.Cong.Hai
 * Update: 26/03/2020
 * Time: 23:00
 */

import React, {Component} from "react";
import moment from "moment";

import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import WalletApi from '../../../services/ob-core/wallet';
import PartnerApi from "../../../services/ob-user/partner";
import {toast} from "react-toastify";

const dateFormat = 'DD-MM-YYYY';

const renderType = function (transactionType) {
    switch (transactionType) {
        case 'RECHARGE_MONEY':
            return 'Nạp tiền cọc';
        // case 'REFUND_MONEY':
        //     return 'Tiền hoàn lại';
        case 'EXPERT_MONEY':
            return 'Trừ tiền cọc';
        default:
            return '';
    }
};

class WalletManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemSelected: {},
            accountBalance: 0,
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            monthCreateAt: '',
            transactionType: '',
            transactionType_temp: '',
            contractCode: '',

            contractCode_temp: '',
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'contract_code',
                    name: "Mã hợp đồng",
                    style: {textAlign: "center", overflow: 'hidden'},
                    // style: {minWidth: 130, textAlign: "left", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'transaction_content',
                    name: "Nội dung giao dịch",
                    style: {textAlign: "center"},
                    // style: {minWidth: 170, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'transaction_value',
                    name: "Giá trị giao dịch (VND)",
                    style: {textAlign: "center"},
                    // style: {minWidth: 150, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'before_transaction',
                    name: "Tài khoản trước giao dịch (VND)",
                    style: {textAlign: "center"},
                    // style: {minWidth: 210, textAlign: "right"},
                    sort: false
                },
                {
                    id: 'after_transaction',
                    name: "Tài khoản sau giao dịch (VND)",
                    style: {textAlign: "center"},
                    // style: {minWidth: 210, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'transaction_type',
                    name: "Loại giao dịch",
                    style: {textAlign: "center"},
                    // style: {minWidth: 120, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmn_created_at',
                    name: "Ngày thực hiện",
                    style: {textAlign: "center"},
                    // style: {minWidth: 130, textAlign: "center"},
                    sort: true,
                },
            ],
        }
    }

    componentDidMount() {
        this.getListWalletOnSearch();
        this.getMyWallet();
    }

    getListWalletOnSearch() {
        let {pageNumber, pageSize, status, contractCode, sort, descending, monthCreateAt, transactionType} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            transactionType: transactionType,
            contractCode: contractCode,
            sort: sort,
            descending: descending,
            monthCreateAt: monthCreateAt,
            status: status,
        };
        // console.log('payWallet: ',payload)
        WalletApi.partnerSearchByContractAndType(payload, res => {
            //  console.log('successListWallet>>>>>>>>>>>>>>>', res)
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize),

                })
            } else {
                toast.error("Đã có lỗi xảy ra! Vui lòng tải lại trang!")
                // console.log('Fail>>>>>>>>>>>>>>>>>>>>>>>')
            }

        })
    }

    getMyWallet() {
        PartnerApi.partnerShowMyWallet(res => {
            if (res.success) {
                this.setState({accountBalance: res.data})
            }
        })
    }

    onSearch = () => {
        let {contractCode} = this.state;

        this.setState(
            {
                contractCode: contractCode,
                pageNumber: 0
            },
            () => {
                this.getListWalletOnSearch();
            }
        );
    }

    //*****************************************************************************************************************

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListWalletOnSearch();
            }
        );
    };


    //*****************************************************************************************************************
    render() {
        let {pageNumber, pageSize, totalItem, accountBalance, listData} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="wallet-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý ví"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20 mb-2">
                                            <div
                                                className='col-xs-12 col-sm-12 col-md-8 col-lg-4 p-0 pr-3 d-flex justify-content-between'>
                                                <label className="label-item">Số dư tài khoản</label>
                                                <span className='pl-2 text-right font-weight-bold'
                                                      style={{fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif'}}
                                                >
                                                    <NumberFormat
                                                        value={accountBalance}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" VND"}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    maxLength={20} type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    value={this.state.contractCode}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') this.getListWalletOnSearch()
                                                    }}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            contractCode: event.target.value.trim()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3'>
                                                <label className="label-item">Loại giao dịch</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.transactionType}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            transactionType: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='RECHARGE_MONEY'>Nạp tiền cọc</option>
                                                    <option value='EXPERT_MONEY'>Trừ tiền cọc</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-6 col-lg-4 col-md-4 p-0'>
                                                <label className="label-item">Thời gian</label>
                                                {/*<div className='d-inline-flex'>*/}
                                                {/*<DatePicker*/}
                                                {/*    dropdownMode="select"*/}
                                                {/*    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"*/}
                                                {/*    placeholderText="Từ ngày"*/}
                                                {/*    selected={this.state.startDate_temp === null ? "" : new Date(this.state.startDate_temp)}*/}
                                                {/*    maxDate={new Date()}*/}
                                                {/*    dateFormat="dd-MM-yyyy"*/}
                                                {/*    isClearable={this.state.startDate_temp === null ? false : true}*/}
                                                {/*    selectsStart*/}
                                                {/*    showMonthDropdown*/}
                                                {/*    showYearDropdown*/}
                                                {/*    onChange={*/}
                                                {/*        (date) => {*/}
                                                {/*            this.setState({*/}
                                                {/*                startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),*/}
                                                {/*            })*/}
                                                {/*        }*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                                {/*<DatePicker*/}
                                                {/*    dropdownMode="select"*/}
                                                {/*    className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"*/}
                                                {/*    placeholderText="Đến ngày"*/}
                                                {/*    selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}*/}
                                                {/*    selectsEnd*/}
                                                {/*    showMonthDropdown*/}
                                                {/*    showYearDropdown*/}
                                                {/*    disabled={this.state.startDate_temp === null ? true : false}*/}
                                                {/*    isClearable={this.state.endDate_temp === null ? false : true}*/}
                                                {/*    dateFormat="dd-MM-yyyy"*/}
                                                {/*    onChange={*/}
                                                {/*        (date) => {*/}
                                                {/*            this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})*/}
                                                {/*        }*/}
                                                {/*    }*/}
                                                {/*    startDate={new Date(this.state.startDate_temp)}*/}
                                                {/*    endDate={new Date(this.state.endDate_temp)}*/}
                                                {/*    minDate={this.state.startDate_temp === null ? new Date() : new Date(this.state.startDate_temp)}*/}
                                                {/*/>*/}
                                                {/*</div>*/}
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.monthCreateAt}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            monthCreateAt: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='1'>Tháng 1</option>
                                                    <option value='2'>Tháng 2</option>
                                                    <option value='3'>Tháng 3</option>
                                                    <option value='4'>Tháng 4</option>
                                                    <option value='5'>Tháng 5</option>
                                                    <option value='6'>Tháng 6</option>
                                                    <option value='7'>Tháng 7</option>
                                                    <option value='8'>Tháng 8</option>
                                                    <option value='9'>Tháng 9</option>
                                                    <option value='10'>Tháng 10</option>
                                                    <option value='11'>Tháng 11</option>
                                                    <option value='12'>Tháng 12</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                                <button
                                                    type="button"
                                                    style={{height: 35}}
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.onSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        style={{height: 34}}
                                                        onClick={() => {
                                                            this.setState({
                                                                contractCode: '',
                                                                transactionType: '',
                                                                // startDate_temp: null,
                                                                // endDate_temp: null,
                                                                monthCreateAt: '',
                                                                pageNumber: 0
                                                            }, () => this.getListWalletOnSearch())
                                                        }}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className='pb-3'>
                                        <div style={{overflow: 'auto'}}>
                                            <table className="table table-striped table-data table-bordered">
                                                <thead>
                                                <TableHeader header={this.state.header} clickSort={(data) => {
                                                    console.log(data);
                                                    let a = '';
                                                    if (data.sortAsc == null) {
                                                        a ='transaction_value'

                                                    } else {
                                                        a = `${data.sortId}=${data.sortAsc}`;
                                                    }
                                                    // console.log(a);
                                                    this.setState({
                                                        sort: a
                                                    }, () => this.getListWalletOnSearch())
                                                }}/>
                                                </thead>
                                                {!this.state.listData || this.state.listData.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <div className='text-center'>Không có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.listData.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {/*<span style={{cursor: 'pointer', color: '#1b90bd'}}>*/}
                                                                {row.contractCode}
                                                                {/*</span>*/}
                                                            </td>
                                                            <td style={{textAlign: 'left'}}>{row.transactionContent}</td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.transactionValue}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.beforeTransaction}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.afterTransaction}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                {renderType(row.transactionType)}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                {moment(row.cmnCreatedAt).format(dateFormat)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                        <div className="pagination-right mt-2">
                                            <Pagination
                                                activePage={this.state.pageNumber + 1}
                                                itemsCountPerPage={this.state.pageSize}
                                                totalItemsCount={this.state.totalItem}
                                                changeHandler={page => this.handleChangePage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WalletManagement
