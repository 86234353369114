import React, {Component} from "react";
import moment from "moment";
import {Link} from "react-router-dom";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import NumberFormat from "react-number-format";
import TableHeader from "../../../../components/TableHeader";

import lang from "../../contracts-management/lang";
import ModalRatingAccountant from "../../../../components/Modal/modalRatingAccountant";
import CommentInDetail from "../../../common/comment/commentInDetail";

import PublicContractApi from "../../../../services/ob-core/public-contract";
import AccountantApi from "../../../../services/ob-user/accountant";
import ModalCloseJob from "../../../../components/Modal/modalCloseJob";
import {toast} from "react-toastify";
import ModalAddJob from "../../../../components/Modal/create-contract-modal/modalAddJob";
import ModalAssignAccountant from "../../../../components/Modal/modalAssignAccountant";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import JobApi from "../../../../services/ob-core/public-job";

const formatDate = "DD-MM-YYYY";

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: false,
            showCloseJob: false,
            itemSelected: {},
            jobId: "",
            code: "",
            status: "",
            mess: "",
            value: "",
            type: "",
            paymentStatus: "",
            supervisorId: "",
            paymentCycle: "",
            servicePlanId: "",
            customerId: "",
            startDate: "",
            accountantId: "",
            paid: "",
            unpaid: "",
            customerName: "",
            servicePlanName: "",
            id: null,
            contractId: null,
            listTimeLine: [],
            listData: [],
            listAccId: [],
            listSupId: [],
            steps: [],
            steps2: [],
            modalAddJob: false,

            listmessage: [
                {
                    name: "Admin",
                    mess: "Bạn cần thanh toán đúng hạn!"
                },
                {
                    name: "Thạch",
                    mess: "Mình sẽ thanh toán tất cả trong một lần"
                },
                {
                    name: "Admin",
                    mess: "Cảm ơn bạn rất nhiều."
                },
                {
                    name: "Thạch",
                    mess: "Tôi rất thích One Book For"
                }
            ],
            headerJob: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên Job",
                    style: {minWidth: 240, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'numberOfReports',
                    name: "Số lượng báo cáo",
                    style: {minWidth: 140, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'implementer',
                    name: "Người thực hiện",
                    style: {minWidth: 160, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 100, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'receiveMoney',
                    name: "Số tiền",
                    style: {minWidth: 160, textAlign: "right"},
                    sort: false,
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center", cursor: 'unset'},
                    sort: false
                }
            ],
        };
    }

    componentDidMount() {
        // console.log("lolololololo: ", this.props.value);
        document.title = "Chi tiết hợp đồng";
        // this.getListByContractFromAPI();
        // this.getDetailFromAPI();
        this.getDetailContractFromAPI();
    }

    //******************************************************************************************************************

    getDetailContractFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.value
        };

        // console.log("dataContract>>>>>>>", payload);
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success === true) {
                let listAccId = [];
                let listSupId = [];
                let dataTemp = res.data.jobs
                dataTemp.map(item => {
                    if (item.isSupervisor){
                        if (item.status !== "UNFULFILLED") {
                            listSupId.push(item.assignerId);
                        }
                    }else {
                        listAccId.push(item.assignerId);
                    }
                });
                this.setState({
                    listData: res.data,
                    listTimeLine: res.data.paymentTimelines,
                    dataJob: res.data.jobs,
                    listAccId,
                    listSupId,
                }, () => {
                    if (listAccId.length > 0) {
                        this.getListAccountantByIds()
                    }
                    if (listSupId.length > 0) {
                        this.getListSupByIdFromAPI()
                    }
                });
            } else {
                toast.error('Đã xảy ra lỗi. Vui lòng tải lại trang !')
            }
        });
    }
    getListJobFromAPI() {
        let {contractCode, status_temp, pageNumber, pageSize, sort,} = this.state;
        let payload = {
            contractCode: contractCode,
            status: status_temp,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,

        };

        JobApi.searchByContractCodeAndStatus(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listAccId = [];
                let listSupId = [];
                //accountantId
                data.map(item => {
                    if (item.isSupervisor){
                        if (item.status !== "UNFULFILLED") {
                            listSupId.push(item.assignerId);
                        }
                    }else {
                        listAccId.push(item.assignerId);
                    }
                });
                this.setState({
                    dataJob: res.data.content,
                    listAccId,
                    listSupId,
                }, () => {
                    if (listSupId.length > 0)
                        this.getListSupByIdFromAPI();
                    if (listAccId.length > 0)
                        this.getListAccountantByIds();
                });
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        });
    }
    getListSupByIdFromAPI() {
        let {listSupId} = this.state;

        let payload = {
            ids: listSupId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                let {dataJob} = this.state;
                dataJob.map(item => {
                    data.map(account => {
                        if(account.id == item.assignerId){
                            item.accountantName = account.fullName;
                        }
                    });
                });
                this.setState({
                    dataJob
                });
            } else {

            }
        });
    }
    getListAccountantByIds = () => {
        let {listAccId} = this.state;
        let payload = {
            ids: listAccId
        };

        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let dataTemp = res.data;
                let {dataJob} = this.state;
                dataTemp.map(item => {
                    dataJob.map(account => {
                        if(account.id == item.assignerId){
                            item.accountantName = account.fullName;
                        }
                    });
                });
                this.setState({
                    dataJob
                });
            } else {

            }
        });
    }

    addJob = (name, numberOfReports, receiveMoney) => {
        let {dataJob, jobTotalMoney, contractPrice} = this.state
        let jobTotalMoneyNew = jobTotalMoney + parseInt(receiveMoney.replace(/,/g, ''))
        dataJob.push({
            name: name,
            numberOfReports: numberOfReports,
            receiveMoney: parseInt(receiveMoney.replace(/,/g, '')),
            // receiveMoney: receiveMoney.replace(/,/g,'')
        })
        // console.log("check>>>>>>>>", jobTotalMoney, jobTotalMoneyNew, contractPrice < jobTotalMoneyNew);
        if (contractPrice < jobTotalMoneyNew) toast.warn("Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        this.setState({
            dataJob,
            modalAddJob: false,
            jobsListErr: false,
            jobTotalMoney: jobTotalMoneyNew,
            jobValueErr: contractPrice < jobTotalMoneyNew
        })
    }

    //******************************************************************************************************************

    renderStatusSteper(data) {
        switch (data.status) {
            case "CANCELED":
                return null;
            case "WAIT_SIGN":
            case "SIGNED":
                return 0;
            case "NO_ASSIGN":
                return 1;
            case "WAITING_FOR_CONFIRMATION":
                return 2;
            case "REJECTED":
                return 3;
            case "PROCESSING":
                return 3;
            case "LIQUIDATED":
                return 4;
            case "CLOSED":
                return 5;
            default:
                return;
        }
    }

    renderStatus(assignStatus) {
        switch (assignStatus) {
            case "WAIT_SIGN":
                return "Chờ ký";
            case "PROCESSING":
                return "Kế toán xác nhận";
            case "NO_ASSIGN":
                return "Chưa assign";
            case "WAITING_FOR_CONFIRMATION":
                return "Chờ kế toán xác nhận";
            case "REJECTED":
                return "Kế toán từ chối";
            case "LIQUIDATED":
                return 'Thanh lý';
            case "CANCELED":
                return "Đã hủy;"
            case "CLOSED":
                return "Đã đóng";
            default:
                return;
        }
    }

    renderStatusJob = (status) => {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-dark">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return '';
        }
    }

    renderPaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return " Đã thanh toán";
            case "UNPAID":
                return "Chưa thanh toán";
            case "PAYMENT_TERM":
                return "Đến hạn thanh toán";
            case "PARTIALLY_PAID":
                return "Thanh toán";

            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return "Hợp đồng đăng kí mới";
            case "EXTENSION":
                return "Hợp đồng gia hạn";

            default:
                return;
        }
    }

    renderPaymentCycle(paymentCycle) {
        switch (paymentCycle) {
            case "PAYMENT_1_TIMES":
                return 0;
            case "PAYMENT_2_TIMES":
                return 1;

            default:
                return;
        }
    }
    showModalAssignAccountant = item => {
        console.log(item);
        this.setState({jobId: item.id, selectedContract: item.contractId, itemSelected: item, modalAssignAccountant: true});
    };

    //******************************************************************************************************************

    render() {
        let {
            activeStep,
            steps,
            steps2,
            activeStep2,
            rows,
            listmessage,
            id,
            listData,
            listStatus,
            code,
            status,
            listTimeLine
        } = this.state;
        return (
            <div style={{padding: 0}}>
                <div style={{fontSize: 20}}>TÌNH TRẠNG HỢP ĐỒNG</div>
                <div className="row">
                    <div className="row d-flex" style={{marginLeft: 0, width: 90}}>
                        <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                            <div
                                className="align-self-end mt-10"
                                style={{fontSize: 14, marginTop: 80, width: "100%"}}
                            ></div>
                            <div
                                className="align-self-start"
                                style={{fontSize: 14, width: "100%"}}
                            ></div>
                        </div>
                        <div
                            className="align-self-end mt-10"
                            style={{fontSize: 12, marginTop: 54, width: "100%"}}
                        ></div>
                        <div
                            className="align-self-start"
                            style={{fontSize: 12, width: "100%"}}
                        ></div>
                    </div>
                    <Stepper
                        alternativeLabel
                        className="d-flex col-10 justify-content-center mr-5"
                        activeStep={this.renderStatusSteper(listData)}
                    >
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                {listData.assignStatus == 'SIGNED' ? <div>Đã kí</div> : <div>Chờ kí</div>}
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chưa assign</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chờ kế toán xác nhận</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                {listData.assignStatus === "REJECTED" ? <div>Kế toán từ chối</div> :
                                    <div>Đang thực hiện</div>}
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Thanh lý hợp đồng</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đã đóng</div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                {listData.status != "WAIT_SIGN" ? (
                    <div>
                        <div style={{fontSize: 20}}>TÌNH TRẠNG THANH TOÁN</div>

                        <div className="row">
                            <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                                <div
                                    className="align-self-end mt-10"
                                    style={{
                                        fontSize: 14,
                                        marginTop: 60,
                                        width: "100%",
                                        minWidth: 110
                                    }}
                                >
                                    Hạn thanh toán
                                </div>
                                <div
                                    className="align-self-start"
                                    style={{fontSize: 14, width: "100%"}}
                                >
                                    Thực tế
                                </div>
                            </div>
                            <Stepper
                                alternativeLabel
                                className="d-flex col-10 justify-content-center"
                                activeStep={this.renderPaymentCycle(listData.paymentCycle)}
                            >
                                {listTimeLine.map(row => (
                                    <Step style={{width: 0}}>
                                        <StepLabel className="d-flex">
                                            <NumberFormat
                                                value={row.paymentAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                            {row.paymentStatus == "PAYMENT_TERM" ? (
                                                <div style={{color: "red"}}>
                                                    {row.paymentTerm ? moment(row.paymentTerm).format(formatDate) : ''}
                                                </div>
                                            ) : (
                                                <div>{moment(row.paymentTerm).format(formatDate)}</div>
                                            )}
                                            {row.paymentStatus != "PAYMENT_TERM" ? (
                                                <div>{row.paymentReal ? moment(row.paymentReal).format(formatDate) : ''}</div>
                                            ) : (
                                                <div>&nbsp;&nbsp;</div>
                                            )}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div style={{fontSize: 20}}>
                    <span>THÔNG TIN CHI TIẾT HỢP ĐỒNG </span>
                </div>
                <div style={{fontSize: 15, padding: 15}}>
                    <div className="row">
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Tên khách hàng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                          <span style={{fontWeight: "bold"}}>
                            {listData.customerName}
                          </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Gói dịch vụ</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              {listData.servicePlanName}
                          </span>
                        </div>

                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Giá trị hợp đồng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <NumberFormat
                                style={{fontWeight: "bold"}}
                                value={listData.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={" VND"}
                            />
                        </div>

                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Ngày bắt đầu</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              {moment(listData.startDate).format(formatDate)}
                          </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                        <div className='col-9'>
                            <span className='text-header-profile'>THÔNG TIN JOB</span>
                        </div>
                        {/* <div className='col-3 mb-2 ml-4'>
                            <div className='d-flex justify-content-end'>
                                <button className="btn btn-primary" onClick={()=>{
                                    this.setState({modalAddJob: true})
                                }}>
                                    Thêm mới
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <div className='col-md-12' style={{overflow: 'auto'}}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerJob} clickSort={(data) => {
                            }}/>
                            </thead>
                            {!this.state.dataJob || this.state.dataJob.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={7}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.dataJob.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                        <td style={{textAlign: 'center'}}>{row.countDone}/{row.numberOfReports}</td>
                                        <td style={{textAlign: 'left'}}>{row.accountantName}</td>
                                        <td style={{textAlign: 'center'}}>{this.renderStatusJob(row.status)}</td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.receiveMoney}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        <td style={{minWidth: 150}}>
                                            <button
                                                className="btn btn-action"
                                                title="Assign"
                                                onClick={() =>
                                                    this.showModalAssignAccountant(row)
                                                }
                                                disabled={row.status == "CLOSED"}
                                                style={{cursor: row.status == "CLOSED" ? "pointer" : "unset"}}
                                            >
                                                <i className="ti-location-arrow"/>
                                            </button>
                                            <button className="btn btn-action"
                                                    title='Đóng Job'
                                                    disabled={row.status != "PROCESSING" || row.countDone != row.numberOfReports}
                                                    onClick={() => {
                                                        this.setState({selectedJob: row, showCloseJob: true})
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-check-box"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-6 pl-0" style={{fontSize: 20}}>
                        COMMENT
                    </div>
                    <div className="col-6 d-flex justify-content-end"></div>
                </div>
                <CommentInDetail contractId={this.props.value}/>
                {listData.status === "LIQUIDATED" || listData.status === "CLOSED" ? (
                    <div
                        className="row mt-4 pt-3"
                        style={{
                            borderTop: "1px dotted #516f7782"
                        }}
                    >
                        <div className="col-sm-6 col-md-6 p-0 ">
                            <span> ĐÁNH GIÁ KẾ TOÁN</span>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <button
                                className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                                style={{height: 37, marginLeft: 10}}
                                onClick={() => this.setState({showRate: true})}
                            >
                                Đánh giá
                            </button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {this.state.showRate && (
                    <ModalRatingAccountant
                        contract={this.state.listData}
                        onHide={() => this.setState({showRate: false})}
                    />
                )}

                {this.state.showCloseJob && <ModalCloseJob job={this.state.selectedJob}
                                                           onHide={() => this.setState({showCloseJob: false})}
                                                           onRefresh={() => this.setState({showCloseJob: false}, () => this.getDetailContractFromAPI())}
                />}
                {this.state.modalAssignAccountant &&
                <ModalAssignAccountant
                    show={this.state.modalAssignAccountant}
                    onHide={() => this.setState({modalAssignAccountant: false})}
                    onSuccess={() => this.setState({modalAssignAccountant: false}, () => this.getListJobFromAPI())}
                    data={this.state.selectedJob}
                    contractId={this.state.selectedContract}
                    jobId={this.state.jobId}
                />}
                {this.state.modalAddJob && <ModalAddJob addJob={this.addJob} onHide={() => {
                    this.setState({modalAddJob: false})
                }}/>}
            </div>
        );
    }
}

export default Information;
