import React, {Component} from "react";
import {withSnackbar} from "notistack";

import moment from 'moment'

import Pagination from "../../../../components/Pagination";
import Sidebar from "../../../../components/Sidebar";
import NumberFormat from "react-number-format";
import TableHeader from "../../../../components/TableHeader";
import DatePicker from "react-datepicker";
import ModalDetailReport from "../../../../components/Modal/modalDetailReport";
import UploadReportFile from "../../../../components/Modal/modalUploadReport";
import {Modal} from "react-bootstrap";

import FilesApi from "../../../../services/resource/files";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import PublicReportContractApi from "../../../../services/ob-core/public-report-contract";
import {toast} from "react-toastify";
import AccountantApi from "../../../../services/ob-user/accountant";

const dateFormat = 'DD-MM-YYYY'

const renderStatus = function (status) {
    switch (status) {
        case "AWAITING_APPROVAL":
            return <h5><span className="badge badge-secondary">Chờ duyệt</span></h5>;
        case "APPROVED":
            return <h6><span className="badge badge-success">Đã duyệt</span></h6>;
        case "REJECTED":
            return <h5><span className="badge badge-warning">Đã từ chối</span></h5>;
        default:
            return '';
    }
};
const renderType = function (value) {
    switch (value) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
};


class ListReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailReason: false,
            showBlock: false,
            showDelete: false,
            itemSelected: {},
            listAccountantId: [],
            data: [],
            page: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            sort: 'id',
            status: '',
            status_temp: '',
            modalDetailReport: false,
            modalUploadFile: false,
            startDate: '',
            endDate: '',
            startDate_temp: null,
            endDate_temp: null,
            rejectReason: '',
            header: [
                {
                    id: '123',
                    name: "Thời gian",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'address',
                    name: "Tên báo cáo",
                    style: {textAlign: "left"},
                    sort: true,
                },
                {
                    id: 'numOfCustomer',
                    name: "Loại báo cáo",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'numOfCustomer',
                    name: "Kế toán thực hiện",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 150, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentDidMount() {
        // this.getAllServicePlan();
        // console.log("props report page>>>>", this.props)
        this.getListReport()
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    getListReport() {
        let {pageNumber, pageSize, status, sort, startDate, endDate} = this.state;
        let payload = {
            contractId: this.props.value,
            startDate: startDate,
            endDate: endDate,
            pageNumber: pageNumber,
            pageSize: pageSize,
            status,
            // sort
        };
        // console.log('CHECK_ABC', payload);
        PublicReportContractApi.searchByContractIdAndDateAndStatus(payload, res => {
            if (res.success === true) {
                let data = res.data.content;
                let listAccountantId = []
                data.map((item) => {
                    listAccountantId.push(item.accountantId)
                })
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    listAccountantId
                }, () => {
                    if (listAccountantId.length > 0) {
                        this.getAccountantNameById()
                    }
                })

            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        });
    }

    getAccountantNameById = () => {
        let {listAccountantId, data} = this.state;

        let payload = {
            ids: listAccountantId
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success) {
                let dataRes = res.data;
                data.map(item => {
                    let filter = dataRes.filter(x => x.id == item.accountantId);
                    item.accountantName = filter[0]?.fullName;
                });

                this.setState({
                    data
                });
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        });
    }

    approveReport = () => {
        let payload = {
            id: this.state.fileId,
        }
        PublicReportContractApi.supervisorConfirm(payload, res => {
            if (res.success) {
                this.setState({modalApproveReport: false}, () => {
                    toast.success("Duyệt báo cáo thành công!")
                    this.getListReport()
                })
            } else {
                toast.error("Duyệt báo cáo không thành công! ")
            }
        })
    }

    rejectReport = () => {
        let payload = {
            id: this.state.fileId,
            body: {
                rejectReason: this.state.rejectReason
            }
        }
        PublicContractApi.reject(payload, res => {
            if (res.success) {
                this.setState({modalRejectReport: false}, () => {
                    this.props.enqueueSnackbar('Từ chối báo cáo thành công!', {
                        variant: 'success',
                    });
                    this.getListReport()
                })
            } else {
                this.props.enqueueSnackbar('Từ chối báo cáo không thành công! ' + res.message, {
                    variant: 'error',
                });
            }
        })
    }


    onhandleSearch = () => {
        let {startDate_temp, endDate_temp} = this.state;

        this.setState(
            {
                startDate: startDate_temp,
                endDate: endDate_temp,
                pageNumber: 0
            },
            () => {
                this.getListReport();
            }
        );
    };

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListReport();
            }
        );
    }

    onShowModalDetailReport = (item) => {
        this.setState({selectedReport: item, modalDetailReport: true})
    }

    downloadReport = () => {
        let payload = {
            fileId: this.state.selectedFile.id,
            customerId: this.props.customerId 
        }
        FilesApi.downloadReportFileByIdCus(payload, res => {
            if (res.success) {
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${this.state.selectedFile.name}.${res.data.extension}`
                a.click();
            }
        })
    }

    showModalUploadFile = () => {
        this.setState({modalUploadFile: true})
    }

    onReload = () => {
        console.log("check>>>")
        this.setState({modalUploadFile: false}, () => {
            // window.location.reload()
            this.getListReport()
            // this.createContractReport()
        })
    }

    // createContractReport = () => {
    //     let payload = {
    //         contentType: "string",
    //         contractId: 0,
    //         reportName: "string",
    //         storageName: "string",
    //         type: "TAX_REPORT"
    //     }
    //     ContractApi.createContractReport(payload, res => {
    //         if (res.success){
    //             console.log(res.data)
    //         }
    //     })
    // }

    renderActionButton = (value) => {
        switch (value) {
            case 'AWAITING_APPROVAL':
                return 'Chờ duyệt';
            case 'APPROVED':
                return 'Đã duyệt';
            case 'REJECTED':
                return 'Đã từ chối';
        }
    }


    render() {
        const {page, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className='p-0  pl-3 pr-0 mb-2'>
                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                        <div className='row kt-margin-b-20'>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3'>
                                <label className="label-item mr-5">Thời gian</label>
                                <div className='d-inline-flex mb-1 w-100'>
                                    <DatePicker
                                        className=" react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Từ ngày"
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.startDate_temp === null ? "" : new Date(this.state.startDate_temp)}
                                        // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                        maxDate={new Date()}
                                        minDate={new Date('2000/01/01')}
                                        // value={this.state.dateIssue}
                                        dateFormat="dd-MM-yyyy"
                                        isClearable={this.state.startDate_temp === null ? false : true}
                                        // fixedHeight
                                        // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                                        selectsStart
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                })
                                            }
                                        }
                                        startDate={new Date(this.state.startDate_temp)}
                                        endDate={new Date(this.state.endDate_temp)}
                                    />
                                    <DatePicker
                                        className="w-auto3 react-datepicker-wrapper react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Đến ngày"
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                        // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                                        selectsEnd

                                        showMonthDropdown
                                        showYearDropdown
                                        disabled={this.state.startDate_temp === null ? true : false}
                                        isClearable={this.state.endDate_temp === null ? false : true}
                                        // fixedHeight
                                        dateFormat="dd-MM-yyyy"
                                        onChange={
                                            (date) => {
                                                this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})
                                            }
                                        }
                                        startDate={new Date(this.state.startDate_temp)}
                                        endDate={new Date(this.state.endDate_temp)}
                                        minDate={this.state.startDate_temp === null ? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
                                    />
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0'>
                                <label className="label-item">Trạng thái</label>
                                <select
                                    value={this.state.status_temp}
                                    onChange={
                                        (e) => {
                                            this.setState({
                                                status_temp: e.target.value,
                                            })
                                        }
                                    }

                                    style={{
                                        marginTop: 4
                                    }}
                                    className="form-control kt-input pd-4-input shadow-none">
                                    <option hidden>Tất cả</option>
                                    <option value="AWAITING_APPROVAL">Chờ duyệt</option>
                                    <option value="APPROVED">Đã duyệt</option>
                                    <option value="REJECTED">Đã từ chối</option>
                                </select>
                            </div>
                            <div className='mt-1 col-xs-12 col-sm-12 col-md-5 align-text-bottom mt-3 p-0'>

                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.onhandleSearch()}
                                    color="primary"
                                >
                                    Tìm kiếm
                                </button>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm"
                                    onClick={() => this.setState({
                                        status_temp: '',
                                        startDate_temp: null

                                    }, () => this.getListReport())}
                                >
                                    Refresh
                                </button>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    disabled={this.props.contract.status === 'CLOSED'}
                                    className="border-1-s background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.showModalUploadFile()}
                                >
                                    Upload
                                </button>
                                {/*<Button*/}
                                {/*    style={{textTransform: "initial", fontSize: 36}}*/}
                                {/*    variant="outlined"*/}
                                {/*    color="default"*/}
                                {/*    onClick={() => {*/}
                                {/*        this.onHandleClearText();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    czxczx*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                    </form>
                </div>
                <div className='pl-3 pr-0 pb-0 mb-0'>
                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>
                    <table
                        className="table table-striped table-data table-bordered"
                    >
                        <table className='table'>
                            <thead>
                            <TableHeader header={this.state.header} clickSort={(data) => {
                                // console.log(data);
                                // let a = '';
                                // if (data.sortAsc == null) {
                                //     a = 'id_sort=asc'
                                // } else {
                                //     if (data.sortId == 'supervisor') {
                                //         a = `supervisor.fullName=${data.sortAsc ? 'asc' : 'desc'}`;
                                //     } else if (data.sortId == 'servicePlan') {
                                //         a = `servicePlan.servicePlanName=${data.sortAsc ? 'asc' : 'desc'}`;
                                //     } else {
                                //         a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                //     }
                                // }
                                // // console.log(a);
                                // this.setState({
                                //     sort: a
                                // }, () => this.getListAccountantOnSearch())
                            }}/>
                            </thead>
                            {!this.state.data || this.state.data.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={12}>
                                        <div className='text-center'>Không có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                :
                                <tbody>
                                {this.state.data?.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{moment(row.cmnCreatedAt).format(dateFormat)}</td>
                                        <td style={{textAlign: 'left',}}>
                                                <span style={{cursor: 'pointer', color: '#0DA9D3'}}>
                                                {row.name}
                                                </span>
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {renderType(row.reportTypeName)}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {row.accountantName || 'Chưa có dữ liệu'}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {renderStatus(row.status)}
                                        </td>
                                        <td style={{textAlign: 'center', fontSize: 18}}>
                                            {/*<button className="btn btn-action"*/}
                                            {/*        disabled={row.status !== 'REJECTED'}*/}
                                            {/*        title='Xem chi tiết'*/}
                                            {/*        onClick={() => this.setState({*/}
                                            {/*            itemSelected: row,*/}
                                            {/*            showDetailReason: true*/}
                                            {/*        })}*/}
                                            {/*        style={{cursor: 'pointer'}}*/}
                                            {/*>*/}
                                            {/*    <i className="ti-book"/>*/}
                                            {/*</button>*/}
                                            <button className="btn btn-action"
                                                    title='Duyệt báo cáo'
                                                    disabled={row.status != "AWAITING_APPROVAL"}
                                                    onClick={() => {
                                                        this.setState({
                                                            fileId: row.id,
                                                            reportName: row.name,
                                                            modalApproveReport: true
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-check-box"/>
                                            </button>
                                            <button className="btn btn-action"
                                                    title='Từ chối báo cáo'
                                                    disabled={row.status != "AWAITING_APPROVAL"}
                                                    onClick={() => {
                                                        this.setState({
                                                            fileId: row.id,
                                                            reportName: row.name,
                                                            modalRejectReport: true
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-close"/>
                                            </button>
                                            <button className="btn btn-action"
                                                    title='Tải xuống'
                                                    onClick={() => {
                                                        this.setState({selectedFile: row}, () => {
                                                            this.downloadReport()
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-download"/>
                                            </button>
                                        </td>
                                        {/*<TableCell align="center">{row.action}</TableCell>*/}
                                    </tr>
                                ))}
                                </tbody>
                            }
                        </table>
                    </table>
                    <div className="row d-flex justify-content-end m-3 font-14">
                        <div className="pagination-right">
                            <Pagination
                                activePage={this.state.pageNumber + 1}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalItem}
                                changeHandler={(page) => this.handleChangePage(page)}
                            />
                        </div>
                    </div>
                    {/*<TablePagination*/}
                    {/*    rowsPerPageOptions={[10, 20, 30, 40]}*/}
                    {/*    component="div"*/}
                    {/*    count={totalItem}*/}
                    {/*    page={page}*/}
                    {/*    rowsPerPage={pageSize}*/}
                    {/*    labelRowsPerPage={'Số kết quả trên trang'}*/}
                    {/*    // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                    {/*    // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                    {/*    labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}*/}
                    {/*    onChangePage={(event, newPage) => {*/}
                    {/*        this.setState({page: newPage}, () => {*/}
                    {/*            // this.getListAccountantOnSearch();*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*    onChangeRowsPerPage={(event) => {*/}
                    {/*        this.setState({*/}
                    {/*            pageSize: event.target.value,*/}
                    {/*            page: page*/}
                    {/*        }, () => {*/}
                    {/*            // this.getListAccountantOnSearch()*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>


                {/*{this.state.modalUploadFile && <UploadFile show={this.state.modalUploadFile} reload={this.onReload} type="report" contractId={this.props.value} onHide={()=> this.setState({modalUploadFile: false})}/>}*/}
                {this.state.modalUploadFile &&
                <UploadReportFile show={this.state.modalUploadFile} customerId={this.props.customerId} reload={this.onReload} contractId={this.props.value} jobs={this.props.jobs}
                                  onHide={() => this.setState({modalUploadFile: false})}/>}
                {this.state.modalApproveReport && <div>
                    <Modal show={true} onHideModal={() => this.setState({modalApproveReport: false})}
                           keyboard={false}
                           backdrop='static' dialogClassName="modal-dialog-centered">
                        <div className="btn-close">
                            <button type="button" className="close"
                                    onClick={() => this.setState({modalApproveReport: false})}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-container">
                                <h1 className="text-center">THÔNG BÁO</h1>
                                <div className="mt-4">
                                    <h6>Bạn có chắc chắn phê duyệt báo cáo <b>{this.state.reportName}</b> này không?
                                    </h6>
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            this.approveReport()
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={() => {
                                            this.setState({modalApproveReport: false})
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}
                {this.state.modalRejectReport && <div>
                    <Modal show={true} onHideModal={() => this.setState({modalRejectReport: false})}
                           keyboard={false}
                           backdrop='static' dialogClassName="modal-dialog-centered">
                        <div className="btn-close">
                            <button type="button" className="close"
                                    onClick={() => this.setState({modalRejectReport: false})}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-container">
                                <h1 className="text-center">THÔNG BÁO</h1>
                                <div className="mt-4">
                                    <h6>Vui lòng nhập lý do huỷ báo cáo <b>{this.state.reportName}</b></h6>
                                    <textarea className="mt-3 form-control" name="" id=""
                                              rows="5"
                                              onChange={(e) => this.setState({rejectReason: e.target.value})}
                                              style={{resize: 'none', width: '100%'}}/>
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            this.rejectReport()
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={() => {
                                            this.setState({modalRejectReport: false})
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}

                {this.state.showDetailReason && <ModalDetailReport show={this.state.showDetailReason}
                                                                   data={this.state.itemSelected}
                                                                   onHide={()=>this.setState({
                                                                       showDetailReason: false
                                                                   })}
                />}
            </div>
        );
    }

}

export default ListReport
