import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import Utils from '../../../utils/utils';

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function onlyNumber(value) {
    return  Utils.filterNumberOnly(value);
}

class modalEditChargePackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id              :  props.data.id,
            servicePlanId   :  props.data.servicePlanId,
            showCreate      : true,
            typeService     : '',
            name            :  props.data.requirementConstraint,
            price           :  props.data.price,
        };
    }

    onSubmit = () => {
        let {name, price, servicePlanId, id} = this.state;
        let item = {
            id: id,
            servicePlanId: servicePlanId,
            price: price,
            requirementConstraint: name,
        };
        this.props.create(item)
        this.setState({name: '', price: '', id: '', servicePlanId: ''})
    }

    render() {
        const modalProp = {
            show: this.props.show,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>SỬA GÓI CƯỚC</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0">Tên gói cước</label>
                                    <input
                                        maxLength={100} type="text"
                                        className="form-control shadow-none"
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({
                                                name: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0">Giá gói cước</label>
                                    <input
                                        type="text"
                                        className="form-control shadow-none"
                                        maxLength={10}
                                        value={formatMoney(this.state.price)}
                                        onChange={(event) => {
                                            // if (this.state.price*1 < 100000000)
                                            this.setState({
                                                price: onlyNumber(event.target.value.trim())
                                            }, () => {
                                                console.log(this.state.price)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    disabled={this.state.name.length === 0 || this.state.price.length === 0}
                                    onClick={() => this.onSubmit()}
                                >
                                    Đồng ý
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                    Hủy bỏ
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default modalEditChargePackage
