export default {
    title: 'Danh sách hợp đồng',
    titleStatus:'TÌNH TRẠNG HỢP ĐỒNG',
    titlePayment:'TÌNH TRẠNG THANH TOÁN',
    breadcrumbs: {
        home: 'Trang chủ',
        titleBreadcrumbs: 'Danh sách hợp đồng'
    },
    input: {
        lableCode: 'Mã hợp đồng',
        lableStatus: 'Trạng thái',
        placeholderStatus: 'Tất cả'

    },
    Button: {
        search: 'Tìm kiếm',
        refresh: 'Làm mới'
    },
    table: {
        code: 'Mã hợp đồng',
        name: 'Tên hợp đồng',
        value: 'Giá trị hợp đồng',
        type: 'Loại hợp đồng',
        supervisor: 'Supervisor xử lý',
        status: 'Trạng thái',
        rowsPerPage: 'Số kết quả trên trang',
    },
    status: {
        waitSign: 'Chờ ký',
        processing: 'Đang thực hiện',
        liquidation: 'Đã thanh lý',
        closed: 'Đóng hợp đồng',
        success:'Đăng kí thành công',
        cancel:'Đã hủy',
    },
    type: {
        new: 'Hợp đồng đăng kí mới',
        extenstion: 'Hợp đồng gia hạn',
    }
}