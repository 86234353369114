import React, {Component} from 'react'


const fakaHistory = {
    dataUpdate: '20-02-2020',
    fullName: 'Nguyễn Văn A',
    reason: 'Lý do chỉnh sửa ................................................................................................................................',
}

class HistoryUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSelected: '',
            data: {}
        };
    }

    componentDidMount() {
        this.setState({
            data: fakaHistory
        })
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <div className='row kt-margin-b-20 mt-4'>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Thời gian chỉnh sửa</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.dataUpdate}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Người chỉnh sửa</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.fullName}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Nội dung</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <p>
                            {data.reason}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoryUpdate
