import React from "react";

class FormInput extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let {label, placeholder, value, checkError, maxLength, errorContent} = this.props
        let type = this.props.type || "text";
        return (
            <div className='row mt-3 col-6'>
                <div
                    className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">{label}
                                            <span className={'color-red d-inline'}>*</span></span>
                </div>
                <div
                    className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">

                    <input type={type}
                           className={checkError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                           placeholder={placeholder}
                           value={value}
                        // aria-label="Username"
                           maxLength={maxLength}
                        // aria-describedby="basic-addon1"
                           onChange={(val) => {
                               // let input = null;
                               // if (input.length == 0) {
                                   // this.setState({
                                   //     placeIssued: val.target.value.trimLeft(),
                                   //     inEditing: true
                                   // })
                                   return this.props.onChange(val.target.value)
                               // }
                               // this.setState({
                               //     placeIssued: val.target.value.trimLeft(),
                               //     inEditing: true
                               // }, () => {
                               //     this.state.placeIssued.length === 0 ? this.setState({
                               //         placeIssuedError: true,
                               //         btnSaveStatus: true
                               //     }) : this.setState({
                               //         placeIssuedError: false,
                               //         btnSaveStatus: false
                               //     })
                               // })
                           }}
                    />
                    {checkError?<span className="pl-1 color-red size-font-err-date">{errorContent}</span>:<div/>}
                </div>
            </div>
        );
    }
}

export default FormInput
