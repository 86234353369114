import React, {Component} from "react"; 
import {Modal} from "react-bootstrap";
import moment from 'moment'

import Pagination from "../../../../components/Pagination";
import TableHeader from "../../../../components/TableHeader";
import Sidebar from "../../../../components/Sidebar";
import NumberFormat from "react-number-format";
import PageHeader from "../../../../components/PageHeader";
import DatePicker from "react-datepicker";
import ModalDetailReport from "../../../../components/Modal/modalDetailReport";
import UploadReportFile from "../../../../components/Modal/modalUploadReport";

import PublicContractApi from "../../../../services/ob-core/public-contract";
import PublicReportContractApi from "../../../../services/ob-core/public-report-contract";
import FilesApi from "../../../../services/resource/files";
import {toast} from "react-toastify";
import {saveAs} from 'file-saver';

const dateFormat = 'DD-MM-YYYY'

const renderStatus = function (status) {
    switch (status) {
        case 'AWAITING_APPROVAL':
            return <h5><span className="badge badge-secondary">Chờ duyệt</span></h5>;
        case 'APPROVED':
            return <h6><span className="badge badge-success">Đã duyệt</span></h6>;
        case 'REJECTED':
            return <h6><span className="badge badge-danger">Đã từ chối</span></h6>;
        default:
            return '';
    }
};
const renderType = function (type) {
    switch (type) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
}


class ListReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            descending: '',
            showBlock: false,
            showDelete: false,
            itemSelected: {},
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            status: '',
            status_temp: '',
            modalDetailReport: false,
            modalUploadFile: false,
            startDate: '',

            endDate: '',
            startDate_temp: null,
            endDate_temp: null,
            rejectReason: '',
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmn_created_at',
                    name: "Thời gian",
                    style: {minWidth: 120, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên báo cáo",
                    style: {minWidth: 150, textAlign: "left"},
                    sort: true,
                },
                {
                    id: 'report_type_name',
                    name: "Loại báo cáo",
                    style: {minWidth: 150, textAlign: "center"},
                    sort: true,
                },

                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: true,
                },
                {
                    id: '',
                    name: "Thao tác",
                    style: {minWidth: 130, textAlign: "center", cursor: 'unset'},
                    sort: false,
                },

            ],
        }
    }

    componentDidMount() {
        // this.getAllServicePlan();
        // console.log("props report page>>>>", this.props)
        this.getListReportFromAPI()
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    getListReportFromAPI() {
        let {pageNumber, pageSize, status, sort, startDate, endDate, descending} = this.state;
        let payload = {
            endDate: endDate,
            startDate: startDate,
            status: status,
            contractId: this.props.value,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            descending: descending
        };
        // console.log('CHECK_ABC', payload);
        PublicReportContractApi.searchByContract(payload, res => {
            if (res.success) {
                // console.log('CHECKDATA', res);

                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),

                })

            } else {
                toast.error("Đã xảy ra lỗi. Vui lòng thử lại@")
            }
        });
    }


    approveReport = () => {
        let payload = {
            id: this.state.fileId,
        }
        PublicReportContractApi.supervisorConfirm(payload, res => {
            if (res.success) {
                this.setState({modalApproveReport: false}, () => {
                    toast.success("Duyệt báo cáo thành công!")  
                    this.getListReportFromAPI()
                })
            } else {
                toast.error("Duyệt báo cáo không thành công! + res.message")  
            }
        })
    }

    rejectReport = () => {
        let payload = {
            id: this.state.fileId,
            body: {
                rejectReason: this.state.rejectReason
            }
        }
        PublicContractApi.reject(payload, res => {
            if (res.success) {
                this.setState({modalRejectReport: false}, () => {
                    toast.success("Từ chối báo cáo thành công!") 
                    this.getListReportFromAPI()
                })
            } else {
                toast.error("Từ chối báo cáo không thành công!") 
            }
        })
    }


    onhandleSearch = () => {
        let {startDate_temp, endDate_temp, status_temp} = this.state;

        this.setState(
            {
                status: status_temp,
                startDate: startDate_temp ? moment(startDate_temp).format('YYYY-MM-DD') : null,
                endDate: endDate_temp ? moment(endDate_temp).format('YYYY-MM-DD') : null,
                pageNumber: 0
            },
            () => {
                this.getListReportFromAPI();
            }
        );
    };

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListReportFromAPI();
            }
        );
    }

    onShowModalDetailReport = (item) => {
        this.setState({selectedReport: item, modalDetailReport: true})
    }

    downloadReport = () => {
        let payload = {
            fileId: this.state.selectedFile.id,
            customerId: this.props.customerId
        }
        FilesApi.downloadReportFileByIdCus(payload, res => {
            if (res.success) {
                // console.log(res.data)
                // window.location.href = 'data:application/octet-stream;base64,' + res.data.content;
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${this.state.selectedFile.name}.${res.data.extension}`
                a.click();
            }
        })
    }

    showModalUploadFile = () => {
        this.setState({modalUploadFile: true})
    }

    onReload = () => {
        this.setState({modalUploadFile: false}, () => {
            // window.location.reload()
            this.getListReportFromAPI()
            // this.createContractReport()
        })
    }

    // createContractReport = () => {
    //     let payload = {
    //         contentType: "string",
    //         contractId: 0,
    //         reportName: "string",
    //         storageName: "string",
    //         type: "TAX_REPORT"
    //     }
    //     ContractApi.createContractReport(payload, res => {
    //         if (res.success){
    //             console.log(res.data)
    //         }
    //     })
    // }

    renderActionButton = (value) => {
        switch (value) {
            case 'AWAITING_APPROVAL':
                return 'Chờ duyệt';
            case 'APPROVED':
                return 'Đã duyệt';
            case 'REJECTED':
                return 'Đã từ chối';
        }
    }


    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListReportFromAPI();
            }
        );
    };


    render() {
        const {page, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className='p-0 pb-2 mb-2'>
                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                        <div className='row kt-margin-b-20'>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3'>
                                <label className="label-item mr-5">Thời gian</label>
                                <div className='d-inline-flex w-100'>
                                    <DatePicker

                                        dropdownMode="select"
                                        className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Từ ngày"
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.startDate_temp === null ? "" : new Date(this.state.startDate_temp)}
                                        // value={this.state.startDate_temp}
                                        maxDate={new Date()}
                                        // minDate={new Date()}
                                        // value={this.state.dateIssue}
                                        dateFormat="dd-MM-yyyy"
                                        isClearable={this.state.startDate_temp === null ? false : true}
                                        // fixedHeight
                                        // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                                        selectsStart
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    startDate_temp: date === null ? null : date,
                                                })
                                            }
                                        }
                                    />
                                    <DatePicker
                                        dropdownMode="select"
                                        className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Đến ngày"
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                        // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                                        selectsEnd

                                        showMonthDropdown
                                        showYearDropdown
                                        disabled={this.state.startDate_temp === null ? true : false}
                                        isClearable={this.state.endDate_temp === null ? false : true}
                                        // fixedHeight
                                        dateFormat="dd-MM-yyyy"
                                        onChange={
                                            (date) => {
                                                this.setState({endDate_temp: date === null ? null : date})
                                            }
                                        }
                                        startDate={new Date(this.state.startDate_temp)}
                                        endDate={new Date(this.state.endDate_temp)}
                                        minDate={this.state.startDate_temp === null ? new Date() : new Date(this.state.startDate_temp)}
                                    />

                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0'>
                                <label className="label-item">Trạng thái</label>
                                <select
                                    value={this.state.status_temp}
                                    onChange={val =>
                                        this.setState(
                                            {status_temp: val.target.value},
                                            () => console.log(this.state.state_temp)
                                        )
                                    }
                                    style={{
                                        marginTop: 4
                                    }}
                                    className="form-control kt-input pd-4-input shadow-none">
                                    <option value=''>Tất cả</option>
                                    <option value="AWAITING_APPROVAL">Chờ duyệt</option>
                                    <option value="APPROVED">Đã duyệt</option>
                                    <option value="REJECTED">Đã từ chối</option>
                                </select>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 text-left p-0 mt-3'>

                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.onhandleSearch()}
                                    color="primary"
                                >
                                    Tìm kiếm
                                </button>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm"
                                    onClick={() => this.setState({
                                        status_temp: "",
                                        startDate_temp: null,
                                        endDate_temp: null,
                                    }, () => this.onhandleSearch())}
                                >
                                    Refresh
                                </button>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    disabled={this.props.contract.status === 'CLOSED'}
                                    className="border-1-s background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.showModalUploadFile()}
                                >
                                    Upload
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
                <div className='table'>
                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>
                    <table
                        className="table table-striped table-data table-bordered table-responsive"
                    >
                        <table className='table'>
                            <thead>
                            <TableHeader
                                header={this.state.header}
                                clickSort={data => {
                                    console.log(data);
                                    let a = `${data.sortId}`;
                                    // if (data.sortAsc == null) {
                                    //     // a = `type`
                                    // } else {
                                    //     a = `${data.sortId}=${data.sortAsc}`;
                                    // }
                                    // console.log(a);
                                    this.setState(
                                        {
                                            sort: a,
                                            descending:!data.sortAsc?"ASC":"desc",
                                        },
                                        () => this.getListReportFromAPI()
                                    );

                                }}/>
                            </thead>
                            {!this.state.listData || this.state.listData.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={12}>
                                        <div className='text-center'>Không có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                :
                                <tbody>
                                {this.state.listData?.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td>

                                            {this.state.pageNumber * this.state.pageSize +
                                            index +
                                            1}
                                        </td>
                                        <td style={{textAlign: 'left'}}>{moment(row.cmnCreatedAt).format(dateFormat)}</td>
                                        <td style={{textAlign: 'left',}}>
                                                <span>
                                                {row.name}
                                                </span>
                                        </td>
                                        <td style={{textAlign: 'left'}}>
                                            {renderType(row.reportTypeName)}
                                            {/*{row.reportTypeName}*/}
                                        </td>

                                        <td style={{textAlign: 'center'}}>
                                            {renderStatus(row.status)}
                                        </td>
                                        <td style={{textAlign: 'center', fontSize: 18}}>
                                            {/*<button className="btn btn-action"*/}
                                            {/*        title='Xem chi tiết'*/}
                                            {/*    // onClick={() => this.onShowModalDetailReport(row)}*/}
                                            {/*        style={{cursor: 'pointer'}}*/}
                                            {/*>*/}
                                            {/*    <i className="ti-book"/>*/}
                                            {/*</button>*/}
                                            <button className="btn btn-action"
                                                    title='Tải xuống'
                                                    onClick={() => {
                                                        this.setState({selectedFile: row}, () => {
                                                            this.downloadReport()
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-download"/>
                                            </button>
                                            {/*<button className="btn btn-action"*/}
                                            {/*        title='Duyệt báo cáo'*/}

                                            {/*    // onClick={() => {*/}
                                            {/*    //     this.setState({*/}
                                            {/*    //         fileId: row.id,*/}
                                            {/*    //         reportName: row.name,*/}
                                            {/*    //         modalApproveReport: true*/}
                                            {/*    //     })*/}

                                            {/*        style={{cursor: 'pointer'}}>*/}
                                            {/*    <i className="ti-arrow-down"/>*/}
                                            {/*</button>*/}

                                        </td>
                                        {/*<TableCell align="center">{row.action}</TableCell>*/}
                                    </tr>
                                ))}
                                </tbody>
                            }
                        </table>
                    </table>

                    <div style={{height: 40}} className="pagination-right">
                        <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItem}
                            changeHandler={(page) => this.handleChangePage(page)}
                        />

                    </div>
                </div>

                {this.state.modalDetailReport && <ModalDetailReport report={this.state.selectedReport}
                                                                    onHide={() => this.setState({modalDetailReport: false})}/>}
                {/*{this.state.modalUploadFile && <UploadFile show={this.state.modalUploadFile} reload={this.onReload} type="report" contractId={this.props.value} onHide={()=> this.setState({modalUploadFile: false})}/>}*/}
                {this.state.modalUploadFile &&
                <UploadReportFile show={this.state.modalUploadFile} reload={this.onReload} customerId={this.props.customerId} contractId={this.props.value} jobs={this.props.jobs}
                                  onHide={() => this.setState({modalUploadFile: false})}/>}
                {this.state.modalApproveReport && <div>
                    <Modal show={true} onHideModal={() => this.setState({modalApproveReport: false})}
                           keyboard={false}
                           backdrop='static' dialogClassName="modal-dialog-centered">
                        <div className="btn-close">
                            <button type="button" className="close"
                                    onClick={() => this.setState({modalApproveReport: false})}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-container">
                                <h1 className="text-center">THÔNG BÁO</h1>
                                <div className="mt-4">
                                    <h6>Bạn có chắc chắn phê duyệt báo cáo <b>{this.state.reportName}</b> này không?
                                    </h6>
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            this.approveReport()
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={() => {
                                            this.setState({modalApproveReport: false})
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}
                {this.state.modalRejectReport && <div>
                    <Modal show={true} onHideModal={() => this.setState({modalRejectReport: false})}
                           keyboard={false}
                           backdrop='static' dialogClassName="modal-dialog-centered">
                        <div className="btn-close">
                            <button type="button" className="close"
                                    onClick={() => this.setState({modalRejectReport: false})}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-container">
                                <h1 className="text-center">THÔNG BÁO</h1>
                                <div className="mt-4">
                                    <h6>Vui lòng nhập lý do huỷ báo cáo <b>{this.state.reportName}</b></h6>
                                    <textarea className="mt-3 form-control" name="" id=""
                                              rows="5"
                                              onChange={(e) => this.setState({rejectReason: e.target.value})}
                                              style={{resize: 'none', width: '100%'}}/>
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            this.rejectReport()
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={() => {
                                            this.setState({modalRejectReport: false})
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}
            </div>
        );
    }

}

export default ListReport;
