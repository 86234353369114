import React, {Component} from "react";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import '../../../assets/css/Supervisor.css';

import Utils from "../../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Grid,
    Button,
    Radio,
    CircularProgress,
} from '@material-ui/core';
import Sidebar from "../../../components/Sidebar";
import Dropzone from 'react-dropzone';
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";

import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar"
import ModalConfirmDeleteCert from "../../../components/Modal/modalConfirmDeleteCert";
import UploadFile from "../../../components/Modal/modalUploadFile";
// import ModalCropImage from "../../../components/Modal/ModalCropImage"

import PartnerApi from "../../../services/ob-user/partner";
import {toast} from "react-toastify";
import { checkPermission } from "../../../utils/common";
import AdminOneBookApi from "../../../services/ob-user/one-book-controller";

const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


const renderSex = function (value) {
    switch (value) {
        case '1':
            return 'MALE';
        case '0':
            return 'Nhỏ';
        default:
            return 'Siêu nhỏ';
    }
};


// const renderBusiness = function (business) {
//     switch (business) {
//         case 'COMMERCE_SERVICE':
//             return 'Thương mại - Dịch vụ';
//         case 'MANUFACTURING_MACHINING':
//             return 'Sản xuất - Gia công';
//         default:
//             return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
//     }
// };

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

const scale = [
    {
        value: "MEDIUM",
        name: "Vừa",
    },
    {
        value: "EXTRASMALL",
        name: "Siêu nhỏ",
    },
    {
        value: "SMALL",
        name: "Nhỏ",
    },

]

class ProfilePartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            showUpload: false,
            dataPartner: [],
            listProvince: [],
            listDistrict: [],
            files: [],
            filesCertificate: [],
            modalConfirmDeleteCert: false,
            fileId: null,
            modalUploadAvatar: false,
            showLoading: false,
            taxcode:'',
            phone:'',
            email:'',
            provinceId:'',
            districtId:'',
            address:'',
            website:'',

            representativeName:'',
            representativeSex:'',
            identify:'',
            dateIssue:'',
            placeIssue:'',
            position:'',

            taxcodeErr:false,
            phoneErr:false,
            emailErr:false,
            provinceIdErr:false,
            districtIdErr:false,
            addressErr:false,
            websiteErr:false,
            representativeNameErr:false,
            representativeSexErr:false,
            identifyErr:false,
            dateIssueErr:false,
            placeIssueErr:false,
            positionErr:false,
            btnSaveStatus:false,

            showConfirmModal: false,


            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Bằng cấp chứng chỉ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File bằng cấp chứng chỉ",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            // this.getPartnerInfo();
            this.getInfoFromLocal();
            this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
        })

    }

    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }

    getInfoFromLocal = () => {
        let partner = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        if (partner){
            this.setState({
                dataPartner: partner,
                taxcode:partner.tax,
                phone:partner.phone,
                email:partner.email,
                provinceId:partner.provinceId,
                districtId:partner.districtId,
                address:partner.address,
                website:partner.website,
                urlImage: partner.urlImage,
                representativeName:partner.representative,
                representativeSex:partner.sex,
                identify:partner.identify,
                dateIssue:partner.dateIssued,
                placeIssue:partner.placeIssued,
                position:partner.position,
                showLoading: false,
            })
        } else {
            this.getPartnerInfo()
        }

    }

    getPartnerInfo() {
        AdminOneBookApi.detail(res => {
            if (res.success) {
                localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                this.setState({
                        dataPartner: res.data,
                        taxcode:res.data.tax,
                        phone:res.data.phone,
                        email:res.data.email,
                        provinceId:res.data.provinceId,
                        districtId:res.data.districtId,
                        address:res.data.address,
                        website:res.data.website,
                        urlImage: res.data.urlImage,
                        representativeName:res.data.representative,
                        representativeSex:res.data.sex,
                        identify:res.data.identify,
                        dateIssue:res.data.dateIssued,
                        placeIssue:res.data.placeIssued,
                        position:res.data.position,
                        showLoading: false,
                    }
                )
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    };

    // getAccountInfo() {
    //     userApi.profile(res => {
    //         if (res.success === true) {
    //             console.log("success>>>>>>", res)
    //             this.setState({
    //                 data: res.data
    //             }, () => {
    //                 this.setState({
    //                     address: this.state.data ? this.state.data.address : "",
    //                     tax: this.state.data ? this.state.data.tax : "",
    //                     identify: this.state.data ? this.state.data.identify : "",
    //                     email: this.state.data ? this.state.data.email : "",
    //                     phone: this.state.data ? this.state.data.phone : "",
    //                     business: this.state.data ? this.state.data.business : "",
    //                     scale: this.state.data ? this.state.data.scale : "",
    //                     revenue: this.state.data ? this.state.data.revenue : "",
    //                     representative: this.state.data ? this.state.data.representative : "",
    //                     position: this.state.data ? this.state.data.position : "",
    //                     representativePhone: this.state.data ? this.state.data.representativePhone : "",
    //                     placeIssued: this.state.data ? this.state.data.placeIssued : "",
    //                     dateIssued: this.state.data ? this.state.data.dateIssued : "",
    //                     sex: this.state.data ? this.state.data.sex : "",
    //                     provinceId: this.state.data ? this.state.data.provinceId : 0,
    //                     districtId: this.state.data ? this.state.data.districtId : 0,
    //
    //                 })
    //             })
    //         } else {
    //             console.log("err >>>>>>", res)
    //         }
    //     })
    // }

    handleChangeTax(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({taxError: false, btnSaveStatus: true}) : this.setState({taxError: true, btnSaveStatus: false});
                this.state.tax.length >= 10 ? this.setState({taxError: false, btnSaveStatus: true}) : this.setState({taxError: true, btnSaveStatus: false})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangeexpyear(val) {
        this.setState({inEditing: true});
        const onlyNums = val.target.value.replace(/[^0-9]/g, '').trim();
        this.setState({expyear: onlyNums})
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({identifyError: false, btnSaveStatus: true}) : this.setState({identifyError: true, btnSaveStatus: false});
                this.state.identify.length >= 9 ? this.setState({identifyError: false, btnSaveStatus: true}) : this.setState({identifyError: true, btnSaveStatus: false})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }

    handleSave() {
        let {
            taxcode,
            phone,
            email,
            provinceId,
            districtId,
            address,
            website,
            representativeName,
            representativeSex,
            identify,
            dateIssue,
            placeIssue,
            position,
        } = this.state;
        // if (address === '') {
        //     this.setState({addressError: true})
        // }
        // if (tax === '') {
        //     this.setState({taxError: true})
        // }
        // if (identify === '') {
        //     this.setState({identifyError: true})
        // }
        // if (email === '') {
        //     this.setState({emailError: true})
        // }
        // if (revenue === '') {
        //     this.setState({revenueError: true})
        // }
        // if (representative === '') {
        //     this.setState({representativeError: true})
        // }
        // if (position === '') {
        //     this.setState({positionError: true})
        // }
        // if (representativePhone === '') {
        //     this.setState({representativePhoneError: true})
        // }
        // if (placeIssued === '') {
        //     this.setState({placeIssuedError: true})
        // }
        // if (dateIssued === '') {
        //     this.setState({dateIssuedError: true})
        // }
        // if (sex === '') {
        //     this.setState({sexError: true})
        // }
        // if (provinceId === '') {
        //     this.setState({provinceIdError: true})
        // }
        // if (districtId === '') {
        //     this.setState({districtIdError: true})
        // } else {
        let payload = {
            address: address,
            dateIssued: dateIssue,
            districtId: districtId,
            email: email,
            identify: identify,
            job: "chưa có dữ liệu",
            phone: phone,
            placeIssued: placeIssue,
            position: position,
            provinceId: provinceId,
            representative: representativeName,
            // representativeEmail: "string",
            sex: representativeSex,
            tax: taxcode,
            // urlImage: "string",
            website: website
        }
        // console.log('payload lưu: ', payload);
        this.setState({loading: true}, () => {

            let timer = setInterval(() => {
                this.tick()
            }, 10);
            setTimeout(() => {
                clearInterval(timer);
                this.setState({
                    loading: false,
                }, () => {
                    PartnerApi.updatePartner(payload, res => {
                        if (res.success) {
                            toast.success("Thông tin đã được cập nhật thành công !")
                            this.setState({
                                onEdit: false,
                                inEditing: false,
                                btnSaveStatus: false
                            }, () => this.getPartnerInfo());
                        } else {
                            // console.log('err: ', res);
                            toast.error("Cập nhật không thành công, vui lòng thử lại sau !")
                        }
                    })
                });
            }, 3000);
        });
        // }
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({onEdit: false},
                () => {
                    this.setState({
                        taxcode:this.state.dataPartner.tax,
                        phone:this.state.dataPartner.phone,
                        email:this.state.dataPartner.email,
                        provinceId:this.state.dataPartner.provinceId,
                        districtId:this.state.dataPartner.districtId,
                        address:this.state.dataPartner.address,
                        website:this.state.dataPartner.website,

                        representativeName:this.state.dataPartner.representative,
                        representativeSex:this.state.dataPartner.sex,
                        identify:this.state.dataPartner.identify,
                        dateIssue:this.state.dataPartner.dateIssued,
                        placeIssue:this.state.dataPartner.placeIssued,
                        position:this.state.dataPartner.position,

                        taxcodeErr:false,
                        phoneErr:false,
                        emailErr:false,
                        addressErr:false,
                        websiteErr:false,
                        representativeNameErr:false,
                        representativeSexErr:false,
                        identifyErr:false,
                        dateIssueErr:false,
                        placeIssueErr:false,
                        positionErr:false,
                        btnSaveStatus:true,
                    })
                }
            )
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    onChangeDistrict = (e) => {
        this.setState({
            districtId: e.target.value,
            inEditing: true
        })
    };

    modalUploadAvatarClose = () => {
        this.setState({modalUploadAvatar: false});
    }
    modalCropClose = () => {
        this.setState({showModalCrop: false});
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }

    onDeleteCert = (item) => {
        this.setState({fileId: item.id, modalConfirmDeleteCert: true})
    }

    render() {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataPartner, tax, placeIssued, dateIssued, sex, provinceId, districtId} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="profile"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div variant="outlined" className='mb-4 pb-2 card border-color'>
                                {/*<CardContent>*/}
                                {/*    <div className='d-flex justify-content-center col-12 mt-3' style={{height: 120}} >*/}
                                {/*        <span className='size-text-header'>*/}
                                {/*            {this.state.onEdit ? 'Chỉnh sửa thông tin cá nhân' : 'Thông tin cá nhân'}*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*//**********************************************Thong tin doanh nghiep****************************************************************************************************/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin cá nhân</span>
                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='justify-center img-logo-wrapper' style={{textAlign: 'center', backgroundImage: dataPartner.urlImage ? `url(${dataPartner.urlImage}` : `url("/images/no-avatar-png.png")`}}>
                                    </div>
                                    {(checkPermission("change_logo")) &&
                                    <div>
                                        <span className="fas fa-camera change-logo-icon" title="Tải lên ảnh đại diện" onClick={()=> this.setState({modalUploadAvatar: true})}/>
                                    </div>
                                    }
                                    {/*<div className='col-4'>*/}
                                    {/*<img*/}
                                    {/*    src={this.state.dataPartner.urlImage || `https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}*/}
                                    {/*    onClick={()=> {*/}
                                    {/*        this.setState({modalUploadAvatar: true})*/}
                                    {/*    }}*/}
                                    {/*    // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}*/}
                                    {/*    // src={data.urlImagePartner ? data.urlImagePartner : "http://ecorau.com/images/logo_mail.png"}*/}
                                    {/*    className='mh-100 mw-100 m-2 cursor-pointer'*/}
                                    {/*/>*/}

                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tên doanh nghiệp</span>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner?.fullName?.toUpperCase()}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mã số thuế{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.taxcodeErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập mã số thuế"
                                                       value={this.state.taxcode}
                                                    // aria-label="Username"
                                                       maxLength={14}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           taxcode: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.taxcode.length === 0 || this.state.taxcode.length < 10 ? this.setState({
                                                               taxcodeErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               taxcodeErr: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : dataPartner.tax}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <span className="text-black-50">
                                                    Số điện thoại{this.state.onEdit &&
                                                <span className={'color-red d-inline'}>*</span>}
                                                </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.phoneErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập SĐT"
                                                       value={this.state.phone}
                                                    // aria-label="Username"
                                                       maxLength={11}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           phone: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.phone.length === 0 || this.state.phone.length < 10 ? this.setState({
                                                               phoneErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               phoneErr: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : dataPartner.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email{this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>} </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="email"
                                                       className={this.state.emailErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input" }
                                                       placeholder="Nhập email"
                                                       value={this.state.email}
                                                       maxLength={100}
                                                       onChange={(val) => {
                                                           if (Utils.checkEmailRegex(val.target.value.trim())) {
                                                               this.setState({
                                                                   email: val.target.value.trim(),
                                                                   emailErr: true,
                                                                   btnSaveStatus: false
                                                               })
                                                           }else{
                                                               this.setState({
                                                                   email: val.target.value.trim(),
                                                                   emailErr: false,
                                                                   inEditing: true,
                                                                   btnSaveStatus: true
                                                               })
                                                           }
                                                       }
                                                       }
                                                />
                                                : dataPartner.email}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={provinceId}
                                                    defaultValue={0}
                                                    onChange={(e) => {
                                                        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                        let _disfilter = _district?.filter(item => item.provinceId == e.target.value);
                                                        this.setState({
                                                            provinceId: e.target.value,
                                                            districtId: _disfilter[0]?.id,
                                                            inEditing: true
                                                        })
                                                    }}
                                                    // onSelect={() => this.onChangeDistrict}
                                                >
                                                    <option value={0}>Chọn Tỉnh/Thành Phố</option>
                                                    {renderProvince(dataPartner.provinceId)}
                                                </select>
                                                : dataPartner.provinceId ? renderProvinceId(dataPartner.provinceId) : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={districtId}
                                                    onChange={this.onChangeDistrict}
                                                >
                                                    {this.renderDistrict(provinceId)}
                                                </select>
                                                : dataPartner.districtId ? renderDistrictId(dataPartner.districtId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.addressErr? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập địa chỉ"
                                                       value={address}
                                                       maxLength={100}
                                                    // aria-label="Username"
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           address: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.address.length === 0 ? this.setState({
                                                               addressErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               addressErr: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : dataPartner.address ? dataPartner.address : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <div className='d-flex col-12 '>
                                                    <div className="form-check col-6 ">
                                                        <input className="form-check-input" type="radio"
                                                               name="femaleRadios" id="female" value="FEMALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       representativeSex: val.target.value,
                                                                       inEditing: true,
                                                                       btnSaveStatus: true
                                                                   })
                                                               }}
                                                               checked={this.state.representativeSex === 'FEMALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nữ
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-6">
                                                        <input className="form-check-input" type="radio"
                                                               name="maleRadios" id="male" value="MALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       representativeSex: val.target.value,
                                                                       inEditing: true,
                                                                       btnSaveStatus: true
                                                                   })
                                                               }}
                                                               checked={this.state.representativeSex === 'MALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nam
                                                        </label>
                                                    </div>
                                                </div>
                                                : dataPartner.sex ? dataPartner.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.identifyErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập số CMND/CCCD"
                                                       value={this.state.identify}
                                                       maxLength={12}
                                                       onChange={(val) => this.setState({
                                                           identify: val.target.value.trim().replace(/[^0-9]/g, ''),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.identify.length === 0 || this.state.identify.length < 9 ? this.setState({
                                                               identifyErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               identifyErr: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : dataPartner.identify ? dataPartner.identify : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <DatePicker
                                                    className="w-auto  form-control kt-input pd-4-input hei-input"
                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                    selected={this.state.dateIssue === '' ? null : new Date(this.state.dateIssue)}
                                                    // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                    maxDate={new Date()}
                                                    // value={this.state.dateIssue}
                                                    dateFormat="dd-MM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={
                                                        (date) => {
                                                            let value = moment(date).format("YYYY-MM-DD").toString();
                                                            try {
                                                                this.setState({
                                                                    dateIssue: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                    inEditing: true
                                                                })
                                                            }catch (e) {

                                                            }
                                                        }
                                                    }
                                                />
                                                : dataPartner.dateIssued ? moment(dataPartner.dateIssued).format("DD-MM-YYYY") : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <input type="text"
                                                       className={this.state.placeIssueErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder="Nhập nơi cấp"
                                                       value={this.state.placeIssue}
                                                    // aria-label="Username"
                                                       maxLength={50}
                                                    // aria-describedby="basic-addon1"
                                                       onChange={(val) => this.setState({
                                                           placeIssue: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.placeIssue.length === 0 ? this.setState({
                                                               placeIssueErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               placeIssueErr: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : dataPartner.placeIssued ? dataPartner.placeIssued : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>

                                {this.state.onEdit ?
                                    <div className="justify-flex-center">
                                        <div className='text-center col-12'>
                                            {this.state.loading &&
                                            <button
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            >
                                                <CircularProgress size={20} variant="determinate"
                                                                  value={this.state.progress}/>
                                            </button>}
                                            {!this.state.loading &&
                                            <button
                                                disabled={!this.state.btnSaveStatus}
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={() => this.handleSave()}
                                            >
                                                Lưu
                                            </button>
                                            }
                                            <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.handleCancel()}
                                            >
                                                Hủy
                                            </button>
                                        </div>
                                    </div>
                                    : <div className="justify-flex-center">
                                        <div className='col-12 text-center'
                                             style={{textTransform: 'initial'}}
                                        >
                                            {(checkPermission("edit_profile")) &&
                                            <button
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                            >
                                                Chỉnh sửa thông tin
                                            </button>
                                            }
                                            <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.handleShowModalForgotPassword()}
                                            >
                                                Đổi mật khẩu
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>


                            {/*/!********************************Thong tin ca nhan****************************************************!/*/}
                            {/*<div variant="outlined" className='mt-3 pb-3 card border-color'>*/}
                            {/*    <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>*/}
                            {/*        <span className='text-header-profile'>Thông tin cá nhân</span>*/}
                            {/*    </div>*/}

                            {/*    <div className="mt-4 font-14 ml-4 mr-4">*/}
                            {/*        <div className='row kt-margin-b-20'>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Họ và tên{this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <input type="text"*/}
                            {/*                           className={this.state.representativeNameErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}*/}
                            {/*                           placeholder='Nhập họ và tên'*/}
                            {/*                           value={this.state.representativeName}*/}
                            {/*                           maxLength={50}*/}
                            {/*                           onChange={(val) => this.setState({*/}
                            {/*                               representativeName: val.target.value.trimLeft(),*/}
                            {/*                               inEditing: true*/}
                            {/*                           }, () => {*/}
                            {/*                               this.state.representativeName.length == 0 ? this.setState({*/}
                            {/*                                   representativeNameErr: true,*/}
                            {/*                                   btnSaveStatus: false*/}
                            {/*                               }) : this.setState({representativeNameErr: false, btnSaveStatus: true})*/}
                            {/*                           })}*/}
                            {/*                    />*/}

                            {/*                    : dataPartner.representative ? dataPartner.representative.toUpperCase() : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Giới tính {this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <div className='d-flex col-12 '>*/}
                            {/*                        <div className="form-check col-6 ">*/}
                            {/*                            <input className="form-check-input" type="radio"*/}
                            {/*                                   name="femaleRadios" id="female" value="FEMALE"*/}
                            {/*                                   onClick={(val) => {*/}
                            {/*                                       this.setState({*/}
                            {/*                                           representativeSex: val.target.value,*/}
                            {/*                                           inEditing: true*/}
                            {/*                                       })*/}
                            {/*                                   }}*/}
                            {/*                                   checked={this.state.representativeSex === 'FEMALE'}*/}
                            {/*                            />*/}
                            {/*                            <label className="form-check-label"*/}
                            {/*                                   htmlFor="exampleRadios1">*/}
                            {/*                                Nữ*/}
                            {/*                            </label>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="form-check col-6">*/}
                            {/*                            <input className="form-check-input" type="radio"*/}
                            {/*                                   name="maleRadios" id="male" value="MALE"*/}
                            {/*                                   onClick={(val) => {*/}
                            {/*                                       this.setState({*/}
                            {/*                                           representativeSex: val.target.value,*/}
                            {/*                                           inEditing: true*/}
                            {/*                                       })*/}
                            {/*                                   }}*/}
                            {/*                                   checked={this.state.representativeSex === 'MALE'}*/}
                            {/*                            />*/}
                            {/*                            <label className="form-check-label"*/}
                            {/*                                   htmlFor="exampleRadios1">*/}
                            {/*                                Nam*/}
                            {/*                            </label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    : dataPartner.sex ? dataPartner.sex == 'MALE' ? 'Nam' : 'Nữ' : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">CMND/CCCD {this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <input type="text"*/}
                            {/*                           className={this.state.identifyErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}*/}
                            {/*                           placeholder="Nhập số CMND/CCCD"*/}
                            {/*                           value={this.state.identify}*/}
                            {/*                           maxLength={12}*/}
                            {/*                           onChange={(val) => this.setState({*/}
                            {/*                               identify: val.target.value.trim().replace(/[^0-9]/g, ''),*/}
                            {/*                               inEditing: true*/}
                            {/*                           }, () => {*/}
                            {/*                               this.state.identify.length === 0 || this.state.identify.length < 9 ? this.setState({*/}
                            {/*                                   identifyErr: true,*/}
                            {/*                                   btnSaveStatus: false*/}
                            {/*                               }) : this.setState({*/}
                            {/*                                   identifyErr: false,*/}
                            {/*                                   btnSaveStatus: true*/}
                            {/*                               })*/}
                            {/*                           })}*/}
                            {/*                    />*/}
                            {/*                    : dataPartner.identify ? dataPartner.identify : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Ngày cấp {this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <DatePicker*/}
                            {/*                        className="w-auto  form-control kt-input pd-4-input hei-input"*/}
                            {/*                        // onChangeRaw={this.handleDateChangeRaw}*/}
                            {/*                        selected={this.state.dateIssue === '' ? null : new Date(this.state.dateIssue)}*/}
                            {/*                        // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}*/}
                            {/*                        maxDate={new Date()}*/}
                            {/*                        // value={this.state.dateIssue}*/}
                            {/*                        dateFormat="dd-MM-yyyy"*/}
                            {/*                        showMonthDropdown*/}
                            {/*                        showYearDropdown*/}
                            {/*                        onChange={*/}
                            {/*                            (date) => {*/}
                            {/*                                let value = moment(date).format("YYYY-MM-DD").toString();*/}
                            {/*                                try {*/}
                            {/*                                    this.setState({*/}
                            {/*                                        dateIssue: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),*/}
                            {/*                                        inEditing: true*/}
                            {/*                                    })*/}
                            {/*                                }catch (e) {*/}

                            {/*                                }*/}
                            {/*                            }*/}
                            {/*                        }*/}
                            {/*                    />*/}
                            {/*                    : dataPartner.dateIssued ? moment(dataPartner.dateIssued).format("DD-MM-YYYY") : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Nơi cấp {this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <input type="text"*/}
                            {/*                           className={this.state.placeIssueErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}*/}
                            {/*                           placeholder="Nhập nơi cấp"*/}
                            {/*                           value={this.state.placeIssue}*/}
                            {/*                        // aria-label="Username"*/}
                            {/*                           maxLength={50}*/}
                            {/*                        // aria-describedby="basic-addon1"*/}
                            {/*                           onChange={(val) => this.setState({*/}
                            {/*                               placeIssue: val.target.value.trimLeft(),*/}
                            {/*                               inEditing: true*/}
                            {/*                           }, () => {*/}
                            {/*                               this.state.placeIssue.length === 0 ? this.setState({*/}
                            {/*                                   placeIssueErr: true,*/}
                            {/*                                   btnSaveStatus: false*/}
                            {/*                               }) : this.setState({*/}
                            {/*                                   placeIssueErr: false,*/}
                            {/*                                   btnSaveStatus: true*/}
                            {/*                               })*/}
                            {/*                           })}*/}
                            {/*                    />*/}
                            {/*                    : dataPartner.placeIssued ? dataPartner.placeIssued : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Chức vụ {this.state.onEdit &&*/}
                            {/*                <span className={'color-red d-inline'}>*</span>}</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                {this.state.onEdit ?*/}
                            {/*                    <input type="text"*/}
                            {/*                           className={this.state.positionErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}*/}
                            {/*                           placeholder="Nhập chức vụ"*/}
                            {/*                           value={this.state.position}*/}
                            {/*                        // aria-label="Username"*/}
                            {/*                           maxLength={50}*/}
                            {/*                        // aria-describedby="basic-addon1"*/}
                            {/*                           onChange={(val) => this.setState({*/}
                            {/*                               position: val.target.value.trimLeft(),*/}
                            {/*                               inEditing: true*/}
                            {/*                           }, () => {*/}
                            {/*                               this.state.position.length === 0 ? this.setState({*/}
                            {/*                                   positionErr: true,*/}
                            {/*                                   btnSaveStatus: false*/}
                            {/*                               }) : this.setState({*/}
                            {/*                                   positionErr: false,*/}
                            {/*                                   btnSaveStatus: true*/}
                            {/*                               })*/}
                            {/*                           })}*/}
                            {/*                    />*/}
                            {/*                    : dataPartner.position ? dataPartner.position : ''*/}
                            {/*                }*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">*/}
                            {/*                    Số điện thoại*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className={this.state.onEdit ?"pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile":"pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>*/}
                            {/*                {dataPartner.representativePhone}*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">*/}
                            {/*                <span className="text-black-50">Email</span>*/}
                            {/*            </div>*/}
                            {/*            <div*/}
                            {/*                className={this.state.onEdit ?"pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile":"pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>*/}
                            {/*                {dataPartner?.representativeEmail}*/}
                            {/*            </div>*/}

                            {/*            <div*/}
                            {/*                className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    {this.state.onEdit ?*/}
                            {/*        <div className="justify-flex-center">*/}
                            {/*            <div className='text-center col-12'>*/}
                            {/*                {this.state.loading &&*/}
                            {/*                <button*/}
                            {/*                    type="button"*/}
                            {/*                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                            {/*                >*/}
                            {/*                    <CircularProgress size={20} variant="determinate"*/}
                            {/*                                      value={this.state.progress}/>*/}
                            {/*                </button>}*/}
                            {/*                {!this.state.loading &&*/}
                            {/*                <button*/}
                            {/*                    disabled={!this.state.btnSaveStatus}*/}
                            {/*                    type="button"*/}
                            {/*                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                            {/*                    onClick={() => this.handleSave()}*/}
                            {/*                >*/}
                            {/*                    Lưu*/}
                            {/*                </button>*/}
                            {/*                }*/}
                            {/*                <button type="button"*/}
                            {/*                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"*/}
                            {/*                        onClick={() => this.handleCancel()}*/}
                            {/*                >*/}
                            {/*                    Hủy*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : <div className="justify-flex-center">*/}
                            {/*            <div className='col-12 text-center'*/}
                            {/*                 style={{textTransform: 'initial'}}*/}
                            {/*            >*/}
                            {/*                {(checkPermission("edit_profile")) &&*/}
                            {/*                <button*/}
                            {/*                    type="button"*/}
                            {/*                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                            {/*                    onClick={() => this.setState({onEdit: !this.state.onEdit})}*/}
                            {/*                >*/}
                            {/*                    Chỉnh sửa thông tin*/}
                            {/*                </button>*/}
                            {/*                }*/}
                            {/*                <button type="button"*/}
                            {/*                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"*/}
                            {/*                        onClick={() => this.handleShowModalForgotPassword()}*/}
                            {/*                >*/}
                            {/*                    Đổi mật khẩu*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    }*/}

                            {/*    /!*</CardContent>*!/*/}
                            {/*</div>*/}


                            <ModalChangePassword show={this.state.showModalChangePassword}
                                                 onHideModal={this.onCloseModalForgotPassword}/>

                            <ModalConfirmEdit show={this.state.confirmCancel}
                                              onHide={() => this.setState({confirmCancel: false})}
                                              handleCancel={
                                                  () => this.setState({
                                                      confirmCancel: false,
                                                      inEditing: false
                                                  }, () => this.handleCancel())
                                              }
                            />
                            {this.state.showUpload && <UploadFile show={this.state.showUpload}
                                                                  onHide={() => this.setState({showUpload: false})}/>}
                        </div>
                    </div>
                </div>
                <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                   onHide={this.modalUploadAvatarClose}
                                   updateSuccess={()=> {
                                       this.setState({modalUploadAvatar: false})
                                       this.getPartnerInfo()
                                   }}
                />
                <ModalConfirmDeleteCert show={this.state.modalConfirmDeleteCert}
                                        onHide={() => this.setState({modalConfirmDeleteCert: false})}
                                        fileId={this.state.fileId}/>
                {/*<ModalCropImage show={this.state.showModalCrop} onHide={this.modalCropClose}*/}
                {/*                   customerId={1}/>*/}
            </div>
        );
    }
}

export default ProfilePartner
