import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let Job = {
    closed: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/job/closed-job/${payload.id}`,
            method: 'POST',
        }, callback)
    },
    searchByContractCodeAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/job/search-by-contract-code-and-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    searchJobByNameAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/job/search-job-by-name-and-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

}
export default Job
