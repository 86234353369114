import React from 'react';
import MenuItems from "./MenuItems";
import Link from "../Link";
import {checkPermission} from "../../utils/common";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selected: null,
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType");
        switch (userType) {
            case "CALL_CENTER":
                return this.setState({items: MenuItems.CallCenter})
            case "CUSTOMER":
                return this.setState({items: MenuItems.Customer})
            case "SUPERVISOR":
                return this.setState({items: MenuItems.Supervisor})
            case "PARTNER":
                return this.setState({items: MenuItems.Partner})
            case "ACCOUNTANT":
                return this.setState({items: MenuItems.Accountant})
            case "ADMIN":
                return this.setState({items: MenuItems.Admin})
        }
    }

    render() {
        let userType = localStorage.getItem("ob_cl_userType");
        let activeClass = this.props?.activeClass;
        let parentClass = this.props?.parentClass;
        let selected = this.state.selected;
        return (
            <div className="col-md-2 card border-color mb-3 p-0" style={{height: '100%'}}>
                {/*<div className="col-md-3">*/}

                <ul className="menu-y text-sm nav nav-tabs sidebar overflow-hidden">
                    {this.state.items?.map((item, index) => (
                        !item.hide && (item.permission && !checkPermission(item.permission) ? null : <li style={{backgroundColor: "#fff"}}>
                            <Link to={item.url ? item.url : "#"} data-toggle="collapse"
                                  data-target={`#${item.activeClass}`}
                                  onClick={() => {
                                      // $(`#${item.activeClass}`).collapse({
                                      //     toggle: false
                                      // })
                                      if (selected == item.activeClass) {
                                          // selected = null;
                                          this.setState({selected: null})
                                      } else {
                                          // selected = item.activeClass
                                          this.setState({selected: item.activeClass})
                                      }
                                  }}
                                  className={`${activeClass === item.activeClass ? 'active' : ''} d-block py-2 no-border-top d-flex justify-content-between align-items-center`}>
                                <span style={{minHeight: 30, lineHeight: '30px'}}>
                                    <i className={item.icon} aria-hidden="true"/>
                                    {item.title}
                                </span>
                                {item?.children?.length > 0 &&
                                <i className={`${(item.activeClass === parentClass || item.activeClass == selected) ? 'fas fa-angle-down' : 'fas fa-angle-right'} icon-arrow-side-bar`}/>}
                            </Link>
                            {item?.children?.length > 0 && <ul id={item.activeClass}
                                                               className={`${(item.activeClass === parentClass || item.activeClass == selected) ? 'content-side-bar-active' : 'content-side-bar'} submenu-y collapse`}>
                                {item?.children?.length > 0 && item?.children?.map((_item, idx) => (
                                    !_item.hide &&(_item.permission && !checkPermission(_item.permission) ? null : <li style={{backgroundColor: "#fff"}}>
                                        <Link to={_item.url}
                                              onClick={() => {
                                                  this.setState({selected: _item.parentClass})
                                              }}
                                              className={`${activeClass === _item.activeClass ? 'active' : ''} d-block py-2`}>
                                            <span style={{overflow: 'hidden',  display: '-webkit-box', '-webkit-line-clamp': '2', '-webkit-box-orient': 'vertical'}}>
                                                <i className="caret"/>
                                                {_item.title}
                                            </span>
                                        </Link>
                                    </li>
                                )))}
                            </ul>}
                        </li>
                    )))}
                </ul>

            </div>
        )
    }
}

export default Sidebar
