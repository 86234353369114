import React from 'react'
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import ModalCreateCallCenter from "../../../components/Modal/admin/modalCreateCallCenter";
import ModalDetailCallCenter from "../../../components/Modal/admin/modalDetailCallCenter";
import ModalBlockCallCenter from "../../../components/Modal/admin/modalBlockCallCenter";
import ModalUnblockCallCenter from "../../../components/Modal/admin/modalUnblockCallCenter";
import ModalDeleteCallCenter from "../../../components/Modal/admin/modalDeleteCallCenter";

import CallCenterApi from "../../../services/ob-user/call-center";
import {checkPermission} from "../../../utils/common";

class CallCenterManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAdd: false,
            showDetail: false,
            showEdit: false,
            showDelete: false,
            showBlock: false,
            showUnblock: false,
            onEdit: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            status: "",
            descending: "",
            sort: "",
            listData: [],
            itemSelected: {},
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên Call Center",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'email',
                    name: "Email",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 200, textAlign: "center"},
                    sort: false,
                },
            ],
        }

    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListCallCenterOnSearch()
        })
    }

    getListCallCenterOnSearch = () => {
        let {status, pageNumber, pageSize, descending, sort} = this.state;
        let payload = {
            pageNumber,
            pageSize,
            status,
            descending,
            sort,
        };
        if (sort === "") {
            delete payload.sort
        }
        CallCenterApi.getList(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            // case "WAIT_COMPLETE_INFO":
            //     return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            // case "WAIT_CONFIRM_EMAIL":
            //     return <h6><span className="badge badge-dark">Chờ xác nhận Email</span></h6>;
            default:
                return;
        }
    };

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getListCallCenterOnSearch()
            }
        );
    }

//*********************************************************************************************************************

    render() {
        let {pageNumber, pageSize} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="call-center-management" parentClass="employee-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Call Center"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="col-md-6 col-sm-12 pl-0">
                                            <label className="label-item">Trạng thái</label>
                                            <select
                                                value={this.state.status}
                                                onChange={(e) => {
                                                    this.setState({
                                                        status: e.target.value,
                                                        pageNumber: 0
                                                    })
                                                }}
                                                className="form-control kt-input pd-4-input shadow-none"
                                            >
                                                <option value=''>Tất cả</option>
                                                <option value='ACTIVE'>Đang hoạt động</option>
                                                <option value='LOCK'>Đã khóa</option>
                                                <option value='CANCELED'>Đã xóa</option>
                                                {/* <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option> */}
                                            </select>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.getListCallCenterOnSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.setState({
                                                            pageNumber: 0,
                                                            status: '',
                                                            descending: "",
                                                            sort: "",
                                                        }, () => this.getListCallCenterOnSearch())}
                                                >
                                                    Refresh
                                                </button>
                                                {checkPermission("create_call_center_account") &&
                                                <button type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                showCreate: true
                                                            })
                                                        }}
                                                >
                                                    Thêm mới
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số Call Center: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader
                                            header={this.state.header}
                                            clickSort={data => {
                                                let a = "";
                                                let descend = "";
                                                if (data.sortAsc == null) {
                                                    // a = "id=asc";
                                                    a = "";
                                                    descend = 'DESC'
                                                } else {
                                                    a = data.sortId;
                                                    descend = data.sortAsc ? 'ASC' : 'DESC'
                                                }
                                                this.setState(
                                                    {
                                                        sort: a,
                                                        descending: descend
                                                    },
                                                    () => this.getListCallCenterOnSearch()
                                                );
                                            }}
                                        />
                                        </thead>
                                        {!this.state.listData || this.state.listData.length === 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.email}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {checkPermission("detail_call_center_account") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem chi tiết"
                                                            onClick={() => this.setState({
                                                                onEdit: false,
                                                                itemSelected: row,
                                                                showDetail: true
                                                            })}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        }
                                                        {checkPermission("block_call_center_account") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title={row.status === "LOCK" ? "Mở khóa" : "Khóa"}
                                                            disabled={row.status === "WAIT_COMPLETE_INFO" || row.status === "WAIT_CONFIRM_EMAIL" || row.status === "CANCELED" || row.status === "WAIT_ACTIVE"}
                                                            onClick={() => {
                                                                if (row.status === "LOCK") {
                                                                    this.setState({
                                                                        itemSelected: row,
                                                                        showUnblock: true
                                                                    })
                                                                } else {
                                                                    this.setState({itemSelected: row, showBlock: true})
                                                                }
                                                            }}
                                                        >
                                                            <i className={row.status === "LOCK" ? "ti-unlock" : "ti-lock"}/>
                                                        </button>
                                                        }
                                                        {checkPermission("delete_call_center_account") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showDelete: true})
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                        }
                                                        {checkPermission("edit_call_center_account") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Sửa"
                                                            onClick={() => this.setState({
                                                                onEdit: true,
                                                                itemSelected: row,
                                                                showDetail: true
                                                            })}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showCreate && <ModalCreateCallCenter show={this.state.showCreate}
                                                                 onHide={() => this.setState({showCreate: false})}
                                                                 onRefresh={() => this.getListCallCenterOnSearch()}/>}
                {this.state.showDetail && <ModalDetailCallCenter show={this.state.showDetail}
                                                                 onEdit={this.state.onEdit}
                                                                 data={this.state.itemSelected}
                                                                 onHide={() => this.setState({showDetail: false})}
                                                                 onRefresh={() => this.getListCallCenterOnSearch()}/>}
                {this.state.showBlock && <ModalBlockCallCenter show={this.state.showBlock}
                                                               data={this.state.itemSelected}
                                                               onHide={() => this.setState({showBlock: false})}
                                                               onRefresh={() => this.getListCallCenterOnSearch()}/>}
                {this.state.showUnblock && <ModalUnblockCallCenter show={this.state.showUnblock}
                                                                   data={this.state.itemSelected}
                                                                   onHide={() => this.setState({showUnblock: false})}
                                                                   onRefresh={() => this.getListCallCenterOnSearch()}/>}
                {this.state.showDelete && <ModalDeleteCallCenter show={this.state.showDelete}
                                                                 data={this.state.itemSelected}
                                                                 onHide={() => this.setState({showDelete: false})}
                                                                 onRefresh={() => this.getListCallCenterOnSearch()}/>}
            </div>
        )
    }
}

export default CallCenterManagement
