import React from 'react';
import logo from '../../../assets/images/logo.png';
import logo_1 from '../../../assets/images/logo_1.png';
import q1 from '../../../assets/images/about/q1.png';
import q2 from '../../../assets/images/about/q2.png';
import okeblue from '../../../assets/images/about/okeblue.png';
import okered from '../../../assets/images/about/okered.png';
import a3 from '../../../assets/images/about/a3.png';
import a4 from '../../../assets/images/about/a4.png';
import a5 from '../../../assets/images/about/a5.png';
import './style.css';
import oke from "../../../assets/images/service/oke.png";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='about-us-wrapper'>
        <div className='banner container text-center'>
          <h1 className='about-us-title'>
            <img src={q1} className='q1' />
            Chi tiết về chúng tôi
            <img src={q2} className='q2' />
          </h1>
          <img src={logo} alt='logo' className='ab-logo' />
          <div className='about-us-des'>
            <p>là nền tảng cung cấp dịch vụ kế toán</p>
            <p>
              kết nối hàng trăm Doanh nghiệp với những kế toán viên giỏi nhất,
              nhiều kinh nghiệm nhất
            </p>
            <p>trong tất cả lĩnh vực hoạt động của Doanh nghiệp</p>
          </div>
        </div>
        <div className='ab-content container'>
          <div className='row bg-light-blue ab-block'>
            <div className='col-sm-11 text-part flex-wrap'>
              <div className='col-sm-1'>
              </div>
              <div className='col-sm-11'>
                <div className='ab-content-title'>
                  <b>TỔNG QUAN VỀ   <img src={logo_1} alt='logo' className='content-logo-1' />&nbsp;</b>
                </div>
                <div className='ab-content text-left'>
                  <p>
                    <img src={logo_1} alt='logo' className='content-logo' />
                    &nbsp; được thành lập và phát triển với mục tiêu trở thành hệ thống nền tảng hàng đầu cung cấp nền tảng dịch vụ kế toán, dịch vụ thuế và dịch vụ tài chính doanh nghiệp trên cơ sở kết nối các tổ chức, cá nhân cung cấp dịch vụ với các doanh nghiệp có nhu cầu nhằm đơn giản hóa và nâng cao hiệu quả công tác kế toán tại các doanh nghiệp đặc biệt là đối với các doanh nghiệp vừa và nhỏ với những mục tiêu cụ thể sau:
                  </p>
                  <p>
                    <img src={okeblue} alt='logo' className='content-oke' />&nbsp; Đăng ký sử dụng dịch vụ đơn giản nhất
                  </p>
                  <p>
                    <img src={okered} alt='logo' className='content-oke' />&nbsp; Cung cấp dịch vụ tiện lợi nhất
                  </p>
                  <p>
                    <img src={okeblue} alt='logo' className='content-oke' />&nbsp; Chất lượng dịch vụ phù hợp nhất
                  </p>
                  <p>
                    <img src={okered} alt='logo' className='content-oke' />&nbsp; Công tác bảo mật an toàn nhất
                  </p>
                  <p>
                    <img src={okeblue} alt='logo' className='content-oke' />&nbsp; Nhân viên phục vụ “nice & profestinonal” nhất
                  </p>
                  <p>
                    <img src={logo_1} alt='logo' className='content-logo' />
                    &nbsp; còn được xây dựng, vận hành dựa trên sự kết hợp và sáng tạo của những chuyên gia công nghệ thông tin và các chuyên gia kế toán, kiểm toán đã có nhiều năm làm việc tại những hãng kiểm toán, kế toán hàng đầu tại Việt Nam.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row bg-white ab-block'>
            <div className='col-sm-11 text-part flex-wrap'>
              <div className='col-sm-1'></div>
              <div className='col-sm-11'>
                <div className='ab-content-title'>
                  SỨ MỆNH VÀ TẦM NHÌN
                </div>
                <div className='ab-content text-left'>
                  <p>
                    <b>"<img src={logo_1} alt='logo' className='content-logo' />
                    &nbsp; sẽ luôn tiên phong trong việc mang đến cho cộng đồng doanh nghiệp Việt Nam và khu vực những giải pháp kế toán thông minh, thuận tiện và hiệu quả
                    </b>
                  </p>
                  <p>
                   Với sứ mệnh và tầm nhìn nêu trên, những giá trị cốt lõi cần không ngừng xây dựng, bảo vệ và phát huy như:
                  </p>
                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Mọi giải pháp đều hướng tới việc không ngừng nâng cao giá trị dịch vụ góp phần vào sự thành công trong kinh doanh của khách hàng;
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Chuyển hóa công tác dịch vụ kế toán, dịch vụ thuế và dịch vụ tài chính doanh nghiệp ngày càng trở nên tiện lợi, hiệu quả và đáng tin cậy;
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tất cả các doanh nghiệp đều có thể yên tâm và đạt hiệu quả cao khi sử dụng dịch vụ trên <img src={logo_1} alt='logo' className='content-logo' />
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tất cả các công ty dịch vụ kế toán, kiểm toán, dịch vụ thuế và tư vấn tài chính doanh nghiệp đều có thể tham gia cung cấp dịch vụ một cách hiệu quả và đáng tin cậy khi gia nhập <img src={logo_1} alt='logo' className='content-logo' />
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tất cả các chuyên gia kế toán, kiểm toán, tư vấn thuế, tư vấn tài chính doanh nghiệp chân chính và chăm chỉ đều có thể sử dụng trí tuệ, kỹ năng của mình phục vụ cho các khách hàng của mình một cách tiện lợi, hiệu quả trên <img src={logo_1} alt='logo' className='content-logo' />, để trở thành những con người tự chủ - Sống hạnh phúc và làm việc hiệu quả;
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tất cả các thành viên tham gia cung cấp dịch vụ trên <img src={logo_1} alt='logo' className='content-logo' /> phải thấm nhuần nguyên tắc sống và làm việc một cách hòa nhã và chuyên nghiệp – “Nice and Profestional”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row bg-light-blue ab-block'>
            <div className='col-sm-11 text-part flex-wrap'>
              <div className='col-sm-1'></div>
              <div className='col-sm-11'>
                <div className='ab-content-title'>
                  <img src={logo_1} alt='logo' className='content-logo-1' />-SỰ LỰA CHỌN TIN CẬY
                </div>
                <div className='ab-content text-left'>
                  <h6>
                    <b>“Cùng nhau tạo lập thành công trong kinh doanh và hạnh phúc trong cuộc sống”</b>
                  </h6>
                  <p>
                    Chúng tôi luôn mang đến sự tin cậy qua việc cung cấp các giải pháp thiết thực, hiệu quả và phù hợp nhất cho khách hàng. Chúng tôi luôn lắng nghe nhu cầu của khách hàng và dành thời gian để thấu hiểu những mong muốn của khách hàng đối với dịch vụ yêu cầu cung cấp
                  </p>
                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tính chuyên nghiệp cao: <img src={logo_1} alt='logo' className='content-logo' /> là nền tảng ứng dựng công nghệ cao, đáng tin cậy, đáp ứng mọi yêu cầu của khách hàng về chất lượng dịch vụ thông qua đội ngũ chuyên gia giàu kinh nghiệm
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Tinh gọn trong cung cấp dịch vụ: <img src={logo_1} alt='logo' className='content-logo' /> tự hào là đơn vị đầu tiên trên thị trường Việt Nam cung cấp giải pháp kế toán thông minh dựa trên nền tảng kết nối doanh nghiệp và các chuyên gia cung cấp dịch vụ kế toán, kiểm toán, tư vấn thuế, dịch vụ tài chính,  giúp tối thiểu hóa chi phí quản lý kế toán và tài chính cho các doanh nghiệp
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Nâng cao tính chủ động của doanh nghiệp: Các doanh ngiệp vừa và nhỏ sẽ không còn bị lệ thuộc vào một và cá nhân hoặc một vài tổ chức thực hiện công tác kế toán cho mình nữa mà vẫn có thể có được các báo cáo kế toán có chất lượng cao, đáng tin cậy, vào mọi lúc và ở mọi nơi với chi phí hợp lý nhất có thể.
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Mạng lưới rộng khắp có thể đáp ứng nhu cầu mọi lúc mọi nơi: <img src={logo_1} alt='logo' className='content-logo' /> là nền tảng hoàn toàn sử dụng công nghệ điện toán đám mây, trí tuệ nhân tạo, kết hợp với các chuyên gia thực hiện dịch vụ hiện diện ở bất cứ đâu trên khắp lãnh thổ Việt Nam.
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Chủ động lựa chọn nhà cung cấp và các chuyên gia phục vụ: Khách hàng có thể tự đăng ký các dịch vụ tiêu chuẩn trên hệ thống, tự lựa chọn tổ chức, cá nhân chuyên gia phục vụ cho mình một cách minh bạch, hiệu quả và đáng tin cậy ở bất cứ không gian nào và bất cứ lúc nào;
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Bảo mật an toàn: Mỗi khách hàng đều có kho dữ liệu của riêng mình trên kho dữ liệu của <img src={logo_1} alt='logo' className='content-logo' /> để gửi chứng từ, tài liệu và yêu cầu phục vụ của mình lên, lưu trữ, bảo quản và lấy ra sử dụng ở bất cứ nơi nào và bất cứ lúc nào một cách tin cậy và hiệu quả
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Chất lượng dịch vụ: Hệ thống thanh tra, kiểm tra, kiểm soát chất lượng dịch vụ được duy trì thường xuên và có hiệu quả để đảm bảo toàn hệ thống hoạt động thông suốt và đảm bảo các nguyên tắc tuân thủ chuẩn mực và đạo đức nghệ nghiệp.
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-1 imb-tcdn'>
                      <img src={okered} />
                    </div>
                    <div className='col-sm-11 text-part flex-wrap'>
                      <p>
                        Mạng lưới đối tác rộng rãi: <img src={logo_1} alt='logo' className='content-logo' /> có nhiều đối tác là các ngân hàng, các tổ chức thanh toán, các hãng tư vấn chuyên nghiệp sẽ giúp khách hàng được hưởng nhiều tiện ích hơn từ các đối tác của <img src={logo_1} alt='logo' className='content-logo' />.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row bg-white ab-block'>
            <div className='col-sm-11 text-part flex-wrap'>
              <div className='col-sm-1'></div>
              <div className='col-sm-11'>
                <div className='ab-content-title'>
                  MẠNG LƯỚI KHÁCH HÀNG
                </div>
                <div className='ab-content text-left'>
                  <p>
                    Công ty Cổ phần giải pháp kế toán thông mình tự hào khi được kết nối hợp tác với các công ty kiểm toán, dịch vụ kế toán uy tín trên khắp cả nước và một số khu vực trên thế giới
                  </p>
                  <p>
                    Tất cả các tổ chức, cá nhân tham gia cung cấp dịch vụ trên <img src={logo_1} alt='logo' className='content-logo' /> đều được đánh giá và thẩm định về pháp lý, trình độ nghiệp vụ, uy tín nghề nghiệp, lich sử tuân thủ đạo đức nghề nghiệp.
                  </p>
                  <p>
                    Các tổ chức và cá nhân cung cấp dịch vụ trên hệ thống có thể hiện diện ở bất cứ đâu đều có thể tham gia phục vụ khách hàng. Quá trình lựa chọn và phân bổ khách hàng cũng sẽ có những nguyên tắc ưu tiên để nâng cao chất lượng và dịch vụ như: sự lựa trọn của khác hàng, ngôn ngữ sử dụng, khu vực địa lý và các yếu tố đặc thù khác.
                  </p>
                  <p>
                    Danh sách các trung tâm dịch vụ, các đối tác và các chuyên gia cung cấp dịch vụ luôn được cập nhận trên website của <img src={logo_1} alt='logo' className='content-logo' />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
