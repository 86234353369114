import React from 'react';
import "./Modal.css"
import Utils from  '../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import PartnerApi from "../../services/ob-user/partner";
import SecureNewsCategory from "../../services/ob-core/secure-news-category";
import {toast} from "react-toastify";
import PublicNewsCategory from "../../services/ob-core/public-news-category";

class ModalAddEditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.item ? this.props.item.name : "",
            listCategory: [],
            onEdit: false,
            nameErr: false,
            path: this.props.item ? this.props.item.path : "",
            pathErr: false,
            position: this.props.item ? this.props.item.position: 0,
            parentId: this.props.item ? this.props.item.parentId: 0,
            type: this.props.item ? this.props.item.type: 0,
            btnStatus: false,
            itemId: null,
        };
    }
    componentDidMount() {
        this.setState({
            btnStatus: false,
        });
        this.getListCategory()
    }
    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: this.reformatData(res.data),
                showLoading: false
            })
        })
    }
    reformatData = (listData) => {
        let listCate = [];
        listData.forEach(cateInfo => {
            if (cateInfo.parentId == 0){
                listCate.push(cateInfo);
                listData.forEach(subCate => {
                    if (subCate.parentId != 0 && subCate.parentId == cateInfo.id){
                        subCate.name = "->"+subCate.name;
                        listCate.push(subCate);
                        listData.forEach(inSubCate => {
                            if (inSubCate.parentId != 0 && inSubCate.parentId == subCate.id){
                                inSubCate.name = "--->"+inSubCate.name;
                                listCate.push(inSubCate);
                                listData.forEach(inInSubCate => {
                                    if (inInSubCate.parentId != 0 && inInSubCate.parentId == inSubCate.id){
                                        inInSubCate.name = "---->"+inInSubCate.name;
                                        listCate.push(inInSubCate);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return listCate;
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                btnStatus: false,
            });
        }
    }

    onSubmit =()=>{
        if (this.state.name.length > 0) {
            if (this.state.categoryId == undefined){
                this.state.categoryId = 0;
            }
            if (this.state.type == undefined){
                this.state.type = 1;
            }
            if (this.state.position == undefined){
                this.state.position = 0;
            }
            if (this.props.modalType === "edit") {
                this.handleEditCategory()
            } else {
                this.handleAddCategory()
            }
        } else {
            toast.error("Tên danh mục không được để trống")
        }
    }

    handleEditCategory = () => {
        let payload = {
            id: this.props.item.id,
            body: {
                name: this.state.name,
                parentId: this.state.categoryId,
                position: this.state.position,
                type: this.state.type,
                path: this.state.path
            }
        }
        SecureNewsCategory.update(payload, res => {
            if(res.success) {
                toast.success("Chỉnh sửa danh mục thành công !")
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra! " + res.message)
            }
        })
    }

    handleAddCategory = () => {
        let payload = {
            name: this.state.name,
            parentId: this.state.categoryId,
            position: this.state.position,
            type: this.state.type,
            path: this.state.path
        }
        SecureNewsCategory.create(payload, res => {
            if(res.success) {
                toast.success("Thêm mới danh mục thành công !")
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra! " + res.message)
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>{this.props.modalType == "edit" ? "CHỈNH SỬA DANH MỤC" : "THÊM MỚI DANH MỤC"}</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-12 mt-2">Tên danh mục </label>
                                <input
                                    className={this.state.nameErr?" w-100 col-12 border-input-error":" w-100 col-12 "}
                                    placeholder="Nhập vào danh mục"
                                    maxLength={100}
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.name.length == 0 ? this.setState({nameErr: true, btnStatus: true}): this.setState({nameErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.nameErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Tên danh mục đang để trống !</label>
                            </div>:<div/>}
                            <div className="row">
                                <label className="col-12 mt-2">Đường dẫn </label>
                                <input
                                    className={this.state.pathErr?" w-100 col-12 border-input-error":" w-100 col-12 "}
                                    placeholder="Nhập vào đường dẫn"
                                    maxLength={100}
                                    value={this.state.path}
                                    onChange={(e) => this.setState({path: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.path.length == 0 ? this.setState({pathErr: true, btnStatus: true}): this.setState({pathErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>

                            <div className="row">
                                <label className="col-12 mt-2">Vị trí hiển thị </label>
                                <input
                                    className={this.state.positionErr?" w-100 col-12 border-input-error":" w-100 col-12 "}
                                    placeholder="Nhập vị trí hiển thị trên menu"
                                    maxLength={3}
                                    value={this.state.position}
                                    onChange={(e) => this.setState({position: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.position.length == 0 ? this.setState({positionErr: true, btnStatus: true}): this.setState({positionErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>

                            {this.state.pathErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Đường dẫn không được để trống!</label>
                            </div>:<div/>}
                            <div className="row">
                                <label className="col-12 mt-2">Danh mục cha</label>
                                <select className="form-control shadow-none"
                                        defaultValue={0}
                                        value={this.state.categoryId}
                                        onChange={(val) => {
                                            this.setState({
                                                categoryId: val.target.value,
                                                onEdit: true
                                            })
                                        }}
                                >
                                    <option value={0} disabled>Chọn một danh mục</option>
                                    {this.state.listCategory.map((item, index) =>
                                        <option selected={item.id == this.state.parentId} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>
                            <div className="row">
                                <label className="col-12 mt-2">Loại danh mục</label>
                                <select className="form-control shadow-none"
                                        defaultValue={0}
                                        value={this.state.type}
                                        onChange={(val) => {
                                            this.setState({
                                                type: val.target.value,
                                                onEdit: true
                                            })
                                        }}
                                >
                                    <option value={1} selected={this.state.type === 1}>Tin tức sự kiện</option>
                                    <option value={2} selected={this.state.type === 2}>Văn bản pháp luật</option>
                                </select>
                            </div>

                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-75 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                         Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAddEditCategory;
