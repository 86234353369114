import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let ContractLog = {
    searchByContractId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract-log/search-by-contract-id?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
}
export default ContractLog
