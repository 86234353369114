export const reportCodeOptions = [
    {
      label: "Báo cáo kết quả hoạt động sản xuất kinh doanh - năm",
      value: "v20GLTC2"
    },
    {
      label: "Báo cáo KQ HĐSXKD giữa niên độ (Dạng đầy đủ)",
      value: "V20GLTC21"
    },
    {
      label: "Báo cáo KQ HĐSXKD giữa niên độ (Dạng tóm lược)",
      value: "V20GLTC22"
    },
    {
      label: "Báo cáo theo thông tư 200",
      value: "V20GLTC23"
    },
    {
      label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
      value: "V20GLTC24"
    },
    {
      label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
      value: "V20GLTC25"
    },
    {
      label: "Báo cáo theo thông tư 133",
      value: "V20GLTC26"
    }
  ]
export const reportCodeOptions200 = [
    {
        label: "Báo cáo theo thông tư 200",
        value: "V20GLTC23"
    },
    {
        label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
        value: "V20GLTC24"
    },
    {
        label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
        value: "V20GLTC25"
    }
  ]
export const reportCodeOptions133 = [
    {
        label: "Báo cáo kết quả hoạt động sản xuất kinh doanh - năm",
        value: "v20GLTC2"
    },
    {
        label: "Báo cáo KQ HĐSXKD giữa niên độ (Dạng đầy đủ)",
        value: "V20GLTC21"
    },
    {
        label: "Báo cáo KQ HĐSXKD giữa niên độ (Dạng tóm lược)",
        value: "V20GLTC22"
    },

    {
        label: "Báo cáo theo thông tư 133",
        value: "V20GLTC26"
    }
]
export const reportFormOptions = [
    {
      label: "Mẫu chuẩn - năm",
      value: "10"
    },
    {
      label: "Mẫu chuẩn - giữa niên độ",
      value: "20"
    },
    {
      label: "Mẫu chuẩn - bán niên",
      value: "30"
    }
  ]