import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
import {
    Modal,
} from 'react-bootstrap';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecureCustomerApi from "../../services/ob-user/secure-customer";
import Autosuggestion from "../Form/Autosuggestion";
import RoleApi from "../../services/ob-user/role";

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

class ModalDetailUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            permissionName: "",
        };
    }

    componentDidMount() {
        // this.getDetail();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.getDetail()
        }
    }

    getDetail = () => {
        let payload = {
            id: this.props.id
        };
        SecureCustomerApi.getGetDetailUser(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data
                }, () => this.getNameRolebyId())
            } else {
                toast.error("Đã có lỗi xảy ra !")
            }
        })
    }

    getNameRolebyId = () => {
        let payload = {
            id: this.state.listData.permissionId
        }
        RoleApi.getNameById(payload, res => {
            if(res.success) {
                this.setState({
                    permissionName: res.data
                })
            } else {
                toast.error("Đã có lỗi với lấy tên quyền")
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };

        let {listData} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>CHI TIẾT USER</b></h1>
                        </div>

                        <div className='mt-5 mb-5 ml-3 mr-3'>
                            <div className="row">
                                <label className="col-5 mt-2">Họ và tên: </label>
                                <div className={" w-100 col-7 mt-2"}>
                                    {listData.fullName}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số điện thoại: </label>
                                <div className={" w-100 col-7 mt-2"}>
                                    {listData.phone}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhóm quyền: </label>
                                <div className={" w-100 col-7 mt-2"}>
                                    {this.state.permissionName}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Vị trí: </label>
                                <div className={" w-100 col-7 mt-2"}>
                                    {listData.position}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Email: </label>
                                <div className={" w-100 col-7 mt-2"}>
                                    {listData.email}
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalDetailUser;
