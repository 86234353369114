import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let SecureCustomerApi = {
    getListCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-one-book-get-customer?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    adminOneBookUpdateCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-one-book-update-customer/${payload.id}`,
            method: 'POST',
            body: {
                ...payload.body,
            },
        }, callback)
    },
    callCenterGetListCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/call-center-get-customer?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    getDetailCustomer: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-one-book-get-detail-customer/${payload.id}`,
            method: 'GET',
        }, callback);
    },
    checkExist: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/check-exist/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    detail: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/detail`,
            method: 'GET'
        }, callback)
    },
    getNameById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/get-name-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getNamesByListId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/get-names-by-list-id?ids=${payload.ids.toString()}`,
            method: 'GET'
        }, callback)
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/update`,
            method: 'POST',
            body: {
                ...payload.body,
            },
        }, callback)
    },
    adminCustomerUpdateInfo: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-customer-update-info`,
            method: 'POST',
            body: {
                ...payload.body,
            },
        }, callback)
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },
    getDetailCusById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/detail/${payload.id}`,
            method: "GET"
        }, callback)
    },
    adminCustomerSearch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-customer-search?${stringify(payload)}`,
            method: "GET"
        }, callback)
    },
    adminCustomerCreateUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-customer-create-user`,
            method: "POST",
            body: payload
        }, callback)
    },
    adminCustomerDeleteUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-customer-detele-user`,
            method: "POST",
            body: payload
        }, callback)
    },
    adminCustomerResetPasswordUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-customer-reset-password-user`,
            method: "POST",
            body: payload
        }, callback)
    },
    getGetDetailUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/detail/${payload.id}`,
            method: "GET"
        }, callback)
    },
    blockUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/block/${payload.id}`,
            method: "GET"
        }, callback)
    },
    unBlockUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/unblock/${payload.id}`,
            method: "GET"
        }, callback)
    },
    updateUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/update/${payload.id}`,
            method: "POST",
            body: payload.body
        }, callback)
    },
    getCustomer: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-user/secure/customer/partner-get-customer?${stringify(payload)}`,
            method: "GET",
        }, callback)
    },
    adminOneBookResetPassword: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/customer/admin-one-book-reset-password`,
            method: "POST",
            body: payload
        }, callback)
    }
};
export default SecureCustomerApi
