import React from 'react';
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';

import PublicOrderApi from "../../services/ob-core/public-order-service";
import {toast} from "react-toastify";

class ModalAcceptOrderCallCenter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            detailId: '',
        };
    }


    accept = () => {
        let payload = {
            id: this.props.selectedOrder.id
        };
        PublicOrderApi.callCenterAcceptOrder(payload, res => {
            if (res.success === true) {
                toast.success(`Xác nhận Order "${this.props.selectedOrder.code}" thành công`)
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error('Xác nhận Order không thành công')
            }
        });
    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
            acceptId: this.props.acceptId
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 18,}}>Bạn có chắc chắn xác nhận
                                Order {this.props.selectedOrder.code} không ?</h1>
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s background-btn type-button-fix btn btn-primary"
                                        onClick={() => this.accept()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s width-btn type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAcceptOrderCallCenter
