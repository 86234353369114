import React from "react";
import Sidebar from "../../../components/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Row, Col,
    Button,
    CardHeader,
    Card,
    CardBody,
    Progress,
    TabContent,
    TabPane,
} from 'reactstrap';

import {
    AreaChart, Area,
    // Line,
    ResponsiveContainer,
    // Bar,
    BarChart,
    ComposedChart,
    CartesianGrid,
    Tooltip,
    LineChart
} from 'recharts';

import { Bar, Line } from 'react-chartjs-2';
import DashboardUser from "../../../services/ob-user/dashboard-user";
import DashboardCore from "../../../services/ob-core/dashboard";
import PageHeader from "../../../components/PageHeader";
import lang from "../contracts-management/lang";
import moment from "moment";


class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            labelsSup: [],
            labelsAcc: [],
            dataSup: [],
            dataAcc: [],
            monthSupAcc: [],
            monthRevenue: [],
            dataRevenue: [],
            orderData: null,
        }
    }

    componentDidMount() {
        this.getSupAcc();
        this.getRevenue();
        this.getOrder();
    }


    getSupAcc = () => {
        DashboardUser.getSupAcc(res => {
            if (res.success) {
                let dataSup = []
                let monthSupAcc = []
                let dataAcc = []
                this.setState({})
                res.data.countNewSupervisor.map((item) => {
                    dataSup.push(item.total)
                    monthSupAcc.push(`Tháng ${item.month}`)
                })
                res.data.countNewAccountant.map((item) => {
                    dataAcc.push(item.total)
                })
                this.setState({countNewSupervisor: res.data.countNewSupervisor, countNewAccountant: res.data.countNewAccountant, dataSup, dataAcc, monthSupAcc})
            }
        })
    }

    getRevenue = () => {
        DashboardCore.getRevenue(res => {
            if (res.success) {
                let monthRevenue = [];
                let dataRevenue = [];
                res.data.map((item) => {
                    monthRevenue.push(`Tháng ${item.month}`);
                    dataRevenue.push(item.total)
                })
                this.setState({monthRevenue, dataRevenue})
            }
        })
    }

    getOrder = () => {
        DashboardCore.getContractOrder(res => {
            if (res.success) {
                this.setState({orderData: res.data})
            }
        })
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="dashboard"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Dashboard"/>
                                </div>
                                <div className="d-flex">
                                    <div className="col-md-6 col-xs-12 p-3">
                                        <div className="text-align-center">
                                            <span className="dashboard-title-item">NHÂN SỰ</span>
                                        </div>
                                        <div className=" mb-3 widget-chart">
                                            <Bar
                                                data={{
                                                    // labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
                                                    labels: this.state.monthSupAcc,
                                                    datasets: [
                                                        {
                                                            label: 'Supervisor',
                                                            backgroundColor: 'rgba(75,192,192,0.4)',
                                                            borderColor: 'rgba(75,192,192,1)',
                                                            borderWidth: 1,
                                                            hoverBackgroundColor: 'rgba(75,192,192,1)',
                                                            hoverBorderColor: 'rgba(220,220,220,1)',
                                                            data: this.state.dataSup
                                                        },
                                                        {
                                                            label: 'Kế toán',
                                                            backgroundColor: 'rgba(255,99,132,0.2)',
                                                            borderColor: 'rgba(255,99,132,1)',
                                                            // backgroundColor: '#C6E6F1',
                                                            // borderColor: '#0075dd',
                                                            borderWidth: 1,
                                                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                            hoverBorderColor: 'rgba(255,99,132,1)',
                                                            // hoverBackgroundColor: '#3ba5f7',
                                                            // hoverBorderColor: '#0075dd',
                                                            data: this.state.dataAcc
                                                        },
                                                    ]
                                                }}

                                                // width={100}
                                                height={350}
                                                options={{ maintainAspectRatio: false }}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12 p-3">
                                        <div className="text-align-center">
                                            <span className="dashboard-title-item">DOANH THU</span>
                                        </div>
                                        <Line ref="chart"
                                              data={{
                                                    labels: this.state.monthRevenue,
                                                    datasets: [
                                                        {
                                                            label: 'Doanh thu',
                                                            fill: false,
                                                            lineTension: 0.1,
                                                            // backgroundColor: 'rgba(75,192,192,0.4)',
                                                            // borderColor: 'rgba(75,192,192,1)',
                                                            backgroundColor: '#0075dd',
                                                            borderColor: '#0075dd',
                                                            borderCapStyle: 'butt',
                                                            borderDash: [],
                                                            borderDashOffset: 0.0,
                                                            borderJoinStyle: 'miter',
                                                            pointBorderColor: 'rgba(75,192,192,1)',
                                                            pointBackgroundColor: '#fff',
                                                            pointBorderWidth: 1,
                                                            pointHoverRadius: 5,
                                                            // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                                            // pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                            pointHoverBackgroundColor: '#0075dd',
                                                            pointHoverBorderColor: '#0075dd',
                                                            pointHoverBorderWidth: 2,
                                                            pointRadius: 1,
                                                            pointHitRadius: 10,
                                                            data: this.state.dataRevenue
                                                            // data: [5000000,1000000,3000000]
                                                        }
                                                    ],
                                              }}
                                              height={250}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-4">
                                    <div className="col-md-4">
                                        <div className="dashboard-info-item" style={{backgroundColor: '#D8FEDF'}}>
                                            <div className="">
                                                <span className="dashboard-title-item">ORDER CƠ BẢN</span>
                                            </div>
                                            <div className="mb-1">
                                                <i className="" style={{fontSize: '0.75rem'}}>Ngày {moment(new Date).format("DD-MM-YYYY")}</i>
                                            </div>
                                            <div className="mt-3">
                                                <span className="dashboard-content-item">{this.state.orderData?.totalOrderBasic}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dashboard-info-item" style={{backgroundColor: '#FFDDDE'}}>
                                            <div className="">
                                                <span className="dashboard-title-item">ORDER NÂNG CAO</span>
                                            </div>
                                            <div className="mb-1">
                                                <i className="" style={{fontSize: '0.75rem'}}>Ngày {moment(new Date).format("DD-MM-YYYY")}</i>
                                            </div>
                                            <div className="mt-3">
                                                <span className="dashboard-content-item">{this.state.orderData?.totalOrderAdvanced}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dashboard-info-item" style={{backgroundColor: '#D3EEFF'}}>
                                            <div className="">
                                                <span className="dashboard-title-item">HỢP ĐỒNG</span>
                                            </div>
                                            <div className="mb-1">
                                                <i className="" style={{fontSize: '0.75rem'}}>Ngày {moment(new Date).format("DD-MM-YYYY")}</i>
                                            </div>
                                            <div className="mt-3">
                                                <span className="dashboard-content-item">{this.state.orderData?.totalContract}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Dashboard
