import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let CompanyApi = {
    autocompleteGetCompanyName: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/company/get-list-name-auto-complete?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
};
export default CompanyApi
