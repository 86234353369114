import React, {Component} from "react";
import clsx from "clsx";

import {Link} from "react-router-dom";

import InformationOfSupervisor from "./InformationSup";
import Accountant from "../supervisor-detail/accountant";
import Sidebar from "../../../../components/Sidebar";
import Convert from "../../../../utils/convertUrlPra";
import ListContract from "../../../partner/supervisor-detail/list-contract";


class DetailSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            contractId: '',
            supervisorId: '',
            idContract: '',
            id: '',
            onTab: 1,
            listData: [],
            code: '',
            onEdit: false,
        }
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({supervisorId: params.supervisorId})
    }


    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };


    render() {
        let {value, onTab, listData} = this.state;
        console.log(this.props.location.state);
        const id = this.props.location.state?.id;
        const supervisorId = this.props.location.state?.supervisorId;
        const code = this.props.location.state?.code;

        let params = Convert.urlParams(this.props.location.search);
        // console.log("param>>>>>", params)
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="supervisor-management" parentClass="employee-management"/>
                        <div className="col-md-10 tab-content">
                            <div className='card' style={{padding: 15}}>
                                <span>
                                    <Link to={"/admin/supervisor-management"}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                <span style={{fontSize: 22}}
                                      className='text-center mb-4'>THÔNG TIN CHI TIẾT SUPERVISOR</span>
                                <nav className="nav nav-tabs justify-content-center" role="tablist">
                                    <a
                                        className={onTab === 1 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                        onClick={() => this.setState({onTab: 1})}
                                    >
                                        Thông tin chung
                                    </a>
                                    <a
                                        className={onTab === 2 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                        onClick={() => this.setState({onTab: 2})}
                                    >
                                        Danh sách kế toán
                                    </a>
                                    <a
                                        className={onTab === 3 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                        onClick={() => this.setState({onTab: 3})}
                                    >
                                        Danh sách hợp đồng
                                    </a>
                                </nav>

                                <div className="tab-content">
                                    <div
                                        className={onTab === 1 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <InformationOfSupervisor value={params.supervisorId}/>
                                    </div>
                                    <div
                                        className={onTab === 2 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <Accountant value={params.supervisorId}/>
                                    </div>
                                    <div
                                        className={onTab === 3 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ListContract supervisorId={params.supervisorId}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default DetailSupervisor
