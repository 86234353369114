/**
 * Created by WebStorm.
 * User:
 * Date:
 * Time:
 *
 * * User: Nguyen.Ngoc.Thinh
 * Update: 26/03/2020
 * Time: 11:00
 */

import React, {Component} from "react";
import lang from "./lang";
import moment from "moment";
import "./index.css";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../../components/Sidebar";
import Pagination from "../../../../components/Pagination";
import TableHeader from "../../../../components/TableHeader/index";
import PageHeader from "../../../../components/PageHeader";

import ModalAssignAccountant from "../../../../components/Modal/modalAssignAccountant";
import ModalChangeSupForAccoutant from "../../../../components/Modal/modalChangeSupForAccoutant";

import AccountantApi from "../../../../services/ob-user/accountant";
import BlockAccountant from "../../../../components/Modal/modalBlockAccountant";
import DeleteAccountant from "../../../../components/Modal/modalDeleteAccountant";
import UnblockAccountant from "../../../../components/Modal/modalUnblockAccountant";
import {toast} from "react-toastify";
import ModalResetPass from "../../../../components/Modal/modalResetPassAccountant";

class Accountant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: "",
            contractId: "",
            sku_contract: "",
            status_temp: "",
            accountant_temp: "",
            listCustomer: [],
            listData: [],
            listId: [],
            pageNumber: 0,
            pageSize: 10,
            page: 0,
            fullName: "",
            fullName_temp: "",
            status: "",
            idAccountant: '',
            // sort: "id=asc",
            totalItem: 0,
            status_temp_search: "",
            accountantId: "",
            customerId: "",
            itemSelected: "",
            nameUser: "",
            showModalReset: false,
            modalAssignAccountant: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "fullName",
                    name: "Tên kế toán",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "phone",
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: true
                },


                {
                    id: "countContractActive",
                    name: "Số hợp đồng đang thực hiện",
                    style: {textAlign: "center"},
                    sort: false
                },

                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {textAlign: "center", minWidth: 185},
                    sort: false
                }
            ]
        };
    }


    componentDidMount() {

        this.getListAccountantFromAPI();
    }


    // ---------------------------------------------------------------------------------------------------------


    getListAccountantFromAPI() {

        let {status, fullName, sort, pageNumber, pageSize} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            fullName: fullName,
            status: status,
            sort: sort,
            supervisorId: this.props.value
        };
        AccountantApi.findBySupervisorIdAndPartnerId(payload, res => {
            if (!res.success) {
                toast.error('Đã xảy ra lỗi trong quá trình lấy dữ liệu. Vui lòng thử lại !')
            } else {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize)
                })
            }
        });
    }

    //BUTTON ---------------------------------------------------------------------------------------------------------

    onhandleSearch = () => {
        let {fullName_temp, status_temp} = this.state;

        this.setState(
            {
                fullName: fullName_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListAccountantFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                accountant_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onhandleSearch()
        );
    }

    // --------------------------------------------------------------------------------------------------------

    renderStatus(status) {
        switch (status) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return '';
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListAccountantFromAPI();
            }
        );
    };
    // ------------------------------------------------------------------------------------------------------

    render() {
        let {listData, pageNumber, header} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="pt-3 mb-2">
                        <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 pr-0 pr-sm-3 p-0">
                                    <label className="label-item">Tên kế toán</label>
                                    <input
                                        placeholder="Nhập vào tên kế toán..."
                                        name="Code"
                                        type="text"
                                        className="form-control shadow-none font-14"
                                        style={{width: "100%", height: 35, padding: 8}}
                                        value={this.state.fullName_temp}
                                        maxLength={50}
                                        onKeyUp={e => {
                                            if (e.key === "Enter") this.onhandleSearch();
                                        }}
                                        onChange={val =>
                                            this.setState({fullName_temp: val.target.value.toLocaleLowerCase()})
                                        }
                                    />
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-0 pr-sm-0 p-0">
                                    <label className="label-item">Trạng thái</label>
                                    <select
                                        value={this.state.status_temp}
                                        onChange={val =>
                                            this.setState({status_temp: val.target.value}
                                                // console.log(this.state.status_temp)
                                            )
                                        }
                                        className="form-control kt-input pd-4-input shadow-none"
                                    >
                                        <option value=''>Tất cả</option>
                                        <option value="ACTIVE">Hoạt động</option>
                                        <option value="LOCK">Khóa</option>
                                        <option value="CANCELED">Đã hủy</option>
                                        <option value="WAIT_COMPLETE_INFO">Chờ kích hoạt</option>
                                        <option value="WAIT_CONFIRM_EMAIL">Chờ xác nhận</option>
                                        {/*<option value="REJECTED">Từ chối</option>*/}

                                    </select>
                                </div>
                                <div className='mt-3 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  '>

                                    <button
                                        type="button"
                                        style={{height: 35}}
                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                        onClick={() =>
                                            this.onhandleSearch()
                                        }
                                    >
                                        Tìm kiếm
                                    </button>
                                    {/*&nbsp;&nbsp;*/}
                                    <button type="button"
                                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                            style={{height: 34}}
                                            onClick={() => {
                                                this.setState({
                                                    fullName_temp: '',
                                                    status_temp: ''
                                                }, () => this.onhandleSearch())
                                            }}
                                    >Refresh
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="p-0 mb-3">
                        <div className="table-wrapper">
                            <table className="table  table-bordered table-striped table-data table-responsive">
                                <table className="table">
                                    <thead>
                                    <TableHeader
                                        header={header}
                                        clickSort={data => {
                                            console.log(data);
                                            let a = "";
                                            if (data.sortAsc == null) {
                                                a = null;
                                            } else {
                                                a = `${data.sortId}=${
                                                    data.sortAsc ? "asc" : "desc"
                                                }`;
                                            }
                                            console.log(a);
                                            this.setState(
                                                {
                                                    sort: a
                                                },
                                                () => this.getListAccountantFromAPI()
                                            );
                                        }}
                                    />
                                    </thead>
                                    {!this.state.listData ||
                                    this.state.listData.length == 0 ? (
                                        <tbody>
                                        <tr>
                                            <td colSpan={12}>
                                                <div className="text-center">
                                                    Không có dữ liệu
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                        {listData &&
                                        listData.length > 0 &&
                                        listData?.map((row, index) => (
                                            <tr style={{height: 50}}>
                                                <td style={{textAlign: "center"}} scope="row">
                                                    {" "}
                                                    {this.state.pageNumber * this.state.pageSize +
                                                    index +
                                                    1}
                                                </td>
                                                <td style={{textAlign: "left"}} scope="row">
                                                    {row.fullName}
                                                </td>
                                                <td style={{textAlign: "left"}}>
                                                    {row.phone}
                                                </td>
                                                <td className='pl-4' style={{textAlign: "center"}}>
                                                    {row.countContractActive}
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    {this.renderStatus(row.status)}
                                                </td>
                                                <td className='p-1' style={{textAlign: "center"}}>
                                                    {/*{this.renderIcon(row)}*/}
                                                    {/*<button*/}
                                                    {/*    onClick={() => this.setState({*/}
                                                    {/*        showDetail: true,*/}
                                                    {/*        idAccountant: row.id*/}
                                                    {/*    })}*/}
                                                    {/*    className="btn btn-action"*/}
                                                    {/*    title="Xem chi tiết"*/}
                                                    {/*>*/}
                                                    {/*    <i className="ti-book"/>*/}
                                                    {/*</button>*/}
                                                    <Link
                                                        to={{
                                                            pathname: "/partner/accountant-detail",
                                                            search: `?accountantId=${row.id}&supervisorId=${this.props.value}`,
                                                            state: {
                                                                fromDashboard: true,
                                                                accountantId: row.id,
                                                                supervisorId: this.props.value
                                                            }
                                                        }}
                                                    >
                                                        <button className="btn btn-action p-2"
                                                                title='Xem chi tiết'
                                                                style={{cursor: 'pointer'}}
                                                            // onClick={()=> window.OBPubConfig.loading = true}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                    </Link>
                                                    <button
                                                        onClick={() => this.setState({
                                                            modalAssignAccountant: true,
                                                            idAccountant: row.id
                                                        })}
                                                        className="btn btn-action p-2"
                                                        title="Chỉnh sửa kế toán"
                                                    >
                                                        <i className="ti-pencil-alt"/>
                                                    </button>
                                                    {row.status !== "LOCK" ?
                                                        <button
                                                            disabled={row.status === 'WAIT_COMPLETE_INFO' || row.status === 'WAIT_CONFIRM_EMAIL'}
                                                            onClick={() => this.setState({
                                                                selectedItem: row,
                                                                modalBlock: true
                                                            })}
                                                            className="btn btn-action p-2"
                                                            title="Khóa"
                                                        >
                                                            <i className="ti-lock"/>
                                                        </button> :
                                                        <button
                                                            disabled={row.status === 'WAIT_COMPLETE_INFO' || row.status === 'WAIT_CONFIRM_EMAIL'}
                                                            onClick={() => this.setState({
                                                                selectedItem: row,
                                                                modalUnblock: true
                                                            })}
                                                            className="btn btn-action p-2"
                                                            title="Mở khóa"
                                                        >
                                                            <i className="ti-unlock"/>
                                                        </button>}
                                                    <button
                                                        onClick={() => this.setState({
                                                            selectedItem: row,
                                                            modalDelete: true
                                                        })}
                                                        className="btn btn-action p-2"
                                                        disabled={row.status == "CANCELED"}
                                                        title="Xóa"
                                                    >
                                                        <i className="ti-trash"/>
                                                    </button>
                                                    <button
                                                        className="btn btn-action p-2"
                                                        title="Reset"
                                                        onClick={() => {
                                                            this.setState({
                                                                itemSelected: row.id,
                                                                nameUser: row.fullName
                                                            }, () => this.setState({showModalReset: true}))
                                                        }}
                                                    >
                                                        <i className="ti-reload"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    )}
                                </table>
                            </table>
                        </div>
                        <div className="pagination-right">
                            <Pagination
                                activePage={this.state.pageNumber + 1}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalItem}
                                changeHandler={page => this.handleChangePage(page)}
                            />
                        </div>
                    </div>
                </div>
                <ModalChangeSupForAccoutant
                    show={this.state.modalAssignAccountant}
                    onHide={() => this.setState({modalAssignAccountant: false})}
                    onRefresh={() => this.getListAccountantFromAPI()}
                    id={this.props.value}
                    idAccountant={this.state.idAccountant}
                />
                {/*modalAssignAccountant*/}
                {this.state.modalBlock &&
                <BlockAccountant id={this.state.selectedItem.id}
                                 show={this.state.modalBlock}
                                 onRefresh={() => this.setState({modalBlock: false}, () => {
                                     this.getListAccountantFromAPI()
                                 })}
                                 onHide={() => this.setState({modalBlock: false})}/>}
                {this.state.modalDelete &&
                <DeleteAccountant id={this.state.selectedItem.id}
                                  show={this.state.modalDelete}
                                  onRefresh={() => this.setState({modalDelete: false}, () => {
                                      this.getListAccountantFromAPI()
                                  })}
                                  onHide={() => this.setState({modalDelete: false})}/>}
                {this.state.modalUnblock &&
                <UnblockAccountant id={this.state.selectedItem.id}
                                   show={this.state.modalUnblock}
                                   onRefresh={() => this.setState({modalUnblock: false}, () => {
                                       this.getListAccountantFromAPI()
                                   })}
                                   onHide={() => this.setState({modalUnblock: false})}/>}
                {this.state.showModalReset && <ModalResetPass
                    show={this.state.showModalReset}
                    onHide={() => this.setState({showModalReset: false})}
                    id={this.state.itemSelected}
                    name={this.state.nameUser}
                />
                }
            </div>
        );
    }
}


export default Accountant
