import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Utils from  '../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import {toast} from "react-toastify";
import Autosuggestion from "../Form/Autosuggestion";
import RoleApi from "../../services/ob-core/admin-role";
import SecureCustomerApi from "../../services/ob-user/secure-customer";

// import PartnerApi from "../../services/ob-user/partner";

class modalUpdateUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            phone:'',
            email:'',
            position:'',
            listRole:[],
            permissions: "",
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            positionErr: false,
            permissionsErr: false,
            btnStatus: false,
        };
    }
    componentDidMount() {
        this.setState({
            name:'',
            phone:'',
            email:'',
            position:'',
            permissions: "",
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            positionErr: false,
            permissionsErr: false,
            btnStatus: false,
        });
        this.getDetail()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                userName: '',
                userPhone: '',
                role:'',
                position: "",
                email: "",
                // provinceId: "",

                roleSearch: '',

                // listRole: [],
                clear: false,

                errUserName: false,
                errUserPhone: false,
                errRole: false,
                errEmail: false,
                errPosition: false
            });


        }
    }

    getDetail = () => {
        let payload = {
            id: this.props.id
        };
        SecureCustomerApi.getGetDetailUser(payload, res => {
            if (res.success) {
                this.setState({
                    userName: res.data.fullName,
                    userPhone: res.data.phone,
                    email: res.data.email,
                    position: res.data.position,
                    role: res.data.permissionId
                }, () => this.getListRole())

            } else {
                toast.error("Đã có lỗi xảy ra!")
            }
        })
    }

    getListRole = () => {
        let{pageNumber, pageSize, roleSearch} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            roleName: roleSearch,
        };
        RoleApi.getList(payload, res => {
            if(res.success) {
                this.setState({
                    listRole: res.data.content
                })
            }
        })
    }

    onSubmit =()=>{
        if(this.state.userName.length  == 0 && this.state.userPhone.length == 0 && this.state.email.length == 0 && this.state.position.length == 0 ){
            this.setState({
                nameErr: true,
                phoneErr: true,
                emailErr: true,
                positionErr: true,
            });
           toast.error("Không được để trống thông tin !")
        }else if(this.state.userName.length  == 0 ){
            this.setState({nameErr: true});
            toast.error("Không được để trống họ và tên !")
        }else if(this.state.userPhone.length <10 || this.state.userPhone.length == 0){
            this.setState({phoneErr: true});
            toast.error("Số điện thoại sai định dạng hoặc đang để trống !")
        }else if(this.state.email.length == 0 ){
            this.setState({emailErr: true});
            toast.error("Email đang để trống !")
        }else if(this.state.position.length == 0 ){
            this.setState({emailErr: true});
            toast.error("Vị trí đang để trống !")
        } else {
            this.onUpdate()
        }
    }

    onUpdate = () => {
        let payload = {
            id: this.props.id,
            body: {
                email: this.state.email.toLowerCase(),
                fullName: this.state.userName,
                // password: this.state.password,
                phone: this.state.userPhone,
                position: this.state.position,
                permissionId: this.state.role
            }
        }
        SecureCustomerApi.updateUser(payload, res => {
            if (res.errorCode == "One-Book-Auth.E000003") {
                toast.error(res.message[0])
            }
            else if (res.success) {
                toast.success("Chỉnh sửa thành công !")

                this.props.onHide();
                this.props.onRefresh();
            }
            else  {
               toast.error("Có lỗi xảy ra!")
            }
        })
    }


    render() {
        let {listRole, role} = this.state
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>CHỈNH SỬA USER</b></h1>
                        </div>

                        <div className='mt-5 mb-5 ml-3 mr-3'>
                            <div className="row">
                                <label className="col-5 mt-2">Họ và tên: </label>
                                <input
                                    className={this.state.errUserName ? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập họ và tên"
                                    maxLength={50}
                                    value={this.state.userName}
                                    onChange={(e) => this.setState({
                                            userName: e.target.value,
                                            errUserName: false
                                        },
                                        () => {
                                            this.state.userName.length == 0 ? this.setState({errUserName: true}) : this.setState({errUserName: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errUserName ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Tên đang để trống !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số điện thoại: </label>
                                <div className="col-7 mt-2">{this.state.userPhone}</div>
                            </div>
                            {this.state.errUserPhone ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">SĐT đang trống hoặc sai định dạng !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhóm quyền: </label>
                                <div className={this.state.errRole?'w-100 col-7 pl-0 pr-0 inputSuggest border-input-error ':'w-100 col-7 pl-0 pr-0 inputSuggest'}>
                                    <select
                                        value={this.state.role}
                                        onChange={val =>
                                            this.setState({role: val.target.value})
                                        }
                                        className="form-control kt-input pd-4-input shadow-none border-0">
                                        {/*<option value="0">check </option>*/}
                                        {listRole?.map((_item, index) => (
                                            <option value={_item.id}>{_item.nameRole}</option>)
                                        )}

                                    </select>
                                </div>
                            </div>
                            {this.state.errRole ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Vui lòng chọn nhóm quyền !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Vị trí: </label>
                                <input
                                    className={this.state.errPosition? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập vị trí"
                                    maxLength={50}
                                    value={this.state.position}
                                    onChange={(e) => this.setState({
                                            position: e.target.value,
                                            errPosition: false
                                        },
                                        () => {
                                            this.state.errPosition.length == 0 ? this.setState({errPosition: true}) : this.setState({errPosition: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errPosition ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">VỊ trí đang để trống !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Email: </label>
                                <input
                                    className={this.state.errEmail? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập email"
                                    maxLength={50}
                                    value={this.state.email}
                                    onChange={(e) => this.setState({
                                            email: e.target.value,
                                            errEmail: false
                                        },
                                        () => {
                                            this.state.errEmail.length == 0 ? this.setState({errEmail: true}) : this.setState({errEmail: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errEmail ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Email đang để trống !</label>
                            </div> :<div/>}

                        </div>


                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={() => this.onSubmit()}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-secondary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default modalUpdateUser;
