import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let EmailHistoryApi = {
    getListErrorEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-history/get-list-error-email?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    deleteErrorEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-history/delete/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    resendErrorEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/email-history/resend/${payload.id}`,
            method: "GET",
        }, callback)
    }
}
export default EmailHistoryApi;
