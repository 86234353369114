import React from 'react';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import AdminServicePlan from "../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";

class ModalDetailAdvanceServicePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataDetail: {},
            showCreate: true,
            typeService: '',
            name: '',
            description: '',
        };
    }

    componentDidMount() {
        this.getDetail()
    }

    getDetail = () => {
        let payload = {
            id: this.props.data.id
        };
        AdminServicePlan.getDetail(payload, res => {
            if(res.success){
                this.setState({
                    dataDetail: res.data
                })
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }

    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "DISABLED":
                return <h6><span className="badge badge-warning">Vô hiệu hóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center text-uppercase m-1 p-1" style={{fontSize: 16,}}>Gói dịch vụ
                            </h1>
                        </div>
                        <div>
                            <h1 className="text-center text-uppercase" style={{fontSize: 20,}}><b>{this.state.dataDetail.servicePlanName}</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                {/*<div className="col-12 d-flex mb-2">*/}
                                {/*    <label className="col-3 label-item pl-0 text-black-50">Tên dịch vụ</label>*/}
                                {/*    <label className="label-item">{this.state.dataDetail.servicePlanName}</label>*/}
                                {/*</div>*/}
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0 pr-2 text-black-50">Loại dịch vụ</label>
                                    <label className="label-item">{this.renderType(this.state.dataDetail.type)}</label>
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0 pr-2 text-black-50">Trạng thái</label>
                                    <label className="label-item">{this.renderStatus(this.state.dataDetail.status)}</label>
                                </div>
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-3 label-item pl-0 pr-2 text-black-50">Mô tả dịch vụ</label>
                                    <label className="label-item">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.dataDetail.content }} />
                                    </label>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDetailAdvanceServicePlan
