import React, {Component} from 'react'
import lang from "../../../../customer/profile/lang";

import AccountantApi from "../../../../../services/ob-user/accountant";
import {toast} from "react-toastify";
import ProvinceApi from "../../../../../services/ob-user/province";

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSelected: this.props.location.state ? this.props.location.state.accountantId : '',
            data: {},
            fullName: "",
            phone: "",
            email: "",
            areaId: "",
            onEdit: false,
            AreaSup: [],
            btnSaveStatus: true,

            fullNameError: false,
            fullNameErrorLength: false,
            areaIdError: false
        };
    }

    componentDidMount() {
        this.getSupportArea()
    }

    getSupportArea() {
        ProvinceApi.supportArea(res => {
            if (res.success) {
                this.setState({
                    AreaSup: res.data.content
                }, () => this.getDetail())
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    getDetail = () => {
        let payload = {
            id: this.props.accountantId
        };
        AccountantApi.getDetailAccountantById(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data,
                    fullName: res.data.fullName,
                    email: res.data.email,
                    areaId: res.data.areaId
                })
            } else {
                toast.error("Đã có lỗi xảy ra, Vui lòng tải lại trang!")
            }
        })
    }

    hanldeSubmit = () => {
        let {fullName, areaId, data} = this.state;
        let payload = {
            id: this.props.accountantId,
            body: {
                fullName,
                areaId,
                address: data.address,
                dateIssued: data.dateIssued,
                districtId: data.districtId,
                identify: data.identify,
                permissionId: data.permissionId,
                placeIssued: data.placeIssued,
                provinceId: data.provinceId,
                sex: data.sex
            }
        }
        AccountantApi.updateAccountantById(payload, res => {
            if (res.success) {
                toast.success('Cập nhật thông tin thành công !')
                this.getDetail()
                this.setState({onEdit: false})
            } else {
                toast.error('Cập nhật thông tin không thành công !')
            }
        })
    }

    render() {
        const {data, fullName, phone, email, areaId} = this.state;
        return (
            <div>
                <div className='row kt-margin-b-20 mt-4'>
                    <div
                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                        {this.state.onEdit === true
                            ? <label className="text-black-50 m-1">Họ và tên
                                <span className={'color-red d-inline'}>*</span></label>
                            : <label className="text-black-50">Họ và tên</label>
                        }
                        {/*<label className="text-black-50">Họ và tên {this.state.onEdit &&*/}
                        {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                    </div>
                    <div
                        className="pl-0 pb-0 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                        {this.state.onEdit ?
                            // <TextField
                            //     fullWidth
                            //     variant={"outlined"}
                            //     InputProps={{style: {height: 28}}}
                            //     value={fullName}
                            //     onChange={(val) => {
                            //         if (fullName.length < 50    ) this.setState({
                            //             fullName: val.target.value,
                            //             inEditing: true
                            //         }, () => {
                            //             fullName.length != 0 ? this.setState({fullNameError: false, btnSaveStatus:true}) : this.setState({fullNameError: true, btnSaveStatus:false})
                            //         })
                            //     }}
                            //     maxLength={50}
                            //     error={this.state.fullNameError}
                            //     helperText={this.state.fullNameError && 'Vui lòng nhập tên người đại diện !!'}
                            // />
                            <input type="text"
                                   className={this.state.fullNameError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                   placeholder='Nhập họ và tên'
                                   value={fullName}
                                   maxLength={50}
                                   onChange={(val) => this.setState({
                                       fullName: val.target.value.trimLeft(),
                                       inEditing: true
                                   }, () => {
                                       this.state.fullName.length == 0 ? this.setState({
                                           fullNameError: true,
                                           btnSaveStatus: false
                                       }) : this.setState({
                                           fullNameError: false,
                                           btnSaveStatus: true
                                       })
                                   })}
                            />
                            : data.fullName ? data.fullName.toUpperCase() : ''
                        }
                    </div>
                    <div
                        className="pl-0 pb-1 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                        {/*<label className="text-black-50">Số điện thoại</label>*/}
                        {this.state.onEdit === true
                            ? <label className="text-black-50 m-1">Số điện thoại</label>
                            : <label className="text-black-50">Số điện thoại</label>
                        }
                    </div>
                    <div
                        className="pl-0 pb-1 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                        {this.state.onEdit === true
                            ? <p className="mt-1">{data.phone ? data.phone : ''}</p>
                            : <span>{data.phone ? data.phone : ''}</span>}
                        {/*<span>{data.phone ? data.phone : ''}</span>*/}
                    </div>
                    <div
                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                        {/*<label className="text-black-50">Email </label>*/}
                        {this.state.onEdit === true
                            ? <label className="text-black-50 m-1">Email</label>
                            : <label className="text-black-50">Email</label>
                        }
                    </div>
                    <div
                        className="pl-0 pb-2 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                        {this.state.onEdit === true
                            ? <p className="mt-1">{data.email ? data.email : ''}</p>
                            : <span>{data.email ? data.email : ''}</span>}
                    </div>
                    <div
                        className="pl-0 pb-2 pr-0 col-md-4 col-lg-4 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                        {/*<label className="text-black-50">Khu vực hỗ trợ {this.state.onEdit &&*/}
                        {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                        {this.state.onEdit === true
                            ? <label className="text-black-50 m-1">Khu vực hỗ trợ
                                <span className={'color-red d-inline'}>*</span></label>
                            : <label className="text-black-50">Khu vực hỗ trợ</label>
                        }
                    </div>
                    <div
                        className="pl-0 pb-2 col-md-8 col-lg-8 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                        {this.state.onEdit ?
                            <select
                                className="form-control kt-input pd-4-input hei-input"
                                value={areaId || data.areaId}
                                defaultValue={0}
                                onChange={(e) => {
                                    this.setState({
                                        areaId: e.target.value,
                                        inEditing: true
                                    })
                                }}
                            >

                                {/*<option value={0}>Chọn Tỉnh/Thành Phố</option>*/}
                                {this.state.AreaSup?.map((item) => (
                                    <option value={item.id}>{item.name}</option>))}
                            </select>
                            : data.areaId ? renderProvinceId(data.areaId) : ''
                        }
                    </div>
                    {/*<div*/}
                    {/*    className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">*/}
                    {/*    <label className="text-black-50">Lĩnh vực hỗ trợ</label>*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*    className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">*/}
                    {/*    {data.fieldSupport ? data.fieldSupport : ''}*/}
                    {/*</div>*/}
                </div>
                {this.state.onEdit ?
                    <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                        <button
                            style={{cursor: this.state.btnSaveStatus ? 'pointer' : 'unset'}}
                            disabled={!this.state.btnSaveStatus}
                            className='btn btn-primary col-sm-3 col-md-3 mr-4'
                            onClick={() => this.hanldeSubmit()}
                            title="Lưu"
                        >
                            <span>Lưu</span>
                        </button>
                        <button
                            className='btn btn-light col-sm-3 col-md-3 border-1-s'
                            onClick={() => this.setState({onEdit: !this.state.onEdit})}
                            title="Hủy"
                        >
                            <span>Hủy</span>
                        </button>
                    </div>
                    : <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                        <button
                            className='btn btn-primary col-sm-3 col-md-3 mr-4'
                            onClick={() => this.setState({
                                onEdit: !this.state.onEdit,
                                // maxContract: data.maxContract,
                                // maxAccountant: data.maxAccountant,
                                // maxContractErr: false,
                                // maxAccountantErr: false,

                            })}
                            title="Chỉnh sửa thông tin"
                        >
                            <span>Chỉnh sửa thông tin</span>
                        </button>
                        {/*<button*/}

                        {/*    className='btn btn-light col-sm-3 col-md-3 border-1-s'*/}

                        {/*    title="Reset mật khẩu"*/}
                        {/*>*/}
                        {/*    <span>Reset mật khẩu</span>*/}
                        {/*</button>*/}
                    </div>}
            </div>
        );
    }
}

export default Information
