import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let OtpApi = {
    sendOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/otp/${payload.phone}`,
            method: 'GET'
        }, callback)
    },
    verifyOtp: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/otp/verify`,
            method: 'POST',
            body: payload,
        }, callback);
    },
};
export default OtpApi
