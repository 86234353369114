import React from 'react'
import Convert from "../../../../utils/convertUrlPra";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import TableHeader from '../../../../components/TableHeader';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import DependingTypeCommerce from './depending-type-commerce';
import DependingTypeBuild from './depending-type-build';
import DependingTypeManufacturing from './depending-type-manufacturing';
import AdminServicePlan from "../../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";

class DetailServicePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            typeService: '',
            name: '',
            description: '',
            selectedServicePackage: '',
            data_PERMANENT: [],
            data_TYPES_OF_BUSINESS_commerce: [],
            data_TYPES_OF_BUSINESS_commerce_bill: [],
            data_TYPES_OF_BUSINESS_commerce_certification: [],
            data_TYPES_OF_BUSINESS_commerce_human: [],
            data_TYPES_OF_BUSINESS_commerce_term: [],
            data_TYPES_OF_BUSINESS_manufacturing: [],
            data_TYPES_OF_BUSINESS_manufacturing_bill: [],
            data_TYPES_OF_BUSINESS_manufacturing_certification: [],
            data_TYPES_OF_BUSINESS_manufacturing_human: [],
            data_TYPES_OF_BUSINESS_manufacturing_term: [],
            data_TYPES_OF_BUSINESS_build: [],
            data_TYPES_OF_BUSINESS_build_bill: [],
            data_TYPES_OF_BUSINESS_build_certification: [],
            data_TYPES_OF_BUSINESS_build_human: [],
            data_TYPES_OF_BUSINESS_build_term: [],
            onTab: 1,
            header_PERMANENT: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên Gói cước",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },

            ],
        };
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({servicePlanId: params.servicePlanId}, () => this.getDetail())
    }

    getDetail = () => {
        let payload = {
            id: this.state.servicePlanId
        };
        AdminServicePlan.getDetail(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data
                })
                if(res.data?.priceType === 'PERMANENT') {
                    this.setState({
                        data_PERMANENT: res.data.serviceQuotationBaseResponseList
                    })
                } else if (res.data?.priceType === 'TYPES_OF_BUSINESS') {
                    this.setState({
                        data_TYPES_OF_BUSINESS_commerce: res.data.serviceQuotationBaseResponseList.filter(item => item.business === 'COMMERCE_SERVICE'),
                        data_TYPES_OF_BUSINESS_manufacturing: res.data.serviceQuotationBaseResponseList.filter(item => item.business === 'MANUFACTURING_MACHINING'),
                        data_TYPES_OF_BUSINESS_build: res.data.serviceQuotationBaseResponseList.filter(item => item.business === 'BUILDING_INSTALLATION'),
                    })
                }
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "DISABLED":
                return <h6><span className="badge badge-warning">Vô hiệu hóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };

    renderPriceType = (type) => {
        switch (type) {
            case 'PERMANENT':
                return 'Giá cố định';
            case 'TYPES_OF_BUSINESS':
                return 'Chia theo loại hình doanh nghiệp';
            default:
                return '';
        }
    };

    render() {
        let {onTab, data} = this.state;
        let userType = localStorage.getItem("ob_cl_userType");
        let params = Convert.urlParams(this.props.location.search);
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="service-plan-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Quản lý gói dịch vụ"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <span>
                                            <Link to={`/${userType.toLowerCase()}/service-plan-management`}>
                                                <i className="ti-arrow-left"/>&nbsp;
                                                Quay lại
                                            </Link>
                                        </span>
                                        <h2 style={{fontSize: 22}}
                                            className='text-center mb-4 text-uppercase'>{this.state.data.servicePlanName}</h2>
                                        <div className="row p-2">
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0 text-black-50">Mô tả dịch
                                                    vụ</label>
                                                <label className="label-item">
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.data.content }} />
                                                </label>
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0 text-black-50">Loại dịch
                                                    vụ</label>
                                                <label className="label-item">{this.renderType(this.state.data.type)}</label>
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0 text-black-50">Gói dịch
                                                    vụ</label>
                                                <label className="label-item">{this.renderPriceType(this.state.data.priceType)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    {this.state.data.priceType === 'PERMANENT' &&
                                    <div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead>
                                                <TableHeader header={this.state.header_PERMANENT}
                                                             clickSort={(data) => {
                                                             }}/>
                                                </thead>
                                                {!this.state.data_PERMANENT || this.state.data_PERMANENT.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.data_PERMANENT.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {/*{row.name}*/}
                                                                {row.requirementConstraint || 'Không có dữ liệu'}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center',}}>
                                                                {this.renderStatus(row.status)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                    }
                                    {this.state.data.priceType === 'TYPES_OF_BUSINESS' &&
                                    <div>
                                        <nav className="nav nav-tabs justify-content-center" role="tablist">
                                            <a
                                                className={onTab === 1 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                                onClick={() => this.setState({onTab: 1})}
                                            >
                                                Thương mại
                                            </a>
                                            <a
                                                className={onTab === 2 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                                onClick={() => this.setState({onTab: 2})}
                                            >
                                                Sản xuất
                                            </a>
                                            <a
                                                className={onTab === 3 ? "cursor-pointer nav-item nav-link active" : "cursor-pointer nav-item nav-link"}
                                                onClick={() => this.setState({onTab: 3})}
                                            >
                                                Xây dựng
                                            </a>
                                        </nav>


                                        <div className="tab-content">
                                            <div
                                                className={onTab === 1 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                            >
                                                <DependingTypeCommerce id={params.servicePlanId} data={this.state.data_TYPES_OF_BUSINESS_commerce}/>
                                            </div>
                                            <div
                                                className={onTab === 2 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                            >
                                                <DependingTypeManufacturing id={params.servicePlanId} data={this.state.data_TYPES_OF_BUSINESS_manufacturing}/>
                                            </div>
                                            <div
                                                className={onTab === 3 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                            >
                                                <DependingTypeBuild id={params.servicePlanId} data={this.state.data_TYPES_OF_BUSINESS_build}/>
                                            </div>
                                        </div>

                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default DetailServicePlan
