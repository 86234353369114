import React, {Component} from "react";
import {withSnackbar} from "notistack";
import Cookies from "js-cookie";
import '../index.css'

import {Route, Redirect, Router, Link} from "react-router-dom";
import {
    Card,
    Grid,
    Button,
    CardContent, NativeSelect
} from '@material-ui/core';
import ServicePlan_Advance from "../Advance";
// import SelectPlan from "../../../utils/modal/SelectPlan"
import Sidebar from "../../../../components/Sidebar";
import NumberFormat from "react-number-format";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";

import PublicServicePlanApi from "../../../../services/ob-core/public-service-plan";
import PublicServiceQuotationApi from "../../../../services/ob-core/public-service-quotation";
import PublicRequirementConstraintApi from "../../../../services/ob-core/public-requirement-constraint";

const business = [
    {
        type: "COMMERCE_SERVICE",
        name: 'Thương mại - Dịch vụ - Đầu tư tài chính'
    },
    {
        type: "MANUFACTURING_MACHINING",
        name: 'Sản xuất - Gia công',
    },
    {
        type: "BUILDING_INSTALLATION",
        name: 'Xây dựng - Lắp đặt - Đầu tư bất động sản'
    }
]

class ServicePlan_Basic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSelectModal: false,
            listRealmBasic: [],
            id: '',
            select: 0,
            typePlan: '',
            listPriceBasic: [],

            idService: "",
            planPrice: [],
            servicePlanQuotationId: null,
            requirementConstraint: [],
            selectData: [],
            selectedBusiness: null,
            billSelected: 0,
            documentSelected: 0,
            periodSelected: 0,
            employeeSelected: 0,
            selectedPlan: null,
        }
    }

    renderName(id) {
        switch (id) {
            case 1 :
                return 'COMMERCE_SERVICE';
            case 2 :
                return 'MANUFACTURING_MACHINING';
            case 3 :
                return 'BUILDING_INSTALLATION';
            default:
                return;
        }
    }

    componentDidMount() {
        this.getList();
        // this.getAllPricePlan();
        // this.getRequirementConstraintOnMonth();
    }

    getList = () => {
        let payload = {
            type: 'BASIC'
        };
        PublicServicePlanApi.getByType(payload, res => {
            console.log('>>>CHECK<<<', res);
            this.setState({listRealmBasic: res.data})
        })
    };

    getRequirementConstraintOnMonth = (callback) => {
        let payload = {};
        PublicRequirementConstraintApi.getAll(res => {
            console.log("response requirementConstraintOnMonth>>>>>", res);
            let data = res.data;
            if (res.success) {
                this.setState({requirementConstraint: res.data});
                // callback(res.data)
                let data = res.data;
                let selectData = {
                    bill: data.filter((_item) => _item.serviceId == 0),
                    documents: data.filter((_item) => _item.serviceId == 3),
                    employee: data.filter((_item) => _item.serviceId == 2),
                }
                this.setState({selectData})
                // return res.data
            }

        });
    };


    getAllServiceQuotation = (callback) => {
        PublicServiceQuotationApi.getAll(res => {
            console.log("response getAllServiceQuotation>>>>>>>", res);
            this.setState({listAllServices: res.data});
            callback(res.data)
            // return res.data
        });
    };

    getAllPricePlan = () => {
        let planPrice = [];
        let {idService} = this.state;
        this.getAllServiceQuotation((data) => {
            if (idService != 3) {
                business.map((item, index) => {
                    planPrice.push({
                        businessType: item.type,
                        businessName: item.name,
                        price: null,
                        data: {
                            report1: data.filter((_item) => _item.serviceId == 1 && _item.business == item.type),
                            report2: data.filter((_item) => _item.serviceId == 2),
                            report3: data.filter((_item) => _item.serviceId == 3),
                            report4: data.filter((_item) => _item.serviceId == 4 && _item.isUsedAllInOne && _item.business == item.type),
                            report4Aio: data.filter((_item) => _item.serviceId == 4 && !_item.isUsedAllInOne && _item.business == item.type),
                            report5: data.filter((_item) => _item.serviceId == 5 && _item.business == item.type),
                            report9: data.filter((_item) => _item.serviceId == 9 && _item.business == item.type),
                        },
                        planLocalId: index,
                    })
                })
            } else {
                // let filter = data.filter((_item) => _item.serviceId == 6)
                planPrice = data.filter((_item) => _item.serviceId == 6)
            }
            setTimeout(() => {
                console.log("data4>>>>", planPrice);
                this.setState({planPrice})
            }, 600)
        })

    }


    filterPrice = (realmId, data) => {
        let filter = data.filter((item) => item.realmId == realmId)
        console.log("filter>>>>>>", filter);
    }

    calculatePrice = () => {
        let {billSelected, documentSelected, periodSelected, employeeSelected, planPrice, selectedBusiness, idService} = this.state
        console.log("calculatePrice>>>>", selectedBusiness, billSelected, documentSelected, employeeSelected, periodSelected, planPrice)
        if (idService == 1) {
            if (billSelected != 0 || documentSelected != 0 || employeeSelected != 0 || periodSelected != 0) {
                let filter = planPrice.filter((item) => item.businessType == selectedBusiness);
                let data = filter[0].data;
                let idx = planPrice.indexOf(filter[0]);
                let totalPrice = null;
                if (billSelected == 6 || documentSelected == 14) {
                    planPrice[idx].price = "Giá thỏa thuận"
                } else {
                    let reportByBill = data.report1.filter(item => item.requirementConstraintOnMonthId == billSelected)
                    let priceByBill = reportByBill[0]?.price * periodSelected;
                    let reportByDocument = data.report3.filter(item => item.requirementConstraintOnMonthId == documentSelected)
                    let priceByDocument = reportByDocument[0]?.price * periodSelected;
                    let reportByEmployee = data.report2.filter(item => item.requirementConstraintOnMonthId == employeeSelected)
                    let priceByEmployee = reportByEmployee[0]?.price * periodSelected;
                    let priceReport4 = 0;
                    if (periodSelected != 12) {
                        let report4 = data.report4.filter(item => item.requirementConstraintOnMonthId == billSelected)
                        priceReport4 = report4[0].price * periodSelected;
                    } else {
                        let report4 = data.report4Aio.filter(item => item.requirementConstraintOnMonthId == billSelected)
                        priceReport4 = report4[0].price * 12;
                    }
                    let report5 = data.report9.filter(item => item.requirementConstraintOnMonthId == billSelected)
                    let priceReport5 = report5[0]?.price * periodSelected;
                    console.log("report>>>>>>>", reportByDocument, reportByEmployee)
                    planPrice[idx].price = priceByBill + priceByDocument + priceByEmployee + priceReport4 + priceReport5
                }
                this.setState({planPrice})
            } else {
                this.props.enqueueSnackbar('Vui lòng chọn đầy đủ thông tin trước khi tính giá cước!', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        } else if (idService == 2) {
            if (billSelected != 0 || periodSelected != 0) {
                let filter = planPrice.filter((item) => item.businessType == selectedBusiness);
                let data = filter[0].data;
                let idx = planPrice.indexOf(filter[0]);
                let report = data.report5.filter(item => item.requirementConstraintOnMonthId == billSelected)
                planPrice[idx].price = report[0].price * periodSelected
                this.setState({planPrice})
            } else {
                this.props.enqueueSnackbar('Vui lòng chọn đầy đủ thông tin trước khi tính giá cước!', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        } else {

        }
    }


    handleClick = (item) => {
        let name = this.renderName(item.id);
        this.setState(
            {idService: item.id, selected: item.id, typePlan: name},
            () => {
                this.getAllPricePlan();
                this.getRequirementConstraintOnMonth();
            }
        )
    };

    render() {
        let {planPrice, selectData, idService} = this.state;
        let self = this;
        return (
            <div>
                <Grid className='row' container spacing={0} justify="space-evenly" alignItems="center">
                    {this.state.listRealmBasic.map((item, index) => {
                        return (
                            <div className='col-xs-12 col-4 pt-4'>
                                <div className="justify-center">
                                    <div className="service-plan-div"
                                         onClick={() => {
                                             // this.handleClick(item)
                                         }}
                                    >
                                        <div className='card border-card-plan'>
                                            <div
                                                // className={this.state.selected == item.id ? 'border-card-plan-small p-2 pb-4' : 'border-card-plan-small-white p-2 pb-4'}>
                                                className={'p-2 pb-4 body-service-plan-card'}>
                                                <div
                                                    className='d-flex justify-content-center justify-center font-weight-bold mb-3 p-2 service-plan-name uppercase'>
                                                    {item.servicePlanName}
                                                    {/*<span className="font-weight-bold uppercase">{item.servicePlanName}</span>*/}
                                                </div>
                                                <div className='height-content'
                                                     dangerouslySetInnerHTML={{__html: item.content}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );

                    })}
                </Grid>

                {/*{this.state.idService && (this.state.idService != 3 ?*/}
                {/*        <Grid className='row' container spacing={0} justify="space-evenly" alignItems="center">*/}
                {/*            {this.state.planPrice.map((item, index) => {*/}
                {/*                return (*/}
                {/*                    <Grid item xs={12} md={4} sm={4} className='pt-4'>*/}
                {/*                        <Grid item md={10} sm={10} justify={'center'}>*/}
                {/*                            <div className="service-plan-div">*/}
                {/*                                <Card className='border-card-plan'>*/}

                {/*                                    <div*/}
                {/*                                        className='d-flex justify-content-center font-weight-bold mt-2 mb-2'>*/}
                {/*                                        {item.businessName ? item.businessName.toUpperCase() : ''}*/}
                {/*                                    </div>*/}

                {/*                                    <CardContent>*/}
                {/*                                        <div className='pt-2 justify-content-center'>*/}

                {/*                                            <Grid item xs={12} className='row'>*/}
                {/*                                                <Grid item xs={7}>*/}
                {/*                                            <span className='pr-3 width-info'>*/}
                {/*                                                Số hóa đơn:*/}
                {/*                                            </span>*/}
                {/*                                                </Grid>*/}
                {/*                                                <Grid item xs={5}>*/}
                {/*                                                    <NativeSelect*/}
                {/*                                                        className="align-center width-select"*/}
                {/*                                                        onChange={(val) => {*/}
                {/*                                                            console.log("billllll>>", val.target.value)*/}
                {/*                                                            this.setState({*/}
                {/*                                                                selectedBusiness: item.businessName,*/}
                {/*                                                                billSelected: parseInt(val.target.value),*/}
                {/*                                                            });*/}
                {/*                                                        }}*/}
                {/*                                                    >*/}
                {/*                                                        <option value={0}>SL hóa đơn</option>*/}
                {/*                                                        {selectData.bill.map((_item, index) => {*/}
                {/*                                                            return (*/}
                {/*                                                                <option*/}
                {/*                                                                    value={index + 1}*/}
                {/*                                                                    name={_item.requirementConstraint}>{_item.requirementConstraint}</option>*/}
                {/*                                                            )*/}
                {/*                                                        })}*/}
                {/*                                                    </NativeSelect>*/}
                {/*                                                </Grid>*/}
                {/*                                            </Grid>*/}

                {/*                                            {idService == 1 &&*/}
                {/*                                            <Grid item xs={12} className='row'>*/}
                {/*                                                <Grid item xs={7}>*/}
                {/*                                            <span className='pr-3 width-info'>*/}
                {/*                                                Số chứng từ:*/}
                {/*                                            </span>*/}
                {/*                                                </Grid>*/}
                {/*                                                <Grid item xs={5}>*/}
                {/*                                                    <NativeSelect*/}
                {/*                                                        className="align-center width-select"*/}
                {/*                                                        onChange={(val) => {*/}
                {/*                                                            this.setState({*/}
                {/*                                                                selectedBusiness: item.businessName,*/}
                {/*                                                                documentSelected: parseInt(val.target.value)*/}
                {/*                                                            });*/}
                {/*                                                        }}*/}
                {/*                                                    >*/}
                {/*                                                        <option value={0}>SL chứng từ</option>*/}
                {/*                                                        {selectData.documents.map((_item, index) => {*/}
                {/*                                                            return (*/}
                {/*                                                                <option*/}
                {/*                                                                    value={_item.id}>{_item.requirementConstraint}</option>*/}
                {/*                                                            )*/}
                {/*                                                        })}*/}
                {/*                                                    </NativeSelect>*/}
                {/*                                                </Grid>*/}
                {/*                                            </Grid>*/}
                {/*                                            }*/}

                {/*                                            {idService == 1 &&*/}
                {/*                                            <Grid item xs={12} className='row'>*/}
                {/*                                                <Grid item xs={7}>*/}
                {/*                                            <span className='pr-2 width-info'>*/}
                {/*                                                Nhân sự:*/}
                {/*                                            </span>*/}
                {/*                                                </Grid>*/}
                {/*                                                <Grid item xs={5}>*/}
                {/*                                                    <NativeSelect*/}
                {/*                                                        className="align-center width-select"*/}
                {/*                                                        onChange={(val) => {*/}
                {/*                                                            this.setState({*/}
                {/*                                                                selectedBusiness: item.businessName,*/}
                {/*                                                                employeeSelected: parseInt(val.target.value)*/}
                {/*                                                            });*/}
                {/*                                                        }}*/}
                {/*                                                    >*/}
                {/*                                                        <option value={0}>SL nhân sự</option>*/}
                {/*                                                        {selectData.employee.map((_item, index) => {*/}
                {/*                                                            return (*/}
                {/*                                                                <option*/}
                {/*                                                                    value={_item.id}>{_item.requirementConstraint}</option>*/}
                {/*                                                            )*/}
                {/*                                                        })}*/}
                {/*                                                    </NativeSelect>*/}
                {/*                                                </Grid>*/}
                {/*                                            </Grid>*/}
                {/*                                            }*/}

                {/*                                            <Grid item xs={12} className='row'>*/}
                {/*                                                <Grid item xs={7}>*/}
                {/*                                            <span className='pr-5 width-info'>*/}
                {/*                                                Số kỳ:*/}
                {/*                                            </span>*/}
                {/*                                                </Grid>*/}
                {/*                                                <Grid item xs={5}>*/}
                {/*                                                    <NativeSelect*/}
                {/*                                                        className="align-center width-select"*/}
                {/*                                                        onChange={(val) => {*/}
                {/*                                                            this.setState({*/}
                {/*                                                                selectedBusiness: item.businessName,*/}
                {/*                                                                periodSelected: parseInt(val.target.value)*/}
                {/*                                                            });*/}
                {/*                                                        }}*/}
                {/*                                                    >*/}
                {/*                                                        <option value={0}>Số kỳ</option>*/}
                {/*                                                        <option value={1}>1 tháng</option>*/}
                {/*                                                        <option value={2}>2 tháng</option>*/}
                {/*                                                        <option value={3}>3 tháng</option>*/}
                {/*                                                        <option value={4}>4 tháng</option>*/}
                {/*                                                        <option value={5}>5 tháng</option>*/}
                {/*                                                        <option value={6}>6 tháng</option>*/}
                {/*                                                        <option value={7}>7 tháng</option>*/}
                {/*                                                        <option value={8}>8 tháng</option>*/}
                {/*                                                        <option value={9}>9 tháng</option>*/}
                {/*                                                        <option value={10}>10 tháng</option>*/}
                {/*                                                        <option value={11}>11 tháng</option>*/}
                {/*                                                        <option value={12}>12 tháng</option>*/}
                {/*                                                    </NativeSelect>*/}
                {/*                                                </Grid>*/}
                {/*                                            </Grid>*/}

                {/*                                        </div>*/}
                {/*                                        <div className="d-flex justify-content-center mt-4">*/}
                {/*                                            <button className="btn btn-success" onClick={() => {*/}
                {/*                                                this.setState({selectedBusiness: item.businessType}, () => {*/}
                {/*                                                    this.calculatePrice()*/}
                {/*                                                })*/}
                {/*                                            }}>Tính giá cước*/}
                {/*                                            </button>*/}
                {/*                                        </div>*/}
                {/*                                        <div className="d-flex justify-content-center">*/}
                {/*                                            <span className="mt-3"><b>*/}
                {/*                                                {item.price ? (item.price > 0 ?*/}
                {/*                                                    <NumberFormat*/}
                {/*                                                        value={item?.price}*/}
                {/*                                                        displayType={"text"}*/}
                {/*                                                        thousandSeparator={true}*/}
                {/*                                                        suffix={"đ"}*/}
                {/*                                                    /> : "Giá thỏa thuận") : "0đ"}*/}
                {/*                                            </b></span>*/}
                {/*                                        </div>*/}
                {/*                                        <div className='d-flex justify-content-center'>*/}
                {/*                                            <Button color='primary' variant="contained"*/}
                {/*                                                    className='mt-4'>*/}
                {/*                                                Đăng ký*/}
                {/*                                            </Button>*/}
                {/*                                        </div>*/}
                {/*                                    </CardContent>*/}
                {/*                                </Card>*/}
                {/*                            </div>*/}
                {/*                        </Grid>*/}
                {/*                    </Grid>*/}
                {/*                );*/}

                {/*            })}*/}
                {/*        </Grid>*/}
                {/*        :*/}
                {/*        <Grid className='row' container spacing={0} justify="space-evenly" alignItems="center">*/}
                {/*            {planPrice?.map((item, index) => {*/}
                {/*                return (*/}
                {/*                    <Grid item xs={12} md={3} sm={3} className='pt-4'>*/}
                {/*                        <Grid item md={10} sm={10} justify={'center'}>*/}
                {/*                            <div className="service-plan-div">*/}
                {/*                                <Card className='border-card-plan'>*/}
                {/*                                    <div*/}
                {/*                                        className='d-flex justify-content-center font-weight-bold mt-2 mb-2 p-2'>*/}
                {/*                                        {item.requirementConstraint}*/}
                {/*                                    </div>*/}
                {/*                                    <CardContent>*/}
                {/*                                        <div className='pt-2 justify-content-center'>*/}
                {/*                                            <div className="d-flex justify-content-center">*/}
                {/*                                                    <span className="mt-3"><b>*/}
                {/*                                                        <NumberFormat*/}
                {/*                                                            value={item?.price}*/}
                {/*                                                            displayType={"text"}*/}
                {/*                                                            thousandSeparator={true}*/}
                {/*                                                            suffix={"đ"}*/}
                {/*                                                        />*/}
                {/*                                                    </b></span>*/}

                {/*                                            </div>*/}
                {/*                                            <div className='d-flex justify-content-center'>*/}
                {/*                                                {this.state.selectedPlan != item.id ?*/}
                {/*                                                    <Button color='primary' variant="contained"*/}
                {/*                                                            className='mt-4'*/}
                {/*                                                            onClick={() => this.setState({selectedPlan: item.id}, () => {*/}
                {/*                                                                this.props.setInsuranceConstraint(item.id, item.price)*/}
                {/*                                                            })}>*/}
                {/*                                                        Đăng ký*/}
                {/*                                                    </Button> : <CheckCircleTwoToneIcon className="mt-4"*/}
                {/*                                                                                        fontSize="large"*/}
                {/*                                                                                        style={{color: "green"}}/>}*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </CardContent>*/}
                {/*                                </Card>*/}
                {/*                            </div>*/}
                {/*                        </Grid>*/}
                {/*                    </Grid>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*        </Grid>*/}
                {/*)}*/}
            </div>
        );
    }

}

export default withSnackbar(ServicePlan_Basic)
