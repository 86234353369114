import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PublicContract = {
    createContractSub: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/contract-sub/create`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    getListContractSub: (contractId, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/contract-sub/get-list-contract-sub?${stringify(contractId)}`,
            method: 'GET',
        }, callback)
    },
    searchPeriod: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/search-period?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    accept: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/accept/${payload.id}`,
            method: 'POST',
            body: payload
        }, callback)
    },

    addContractDocument: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/add-contract-documents/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },

    assignToAccountant: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/assign-to-accountant`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/create`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    createJobs: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/create-jobs`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    cancelJob: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/job/cancel-job`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    createPayments: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/create-payments`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    cancelPayment: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/payment/cancel-payment`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    createContractReport: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/create-contract-report`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    reject: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/reject/${payload.id}`,
            method: 'POST',
            body: {
                ...payload.body
            }
        }, callback);
    },


    reviewAccountant: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/review-accountant`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    reviewSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/review-supervisor`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    deleteContractDocument: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/delete-contract-document/${payload.documentId}`,
            method: 'DELETE',
            body: payload,
        }, callback);
    },

    getMoreDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/detail-and-more/${payload.id}`,
            method: 'GET'
        }, callback)
    },

    getContractDocumentFilePath: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-contract-document-file-path/${payload.documentId}`,
            method: 'GET'
        }, callback)
    },

    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/detail/${payload.id}`,
            method: 'GET'
        }, callback)
    },

    getNamesByListId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-names-by-list-id`,
            method: 'GET'
        }, callback)
    },

    getReportFilePath: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-report-file-path/${payload.reportId}`,
            method: 'GET'
        }, callback)
    },

    getRootFolder: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-root-folder/${payload.id}`,
            method: 'GET'
        }, callback)
    },

    adminOnebookSearchContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/admin-one-book-search-contract?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    searchPaging: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/search-by-code-and-status-all-user-in-one?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    searchByCodeAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/search-by-code-and-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    searchPaymentByCode: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/search-payment-by-code?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    findByAccountantId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/supervisor-get-list-contract-of-accountant?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    activeContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/active-contract`,
            method: 'POST',
            body: payload
        }, callback)
    },

    getRevenueAccountant: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-accountant?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueOneBook: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-one-book?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenuePartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-partner?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-supervisor?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueTotalAccountant: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-total-accountant?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueTotalOneBook: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-total-one-book?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueTotalPartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-total-partner?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getRevenueTotalSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-revenue-total-supervisor?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    liquidateContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/liquidated/${payload.id}`,
            method: 'POST',
            body: payload
        }, callback)
    },
    closeContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/closed/${payload.id}`,
            method: 'POST',
            body: payload
        }, callback)
    },
    signedContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/signed/${payload.id}`,
            method: 'POST',
            body: payload
        }, callback)
    },
    getNotification: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/contract/get-notification-action`,
            method: 'GET',
        }, callback)
    },

}

export default PublicContract
