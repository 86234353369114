import React from "react";
import Sidebar from "../../../components/Sidebar";
import DashboardUser from "../../../services/ob-user/dashboard-user";
import DashboardCore from "../../../services/ob-core/dashboard";
import PageHeader from "../../../components/PageHeader";
import moment from "moment";
import TableHeader from "../../../components/TableHeader";
import NumberFormat from "react-number-format";
import StepLabel from "@material-ui/core/StepLabel";
import PartnerApi from "../../../services/ob-user/partner";
import {Bar, Line} from "react-chartjs-2";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            monthSales: new Date().getMonth() + 1,
            monthPartner: new Date().getMonth() + 1,
            listPartnerId: [],
            dataDashboardPartner: [],
            dataPartnerByMonth: [],
            dataAccumulated: [],
            dataAccumulatedToNow: [],
            dataContractOrder: [],
            dataSales: [],
            dataSalesYear: [],
            dataSalesByMonth: {},
            monthSupAcc: [],
            showLoading: false,
            labelsSup: [],
            labelsAcc: [],
            dataSup: [],
            dataAcc: [],
            monthRevenue: [],
            dataRevenue: [],
            orderData: null,
            header1: [
                {
                    id: 'total',
                    name: "Total",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'ob',
                    name: "OneBook (20%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'partner',
                    name: "Partner (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'sup',
                    name: "Supervisor (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'acc',
                    name: "Kế toán (50%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'total',
                    name: "Total",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'ob',
                    name: "OneBook (20%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'partner',
                    name: "Partner (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'sup',
                    name: "Supervisor (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'acc',
                    name: "Kế toán (50%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'total',
                    name: "Total",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'ob',
                    name: "OneBook (20%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'partner',
                    name: "Partner (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'sup',
                    name: "Supervisor (15%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'acc',
                    name: "Kế toán (50%)",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
            header2: [
                {
                    id: 'contract',
                    name: "Hợp đồng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'order',
                    name: "Order",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'revenue',
                    name: "Doanh số (đvt: VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'contract',
                    name: "Hợp đồng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'order',
                    name: "Order",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'revenue',
                    name: "Doanh số (đvt: VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'contract',
                    name: "Hợp đồng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'order',
                    name: "Order",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'revenue',
                    name: "Doanh số (đvt: VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ]
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getSupAcc();
            this.getRevenue();
            this.getPartnerByMonth();
            // this.getAccumulatedYear();
            // this.getAccumulatedToNow();
            this.getSalesByMonth();
            this.getSalesYear();
            this.getSalesToNow();
        })
    }

    getSupAcc = () => {
        DashboardUser.adminGetSupAcc(res => {
            if (res.success) {
                let dataSup = []
                let monthSupAcc = []
                let dataAcc = []
                // this.setState({countNewSupervisor: res.data.countNewSupervisor, countNewAccountant: res.data.countNewAccountant})
                res.data.countNewSupervisor.map((item) => {
                    dataSup.push(item.total)
                    monthSupAcc.push(`Tháng ${item.month}`)
                })
                res.data.countNewAccountant.map((_item) => {
                    dataAcc.push(_item.total)
                })
                this.setState({countNewSupervisor: res.data.countNewSupervisor, countNewAccountant: res.data.countNewAccountant, dataSup, dataAcc, monthSupAcc})
            }
        })
    }

    getRevenue = () => {
        DashboardCore.adminGetRevenue(res => {
            if (res.success) {
                let monthRevenue = [];
                let dataRevenue = [];
                res.data.map((item) => {
                    monthRevenue.push(`Tháng ${item.month}`);
                    dataRevenue.push(item.total)
                })
                this.setState({monthRevenue, dataRevenue})
            }
        })
    }

    getListPartnerById = () => {
        let {listPartnerId, dataDashboardPartner} = this.state;

        let payload = {
            ids: listPartnerId
        };
        PartnerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                dataDashboardPartner.map(item => {
                    let filter = data.filter(x => x.id == item.id);
                    item.partnerName = filter[0]?.fullName;
                });

                this.setState({
                    dataDashboardPartner
                });
            }
        });
    }

    getPartnerByMonth = () => {
        this.setState({showLoading: true})
        let dataDashboardPartner = [];
        let payload = {
            month: this.state.monthPartner
        }
        DashboardUser.getDashBoardOfPartnerByMonth(payload, res => {
            if (res.success) {
                let data = res?.data;
                let listPartnerId = [];
                data.map(item => {
                    listPartnerId.push(item.partnerId);
                });
                this.setState({
                    dataPartnerByMonth: res.data,
                    listPartnerId,
                }, () => {
                    if (res.data.length > 0) {
                        res.data.map((item, index) => {
                            dataDashboardPartner.push({id: item?.partnerId, dataPartnerByMonth: item})
                        })
                        this.setState({
                            dataDashboardPartner
                        }, () => {
                            this.getListPartnerById()
                            this.getAccumulatedYear()
                            this.getAccumulatedToNow()
                        })
                    }
                })
            }
        })
    }

    getAccumulatedYear = () => {
        let dataDashboardPartner = this.state.dataDashboardPartner
        DashboardCore.getAccumulated(res => {
            if (res.success) {
                this.setState({
                    dataAccumulated: res.data
                }, () => {
                    if (res.data.length > 0) {
                        res.data.map(item => {
                            dataDashboardPartner.map(item1 => {
                                if (item1.id === item.partnerId) item1.dataAccumulatedYear = item
                            })
                        })
                        this.setState({dataDashboardPartner})
                    }
                })
            }
        })
    }

    getAccumulatedToNow = () => {
        let dataDashboardPartner = this.state.dataDashboardPartner
        DashboardCore.getAccumulatedToNow(res => {
            if (res.success) {
                this.setState({
                    dataAccumulatedToNow: res.data
                }, () => {
                    if (res.data.length > 0) {
                        res.data.map(item => {
                            dataDashboardPartner.map(item1 => {
                                if (item1.id === item.partnerId) item1.dataAccumulatedToNow = item
                            })
                        })
                        this.setState({
                            dataDashboardPartner,
                            showLoading: false
                        })
                    }
                })
            }
        })
    }

    getSalesByMonth = () => {
        this.setState({showLoading: true})
        let payload = {
            month: this.state.monthSales
        }
        DashboardCore.getSaleByMonth(payload, res => {
            if (res.success) {
                this.setState({dataSalesByMonth: res.data, showLoading: false})
            }
        })
    }

    getSalesYear = () => {
        DashboardCore.dashBoardSalesInYear(res => {
            if (res.success) {
                this.setState({dataSalesYear: res.data, showLoading: false})
            }
        })
    }

    getSalesToNow = () => {
        DashboardCore.dashBoardSalesToNow(res => {
            if (res.success) {
                this.setState({dataSales: res.data, showLoading: false})
            }
        })
    }

    render() {
        const {dataSalesByMonth, dataSalesYear, dataSales, dataDashboardPartner} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="dashboard"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Dashboard"/>
                                </div>
                                <div className="row w-100">
                                    <div className="col-md-6 col-xs-12 pt-3 pb-3 pr-3 pl-0">
                                        <div className="text-align-center">
                                            <span className="dashboard-title-item">NHÂN SỰ</span>
                                        </div>
                                        <div className=" mb-3 widget-chart">
                                            <Bar
                                                data={{
                                                    // labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
                                                    labels: this.state.monthSupAcc,
                                                    datasets: [
                                                        {
                                                            label: 'Supervisor',
                                                            backgroundColor: 'rgba(75,192,192,0.4)',
                                                            borderColor: 'rgba(75,192,192,1)',
                                                            borderWidth: 1,
                                                            hoverBackgroundColor: 'rgba(75,192,192,1)',
                                                            hoverBorderColor: 'rgba(220,220,220,1)',
                                                            data: this.state.dataSup
                                                        },
                                                        {
                                                            label: 'Kế toán',
                                                            backgroundColor: 'rgba(255,99,132,0.2)',
                                                            borderColor: 'rgba(255,99,132,1)',
                                                            // backgroundColor: '#C6E6F1',
                                                            // borderColor: '#0075dd',
                                                            borderWidth: 1,
                                                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                            hoverBorderColor: 'rgba(255,99,132,1)',
                                                            // hoverBackgroundColor: '#3ba5f7',
                                                            // hoverBorderColor: '#0075dd',
                                                            data: this.state.dataAcc
                                                        },
                                                    ]
                                                }}

                                                // width={100}
                                                height={350}
                                                options={{ maintainAspectRatio: false }}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12 p-3">
                                        <div className="text-align-center">
                                            <span className="dashboard-title-item">DOANH THU</span>
                                        </div>
                                        <Line ref="chart"
                                              data={{
                                                  labels: this.state.monthRevenue,
                                                  datasets: [
                                                      {
                                                          label: 'Doanh thu',
                                                          fill: false,
                                                          lineTension: 0.1,
                                                          // backgroundColor: 'rgba(75,192,192,0.4)',
                                                          // borderColor: 'rgba(75,192,192,1)',
                                                          backgroundColor: '#0075dd',
                                                          borderColor: '#0075dd',
                                                          borderCapStyle: 'butt',
                                                          borderDash: [],
                                                          borderDashOffset: 0.0,
                                                          borderJoinStyle: 'miter',
                                                          pointBorderColor: 'rgba(75,192,192,1)',
                                                          pointBackgroundColor: '#fff',
                                                          pointBorderWidth: 1,
                                                          pointHoverRadius: 5,
                                                          // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                                          // pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                          pointHoverBackgroundColor: '#0075dd',
                                                          pointHoverBorderColor: '#0075dd',
                                                          pointHoverBorderWidth: 2,
                                                          pointRadius: 1,
                                                          pointHitRadius: 10,
                                                          data: this.state.dataRevenue
                                                          // data: [5000000,1000000,3000000]
                                                      }
                                                  ],
                                              }}
                                              height={250}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className='row justify-content-between pl-0'>
                                        <label className='font-18 col-8 pl-0'>DOANH SỐ</label>
                                        <label style={{fontSize: 12}} className='col-4 font-italic text-right pt-3 pr-0'>Đvt:
                                            VND</label>
                                    </div>
                                    <div className="table-wrapper">
                                        <table
                                            className="table table-striped table-data table-bordered table-admin-dashboard">
                                            <thead>
                                            <tr style={{backgroundColor: '#8bdaf6'}}>
                                                <th style={{minWidth: 145}} rowSpan={2}></th>
                                                <th colSpan={5}>
                                                    <div className='d-inline-flex' style={{lineHeight: '28px'}}>

                                                        <select
                                                            className="form-control pd-4-input ml-1"
                                                            style={{
                                                                height: 26, width: 100, fontSize: 12,
                                                                // backgroundColor: '#e6f9ff'
                                                            }}
                                                            value={this.state.monthSales}
                                                            defaultValue={6}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    monthSales: e.target.value,
                                                                }, () => this.getSalesByMonth())
                                                            }}
                                                        >
                                                            <option value={1}>Tháng 1</option>
                                                            <option value={2}>Tháng 2</option>
                                                            <option value={3}>Tháng 3</option>
                                                            <option value={4}>Tháng 4</option>
                                                            <option value={5}>Tháng 5</option>
                                                            <option value={6}>Tháng 6</option>
                                                            <option value={7}>Tháng 7</option>
                                                            <option value={8}>Tháng 8</option>
                                                            <option value={9}>Tháng 9</option>
                                                            <option value={10}>Tháng 10</option>
                                                            <option value={11}>Tháng 11</option>
                                                            <option value={12}>Tháng 12</option>
                                                        </select>
                                                    </div>
                                                </th>
                                                <th colSpan={5}>Lũy kế năm</th>
                                                <th colSpan={5}>Lũy kế từ đầu đến hiện tại</th>
                                            </tr>
                                            <TableHeader header={this.state.header1}/>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>Tổng giá trị hợp đồng</th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.totalValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenuePartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>

                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.totalValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenuePartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>

                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.totalValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenuePartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Doanh thu đã thực hiện</th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueJobDone}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.revenueJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueJobDone}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.revenueJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueJobDone}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.revenueJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Doanh thu chưa thực hiện</th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.unpaidTotalJob}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.unpaidJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.unpaidJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.unpaidJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.unpaidJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.unpaidTotalJob}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.unpaidJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.unpaidJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.unpaidJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSalesYear.unpaidJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.unpaidTotalJob}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.unpaidJobAdmin}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.unpaidJobPartner}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.unpaidJobSupervisor}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th className='text-right'>
                                                    <NumberFormat
                                                        value={dataSales.unpaidJobAccountant}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Thanh toán</th>
                                                <th colSpan={5} className='text-center'>
                                                    <NumberFormat
                                                        value={dataSalesByMonth.paidValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th colSpan={5} className='text-center'>
                                                    <NumberFormat
                                                        value={dataSalesYear.paidValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                                <th colSpan={5} className='text-center'>
                                                    <NumberFormat
                                                        value={dataSales.paidValueContract}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th title={'Thanh toán/GTHĐ'}>Tỷ lệ thanh toán (= Thanh toán/GTHĐ)</th>
                                                <th colSpan={5} className='text-center'>
                                                    {dataSalesByMonth.paymentRate}%
                                                </th>
                                                <th colSpan={5} className='text-center'>
                                                    {dataSalesYear.paymentRate}%
                                                </th>
                                                <th colSpan={5} className='text-center'>
                                                    {dataSales.paymentRate}%
                                                </th>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="">
                                    <label className='font-18'>PARTNER</label>
                                    <div className="table-wrapper">
                                        <table
                                            className="table table-striped table-data table-bordered table-admin-dashboard">
                                            <thead>
                                            <tr style={{backgroundColor: '#8bdaf6'}}>
                                                <th style={{minWidth: 145}} rowSpan={2}></th>
                                                <th rowSpan={2} style={{minWidth: 60}}>Kế toán</th>
                                                <th rowSpan={2}>Supervisor</th>
                                                <th colSpan={3}>
                                                    <div className='d-inline-flex' style={{lineHeight: '28px'}}>
                                                        {/*Tháng*/}
                                                        <select
                                                            className="form-control pd-4-input ml-1"
                                                            style={{
                                                                height: 26, width: 100, fontSize: 12,
                                                                // backgroundColor: '#e6f9ff'
                                                            }}
                                                            value={this.state.monthPartner}
                                                            defaultValue={6}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    monthPartner: e.target.value,
                                                                }, () => this.getPartnerByMonth())
                                                            }}
                                                        >
                                                            <option value={1}>Tháng 1</option>
                                                            <option value={2}>Tháng 2</option>
                                                            <option value={3}>Tháng 3</option>
                                                            <option value={4}>Tháng 4</option>
                                                            <option value={5}>Tháng 5</option>
                                                            <option value={6}>Tháng 6</option>
                                                            <option value={7}>Tháng 7</option>
                                                            <option value={8}>Tháng 8</option>
                                                            <option value={9}>Tháng 9</option>
                                                            <option value={10}>Tháng 10</option>
                                                            <option value={11}>Tháng 11</option>
                                                            <option value={12}>Tháng 12</option>
                                                        </select>
                                                    </div>
                                                </th>
                                                <th colSpan={3}>Lũy kế năm</th>
                                                <th colSpan={3}>Lũy kế từ đầu đến hiện tại</th>
                                            </tr>
                                            <TableHeader header={this.state.header2}/>
                                            </thead>
                                            <tbody>
                                            {dataDashboardPartner.map((item, index) => (
                                                <tr>
                                                    <th>{item.partnerName || ''}</th>
                                                    <th className='text-center'>{item.dataPartnerByMonth?.accountantByMonth}</th>
                                                    <th className='text-center'>{item.dataPartnerByMonth?.supervisorByMonth}</th>
                                                    <th className='text-center'>{item.dataPartnerByMonth?.countTotalContractByMonth}</th>
                                                    <th className='text-center'>{item.dataPartnerByMonth?.countTotalOrderByMonth}</th>
                                                    <th className='text-right'>
                                                        <NumberFormat
                                                            value={item.dataPartnerByMonth?.totalValueByMonth}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                    </th>
                                                    <th className='text-center'>{item.dataAccumulatedYear?.countTotalContract}</th>
                                                    <th className='text-center'>{item.dataAccumulatedYear?.countTotalOrder}</th>
                                                    <th className='text-right'>
                                                        <NumberFormat
                                                            value={item.dataAccumulatedYear?.totalValue}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />

                                                    </th>
                                                    <th className='text-center'>{item.dataAccumulatedToNow?.countTotalContract}</th>
                                                    <th className='text-center'>{item.dataAccumulatedToNow?.countTotalOrder}</th>
                                                    <th className='text-right'>
                                                        <NumberFormat
                                                            value={item.dataAccumulatedToNow?.totalValue}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                    </th>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Dashboard
