import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PublicCustomerApi = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/customer/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    checkExist: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/customer/check-user-exits/${payload.phone}`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    checkTaxExist: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/customer/check-user-exits-tax/${payload.tax}`,
            method: 'POST',
            body: payload,
        }, callback);
    },
};
export default PublicCustomerApi
