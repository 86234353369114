import React, {Component} from "react";
import clsx from 'clsx';
import '../../../../assets/css/Supervisor.css';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Grid,
    Button,
    Radio,
    CircularProgress,
} from '@material-ui/core';

import ModalChangePassword from "../../../../components/Modal/modalChangePassword";
import ModalConfirmEdit from "../../../../components/Modal/modalConfirmEdit";
import TableHeader from "../../../../components/TableHeader";
import UploadFile from "../../../../components/Modal/modalUploadFile";
import ModalUploadAvatar from "../../../../components/Modal/ModalChangeAvatar"
import ModalConfirmDeleteCert from "../../../../components/Modal/modalConfirmDeleteCert";
import ModalResetPasswordSup from "../../../../components/Modal/modalResetPasswordSup"
// import ModalCropImage from "../../../components/Modal/ModalCropImage"

import SupervisorApi from "../../../../services/ob-user/supervisor";
import PartnerApi from "../../../../services/ob-user/partner";
import FilesApi from "../../../../services/resource/files";
import UserFile from "../../../../services/ob-user/user-file";
import ProvinceApi from "../../../../services/ob-user/province";
import {toast} from "react-toastify";
import {checkPermission} from "../../../../utils/common";
// import Partner from "../../partner";

const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn quận huyện'})
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    _province.unshift({id: null, name: 'Vui lòng chọn thành phố'})
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};


function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}


class InformationOfSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            showUpload: false,
            modalResetPassSup: false,
            dataSupervisor: {},
            listProvince: [],
            listDistrict: [],
            AreaSup: [],
            files: [],
            filesCertificate: [],
            listData: [],
            modalConfirmDeleteCert: false,
            fileId: null,
            address: '',
            provinceId: 0,
            districtId: 0,
            gender: 'FEMALE',
            identifycode: '',
            dateIssue: '',
            placeIssue: '',
            placeSupportId: '',
            phoneNum: '',
            email: '',
            job: '',
            expyear: '',
            name: '',
            avatar: '',
            description: "",
            field: [],
            modalUploadAvatar: false,
            showLoading: false,
            addressError: false,
            identifyError: false,
            nameError: false,
            placeIssuedError: false,
            dateIssuedError: false,
            sexError: false,
            expError: false,
            descriptionError: false,
            provinceIdError: false,
            districtIdError: false,
            btnSaveStatus: false,

            partnerDetail: {},
            selectedFile:'',

            showConfirmModal: false,


            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,

            fieldSupport: [
                {
                    id: 0,
                    name: "Thương mại - Dịch vụ",
                    checked: false
                },
                {
                    id: 1,
                    name: "Sản xuất",
                    checked: false
                },
                {
                    id: 2,
                    name: "Xây dựng",
                    checked: false
                },
            ],

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Bằng cấp chứng chỉ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File bằng cấp chứng chỉ",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getDetailFromAPI();
            this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
        })

    }

    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }

    getSupportArea() {
        ProvinceApi.supportArea(res => {
            if (res.success) {
                this.setState({
                    AreaSup: res.data.content
                }, () => this.getFile())
            } else {

            }
        })
    }
    getDetailPartner(id){
        let payload = {
            id: id
        }
        PartnerApi.getDetail(payload, res =>{
            if(res.success){
                this.setState({
                    partnerDetail: res.data
                })
            }
        })
    }


    getDetailFromAPI() {
        let myDate = new Date();
        let payload = {
            body:{
                id: this.props.value,
            },
            id: this.props.value,
            maxAccountant: this.state.maxAccountant,
            maxContract: this.state.maxContract

        };
        SupervisorApi.getDetail(payload, res => {
            if (res.success)  {
                this.setState({
                    listData: res.data,
                    maxContract: res.data.maxContract,
                    maxAccountant: res.data.maxAccountant,
                    dateIssued: res.data.dateIssued,
                    fullName: res.data.fullName,
                    showLoading: false
                });
            } else {
                toast.error("Lỗi lấy dữ liệu !")}
        });
    }

    getFile = () => {
        UserFile.getAll(res => {
            if (res.success) {
                this.setState({filesCertificate: res.data, showLoading: false})
            }
        })
    }


    handleChangeTax(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length != 0 ? this.setState({taxError: false}) : this.setState({taxError: true});
                this.state.tax.length >= 10 ? this.setState({taxError: false}) : this.setState({taxError: true})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangeexpyear(val) {
        this.setState({inEditing: true});
        const onlyNums = val.target.value.replace(/[^0-9]/g, '').trim();
        this.setState({expyear: onlyNums})
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({identifyError: false}) : this.setState({identifyError: true});
                this.state.identify.length >= 9 ? this.setState({identifyError: false}) : this.setState({identifyError: true})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }

    handleSubmit() {
        let {maxAccountant, maxContract, areaId, provinceId, experience, dateIssued, address, fullName, identify, sex, placeIssued, districtId, listData} = this.state;
        console.log('aloll: ', listData)
        let payload = {
            id: this.props.value,
            address: listData.address,
            areaId: listData.areaId,
            districtId: listData.districtId,
            dateIssued: listData.dateIssued,
            experience: listData.experience,
            fullName: listData.fullName,
            identify: listData.identify,
            placeIssued: listData.placeIssued,
            provinceId: listData.provinceId,
            sex: listData.sex,
            maxAccountant: maxAccountant,
            maxContract: maxContract

        }
        SupervisorApi.partnerUpdateSupervisor(payload, res => {
            if (res.success) {
                toast.success("Cập nhật thành công!")
                this.setState({
                    btnSaveStatus: false,
                    onEdit: false,
                }, () => this.getDetailFromAPI())
            }
        })
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({onEdit: false},
                () => {
                    this.setState({
                        address: this.state.dataSupervisor ? this.state.dataSupervisor.address : '',
                        provinceId: this.state.dataSupervisor ? this.state.dataSupervisor.provinceId : '',
                        districtId: this.state.dataSupervisor ? this.state.dataSupervisor.districtId : '',
                        gender: this.state.dataSupervisor.sex,
                        dateIssue: this.state.dataSupervisor.dateIssued,
                        identifycode: this.state.dataSupervisor ? this.state.dataSupervisor.identify : '',
                        placeIssue: this.state.dataSupervisor ? this.state.dataSupervisor.placeIssued : '',
                        placeSupportId: this.state.dataSupervisor ? this.state.dataSupervisor.areaId : '',
                        job: this.state.dataSupervisor ? this.state.dataSupervisor.job : '',
                        expyear: this.state.dataSupervisor ? this.state.dataSupervisor.experience : '',
                        name: this.state.dataSupervisor ? this.state.dataSupervisor.fullName : '',
                        description: this.state.dataSupervisor ? this.state.dataSupervisor.description : '',

                        addressError: false,
                        identifyError: false,
                        nameError: false,
                        placeIssueError: false,
                        dateIssuedError: false,
                        sexError: false,
                        expError: false,
                        provinceIdError: false,
                        districtIdError: false,
                        descriptionError: false,
                        btnSaveStatus: false,
                    })
                }
            )
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    onChangeDistrict = (e) => {
        this.setState({
            districtId: e.target.value,
            inEditing: true
        })
    };

    modalUploadAvatarClose = () => {
        this.setState({modalUploadAvatar: false});
    }
    modalCropClose = () => {
        this.setState({showModalCrop: false});
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }

    onDeleteCert = (item) => {
        this.setState({fileId: item.id, modalConfirmDeleteCert: true})
    }

    uploadCert = (res, file, fileName) => {
        let payload = {
            fileName: file.name,
            fullName: fileName,
            // url: res
            destination: res.destination,
            storageName: res.storageName
        }
        FilesApi.supervisorUploadCertificate(payload, res => {
            if (res.success) {
                toast.success("Cập nhật chứng chỉ thành công!")
            } else {
                toast.error("Cập nhật chứng chỉ không thành công!")
            }
        })
    }

    downloadCert = (item) => {
        let payload = {
            fileId: item.id
        }
        FilesApi.downloadUserFile(payload)
    }

    onCheckSupport = (item) => {
        let {field} = this.state
        if (field.find(name => name === item.name) === item.name) {
            return true
        } else {
            return false
        }
    }

    onHandleChooseSupport = (val, item) => {
        let {field, fieldSupport} = this.state
        if(val.target.checked) {
            field.push(val.target.value)
        }else{
            field.splice(field.indexOf(val.target.value),1)
        }
        fieldSupport.map(_item => {
            if (_item.name == item.name){
                _item.checked = val.target.checked
            }
        })
        this.setState({
            field: field,
            fieldSupport,
            inEditing: true
        })

    }

    render() {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataSupervisor, partnerDetail, tax, placeIssued, dateIssued, sex, provinceId, districtId, listData} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className=" with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            {/********************************Thong tin ca nhan****************************************************/}
                            <div variant="outlined" className='mb-4 pb-2 border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin cá nhân</span>
                                </div>
                                <div className='d-flex justify-content-center col-12 mt-3 kt-margin-b-20'>
                                    <div className=' d-flex ml-2'>
                                        {/*<span className='text-header-profile'>Thông tin cá nhân</span>*/}
                                    </div>
                                        <div className='justify-center img-ava-wrapper' style={{textAlign: 'center', backgroundImage: listData.urlImage ? `url(${listData.urlImage}` : `url("/images/no-avatar-png.png")`}}>
                                            {/*<span className="fas fa-camera change-avt-icon" title="Tải lên ảnh đại diện" onClick={this.showModalUploadAvatar}/>*/}

                                        </div>
                                    {/*<div className='col-4 pr-0'>*/}
                                    {/*    {this.state.onEdit ?*/}
                                    {/*        <div/> :*/}
                                    {/*        <div className='d-flex justify-content-end'>*/}
                                    {/*            <Button variant="contained"*/}
                                    {/*                    className='rounded mb-1 btn-primary'*/}
                                    {/*                    color='primary'*/}
                                    {/*                // disabled={this.state.dataSupervisor.length == 0?true:false}*/}
                                    {/*                    style={{textTransform: 'initial'}}*/}
                                    {/*                    onClick={() => this.setState({onEdit: !this.state.onEdit})}*/}
                                    {/*            >*/}
                                    {/*                Chỉnh sửa thông tin*/}
                                    {/*            </Button>*/}
                                    {/*        </div>*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Họ và tên</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.fullName ? listData.fullName.toUpperCase() : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.sex ? listData.sex === 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.identify ? listData.identify : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-sm-3 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.dateIssued ? moment(listData.dateIssued).format("DD-MM-YYYY") : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.placeIssued ? listData.placeIssued : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Khu vực hỗ trợ </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.areaId ? renderProvinceId(listData.areaId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">
                                                Số điện thoại
                                            </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {listData?.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {listData?.email}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Vị trí công việc</span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {listData.job}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                                            <span
                                                className="text-black-50">Kinh nghiệm làm việc (năm)</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.experience ? listData.experience : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.provinceId ? renderProvinceId(listData.provinceId) : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.districtId ? renderDistrictId(listData.districtId) : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.address ? listData.address : ''
                                            }
                                        </div>

                                        {/*<div*/}
                                        {/*    className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-2 kt-margin-b-10-tablet-and-mobile">*/}
                                        {/*    <span className="text-black-50">Mật khẩu</span>*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={this.state.onEdit ? "pl-2" : "pl-0"}>*/}
                                        {/*    ***********/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className="pb-2 col-sm-2 change-password"*/}
                                        {/*    onClick={() => this.setState({modalResetPassSup: true})}*/}
                                        {/*>*/}
                                        {/*    Reset mật khẩu*/}
                                        {/*</div>*/}

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Lĩnh vực </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                (listData.fieldSupport ? listData.fieldSupport : '')
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mô tả</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {
                                                listData.description ? listData.description : ''
                                            }
                                        </div>


                                    </div>
                                </div>

                                {/*</CardContent>*/}
                            </div>
                            {/************************************************************************************************************************************/}

                            <div variant="outlined" className='pb-2 mb-5 border-color'>
                                {/*<CardContent>*/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'></span>
                                </div>
                                <div className='row col-sm-12 col-md-12 col-lg-12  mt-3 '>
                                    <div className=' col-sm-6 col-md-6 col-lg-6 p-0 '>
                                        <label className='col-sm-7 col-md-7 col-lg-7 p-0 '>Số kế toán được phụ trách</label>
                                        <label style={{fontWeight: '500'}}
                                               className='col-sm-4 col-md-4 col-lg-4 p-0'>{this.state.onEdit ?
                                            <div className={"input-group"}>
                                                <input type="text"
                                                       className={this.state.maxAccountantErr ? "form-control border-input-error p-0 kt-input p-0-input hei-input p-0" : "form-control kt-input p-0 p-0-input hei-input"}
                                                       placeholder="Nhập số kế toán..."
                                                       value={this.state.maxAccountant}
                                                    // aria-label="Username"
                                                       maxLength={2}
                                                    // onChange={(val) =>this.setState({
                                                    //     maxAccountant: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                    // },() => this.setState({
                                                    //
                                                    // }))}
                                                       onChange={(val) => this.setState({
                                                           btnSaveStatus: false,
                                                           maxAccountant: val.target.value.trimLeft().replace(/[^0-9]/g, ''),

                                                       }, () => {
                                                           this.state.maxAccountant.length === 0 || this.state.maxAccountant < listData.countAccountant ? this.setState({
                                                               maxAccountantErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               maxAccountantErr: false,
                                                               btnSaveStatus: true,

                                                           })
                                                       })}
                                                    // aria-describedby="basic-addon1"
                                                    //    onChange={(val) => this.setState({
                                                    //        taxcode: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                    //        inEditing: true
                                                    //    }, () => {
                                                    //        this.state.taxcode.length === 0 || this.state.taxcode.length < 10 ? this.setState({
                                                    //            taxcodeErr: true,
                                                    //            btnSaveStatus: true
                                                    //        }) : this.setState({
                                                    //            taxcodeErr: false,
                                                    //            btnSaveStatus: false
                                                    //        })
                                                    //    })}
                                                />
                                                <div
                                                    className={this.state.maxAccountantErr ? "invalid-feedback d-block" : "invalid-feedback"}>
                                                    Vui lòng nhập kế toán
                                                </div>
                                            </div>
                                            : listData.maxAccountant}</label>
                                    </div>
                                    <div className=' col-sm-6 col-md-6 col-lg-6 p-0 '>
                                        <label className='col-sm-7 col-md-7 col-lg-7 p-0  '>Số kế toán đang phụ trách</label>
                                        <label style={{fontWeight: '500'}}
                                               className='col-sm-4 col-md-4 col-lg-4 p-0'>{listData.countAccountant}</label>
                                    </div>

                                    <div className=' col-sm-6 col-md-6 col-lg-6 p-0 '>
                                        <label className='col-sm-7 col-md-7 col-lg-7 p-0  '>Số hợp đồng được thực hiện</label>
                                        <label style={{fontWeight: '500'}}
                                               className='col-sm-4 col-md-4 col-lg-4 p-0'>{this.state.onEdit ?
                                            <div className={"input-group"}>
                                                <input type="text"
                                                       className={this.state.maxContractErr ? "form-control border-input-error kt-input p-0-input hei-input" : "form-control kt-input p-0 p-0-input hei-input"}
                                                       placeholder="Nhập số hợp đồng..."
                                                       value={this.state.maxContract}
                                                    // aria-label="Username"
                                                       maxLength={2}
                                                    // onChange={(val) =>this.setState({
                                                    //     maxAccountant: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                    // },() => this.setState({
                                                    //
                                                    // }))}
                                                       onChange={(val) => this.setState({
                                                           btnSaveStatus: true,
                                                           maxContract: val.target.value.trim().replace(/[^0-9]/g, ''),

                                                       }, () => {
                                                           this.state.maxContract.length === 0 || this.state.maxContract < listData.countContract ? this.setState({
                                                               maxContractErr: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               maxContractErr: false,
                                                               btnSaveStatus: true,

                                                           })
                                                       })}
                                                    // aria-describedby="basic-addon1"
                                                    //    onChange={(val) => this.setState({
                                                    //        taxcode: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                    //        inEditing: true
                                                    //    }, () => {
                                                    //        this.state.taxcode.length === 0 || this.state.taxcode.length < 10 ? this.setState({
                                                    //            taxcodeErr: true,
                                                    //            btnSaveStatus: true
                                                    //        }) : this.setState({
                                                    //            taxcodeErr: false,
                                                    //            btnSaveStatus: false
                                                    //        })
                                                    //    })}
                                                />
                                                <div
                                                    className={this.state.maxContractErr ? "invalid-feedback d-block" : "invalid-feedback"}>
                                                    Vui lòng nhập hợp đồng
                                                </div>
                                            </div>
                                            : listData.maxContract}</label>
                                    </div>

                                    <div className=' col-sm-6 col-md-6 col-lg-6 p-0 '>
                                        <label className='col-sm-7 col-md-7 col-lg-7 p-0  '>Số hợp đồng đang thực hiện</label>
                                        <label style={{fontWeight: '500'}}
                                               className='col-sm-4 col-md-4 col-lg-4 p-0'>{listData.countContractProcessing}</label>
                                    </div>

                                    {this.state.onEdit ?
                                        <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                                            <button
                                                style={{cursor: this.state.btnSaveStatus ? 'pointer' : 'unset'}}
                                                disabled={!this.state.btnSaveStatus}
                                                className='btn btn-primary col-sm-3 col-md-3 mr-4'
                                                onClick={() => this.handleSubmit()}
                                                title="Lưu"
                                            >
                                                <span>Lưu</span>
                                            </button>
                                            <button
                                                className='btn btn-light col-sm-3 col-md-3 border-1-s'
                                                onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                                title="Hủy"
                                            >
                                                <span>Hủy</span>
                                            </button>
                                        </div>
                                        : <div className='row justify-content-center mt-5 col-sm-12 col-md-12'>

                                            <button
                                                className='btn btn-primary col-sm-3 col-md-3 mr-4'
                                                disabled={!checkPermission("edit_supervisor_information")}
                                                onClick={() => this.setState({
                                                    onEdit: !this.state.onEdit,
                                                    maxContract: listData.maxContract,
                                                    maxAccountant: listData.maxAccountant,
                                                    maxContractErr: false,
                                                    maxAccountantErr: false,

                                                })}
                                                title="Chỉnh sửa thông tin"
                                            >
                                                <span>Chỉnh sửa thông tin</span>
                                            </button>
                                            <button
                                                disabled={!checkPermission("reset_supervisor_account_password")}
                                                className='btn btn-light col-sm-3 col-md-3 border-1-s'
                                                title="Reset mật khẩu"
                                                onClick={() => this.setState({modalResetPassSup: true})}
                                            >
                                                <span>Reset mật khẩu</span>
                                            </button>
                                        </div>}
                                </div>
                            </div>

                            {/*********************************************************************************************************************************??*/}
                            <div variant="outlined" className='pb-2 mb-5 border-color'>
                                {/*<CardContent>*/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Hồ sơ cá nhân</span>
                                </div>
                                {/*<div className='d-flex justify-content-center col-12 mt-3'>*/}
                                {/*<div className='col-8 d-flex ml-2'>*/}
                                {/*    <span className='text-header-profile'>Hồ sơ cá nhân</span>*/}
                                {/*</div>*/}
                                {/*<div className='col-4'>*/}
                                <div className='d-flex col-12 mt-3 justify-content-end'>
                                    {/*<Dropzone*/}
                                    {/*    getUploadParams={() => ({url: 'https://httpbin.org/post'})} // test*/}
                                    {/*    accept="image/*"*/}
                                    {/*    onDrop={acceptedFiles => {*/}
                                    {/*        console.log(acceptedFiles)*/}
                                    {/*        this.setState({*/}
                                    {/*            filesCertificate: acceptedFiles.map(file => Object.assign(file, {*/}
                                    {/*                preview: URL.createObjectURL(file)*/}
                                    {/*            }))*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    {({getRootProps, getInputProps}) => (*/}
                                    {/*        <span title='Upload file'>*/}
                                    {/*            <span {...getRootProps()}>*/}
                                    {/*                <input {...getInputProps()} />*/}
                                    {/*<Button className="btn btn-primary"*/}
                                    {/*        variant="contained"*/}
                                    {/*        color='primary'*/}
                                    {/*        onClick={() => this.setState({showUpload: true})}>*/}
                                    {/*    Upload*/}
                                    {/*</Button>*/}
                                    {/*            </span>*/}
                                    {/*        </span>*/}
                                    {/*    )}*/}
                                    {/*</Dropzone>*/}

                                </div>
                                {/*</div>*/}
                                {/*</div>*/}



                                {/*{this.state.confirmCancel &&*/}
                                {/*<Dialog*/}
                                {/*    open={true}*/}
                                {/*    onClose={() => this.setState({confirmCancel: false})}*/}
                                {/*>*/}
                                {/*    <DialogTitle>Thông tin đã thay đổi, bạn có chắc muốn hủy ?</DialogTitle>*/}
                                {/*    <DialogActions>*/}
                                {/*        <Button*/}
                                {/*            style={{textTransform: 'initial'}}*/}
                                {/*            color="primary"*/}
                                {/*            onClick={() => this.setState({*/}
                                {/*                confirmCancel: false,*/}
                                {/*                inEditing: false*/}
                                {/*            }, () => this.handleCancel())}*/}
                                {/*        >*/}
                                {/*            Có*/}
                                {/*        </Button>*/}
                                {/*        <Button*/}
                                {/*            color="primary"*/}
                                {/*            style={{textTransform: 'initial'}}*/}
                                {/*            onClick={() => this.setState({confirmCancel: false})}*/}
                                {/*        >*/}
                                {/*            Không*/}
                                {/*        </Button>*/}
                                {/*    </DialogActions>*/}
                                {/*</Dialog>}*/}
                                {/*</CardContent>*/}
                                {/*</div>*/}
                                <div className="table-wrapper col-12">
                                    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                        }}/>
                                        </thead>
                                        <tbody>
                                        {listData?.listUserFile && listData?.listUserFile.length > 0
                                            ? listData?.listUserFile.map((item, index) =>
                                                <tr>
                                                    <th scope="row" className='text-center'>{index + 1}</th>
                                                    <td className='text-center'>{item.fullName}</td>
                                                    <td className='text-center'>{item.fileName}</td>
                                                    <td className='text-ce
                                                    nter'>
                                                        <div>
                                                            <button className="btn" title="Xóa chứng chỉ"
                                                                    onClick={() => this.onDeleteCert(item)}>
                                                                <i className='ti-trash'/>
                                                            </button>

                                                            <button className="btn btn-action"
                                                                    title='Tải xuống'
                                                                    onClick={() => {
                                                                        // this.setState({selectedFile: item}, () => {
                                                                        this.downloadCert(item)
                                                                        // })
                                                                    }}
                                                                    style={{cursor: 'pointer'}}>
                                                                <i className="ti-download"/>
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                            : <tr>
                                                <td colSpan={4}>
                                                    <div><span><h6>Không có dữ liệu</h6></span></div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalChangePassword show={this.state.showModalChangePassword}
                                         onHideModal={this.onCloseModalForgotPassword}/>
                    <ModalConfirmEdit show={this.state.confirmCancel}
                                      onHide={() => this.setState({confirmCancel: false})}
                                      handleCancel={
                                          () => this.setState({
                                              confirmCancel: false,
                                              inEditing: false
                                          }, () => this.handleCancel())
                                      }
                    />
                    {this.state.showUpload && <UploadFile show={this.state.showUpload}
                                                          uploadSuccess={this.uploadCert}
                                                          onHide={() => this.setState({showUpload: false}, () => this.getFile())}/>}
                    <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                       onHide={this.modalUploadAvatarClose}
                                       updateSuccess={(item) => {
                                           this.setState({modalUploadAvatar: false, avatar: item})
                                           this.getSupervisorInfo()
                                       }}/>
                    <ModalConfirmDeleteCert show={this.state.modalConfirmDeleteCert}
                                            onRefresh={() => this.setState({modalConfirmDeleteCert: false}, ()=> this.getFile())}
                                            onHide={() => this.setState({modalConfirmDeleteCert: false})}
                                            fileId={this.state.fileId}
                    />
                    <ModalResetPasswordSup onHide={()=>this.setState({modalResetPassSup: false})}
                                           name={this.state.fullName}
                                           id={this.state.chooseId}
                                           show={this.state.modalResetPassSup}
                    />
                    {/*<ModalCropImage show={this.state.showModalCrop} onHide={this.modalCropClose}*/}
                    {/*                   customerId={1}/>*/}
                </div>
            </div>
        );
    }
}


export default InformationOfSupervisor
