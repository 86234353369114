import React from 'react';
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
import Link from '../Link';
import fetch from "node-fetch";
// import window from "global";
// import {searchHome} from '../../actions/home';
// import {toast} from 'react-toastify';
import httpUtils from "../../utils/httpUtils";
import './style.css';

class HeaderBeforeLogin extends React.Component {
    // static propTypes = {
    //   onOpenModalLogin: PropTypes.func,
    // };

    static defaultProps = {
        onOpenModalLogin: null,
        onOpenModalSignup: null,
    };

    constructor(...args) {
        super(...args);
        this.state = {
            showContent: false,
            homeData: {
                headerTitle: [],
                headerCategories: [],
                searchText: '',
                dataNews: null,
                dataHelp: null,
                suggestions: [],
                value: "",
                showSearchResult: false,
                showLoading: false,
                errMes: '',
            }
        };
    };

    componentDidMount() {
        let loadHomeTotal = window.location.pathname.trim() === '/';
        if (!window.homeData && !loadHomeTotal) {
            this.loadHomeData();
        } else if (window.homeData) {
            this.setState({homeData: window.homeData}, () => {
                this.setState({showContent: true})
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated && nextProps.isAuthenticated !== this.props.isAuthenticated) {
            let userType = '';
            if (typeof localStorage !== 'undefined') {
                userType = localStorage.getItem('user_type');
            }
            // return window.location = userType !== 'SUB_USER' ? '/manage-profile' : '/manage-meeting-room';
        }
        if (nextProps.homeData && nextProps.homeData.hasOwnProperty('code')) {
            // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>homeData',nextProps.homeData);
            this.setState({homeData: nextProps.homeData.data.header}, () => {
                this.setState({showContent: true})
            })
        }
    }

    loadHomeData = () => {
        fetch('/getHeader')
            .then(response => response.json().then(homeData => ({homeData, response})))
            .then(({homeData, response}) => {
                if (response.ok) {
                    window.homeData = homeData.data;
                    this.setState({homeData: homeData.data}, () => {
                        this.setState({showContent: true})
                    })
                }
            })
            .catch(err => console.error('Error: ', err));
    }

    handleClick = event => {
        event.preventDefault();
        if (this.props.onOpenModalLogin) {
            this.props.onOpenModalLogin(event);
        }
    };

    handleShowSignup = event => {
        event.preventDefault();
        if (this.props.onOpenModalSignup) {
            this.props.onOpenModalSignup(event);
        }
    };

    handleShowSignup2 = event => {
        event.preventDefault();
        if (this.props.onOpenModalSignupMember) {
            this.props.onOpenModalSignupMember(event);
        }
    };

    onChangeSearchText = (event) => {
        this.setState({searchText: event.target.value, value: event.target.value})
    }

    // onSearch = () => {
    //   let self = this;
    //   if (!this.state.searchText) {
    //     this.setState({showSearchResult: true, dataNews: [], dataHelp: [], errMes: "Vui lòng nhập vào ô tìm kiếm trên!"});
    //   }
    //   else {
    //     this.setState({showSearchResult: true, showLoading: true});
    //     this.props.dispatch(searchHome(self.state.searchText, (data) => {
    //       if (data.code !== 200) {
    //         self.setState({
    //           showSearchResult: true,
    //           showLoading: false,
    //           errMes: 'Đã xảy ra lỗi khi tìm kiếm, vui lòng thử lại'
    //         })
    //       }
    //       self.setState({
    //         dataNews: data.data.news,
    //         dataHelp: data.data.helps,
    //         showSearchResult: true,
    //         showLoading: false,
    //         errMes: ''
    //       }, () => {
    //         let boxSearch = document.querySelector(".search-box-res");
    //         document.addEventListener("click", function (event) {
    //           if (event.target.closest(".search-box-res") || event.target.closest(".search-box-input")) return;
    //           // box.classList.add("");
    //           self.setState({showSearchResult: false})
    //         });
    //       })
    //     }))
    //   }
    // }

    renderLoading = () => {
        return (
            <div className="spinner">
                <div className="rect1"/>
                <div className="rect2"/>
                <div className="rect3"/>
                <div className="rect4"/>
                <div className="rect5"/>
            </div>
        )
    }

    render() {
        let self = this;
        const {homeData, showContent, searchText, value, dataNews, dataHelp, showSearchResult, showLoading, errMes} = this.state
        let headerCategories = [];

        if (homeData && homeData.headerCategories) {
            homeData.headerCategories.sort(function (a, b) {
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                return 0;
            })
            for (let i in homeData.headerCategories) {
                if (!homeData.headerCategories[i].hide) {
                    headerCategories.push({
                        category: homeData.headerCategories[i],
                        items: []
                    })
                }
            }
        }
        let columnCount = headerCategories.length <= 0 ? 12 : (Math.floor(12 / headerCategories.length))
        let className = `col-md-${columnCount}`
        if (homeData && homeData.headerTitle) {
            homeData.headerTitle.sort(function (a, b) {
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                return 0;
            })

            for (let i in homeData.headerTitle) {
                for (let j in headerCategories) {
                    if (headerCategories[j].category.id === homeData.headerTitle[i].categoryId) {
                        headerCategories[j].items.push(homeData.headerTitle[i]);
                        break;
                    }
                }
            }
        }
        return (
            <div className="header-before-login-wrapper">
                <header className="affix">
                    <div className="container pr-4 pl-4">
                        <nav className="navbar navbar-expand-lg navbar-light">
              <span className="navbar-brand"><Link to="/" className="d-flex align-items-center">
                <img src="/images/onebook_logo.png" alt="" style={{width: 180}}/>
                  {/*ONEBOOK*/}
              </Link></span>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation" onClick={()=> this.props.toggleSidebarMobile()}>
                                <span className="navbar-toggler-icon"/>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto main-mn">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/" role="button"
                                              aria-haspopup="true" aria-expanded="false">Trang chủ</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/partner" role="button"
                                              aria-haspopup="true" aria-expanded="false">Đối tác</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/supervisor" role="button"
                                              aria-haspopup="true" aria-expanded="false">Chuyên gia</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        {/*    /!*  <span className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"*!/*/}
                                        {/*    /!*        aria-haspopup="true" aria-expanded="false">*!/*/}
                                        {/*    /!*      Dịch vụ <span className="caret"></span>*!/*/}
                                        {/*    /!*  </span>*!/*/}
                                        {/*    /!*<div className="dropdown-menu p-2 pt-3" aria-labelledby="navbarDropdown">*!/*/}
                                        {/*    /!*    <div className="col">*!/*/}
                                        {/*    /!*        /!*<h3 className="text-uppercase">Action</h3>*!/*!/*/}
                                        {/*    /!*        <ul>*!/*/}
                                        {/*    /!*            <li>*!/*/}
                                        {/*    /!*                <Link className="dropdown-item" to="#"*!/*/}
                                        {/*    /!*                    // target={item.target ? item.target : '_self'}*!/*/}
                                        {/*    /!*                >*!/*/}
                                        {/*    /!*                    <h4>Dịch vụ cơ bản</h4>*!/*/}
                                        {/*    /!*                </Link>*!/*/}
                                        {/*    /!*            </li>*!/*/}
                                        {/*    /!*            <li>*!/*/}
                                        {/*    /!*                <Link className="dropdown-item" to="#"*!/*/}
                                        {/*    /!*                    // target={item.target ? item.target : '_self'}*!/*/}
                                        {/*    /!*                >*!/*/}
                                        {/*    /!*                    <h4>Dịch vụ nâng cao</h4>*!/*/}
                                        {/*    /!*                </Link>*!/*/}
                                        {/*    /!*            </li>*!/*/}
                                        {/*    /!*        </ul>*!/*/}
                                        {/*    /!*    </div>*!/*/}
                                        {/*    /!*</div>*!/*/}
                                        <Link className="nav-link dropdown-toggle" to="/home/select-plan-step-1" role="button"
                                              aria-haspopup="true" aria-expanded="false">Báo giá dịch vụ</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <span className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                              aria-haspopup="true" aria-expanded="false">Dịch vụ<span className="caret"></span>
                                        </span>
                                        <div className="dropdown-menu p-2 pt-3" aria-labelledby="navbarDropdown">
                                            <div className="col">
                                                <ul>
                                                    <li>
                                                        <Link to="/home/dich-vu-ke-toan" className="dropdown-item">
                                                            <h4>Dịch vụ kế toán</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/dich-vu-thue" className="dropdown-item">
                                                            <h4>Dịch vụ thuế</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/tu-van-tai-chinh-doanh-nghiep" className="dropdown-item">
                                                            <h4>Tư vấn tài chính doanh nghiệp</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/soat-xet-va-hoan-thien-bao-cao-tai-chinh" className="dropdown-item">
                                                            <h4>Soát xét và thoàn thiện báo cáo tài chính</h4>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/about-us" role="button"
                                              aria-haspopup="true" aria-expanded="false">Về chúng tôi</Link>
                                    </li>
                                    {/*<li className="nav-item dropdown">*/}
                                    {/*    <a className="nav-link dropdown-toggle" href="https://www.facebook.com/" role="button"*/}
                                    {/*          aria-haspopup="true" aria-expanded="false">Đào tạo</a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item dropdown">
                                        {/*<Link className="nav-link dropdown-toggle" to="/home/news" role="button"*/}
                                        {/*      data-toggle="dropdown"*/}
                                        {/*   aria-haspopup="true" aria-expanded="false">Kiến thức*/}
                                        {/*  /!*<span className="caret"/>*!/*/}
                                        {/*</Link>*/}
                                        {/*<div className="dropdown-menu" aria-labelledby="navbarDropdown">*/}
                                        {/*  <div className="row">*/}
                                        {/*    /!*{showContent && headerCategories.map((service, index) => {*!/*/}
                                        {/*    /!*    return (*!/*/}
                                        {/*    <div className="col">*/}
                                        {/*      <h3 className="text-uppercase">Action</h3>*/}
                                        {/*      /!*<ul>*!/*/}
                                        {/*      /!*    {service.items.map((item, id2) => {*!/*/}
                                        {/*      /!*        return (*!/*/}
                                        {/*      /!*            <li key={id2}>*!/*/}
                                        {/*      /!*                <a className="dropdown-item" href={item.link}*!/*/}
                                        {/*      /!*                   target={item.target ? item.target : '_self'}>*!/*/}
                                        {/*      /!*                    <h4>{item.name}</h4>*!/*/}
                                        {/*      /!*                    <span>{item.description}</span>*!/*/}
                                        {/*      /!*                </a>*!/*/}
                                        {/*      /!*            </li>*!/*/}
                                        {/*      /!*        )*!/*/}
                                        {/*      /!*    })}*!/*/}
                                        {/*      /!*</ul>*!/*/}
                                        {/*    </div>*/}
                                        {/*    /!*    )*!/*/}
                                        {/*    /!*})}*!/*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                        <span className="nav-link dropdown-toggle" href="#" role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true" aria-expanded="false">Kiến thức<span
                                            className="caret"></span>
                                        </span>
                                        <div className="dropdown-menu p-2 pt-3" aria-labelledby="navbarDropdown">
                                            <div className="col">
                                                {/*<h3 className="text-uppercase">Action</h3>*/}
                                                <ul>
                                                    {this.props.listCategory.map((item, index) =>
                                                        <li>
                                                            <Link className="dropdown-item"   to={item.type==1?`/home/news?c=${item.path}`:`/home/documents?doc=${item.path}`}>
                                                                <h4>{item.name}</h4>
                                                            </Link>
                                                        </li>

                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="navbar-nav navbar-user">
                                    {/*<li className="nav-item d-flex align-self-center">*/}
                                    {/*  <a className="nav-link btn btn-signup btn-search px-md-0 collapsed" href="#search-box"*/}
                                    {/*     data-toggle="collapse"><i className="flaticon-search"/></a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        {/*<a className="nav-link btn btn-signup" onClick={this.handleClick}>Đăng nhập</a>*/}
                                        <button className='btn sign-up-btn bg-green color-white hide-on-tablet'
                                                onClick={this.handleShowSignup}><i className="flaticon-signup"/> Đăng ký
                                        </button>
                                        {/*<button className="btn sign-in-btn" onClick={this.handleClick}>Đăng nhập</button>*/}
                                    </li>
                                    {this.props.url !== 'signup'
                                    && (
                                        <span className='d-flex'>
                        <li className="nav-item">
                          {/*<button className='btn sign-up-btn bg-green color-white hide-on-tablet' onClick={this.handleShowSignup}><i className="flaticon-signup"/> Đăng ký</button>*/}
                            <button className="btn sign-in-btn" onClick={this.handleClick}>Đăng nhập</button>
                            {/*<button className="nav-link btn btn-signup btn-signin" onClick={this.handleShowSignup}><i className="flaticon-signup"/> Đăng ký</button>*/}
                        </li>
                                            {/*<li className="nav-item">*/}
                                            {/*    <button className="ml-2 nav-link btn btn-signup btn-signin" onClick={this.handleShowSignup2}><i className="flaticon-meeting-manage"/> Tham gia</button>*/}
                                            {/*</li>*/}
                      </span>
                                    )
                                    }
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        );
    }
}

// function mapStateToProps(state) {
//   return {
//     homeData: state.auth.homeData,
//     isFetching: state.auth.isFetching,
//     isCommonFetching: state.common.isFetching,
//     isAuthenticated: state.auth.isAuthenticated,
//   };
// }

// export default connect(mapStateToProps)(HeaderBeforeLogin);
export default HeaderBeforeLogin;
