import React from 'react'
import Convert from "../../../../utils/convertUrlPra";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import TableHeader from '../../../../components/TableHeader';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import ModalCreateChargePackage from "../../../../components/Modal/admin/modalCreateChargePackage";
import ModalEditChargePackage from "../../../../components/Modal/admin/modalEditChargePackage";
import ModalDeleteChargePackage from "../../../../components/Modal/admin/modalDeleteChargePackage";
import AdminServicePlan from "../../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";
import ModalCreateBill from "../../../../components/Modal/admin/modalCreateBill";
import ModalEditBill from "../../../../components/Modal/admin/modalEditBill";
import ModalDeleteBill from "../../../../components/Modal/admin/modalDeleteBill";
import ModalCreateCertification from "../../../../components/Modal/admin/modalCreateCertification";
import ModalEditCertification from "../../../../components/Modal/admin/modalEditCertification";
import ModalDeleteCertification from "../../../../components/Modal/admin/modalDeleteCertification";
import ModalCreateEmployee from "../../../../components/Modal/admin/modalCreateEmployee";
import ModalEditEmployee from "../../../../components/Modal/admin/modalEditEmployee";
import ModalDeleteEmployee from "../../../../components/Modal/admin/modalDeleteEmployee";
import PropTypes from "prop-types";
import {forEach} from "react-bootstrap/ElementChildren";
class EditServicePlan extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            showCreateChargePackage: false,
            showEditChargePackage: false,
            showDeleteChargePackage: false,

            showCreateBill: false,
            showEditBill: false,
            showDeleteBill: false,

            showCreateCertification: false,
            showEditCertification: false,
            showDeleteCertification: false,

            showCreateEmployee: false,
            showEditEmployee: false,
            showDeleteEmployee: false,

            showCreateTerm: false,
            activeButtonSubmit: false,
            updateServiceQuotationList: [],
            createServiceQuotationList: [],
            serviceQuotations: [],
            listIdDelete: [],
            serviceQuotationBill: [],
            serviceQuotationDocument: [],
            serviceQuotationEmployee: [],
            typeService: '',
            name: '',
            description: '',
            selectedServicePackage: 'PERMANENT',
            data_PERMANENT: [],

            data_TYPES_OF_BUSINESS_commerce_bill: [],
            data_TYPES_OF_BUSINESS_commerce_certification: [],
            data_TYPES_OF_BUSINESS_commerce_human: [],
            data_TYPES_OF_BUSINESS_commerce_term: [],
            data_TYPES_OF_BUSINESS_manufacturing_bill: [],
            data_TYPES_OF_BUSINESS_manufacturing_certification: [],
            data_TYPES_OF_BUSINESS_manufacturing_human: [],
            data_TYPES_OF_BUSINESS_manufacturing_term: [],
            data_TYPES_OF_BUSINESS_build_bill: [],
            data_TYPES_OF_BUSINESS_build_certification: [],
            data_TYPES_OF_BUSINESS_build_human: [],
            data_TYPES_OF_BUSINESS_build_term: [],
            collapse: 1,
            header_PERMANENT: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên gói cước",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: '',
                    name: "",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
            ],
        };
    }
    renderPriceType = (type) => {
        switch (type) {
            case 'PERMANENT':
                return 'Giá cố định';
            case 'TYPES_OF_BUSINESS':
                return 'Chia theo loại hình doanh nghiệp';
            default:
                return '';
        }
    };
    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({servicePlanId: params.servicePlanId}, () => this.getDetail())
    }
    getDetail = () => {
        let payload = {
            id: this.state.servicePlanId
        };
        AdminServicePlan.getDetail(payload, res => {
            if (res.success) {
                let listData =  res.data.serviceQuotationBaseResponseList;
                this.setState({
                    typeService: res.data.type,
                    selectedServicePackage: res.data.priceType,
                    servicePlanName: res.data.servicePlanName,
                    content: res.data.content.replace(/<br\s*\/?>/g, '\n'),
                    updateServiceQuotationList: listData,
                })
                if (this.state.selectedServicePackage === 'PERMANENT'){
                    this.setState({
                        serviceQuotations: listData,
                    })
                }
                let mapObj = {};
                res.data.serviceQuotationBaseResponseList.forEach((item) => {
                    if (mapObj[item.type] == undefined)
                        mapObj[item.type] = [];
                    if ( mapObj[item.type][item.requirementConstraint] == undefined)
                        mapObj[item.type][item.requirementConstraint] = [];
                    mapObj[item.type][item.requirementConstraint].push(item);
                });
                Object.keys(mapObj).forEach((type) => {
                    let allNumbers = mapObj[type];
                    Object.keys(allNumbers).forEach((key) => {
                        let item = {};
                        item.type = type;
                        item.requirementConstraint = key;
                        allNumbers[key].forEach((money) => {
                            item.business = money.business;
                            if (money.business === "COMMERCE_SERVICE"){
                                item.commercePrice = money.price;
                                item.commerceId    = money.id;
                            }else if (money.business === "MANUFACTURING_MACHINING"){
                                item.manufacturingPrice = money.price;
                                item.manufacturingId    = money.id;
                            }else if (money.business === "BUILDING_INSTALLATION"){
                                item.buildingPrice = money.price;
                                item.buildingId    = money.id;
                            }
                            item.servicePlanId     = money.servicePlanId;
                        });
                        if (type === "INVOICE"){
                            let serviceQuotationBill = this.state.serviceQuotationBill;
                            serviceQuotationBill.push(item);
                            this.setState({serviceQuotationBill})
                        }else  if (type === "DOCUMENT"){
                            let serviceQuotationDocument = this.state.serviceQuotationDocument;
                            serviceQuotationDocument.push(item);
                            this.setState({serviceQuotationDocument})
                        }else if (type === "EMPLOYEE"){
                            let serviceQuotationEmployee = this.state.serviceQuotationEmployee;
                            serviceQuotationEmployee.push(item);
                            this.setState({serviceQuotationEmployee})
                        }
                    });
                });
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }
    renderStatus = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'Đang hoạt động';
            case 'LOCK':
                return 'Đã khóa';
            default:
                return '';
        }
    };
    onUpdate = () => {
        let {servicePlanName, content, listIdDelete, updateServiceQuotationList, createServiceQuotationList} = this.state
        let payload = {
            id: this.state.servicePlanId,
            servicePlanName: servicePlanName,
            content: content,
            status: "ACTIVE",
            updateServiceQuotations: updateServiceQuotationList,
            createServiceQuotationList: createServiceQuotationList,
            listIdDelete: listIdDelete,
        }
        AdminServicePlan.update(payload, res => {
            if (res.success){
                this.props.history.push("/admin/service-plan-management")
                toast.success("Cập nhật gói cước mới thành công!")
            } else {
                toast.error("Đã xảy ra lỗi! " + res.message)
            }
        })
    }
    createChargePackage = (item) => {
        let {serviceQuotations, createServiceQuotationList} = this.state;
        serviceQuotations.push(item);
        createServiceQuotationList.push(item);
        this.setState({serviceQuotations, createServiceQuotationList});
    }
    editChargePackage = (item) => {
        this.setState({showEditChargePackage: false});
        let _list = this.state.updateServiceQuotationList;
        let serviceQuotations = this.state.serviceQuotations;
        _list.map((_itemCheck, index) => {
            if (item.id == _itemCheck.id){
                _list[index] = item;
            }
        })
        serviceQuotations.map((_itemCheck, index) => {
            if (item.id == _itemCheck.id){
                serviceQuotations[index] = item;
            }
        })
        this.setState({updateServiceQuotationList: _list, serviceQuotations});
        toast.success("Cập nhật thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    deleteChargePackage = (item) => {
        this.setState({showDeleteChargePackage: false});
        let serviceQuotations = this.state.serviceQuotations;
        let listIdDelete = this.state.listIdDelete;
        if (item.servicePlanId !== undefined){
            listIdDelete.push(item.id);
            let _list            = this.state.updateServiceQuotationList;
            _list                = _list.filter((_itemCheck) => !(item.id == _itemCheck.id));
            serviceQuotations    = serviceQuotations.filter((_itemCheck) => !(item.id == _itemCheck.id));
            this.setState({updateServiceQuotationList: _list, serviceQuotations, listIdDelete})
        }else {
            let _list = this.state.createServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !((_itemCheck.type == item.type && _itemCheck.requirementConstraint == item.requirementConstraint) && ((_itemCheck.business == "COMMERCE_SERVICE" && _itemCheck.price == item.commercePrice) || (_itemCheck.business == "MANUFACTURING_MACHINING" && _itemCheck.price == item.manufacturingPrice) || (_itemCheck.business == "BUILDING_INSTALLATION" && _itemCheck.price == item.buildingPrice))))
            serviceQuotations    = serviceQuotationBill.filter((_itemCheck) => !(item.business == _itemCheck.business && item.type == _itemCheck.type && item.requirementConstraint == _itemCheck.requirementConstraint && item.buildingPrice == _itemCheck.buildingPrice && item.commercePrice == _itemCheck.commercePrice && item.manufacturingPrice == _itemCheck.manufacturingPrice));
            this.setState({updateServiceQuotationList: _list, serviceQuotationBill})
        }
        toast.success("Xóa thành công. Vui lòng ấn Lưu để cập nhật!");
    }

    createBill = (list, item) => {
        let _list = this.state.createServiceQuotationList;
        let serviceQuotationBill = this.state.serviceQuotationBill;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationBill.push(item)
        this.setState({createServiceQuotationList: _list, serviceQuotationBill})
    }
    editBill = (list, item) => {
        this.setState({showEditBill: false});
        let _list = this.state.updateServiceQuotationList;
        let serviceQuotationBill = this.state.serviceQuotationBill;
        list.map((_item) => {
            _list.map((_itemCheck, index) => {
                if (_item.id == _itemCheck.id){
                    _list[index] = _item;
                }
            })
        })
        serviceQuotationBill.map((_itemCheck, index) => {
            if (item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId){
                serviceQuotationBill[index] = item;
            }
        })
        this.setState({updateServiceQuotationList: _list, serviceQuotationBill});
        toast.success("Cập nhật thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    deleteBill = (item) => {
        this.setState({showDeleteBill: false});
        let serviceQuotationBill = this.state.serviceQuotationBill;
        let listIdDelete = this.state.listIdDelete;
        if (item.servicePlanId !== undefined){
            listIdDelete.push(item.buildingId);
            listIdDelete.push(item.commerceId);
            listIdDelete.push(item.manufacturingId);

            let _list = this.state.updateServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !(item.buildingId == _itemCheck.id || item.commerceId == _itemCheck.id || item.manufacturingId == _itemCheck.id))
            serviceQuotationBill    = serviceQuotationBill.filter((_itemCheck) => !(item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId))
            this.setState({updateServiceQuotationList: _list, serviceQuotationBill, listIdDelete})
        }else {
            let _list = this.state.createServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !((_itemCheck.type == item.type && _itemCheck.requirementConstraint == item.requirementConstraint) && ((_itemCheck.business == "COMMERCE_SERVICE" && _itemCheck.price == item.commercePrice) || (_itemCheck.business == "MANUFACTURING_MACHINING" && _itemCheck.price == item.manufacturingPrice) || (_itemCheck.business == "BUILDING_INSTALLATION" && _itemCheck.price == item.buildingPrice))))
            serviceQuotationBill    = serviceQuotationBill.filter((_itemCheck) => !(item.business == _itemCheck.business && item.type == _itemCheck.type && item.requirementConstraint == _itemCheck.requirementConstraint && item.buildingPrice == _itemCheck.buildingPrice && item.commercePrice == _itemCheck.commercePrice && item.manufacturingPrice == _itemCheck.manufacturingPrice));
            this.setState({updateServiceQuotationList: _list, serviceQuotationBill})
        }
        toast.success("Xóa thành công. Vui lòng ấn Lưu để cập nhật!");
    }

    createCertification = (list, item) => {
        let _list = this.state.createServiceQuotationList;
        let serviceQuotationDocument = this.state.serviceQuotationDocument;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationDocument.push(item)
        this.setState({createServiceQuotationList: _list, serviceQuotationDocument})
    }
    editCertification = (list, item) => {
        this.setState({showEditCertification: false});
        let _list = this.state.updateServiceQuotationList;
        let serviceQuotationDocument = this.state.serviceQuotationDocument;
        list.map((_item) => {
            _list.map((_itemCheck, index) => {
                if (_item.id == _itemCheck.id){
                    _list[index] = _item;
                }
            })
        })
        serviceQuotationDocument.map((_itemCheck, index) => {
            if (item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId){
                serviceQuotationDocument[index] = item;
            }
        })
        this.setState({updateServiceQuotationList: _list, serviceQuotationDocument});
        toast.success("Cập nhật thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    deleteCertification = (item) => {
        this.setState({showDeleteCertification: false});
        let serviceQuotationDocument = this.state.serviceQuotationDocument;
        let listIdDelete = this.state.listIdDelete;
        if (item.servicePlanId !== undefined){
            listIdDelete.push(item.buildingId);
            listIdDelete.push(item.commerceId);
            listIdDelete.push(item.manufacturingId);

            let _list = this.state.updateServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !(item.buildingId == _itemCheck.id || item.commerceId == _itemCheck.id || item.manufacturingId == _itemCheck.id))
            serviceQuotationDocument    = serviceQuotationDocument.filter((_itemCheck) => !(item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId))
            this.setState({updateServiceQuotationList: _list, serviceQuotationDocument, listIdDelete})
        }else {
            let _list = this.state.createServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !((_itemCheck.type == item.type && _itemCheck.requirementConstraint == item.requirementConstraint) && ((_itemCheck.business == "COMMERCE_SERVICE" && _itemCheck.price == item.commercePrice) || (_itemCheck.business == "MANUFACTURING_MACHINING" && _itemCheck.price == item.manufacturingPrice) || (_itemCheck.business == "BUILDING_INSTALLATION" && _itemCheck.price == item.buildingPrice))))
            serviceQuotationDocument    = serviceQuotationDocument.filter((_itemCheck) => !(item.business == _itemCheck.business && item.type == _itemCheck.type && item.requirementConstraint == _itemCheck.requirementConstraint && item.buildingPrice == _itemCheck.buildingPrice && item.commercePrice == _itemCheck.commercePrice && item.manufacturingPrice == _itemCheck.manufacturingPrice));
            this.setState({updateServiceQuotationList: _list, serviceQuotationDocument})
        }
        toast.success("Xóa thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    createEmployee = (list, item) => {
        let _list = this.state.createServiceQuotationList;
        let serviceQuotationEmployee = this.state.serviceQuotationEmployee;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationEmployee.push(item)
        this.setState({createServiceQuotationList: _list, serviceQuotationEmployee})
    }
    editEmployee = (list, item) => {
        this.setState({showEditEmployee: false});
        let _list = this.state.updateServiceQuotationList;
        let serviceQuotationEmployee = this.state.serviceQuotationEmployee;
        list.map((_item) => {
            _list.map((_itemCheck, index) => {
                if (_item.id == _itemCheck.id){
                    _list[index] = _item;
                }
            })
        })
        serviceQuotationEmployee.map((_itemCheck, index) => {
            if (item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId){
                serviceQuotationEmployee[index] = item;
            }
        })
        this.setState({updateServiceQuotationList: _list, serviceQuotationEmployee});
        toast.success("Cập nhật thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    deleteEmployee = (item) => {
        this.setState({showDeleteEmployee: false});
        let serviceQuotationEmployee = this.state.serviceQuotationEmployee;
        let listIdDelete = this.state.listIdDelete;
        if (item.servicePlanId !== undefined){
            listIdDelete.push(item.buildingId);
            listIdDelete.push(item.commerceId);
            listIdDelete.push(item.manufacturingId);

            let _list = this.state.updateServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !(item.buildingId == _itemCheck.id || item.commerceId == _itemCheck.id || item.manufacturingId == _itemCheck.id))
            serviceQuotationEmployee    = serviceQuotationEmployee.filter((_itemCheck) => !(item.buildingId == _itemCheck.buildingId && item.commerceId == _itemCheck.commerceId && item.manufacturingId == _itemCheck.manufacturingId))
            this.setState({updateServiceQuotationList: _list, serviceQuotationEmployee, listIdDelete})
        }else {
            let _list = this.state.createServiceQuotationList;
            _list                   = _list.filter((_itemCheck) => !((_itemCheck.type == item.type && _itemCheck.requirementConstraint == item.requirementConstraint) && ((_itemCheck.business == "COMMERCE_SERVICE" && _itemCheck.price == item.commercePrice) || (_itemCheck.business == "MANUFACTURING_MACHINING" && _itemCheck.price == item.manufacturingPrice) || (_itemCheck.business == "BUILDING_INSTALLATION" && _itemCheck.price == item.buildingPrice))))
            serviceQuotationEmployee    = serviceQuotationEmployee.filter((_itemCheck) => !(item.business == _itemCheck.business && item.type == _itemCheck.type && item.requirementConstraint == _itemCheck.requirementConstraint && item.buildingPrice == _itemCheck.buildingPrice && item.commercePrice == _itemCheck.commercePrice && item.manufacturingPrice == _itemCheck.manufacturingPrice));
            this.setState({updateServiceQuotationList: _list, serviceQuotationEmployee});
        }
        toast.success("Xóa thành công. Vui lòng ấn Lưu để cập nhật!");
    }
    render() {
        let {serviceQuotations} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="service-plan-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Quản lý gói dịch vụ"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <h2 className='text-center mb-4'>CHỈNH SỬA GÓI DỊCH
                                            VỤ {this.state.typeService === 'BASIC' ? 'CƠ BẢN' : 'NÂNG CAO'}</h2>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Tên dịch vụ</label>
                                                <input
                                                    maxLength={100} type="text"
                                                    className="form-control shadow-none"
                                                    value={this.state.servicePlanName}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            servicePlanName: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Mô tả dịch vụ</label>
                                                <div className="form-control w-100 h-100 p-0">
                                                    <textarea
                                                        value={this.state.content}
                                                        rows={5}
                                                        className="form-control border-0 shadow-none p-2"
                                                        style={{resize: 'none', fontSize: 14}}
                                                        maxLength={1000}
                                                        onChange={(e) => {
                                                            this.setState({content: e.target.value})
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0 text-black-50">Gói dịch vụ</label>
                                                <label className="label-item">{this.renderPriceType(this.state.selectedServicePackage)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    {this.state.selectedServicePackage === 'PERMANENT' &&
                                    <div>
                                        <div className="col-12 d-flex justify-content-end align-right mb-2 pr-0">
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateChargePackage: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <TableHeader header={this.state.header_PERMANENT}
                                                             clickSort={(data) => {
                                                             }}/>
                                                {/*</thead>*/}
                                                {!serviceQuotations || serviceQuotations.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {serviceQuotations?.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Sửa"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            itemSelected: row,
                                                                            showEditChargePackage: true,
                                                                            modalType: "edit"
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="ti-pencil-alt"/>
                                                                </button>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Xóa"
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row, showDeleteChargePackage: true})
                                                                    }}
                                                                >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                    }
                                    {this.state.selectedServicePackage === 'TYPES_OF_BUSINESS' &&
                                    <div className="mt-3">
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Hóa đơn</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateBill: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Số lượng hóa đơn</th>
                                                    <th colSpan={5}>Giá gói cước (VNĐ)</th>
                                                </tr>
                                                <tr>
                                                    <th>Thương mại</th>
                                                    <th>Sản xuất</th>
                                                    <th>Xây dựng</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                {!this.state.serviceQuotationBill || this.state.serviceQuotationBill.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationBill.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'center',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Sửa"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            itemSelected: row,
                                                                            showEditBill: true,
                                                                            modalType: "edit"
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="ti-pencil-alt"/>
                                                                </button>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Xóa"
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row, showDeleteBill: true})
                                                                    }}
                                                                >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Chứng từ</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateCertification: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Số lượng chứng từ</th>
                                                    <th colSpan={5}>Giá gói cước (VNĐ)</th>
                                                </tr>
                                                <tr>
                                                    <th>Thương mại</th>
                                                    <th>Sản xuất</th>
                                                    <th>Xây dựng</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                {!this.state.serviceQuotationDocument || this.state.serviceQuotationDocument.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationDocument.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'center',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Sửa"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            itemSelected: row,
                                                                            showEditCertification: true,
                                                                            modalType: "edit"
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="ti-pencil-alt"/>
                                                                </button>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Xóa"
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row, showDeleteCertification: true})
                                                                    }}
                                                                >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Nhân sự</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateEmployee: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Số lượng nhân sự</th>
                                                    <th colSpan={5}>Giá gói cước (VNĐ)</th>
                                                </tr>
                                                <tr>
                                                    <th>Thương mại</th>
                                                    <th>Sản xuất</th>
                                                    <th>Xây dựng</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                {!this.state.serviceQuotationEmployee || this.state.serviceQuotationEmployee.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationEmployee.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'center',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Sửa"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            itemSelected: row,
                                                                            showEditEmployee: true,
                                                                            modalType: "edit"
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className="ti-pencil-alt"/>
                                                                </button>
                                                                <button
                                                                    className="btn btn-action"
                                                                    title="Xóa"
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row, showDeleteEmployee: true})
                                                                    }}
                                                                >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                    }

                                    <div className="align-right col-12">
                                        <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                            <button
                                                type="button"
                                                className="ml-4 col-1 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                                onClick={()=> {
                                                    this.onUpdate()
                                                }}
                                            >
                                                Lưu
                                            </button>
                                            <button
                                                type="button"
                                                className="ml-4  border-1-s type-button-fix btn btn-light shadow-none"
                                            >
                                                <Link
                                                    style={{color: '#000'}}
                                                    to={{
                                                        pathname: "/admin/service-plan-management",
                                                    }}
                                                >
                                                    Quay lại
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showCreateChargePackage && <ModalCreateChargePackage
                    show={this.state.showCreateChargePackage}
                    setData={{}}
                    onHide={() => this.setState({showCreateChargePackage: false})}
                    create={(item) => this.createChargePackage(item)}
                    // onRefresh={() => this.getListSupOnSearch()}
                />}
                {this.state.showEditChargePackage && <ModalEditChargePackage
                    show={this.state.showEditChargePackage}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showEditChargePackage: false})}
                    create={(item) => this.editChargePackage(item)}
                    // onRefresh={() => this.getListSupOnSearch()}
                />}
                {this.state.showDeleteChargePackage && <ModalDeleteChargePackage
                    show={this.state.showDeleteChargePackage}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showDeleteChargePackage: false})}
                    delete={this.deleteChargePackage}
                    // onRefresh={() => this.()}
                />}

                {this.state.showCreateBill && <ModalCreateBill
                    show={this.state.showCreateBill}
                    // type='commerce'
                    onHide={() => this.setState({showCreateBill: false})}
                    create={this.createBill}
                    // onRefresh={() => this.()}
                />}
                {this.state.showEditBill && <ModalEditBill
                    show={this.state.showEditBill}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showEditBill: false})}
                    create={this.editBill}
                    // onRefresh={() => this.()}
                />}
                {this.state.showDeleteBill && <ModalDeleteBill
                    show={this.state.showDeleteBill}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showDeleteBill: false})}
                    delete={this.deleteBill}
                    // onRefresh={() => this.()}
                />}

                {this.state.showCreateCertification && <ModalCreateCertification
                    show={this.state.showCreateCertification}
                    // type='commerce'
                    onHide={() => this.setState({showCreateCertification: false})}
                    create={this.createCertification}
                    // onRefresh={() => this.()}
                />}
                {this.state.showEditCertification && <ModalEditCertification
                    show={this.state.showEditCertification}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showEditCertification: false})}
                    create={this.editCertification}
                    // onRefresh={() => this.()}
                />}
                {this.state.showDeleteCertification && <ModalDeleteCertification
                    show={this.state.showDeleteCertification}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showDeleteCertification: false})}
                    delete={this.deleteCertification}
                    // onRefresh={() => this.()}
                />}
                {this.state.showCreateEmployee && <ModalCreateEmployee
                    show={this.state.showCreateEmployee}
                    // type='commerce'
                    onHide={() => this.setState({showCreateEmployee: false})}
                    create={this.createEmployee}
                    // onRefresh={() => this.()}
                />}
                {this.state.showEditEmployee && <ModalEditEmployee
                    show={this.state.showEditEmployee}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showEditEmployee: false})}
                    create={this.editEmployee}
                    // onRefresh={() => this.()}
                />}
                {this.state.showDeleteEmployee && <ModalDeleteEmployee
                    show={this.state.showDeleteEmployee}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showDeleteEmployee: false})}
                    delete={this.deleteEmployee}
                    // onRefresh={() => this.()}
                />}
            </div>
        )
    }

}
export default EditServicePlan
