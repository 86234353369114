export const reportCodeOptions = [
  {
  textSearch: "131-Phải thu khách hàng",
    label: "Phải thu khách hàng",
    value: "131"
  },
  {
  textSearch: "1311-Phải thu ngắn hạn khách hàng",
    label: "Phải thu ngắn hạn khách hàng",
    value: "1311"
  },
  {
  textSearch: "13111-Phải thu ngắn hạn khách hàng: hoạt động SXKD",
    label: "Phải thu ngắn hạn khách hàng: hoạt động SXKD",
    value: "13111"
  },
  {
  textSearch: "13112-Phải thu ngắn hạn khách hàng: hoạt động đầu tư",
    label: "Phải thu ngắn hạn khách hàng: hoạt động đầu tư",
    value: "13112"
  },
  {
  textSearch: "13113-Phải thu ngắn hạn khách hàng: hoạt động tài chính",
    label: "Phải thu ngắn hạn khách hàng: hoạt động tài chính",
    value: "13113"
  },

  {
  textSearch: "1312-Phải thu dài hạn khách hàng",
    label: "Phải thu dài hạn khách hàng",
    value: "1312"
  },
  {
  textSearch: "13121-Phải thu dài hạn khách hàng: hoạt động SXKD",
    label: "Phải thu dài hạn khách hàng: hoạt động SXKD",
    value: "13121"
  },
  {
  textSearch: "13122-Phải thu dài hạn khách hàng: hoạt động đầu tư",
    label: "Phải thu dài hạn khách hàng: hoạt động đầu tư",
    value: "13122"
  },
  {
  textSearch: "13123-Phải thu dài hạn khách hàng: hoạt động tài chính",
    label: "Phải thu dài hạn khách hàng: hoạt động tài chính",
    value: "13123"
  },
  {
  textSearch: "1388-Phải thu khác",
    label: "Phải thu khác",
    value: "1388"
  },
  {
  textSearch: "13881-Phải thu ngắn hạn khác",
    label: "Phải thu ngắn hạn khác",
    value: "13881"
  },
  {
  textSearch: "138811-Phải thu ngắn hạn khác: HĐ SXKD",
    label: "Phải thu ngắn hạn khác: HĐ SXKD",
    value: "138811"
  },
  {
  textSearch: "1388111-Cho mượn",
    label: "Cho mượn",
    value: "1388111"
  },
  {
  textSearch: "1388112-Các khoản chi hộ",
    label: "Các khoản chi hộ",
    value: "1388112"
  },
  {
  textSearch: "1388118-Phải thu ngắn hạn HĐ SXKD khác",
    label: "Phải thu ngắn hạn HĐ SXKD khác",
    value: "1388118"
  },
  {
  textSearch: "138812-Phải thu ngắn hạn khác: HĐ đầu tư",
    label: "Phải thu ngắn hạn khác: HĐ đầu tư",
    value: "138812"
  },
  {
  textSearch: "1388121-Phải thu về cổ tức, lợi nhuận được chia",
    label: "Phải thu về cổ tức, lợi nhuận được chia",
    value: "1388121"
  },
  {
  textSearch: "1388128-Phải thu ngắn hạn HĐ đầu tư khác",
    label: "Phải thu ngắn hạn HĐ đầu tư khác",
    value: "1388128"
  },
  {
  textSearch: "138813-Phải thu ngắn hạn khác: HĐ tài chính",
    label: "Phải thu ngắn hạn khác: HĐ tài chính",
    value: "138813"
  },
  {
  textSearch: "13882-Phải thu dài hạn khác",
    label: "Phải thu dài hạn khác",
    value: "13882"
  },
  {
  textSearch: "138821-Phải thu dài hạn khác: HĐ SXKD",
    label: "Phải thu dài hạn khác: HĐ SXKD",
    value: "138821"
  },
  {
  textSearch: "1388211-Cho mượn",
    label: "Cho mượn",
    value: "1388211"
  },
  {
  textSearch: "1388212-Các khoản chi hộ",
    label: "Các khoản chi hộ",
    value: "1388212"
  },
  {
  textSearch: "1388218-Phải thu dài hạn HĐ SXKD khác",
    label: "Phải thu dài hạn HĐ SXKD khác",
    value: "1388218"
  },
  {
  textSearch: "138822-Phải thu dài hạn khác: HĐ đầu tư",
    label: "Phải thu dài hạn khác: HĐ đầu tư",
    value: "138822"
  },
  {
  textSearch: "1388221-Phải thu về cổ tức, lợi nhuận được chia",
    label: "Phải thu về cổ tức, lợi nhuận được chia",
    value: "1388221"
  },
  {
  textSearch: "1388228-Phải thu dài hạn HĐ đầu tư khác",
    label: "Phải thu dài hạn HĐ đầu tư khác",
    value: "1388228"
  },
  {
  textSearch: "138823-Phải thu dài hạn khác: HĐ tài chính",
    label: "Phải thu dài hạn khác: HĐ tài chính",
    value: "138823"
  },
  {
  textSearch: "141-Tạm ứng",
    label: "Tạm ứng",
    value: "141"
  },
  {
  textSearch: "1411-Tạm ứng: Ngắn hạn",
    label: "Tạm ứng: Ngắn hạn",
    value: "1411"
  },
  {
  textSearch: "1412-Tạm ứng: Dài hạn",
    label: "Tạm ứng: Dài hạn",
    value: "1412"
  }
];
