import React from 'react'
import Convert from "../../../../utils/convertUrlPra";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import TableHeader from '../../../../components/TableHeader';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import DependingTypeCommerce from './depending-type-commerce';
import DependingTypeBuild from './depending-type-build';
import DependingTypeManufacturing from './depending-type-manufacturing';
import ModalCreateChargePackage from '../../../../components/Modal/admin/modalCreateChargePackage';
import AdminServicePlan from "../../../../services/ob-core/admin-service-plan";
import {toast} from "react-toastify";
import ModalCreateBill from "../../../../components/Modal/admin/modalCreateBill";
import ModalCreateCertification from "../../../../components/Modal/admin/modalCreateCertification";
import ModalCreateEmployee from "../../../../components/Modal/admin/modalCreateEmployee";
import PropTypes from "prop-types";
import { createHashHistory } from 'history'

class CreateServicePlan extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            showCreateChargePackage: false,
            showCreateBill: false,
            showCreateCertification: false,
            showCreateEmployee: false,
            showCreateTerm: false,
            activeButtonSubmit: false,
            serviceQuotationList: [],
            serviceQuotationBill: [],
            serviceQuotationDocument: [],
            serviceQuotationEmployee: [],
            typeService: '',
            name: '',
            description: '',
            selectedServicePackage: 'PERMANENT',
            data_PERMANENT: [],

            data_TYPES_OF_BUSINESS_commerce_bill: [],
            data_TYPES_OF_BUSINESS_commerce_certification: [],
            data_TYPES_OF_BUSINESS_commerce_human: [],
            data_TYPES_OF_BUSINESS_commerce_term: [],
            data_TYPES_OF_BUSINESS_manufacturing_bill: [],
            data_TYPES_OF_BUSINESS_manufacturing_certification: [],
            data_TYPES_OF_BUSINESS_manufacturing_human: [],
            data_TYPES_OF_BUSINESS_manufacturing_term: [],
            data_TYPES_OF_BUSINESS_build_bill: [],
            data_TYPES_OF_BUSINESS_build_certification: [],
            data_TYPES_OF_BUSINESS_build_human: [],
            data_TYPES_OF_BUSINESS_build_term: [],
            collapse: 1,
            header_PERMANENT: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên gói cước",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá gói cước",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
            ],
        };
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({typeService: params.typeService})
    }

    renderStatus = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'Đang hoạt động';
            case 'LOCK':
                return 'Đã khóa';
            default:
                return '';
        }
    };

    createChargePackage = (item) => {
        let lst = this.state.serviceQuotationList || [];
        lst.push(item);
        this.setState({serviceQuotationList: lst})
    }

    onCreate = () => {
        let {servicePlanName, content, selectedServicePackage, serviceQuotationList} = this.state
        let payload = {
            content: content,
            createServiceQuotationList: serviceQuotationList,
            priceType: selectedServicePackage,
            servicePlanName: servicePlanName,
            status: "DISABLED",
            type: "BASIC"
        }
        AdminServicePlan.create(payload, res => {
            if (res.success){
                this.props.history.push("/admin/service-plan-management")
                toast.success("Tạo gói cước mới thành công!")
            } else {
                toast.error("Đã xảy ra lỗi! " + res.message)
            }
        })
    }

    createBill = (list, item) => {
        let _list = this.state.serviceQuotationList;
        let serviceQuotationBill = this.state.serviceQuotationBill;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationBill.push(item)
        this.setState({serviceQuotationList: _list, serviceQuotationBill})
    }

    createDocument = (list, item) => {
        let _list = this.state.serviceQuotationList;
        let serviceQuotationDocument = this.state.serviceQuotationDocument;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationDocument.push(item)
        this.setState({serviceQuotationList: _list, serviceQuotationDocument})
    }

    createEmployee = (list, item) => {
        let _list = this.state.serviceQuotationList;
        let serviceQuotationEmployee = this.state.serviceQuotationEmployee;
        list.map((_item) => {
            _list.push(_item)
        })
        serviceQuotationEmployee.push(item)
        this.setState({serviceQuotationList: _list, serviceQuotationEmployee})
    }

    render() {
        let {serviceQuotationList} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="service-plan-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Quản lý gói dịch vụ"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <h2 className='text-center mb-4'>THÊM MỚI GÓI DỊCH
                                            VỤ {this.state.typeService === 'BASIC' ? 'CƠ BẢN' : 'NÂNG CAO'}</h2>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Tên dịch vụ</label>
                                                <input
                                                    maxLength={100} type="text"
                                                    className="form-control shadow-none"
                                                    value={this.state.servicePlanName}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            servicePlanName: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Mô tả dịch vụ</label>
                                                {/*<input*/}
                                                {/*    maxLength={1000} type="text"*/}
                                                {/*    className="form-control shadow-none"*/}
                                                {/*    value={this.state.description}*/}
                                                {/*    onChange={(event) => {*/}
                                                {/*        this.setState({*/}
                                                {/*            description: event.target.value*/}
                                                {/*        })*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                                <div className="form-control w-100 h-100 p-0">
                                                    <textarea
                                                        value={this.state.content}
                                                        rows={5}
                                                        className="form-control border-0 shadow-none p-2"
                                                        style={{resize: 'none', fontSize: 14}}
                                                        maxLength={1000}
                                                        onChange={(e) => {
                                                            this.setState({content: e.target.value})
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex">
                                                <label className="col-2 label-item pl-0">
                                                    Gói dịch vụ
                                                </label>
                                                <div className="form-check mr-4"
                                                     onClick={() => this.setState({selectedServicePackage: 'PERMANENT'})}>
                                                    <input className="form-check-input" type="radio"
                                                           checked={this.state.selectedServicePackage === 'PERMANENT'}/>
                                                    <label className="form-check-label">
                                                        Giá cố định
                                                    </label>
                                                </div>
                                                <div className="form-check mr-4"
                                                     onClick={() => this.setState({selectedServicePackage: 'TYPES_OF_BUSINESS'})}>
                                                    <input className="form-check-input" type="radio"
                                                           checked={this.state.selectedServicePackage === 'TYPES_OF_BUSINESS'}/>
                                                    <label className="form-check-label">
                                                        Chia theo loại hình doanh nghiệp
                                                    </label>
                                                </div>
                                                {/*<div className="form-check mr-4"*/}
                                                {/*     onClick={() => this.setState({selectedServicePackage: 'CUSTOM'})}>*/}
                                                {/*    <input className="form-check-input" type="radio"*/}
                                                {/*           checked={this.state.selectedServicePackage === 'CUSTOM'}/>*/}
                                                {/*    <label className="form-check-label">*/}
                                                {/*        Cấu hình thủ công*/}
                                                {/*    </label>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    {this.state.selectedServicePackage === 'PERMANENT' &&
                                    <div>
                                        <div className="col-12 d-flex justify-content-end align-right mb-2 pr-0">
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateChargePackage: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                {/*<thead style={{backgroundColor: '#c6e6f1'}}>*/}
                                                {/*    <tr>*/}
                                                {/*        <th rowSpan={2}>STT</th>*/}
                                                {/*        <th rowSpan={2}>Tên gói cước</th>*/}
                                                {/*        <th colSpan={3}>Giá gói cước</th>*/}
                                                {/*    </tr>*/}
                                                {/*    <tr>*/}
                                                {/*        <th>Thương mại</th>*/}
                                                {/*        <th>Sản xuất</th>*/}
                                                {/*        <th>Xây dựng</th>*/}
                                                {/*    </tr>*/}
                                                <TableHeader header={this.state.header_PERMANENT}
                                                             clickSort={(data) => {
                                                             }}/>
                                                {/*</thead>*/}
                                                {!serviceQuotationList || serviceQuotationList.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {serviceQuotationList?.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.name}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    suffix={" VND"}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                    }
                                    {this.state.selectedServicePackage === 'TYPES_OF_BUSINESS' &&
                                    <div className="mt-3">
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Hóa đơn</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateBill: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Số lượng hóa đơn</th>
                                                    <th colSpan={3}>Giá gói cước (VNĐ)</th>
                                                </tr>
                                                <tr>
                                                    <th>Thương mại</th>
                                                    <th>Sản xuất</th>
                                                    <th>Xây dựng</th>
                                                </tr>
                                                </thead>
                                                {!this.state.serviceQuotationBill || this.state.serviceQuotationBill.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationBill.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Chứng từ</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateCertification: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                    <tr>
                                                        <th rowSpan={2}>STT</th>
                                                        <th rowSpan={2}>Số lượng chứng từ</th>
                                                        <th colSpan={3}>Giá gói cước (VNĐ)</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Thương mại</th>
                                                        <th>Sản xuất</th>
                                                        <th>Xây dựng</th>
                                                    </tr>
                                                </thead>
                                                {!this.state.serviceQuotationDocument || this.state.serviceQuotationDocument.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationDocument.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-right mb-2 pr-0">
                                            <label>Nhân sự</label>
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showCreateEmployee: true})
                                                }}
                                            >
                                                Thêm mới
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <thead style={{backgroundColor: '#c6e6f1'}}>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Số lượng nhân sự</th>
                                                    <th colSpan={3}>Giá gói cước (VNĐ)</th>
                                                </tr>
                                                <tr>
                                                    <th>Thương mại</th>
                                                    <th>Sản xuất</th>
                                                    <th>Xây dựng</th>
                                                </tr>
                                                </thead>
                                                {!this.state.serviceQuotationEmployee || this.state.serviceQuotationEmployee.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceQuotationEmployee.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.requirementConstraint}
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.commercePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.manufacturingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                            <td style={{textAlign: 'right'}}>
                                                                <NumberFormat
                                                                    value={row.buildingPrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    // suffix={" VND"}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>


                                        {/*<div className="accordion">*/}
                                        {/*    <div className="card">*/}
                                        {/*        <div className="card-header d-flex justify-content-between"*/}
                                        {/*             style={{backgroundColor: 'rgb(229, 241, 245)', cursor: 'pointer'}}*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 if (this.state.collapse !== 1) this.setState({collapse: 1})*/}
                                        {/*                 else this.setState({collapse: 0})*/}
                                        {/*             }}>*/}
                                        {/*            <h2 className="mb-0" style={{color: 'rgb(109, 179, 209)'}}>*/}
                                        {/*                /!*<button className="btn btn-link" type="button"*!/*/}
                                        {/*                /!*        aria-expanded="true">*!/*/}
                                        {/*                Thương mại*/}
                                        {/*                /!*</button>*!/*/}
                                        {/*            </h2>*/}
                                        {/*            {this.state.collapse === 1 ? <i className='ti-arrow-circle-up'/> :*/}
                                        {/*                <i className='ti-arrow-circle-down'/>}*/}
                                        {/*        </div>*/}
                                        {/*        <div*/}
                                        {/*            className={this.state.collapse === 1 ? "collapse show" : "collapse"}>*/}
                                        {/*            <div className="card-body">*/}
                                        {/*                <DependingTypeCommerce create={this.createChargePackage}/>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="card">*/}
                                        {/*        <div className="card-header d-flex justify-content-between"*/}
                                        {/*             style={{backgroundColor: 'rgb(229, 241, 245)', cursor: 'pointer'}}*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 if (this.state.collapse !== 2) this.setState({collapse: 2})*/}
                                        {/*                 else this.setState({collapse: 0})*/}
                                        {/*             }}>*/}
                                        {/*            <h2 className="mb-0" style={{color: 'rgb(109, 179, 209)'}}>*/}
                                        {/*                /!*<button className="btn btn-link collapsed" type="button"*!/*/}
                                        {/*                /!*        aria-expanded="false">*!/*/}
                                        {/*                Sản xuất*/}
                                        {/*                /!*</button>*!/*/}
                                        {/*            </h2>*/}
                                        {/*            {this.state.collapse === 2 ? <i className='ti-arrow-circle-up'/> :*/}
                                        {/*                <i className='ti-arrow-circle-down'/>}*/}
                                        {/*        </div>*/}
                                        {/*        <div*/}
                                        {/*            className={this.state.collapse === 2 ? "collapse show" : "collapse"}>*/}
                                        {/*            <div className="card-body">*/}
                                        {/*                <DependingTypeManufacturing create={this.createChargePackage}/>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="card">*/}
                                        {/*        <div className="card-header d-flex justify-content-between"*/}
                                        {/*             style={{backgroundColor: 'rgb(229, 241, 245)', cursor: 'pointer'}}*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 if (this.state.collapse !== 3) this.setState({collapse: 3})*/}
                                        {/*                 else this.setState({collapse: 0})*/}
                                        {/*             }}>*/}
                                        {/*            <h2 className="mb-0" style={{color: 'rgb(109, 179, 209)'}}>*/}
                                        {/*                /!*<button className="btn btn-link collapsed" type="button"*!/*/}
                                        {/*                /!*        aria-expanded="false">*!/*/}
                                        {/*                Xây dựng*/}
                                        {/*                /!*</button>*!/*/}
                                        {/*            </h2>*/}
                                        {/*            {this.state.collapse === 3 ? <i className='ti-arrow-circle-up'/> :*/}
                                        {/*                <i className='ti-arrow-circle-down'/>}*/}
                                        {/*        </div>*/}
                                        {/*        <div*/}
                                        {/*            className={this.state.collapse === 3 ? "collapse show" : "collapse"}>*/}
                                        {/*            <div className="card-body">*/}
                                        {/*                <DependingTypeBuild create={this.createChargePackage}/>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    }

                                    <div className="align-right col-12">
                                        <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                            <button
                                                type="button"
                                                className="ml-4 col-1 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                                // disabled={!this.state.activeButtonSubmit}
                                                onClick={()=> {
                                                    this.onCreate()
                                                }}
                                            >
                                                Lưu
                                            </button>
                                            <button
                                                type="button"
                                                className="ml-4  border-1-s type-button-fix btn btn-light shadow-none"
                                            >
                                                <Link
                                                    style={{color: '#000'}}
                                                    to={{
                                                        pathname: "/admin/service-plan-management",
                                                    }}
                                                >
                                                    Hủy bỏ
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showCreateChargePackage && <ModalCreateChargePackage
                    show={this.state.showCreateChargePackage}
                    setData={{}}
                    onHide={() => this.setState({showCreateChargePackage: false})}
                    create={(item) => this.createChargePackage(item)}
                    // onRefresh={() => this.getListSupOnSearch()}
                />}

                {this.state.showCreateBill && <ModalCreateBill
                    show={this.state.showCreateBill}
                    // type='commerce'
                    onHide={() => this.setState({showCreateBill: false})}
                    create={this.createBill}
                    // onRefresh={() => this.()}
                />}
                {this.state.showCreateCertification && <ModalCreateCertification
                    show={this.state.showCreateCertification}
                    // type='commerce'
                    onHide={() => this.setState({showCreateCertification: false})}
                    create={this.createDocument}
                    // onRefresh={() => this.()}
                />}
                {this.state.showCreateEmployee && <ModalCreateEmployee
                    show={this.state.showCreateEmployee}
                    // type='commerce'
                    onHide={() => this.setState({showCreateEmployee: false})}
                    create={this.createEmployee}
                    // onRefresh={() => this.()}
                />}

            </div>
        )
    }

}

export default CreateServicePlan
