import React from 'react';
import {stringify} from 'qs';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import ModalDetailOrder from "../../../components/Modal/modalDetailOrder";
import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../services/ob-user/supervisor";
import PublicOrderApi from "../../../services/ob-core/public-order-service";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import {toast} from "react-toastify";
import PartnerApi from "../../../services/ob-user/partner";
import VotingApi from "../../../services/ob-core/voting";
import AccountantApi from "../../../services/ob-user/accountant";

class RatingManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            orderCode: null,
            sort: null,
            status: "",
            status_temp: '',
            listCustomerId: [],
            listSupervisorId: [],
            modalDetailOrder: false,
            modalAssignOrder: false,
            selectedId: '',
            clearSearch: false,
            supervisorSuggestions: [],
            supervisor_temp: '',
            supervisorList: [],
            accountantList: [],
            servicePlan: [],
            servicePlanId: null,
            servicePlanId_temp: null,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Khách hàng",
                    style: {textAlign: "center"},
                    sort: false
                },
                //
                // {
                //     id: "value",
                //     name: "Giá trị hợp đồng (VND)",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "supervisorId",
                    name: "Supervisor",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "supervisorRating",
                    name: "Đánh giá Supervisor",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "accountantId",
                    name: "Kế toán",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "accountantRating",
                    name: "Đánh giá kế toán",
                    style: {textAlign: "center"},
                    sort: true
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false
                }
            ]
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListRating()
            // this.getOrderList();
            // this.getListSupervisor()
            // this.getServicePlan()
        })

    }

    onhandleSearch = () => {
        let {code_temp, status_temp, servicePlanId_temp, selectedSupervisor, supervisorId_temp, selectedAcountant, acountantId_temp} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0,
                supervisorId: selectedSupervisor ? selectedSupervisor.id : supervisorId_temp,
                acountantId: selectedAcountant ? selectedAcountant.id : acountantId_temp
            },
            () => {
                this.getListRating();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                sort: '',
                orderCode: "",
                status: "",
                status_temp: "",
                servicePlanId: null,
                // servicePlanId_temp: '',
                selectedSupervisor: null,
                selectedAcountant: null,
                supervisorName: '',
                supervisorId: null,
                acountantId: null,
                clearSearch: true,
            },
            () => this.getListRating()
        );
    }

    getListRating = () => {
        let self = this;
        let {supervisorId, pageNumber, pageSize, acountantId, sort} = this.state
        let payload = {
            assignerId: supervisorId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            receiverId: acountantId,
            sort: sort
        }
        VotingApi.getListBySupAndAcc(payload, res => {
            if (res.success) {
                let listCustomerId = [];
                let listSupervisorId = [];
                let listAccountantId = [];
                let data = res.data.content
                data.map(item => {
                    listCustomerId.push(item.customerId);
                    listSupervisorId.push(item.assignerId);
                    listAccountantId.push(item.receiverId);
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                    listSupervisorId,
                    listAccountantId,
                    showLoading: false
                }, () => {
                    if (data.length > 0) {
                        self.getListCustomerByIds();
                        self.getListSupervisorByIds();
                        self.getListAccountantByIds();
                    }
                })
            }else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!" +  res.message)
            }
        })
    }

    // getOrderList = () => {
    //     let self = this;
    //     let payload = {
    //         pageNumber: this.state.pageNumber,
    //         pageSize: this.state.pageSize,
    //         orderCode: this.state.orderCode,
    //         sort: this.state.sort,
    //         status: this.state.status,
    //         supervisorId: this.state.supervisorId,
    //         servicePlanId: this.state.servicePlanId,
    //     }
    //     PublicOrderApi.searchBySupervisorIdServicePlanIdAndStatus(payload, res => {
    //         if (res.success) {
    //             let listCustomerId = [];
    //             let listSupervisorId = [];
    //             let listPartnerId = [];
    //             let data = res.data.content
    //             data.map(item => {
    //                 listCustomerId.push(item.customerId);
    //                 listSupervisorId.push(item.supervisorId);
    //                 listPartnerId.push(item.partnerId);
    //             });
    //             this.setState({
    //                 listData: res.data.content,
    //                 totalItem: res.data.totalElements,
    //                 listCustomerId,
    //                 listSupervisorId,
    //                 listPartnerId,
    //                 showLoading: false
    //             }, () => {
    //                 if (data.length > 0) {
    //                     self.getListCustomerByIds();
    //                     self.getListSupervisorByIds();
    //                     self.getListPartnerByIds();
    //                 }
    //             })
    //         } else {
    //             toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
    //         }
    //     }).catch(err => {
    //
    //     })
    // }

    getListCustomerByIds = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                    item.customerPhone = filter[0]?.phone;
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    getListSupervisorByIds() {
        let {listSupervisorId, listData} = this.state;

        let payload = {
            ids: listSupervisorId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.assignerId);
                    item.supervisorName = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }


    getListAccountantByIds = () => {
        let {listData, listAccountantId} = this.state;
        let payload = {
            ids: listAccountantId
        };

        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let dataTemp = res.data;
                listData.map(item => {
                    let filter = dataTemp.filter(x => x.id == item.receiverId);
                    item.accountantName = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {

            }
        });
    }

    showModalAssignOrder = item => {
        this.setState({selectedId: item.id, modalAssignOrder: true});
    };


    renderStatus(status) {
        switch (status) {
            case "WAITING_FOR_CONFIRMATION":
                return <span className="badge badge-secondary">Chờ xác nhận</span>;
            case "PROCESSING":
                return <span className="badge badge-primary">Đang thực hiện</span>;
            case "REJECTED":
                return <span className="badge badge-warning">Đã từ chối</span>;
            case "CONFIRMED":
                return <span className="badge badge-success">Đã xác nhận</span>;
            case "CANCELED":
                return <span className="badge badge-danger">Đã hủy</span>;
            case "CREATED_CONTRACT":
                return <span className="badge badge-info">Đã tạo hợp đồng</span>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getOrderList();
            }
        );
    }

    showModalDetailOrder = () => {
        this.setState({modalDetailOrder: true})
    }

    getListAccountant = () => {
        let payload = {
            fullName: this.state.supervisorName,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                this.setState({supervisorList: res.data.content})
            }
        })
    }

    getListSupervisor = () => {
        let payload = {
            fullName: this.state.supervisorName,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                this.setState({supervisorList: res.data.content})
            }
        })
    }

    onChangeSupervisor = (event, {newValue}) => {
        this.setState({
            supervisor_temp: newValue
        });
    };

    getServicePlan = () => {
        PublicServicePlanApi.getAll(res => {
            if (res.success) {
                this.setState({servicePlan: res.data})
            }
        })
    }

    render() {
        let {listData, pageNumber, header, supervisorSuggestions, supervisor_temp, servicePlan} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="rating-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-4">
                                    <PageHeader routerEnable={true} title="Danh sách đánh giá"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-4 pl-0">
                                                <label className="label-item">Supervisor</label>
                                                <Autosuggestion
                                                    label="Người phụ trách"
                                                    placeholder="Nhập tên người phụ trách"
                                                    // value={this.state.contractCode}
                                                    type="text"
                                                    data={this.state.supervisorList}
                                                    onSelect={(item) => this.setState({selectedSupervisor: item})}
                                                    onChange={(val) => {
                                                        this.setState({supervisorName: val, selectedSupervisor: null, clearSearch: false})
                                                        setTimeout(() => {
                                                            this.getListSupervisor()
                                                        }, 300);
                                                    }}
                                                    clearSearch={this.state.clearSearch}
                                                    checkError={!this.state.supervisorList}
                                                    errorContent="Vui lòng chọn một Supervisor"
                                                />
                                            </div>
                                            <div className="col-4">
                                                <label className="label-item">Kế toán</label>
                                                <Autosuggestion
                                                    label="Kế toán"
                                                    placeholder="Nhập tên kế toán"
                                                    // value={this.state.contractCode}
                                                    type="text"
                                                    data={this.state.accountantList}
                                                    onSelect={(item) => this.setState({selectedAcountant: item})}
                                                    onChange={(val) => {
                                                        this.setState({accountantName: val, selectedAccountant: null, clearSearchAcc: false})
                                                        setTimeout(() => {
                                                            this.getListAccountant()
                                                        }, 300);
                                                    }}
                                                    clearSearch={this.state.clearSearch}
                                                    checkError={!this.state.accountantList}
                                                    errorContent="Vui lòng chọn một kế toán"
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-2 align-text-bottom pl-0 button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onhandleSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onHandleClearText()}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    console.log(data);
                                                    let a = "";
                                                    if (data.sortAsc == null) {
                                                        a = "id=asc";
                                                    } else {
                                                        a = `${data.sortId}=${
                                                            data.sortAsc ? "asc" : "desc"
                                                        }`;
                                                    }
                                                    console.log(a);
                                                    this.setState(
                                                        {
                                                            sort: a
                                                        },
                                                        () => this.getListContractFromAPI()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData ||
                                            this.state.listData.length == 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="text-center">
                                                            Không có dữ liệu
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData &&
                                                listData.length > 0 &&
                                                listData?.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            {row.code}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row?.customerName}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.supervisorName}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.reviewSupervisorComment}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.accountantName}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.reviewAccountantComment}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xem chi tiết"
                                                                onClick={() => {
                                                                    this.setState({selectedOrder: row}, () => {
                                                                        this.showModalDetailOrder()
                                                                    })
                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default RatingManagement
