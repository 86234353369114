import React, {Component} from 'react';
import {withSnackbar} from "notistack";
import './index.css'

import ContractApi from '../../../services/ob-core/public-contract';
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";

class ModalReject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalReject: true,
            idSelect: this.props.itemId,
            reason: ''
        }
    }

    handleRejectContract = () => {
        let {idSelect, reason} = this.state
        let payload = {
            id: idSelect,
            body: {
                rejectReason: reason
            }
        }
        ContractApi.reject(payload, res => {
            if (res.success === true) {
                toast.success("Từ chối thành công !")
                this.setState({
                    showModalReject: false
                });
                this.props.onRefresh()
            } else {
                // console.log('err: ', res);
               toast.error("Đã có lỗi xảy ra! vui lòng thử lại sau !")
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        {/*<div className='d-flex justify-content-center mt-3 mb-3'>*/}
                        {/*    <span className='font-size-content'> Bạn có chắc chắn nhận hợp đồng {this.state.idContractSelect} không?</span>*/}
                        {/*</div>*/}
                        <div className="modal-body text-center mt-3 mb-3">
                            <label className="lableModal">Bạn cần lý do trước khi từ chối nhận hợp đồng:</label>
                            <textarea
                                rows={5}
                                className="form-control"
                                placeholder={'Nhập lý do'}
                                maxLength={100}
                                value={this.state.reason}
                                onChange={(event) => {
                                    this.setState({reason: event.target.value})
                                }}
                            >
                                </textarea>
                        </div>

                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    onClick={() => this.handleRejectContract()}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Xác nhận
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                    onClick={this.props.onHide}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Hủy bỏ
                                    </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    // render() {
    //     let {reason} = this.state
    //     return (
    //         <div>
    //             <div className="modal fade show d-block" role="dialog">
    //                 <div className="modal-dialog modal-dialog-centered" role="document">
    //                     <div className="modal-content">
    //                         <div className="modal-header border-0">
    //                             <h5 className="modal-title ml-4 mt-2">Thông báo</h5>
    //                             <button onClick={this.props.onHide} type="button" className="close" data-dismiss="modal"
    //                                     aria-label="Close">
    //                                 <span aria-hidden="true">&times;</span>
    //                             </button>
    //                         </div>
    //                         <div className="modal-body text-center">
    //                             <label className="lableModal">Bạn cần lý do trước khi từ chối nhận hợp đồng:</label>
    //                             <textarea
    //                                 rows={5}
    //                                 className="form-control"
    //                                 placeholder={'Nhập lý do'}
    //                                 maxLength={250}
    //                                 value={reason}
    //                                 onChange={(event) => {
    //                                     this.setState({reason: event.target.value})
    //                                 }}
    //                             >
    //                             </textarea>
    //                         </div>
    //                         <div className="modal-footer border-0 justify-content-center">
    //                             <button disabled={this.state.reason.length == 0} type="button"
    //                                     className="btn btn-primary btn-sm mr-5 buttonModal"
    //                                     onClick={() => this.handleRejectContract()}
    //                             >
    //                                 Xác nhận
    //                             </button>
    //                             <button onClick={this.props.onHide} type="button"
    //                                     className="btn btn-light btn-sm border-1-s buttonModal">Hủy
    //                             </button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
}

export default withSnackbar(ModalReject);
