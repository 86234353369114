import React from 'react';
import {toast} from "react-toastify";
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';

import PublicOrderApi from "../../services/ob-core/public-order-service";

class ModalDeleteOrderedService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailId: '',
            reasonReject: '',
        };
    }

    componentDidMount() {
        this.setState({
            reasonReject: ''
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                reasonReject: '', detailId: this.props.data?.id
            });
        }
    }


    reject = () => {
        if (this.state.reasonReject === '') {
            toast.error('Bạn chưa nhập lý do hủy dịch vụ');
        } else {
            let payload = {
                id: this.props.order,
                body: {
                    rejectReason: "Khách hủy: "+this.state.reasonReject
                }
            };
            PublicOrderApi.cancel(payload, res => {
                if (res.success === true) {
                    this.props.onRefresh();
                    toast.success(`Hủy dịch vụ "${this.props.selectedOrder.code}" thành công`)
                } else {
                    this.props.onHide();
                    toast.error('Hủy dịch vụ không thành công');
                }
            });
        }
    }

    render() {
        let {pageNumber, header} = this.state;
        let self = this;
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        <h1 className="text-center" style={{fontSize: 16,}}><b>Vui lòng nhập lý do hủy dịch vụ</b></h1>
                        <div>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập lý do ...'}
                                maxLength={200}
                                value={this.state.reasonReject}
                                onChange={(e) => {
                                    self.setState({reasonReject: e.target.value.trimLeft()})
                                }}
                            />
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s background-btn w-60 type-button-fix btn btn-primary"
                                        onClick={() => this.reject()}
                                        disabled={this.state.reasonReject.length === 0}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s width-btn type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                           Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDeleteOrderedService
