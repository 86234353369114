import React from 'react'
import Convert from "../../../../utils/convertUrlPra";
import {Link} from "react-router-dom";
import TableHeader from '../../../../components/TableHeader';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import ModalAddService from '../../../../components/Modal/admin/modaAddService';
import AdminProgramManager from "../../../../services/ob-core/admin-program-manager";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import { createHashHistory } from 'history'
import moment from "moment";
import DatePicker from "react-datepicker";

class EditProgram extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            name: "",
            des: "",
            discountType: 1,
            discountValue: 0,
            status: "",
            numberOfInvoicesConstraintId: 0,
            numberOfEmployeesConstraintId: 0,
            numberOfDocumentsConstraintId: 0,
            numberOfMonths: 6,
            serviceList: [],
            header_program: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên gói dịch vụ',
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'category',
                    name: "Loại gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: true,
                }
            ],
        };
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({programId: params.programId}, () => this.getDetail())
    }
    getDetail = () => {
        let payload = {
            id: this.state.programId
        };
        AdminProgramManager.getDetail(payload, res => {
            if (res.success) {
                this.setState(res.data)
                this.setState({serviceList: res.data.programServiceResponseList})
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }
    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };
    createChargePackage = (listService) => {
        this.setState({showListServicePackage: false});
        this.setState({serviceList: listService});
    }

    onUpdate = () => {
        let {discountType, discountValue, endDate, startDate, name, des, status, numberOfInvoicesConstraintId, numberOfEmployeesConstraintId, numberOfDocumentsConstraintId, numberOfMonths, serviceList} = this.state;
        if (name == "" || name.length < 6){
            toast.error("Tên chương trình phải lớn hơn 6 ký tự");
        }else if (des == "" || des.length < 6){
            toast.error("Mô tả chương trình phải lớn hơn 6 ký tự");
        }else if (startDate == null || endDate == null){
            toast.error("Vui lòng chọn ngày bắt đầu và kết thúc chương trình");
        }else if (startDate > endDate){
            toast.error("Ngày kết thúc phải lớn hơn ngày bắt đầu");
        }else if (serviceList.length < 1){
            toast.error("Vui lòng chọn dịch vụ!");
        }else if (discountValue < 0){
            toast.error("Giá trị giảm phải lớn hơn hoặc bằng 0");
        }else {
            let listData = serviceList.map((data) => ({"servicePlanId": data.id, "servicePlanName": data.servicePlanName, "servicePlanType": data.type, "programId": parseFloat(this.state.programId)}));
            let payload = {
                programId: parseFloat(this.state.programId),
                name: name,
                des: des,
                discountType: discountType,
                discountValue: discountValue,
                startDate: startDate,
                endDate: endDate,
                numberOfInvoicesConstraintId: numberOfInvoicesConstraintId,
                numberOfEmployeesConstraintId: numberOfEmployeesConstraintId,
                numberOfDocumentsConstraintId: numberOfDocumentsConstraintId,
                numberOfMonths: numberOfMonths,
                createProgramServiceList: listData,
            }
            AdminProgramManager.edit(payload, res => {
                if (res.success){
                    this.props.history.push("/admin/program-management")
                    toast.success("Tạo chương trình thành công!")
                } else {
                    toast.error("Đã xảy ra lỗi! " + res.message)
                }
            })
        }
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="program-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Quản lý chương trình"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <h2 className='text-center mb-4'>SỬA CHƯƠNG TRÌNH</h2>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Tên chương trình</label>
                                                <input
                                                    maxLength={255} type="text"
                                                    className="form-control shadow-none"
                                                    value={this.state.name}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            name: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Mô tả chương trình</label>
                                                <div className="form-control w-100 h-100 p-0">
                                                    <textarea
                                                        value={this.state.des}
                                                        rows={5}
                                                        className="form-control border-0 shadow-none p-2"
                                                        style={{resize: 'none', fontSize: 14}}
                                                        maxLength={1000}
                                                        onChange={(e) => {
                                                            this.setState({des: e.target.value})
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Loại giảm giá</label>
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={this.state.discountType}
                                                    onChange={(e) => {
                                                        this.state.discountType = e.target.value;
                                                        this.setState({
                                                            discountType: e.target.value,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >
                                                    <option value="1">Giảm giá theo phần trăm(%)</option>
                                                    <option value="2">Giảm giá theo giá trị</option>
                                                </select>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Giá trị giảm</label>
                                                <input
                                                    min={0}
                                                    className="form-control shadow-none"
                                                    value={this.state.discountValue}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            discountValue: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Ngày bắt đầu</label>
                                                <DatePicker
                                                    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Từ ngày"
                                                    selected={this.state.startDate === null ? "" : new Date(this.state.startDate)}
                                                    minDate={new Date()}
                                                    dateFormat="dd-MM-yyyy"
                                                    isClearable={this.state.startDate === null ? false : true}
                                                    selectsStart
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={
                                                        (date) => {
                                                            this.setState({
                                                                startDate: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                            })
                                                        }
                                                    }
                                                    startDate={new Date()}
                                                />
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Ngày kết thúc</label>
                                                <DatePicker
                                                    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Đến ngày"
                                                    selected={this.state.endDate === null ? "" : new Date(this.state.endDate)}
                                                    minDate={new Date()}
                                                    dateFormat="dd-MM-yyyy"
                                                    isClearable={this.state.endDate === null ? false : true}
                                                    selectsStart
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={
                                                        (date) => {
                                                            this.setState({
                                                                endDate: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                            })
                                                        }
                                                    }
                                                    endDate={new Date()}
                                                />
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-12 label-item pl-0" >Điều kiện tham gia chương trình(Nếu không cần thì để trống)</label>
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số hóa đơn</label>
                                                <input
                                                    max={10000} type="number"
                                                    min={0}
                                                    className="form-control shadow-none"
                                                    value={this.state.numberOfInvoicesConstraintId}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            numberOfInvoicesConstraintId: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Tên chứng từ</label>
                                                <input
                                                    max={10000} type="number"
                                                    min={0}
                                                    className="form-control shadow-none"
                                                    value={this.state.numberOfDocumentsConstraintId}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            numberOfDocumentsConstraintId: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số nhân sự</label>
                                                <input
                                                    max={10000} type="number"
                                                    min={0}
                                                    className="form-control shadow-none"
                                                    value={this.state.numberOfEmployeesConstraintId}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            numberOfEmployeesConstraintId: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số kỳ</label>
                                                <input
                                                    max={100} type="number"
                                                    min={0}
                                                    className="form-control shadow-none"
                                                    value={this.state.numberOfMonths}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            numberOfMonths: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <div>
                                        <div className="col-12 d-flex justify-content-end align-right mb-2 pr-0">
                                            <button
                                                type="button"
                                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                                onClick={() => {
                                                    this.setState({showListServicePackage: true})
                                                }}
                                            >
                                                Thêm Gói Dịch Vụ
                                            </button>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <TableHeader header={this.state.header_program}
                                                             clickSort={(data) => {
                                                             }}/>
                                                {!this.state.serviceList || this.state.serviceList.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.serviceList?.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.servicePlanName}
                                                            </td>
                                                            <td style={{textAlign: 'left'}}>
                                                                {this.renderType(row.type)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="align-right col-12">
                                    <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                        <button
                                            type="button"
                                            className="ml-4 col-1 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                            disabled={this.state.serviceList.length < 1 || this.state.name == "" || this.state.des == "" || this.state.startDate == null || this.state.endDate == null}
                                            onClick={()=> {
                                                this.onUpdate()
                                            }}
                                        >
                                            Lưu
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-4  border-1-s type-button-fix btn btn-light shadow-none"
                                        >
                                            <Link
                                                style={{color: '#000'}}
                                                to={{
                                                    pathname: "/admin/program-management",
                                                }}
                                            >
                                                Hủy bỏ
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showListServicePackage && <ModalAddService
                    show={this.state.showListServicePackage}
                    data={this.state.serviceList}
                    onHide={() => this.setState({showListServicePackage: false})}
                    create={(listService) => this.createChargePackage(listService)}
                />}
            </div>
        )
    }

}

export default EditProgram
