import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
import {
    Modal,
} from 'react-bootstrap';

import AdminUserManagement from "../../../services/ob-core/admin-user-management";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Utils from "../../../utils/utils";
import RoleApi from "../../../services/ob-core/admin-role";

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

class ModalDetailAdminUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            listRole: [],
            email:'',
            permissionId:'',
            emailErr:false,
        };
    }

    componentDidMount() {
        this.getDetail();
        this.getListRole()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.getDetail()
        }
    }

    getDetail = () => {
        let payload = {
            id: this.props.id
        };
        AdminUserManagement.detail(payload, res => {
            console.log('sup:', res)
            if(res.success) {
                this.setState({
                    listData: res.data,
                },
                    () => this.setState({email: this.state.listData.email,permissionId: this.state.listData.permissionId,},
                        console.log('checkemail', this.state.email)
                        )
                )
            }
        })
    }

    getListRole = () => {
        let{page, pageSize, roleSearch} = this.state;
        let payload = {
            pageNumber: page,
            pageSize: pageSize,
            roleName: roleSearch,
        };
        RoleApi.getList(payload, res => {
            console.log('sup:', res)
            if(res.success) {
                this.setState({
                    listRole: res.data.content,
                })
            }
        })
    }


    update = () => {
        let payload = {
            id: this.props.id,
            body: {
                email: this.state.email,
                permissionId: this.state.permissionId,
            }
        };
        AdminUserManagement.update(payload, res => {
            console.log('sup:', res)
            if(res.success) {
                toast.success('Chỉnh sửa thành công !', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                this.props.onHide();
                this.props.onRefresh();
            }else{
                toast.error('Có lỗi xảy ra, vui lòng thử lại sau !', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }
        })
    }

    renderPermission = (item) => {
        switch (item) {
            case 1:
                return 'Admin';
            case 2:
                return 'Partner';
            case 3:
                return 'Supervisor';
            case 4:
                return 'Accountant';
            case 5:
                return 'Customer';
        }
    }

    renderStatus = (item) => {
        switch (item) {
            case 'WAIT_ACTIVE':
                return 'Chờ kích hoạt';
            case 'WAIT_COMPLETE_INFO':
                return 'Chờ hoàn thiện thông tin';
            case 'ACTIVE':
                return 'Hoạt động';
            case 'LOCK':
                return 'Khoá';
            case 'CANCELED':
                return 'Đã huỷ';
        }
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };

        let {listData} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-container">

                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>CHỈNH SỬA THÔNG TIN</b></h1>
                        </div>

                        <div className='mt-5 mb-1 ml-2 mr-2'>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Tên </label>
                                <label className="col-7 mt-2">{listData?.fullName}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số điện thoại </label>
                                <label className="col-7 mt-2">{listData?.phone} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Email </label>
                                <label className="col-7 mt-2">{listData?.email} </label>
                                {/* <input
                                    type="email"
                                    className={this.state.emailErr?"form-control ml-3 w-100 col-5 kt-input pd-4-input shadow-none border-input-error":"form-control w-100 ml-3 col-5 kt-input pd-4-input shadow-none"}
                                    placeholder='Nhập email'
                                    fullWidth
                                    maxLength={100}
                                    value={this.state.email}
                                    onChange={(e)=> {
                                        if (Utils.checkEmailRegex(e.target.value.trim())){
                                            // khoong dudng dinh dang
                                            this.setState({email: e.target.value.trim(), emailErr: true, btnStatus: true})
                                        }else{
                                            this.setState({email: e.target.value.trim(),emailErr: false, btnStatus: false})
                                        }
                                    }}
                                /> */}
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">CMND/CCCD </label>
                                <label className="col-7 mt-2">{listData?.identify} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Địa chỉ </label>
                                <label className="col-7 mt-2">{listData?.address}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Quận/Huyện </label>
                                <label className="col-7 mt-2">{renderDistrictId(listData?.districtId)}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Tỉnh/Thành phố</label>
                                <label className="col-7 mt-2">{renderProvinceId(listData?.provinceId)} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhóm quyền </label>
                                {/*<label className="col-7 mt-2">{this.renderPermission(listData?.permissionId)}</label>*/}
                                <select
                                    className="form-control ml-3 col-5 kt-input pd-4-input shadow-none"
                                    value={this.state.permissionId}
                                    onChange={(e) => {
                                        this.setState({
                                            permissionId: e.target.value
                                        })
                                    }}>
                                    {this.state.listRole.map(_item => {
                                        return (
                                            <option value={_item.id}>{_item.nameRole}</option>
                                        )
                                    })}

                                </select>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Trạng thái </label>
                                <label className="col-7 mt-2">{this.renderStatus(listData?.status)} </label>
                            </div>

                            <div className=" align-right col-12">
                                <div className="d-flex justify-content-center align-center mt-3">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus }
                                        onClick={() => this.update()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalDetailAdminUser;
