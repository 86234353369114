import React from 'react';
import {toast} from "react-toastify";
import "./Modal.css"
import iconLoading from '../../assets/images/loading-icon.gif'
import {
    Modal,
} from 'react-bootstrap';
import SubUserApi from "../../services/ob-user/sub-user";

class ModalConfirmToSub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            btnStatus: true
        };
    }

    componentDidMount() {
        this.setState({
            btnStatus: true
        })
    }

    onConfirm = () => {
        this.setState({
            btnStatus: false
        })
        let body = this.props.data;
        body.password = '12345678@';
        let payload = {
            id: this.props.data.id,
            body
        }
        SubUserApi.createSubUser(payload, res => {
            if (res.success) {
                toast.success(`Tạo tài khoản dự bị "${this.props.data.fullName}" thành công!`)
                this.props.onRefresh();
                this.props.onHide()
            } else {
                toast.error("Thao tác thất bại. Vui lòng thử lại!")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='m-2'>
                            <label>Bạn có muốn chắc chắn muốn tạo tại khoản dự bị không ?</label>

                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className="align-center mt-3 mb-2">
                                    {this.state.btnStatus ? <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary shadow-none "
                                        onClick={() => this.onConfirm()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    : <button
                                            type="button"
                                            style={{cursor: 'unset'}}
                                            className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-primary shadow-none "
                                        >
                                        <span className='d-flex justify-content-center'>
                                            <img src={iconLoading} alt='loading'/>
                                        </span>
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none "
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalConfirmToSub;

