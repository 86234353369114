import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Utils from  '../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import AccountantApi from "../../services/ob-user/accountant";

class ModalAddAccountant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            phone:'',
            email:'',
            contract:'',
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        };
    }
    componentDidMount() {
        this.setState({
            name:'',
            phone:'',
            email:'',
            contract:'',
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                name:'',
                phone:'',
                email:'',
                contract:'',
                nameErr: false,
                phoneErr: false,
                emailErr: false,
                contractErr: false,
                btnStatus: false,
            });
        }
    }

    onSubmit =()=>{
        if(this.state.name.length  == 0 && this.state.phone.length == 0 && this.state.email.length == 0 && this.state.contract.length == 0){
            this.setState({
                nameErr: true,
                phoneErr: true,
                emailErr: true,
                contractErr: true,});
            this.props.enqueueSnackbar('Không được để trống thông tin !', {
                variant: 'error',
            });
        }else if(this.state.name.length  == 0 ){
            this.setState({nameErr: true});
            this.props.enqueueSnackbar('Không được để trống họ và tên !', {
                variant: 'error',
            });
        }else if(this.state.phone.length <10 || this.state.phone.length == 0){
            this.setState({phoneErr: true});
            this.props.enqueueSnackbar('Số điện thoại sai định dạng hoặc đang để trống !', {
                variant: 'error',
            });
        }else if(this.state.email.length == 0 ){
            this.setState({emailErr: true});
            this.props.enqueueSnackbar('Email đang để trống !', {
                variant: 'error',
            });
        }else if(this.state.contract.length == 0 || this.state.contract == '00' || this.state.contract == '0' ){
            this.setState({contractErr: true});
        }else {
            this.addAccountant()
        }
    }

    addAccountant = () => {
        let payload = {
            email: this.state.email.toLowerCase(),
            fullName: this.state.name,
            maxContract: this.state.contract,
            password: '12345678@',
            phone: this.state.phone
        }
        AccountantApi.supervisorCreate(payload, res => {
            if (res.errorCode == "One-Book-Auth.E000003") {
                this.props.enqueueSnackbar(res.message[0], {
                    variant: 'error',
                });
            }
            else if (res.success) {
                this.props.enqueueSnackbar('Tạo mới kế toán thành công !', {
                    variant: 'success',
                });
                this.props.onRefresh();
                this.props.onHide();
            }
            else  {
                return this.props.enqueueSnackbar('Tạo mới không thành công, vui lòng thử lại !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>TẠO MỚI KẾ TOÁN</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2">Họ tên </label>
                                <input
                                    className={this.state.nameErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder="Nhập vào họ tên"
                                    maxLength={50}
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.name.length == 0 ? this.setState({nameErr: true, btnStatus: true}): this.setState({nameErr: false, btnStatus: false})
                                        }
                                        )}
                                />
                            </div>
                            {this.state.nameErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Tên đang để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.phoneErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Tên đăng nhập </label>
                                <input
                                    className={this.state.phoneErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6 "}
                                    placeholder="Nhập vào SĐT"
                                    fullWidth
                                    maxLength={11}
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () =>{
                                            this.state.phone.length <10 ? this.setState({phoneErr: true, btnStatus: true}): this.setState({phoneErr: false, btnStatus: false})
                                        }

                                    )}
                                />
                            </div>
                            {this.state.phoneErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">SĐT không đúng hoặc đang để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.emailErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Email</label>
                                <input
                                    // type="email"
                                    className={this.state.emailErr?"inputPassword w-100 col-6 border-input-error ":"inputPassword w-100 col-6 "}
                                    placeholder='Nhập Email'
                                    fullWidth
                                    // value={this.state.email}
                                    // onChange={(e) => this.setState({email: e.target.value.trim()},
                                    //     () =>{
                                    //         console.log('CHECKEMAIL>>>>>>',this.state.email);
                                    //         this.state.email.length == 0 ? this.setState({emailErr: true, btnStatus: true}): this.setState({emailErr: false, btnStatus: false})
                                    //     })}
                                    onChange={(e)=> {
                                        if (Utils.checkEmailRegex(e.target.value.trim())){
                                            // khoong dudng dinh dang
                                            this.setState({emailErr: true, btnStatus: true})
                                        }else{
                                            this.setState({email: e.target.value.trim(),emailErr: false, btnStatus: false})
                                        }
                                    }}
                                />
                            </div>
                            {this.state.emailErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Email không đúng hoặc đang để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.contarctErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Số hợp đồng phụ trách</label>
                                <input
                                    className={this.state.contractErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder='Số hợp đồng'
                                    fullWidth
                                    maxLength={2}
                                    value={this.state.contract}
                                    onChange={(e) => this.setState({contract: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () =>{
                                            this.state.contract.length == 0
                                                || this.state.contract == '00'
                                                || this.state.contract =='0' ? this.setState({contractErr: true, btnStatus: true}): this.setState({contractErr: false, btnStatus: false})
                                        })}
                                />
                            </div>
                            {this.state.contractErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Số hợp đồng không đúng hoặc đang để trống !</label>
                            </div>:''}

                            <div className="row">
                                <label className="col-6 mt-3">Mật khẩu</label>
                                <label className="col-6 mt-3">12345678@</label>
                            </div>
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-2 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Lưu
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-2 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAddAccountant);
