import React from 'react'
import {Button, Card, Table, Form, Col, Row} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";

import ModalAddMoney from "../../../components/Modal/admin/modalAddMoneyPartnerWallet"
import PartnerApi from "../../../services/ob-user/partner";
import {toast} from "react-toastify";
import {checkPermission} from "../../../utils/common";

const dateFormat = 'DD-MM-YYYY';
class PartnerWalletManagement extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            partner: null,
            status: null,
            sort: '',
            partner_temp: null,
            status_temp: null,
            showModalAddMoney: false,
            selectedWallet: [],
            showLoading: false,
            namePartner: "",
            idPartner: "",
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên Partner",
                    style: {textAlign: "center"},
                    sort: true,
                },
                // {
                //     id: 'numberAccount',
                //     name: "Tài khoản ví",
                //     style: {textAlign: "center"},
                //     sort: false,
                // },
                {
                    id: 'balanceAccount',
                    name: "Số dư ví (VND)",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'walletStatus',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },

                {
                    id: '',
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListWallet()
        })
    }

    onSearch = () => {
        this.setState({
            partner: this.state.partner_temp,
            status: this.state.status_temp,
        }, () => this.searchListWallet())
    }

    searchListWallet = () => {
        let {partner, status, pageNumber, pageSize, sort} = this.state
        let payload = {
            fullName: partner,
            walletStatus: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
        }
        PartnerApi.adminGetPartnerWallet(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                }, () => {
                    if (res.data.length < 1) this.setState({
                        showLoading: false
                    })
                })
            } else {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra, vui long tải lại trang!"))
            }
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListWallet()
            }
        );
    }

    renderType = (transactionType) => {
        switch (transactionType) {
            case 'ACTIVE':
                return <span className="badge badge-success">Đang hoạt động</span>;
            case 'CANCELED':
                return <span className="badge badge-danger">Đã hủy</span>;
            default:
                return 'Chưa rõ';
        }
    };

    handleShowModalAddMoney = (data) => {
        this.setState({
            namePartner: data.fullName,
            idPartner: data.id,
            showModalAddMoney: true
        })
    }


    render() {
        let {pageNumber, pageSize}  = this.state
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="partner-wallet-management" parentClass="wallet"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý ví Partner"/>
                                    <div className="row">
                                        <div className='col-6 pl-0'>
                                            <label className="label-item">Partner</label>
                                            <input
                                                maxLength={20} type="text"
                                                className="form-control shadow-none"
                                                placeholder="Nhập vào tên Partner"
                                                value={this.state.partner_temp}
                                                onKeyUp={(e) => {
                                                    if (e.key === 'Enter') this.getListWalletOnSearch()
                                                }}
                                                onChange={(event) => {
                                                    this.setState({
                                                        partner_temp: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-6 pr-0'>
                                            <label className="label-item">Trạng thái</label>
                                            <select
                                                className="form-control kt-input pd-4-input shadow-none"
                                                value={this.state.status_temp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        status_temp: e.target.value
                                                    })
                                                }}
                                            >
                                                <option value=''>Tất cả</option>
                                                <option value='ACTIVE'>Đang hoạt động</option>
                                                <option value='CANCELED'>Đã xóa</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            onClick={() => this.onSearch()}
                                        >
                                            Tìm kiếm
                                        </button>
                                        <button type="button"
                                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                onClick={() => {
                                                    this.setState({
                                                        status_temp: '',
                                                        partner_temp: '',
                                                        fullName: '',
                                                        partner: "",
                                                        status: "",
                                                        walletStatus: null,
                                                        pageNumber: 0,
                                                        pageSize: 10,
                                                        sort: '',
                                                    }, () => this.searchListWallet())
                                                }}
                                        >
                                            Refresh
                                        </button>
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        this.setState({*/}
                                        {/*            showModalAddMoney: true*/}
                                        {/*        })*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    Thêm mới*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                {/*        <Table striped bordered hover>*/}
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            // console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListWallet())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    {/*<td style={{textAlign: 'left'}}>*/}
                                                    {/*    {row.numberAccount || "Không có thông tin"}*/}
                                                    {/*</td>*/}
                                                    <td style={{textAlign: 'right'}}>
                                                        <NumberFormat
                                                            value={row.balanceAccount}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            // suffix={" VND"}
                                                        />
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderType(row.walletStatus)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {checkPermission("detail_partner_wallet") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem chi tiết"
                                                            onClick={() => {
                                                                this.setState({selectedWallet: row}, () => {

                                                                })
                                                            }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        }
                                                        {checkPermission("recharge_partner_wallet") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Thêm"
                                                            onClick={() => this.handleShowModalAddMoney(row)}
                                                        >
                                                            <i className="ti-plus"/>
                                                        </button>
                                                        }
                                                        {checkPermission("delete_partner_wallet") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                this.setState({selectedWallet: row}, () => {

                                                                })
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAddMoney
                    show={this.state.showModalAddMoney}
                    onRefresh={() => this.onSearch()}
                    idPartner={this.state.idPartner}
                    namePartner={this.state.namePartner}
                    onHide={() => this.setState({showModalAddMoney:false})}
                />
            </div>
        )
    }

}

export default PartnerWalletManagement
