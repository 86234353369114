import React from 'react'
import { Link, Switch, Route, Redirect } from "react-router-dom";
import Profile from './profile'
import CompleteProfile from './complete-profile'
import ServicePlan from "./service-plan/index";
import ServicePlan_Advance from "./service-plan/Advance";
import PartnerCompany from "./partner-company";
import Supervisor from "./supervisor-list";
import SelectService from "./select-service";
import OrderedService from "./ordered-service";
import ContractsManagement from "./contracts-management";
import ContractsDetail from "./contracts-detail";
import FilesManagementDoc from "./data-management/document";
import FilesManagementInvoice from "./data-management/invoice";
import PaymentManagement from "./payment-management";
import UploadFiles from "./upload-files";
import UserManagement from "./user-management";
import OrderAdvanceService from "./order-advance-service";
import PermissionManagement from "./permission-management";
import { checkPermission } from '../../utils/common';
import AccountingBalanceSheet from "../accountant/accounting-balance-sheet";
import ReportBusinessResults from "../accountant/report-business-results";
import AccountsPayableReport from "../accountant/accounts-payable-report";
import AccountsReceivableReport from "../accountant/accounts-receivable-report";
import DirectCashFlowStatement from "../accountant/direct-cash-flow-statement";
import IndirectCashFlowStatement from "../accountant/indirect-cash-flow-statement";
import ReportSummary from "../accountant/report-summary";

class Customer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isCustomer: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isCustomer: userType == "CUSTOMER"})
    }

    render() {
        return(
            <Switch>
                {!this.state.isCustomer && <Redirect to={"/"}/>}
                <Route path="/customer/profile" component={Profile}/>
                <Route path="/customer/complete-profile" component={CompleteProfile}/>
                <Route path="/customer/service-plan" component={ServicePlan} />
                <Route path="/customer/service-plan/advance" component={ServicePlan_Advance} />
                <Route path="/customer/partner-company" component={PartnerCompany} />
                <Route path="/customer/supervisor" component={Supervisor} />
                {checkPermission("register_service_order") && <Route path="/customer/order-service" component={SelectService} />}
                {checkPermission("register_service_order") && <Route path="/customer/order-advance-service" component={OrderAdvanceService} />}
                {checkPermission("register_service_order") && <Route path="/customer/list-ordered-service" component={OrderedService} />}
                <Route path="/customer/contracts-management" component={ContractsManagement} />
                {checkPermission("view_detail_contract") &&<Route path="/customer/contracts-detail" component={ContractsDetail} />}
                <Route path="/customer/files-management/document" component={FilesManagementDoc} />
                <Route path="/customer/files-management/invoice" component={FilesManagementInvoice} />
                <Route path="/customer/files-management/upload" component={UploadFiles} />
                {checkPermission("list_payment") && <Route path="/customer/payment-management" component={PaymentManagement} />}
                <Route path="/customer/user-management" component={UserManagement} />
                <Route path="/customer/permission-management" component={PermissionManagement} />

                <Route path="/customer/balance" component={AccountingBalanceSheet}/>
                <Route path="/customer/business-results" component={ReportBusinessResults}/>
                <Route path="/customer/cong-no-phai-tra" component={AccountsPayableReport}/>
                <Route path="/customer/cong-no-phai-thu" component={AccountsReceivableReport}/>

                <Route path="/customer/direct-cash" component={DirectCashFlowStatement}/>
                <Route path="/customer/indirect-cash" component={IndirectCashFlowStatement}/>
                <Route path="/customer/report-summary" component={ReportSummary}/>
            </Switch>
        )
    }
}

export default Customer