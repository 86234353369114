import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Authentication from "../../services/auth";
import {toast} from "react-toastify";

class ModalForgotPassword2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pass1: '',
            pass2: '',
        };
    }

    componentDidMount() {
        this.setState(
            {
                pass1: '',
                pass2: '',
            }
        )
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     if(nextProps.show){
    //         this.setState({pass1:'',pass2:''});
    //     }
    // }

    onOK = () => {
        this.props.onHideModal();
    }

    onSubmit = () => {
        if (this.state.pass1 === '') {
            toast.error('Bạn chưa nhập mật khẩu !')
        } else if (this.state.pass1.length < 6 || this.state.pass1.length > 20) {
            toast.error('Mật khẩu nằm trong khoảng 6-20 kí tự !')
        } else if (this.state.pass1 != this.state.pass2) {
            toast.error('Nhập lại mật khẩu chưa chính xác !')
        } else {
            this.submitPassword()
        }
    }

    submitPassword = () => {
        let payload = {
            otpCode: this.props.otp,
            password: this.state.pass1,
            token: this.props.token,
        };
        Authentication.forgotPasswordUpdateNew(payload, res => {
            if (res.success) {
                toast.success('Đổi mật khẩu thành công ! Giờ đây bạn đã có thể đăng nhập với mật khẩu mới.')
                this.props.onHideModal();
            } else {
                toast.error('Đổi mật khẩu không thành công !')
            }
        })
    }

    render() {
        let self = this;
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={() => {
                            this.setState({pass1: '', pass2: ''}, this.props.onHideModal())
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 22, fontWeight: 'bold'}}>Lấy lại mật khẩu</h1>
                        </div>
                        <div>
                            <Grid container spacing={1} item xs={12}>
                                <TextField
                                    className="mr-1 ml-1"
                                    // id="outlined-margin-dense"
                                    placeholder='Nhập mật khẩu mới'
                                    margin="dense"
                                    fullWidth
                                    type='password'
                                    value={this.state.pass1}
                                    size="small"
                                    inputProps={{maxlength: 20}}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => this.setState({pass1: e.target.value})}
                                />
                            </Grid>
                        </div>

                        <div>
                            <Grid container spacing={1} item xs={12}>
                                <TextField
                                    className="mr-1 ml-1"
                                    type='password'
                                    // id="outlined-margin-dense"
                                    placeholder='Nhập lại mật khẩu mới'
                                    margin="dense"
                                    fullWidth
                                    value={this.state.pass2}
                                    size="small"
                                    inputProps={{maxlength: 20}}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => this.setState({pass2: e.target.value})}
                                />
                            </Grid>
                        </div>

                        <Grid container spacing={1} item xs={12}>
                            <Grid item xs={12}>
                                <div className="align-center mt-2 mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-original btn-1st w-100 type-button-fix"
                                        // href="/#"
                                        disabled={this.state.pass1?.length < 0}
                                        onClick={this.onSubmit}
                                    >
                                          <span>
                                            Xác nhận
                                            <i className="flaticon-right"/>
                                          </span>
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalForgotPassword2;
