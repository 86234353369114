import React from 'react';
import './style.css';
import '../style.css';
import {StarFill, Star, ChevronRight} from 'react-bootstrap-icons';
import logo from '../../../../assets/images/ava 1.png';
import {Link} from "react-router-dom";
import Convert from "../../../../utils/convertUrlPra";
import PublicSupervisorApi from "../../../../services/ob-user/public-supervisor";
import {changeAlias} from "../../../../utils/common";
import Pagination from "../../../../components/Pagination";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PartnerApi from "../../../../services/ob-user/partner";
import PublicPartnerApi from "../../../../services/ob-user/public-partner";

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            areaId: null,
            fullName: null,
            listPartner: [],
            partnerSearch: '',
            supervisorData: [],
            pageNumber: 0,
            pageSize: 6,
            totalItem: 0,
            sort: '',
            sortBy: '',
            sortAsc: '',
            partnerId: null,
            selectedSupervisor: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let params = Convert.urlParams(this.props.location.search);
        this.getListData();
        this.setState({
            showLoading: true
        }, () => this.searchSupervisor())
    }
    getListData() {
        let payload = {
            pageNumber: 0,
            pageSize: 200,
            status: "ACTIVE",
            sort: "fullName=asc",
        }
        PublicPartnerApi.searchByFullNameAndAddress(payload, res => {
            if (res.success) {
                console.log(res.data);
                this.setState({
                    listPartner: res.data.content,
                })
            }
        })
    };
    searchSupervisor = () => {
        let {address, areaId, fullName, pageNumber, pageSize, sortBy, sortAsc, partnerId} = this.state;
        let sort = sortBy && sortAsc ? `${sortBy}=${sortAsc}` : '';
        let payload = {
            fullName,
            areaId: areaId,
            pageNumber,
            pageSize,
            partnerId,
            sort
        }
        if (sort === '') {
            delete payload.sort
        }
        PublicSupervisorApi.searchByFullNameAndAddress(payload, res => {
            if (res.success) {
                this.setState({
                    supervisorData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
            }
        })
    }

    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.searchSupervisor())
    };

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderNewsUrl = (value) => {
        let title = changeAlias(value);
        return title.replace(/ /g, "-")
    }


    render() {
        const {supervisorData, selectedSupervisor} = this.state
        return (
            <div className='stepper-wrapper container'>
                <div className='stepper-router'>
                    <a href=''>
                        Trang chủ / <strong>Đăng ký dịch vụ</strong>
                    </a>
                </div>
                {this.state.showLoading &&
                <div className="content-loading col-12 ml-3 align-items-center"
                     style={{display: 'block'}}>
                    <div className="spinner-grow text-info"/>
                </div>
                }
                <ul className="step-top d-flex align-items-center justify-content-center">
                    <li className="step step-1">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                1
                            </span>
                        </div>
                        <span className="text">
                            Chọn dịch vụ
                        </span>
                    </li>
                    <li className="step step-2">
                        <div className="step-number">
                            <i class="fas fa-check"></i>
                            <span className="sub-number">
                                2
                            </span>
                        </div>
                        <span className="text">
                            Chọn gói cước
                        </span>
                    </li>
                    <li className="step step-3 active">
                        <div className="step-number">
                            3
                            <span className="sub-number">
                                3
                            </span>
                        </div>
                        <span className="text">
                            Chọn người phụ trách
                        </span>
                    </li>
                    <li className="step step-4">
                        <div className="step-number">
                            4
                            <span className="sub-number">
                                4
                            </span>
                        </div>
                        <span className="text">
                            Xác nhận
                        </span>
                    </li>
                </ul>
                <h2 className='stepper-title step-title-large app-separator'>3. Chọn Người Phụ Trách</h2>
                <div className='stepper-content app-separator'>
                    <div className='max-1480 step3-search d-flex align-items-center'>
                        <div className='form-group search-input mr-3'>
                            <Autocomplete
                                size="small"
                                options={this.state.listPartner}
                                getOptionLabel={option => option.fullName}
                                style={{width: '100%'}}
                                inputValue={this.state.partnerSearch}
                                renderInput={params => {
                                    return <TextField
                                        placeholder='Ex: UHY'
                                        {...params}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                fontSize: 17,
                                                backgroundColor: "#FFF",
                                                paddingRight: 10,
                                                paddingLeft: 10,
                                                color: '#2e495c',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat'
                                            }
                                        }}
                                        label="Nhập tên công ty"
                                        variant="outlined"
                                        fullWidth
                                    />
                                }}
                                onInputChange={(event) => {
                                    // debugger
                                    console.log(event.target)
                                    if (event === null || event.target.value === undefined || event.target.value === "") {
                                        this.setState({
                                            partnerId: '',
                                            partnerSearch: ''
                                        })
                                    } else {
                                        this.setState({
                                            partnerSearch: event?.target.value
                                        })
                                    }
                                }}
                                onChange={(e, val) => {
                                    if (val?.id != undefined){
                                        this.state.partnerId     = val?.id;
                                        this.state.partnerSearch = val?.fullName;
                                    }else {
                                        this.state.partnerId = null;
                                    }
                                    this.searchSupervisor()
                                }}

                            />
                        </div>
                        <div className='form-group search-input mr-3'>
                            <TextField
                                id='step3search'
                                placeholder='Ex: Nguyễn Thị Phương Thảo'
                                value={this.state.fullName}
                                label="Tên người phụ trách"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: 17,
                                        backgroundColor: "#FFF",
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        color: '#2e495c',
                                        fontWeight: 500,
                                        fontFamily: 'Montserrat'
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') this.searchSupervisor()
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        fullName: e.target.value
                                    })
                                }}
                            />
                            
                        </div>
                        <div className='btn-search-refresh'>
                            <button
                                style={{ marginRight: 15}}
                                className='btn btn-search'
                                onClick={() => {
                                    this.searchSupervisor()
                                }}
                            >
                                Tìm kiếm
                            </button>
                            <button
                                className='btn btn-refresh'
                                onClick={() => {
                                    this.state.partnerId = null;
                                    this.setState({
                                        fullName: '',
                                        pageNumber: 0
                                    }, () => this.searchSupervisor())
                                }}
                            >
                                Tải lại
                            </button>
                        </div>
                    </div>
                    <div className='step3-content'>
                        <div className="max-1480">
                            <div className='row step3-content-list'>
                                {supervisorData?.map(item => (
                                    <div className='col-md-4 mb-4' key={item.fullName}>
                                        <div
                                            className={item.id === selectedSupervisor.id ? 'selected step3-item' : 'step3-item'}
                                            title={item.id === selectedSupervisor.id ? `Bạn đang chọn người phụ trách chuyên môn ${selectedSupervisor.fullName}` : ''}
                                            onClick={() => {
                                                this.setState({
                                                    selectedSupervisor: item
                                                })
                                            }}
                                        >
                                            <div className='sup-location'>{item.areaName}</div>
                                            <img src={item.urlImage || logo} className='step3-item-img'/>
                                            <div className='sup-name color-main-dark'>
                                                {item.fullName}
                                            </div>
                                            <div className='rating'>
                                                {item.vote >= 1 ? <StarFill color='yellow'/> : <Star/>}
                                                {item.vote >= 2 ? <StarFill color='yellow'/> : <Star/>}
                                                {item.vote >= 3 ? <StarFill color='yellow'/> : <Star/>}
                                                {item.vote >= 4 ? <StarFill color='yellow'/> : <Star/>}
                                                {item.vote >= 5 ? <StarFill color='yellow'/> : <Star/>}
                                            </div>
                                            <div className='sup-skills'>
                                                <p className='skill-title'>Thuộc công ty</p>
                                                <p className='skill-des color-main-dark'>{item.partnerName}</p>
                                            </div>
                                            <div className='sup-skills des-experience'>
                                                <p className='skill-title'>Kinh nghiệm</p>

                                                <p className='skill-des color-main-dark'>{item.certificate != null && <div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: item.certificate.replaceAll("\n", "</br>")}}/>}</p>
                                            </div>
                                            <Link
                                                to={{
                                                    pathname: "/home/supervisor-page",
                                                    // search: `?s=${listSupHighlight[0].id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                    search: `?s=${item.id}-${this.renderNewsUrl(item.fullName)}`,
                                                    state: {
                                                        fromDashboard: true,
                                                        supervisor: item.id,
                                                    }
                                                }}
                                                className="btn-readmore"
                                            >
                                                Xem thêm <ChevronRight/>
                                            </Link>
                                        </div>
                                    </div>))}

                            </div>
                        </div>
                    </div>
                    <div className="pagination-right text-right mt-4 max-1480">
                        <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItem}
                            changeHandler={(page) => this.handleChangePage(page)}
                        />
                    </div>
                </div>
                <div className='btn-redirect btn-bottom btn-step-3'>
                    <Link
                        to={{
                            pathname: '/home/select-plan-step-2',
                            search: `${this.props.location.search}`
                        }}
                    >
                        <button className='btn btn-cancel-stepper'>Hủy</button>
                    </Link>
                    <Link
                        to={selectedSupervisor.id ? {
                            pathname: "/home/select-plan-step-4",
                            search: `${this.props.location.search}&sup=${selectedSupervisor.id}-${this.renderNewsUrl(selectedSupervisor.fullName)}`,
                            state: {
                                fromDashboard: true,
                            }
                        } : {}}
                        style={{color: "#000000"}}
                    >
                        <button
                            className='btn btn-next-stepper'
                            disabled={!selectedSupervisor.id}
                            onClick={() => {
                            }}
                        >
                            Tiếp theo
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Step3;
