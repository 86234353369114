import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let RoleApi = {
    getListByName: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/get-list-by-name?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getListByIds: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/get-names-by-ids?ids=${param}`,
            method: 'GET'
        }, callback)
    },
    removeRole: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/remove-role/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    createRole: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/create-role`,
            method: 'POST',
            body: payload
        }, callback)
    },
    getNameById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/get-name-by-id/${payload.id}`,
            method: "GET"
        }, callback)
    },
    getList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/role/get-list-by-name?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    }
}
export default RoleApi;
