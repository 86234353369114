import React from 'react';
import './style.css';
import '../style.css';
import checkIcon from '../../../../assets/images/check-icon.svg'
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Cookies from 'js-cookie'
import {changeAlias} from "../../../../utils/common";
import ModalRequireLogin from "../../../../components/Modal/modalRequireLogin";
import PublicServicePlanApi from "../../../../services/ob-core/public-service-plan";
import ModalLogin from "../../../../components/Modal/ModalLogin";
class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showRequireLogin: false,
            listService: [],
            serviceSelected: {},
            type: 'BASIC',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            showLoading: true,
            serviceSelected: {}
        }, () => {
            this.getServicePlan()
        })
    }

    componentWillMount() {
        this.setState({
            serviceSelected: {}
        })
    }

    getServicePlan = () => {
        this.setState({
            showLoading: true
        })
        let payload = {
            type: this.state.type
        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({
                    listService: res.data,
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng thử lại sau!")
            }
        })
    };
    onOpenModalLogin = (event) => {
        event.preventDefault();
        this.setState({modalLogin: true});
    };
    modalLoginClose = () => this.setState({modalLogin: false});
    onOpenModalSignup = (event) => {
        // event.preventDefault();
        this.setState({modalSignup: true, modalLogin: false});
    };
    showModalForgot = () => {
        this.setState({
            modalLogin: false,
            modalForgotPassword: true
        })
    }

    onCloseModalForgotPassword = () => {
        this.setState({
            modalForgotPassword: false
        })
    }
    renderNewsUrl = (value) => {
        let title = changeAlias(value);
        return title.replace(/ /g, "-")
    }

    render() {
        const {listService, serviceSelected, type} = this.state;
        let token = Cookies.get("ob_cl_token")
        return (
            <div className='stepper-wrapper container'>
                <div className='stepper-router'>
                    <a href='/'>
                        Trang chủ / <strong>Đăng ký dịch vụ</strong>
                    </a>
                </div>
                {this.state.showLoading &&
                <div className="content-loading col-12 ml-3 align-items-center"
                     style={{display: 'block'}}>
                    <div className="spinner-grow text-info"/>
                </div>
                }
                <ul className="step-top d-flex align-items-center justify-content-center">
                    <li className="step step-1 active">
                        <div className="step-number">
                            1
                            <span className="sub-number">
                                1
                            </span>
                        </div>
                        <span className="text">
                            Chọn dịch vụ
                        </span>
                    </li>
                    <li className="step step-2">
                        <div className="step-number">
                            2
                            <span className="sub-number">
                                2
                            </span>
                        </div>
                        <span className="text">
                            Chọn gói cước
                        </span>
                    </li>
                    <li className="step step-3">
                        <div className="step-number">
                            3
                            <span className="sub-number">
                                3
                            </span>
                        </div>
                        <span className="text">
                            Chọn supervisor
                        </span>
                    </li>
                    <li className="step step-4">
                        <div className="step-number">
                            4
                            <span className="sub-number">
                                4
                            </span>
                        </div>
                        <span className="text">
                            Xác nhận
                        </span>
                    </li>
                </ul>
                <h2 className='stepper-title step-title-large app-separator'>1. Chọn Dịch Vụ</h2>
                <div className='row'>
                    <div className='col-sm-5 font-18 pt-1'>
                        <label><strong>Loại dịch vụ: </strong></label>
                    </div>
                    <div className="col-sm-7 custom-radios">
                        <div
                            className='d-inline-block mr-2 cursor-pointer'
                            onClick={() => {
                                this.setState({
                                    type: 'BASIC'
                                }, () => {
                                    this.getServicePlan()
                                })
                            }}
                        >
                            <span className='mr-2 font-18'>Cơ bản</span>
                            <input type="radio" id="color-2" name="color" value="BASIC" checked={type === 'BASIC'}/>
                            <label htmlFor="color-2">
                              <span>
                                <img src={checkIcon}
                                     alt="Checked Icon"/>
                              </span>
                            </label>
                        </div>

                        <div
                            className='d-inline-block cursor-pointer'
                            onClick={() => {
                                this.setState({
                                    type: 'ADVANCED'
                                }, () => {
                                    this.getServicePlan()
                                })
                            }}
                        >
                            <input type="radio" id="color-1" name="color" value="ADVANCED"
                                   checked={type === 'ADVANCED'}/>
                            <label htmlFor="color-1">
                              <span>
                                <img src={checkIcon}
                                     alt="Checked Icon"/>
                              </span>
                            </label>
                            <span className='ml-2 font-18'>Nâng cao</span>
                        </div>
                    </div>
                </div>


                <div className='stepper-content app-separator'>
                    <div className='max-1480 d-flex justify-content-between'>
                        {listService.length === 0 || !listService
                            ? <div>Chưa có dữ liệu.</div>
                            : listService.map((item, index) => (
                                <div
                                    className={item.id === serviceSelected.id ? 'selected step1-item' : 'step1-item'}
                                    onClick={() => this.setState({serviceSelected: item})}
                                >
                                    <div
                                        className={item.type === 'ADVANCED' ? 'step1-item-title color-main-dark' : 'step1-item-title color-main-dark'}
                                        style={item.type === 'ADVANCED' ? {backgroundColor: '#2ecc71'} : {}}
                                    >
                                        {item.servicePlanName}
                                    </div>
                                    <div className='step1-item-content text-left'>
                                        <ul className='list-dot-blue'>
                                            <div dangerouslySetInnerHTML={{__html: item.content.replaceAll("\n", "</br>")}}/>
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='btn-redirect btn-bottom'>
                    <Link to={{pathname: "/"}}>
                        <button className='btn btn-cancel-stepper'>Hủy</button>
                    </Link>

                    <Link
                        // to={serviceSelected.id && token ? {
                        to={serviceSelected.id && ((this.state.type == "ADVANCED" && token) || (this.state.type == "BASIC")) ? {
                            pathname: type === 'BASIC' ? "/home/select-plan-step-2" : "/home/select-plan-step-4",
                            // search: `?service=${serviceSelected.id}`,
                            search: `?epyt=${type}&s=${serviceSelected.id}-${this.renderNewsUrl(serviceSelected.servicePlanName)}&t=${this.renderNewsUrl(serviceSelected.priceType)}`,
                            state: {
                                fromDashboard: true,
                                service: serviceSelected.id,
                            }
                        } : {}}
                        style={{color: "#000000"}}
                    >
                        <button
                            className='btn btn-next-stepper'
                            disabled={!this.state.serviceSelected.id}
                            onClick={() => {
                                if(!token && this.state.type == "ADVANCED")
                                    this.setState({
                                        modalLogin: true
                                    })
                            }}
                        >
                            Tiếp theo
                        </button>
                    </Link>
                </div>
                {this.state.modalLogin && <ModalLogin show={this.state.modalLogin} onHide={this.modalLoginClose}
                                                      onOpenModalSignup={this.onOpenModalSignup}
                                                      showModalForgot={this.showModalForgot}/>}
                {this.state.showRequireLogin && <ModalRequireLogin show={this.state.showRequireLogin}
                                                                   onHide={() => this.setState({showRequireLogin: false})}
                />}
            </div>
        );
    }
}

export default Step1;
