import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
import {
    Modal,
} from 'react-bootstrap';
import WalletApi from "../../../services/ob-core/wallet";
import Autosuggestion from "../../Form/Autosuggestion";

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from "react-number-format";

class ModalAddMoney extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerName: '',
            money: '',

            partnerNameSearch: '',

            listPartnerName: [],
            clear: false,

            errPartnerName: false,
            errMoney: false,

        };
    }

    componentDidMount() {
        this.setState({
            partnerName: '',
            money: '',

            errPartnerName: false,
            errMoney: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                partnerName: '',
                money: '',

                errMoney: false,
            });
        }
    }

    // getListRole = () => {
    //     let{pageNumber, pageSize, roleSearch} = this.state;
    //     let payload = {
    //         pageNumber: pageNumber,
    //         pageSize: pageSize,
    //         roleName: roleSearch,
    //     };
    //     RoleApi.getList(payload, res => {
    //         console.log('sup:', res)
    //         if(res.success) {
    //             this.setState({
    //                 listRole: res.data.content,
    //             })
    //         }
    //     })
    // }

    onSubmit = () => {
        if (this.state.money === "") {
            this.setState({
                errMoney: true,
            })
        } else {
            this.addMoney()
        }
    }

    // create = () => {
    //     let payload = {
    //         fullName: this.state.userName,
    //         phone: this.state.userPhone,
    //         permissionId: this.state.role,
    //         password: "12345678@",
    //     };
    //     AdminUserManagement.create(payload, res => {
    //         if (res.errorCode === 'One-Book-Auth.E000003') {
    //             toast.error(res.message[0], {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 3000
    //             });
    //         }else
    //         if (res.success) {
    //             toast.success("Tạo mới thành công !", {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 3000
    //             });
    //             this.props.onHide();
    //             this.props.onRefresh();
    //         } else {
    //             return (toast.error("Có lỗi xảy ra !", {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 3000
    //             }))
    //         }
    //     })
    // }

    addMoney () {
        let payload = {
            partnerId: this.props.idPartner,
            transactionType: "RECHARGE_MONEY",
            transactionValue: this.state.money
        }
        WalletApi.rechageWalletPartner(payload, res => {
            if (res.success) {
                toast.success("Nạp tiền vào ví partner thành công!")
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra! Vui lòng thử lại")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-container">

                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>THÊM MỚI NẠP TIỀN CỌC</b></h1>
                        </div>

                        <div className='mt-5 mb-5 ml-3 mr-3'>
                            <div className="row">
                                <label className="col-5 mt-2">Tên Partner: </label>
                                <div className={'w-100 col-7 pl-0 pr-0 mt-2 '}>
                                    {this.props.namePartner}
                                </div>
                            </div>
                            {this.state.errPartnerName ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Vui lòng chọn tên Partner !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số tiền: </label>
                                {/*<input*/}
                                {/*    className={this.state.errMoney ? "inputPassword w-100 col-7 border-input-error " : "inputPassword w-100 col-7 "}*/}
                                {/*    placeholder="Nhập số tiền"*/}
                                {/*    fullWidth*/}
                                {/*    maxLength={10}*/}
                                {/*    value={this.state.money}*/}
                                {/*    onChange={(e) => this.setState({money: e.target.value.trim().replace(/[^0-9]/g, '')})}*/}
                                {/*/>*/}
                                <NumberFormat
                                    thousandSeparator={true}
                                    className="inputPassword w-100 col-7"
                                    onChange={(e) => this.setState({money: e.target.value.trim().replace(/[^0-9]/g, '')})}
                                    value={this.state.money}
                                />
                            </div>
                            {this.state.errMoney ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Vui lòng điền số tiền !</label>
                            </div> :<div/>}

                        </div>


                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAddMoney;
