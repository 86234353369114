import React, {Component} from "react";
import clsx from "clsx";
import {Link} from "react-router-dom";
import Information from "../contracts-detail/Information";
import ListData from "../contracts-detail/ListData";
import ListReport from "../contracts-detail/ListReport";
import SubContract from "../contracts-detail/SubContract";
import History from "../contracts-detail/history";
import Sidebar from "../../../components/Sidebar";
import Convert from "../../../utils/convertUrlPra";
import { checkPermission } from "../../../utils/common";
import PublicContractApi from "../../../services/ob-core/public-contract";

class DetailContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            contractId: '',
            idContract: '',
            id: '',
            onTab: 1,
            listData: [],
            code:''
        }
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({contractId: params.contractId})
    }

    componentDidMount() {
        this.getDetailContractFromAPI()
    }


    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    getDetailContractFromAPI() {
        let payload = {
            id: this.state.contractId
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data,
                });
            }
        });
    }


    render() {
        let {value, onTab,listData} = this.state;
        console.log(this.props.location.state);
        const id = this.props.location.state?.id;
        const contractId = this.props.location.state?.contractId;
        const code = this.props.location.state?.code;

        let params = Convert.urlParams(this.props.location.search);
        console.log("param>>>>>", params)
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content">
                            <div className='card' style={{padding: 15}}>
                                <span>
                                    <Link to={"/partner/contracts-management"}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                <span style={{fontSize:22}} className='text-center mb-4'>Thông tin chi tiết hợp đồng {params.code}</span>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={onTab === 1 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-home-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-home" onClick={() => this.setState({onTab: 1})}
                                           aria-selected="true">Thông tin chung</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={onTab === 2 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-profile-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-profile" onClick={() => this.setState({onTab: 2})}
                                           aria-selected="false">Danh sách dữ liệu</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={onTab === 3 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-contact-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-contact" onClick={() => this.setState({onTab: 3})}
                                           aria-selected="false">Danh sách báo cáo</a>
                                    </li>
                                    {checkPermission("view_editing_history_detail") &&
                                    <li className="nav-item">
                                        <a className={onTab === 5 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-contact-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-contact" onClick={() => this.setState({onTab: 5})}
                                           aria-selected="false">Phụ lục hợp đồng</a>
                                    </li>
                                    }
                                    {checkPermission("view_editing_history_detail") &&
                                    <li className="nav-item">
                                        <a className={onTab === 4 ? "cursor-pointer nav-link active" : "cursor-pointer nav-link"} id="pills-contact-tab" data-toggle="pill"
                                           role="tab" aria-controls="pills-contact" onClick={() => this.setState({onTab: 4})}
                                           aria-selected="false">Lịch sử chỉnh sửa</a>
                                    </li>
                                    }

                                </ul>

                                <div className="tab-content">
                                    <div
                                        className={onTab === 1 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <Information value={params.contractId} customerId={listData.customerId}/>
                                    </div>
                                    <div
                                        className={onTab === 2 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ListData value={params.contractId}/>
                                    </div>
                                    <div
                                        className={onTab === 3 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <ListReport value={params.contractId} jobs={this.state.listData.jobs} customerId={listData.customerId}/>
                                    </div>
                                    <div
                                        className={onTab === 5 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <SubContract value={params.contractId} jobs={this.state.listData.jobs} customerId={listData.customerId}/>
                                    </div>
                                    <div
                                        className={onTab === 4 ? "fade tab-pane active show shadow-none" : "fade tab-pane shadow-none"}
                                    >
                                        <History contractId={params.contractId}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default DetailContract
