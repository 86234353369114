import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Utils from  '../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import WalletApi from "../../services/ob-core/wallet";
import AccountantApi from "../../services/ob-user/accountant";
import PartnerApi from "../../services/ob-user/partner";

class ModalAddWallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listPartner:[],
            listContract:[],
            transactionContent:'',
            transactionType:'',
            beforeTransaction:'',
            partnerName:'',
            partnerNameErr:false,
            transactionValue:'',
            transactionValueErr:false,
            contractCode:'',
            contractCodeErr:false,
            signupInfo: null,
            transactionContentErr:false,
            nameErr: false,
            transactionTypeErr:false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        };
    }
    componentDidMount() {
        this.getListPartner();
        this.getListContract()

        this.setState({
            contractCode:'',
            transactionContent:'',
            partnerNameErr:false,
            transactionContentErr:false,
            contractCodeErr:false,
            transactionType:'',
            transactionTypeErr:false,
            transactionValue:'',
            transactionValueErr:false,
            partnerName:'',
            name:'',
            phone:'',
            email:'',
            contract:'',
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                contractCode:'',
                contractCodeErr:false,
                transactionContent:'',
                partnerNameErr:false,
                transactionContentErr:false,
                transactionType:'',
                transactionTypeErr:false,
                transactionValue:'',
                transactionValueErr:false,
                partnerName:'',
                name:'',
                phone:'',
                email:'',
                contract:'',
                nameErr: false,
                phoneErr: false,
                emailErr: false,
                contractErr: false,
                btnStatus: false,
            });
        }
    }




    getListPartner = () => {
        let payload = {
            status: "ACTIVE",
            // sort: 'id=ASC',
        };
        PartnerApi.adminGetListPartner(payload, res => {
            if (res.success) {
                this.setState({
                    listPartner: res.data.content
                })
            }
        })
    }

    getListContract = () => {
        let payload = {

            status: "PROCESSING",
            // sort: 'id=ASC',
        };
        WalletApi.adminSearchByContractAndType(payload, res => {
            if (res.success) {
                this.setState({
                    listContract: res.data.content
                })
            }
        })
    }


    onSubmit =()=>{
        if(this.state.contractCode.length  == 0 &&
            this.state.transactionContent.length == 0 &&
            this.state.transactionType.length == '' &&
            this.state.transactionValue.length == 0 &&
            this.state.partnerName.length == 0){
            this.setState({
                contractCodeErr:true,
                partnerNameErr:true,
                transactionContentErr:true,
                transactionTypeErr:true,
                transactionValueErr:true,});
            this.props.enqueueSnackbar('Không được để trống thông tin !', {
                variant: 'error',
            });
        }else if(this.state.contractCode.length  == 0 ){
            this.setState({contractCodeErr: true});
            this.props.enqueueSnackbar('Không được để trống mã hợp đồng !', {
                variant: 'error',
            });
        }else if(this.state.partnerName.length == 0){
            this.setState({partnerNameErr: true});
            this.props.enqueueSnackbar('Không được để trống tên Partner !', {
                variant: 'error',
            });
        }else if(this.state.transactionContent.length == 0 ) {
            this.setState({transactionContentErr: true});
            this.props.enqueueSnackbar('Không được để trống nội dung !', {
                variant: 'error',
            });
        }else if(this.state.transactionValue.length == 0 ){
                this.setState({transactionValueErr: true});
                this.props.enqueueSnackbar('Không được để trống số tiền !', {
                    variant: 'error',
                });
        }else if(this.state.transactionType.length == 0 ){
            this.setState({transactionTypeErr: true});
            this.props.enqueueSnackbar('Vui lòng chọn loại giao dịch !', {
                variant: 'error',
            });
        }else {
            this.addWallet()
        }
    }

    addWallet = () => {
        let payload = {
            contractId: '1',
            partner:this.props.partnerName,
            partnerId:this.state.partnerName,
            transactionValue: this.state.transactionValue,
            transactionContent: this.state.transactionContent,
            transactionType: this.state.transactionType,
            beforeTransaction: this.state.beforeTransaction,

        }
        WalletApi.adminCreate(payload, res => {
            if (res.errorCode) {
                this.props.enqueueSnackbar(res.message, {
                    variant: 'error',
                });
            }
            else if (res.success) {
                this.props.enqueueSnackbar('Tạo mới kế toán thành công !', {
                    variant: 'success',
                });

                this.props.onHide();
                this.props.onRefresh();
            }
            else  {
                return this.props.enqueueSnackbar('Tạo mới không thành công, vui lòng thử lại !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }


    renderType(transactionType) {
        switch (transactionType) {
            case "REFUND_MONEY":
                return 'Hoàn tiền cọc';
            case "EXPERT_MONEY":
                return 'Trừ tiền cọc';
            case "RECHARGE_MONEY":
                return 'Nạp tiền cọc';

            default:
                return;
        }
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>Thêm mới giao dịch</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2">Mã hợp đồng </label>
                                <select
                                    className={this.state.contractCodeErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    fullWidth
                                    value={this.state.contractCode}
                                    onChange={(e) => this.setState({contractCode: e.target.value},
                                        () =>{
                                            if(this.state.contractCode.length == ''){
                                                this.setState({contractCodeErr: true, btnStatus: true})
                                            }else{
                                                this.setState({contractCodeErr: false, btnStatus: false})
                                            }
                                        }

                                    )}
                                >
                                    <option value=''>Chọn mã hợp đồng</option>
                                    {this.state.listContract?.map(item => {
                                        return (
                                            <option value={item.id}>{item.contractCode}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {this.state.contractCodeErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Vui lòng không để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.partnerNameErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Tên Partner </label>
                                <select
                                    className={this.state.transactionTypeErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    fullWidth
                                    value={this.state.partnerName}
                                    onChange={(e) => this.setState({partnerName: e.target.value},
                                        () =>{
                                            if(this.state.partnerName.length == ''){
                                                this.setState({partnerNameErr: true, btnStatus: true})
                                            }else{
                                                this.setState({partnerNameErr: false, btnStatus: false})
                                            }
                                        }

                                    )}
                                >
                                    <option value=''>Chọn tên Partner</option>
                                    {this.state.listPartner?.map(item => {
                                        return (
                                            <option value={item.id}>{item.fullName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {this.state.partnerNameErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Vui lòng chọn tên Partner !</label>
                            </div>:<div/>}

                            <div className={this.state.transactionContentErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Nội dung</label>
                                <input
                                    // type="email"
                                    className={this.state.transactionContentErr?"inputPassword w-100 col-6 border-input-error ":"inputPassword w-100 col-6 "}
                                    placeholder='Nhập nội dung...'
                                    fullWidth
                                    value={this.state.transactionContent}
                                    maxLength={100}
                                    onChange={(e) => this.setState({transactionContent: e.target.value},
                                        () =>{
                                            if(this.state.transactionContent.length == 0){
                                                this.setState({transactionContentErr: true, btnStatus: true})
                                            }else{
                                                this.setState({transactionContentErr: false, btnStatus: false})
                                            }
                                        }

                                    )}
                                />
                            </div>
                            {this.state.transactionContentErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Vui lòng không để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.transactionValueErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Số tiền</label>
                                <input
                                    className={this.state.transactionValueErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder='Số tiền...'
                                    fullWidth
                                    maxLength={10}
                                    value={this.state.transactionValue}
                                    onChange={(e) => this.setState({transactionValue: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () =>{
                                            if(this.state.transactionValue.length == 0){
                                                this.setState({transactionValueErr: true, btnStatus: true})
                                            }else{
                                                this.setState({transactionValueErr: false, btnStatus: false})
                                            }
                                        }

                                    )}
                                />
                            </div>
                            {this.state.transactionValueErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Vui lòng không để trống !</label>
                            </div>:''}

                            <div className={this.state.transactionTypeErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Loại giao dịch</label>
                                <select
                                    className={this.state.transactionTypeErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    fullWidth
                                    value={this.state.transactionType}
                                    onChange={(e) => this.setState({transactionType: e.target.value},
                                        () =>{
                                            this.state.transactionType == ''
                                             ? this.setState({transactionTypeErr: true, btnStatus: true}): this.setState({transactionTypeErr: false, btnStatus: false})
                                        })}
                                >
                                    <option value=''>Chọn giao dịch</option>
                                    <option value='REFUND_MONEY'>Hoàn tiền cọc</option>
                                    <option value='RECHARGE_MONEY'>Nạp tiền</option>
                                    <option value='EXPERT_MONEY'>Trừ tiền cọc</option>

                                </select>
                            </div>
                            {this.state.transactionTypeErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Vui lòng chọn giao dịch !</label>
                            </div>:''}


                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                          Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAddWallet);
