import React, {Component} from "react";
import './index.css';
import clsx from 'clsx';
import moment from 'moment';

import {
    Card,
    TextField,
    Grid,
    Radio,
    CardContent,
    CircularProgress,
    Button,
} from '@material-ui/core';
import DatePicker from "react-datepicker/es";

import {makeStyles} from '@material-ui/core/styles';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import Sidebar from "../../../components/Sidebar";
import Dropzone from 'react-dropzone';
import PageHeader from "../../../components/PageHeader";
import ModalChangePassword from "../../../components/Modal/modalChangePassword";
import FormInput from "../../../components/Form/input";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar";

import AccountantApi from "../../../services/ob-user/accountant";
import ProvinceApi from "../../../services/ob-user/province";
import {toast} from "react-toastify";
import Authentication from "../../../services/auth";
import {forceLogout} from "../../../utils/authority";
import TableHeader from "../../../components/TableHeader";

const formatDate = 'DD-MM-YYYY';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            // backgroundColor: '#1BBDA5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1BBDA5',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
            backgroundColor: '#ea7b22',
        },
    },
});


const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderAreaId = function (areaId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === areaId)[0] ? _province?.filter(item => item.id === areaId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

const renderArea = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};


function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

function renderPosition(status) {
    switch (status) {
        case "CUSTOMER":
            return "Khách hàng";
        case "PARTNER":
            return "Admin Partner";
        case "ACCOUNTANT":
            return "Kế toán";

        default:
            return;
    }
}

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            data: {},
            listProvince: [],
            listDistrict: [],
            AreaSup: [],

            fullName: "",
            sex: "",
            provinceId: 0,
            districtId: 0,
            address: "",
            areaId: 0,
            identify: "",
            dateIssued: "",
            placeIssued: "",
            urlImage: "",
            btnSaveStatus: true,


            fullNameError: false,
            fullNameErrorLength: false,
            sexError: false,
            provinceIdError: false,
            districtIdError: false,
            addressError: false,
            areaIdError: false,
            identifyError: false,
            dateIssuedError: false,
            placeIssuedError: false,

            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,
            modalUploadAvatar: false,
            showLoading: false,
            allUserTypes: [],
            currentUserType: localStorage.getItem("ob_cl_userType"),
            accounts: [
                {
                    id: '1',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Loại tài khoản",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getSupportArea();
            this.getAllUserTypes();
        })

    }
    getAllUserTypes = () => {
        Authentication.getAllUserType(res => {
            if (res.success) {
                this.setState({allUserTypes: res.data, showLoading: false});
            }
        })
    }
    switchUserType = (userType) => {
        let payload = {
            type: Authentication.getIndexUserType(userType)
        }
        Authentication.switchUserType(payload, res => {
            if (res.success){
                forceLogout();
                toast.success("Chuyển thành công! \nVui lòng đăng nhập lại để tiếp tục!");
            }
            else {
                toast.error("Chuyển tài khoản thất bại");
            }
        })
    }
    handleShowModalForgotPassword = () => {
        this.setState({showModalChangePassword: true});
    };

    onCloseModalForgotPassword = () => {
        this.setState({showModalChangePassword: false});
    }

    getSupportArea() {
        ProvinceApi.supportArea(res => {
            if (res.success) {
                this.setState({
                    AreaSup: res.data.content
                }, () => this.getAccountInfo())
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    getAccountInfo() {
        AccountantApi.detailAccountant(res => {
            if (res.success) {
                this.setState({
                    data: res.data,
                    fullName: res.data.fullName,
                    sex: res.data.sex,
                    provinceId: res.data.provinceId,
                    districtId: res.data.districtId,
                    address: res.data.address,
                    areaId: res.data.areaId,
                    identify: res.data.identify,
                    dateIssued: res.data.dateIssued,
                    placeIssued: res.data.placeIssued,
                    urlImage: res.data.urlImage,
                    showLoading: false,
                })
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    }

    handleChangePhone(val) {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({value: onlyNums, representativePhone: onlyNums}, () => {
                this.state.representativePhone.length != 0 ? this.setState({representativePhoneError: false}) : this.setState({representativePhoneError: true})
            });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({value: number, error: false, representativePhone: onlyNums});
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({value: number, error: false, representativePhone: onlyNums});
        }
    };

    handleChangeIdentify(val) {
        // console.log(val)
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        // const onlyNums = val.target.value;
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length != 0 ? this.setState({identifyError: false}) : this.setState({
                    identifyError: true,
                    btnSaveStatus: false
                });
                this.state.identify.length >= 9 ? this.setState({
                    identifyError: false,
                    btnSaveStatus: true
                }) : this.setState({
                    identifyError: true,
                    btnSaveStatus: false
                })
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }

    handleSave() {
        let {
            fullName, sex, provinceId, districtId, address, areaId,
            identify, dateIssued, placeIssued, urlImage
        } = this.state;
        if (fullName === '') {
            this.setState({fullNameError: true})
        } else if (sex === '') {
            this.setState({sexError: true})
        } else if (provinceId === '') {
            this.setState({provinceIdError: true})
        } else if (districtId === '') {
            this.setState({districtIdError: true})
        } else if (address === '') {
            this.setState({addressError: true})
        } else if (areaId === '') {
            this.setState({areaIdError: true})
        } else if (identify === '') {
            this.setState({identifyError: true})
        } else if (placeIssued === '') {
            this.setState({placeIssuedError: true})
        } else if (dateIssued === '') {
            this.setState({dateIssuedError: true})
        } else {
            let payload = {
                body: {
                    fullName: fullName ? fullName : '',
                    sex: sex ? sex : '',
                    provinceId: provinceId ? provinceId : '',
                    districtId: districtId ? districtId : '',
                    address: address ? address : '',
                    areaId: areaId ? areaId : '',
                    identify: identify ? identify : '',
                    dateIssued: dateIssued ? dateIssued : 0,
                    placeIssued: placeIssued ? placeIssued : '',
                }
            }
            this.setState({loading: true}, () => {
                let timer = setInterval(() => {
                    this.tick()
                }, 10);
                setTimeout(() => {
                    clearInterval(timer);
                    this.setState({
                        loading: false,
                    }, () => {
                        AccountantApi.updateAccountant(payload, res => {
                            if (res.success) {
                                toast.success("Thông tin đã được cập nhật thành công !")
                                this.setState({
                                    onEdit: false,
                                    inEditing: false,
                                }, () => this.getAccountInfo());
                            } else {
                                // console.log('err: ', res);
                                toast.error("Cập nhật không thành công, vui lòng thử lại sau !")
                            }
                        })
                    });
                }, 3000);
            });
        }
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({onEdit: false}, () => {
                this.setState({
                    fullName: this.state.data ? this.state.data.fullName : "",
                    sex: this.state.data ? this.state.data.sex : "",
                    provinceId: this.state.data ? this.state.data.provinceId : 0,
                    districtId: this.state.data ? this.state.data.districtId : 0,
                    address: this.state.data ? this.state.data.address : "",
                    areaId: this.state.data ? this.state.data.areaId : 0,
                    identify: this.state.data ? this.state.data.identify : "",
                    dateIssued: this.state.data ? this.state.data.dateIssued : "",
                    placeIssued: this.state.data ? this.state.data.placeIssued : "",
                    urlImage: this.state.data ? this.state.data.urlImage : "",

                    fullNameError: false,
                    sexError: false,
                    provinceIdError: false,
                    districtIdError: false,
                    areaIdError: false,
                    identifyError: false,
                    dateIssuedError: false,
                    placeIssuedError: false,
                    urlImageError: false,
                })
            })
        }
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderPlaceSupport = (areaId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.areaId == areaId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };


    handleDateChangeRaw = (e) => {
        e.preventDefault()
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }

    render() {
        const {
            data, fullName, provinceId, districtId, address, areaId, identify,
            dateIssue, placeIssued
        } = this.state;
        let jobDetail = localStorage.getItem("ob_cl_userType");

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="profile"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className='mb-4 card border-color'>
                                {/*<CardContent>*/}
                                {/*    <PageHeader*/}
                                {/*        title='Thông tin doanh nghiệp'/>*/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin doanh nghiệp</span>
                                </div>
                                <div className='text-center mt-2 mb-4' style={{height: 120}}>
                                    <img
                                        // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}
                                        src={data.urlImagePartner ? data.urlImagePartner : "http://ecorau.com/images/logo_mail.png"}
                                        className='mh-100 mw-100 m-2'
                                    />
                                    {/*<Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>*/}
                                    {/*    {({getRootProps, getInputProps}) => (*/}
                                    {/*        <span title='Thay đổi logo' className='change-avt'>*/}
                                    {/*                <span {...getRootProps()}>*/}
                                    {/*                    <input {...getInputProps()} />*/}
                                    {/*                    <CameraAltOutlinedIcon/>*/}
                                    {/*                </span>*/}
                                    {/*            </span>*/}
                                    {/*    )}*/}
                                    {/*</Dropzone>*/}
                                </div>

                                <div className="m-4 font-14">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Tên doanh nghiệp</label>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {/*{data.partnerName ? data.partnerName.toUpperCase() : ""}*/}
                                            {data?.partnerName?.toUpperCase()}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Mã số thuế </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.tax ? data.tax : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Email </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.emailPartner ? data.emailPartner : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">
                                                Số điện thoại
                                            </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.partnerPhone ? data.partnerPhone : ''}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Tỉnh/Thành phố</label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.provinceIdPartner ? renderProvinceId(data.provinceIdPartner) : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Quận/Huyện </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.districtIdPartner ? renderDistrictId(data.districtIdPartner) : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <label className="text-black-50">Địa chỉ </label>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {data.addressPartner ? data.addressPartner : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>
                                {/*</CardContent>*/}
                            </div>
                            <div variant="outlined" className='mt-3 pb-3 card border-color'>
                                <div className='d-block col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>
                                        {this.state.onEdit ? 'Chỉnh sửa thông tin tài khoản' : 'Thông tin tài khoản'}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className='justify-center img-ava-wrapper' style={{
                                        textAlign: 'center',
                                        backgroundImage: data.urlImage ? `url(${data.urlImage}` : `url("/images/no-avatar-png.png")`
                                    }}>
                                        <span className="fas fa-camera change-avt-icon" title="Tải lên ảnh đại diện"
                                              onClick={this.showModalUploadAvatar}/>
                                    </div>
                                </div>
                                {/*<div className='text-center mt-3 mb-4' style={{height: 120}}>*/}
                                {/*    <span*/}
                                {/*        className='img-thumbnail'*/}
                                {/*        style={{*/}
                                {/*            width: 130,*/}
                                {/*            height: 130,*/}
                                {/*            overflow: 'hidden',*/}
                                {/*            display: 'inline-block',*/}
                                {/*            padding: 2*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <img*/}
                                {/*            // src={`https://www.uhy.vn/sites/default/files/uhyco.ltd_.jpg`}*/}
                                {/*            src={data.urlImage ? data.urlImage : "https://www.w3schools.com/howto/img_avatar.png"}*/}
                                {/*            className='mh-100 mw-100 cursor-pointer'*/}
                                {/*            onClick={() => this.setState({modalUploadAvatar: true})}*/}
                                {/*        />*/}
                                {/*    </span>*/}
                                {/*    {this.state.onEdit ?*/}
                                {/*        <Dropzone onDrop={acceptedFiles => console.log("img>>>", acceptedFiles)}>*/}
                                {/*            {({getRootProps, getInputProps}) => (*/}
                                {/*                <span title='Thay đổi logo' className='change-avt-1'>*/}
                                {/*                    <span {...getRootProps()}>*/}
                                {/*                        <input {...getInputProps()} />*/}
                                {/*                        <CameraAltOutlinedIcon/>*/}
                                {/*                    </span>*/}
                                {/*                </span>*/}
                                {/*            )}*/}
                                {/*        </Dropzone>*/}
                                {/*        : <></>*/}
                                {/*    }*/}

                                {/*</div>*/}
                                <div className='m-4 font-14'>
                                    <div className="row">
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Họ và tên
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Họ và tên</label>
                                            }
                                            {/*<label className="text-black-50">Họ và tên {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                // <TextField
                                                //     fullWidth
                                                //     variant={"outlined"}
                                                //     InputProps={{style: {height: 28}}}
                                                //     value={fullName}
                                                //     onChange={(val) => {
                                                //         if (fullName.length < 50    ) this.setState({
                                                //             fullName: val.target.value,
                                                //             inEditing: true
                                                //         }, () => {
                                                //             fullName.length != 0 ? this.setState({fullNameError: false, btnSaveStatus:true}) : this.setState({fullNameError: true, btnSaveStatus:false})
                                                //         })
                                                //     }}
                                                //     maxLength={50}
                                                //     error={this.state.fullNameError}
                                                //     helperText={this.state.fullNameError && 'Vui lòng nhập tên người đại diện !!'}
                                                // />
                                                <input type="text"
                                                       className={this.state.fullNameError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nhập họ và tên'
                                                       value={fullName}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           fullName: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.fullName.length == 0 ? this.setState({
                                                               fullNameError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               fullNameError: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : data.fullName ? data.fullName.toUpperCase() : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Giới tính
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Giới tính</label>
                                            }
                                            {/*<label className="text-black-50">Giới tính {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                // <FormControl component="fieldset" className='m-0'>
                                                //     <RadioGroup aria-label="Gender" className='d-inline ' value={data.sex}
                                                //                 onChange={(val) => this.setState({
                                                //                     sex: val.target.value,
                                                //                     inEditing: true
                                                //                 })
                                                //                 }
                                                //     >
                                                //         <FormControlLabel value="FEMALE" control={<StyledRadio/>}
                                                //                           label="Nữ"/>
                                                //         <FormControlLabel value="MALE" control={<StyledRadio/>}
                                                //                           label="Nam"/>
                                                //     </RadioGroup>
                                                // </FormControl>
                                                <div className='d-flex col-12'>
                                                    <div className="form-check col-6 ">
                                                        <input className="form-check-input" type="radio"
                                                               name="femaleRadios" id="female" value="FEMALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       sex: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.sex === 'FEMALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nữ
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-6">
                                                        <input className="form-check-input" type="radio"
                                                               name="maleRadios" id="male" value="MALE"
                                                               onClick={(val) => {
                                                                   this.setState({
                                                                       sex: val.target.value,
                                                                       inEditing: true
                                                                   })
                                                               }}
                                                               checked={this.state.sex === 'MALE'}
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="exampleRadios1">
                                                            Nam
                                                        </label>
                                                    </div>
                                                </div>
                                                : data.sex ? data.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">CMT/CCCD
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">CMT/CCCD</label>
                                            }
                                            {/*<label className="text-black-50">*/}
                                            {/*    CMT/CCCD {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}*/}
                                            {/*</label>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <TextField
                                                    fullWidth
                                                    variant={"outlined"}
                                                    InputProps={{style: {height: 28}}}
                                                    value={identify}
                                                    onChange={(val) => {
                                                        // debugger
                                                        // console.log(val)
                                                        this.handleChangeIdentify(val)
                                                    }}

                                                    error={this.state.identifyError}
                                                    helperText={this.state.identifyError && 'Vui lòng nhập số CMT/CCCD !!'}

                                                />
                                                : data.identify ? data.identify : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Ngày cấp
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Ngày cấp</label>
                                            }
                                            {/*<label className="text-black-50">*/}
                                            {/*    Ngày cấp {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}*/}
                                            {/*</label>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                // <TextField
                                                //     type="date"
                                                //     variant="outlined"
                                                //     size="small"
                                                //     fullWidth
                                                //     required
                                                //     inputProps={{style: {paddingLeft: 6}}}
                                                //     InputProps={{style: {height: 28, margin: 0, fontSize: 14}}}
                                                //     InputLabelProps={{
                                                //         shrink: true,
                                                //     }}
                                                //     value={dateIssued}
                                                //     onChange={(val) => {
                                                //         // this.setState({
                                                //         //     dateIssued: val.target.value,
                                                //         //     inEditing: true
                                                //         // })
                                                //         console.log("dateIss>>>>>>", val)
                                                //     }}
                                                //     helperText={this.state.dateIssuedError}
                                                //     error={this.state.dateIssuedError && 'Vui lòng chọn ngày cấp !!'}
                                                // />
                                                <DatePicker
                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                    className="w-auto h-100 kt-input pd-4-input hei-input"
                                                    selected={this.state.dateIssued ? new Date(this.state.dateIssued) : ''}
                                                    maxDate={new Date()}
                                                    dateFormat="dd-MM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    // showMonthYearDropdown
                                                    onSelect={(val) => {
                                                        this.setState({
                                                            dateIssued: val,
                                                            inEditing: true
                                                        }, () => console.log("dateIISS?>>>>>", this.state.dateIssued))
                                                    }
                                                    }
                                                    onChange={(date) => {
                                                        console.log("fucking date selected>>>>", date);
                                                        this.setState({
                                                            dateIssued: moment(date).format("YYYY-MM-DD"),
                                                            inEditing: true
                                                        })
                                                    }
                                                    }
                                                />
                                                : data.dateIssued ? moment(data.dateIssued).format(formatDate) : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Nơi cấp
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Nơi cấp</label>
                                            }
                                            {/*<label className="text-black-50">*/}
                                            {/*    Nơi cấp {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}*/}
                                            {/*</label>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                // <TextField
                                                //     fullWidth
                                                //     variant={"outlined"}
                                                //     InputProps={{style: {height: 28}}}
                                                //     value={placeIssued}
                                                //     onChange={(val) => {
                                                //         if (placeIssued.length < 50) this.setState({
                                                //             placeIssued: val.target.value,
                                                //             inEditing: true
                                                //         }, () => {
                                                //             this.state.placeIssued.length != 0 ? this.setState({
                                                //                 placeIssuedError: false,
                                                //                 btnSaveStatus: true
                                                //             }) : this.setState({
                                                //                 placeIssuedError: true,
                                                //                 btnSaveStatus: false
                                                //             })
                                                //         })
                                                //     }}
                                                //     error={this.state.placeIssuedError}
                                                //     helperText={this.state.placeIssuedError && 'Vui lòng nhập nơi cấp  !!'}
                                                //     maxLength={50}
                                                <input type="text"
                                                       className={this.state.placeIssuedError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nơi cấp'
                                                       value={placeIssued}
                                                       maxLength={50}
                                                       onChange={(val) => this.setState({
                                                           placeIssued: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.placeIssued.length == 0 ? this.setState({
                                                               placeIssuedError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               placeIssuedError: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : data.placeIssued ? data.placeIssued : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                                            {/*<label className="text-black-50">Vị trí công việc </label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Vị trí công việc</label>
                                                : <label className="text-black-50">Vị trí công việc</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                            {this.state.onEdit === true
                                                ? <p className="mt-1">{renderPosition(jobDetail)}</p>
                                                : <span> {renderPosition(jobDetail)}</span>}
                                        </div>
                                        <div
                                            className="pl-0 pb-1 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                                            {/*<label className="text-black-50">Số điện thoại</label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Số điện thoại</label>
                                                : <label className="text-black-50">Số điện thoại</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-1 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                            {this.state.onEdit === true
                                                ? <p className="mt-1">{data.phone ? data.phone : ''}</p>
                                                : <span>{data.phone ? data.phone : ''}</span>}
                                            {/*<span>{data.phone ? data.phone : ''}</span>*/}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile h-36">
                                            {/*<label className="text-black-50">Email </label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Email<span className={'color-red d-inline'}>*</span></label>

                                                : <label className="text-black-50">Email</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile h-36">
                                            {this.state.onEdit === true
                                                ? <p className="mt-1">{data.email ? data.email : ''}</p>
                                                : <span>{data.email ? data.email : ''}</span>}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {/*<label className="text-black-50">Tỉnh/Thành phố {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Tỉnh/Thành Phố
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Tỉnh/Thành Phố</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={provinceId || data.provinceId}
                                                    defaultValue={0}
                                                    onChange={(e) => {
                                                        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                        let id = 0;
                                                        id = _district?.filter(item => item.provinceId == e.target.value)[0].id;
                                                        this.setState({
                                                            provinceId: e.target.value,
                                                            districtId: id,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >
                                                    <option value={0}>Chọn Tỉnh/Thành Phố</option>
                                                    {renderProvince()}
                                                </select>
                                                : data.provinceId ? renderProvinceId(data.provinceId) : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {/*<label className="text-black-50">Quận/Huyện {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Quận/Huyện
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Quận/Huyện</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={districtId || data.districtId}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            districtId: e.target.value,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >
                                                    {this.renderDistrict(provinceId ? provinceId : data.provinceId)}
                                                </select>
                                                : data.districtId ? renderDistrictId(data.districtId) : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {/*<label className="text-black-50">Địa chỉ {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Địa chỉ
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Địa chỉ</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                // <TextField
                                                //     fullWidth
                                                //     variant={"outlined"}
                                                //     InputProps={{style: {height: 28}}}
                                                //     value={address}
                                                //     onChange={(val) => {
                                                //         if (address.length < 101) {
                                                //             this.setState({
                                                //                 address: val.target.value,
                                                //                 inEditing: true
                                                //             }, () => {
                                                //                 address.length != 0 ? this.setState({
                                                //                     addressError: false,
                                                //                     btnSaveStatus: true
                                                //                 }) : this.setState({
                                                //                     addressError: true,
                                                //                     btnSaveStatus: false
                                                //                 })
                                                //             })
                                                //         } else {
                                                //             this.setState({
                                                //                 address: val.target.value,
                                                //
                                                //             })
                                                //             this.props.enqueueSnackbar('Độ dài địa chỉ không được quá 100 ký tự', {
                                                //                 anchorOrigin: {
                                                //                     vertical: "top",
                                                //                     horizontal: "right"
                                                //                 },
                                                //                 variant: 'error',
                                                //             });
                                                //         }
                                                //     }}
                                                //     maxLength={100}
                                                //     error={this.state.addressError}
                                                //     helperText={this.state.addressError && 'Vui lòng nhập địa chỉ người đại diện !!'}
                                                //
                                                // />
                                                <input type="text"
                                                       className={this.state.addressError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                       placeholder='Nơi cấp'
                                                       value={address}
                                                       maxLength={100}
                                                       onChange={(val) => this.setState({
                                                           address: val.target.value.trimLeft(),
                                                           inEditing: true
                                                       }, () => {
                                                           this.state.address.length == 0 ? this.setState({
                                                               addressError: true,
                                                               btnSaveStatus: false
                                                           }) : this.setState({
                                                               addressError: false,
                                                               btnSaveStatus: true
                                                           })
                                                       })}
                                                />
                                                : data.address ? data.address : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            {/*<label className="text-black-50">Khu vực hỗ trợ {this.state.onEdit &&*/}
                                            {/*<span className={'color-red d-inline'}>*</span>}</label>*/}
                                            {this.state.onEdit === true
                                                ? <label className="text-black-50 m-1">Khu vực hỗ trợ
                                                    <span className={'color-red d-inline'}>*</span></label>
                                                : <label className="text-black-50">Khu vực hỗ trợ</label>
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {this.state.onEdit ?
                                                <select
                                                    className="form-control kt-input pd-4-input hei-input"
                                                    value={areaId || data.areaId}
                                                    defaultValue={0}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            areaId: e.target.value,
                                                            inEditing: true
                                                        })
                                                    }}
                                                >

                                                    {/*<option value={0}>Chọn Tỉnh/Thành Phố</option>*/}
                                                    {this.state.AreaSup?.map((item) => (
                                                        <option value={item.id}>{item.name}</option>))}
                                                </select>
                                                : data.areaId ? renderAreaId(data.areaId) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                {this.state.onEdit ?
                                    <div className="justify-flex-center">
                                        <div className='text-center col-12'>
                                            {this.state.loading &&
                                            <button
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            >
                                                <CircularProgress size={20} variant="determinate"
                                                                  value={this.state.progress}/>
                                            </button>}
                                            {!this.state.loading &&
                                            <button
                                                disabled={!this.state.btnSaveStatus}
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={() => this.handleSave()}
                                            >
                                                Lưu
                                            </button>
                                            }
                                            <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.handleCancel()}
                                            >
                                                Hủy
                                            </button>
                                        </div>
                                    </div>
                                    : <div className="justify-flex-center">
                                        <div className='col-12 text-center'
                                             style={{textTransform: 'initial'}}
                                        >
                                            <button
                                                type="button"
                                                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                onClick={() => this.setState({onEdit: !this.state.onEdit})}
                                            >
                                                Chỉnh sửa thông tin
                                            </button>
                                            <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => this.handleShowModalForgotPassword()}
                                            >
                                                Đổi mật khẩu
                                            </button>
                                        </div>
                                    </div>
                                }
                                {/*{this.state.onEdit ? */}
                                {/*    <Grid container spacing={2} justify={"flex-center"}>*/}
                                {/*        <Grid item xs={12} sm={12} md={12} className='text-center'>*/}
                                {/*            {this.state.loading &&*/}
                                {/*            <Button variant="outlined" color="primary"*/}
                                {/*                    className='mr-3'*/}
                                {/*                    style={{textTransform: 'initial'}}*/}
                                {/*            >*/}
                                {/*                <CircularProgress size={20} variant="determinate"*/}
                                {/*                                  value={this.state.progress}/>*/}
                                {/*            </Button>}*/}
                                {/*            {!this.state.loading &&*/}
                                {/*            <Button disabled={!this.state.btnSaveStatus} variant="contained"*/}
                                {/*                    color="primary"*/}
                                {/*                    className='mr-3'*/}
                                {/*                    style={{textTransform: 'initial'}}*/}
                                {/*                    onClick={() => this.handleSave()}*/}
                                {/*            >*/}
                                {/*                Lưu*/}
                                {/*            </Button>}*/}
                                {/*            <Button variant="outlined" style={{textTransform: 'initial'}}*/}
                                {/*                    onClick={() => this.handleCancel()}*/}
                                {/*            >*/}
                                {/*                Hủy*/}
                                {/*            </Button>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*    : <Grid container spacing={3} justify={"flex-center"}>*/}
                                {/*        <Grid item xs={12} sm={12} md={12} className='text-center'*/}
                                {/*              style={{textTransform: 'initial'}}*/}
                                {/*        >*/}
                                {/*            <Button variant="contained"*/}
                                {/*                    className='rounded mr-md-3 mr-sm-3 mr-lg-3 mb-1' color='primary'*/}
                                {/*                    style={{textTransform: 'initial'}}*/}
                                {/*                    onClick={() => this.setState({onEdit: !this.state.onEdit})}*/}
                                {/*            >*/}
                                {/*                Chỉnh sửa thông tin*/}
                                {/*            </Button>*/}
                                {/*            <Button variant="outlined" className='rounded mb-1'*/}
                                {/*                    style={{textTransform: 'initial'}}*/}
                                {/*                    onClick={() => this.handleShowModalForgotPassword()}*/}
                                {/*            >*/}
                                {/*                <a className='ml-3 mr-3'>Đổi mật khẩu</a>*/}
                                {/*            </Button>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*}*/}


                                {/*{this.state.confirmCancel && <Dialog*/}
                                {/*    open={true}*/}
                                {/*    onClose={() => this.setState({confirmCancel: false})}*/}
                                {/*>*/}
                                {/*    <DialogTitle>Thông tin đã thay đổi, bạn có chắc muốn hủy ?</DialogTitle>*/}
                                {/*    <DialogActions>*/}
                                {/*        <Button*/}
                                {/*            style={{textTransform: 'initial'}}*/}
                                {/*            color="primary"*/}
                                {/*            onClick={() => this.setState({*/}
                                {/*                confirmCancel: false,*/}
                                {/*                inEditing: false*/}
                                {/*            }, () => this.handleCancel())}*/}
                                {/*        >*/}
                                {/*            Có*/}
                                {/*        </Button>*/}
                                {/*        <Button*/}
                                {/*            color="primary"*/}
                                {/*            style={{textTransform: 'initial'}}*/}
                                {/*            onClick={() => this.setState({confirmCancel: false})}*/}
                                {/*        >*/}
                                {/*            Không*/}
                                {/*        </Button>*/}
                                {/*    </DialogActions>*/}
                                {/*</Dialog>}*/}
                            </div>

                            {this.state.allUserTypes.length > 1 &&
                            <div variant="outlined" className='pb-2 mb-5 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Quản lý tài khoản</span>
                                </div>
                                <div className="table-wrapper col-12">
                                    <table className="table mt-5 mb-5 table-striped table-data table-bordered ">
                                        <thead>
                                        <TableHeader header={this.state.accounts} clickSort={(data) => {
                                        }}/>
                                        </thead>
                                        <tbody>
                                        {this.state.allUserTypes.length !== 0
                                            ? this.state.allUserTypes.map((item, index) =>
                                                <tr>
                                                    <th scope="row" className='text-center'>{index + 1}</th>
                                                    <td className='text-center'>{item.userType}</td>
                                                    <td className='text-center'>
                                                        <div>
                                                            {this.state.currentUserType != item.userType && <Button disabled={this.state.btnSaveStatus} variant="contained" color="primary"
                                                                                                                    className='mr-3 btn-primary'
                                                                                                                    style={{textTransform: 'initial'}}
                                                                                                                    onClick={() => this.switchUserType(item.userType)}
                                                            >
                                                                Chuyển tài khoản
                                                            </Button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                            : <tr>
                                                <td colSpan={4}>
                                                    <div><span><h6>Không có dữ liệu</h6></span></div>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                            <ModalChangePassword show={this.state.showModalChangePassword}
                                                 onHideModal={this.onCloseModalForgotPassword}/>
                            <ModalConfirmEdit show={this.state.confirmCancel}
                                              onHide={() => this.setState({confirmCancel: false})}
                                              handleCancel={
                                                  () => this.setState({
                                                      confirmCancel: false,
                                                      inEditing: false
                                                  }, () => this.handleCancel())
                                              }
                            />
                            {this.state.modalUploadAvatar && <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                                                                onHide={() => this.setState({modalUploadAvatar: false})}
                                                                                updateSuccess={() => {
                                                                                    this.setState({modalUploadAvatar: false})
                                                                                    this.getAccountInfo()
                                                                                }}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile
