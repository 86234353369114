import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let SupervisorApi = {
    adminOneBookGetSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-get-supervisor?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    adminOneBookGetSupervisorDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-get-detail-supervisor/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    adminOneBookActiveSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-active-supervisor/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    adminOneBookBlockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-block-supervisor/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    adminOneBookUnblockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-unblock-supervisor/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    adminAccept: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-accept/${payload.id}`,
            method: 'POST',
            body: payload
        }, callback)
    },
    adminOneBookDeleteSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-one-book-delete-supervisor`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback)
    },
    searchByFullNameAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-partner-search-supervisor-by-name-and-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    partnerUpdateSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-partner-update-supervisor`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    blockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/block/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    confirmEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/confirm-email`,
            method: 'POST',
            body: payload
        }, callback)
    },
    deleteSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/delete`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback)
    },
    detailSupervisor: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/detail`,
            method: 'GET'
        }, callback)
    },
    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-detail/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getIdsByPartnerId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-ids-by-partner`,
            method: 'GET'
        }, callback)
    },
    findByPartnerId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-list-by-partner-id`,
            method: 'GET'
        }, callback)
    },
    getListSupervisorSelectChange: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-list-supervisor-select-change?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getListSupervisorSelectOrder: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-list-supervisor-select-order?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getNameById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-name-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getNamesByListId: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/get-names-by-ids?ids=${param}`,
            method: 'GET'
        }, callback)
    },
    partnerCheckPermission: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/partner-check-permission?ids=${param}`,
            method: 'GET'
        }, callback)
    },
    partnerCheckPermissionById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/partner-check-permission/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    partnerCreate: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/partner-create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    searchByFullName: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/search-by-fullname?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    searchByFullNameAndAddress: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/search-by-fullname-and-address?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    unblockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/unblock/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    updateSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/update`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    updateReassignContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/update-reassign-contract`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },
    partnerResetPasswordSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/partner-reset-password-supervisor`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    updateHighlightSupImg: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/admin-onebook-update-image-highlight-supervisor`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    setHighlight: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/highlight/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    setUnHighlight: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/supervisor/unHighlight/${payload.id}`,
            method: 'GET',
        }, callback)
    }
};
export default SupervisorApi
