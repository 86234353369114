import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicServiceQuotation = {
    getProgramByPlanId: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/public/program/detail?${stringify(payload)}`,
            method: 'GET'
        }, callback);
    },
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-quotation/get-all`,
            method: 'GET'
        }, callback)
    },
    getByBusiness: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-quotation/get-by-business?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getByServicePlan: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-quotation/get-by-service-plan-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getByServicePlanAndBusiness: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/service-quotation/get-by-service-plan-id-and-business?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },


    getPricePlanbyServicePlanAndRealm: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/service-plan-quotation/get-by-service-plan-and-realm?${stringify(payload)}`,
            // url: `${URL_API}/api/one-book-user/public/service-plan-quotation/get-by-service-plan-and-realm?realmId=1&servicePlanId=${payload.servicePlanId}`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
}
export default PublicServiceQuotation
