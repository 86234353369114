/**
 * User: N. Thinh
 * Create: --/--/----
 * Time: ----
 *
 * User: N. Ninh
 * Update: 23/10/2020
 * Time: 13:52
 *
 * User: Le.Cong.Hai
 * Update: 25/03/2020
 * Time: 9:20
 */

import React, { Component } from "react";
import "./index.css";

import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import TableHeader from "../../../components/TableHeader/index";
import PageHeader from "../../../components/PageHeader";
import Pagination from "../../../components/Pagination";
import ModalBlockAdmin from "../../../components/Modal/modalBlockAdmin";
import ModalUnblockAdmin from "../../../components/Modal/modalUnblockAdmin";
import ModalDeleteAdmin from "../../../components/Modal/modalDeleteAdmin";
import ModalDetail from "../../../components/Modal/modalDetailPartnerUser";
import ModalUpdateAdmin from "../../../components/Modal/modalUpdatePartnerUser";
import ModalAddAdministratior from "../../../components/Modal/modalAddAdministratior";

import PartnerApi from "../../../services/ob-user/partner";

import lang from "./lang";

class AdminManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlock: false,
            showDelete: false,
            showAdd: false,
            showModalDetai: false,
            showUnblock: false,
            showUpdate: false,
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            itemSelected: '',
            permissionId: null,
            status: "",
            sort: "id=asc",
            header: [
                {
                    id: "index",
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false
                },
                {
                    id: "fullName",
                    name: "Tên Admin",
                    style: { textAlign: "center" },
                    // style: {minWidth: 130, textAlign: "left"},
                    sort: false
                },
                {
                    id: "phone",
                    name: "Số điện thoại",
                    style: { textAlign: "center" },
                    // style: {minWidth: 130, textAlign: "center"},
                    sort: false
                },
                {
                    id: "permissionName",
                    name: "Tên nhóm quyền",
                    style: { textAlign: "center" },
                    // style: {minWidth: 100, textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: { textAlign: "center" },
                    // style: {minWidth: 120, textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    // style: {textAlign: "center"},
                    style: { minWidth: 120, textAlign: "center" },
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        this.getListData();
    }

    getListData() {
        let { pageNumber, pageSize, permissionId, sort, status } = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            permissionId: permissionId,
            partnerStatus: status
            // sort: sort
        };
        PartnerApi.getListAdmin(payload, res => {
            console.log(res)
            if (res.success === true) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize)
                })
            }
        });
    };

    renderStatus(status) {
        switch (status) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    }

    // renderIconStatus(row) {
    //     switch (row.status) {
    //         case "WAIT_COMPLETE_INFO":
    //         case "WAIT_CONFIRM_EMAIL":
    //         case "ACTIVE":
    //             return (
    //                 <span>
    //                     <Link to={{}}>
    //                         <button className="btn btn-action" title="Chi tiết"
    //                         onClick={() => {
    //                                 this.setState({itemSelected: row.id}, () => this.setState({showModalDetail: true}))
    //                                 }}
    //                                 >
    //                             <i className="ti-book" />
    //                         </button>
    //                     </Link>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Khóa"
    //                         onClick={() => {
    //                             this.setState({ itemSelected: row.id }, () => this.setState({ showBlock: true }))
    //                         }}
    //                     >
    //                         <i className='ti-lock' />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Xóa"
    //                         onClick={() => {
    //                             this.setState({ itemSelected: row.id }, () => this.setState({ showDelete: true }))
    //                         }}
    //                     >
    //                         <i className="ti-trash" />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Sửa"
    //                         onClick={() => {
    //                         }}
    //                     >
    //                         <i className="ti-pencil-alt" />
    //                     </button>
    //                 </span>
    //             );
    //         case "LOCK":
    //             return (
    //                 <span>
    //                     <Link to={{}}>
    //                         <button className="btn btn-action" title="Chi tiết">
    //                             <i className="ti-book" />
    //                         </button>
    //                     </Link>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Mở khóa"
    //                         onClick={() => {
    //                             this.setState({ itemSelected: row.id }, () => this.setState({ showUnblock: true }))
    //                         }}
    //                     >
    //                         <i className='ti-unlock' />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Xóa"
    //                         onClick={() => {
    //                             this.setState({ itemSelected: row.id }, () => this.setState({ showDelete: true }))
    //                         }}
    //                     >
    //                         <i className="ti-trash" />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Sửa"
    //                         onClick={() => {
    //                         }}
    //                     >
    //                         <i className="ti-pencil-alt" />
    //                     </button>
    //                 </span>
    //             );
    //         case "CANCELED":
    //             return (
    //                 <span>
    //                     <Link to={{}}>
    //                         <button className="btn btn-action" title="Chi tiết">
    //                             <i className="ti-book" />
    //                         </button>
    //                     </Link>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Khóa"
    //                         disabled
    //                         style={{ color: '#ccc', cursor: 'unset' }}
    //                     >
    //                         <i className='ti-lock' />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Xóa"
    //                         disabled
    //                         style={{ color: '#ccc', cursor: 'unset' }}
    //                     >
    //                         <i className="ti-trash" />
    //                     </button>
    //                     <button
    //                         className="btn btn-action"
    //                         title="Sửa"
    //                         disabled
    //                         style={{ color: '#ccc', cursor: 'unset' }}
    //                     >
    //                         <i className="ti-pencil-alt" />
    //                     </button>
    //                 </span>
    //             );
    //         default:
    //             return;
    //     }
    // }

    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.getListData())
    };

    render() {
        let { listData, pageNumber, pageSize, totalItem, header } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="admin-management" parentClass="staff-management-partner" />
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title='Danh sách Admin User' />
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3">
                                                <label className="label-item">Nhóm quyền</label>
                                                <select
                                                    value={this.state.permissionId}
                                                    onChange={val => this.setState({ permissionId: val.target.value }, () =>
                                                        console.log(this.state.permissionId)
                                                    )}
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value="1">Nhóm quản lý hợp đồng</option>
                                                    <option value="2">Nhóm quản lý nhân sự</option>
                                                    <option value="3">Nhóm quản lý ví</option>
                                                    <option value="4">Nhóm quản Order</option>
                                                    {/*<option value="REJECTED">Từ chối</option>*/}
                                                </select>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0">
                                                <label className="label-item">Trạng thái</label>
                                                {/*<input*/}
                                                {/*    placeholder="0123456789"*/}
                                                {/*    type="tel"*/}
                                                {/*    className="form-control shadow-none font-14"*/}
                                                {/*    style={{width: "100%", height: 35, padding: 8}}*/}
                                                {/*    value={this.state.phone}*/}
                                                {/*    maxLength={10}*/}
                                                {/*    // onKeyUp={e => {*/}
                                                {/*    //     if (e.key === "Enter") this.getListData();*/}
                                                {/*    // }}*/}
                                                {/*    onChange={val => this.setState({phone: val.target.value.replace(/[^0-9]/g, '')})}*/}
                                                {/*/>*/}
                                                <select
                                                    value={this.state.status}
                                                    onChange={val => this.setState({ status: val.target.value }, () =>
                                                        console.log(this.state.status)
                                                    )}
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value="ACTIVE">Đang hoạt động</option>
                                                    <option value="LOCK">Đã khóa</option>
                                                    <option value="CANCELED">Đã xóa</option>
                                                    <option value="WAIT_COMPLETE_INFO">Chờ hoàn thiện thông tin</option>
                                                    <option value="WAIT_CONFIRM_EMAIL">Chờ xác nhận email</option>
                                                </select>
                                            </div>
                                            <div className='mt-3 col-xs-12 col-sm-12 col-md-4 align-text-bottom pl-0 '>
                                                <button
                                                    type="button"
                                                    style={{ height: 35 }}
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            pageNumber: 0,
                                                        }, () => this.getListData())
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    style={{ height: 34 }}
                                                    onClick={() => {
                                                        this.setState({
                                                            status: '',
                                                            pageNumber: 0,
                                                            permissionId: ''
                                                        }, () => this.getListData())
                                                    }}
                                                >
                                                    Refresh
                                                </button>
                                                <button
                                                    style={{ height: 34 }}
                                                    type="button"
                                                    className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({ showAdd: true })
                                                    }}
                                                >
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-responsive table-bordered">
                                            <table className="table">
                                                <thead>
                                                    <TableHeader
                                                        header={header}
                                                        clickSort={data => {
                                                            console.log(data);
                                                            let a = "";
                                                            if (data.sortAsc == null) {
                                                                a = "id=asc";
                                                            } else {
                                                                a = `${data.sortId}=${
                                                                    data.sortAsc ? "asc" : "desc"
                                                                    }`;
                                                            }
                                                            console.log(a);
                                                            this.setState(
                                                                {
                                                                    sort: a
                                                                },
                                                                () => this.getListData()
                                                            );
                                                        }}
                                                    />
                                                </thead>
                                                {!this.state.listData || this.state.listData.length === 0 ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <div className="text-center">
                                                                    Không có dữ liệu
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                        <tbody>
                                                            {this.state.listData.map((row, index) => (
                                                                <tr style={{ height: 50 }}>
                                                                    <td style={{ textAlign: "center" }}>{this.state.pageNumber * this.state.pageSize + index + 1}</td>
                                                                    <td style={{ textAlign: "left" }}>{row.representative}</td>
                                                                    <td style={{ textAlign: "center" }}>{row.representativePhone}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {!row.permission ? "Chưa có" : row.permission}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {this.renderStatus(row.status)}
                                                                    </td>
                                                                    <td className='p-1' style={{textAlign: 'center'}}>
                                                    <button className="btn btn-action p-2"
                                                            title="Chi tiết"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showModalDetail: true}))
                                                            }}
                                                    >
                                                        <i className="ti-book"/>
                                                    </button>
                                                    <button
                                                        className="btn btn-action p-2"
                                                        title={row.status === "LOCK" ? "Mở Khóa" : "Khóa"}
                                                        disabled={row.status === "WAIT_ACTIVE" || row.status === "CANCELED" || row.status === "WAIT_COMPLETE_INFO"}
                                                        onClick={() => {
                                                            if (row.status === "LOCK") {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showUnblock: true}))
                                                            } else {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showBlock: true}))
                                                            }
                                                        }}
                                                    >
                                                        <i className={row.status === "LOCK" ? "ti-unlock" :"ti-lock"}/>
                                                    </button>
                                                    <button
                                                        className="btn btn-action p-2"
                                                        title="Xóa"
                                                        disabled={row.status === "CANCELED"}
                                                        onClick={() => {
                                                            this.setState({itemSelected: row.id}, () => this.setState({showDelete: true}))
                                                        }}
                                                    >
                                                        <i className="ti-trash"/>

                                                    </button>
                                                    {/* <button
                                                        className="btn btn-action p-2"
                                                        title="Sửa"
                                                        disabled={row.status === "CANCELED"}
                                                        onClick={() => {
                                                            this.setState({itemSelected: row.id}, () => this.setState({showUpdate: true}))
                                                        }}
                                                    >
                                                        <i className="ti-pencil-alt"/>
                                                    </button> */}
                                                    <button
                                                        className="btn btn-action p-2"
                                                        title="Đổi mật khẩu"
                                                        disabled={row.status !== "ACTIVE"}
                                                        onClick={() => {
                                                            this.setState({
                                                                itemSelected: row.id,
                                                                nameUser: row.fullName
                                                            }, () => this.setState({showModalReset: true}))
                                                        }}
                                                    >
                                                        <i className="ti-reload"/>
                                                    </button>
                                                </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    )}
                                            </table>
                                        </table>
                                    </div>
                                    <div className="pagination-right">
                                        <Pagination
                                            activePage={pageNumber + 1}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showBlock && <ModalBlockAdmin id={this.state.itemSelected}
                    show={this.state.showBlock}
                    onRefresh={() => this.getListData()}
                    onHide={() => this.setState({ showBlock: false })} />}
                {this.state.showModalDetail && <ModalDetail id={this.state.itemSelected}
                    show={this.state.showModalDetail}
                    onHide={() => this.setState({ showModalDetail: false })}
                />}
                {this.state.showDelete && <ModalDeleteAdmin id={this.state.itemSelected}
                    show={this.state.showDelete}
                    onRefresh={() => this.getListData()}
                    onHide={() => this.setState({ showDelete: false })} />}
                {this.state.showUpdate && <ModalUpdateAdmin id={this.state.itemSelected}
                    show={this.state.showUpdate}
                    onRefresh={() => this.getListData()}
                    onHide={() => this.setState({ showUpdate: false })} />}
                {this.state.showUnblock && <ModalUnblockAdmin id={this.state.itemSelected}
                    show={this.state.showUnblock}
                    onRefresh={() => this.getListData()}
                    onHide={() => this.setState({ showUnblock: false })} />}
                {this.state.showAdd && <ModalAddAdministratior show={this.state.showAdd}
                    onHide={() => this.setState({ showAdd: false })}
                    onRefresh={() => this.getListData()} />}

            </div>
        );
    }
}

export default AdminManagement;
