export default {
    header: {

    },
    footer: {

    },
    menu: {
        guest: 'Guest',
        profile: 'Profile',
        listBrandname: 'List Brandname',
        listPhoneNumber: 'List phone number',
        listRequest: 'List request',
        createRequest: 'Create request',
        logout: 'Logout'
    }
}
