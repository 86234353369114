import React from 'react';
import {withSnackbar} from "notistack";
import Utils from  '../../utils/utils'
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import PartnerApi from "../../services/ob-user/partner";

class ModalAddAdministratior extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            phone:'',
            email:'',
            pass:'',
            // repass:'',
            role:'',
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            RoleErr: false,
            passErr: false,
            // repassErr:false,
            btnStatus: false,
        };
    }
    componentDidMount() {
        this.setState({
            name:'',
            phone:'',
            email:'',
            pass:'',
            // repass:'',
            role: '',

            nameErr: false,
            phoneErr: false,
            emailErr: false,
            passErr: false,
            // repassErr:false,
            RoleErr: '',
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({
                name:'',
                phone:'',
                email:'',
                pass:'',
                // repass:'',

                nameErr: false,
                phoneErr: false,
                emailErr: false,
                passErr: false,
                // repassErr:false,
            });
        }
    }

    onSubmit =()=>{
        if(this.state.name.length  == 0 && this.state.phone.length == 0  && this.state.pass.length == 0 && this.state.email.length == 0){
            this.setState({
                nameErr: true,
                phoneErr: true,
                emailErr: true,
                passErr: true,
                // RoleErr: true,
                // repassErr: true,
            });
            this.props.enqueueSnackbar('Không được để trống thông tin !', {
                variant: 'error',
            });
        }else if(this.state.name.length  == 0 ){
            this.setState({nameErr: true});
            this.props.enqueueSnackbar('Không được để trống họ và tên !', {
                variant: 'error',
            });
        }else if(this.state.phone.length <10 || this.state.phone.length == 0){
            this.setState({phoneErr: true});
            // this.props.enqueueSnackbar('Số điện thoại sai định dạng hoặc đang để trống !', {
            //     variant: 'error',
            // });
        }else if(this.state.email.length == 0 ){
            this.setState({emailErr: true});
            this.props.enqueueSnackbar('Email đang để trống !', {
                variant: 'error',
            });
        // }else if(this.state.role.length == 0 ){
        //     this.setState({RoleErr: true});
        }else {
            this.addAdmin()
        }
    }

    addAdmin = () => {
        let payload = {
            email: this.state.email,
            fullName: this.state.name,
            password: "12345678@",
            phone: this.state.phone,
            // role: this.state.role
        }
        PartnerApi.partnerCreateAdmin(payload, res => {
            if (res.errorCode == "One-Book-Auth.E000003") {
                this.props.enqueueSnackbar(res.message[0], {
                    variant: 'error',
                });
            }
            else if (res.success) {
                this.props.enqueueSnackbar('Tạo mới admin thành công !', {
                    variant: 'success',
                });
                this.props.onRefresh();
                this.props.onHide();
                // this.props.onrefresh();
            }
            else  {
                return this.props.enqueueSnackbar('Tạo mới không thành công, vui lòng thử lại !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>TẠO MỚI ADMIN USER</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2">Họ và tên:</label>
                                <input
                                    className={this.state.nameErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder="Nhập họ và tên "
                                    maxLength={50}
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.name.length == 0 ? this.setState({nameErr: true, btnStatus: true}): this.setState({nameErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.nameErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Tên đang để trống !</label>
                            </div>:<div/>}

                            <div className={this.state.phoneErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Tên đăng nhập: </label>
                                <input
                                    className={this.state.phoneErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6 "}
                                    placeholder="Nhập số điện thoại"
                                    fullWidth
                                    maxLength={11}
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () =>{
                                            this.state.phone.length <10 || this.state.phone.length == 0 ? this.setState({phoneErr: true, btnStatus: true}): this.setState({phoneErr: false, btnStatus: false})
                                        }

                                    )}
                                />
                            </div>
                            {this.state.phoneErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">SĐT không đúng hoặc đang để trống !</label>
                            </div>:<div/>}

                            {/* <div className={this.state.RoleErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Tên nhóm quyền: </label>
                                <input
                                    className={this.state.RoleErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder="Nhập tên nhóm quyền "
                                    // maxLength={50}
                                    value={this.state.role}
                                    onChange={(e) => this.setState({role: e.target.value.trimLeft()
                                        },
                                        () =>{
                                            this.state.role.length == 0 ? this.setState({RoleErr: true, btnStatus: true}): this.setState({RoleErr: false, btnStatus: false})
                                        }
                                    )}
                                /> */}
                                <div className={this.state.emailErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Email: </label>
                                <input
                                    type="email"
                                    className={this.state.emailErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6 "}
                                    placeholder='Nhập email'
                                    fullWidth
                                    maxLength={100}
                                    // value={this.state.email}
                                    // onChange={(e) => this.setState({email: e.target.value.trim()},
                                    //     () =>{
                                    //         console.log('CHECKEMAIL>>>>>>',this.state.email);
                                    //         this.state.email.length == 0 ? this.setState({emailErr: true, btnStatus: true}): this.setState({emailErr: false, btnStatus: false})
                                    //     })}
                                    onChange={(e)=> {
                                        if (Utils.checkEmailRegex(e.target.value.trim())){
                                            // khoong dudng dinh dang
                                            this.setState({emailErr: true, btnStatus: true})
                                        }else{
                                            this.setState({email: e.target.value.trim(),emailErr: false, btnStatus: false})
                                        }
                                    }}
                                />
                                </div>
                            {/* </div>
                            {this.state.RoleErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Tên nhóm quyền đang để trống !</label>
                            </div>:<div/>} */}

                            {/*<div className={this.state.emailErr?"row mt-2":"row mt-3"}>*/}
                            {/*    <label className="col-6 mt-2">Nhóm quyền</label>*/}
                            {/*    <select*/}
                            {/*        className="w-100 col-6"*/}
                            {/*        // value={provinceId}*/}
                            {/*        // onChange={(e) => {*/}
                            {/*        //     let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';*/}
                            {/*        //     let _disfilter = _district?.filter(item => item.provinceId == e.target.value);*/}
                            {/*        //     this.setState({*/}
                            {/*        //         provinceId: e.target.value,*/}
                            {/*        //         districtId: _disfilter[0].id,*/}
                            {/*        //         inEditing: true*/}
                            {/*        //     })*/}
                            {/*        // }}*/}
                            {/*        // onSelect={() => this.onChangeDistrict}*/}
                            {/*    >*/}

                            {/*    </select>*/}
                            {/*</div>*/}
                            {/* <div className={this.state.contarctErr?"row mt-2":"row mt-3"}> */}
                            <div className="row mt-3">
                                <label className="col-6 mt-2">Mật khẩu</label>
                                <label className="col-6 mt-2">12345678@ </label>
                                {/* <input
                                    type='password'
                                    className={this.state.passErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder='Nhập mật khẩu'
                                    fullWidth
                                    maxLength={20}
                                    value={this.state.pass}
                                    onChange={(e) => this.setState({pass: e.target.value.trimLeft()},
                                        () =>{
                                            this.state.pass.length == 0 || this.state.pass.length < 6 ? this.setState({passErr: true, btnStatus: true}): this.setState({passErr: false, btnStatus: false})
                                        }
                                    )}
                                /> */}
                            </div>
                            {/* {this.state.passErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Mật khẩu không đúng định dạng hoặc đang để trống !</label>
                            </div>:''} */}

                            {/* <div className={this.state.repasstErr?"row mt-2":"row mt-3"}>
                                <label className="col-6 mt-2">Nhập lại mật khẩu</label>
                                <input
                                    type='password'
                                    className={this.state.repassErr?"inputPassword w-100 col-6 border-input-error":"inputPassword w-100 col-6"}
                                    placeholder='Nhập lại mật khẩu'
                                    fullWidth
                                    maxLength={20}
                                    value={this.state.repass}
                                    onChange={(e) => this.setState({repass: e.target.value.trimLeft()},
                                        () =>{
                                            this.state.repass.length == 0 || this.state.repass != this.state.pass ? this.setState({repassErr: true, btnStatus: true}): this.setState({repassErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.repassErr?<div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Nhập lại mật khẩu không đúng !</label>
                            </div>:''} */}
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-100 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Tạo mới
                                        </span>
                                    </button>
                                    {/*<button*/}
                                    {/*    type="button"*/}
                                    {/*    className="ml-4 col-2 border-1-s w-50 type-button-fix btn btn-light"*/}
                                    {/*    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }*/}
                                    {/*    onClick={this.props.onHide}*/}
                                    {/*>*/}
                                    {/*    <span className='d-flex justify-content-center'>*/}
                                    {/*        Hủy*/}
                                    {/*    </span>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAddAdministratior);
