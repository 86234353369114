import { stringify } from 'qs';
import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let VotingApi = {
    getListBySupAndAcc: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/voting/search-paging-by-sup-and-acc?${stringify(payload)}`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
}
export default VotingApi
