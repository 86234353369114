import React, { Component } from "react";
import Convert from "../../../../utils/convertUrlPra";
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableHeader from "../../../../components/TableHeader";

import PublicReportContractApi from "../../../../services/ob-core/public-report-contract";
import SecureContractApi from "../../../../services/ob-core/secure-contract";
import { toast } from "react-toastify";
import FilesApi from "../../../../services/resource/files";
import Pagination from "../../../../components/Pagination";
import { checkPermission } from "../../../../utils/common";

const formatDate = 'DD-MM-YYYY';

function renderReportType(type) {
    switch (type) {
        case "TAX_REPORT":
            return "Báo cáo thuế";
        default:
            return "Báo cáo tài chính";
    }
}

class ListReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listReport: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: { maxWidth: 50, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'date',
                    name: "Thời gian",
                    style: { minWidth: 130, textAlign: "center" },
                    sort: true,
                },
                {
                    id: 'name',
                    name: "Tên báo cáo",
                    style: { minWidth: 140, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'type',
                    name: "Loại báo cáo",
                    style: { minWidth: 150, textAlign: "center" },
                    sort: false,
                },
                // {
                //     id: 'type',
                //     name: "Trạng thái",
                //     style: {minWidth: 50, textAlign: "left"},
                //     sort: false,
                // },
                {
                    id: 'download',
                    name: "Tải xuống",
                    style: { maxWidth: 60, textAlign: "center" },
                    sort: false,
                },
            ]
        }
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng";
        this.getListReport()
    }

    getListReport() {
        let { code, status, pageNumber, pageSize, sort } = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            contractId: this.props.value,
            status: "APPROVED"
        };
        PublicReportContractApi.searchByContract(payload, res => {
            if (res.success) {
                this.setState({
                    listReport: res.data.content,
                    totalItem: res.data.totalElements,
                    // totalPage: Math.round(res.data.totalElements / this.state.pageSize)
                });
            } else {
                toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng thử lại!")
            }
        });
    }

    downloadReport = () => {
        let payload = {
            code: this.state.selectedFile.contractCode
        }
        SecureContractApi.contractCodeToCustomerId(payload, res => {
            if(res.success){
                let payload1 = {
                    fileId: this.state.selectedFile.id,
                    customerId: res?.data
                }
                FilesApi.downloadReportFileByIdCus(payload1, res1 => {
                    if (res1.success) {
                        let a = document.createElement("a");
                        a.href = "data:application/octet-stream/png;base64," + res1.data.content;
                        a.download = `${this.state.selectedFile.name}.${res1.data.extension}`
                        a.click();
                    }
                })
            }
        }) 
    }


    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListReport();
            }
        );
    }

    render() {
        let { rows, pageNumber, pageSize, totalItem, header } = this.state;
        // console.log(this.props.value)
        return (
            <div>
                <table className="table table-striped table-data">
                    <thead>
                        <TableHeader header={header} clickSort={(data) => {
                        }} />
                    </thead>
                    {this.state.listReport.length == 0
                        ?
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <div className="text-center">
                                        Không có dữ liệu
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {this.state.listReport.map((row, index) => (
                                <tr style={{ height: 50 }}>
                                    <td style={{ textAlign: "center" }} scope="row">
                                        {
                                            this.state.pageNumber * this.state.pageSize +
                                            index + 1
                                        }
                                    </td>
                                    <td style={{ textAlign: "center" }} scope="row">
                                        {moment(row.date).format(formatDate)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {row.name}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {renderReportType(row.type)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {(checkPermission("download_report")) &&

                                            <button className="btn btn-action"
                                                title='Tải xuống'
                                                onClick={() => {
                                                    this.setState({ selectedFile: row }, () => {
                                                        this.downloadReport()
                                                    })
                                                }}
                                                style={{ cursor: 'pointer' }}>
                                                <i className="ti-download" />
                                            </button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
                <div className="pagination-right">
                    <Pagination
                        activePage={this.state.pageNumber + 1}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={this.state.totalItem}
                        changeHandler={(page) => this.handleChangePage(page)}
                    />
                </div>
            </div>
        );
    }
}

export default ListReport
