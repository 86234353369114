import React from "react";
import { Modal } from "react-bootstrap";

class ModalDetailReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  renderStatus = (value) => {
    switch (value) {
      case "AWAITING_APPROVAL":
        return "Chờ duyệt";
      case "APPROVED":
        return "Đã duyệt";
      case "REJECTED":
        return "Đã từ chối";
      default:
        return "";
    }
  };
  renderType = (value) => {
    switch (value) {
      case "TAX_REPORT":
        return "Báo cáo thuế";
      case "FINANCIAL_REPORT":
        return "Báo cáo tài chính";
      default:
        return "";
    }
  };

  render() {
    const modalProp = {
      show: true,
      onHideModal: this.props.onHideModal,
      keyboard: false,
      size: "xl",
      backdrop: "static",
    };
    let { data } = this.props;
    const { pageNumber, numPages } = this.state;
    return (
      <div>
        <Modal {...modalProp} dialogClassName="modal-dialog-centered">
          <div className="btn-close">
            <button type="button" className="close" onClick={this.props.onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <div className="modal-container"></div>
            {/*<div>*/}
            {/*    <h1 className="text-center" style={{fontSize: 20,}}><b>CHI TIẾT</b></h1>*/}
            {/*</div>*/}
            {/*<div className="align-center">*/}
            {/*    <h6>Bạn có chắc chắn xóa tập tin này không?</h6>*/}
            {/*</div>*/}
            <div>
              <h1 className="text-center" style={{ fontSize: 20 }}>
                <b>Chi tiết báo cáo</b>
              </h1>
            </div>
            <div className="row">
              <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <label className="text-black">Tên báo cáo</label>
              </div>
              <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <span style={{ fontWeight: "bold" }}>
                  {data.name || "Chưa có dữ liệu"}
                </span>
              </div>
              <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <label className="text-black">Loại báo cáo </label>
              </div>
              <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <span style={{ fontWeight: "bold" }}>
                  {this.renderType(data.type) || "Chưa có dữ liệu"}
                </span>
              </div>
              <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <label className="text-black">Trạng thái </label>
              </div>
              <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <span style={{ fontWeight: "bold" }}>
                  {this.renderStatus(data.status) || "Chưa có dữ liệu"}
                </span>
              </div>
              <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <label className="text-black">Lý do từ chối </label>
              </div>
              <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                <span style={{ fontWeight: "bold" }}>
                  {data.rejectReason || "Chưa có dữ liệu"}
                </span>
              </div>
            </div>
            <div className="align-center mt-3 mb-2">
              <button
                type="button"
                className="border-1-s type-button-fix btn btn-light"
                onClick={() => {
                  this.props.onHide();
                }}
              >
                <span className="d-flex justify-content-center">Đóng</span>
              </button>
            </div>
            {/*<div>*/}
            {/*    <Document*/}
            {/*        file="https://www.vpbank.com.vn/sites/default/files/VPB_Documents/mb04_qd-gdcn_tc_final_25_06_2019.pdf"*/}
            {/*        loading={"Đang tải file, vui lòng chờ trong ít giây!"}*/}
            {/*        onLoadSuccess={this.onDocumentLoadSuccess}*/}
            {/*    >*/}
            {/*        <Page pageNumber={pageNumber} />*/}
            {/*    </Document>*/}
            {/*    <p>Page {pageNumber} of {numPages}</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <PDFViewer*/}
            {/*        document={{*/}
            {/*            url: '/test/testfile.pdf',*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalDetailReport;
