import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import moment from 'moment'


import DatePicker from "react-datepicker";
import Pagination from "../../../../components/Pagination";
import TableHeader from "../../../../components/TableHeader";
import Sidebar from "../../../../components/Sidebar";
import NumberFormat from "react-number-format";
import PageHeader from "../../../../components/PageHeader";
import ModalDetailReport from "../../../../components/Modal/modalDetailReport";
import UploadReportFile from "../../../../components/Modal/modalUploadReport";
import AdminContract from "../../../../services/ob-core/admin-contract"

import FilesApi from "../../../../services/resource/files";
import PublicReportContractApi from "../../../../services/ob-core/public-report-contract";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import {checkPermission} from "../../../../utils/common";
import ModalConfirmReport from "../../../../components/Modal/modalConfirmReport";
import {toast} from "react-toastify";

const dateFormat = 'DD-MM-YYYY';

const renderType = function (value) {
    switch (value) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
};


class ListReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlock: false,
            showDelete: false,
            itemSelected: {},
            data: [],
            page: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            sort: '',
            descending: '',
            status: '',
            modalDetailReport: false,
            modalUploadFile: false,
            startDate: '',
            endDate: '',
            startDate_temp: null,
            endDate_temp: null,
            status_temp:'',
            rejectReason: '',
            header: [
                {
                    id: '123',
                    name: "Thời gian",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'address',
                    name: "Tên báo cáo",
                    style: { textAlign: "left"},
                    sort: false,
                },
                {
                    // id: 'typeofReport',
                    id: 'reportTypeName',
                    name: "Loại báo cáo",
                    style: { textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'dateCreate',
                    name: "Ngày lập báo cáo",
                    style: { textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'dateApprove',
                    name: "Ngày duyệt báo cáo",
                    style: { textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: { textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { textAlign: "center",minWidth:130},
                    sort: false,
                },

            ],
        }
    }

    componentDidMount() {
        // this.getAllServicePlan();
        this.getListReport()
    }

    getListReport = () => {
        let {pageNumber, pageSize, status, sort, startDate, endDate, descending} = this.state;
        let payload = {
            contractCode: this.props.code,
            contractId: this.props.value,
            startDate: startDate,
            endDate: endDate,
            pageNumber: pageNumber,
            pageSize: pageSize,
            status: status,
            // sort,
            // descending
        };
        if (sort === "") {
            delete payload.sort
        }
        AdminContract.getReportContract(payload, res => {
            if (res.success) {
                // let data = res.data;
                // listData.map(item => {
                //     let filter = data.filter(x => x.id == item.accountantId)
                //     item.accountantId = filter[0]?.fullName
                // });
                //
                // this.setState({
                //         listData
                //     }
                // )
                this.setState({data: res.data.content, totalItem: res.data.totalElements })

            } else {

            }
        });
    }

    onhandleSearch = () => {
        let {startDate_temp, endDate_temp, status_temp} = this.state;

        this.setState(
            {
                startDate: startDate_temp,
                endDate: endDate_temp,
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListReport();
            }
        );
    };

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getListReport();
            }
        );
    }

    onShowModalDetailReport = (item) => {
        this.setState({selectedReport: item, modalDetailReport: true})
    }

    downloadReport = () => {
        let payload = {
            fileId: this.state.fileId,
            customerId: this.props.customerId
        }
        FilesApi.downloadReportFileByIdCus(payload, res => {
            if (res.success){
                let a = document.createElement("a");
                a.href = "data:application/octet-stream/png;base64," + res.data.content;
                a.download = `${this.state.selectedFile.name}.${res.data.extension}`
                a.click();
            }
        })
    }

    showModalUploadFile = () => {
        this.setState({modalUploadFile: true})
    }

    onReload = () => {
        this.setState({modalUploadFile: false}, ()=> {
            // window.location.reload()
            this.getListReport()
            // this.createContractReport()
        })
    }


    rejectReport = () => {
        let payload = {
            body: {
                id: this.state.fileId,
                rejectReason: this.state.rejectReason
            }
        }
        PublicReportContractApi.supervisorReject(payload, res => {
            if (res.success) {
                this.setState({ modalRejectReport: false }, () => {
                    toast.success("Từ chối báo cáo thành công!")
                    this.getListReport()
                })
            } else {
                toast.error("Từ chối báo cáo không thành công!")
            }
        })
    }



    renderStatus(status) {
        switch (status) {
            case "AWAITING_APPROVAL":
                return <span className="badge badge-secondary">Chờ duyệt</span>;
            case "APPROVED":
                return <span className="badge badge-success">Đã duyệt</span>;
            case "REJECTED":
                return <span className="badge badge-warning">Bị từ chối</span>;
            default:
                return;
        }
    }

    onHandleClearText() {
        this.setState(
            {
                status: '',
                status_temp: '',
                startDate: '',
                startDate_temp: null,
                endDate: '',
                endDate_temp: null,
            },
            () => this.onhandleSearch()
        );
    }

    render() {
        const {page, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className='p-0 pr-0 pl-0 mb-2'>
                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                        <div className='row kt-margin-b-20'>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-3'>
                                <label className="label-item mr-5">Thời gian</label>
                                <div className='d-inline-flex mb-1 w-100'>
                                    <DatePicker
                                        className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Từ ngày"
                                        selected={this.state.startDate_temp === null ? "" : new Date(this.state.startDate_temp)}
                                        // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                        maxDate={new Date()}
                                        minDate={new Date('2000/01/01')}
                                        // value={this.state.dateIssue}
                                        dateFormat="dd-MM-yyyy"
                                        isClearable={this.state.startDate_temp === null ? false : true}
                                        // fixedHeight
                                        // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                                        selectsStart
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                })
                                            }
                                        }
                                        startDate={new Date(this.state.startDate_temp)}
                                        endDate={new Date(this.state.endDate_temp)}
                                    />
                                    <DatePicker
                                        className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                        placeholderText="Đến ngày"
                                        selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                        // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                                        selectsEnd

                                        showMonthDropdown
                                        showYearDropdown
                                        disabled={this.state.startDate_temp === null ? true : false}
                                        isClearable={this.state.endDate_temp === null ? false : true}
                                        // fixedHeight
                                        dateFormat="dd-MM-yyyy"
                                        onChange={
                                            (date) => {
                                                this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})
                                            }
                                        }
                                        startDate={new Date(this.state.startDate_temp)}
                                        endDate={new Date(this.state.endDate_temp)}
                                        minDate={this.state.startDate_temp === null ? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
                                    />
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0'>
                                <label className="label-item">Trạng thái</label>
                                <select
                                    style={{
                                        marginTop: 4
                                    }}
                                    value={this.state.status_temp}
                                    onChange={val =>
                                        this.setState({ status_temp: val.target.value })
                                    }
                                    className="form-control kt-input pd-4-input shadow-none">
                                    <option value=''>Tất cả</option>
                                    <option value="AWAITING_APPROVAL">Chờ duyệt</option>
                                    <option value="APPROVED">Đã duyệt</option>
                                    <option value="REJECTED">Đã từ chối</option>
                                </select>
                            </div>
                            <div className='mt-3 p-0 col-xs-12 col-sm-12 col-md-6 align-text-bottom'>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.onhandleSearch()}
                                    color="primary"
                                >
                                    Tìm kiếm
                                </button>
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm"
                                    onClick={() => this.onHandleClearText()
                                    }
                                >
                                    Refresh
                                </button>
                                {checkPermission("upload_report") &&
                                <button
                                    style={{height: 35}}
                                    type="button"
                                    className="border-1-s background-btn type-button-fix btn btn-primary btn-sm"
                                    onClick={() => this.showModalUploadFile()}
                                >
                                    Upload
                                </button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>
                    <table
                        className="table table-striped table-data table-bordered table-responsive"
                    >
                        <table className='table'>
                            <thead>
                            <TableHeader
                                header={this.state.header}
                                clickSort={data => {
                                    console.log(data);
                                    let a = "";
                                    let descend = "";
                                    if (data.sortAsc == null) {
                                        // a = "id=asc";
                                        a = "id";
                                        descend = 'DESC'
                                    } else {
                                        a = data.sortId;
                                        descend = data.sortAsc ? 'ASC' : 'DESC'
                                    }
                                    this.setState(
                                        {
                                            sort: a,
                                            descending: descend
                                        },
                                        () => this.getListReport()
                                    );
                                }}
                            />
                            </thead>
                            {!this.state.data || this.state.data.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={12}>
                                        <div className='text-center'>Không có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                :
                                <tbody>
                                {this.state.data?.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{moment(row.cmnCreatedAt).format(dateFormat)}</td>
                                        <td style={{textAlign: 'left',}}>
                                                <span style={{cursor: 'pointer', color: '#0DA9D3'}}>
                                                {row.name}
                                                </span>
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {renderType(row.reportTypeName)}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {moment(row.cmnCreatedAt).format((dateFormat))}
                                        </td>
                                        <td style={{textAlign: 'center'}}>{row.acceptAt ? moment(row.acceptAt).format(dateFormat) : 'Chưa duyệt'}</td>
                                        <td style={{textAlign: 'center'}}>
                                            {this.renderStatus(row.status)}
                                        </td>
                                        <td className='p-1' style={{textAlign: 'center', fontSize: 18}}>
                                            {/*<button className="btn btn-action"*/}
                                            {/*        title='Xem chi tiết'*/}
                                            {/*        onClick={() => this.onShowModalDetailReport(row)}*/}
                                            {/*        style={{cursor: 'pointer'}}*/}
                                            {/*>*/}
                                            {/*    <i className="ti-book"/>*/}
                                            {/*</button>*/}
                                            {checkPermission("review_report") &&
                                            <button className="btn btn-action p-2"
                                                    title='Review báo cáo'
                                                    disabled={row.status != "AWAITING_APPROVAL"}
                                                    onClick={() => {
                                                        this.setState({
                                                            fileId: row.id,
                                                            reportName: row.name,
                                                            modalApproveReport: true
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-check-box"/>
                                            </button>
                                            }
                                            {checkPermission("reject_report") &&
                                            <button className="btn btn-action p-2"
                                                    title='Từ chối báo cáo'
                                                    disabled={row.status != "AWAITING_APPROVAL"}
                                                    onClick={() => {
                                                        this.setState({
                                                            fileId: row.id,
                                                            reportName: row.name,
                                                            modalRejectReport: true
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-close"/>
                                            </button>
                                            }
                                            {checkPermission("download_report") &&
                                            <button className="btn btn-action p-2"
                                                    title='Tải xuống'
                                                    onClick={() => {
                                                        this.setState({fileId: row.id}, () => {
                                                            this.downloadReport()
                                                        })
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-download"/>
                                            </button>
                                            }
                                        </td>
                                        {/*<TableCell align="center">{row.action}</TableCell>*/}
                                    </tr>
                                ))}
                                </tbody>
                            }
                        </table>
                    </table>
                    <div className="row d-flex justify-content-end m-3 font-14">
                        <div className="pagination-right">
                            <Pagination
                                activePage={this.state.pageNumber + 1}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.totalItem}
                                changeHandler={(page) => this.handleChangePage(page)}
                            />
                        </div>
                    </div>
                    {/*<TablePagination*/}
                    {/*    rowsPerPageOptions={[10, 20, 30, 40]}*/}
                    {/*    component="div"*/}
                    {/*    count={totalItem}*/}
                    {/*    page={page}*/}
                    {/*    rowsPerPage={pageSize}*/}
                    {/*    labelRowsPerPage={'Số kết quả trên trang'}*/}
                    {/*    // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                    {/*    // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                    {/*    labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}*/}
                    {/*    onChangePage={(event, newPage) => {*/}
                    {/*        this.setState({page: newPage}, () => {*/}
                    {/*            // this.getListAccountantOnSearch();*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*    onChangeRowsPerPage={(event) => {*/}
                    {/*        this.setState({*/}
                    {/*            pageSize: event.target.value,*/}
                    {/*            page: page*/}
                    {/*        }, () => {*/}
                    {/*            // this.getListAccountantOnSearch()*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
                {this.state.modalApproveReport && <ModalConfirmReport id={this.state.fileId}
                                                                      reportName={this.state.reportName}
                                                                      onRefresh={() => {
                                                                          this.setState({ modalApproveReport: false })
                                                                          this.getListReport()
                                                                      }}
                                                                      onHide={() => this.setState({ modalApproveReport: false })}
                />}
                {this.state.modalDetailReport && <ModalDetailReport report={this.state.selectedReport} onHide={()=> this.setState({modalDetailReport: false})}/>}
                {/*{this.state.modalUploadFile && <UploadFile show={this.state.modalUploadFile} reload={this.onReload} type="report" contractId={this.props.value} onHide={()=> this.setState({modalUploadFile: false})}/>}*/}
                {this.state.modalUploadFile && <UploadReportFile show={this.state.modalUploadFile} customerId={this.props.customerId} reload={this.onReload} contractId={this.props.value} onHide={()=> this.setState({modalUploadFile: false})}/>}

                {this.state.modalRejectReport && <div>
                    <Modal show={true} onHideModal={() => this.setState({ modalRejectReport: false })}
                           keyboard={false}
                           backdrop='static' dialogClassName="modal-dialog-centered">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={() => this.setState({ modalRejectReport: false })}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <div className="modal-container">
                                <h1 className="text-center">THÔNG BÁO</h1>
                                <div className="mt-4">
                                    <h6>Vui lòng nhập lý do huỷ báo cáo <b>{this.state.reportName}</b></h6>
                                    <textarea className="mt-3 form-control" name="" id=""
                                              rows="5"
                                              onChange={(e) => this.setState({ rejectReason: e.target.value })}
                                              style={{ resize: 'none', width: '100%' }} />
                                </div>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            this.rejectReport()
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={() => {
                                            this.setState({ modalRejectReport: false })
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>}
            </div>
        );
    }

}

export default ListReport
