import React from 'react';
import Link from '../Link';
import warningImg from "../../assets/images/warning.png"
import Cookies from "js-cookie";
import PublicContract from "../../services/ob-core/public-contract";
import PropTypes from "prop-types";

class TopHeader extends React.Component {
  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired,
  //   isFetching: PropTypes.bool,
  // };
  constructor(props) {
    super(props);
    this.state = {
      notification: null
    }
  }

  componentWillMount() {
    let token = Cookies.get('ob_cl_token');
    if (token) {
      this.getNotification()
    }
  }

  onChangeLang = (lang) => {
    localStorage.setItem('ob_cl_language', lang);
    // window.OBPubConfig.lang = lang
    window.location.reload()
    // vbnConfig = lang
  };

  getNotification = () => {
    PublicContract.getNotification(res => {
      if (res.success){
        if (res.data.lock){
          // this.props.history.push("/home")
          // window.location.replace("/home")
          this.props.showModalLockAccount(res.data.list)
        }
        localStorage.setItem("ob_cl_noti", JSON.stringify(res.data))
        this.setState({notification: res.data.list})
      }
    })
  }

  render() {
    let token = Cookies.get('ob_cl_token');
    return (
        <div className="top-header">
          <div className="container pr-3 d-flex">
            <div className="notification-scroll col-md-9 pl-0">
              {token && <marquee behavior="scroll" direction="left">
                {this.state.notification?.map((item) => (
                    <span>
                     - - - - <img src={warningImg} alt="" style={{height: 25}}/> <span> {item.body} </span><img src={warningImg} alt="" style={{height: 25}}/>
                  </span>
                ))}
              </marquee>}
            </div>
            <div className="col-md-3 pr-0">
              <ul className="list-inline text-right pr-3">
                {/*<li className="list-inline-item text-center"><a href="#" className="language"><img src="/images/ico-vn.png" alt="" /></a></li>*/}

                <li className="list-inline-item hotline text-center">
              <span className="d-flex">
                <i className="flaticon-phone mr-1" />
                {' '}
                <a href="tel:18008000">18008000</a>

              </span>
                </li>
                <li className="list-inline-item text-center">
                  {/*<Link to="/ho-tro">*/}
                  <img src="/images/vn.png" alt="" className="img-lang" title="Tiếng Việt" onClick={()=> this.onChangeLang("vi")}/>
                  <img src="/images/us.png" alt="" className="img-lang ml-2" titile="English" onClick={()=> this.onChangeLang("en")}/>
                  {/*<i className="flaticon-question mr-1" /> 🇻🇳*/}
                  {/*</Link>*/}
                </li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}

export default TopHeader
