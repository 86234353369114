import React, {Component} from "react";
import moment from "moment";
import "./index.css";
import {Link, Router} from "react-router-dom";

import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import NumberFormat from "react-number-format";

import JobApi from "../../../services/ob-core/public-job";
import {toast} from "react-toastify";

const dateFormat = 'DD-MM-YYYY'
class JobsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractCode:'',
            status: "PROCESSING",
            contractCode_temp: "",
            status_temp_search: "",
            name: "",
            sort:'',
            name_temp: "",
            pageNumber: 0,
            itemId: "",
            contractId: "",
            showLoading: false,
            page: 0,
            pageSize: 10,
            totalItem: 0,
            listData: [],
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "contractCode",
                    name: "Mã hợp đồng",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: true
                },
                {
                    id: "name",
                    name: "Tên job",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: true
                },
                {
                    id: "doneDate",
                    name: "Ngày hoàn thiện",
                    style: {minWidth: 165, textAlign: "center"},
                    sort: true
                },
                {
                    id: "doneDate",
                    name: "Số lượng báo cáo",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "receiveMoney",
                    name: "Giá trị Job",
                    style: {minWidth: 120, textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {minWidth: 120, textAlign: "center"},
                    sort: true
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center",cursor:'unset'},
                    sort: false
                }
            ]
        };
    }


    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getListJobFromAPI();
        })

    }

    //******************************************************************************************************************

    getListJobFromAPI() {
        let { contractCode,status, pageNumber, pageSize, sort,} = this.state;
        let payload = {
            contractCode:contractCode,
            status: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
        };
        JobApi.searchByContractCodeAndStatus(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false,
                });
            } else {
                toast.error("Đã xảy ra lỗi khi lấy dữ liệu, vui lòng tải lại trang!")
            }


        });
    }


    //******************************************************************************************************************

    onhandleSearch = () => {
        let {contractCode_temp, status_temp} = this.state;

        this.setState(
            {
                contractCode: contractCode_temp.trim(),
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListJobFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                contractCode_temp: "",
                status_temp: "PROCESSING",
                showLoading: true,
            },
            () => this.onhandleSearch()
        );
    }

    //******************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-secondary">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <span className="badge badge-primary">Đang thực hiện</span>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getListJobFromAPI();
            }
        );
    };


    render() {
        let {
            header,
            listData,
            pageNumber,
            pageSize,
            totalItem,
            page
        } = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="jobs-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Job"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-3'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    className="form-control font-14 shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    type="text"
                                                    maxLength={20}
                                                    value={this.state.contractCode_temp}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    onChange={val => {
                                                        this.setState({
                                                            contractCode_temp: val.target.value.trimLeft()
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 '>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    defaultValue={this.state.status}
                                                    onChange={val =>
                                                        this.setState(
                                                            {status_temp: val.target.value},
                                                            () => console.log(this.state.state_temp)
                                                        )
                                                    }
                                                >
                                                    <option value="">Tất cả</option>
                                                    <option value="PROCESSING">Đang thực hiện</option>
                                                    <option value="UNFULFILLED">Chờ xử lý</option>
                                                    <option value="CLOSED">Đã đóng</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-4 text-left p-0 mt-3'>

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onhandleSearch();
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button
                                                    type="button" className="btn border-1-s mr-3 btn-light btn-sm"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onHandleClearText();
                                                    }}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    if (data.sortAsc == null) {
                                                        a = null;
                                                    } else {
                                                        a = `${data.sortId}=${
                                                            data.sortAsc ? "asc" : "desc"
                                                        }`;
                                                    }
                                                    console.log(a);
                                                    this.setState(
                                                        {
                                                            sort: a
                                                        },
                                                        () => this.getListJobFromAPI()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData || this.state.listData.length == 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="text-center">Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td>
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.contractCode}
                                                        </td>
                                                        <td className="text-left">
                                                            {row.name}
                                                        </td>
                                                        {/*<td className="text-left" style={{minWidth: 150}}>*/}
                                                        {/*    {row.assignerId}*/}
                                                        {/*</td>*/}
                                                        <td className="text-left">
                                                            {row.doneDate ? moment(row.doneDate).format(dateFormat) : "Chưa có thông tin"}
                                                        </td>
                                                        <td className="text-center" >
                                                            {row.countDone}/{row.numberOfReports}
                                                        </td>
                                                        <td className="text-right">
                                                            <NumberFormat
                                                                value={row.receiveMoney}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                suffix={' VND'}
                                                            />
                                                        </td>
                                                        <td className="text-center" >
                                                            {this.renderStatus(row.status)}
                                                        </td>
                                                        <td>

                                                            <Link
                                                                to={{
                                                                    pathname: "./contracts-detail",
                                                                    search: `?contractId=${row.contractId}&code=${row.contractCode}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        contractId: row.contractId,
                                                                        contractCode:row.contractCode
                                                                    }
                                                                }}
                                                                className="cursor-pointer"
                                                                style={{fontSize: 20}}
                                                            >
                                                                <button className="btn btn-action"
                                                                        title='Xem chi tiết'
                                                                        style={{cursor: 'pointer'}}
                                                                    // onClick={()=> window.OBPubConfig.loading = true}
                                                                >
                                                                    <i className="ti-book"/>
                                                                </button>
                                                            </Link>


                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="pagination-right mt-2">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default JobsManagement

