import React from 'react'
import {Button, Card, Table, Form, Col, Row} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import WalletApi from '../../../services/ob-core/wallet'
import SupervisorApi from "../../../services/ob-user/supervisor";
import ModalAddWallet from "../../../components/Modal/modalAddWallet";
import {toast} from "react-toastify";
const dateFormat = 'DD-MM-YYYY';
class WalletManagement extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: 50,
            partnerName:'',
            sort:'id',
            descending:'DESC',
            showModalAdd:false,
            transactionType: "",
            transactionType_temp: "",
            monthCreateAt: "",
            monthCreateAt_temp: "",
            contractCode: "",
            accountBalance: 5000000,
            contractCode_temp: "",

            listData: [],
            showLoading: false,

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'contractCode',
                    name: "Mã hợp đồng",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'transactionContent',
                    name: "Nội dung giao dịch",
                    style: {textAlign: "center"},
                    sort: false,
                },
                // {
                //     id: 'partner',
                //     name: "Partner",
                //     style: {textAlign: "center"},
                //     sort: true,
                // },
                {
                    id: 'transactionValue',
                    name: "Giá trị giao dịch (VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'beforeTransaction',
                    name: "Tài khoản trước giao dịch (VND)",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: 'afterTransaction',
                    name: "Tài khoản sau giao dịch (VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'transactionType',
                    name: "Loại giao dịch",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreatedAt',
                    name: "Ngày thực hiện",
                    style: {textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListWallet();
            this.showWallet()
        })
    }

    showWallet = () => {
        WalletApi.showWallet( res => {
            // console.log('sup:', res)
            if(res.success) {
                this.setState({
                    accountBalance: res.data
                })
            }
        })
    }

    onSearch = () => {
        this.setState({
            transactionType: this.state.transactionType_temp,
            monthCreateAt: this.state.monthCreateAt_temp,
            contractCode: this.state.contractCode_temp,
            pageNumber: 0,
        },
            () => this.searchListWallet()
        )
    }

    searchListWallet = () => {
        let {contractCode, monthCreateAt, transactionType, pageNumber, pageSize, sort, descending} = this.state;
        let payload = {
            contractCode: contractCode,
            monthCreateAt: monthCreateAt,
            transactionType: transactionType,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            descending: descending,
        }

        WalletApi.adminSearchByContractAndType(payload, res => {
            // console.log('sup:', res)
            if(res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra!"))
            }
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                    this.searchListWallet();
            }
        );
    }

    renderType = (transactionType) => {
        switch (transactionType) {
            case 'RECHARGE_MONEY':
                return 'Nạp tiền cọc';
            case 'REFUND_MONEY':
                return 'Tiền hoàn lại';
            case 'EXPERT_MONEY':
                return 'Trừ tiền cọc';
            default:
                return '';
        }
    };

    handleModalAddWallet = () =>{
        this.setState({
            showModalAdd:true
        })
    }

    render() {
        let {pageNumber, pageSize, accountBalance}  = this.state
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="wallet-management" parentClass="wallet"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý ví"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row mb-2">
                                            <div className='col p-0 pr-3'>
                                                <label className="label-item">Số dư tài khoản:</label>
                                                <span className='pl-2 text-right font-weight-bold'>
                                                    <NumberFormat
                                                        value={accountBalance}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        suffix={" VND"}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-4 pl-0'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    maxLength={20} type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng"
                                                    value={this.state.contractCode_temp}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') this.getListWalletOnSearch()
                                                    }}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            contractCode_temp: event.target.value.trim()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <label className="label-item">Loại giao dịch</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.transactionType_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            transactionType_temp: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='RECHARGE_MONEY'>Nạp tiền cọc</option>
                                                    <option value='EXPERT_MONEY'>Trừ tiền cọc</option>
                                                    <option value='REFUND_MONEY'>Tiền hoàn lại</option>
                                                </select>
                                            </div>
                                            <div className='col-4 pr-0'>
                                                <label className="label-item">Thời gian</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.monthCreateAt_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            monthCreateAt_temp: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='1'>Tháng 1</option>
                                                    <option value='2'>Tháng 2</option>
                                                    <option value='3'>Tháng 3</option>
                                                    <option value='4'>Tháng 4</option>
                                                    <option value='5'>Tháng 5</option>
                                                    <option value='6'>Tháng 6</option>
                                                    <option value='7'>Tháng 7</option>
                                                    <option value='8'>Tháng 8</option>
                                                    <option value='9'>Tháng 9</option>
                                                    <option value='10'>Tháng 10</option>
                                                    <option value='11'>Tháng 11</option>
                                                    <option value='12'>Tháng 12</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.onSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                contractCode_temp: '',
                                                                transactionType_temp: '',
                                                                monthCreateAt_temp: '',
                                                                contractCode: '',
                                                                transactionType: '',
                                                                monthCreateAt: '',
                                                                pageNumber:0
                                                            }, () => this.searchListWallet())
                                                        }}
                                                >Refresh
                                                </button>
                                                {/*<button*/}
                                                {/*    type="button"*/}
                                                {/*    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                {/*    onClick={() => this.handleModalAddWallet()}*/}
                                                {/*>*/}
                                                {/*    Thêm mới*/}
                                                {/*</button>*/}
                                            </div>
                                        </div>
                                    </div>
                                            {/*<Form className='d-flex'>*/}
                                            {/*    <label>*/}
                                            {/*        Số dư Tài khoản: 50.000.000 VND*/}
                                            {/*    </label>*/}
                                            {/*</Form>*/}
                                            {/*<Form className="d-flex">*/}
                                            {/*    <div className="col-4 pl-0">*/}
                                            {/*        <Form.Group controlId="formPlaintextEmail">*/}
                                            {/*            <Form.Label>*/}
                                            {/*                Mã hợp đồng*/}
                                            {/*            </Form.Label>*/}
                                            {/*            <Form.Control defaultValue="" placeholder="Nhập mã hợp đồng" onChange={(e)=> this.setState({contractCode_tenp: e.target.value})}/>*/}
                                            {/*        </Form.Group>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-4">*/}
                                            {/*        <Form.Group controlId="formPlaintextEmail">*/}
                                            {/*            <Form.Label>*/}
                                            {/*                Loại giao dịch*/}
                                            {/*            </Form.Label>*/}
                                            {/*            <Form.Control as="select"*/}
                                            {/*                          value={this.state.transactionType_temp}*/}
                                            {/*                          onChange={(e) => {*/}
                                            {/*                              this.setState({*/}
                                            {/*                                  transactionType_temp: e.target.value*/}
                                            {/*                              })*/}
                                            {/*                          }}>*/}
                                            {/*                <option value=''>Tất cả</option>*/}
                                            {/*                <option value='RECHARGE_MONEY'>Nạp tiền cọc</option>*/}
                                            {/*                <option value='EXPERT_MONEY'>Trừ tiền cọc</option>*/}
                                            {/*            </Form.Control>*/}
                                            {/*        </Form.Group>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-4">*/}
                                            {/*        <Form.Group controlId="formPlaintextEmail">*/}
                                            {/*            <Form.Label>*/}
                                            {/*                Thời gian*/}
                                            {/*            </Form.Label>*/}
                                            {/*            <Form.Control as="select"*/}
                                            {/*                          value={this.state.monthCreateAt_temp}*/}
                                            {/*                          onChange={(e) => {*/}
                                            {/*                              this.setState({*/}
                                            {/*                                  monthCreateAt_temp: e.target.value*/}
                                            {/*                              })*/}
                                            {/*                          }}>*/}
                                            {/*                <option value=''>Tất cả</option>*/}
                                            {/*                <option value='1'>Tháng 1</option>*/}
                                            {/*                <option value='2'>Tháng 2</option>*/}
                                            {/*                <option value='3'>Tháng 3</option>*/}
                                            {/*                <option value='4'>Tháng 4</option>*/}
                                            {/*                <option value='5'>Tháng 5</option>*/}
                                            {/*                <option value='6'>Tháng 6</option>*/}
                                            {/*                <option value='7'>Tháng 7</option>*/}
                                            {/*                <option value='8'>Tháng 8</option>*/}
                                            {/*                <option value='9'>Tháng 9</option>*/}
                                            {/*                <option value='10'>Tháng 10</option>*/}
                                            {/*                <option value='11'>Tháng 11</option>*/}
                                            {/*                <option value='12'>Tháng 12</option>*/}
                                            {/*            </Form.Control>*/}
                                            {/*        </Form.Group>*/}
                                            {/*    </div>*/}

                                            {/*</Form>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        style={{height: 35}}*/}
                                        {/*        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                        {/*        onClick={() => {}}*/}
                                        {/*    >*/}
                                        {/*        Tìm kiếm*/}
                                        {/*    </button>*/}
                                        {/*    <button type="button"*/}
                                        {/*            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"*/}
                                        {/*            style={{height: 34}}*/}
                                        {/*            onClick={() => {*/}
                                        {/*                this.setState({*/}
                                        {/*                    contractCode: '',*/}
                                        {/*                    transactionType: '',*/}
                                        {/*                    startDate_temp: null,*/}
                                        {/*                    endDate_temp: null,*/}
                                        {/*                    monthCreateAt: ''*/}
                                        {/*                }, () => {})*/}
                                        {/*            }}*/}
                                        {/*    >Refresh*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                {/*        <Table striped bordered hover>*/}
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            let b = '';
                                            if (data.sortAsc == null) {
                                                a = 'id';
                                                b = 'DESC';
                                            } else {
                                                a = `${data.sortId}`;
                                                b = `${data.sortAsc ? 'ASC' : 'DESC'}`
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a,
                                                descending: b
                                            }, () => this.searchListWallet())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.contractCode}
                                                        {/*HD000001*/}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.transactionContent}
                                                        {/*Nội dung chuyển tiền*/}
                                                    </td>
                                                    {/*<td style={{textAlign: 'left'}}>*/}
                                                    {/*    {row.partnerName}*/}
                                                    {/*    /!*Nội dung chuyển tiền*!/*/}
                                                    {/*</td>*/}
                                                    <td style={{textAlign: 'right'}}>

                                                        <NumberFormat
                                                            value={row.transactionValue}
                                                            // value={10000000}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            // suffix={" VND"}
                                                        />
                                                    </td>
                                                    <td style={{textAlign: 'right'}}>

                                                        <NumberFormat
                                                            value={row.beforeTransactionAdmin}
                                                            // value={2000000}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            // suffix={" VND"}
                                                        />
                                                    </td>
                                                    <td style={{textAlign: 'right'}}>

                                                            <NumberFormat
                                                            value={row.afterTransactionAdmin}
                                                            // value={3000000}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            // suffix={" VND"}
                                                        />

                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderType(row.transactionType)}
                                                        {/*{this.renderType("RECHARGE_MONEY")}*/}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {moment(row.cmnCreatedAt).format(dateFormat)}
                                                        {/*15-04-2020*/}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAddWallet show = {this.state.showModalAdd}

                                onRefresh = {() => this.showWallet()}
                                onHide={() => this.setState({showModalAdd:false})}
                />
            </div>
        )
    }

}

export default WalletManagement
