import React from 'react';
import "./Modal.css"
import Cookies from "js-cookie";

import Dropzone from "react-dropzone";
import {
    Modal,
} from 'react-bootstrap';

import FilesApi from "../../services/resource/files";

class uploadFileCustomerId extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            showUpload: true,
            files: [],
            filesCertificate: [],
            customerId: '',
            url: '',
            fileName: '',
            fileType: 'BILL',
        };
    }

    componentDidMount() {
        // console.log("check>>>/>>>>>>>>>>")
    }

    uploadDocument = () => {
        console.log("file upload>>>>>>");
        let payload = {
            file: this.state.files,
            customerId: this.props.customerId
        }

        FilesApi.uploadContractDocuments(payload, res => {
            if (res.success) {
                this.setState({
                    files: []
                }, () => {
                    this.props.uploadSuccess(res.data, this.state.fileName, this.state.fileType)
                    // this.props.enqueueSnackbar('Upload thành công!', {
                    //     variant: 'success',
                    // });
                })
            }

        }).catch(error => console.log("upload error>>>", error))

    }

    uploadFile = () => {
        let payload = {
            file: this.state.files,
            customerId: this.props.customerId
        }

        FilesApi.uploadSecureFile(payload, res => {
            if (res.success) {
                this.props.uploadSuccess(res.data, payload.file[0])
                this.setState({
                    files: []
                }, () => {
                    this.props.onHide()
                    // this.props.enqueueSnackbar('Upload thành công!', {
                    //     variant: 'success',
                    // });
                })
            }

            // console.log("upload secure response>>>>>>>>>", res);
        }).catch(error => console.log("upload error>>>", error))

    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const userType = localStorage.getItem("ob_cl_userType")
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        const {name, filesCertificate} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">
                            {/*<div>*/}
                            {/*    <h1 className="text-center" style={{fontSize: 20,}}><b>UPLOAD FILE</b></h1>*/}
                            {/*</div>*/}

                            {/*<div className='row kt-margin-b-20'>*/}
                            {/*<div className='col-xs-4 col-sm-4 col-md-4 p-0 mb-3'>*/}
                            {/*    <label className='label-item'>*/}
                            {/*        Tên file*/}
                            {/*        <span style={{color: 'red'}}>*</span>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*<div className='col-xs-8 col-sm-8 col-md-8 p-0 mb-2'>*/}
                            {/*    <input*/}
                            {/*        maxLength={50} type="text" className="form-control shadow-none"*/}
                            {/*        value={name}*/}
                            {/*        onChange={(val) => {*/}
                            {/*            this.setState({*/}
                            {/*                name: val.target.value*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="tab-content">
                                <div className="tab-pane active">
                                    <h1 className="text-center">Upload File</h1>
                                    <div className="mt-4">
                                        {userType == "CUSTOMER" &&
                                        <div>
                                            <label className="label-item">Loại file</label>
                                            <select
                                                value={this.state.fileType}
                                                placeholder="Chọn một loại file"
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileType: e.target.value
                                                    })
                                                }}
                                                className="form-control kt-input pd-4-input shadow-none"
                                            >
                                                <option value='BILL'>Hóa đơn</option>
                                                <option value='RECEIPT_VOUCHER'>Phiếu thu</option>
                                                <option value='PAYMENT_VOUCHER'>Phiếu chi</option>
                                            </select>
                                        </div>}
                                        <div className="form-group mx-0 mb-4">
                                            <div className="alert alert-info my-3 text-center text-sm" role="alert">
                                                Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.
                                            </div>
                                            <div>
                                                <label htmlFor="">Tên file</label>
                                                <input type="text" className="form-control mb-3" onChange={(e) => {
                                                    this.setState({fileName: e.target.value})
                                                }}/>
                                            </div>
                                            <Dropzone accept="image/*, application/pdf"
                                                      onDrop={acceptedFiles => {
                                                          // console.log(acceptedFiles)
                                                          this.setState({files: acceptedFiles})
                                                          // this.setState({
                                                          //     filesCertificate: acceptedFiles.map(file => Object.assign(file, {
                                                          //         preview: URL.createObjectURL(file)
                                                          //     }))
                                                          // })
                                                      }}
                                                      multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files.length > 0 ? <div className="align-center">
                                                                <ul>{files}</ul>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                        </div>
                                    </div>
                                    <div container item xs={12}>
                                        <div item xs={12}>
                                            <div className="align-center mt-3 mb-2">
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                                    disabled={this.state.files == 0}
                                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                                    onClick={() => {
                                                        // console.log("check file >>>>>", file[0]);
                                                        if (this.props.type == "inContract") {
                                                            this.uploadDocument()
                                                        } else {
                                                            this.uploadFile()
                                                            let file = this.state.files
                                                            file[0].fileName = this.state.fileName
                                                            this.props.onClose(file[0])
                                                        }
                                                    }}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                                    onClick={this.props.onHide}
                                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default uploadFileCustomerId;

