import React from 'react';
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';

import SecureNews from "../../services/ob-core/secure-news";
import {toast} from "react-toastify";

class ModalHideNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            show: true
        };
    }

    hideNews = (item) => {
        let payload = {
            id: item.id
        }
        SecureNews.hide(payload, res => {
            if (res.success) {
                toast.success(`Đã ẩn thành công bài viết "${item.title}" !`)
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error(`Thao tác thất bại !`)
            }
        })
    }

    unHideNews = (item) => {
        let payload = {
            id: item.id
        }
        SecureNews.unhide(payload, res => {
            if (res.success) {
                toast.success(`Đã hiện thành công bài viết "${item.title}" !`)
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error(`Thao tác thất bại !`)
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='m-2 text-center'>
                            <label>Bạn có muốn {this.props.data?.hide ? 'hiện' : 'ẩn'} bài viết
                                "{this.props.data?.title}" này không ?</label>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={() => {
                                            if (this.props.data?.hide) {
                                                this.unHideNews(this.props.data)
                                            } else {
                                                this.hideNews(this.props.data)
                                            }
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalHideNews;

