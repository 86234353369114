import { stringify } from 'qs';
import {URL_API} from "../../url.config";
import {request} from "../../utils/apiUtils";
import axios from "axios";

const CommentApi  = {
    sendComment: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/comment/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    getComment: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/comment/get-list-by-contract-id?${stringify(payload)}`,
            method: 'GET'
        }, callback);
    },

}

export default CommentApi

