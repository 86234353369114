import React, {Component} from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import {Link} from "react-router-dom";
import PublicReportContractApi from "../../../services/ob-core/public-report-contract";
import SecureContractApi from "../../../services/ob-core/secure-contract";
import FilesApi from "../../../services/resource/files";

const dateFormat = 'DD-MM-YYYY'

const renderStatus = function (status) {
    switch (status) {
        case "AWAITING_APPROVAL":
            return <span className="badge badge-secondary">Chờ duyệt</span>;
        case "APPROVED":
            return <span className="badge badge-success">Đã duyệt</span>;
        case "REJECTED":
            return <span className="badge badge-warning">Bị từ chối</span>;
        default:
            return '';
    }
};

const renderType = function (type) {
    switch (type) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        case 'TAX_RETURN':
            return 'Khai thuế';
        case '1':
            return 'Bảng cân đối kế toán';
        case '2':
            return 'Báo cáo kết quả kinh doanh';
        case '3':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp trực tiếp';
        case '4':
            return 'Báo cáo lưu chuyển tiền tệ theo phương pháp gián tiếp';
        case '5':
            return 'Bảng cân đối phát sinh tài khoản';
        case '6':
            return 'Báo cáo thu chi và tồn quỹ tiền mặt';
        case '7':
            return 'Báo cáo số dư tiền gửi ngân hàng';
        case '8':
            return 'Báo cáo tổng hợp công nợ phải thu khách hàng';
        case '9':
            return 'Báo cáo tổng hợp công nợ phải trả nhà cung cấp';
        case '10':
            return 'Báo cáo tổng hợp công nợ phải trả khác';
        case '11':
            return 'Báo cáo tổng hợp công nợ nhân viên';
        default:
            return ''
    }
};

class ReportManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemSelected: {},
            data: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            showLoading: false,
            contractCode: '',
            contractCode_temp: '',
            status: '',

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'code',
                    name: "Mã hợp đồng",
                    // style: {minWidth: 140, textAlign: "center", overflow: 'hidden'},
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên báo cáo",
                    // style: {minWidth: 140, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'type',
                    name: "Loại báo cáo",
                    // style: {minWidth: 130, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreatedAt',
                    name: "Ngày lập báo cáo",
                    // style: {minWidth: 180, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'cmnAcceptAt',
                    name: "Ngày duyệt báo cáo",
                    // style: {minWidth: 190, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    // style: {minWidth: 120, textAlign: "center"},
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getListReportContract()
        })

    }

    getListReportContract = () => {
        let {
            pageNumber,
            pageSize,
            status,
            contractCode
        } = this.state;

        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // contractId: code,
            status: status,
            contractCode: contractCode
        }
        // console.log(payload)
        PublicReportContractApi.searchByContractIdAndDateAndStatus(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize),
                    showLoading: false,
                })
            }
        })

    }


    onhandleSearch = () => {
        let {contractCode_temp, status_temp} = this.state;

        this.setState(
            {
                contractCode: contractCode_temp.trim(),
                status: status_temp,
                pageNumber: 0,
                showLoading: true,
            },
            () => {
                this.getListReportContract();
            }
        );
    };
    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1,
                showLoading: true,
            },
            () => {
                this.getListReportContract();
            }
        );
    };

    downloadReport = () => {
        let payload = {
            code: this.state.selectedFile.contractCode
        }
        SecureContractApi.contractCodeToCustomerId(payload, res => {
            if(res.success){
                let payload1 = {
                    fileId: this.state.selectedFile.id,
                    customerId: res?.data
                }
                FilesApi.downloadReportFileByIdCus(payload1, res1 => {
                    if (res1.success) {
                        // console.log(res.data)
                        let a = document.createElement("a");
                        a.href = "data:application/octet-stream/png;base64," + res1.data.content;
                        a.download = `${this.state.selectedFile.name}.${res1.data.extension}`
                        a.click();
                    }
                })
            }
        }) 
    }

    render() {
        const {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="report-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Danh sách báo cáo"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-6 pr-sm-0 pr-md-3 p-0'>
                                                <label className="label-item">Mã hợp đồng</label>
                                                <input
                                                    maxLength={20} type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Nhập vào mã hợp đồng..."
                                                    value={this.state.contractCode_temp}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            contractCode_temp: event.target.value.trim()
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 '>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status_temp: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='APPROVED'>Đã duyệt</option>
                                                    <option value='AWAITING_APPROVAL'>Chờ duyệt</option>
                                                    <option value='REJECTED'>Bị từ chối</option>
                                                </select>
                                            </div>
                                            {/*<div className='col-xs-12 col-sm-12 col-md-4 text-right pr-0'>*/}
                                            {/*    <div>*/}
                                            {/*        <label className='label-item mb-4'></label>*/}
                                            {/*    </div>*/}
                                            {/*    <button*/}
                                            {/*        type="button"*/}
                                            {/*        className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                            {/*        style={{height: 35}}*/}
                                            {/*        onClick={() => this.getListReportContract()}*/}
                                            {/*    >*/}
                                            {/*        Tìm kiếm*/}
                                            {/*    </button>*/}
                                            {/*    /!*&nbsp;&nbsp;*!/*/}
                                            {/*    <button type="button" className="btn border-1-s btn-light btn-sm"*/}
                                            {/*            style={{height: 35}}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                this.setState({*/}
                                            {/*                    code: '',*/}
                                            {/*                    status: '',*/}
                                            {/*                    sort: 'id=asc'*/}
                                            {/*                }, () => {*/}
                                            {/*                    this.getListReportContract()*/}
                                            {/*                })*/}
                                            {/*            }}*/}
                                            {/*    >Refresh*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                            <div className='col-xs-12 col-sm-12 col-md-4 text-left p-0 mt-3'>

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    style={{height: 35}}
                                                    onClick={() => {
                                                        this.onhandleSearch();
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button
                                                    type="button" className="btn border-1-s btn-light btn-sm"
                                                    style={{height: 35}}
                                                    onClick={() =>
                                                        this.setState({
                                                                status_temp: '',
                                                                contractCode_temp: ''
                                                            }, () => this.onhandleSearch()
                                                        )
                                                    }
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="text-align-right mb-2">Tổng số báo cáo: {this.state.totalItem}</div>
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader header={this.state.header} clickSort={(data) => {
                                                console.log(data);
                                                let a = '';
                                                if (data.sortAsc == null) {
                                                    a = null
                                                } else {
                                                    a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                                }
                                                // console.log(a);
                                                this.setState({
                                                    sort: a
                                                }, () => this.getListReportContract())
                                            }}/>
                                            </thead>
                                            {!this.state.data || this.state.data.length == 0
                                                ? <tbody>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className='text-center'>Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                : <tbody>
                                                {this.state.data.map((row, index) => (
                                                    <tr key={row.id} style={{height: 50}}>
                                                        <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                        <td style={{textAlign: 'left',}}>
                                                        <span style={{cursor: 'pointer',}}>
                                                            {/*<Link*/}
                                                            {/*    to={{*/}
                                                            {/*        pathname: "/accountant/contracts-detail",*/}
                                                            {/*        search: `?contractId=${row.contractId}`,*/}
                                                            {/*        state: {*/}
                                                            {/*            fromDashboard: true,*/}
                                                            {/*            contractId: row.contractId*/}
                                                            {/*        }*/}
                                                            {/*    }}*/}
                                                            {/*    style={{color: "#1b90bd"}}*/}
                                                            {/*>*/}
                                                            {row.contractCode}
                                                            {/*</Link>*/}
                                                        </span>
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                                        <td style={{textAlign: 'left'}}>{renderType(row.reportTypeName)}</td>
                                                        <td style={{textAlign: 'center'}}>{row.cmnCreatedAt ? moment(row.cmnCreatedAt).format(dateFormat) : ''}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {row.acceptAt ? moment(row.acceptAt).format(dateFormat) : ''}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            {renderStatus(row.status)}
                                                        </td>
                                                        <td style={{textAlign: 'center', fontSize: 18}}>
                                                            {/*<button className="btn btn-action"*/}
                                                            {/*        title='Xem chi tiết báo cáo'*/}
                                                            {/*        style={{cursor: 'pointer'}}*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-book"/>*/}
                                                            {/*</button>*/}
                                                            <button className="btn btn-action"
                                                                    title='Tải xuống'
                                                                    style={{cursor: 'pointer'}} onClick={()=>{
                                                                        this.setState({selectedFile: row}, () => {
                                                                            this.downloadReport()
                                                                        })
                                                            }}>
                                                                <i className="ti-download"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>}
                                        </table>
                                    </div>
                                    <div style={{height: 40}} className="pagination-right">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default ReportManagement
