
import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PublicPartnerApi = {
    searchByFullNameAndAddress: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/partner/search-by-name-and-address?${stringify(payload)}`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
};
export default PublicPartnerApi
