import React from 'react';
import {withSnackbar} from "notistack";
import Link from '../Link';
import {forceLogout} from "../../utils/authority";
import Authentication from "../../services/auth";
import lang from '../lang'
import SecureCustomerApi from "../../services/ob-user/secure-customer";
import SupervisorApi from "../../services/ob-user/supervisor";
import AccountantApi from "../../services/ob-user/accountant";
import PartnerApi from "../../services/ob-user/partner";
import AdminCustomerApi from "../../services/ob-user/one-book-controller"
import CallCenterApi from "../../services/ob-user/call-center";
import ActionRoleApi from "../../services/ob-user/action-role";
import NotificationApi from "../../services/ob-user/notification";
import moment from "moment";

class HeaderAfterLogin extends React.Component {
    // static propTypes = {
    //   dispatch: PropTypes.func.isRequired,
    //   account: PropTypes.object,
    //   isUploadDone: PropTypes.bool,
    //   avatarInfo: PropTypes.object,
    //   isFetching: PropTypes.bool,
    // };

    static defaultProps = {
        account: {},
        isUploadDone: false,
        avatarInfo: {},
        isFetching: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            showContent: false,
            homeData: {
                headerTitle: [],
                headerCategories: []
            },
            searchText: '',
            dataNews: [],
            dataHelp: [],
            suggestions: [],
            value: "",
            showSearchResult: false,
            showLoading: false,
            errMes: '',
            userDetail: null,
            userFullName: '',
            location: {},
            completeInfo: false,
            isAdmin: false,
            userType: '',
            pageNumNoti: 0,
            notification: [],
            showNotification: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType");
        if (userType) this.setState({userType, isAdmin: userType == "ADMIN"});
    }

    componentDidMount() {
        this.setState({dataNews: this.props.listCategory})
        let userDetail = window.OBPubConfig.userInfo || "";
        this.getNotification()
        if (userDetail) this.setState({userDetail, userFullName: userDetail.fullName});
        this.getDetail()
        this.getPermission()
        setInterval(() => {
            this.getDetail()
            this.getPermission()
        }, 600000);
    }


    componentWillUpdate(nextProps) {
        // if (nextProps.isUploadDone !== this.props.isUploadDone && nextProps.uploadInfo && nextProps.uploadInfo.code === 200) {
        //   this.setState({avatar: nextProps.uploadInfo.data.fileDownloadUri});
        // }
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.homeData && nextProps.homeData.hasOwnProperty('code')&& nextProps.homeData.hasOwnProperty('data')){
        //   this.setState({homeData: nextProps.homeData.data.header}, () => {
        //     this.setState({showContent: true})
        //   })
        // }
    }

    getNotification = () => {
        let payload = {
            pageNumber: this.state.pageNumNoti,
            pageSize: 5,
        }
        NotificationApi.getList(payload, res => {
            if (res.success){
                localStorage.setItem("ob_cl_notification", JSON.stringify(res.data.content))
                this.setState({notification: res.data.content})
            }
        })
    }

    // getInformationAccount() {
    //   this.props.dispatch(getInfoAccount());
    // }

    getPermission = () => {
        ActionRoleApi.getPermission(res => {
            if (res.success) {
                window.OBPubConfig.permission = res.data
                localStorage.setItem("ob_vl_per", JSON.stringify(res.data));
            }
        })
    }

    getDetail = () => {
        Authentication.getUserDetail(res => {
            if (!res.success) {
                this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                    variant: 'error',
                });
                forceLogout()
            } else {
                window.OBPubConfig.user = res.data;
                this.getName();
                this.setState({userPhone: res.data.phone})

                localStorage.setItem('ob_cl_userDetail', JSON.stringify(res.data));
            }
        })
    }

    getName = () => {
        let type = window.OBPubConfig.user.type
        let userFullName = "";
        if (type == "CUSTOMER") {
            SecureCustomerApi.detail(res => {
                if (!res.success) {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    forceLogout()
                } else {
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    window.OBPubConfig.userInfo = res.data;
                    this.checkbyStatus(res.data.status);
                    // this.checkbyStatus("ACTIVE")
                    this.setState({userFullName: res.data.companyName})
                }
            })
        } else if (type == "SUPERVISOR") {
            SupervisorApi.detailSupervisor(res => {
                if (!res.success) {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    // forceLogout()
                } else {
                    // console.log("abc222!!>>>>",res);
                    // window.OBPubConfig.userInfo.fullName = res?.data[0]?.fullName
                    // this.setState({userFullName: res?.data[0]?.fullName})
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    window.OBPubConfig.userInfo = res.data
                    this.checkbyStatus(res.data.status)
                    this.setState({userFullName: res.data.fullName})
                }
            })
        } else if (type == "ACCOUNTANT") {
            AccountantApi.detailAccountant(res => {
                if (res.success === true) {
                    window.OBPubConfig.userInfo = res.data
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    this.checkbyStatus(res.data.status)
                    this.setState({userFullName: res.data.fullName})
                    // this.checkbyStatus("WAIT_COMPLETE_INFO")

                } else {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    forceLogout()
                }
            }).catch(error => {

            })
        } else if (type == "PARTNER") {
            PartnerApi.detail(res => {
                if (res.success === true) {
                    window.OBPubConfig.userInfo = res.data
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    this.checkbyStatus(res.data.status)
                    this.setState({userFullName: res.data.fullName})
                    // this.checkbyStatus("ACTIVE")
                } else {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    forceLogout()
                }
            })
        } else if (type == "ADMIN") {
            // this.setState({userFullName: "Administrator"})
            AdminCustomerApi.getAdminDetail(res => {
                if (res.success === true) {
                    window.OBPubConfig.userInfo = res.data
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    this.checkbyStatus(res.data.status)
                    this.setState({userFullName: res.data.fullName})
                    // this.checkbyStatus("ACTIVE")
                } else {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    forceLogout()
                }
            })
        } else if (type == "CALL_CENTER") {
            // this.setState({userFullName: "Administrator"})
            CallCenterApi.getDetail(res => {
                if (res.success === true) {
                    window.OBPubConfig.userInfo = res.data
                    localStorage.setItem("ob_cl_userInfo", JSON.stringify(res.data))
                    this.checkbyStatus(res.data.status)
                    this.setState({userFullName: res.data.fullName})
                    // this.checkbyStatus("ACTIVE")
                } else {
                    this.props.enqueueSnackbar("Không thể truy cập do không lấy được thông tin người dùng!", {
                        variant: 'error',
                    });
                    forceLogout()
                }
            })
        }

    }

    checkbyStatus = (status) => {
        // console.log("check status>>>>>>>", status);
        let url = window.location.hash.split('/');
        let type = window.OBPubConfig.user.type
        switch (status) {
            case("WAIT_COMPLETE_INFO"): {
                if (url[2] && (url[2] == "complete-profile" || url[2] == "confirm-email")) {
                    return
                } else {
                    return this.props.showModalWarningInfo()
                }
            }
            case("WAIT_CONFIRM_EMAIL"): {
                // return
                if (url[2] && (url[2] == "complete-profile" || url[2] == "confirm-email")) {
                    return
                } else {
                    // return history.push("/#/" + type.toLowerCase() + "/complete-profile")
                    // return <Link to={"/" + type.toLowerCase() + "/complete-profile"}/>
                    return this.props.showModalWarningEmail()
                }
            }
            case("ACTIVE"): {
                return this.setState({completeInfo: true})
            }
            case("BLOCKED"): {
                return this.setState({completeInfo: false})
            }
        }
    }

    renderLoading = () => {
        return (
            <div className="spinner">
                <div className="rect1"/>
                <div className="rect2"/>
                <div className="rect3"/>
                <div className="rect4"/>
                <div className="rect5"/>
            </div>
        )
    }

    renderMenuItem = () => {
        if (this.state.completeInfo || this.state.isAdmin) {
            let userType = localStorage.getItem("ob_cl_userType")
            switch (userType) {
                case "CUSTOMER":
                    return (
                        <ul>
                            <li><Link className="dropdown-item" to="/customer/profile">{lang.menu.profile}</Link></li>
                            <li><Link className="dropdown-item" to="/home/select-plan-step-1">Đăng ký dịch vụ</Link></li>
                            <li><Link className="dropdown-item" to="/customer/list-ordered-service">Dịch vụ đã đăng
                                ký</Link></li>
                            <li><Link className="dropdown-item" to="/customer/contracts-management">Quản lý hợp
                                đồng</Link></li>
                            <li><Link className="dropdown-item" to="/customer/files-management/upload">Upload
                                File</Link></li>
                            <li><Link className="dropdown-item" to="/customer/payment-management">Quản lý thanh
                                toán</Link>
                            </li>
                            {/*<li><Link className="dropdown-item" to="/customer/permission-management">Quản lý phân*/}
                            {/*    quyền</Link>*/}
                            {/*</li>*/}
                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    )
                case "SUPERVISOR":
                    return (
                        <ul>
                            <li><Link className="dropdown-item" to="/supervisor/profile">{lang.menu.profile}</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/landing-page">Quản lý trang cá
                                nhân</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/accountant-management">Quản lý kế
                                toán</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/contracts-management">Quản lý hợp
                                đồng</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/jobs-management">Quản lý Job</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/orders-management">Quản lý order</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/supervisor/revenue-management">Quản lý doanh
                                thu</Link></li>
                            <li><Link className="dropdown-item" to="/supervisor/report-management">Quản lý báo
                                cáo</Link></li>
                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    );
                case "ACCOUNTANT":
                    return (
                        <ul>
                            <li><Link className="dropdown-item" to="/accountant/profile">{lang.menu.profile}</Link></li>
                            <li><Link className="dropdown-item" to="/accountant/contracts-management">Quản lý hợp
                                đồng</Link></li>
                            <li><Link className="dropdown-item" to="/accountant/report-management">Quản lý báo
                                cáo</Link></li>
                            <li><Link className="dropdown-item" to="/accountant/revenue-management">Quản lý doanh
                                thu</Link></li>
                            <li><Link className="dropdown-item" to="/accountant/jobs-management">Quản lý Job</Link></li>
                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    );
                case "PARTNER":
                    return (
                        <ul>
                            <li><Link className="dropdown-item" to="/partner/profile">{lang.menu.profile}</Link></li>
                            <li><Link className="dropdown-item" to="/partner/company-organizational-chart">Quản lý nhân
                                sự</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/partner/contracts-management">Quản lý hợp
                                đồng</Link></li>
                            <li><Link className="dropdown-item" to="/partner/revenue-management">Quản lý doanh
                                thu</Link></li>
                            <li><Link className="dropdown-item" to="/partner/wallet-management">Quản lý ví</Link></li>
                            <li><Link className="dropdown-item" to="/partner/orders-management">Quản lý Order</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/partner/report-management">Quản lý báo cáo</Link>
                                <li><Link className="dropdown-item" to="/partner/permission-management">Quản lý phân
                                    quyền</Link></li>
                            </li>
                            <li><Link className="dropdown-item" to="/partner/new-signup-management">QL đăng ký tài
                                khoản</Link></li>
                            <li><Link className="dropdown-item" to="/partner/overdue-payment-management">QL Quá hạn
                                thanh toán</Link></li>

                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    );
                case "ADMIN":
                    return (
                        <ul>
                            {/*<li><Link className="dropdown-item" to="/admin/profile">{lang.menu.profile}</Link></li>*/}
                            <li><Link className="dropdown-item" to="/admin/service-plan-management">Gói dịch vụ</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/admin/program-management">QL Chương trình</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/admin/contract-management">QL Hợp đồng</Link></li>
                            <li><Link className="dropdown-item" to="/admin/customer-management">QL Khách hàng</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/admin/order-management">QL Order</Link></li>
                            <li><Link className="dropdown-item" to="/admin/partner-management">QL Partner</Link></li>
                            <li><Link className="dropdown-item" to="/admin/supervisor-management">QL Supervisor</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/admin/revenue-management">Doanh thu</Link></li>
                            <li><Link className="dropdown-item" to="/admin/wallet-management">Ví tiền</Link></li>
                            <li><Link className="dropdown-item" to="/admin/partner-wallet-management">Ví Partner</Link>
                            </li>
                            {/*<li><Link className="dropdown-item" to="/admin/user-management">User Admin</Link></li>*/}
                            <li><Link className="dropdown-item" to="/admin/call-center-management">QL Call Center</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/admin/new-signup-management">QL đăng ký tài
                                khoản</Link></li>
                            <li><Link className="dropdown-item" to="/admin/error-email-management">Quản lý Email</Link></li>
                            <li><Link className="dropdown-item" to="/admin/sms-management">Quản lý SMS</Link></li>
                            <li><Link className="dropdown-item" to="/admin/permission-management">Phân quyền</Link></li>
                            <li><Link className="dropdown-item" to="/admin/rating-management">QL Đánh giá</Link></li>
                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    );
                case "CALL_CENTER":
                    return (
                        <ul>
                            <li><Link className="dropdown-item" to="/call-center/profile">{lang.menu.profile}</Link>
                            </li>
                            <li><Link className="dropdown-item" to="/call-center/new-orders">QL Order mới</Link></li>
                            <li><Link className="dropdown-item" to="/call-center/orders-management">QL Order</Link></li>
                            <li><Link className="dropdown-item" to="/call-center/customer-management">QL Khách
                                hàng</Link></li>
                            <li><Link className="dropdown-item" to="/call-center/supervisor-management">QL
                                Supervisor</Link></li>
                            <li><Link className="dropdown-item" to="/call-center/new-signup-management">QL đăng ký tài
                                khoản</Link></li>
                            <li>
                                <a className="dropdown-item" href="/" onClick={() => {
                                    forceLogout()
                                }}>{lang.menu.logout}</a>
                            </li>
                        </ul>
                    );
            }
        } else {
            return (
                <ul>
                    <li>
                        <a className="dropdown-item" href="/" onClick={() => {
                            forceLogout()
                        }}>{lang.menu.logout}</a>
                    </li>
                </ul>
            )
        }
    }

    render() {
        let userType = '';
        let isSubUser = false;
        if (typeof localStorage !== 'undefined') {
            userType = localStorage.getItem('user_type');
            isSubUser = userType === 'SUB_USER'
        }
        const {account} = this.props;
        const {avatar, homeData, showContent, userDetail, userFullName, userPhone} = this.state;
        let headerCategories = [];
        let self = this;
        if (homeData && homeData.headerCategories) {
            homeData.headerCategories.sort(function (a, b) {
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                return 0;
            })
            for (let i in homeData.headerCategories) {
                if (!homeData.headerCategories[i].hide) {
                    headerCategories.push({
                        category: homeData.headerCategories[i],
                        items: []
                    })
                }
            }
        }
        let columnCount = headerCategories.length <= 0 ? 12 : (Math.floor(12 / headerCategories.length))
        let className = `col-md-${columnCount}`
        if (homeData && homeData.headerTitle) {
            homeData.headerTitle.sort(function (a, b) {
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                return 0;
            })

            for (let i in homeData.headerTitle) {
                for (let j in headerCategories) {
                    if (headerCategories[j].category.id === homeData.headerTitle[i].categoryId) {
                        headerCategories[j].items.push(homeData.headerTitle[i]);
                        break;
                    }
                }
            }
        }
        let customerAvatar = account && account.data ? account.data.avatarUrl : '';
        let imageAvatar = avatar ? avatar : customerAvatar;
        imageAvatar = imageAvatar ? imageAvatar : '';
        return (
            <div className="header-after-login-wrapper">
                <header className="affix header-after-login">
                    <div className="container pl-4 pr-4">

                        <nav className="navbar navbar-expand-lg navbar-light">
                          <span className="navbar-brand">
                              <Link to="/" className="d-flex align-items-center">
                                <img src="/images/onebook_logo.png" alt="" style={{width: 180}}/>
                                  {/*ONEBOOK*/}
                              </Link>
                          </span>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={()=> this.props.toggleSidebarMobile()}
                            >
                                <span className="navbar-toggler-icon"/>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto main-mn">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/" role="button"
                                              aria-haspopup="true" aria-expanded="false">Trang chủ</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/partner" role="button"
                                              aria-haspopup="true" aria-expanded="false">Đối tác</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/supervisor" role="button"
                                              aria-haspopup="true" aria-expanded="false">Chuyên gia</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/select-plan-step-1" role="button"
                                              aria-haspopup="true" aria-expanded="false">Báo giá dịch vụ</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <span className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                              aria-haspopup="true" aria-expanded="false">Dịch vụ<span className="caret"></span>
                                        </span>
                                        <div className="dropdown-menu p-2 pt-3" aria-labelledby="navbarDropdown">
                                            <div className="col">
                                                <ul>
                                                    <li>
                                                        <Link to="/home/dich-vu-ke-toan" className="dropdown-item">
                                                            <h4>Dịch vụ kế toán</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/dich-vu-thue" className="dropdown-item">
                                                            <h4>Dịch vụ thuế</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/tu-van-tai-chinh-doanh-nghiep" className="dropdown-item">
                                                            <h4>Tư vấn tài chính doanh nghiệp</h4>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/home/soat-xet-va-hoan-thien-bao-cao-tai-chinh" className="dropdown-item">
                                                            <h4>Soát xét và thoàn thiện báo cáo tài chính</h4>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/home/about-us" role="button"
                                              aria-haspopup="true" aria-expanded="false">Về chúng tôi</Link>
                                    </li>
                                    {/*<li className="nav-item dropdown">*/}
                                    {/*    <a className="nav-link dropdown-toggle" href="https://a1.faonline.vn/SAS/Main/Login.aspx" role="button"*/}
                                    {/*       aria-haspopup="true" aria-expanded="false">Fast Accounting</a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item dropdown">
                                        <span className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                              aria-haspopup="true" aria-expanded="false">Kiến thức<span className="caret"></span>
                                        </span>
                                        <div className="dropdown-menu p-2 pt-3" aria-labelledby="navbarDropdown">
                                            <div className="col">
                                                {/*<h3 className="text-uppercase">Action</h3>*/}
                                                <ul>
                                                    {this.props.listCategory.map((item, index) =>
                                                        <li>
                                                            <Link className="dropdown-item"   to={item.type==1?`/home/news?c=${item.path}`:`/home/documents?doc=${item.path}`}>
                                                                <h4>{item.name}</h4>
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="" style={{position: "relative"}}>
                                    <button className="btn btn-notification" onClick={() => {
                                        this.setState({showNotification: !this.state.showNotification})
                                    }}>
                                        <span className="fas fa-bell" />

                                    </button>
                                    {this.state.showNotification && <div className="notification-box-wrapper">
                                        <div className='text-left pl-2' style={{borderBottom: '1px solid #ccc', background: '#f5f6f7'}}>Thông báo</div>
                                        <ul className="notification-box">
                                            {this.state.notification.map((_item) => (
                                                <li className="notification-item">
                                                    <div>
                                                        <span className="notification-title"><b>{_item.title}</b></span>
                                                        <p className="mb-0 notification-content mt-1" style={{fontSize: 13}}>
                                                            {_item.body}
                                                        </p>
                                                        <div style={{textAlign: "right", color: "gray", fontSize: 13}}>
                                                            <span>{moment(_item.cmnCreatedAt).format("DD-MM-YYYY HH:mm")}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                            <li className="notification-item">
                                                <div>
                                                    <div style={{textAlign: "center", color: "#2673dd", fontSize: 13}}>
                                                        <Link  to='/home/portal/notification'>
                                                            <span>Xem tất cả thông báo</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>}
                                </ul>
                                <ul className="navbar-nav navbar-user">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" role="button"
                                           data-toggle="dropdown"
                                           aria-haspopup="true" aria-expanded="false">
                                            <img
                                                src={window.OBPubConfig?.userInfo?.urlImage || "/images/logo_square.png"}
                                                alt="avatar"
                                                className="d-inline-block thumb" style={{borderRadius: "50%"}}/>
                                            <span
                                                style={{fontWeight: 'bold'}}>{userPhone ? userPhone : lang.menu.guest}</span>
                                            <span className="caret"/>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-pc"
                                             aria-labelledby="navbarDropdown">
                                            {this.renderMenuItem()}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        );
    }
}

export default withSnackbar(HeaderAfterLogin);
