import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Cookies from "js-cookie";
import Authentication from "../../services/auth";
import {toast} from "react-toastify";


class ModalForgotPassword3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otpCode: '',
            showForgotPassword2: false,
            phone: '',
            signupInfo: null,
            count: 60,
            mes: ''
        };
    }

    componentDidMount() {
        this.setState({otpCode: '',});
    }

    start() {
        this.timer = setInterval(() => {
            if (this.state.count === 1) {
                clearInterval(this.timer);
                this.setState({
                    mes: 'Gửi lại'
                });
            } else {
                this.setState((prevState) => ({count: prevState.count - 1}));
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            // console.log("componentWillReceiveProps>>>>>>start");
            this.setState({otpCode: '',});
            clearInterval(this.timer);
            this.start();
        }
    }


    handleShowModalForgotPassword2 = () => {
        this.props.saveOtp(this.props.token, this.state.otpCode);
        this.props.onHideModal(true);
    };

    onCloseModalForgotPassword2 = () => {
        this.setState({showForgotPassword2: false});
    }

    submitVerifyOTP = () => {
        let payload = {
            otpCode: this.state.otpCode,
            // transactionId: this.state.signupInfo.transId
            token: this.props.token,
        }
        Authentication.forgotPasswordVerifyOtp(payload, res => {
            if (res.success) {
                this.handleShowModalForgotPassword2()
            } else {
                return toast.error("Xác thực OTP không thành công!")
            }
        })
    }

    getOtp = () => {
        clearInterval(this.timer);
        let payload = {
            phone: this.props.name
        };
        Authentication.forgotPasswordGetOtp(payload, res => {
            if (res.success) {
                toast.success("Gửi mã OTP thành công !")
                this.setState({count: 60, mes: ''}, () => {
                    this.start()
                });
            } else {
                return toast.error("Gửi mã OTP không thành công !")
            }
        })
    };


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={() => {
                            this.setState({count: 60, mes: ''});
                            clearInterval(this.timer);
                            this.props.onHideModal(false);
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 14,}}>Vui lòng nhập mã OTP đã được gửi về
                                SĐT {this.props?.signupInfo?.info?.phone}</h1>
                        </div>
                        <Grid container spacing={1} item xs={12}>
                            <TextField
                                className="mr-1 ml-1"
                                id="outlined-margin-dense"
                                placeholder='Nhập mã OTP'
                                margin="dense"
                                fullWidth
                                rows='1'
                                rowsMax="1"
                                value={this.state.otpCode}
                                size="small"
                                inputProps={{maxlength: 255}}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    this.setState({otpCode: e.target.value})
                                }}
                            />

                        </Grid>

                        <Grid container spacing={1} item xs={12}>
                            <div className='mt-4 mb-4 mr-2'>
                                Bạn chưa nhận được mã?
                            </div>
                            {this.state.mes ?
                                <div className='mt-4 mb-4 color-blue'
                                     type="button"
                                     onClick={this.getOtp}
                                >
                                    {this.state.mes}
                                </div>
                                :
                                <div className='mt-4 mb-4 d-flex'>
                                    Gửi lại sau <p className='color-blue ml-1'>{this.state.count}s</p>
                                </div>
                            }
                        </Grid>

                        <Grid container spacing={1} item xs={12}>
                            <Grid item xs={12}>
                                <div className="align-center mt-2 mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-original btn-1st w-100 type-button-fix"
                                        disabled={this.state.otpCode?.length < 6}
                                        onClick={this.submitVerifyOTP}
                                    >
                                          <span>
                                            Xác thực
                                            <i className="flaticon-right"/>
                                          </span>
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalForgotPassword3;
