export const reportCodeOptions =  [
    {
      label: "Báo cáo lưu chuyển tiền tệ năm",
      value: "v20GLTC5"
    },
    {
      label: "Báo cáo lưu chuyển tiền tệ giữa niên độ (Dạng đầy đủ)",
      value: "V20GLTC51"
    },
    {
      label: "Báo cáo lưu chuyển tiền tệ giữa niên độ (Dạng tóm lược)",
      value: "V20GLTC52"
    },
    {
      label: "Báo cáo theo thông tư 200",
      value: "V20GLTC53"
    },
    {
      label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
      value: "V20GLTC54"
    },
    {
      label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
      value: "V20GLTC55"
    },
    {
      label: "Báo cáo theo thông tư 133",
      value: "V20GLTC56"
    }
  ];
export const reportCodeOptions200 =  [
    {
        label: "Báo cáo theo thông tư 200",
        value: "V20GLTC53"
    },
    {
        label: "Báo cáo giữa niên độ theo thông tư 200 (dạng đầy đủ)",
        value: "V20GLTC54"
    },
    {
        label: "Báo cáo giữa niên độ theo thông tư 200 (dạng tóm lược)",
        value: "V20GLTC55"
    }
];
export const reportCodeOptions133 =  [
    {
        label: "Báo cáo lưu chuyển tiền tệ năm",
        value: "v20GLTC5"
    },
    {
        label: "Báo cáo lưu chuyển tiền tệ giữa niên độ (Dạng đầy đủ)",
        value: "V20GLTC51"
    },
    {
        label: "Báo cáo lưu chuyển tiền tệ giữa niên độ (Dạng tóm lược)",
        value: "V20GLTC52"
    },
    {
        label: "Báo cáo theo thông tư 133",
        value: "V20GLTC56"
    }
];
  export const reportFormOptions = [
    {
      label: "Mẫu chuẩn - năm",
      value: "10"
    },
    {
      label: "Mẫu chuẩn - giữa niên độ",
      value: "20"
    },
    {
      label: "Mẫu chuẩn - giữa niên độ - tóm lược",
      value: "30"
    },
    {
      label: "Mẫu chuẩn - bán niên",
      value: "40"
    }
  ]