import React from 'react'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import Autosuggestion from '../../../components/Form/Autosuggestion';
import ModalSupervisorDetailWaitActive from "../../../components/Modal/admin/modalSupervisorDetailWaitActive";
import SupervisorApi from '../../../services/ob-user/supervisor';

toast.configure()
const dateFormat = 'DD-MM-YYYY';

class SupervisorManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetail: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            itemSelected: null,
            status: "",
            supervisorName: '',
            listData: [],
            sort: '',
            selectedSupervisorId: null,
            selectedSupervisor: null,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center", width: 80},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên Supervisor",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: true,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                // {
                //     id: 'action',
                //     name: "Thao tác",
                //     style: {minWidth: 80, textAlign: "center"},
                //     sort: false,
                // },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListSupOnSearch();
        })
    }

    getListSupOnSearch = () => {
        let {pageNumber, pageSize, status, sort, supervisorName} = this.state;
        let payload = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            fullName: supervisorName,
            // partnerId: selectedPartner?.id,
            status: status,
            sort: sort
        };
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getListSupOnSearch()
            }
        );
    }

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    };

    render() {
        let {pageNumber, pageSize} = this.state
        let self = this;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        {/*<Sidebar activeClass="supervisor-management"/>*/}
                        <Sidebar activeClass="supervisor-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Supervisor"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Tên Supervisor</label>
                                                <input
                                                    placeholder='Nhập tên người phụ trách'
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.getListSupOnSearch()
                                                        }
                                                    }}
                                                    className="form-control font-14 kt-input shadow-none"
                                                    value={this.state.supervisorName}
                                                    onChange={(val) =>
                                                        this.setState({supervisorName: val.target.value, pageNumber: 0})
                                                    }
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status: e.target.value,
                                                            pageNumber: 0
                                                        })
                                                    }}
                                                    className="form-control kt-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='ACTIVE'>Đang hoạt động</option>
                                                    <option value='LOCK'>Đã khóa</option>
                                                    <option value='CANCELED'>Đã xóa</option>
                                                    <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                    <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option>
                                                    <option value='WAIT_ACTIVE'>Chờ kích hoạt</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.getListSupOnSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                pageNumber: 0,
                                                                status: '',
                                                                sort: ''
                                                            }, () => {
                                                                this.getListSupOnSearch()
                                                            })
                                                        }}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListSupOnSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    {/*<td style={{textAlign: 'center'}}>*/}
                                                    {/*    <button*/}
                                                    {/*        className="btn btn-action"*/}
                                                    {/*        title="Xem chi tiết"*/}
                                                    {/*        onClick={() => this.setState({*/}
                                                    {/*            selectedSupervisorId: row.id,*/}
                                                    {/*            showDetail: true*/}
                                                    {/*        })}*/}
                                                    {/*    >*/}
                                                    {/*        <i className="ti-book"/>*/}
                                                    {/*    </button>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*{this.state.showDetail &&*/}
                {/*<ModalSupervisorDetailWaitActive show={this.state.showDetail}*/}
                {/*                                 supervisorId={this.state.selectedSupervisorId}*/}
                {/*                                 onRefresh={() => self.getListSupOnSearch()}*/}
                {/*                                 onHide={() => this.setState({showDetail: false})}*/}
                {/*/>}*/}

            </div>
        )
    }

}

export default SupervisorManagement
