import { stringify } from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let BranchApi = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    getListById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/get-list-branch/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    deleteBranch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/delete-branch/${payload.id}`,
            method: 'POST',
        }, callback)
    },
    addSupervisorToBranch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/add-new`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    deleteSupervisorFromBranch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/delete-supervisor-from-branch`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    getNameById: (payload, callback) => {
        let param = payload.branchId.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/branch/get-names-by-ids?ids=${param}`,
            method: 'GET',
        }, callback)
    }
};
export default BranchApi
