const ShowNotification = false;
const TimeNotification = 3000;
const Notification = {
    status: 'success',
    message: '',
    time: '',
};
const ShowLoading = false;
const isFetching = false;

export default {
    Notification,
    ShowNotification,
    TimeNotification,
    ShowLoading
}
