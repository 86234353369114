import React from 'react';
import logo from '../../../assets/images/logo.png';
import as1 from '../../../assets/images/as1.png';
import as2 from '../../../assets/images/as2.png';
import as3 from '../../../assets/images/as3.png';
import './style.css';
import ModalSignupMember from "../../../components/Modal/ModalSignupMember";


class AccountantRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalSignupMember: false,
        }
    }

    handleShowSignup = () => {
        this.setState({modalSignupMember: true})
    }

    render() {
        return (
            <div className="as-wrapper">
                <div className="as-banner">
                    <h1>Đăng ký làm kế toán <img src={logo} className="as-img-banner" /> </h1>
                    <div className="btn-joining-system bg-gradient-main mt-5"  onClick={this.handleShowSignup}>
                        <span className='first-text'>Tham gia</span>
                        <span className='second-text'>vào hệ thống</span>
                    </div>
                </div>
                <div className="as-benefit app-separator container">
                    <h2 className='mb-5'>Lợi ích nhận được khi tham gia ONEBOOK</h2>
                    <div className="row align-items-center mb-3">
                        <div className="col-sm-3">
                            <img src={as1} className="img-fluid" />
                        </div>
                        <div className="col-sm-9 text-left text-part">
                            <div className="as-benefit-title color-main-dark text-bold">
                                Tự do có thêm thu nhập
                            </div>
                            <div className="as-benefit-content">
                                Thu nhập ổn định theo dịch vụ cung cấp và năng suất làm việc
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-sm-3">
                            <img src={as2} className="img-fluid" />
                        </div>
                        <div className="col-sm-9 text-left text-part">
                            <div className="as-benefit-title color-main-dark text-bold">
                                Tự chủ về thời gian
                            </div>
                            <div className="as-benefit-content">
                                Không cố định thời gian làm việc, bạn có thể tự quyết định thời gian
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-sm-3">
                            <img src={as3} className="img-fluid" />
                        </div>
                        <div className="col-sm-9 text-left text-part">
                            <div className="as-benefit-title color-main-dark text-bold">
                                Nâng cao kinh nghiệm
                            </div>
                            <div className="as-benefit-content">
                                Bạn sẽ tiếp cận được với nhiều loại hình công ty, nhiều lĩnh vực hơn, giúp nâng cao kinh nghiệm nhanh chóng hơn
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalSignupMember && <ModalSignupMember show={this.state.modalSignupMember} onHide={() => {this.setState({modalSignupMember: false})}}/>}
            </div>
        )
    }
}

export default AccountantRegister;
