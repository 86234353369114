import React from 'react';
import moment from "moment";
import {toast} from "react-toastify";
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';
import DatePicker from "react-datepicker/es";
import SecureCustomerApi from "../../services/ob-user/secure-customer";


class ModalUpdateCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditing: false,
            customerId: '',
            id: null,
            listData: [],

            address: "",
            identify: "",
            phone: '',
            email: "",
            business: "",
            scale: "",
            revenue: "",
            representative: "",
            position: "",
            representativePhone: "",
            placeIssued: "",
            dateIssued: "",
            tax: "",
            sex: "",
            provinceId: 0,
            districtId: 0,

            addressError: false,
            identifyError: false,
            emailError: false,
            businessError: false,
            scaleError: false,
            revenueError: false,
            representativeError: false,
            positionError: false,
            phoneError: false,
            placeIssuedError: false,
            taxError: false,
            dateIssuedError: false,
            sexError: false,
            provinceIdError: false,
            districtIdError: false,
            btnSaveStatus: true,
        };
    }

    componentDidMount() {
        let self = this;
        this.setState({
            listData: this.props.data,
            address: this.props.data.address || "",
            tax: this.props.data.tax || "",
            identify: this.props.data.identify || "",
            email: this.props.data.email || "",
            phone: this.props.data.phone || "",
            business: this.props.data.business || "",
            scale: this.props.data.scale || "",
            revenue: this.props.data.revenue || "",
            representative: this.props.data.representative || "",
            position: this.props.data.position || "",
            representativePhone: this.props.data.representativePhone || "",
            placeIssued: this.props.data.placeIssued || "",
            dateIssued: this.props.data.dateIssued || "",
            sex: this.props.data.sex || "",
            provinceId: this.props.data.provinceId || 0,
            districtId: this.props.data.districtId || 0,
            showLoading: false,
        })
    }

    renderScale = (business) => {
        let val1 = [
            {value: 'MEDIUM', name: 'Vừa (Nhân sự <100, doanh thu < 100)'},
            {value: 'SMALL', name: 'Nhỏ (Nhân sự <50, doanh thu < 50)'},
            {value: 'EXTRASMALL', name: 'Siêu nhỏ (Nhân sự <10, doanh thu < 3)'},
        ];
        let val2 = [
            {value: 'MEDIUM', name: 'Vừa (Nhân sự <200, doanh thu < 100)'},
            {value: 'SMALL', name: 'Nhỏ (Nhân sự <100, doanh thu <20)'},
            {value: 'EXTRASMALL', name: 'Siêu nhỏ (Nhân sự <10, doanh thu < 3)'},
        ];

        if (business === 'COMMERCE_SERVICE') {
            return val1.map((item, index) => <option
                value={item.value}>{item.name}</option>)
        } else if (business === 'MANUFACTURING_MACHINING' || business === 'BUILDING_INSTALLATION') {
            return val2.map((item, index) => <option
                value={item.value}>{item.name}</option>)
        }
    }

    renderBusiness = (business) => {
        switch (business) {
            case 'COMMERCE_SERVICE':
                return 'Thương mại - Dịch vụ - Đầu tư tài chính';
            case 'MANUFACTURING_MACHINING':
                return 'Sản xuất - Gia công';
            case 'BUILDING_INSTALLATION':
                return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
            default:
                return 'Chưa có dữ liệu';
        }
    };
    renderDistrictId = (districtId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
        return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
    };
    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    renderProvinceId = (provinceId) => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
        _province.unshift({id: null, name: 'Vui lòng chọn quận huyện'})
        return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
    };
    renderProvince = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
    };
    validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleChangeTax = (val) => {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 13) {
            this.setState({tax: onlyNums}, () => {
                this.state.tax.length !== 0 ? this.setState({
                    taxError: false,
                }) : this.setState({taxError: true, inEditing: false});
                this.state.tax.length >= 10 ? this.setState({
                    taxError: false,
                }) : this.setState({taxError: true, inEditing: false})
            });
        } else if (onlyNums.length === 13) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        } else if (onlyNums.length === 14) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({tax: onlyNums});
        }
    };

    handleChangePhone = (val) => {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({phone: onlyNums}, () => {
                this.state.phone.length !== 0 ? this.setState({
                    phoneError: false,
                }) : this.setState({phoneError: true, inEditing: false})
            });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({phone: number, phoneError: false, inEditing: true});
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({phone: number, phoneError: false, inEditing: true});
        }
    };

    handleChangeIdentify = (val) => {
        this.setState({inEditing: true})
        const onlyNums = val.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 11) {
            this.setState({identify: onlyNums}, () => {
                this.state.identify.length !== 0 ? this.setState({
                    identifyError: false,
                }) : this.setState({identifyError: true, inEditing: false});
                this.state.identify.length >= 9 ? this.setState({
                    identifyError: false,
                }) : this.setState({identifyError: true, inEditing: false})
            });
        } else if (onlyNums.length === 11) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{4})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        } else if (onlyNums.length === 12) {
            const number = onlyNums.replace(
                /(\d{2})(\d{4})(\d{5})/,
                '$1$2$3'
            );
            this.setState({identify: onlyNums});
        }
    };

    onSubmit = () => {
        let ready = true;
        let {address, identify, business, scale, email, revenue, representative, position, phone, placeIssued, tax, dateIssued, sex, provinceId, districtId, representativePhone} = this.state;
        if (address === '' || address === null) {
            ready = false;
            this.setState({addressError: true})
        }
        if (tax === '' || tax === null) {
            ready = false;
            this.setState({taxError: true})
        }
        if (identify === '' || identify === null) {
            ready = false;
            this.setState({identifyError: true})
        }
        if (email === '' || !this.validateEmail(email || email === null)) {
            ready = false;
            this.setState({emailError: true})
        }
        // if (revenue === '') {
        //     ready = false;
        //     this.setState({revenueError: true})
        // }
        if (representative === '' || representative === null) {
            ready = false;
            this.setState({representativeError: true})
        }
        if (position === '' || position === null) {
            ready = false;
            this.setState({positionError: true})
        }
        if (phone === '' || phone === null) {
            ready = false;
            this.setState({phoneError: true})
        }
        if (placeIssued === '' || placeIssued === null) {
            ready = false;
            this.setState({placeIssuedError: true})
        }
        if (dateIssued === '' || dateIssued === null) {
            ready = false;
            this.setState({dateIssuedError: true})
        }
        if (sex === '' || sex === null) {
            ready = false;
            this.setState({sexError: true})
        }
        if (provinceId === '' || provinceId === null) {
            ready = false;
            this.setState({provinceIdError: true})
        }
        if (districtId === '' || districtId === null) {
            ready = false;
            this.setState({districtIdError: true})
        }
        if (ready) {
            let payload = {
                id: this.props.data.id,
                body: {
                    address: address ? address : '',
                    business: business ? business : 'COMMERCE_SERVICE',
                    email: email ? email : '',
                    identify: identify ? identify : '',
                    position: position ? position : '',
                    phone: phone ? phone : '',
                    representative: representative ? representative : '',
                    representativePhone: representativePhone ? representativePhone : '',
                    revenue: revenue ? revenue : 0,
                    scale: scale ? scale : 'MEDIUM',
                    placeIssued: placeIssued ? placeIssued : '',
                    dateIssued: dateIssued ? dateIssued : '',
                    tax: tax ? tax : '',
                    sex: sex ? sex : '',
                    provinceId: provinceId ? provinceId : 0,
                    districtId: districtId ? districtId : 0,
                }
            }
            SecureCustomerApi.adminOneBookUpdateCustomer(payload, res => {
                if (res.success) {
                    toast.success('Thông tin đã được cập nhật thành công !')
                    this.setState({
                        onEdit: false,
                        inEditing: false,
                    }, () => this.props.onRefresh());
                } else {
                    toast.error('Cập nhật không thành công, vui lòng thử lại !')
                }
            })
        }
    }


    render() {
        let {listData} = this.state;
        let {data} = this.props
        const modalProp = {
            show: true,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-large">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>Cập nhật thông tin khách hàng</b>
                            </h1>
                        </div>
                        <div className='p-0'>
                            {/*_________________________________________________Company Information_____________________________________________*/}

                            <div className='row'>
                                <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin doanh nghiệp</span>
                                <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Tên doanh nghiệp</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                        {listData.fullName ? listData.fullName : 'Chưa có dữ liệu'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Mã số thuế</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.tax ? listData.tax : 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.taxError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Hoàn thiện mã số thuế'
                                               value={this.state.tax}
                                               maxLength={11}
                                               onChange={(val) => {
                                                   this.handleChangeTax(val)
                                               }}
                                        />
                                        {this.state.taxError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng nhập mã số thuế !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Email</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.email || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.emailError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Hoàn thiện email'
                                               value={this.state.email}
                                               maxLength={100}
                                               onChange={(val) => this.setState({
                                                   email: val.target.value.trimLeft(),
                                                   inEditing: true
                                               }, () => {
                                                   this.state.email.length === 0 ? this.setState({
                                                       emailError: true,
                                                       btnSaveStatus: false
                                                   }) : this.setState({
                                                       emailError: false,
                                                       btnSaveStatus: true
                                                   })
                                               })}
                                        />
                                        {this.state.emailError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng nhập Email !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Lĩnh vực hoạt động</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{this.renderBusiness(listData.business) || 'Chưa có dữ liệu'}*/}
                                        <select
                                            className="form-control kt-input pd-4-input hei-input my-select"
                                            defaultValue={"COMMERCE_SERVICE"}
                                            value={this.state.business}
                                            onChange={(e) => {
                                                this.setState({
                                                    business: e.target.value,
                                                    inEditing: true
                                                })
                                            }}
                                        >
                                            <option value="COMMERCE_SERVICE">Thương mại - Dịch vụ - Đầu tư tài chính
                                            </option>
                                            <option value="MANUFACTURING_MACHINING">Sản xuất - Gia công
                                            </option>
                                            <option value="BUILDING_INSTALLATION">Xây dựng - Lắp đặt - Đầu tư bất động
                                                sản
                                            </option>
                                        </select>
                                        {this.state.businessError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng chọn !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Số điện thoại</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.phone || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.phoneError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Nhập số điện thoại'
                                               value={this.state.phone}
                                               maxLength={11}
                                               onChange={(val) => {
                                                   this.handleChangePhone(val)
                                               }}
                                        />
                                        {this.state.phoneError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng nhập số điện thoại !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Quy mô hoạt động</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{this.renderScale(listData.scale) || 'Chưa có dữ liệu'}*/}
                                        <select
                                            className="form-control kt-input pd-4-input hei-input my-select"
                                            disabled={!this.state.business}
                                            value={this.state.scale}
                                            onChange={(e) => {
                                                this.setState({
                                                    scale: e.target.value,
                                                    inEditing: true
                                                })
                                            }}
                                        >
                                            {this.renderScale(this.state.business)}
                                        </select>
                                        {this.state.scaleError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng chọn !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Tỉnh/Thành phố</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{this.renderProvinceId(listData.provinceId) || 'Chưa có dữ liệu'}*/}
                                        <select
                                            className="form-control kt-input pd-4-input hei-input"
                                            value={this.state.provinceId}
                                            onChange={(e) => {
                                                let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
                                                let id = 0;
                                                id = _district?.filter(item => item.provinceId == e.target.value)[0].id;
                                                this.setState({
                                                    provinceId: e.target.value,
                                                    districtId: id,
                                                    inEditing: true
                                                })
                                            }}
                                        >
                                            {this.renderProvince()}
                                        </select>
                                        {this.state.provinceIdError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng chọn !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Địa chỉ</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.address || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.addressError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Nhập địa chỉ'
                                               value={this.state.address}
                                               maxLength={255}
                                               onChange={(val) => this.setState({
                                                   address: val.target.value.trimLeft(),
                                                   inEditing: true
                                               }, () => {
                                                   this.state.address.length === 0 ? this.setState({
                                                       addressError: true,
                                                       btnSaveStatus: false
                                                   }) : this.setState({
                                                       addressError: false,
                                                       // btnSaveStatus: true
                                                   })
                                               })}
                                        />
                                        {this.state.addressError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng nhập địa chỉ !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Quận/huyện</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{this.renderDistrictId(listData.districtId) || 'Chưa có dữ liệu'}*/}
                                        <select
                                            className="form-control kt-input pd-4-input hei-input"
                                            disabled={!this.state.provinceId}
                                            value={this.state.districtId}
                                            onChange={(e) => {
                                                this.setState({
                                                    districtId: e.target.value,
                                                    inEditing: true
                                                })
                                            }}
                                        >
                                            {this.renderDistrict(this.state.provinceId)}
                                        </select>
                                        {this.state.districtIdError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng chọn !
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            {/*______________________________________________Customer Information__________________________________________*/}

                            <div className='row mt-5'>
                                <span style={{fontSize: 20}} className='font-weight-bold'>Thông tin tài khoản</span>
                                <div className='row col-sm-12 col-md-12 col-lg-12 p-0 mt-3 '>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Nguời đại diện</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.representative || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.representativeError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Người đại diện'
                                               value={this.state.representative}
                                               maxLength={100}
                                               onChange={(val) => this.setState({
                                                   representative: val.target.value.trimLeft(),
                                                   inEditing: true
                                               }, () => {
                                                   this.state.representative.length === 0 ? this.setState({
                                                       representativeError: true,
                                                       btnSaveStatus: false
                                                   }) : this.setState({
                                                       representativeError: false,
                                                       // btnSaveStatus: true
                                                   })
                                               })}
                                        />
                                        {this.state.representativeError &&
                                        <div className="invalid-feedback d-block">
                                            Vui lòng nhập người đại diện !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Giới tính</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile d-flex">
                                        {/*{listData.sex === 'MALE' ? 'Nam' : 'Nữ'}*/}
                                        <div className="form-check mr-4"
                                             onClick={() => this.setState({sex: 'MALE'})}>
                                            <input className="form-check-input" type="radio"
                                                   checked={this.state.sex === 'MALE'}/>
                                            <label className="form-check-label">
                                                Nam
                                            </label>
                                        </div>
                                        <div className="form-check mr-4"
                                             onClick={() => this.setState({sex: 'FEMALE'})}>
                                            <input className="form-check-input" type="radio"
                                                   checked={this.state.sex === 'FEMALE'}/>
                                            <label className="form-check-label">
                                                Nữ
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Số điện thoại</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile d-flex">
                                        {listData.representativePhone ? listData.representativePhone : 'Không có SĐT'}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>CMT/CCCD</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.identify || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.identifyError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='CMT/CCCD'
                                               value={this.state.identify}
                                               maxLength={12}
                                               onChange={(val) => {
                                                   this.handleChangeIdentify(val)
                                               }}
                                        />
                                        {this.state.identifyError && <div className="invalid-feedback d-block">
                                            Vui lòng nhập CMT/CCCD !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Chức vụ</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.position || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.positionError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Chức vụ'
                                               value={this.state.position}
                                               maxLength={50}
                                               onChange={(val) => this.setState({
                                                   position: val.target.value.trimLeft(),
                                                   inEditing: true
                                               }, () => {
                                                   this.state.position.length === 0 ? this.setState({
                                                       positionError: true,
                                                       btnSaveStatus: false
                                                   }) : this.setState({
                                                       positionError: false,
                                                   })
                                               })}
                                        />
                                        {this.state.positionError && <div className="invalid-feedback d-block">
                                            Vui lòng nhập chức vụ !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Nơi cấp</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.placeIssued || 'Chưa có dữ liệu'}*/}
                                        <input type="text"
                                               className={this.state.placeIssuedError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                               placeholder='Nơi cấp'
                                               value={this.state.placeIssued}
                                               maxLength={50}
                                               onChange={(val) => this.setState({
                                                   placeIssued: val.target.value.trimLeft(),
                                                   inEditing: true
                                               }, () => {
                                                   this.state.placeIssued.length === 0 ? this.setState({
                                                       placeIssuedError: true,
                                                       btnSaveStatus: false
                                                   }) : this.setState({
                                                       placeIssuedError: false,
                                                   })
                                               })}
                                        />
                                        {this.state.placeIssuedError && <div className="invalid-feedback d-block">
                                            Vui lòng nhập nơi cấp !
                                        </div>}
                                    </div>
                                    <div
                                        className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile text-black-50">
                                        <span>Ngày cấp</span>
                                    </div>
                                    <div
                                        className="mb-1 pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                        {/*{listData.dateIssued ? moment(listData.dateIssued).format("DD-MM-YYYY") : 'Chưa có dữ liệu'}*/}
                                        <DatePicker
                                            className={"w-auto h-100 kt-input pd-4-input hei-input form-control"}
                                            selected={this.state.dateIssued ? new Date(this.state.dateIssued) : new Date()}
                                            maxDate={new Date()}
                                            dateFormat="dd-MM-yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            onSelect={(val) => {
                                                this.setState({
                                                    dateIssued: val,
                                                })
                                            }}
                                            onChange={(date) => {
                                                this.setState({
                                                    dateIssued: moment(date).format("YYYY-MM-DD"),
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="border-1-s type-button-fix btn btn-primary mr-2"
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                    disabled={!this.state.inEditing}
                                >
                                    Cập nhật
                                </button>
                                <button
                                    type="button"
                                    className="border-1-s type-button-fix btn btn-light"
                                    onClick={() => {
                                        this.props.onHide()
                                    }}
                                >
                                    Đóng
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalUpdateCustomer;

