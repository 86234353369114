import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
import {
    Modal,
} from 'react-bootstrap';
import RoleApi from "../../services/ob-core/admin-role";
import Autosuggestion from "../Form/Autosuggestion";

import AdminUserManagement from "../../services/ob-core/admin-user-management";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecureCustomerApi from "../../services/ob-user/secure-customer";

class ModalAddNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userPhone: '',
            role:'',
            position: "",
            email: "",

            roleSearch: '',

            listRole: [],
            clear: false,

            errUserName: false,
            errUserPhone: false,
            errRole: false,
            errEmail: false,
            errPosition: false
        };
    }

    componentDidMount() {
        this.setState({
            userName: '',
            userPhone: '',
            role: '',
            position: "",
            email: "",
            errUserName: false,
            errUserPhone: false,
            errRole: false,
            errEmail: false,
            errPosition: false
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                userName: '',
                userPhone: '',
                role: '',
                email: "",
                position: "",
                errUserName: false,
                errUserPhone: false,
                errRole: false,
                errEmail: false,
                errPosition: false
            });
        }
    }

    getListRole = () => {
        let{pageNumber, pageSize, roleSearch} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            roleName: roleSearch,
        };
        RoleApi.getList(payload, res => {
            // console.log('sup:', res)
            if(res.success) {
                this.setState({
                    listRole: res.data.content,
                })
            }
        })
    }

    onSubmit = () => {
        if (this.state.userName.length === 0) {
            this.setState({
                errUserName: true,
            })
        } else if (this.state.userPhone.length === 0) {
            this.setState({
                errUserPhone: true,
            })
        } else if (this.state.role.length === 0) {
            this.setState({
                errRole: true,
            })
        } else if (this.state.email.length === 0) {
            this.setState({
                errEmail: true,
            })
        } else if (this.state.position.length === 0) {
            this.setState({
                errPosition: true,
            })
        } else {
            this.create()
        }
    }

    create = () => {
        let self = this;
        let payload = {
            fullName: this.state.userName,
            phone: this.state.userPhone,
            permissionId: this.state.role,
            email: this.state.email,
            position: this.state.position,
            password: "12345678@",
        };
        SecureCustomerApi.adminCustomerCreateUser(payload, res => {
            console.log("check>>>", payload)
            if (res.errorCode === 'One-Book-Auth.E000003') {
                toast.error(res.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }else
            if (res.success) {
                toast.success("Tạo mới thành công!")
                setTimeout(() => {
                    self.props.onHide()
                    self.props.onRefresh()
                }, 500)

            } else {
                return (toast.error("Có lỗi xảy ra !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                }))
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-container">

                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>THÊM MỚI USER</b></h1>
                        </div>

                        <div className='mt-5 mb-5 ml-3 mr-3'>
                            <div className="row">
                                <label className="col-5 mt-2">Họ và tên: </label>
                                <input
                                    className={this.state.errUserName ? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập họ và tên"
                                    maxLength={50}
                                    value={this.state.userName}
                                    onChange={(e) => this.setState({
                                            userName: e.target.value,
                                            errUserName: false
                                        },
                                        () => {
                                            this.state.userName.length == 0 ? this.setState({errUserName: true}) : this.setState({errUserName: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errUserName ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Tên đang để trống !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số điện thoại: </label>
                                <input
                                    className={this.state.errUserPhone ? "inputPassword w-100 col-7 border-input-error " : "inputPassword w-100 col-7 "}
                                    placeholder="Nhập số điện thoại"
                                    fullWidth
                                    maxLength={20}
                                    value={this.state.userPhone}
                                    onChange={(e) => this.setState({userPhone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () =>{
                                            this.state.userPhone.length <10 || this.state.userPhone.length == 0 ? this.setState({errUserPhone: true, btnStatus: true}): this.setState({errUserPhone: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errUserPhone ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">SĐT đang trống hoặc sai định dạng !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhóm quyền: </label>
                                <div className={this.state.errRole?'w-100 col-7 pl-0 pr-0 inputSuggest border-input-error ':'w-100 col-7 pl-0 pr-0 inputSuggest'}>
                                    <Autosuggestion
                                        // label="Supervisor"
                                        className="inputSuggest2 pd-4-input shadow-none"
                                        placeholder="Nhập tên nhóm quyền"
                                        // value={this.state.roleSearch}
                                        type="text"
                                        // clearSearch={this.state.clear}
                                        data={this.state.listRole}
                                        onSelect={(item) => this.setState({role: item.id, errRole: false})}
                                        onChange={(val) => {
                                            if(val === '' || this.state.listRole.length === 0){
                                                this.setState({roleSearch: val, role: ''});
                                                setTimeout(() => {
                                                    this.getListRole()
                                                }, 300);
                                            }else{
                                                this.setState({roleSearch: val, clear: false});
                                                setTimeout(() => {
                                                    this.getListRole()
                                                }, 300);
                                            }

                                        }}
                                        // checkError={this.state.errRole}
                                        // errorContent="Vui lòng chọn một Supervisor"
                                    />
                                </div>
                            </div>
                            {this.state.errRole ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Vui lòng chọn nhóm quyền !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Vị trí: </label>
                                <input
                                    className={this.state.errPosition? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập vị trí"
                                    maxLength={50}
                                    value={this.state.position}
                                    onChange={(e) => this.setState({
                                            position: e.target.value,
                                            errPosition: false
                                        },
                                        () => {
                                            this.state.errPosition.length == 0 ? this.setState({errPosition: true}) : this.setState({errPosition: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errPosition ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">VỊ trí đang để trống !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Email: </label>
                                <input
                                    className={this.state.errEmail? "inputPassword w-100 col-7 border-input-error" : "inputPassword w-100 col-7"}
                                    placeholder="Nhập email"
                                    maxLength={50}
                                    value={this.state.email}
                                    onChange={(e) => this.setState({
                                            email: e.target.value.trim().toLowerCase(),
                                            errEmail: false
                                        },
                                        () => {
                                            this.state.errEmail.length == 0 ? this.setState({errEmail: true}) : this.setState({errEmail: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.errEmail ?<div className="row">
                                <label className="col-5"></label>
                                <label className="col-7 font-err">Email đang để trống !</label>
                            </div> :<div/>}

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Mật khẩu </label>
                                <label className="col-7 mt-2">12345678@ </label>
                            </div>
                        </div>


                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={() => this.onSubmit()}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Tạo mới
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-secondary"
                                    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAddNew;
