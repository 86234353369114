import React, {Component} from "react";
import "./index.css";
import {Link} from "react-router-dom";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";

import NumberFormat from "react-number-format";
import {
    TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Sidebar from "../../../components/Sidebar";
import TableHeader from "../../../components/TableHeader/index";
import PageHeader from "../../../components/PageHeader";
import lang from "./lang";

import PublicContractApi from "../../../services/ob-core/public-contract";
import SupervisorApi from "../../../services/ob-user/supervisor";
import Pagination from "../../../components/Pagination";

const status = [
    // {
    //     value: "WAIT_SIGN",
    //     label: lang.status.waitSign
    // },
    // {
    //     value: "SIGNED",
    //     label: lang.status.signed
    // },
    {
        value: "PROCESSING",
        label: lang.status.processing
    },
    {
        value: "LIQUIDATED",
        label: lang.status.liquidation
    },
    {
        value: "CANCELED",
        label: lang.status.canceled
    },
    {
        value: "CLOSED",
        label: lang.status.closed
    }
];

class ContractsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supervisorId: '',
            contractId: "",
            sku_contract: "",
            status_temp: "",
            code_temp: "",
            listId: '',

            listData: [],
            pageNumber: 0,
            pageSize: 10,
            page: 0,
            code: "",
            status: "",
            sort: 'id',
            descending: 'DESC',
            totalItem: 0,
            status_temp_search: "",
            showLoading: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã hợp đồng",
                    style: {minWidth: 130, textAlign: "left"},
                    sort: false
                },
                {
                    id: "cmnCreatedAt",
                    name: "Thời gian tạo",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "name",
                    name: "Tên hợp đồng",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false
                },
                {
                    id: "type",
                    name: "Loại hợp đồng",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: false
                },
                {
                    id: "value",
                    name: "Giá trị hợp đồng (VNĐ)",
                    style: {minWidth: 150, textAlign: "right"},
                    sort: false
                },
                {
                    id: "fullName",
                    name: "Supervisor xử lý",
                    style: {minWidth: 190, textAlign: "center"},
                    sort: false
                },

                {
                    id: "status",
                    name: "Trạng thái",
                    style: {minWidth: 110, textAlign: "center"},
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            // this.onhandleSearch();
            this.getListContractFromAPI();
        })

    }

    // ---integrate API---------------------------------------------------------------------------------------------------

    getListContractFromAPI() {
        let {code, status, pageNumber, pageSize, sort, contractId, descending} = this.state;
        let payload = {
            code: code,
            status: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort,
            descending: descending,
            supervisorId: contractId
        };
        PublicContractApi.searchPaging(payload, res => {
            if (res.success) {
                let data = res.data.content;
                let listId = [];
                data.map(item => {
                    listId.push(item.supervisorId)

                })
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listId,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false,
                }, () => {
                    if (data.length > 0) this.getListAccountantByIdFromAPI()
                    else this.setState({showLoading: false})
                });
            } else {
            }
        });
    }


    getListAccountantByIdFromAPI() {
        let {
            listId,
            listData,
        } = this.state;

        let payload = {
            ids: listId
        };
        SupervisorApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.supervisorId)
                    item.supervisorId = filter[0]?.fullName
                });

                this.setState({
                        listData
                    }
                )

            } else {
            }
        });
    }

    getDetailFromAPI() {
        let payload = {
            id: this.props.value
        };

        SupervisorApi.detailSupervisor(payload, res => {
            if (res.success === true) {
            } else {
            }
        });
    }

    //BUTTON ---------------------------------------------------------------------------------------------------------

    onhandleSearch = () => {
        let {code_temp, status_temp} = this.state;

        this.setState(
            {
                code: code_temp.trim().toLowerCase(),
                status: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                sort: 'id',
                descending: 'DESC',
                code_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onhandleSearch()
        );
    }


    // --------------------------------------------------------------------------------------------------------


    renderStatus(status) {
        switch (status) {
            case "WAIT_SIGN":
                return <h5><span className="badge badge-secondary">Chờ ký</span></h5>;
            case "SIGNED":
                return <h5><span className="badge badge-secondary">Đã ký</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "NO_ASSIGN":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "WAITING_FOR_CONFIRMATION":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "LIQUIDATED":
                return <h6><span className="badge badge-info">Thanh lý</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return lang.type.new;
            case "EXTENSION":
                return lang.type.extenstion;

            default:
                return;
        }
    }

    handleChangePage = pageNumber => {
        this.setState(
            {
                pageNumber: pageNumber - 1,
                showLoading: true,
            },
            () => {
                this.getListContractFromAPI();
            }
        );
    };


    // ------------------------------------------------------------------------------------------------------


    render() {
        let {listData, pageNumber, header} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title={lang.title}/>
                                    <form className="pt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-6 pl-0">
                                                <TextField
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    fullWidth
                                                    size={"small"}
                                                    label={lang.input.lableCode}
                                                    placeholder="E.g: HD08784757"
                                                    variant="outlined"
                                                    value={this.state.code_temp}
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onChange={val => {
                                                        this.setState({
                                                            code_temp: val.target.value.trim()
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-xs-12 col-6 pr-0">
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    options={status}
                                                    inputValue={this.state.status_temp_search}
                                                    getOptionLabel={option => option.label}
                                                    style={{width: "100%"}}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            label={lang.input.lableStatus}
                                                            placeholder={lang.input.placeholderStatus}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onInputChange={val => {
                                                        if (val === null || val.target.value === undefined) {
                                                            this.setState({
                                                                status_temp_search: ""
                                                            });
                                                        } else {
                                                            this.setState({
                                                                status_temp_search: val.target.value
                                                            });
                                                        }
                                                    }}
                                                    onChange={(val, c) => {
                                                        this.setState({
                                                            status_temp: c?.value,
                                                            status_temp_search: c?.label
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='mt-2 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                                <div className="button-filter">
                                                    <button
                                                        type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => this.onhandleSearch()}
                                                    >
                                                        Tìm kiếm
                                                    </button>
                                                    {/*&nbsp;&nbsp;*/}
                                                    <button type="button"
                                                            className="btn border-1-s btn-light btn-sm"
                                                            onClick={() => {
                                                                this.onHandleClearText();
                                                            }}
                                                    >Refresh
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <div className="text-align-right mb-2">Tổng số Hợp đồng: {this.state.totalItem}</div>
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    let descend = "";
                                                    if (data.sortAsc == null) {
                                                        // a = "id=asc";
                                                        a = "id";
                                                        descend = 'DESC'
                                                    } else {
                                                        a = data.sortId;
                                                        descend = data.sortAsc ? 'ASC' : 'DESC'
                                                    }
                                                    this.setState(
                                                        {
                                                            sort: a,
                                                            descending: descend
                                                        },
                                                        () => this.getListContractFromAPI()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData || this.state.listData.length === 0
                                                ? <tbody>
                                                <tr>
                                                    <td colSpan={12}>
                                                        <div className='text-center'>Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                : <tbody>
                                                {listData &&
                                                listData.length > 0 &&
                                                listData?.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            <Link
                                                                // onClick = {() =>  Utils.addOrUpdateUrlParam("contractId", row.id)}
                                                                to={{
                                                                    pathname: "/customer/contracts-detail",
                                                                    search: `?contractId=${row.id}&code=${row.code}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        contractId: row.contractId,
                                                                        code: row.code,
                                                                    }
                                                                }}
                                                                style={{color: "#67b1ee"}}
                                                            >
                                                                {row.code}
                                                            </Link>
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {row.cmnCreatedAt ? moment(row.cmnCreatedAt).format('DD-MM-YYYY') : 'Chưa có dữ liệu'}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>{row.name}</td>
                                                        <td style={{textAlign: "left"}}>
                                                            {" "}
                                                            {this.renderContractType(row.type)}
                                                        </td>
                                                        <td style={{textAlign: "right", paddingRight: 20}}>
                                                            <NumberFormat
                                                                value={row.value}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                // suffix={"đ"}
                                                            />
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row.supervisorId}
                                                        </td>

                                                        <td style={{textAlign: "center"}}>
                                                            {this.renderStatus(row.assignStatus)}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>}
                                        </table>
                                    </div>
                                    <div className="pagination-right justify-flex-end">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                    {/*<Grid container justify={"flex-end"}>*/}
                                    {/*    <TablePagination*/}
                                    {/*        rowsPerPageOptions={[*/}
                                    {/*            10,*/}
                                    {/*            20,*/}
                                    {/*            30,*/}
                                    {/*            40,*/}
                                    {/*            {label: "All", value: this.state.totalItem}*/}
                                    {/*        ]}*/}
                                    {/*        colSpan={3}*/}
                                    {/*        component="div"*/}
                                    {/*        count={this.state.totalItem}*/}
                                    {/*        page={pageNumber}*/}
                                    {/*        rowsPerPage={this.state.pageSize}*/}
                                    {/*        labelRowsPerPage={lang.table.rowsPerPage}*/}
                                    {/*        // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                                    {/*        // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                                    {/*        labelDisplayedRows={({from, to, count}) =>*/}
                                    {/*            `${from}-${to === -1 ? count : to} / ${count}`*/}
                                    {/*        }*/}
                                    {/*        onChangePage={(event, newPage) => {*/}
                                    {/*            this.setState({pageNumber: newPage}, () => {*/}
                                    {/*                this.getListContractFromAPI();*/}
                                    {/*            });*/}
                                    {/*        }}*/}
                                    {/*        onChangeRowsPerPage={event => {*/}
                                    {/*            this.setState(*/}
                                    {/*                {*/}
                                    {/*                    pageSize: event.target.value,*/}
                                    {/*                    pageNumber: pageNumber*/}
                                    {/*                },*/}
                                    {/*                () => {*/}
                                    {/*                    this.getListContractFromAPI();*/}
                                    {/*                }*/}
                                    {/*            );*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractsManagement;
