import React, {Component} from "react";
import './index.css'
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker/es";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import ModalAddSupervisor from "../../../components/Modal/modalAddSupervisor";
import ModalBlockSupervisor from "../../../components/Modal/modalBlockSupervisor";
import ModalUnblockSupervisor from "../../../components/Modal/modalUnblockSupervisor";
import ModalDeleteSupervisor from "../../../components/Modal/modalDeleteSupervisor";
import ModalRemoveSupFromBranch from "../../../components/Modal/modalRemoveSupFromBranch";
import ModalAddSupToBranch from "../../../components/Modal/modalAddSupToBranch";

import SupervisorApi from "../../../services/ob-user/supervisor";
import BranchApi from "../../../services/ob-user/branch"
import {toast} from "react-toastify";
import { checkPermission } from "../../../utils/common";

const dateFormat = 'DD-MM-YYYY';

const renderType = function (type) {
    switch (type) {
        case 'TAX_REPORT':
            return 'Báo cáo thuế';
        default:
            return 'Báo cáo tài chính';
    }
};

class SupervisorManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalAdd: false,
            showModalBlock: false,
            showModalUnblock: false,
            showModalDel: false,
            showRemoveFromBranch: false,
            showAddToBranch: false,
            fullName: '',
            branchName_temp: "",
            branchName: "",
            fullName_temp: '',
            date: new Date(),
            itemSelected: {},
            listData: [],
            listBranch: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 4,
            totalPage: 0,
            status: '',
            sort: '',

            id: '',
            nameBlock: '',

            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "fullName",
                    name: "Tên Supervisor",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "identify",
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "countContractProcessing",
                    name: "Số hợp đồng đang thực hiện",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "countAccountant",
                    name: "Số kế toán đang phụ trách ",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "branchName",
                    name: "Chi nhánh",
                    style: {textAlign: "center", width: 100},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {textAlign: "center", width: 182},
                    sort: false
                }
            ]
        }
    }

    handleShowModalAdd = () => {
        this.setState({showModalAdd: true});
    };
    onCloseModalAdd = () => {
        this.setState({showModalAdd: false});
    }
    handleShowModalBlock = (data) => {
        this.setState({showModalBlock: true, id: data.id, nameBlock: data.fullName});
    };
    onCloseModalBlock = () => {
        this.setState({showModalBlock: false, id: '', nameBlock: ''});
    }
    handleShowModalUnblock = (data) => {
        this.setState({showModalUnblock: true, id: data.id, nameBlock: data.fullName});
    };
    onCloseModalUnblock = () => {
        this.setState({showModalUnblock: false, id: '', nameBlock: ''});
    }
    handleShowModalDel = (data) => {
        this.setState({showModalDel: true, id: data.id});
    };

    onCloseModalDel = () => {
        this.setState({showModalDel: false, id: ''});
    }

    componentDidMount() {
        this.getListSupervisorFromAPI()
    }

    getListSupervisorFromAPI() {
        let {pageNumber, pageSize, status, fullName, sort, branchName} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            status: status,
            fullName: fullName,
            branchName: branchName,
            sort: sort,
        };
        SupervisorApi.searchByFullNameAndStatus(payload, res => {
                if (res.success) {
                    let listBranchId = [];
                    let data = res.data.content
                    data.map(item => {
                        listBranchId.push(item.branchId)
                    })
                    this.setState({
                        listData: data,
                        totalItem: res.data.totalElements,
                        totalPage: Math.round((res.data.totalElements) / this.state.pageSize),
                        listBranchId
                    }, () => {
                        if (data.length > 0) {
                            this.getListBranch();
                        }
                    })
                } else {
                    toast.error("Lỗi lấy dữ liệu. Vui lòng tải lại trang !")
                }
        })
    }

    getListBranch = () => {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        let payload = {
            id: profile.id
        }
        BranchApi.getListById(payload, res => {
            if (res.success) {
                this.setState({
                    listBranch: res.data,
                    showLoading: false
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    onhandleSearch = () => {
        let {fullName_temp, status_temp, branchName_temp} = this.state;
        this.setState(
            {
                fullName: fullName_temp.toLowerCase(),
                status: status_temp,
                branchName: branchName_temp,
                pageNumber: 0
            },
            () => {
                this.getListSupervisorFromAPI();
            }
        );
    };

    renderStatus(status) {
        switch (status) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            // case "CANCELED":
            //     return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "REJECT":
                return <h6><span className="badge badge-danger">Đã từ chối</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    }

    render() {
        const {pageNumber, pageSize, totalItem, listBranch} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="supervisor-management" parentClass="staff-management-partner"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Danh sách Supervisor"/>

                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3">
                                                <label className="label-item">Tên supervisor</label>
                                                <input
                                                    maxLength={20} type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Nhập vào tên supervisor..."
                                                    value={this.state.fullName_temp}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') this.onhandleSearch()
                                                    }}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            fullName_temp: event.target.value.trimLeft()
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-md-3">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({status_temp: val.target.value}, () =>
                                                            console.log(this.state.status_temp)
                                                        )
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='WAIT_ACTIVE'>Chờ kích hoạt</option>
                                                    <option value='ACTIVE'>Đang hoạt động</option>
                                                    <option value='LOCK'>Đã khóa</option>
                                                    {/* <option value='CANCELED'>Đã hủy</option> */}
                                                    <option value='REJECT'>Đã từ chối</option>
                                                    <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                    <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option>
                                                </select>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-4 p-0 pr-0 pr-sm-0">
                                                <label className="label-item">Chi nhánh</label>
                                                <select
                                                    value={this.state.branchName_temp}
                                                    onChange={val =>
                                                        this.setState({branchName_temp: val.target.value}, () =>
                                                            console.log(this.state.status_temp)
                                                        )
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    {/*<option value='null'>Chưa có chi nhánh</option>*/}
                                                    {listBranch.map((item, index) =>
                                                        <option value={item.branchName}>{item.branchName}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className='mt-3 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0 '>

                                                <button
                                                    type="button"
                                                    style={{height: 35}}
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onhandleSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        style={{height: 34}}
                                                        onClick={() => {
                                                            this.setState({
                                                                fullName_temp: '',
                                                                status_temp: '',
                                                                branchName_temp: "",
                                                            }, () => {
                                                                this.onhandleSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                                {(checkPermission("add_new_supervisor_information")) &&
                                                <button
                                                    style={{height: 34}}
                                                    type="button"
                                                    className="border-1-s background-btn type-button-fix btn btn-primary btn-sm shadow-none"

                                                    onClick={() =>
                                                        this.handleShowModalAdd()
                                                    }
                                                >
                                                    Thêm mới
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="text-align-right mb-2">Tổng số Supervisor: {this.state.totalItem}</div>
                                    <div className="table-wrapper">
                                        <table
                                            className="table table-striped table-data table-responsive table-bordered">
                                            <table className="table">
                                                <thead>
                                                <TableHeader header={this.state.header} clickSort={(data) => {
                                                    console.log(data);
                                                    let a = '';
                                                    if (data.sortAsc == null) {
                                                        a = null
                                                    } else {
                                                        a = `${data.sortId}=${data.sortAsc}`;
                                                    }
                                                    // console.log(a);
                                                    this.setState({
                                                        sort: a
                                                    }, () => this.getListSupervisorFromAPI())
                                                }}/>
                                                </thead>
                                                {!this.state.listData || this.state.listData.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <div className='text-center'>Không có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.listData.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{this.state.pageNumber * pageSize + index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                <span>{row.fullName}</span>
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <span>{row.phone}</span>
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>
                                                                <span>{row.countContractProcessing}</span>
                                                            </td>
                                                            <td style={{textAlign: 'center',}}>
                                                                <span>{row.countAccountant}</span>
                                                            </td>
                                                            <td style={{textAlign: 'left',}}>
                                                                <span>{row.branchName !== null ? row.branchName : "Chưa có chi nhánh"}</span>
                                                            </td>
                                                            <td style={{textAlign: 'center'}}>{this.renderStatus(row.status)}</td>
                                                            <td className='p-1' style={{textAlign: 'center',}}>
                                                                <Link
                                                                    to={{
                                                                        pathname: "./supervisor-detail",
                                                                        search: `?supervisorId=${row.id}`,
                                                                        state: {
                                                                            fromDashboard: true,
                                                                            supervisorId: row.supervisorId,
                                                                        }
                                                                    }}
                                                                    style={{color: "#000000"}}
                                                                >
                                                                    {(checkPermission("view_supervisor_account_detail")) &&
                                                                    <button
                                                                        className="btn btn-action p-2"
                                                                        title="Xem chi tiết"
                                                                    >
                                                                        <i className="ti-book"/>
                                                                    </button>
                                                                    }
                                                                </Link>
                                                                {(checkPermission("block_supervisor_account")) &&
                                                                <button
                                                                    disabled={row.status !== 'ACTIVE' && row.status !== 'LOCK'}
                                                                    onClick={row.status === "LOCK" ? () => this.handleShowModalUnblock(row) : () => this.handleShowModalBlock(row)}
                                                                    className="btn btn-action p-2"
                                                                    title={row.status === "LOCK" ? "Mở khóa" : "Khóa"}
                                                                >
                                                                    <i className={row.status === "LOCK" ? "ti-unlock" : "ti-lock"}/>
                                                                </button>
                                                                }
                                                                {(checkPermission("delete_supervisor_account")) &&
                                                                <button
                                                                    // disabled={row.status !== 'ACTIVE'}
                                                                    onClick={() => this.handleShowModalDel(row)}
                                                                    className="btn btn-action p-2"
                                                                    title="Xóa"
                                                                >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                                }
                                                                {(checkPermission("add_supervisor_to_branch")) &&
                                                                <button
                                                                    disabled={row.status !== 'ACTIVE'|| row.branchId}
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row}, () => this.setState({showAddToBranch: true}))
                                                                    }}
                                                                    className="btn btn-action p-2"
                                                                    title="Cập nhật vào chi nhánh"
                                                                >
                                                                    <i className="ti-stats-down"/>
                                                                </button>
                                                                }
                                                                {(checkPermission("remove_supervisor_from_branch")) &&
                                                                <button
                                                                    disabled={row.status !== 'ACTIVE' || !row.branchId}
                                                                    onClick={() => {
                                                                        this.setState({itemSelected: row}, () => this.setState({showRemoveFromBranch: true}))
                                                                    }}
                                                                    className="btn btn-action p-2"
                                                                    title="Rút khỏi chi nhánh"
                                                                >
                                                                    <i className="ti-share"/>
                                                                </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </table>
                                    </div>
                                    <div className="mt-3 pagination-right">
                                        <Pagination
                                            activePage={pageNumber + 1}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={totalItem}
                                            changeHandler={(pageNumber) =>
                                                this.setState({
                                                    pageNumber: pageNumber - 1
                                                }, () => this.getListSupervisorFromAPI())
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAddSupervisor show={this.state.showModalAdd}
                    // onRefresh={() => this.getListSupervisorFromAPI()}
                                    onRefresh={() => {
                                        this.setState({showModalAdd: false}, () => this.getListSupervisorFromAPI())
                                    }}
                                    onHide={this.onCloseModalAdd}/>
                <ModalBlockSupervisor show={this.state.showModalBlock}
                                      id={this.state.id}
                                      name={this.state.nameBlock}
                                      onRefresh={() => {
                                          this.setState({showModalBlock: false}, () => this.getListSupervisorFromAPI())
                                      }}
                                      onHide={this.onCloseModalBlock}/>
                <ModalUnblockSupervisor show={this.state.showModalUnblock}
                                        id={this.state.id}
                                        name={this.state.nameBlock}
                                        onRefresh={() => this.getListSupervisorFromAPI()}
                                        onHide={this.onCloseModalUnblock}/>
                <ModalDeleteSupervisor show={this.state.showModalDel}
                                       id={this.state.id}
                                       onRefresh={() => this.getListSupervisorFromAPI()}
                                       onHide={this.onCloseModalDel}/>
                {this.state.showRemoveFromBranch && <ModalRemoveSupFromBranch show={this.state.showRemoveFromBranch}
                                                                              supervisor={this.state.itemSelected}
                                                                              onRefresh={() => this.setState({showRemoveFromBranch: false}, () => this.getListSupervisorFromAPI())}
                                                                              onHide={() => this.setState({showRemoveFromBranch: false})}/>}
                {this.state.showAddToBranch && <ModalAddSupToBranch show={this.state.showAddToBranch}
                                                                    supervisor={this.state.itemSelected}
                                                                    onRefresh={() => this.setState({showAddToBranch: false}, () => this.getListSupervisorFromAPI())}
                                                                    onHide={() => this.setState({showAddToBranch: false})}/>}
            </div>
        );
    }

}

export default SupervisorManagement

