import React from 'react';
import {StarFill, Star, ChevronLeft} from 'react-bootstrap-icons';
import './style.css';
import Convert from "../../../utils/convertUrlPra";
import PublicSupervisorApi from "../../../services/ob-user/public-supervisor";
import {URL_IMG_PUB} from '../../../url.config'

class DetailSupervisor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supervisorId: 0,
            data: {},
            showLoading: false
        }
    }

    componentDidMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({
            supervisorId: params.s?.split("-")[0],
            showLoading: true
        }, () => {
            // console.log(params)
            // console.log(this.state.supervisorId)
            this.getDetailSup()
        })
    }

    getDetailSup = () => {
        let payload = {
            id: this.state.supervisorId
        }
        PublicSupervisorApi.getDetailById(payload, res => {
            this.setState({
                data: res.data,
                showLoading: false
            })
        })
    }

    render() {
        const {data} = this.state;
        return (
            <div className='detail-sup-wrapper container'>
                {/*<div className='w-100 text-left mb-4'>*/}
                {/*  <Link to={{pathName: '/#'}}>*/}
                {/*    <ChevronLeft color='black' size={14} />*/}
                {/*    Quay lại trang chủ*/}
                {/*  </Link>*/}
                {/*</div>*/}
                {this.state.showLoading &&
                <div className="content-loading col-12 ml-3 align-items-center"
                     style={{display: 'block'}}>
                    <div className="spinner-grow text-info"/>
                </div>
                }
                <div className='detail-content box-border-radius text-left'>
                    <div className=''>
                        <div className='row detail-sup-1'>
                            <div className='detail-sub-avt'>
                                <img src={data?.urlImage} className="detail-img"/>
                            </div>
                            <div className='col-sm-8 d-flex align-items-center justify-content-center'>
                                <h2 className="company-name color-main">
                                    {data?.fullName}
                                    {/*{data.partnerName}*/}
                                </h2>
                            </div>
                        </div>
                        <div className='detail-sup-desc'>
                            <div className='part mb-4'>
                                <h5 className='text-bold mb-2'>Giới thiệu</h5>
                                {/*<p>Chúng tôi có sứ mệnh</p>*/}
                                <p style={{'white-space': 'pre-wrap'}}>{data?.description}</p>
                                {/*<p className='text-uppercase'>*/}
                                {/*    "tối ưu hóa chi phí thuế - nâng tầm kế toán doanh nghiệp"*/}
                                {/*</p>*/}
                            </div>
                            <div className='part mb-4'>
                                <h5 className='text-bold mb-2'>Kinh nghiệm làm việc</h5>
                                <p style={{'white-space': 'pre-wrap'}}>{data?.certificate}</p>
                            </div>
                            <div className='part mb-4'>
                                <h5 className='text-bold'>Lĩnh vực</h5>
                                <ul>
                                    {data?.fieldSupport?.split(',').map(item => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className='part mb-4'>
                                <h5 className='text-bold mb-2'>Khu vực hỗ trợ</h5>
                                <p>{data?.areaName}</p>
                            </div>
                            <div className='part mb-4'>
                                <h5 className='text-bold mb-2'>Công ty quản lý</h5>
                                <p>{data?.partnerName}</p>
                            </div>
                            {/*<div className='part'>*/}
                            {/*    <h5 className='text-bold mb-2'>Câu chuyện khách hàng</h5>*/}
                            {/*    <div className='rating mb-2'>*/}
                            {/*        {data?.vote >= 1 ? <StarFill color='yellow'/> : <Star/>}*/}
                            {/*        {data?.vote >= 2 ? <StarFill color='yellow'/> : <Star/>}*/}
                            {/*        {data?.vote >= 3 ? <StarFill color='yellow'/> : <Star/>}*/}
                            {/*        {data?.vote >= 4 ? <StarFill color='yellow'/> : <Star/>}*/}
                            {/*        {data?.vote >= 5 ? <StarFill color='yellow'/> : <Star/>}*/}
                            {/*    </div>*/}
                            {/*    <p>*/}
                            {/*        "có trách nghiệm, tôi rất hài lòng và sẽ hợp tác lâu*/}
                            {/*        dài"*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailSupervisor;
