import React from 'react';
import oke from '../../../../assets/images/service/oke.png';
import tuvan from '../../../../assets/images/service/tuvan.jpg';
import './style.css';
import logo_1 from "../../../../assets/images/logo_1.png";
class TCDN extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='about-us-wrapper'>
          <div className='ab-content container'>
            <div className='row bg-white ab-block'>
              <div className='col-sm-12 text-part flex-wrap'>
                <div className='col-sm-1'>
                </div>
                <div className='col-sm-11'>
                  <div className='ab-content-title'>
                    Dịch vụ tư vấn tài chính doanh nghiệp
                  </div>
                  <div className='ab-content text-left'>
                    <p className='custom-text'>
                      <b>
                        “Môi trường kinh doanh không ngừng thay đổi, tạo ra những cơ hội và thách thức mới
                        về tài chính. Chúng tôi có thể đáp ứng tốt nhất các nhu cầu về tư vấn tài chính cho các
                        doanh nghiệp trong nước và quốc tế”
                      </b>
                    </p>
                    <p className='custom-text'>
                      <b>
                        “Với đội ngũ chuyên gia giàu kinh nghiệm tư vấn và quản lý tài chính thực tế tại nhiều
                        doanh nghiệp trên hệ thống, chúng tôi luôn sẵn sàng cung cấp cho khách hàng các dịch
                        vụ tư vấn tài chính hiệu quả và chất lượng nhất”
                      </b>
                    </p>
                    <img className='img-bctc' src={tuvan} />
                  </div>
                  <div className='ab-content text-left'>
                    <p className='custom-text'>
                      Với kinh nghiệm tư vấn và thực tế quản lý tài chính tại nhiều doanh nghiệp có quy mô khác
                      nhau, kết hợp chặt chẽ với các chuyên gia hàng đầu về tư vấn đầu tư, tư vấn thu xếp vốn cho
                      doanh nghiệp, cho dự án, đồng thời chúng tôi cũng là đối tác tin cậy của nhiều tổ chức tài
                      chính, tổ chức tín dụng, chúng tôi có thể cùng với doanh nghiệp đưa ra các giải pháp tài chính
                      đáp ứng các thách thức về tài chính trong điều kiện kinh doanh nhiều thay đổi như hiện nay.
                      Dịch vụ tư vấn tài chính doanh nghiệp của chúng tôi tập trung những vấn đề sau:
                    </p>
                    <div className='list-dn black'>
                      <div className='row row-bctc '>
                        <div className='col-sm-1 imb-tcdn'>
                          <img src={oke} />
                        </div>
                        <div className='col-sm-11 text-part flex-wrap'>
                          <p className='op2'>
                            Tư vấn lập chiến lược tài chính cho doanh nghiệp từ 5 đến 10 năm
                          </p>
                          <p className='op2'>
                            Tư vấn lập kế hoạch kinh doanh, kế hoạch tài chính, kế hoạch lưu chuyển tiền hàng năm
                          </p>
                        </div>
                      </div>
                      <div className='row row-bctc '>
                        <div className='col-sm-1 imb-tcdn'>
                          <img src={oke} />
                        </div>
                        <div className='col-sm-11 text-part flex-wrap'>
                          <p className='op2'>
                            Tư vấn hỗ trợ quản trị tài chính thường xuyên
                          </p>
                          <p className='op2'>
                            Cung cấp nhân sự quản trị tài chính cao cấp tạm thời
                          </p>
                        </div>
                      </div>
                      <div className='row row-bctc '>
                        <div className='col-sm-1 imb-tcdn'>
                          <img src={oke} />
                        </div>
                        <div className='col-sm-11 text-part flex-wrap'>
                          <p className='op2'>
                            Tư vấn tái cơ cấu trúc danh mục đầu tư, tái cơ cấu nợ, tái cấu trúc vốn
                          </p>
                          <p className='op2'>
                            Tư vấn chuẩn bị nêm yết và phát hành cổ phiếu ra công chúng
                          </p>
                        </div>
                      </div>
                      <div className='row row-bctc '>
                        <div className='col-sm-1 imb-tcdn'>
                          <img src={oke} />
                        </div>
                        <div className='col-sm-11 text-part flex-wrap'>
                          <p className='op2'>
                            Tư vấn các giả pháp thu xếp vốn cho các dự án đầu tư.
                          </p>
                          <p className='op2'>
                            Xác định giá trị doanh nghiệp và giá trị cổ phiếu.
                          </p>
                        </div>
                      </div>
                      <div className='row row-bctc '>
                        <div className='col-sm-1 imb-tcdn'>
                          <img src={oke} />
                        </div>
                        <div className='col-sm-11 text-part flex-wrap'>
                          <p className='op2'>
                            Tư vấn mua bán và sáp nhập doanh nghiệp;
                          </p>
                          <p className='op2'>
                            Tư vấn kiểm toán đánh giá tổng thể doanh nghiệp (Due Diligence);
                          </p>
                          <p className='op2'>
                            Tư vấn phân tích và đánh giá tính khả thi của dự án đầu tư
                          </p>
                        </div>
                      </div>
                    </div>

                    <p className='custom-text'>
                      <i>
                        Đối với các dịch vụ tư vấn tài chính nêu trên, các khách hàng có thể trực tiếp liên hệ với các
                        trung tâm dịch vụ để trao đổi trực tiếp về nội dung và phí dịch vụ.
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default TCDN;
