export const reportCodeOptions = [
  {
  textSearch: "331-Phải trả cho người bán",
    label: "Phải trả cho người bán",
    value: "331"
  },
  {
  textSearch: "3311-Phải trả ngắn hạn người bán",
    label: "Phải trả ngắn hạn người bán",
    value: "3311"
  },
  {
  textSearch: "33111-Phải trả cho người bán: hoạt động SXKD",
    label: "Phải trả cho người bán: hoạt động SXKD",
    value: "33111"
  },
  {
  textSearch: "33112-Phải trả ngắn hạn cho người bán: hoạt động đầu tư",
    label: "Phải trả ngắn hạn cho người bán: hoạt động đầu tư",
    value: "33112"
  },
  {
  textSearch: "33113-Phải trả ngắn hạn cho người bán: hoạt động tài chính",
    label: "Phải trả ngắn hạn cho người bán: hoạt động tài chính",
    value: "33113"
  },
  {
  textSearch: "3312-Phải trả dài hạn người bán",
    label: "Phải trả dài hạn người bán",
    value: "3312"
  },
  {
  textSearch: "33121-Phải trả dài hạn người bán: hoạt động SXKD",
    label: "Phải trả dài hạn người bán: hoạt động SXKD",
    value: "33121"
  },
  {
  textSearch: "33122-Phải trả dài hạn người bán: hoạt động đầu tư",
    label: "Phải trả dài hạn người bán: hoạt động đầu tư",
    value: "33122"
  },
  {
  textSearch: "33123-Phải trả dài hạn người bán: hoạt động tài chính",
    label: "Phải trả dài hạn người bán: hoạt động tài chính",
    value: "33123"
  },
  {
  textSearch: "3388-Phải trả, phải nộp khác",
    label: "Phải trả, phải nộp khác",
    value: "3388"
  },
  {
  textSearch: "33881-Phải trả, phải nộp ngắn hạn khác",
    label: "Phải trả, phải nộp ngắn hạn khác",
    value: "33881"
  },
  {
  textSearch: "338811-Phải trả, phải nộp khác: HĐ SXKD",
    label: "Phải trả, phải nộp khác: HĐ SXKD",
    value: "338811"
  },
  {
  textSearch: "338812-Phải trả, phải nộp ngắn hạn khác: HĐ đầu tư",
    label: "Phải trả, phải nộp ngắn hạn khác: HĐ đầu tư",
    value: "338812"
  },
  {
  textSearch: "338813-Phải trả, phải nộp ngắn hạn khác: HĐ tài chính",
    label: "Phải trả, phải nộp ngắn hạn khác: HĐ tài chính",
    value: "338813"
  },
  {
  textSearch: "33882-Phải trả, phải nộp dài hạn khác",
    label: "Phải trả, phải nộp dài hạn khác",
    value: "33882"
  },
  {
  textSearch: "33882-Phải trả, phải nộp dài hạn khác",
    label: "Phải trả, phải nộp dài hạn khác",
    value: "33882"
  },
  {
    textSearch: "338821-Phải trả, phải nộp dài hạn khác: HĐSXKD",
    label: "Phải trả, phải nộp dài hạn khác: HĐSXKD",
    value: "338821"
  },
  {
    textSearch: "338822-Phải trả, phải nộp dài hạn khác: HĐ đầu tư",
    label: "Phải trả, phải nộp dài hạn khác: HĐ đầu tư",
    value: "338822"
  },
  {
    textSearch: "338823-Phải trả, phải nộp dài hạn khác: HĐ tài chính",
    label: "Phải trả, phải nộp dài hạn khác: HĐ tài chính",
    value: "338823"
  }
];
