import React, {Component} from 'react';
import {withSnackbar} from "notistack";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import "./Modal.css"

import Rating from 'react-rating'
import {
    Modal,
} from 'react-bootstrap';

import PublicContractApi from "../../services/ob-core/public-contract";

class RatingSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: true,
            idSelected: this.props.itemSelected ? this.props.itemSelected.id : '',
            rate: "NO_VOTE",
            score: 0,
            commentTag: [false, false, false, false, false, false],
            comment: '',
        };
    }

    componentDidMount() {

    }

    renderRating = (score) => {
        let rate = "";
        switch (score) {
            case (null):
                return this.setState({rate: "NO_VOTE", score})
            case (1):
                return this.setState({rate: "ONE_STAR", score})
            case (2):
                return this.setState({rate: "TWO_STAR", score})
            case (3):
                return this.setState({rate: "THREE_STAR", score})
            case (4):
                return this.setState({rate: "FOUR_STAR", score})
            case (5):
                return this.setState({rate: "FIVE_STAR", score})
        }
    }

    submitReview = () => {
        let payload = {
            assignId: this.props.contract.assignId,
            reviewSupervisorComment: this.state.comment,
            reviewSupervisorScore: this.state.rate,
        }
        PublicContractApi.reviewSupervisor(payload, res => {
            if (res.success) {
                // this.props.onHide();
                this.props.onRefresh();
                return (toast.success(`Gửi đánh giá Supervisor thành công !`))
            } else {
                return (toast.error("Thao tác thất bại. Vui lòng thử lại!" + res.message))
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHide: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        let {comment, rate, commentTag} = this.state;
        let {contract} = this.props
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h5 className="text-center m-3 mb-4 font-weight-bold" style={{fontSize: 20}}>ĐÁNH GIÁ
                                SUPERVISOR</h5>
                        </div>
                        <div>
                            <div className='row kt-margin-b-20'>
                                <div
                                    className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Mã hợp đồng: &nbsp;</label>
                                </div>
                                <div
                                    className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                                    {contract?.code}
                                </div>
                            </div>
                            <div className='row kt-margin-b-20'>
                                <div
                                    className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Tên Supervisor: &nbsp;</label>
                                </div>
                                <div
                                    className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                                    {contract?.supervisorName}
                                </div>
                            </div>
                            <div className='text-center'>
                                <Rating
                                    start={0}
                                    stop={5}
                                    initialRating={this.state.score}
                                    emptySymbol={
                                        <i
                                            style={{fontSize: 20, color: '#1b90bd'}}
                                            className="far fa-star m-1"
                                        />
                                    }
                                    fullSymbol={
                                        <i
                                            style={{fontSize: 20, color: "#1b90bd"}}
                                            className="fas fa-star m-1"
                                        />
                                    }
                                    onChange={(val) => {
                                        this.renderRating(val)
                                        // this.setState({rate: val})
                                    }}
                                />
                            </div>
                            <div className='row kt-margin-b-20 mt-4'>
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[0] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[0] = !temp[0];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Thái độ thân thiệt*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[1] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[1] = !temp[1];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Kiến thức nghiệp vụ*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[2] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[2] = !temp[2];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Thời gian giải quyết*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[3] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[3] = !temp[3];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Giải pháp hợp lý*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[4] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[4] = !temp[4];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Khả năng phát triển*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    className="pb-1 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile cursor-pointer">*/}
                                {/*    <div*/}
                                {/*        className={commentTag[5] ? "item-selected w-100 text-center" : "item-unselected w-100 text-center"}*/}
                                {/*        onClick={() => {*/}
                                {/*            let temp = commentTag;*/}
                                {/*            temp[5] = !temp[5];*/}
                                {/*            this.setState({commentTag: temp})*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Sáng tạo*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div
                                    className="pb-1 pt-2 col-md-12 col-lg-12 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                    <div
                                        className="item-unselected w-100"
                                    >
                                            <textarea
                                                value={comment}
                                                rows={7}
                                                placeholder='Nhận xét ...'
                                                className="form-control border-0 shadow-none"
                                                style={{resize: 'none'}}
                                                maxLength={500}
                                                onChange={(e) => {
                                                    this.setState({comment: e.target.value})
                                                }}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-center">
                            {/*<button disabled={this.state.comment.length == 0} type="button"*/}
                            {/*        onClick={this.props.onHide}*/}
                            {/*        className="btn btn-primary btn-sm">Gửi đánh giá*/}
                            {/*</button>*/}
                            <button
                                disabled={comment.length === 0}
                                type="button"
                                className="border-1-s background-btn type-button-fix btn btn-primary"
                                onClick={() => this.submitReview()}
                            >
                                <span className='d-flex justify-content-center'>
                                    Gửi đánh giá
                                </span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default RatingSupervisor
