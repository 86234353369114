import React, {Component} from "react";
import moment from 'moment';
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Convert from "../../../utils/convertUrlPra";
import {URL_IMG_PUB} from "../../../url.config";
import '../../../assets/css/Supervisor.css';
import "react-datepicker/dist/react-datepicker.css";
import Utils from "../../../utils/utils";
import DatePicker from "react-datepicker";

import Sidebar from "../../../components/Sidebar";
import ModalConfirmEdit from "../../../components/Modal/modalConfirmEdit";
import ModalUploadAvatar from "../../../components/Modal/ModalChangeAvatar"
import UploadFile from "../../../components/Modal/modalUploadFile";
import {
    CircularProgress,
} from '@material-ui/core';

import PartnerApi from "../../../services/ob-user/partner";

const formatDate = 'DD-MM-YYYY';

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

const renderProvince = function () {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
};

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const scale = [
    {
        value: "MEDIUM",
        name: "Vừa",
    },
    {
        value: "EXTRASMALL",
        name: "Siêu nhỏ",
    },
    {
        value: "SMALL",
        name: "Nhỏ",
    },

]

class EditPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onEdit: false,
            inEditing: false,
            showUpload: false,
            dataPartner: [],
            listProvince: [],
            listDistrict: [],
            files: [],
            filesCertificate: [],
            modalConfirmDeleteCert: false,
            fileId: null,
            modalUploadAvatar: false,
            showLoading: false,
            taxcode: '',
            phone: '',
            email: '',
            provinceId: '',
            districtId: '',
            address: '',
            website: '',

            representativeName: '',
            representativeSex: '',
            identify: '',
            dateIssue: '',
            placeIssue: '',
            position: '',

            fullNameErr: false,
            taxcodeErr: false,
            phoneErr: false,
            emailErr: false,
            provinceIdErr: false,
            districtIdErr: false,
            addressErr: false,
            websiteErr: false,
            representativeNameErr: false,
            representativeSexErr: false,
            identifyErr: false,
            dateIssueErr: false,
            placeIssueErr: false,
            positionErr: false,
            btnSaveStatus: false,

            showConfirmModal: false,


            loading: false,
            progress: 0,
            confirmCancel: false,

            showModalChangePassword: false,

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Bằng cấp chứng chỉ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File bằng cấp chứng chỉ",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({partnerId: params.partnerId}, () => {
            this.setState({showLoading: true}, () => {
                this.getPartnerInfo();
                // this.getInfoFromLocal();
                this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
            })
        })
    }

    getPartnerInfo() {
        let payload = {
            id: this.state.partnerId
        };
        PartnerApi.getDetail(payload, res => {
            if (res.success) {
                this.setState({
                        dataPartner: res.data,
                        fullName: res.data.fullName,
                        taxcode: res.data.tax,
                        phone: res.data.phone,
                        email: res.data.email,
                        provinceId: res.data.provinceId,
                        districtId: res.data.districtId,
                        address: res.data.address,
                        website: res.data.website,
                        urlImage: res.data.urlImage,
                        representativeName: res.data.representative,
                        representativeEmail: res.data.representativeEmail,
                        representativeSex: res.data.sex,
                        identify: res.data.identify,
                        dateIssue: res.data.dateIssued,
                        placeIssue: res.data.placeIssued,
                        position: res.data.position,
                        showLoading: false,
                    }
                )
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }

    handleSave() {
        let {
            fullName,
            taxcode,
            phone,
            email,
            provinceId,
            districtId,
            address,
            website,
            representativeName,
            representativeEmail,
            representativeSex,
            identify,
            dateIssue,
            placeIssue,
            urlImage,
            position,
        } = this.state;

        let payload = {
            id: this.state.partnerId,
            body: {
                fullName: fullName,
                address: address,
                dateIssued: dateIssue,
                districtId: districtId,
                email: email,
                identify: identify,
                job: "chưa có dữ liệu",
                phone: phone,
                placeIssued: placeIssue,
                position: position,
                provinceId: provinceId,
                representative: representativeName,
                representativeEmail: representativeEmail,
                sex: representativeSex,
                tax: taxcode,
                urlImage: urlImage,
                website: website
            }
        }
        this.setState({loading: true}, () => {
            let timer = setInterval(() => {
                this.tick()
            }, 10);
            setTimeout(() => {
                clearInterval(timer);
                this.setState({
                    loading: false,
                }, () => {
                    PartnerApi.adminUpdatePartner(payload, res => {
                        if (res.success) {
                            toast.success("Thông tin đã được cập nhật thành công !")
                            this.setState({
                                onEdit: false,
                                inEditing: false,
                                btnSaveStatus: false
                            }, () => this.props.history.push(`/admin/partner-detail?partnerId=${this.state.partnerId}`));
                        } else {
                            toast.error("Cập nhật không thành công, vui lòng thử lại sau !")
                        }
                    })
                });
            }, 3000);
        });
    }

    tick = () => {
        // reset when reaching 100%
        let {progress} = this.state;
        this.setState({
            progress: progress >= 100 ? 0 : progress + 1
        });
    };

    handleCancel() {
        if (this.state.inEditing) {
            this.setState({
                confirmCancel: true
            })
        } else {
            this.setState({
                taxcode: this.state.dataPartner.tax,
                phone: this.state.dataPartner.phone,
                email: this.state.dataPartner.email,
                provinceId: this.state.dataPartner.provinceId,
                districtId: this.state.dataPartner.districtId,
                address: this.state.dataPartner.address,
                website: this.state.dataPartner.website,

                representativeName: this.state.dataPartner.representative,
                representativeSex: this.state.dataPartner.sex,
                identify: this.state.dataPartner.identify,
                dateIssue: this.state.dataPartner.dateIssued,
                placeIssue: this.state.dataPartner.placeIssued,
                position: this.state.dataPartner.position,

                fullNameErr: false,
                taxcodeErr: false,
                phoneErr: false,
                emailErr: false,
                addressErr: false,
                websiteErr: false,
                representativeNameErr: false,
                representativeSexErr: false,
                identifyErr: false,
                dateIssueErr: false,
                placeIssueErr: false,
                positionErr: false,
                btnSaveStatus: false,
            }, () => {
                this.props.history.push(`/admin/partner-management`)
            })
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId == provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };
    onChangeDistrict = (e) => {
        this.setState({
            districtId: e.target.value,
            inEditing: true
        })
    };

    modalUploadAvatarClose = () => {
        this.setState({modalUploadAvatar: false});
    }
    modalCropClose = () => {
        this.setState({showModalCrop: false});
    }

    showModalUploadAvatar = () => {
        this.setState({modalUploadAvatar: true})
    }


    render() {
        let userType = localStorage.getItem("ob_cl_userType");
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataPartner, tax, placeIssued, dateIssued, sex, provinceId, districtId} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="profile"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div variant="outlined" className='mb-4 pb-2 card border-color'>
                                <span className='m-2'>
                                    <Link to={`/${userType.toLowerCase()}/partner-management`}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                {/*//**********************************************Thong tin doanh nghiep****************************************************************************************************/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin doanh nghiệp</span>
                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='justify-center img-logo-wrapper' style={{
                                        textAlign: 'center',
                                        backgroundImage: dataPartner.urlImage ? `url(${URL_IMG_PUB + dataPartner.urlImage}` : `url("/images/no-avatar-png.png")`
                                    }}>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <span className="fas fa-camera change-logo-icon" title="Tải lên ảnh đại diện"*/}
                                    {/*          onClick={() => this.setState({modalUploadAvatar: true})}/>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tên doanh nghiệp <span
                                                className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner?.fullName?.toUpperCase()}
                                            {/*<input type="text"*/}
                                            {/*       className={this.state.fullNameErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}*/}
                                            {/*       placeholder="Nhập tên doanh nghiệp"*/}
                                            {/*       value={this.state.fullName}*/}
                                            {/*       maxLength={100}*/}
                                            {/*       onChange={(val) => this.setState({*/}
                                            {/*           fullName: val.target.value.trimLeft(),*/}
                                            {/*           inEditing: true*/}
                                            {/*       }, () => {*/}
                                            {/*           this.state.fullName.length === 0 ? this.setState({*/}
                                            {/*               fullNameErr: true,*/}
                                            {/*               btnSaveStatus: true*/}
                                            {/*           }) : this.setState({*/}
                                            {/*               fullNameErr: false,*/}
                                            {/*               btnSaveStatus: false*/}
                                            {/*           })*/}
                                            {/*       })}*/}
                                            {/*/>*/}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mã số thuế <span
                                                className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            <input type="text"
                                                   className={this.state.taxcodeErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                   placeholder="Nhập mã số thuế"
                                                   value={this.state.taxcode}
                                                   maxLength={14}
                                                   onChange={(val) => this.setState({
                                                       taxcode: val.target.value.trimLeft().replace(/[^0-9]/g, ''),
                                                       inEditing: true
                                                   }, () => {
                                                       this.state.taxcode.length === 0 || this.state.taxcode.length < 10 ? this.setState({
                                                           taxcodeErr: true,
                                                           btnSaveStatus: true
                                                       }) : this.setState({
                                                           taxcodeErr: false,
                                                           btnSaveStatus: false
                                                       })
                                                   })}
                                            />
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <span className="text-black-50">
                                                    Số điện thoại
                                                </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.email}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.provinceId ? renderProvinceId(dataPartner.provinceId) : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.districtId ? renderDistrictId(dataPartner.districtId) : ''}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.address ? dataPartner.address : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Website</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.website}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/********************************Thong tin ca nhan****************************************************/}
                            <div variant="outlined" className='mt-3 pb-3 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin cá nhân</span>
                                </div>
                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Họ và tên</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.representative ? dataPartner.representative.toUpperCase() : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.sex ? dataPartner.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.identify ? dataPartner.identify : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.dateIssued ? moment(dataPartner.dateIssued).format("DD-MM-YYYY") : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.placeIssued ? dataPartner.placeIssued : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Chức vụ</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.position ? dataPartner.position : ''
                                            }
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">
                                                Số điện thoại
                                            </span>
                                        </div>
                                        <div
                                            className={"pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {dataPartner.representativePhone}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email <span
                                                className={'color-red d-inline'}>*</span></span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {/*{dataPartner?.representativeEmail}*/}
                                            <input type="text"
                                                   className={this.state.emailErr ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                                   placeholder="Nhập email"
                                                   value={this.state.representativeEmail}
                                                   maxLength={100}
                                                   onChange={(val) => this.setState({
                                                       representativeEmail: val.target.value,
                                                       inEditing: true
                                                   }, () => {
                                                       this.state.representativeEmail.length === 0 ? this.setState({
                                                           emailErr: true,
                                                           btnSaveStatus: true
                                                       }) : this.setState({
                                                           emailErr: false,
                                                           btnSaveStatus: false
                                                       })
                                                   })}
                                            />
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>

                                <div className="justify-flex-center">
                                    <div className='text-center col-12'>
                                        {this.state.loading &&
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                        >
                                            <CircularProgress size={20} variant="determinate"
                                                              value={this.state.progress}/>
                                        </button>}
                                        {!this.state.loading &&
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            onClick={() => this.handleSave()}
                                        >
                                            Đồng ý
                                        </button>
                                        }
                                        <button type="button"
                                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                onClick={() => this.handleCancel()}
                                        >
                                            Hủy bỏ
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ModalConfirmEdit show={this.state.confirmCancel}
                                              onHide={() => this.setState({confirmCancel: false})}
                                              handleCancel={
                                                  () => this.setState({
                                                      confirmCancel: false,
                                                      inEditing: false
                                                  }, () => this.handleCancel())
                                              }
                            />
                            {this.state.showUpload && <UploadFile show={this.state.showUpload}
                                                                  onHide={() => this.setState({showUpload: false})}/>}
                        </div>
                    </div>
                </div>
                <ModalUploadAvatar show={this.state.modalUploadAvatar}
                                   onHide={this.modalUploadAvatarClose}
                                   updateSuccess={() => {
                                       this.setState({modalUploadAvatar: false})
                                       this.getPartnerInfo()
                                   }}
                />
            </div>
        );
    }
}

export default EditPartner
