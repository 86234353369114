import React from 'react'
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalCreate from '../../../components/Modal/admin/modalCreateServicePlan';
import ModalDeleteProgram from '../../../components/Modal/admin/modalDeleteProgram';
import ModalLockProgram from '../../../components/Modal/admin/modalLockProgram'
import ModalUnlockProgram from '../../../components/Modal/admin/modalUnlockProgram'
import {Link} from "react-router-dom";
import {checkPermission} from "../../../utils/common";
import AdminProgramManager from "../../../services/ob-core/admin-program-manager";
import moment from "moment";
import ModalUnblockAdmin from "../../../components/Modal/modalUnblockAdmin";
const dateFormat = 'DD-MM-YYYY';

class ProgramManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: '',

            sort: '',

            categoryType:  "ALL",
            listData: [],
            itemSelected: "",
            showModalDelete: false,
            showModalLock: false,
            showModalUnlock: false,
            showLoading: false,
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên chương trình',
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'des',
                    name: "Mô tả",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'start_time',
                    name: "Bắt đầu",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'end_time',
                    name: "Kết thúc",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false
                },

            ],
        }
    }
    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListService()
        })

    }

    onSearch = () => {
        this.setState({showLoading: true}, () => {
            this.searchListService()
        })
    }

    searchListService = () => {
        let {pageNumber, pageSize, sort, descending, categoryType} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            type: categoryType,
            sort: sort,
        }
        AdminProgramManager.searchByType(payload, res => {
            this.setState({
                listData: res.data.content,
                totalItem: res.data.totalElements,
                totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                showLoading: false
            })
            console.log(this.state.totalItem);
            console.log(this.state.listData);
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListService()
            }
        );
    }

    renderStatus = (statusType, startDate, endDate) => {
        let currentDate = new Date();
        startDate   = moment(startDate).format("DD-MM-YYYY")
        endDate     = moment(endDate).format("DD-MM-YYYY")
        currentDate = moment(currentDate).format("DD-MM-YYYY")
        switch (statusType) {
            case "PENDING":
                return <h6><span className="badge badge-info">Đang duyệt</span></h6>;
            case "ACTIVE":
                if (startDate <= currentDate && currentDate <= endDate)
                    return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
                else if (currentDate < startDate) {
                    return <h6><span className="badge badge-info">Đang chờ</span></h6>;
                }else {
                    return <h6><span className="badge badge-warning">Hết hạn</span></h6>;
                }
            case "DISABLE":
                return <h6><span className="badge badge-dark">Đã khóa</span></h6>;
            case "EXPIRED":
                return <h6><span className="badge badge-warning">Hết hạn</span></h6>;
            case "DELETEs":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    render() {
        let {pageNumber, pageSize, accountBalance} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="program-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý chương trình"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            {/*<div className='col-4 p-0'>*/}
                                            {/*    <label className="label-item">Phân loại chương trình</label>*/}
                                            {/*    <select*/}
                                            {/*        className="form-control kt-input pd-4-input shadow-none"*/}
                                            {/*        value={this.state.categoryType_temp}*/}
                                            {/*        onChange={(e) => {*/}
                                            {/*            this.setState({*/}
                                            {/*                categoryType_temp: e.target.value*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <option value=''>Tất cả</option>*/}
                                            {/*        <option value='PENDING'>Đang đợi duyệt</option>*/}
                                            {/*        <option value='ACTIVE'>Đang hoạt động</option>*/}
                                            {/*        <option value='EXPIRED'>Hết hạn</option>*/}
                                            {/*        <option value='DISABLE'>Đã bị khóa</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                            <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                                {/*<button*/}
                                                {/*    type="button"*/}
                                                {/*    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"*/}
                                                {/*    onClick={() => {*/}
                                                {/*        this.onSearch()*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    Tìm kiếm*/}
                                                {/*</button>*/}
                                                {checkPermission("create_new_program") &&
                                                <Link
                                                    style={{color: '#000'}}
                                                    to={{
                                                        pathname: "/admin/create-program",
                                                        search: `?servicePlanId=1`,
                                                        state: {
                                                            fromDashboard: true,
                                                            servicePlanId: 1
                                                        }
                                                    }}
                                                >
                                                    <button
                                                        type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        title='Thêm mới'
                                                            style={{cursor: 'pointer'}}
                                                    >
                                                        Thêm mới
                                                    </button>
                                                </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListService())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.name}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.des}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.startDate ? moment(row.startDate).format("DD-MM-YYYY") : ''}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.endDate ? moment(row.endDate).format("DD-MM-YYYY") : ''}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.programStatus, row.startDate, row.endDate)}
                                                    </td>
                                                    <td className='p-1' style={{textAlign: 'center'}}>
                                                        {checkPermission("detail_program") &&
                                                        <Link
                                                            style={{color: '#000'}}
                                                            to={{
                                                                pathname: "/admin/detail-program",
                                                                search: `?programId=${row.id}`,
                                                                state: {
                                                                fromDashboard: true,
                                                                servicePlanId: row.id
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row
                                                                })
                                                            }}
                                                        >
                                                            <button className="btn btn-action p-2"
                                                                    title='Xem chi tiết'
                                                                    style={{cursor: 'pointer'}}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission("edit_program") &&
                                                        <Link
                                                            style={{color: '#000'}}
                                                            to={{
                                                                pathname: "/admin/edit-program",
                                                                search: `?programId=${row.id}`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    programId: row.id
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row
                                                                })
                                                            }}
                                                        >
                                                            <button className="btn btn-action p-2"
                                                                    title='Chỉnh sửa'
                                                                    style={{cursor: 'pointer'}}
                                                            >
                                                                <i className="ti-pencil-alt"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission("edit_program") &&
                                                        <button className="btn btn-action p-2"
                                                                title={row.programStatus === 'DISABLE' ? 'Mở khóa' : 'Khóa'}
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    if (row.programStatus === 'DISABLE') this.setState({
                                                                        itemSelected: row.id,
                                                                        showModalUnlock: true,
                                                                    })
                                                                    else {
                                                                        this.setState({
                                                                            itemSelected: row.id,
                                                                            showModalLock: true
                                                                        })
                                                                    }
                                                                }}
                                                        >
                                                            <i className={row.programStatus !== 'DISABLE' ? "ti-unlock" : 'ti-lock'}/>
                                                        </button>
                                                        }
                                                        {checkPermission("edit_program") &&
                                                        <button className="btn btn-action p-2"
                                                                title='Xóa'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row.id, showModalDelete: true})
                                                                }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                <div className="pagination-right">
                                   <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalDeleteProgram
                    show={this.state.showModalDelete}
                    onHide={() => this.setState({showModalDelete: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />

                <ModalLockProgram
                    show={this.state.showModalLock}
                    onHide={() => this.setState({showModalLock: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />

                <ModalUnlockProgram
                    show={this.state.showModalUnlock}
                    onHide={() => this.setState({showModalUnlock: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
            </div>
        )
    }

}

export default ProgramManagement
