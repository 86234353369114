import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import Utils from '../../utils/utils';
import BranchApi from "../../services/ob-user/branch";
import {toast} from "react-toastify";

function onlyNumber(value) {
    return Utils.filterNumberOnly(value);
}

class ModalCreateBranch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: true,
            name: '',
            countSup: '',
            countAcc: '',
        };
    }

    onSubmit = () => {
        let {name} = this.state;
        let payload = {
            branchName: name
        };
        BranchApi.create(payload, res => {
            if (!res.success) {
                return (toast.error("Thao tác thất bại. Vui lòng thử lại!", {
                    position: toast.POSITION.TOP_RIGHT,
                    // autoClose: 5000
                }))
            } else {
                this.props.onRefresh();
                this.props.onHide();
                return (toast.success(`Thêm mới chi nhánh thành công!`, {
                    position: toast.POSITION.TOP_RIGHT,
                    // autoClose: 5000
                }))
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            // onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body className='pl-2 pr-2'>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}>
                                <b>THÊM MỚI CHI NHÁNH</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-2">
                                    <label className="col-5 label-item pl-0">Tên chi nhánh</label>
                                    <input
                                        maxLength={100} type="text"
                                        className="form-control shadow-none"
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({
                                                name: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                                {/*<div className="col-12 d-flex mb-2">*/}
                                {/*    <label className="col-5 label-item pl-0">Số lượng Supervisor</label>*/}
                                {/*    <input*/}
                                {/*        maxLength={3} type="text"*/}
                                {/*        className="form-control shadow-none"*/}
                                {/*        value={this.state.countSup}*/}
                                {/*        onChange={(event) => {*/}
                                {/*            this.setState({*/}
                                {/*                countSup: onlyNumber(event.target.value)*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className="col-12 d-flex mb-2">*/}
                                {/*    <label className="col-5 label-item pl-0">Số lượng kế toán</label>*/}
                                {/*    <input*/}
                                {/*        maxLength={3} type="text"*/}
                                {/*        className="form-control shadow-none"*/}
                                {/*        value={this.state.countAcc}*/}
                                {/*        onChange={(event) => {*/}
                                {/*            this.setState({*/}
                                {/*                countAcc: onlyNumber(event.target.value)*/}
                                {/*            })*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    disabled={this.state.name.length === 0}
                                    onClick={() => this.onSubmit()}
                                    // disabled={this.state.name.length === 0 || this.state.countAcc.length === 0 || this.state.countSup.length === 0}
                                >
                                    Đồng ý
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                    Hủy bỏ
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalCreateBranch
