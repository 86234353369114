import React from 'react';
import {toast} from 'react-toastify';
import {
    Modal,
} from 'react-bootstrap';
import Dropzone from "react-dropzone";
import ModalNotifySignUpMember from "./modalNotifySignUpMember";
import {CircularProgress} from "@material-ui/core";

import UserWaitingApi from "../../services/ob-user/user-waiting";
import FilesApi from "../../services/resource/files";
import PublicFilesApi from "../../services/resource/public-files";
import ProvinceApi from "../../services/ob-user/province";
import DistrictApi from "../../services/ob-user/district";
import {Link} from "react-router-dom";

class ModalSignupMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: this.props.show ? this.props.show : true,
            showNotify: false,
            files: [],
            fileNameFile: '',
            fullNameFile: '',
            FileSave: [],
            agreeTerms: false,

            fullName: '',
            phone: '',
            email: '',
            description: '',
            application: '',
            areaId: 0,
            provinceId: 0,
            youIs: 'ACCOUNTANT',
            sex: 'FEMALE',

            fullNameErr: false,
            phoneErr: false,
            emailErr: false,
            descriptionErr: false,
            areaErr: false,
            provinceErr: false,
            youIsErr: false,

            loading: false,
            progress: 0,
        };
        this.onShow = this.onShow.bind(this)
    }

    componentWillMount() {
        let location = JSON.parse(localStorage.getItem("province"));
        if (!location) {
            ProvinceApi.getAll(res => {
                if (res.success) {
                    localStorage.setItem("province", JSON.stringify(res.data))
                }
            });
            DistrictApi.getAll(res => {
                if (res.success) {
                    localStorage.setItem("district", JSON.stringify(res.data))
                }
            });
        }
    }

    handleLoginClick = (e) => {
        e.preventDefault();
        this.submit();
    };

    submit = () => {
        let ready = true;
        if (this.state.phone == '' && this.state.fullName === '' && this.state.email == '' && this.state.description == '' && this.state.provinceId == '') {
            this.setState({
                fullNameErr: true,
                phoneErr: true,
                emailErr: true,
                descriptionErr: true,
                provinceErr: true,
            })
            ready = false;
        }
        if (this.state.phone == '') {
            this.setState({
                phoneErr: true,
            })
            ready = false;
        }
        if (this.state.fullName === '') {
            this.setState({
                fullNameErr: true,
            })
            ready = false;
        }
        if (this.state.email === '') {
            this.setState({
                emailErr: true,
            })
            ready = false;
        }
        if (this.state.description === '') {
            this.setState({
                descriptionErr: true,
            })
            ready = false;
        }
        if (this.state.application === '') {
            this.setState({
                applicationErr: true,
            })
            ready = false;
        }
        if (this.state.areaId === '' || this.state.areaId === 0) {
            this.setState({
                areaErr: true,
            })
            ready = false;
        }
        if (this.state.provinceId === '' || this.state.provinceId === 0) {
            this.setState({
                provinceErr: true,
            })
            ready = false;
        }
        if (this.state.youIs === '') {
            this.setState({
                youIsErr: true,
            })
            ready = false;
        }
        if (this.state.youIs === 'SUPERVISOR' && this.state.files.length === 0 && this.state.fileNameFile === '') {
            if (this.state.fileNameFile === '' || this.state.urlFile === '') {
                toast.error('Vui lòng tải file chứng chỉ !')
            }
            this.setState({
                requireUpload: true
            })
            ready = false;
        } else {
            this.setState({
                requireUpload: false
            })
        }

        if (ready) {
            let {fullName, phone, email, description, application, provinceId, areaId, youIs, sex} = this.state;
            let payload = {};
            if (youIs === 'SUPERVISOR') {
                payload = {
                    fullName: fullName,
                    sex: sex,
                    phone: phone,
                    email: email,
                    exp_work: description,
                    applicationToJoin: application,
                    provinceId: provinceId,
                    areaId: areaId,
                    professionalAccountant: true,
                    fileNameFile: this.state.fileNameFile,
                    fullNameFile: this.state.fullNameFile ? this.state.fullNameFile : "Chứng chỉ hành nghề",
                    storageName: this.state.FileSave[0].storageName,
                    destination: this.state.FileSave[0].destination,
                }
            } else {
                payload = {
                    fullName: fullName,
                    sex: sex,
                    phone: phone,
                    email: email,
                    exp_work: description,
                    applicationToJoin: application,
                    provinceId: provinceId,
                    areaId: areaId,
                    professionalAccountant: false,
                }
            }
            if (this.state.FileSave.length === 0 || this.state.files.length === 0) {
                delete payload.fileNameFile
                delete payload.fullNameFile
                delete payload.storageName
                delete payload.destination
            }
            // toast.success('Bạn đã đăng ký tài khoản thành công. Hệ thống sẽ liên hệ với bạn trong thời gian sớm nhất.', {
            //     autoClose: 8000
            // });
            this.setState({loading: true}, () => {
                let timer = setInterval(() => {
                    let {progress} = this.state;
                    this.setState({
                        progress: progress >= 100 ? 0 : progress + 1
                    });
                }, 10);
                setTimeout(() => {
                    clearInterval(timer);
                    this.setState({
                        loading: false,
                    }, () => {
                        UserWaitingApi.signUpNewUser(payload, res => {
                            if (res.success) {
                                this.setState({showNotify: true, show: false,})
                            } else if (res.errorCode === "One-Book-User.E000045") {
                                toast.error('Số điện thoại đã tồn tại trên hệ thống. Vui lòng nhập số điện thoại khác hoặc chuyển qua đăng nhập!')
                            } else {
                                toast.error('Đã xảy ra lỗi. Vui lòng thử lại sau !')
                            }
                        })
                    });
                }, 3000);
            });
            // this.props.onHide();
        }
    }

    keyPress = (event, input) => {
        if (event.keyCode === 13) {
            if (input === 'enter') {
                this.submit();
            } else {
                input.focus();
            }
        }
    };

    onShow = (e) => {
        this.setState({
            fullName: '',
            phone: '',
            email: '',
            description: '',
            fullNameErr: false,
            phoneErr: false,
            emailErr: false,
            descriptionErr: false,
        });
    };

    validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    renderProvince = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.map((item, index) => <option value={item.id}>{item.name}</option>)
    };

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };


    uploadFile = () => {
        let payload = {
            file: this.state.files,
        }

        PublicFilesApi.uploadPublicFile(payload, res => {
            if (res.success) {
                this.setState({
                    fileNameFile: this.state.files[0].name,
                    FileSave: res.data,
                    requireUpload: false
                }, () => {
                    toast.success('Upload thành công !')
                })
            } else {
                toast.error("Upload xảy ra lỗi. Vui lòng thử lại !")
            }
        })

    }


    render() {
        const {files} = this.state;
        const modalProp = {
            show: this.state.show, onHide: this.props.onHide, keyboard: false, backdrop: 'static',
        };
        return (
            <div className='modal-signup-member'>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-medium"
                       onExited={this.onExited}>
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="loginmodal-container">
                            <h1 className="text-center mb-2">Đăng ký gia nhập One Book</h1>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Họ và tên <span
                                    className='color-red d-inline'>*</span></label>
                                <input
                                    className={this.state.fullNameErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    placeholder='Nhập họ và tên'
                                    maxLength={100}
                                    value={this.state.fullName}
                                    onChange={(e) => this.setState({fullName: e.target.value},
                                        () => {
                                            this.state.fullName.length === 0 ? this.setState({fullNameErr: true}) : this.setState({
                                                fullNameErr: false,
                                                btnStatus: false
                                            })
                                        })}
                                />
                                {this.state.fullNameErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng nhập họ và tên !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Giới tính <span
                                    className='color-red d-inline'>*</span></label>
                                <div className="form-check mr-2 mt-2">
                                    <input className="form-check-input" type="radio"
                                           name="femaleRadios" id="female" value="FEMALE"
                                           onClick={(val) => {
                                               this.setState({
                                                   sex: val.target.value,
                                               })
                                           }}
                                           checked={this.state.sex === 'FEMALE'}
                                    />
                                    <label className="form-check-label"
                                           onClick={() => this.setState({sex: 'FEMALE',})}>
                                        Nữ
                                    </label>
                                </div>
                                <div className="pl-4 form-check mt-2">
                                    <input className="form-check-input" type="radio"
                                           name="maleRadios" id="male" value="MALE"
                                           onClick={(val) => {
                                               this.setState({
                                                   sex: val.target.value,
                                                   inEditing: true
                                               })
                                           }}
                                           checked={this.state.sex === 'MALE'}
                                    />
                                    <label className="form-check-label"
                                           onClick={() => this.setState({sex: 'MALE'})}>
                                        Nam
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Số điện thoại <span
                                    className='color-red d-inline'>*</span></label>
                                <input
                                    className={this.state.phoneErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    placeholder='Nhập số điện thoại'
                                    maxLength={11}
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () => {
                                            this.state.phone.length === 0 || this.state.phone.length < 10 ? this.setState({
                                                phoneErr: true,
                                                btnStatus: true
                                            }) : this.setState({phoneErr: false, btnStatus: false})
                                        })}
                                />
                                {this.state.phoneErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">SĐT đang để trống hoặc sai định dạng !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Email <span
                                    className='color-red d-inline'>*</span></label>
                                <input
                                    className={this.state.emailErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    placeholder='Nhập email'
                                    value={this.state.email}
                                    maxLength={50}
                                    onChange={(e) => this.setState({email: e.target.value.trim()},
                                        () => {
                                            this.state.email.length === 0 || !this.validateEmail(this.state.email) ? this.setState({
                                                emailErr: true,
                                                btnStatus: true
                                            }) : this.setState({emailErr: false, btnStatus: false})
                                        })}
                                />
                                {this.state.emailErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Email đang để trống hoặc sai định dạng !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Tỉnh/Thành phố <span
                                    className='color-red d-inline'>*</span></label>
                                <select
                                    className={this.state.provinceErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    value={this.state.provinceId}
                                    onChange={(e) => {
                                        this.setState({
                                            provinceId: e.target.value,
                                            provinceErr: false
                                        })
                                    }}
                                >
                                    <option value={0} hidden>Chọn Tỉnh/Thành phố</option>
                                    {this.renderProvince()}
                                </select>
                                {this.state.provinceErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng chọn tỉnh thành phố !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Kinh nghiệm làm việc <span
                                    className='color-red d-inline'>*</span></label>
                                <textarea
                                    style={{resize: 'none'}}
                                    rows={2}
                                    className={this.state.descriptionErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    placeholder='Nhập mô tả kinh nghiệm bản thân'
                                    maxLength={500}
                                    value={this.state.description}
                                    onChange={(e) => this.setState({description: e.target.value},
                                        () => {
                                            this.state.description.length === 0 ? this.setState({
                                                descriptionErr: true,
                                                btnStatus: true
                                            }) : this.setState({descriptionErr: false, btnStatus: false})
                                        })}
                                />
                                {this.state.descriptionErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng nhập mô tả kinh nghiệm bản thân !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Lý do gia nhập <span
                                    className='color-red d-inline'>*</span></label>
                                <textarea
                                    style={{resize: 'none'}}
                                    rows={2}
                                    className={this.state.applicationErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    placeholder='Nhập lý do gia nhập'
                                    maxLength={500}
                                    value={this.state.application}
                                    onChange={(e) => this.setState({application: e.target.value},
                                        () => {
                                            this.state.application.length === 0 ? this.setState({
                                                applicationErr: true,
                                                btnStatus: true
                                            }) : this.setState({applicationErr: false, btnStatus: false})
                                        })}
                                />
                                {this.state.applicationErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng nhập lý do !</span> :
                                    <div/>}
                            </div>
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Khu vực muốn tham gia <span
                                    className='color-red d-inline'>*</span></label>
                                <select
                                    className={this.state.areaErr ? "inputPassword form-control w-100 col-7 border-input-error" : "inputPassword form-control w-100 col-7"}
                                    value={this.state.areaId}
                                    onChange={(e) => {
                                        this.setState({
                                            areaId: e.target.value,
                                            areaErr: false
                                        })
                                    }}
                                >
                                    <option value={0} hidden>Chọn Tỉnh/Thành phố</option>
                                    {this.renderSupportArea()}
                                </select>
                                {this.state.areaErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng chọn khu vực muốn tham gia !</span> :
                                    <div/>}
                                {/*{this.state.areaErr ?*/}
                                {/*    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng chọn khu vực muốn tham gia !</span> :*/}
                                {/*    <div/>}*/}
                            </div>
                            {this.state.youIs === 'SUPERVISOR' && <div className="row mt-1">
                                <label className="col-5 mt-2">Chứng chỉ <span
                                    className='color-red d-inline'>*</span>
                                <div style={{fontSize: 12, fontStyle: 'italic', width: 160, color: 'red'}}>Vui lòng bấm nút Upload sau khi chọn file !</div>
                                </label>
                                <div className={this.state.requireUpload ? 'col-7 m-0 pl-0 pr-0 d-inline-flex border-input-error justify-content-between' : 'col-7 m-0 pl-0 pr-0 d-inline-flex justify-content-between'}>
                                    <span
                                        style={{width: 202, overflow: 'hidden'}}
                                        className='d-inline-flex'
                                        title='Vui lòng chỉ sử dụng ảnh hoặc tài liệu có định dạng JPG, PNG, PDF.'
                                    >
                                        {/*<a>Chọn file</a>*/}
                                        <Dropzone
                                            class='p-2'
                                            accept="image/*, application/pdf"
                                            onDrop={acceptedFiles => {
                                                this.setState({files: acceptedFiles})
                                            }}
                                            multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            {files && files.length > 0 ? <div className="align-center">
                                                                {/*<ul>{files}</ul>*/}
                                                                {this.state.FileSave.length !== 0 &&
                                                                <i
                                                                    title='Đã upload thành công'
                                                                    className='ti-widget-alt mr-1'
                                                                    style={{
                                                                        backgroundColor: '#85c525',
                                                                        color: '#fff',
                                                                        height: 14
                                                                    }}
                                                                />
                                                                }
                                                                <span title={files[0].name}>{files[0].name}</span>
                                                            </div> : <p>Chọn tập tin để upload</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                    </span>
                                    <span className='pl-1'>
                                        <input
                                            className="form-control shadow-none w-100"
                                            style={{fontSize: 12}}
                                            disabled={this.state.FileSave.length !== 0}
                                            title='Tên mặc định: "Chứng chỉ hành nghề"'
                                            placeholder='Nhập tên file'
                                            maxLength={100}
                                            value={this.state.fullNameFile}
                                            onChange={(e) => {
                                                this.setState({
                                                    fullNameFile: e.target.value
                                                })
                                            }}
                                        />
                                        <button
                                            type="button"
                                            disabled={this.state.files.length === 0 || this.state.FileSave.length !== 0}
                                            className="btn btn-primary type-button-fix btn-sm shadow-none mt-1"
                                            style={{verticalAlign: 'top'}}
                                            onClick={() => {
                                                this.uploadFile()
                                            }}
                                        >
                                            Upload
                                        </button>

                                    </span>

                                    {/*{this.state.files?.map((item, index) => <div>{item.name}</div>)}*/}
                                </div>
                                {this.state.requireUpload &&
                                <span className="col-12 text-right color-red size-font-err-date">
                                    Vui lòng nhập Upload chứng chỉ nếu bạn là Kế toán nhiều năm kinh nghiệm !
                                </span>}
                            </div>}
                            <div className="row mt-1">
                                <label className="col-5 mt-2">Bạn là? <span
                                    className='color-red d-inline'>*</span></label>
                                <div className='col-7 mt-2'>
                                    {/*<div className="form-check mb-2"*/}
                                    {/*     onClick={() => this.setState({youIs: 'PARTNER', youIsErr: false})}>*/}
                                    {/*    <input className="form-check-input" type="radio"*/}
                                    {/*           checked={this.state.youIs === 'PARTNER'}/>*/}
                                    {/*    <label className="form-check-label">*/}
                                    {/*        Doanh nghiệp cung cấp dịch vụ*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}
                                    <div className="form-check mb-2"
                                         onClick={() => this.setState({youIs: 'SUPERVISOR', youIsErr: false})}>
                                        <input className="form-check-input" type="radio"
                                               checked={this.state.youIs === 'SUPERVISOR'}/>
                                        <label className="form-check-label">
                                            Kế toán nhiều năm kinh nghiệm (> 10 năm)
                                        </label>
                                    </div>
                                    <div className="form-check mb-2"
                                         onClick={() => this.setState({youIs: 'ACCOUNTANT', youIsErr: false})}>
                                        <input className="form-check-input" type="radio"
                                               checked={this.state.youIs === 'ACCOUNTANT'}/>
                                        <label className="form-check-label">
                                            Kế toán ít kinh nghiệm
                                            (Kế toán mới ra trường hoặc có ít kinh nghiệm)
                                        </label>
                                    </div>
                                </div>
                                {this.state.youIsErr ?
                                    <span className="col-12 text-right pl-1 color-red size-font-err-date">Vui lòng chọn "Bạn là?" !</span> :
                                    <div/>}

                            </div>

                            <div className="d-flex justify-content-center mt-3">
                                <div className="form-group">
                                    <div className="checkbox clearfix">
                                        <label>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    const value = this.state.agreeTerms;
                                                    this.setState({agreeTerms: !value});
                                                }}
                                                checked={this.state.agreeTerms}
                                            />
                                            {' '}
                                            Tôi đã đọc và đồng ý với <Link  target="_blank" to="/dieu-khoan-dich-vu" replace>Quy định và quy chế </Link> của nền tảng
                                            SAS
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {this.state.loading &&
                            <button
                                type="button"
                                className="btn btn-original btn-1st w-100 type-button-fix mt-2"
                                onClick={this.handleLoginClick}
                            >
                                <CircularProgress size={23} variant="determinate" color="secondary"
                                                  value={this.state.progress}/>
                            </button>}
                            {!this.state.loading &&
                            <button
                                type="button"
                                disabled={!this.state.agreeTerms}
                                className="btn btn-original btn-1st w-100 type-button-fix mt-2 bg-green"
                                onClick={this.handleLoginClick}
                            >
                                <span>Đăng ký <i className="flaticon-right"/></span>
                            </button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>

                {this.state.showNotify && <ModalNotifySignUpMember show={this.state.showNotify}/>}
            </div>
        );
    }
}

export default ModalSignupMember
