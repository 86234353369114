import React from 'react';
import {toast} from 'react-toastify';
import {URL_IMG_PUB} from "../../url.config";
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {Modal} from 'react-bootstrap';
import Pagination from "../Pagination";

import SupervisorApi from "../../services/ob-user/supervisor";
import PublicOrderApi from "../../services/ob-core/public-order-service";

toast.configure()

class ModalResendOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showAdd: true,
            idSelected: '',
            pageNumber: 0,
            pageSize: 6,
            totalItem: 0,
            // id: this.props.data.id,
            supervisorName: '',
            supervisorId: '',
            listSup: [],
        };
    }

    componentDidMount() {
        this.getListSup()
    }

    getListSup = () => {
        let {pageNumber, pageSize, supervisorName} = this.state;
        let payload = {
            pageNumber,
            pageSize,
            fullName: supervisorName,
        }
        SupervisorApi.searchByFullNameAndAddress(payload, res => {
            // console.log("check>>>", payload)
            if (res.success) {
                this.setState({
                    listSup: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round((res.data.totalElements) / this.state.pageSize),
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    onSubmit = () => {
        let {id, idSelected} = this.state;
        let payload = {
            supervisorId: idSelected,
            id: this.props.order.id
        };
        PublicOrderApi.reassignOrder(payload, res => {
            if (!res.success) {
                return toast.error("Thao tác thất bại. Vui lòng thử lại!" + res.message)
            } else {
                this.props.onRefresh();
                this.props.onHide();
                return (toast.success(`Gửi lại yêu cầu thành công!`))
            }
        })
    }

    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.getListSup())
    };

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div className='text-center'>
                            <h1 className="text-center pb-2 mb-0" style={{fontSize: 20,}}><b>GỬI LẠI YÊU CẦU</b>
                            </h1>
                            <span className="text-center font-italic pt-0">Vui lòng chọn lại Supervisor !</span>
                        </div>
                        <div className='mt-3 mb-2'>
                            <div className="row">
                                <div className="col-12 d-flex mb-2 p-0">
                                    <label className="col-4 label-item pl-0 pt-1">Tên Supervisor</label>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                this.getListSup()
                                            }
                                        }}
                                        className="form-control kt-input pd-4-input shadow-none"
                                        value={this.state.supervisorName}
                                        onChange={(val) =>
                                            this.setState({supervisorName: val.target.value})
                                        }
                                    />
                                    <span
                                        style={{
                                            display: this.state.supervisorName.length === 0 ? 'none' : 'block',
                                            position: 'absolute',
                                            textAlign: 'center',
                                            right: 40,
                                            width: 14,
                                            top: 10,
                                            height: 14,
                                            lineHeight: 14,
                                            borderRadius: '50%',
                                            // background: '#e7e7e7',
                                            background: "url('/images/close-alt-480.png')",
                                            cursor: 'pointer',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain'
                                        }}
                                        onClick={() => this.setState({supervisorName: ''})}
                                    >
                                    </span>
                                    <span
                                        className="btn btn-action"
                                        title="Lọc"
                                        onClick={() => this.getListSup()}
                                        style={{
                                            padding: '4px 6px 4px 6px',
                                            marginLeft: 4,
                                            border: '1px solid #ccc',
                                        }}
                                    >
                                        <i className="ti-filter"/>
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                {/*<div className="col-12 d-flex mb-4">*/}
                                <label className="col-12 label-item pl-0">Danh sách</label>
                                {this.state.listSup.length === 0
                                    ? 'Không có dữ liệu'
                                    : <div className="row col-12 p-0">
                                        {this.state.listSup.map((item, index) =>
                                            <div className="col-xs-12 col-6 mb-1">
                                                <div
                                                    className={item.id === this.state.idSelected ? 'row priority-supervisor p-1' : 'row p-1'}
                                                    style={{
                                                        border: item.id === this.state.idSelected ? '1px solid #57afce' : '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => this.setState({idSelected: item.id})}
                                                >
                                                    <div className="justify-center col-4 text-center p-1">
                                                        {/*<div className='align-middle pb-2'*/}
                                                        {/*     style={{cursor: 'pointer'}}*/}
                                                        {/*     onClick={() => this.handleChangeRadio(item)}*/}
                                                        {/*>*/}
                                                        {/*    <Radio*/}
                                                        {/*        checked={item.id == this.state.idRadio ? true : false}*/}
                                                        {/*        onChange={() => this.handleChangeRadio(item)}*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                        {/*<div className='text-center'>*/}
                                                        <img
                                                            src={item.urlImage && item.urlImage !== "string" ? item.urlImage : '/images/no-avatar-png.png'}
                                                            // src={item.urlImage && item.urlImage !== "string" ? URL_IMG_PUB + item.urlImage : '/images/no-avatar-png.png'}
                                                            className='text-center'
                                                            style={{
                                                                height: 50,
                                                                width: 50
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-8 pl-2'>
                                                        <div className='pt-2 justify-center text-left'>
                                                            <h6
                                                                className='font-weight-bold'
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                {item.fullName}
                                                            </h6>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="pagination-right justify-flex-end mb-0 mt-3">
                                            <Pagination
                                                activePage={this.state.pageNumber + 1}
                                                itemsCountPerPage={this.state.pageSize}
                                                totalItemsCount={this.state.totalItem}
                                                changeHandler={page => this.handleChangePage(page)}
                                            />
                                        </div>
                                    </div>
                                }
                                {/*<select*/}
                                {/*    value={this.state.supervisorId}*/}
                                {/*    onChange={val =>*/}
                                {/*        this.setState({supervisorId: val.target.value})*/}
                                {/*    }*/}
                                {/*    className="form-control kt-input pd-4-input shadow-none">*/}
                                {/*    {this.state.listSup.map((item, index) =>*/}
                                {/*        <option value={item.id}>{item.fullName}</option>*/}
                                {/*    )}*/}
                                {/*</select>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="mt-2 align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    disabled={!this.state.idSelected}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalResendOrder
