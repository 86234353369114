import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicNews = {
    getListByCategory: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/news/get-list-by-id-category?${stringify(payload)}`,
            method: 'GET',
        }, callback)
    },
    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/news/get-detail/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    getListHighlight: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/public/news/get-list-highlight`,
            method: "GET"
        }, callback)
    }
}
export default PublicNews
