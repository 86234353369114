import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let SMSTypeApi = {
    getAllSMS: (callback) => {
        return request({
            url:`${URL_API}/api/one-book-core/secure/sms-type/get-all`,
            method: "GET"
        }, callback)
    },
    getSMSTypeBody: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/sms-type/get-sms-type-body/${payload.type}`,
            method: "GET"
        }, callback)
    },
    updateSMS: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/sms-type/update`,
            method: 'POST',
            body: payload
        }, callback)
    },
}

export default SMSTypeApi
