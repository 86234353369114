import React from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import CloseIcon from '@material-ui/icons/Close';
import {URL_IMG_PUB} from "../../../url.config";

class SupervisorDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supervisor: this.props.supSelected ? this.props.supSelected : {}
        }
    }

    render() {
        const modalProp = {
            open: this.props.show, onClose: this.props.onHide
        };
        return (
            <div>
                <Dialog
                    {...modalProp}
                    fullWidth
                    scroll={'body'}
                >
                    <DialogTitle disableTypography onClose={this.props.onHide}>
                        <h4 className='text-center mb-0'>
                            Thông tin supervisor
                        </h4>
                        <IconButton
                            aria-label="close"
                            onClick={this.props.onHide}
                            style={{
                                position: 'absolute',
                                top: '4px',
                                right: '4px',
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div
                            style={{
                                border: '1px dashed #ccc',
                                borderRadius: '8px',
                            }}
                            className='p-4'
                        >
                            <h4 style={{color: '#3d1e6d'}} className="text-center font-weight-light">
                                {this.state.supervisor.fullName}
                            </h4>
                            <div className="text-center">
                                "Hãy nói theo cách của bạn"
                            </div>
                            <Grid container>
                                <Grid item xs={12} md={8} sm={8}>
                                    <label>Giới thiệu chung:</label>
                                    <p>"Tôi có kinh nghiệm {this.state.supervisor.experience} năm trong nghề . Hiện đang
                                        làm việc tại công ty&nbsp;
                                        {this.state.supervisor.partnerName || "N/A"}"</p>
                                </Grid>
                                <Grid item xs={6} md={4} sm={4}>
                                    <div className="justify-center col-12 text-center supervisor-img-wrapper">
                                        <img
                                            // src={this.state.supervisor.urlImg}
                                            src={this.state.supervisor.urlImage ? URL_IMG_PUB + this.state.supervisor.urlImage : '/images/no-avatar-png.png'}
                                            // src={'https://miscmedia-9gag-fun.9cache.com/images/thumbnail-facebook/1557376304.186_U5U7u5_n.jpg'}
                                            className='ml-1 supervisor-img-avt'
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div
                            style={{
                                border: '1px dashed #00a0b0',
                                borderRadius: '8px',
                            }}
                            className='mt-3 p-4'
                        >
                            <h4 style={{color: '#3d1e6d'}} className="text-center font-weight-light">
                                Dịch vụ cung cấp
                            </h4>
                            <Grid container spacing={2} className='pl-4'>
                                <Grid item md={4} sm={4}>
                                    Báo cáo thuê
                                </Grid>
                                <Grid item md={4} sm={4}>
                                    Báo cáo tài chính
                                </Grid>
                                <Grid item md={4} sm={4}>
                                    Tư vấn thuế
                                </Grid>
                            </Grid>
                        </div>
                        <div
                            style={{
                                border: '1px dashed #00a0b0',
                                borderRadius: '8px',
                            }}
                            className='mt-3 p-4'
                        >
                            <h4 style={{color: '#3d1e6d'}} className="text-center font-weight-light">
                                Lĩnh vực
                            </h4>
                            <Grid container spacing={2} className='pl-4'>

                                <Grid item md={4} sm={4}>
                                    {this.state.supervisor.fieldSupport}
                                </Grid>
                            </Grid>
                        </div>
                        <div
                            style={{
                                border: '1px dashed #00a0b0',
                                borderRadius: '8px',
                            }}
                            className='mt-3 p-4'
                        >
                            <h4 style={{color: '#3d1e6d'}} className="text-center font-weight-light">
                                Câu chuyện khách hàng
                            </h4>
                            <Grid container>
                                <label>Đánh giá: </label>
                                <span>
                                    <Rating defaultValue={this.state.supervisor.vote} precision={0.5}
                                            readOnly/>
                                </span>
                                <p>
                                    "Nhân viên nhiệt tình, rất trách nhiệm. Tôi rất hài lòng về dịch vụ và sẽ tiếp tục
                                    hợp tác lâu dài."
                                </p>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button type="button"
                                className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                onClick={this.props.onHide}
                        >Đóng
                        </button>
                        {/*<Button autoFocus onClick={this.props.onHide} color="default">*/}
                        {/*    Đóng*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default SupervisorDetail;
