import React from 'react';
import {toast} from "react-toastify";
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';
import UserWaitingApi from "../../services/ob-user/user-waiting";

class ModalConfirmToReadyCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
    }


    onConfirm = () => {
        let payload = {
            id: this.props.data.id
        }
        UserWaitingApi.doneToCreateOfficialAccount(payload, res => {
            if (res.success) {
                toast.success(`Chuyển trạng thái của "${this.props.data.fullName}" thành "Chờ tạo tài khoản chính thức" thành công!`)
                this.props.onRefresh();
                this.props.onHide();
            } else {
                toast.error("Thao tác thất bại. Vui lòng thử lại!")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='m-2'>
                            <label>Bạn có muốn chắc chắn muốn chuyển tài khoản "{this.props.data.fullName}" sang trạng thái "Chờ tạo tài khoản chính thức" không ?</label>

                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary shadow-none "
                                        onClick={() => this.onConfirm()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none "
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalConfirmToReadyCreate;




// import React from 'react';
// import {toast} from "react-toastify";
// import "./Modal.css"
//
// import {
//     Modal,
// } from 'react-bootstrap';
// import Autosuggestion from "../Form/Autosuggestion";
//
// import PartnerApi from "../../services/ob-user/partner";
//
// class ModalTransferToPartner extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             partId: '',
//             listPart: [],
//             name: '',
//             partner: ''
//         };
//     }
//
//     componentDidMount() {
//         this.setState({
//             partId: '',
//             listPart: [],
//         });
//         this.getListPart()
//     }
//
//     componentWillReceiveProps(nextProps, nextContext) {
//         if (nextProps.show) {
//             this.setState({
//                 partId: '',
//                 listPart: [],
//             });
//         }
//     }
//
//     getListPart = () => {
//         let payload = {
//             fullName: this.state.partner,
//             pageNumber: 0,
//             pageSize: 8,
//         }
//         PartnerApi.searchByPartnerNameAndAddress(payload, res => {
//             if (res.success) {
//                 this.setState({listPart: res.data.content})
//             }
//         })
//     };
//
//
//     onSubmit = () => {
//         if (this.state.partId === '') {
//             toast.error('Bạn chưa chọn Partner !')
//         } else {
//             this.onChangeSup()
//         }
//     }
//
//     onTransfer = () => {
//         let payload = {
//             partnerId: this.state.partId
//         }
//         // AccountantApi.changeAccountantToSupervisor(payload, res => {
//         //     if (res.errorCode === 'One-Book-User.E000021') {
//         //         toast.error('Đã xảy ra lỗi, vui lòng thử lại !')
//         //     } else if (res.success) {
//         //         toast.success('Chỉnh sửa thành công !')
//         //         this.props.onHide();
//         //         setTimeout(this.props.onRefresh(), 1000);
//         //     } else {
//         //         toast.error('Đã xảy ra lỗi, vui lòng thử lại !')
//         //     }
//         // })
//     }
//
//
//     render() {
//         const modalProp = {
//             show: this.props.show,
//             onHideModal: this.props.onHide,
//             keyboard: false,
//             backdrop: 'static',
//         };
//         return (
//             <div>
//                 <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-assign-order">
//                     <div className="btn-close">
//                         <button type="button" className="close" onClick={this.props.onHide}>
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <Modal.Body>
//                         <div className="modal-container">
//
//                         </div>
//                         <div>
//                             <h1 className="text-center" style={{fontSize: 18}}><b>CHUYỂN THÔNG TIN CHO PARTNER TẠO TÀI KHOẢN</b></h1>
//                         </div>
//                         <div>
//                             <div className="row">
//                                 <label className="col-6 mt-2">Chọn Partner</label>
//                             </div>
//                             <Autosuggestion
//                                 label="Partner"
//                                placeholder="Nhập tên Partner"
//                                 type="text"
//                                 data={this.state.listPart}
//                                 onSelect={(item) => {
//                                     this.setState({selectedPartner: item})
//                                 }}
//                                 onChange={(val) => {
//                                     this.setState({partner: val, selectedPartner: null})
//                                     setTimeout(() => {
//                                         this.getListPart()
//                                     }, 300);
//
//                                 }}
//                                 checkError={!this.state.listPart}
//                                 errorContent="Vui lòng chọn một Partner"
//                             />
//                         </div>
//                         <div className='row'>
//                             <div className='col-12'>
//                                 <div className="align-center mt-4 mb-2">
//                                     <button
//                                         type="button"
//                                         disabled={this.state.partId === '' || this.state.partId === null}
//                                         className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
//                                         onClick={() => this.onTransfer()}
//                                     >
//                                         <span className='d-flex justify-content-center'>
//                                             Đồng ý
//                                         </span>
//                                     </button>
//                                     <button
//                                         type="button"
//                                         className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
//                                         onClick={this.props.onHide}
//                                     >
//                                         <span className='d-flex justify-content-center'>
//                                             Hủy bỏ
//                                         </span>
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </div>
//         );
//     }
// }
//
//
// export default ModalTransferToPartner;
