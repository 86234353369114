import React from 'react';
import Layout from "./components/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css'
import './assets/css/responsive.css'
import './assets/css/fonts.css'
import './assets/css/app.css'
import {
    BrowserRouter,
    Router,
    Switch,
    Route,
    Link, withRouter, Redirect
} from "react-router-dom";
import Home from './routes/home'
import Customer from './routes/customer'
import Notification from "./components/Notification/Notification";
import Partner from "./routes/partner";
import Supervisor from "./routes/supervisor";
import Accountant from "./routes/accountant";
import ConfirmEmail from "./routes/common/confirm-email";
import Cookies from "js-cookie";
import {forceLogout} from "./utils/authority";
import Admin from "./routes/admin";
import CallCenter from "./routes/call-center";
import HomePage from "./routes/home/homepage";
import GenericNotFound from "./routes/404";
import DieuKhoan from "./routes/dieukhoan";
// import firebase from "firebase/app";


// import { createBrowserHistory } from "history";
// const history = createBrowserHistory();
// import { messaging } from "./init-fcm";
// import {useHistory} from 'react-router-
import MessengerCustomerChat from 'react-messenger-customer-chat';
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: false
        }
    }
    componentWillMount() {
        let token = Cookies.get('ob_cl_token');
        this.setState({isAuth: !!token})
    }

    async createNotificationListeners() {

    }

    render() {
        return (
           <Layout>
              <Notification/>
              {/*{!this.state.isAuth && <Redirect to={"/"}/>}*/}
              <Route exact path="/" component={HomePage}/>{/*haovc remove*/}
              <Route path="/home" component={Home}/>
              <Route path="/confirm-email" component={ConfirmEmail}/>
              <Route path="/admin" component={Admin}/>
              <Route path="/call-center" component={CallCenter}/>
              <Route path="/customer" component={Customer}/>
              <Route path="/partner" component={Partner}/>
              <Route path="/supervisor" component={Supervisor}/>
              <Route path="/accountant" component={Accountant}/>
               <Route path="/404" component={GenericNotFound} />
               <Route path="/dieu-khoan-dich-vu" component={DieuKhoan} />
               {/*<Redirect to="/404" />*/}
               {/*<Route path='*' exact={true} component={GenericNotFound} />*/}
               <MessengerCustomerChat
                   pageId="102112485586198"
                   appId="661587754826809"
               />
           </Layout>
        );
  }
}

export default withRouter(App)
