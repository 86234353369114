
import { stringify } from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let DistrictApi = {
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/district/get-all`,
            method: 'GET'
        }, callback)
    },
    getByProvinceId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/district/get-by-province-id/${payload.id}`,
            method: 'GET'
        }, callback)
    }
};
export default DistrictApi
