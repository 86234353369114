import React from 'react'
import { Link, Switch, Route, Redirect } from "react-router-dom";
import Profile from './profile'
import OrdersManagement from './orders-management'
import AdvanceOrdersManagement from "./advance-orders-management";
import NewOrdersManagement from './new-orders'
import CustomerManagement from "./customer-management";
import SupervisorManagement from "./supervisor-management";
import NewSignUpManagement from "./new-signup-management";
import { checkPermission } from '../../utils/common';

class CallCenter extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isCallCenter: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isCallCenter: userType == "CALL_CENTER"})
    }

    render() {
        return(
            <Switch>
                {!this.state.isCallCenter && <Redirect to={"/"}/>}
                <Route path="/call-center/profile" component={Profile}/>
                {checkPermission("view_order_list") && <Route path="/call-center/orders-management" component={OrdersManagement} />}
                {checkPermission("view_order_list") && <Route path="/call-center/advance-orders-management" component={AdvanceOrdersManagement} />}
                {checkPermission("view_order_list") && <Route path="/call-center/new-orders" component={NewOrdersManagement} />}
                <Route path="/call-center/customer-management" component={CustomerManagement} />
                <Route path="/call-center/supervisor-management" component={SupervisorManagement} />
                {checkPermission("management_new_accounts_registered_to_the_platform") &&
                <Route path="/call-center/new-signup-management" component={NewSignUpManagement} />}
            </Switch>
        )
    }
}

export default CallCenter
