export const reportCodeOptions =  [
    {
      label: "Báo cáo lưu chuyển tiền tệ",
      value: "v20GLTC6"
    },
    {
      label: "Báo cáo lưu chuyển tiền tệ giữa niên độ",
      value: "V20GLTC61"
    },
    {
      label: "Báo cáo theo thông tư 200",
      value: "V20GLTC62"
    },
    {
      label: "Báo cáo giữa niên độ theo thông tư 200",
      value: "V20GLTC63"
    },
    {
      label: "Báo cáo theo thông tư 133",
      value: "V20GLTC64"
    }
  ];
export const reportCodeOptions200 =  [
    {
        label: "Báo cáo theo thông tư 200",
        value: "V20GLTC62"
    },
    {
        label: "Báo cáo giữa niên độ theo thông tư 200",
        value: "V20GLTC63"
    }
];
export const reportCodeOptions133 =  [
    {
        label: "Báo cáo lưu chuyển tiền tệ",
        value: "v20GLTC6"
    },
    {
        label: "Báo cáo lưu chuyển tiền tệ giữa niên độ",
        value: "V20GLTC61"
    },
    {
        label: "Báo cáo theo thông tư 133",
        value: "V20GLTC64"
    }
];
  export const reportFormOptions = [
    {
      label: "Mẫu chuẩn - năm",
      value: "10"
    },
    {
      label: "Mẫu chuẩn - giữa niên độ",
      value: "20"
    },
    {
      label: "Mẫu chuẩn - giữa niên độ - tóm lược",
      value: "30"
    }
  ]