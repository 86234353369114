import React from "react";
import { withSnackbar } from "notistack";
import moment from "moment";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import DatePicker from "react-datepicker";
import ReportApi from "../../../services/ob-core/report";
import { getUserInfo } from "../../../utils/authority";
import {
    reportCodeOptions
} from "./mockdata";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class AccountsReceivableReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateTo: null,
            accountInfo: null,
            orderBy: "0",
            language: "v",
            dateToPrev: null,
            dateFromPrev: null,
            loading: false,
            unit: ""
        };
    }

    isInvalidForm() {
        const {
            dateFrom,
            dateTo,
            reportCode,
        } = this.state;
        return (
            dateFrom === null ||
            dateTo === null ||
            reportCode === null
        );
    }

    onSubmit() {
        const { accountInfo, orderBy, language } = this.state;
        let dateTo = moment(this.state.dateTo).format("yyyyMMDD");
        let dateFrom = moment(this.state.dateFrom).format("yyyyMMDD");

        let mailTo = _.get(getUserInfo(), "email");
        let account = accountInfo.value;
        const payload = {
            id: 400,
            data: {
                dateTo,
                dateFrom,
                account,
                orderBy,
                language,
                mailTo,
                unit: "",
                customerID: "",
                mailCC: "",
                mailBCC: ""
            }
        };
        this.setState({ loading: true });
        console.log(payload)
        ReportApi.createReport(payload, res => {
            this.setState({ loading: false });
            if (res.success === true) {
                const resData = JSON.parse(res.data);
                if (_.get(resData, "Success") !== "0") {
                    this.notify("success", "Tạo báo cáo thành công!");
                } else {
                    this.notify("error", resData.Message);
                }
            } else {
                this.notify(
                    "error",
                    "Tạo báo cáo không thành công, vui lòng thử lại sau !"
                );
            }
        });
    }

    notify(variant, message) {
        this.props.enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: "top",
                horizontal: "right"
            },
            variant
        });
    }

    componentDidMount() {
        // this.setState({showLoading: true}, () => {
        //     this.searchListPartnerFromAPI()
        // })
    }

    render() {
        const {
            dateFrom,//yyyyMMdd
            dateTo,//yyyyMMdd
            accountInfo,
            orderBy,
            unit,
            language,
            mailTo,
            mailCC,
            mailBCC,
            loading
        } = this.state;
        return (
            <div className="container">
                <div
                    className="loading"
                    style={loading ? { display: "block" } : { display: "none" }}
                >
                    <div className="lds-dual-ring"></div>
                </div>
                <div className="row">
                    <Sidebar activeClass="report-receivable" parentClass="report-management" />
                    <div className="mb-4 col-md-10 tab-content">
                        <div>
                            <PageHeader routerEnable={false} title="Báo cáo công nợ phải thu" />
                        </div>
                        <div className="mt-3 mb-3" style={{ padding: "0 18px" }}>
                            <div className="row">
                                <div className="col-12 d-flex mb-2 pl-0">
                                    <label className="col-2 label-item pl-0">
                                        Tài khoản<span style={{ color: "red" }}> *</span>
                                    </label>
                                    <Autocomplete
                                        id="country-select-demo"
                                        className="col-10 kt-input pd-2-input shadow-none mb-1"
                                        options={reportCodeOptions}
                                        autoHighlight
                                        getOptionLabel={(option) => option.textSearch}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                accountInfo:newValue
                                            });
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span>{option.value}-{option.label}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Chọn tài khoản"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 mb-3" style={{ padding: "0 18px" }}>
                            <div className="row">
                                <div className="col-12 d-flex mb-2 pl-0">
                                    <label className="col-2 label-item pl-0">
                                        Sắp xếp theo
                                    </label>
                                    <select
                                        className="form-control col-10 kt-input pd-2-input shadow-none mb-1"
                                        onChange={e => {
                                            this.setState({
                                                orderBy: e.target.value
                                            });
                                        }}
                                    >
                                        <option key="ma-khach" value="0">
                                            Mã khách
                                        </option>
                                        <option key="ten-khach" value="1">
                                            Tên khách
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <fieldset
                            className="area-content-modal mb-3"
                            style={{ width: "100%" }}
                        >
                            <legend
                                className="font-14 "
                                style={{ paddingLeft: 10 }}
                                style={{ width: 80, paddingLeft: 10 }}
                            >
                                Thời gian
                            </legend>
                            <div className="row" style={{ padding: "0 9px" }}>
                                <div className="col-12 d-flex mb-2 p-0">
                                    <div className="col-2 d-flex mb-2 pl-0">
                                        <label className="label-item " htmlFor="dateFrom">
                                            Từ ngày
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                    </div>
                                    <div className="col-4 d-flex mb-2 pl-0">
                                        <DatePicker
                                            dropdownMode="select"
                                            id="dateFrom"
                                            className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                            placeholderText="Từ ngày"
                                            // onChangeRaw={this.handleDateChangeRaw}
                                            selected={dateFrom === null ? "" : new Date(dateFrom)}
                                            // value={dateFrom}
                                            maxDate={new Date()}
                                            // minDate={new Date()}
                                            // value={dateIssue}
                                            dateFormat={"dd-MM-yyyy"}
                                            isClearable={dateFrom === null ? false : true}
                                            // fixedHeight
                                            // customInput={<input className="w-auto2 react-datepicker-wrapper2"/>}
                                            selectsStart
                                            showMonthDropdown
                                            showYearDropdown
                                            onChange={date => {
                                                this.setState({
                                                    dateFrom: date === null ? null : date
                                                });
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="col-2 d-flex mb-2 "
                                        style={{ paddingLeft: 20 }}
                                    >
                                        <label className="label-item " htmlFor="dateTo">
                                            Tới ngày
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                    </div>
                                    <div className="col-4 d-flex mb-2 pl-0">
                                        <DatePicker
                                            dropdownMode="select"
                                            id="dateTo"
                                            className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                            placeholderText="Đến ngày"
                                            // onChangeRaw={this.handleDateChangeRaw}
                                            selected={dateTo === null ? "" : new Date(dateTo)}
                                            // customInput={<input className="w-auto3 react-datepicker-wrapper2"/>}
                                            selectsEnd
                                            showMonthDropdown
                                            showYearDropdown
                                            disabled={dateFrom === null ? true : false}
                                            isClearable={dateTo === null ? false : true}
                                            // fixedHeight
                                            dateFormat={"dd-MM-yyyy"}
                                            onChange={date => {
                                                this.setState({
                                                    dateTo: date === null ? null : date
                                                });
                                            }}
                                            startDate={new Date(dateFrom)}
                                            endDate={new Date(dateTo)}
                                            minDate={
                                                dateFrom === null ? new Date() : new Date(dateFrom)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div>
                            <div className="d-flex justify-content-end mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn shadow-none"
                                    style={{
                                        backgroundColor: "#169bd5",
                                        borderColor: "#169bd5",
                                        color: "white"
                                    }}
                                    // disabled={this.state.start.length === 0 || this.state.end.length === 0 || this.state.price.commercePrice === 0 || this.state.price.manufacturingPrice === 0 || this.state.price.buildingPrice === 0}
                                    disabled={this.isInvalidForm()}
                                    onClick={() => this.onSubmit()}
                                >
                                    Khởi tạo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(AccountsReceivableReport);
