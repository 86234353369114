import React from 'react';
import { toast } from "react-toastify";
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import CallCenterApi from "../../../services/ob-user/call-center";

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class ModalDetailCallCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            data: this.props.data ? this.props.data : {},
            onEdit: false,
            email: this.props.data ? this.props.data.email : ''
        };
    }

    componentDidMount() {
        this.setState({ userType: localStorage.getItem("ob_cl_userType") })
        console.log("check propss>>>>>>", this.props)
        if (this.props.onEdit)
            this.setState({
                onEdit: true
            })
    }

    onSubmit = () => {
        let ready = true;
        let { data, email } = this.state;
        if (email === '' || !validateEmail(email || email === null)) {
            ready = false;
            this.setState({ emailError: true })
        }
        if (ready) {
            let payload = {
                id: data.id,
                body: {
                    address: data.address,
                    dateIssued: data.dateIssued,
                    districtId: data.districtId,
                    email: email,
                    fullName: data.fullName,
                    identify: data.identify,
                    placeIssued: data.placeIssued,
                    provinceId: data.provinceId,
                    sex: data.sex,
                    urlImage: data.urlImage,
                    // status: data.status
                }
            }
            CallCenterApi.updateById(payload, res => {
                if (res.success) {
                    this.props.onHide();
                    this.props.onRefresh();
                    return toast.success('Cập nhật Call Center thành công !');
                } else {
                    return toast.error('Cập nhật không thành công, vui lòng thử lại !');
                }
            })
        }
    }

    renderStatus(status) {
        switch (status) {
            case "WAIT_COMPLETE_INFO":
                return "Chờ hoàn thiện thông tin";
            case "WAIT_CONFIRM_EMAIL":
                return "Chờ xác nhận Email";
            case "WAIT_ACTIVE":
                return "Chờ kích hoạt";
            case "LOCK":
                return "Đã khóa";
            case "ACTIVE":
                return "Đang hoạt động";
            case "CANCELED":
                return "Đã xóa";
        }
    }

    renderDistrictId = (districtId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : [];
        return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
    };

    renderProvinceId = (provinceId) => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
    };

    render() {
        // let {listData, pageNumber, header} = this.state;
        let { data } = this.state
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{ fontSize: 20, }}><b>{this.state.onEdit ? 'Cập nhật thông tin Call Center' : 'Thông tin Call Center'}</b></h1>
                        </div>
                        <div className="row">
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Tên</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>{data.fullName || "Chưa có dữ liệu"}</span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Số điện thoại </label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>{data.phone || "Chưa có dữ liệu"}</span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Email</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                {this.state.onEdit
                                    ? <input type="text"
                                        className={this.state.emailError ? "form-control border-input-error kt-input pd-4-input hei-input" : "form-control kt-input pd-4-input hei-input"}
                                        placeholder='Nhập email'
                                        value={this.state.email}
                                        maxLength={100}
                                        onChange={(val) => this.setState({
                                            email: val.target.value.trimLeft(),
                                        }, () => {
                                            this.state.email.length === 0 ? this.setState({
                                                emailError: true,
                                            }) : this.setState({
                                                emailError: false,
                                            })
                                        })}
                                    />
                                    : <span style={{ fontWeight: "bold" }}>{data.email || "Chưa có dữ liệu"}</span>
                                }
                                {this.state.emailError &&
                                    <div className="invalid-feedback d-block">
                                        Vui lòng nhập Email !
                                </div>}
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">CMND/CCCD</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>{data.identify || "Chưa có dữ liệu"}</span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Địa chỉ</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>{data.address || "Chưa có dữ liệu"}</span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Quận/Huyện</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>
                                    {this.renderDistrictId(data.districtId) || "Chưa có dữ liệu"}
                                </span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Tỉnh/Thành phố</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>
                                    {this.renderProvinceId(data.provinceId) || "Chưa có dữ liệu"}
                                </span>
                            </div>
                            <div className="pl-0 pb-2 pr-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <label className="text-black-50">Trạng thái</label>
                            </div>
                            <div className="pl-0 col-md-6 col-lg-6 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{ fontWeight: "bold" }}>
                                    {this.renderStatus(data.status) || "Chưa có dữ liệu"}
                                </span>
                            </div>
                        </div>
                        {this.state.onEdit && <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    disabled={this.state.email?.length === 0}
                                    onClick={() => this.onSubmit()}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Xác nhận
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDetailCallCenter;

