import React from 'react'
import {Button, Card, Form, Table} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import AdminUserManagement from '../../../services/ob-core/admin-user-management'
import AdminRoleApi from '../../../services/ob-core/admin-role'
import RoleApi from "../../../services/ob-user/role";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import {toast} from "react-toastify";

import ModalAddNew from '../../../components/Modal/admin/modalCreateAdminUser'
import ModalBlock from '../../../components/Modal/admin/modalBlockAdminUser'
import ModalUnblock from '../../../components/Modal/admin/modalUnblockAdminUser'
import ModalDel from '../../../components/Modal/admin/modalDelAdminUser'
import ModalDetail from '../../../components/Modal/admin/modalDetailAdminUser'
import ModalUpdate from '../../../components/Modal/admin/modalUpdateAdminUser'
import ModalResetPass from '../../../components/Modal/admin/modalResetPassAdminUser'
import {checkPermission} from "../../../utils/common";

class UserManagement extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            page:0,
            pageNumber: 0,

            pageSize: 10,
            totalItem:'',

            listData: [],
            listRole: [],
            listPermissionId: [],

            roleSearch: '',
            clear: false,
            sort: 'id',
            descending: 'DESC',

            role:'',
            status:'',
            role_temp:'',
            status_temp:'',

            itemSelected:'',
            nameUser:'',

            showModalAddNew: false,
            showModalBlock: false,
            showModalUnblock: false,
            showModalEdit: false,
            showModalDelete: false,
            showModalDetail:false,
            showModalUpdate:false,
            showModalReset:false,
            showLoading: false,

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'full_name',
                    name: "Tên Admin",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: true,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'rules',
                    name: "Nhóm quyền",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    // id: 'beforeTransaction',
                    name: "Thao tác",
                    style: {minWidth: 200, textAlign: "center"},
                    sort: false
                }
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getList();
            this.getListRole()
        })
    }

    getList = () => {
        let {pageNumber, pageSize, status, role, sort, descending, listPermissionId} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            status: status,
            roleId: role,
            sort: sort,
            descending: descending
        };
        AdminUserManagement.getList(payload, res => {
            if(res.success) {
                let data = res.data.content
                let listPermissionId = []
                data.map(item => {
                    listPermissionId.push(item.permissionId);
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    listPermissionId,
                    showLoading: false
                }, () => {
                    if(data.length > 0) this.getNamePermissionByIds()
                })
            }
        })
    }

    getListRole = () => {
        let{page, pageSize, roleSearch} = this.state;
        let payload = {
            pageNumber: page,
            pageSize: pageSize,
            roleName: roleSearch,
        };
        AdminRoleApi.getList(payload, res => {
            if(res.success) {
                this.setState({
                    listRole: res.data.content
                })
            }
        })
    }

    getNamePermissionByIds = () => {
        let {listData, listPermissionId} = this.state;
        let payload = {
            ids: listPermissionId
        }
        RoleApi.getListByIds(payload, res => {
            if(res.success){
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.permissionId);
                    item.nameRole = filter[0]?.nameRole;
                });
                this.setState({
                    listData
                });
            }
        })
    }

    onSearch = () => {
        this.setState({
                role: this.state.role_temp,
                status: this.state.status_temp,
                pageNumber: 0,
            },
            () => {this.getList()}
        )
    }

    onRefresh = () => {
        this.setState({
            roleSearch: null,
            role:'',
            status:'',
            role_temp:'',
            status_temp:'',
            clear:true,
            pageNumber: 0,
        }, ()=> this.onSearch())
    }

    renderPermission = (item) => {
        switch (item) {
            case 1:
                return 'Admin';
            case 2:
                return 'Partner';
            case 3:
                return 'Supervisor';
            case 4:
                return 'Accountant';
            case 5:
                return 'Customer';
        }
    }

    renderStatus = (item) => {
        switch (item) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.getList()
            }
        );
    }

    render() {
        let {pageNumber, pageSize}  = this.state
        return(
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="user-management" parentClass="employee-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Admin User"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Nhóm quyền</label>
                                                <Autosuggestion
                                                    // label="Supervisor"
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    placeholder="Nhập tên nhóm quyền"
                                                    // value={this.state.roleSearch}
                                                    type="text"
                                                    clearSearch={this.state.clear}
                                                    data={this.state.listRole}
                                                    onSelect={(item) => this.setState({role_temp: item.id})}
                                                    onChange={(val) => {
                                                        this.setState({roleSearch: val, clear: false})
                                                        setTimeout(() => {
                                                            this.getListRole()
                                                        }, 300);
                                                    }}
                                                    // checkError={!this.state.supervisorList}
                                                    // errorContent="Vui lòng chọn một Supervisor"
                                                />
                                            </div>

                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.status_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status_temp: e.target.value
                                                        })
                                                    }}>
                                                    <option value=''>Tất cả</option>
                                                    <option value='WAIT_CONFIRM_EMAIL'>Chờ kích hoạt</option>
                                                    <option value=' WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                    <option value='ACTIVE'>Đang hoạt động</option>
                                                    <option value='LOCK'>Đã khoá</option>
                                                    <option value='CANCELED'>Đã xoá</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >Refresh
                                                </button>
                                                {checkPermission("create_admin_user_account") &&
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({showModalAddNew: true})
                                                        }}
                                                >
                                                    Thêm mới
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                {/*        <Table striped bordered hover>*/}
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số Admin User: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            let b = '';
                                            if (data.sortAsc == null) {
                                                a = 'id';
                                                b = 'DESC'
                                            } else {
                                                a = `${data.sortId}`;
                                                b = `${data.sortAsc ? 'ASC' : 'DESC'}`
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a,
                                                descending: b
                                            }, () => this.getList())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            :
                                            <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.nameRole || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td className='p-1' style={{textAlign: 'center'}}>
                                                        {checkPermission("detail_admin_user_ account") &&
                                                        <button className="btn btn-action p-2"
                                                                title="Chi tiết"
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row.id}, () => this.setState({showModalDetail: true}))
                                                                }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        }
                                                        {checkPermission("lock_admin_user_account") &&
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title={row.status === "LOCK" ? "Mở Khóa" : "Khóa"}
                                                            disabled={row.status === "WAIT_ACTIVE" || row.status === "CANCELED" || row.status === "WAIT_COMPLETE_INFO"}
                                                            onClick={() => {
                                                                if (row.status === "LOCK") {
                                                                    this.setState({itemSelected: row.id}, () => this.setState({showModalUnblock: true}))
                                                                } else {
                                                                    this.setState({itemSelected: row.id}, () => this.setState({showModalBlock: true}))
                                                                }
                                                            }}
                                                        >
                                                            <i className={row.status === "LOCK" ? "ti-unlock" :"ti-lock"}/>
                                                        </button>
                                                        }
                                                        {checkPermission("delete_admin_user_account") &&
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title="Xóa"
                                                            disabled={row.status === "CANCELED"}
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showModalDelete: true}))
                                                            }}
                                                        >
                                                            <i className="ti-trash"/>

                                                        </button>
                                                        }
                                                        {checkPermission("edit_admin_user_account") &&
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title="Sửa"
                                                            disabled={row.status === "CANCELED"}
                                                            onClick={() => {
                                                                this.setState({itemSelected: row.id}, () => this.setState({showModalUpdate: true}))
                                                            }}
                                                        >
                                                            <i className="ti-pencil-alt"/>
                                                        </button>
                                                        }
                                                        {checkPermission("reset_password_admin_user") &&
                                                        <button
                                                            className="btn btn-action p-2"
                                                            title="Đổi mật khẩu"
                                                            disabled={row.status !== "ACTIVE"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row.id,
                                                                    nameUser: row.fullName
                                                                }, () => this.setState({showModalReset: true}))
                                                            }}
                                                        >
                                                            <i className="ti-reload"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*</Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <ModalAddNew
                    show={this.state.showModalAddNew}
                    onRefresh={() => this.onSearch()}
                    onHide={() => this.setState({showModalAddNew:false})}
                />
                <ModalUnblock
                    show={this.state.showModalUnblock}
                    onHide={() => this.setState({showModalUnblock:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalBlock
                    show={this.state.showModalBlock}
                    onHide={() => this.setState({showModalBlock:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalDel
                    show={this.state.showModalDelete}
                    onHide={() => this.setState({showModalDelete:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalDetail
                    show={this.state.showModalDetail}
                    onHide={() => this.setState({showModalDetail:false})}
                    id={this.state.itemSelected}
                />
                <ModalUpdate
                    show={this.state.showModalUpdate}
                    onHide={() => this.setState({showModalUpdate:false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
                <ModalResetPass
                    show={this.state.showModalReset}
                    onHide={() => this.setState({showModalReset:false})}
                    // onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                    name={this.state.nameUser}
                />
            </div>
        )
    }

}

export default UserManagement
