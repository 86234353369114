import React from 'react'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {Link} from "react-router-dom";

import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalInfoSignupMember from "../../../components/Modal/modalInfoSignupMember";

toast.configure()
const dateFormat = 'DD-MM-YYYY';

class NewSignUpManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            itemSelected: {},
            position: '',
            status: "",
            listData: [],
            sort: '',
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'position',
                    name: "Vị trí",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreatedAt',
                    name: "Thời gian gửi yêu cầu",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 220, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListNewSignUpOnSearch()
        })
    }

    getListNewSignUpOnSearch = () => {
        let self = this;
        let {pageNumber, pageSize, selectedPartner, status, sort} = this.state;
        let payload = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            status: status,
            // sort: sort
        };
        // SupervisorApi.adminOneBookGetSupervisor(payload, res => {
        //     if (res.success) {
        //         this.setState({
        //             listData: res.data.content,
        //             totalItem: res.data.totalElements,
        //             showLoading: false
        //         })
        //     } else {
                this.setState({
                    showLoading: false
                })
                // }, () => toast.error("Đã có lỗi xảy ra, vui long tải lại trang!"))
        //     }
        // })
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getListNewSignUpOnSearch()
            }
        );
    }

    renderStatus = (status) => {
        switch (status) {
            // case 'WAIT_ACTIVE':
            //     return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            // case "ACTIVE":
            //     return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            // case "LOCK":
            //     return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            // case "CANCELED":
            //     return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            // case "WAIT_COMPLETE_INFO":
            //     return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            // case "WAIT_CONFIRM_EMAIL":
            //     return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    };

    render() {
        let {pageNumber, pageSize} = this.state
        let self = this;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="new-signup-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý đăng ký tài khoản"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Vị trí</label>
                                                <select
                                                    value={this.state.position}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            position: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='SUPERVISOR'>Supervisor</option>
                                                    <option value='ACCOUNTANT'>Kế toán</option>
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='ACTIVE'>Kế toán trọn gói</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            pageNumber: 0
                                                        })
                                                        this.getListNewSignUpOnSearch()
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                status: '',
                                                                position: '',
                                                                sort: ''
                                                            }, () => {
                                                                this.getListNewSignUpOnSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListNewSignUpOnSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.position || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.cmnCreatedAt ? moment(row.cmnCreatedAt) : 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem thông tin đăng ký"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row , showInfo: true})
                                                            }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Tạo tài khoản"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <i className="ti-plus"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showInfo && <ModalInfoSignupMember show={this.state.showInfo}
                                                               onHide={() => this.setState({showInfo: false})}
                                                               data={this.state.itemSelected}
                />}
            </div>
        )
    }

}

export default NewSignUpManagement
