
import { stringify } from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let DashboardUser = {
    getSupAcc: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/sup-acc`,
            method: 'GET'
        }, callback)
    },
    getAccumulated: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/accumulated`,
            method: 'GET'
        }, callback)
    },
    getAccumulatedToNow: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/accumulated-to-now`,
            method: 'GET'
        }, callback)
    },
    getContractOrder: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/contract-order`,
            method: 'GET'
        }, callback)
    },
    getRevenue: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/revenue`,
            method: 'GET'
        }, callback)
    },
    getSaleByMonth: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/sales/${payload.month}`,
            method: 'GET'
        }, callback)
    },
    getDashBoardOfPartnerByMonth: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/dash-board-of-partner-by-month/${payload.month}`,
            method: 'GET'
        }, callback)
    },
    adminGetSupAcc: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/dash-board/admin-get-sup-acc`,
            method: 'GET'
        }, callback)
    },
};
export default DashboardUser
