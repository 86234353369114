import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let AccountantApi = {
    adminGetAccountantOfSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/admin-get-list-accountant-of-supervisor?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    adminGetDetailAccountantById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/admin-one-book-get-detail-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    accountantUpdateAcceptContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/accountant-update-accept-contract/${payload.id}`,
            method: 'POST',
            body: payload.body
        }, callback)
    },
    blockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/block/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    updateAccountantById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/update/${payload.id}`,
            method: 'POST',
            body: payload.body
        }, callback)
    },
    changeAccountantToSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/change-accountant-supervisor`,
            method: 'POST',
            body: payload
        }, callback)
    },
    checkExist: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/check-exist/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    confirmEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/confirm-email`,
            method: 'POST',
            body: payload
        }, callback)
    },
    deleteSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/delete`,
            method: 'POST',
            body: payload
        }, callback)
    },
    detailAccountant: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/detail`,
            method: 'GET'
        }, callback)
    },
    getDetailAccountantById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/get-detail-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    findBySupervisorId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/get-list-by-supervisor-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getNameById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/get-name-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getAccountantNamesByListIds: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/get-names-by-ids?ids=${param}`,
            method: 'GET'
        }, callback)
    },
    findBySupervisorIdAndPartnerId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/partner-get-list-accountant-of-supervisor?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    supervisorCheckPermissionAndAbilityReceiveMoreContracts: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/supervisor-check-permission-and-ability-receice-more-contracts/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    supervisorCheckPermission: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/supervisor-check-permission/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    supervisorCreate: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/supervisor-create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    searchByNameStatusAndSupervisorId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/supervisor-search-by-name-and-status?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getByName: (payload, callback) => {
        return request({
            url: `${URL_API}/secure/accountant/get-all-accountant?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    unblockSupervisor: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/unblock/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    updateAccountant: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/update`,
            method: 'POST',
            body: {
                ...payload.body,
            },
        }, callback);
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/accountant/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },
    partnerResetPasswordAccountant: (payload, callback) => {
        return request({
            url:`${URL_API}/api/one-book-user/secure/accountant/partner-reset-password-accountant`,
            method: "POST",
            body: payload
        }, callback)
    }
};
export default AccountantApi
