import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let AdminProgramManager = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    edit: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/edit`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    searchByType: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/search-by-type?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },

    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/detail/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    block: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/block/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    unblock: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/unblock/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/program/delete/${payload.id}`,
            method: 'GET'
        }, callback)
    },
}
export default AdminProgramManager
