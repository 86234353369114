import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let SecureNews = {
    getListNews: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/get-list?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/create`,
            method: 'POST',
            body: payload,
        }, callback)
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/update/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback)
    },
    hide: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/hide/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    unhide: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/unHide/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/delete/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    unHighlight: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/unHighlight/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    highlight: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news/highlight/${payload.id}`,
            method: 'GET',
        }, callback)
    },
}
export default SecureNews
