export default {
    title: 'Contract list',
    breadcrumbs: {
        home: 'Home',
        titleBreadcrumbs: 'Contract list'
    },
    input:{
        lableCode:'Code',
        lableStatus:'Status',
        placeholderStatus:'All'

    },
    Button:{
        search:'Search',
        refresh:'Refresh'
    },
    table:{
        code:'Code',
        name:'Name',
        value:'Value',
        type:'Type',
        supervisor:'Supervisor ',
        status:'Status',
        rowsPerPage: 'Rows per page',
    },


    status: {
        waitSign: 'WAIT SIGN',
        signed: 'SIGNED',
        processing: 'PROCESSING',
        liquidation: 'LIQUIDATION',
        closed: 'CLOSED',
        success:'Success sign',
        rejected: "REJECTED",
        canceled: "CANCELED"
    },
    type: {
        new: 'NEW',
        extenstion: 'EXTENSION',
    }
}
