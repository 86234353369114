import React from 'react'
import Convert from "../../../../utils/convertUrlPra";
import TableHeader from '../../../../components/TableHeader';
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import AdminProgramManager from "../../../../services/ob-core/admin-program-manager";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import { createHashHistory } from 'history'
import moment from "moment";
import DatePicker from "react-datepicker";

class DetailProgram extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            name: "",
            des: "",
            status: "",
            numberOfInvoicesConstraintId: 0,
            numberOfEmployeesConstraintId: 0,
            numberOfDocumentsConstraintId: 0,
            numberOfMonths: 6,
            serviceList: [],
            header_program: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên gói dịch vụ',
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'category',
                    name: "Loại gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: true,
                },
            ],
        };
    }
    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };
    formatMoney(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    renderDiscountType = (statusType) => {
        switch (statusType) {
            case 1:
                return 'Giảm %';
            case 2:
                return 'Giảm giá trị';
            default:
                return '';
        }
    };
    createChargePackage = (listService) => {
        this.setState({showListServicePackage: false});
        this.setState({serviceList: listService});

    }
    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({programId: params.programId}, () => this.getDetail())
    }
    getDetail = () => {
        let payload = {
            id: this.state.programId
        };
        AdminProgramManager.getDetail(payload, res => {
            if (res.success) {

                this.setState({
                    data: res.data
                })
            } else {
                toast.error('Lỗi lấy dữ liệu !')
            }
        })
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="program-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Quản lý chương trình"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <h2 className='text-center mb-4'>CHI TIẾT CHƯƠNG TRÌNH</h2>
                                        <div className="row">
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Tên chương trình</label>
                                                <label className="label-item">
                                                    {this.state.data?.name}
                                                </label>
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-2 label-item pl-0">Mô tả chương trình</label>
                                                <label className="label-item">
                                                    {this.state.data?.des}
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Kiểu giảm giá</label>
                                                <label className="label-item">
                                                    {this.renderDiscountType(this.state.data?.discountType)}
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Giá trị giảm</label>
                                                <label className="label-item">
                                                    {this.state.data?.discountValue?this.formatMoney(this.state.data?.discountValue):"0"}
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Ngày bắt đầu</label>
                                                <label className="label-item">
                                                    {this.state.data?.startDate ? moment(this.state.data?.startDate).format("DD-MM-YYYY") : ''}
                                                </label>
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Ngày kết thúc</label>
                                                <label className="label-item">
                                                    {this.state.data?.endDate ? moment(this.state.data?.endDate).format("DD-MM-YYYY") : ''}
                                                </label>
                                            </div>
                                            <div className="col-12 d-flex mb-2">
                                                <label className="col-12 label-item pl-0" >Điều kiện tham gia chương trình</label>
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số hóa đơn</label>
                                                <label className="label-item">
                                                    {this.state.data?.numberOfInvoicesConstraintId}
                                                </label>
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Tên chứng từ</label>
                                                <label className="label-item">
                                                    {this.state.data?.numberOfDocumentsConstraintId}
                                                </label>
                                            </div>

                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số nhân sự</label>
                                                <label className="label-item">
                                                    {this.state.data?.numberOfEmployeesConstraintId}
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex mb-2">
                                                <label className="col-4 label-item pl-0">Số kỳ</label>
                                                <label className="label-item">
                                                    {this.state.data?.numberOfMonths}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <div>
                                        <div className="col-12 d-flex mb-2 pr-0">
                                            <h4 className="text-center mb-4">Danh sách các gói dịch vụ</h4>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="table table-striped table-data table-bordered">
                                                <TableHeader header={this.state.header_program}
                                                             clickSort={(data) => {
                                                             }}/>
                                                {!this.state.data?.programServiceResponseList || this.state.data?.programServiceResponseList?.length == 0
                                                    ? <tbody>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center'>Chưa có dữ liệu</div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                    : <tbody>
                                                    {this.state.data?.programServiceResponseList?.map((row, index) => (
                                                        <tr key={row.id} style={{height: 50}}>
                                                            <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                            <td style={{textAlign: 'left',}}>
                                                                {row.servicePlanName}
                                                            </td>
                                                            <td style={{textAlign: 'left'}}>
                                                                {this.renderType(row.type)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default DetailProgram
