import React from 'react';
import {toast} from 'react-toastify'
import "./Modal.css"
import Utils from '../../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import CallCenterApi from "../../../services/ob-user/call-center";

class ModalCreateCallCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            permission: 1,
            repassword: '',
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            permissionErr: false,

            btnStatus: false,
        };
    }

    componentDidMount() {
        this.setState({
            name: '',
            phone: '',
            permission: '',
            password: '',
            repassword: '',
            nameErr: false,
            phoneErr: false,
            permissionErr: false,
            passwordErr: false,
            rePasswordErr: false,
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                name: '',
                phone: '',
                permission: '',
                password: '',
                repassword: '',
                nameErr: false,
                passwordErr: false,
                rePasswordErr: false,
                phoneErr: false,
                permissionErr: false,
                btnStatus: false,
            });
        }
    }

    onSubmit = () => {
        let ready = true;
        if (this.state.name.length === 0 && this.state.phone.length === 0 && this.state.password.length === 0 && this.state.repassword.length === 0) {
            this.setState({
                nameErr: true,
                phoneErr: true,
                // permissionErr: true,
                passwordErr: true,
                rePasswordErr: true,
            });
            ready = false;
        } else if (this.state.name.length === 0) {
            this.setState({nameErr: true});
            ready = false;
        } else if (this.state.phone.length < 10 || this.state.phone.length === 0) {
            this.setState({phoneErr: true});
            ready = false;
        // } else if (this.state.permission.length == 0) {
        //     this.setState({permissionErr: true});
        //     ready = false;
        } else if (this.state.password.length < 6) {
            this.setState({
                passwordErr: true,
            })
            ready = false;
        } else if (this.state.password !== this.state.repassword) {
            this.setState({
                rePasswordErr: true,
            });
            ready = false;
        }
        if (ready) {
            this.addCallCenter()
        }
    }

    addCallCenter = () => {
        let payload = {
            // permission: this.state.permission,
            fullName: this.state.name,
            password: this.state.password,
            phone: this.state.phone
        }
        CallCenterApi.create(payload, res => {
            if (res.success) {
                this.props.onHide();
                this.props.onRefresh();
                return toast.success('Tạo mới Call Center thành công !');
            } else {
                return toast.error('Tạo mới không thành công, vui lòng thử lại !');
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÊM MỚI CALL CENTER</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2">Họ và tên <span className={'color-red d-inline'}>*</span></label>
                                <input
                                    className={this.state.nameErr ? "inputPassword w-100 col-6 border-input-error" : "inputPassword w-100 col-6"}
                                    placeholder="Nhập vào họ tên"
                                    maxLength={50}
                                    value={this.state.name}
                                    onChange={(e) => this.setState({
                                            name: e.target.value.trimLeft()
                                        },
                                        () => {
                                            this.state.name.length === 0 ? this.setState({
                                                nameErr: true,
                                                btnStatus: true
                                            }) : this.setState({nameErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.nameErr ? <div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Họ tên đang để trống !</label>
                            </div> : <div/>}

                            <div className={this.state.phoneErr ? "row mt-2" : "row mt-3"}>
                                <label className="col-6 mt-2">Số điện thoại <span className={'color-red d-inline'}>*</span></label>
                                <input
                                    className={this.state.phoneErr ? "inputPassword w-100 col-6 border-input-error" : "inputPassword w-100 col-6 "}
                                    placeholder="Nhập vào SĐT"
                                    maxLength={11}
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                        () => {
                                            this.state.phone.length < 10 ? this.setState({
                                                phoneErr: true,
                                                btnStatus: true
                                            }) : this.setState({phoneErr: false, btnStatus: false})
                                        }
                                    )}
                                />
                            </div>
                            {this.state.phoneErr ? <div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">SĐT không đúng hoặc đang để trống !</label>
                            </div> : <div/>}

                            {/*<div className={this.state.permissionErr ? "row mt-2" : "row mt-3"}>*/}
                            {/*    <label className="col-6 mt-2">Nhóm quyền <span className={'color-red d-inline'}>*</span></label>*/}
                            {/*    <select*/}
                            {/*        className={this.state.permissionErr ? "inputPassword w-100 col-6 border-input-error " : "inputPassword w-100 col-6 "}*/}
                            {/*        placeholder='Chọn nhóm quyền'*/}
                            {/*        defaultValue={1}*/}
                            {/*        value={this.state.permission}*/}
                            {/*        disabled*/}
                            {/*    >*/}
                            {/*        <option value={1}>1</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/*{this.state.permissionErr ? <div className="row">*/}
                            {/*    <label className="col-6 "></label>*/}
                            {/*    <label className="col-6 font-err">Vui lòng chọn nhóm quyền !</label>*/}
                            {/*</div> : <div/>}*/}

                            <div className={this.state.contarctErr ? "row mt-2" : "row mt-3"}>
                                <label className="col-6 mt-2">Mật khẩu <span className={'color-red d-inline'}>*</span></label>
                                <input
                                    className={this.state.passwordErr ? "inputPassword w-100 col-6 border-input-error" : "inputPassword w-100 col-6"}
                                    placeholder='Mật khẩu'
                                    type='password'
                                    security={true}
                                    autoComplete={"false"}
                                    maxLength={20}
                                    minLength={6}
                                    value={this.state.password}
                                    onChange={(e) => this.setState({password: e.target.value.trim()},
                                        () => {
                                            this.state.password.length === 0
                                                ? this.setState({
                                                    passwordErr: true,
                                                    btnStatus: true
                                                }) : this.setState({passwordErr: false, btnStatus: false})
                                        })}
                                />
                            </div>
                            {this.state.passwordErr ? <div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Mật khẩu đang để trống !</label>
                            </div> : ''}

                            <div className={this.state.contarctErr ? "row mt-2" : "row mt-3"}>
                                <label className="col-6 mt-2">Xác nhận mật khẩu <span className={'color-red d-inline'}>*</span></label>
                                <input
                                    className={this.state.passwordErr ? "inputPassword w-100 col-6 border-input-error" : "inputPassword w-100 col-6"}
                                    placeholder='Xác nhận mật khẩu'
                                    maxLength={20}
                                    minLength={6}
                                    type='password'
                                    value={this.state.repassword}
                                    onChange={(e) => this.setState({repassword: e.target.value.trim()},
                                        () => {
                                            this.state.password.length === 0
                                                ? this.setState({
                                                    rePasswordErr: true,
                                                    btnStatus: true
                                                }) : this.setState({rePasswordErr: false, btnStatus: false})
                                        })}
                                />
                            </div>
                            {this.state.rePasswordErr ? <div className="row">
                                <label className="col-6 "></label>
                                <label className="col-6 font-err">Mật khẩu không đúng không đúng hoặc đang để trống
                                    !</label>
                            </div> : ''}
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-center mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-75 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                         Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalCreateCallCenter;
