import React from "react";

class Autosuggestion extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            showSuggest: false,
        }
    }

    componentDidMount() {
        let boxSearch = document.querySelector(".suggest-wrapper");
        let self = this
        document.addEventListener("click", function (event) {
            if (event.target.closest(".suggest-wrapper") || event.target.closest(".suggest-box-input")) return;
            // box.classList.add("");
            self.setState({showSuggest: false})
        });
    }

    // a = () => {
    //     let boxSearch = document.querySelector(".suggest-wrapper");
    //     document.addEventListener("click", function (event) {
    //         if (event.target.closest(".suggest-wrapper") || event.target.closest(".suggest-box-input")) return;
    //         // box.classList.add("");
    //         self.setState({showSearchResult: false})
    //     });
    // }

    render() {
        let {label, placeholder, checkError, maxLength, errorContent, disabled} = this.props
        let {data} = this.props;
        let {selectedItem, value, showSuggest} = this.state;
        let type = this.props.type || "text";
        return (
                <div className="">

                    <input type={type}
                           className={checkError ? "form-control border-input-error shadow-none font-14 suggest-box-input" : "form-control shadow-none font-14 suggest-box-input"}
                           placeholder={placeholder}
                           value={this.props.clearSearch ? "" : value}
                           disabled={disabled}
                        // aria-label="Username"
                           maxLength={maxLength}
                        // aria-describedby="basic-addon1"
                           onChange={(event) => {
                               let input = event.target.value;
                               if (selectedItem) {
                                   this.setState({selectedItem: null})
                               }
                               this.setState({value: event.target.value, showSuggest: true}, ()=> {
                                   return this.props.onChange(input)
                               })
                               // if (input.length == 0) {
                               //     return this.props.onChange(val.target.value)
                               // }

                           }}
                    />
                    {showSuggest &&
                    <div className="suggest-wrapper">
                        <ul className="suggest-box">
                            {data && data.length >  0 ? data.map(_item => {
                                return (
                                    <li className="suggest-item" onClick={()=>{
                                        this.setState({selectedItem: _item, value: _item.fullName || _item.nameRole || _item.companyName, showSuggest: false}, ()=> {
                                                this.props.onSelect(_item)
                                            })
                                    }}>{_item.fullName || _item.name || _item.nameRole || _item.title || _item.companyName}</li>
                                    )
                            }):
                                <li className="align-center pt-2 pb-2">Không có kết quả</li>
                            }
                        </ul>
                    </div>
                    }
                    {checkError?<span className="pl-1 color-red size-font-err-date">{errorContent}</span>:<div/>}
                </div>

        );
    }
}

export default Autosuggestion
