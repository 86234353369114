import React from 'react';
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../services/ob-user/supervisor";
import {toast} from "react-toastify";
import SecureNewsCategory from "../../services/ob-core/secure-news-category";

class ModalDeleteCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showBlock: true,
            signupInfo: null
        };
    }

    componentDidMount() {
        // this.setState({reason: ''});
        // console.log("checkk>>", this.props.item)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            // this.setState({reason: ''});
        }
    }


    onDeleteCategory = () => {
        let payload = {
            id: this.props.item
        }
        SecureNewsCategory.delete(payload, res => {
            if (res.success) {
                toast.success("Xóa danh mục thành công!")
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra !")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div>
                            {/*<label>Vui lòng nhập lý do xoá danh mục bài viết:</label>*/}
                            {/*<textarea*/}
                            {/*    rows={5}*/}
                            {/*    className="form-control shadow-none"*/}
                            {/*    style={{resize: 'none'}}*/}
                            {/*    placeholder={'Nhập vào lý do xoá danh mục bài viết  '}*/}
                            {/*    maxLength={250}*/}
                            {/*    value={this.state.reason}*/}
                            {/*    onChange={(event) => {*/}
                            {/*        this.setState({reason: event.target.value})*/}
                            {/*    }}*/}
                            {/*/>*/}
                            Bạn có chắc chắc xóa danh mục này không
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        // disabled={this.state.reason?.length == 0}
                                        onClick={() => this.onDeleteCategory()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Có
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Không
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalDeleteCategory;

