/**
 * Created by WebStorm.
 * User: LongP
 * Date: 23/03/2020
 * Time: 14:20
 **/
import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Utils from '../../utils/utils'
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import Autosuggestion from "../Form/Autosuggestion";

import PublicOrderApi from "../../services/ob-core/public-order-service";
import SupervisorApi from "../../services/ob-user/supervisor";

class ModalAssignOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supId: '',
            name: '',
            listSup: []
        };
    }

    componentWillMount() {
        this.getListSup()
    }

    componentDidMount() {
        this.setState({
            supId: '',
            // listSup: [],
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {

        }
    }

    getListSup = () => {
        let payload = {
            fullName: this.state.name,
        }
        SupervisorApi.getListSupervisorSelectOrder(payload, res => {
            if (res.success) {
                let listSup = res.data.content.filter(item => item.id !== this.props.supIdFilter);
                this.setState({listSup})
                // this.props.onHide();
                // this.props.onRefresh();
            } else {
                return this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    };

    onSubmit = () => {
        if (this.state.supId == '') {
            this.props.enqueueSnackbar('Vui lòng chọn người phụ trách chuyên môn!', {
                variant: 'error',
            });
        } else {
            this.onAssignOrder()
        }
    }

    onAssignOrder = () => {
        let payload = {
            id: this.props.id,
            supervisorId: this.state.supId
        }
        PublicOrderApi.assignOrder(payload, res => {
            if (res.errorCode == 'One-Book-User.E000005') {
                this.props.enqueueSnackbar(res.message[0], {
                    variant: 'error',
                });
            } else if (res.errorCode == 'One-Book-Core.E000022') {
                this.props.enqueueSnackbar(res.message[0], {
                    variant: 'error',
                });
            } else if (res.success) {
                this.props.enqueueSnackbar('Assign thành công !', {
                    variant: 'success',
                });
                this.props.onHide();
                // this.props.onRefresh();
            } else {
                return this.props.enqueueSnackbar('Có lỗi xảy ra !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-assign-order">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 18}}><b>ASSIGN ORDER</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2 pl-0">Người thực hiện</label>
                            </div>
                            <Autosuggestion
                                label="Người phụ trách"
                                // className={this.state.supId?"form-control border-input-error col-6 mt-2 kt-input pd-4-input hei-input":"form-control col-6 mt-2 kt-input pd-4-input hei-input"}
                                className='ml-2'
                                placeholder="Nhập tên người phụ trách"
                                // value={this.state.contractCode}
                                type="text"
                                data={this.state.listSup}
                                onSelect={(item) => this.setState({supId: item.id})}
                                onChange={(val) => {
                                    this.setState({name: val, selectedSupervisor: null})
                                    setTimeout(() => {
                                        this.getListSup()
                                    }, 300);
                                }}
                                // checkError={!this.state.supervisorList}
                                // errorContent="Vui lòng chọn một Supervisor"
                            />
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-center mt-4 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalAssignOrder);
