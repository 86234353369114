import React from 'react';
import {withSnackbar} from "notistack";
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";

import OtpApi from "../../services/ob-user/otp";
import PublicCustomerApi from "../../services/ob-user/public-customer";
import {toast} from "react-toastify";
import Authentication from "../../services/auth";
import Utils from "../../utils/utils";
import PropTypes from 'prop-types';
class modalOtp extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            otpCode: '',
            showForgotPassword2: false,
            phone: '',
            signupInfo: null
        };
    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.signupInfo != this.props.signupInfo) {
            this.setState({signupInfo: nextProps.signupInfo})
        }
    }

    getOtp = () => {
        let payload = {
            phone: this.state.phone
        }
        OtpApi.sendOtp(payload, res => {
            if (res.success) this.setState({transId: res.data })
        })
    }

    submitVerifyOTP = () => {
        let payload = {
            otpCode: this.state.otpCode,
            transactionId: this.state.signupInfo.transId
        }
        OtpApi.verifyOtp(payload, res => {
            if (res.success) this.registerCustomer();
            else {
                return this.props.enqueueSnackbar('Xác thực OTP không thành công!', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }

    registerCustomer = () => {
        let data = this.state.signupInfo;
        let self = this
        let payload = {
            ...data.info
        }
        PublicCustomerApi.create(payload, res => {
            if (res.success) {
                self.props.onHide();
                this.autoLogin(payload.tax, payload.password);
                // toast.success('Đăng ký tài khoản thành công! Vui lòng đăng nhập để sử dụng dịch vụ');
            } else {
                toast.error('Đăng ký tài khoản không thành công! ' + res.message)
            }
        })
    }
    autoLogin(username, password) {
        let payload = {
            name: username,
            password: password
        }
        Authentication.login(payload, res => {
            if (!res.success) {
                toast.error("Đăng nhập không thành công")
            } else {
                // let day = this.state.remember ? 7 * 3600 * 24 : 3600 * 24;
                let day = this.state.remember ? 7 : 1;
                Cookies.set('ob_cl_token', res.data, {expires: day});
                let data = Utils.parseJwt(res.data);
                localStorage.setItem("ob_cl_userType", data.userType);
                // this.getPermission()
                // this.getUserDetail();
                setTimeout(() => {
                    this.props.onHide();
                    window.location.reload()
                }, 1000)
                // window.location.reload()
            }
        }).catch((error) => {
            toast.error("Đăng nhập không thành công")
        })
    }
    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        {/*<div className="modal-container">*/}

                        {/*</div>*/}
                        <div>
                            <h1 className="text-center" style={{fontSize:14,}}>Vui lòng nhập mã OTP đã được gửi về SĐT {this.props?.signupInfo?.info?.phone}</h1>
                        </div>
                        <Grid container spacing={1} item xs={12} >
                            <TextField
                                className="mr-1 ml-1"
                                id="outlined-margin-dense"
                                placeholder='Nhập mã OTP'
                                margin="dense"
                                fullWidth
                                rows='1'
                                rowsMax="1"
                                value={this.state.otpCode}
                                size="small"
                                inputProps={{ maxlength: 255 }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {this.setState({otpCode: e.target.value})}}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} >
                            <Grid item xs={12}>
                                <div className="align-center mt-2 mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-original btn-1st w-100 type-button-fix"
                                        disabled={this.state.otpCode?.length != 6}
                                        onClick={this.submitVerifyOTP}
                                    >
                                          <span>
                                            Xác thực
                                            <i className="flaticon-right"/>
                                          </span>
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(modalOtp);
