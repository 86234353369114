import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import PublicPayment from "../../services/ob-core/public-payment";
import {toast} from "react-toastify";

class ModalUpdatePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            show: true,
            needPayment: this.props.needPayment,
            selectPayment: this.props.selectPayment,
            // id: this.props.id,
        };
    }

    componentDidMount() {
        console.log(this.props)
    }

    renderMultiTime(start, end) {
        let a = ` thứ ${start}`;
        // console.log('start+end' ,start, 'end' , end)
        // debugger
        for (let i = 1; i <= end - start; i++) {
            a = a + `, thứ ${start + i}`
        }
        // console.log('ancncncn: ', a);
        return a;
    }

    onSubmit() {
        let {paymentDetail, contractId, selectPayment, listTimeLine} = this.props;
        console.log("check props>>>>>>",this.props)
        let body = [];
        // paymentDetail.map(item => {
        //     body.push({
        //         id: item.id,
        //         paymentAmount: item.paymentAmount,
        //         paymentTerm: item.paymentTerm,
        //     })
        // })
        let list = listTimeLine.slice(0, selectPayment + 1)
        console.log(list)
        // if (selectPayment > 0){
        list.map(item => {
            body.push({
                id: item.id,
                paymentAmount: item.paymentAmount,
                paymentTerm: item.paymentTerm,
                paymentStatus: "PAID",
            })
        })
        // }
        let payload = {
            contractId: contractId,
            body: body
            // body: [{
            //     id: paymentDetail.id,
            //     paymentAmount: paymentDetail.paymentAmount,
            //     paymentTerm: paymentDetail.paymentTerm,
            //     paymentStatus: "PAID",
            // }]
        }
        PublicPayment.updatePaymentCycle(payload, res => {
            if (res.success){
                toast.success("Cập nhật thanh toán thành công!")
                this.props.onRefresh()
            }else{
                toast.error("Đã xảy ra lỗi! " + res.message)
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='m-1 text-center'>
                            {this.state.selectPayment - this.state.needPayment === 0
                                ? <label>Bạn có xác nhận đã hoàn thành lần thanh toán thứ {this.state.needPayment} này
                                    không ?</label>
                                : <div>
                                    <label>
                                        Bạn có xác nhận đã hoàn thành lần thanh
                                        toán {this.renderMultiTime(this.state.needPayment, this.state.selectPayment)} này
                                        không ?
                                    </label>
                                    <label className='font-italic' style={{fontSize: 12}}>
                                        (Lưu ý: Bạn không thể thay đổi trạng thái thanh toán sau khi đã nhấn đồng ý)
                                    </label>
                                </div>
                            }

                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={() => this.onSubmit()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalUpdatePayment);

