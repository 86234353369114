import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {decrement, increment, reset, cleanNotification} from "../../store/actions";
import {connect} from "react-redux";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const variantColor = {
    success: '#479F4B',
    warning: '#FE9F29',
    error: '#D13035',
    info: '#97C8FA',
};

class Notification extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        let Icon = variantIcon[this.props.status];
        let Color = variantColor[this.props.status];
        return(
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.props.show}
                    aria-describedby="client-snackbar"
                    autoHideDuration={this.props.time}
                    onClose={(event, reason) => {
                        if(reason != "clickaway"){
                            this.props.cleanNotification()
                        }
                    }}
                >
                    <SnackbarContent
                        style={{backgroundColor: Color}}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" style={{ display: 'flex',
                                alignItems: 'center',}}>
                                <Icon style={{ fontSize: 20,opacity: 0.9,
                                    marginRight: 20}} />
                                {this.props.message}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.props.cleanNotification()}}>
                                <CloseIcon style={{ fontSize: 20}} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        cleanNotification: () => dispatch(cleanNotification())
    };
};

const mapStateToProps = (state) => {
    return {
        show: state.ShowNotification,
        time: state.TimeNotification,
        status: state.Notification?.status,
        message: state.Notification?.message
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
