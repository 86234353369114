import React from 'react'
import { Link, Switch, Route, Redirect } from "react-router-dom";
import Profile from "./profile";
import CompleteProfile from "./complete-profile";
import AccountantManagement from "./accountant-management";
import AccountantDetail from "./accountant-management/accountant-detail";
import ContractsManagement from "./contracts-management";
import ReportManagement from "./report-management";
import Management from "./payment";
import ContractsDetail from "./contracts-detail";
import JobsManagement from "./jobs-management";
import RevenueManagement from "./revenue-management";
import Rating from './rating';
import history from "../../history";
import OrdersManagement from "./orders-management";
import AccountingBalanceSheet from "../accountant/accounting-balance-sheet";
import ReportBusinessResults from "../accountant/report-business-results";
import DirectCashFlowStatement from "../accountant/direct-cash-flow-statement";
import IndirectCashFlowStatement from "../accountant/indirect-cash-flow-statement";
import ReportSummary from "../accountant/report-summary";
class Supervisor extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isSupervisor: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isSupervisor: userType == "SUPERVISOR"})
    }

    render() {
        return(
            <Switch>
                {!this.state.isSupervisor && <Redirect to={"/"}/>}
                <Route path="/supervisor/profile" component={Profile}/>
                <Route path="/supervisor/complete-profile" component={CompleteProfile}/>
                <Route path="/supervisor/accountant-management" component={AccountantManagement}/>
                <Route path="/supervisor/accountant-detail" component={AccountantDetail}/>
                <Route path="/supervisor/contracts-management" component={ContractsManagement}/>
                <Route path="/supervisor/jobs-management" component={JobsManagement}/>
                <Route path="/supervisor/orders-management" component={OrdersManagement}/>
                <Route path="/supervisor/report-management" component={ReportManagement}/>
                <Route path="/supervisor/contracts-detail" component={ContractsDetail}/>
                <Route path="/supervisor/payment" component={Management}/>
                <Route path="/supervisor/revenue-management" component={RevenueManagement}/>
                <Route path="/supervisor/rating" component={Rating}/>
                {/*<Route path="/supervisor/balance" component={AccountingBalanceSheet}/>*/}
                {/*<Route path="/supervisor/business-results" component={ReportBusinessResults}/>*/}
                {/*<Route path="/supervisor/direct-cash" component={DirectCashFlowStatement}/>*/}
                {/*<Route path="/supervisor/indirect-cash" component={IndirectCashFlowStatement}/>*/}
                {/*<Route path="/supervisor/report-summary" component={ReportSummary}/>*/}
            </Switch>
        )
    }
}

export default Supervisor
