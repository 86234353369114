import React from 'react';
import {
    Modal,
} from 'react-bootstrap';
import "../Modal.css"
import Cookies from "js-cookie";
import {withSnackbar} from "notistack";
import FormInput from "../../Form/input";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";

class ModalAddSubContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            jobName: null,
            numberOfReport: null,
            nameError: false,
            jobNameError: false,
            startDate: null,
            startDateErr: false,
            endDate: null,
            endDateErr: false,
        };
    }

    componentDidMount() {

    }


    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        const {name, numberOfReport} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">
                            <div>
                                <h1 className="text-center" style={{fontSize: 20,}}><b>THÊM MỚI HỢP ĐỒNG PHỤ</b></h1>
                            </div>

                            <div className='col-12 row'>
                                <div className="col-12">
                                    <span className="text-black-50">Tên hợp đồng phụ<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-12">
                                    <input
                                        maxLength={500} type="text" className="form-control shadow-none"
                                        value={name}
                                        onChange={(val) => {
                                            this.setState({
                                                name: val.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 row'>
                                <div className="col-12">
                                    <span className="text-black-50">Mô tả<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        rows={8}
                                        className="form-control shadow-none"
                                        style={{resize: 'none'}}
                                        placeholder={'Nhập vào lý do xoá tài khoản'}
                                        maxLength={500}
                                        value={this.state.content}
                                        onChange={(event) => {
                                            this.setState({content: event.target.value})
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 row mt-2'>
                                <div className="col-5">
                                    <span className="text-black-50">Số tiền<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <NumberFormat
                                        value={this.state.receiveMoney}
                                        // displayType={"text"}
                                        thousandSeparator={true}
                                        className="form-control shadow-none"
                                        // suffix={"VND"}
                                        onChange={(val) => {
                                            this.setState({
                                                receiveMoney: val.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-12 row'>
                                <div className="col-5 mt-2">
                                    <span className="text-black-50">Ngày bắt đầu<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <DatePicker
                                        className={this.state.startDateErr?"w-auto form-control border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input":"w-auto form-control react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.startDate === null ? null :new Date(this.state.startDate)}
                                        value={moment(this.state.startDate, 'YYYY-MM-DD')}
                                        minDate={new Date()}
                                        isClearable
                                        dateFormat="dd-MM-yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    startDate: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                    startDateErr:false
                                                })
                                            }
                                        }
                                    />
                                    {this.state.startDateErr?
                                        <div className="row">
                                            <label className="col-12 font-err mb-0">Vui lòng chon ngày !</label>
                                        </div>:<div/>}
                                </div>
                            </div>
                            <div className='col-12 row'>
                                <div className="col-5 mt-2">
                                    <span className="text-black-50">Ngày kết thúc<span style={{color: 'red'}}>*</span></span>
                                </div>
                                <div className="col-7">
                                    <DatePicker
                                        className={this.state.endDateErr?"w-auto form-control border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input":"w-auto form-control react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                        onChangeRaw={this.handleDateChangeRaw}
                                        selected={this.state.endDate === null ? null :new Date(this.state.endDate)}
                                        value={moment(this.state.endDate, 'YYYY-MM-DD')}
                                        minDate={new Date()}
                                        isClearable
                                        dateFormat="dd-MM-yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={
                                            (date) => {
                                                this.setState({
                                                    endDate: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                    endDateErr:false
                                                })
                                            }
                                        }
                                    />
                                    {this.state.endDateErr?
                                        <div className="row">
                                            <label className="col-12 font-err mb-0">Vui lòng chon ngày !</label>
                                        </div>:<div/>}
                                </div>
                            </div>
                            <div className="align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    disabled={this.state.startDate == null || this.state.endDate == null || this.state.name == null || this.state.content == null || this.state.receiveMoney == null || this.state.name == "" || this.state.content == "" || this.state.receiveMoney == "" }
                                    onClick={() => {
                                        this.props.addSubContract(this.state.name, this.state.content, this.state.receiveMoney, this.state.startDate, this.state.endDate);
                                        this.props.onHide();
                                    }}
                                >
                                                <span className='d-flex justify-content-center'>
                                                    Đồng ý
                                                </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                    onClick={this.props.onHide}
                                >
                                                <span className='d-flex justify-content-center'>
                                                    Hủy
                                                </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default withSnackbar(ModalAddSubContact);

