import React from 'react'
import {toast} from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import NumberFormat from "react-number-format";

import PublicContract from "../../../services/ob-core/public-contract";
import SupervisorApi from "../../../services/ob-user/supervisor";
import AccountantApi from "../../../services/ob-user/accountant";

class RevenueManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            listData: [],
            sort: '',
            supervisorName: '',
            supervisorId: '',
            selectedSupervisor: null,
            supervisorSuggestions: [],
            supervisor_temp: '',
            supervisorList: [],
            accountantList: [],
            accountantName: '',
            accountantId: '',
            selectedAccountant: null,
            startDate: '',
            endDate: '',
            startDate_temp: null,
            endDate_temp: null,
            showLoading: false,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'contractCode',
                    name: "Mã hợp đồng",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'totalRevenue',
                    name: "Tổng giá trị hợp đồng (VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'revenue',
                    name: "Doanh thu đã thực hiện (VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'revenue',
                    name: "Doanh thu chưa thực hiện (VND)",
                    style: {textAlign: "center"},
                    sort: false,
                },
                // {
                //     id: 'date',
                //     name: "Thời gian",
                //     style: {textAlign: "center"},
                //     sort: false,
                // },
            ],
        }
    }

    componentWillMount() {
        this.setState({showLoading: true}, () => {
            this.getData()
        })
    }

    getData = () => {
        let {pageNumber, pageSize, sort, startDate, endDate, supervisorId, accountantId} = this.state
        if(supervisorId) {
            let payload = {
                pageNumber: pageNumber,
                pageSize: pageSize,
                // sort: sort,
                startDate: startDate,
                endDate: endDate,
                userId: supervisorId
            }
            PublicContract.getRevenueSupervisor(payload, res => {
                if (res.success) {
                    this.setState({listData: res.data.content, totalItem: res.data.totalElements, showLoading: false})
                } else {
                    toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
                }
            })
        }
        else if(accountantId) {
            let payload = {
                pageNumber: pageNumber,
                pageSize: pageSize,
                // sort: sort,
                startDate: startDate,
                endDate: endDate,
                userId: accountantId
            }
            PublicContract.getRevenueAccountant(payload, res => {
                if (res.success) {
                    this.setState({listData: res.data.content, totalItem: res.data.totalElements, showLoading: false})
                } else {
                    toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
                }
            })
        } else {
            let payload = {
                pageNumber: pageNumber,
                pageSize: pageSize,
                // sort: sort,
                startDate: startDate,
                endDate: endDate,
            }
            PublicContract.getRevenuePartner(payload, res => {
                if (res.success) {
                    this.setState({listData: res.data.content, totalItem: res.data.totalElements, showLoading: false})
                } else {
                    toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
                }
            })
        }
    }

    getListSupervisor = () => {
        let payload = {
            fullName: this.state.supervisorName,
            pageNumber: 0,
            pageSize: 10,
        }
        SupervisorApi.searchByFullNameAndStatus(payload, res => {
            if (res.success) {
                this.setState({supervisorList: res.data.content})
            }
        })
    }

    getListAccountant = () => {
        let payload = {
            fullName: this.state.accountantName,
            pageNumber: 0,
            pageSize: 10,
        }
        AccountantApi.findBySupervisorIdAndPartnerId(payload, res => {
            if (res.success) {
                this.setState({accountantList: res.data.content})
            }
        })
    }

    onSearch = () => {
        let {startDate_temp, endDate_temp, selectedSupervisor, selectedAccountant} = this.state;
        this.setState({
            startDate: startDate_temp,
            endDate: endDate_temp,
            supervisorId: selectedSupervisor ? selectedSupervisor.id : '',
            accountantId: selectedAccountant ? selectedAccountant.id : '',

        }, () => this.getData())
    }

    onRefresh() {
        this.setState(
            {
                startDate_temp: null,
                endDate_temp: null,
                selectedSupervisor: "",
                supervisorName: '',
                supervisorList: [],
                supervisorId: null,
                accountantName: '',
                accountantId: null,
                selectedAccountant: null,
            },
            () => this.onSearch()
        );
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getData()
            }
        );
    }

    render() {
        let {pageNumber, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="partner-revenue-management" parentClass="revenue-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý doanh thu"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Từ ngày</label>
                                                <DatePicker
                                                    className="w-auto2 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Từ ngày"
                                                    selected={this.state.startDate_temp === null ? null : new Date(this.state.startDate_temp)}
                                                    maxDate={new Date()}
                                                    minDate={new Date('01/01/2000')}
                                                    dateFormat="dd-MM-yyyy"
                                                    isClearable={this.state.startDate_temp !== null}
                                                    // selectsStart
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => {
                                                        this.setState({
                                                            startDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                                        })
                                                    }}
                                                    startDate={new Date(this.state.startDate_temp)}
                                                    endDate={new Date(this.state.endDate_temp)}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Đến ngày</label>
                                                <DatePicker
                                                    className="w-auto3 react-datepicker-wrapper2 react-datepicker__input-container form-control shadow-none"
                                                    placeholderText="Đến ngày"
                                                    selected={this.state.endDate_temp === null ? "" : new Date(this.state.endDate_temp)}
                                                    selectsEnd
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    // disabled={this.state.startDate_temp === null || this.state.startDate_temp === moment(new Date()).format("YYYY-MM-DD")}
                                                    disabled={this.state.startDate_temp === null}
                                                    isClearable={this.state.endDate_temp !== null}
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => {
                                                        this.setState({endDate_temp: date === null ? null : moment(date).format("YYYY-MM-DD")})
                                                    }}
                                                    startDate={new Date(this.state.startDate_temp)}
                                                    endDate={new Date(this.state.endDate_temp)}
                                                    minDate={this.state.startDate_temp === null ? new Date(this.state.startDate_temp) : new Date('2000/01/01')}
                                                />
                                            </div>
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div className="col-6 pl-0">*/}
                                        {/*        <label className="label-item">Tên Supervisor</label>*/}
                                        {/*        <Autosuggestion*/}
                                        {/*            label="Supervisor"*/}
                                        {/*            placeholder="Nhập tên Supervisor"*/}
                                        {/*            type="text"*/}
                                        {/*            disabled={this.state.accountantName || this.state.accountantId}*/}
                                        {/*            data={this.state.supervisorList}*/}
                                        {/*            value={this.state.selectedSupervisor}*/}
                                        {/*            onSelect={(item) => this.setState({selectedSupervisor: item})}*/}
                                        {/*            onChange={(val) => {*/}
                                        {/*                this.setState({supervisorName: val, selectedSupervisor: null})*/}
                                        {/*                setTimeout(() => {*/}
                                        {/*                    this.getListSupervisor()*/}
                                        {/*                }, 300);*/}
                                        {/*            }}*/}
                                        {/*            checkError={!this.state.supervisorList}*/}
                                        {/*            errorContent="Vui lòng chọn một Supervisor"*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-6 pr-0">*/}
                                        {/*        <label className="label-item">Tên kế toán</label>*/}
                                        {/*        <Autosuggestion*/}
                                        {/*            label="Supervisor"*/}
                                        {/*            placeholder="Nhập tên kế toán"*/}
                                        {/*            type="text"*/}
                                        {/*            disabled={this.state.supervisorName || this.state.supervisorId}*/}
                                        {/*            data={this.state.accountantList}*/}
                                        {/*            onSelect={(item) => this.setState({selectedAccountant: item})}*/}
                                        {/*            onChange={(val) => {*/}
                                        {/*                this.setState({accountantName: val, selectedAccountant: null})*/}
                                        {/*                setTimeout(() => {*/}
                                        {/*                    this.getListAccountant()*/}
                                        {/*                }, 300);*/}
                                        {/*            }}*/}
                                        {/*            checkError={!this.state.accountantList}*/}
                                        {/*            errorContent="Vui lòng chọn một kế toán"*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.onSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.onSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length === 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.contractCode}
                                                    </td>
                                                    <td style={{textAlign: 'right',}}>
                                                        {<NumberFormat
                                                            value={row.priceTotal}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        /> || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'right',}}>
                                                        {<NumberFormat
                                                            value={row.priceDone}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        /> || 'Chưa có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'right'}}>
                                                        <NumberFormat
                                                            value={row.priceDoing}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                        />
                                                    </td>
                                                    {/*<td style={{textAlign: 'center'}}>*/}
                                                    {/*    {moment(row.date).format("DD-MM-YYYY HH:mm:ss")}*/}
                                                    {/*</td>*/}
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default RevenueManagement
