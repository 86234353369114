import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let SubUserApi = {
    createSubUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/sub-user/call-center-create-sub-user/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    createOfficialUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/sub-user/admin-create-official-user/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
};
export default SubUserApi
