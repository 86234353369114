import React, {Component} from "react";
import './index.css';
import {Link} from "react-router-dom";
import moment from "moment";

import Sidebar from "../../../components/Sidebar";
import NumberFormat from "react-number-format";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";

import BlockAccountant from "../../../components/Modal/modalBlockAccountant";
import UnblockAccountant from "../../../components/Modal/modalUnblockAccountant";
import DeleteAccountant from "../../../components/Modal/modalDeleteAccountant";
import AddAccountant from "../../../components/Modal/modalAddAccountant";

import AccountantApi from "../../../services/ob-user/accountant";

const renderStatus = function (status) {
    switch (status) {
        case 'WAIT_ACTIVE':
            return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
        case "ACTIVE":
            return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
        case "LOCK":
            return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
        case "CANCELED":
            return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
        case "WAIT_COMPLETE_INFO":
            return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
        case "WAIT_CONFIRM_EMAIL":
            return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
        default:
            return '';
    }
};

class AccountantManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlock: false,
            showUnblock: false,
            showDelete: false,
            showAdd: false,
            supervisorCurrent: {},
            itemSelected: {},
            data: [],
            listData: [],
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            sort: '',
            accountantName: '',
            status: '',

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên kế toán",
                    style: {minWidth: 160, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {minWidth: 80, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'countContractActive',
                    name: "Số HĐ đang thực hiện",
                    style: {minWidth: 50, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 80, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 100, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.getListAccountantOnSearch();
    }

    getListAccountantOnSearch() {
        let {
            pageNumber,
            pageSize,
            accountantName,
            status,
            sort
        } = this.state;
        let payload = {
            pageNumber,
            pageSize,
            accountantName: accountantName,
            status,
            sort
        };
        AccountantApi.searchByNameStatusAndSupervisorId(payload, res => {
            window.OBPubConfig.loading = false
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                })
                let listData = res.data.content?.filter(item => item.status == 'ACTIVE');
                this.setState({listData});
            }
        })
    }

    handleBlock = (item) => {
        this.setState({
            itemSelected: item.id,
            showBlock: true
        })
    }

    handleUnblock = (item) => {
        this.setState({
            itemSelected: item.id,
            showUnblock: true
        })
    }

    handleDelete = (item) => {
        this.setState({
            itemSelected: item.id,
            showDelete: true
        })
    }


    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListAccountantOnSearch();
            }
        );
    };

    render() {
        const {pageNumber, pageSize, totalItem, accountantName, status, sort} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="accountant-management"/>
                        <div className="col-md-10 tab-content">
                            <div className="card col-12">
                                <div className='pt-3 mb-2'>
                                    <PageHeader routerEnable={true} title="Danh sách kế toán"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row kt-margin-b-20">
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-3'>
                                                <label className="label-item">Tên kế toán</label>
                                                <input
                                                    maxLength={50} type="text" className="form-control shadow-none"
                                                    placeholder="Nhập vào tên kế toán"
                                                    value={accountantName}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.getListAccountantOnSearch()
                                                        }
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            accountantName: val.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 p-0 pr-md-0'>
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={status}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            status: val.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='ACTIVE'>Đang hoạt động</option>
                                                    <option value='LOCK'>Đã khóa</option>
                                                    <option value='CANCELED'>Đã xóa</option>
                                                    <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                    <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option>

                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-12 align-text-bottom p-0 mt-3'>
                                                {/*<div>*/}
                                                {/*    <label className='label-item mb-4'></label>*/}
                                                {/*</div>*/}
                                                <button
                                                    type="button"
                                                    style={{height: 35}}
                                                    className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.getListAccountantOnSearch()
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>

                                                <button
                                                    type="button"
                                                    style={{height: 35}}
                                                    className="border-1-s mr-3 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            status: '',
                                                            accountantName: '',
                                                            sort: ''
                                                        }, () => this.getListAccountantOnSearch())

                                                    }}
                                                >
                                                    Refresh
                                                </button>

                                                {/*&nbsp;&nbsp;*/}
                                                {this.state.listData.length === 10 ? <div/> :
                                                    <button
                                                        type="button"
                                                        style={{height: 35}}
                                                        className="border-1-s mr-3 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({showAdd: true})
                                                        }}
                                                    >
                                                        Thêm mới
                                                    </button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số kế toán: {this.state.totalItem}</div>
                                    <table
                                        className="table table-striped table-data table-bordered"
                                    >
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = 'id=asc'
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListAccountantOnSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.data || this.state.data.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.data.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {/*<span style={{cursor: 'pointer', color: '#1b90bd'}}>*/}
                                                        {/*<Link*/}
                                                        {/*    to={{*/}
                                                        {/*        pathname: "/supervisor/accountant-detail",*/}
                                                        {/*        search: `?accountantId=${row.id}`,*/}
                                                        {/*        state: {*/}
                                                        {/*            fromDashboard: true,*/}
                                                        {/*            accountantId: row.id*/}
                                                        {/*        }*/}
                                                        {/*    }}*/}
                                                        {/*    style={{color: "#1b90bd"}}*/}
                                                        {/*    // onClick={()=> window.OBPubConfig.loading = true}*/}
                                                        {/*>*/}
                                                        {row.fullName}
                                                        {/*</Link>*/}
                                                        {/*</span>*/}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>{row.phone}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.countContractActive}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center', fontSize: 18}}>
                                                        {
                                                            row.status == 'ACTIVE'
                                                                ? <span>
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/supervisor/accountant-detail",
                                                                            search: `?accountantId=${row.id}`,
                                                                            state: {
                                                                                fromDashboard: true,
                                                                                accountantId: row.id
                                                                            }
                                                                        }}
                                                                    >
                                                                        <button className="btn btn-action"
                                                                                title='Xem chi tiết'
                                                                                style={{cursor: 'pointer'}}
                                                                            // onClick={()=> window.OBPubConfig.loading = true}
                                                                        >
                                                                            <i className="ti-book"/>
                                                                        </button>
                                                                    </Link>
                                                                <button className="btn btn-action"
                                                                        title='Khóa'
                                                                        // disabled

                                                                        onClick={() => this.handleBlock(row)}
                                                                        style={{cursor: 'pointer'}}>
                                                                    <i className="ti-lock"/>
                                                                </button>
                                                                    {/*<button className="btn btn-action"*/}
                                                                    {/*        title='Chỉnh sửa thông tin'*/}
                                                                    {/*        style={{cursor: 'pointer'}}*/}
                                                                    {/*>*/}
                                                                    {/*    <i className="ti-pencil-alt cursor-pointer"/>*/}
                                                                    {/*</button>*/}
                                                                    <button className="btn btn-action"
                                                                            title='Xóa tài khoản'
                                                                            onClick={() => this.handleDelete(row)}
                                                                            style={{cursor: 'pointer'}}
                                                                    >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </span>
                                                                : row.status == 'LOCK' ?
                                                                <span>
                                                                    <button className="btn btn-action"
                                                                            title='Xem chi tiết'
                                                                            style={{cursor: 'pointer'}}
                                                                        // onClick={()=> window.OBPubConfig.loading = true}
                                                                    >
                                                                        <Link
                                                                            to={{
                                                                                pathname: "/supervisor/accountant-detail",
                                                                                search: `?accountantId=${row.id}`,
                                                                                state: {
                                                                                    fromDashboard: true,
                                                                                    accountantId: row.id
                                                                                }
                                                                            }}>
                                                                            <i className="ti-book"/>
                                                                        </Link>
                                                                    </button>
                                                                    <button className="btn btn-action  text-black-50"
                                                                            title='Mở khóa'
                                                                            onClick={() => this.handleUnblock(row)}
                                                                            style={{cursor: 'pointer'}}>
                                                                        <i className="ti-unlock"/>
                                                                    </button>
                                                                    {/*<button className="btn btn-action"*/}
                                                                    {/*        title='Chỉnh sửa thông tin'*/}
                                                                    {/*        style={{cursor: 'pointer'}}>*/}
                                                                    {/*    <i className="ti-pencil-alt cursor-pointer"/>*/}
                                                                    {/*</button>*/}
                                                                    <button className="btn btn-action  text-black-50"
                                                                            title='Xóa tài khoản'
                                                                            onClick={() => this.handleDelete(row)}
                                                                            style={{cursor: 'pointer'}}>
                                                                        <i className="ti-trash"/>
                                                                    </button>
                                                                </span>
                                                                : <span>
                                                                <button className="btn btn-action"
                                                                        title='Xem chi tiết'
                                                                        style={{cursor: 'pointer'}}
                                                                    // onClick={()=> window.OBPubConfig.loading = true}
                                                                >
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/supervisor/accountant-detail",
                                                                            search: `?accountantId=${row.id}`,
                                                                            state: {
                                                                                fromDashboard: true,
                                                                                accountantId: row.id
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="ti-book"/>
                                                                    </Link>
                                                                </button>
                                                                <button className="btn btn-action text-black-50"
                                                                        disabled
                                                                        title='Khóa'
                                                                        onClick={() => this.handleBlock(row)}
                                                                        style={{cursor: 'unset'}}>
                                                                    <i className="ti-lock"/>
                                                                </button>
                                                                    {/*<button className="btn btn-action"*/}
                                                                    {/*        title='Chỉnh sửa thông tin'*/}
                                                                    {/*        disabled*/}
                                                                    {/*        style={{cursor: 'unset'}}*/}
                                                                    {/*>*/}
                                                                    {/*    <i className="ti-pencil-alt"/>*/}
                                                                    {/*</button>*/}
                                                                    <button className="btn btn-action"
                                                                            // disabled
                                                                            title='Xóa tài khoản'
                                                                            onClick={() => this.handleDelete(row)}
                                                                            style={{cursor: 'unset'}}
                                                                    >
                                                                    <i className="ti-trash"/>
                                                                </button>
                                                            </span>
                                                        }
                                                    </td>
                                                    {/*<TableCell align="center">{row.action}</TableCell>*/}
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={page => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showBlock &&
                <BlockAccountant id={this.state.itemSelected}
                                 show={this.state.showBlock}
                                 onRefresh={() => this.getListAccountantOnSearch()}
                                 onHide={() => this.setState({showBlock: false})}/>}
                {this.state.showDelete &&
                <DeleteAccountant id={this.state.itemSelected}
                                  show={this.state.showDelete}
                                  onRefresh={() => this.getListAccountantOnSearch()}
                                  onHide={() => this.setState({showDelete: false})}/>}

                <UnblockAccountant id={this.state.itemSelected}
                                   show={this.state.showUnblock}
                                   onRefresh={() => this.getListAccountantOnSearch()}
                                   onHide={() => this.setState({showUnblock: false})}/>

                <AddAccountant show={this.state.showAdd}
                               onHide={() => this.setState({showAdd: false})}
                               onRefresh={() => this.getListAccountantOnSearch()}/>
            </div>
        );
    }

}

export default AccountantManagement
