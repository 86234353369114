import React from 'react'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {Link} from "react-router-dom";

import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import ModalInfoSignupMember from "../../../components/Modal/modalInfoSignupMember";
import ModalAdminCreateNewAccount from "../../../components/Modal/modalAdminCreateNewAccount";
import UserWaitingApi from "../../../services/ob-user/user-waiting";

toast.configure()
const dateFormat = 'DD-MM-YYYY';

class NewSignUpManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            itemSelected: {},
            position: '',
            status: "",
            listData: [],
            sort: '',
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'position',
                    name: "Vị trí",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'cmnCreatedAt',
                    name: "Thời gian gửi yêu cầu",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 140, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListNewSignUpOnSearch()
        })
    }

    getListNewSignUpOnSearch = () => {
        let self = this;
        let {pageNumber, pageSize, selectedPartner, status, position, sort} = this.state;
        let payload = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            status: status,
            job: position,
            // sort: sort
        };
        UserWaitingApi.callCenterSearch(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false
                })
                toast.error("Đã có lỗi xảy ra, vui long tải lại trang!")
            }
        })
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getListNewSignUpOnSearch()
            }
        );
    }

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_CONFIRM':
                return <h6><span className="badge badge-secondary">Chờ xử lý</span></h6>;
            case "CREATE_SUB_ACCOUNT":
                return <h6><span className="badge badge-warning">Tài khoản dự bị</span></h6>;
            case "CALL_CENTER_RECEIVE":
                return <h6><span className="badge badge-success">Call Center đã nhận</span></h6>;
            case "CREATE_OFFICIAL_ACCOUNT":
                return <h6><span className="badge badge-primary">Chờ tạo tài khoản chính thức</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            default:
                return;
        }
    };

    renderPosition = (status) => {
        switch (status) {
            case 'Accountant':
                return 'Kế toán';
            case "Supervisor":
                return 'Supervisor';
            default:
                return;
        }
    };

    render() {
        let {pageNumber, pageSize} = this.state
        let self = this;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="new-signup-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý đăng ký tài khoản"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Vị trí</label>
                                                <select
                                                    value={this.state.position}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            position: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='Supervisor'>Supervisor</option>
                                                    <option value='Accountant'>Kế toán</option>
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='WAIT_CONFIRM'>Chờ xử lý</option>
                                                    <option value='CREATE_SUB_ACCOUNT'>Tài khoản dự bị</option>
                                                    <option value='CALL_CENTER_RECEIVE'>Call Center đã nhận</option>
                                                    <option value='CREATE_OFFICIAL_ACCOUNT'>Chờ tạo tài khoản chính thức
                                                    </option>
                                                    <option value='CANCELED'>Đã hủy</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            pageNumber: 0
                                                        })
                                                        this.getListNewSignUpOnSearch()
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                status: '',
                                                                position: '',
                                                                sort: ''
                                                            }, () => {
                                                                this.getListNewSignUpOnSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListNewSignUpOnSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderPosition(row.job)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{row.cmnCreatedAt ? moment(row.cmnCreatedAt).format('') : 'Không có dữ liệu'}*/}
                                                        {moment(row.cmnCreatedAt).format('DD-MM-YYYY') || 'Không có dữ liệu'}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button
                                                            className="btn btn-action"
                                                            title="Xem thông tin đăng ký"
                                                            onClick={() => {
                                                                this.setState({itemSelected: row, showInfo: true})
                                                            }}
                                                        >
                                                            <i className="ti-book"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-action"
                                                            disabled={row.status !== 'CREATE_OFFICIAL_ACCOUNT'}
                                                            title="Tạo tài khoản"
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row,
                                                                    showCreate: true
                                                                })
                                                            }}
                                                        >
                                                            <i className="ti-plus"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showInfo && <ModalInfoSignupMember show={this.state.showInfo}
                                                               onHide={() => this.setState({showInfo: false})}
                                                               onRefresh={() => this.getListNewSignUpOnSearch()}
                                                               data={this.state.itemSelected}/>}
                {this.state.showCreate && <ModalAdminCreateNewAccount show={this.state.showCreate}
                                                                      onHide={() => this.setState({showCreate: false})}
                                                                      onRefresh={() => this.getListNewSignUpOnSearch()}
                                                                      data={this.state.itemSelected}/>}
            </div>
        )
    }

}

export default NewSignUpManagement
