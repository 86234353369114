import React, {Component} from "react";
import Sidebar from "../../../../components/Sidebar";
import PageHeader from "../../../../components/PageHeader";
import {toast} from "react-toastify";
import Convert from "../../../../utils/convertUrlPra";
import PublicNewsCategory from "../../../../services/ob-core/public-news-category";
import PublicNews from "../../../../services/ob-core/public-news";
import Dropzone from "react-dropzone";
import {changeAlias} from "../../../../utils/common";
import SecureNews from "../../../../services/ob-core/secure-news";
import FilesApi from "../../../../services/resource/files";
import PropTypes from "prop-types";
import Link from "../../../../components/Link";
import AutoSuggestionNews from "../../../../components/Form/AutoSuggestionNews";
import UploadFile from "../../../../components/Modal/modalUploadFile";
import DocViewer, { PDFRenderer} from "react-doc-viewer";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {URL_API} from "../../../../url.config";
class PostEditor extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            linkImage: "",
            linkFile: "",
            body: "",
            data: [],
            listCategory: [],
            newsType: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            showLoading: false,
            onEdit: false,
            files: [],
            filesImage: [],
            url: null,
            relativeNews: [],
            clearSearchNews: false,
            relativeNewsTitle: '',
            relativeNewsList: [],
            relativeNewsListId: [],
        }
    }

    componentDidMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({params, showLoading: true}, () => {
            if (params.type == "edit"){
                this.getDetail()
            }
        })
        this.getListCategory()
    }

    getListCategory = () => {
        PublicNewsCategory.getList(null, res => {
            this.setState({
                listCategory: res.data,
                showLoading: false
            })
        })
    }

    getDetail = () => {
        let payload = {
            id: this.state.params.postId
        }
        PublicNews.getDetail(payload, res => {
            if (res.success) {
                this.setState({description: res.data.description, title: res.data.title, categoryId: res.data.categoryId, linkFile: res.data.linkFile, newsType: res.data.newsType, createByName: res.data.createByName, data: res.data, showLoading: false, body: res.data.body, relativeNewsListId: JSON.parse(res.data.tagId), relativeNewsList: res.data.tagList})
            } else{
                toast.error("Lỗi! Vui lòng thử lại! " + res.message)
            }
        })
    };
    onUpdate = () => {
        let {data, body, categoryId, description, image, title, url, createByName, newsType, linkFile} = this.state
        let tagId = `[${this.state.relativeNewsListId.toString()}]`;
        if (title === undefined || title === '' || title === null){
            toast.error("Vui lòng nhập tiêu đề");
        }else if(categoryId === undefined || categoryId < 1){
            toast.error("Vui lòng chọn danh mục");
        }else if (description === undefined || description === '' || description == null){
            toast.error("Vui lòng nhập mô tả");
        }else if (newsType <2 && (body === '' || body === null)){
            toast.error("Vui lòng nhập nội dung bài viết");
        }else if (newsType > 1 && linkFile === ''){
            toast.error("Vui lòng nhập tải nội dung bài viết");
        }else {
            this.setState({showLoading: true});
            let payload = {
                id: this.state.params.postId,
                body: {
                    body: body ? (data.body == body ? `${data.body}` : `${body}`) : `${data.body}`,
                    categoryId: categoryId ? (data.categoryId == categoryId ? data.categoryId : categoryId) : data.categoryId,
                    description: description ? (data.description == description ? `${data.description}` : `${description}`) : `${data.description}`,
                    hide: data.hide,
                    newsType: data.newsType,
                    linkFile: linkFile ? (data.linkFile == linkFile ? data.linkFile : linkFile) : data.linkFile,
                    image: image ? (data.image == image ? data.image : image) : data.image,
                    tagId: tagId ? (data.tagId == tagId ? data.tagId : tagId) : data.tagId,
                    title: title ? (data.title == title ? data.title : title.trim()) : data.title,
                    url: url ? (data.url == url ? data.url : url) : data.url,
                    createByName: createByName ? (data.createByName == createByName ? data.createByName : createByName) : data.createByName
                }
            }
            SecureNews.update(payload, res => {
                if (res.success) {
                    this.props.history.push("/admin/post-management")
                    toast.success("Cập nhật tin tức thành công!")
                } else{
                    toast.error("Đã xảy ra lỗi! " + res.message)
                }
            }).catch(err => {
                toast.error("Đã xảy ra lỗi! " + err)
            })
        }
    }
    onSave = () => {
        let { body, categoryId, description, title,  newsType, linkFile, createByName, image} = this.state;
        if (title === undefined || title === '' || title === null){
            toast.error("Vui lòng nhập tiêu đề");
        }else if(categoryId === undefined || categoryId < 1){
            toast.error("Vui lòng chọn danh mục");
        }else if (description === undefined || description === '' || description == null){
            toast.error("Vui lòng nhập mô tả");
        }else if (newsType <2 && (body === undefined || body === '' || body === null)){

            toast.error("Vui lòng nhập nội dung bài viết");
        }else  if (newsType > 1 && linkFile === ''){

            toast.error("Vui lòng nhập tải nội dung bài viết");
        }else if (createByName == undefined || createByName ==''){
            toast.error("Vui lòng nhập tên người viết");
        }else if (image == undefined || image ==''){
            toast.error("Vui lòng nhập ảnh Thumbnail");
        }else {
            if ((linkFile === undefined || linkFile === '' || linkFile === null)){
                linkFile  = "_";
            }
            if ((body === undefined || body === '' || body === null)){
                body = description;
            }
            this.setState({showLoading: true});
            let payload = {
                body: body,
                categoryId: this.state.categoryId,
                description: `${this.state.description}`,
                hide: true,
                image: this.state.image,
                tagId: `[${this.state.relativeNewsListId.toString()}]`,
                title: this.state.title.trim(),
                url: this.state.url,
                linkFile: linkFile,
                newsType: newsType,
                createByName: this.state.createByName
            }
            SecureNews.create(payload, res => {
                if (res.success) {
                    this.props.history.push("/admin/post-management")
                    toast.success("Tạo tin tức thành công!")
                } else{
                    toast.error("Đã xảy ra lỗi! " + res.message)
                }
                this.props.hideLoading();
            }).catch(err => {
                toast.error("Đã xảy ra lỗi! " + err)
            })
        }
    }

    onDrop = acceptedFiles => {
        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        })
        this.uploadFile()
    }
    uploadFile = () => {
        let payload = {
            file: this.state.files,
        }

        FilesApi.uploadPublicNewsImg(payload, res => {
            if (res.success) {
                this.setState({
                    image: res.data.url
                })
            }
        }).catch(error => console.log("upload error>>>", error))

    }
    onDropImage = acceptedFiles => {
        this.setState({
            filesImage: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        })
        this.uploadFileImage()
    }
    uploadFileImage = () => {
        let payload = {
            file: this.state.filesImage,
        }

        FilesApi.uploadPublicNewsImg(payload, res => {
            if (res.success) {
                this.setState({
                    linkImage: res.data.url
                })
            }
        }).catch(error => console.log("upload error>>>", error))
    }

    renderNewsUrl = (value) => {
        let title = changeAlias(value);
        let url = title.replace(/ /g, "-")
        this.setState({url})
    }

    getNewsList = (val) => {
        let payload = {
            title: this.state.relativeNewsTitle
        }
        SecureNews.getListNews(payload, res => {
            if (res.success){
                this.setState({relativeNews: res.data.content})
            }
        })
    }

    removeRelativeItem = (item) => {
        let {relativeNewsList, relativeNewsListId} = this.state;
        // let idx =
        relativeNewsList.splice(relativeNewsList.indexOf(item),1)
        relativeNewsListId.splice(relativeNewsListId.indexOf(item.id),1)
        this.setState({relativeNewsList})
    }
    uploadNews = (res, file, fileName) => {
        let payload = {
            fileName: file.name,
            fullName: fileName,
            destination: res.destination,
            storageName: res.storageName
        }
        this.setState({linkFile: URL_API+'/api/one-book-resource/public/view-file?path='+res.destination+res.storageName})
    }
    render() {
        const {pageNumber, pageSize, totalItem, listCategory, newsType} = this.state;
        let data = this.state.data;
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name}-{file.size} bytes
            </li>
        ));
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="post-management" parentClass='home-management'/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true}
                                                title={this.state.params?.type == 'new' ? "Bài viết mới" : "Chỉnh sửa bài viết"}/>
                                    <div className="row">
                                        <label className="label-item">Tiêu đề:</label>
                                        <input
                                            maxLength={500} type="text"
                                            className="form-control shadow-none"
                                            placeholder="Tiêu đề bài viết"
                                            value={this.state.onEdit ? this.state.title : data.title}
                                            onChange={(event) => {
                                                this.renderNewsUrl(event.target.value)
                                                this.setState({
                                                    title: event.target.value,
                                                    onEdit: true
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <label className="label-item">Danh mục bài viết</label>
                                        <select className="form-control shadow-none"
                                                defaultValue={0}
                                                value={this.state.onEdit ? this.state.categoryId : data.categoryId}
                                                onChange={(val) => {
                                                    this.setState({
                                                        categoryId: val.target.value,
                                                        onEdit: true
                                                    })
                                                }}
                                        >
                                            <option value={0} disabled>Chọn một danh mục</option>
                                            {this.state.listCategory.map((item, index) => <option
                                                value={item.id}>{item.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <label className="label-item">Loại bài viết</label>
                                        <select className="form-control shadow-none"
                                                defaultValue={0}
                                                value={data.newsType}
                                                onChange={(val) => {
                                                    data.newsType = val.target.value;
                                                    this.setState({
                                                        newsType: val.target.value,
                                                        onEdit: true
                                                    })
                                                }}
                                        >
                                            <option value={0} disabled>Chọn loại bài viết</option>
                                            <option value={1}>Tin tức</option>
                                            {/*<option value={2}>File Word</option>*/}
                                            <option value={3}>File PDF</option>
                                        </select>
                                    </div>
                                    {newsType >1 &&
                                        <div className="row mt-3 mb-3">
                                            <label className="mr-3" >Tải File Word, PDF</label>
                                            <button className="btn btn-primary mr-3" onClick={()=> {
                                                this.setState({modalUploadFile: true})
                                            }}>Tải file</button>
                                        </div>
                                    }
                                    {newsType == 3 &&
                                    <div>
                                        {(this.state.linkFile != "" && this.state.linkFile != "_")  && <DocViewer
                                            pluginRenderers={[PDFRenderer]}
                                            documents={[
                                                { uri: this.state.linkFile},
                                            ]} />}
                                    </div>
                                    }
                                    <div className="row mt-3 mb-3">
                                        <label className="label-item">Tên người viết</label>
                                        <input
                                            maxLength={100} type="text"
                                            className="form-control shadow-none"
                                            placeholder="Tên người tạo bài viết"
                                            value={this.state.onEdit ? this.state.createByName : data.createByName}
                                            onChange={(event) => {
                                                this.setState({
                                                    createByName: event.target.value,
                                                    onEdit: true
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="row mt-3 mb-2">
                                        <label className="label-item">Ảnh thumbnail</label>
                                        <div className="mx-0 mb-4 w-100 img-mh200">
                                            {/*<div className="alert alert-info my-3 text-center text-sm" role="alert">*/}
                                            {/*    Vui lòng chỉ sử dụng ảnh vuông, có định dạng JPEG, PNG, TIF.*/}
                                            {/*</div>*/}
                                            <Dropzone accept="image/*" onDrop={this.onDrop} multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />

                                                            {files.length > 0 ? <div className="align-center">
                                                                <div className="img-wh120-wrapper"> <img src={this.state.files[0].preview} alt="" className="img-h200"/></div>
                                                                {/*<ul>{files}</ul>*/}
                                                            </div> : (this.state.params?.type == "edit" ?
                                                            <div className="align-center">
                                                                <div className="img-wh120-wrapper"> <img src={this.state.file} alt="" className="img-h200"/></div>
                                                                {/*<ul>{files}</ul>*/}
                                                            </div> :<p>Chọn file ảnh</p>)}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="label-item">Mô tả ngắn &nbsp;<span>({data ? (this.state.onEdit ? this.state.description?.length : data?.description?.length) : data?.description?.length}/250)</span></label>
                                    <textarea name="" id="" rows="6" className="form-control mb-3"
                                        maxLength={255}
                                        value={this.state.onEdit ? this.state.description : data.description} onChange={(val) => {
                                            this.setState({
                                                description: val.target.value,
                                                onEdit: true
                                            })
                                    }}/>
                                </div>

                                <div className="p-0 mb-3">
                                    <label className="label-item">Nội dung &nbsp;<span>({data ? (this.state.onEdit ? this.state.body?.length : data?.body?.length) : data?.body?.length}/5000)</span></label>
                                    <div className="row">
                                        <div className="mx-0 mb-4 w-100 img-mh200">
                                            {/*<div className="alert alert-info my-3 text-center text-sm" role="alert">*/}
                                            {/*    Vui lòng chỉ sử dụng ảnh vuông, có định dạng JPEG, PNG, TIF.*/}
                                            {/*</div>*/}
                                            <Dropzone accept="image/*" onDrop={this.onDropImage} multiple={false}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="container-upload">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />

                                                            {this.state.filesImage.length > 0 ? <div className="align-center">
                                                                <div className="img-wh120-wrapper"> <img src={this.state.filesImage[0].preview} alt="" className="img-h200"/></div>
                                                                {/*<ul>{files}</ul>*/}
                                                            </div> : (this.state.params?.type == "edit" ?
                                                                <div className="align-center">
                                                                    <div className="img-wh120-wrapper"> <img src={this.state.linkImage} alt="" className="img-h100"/></div>
                                                                </div> :<p>Chọn file ảnh</p>)}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <input
                                            type="text"
                                            className="col-12 pl-0 form-control shadow-none  mr-3"
                                            placeholder="Link ảnh copy vào nội dung bài viết"
                                            value={this.state.linkImage}
                                        />
                                    </div>
                                    <SunEditor setOptions={{
                                        buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                                        }}
                                        onChange={(content)=>{
                                            this.setState({body: content});
                                        }}
                                    />
                                    <div className="mt-3 mb-3 relative-news row">
                                        <div className="col-6 pl-0">
                                            <label className="label-item">Tin liên quan</label>
                                            <AutoSuggestionNews label="Partner"
                                                                placeholder="Nhập tiêu đề tin"
                                                                type="text"
                                                                value={this.state.relativeNewsTitle}
                                                                data={this.state.relativeNews}
                                                                clearSearch={this.state.clearSearchNews}
                                                                onSelect={(item) => {
                                                                    let {relativeNewsList, relativeNewsListId} = this.state;
                                                                    relativeNewsList.push(item);
                                                                    relativeNewsListId.push(item.id);
                                                                    this.setState({relativeNewsList, relativeNewsListId})
                                                                }}
                                                                onChange={(val) => {
                                                                    this.setState({relativeNewsTitle: val, selectedNews: null, clearSearchNews: false})
                                                                    setTimeout(() => {
                                                                        this.getNewsList()
                                                                    }, 300);

                                                                }}
                                                                checkError={!this.state.relativeNews}
                                                                errorContent="Vui lòng chọn tin liên quan"/>
                                        </div>
                                        <div className="col-6">
                                            <label className="label-item">Danh sách tin đã chọn</label>
                                            <div className="card" style={{minHeight: 60}}>
                                                <ul>
                                                    {this.state.relativeNewsList && this.state.relativeNewsList.length > 0 && this.state.relativeNewsList.map((item) => (
                                                        <li className="relative-news-item">
                                                            <div className="d-flex">
                                                                <div className="col-11 relative-news-title p-2">
                                                                    <span>{item.title}</span>
                                                                </div>
                                                                <div className="col-1 d-flex justify-content-center align-items-center icon-delete-relative-news" onClick={() => this.removeRelativeItem(item)}>
                                                                    <span className="fas fa-trash"/>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagination-right justify-flex-end mt-3">
                                        <button
                                            type="button"
                                            className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                            onClick={() => {
                                                if (this.state.params.type == "edit"){
                                                    this.onUpdate()
                                                } else {
                                                    this.onSave()
                                                }
                                            }}
                                        >
                                            Lưu
                                        </button>
                                        <Link to={"/admin/post-management"}>
                                            <button type="button"
                                                    className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                    onClick={() => {

                                                    }}
                                            >
                                                Hủy bỏ
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalUploadFile && <UploadFile show={this.state.modalUploadFile}
                                                      uploadSuccess={this.uploadNews}
                                                      onHide={() => this.setState({modalUploadFile: false})}/>}
            </div>
        );
    }

}

export default PostEditor
