import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let AdminOneBookApi = {
    getAdminDetail: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/detail`,
            method: "GET"
        }, callback)
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },
    detail: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/detail`,
            method: 'GET'
        }, callback)
    },
    updateUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/one-book/update-user`,
            method: "POST",
            body: payload.body
        }, callback)
    }
};

export default AdminOneBookApi
