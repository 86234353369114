import React from 'react'
import {Button, Card, Table, Form, Col, Row} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import Autosuggestion from '../../../components/Form/Autosuggestion';
import NumberFormat from "react-number-format";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ModalDeleteSupervisor from '../../../components/Modal/admin/modalDeleteSupervisor';
import SupervisorApi from '../../../services/ob-user/supervisor';
import PartnerApi from '../../../services/ob-user/partner';
import {Link} from "react-router-dom";
import ModalSupervisorDetailWaitActive from "../../../components/Modal/admin/modalSupervisorDetailWaitActive";
import ModalBlockSupervisor from "../../../components/Modal/modalBlockSupervisor";
import ModalUnblockSupervisor from "../../../components/Modal/modalUnblockSupervisor";
import {checkPermission} from "../../../utils/common";

toast.configure()
const dateFormat = 'DD-MM-YYYY';

class SupervisorManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDelete: false,
            showNotDelete: false,
            modalDetailSupWaitActive: false,
            modalBlockSupervisor: false,
            modalUnblockSupervisor: false,
            clearSearchSup: false,
            pageNumber: 0,
            pageSize: 10,
            totalItem: 0,
            itemSelected: null,
            status: "",
            selectedPartner: {},
            partner: "",
            partner_temp: "",
            listData: [],
            listPartner: [],
            listPartnerId: [],
            sort: '',
            selectedSupervisorId: null,
            selectedSupervisor: null,
            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên Supervisor",
                    style: {textAlign: "center", overflow: 'hidden'},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'partnerName',
                    name: "Partner",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'countAccountant',
                    name: "Số kế toán đang phụ trách",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {minWidth: 230, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getListSupOnSearch();
            this.getListPartner();
        })
    }

    getListSupOnSearch = () => {
        let self = this;
        let {pageNumber, pageSize, selectedPartner, status, sort} = this.state;
        let payload = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            partnerId: selectedPartner?.id,
            status: status,
            sort: sort
        };
        SupervisorApi.adminOneBookGetSupervisor(payload, res => {
            if (res.success) {
                let listPartnerId = [];
                let data = res.data.content
                data.map(item => {
                    listPartnerId.push(item.partnerId);
                });
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    listPartnerId,
                    showLoading: false
                }, () => {
                    if (data.length > 0) {
                        self.getListPartnerByIds();
                    } else {
                        this.setState({
                            showLoading: false
                        })
                    }
                })
            } else {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra, vui long tải lại trang!"))
            }
        })
    }

    getListPartner = () => {
        let payload = {
            fullName: this.state.partner,
            pageNumber: 0,
            pageSize: 10,
        }
        PartnerApi.searchByPartnerNameAndAddress(payload, res => {
            // console.log('partner:', res)
            if (res.success) {
                this.setState({
                    listPartner: res.data.content
                })
            }
        })
    }

    getListPartnerByIds() {
        let {listPartnerId, listData} = this.state;

        let payload = {
            ids: listPartnerId
        };
        PartnerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.partnerId);
                    item.partnerName = filter[0]?.fullName;
                });
                this.setState({
                    listData
                });
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }

    handleChangePage = (page) => {
        this.setState({
                pageNumber: page - 1,
            }, () => {
                this.getListSupOnSearch()
            }
        );
    }

    renderStatus = (status) => {
        switch (status) {
            case 'WAIT_ACTIVE':
                return <h6><span className="badge badge-secondary">Chờ kích hoạt</span></h6>;
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "REJECT":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    };

    render() {
        let {pageNumber, pageSize} = this.state
        let self = this;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="supervisor-management" parentClass="employee-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý Supervisor"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Partner quản lý</label>
                                                <Autosuggestion
                                                    label="Partner"
                                                    placeholder="Nhập tên Partner"
                                                    type="text"
                                                    value={this.state.partner}
                                                    data={this.state.listPartner}
                                                    clearSearch={this.state.clearSearchSup}
                                                    onSelect={(item) => {
                                                        this.setState({selectedPartner: item}, () => console.log(this.state.selectedPartner))
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({partner: val, selectedPartner: null, clearSearchSup: false})
                                                        setTimeout(() => {
                                                            this.getListPartner()
                                                        }, 300);

                                                    }}
                                                    checkError={!this.state.listPartner}
                                                    errorContent="Vui lòng chọn một Partner"
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    value={this.state.status}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            status: e.target.value
                                                        })
                                                    }}
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value=''>Tất cả</option>
                                                    <option value='ACTIVE'>Đang hoạt động</option>
                                                    <option value='LOCK'>Đã khóa</option>
                                                    <option value='REJECT'>Đã xóa</option>
                                                    <option value='WAIT_COMPLETE_INFO'>Chờ hoàn thiện thông tin</option>
                                                    <option value='WAIT_CONFIRM_EMAIL'>Chờ xác nhận email</option>
                                                    <option value='WAIT_ACTIVE'>Chờ kích hoạt</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() =>
                                                        this.getListSupOnSearch()
                                                    }
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                status: '',
                                                                partner: '',
                                                                selectedPartner: {},
                                                                sort: '',
                                                                clearSearchSup: true
                                                            }, () => {
                                                                this.getListSupOnSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số Supervisor: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            console.log(data);
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.getListSupOnSearch())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {row.partnerName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.countAccountant}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{this.renderIconStatus(row)}*/}
                                                        <span>
                                                            <button
                                                                className="btn btn-action"
                                                                disabled={row.status !== "WAIT_ACTIVE"}
                                                                title="Xem chi tiết - Kích hoạt tài khoản"
                                                                onClick={() => this.setState({
                                                                    selectedSupervisorId: row.id,
                                                                    modalDetailSupWaitActive: true
                                                                })}
                                                            >
                                                                <i className="ti-key"/>
                                                            </button>
                                                            {checkPermission('view_supervisor_account_detail') &&
                                                            <Link
                                                                to={{
                                                                    pathname: "./supervisor-detail",
                                                                    search: `?supervisorId=${row.id}`,
                                                                    state: {
                                                                        fromDashboard: true,
                                                                        supervisorId: row.supervisorId,

                                                                    }
                                                                }}
                                                                style={{color: "#000000"}}
                                                            >
                                                                <button
                                                                    className="btn btn-action"
                                                                    disabled={row.status === "WAIT_ACTIVE"}
                                                                    title="Xem chi tiết"
                                                                    onClick={() => {
                                                                    }}
                                                                >
                                                                    <i className="ti-book"/>
                                                                </button>
                                                            </Link>
                                                            }
                                                            {checkPermission('block_supervisor_account') &&
                                                            <button
                                                                className="btn btn-action"
                                                                title={row.status == "LOCK" ? "Mở khóa" : "Khóa"}
                                                                disabled={row.status == "WAIT_COMPLETE_INFO" || row.status == "WAIT_CONFIRM_EMAIL" || row.status == "REJECT" || row.status == "WAIT_ACTIVE"}
                                                                onClick={() => {
                                                                    if (row.status == "LOCK") {
                                                                        this.setState({
                                                                            selectedSupervisor: row,
                                                                            modalUnblockSupervisor: true
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            selectedSupervisor: row,
                                                                            modalBlockSupervisor: true
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <i className={row.status == "LOCK" ? "ti-unlock" : "ti-lock"}/>
                                                            </button>
                                                            }
                                                            {checkPermission("delete_supervisor_account") &&
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xóa"
                                                                onClick={() => {
                                                                    if (row.countContract == 0 && row.countAccountant == 0) {
                                                                        this.setState({itemSelected: row}, () => this.setState({showDelete: true}))
                                                                    } else {
                                                                        return (toast.error("Tài khoản Supervisor còn hợp đồng đang thực hiện (hoặc còn đang phụ trách kế toán), không thể thực hiện thao tác xóa", {
                                                                            position: toast.POSITION.TOP_RIGHT,
                                                                            autoClose: 8000
                                                                        }))
                                                                    }
                                                                }}
                                                            >
                                                                <i className="ti-trash"/>
                                                            </button>
                                                            }
                                                            {checkPermission('edit_supervisor_information') &&
                                                            <button
                                                                className="btn btn-action"
                                                                title="Sửa"
                                                                onClick={() => {
                                                                }}
                                                            >
                                                                <i className="ti-pencil-alt"/>
                                                            </button>
                                                            }
                                                            </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showDelete && <ModalDeleteSupervisor id={this.state.itemSelected.id}
                                                                 name={this.state.itemSelected.fullName}
                                                                 show={this.state.showDelete}
                                                                 onHide={() => this.setState({showDelete: false})}
                                                                 onRefresh={() => this.getListSupOnSearch()}
                />}
                {this.state.modalDetailSupWaitActive &&
                <ModalSupervisorDetailWaitActive supervisorId={this.state.selectedSupervisorId}
                                                 onRefresh={() => {
                                                     this.setState({modalDetailSupWaitActive: false}, () => self.getListSupOnSearch())
                                                 }}
                                                 onHide={() => this.setState({modalDetailSupWaitActive: false})}
                />}
                {this.state.modalBlockSupervisor &&
                <ModalBlockSupervisor show={this.state.modalBlockSupervisor} id={this.state.selectedSupervisor.id}
                                      name={this.state.selectedSupervisor.fullName}
                                      onRefresh={() => {
                                          this.setState({modalBlockSupervisor: false}, () => self.getListSupOnSearch())
                                      }}
                                      onHide={() => this.setState({modalBlockSupervisor: false})}
                />}
                {this.state.modalUnblockSupervisor &&
                <ModalUnblockSupervisor show={this.state.modalUnblockSupervisor} id={this.state.selectedSupervisor.id}
                                        name={this.state.selectedSupervisor.fullName}
                                        onRefresh={() => {
                                            this.setState({modalUnblockSupervisor: false}, () => self.getListSupOnSearch())
                                        }}
                                        onHide={() => this.setState({modalUnblockSupervisor: false})}
                />}

            </div>
        )
    }

}

export default SupervisorManagement
