import React from 'react';
// import {withSnackbar} from "notistack";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../../services/ob-user/supervisor";

toast.configure()

class modalDeleteSupervisor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showDelete: true,
            id: this.props.id
        };
    }

    onSubmit = () => {
        let {reason, id} = this.state;
        let payload = {
            id: id,
            reason: reason,
        };
        console.log('delSUp:', payload)
        SupervisorApi.adminOneBookDeleteSupervisor(payload, res => {
            if (!res.success) {
                return (toast.error("Thao tác thất bại. Vui lòng thử lại!", {
                    position: toast.POSITION.TOP_RIGHT,
                    // autoClose: 5000
                }))
            } else {
                this.props.onRefresh();
                this.props.onHide();
                return (toast.success(`Xóa tài khoản supervisor ${this.props.name} thành công!`, {
                    position: toast.POSITION.TOP_RIGHT,
                    // autoClose: 5000
                }))
            }
        })
    }

    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <label className='font-size-content'>
                                Vui lòng nhập lí do trước khi xóa tài khoản Supervisor {this.props.name}
                            </label>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập lý do '}
                                maxLength={250}
                                value={this.state.reason}
                                onChange={(event) => {
                                    this.setState({reason: event.target.value})
                                }}
                            />
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    disabled={this.state.reason?.length == 0}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default modalDeleteSupervisor
