import React from 'react';
import {Modal} from "react-bootstrap";
import "./Modal.css"
import AdminServicePlan from "../../../services/ob-core/admin-service-plan";
import TableHeader from "../../TableHeader";
import Pagination from "../../Pagination";

class modalAddService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            price: 0,
            showCreate: false,
            showDetailAdvance: false,
            showEditAdvance: false,
            showActive: false,

            page: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: '',

            sort: '',

            categoryType: "",
            categoryType_temp: "",
            listData: [],
            serviceList: this.props.data,
            itemSelected: "",
            showModalDelete: false,
            showModalLock: false,
            showModalUnlock: false,
            showLoading: false,
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên gói dịch vụ',
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'category',
                    name: "Loại gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Chọn",
                    style: {textAlign: "center"},
                    sort: false
                },
            ],
        }
    }
    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListService()
        })

    }

    onSearch = () => {
        this.setState({
            categoryType: this.state.categoryType_temp,
        }, () => this.searchListService())
    }

    searchListService = () => {
        let {pageNumber, pageSize, sort, descending, categoryType} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            type: categoryType,
            sort: sort,
        }
        AdminServicePlan.searchByType(payload, res => {
            let newData = res.data.content.filter(item => item.status === "ACTIVE");
            console.log("xxxxxxxxxxxxxxxxxx");
            console.log(newData);
            console.log(this.state.serviceList);
            if (this.state.serviceList.length > 0 && newData.length > 0){
                for (let i = 0; i < newData.length; i++) {
                    for (let j = 0; j < this.state.serviceList.length; j++) {
                        if (newData[i].id == this.state.serviceList[j].id || newData[i].id == this.state.serviceList[j].servicePlanId){
                            newData[i].status = "SELECTED";
                        }
                    }
                }
            }
            console.log(newData);
            this.setState({
                listData: newData,
                totalItem: newData.length,
                totalPage: Math.round(newData.length / this.state.pageSize),
                showLoading: false
            });
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListService()
            }
        );
    }

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "DISABLED":
                return <h6><span className="badge badge-warning">Vô hiệu hóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };
    onSubmit = () => {
        let listData = this.state.listData.filter(item => item.status === "SELECTED");
        this.props.create(listData)
    }

    render() {
        const modalProp = {
            show: this.props.show,
            keyboard: false,
            backdrop: 'static',
        };
        let {pageNumber, pageSize, accountBalance} = this.state
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered" style={{minWidth: 900}}>
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÊM GÓI DỊCH VỤ</b>
                            </h1>
                        </div>
                        <div className='mt-12 mb-12'>
                            <div className="row">
                                <div className="container">
                                        <div className="row">
                                            <div className="mb-4 col-md-12 tab-content">
                                                {this.state.showLoading &&
                                                <div className="content-loading col-12 ml-3 align-items-center"
                                                     style={{display: 'block'}}>
                                                    <div className="spinner-grow text-info"/>
                                                </div>
                                                }
                                                <div className="card col-12">
                                                    <div className='mb-4'>
                                                        <div className="kt-form kt-form--fit kt-margin-b-20">
                                                            <div className="row">
                                                                <div className='col-4 p-0'>
                                                                    <label className="label-item">Loại gói dịch vụ</label>
                                                                    <select
                                                                        className="form-control kt-input pd-4-input shadow-none"
                                                                        value={this.state.categoryType_temp}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                categoryType_temp: e.target.value
                                                                            })
                                                                        }}
                                                                    >
                                                                        <option value=''>Tất cả</option>
                                                                        <option value='BASIC'>Dịch vụ cơ bản</option>
                                                                        <option value='ADVANCED'>Dịch vụ nâng cao</option>
                                                                    </select>
                                                                </div>
                                                                <div className='col-xs-4 col-sm-4 col-md-4 pl-2' style={{marginTop: 35}}>
                                                                    <button
                                                                        type="button"
                                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                                        onClick={() => {
                                                                            this.onSearch()
                                                                        }}
                                                                    >
                                                                        Tìm kiếm
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-wrapper">
                                                        <table className="table table-striped table-data table-bordered">
                                                            <thead>
                                                            <TableHeader header={this.state.header} clickSort={(data) => {
                                                                let a = '';
                                                                if (data.sortAsc == null) {
                                                                    a = ''
                                                                } else {
                                                                    a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                                                }
                                                                this.setState({
                                                                    sort: a
                                                                }, () => this.searchListService())
                                                            }}/>
                                                            </thead>
                                                            {!this.state.listData || this.state.listData.length == 0
                                                                ? <tbody>
                                                                <tr>
                                                                    <td colSpan={12}>
                                                                        <div className='text-center'>Không có dữ liệu</div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                                : <tbody>
                                                                {this.state.listData.map((row, index) => (
                                                                     <tr key={row.id} style={{height: 50}}>
                                                                        <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                                        <td style={{textAlign: 'left',}}>
                                                                            {row.servicePlanName}
                                                                        </td>
                                                                        <td style={{textAlign: 'left'}}>
                                                                            {this.renderType(row.type)}
                                                                        </td>
                                                                        <td className='p-1' style={{textAlign: 'center'}}>
                                                                            <button className="btn btn-action p-2"
                                                                                    title={row.status === 'SELECTED' ? 'Bỏ chọn' : 'Chọn'}
                                                                                    style={{cursor: 'pointer'}}
                                                                                    onClick={() => {
                                                                                        if (row.status === 'SELECTED'){
                                                                                            this.state.listData[index].status = 'ACTIVE';
                                                                                        } else {
                                                                                            this.state.listData[index].status = 'SELECTED';
                                                                                        }
                                                                                        let listData =  this.state.listData;
                                                                                        this.setState({
                                                                                            listData: listData,
                                                                                        });
                                                                                    }}
                                                                            >
                                                                                <i className={row.status === 'ACTIVE' ? "ti-plus" : 'ti-check-box'}/>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>}
                                                        </table>
                                                    </div>

                                                    <div className="pagination-right">
                                                        {
                                                            this.state.totalItem > this.state.pageSize &&
                                                            <Pagination
                                                                activePage={this.state.pageNumber + 1}
                                                                itemsCountPerPage={this.state.pageSize}
                                                                totalItemsCount={this.state.totalItem}
                                                                changeHandler={(page) => this.handleChangePage(page)}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    onClick={() => this.onSubmit()}
                                >
                                    Đồng ý
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                    Hủy bỏ
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default modalAddService
