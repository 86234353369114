import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';
import AdminServicePlan from '../../../services/ob-core/admin-service-plan'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminProgramManager from "../../../services/ob-core/admin-program-manager";

class modalLockProgram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showBlock: true,
            signupInfo: null
        };
    }

    block = () => {
        let payload = {
            id: this.props.id,
        }
        AdminProgramManager.block(payload, res => {
            if (res.errorCode === "One-Book-User.E000017") {
                toast.success(res.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                // this.props.onHideModal();
            } else if (res.success) {
                toast.success("Khóa chương trình thành công!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                this.props.onHide();
                this.props.onRefresh()
            } else {
                toast.success("Có lỗi xảy ra!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='d-flex justify-content-center mt-3 mb-3'>
                            <label className='font-size-content'>Bạn có muốn khoá chương trình này không ?</label>
                        </div>


                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    onClick={() => this.block()}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export  default  modalLockProgram
