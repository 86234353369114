import React from 'react';
import {
    ChevronRight,
    ClockFill,
    GeoAlt,
    Person,
    Star,
    StarFill,
} from 'react-bootstrap-icons';
import avat1 from '../../../assets/images/ava 1.png';
import banner1 from '../../../assets/images/banner/onebook_dich_vu_ke_toan_1.jpg';
import banner2 from '../../../assets/images/banner/onebook_dich_vu_ke_toan_2.jpg';
import banner3 from '../../../assets/images/banner/onebook_dich_vu_ke_toan_3.jpg';
import daian from '../../../assets/images/logodaian.png';
import onenet from '../../../assets/images/onenet.png';
import vline from '../../../assets/images/vline.png';
import star from '../../../assets/images/starFull.png';
import starEmpty from '../../../assets/images/startEmpty.png';
import Banner from '../../../assets/images/Banner.png';
import profAvt from '../../../assets/images/chuyengia.png';
import empAvt from '../../../assets/images/ds.png';
import companyDes1 from '../../../assets/images/icon dichvu/1.png';
import companyDes2 from '../../../assets/images/icon dichvu/2.png';
import companyDes3 from '../../../assets/images/icon dichvu/3.png';
import companyDes4 from '../../../assets/images/icon dichvu/4.png';
import companySer1 from '../../../assets/images/icon-dkdv/1.png';
import companySer2 from '../../../assets/images/icon-dkdv/2.png';
import companySer3 from '../../../assets/images/icon-dkdv/3.png';
import iconEz from '../../../assets/images/icon-nt/2.png';
import iconEz2 from '../../../assets/images/icon-nt/1.png';
import iconAccountant from '../../../assets/images/icon-nt/3.png';
import './style.css';
import ModalSignupMember from "../../../components/Modal/ModalSignupMember";
import Link from "../../../components/Link";
import PublicSupervisorApi from "../../../services/ob-user/public-supervisor";
import ProvinceApi from "../../../services/ob-user/province";
import DistrictApi from "../../../services/ob-user/district";
import PublicNews from "../../../services/ob-core/public-news";
import moment from "moment";
import {changeAlias} from "../../../utils/common";
import Rating from "react-rating";
import RoleApi from "../../../services/ob-user/role";
import Authentication from "../../../services/auth";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalSignupMember: false,
            listSupHighlight: [],
            typeSearch: 'supervisor',
            areaId: 0
        }
    }

    componentDidMount() {
        this.getSupervisorHighlight()
        this.getNewsHighlight()
    }

    componentWillMount() {
        let location = JSON.parse(localStorage.getItem("province"));
        if (!location) {
            ProvinceApi.getAll(res => {
                if (res.success) {
                    localStorage.setItem("province", JSON.stringify(res.data))
                }
            });
            DistrictApi.getAll(res => {
                if (res.success) {
                    localStorage.setItem("district", JSON.stringify(res.data))
                }
            });
        }
    }
    handleShowSignup = () => {
        this.setState({modalSignupMember: true})
    }

    getSupervisorHighlight = () => {
        PublicSupervisorApi.getSupervisorHighlight(res => {
            if (res.success) {
                this.setState({
                    listSupHighlight: res.data
                })
            }
        })
    }

    getNewsHighlight = () => {
        PublicNews.getListHighlight(res => {
            if (res.success) {
                this.setState({listNews: res.data})
            }
        })
    }

    renderSupportArea = () => {
        let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
        return _province?.filter(v => v.status === 'ACTIVE').map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    renderNewsUrl = (value) => {
        if(value) {
            let title = changeAlias(value);
            return title.replace(/ /g, "-")
        }
    }

    render() {
        const {listSupHighlight, typeSearch, areaId} = this.state;
        const fadeProperties = {
            duration: 3000,
            canSwipe: false,
        };

        const goToUrl = (pos) => {
            window.location.href = "/home/select-plan-step-1";
        }
        return (
            <div className='App'>
                <div className='homepage-banner'>
                    {/*<img src={Banner} alt='banner' className='img-fluid'/>*/}
                    <div>
                        <div className="slide-container">
                            <Slide {...fadeProperties}>
                                <div className="each-fade">
                                    <div>
                                        <img src={banner1}/>
                                    </div>
                                </div>
                                <div className="each-fade">
                                    <div>
                                        <img src={banner2} onClick={() => window.location.href = "http://onebook.ac/#/home/select-plan-step-1"}/>
                                    </div>
                                </div>
                                <div className="each-fade">
                                    <div>
                                        <img src={banner3} onClick={() => window.location.href = "http://onebook.ac/#/home/join-with-us"}/>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <div className='search-panel container back'>
                    <div className='row bg-white box-shadow-main search-content'>
                        <div className='col-md-7 pd-30'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <h4 className='title color-main-dark mb-3'>Bắt Đầu Tìm Kiếm</h4>
                                <div className='row selection'>
                                    <div className='col-sm-6'>
                                        <button
                                            className={typeSearch === 'partner' ? 'btn btn-professional bg-gradient-main color-white mb-3 w-100' : 'btn btn-partner bg-white color-main mb-3 w-100'}
                                            onClick={() => {
                                                this.setState({
                                                    typeSearch: 'partner'
                                                })
                                                // this.props.history.push('/home/partner')
                                            }}
                                        >
                                            Đối Tác
                                        </button>
                                    </div>
                                    <div className='col-sm-6'>
                                        <button
                                            className={typeSearch === 'supervisor' ? 'btn btn-professional bg-gradient-main color-white mb-3 w-100' : 'btn btn-partner bg-white color-main mb-3 w-100'}
                                            onClick={() => {
                                                this.setState({
                                                    typeSearch: 'supervisor'
                                                })
                                                // this.props.history.push('/home/supervisor')
                                            }}
                                        >
                                            Chuyên Gia
                                        </button>
                                    </div>
                                    <div className='col-12'>
                                        <div className='search'>
                                            <div className='form-group'>
                                                <select
                                                    className='form-control'
                                                    id='exampleFormControlSelect2'
                                                    placeholder='Chọn khu vực'
                                                    onChange={(e) => {
                                                        this.setState({
                                                            areaId: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option disabled selected value=''>
                                                        Chọn khu vực
                                                    </option>
                                                    {this.renderSupportArea()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<button className='advance-search color-main'>*/}
                                {/*    <img*/}
                                {/*        src={advanceSearch}*/}
                                {/*        alt='advance-search'*/}
                                {/*        className='img-small mr-3'*/}
                                {/*    />*/}
                                {/*    <span>Tìm kiếm nâng cao</span>*/}
                                {/*</button>*/}
                                <button
                                    title='Tìm kiếm'
                                    className='btn btn-sm ml-2'
                                    style={{
                                        width: 140,
                                        border: '1px solid #ccc'
                                    }}
                                    onClick={() => {
                                        if (typeSearch === 'partner') {
                                            this.props.history.push('/home/partner')
                                        } else {
                                            if (areaId) this.props.history.push(`/home/supervisor?areaId=${areaId}`)
                                            else this.props.history.push('/home/supervisor')
                                        }
                                    }}
                                >
                                    <i className='fas fa-search color-main mr-1'/>
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                        <div className='col-md-5 pd-30 box-shadow-main right-part'>
                            <h3 className='secondary-title mb-4' style={{fontSize: "1.75rem"}}>
                                <span className='color-main-dark text-bold'><b>Bạn Là</b></span>
                                <span className='color-main text-bold'><b> Kế Toán?</b></span>
                            </h3>
                            <Link to="/home/join-with-us">
                                <div className='join-onebook color-white bg-green'>
                                    <span>Tham Gia</span>
                                    <span>ONEBOOK</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='company-description container app-separator'>
                    <h2 className='mb-5 app-title'>Tại sao chọn Onebook?</h2>
                    <div className='row align-items-start'>
                        <div className='col-sm-3 mb-3 col-6'>
                            <img
                                src={companyDes1}
                                alt='ab'
                                className='img-fluid cpn-des-img'
                            />
                            <div className='cpn-des'>Công ty uy tín</div>
                        </div>
                        <div className='col-sm-3 col-6 mb-3'>
                            <img
                                src={companyDes2}
                                alt='ab'
                                className='img-fluid cpn-des-img'
                            />
                            <div className='cpn-des'>Chuyên gia hàng đầu</div>
                        </div>
                        <div className='col-sm-3 mb-3 col-6'>
                            <img
                                src={companyDes3}
                                alt='ab'
                                className='img-fluid cpn-des-img'
                            />
                            <div className='cpn-des'>Dịch vụ giá rẻ<br/> chất lượng cao</div>
                        </div>
                        <div className='col-sm-3 mb-3 col-6'>
                            <img
                                src={companyDes4}
                                alt='ab'
                                className='img-fluid cpn-des-img'
                            />
                            <div className='cpn-des'>
                                Nhanh chóng, chính xác,<br/> bảo mật, tin cậy
                            </div>
                        </div>
                    </div>
                </div>
                <div className='company-description container'>
                    <h2 className='mb-5 app-title sub-title'>Đăng ký dịch vụ dễ dàng</h2>
                    <div className='row align-items-start mb-3'>
                        <div className='col-sm-4 mb-3 text-center'>
                            <img
                                src={companySer1}
                                alt='ab'
                                className='img-fluid cpn-sv-img'
                            />
                            <div className='cpn-sv-des cpn-des'><b>1.</b> Chọn dịch vụ kế toán</div>
                        </div>
                        <div className='col-sm-4 mb-3 text-center'>
                            <img
                                src={companySer2}
                                alt='ab'
                                className='img-fluid cpn-sv-img'
                            />
                            <div className='cpn-sv-des cpn-des'><b>2.</b> Chọn chuyên gia</div>
                        </div>
                        <div className='col-sm-4 mb-3 text-center'>
                            <img
                                src={companySer3}
                                alt='ab'
                                className='img-fluid cpn-sv-img'
                            />
                            <div className='cpn-sv-des cpn-des'><b>3.</b> Đặt dịch vụ</div>
                        </div>
                    </div>
                    <div className="pd-30">
                        <Link to="/home/select-plan-step-1">
                            <div className='choose-service color-white bg-green pd-30'>
                                <span>ĐĂNG KÝ DỊCH VỤ</span>
                                {/*<span>Dịch vụ</span>*/}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='company-professional container'>
                    <h2 className='mb-5 app-title sub-title'>Chuyên gia của chúng tôi</h2>
                    {listSupHighlight.length === 0
                        ? <div className='text-center'>
                            Chưa có dữ liệu
                        </div>
                        : <div className='row align-items-start'>
                            <div className='col-md-3 mb-3'>
                                <div className='cpn-pro-item'>
                                    <img className='cpn-pro-item--img' src={listSupHighlight[0]?.imageHighlight}/>
                                    <div className='cpn-pro-item--rate'>
                                        <Rating
                                            start={0}
                                            stop={5}
                                            readonly
                                            initialRating={listSupHighlight[0]?.vote}
                                            emptySymbol={<img src={starEmpty} alt='star' className="w-100 star-empty"/>}
                                            fullSymbol={<img src={star} alt='star' className="w-100 star-full"/>}
                                        />
                                    </div>

                                    <div className='cpn-pro-item--des'>
                                        <p className='emp-name'>{listSupHighlight[0]?.fullName}</p>
                                        {listSupHighlight[0] ? <p className='emp-des'>{listSupHighlight[0]?.experience} năm kinh nghiệm làm
                                            việc</p> : <p className='emp-des'>N/A</p>}
                                        <Link
                                            to={{
                                                pathname: "/home/supervisor-page",
                                                // search: `?s=${listSupHighlight[0].id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                search: `?s=${listSupHighlight[0]?.id}-${this.renderNewsUrl(listSupHighlight[0]?.fullName)}`,
                                                state: {
                                                    fromDashboard: true,
                                                    supervisor: listSupHighlight[0]?.id,
                                                }
                                            }}
                                        >
                                            Đọc thêm <ChevronRight/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <div className='cpn-pro-item'>
                                    <div className='cpn-pro-item--rate'>
                                        <Rating
                                            start={0}
                                            stop={5}
                                            readonly
                                            initialRating={listSupHighlight[1]?.vote}
                                            emptySymbol={<img src={starEmpty} alt='star' className="w-100 star-empty"/>}
                                            fullSymbol={<img src={star} alt='star' className="w-100 star-full"/>}
                                        />
                                    </div>
                                    <img className='cpn-pro-item--img' src={listSupHighlight[1]?.imageHighlight}/>
                                    <div className='cpn-pro-item--des'>
                                        <p className='emp-name'>{listSupHighlight[1]?.fullName}</p>
                                        {listSupHighlight[1] ? <p className='emp-des'>{listSupHighlight[1]?.experience} năm kinh nghiệm làm
                                            việc</p> : <p className='emp-des'>N/A</p>}
                                        <Link
                                            to={{
                                                pathname: "/home/supervisor-page",
                                                // search: `?s=${listSupHighlight[2].id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                search: `?s=${listSupHighlight[1]?.id}-${this.renderNewsUrl(listSupHighlight[1]?.fullName)}`,
                                                state: {
                                                    fromDashboard: true,
                                                    supervisor: listSupHighlight[1]?.id,
                                                }
                                            }}
                                        >
                                            Đọc thêm <ChevronRight/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <div className='cpn-pro-item'>
                                    <div className='cpn-pro-item--rate'>
                                        <Rating
                                            start={0}
                                            stop={5}
                                            readonly
                                            initialRating={listSupHighlight[2]?.vote}
                                            emptySymbol={<img src={starEmpty} alt='star' className="w-100 star-empty"/>}
                                            fullSymbol={<img src={star} alt='star' className="w-100 star-full"/>}
                                        />
                                    </div>
                                    <img className='cpn-pro-item--img' src={listSupHighlight[2]?.imageHighlight}/>
                                    <div className='cpn-pro-item--des'>
                                        <p className='emp-name'>{listSupHighlight[2]?.fullName}</p>
                                        {listSupHighlight[2] ? <p className='emp-des'>{listSupHighlight[2]?.experience} năm kinh nghiệm làm
                                            việc</p> : <p className='emp-des'>N/A</p>}
                                        <Link
                                            to={{
                                                pathname: "/home/supervisor-page",
                                                // search: `?s=${listSupHighlight[2].id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                search: `?s=${listSupHighlight[2]?.id}-${this.renderNewsUrl(listSupHighlight[2]?.fullName)}`,
                                                state: {
                                                    fromDashboard: true,
                                                    supervisor: listSupHighlight[2]?.id,
                                                }
                                            }}
                                        >
                                            Đọc thêm <ChevronRight/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <div className='cpn-pro-item'>
                                    <div className='cpn-pro-item--rate'>
                                        <Rating
                                            start={0}
                                            stop={5}
                                            readonly
                                            initialRating={listSupHighlight[3]?.vote}
                                            emptySymbol={<img src={starEmpty} alt='star' className="w-100 star-empty"/>}
                                            fullSymbol={<img src={star} alt='star' className="w-100 star-full"/>}
                                        />
                                    </div>
                                    <img className='cpn-pro-item--img' src={listSupHighlight[3]?.imageHighlight}/>
                                    <div className='cpn-pro-item--des'>
                                        <p className='emp-name'>{listSupHighlight[3]?.fullName}</p>
                                        {listSupHighlight[3] ? <p className='emp-des'>{listSupHighlight[3]?.experience} năm kinh nghiệm làm
                                                việc</p>
                                            : <p className='emp-des'>N/A</p>
                                        }
                                        <Link
                                            to={{
                                                pathname: "/home/supervisor-page",
                                                // search: `?s=${listSupHighlight[2].id}-${Math.floor((Math.random() * 1000000000) + 1).toString(36)}-${Math.floor((Math.random() * 1000000000) + 1)}`,
                                                search: `?s=${listSupHighlight[3]?.id}-${this.renderNewsUrl(listSupHighlight[3]?.fullName)}`,
                                                state: {
                                                    fromDashboard: true,
                                                    supervisor: listSupHighlight[3]?.id,
                                                }
                                            }}
                                        >
                                            Đọc thêm <ChevronRight/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <Link to={`/home/supervisor`}>
                        <button className='btn btn-see-all color-main bg-white'>
                            Xem Tất cả
                        </button>
                    </Link>
                </div>
                <div className='infrastructure container'>
                    <h2 className='mb-1 app-title color-main'>
                        Nền tảng kết nối dịch vụ kế toán
                    </h2>
                    <h2 className='mb-5 color-main-dark' style={{marginTop: -20}}>
                        Dành Cho Doanh Nghiệp Nhỏ
                    </h2>
                    <div className='row'>
                        <div className='col-md-6 map'>
                            <img src={profAvt} class="supervisor-avt"/>
                        </div>
                        <div className='col-md-6 infor'>
                            <div className='item-block d-flex align-items-center justify-content-start mb-3'>
                                <img src={iconAccountant} className='mr-3 img-medium'/>
                                <div className='text-left'>
                                    <div className='color-green'>Tìm kế toán dịch vụ</div>
                                    <div className=''>nhanh chóng và chuyên nghiệp</div>
                                </div>
                            </div>
                            <div className='item-block d-flex align-items-center justify-content-start mb-3'>
                                <img src={iconEz} className='mr-3 img-medium'/>
                                <div className='text-left'>
                                    <div className='color-green'>Dễ dàng hợp tác</div>
                                    <div className=''>với kế toán và dịch vụ</div>
                                </div>
                            </div>
                            <div className='item-block d-flex align-items-center justify-content-start mb-3'>
                                <img src={iconEz2} className='mr-3 img-medium'/>
                                <div className='text-left'>
                                    <div className='color-green'>Thuận tiện</div>
                                    <div className=''>
                                        trong công tác tài chính kế toán với phần mềm kế toán MISA
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={'/home/about-us'}>
                        <button className='btn readmore-ob'>
                            Tìm hiểu thêm về
                            <span className='color-main'>
                                {' '}
                                ONEBOOK <ChevronRight size={14}/>
                            </span>
                        </button>
                    </Link>

                </div>

                <div className='customer-rate container-fluid app-separator'>
                    <h2 className='mb-5 app-title color-main-dark'>
                        Khách hàng nói gì về ONEBOOK
                    </h2>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 '>
                                <div className='customer-rate-item'>
                                    <img src={daian} className='logo-cty'/>
                                    <div className='customer-company color-main'>
                                        CTY TNHH May và Xuất Nhập Khẩu Đại An
                                    </div>
                                    <div className='customer-desc'>
                                        <p className='danh-gia'>- Cách làm việc chuyên nghiệp, có tâm, có tầm</p>
                                        <p className='danh-gia'>- Tư vấn nhanh, chính xác</p>
                                        <p className='danh-gia'>- Luôn đặt quyền lợi khách hàng trên lợi ích kinh tế riêng.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='customer-rate-item'>
                                    <img src={onenet} className='logo-cty'/>
                                    <div className='customer-company color-main'>
                                        CTY CP Phần mềm trực tuyến và thương mai điện tử(OneNET., JSC)
                                    </div>
                                    <div className='customer-desc'>
                                        <p className='danh-gia'>- Xử lý cực nhanh, độ chính xác cao, không quản ngày đêm, đúng hẹn.</p>
                                        <p className='danh-gia'>- Luôn có giải pháp hợp tình, hợp lý trong mọi trường hợp.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='customer-rate-item'>
                                    <img src={vline} className='logo-cty'/>
                                    <div className='customer-company color-main'>
                                        Công Ty Cổ Phần Vline <br />Việt Nam
                                    </div>
                                    <div className='customer-desc'>
                                        <p className='danh-gia'>- Dịch vụ nhanh chóng, đầy đủ, chính xác, tận tâm với khách hàng.</p>
                                        <p className='danh-gia'>- Tư vấn giải pháp nhanh, chính xác cho mọi trường hợp phát sinh trong doanh nghiệp.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*{this.state.listNews && this.state.listNews.length > 0 &&*/}
                {/*<div className='hot-article app-separator container'>*/}
                {/*    <h2 className='mb-5 app-title color-main-dark'>Bài viết nổi bật</h2>*/}
                {/*    <div className='row'>*/}
                {/*        {this.state.listNews.map((item) => (*/}
                {/*            <div className='col-md-3 mb-3'>*/}
                {/*                <div className='card w-100 article-item'>*/}
                {/*                    <Link to={`/home/documents-detail?c=${item.categoryId}&i=${item.id}&t=${item.url}`}*/}
                {/*                          title={item.title}><img className='card-img-top article-avt'*/}
                {/*                                                  src={item.image}/></Link>*/}
                {/*                    <div className='card-body text-left article-infor'>*/}
                {/*                        <p className='article-time'>*/}
                {/*                            <ClockFill*/}
                {/*                                size={14}/> {moment(item.cmnCreatedAt).format("DD-MM-YYYY HH:mm")}*/}
                {/*                        </p>*/}
                {/*                        <p className='article-owner'>*/}
                {/*                            <Person size={14}/> {item.createByName}*/}
                {/*                        </p>*/}
                {/*                        <p className='article-content text-bold'>*/}
                {/*                            <Link to={`/home/documents-detail?c=${item.categoryId}&i=${item.id}&t=${item.url}`}*/}
                {/*                                  title={item.title}><span style={{color: "black"}}>{item.title}</span></Link>*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*        /!*<div className='col-md-3 mb-3'>*!/*/}
                {/*        /!*    <div className='card w-100'>*!/*/}
                {/*        /!*        <img className='card-img-top article-avt' src=''/>*!/*/}
                {/*        /!*        <div className='card-body text-left article-infor'>*!/*/}
                {/*        /!*            <p className='article-time'>*!/*/}
                {/*        /!*                <ClockFill size={14}/> 20 tháng 2, 2020*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-owner'>*!/*/}
                {/*        /!*                <Person size={14}/> Nguyễn Văn A*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-content text-bold'>*!/*/}
                {/*        /!*                Nộp quyết toán thuế qua mạng, không cần tới cơ quan thuế.*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*        </div>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*        /!*<div className='col-md-3 mb-3'>*!/*/}
                {/*        /!*    <div className='card w-100'>*!/*/}
                {/*        /!*        <img className='card-img-top article-avt' src=''/>*!/*/}
                {/*        /!*        <div className='card-body text-left article-infor'>*!/*/}
                {/*        /!*            <p className='article-time'>*!/*/}
                {/*        /!*                <ClockFill size={14}/> 20 tháng 2, 2020*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-owner'>*!/*/}
                {/*        /!*                <Person size={14}/> Nguyễn Văn A*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-content text-bold'>*!/*/}
                {/*        /!*                Nộp quyết toán thuế qua mạng, không cần tới cơ quan thuế.*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*        </div>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*        /!*<div className='col-md-3 mb-3'>*!/*/}
                {/*        /!*    <div className='card w-100'>*!/*/}
                {/*        /!*        <img className='card-img-top article-avt' src=''/>*!/*/}
                {/*        /!*        <div className='card-body text-left article-infor'>*!/*/}
                {/*        /!*            <p className='article-time'>*!/*/}
                {/*        /!*                <ClockFill size={14}/> 20 tháng 2, 2020*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-owner'>*!/*/}
                {/*        /!*                <Person size={14}/> Nguyễn Văn A*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-content text-bold'>*!/*/}
                {/*        /!*                Nộp quyết toán thuế qua mạng, không cần tới cơ quan thuế.*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*        </div>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*        /!*<div className='col-md-3 mb-3'>*!/*/}
                {/*        /!*    <div className='card w-100'>*!/*/}
                {/*        /!*        <img className='card-img-top article-avt' src=''/>*!/*/}
                {/*        /!*        <div className='card-body text-left article-infor'>*!/*/}
                {/*        /!*            <p className='article-time'>*!/*/}
                {/*        /!*                <ClockFill size={14}/> 20 tháng 2, 2020*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-owner'>*!/*/}
                {/*        /!*                <Person size={14}/> Nguyễn Văn A*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*            <p className='article-content text-bold'>*!/*/}
                {/*        /!*                Nộp quyết toán thuế qua mạng, không cần tới cơ quan thuế.*!/*/}
                {/*        /!*            </p>*!/*/}
                {/*        /!*        </div>*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {this.state.modalSignupMember && <ModalSignupMember show={this.state.modalSignupMember} onHide={() => {
                    this.setState({modalSignupMember: false})
                }}/>}
            </div>
        );
    }
}

export default HomePage;
