import React from 'react';
import './style.css';

class Thue extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='about-us-wrapper'>
          <div className='ab-content container'>
            <div className='row bg-white ab-block'>
              <div className='col-sm-12 text-part flex-wrap'>
                <div className='col-sm-1'>
                </div>
                <div className='col-sm-11'>
                  <div className='ab-content-title'>
                    Dịch vụ thuế
                  </div>
                  <div className='ab-content text-left'>
                    <p className='custom-text'>
                      Hệ thống nền tảng SAS cho phép các khách hàng sử dụng dịch vụ kế toán đăng ký các dịch vụ liên quan đến thuế
                      một cách dễ dàng như: Dịch vụ lập các bản kê khai thuế, ký và nộp các bản kê khai thuế cho tất cả các sắc thuế
                      cơ bản; Dịch vụ thanh toán tiền thuế phải nộp; Nhận các báo cáo phân tích và cảnh báo các vấn đề về thuế; Dịch
                      vụ soát soát tình hình tuân thủ các qui định về thuế; Dịch vụ lập kế hoạch thuế cho dự án, cho doanh nghiệp và
                      chiến lược tận dụng tối ưu các chính sách ưu đãi thuế;
                    </p>
                    <p>
                      <ul className='list-op black'>
                        <li className='op1'>"Các chuyên gia về thuế của chúng tôi luôn làm việc và phối hợp chặt chẽ với khách hàng và các
                          nhân viên chuyên nghiệp khác trên hệ thống nhằm cung cấp các giải pháp chủ động và gia tăng"</li>
                      </ul>
                    </p>
                    <p className='custom-text'>
                      Ngoài các dịch vụ cơ bản đã nêu, các chuyên gia của chúng tôi có thể cung cấp các dịch vụ tư vấn bổ sung như:
                      Tư vấn chung về thuế; Tư vấn tuân thủ hợp lý các quy định về chống chuyển giá; Hỗ trợ giải quyết, xử lý các câu
                      hỏi và yêu cầu của Cơ quan thuế; Đánh giá, điều tra và khiếu nại về thuế; Lập kế hoạch thuế thu nhập doanh
                      nghiệp và thuế thu nhập cá nhân; Hỗ trợ xin miễn giảm thuế & thủ tục theo Hiệp định tránh đánh thuế 2 lần;Hỗ trợ
                      thủ tục xin ưu đãi và miễn giảm thuế; Dịch vụ hải quan & thuế gián thu; Đào tạo thuế; Tư vấn quản trị rủi ro về thuế
                    </p>
                    <p>
                      <ul className='list-op red'>
                        <li className='op1'>“Chúng tôi đưa ra chiến lược hiệu quả khi lập kế hoạch thuế trong khi vẫn đảm bảo
                          tuân thủ hiệu quả các quy định của Nhà nước. Chúng tôi cung cấp đầy đủ các dịch vụ
                          thuế giúp khách hàng tuân thủ và tối ưu hóa lợi ích, giảm thiểu các rủi ro về thuế.”
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Thue;
