import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let SecureNewsCategory = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news-category/create`,
            method: 'POST',
            body: payload
        }, callback)
    },
    delete: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news-category/delete/${payload.id}`,
            method: 'GET',
        }, callback)
    },
    update: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/news-category/update/${payload.id}`,
            method: 'POST',
            body: payload.body
        }, callback)
    },

}
export default SecureNewsCategory
