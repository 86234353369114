import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import {stringify} from 'qs';

let PublicReportContract = {
    getByContractId: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/get-by-contract-id?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    partnerConfirmReportData: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/partner-confirm-report-data?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    searchByContractIdAndDateAndStatus: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/search?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    searchByContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/search-of-contract?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    supervisorConfirm: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/supervisor-confirm/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    supervisorReject: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/secure/report-contract/reject-report-contract`,
            method: 'POST',
            body: payload.body
        }, callback)
    },
}
export default PublicReportContract
