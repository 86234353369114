import React, {Component} from "react";
import moment from 'moment';
import '../../../assets/css/Supervisor.css';
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../../components/Sidebar";

import PartnerApi from "../../../services/ob-user/partner";
import {toast} from "react-toastify";
import Convert from "../../../utils/convertUrlPra";
import {Link} from "react-router-dom";
import {URL_IMG_PUB} from "../../../url.config";

const formatDate = 'DD-MM-YYYY';

// const renderBusiness = function (business) {
//     switch (business) {
//         case 'COMMERCE_SERVICE':
//             return 'Thương mại - Dịch vụ';
//         case 'MANUFACTURING_MACHINING':
//             return 'Sản xuất - Gia công';
//         default:
//             return 'Xây dựng - Lắp đặt - Đầu tư bất động sản';
//     }
// };

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

function formatMoney(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const scale = [
    {
        value: "MEDIUM",
        name: "Vừa",
    },
    {
        value: "EXTRASMALL",
        name: "Siêu nhỏ",
    },
    {
        value: "SMALL",
        name: "Nhỏ",
    },

]

class PartnerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerId: '',
            dataPartner: [],
            listProvince: [],
            listDistrict: [],
            files: [],
            filesCertificate: [],
            fileId: null,
            showLoading: false,
            taxcode: '',
            phone: '',
            email: '',
            provinceId: '',
            districtId: '',
            address: '',
            website: '',

            representativeName: '',
            representativeSex: '',
            identify: '',
            dateIssue: '',
            placeIssue: '',
            position: '',

            loading: false,
            progress: 0,
            confirmCancel: false,

        }
    }

    componentWillMount() {
        let params = Convert.urlParams(this.props.location.search);
        this.setState({partnerId: params.partnerId}, () => {
            this.setState({showLoading: true}, () => {
                // this.getPartnerInfo();
                this.getPartnerInfo();
            })
        })
    }

    getPartnerInfo() {
        let payload = {
            id: this.state.partnerId
        };
        PartnerApi.getDetail(payload, res => {
            if (res.success) {
                this.setState({
                        dataPartner: res.data,
                        taxcode: res.data.tax,
                        phone: res.data.phone,
                        email: res.data.email,
                        provinceId: res.data.provinceId,
                        districtId: res.data.districtId,
                        address: res.data.address,
                        website: res.data.website,
                        urlImage: res.data.urlImage,
                        representativeName: res.data.representative,
                        representativeSex: res.data.sex,
                        identify: res.data.identify,
                        dateIssue: res.data.dateIssued,
                        placeIssue: res.data.placeIssued,
                        position: res.data.position,
                        showLoading: false,
                    }
                )
            } else {
                toast.error("Đã có lỗi xảy ra trong quá trình lấy dữ liệu, vui lòng tải lại trang!")
            }
        })
    };

    componentDidMount() {
        // this.setState({
        //     onEdit: false,
        //     inEditing: false,
        // });
        document.title = 'Thông tin cá nhân - OneBook';
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderDistrict = (provinceId) => {
        let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : '';
        return _district?.filter(item => item.provinceId === provinceId).map((item, index) => <option
            value={item.id}>{item.name}</option>)
    };

    render() {
        let userType = localStorage.getItem("ob_cl_userType");
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        const {files, address, identify, email, business, scale, revenue, representative, position, representativePhone, dataPartner, tax, placeIssued, dateIssued, sex, provinceId, districtId} = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="partner-management" parentClass="employee-management"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }

                            <div variant="outlined" className='mb-4 pb-2 card border-color'>
                                <span className='m-2'>
                                    <Link to={`/${userType.toLowerCase()}/partner-management`}>
                                        <i className="ti-arrow-left"/>&nbsp;
                                        Quay lại
                                    </Link>
                                </span>
                                {/*//**********************************************Thong tin doanh nghiep****************************************************************************************************/}
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin doanh nghiệp</span>
                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='justify-center img-logo-wrapper' style={{
                                        textAlign: 'center',
                                        backgroundImage: dataPartner.urlImage ? `url(${dataPartner.urlImage}` : `url("/images/no-avatar-png.png")`
                                    }}>
                                    </div>
                                </div>
                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tên doanh nghiệp</span>
                                        </div>
                                        <div
                                            className="pl-0 col-md-4 col-lg-4 col-sm-8 font-weight-bold kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner?.fullName?.toUpperCase()}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Mã số thuế</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.tax}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                                <span className="text-black-50">
                                                    Số điện thoại
                                                </span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.phone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.email}
                                        </div>


                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Tỉnh/Thành phố</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.provinceId ? renderProvinceId(dataPartner.provinceId) : ''}
                                        </div>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Quận/Huyện</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.districtId ? renderDistrictId(dataPartner.districtId) : ''}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Địa chỉ</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.address ? dataPartner.address : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Website</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.website}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/********************************Thong tin ca nhan****************************************************/}
                            <div variant="outlined" className='mt-3 pb-3 card border-color'>
                                <div className='d-flex col-12 mt-1 kt-margin-b-20 card-header'>
                                    <span className='text-header-profile'>Thông tin cá nhân</span>
                                </div>

                                <div className="mt-4 font-14 ml-4 mr-4">
                                    <div className='row kt-margin-b-20'>
                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Họ và tên</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.representative ? dataPartner.representative.toUpperCase() : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Giới tính</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.sex ? dataPartner.sex == 'MALE' ? 'Nam' : 'Nữ' : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">CMND/CCCD {this.state.onEdit &&
                                            <span className={'color-red d-inline'}>*</span>}</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.identify ? dataPartner.identify : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Ngày cấp</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.dateIssued ? moment(dataPartner.dateIssued).format("DD-MM-YYYY") : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Nơi cấp</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.placeIssued ? dataPartner.placeIssued : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Chức vụ</span>
                                        </div>
                                        <div
                                            className="pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile">
                                            {dataPartner.position ? dataPartner.position : ''
                                            }
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4 kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">
                                                Số điện thoại
                                            </span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-2 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {dataPartner.representativePhone}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 pr-0 col-md-2 col-lg-2 col-sm-4  kt-margin-b-10-tablet-and-mobile">
                                            <span className="text-black-50">Email</span>
                                        </div>
                                        <div
                                            className={this.state.onEdit ? "pl-0 pb-2 col-md-4 col-lg-4 col-sm-8 kt-margin-b-10-tablet-and-mobile" : "pl-0 pb-2 col-md-3 col-lg-3 col-sm-8 kt-margin-b-10-tablet-and-mobile"}>
                                            {dataPartner?.representativeEmail}
                                        </div>

                                        <div
                                            className="pl-0 pb-2 col-md-6 col-lg-6 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PartnerDetail;
