import React from "react";
import Utils from "../../../utils/utils";
import Pagination from "../../../components/Pagination";
import CommentApi from "../../../services/ob-core/comment";

class CommentInDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cmtPageNumber: 0,
            cmtPageSize: 10,
            totalCmt: 0,
            listCmt: [],

        }
    }


    componentDidMount() {
        this.getListComment()
    }

    getListComment = () => {
        let self = this;
        let payload = {
            contractId: this.props.contractId,
            pageNumber: this.state.cmtPageNumber,
            pageSize: this.state.cmtPageSize
        }
        CommentApi.getComment(payload, res => {
            if (res.success) {
                console.log("ppppppppppppppp>>>>>>>>>>>>>", res);
                self.setState({listCmt: res.data.content, totalCmt: res.data.totalElements})
            } else {
                console.log(">>>>>>>>>>>>>>>>>no hope", res);
            }
        });
    }


    sendComment = () => {
        let self = this;
        let payload = {
            contractId: this.props.contractId,
            fullName: window.OBPubConfig.userInfo.fullName,
            message: this.state.mess,
        }
        CommentApi.sendComment(payload, res => {
            if (res.success) {
                console.log("ppppppppppppppptttt>>>>>>>>>>>>>", res);
                this.setState({mess: ""}, () => {
                    this.getListComment()
                })

            } else {
                console.log(">>>>>>>>>>>>>>>>>no hope", res);
            }
        });
    }

    render() {
        let {listCmt, totalCmt} = this.state
        return (
            <div>
                <div className="row" style={{fontSize: 20}}>
                    <div className="col-8 pl-0 " style={{fontSize: 20}}>
                        <textarea name="" id="" cols="30" rows="3"
                                  maxLength="255"
                                  className="form-control"
                                  value={this.state.mess}
                                  onChange={e => this.setState({mess: e.target.value})}/>
                    </div>
                    <div className="d-flex justify-content-start">
                        <button
                            className="border-1-s type-button-fix btn btn-primary btn-sm shadow-none"
                            variant="contained"
                            onClick={() => {
                                this.sendComment()
                                // let {listmessage, mess} = this.state;
                                // listmessage.unshift({
                                //     name: "Thạch",
                                //     mess: mess
                                // });
                                // this.setState({listmessage, mess: ""});
                            }}
                            style={{height: 37, marginLeft: 10}}
                        >
                            Bình luận
                        </button>
                        <button
                            className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                            onClick={() => this.getListComment()}
                            // onClick={this.handleDownload}
                            style={{height: 37, marginLeft: 10}}
                            variant="contained"
                        >
                            Refresh
                        </button>
                    </div>
                </div>
                {listCmt.length > 0 ?
                    <div className="col-10 mt-3">
                        {/*<div className="d-flex ml-1 mt-1 justify-content-start">*/}
                        {listCmt.map(obj => (
                            <div className="comment-line">
                                <div>
                                    <b><span className="m-1 font-weight-bold">{obj?.fullName}</span></b> (
                                    <span className="m-1 font-weight-lighter">{obj?.userType}</span>) -
                                    <i><span
                                        className="m-1 font-weight-light">{Utils.convertTime(obj?.cmnCreatedAt)}</span></i>
                                </div>
                                <div>
                                    <span className="m-1">{obj.message}</span>
                                </div>
                            </div>
                        ))}
                        {/*</div>*/}
                        <div className="pagination-left">
                            <Pagination
                                activePage={this.state.cmtPageNumber + 1}
                                itemsCountPerPage={this.state.cmtPageSize}
                                totalItemsCount={this.state.totalCmt}
                                changeHandler={(page) => {
                                    // this.handleChangePage(page)
                                    this.setState({cmtPageNumber: page - 1,},
                                        () => {
                                            this.getListComment();
                                        }
                                    );
                                }}
                            />
                        </div>

                    </div> : <div className="align-center mt-4 col-6">
                        <span><i>Không có bình luận!</i></span>
                    </div>}
            </div>
        )
    }

}

export default CommentInDetail
