import React, {Component} from 'react';
import {withSnackbar} from "notistack";
import './index.css'

import ContractApi from '../../../services/ob-core/public-contract';
import {Modal} from "react-bootstrap";

class ModalAccept extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalAccept: true,
            idSelect: this.props.itemId,
            idContractSelect: this.props.contractId ? this.props.contractId : '',
        }
    }

    acceptContract = () => {
        let {
            idSelect
        } = this.state
        let payload = {
            id: idSelect
        }
        ContractApi.accept(payload, res => {
            if (res.success) {
                this.props.enqueueSnackbar('Hợp đồng đã được chấp nhận', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'success',
                });
                this.setState({
                    showModalAccept: false,
                })
                this.props.onRefresh()
            } else {
                this.props.enqueueSnackbar('Đã có lỗi xảy ra, vui lòng thử lại sau !', {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    variant: 'error',
                });
            }
        })
    }

    componentWillMount() {
        console.log(this.props.contractId)
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>

                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÔNG BÁO</b></h1>
                        </div>

                        <div className='d-flex justify-content-center mt-3 mb-3'>
                            <span className='font-size-content'> Bạn có chắc chắn nhận hợp đồng {this.state.idContractSelect} không?</span>
                        </div>

                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                    onClick={() => this.acceptContract()}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Đồng ý
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-secondary"
                                    onClick={this.props.onHide}
                                >
                                    <span className='d-flex justify-content-center'>
                                        Hủy bỏ
                                    </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withSnackbar(ModalAccept);
