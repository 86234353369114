/**
 *
 * User: Hao
 * Update: 22/07/2021
 * Time: 9:00
 */

import React, { Component } from "react";
import moment from "moment";
import '../info.css';

import NumberFormat from "react-number-format";
import TableHeader from "../../../../components/TableHeader";
import PublicContractApi from "../../../../services/ob-core/public-contract";
import SupervisorApi from "../../../../services/ob-user/supervisor";
import AccountantApi from "../../../../services/ob-user/accountant";
import { toast } from "react-toastify";
import { checkPermission } from "../../../../utils/common";
import ModalAddJob from "../../../../components/Modal/create-contract-modal/modalAddJob";
import ModalAddSubContact from "../../../../components/Modal/create-contract-modal/modalAddSubContract";
import ModalAddPayment from "../../../../components/Modal/create-contract-modal/modalAddPayment";
import ModalCancelPayment from "../../../../components/Modal/modalCancelPayment";
import ModalCancelJob from "../../../../components/Modal/modalCancelJob";
import DatePicker from "react-datepicker";
import ModalRejectOrder from "../../../../components/Modal/modalRejectOrder";

const formatDate = "DD-MM-YYYY";

class SubContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: false,
            showUpdatePayment: false,
            itemSelected: {},
            needPayment: null,
            selectPayment: null,
            code: "",
            status: "",
            mess: "",
            value: "",
            type: "",
            paymentStatus: "",
            supervisorId: "",
            paymentCycle: "",
            servicePlanId: "",
            customerId: "",
            startDate: "",
            accountantId: "",
            paid: "",
            unpaid: "",
            customerName: "",
            servicePlanName: "",
            supervisorName: '',
            id: null,
            contractId: null,
            listAccId: [],
            data: [],
            steps: [],
            steps2: [],
            dataFile: [],
            paymentDetail: [],
            modalUploadFile: false,
            paymentTimelines: [],
            listContractSub: [],
            jobsList: [],
            jobsListErr: false,
            paymentTableErr: false,
            jobTotalMoney: 0,
            payTotalMoney: 0,
            dealPrice: 0,
            headerJob: [
                {
                    id: 'index',
                    name: "STT",
                    style: { width: 60, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên Job",
                    style: { minWidth: 240, textAlign: "left" },
                    sort: false,
                },
                {
                    id: 'numberOfReports',
                    name: "Số lượng báo cáo",
                    style: { minWidth: 140, textAlign: "left" },
                    sort: false,
                },
                {
                    id: 'implementer',
                    name: "Người thực hiện",
                    style: { minWidth: 160, textAlign: "right" },
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: { minWidth: 100, textAlign: "right" },
                    sort: false,
                },
                {
                    id: 'receiveMoney',
                    name: "Số tiền",
                    style: { minWidth: 160, textAlign: "right" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 20, textAlign: "center" },
                    sort: false,
                }
            ],
            headerContract: [
                {
                    id: '123',
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Tên",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'content',
                    name: "Nội dung",
                    style: { minWidth: 120, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'price',
                    name: "Giá trị",
                    style: { minWidth: 30, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'startDate',
                    name: "Bắt đầu",
                    style: { minWidth: 30, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'endDate',
                    name: "Kết thúc",
                    style: { minWidth: 30, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 20, textAlign: "center" },
                    sort: false,
                }
            ],
            headerPayment: [
                {
                    id: '123',
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Hạn thanh toán",
                    style: { minWidth: 60, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Số tiền thanh toán",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                }
            ],
        };
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng";
        this.getDetailContractFromAPI();
        this.getListContractSub();
    }
    getDetailContractFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.value
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                let listAccId = [];
                let dataTemp = res.data.jobs
                dataTemp.map(item => {
                    listAccId.push(item.assignerId);
                });
                console.log(res.data);
                this.setState({
                    data: res.data,
                    paymentTimelines: res.data.paymentTimelines,
                    jobsList: res.data.jobs,
                    dataFile: res.data.contractDocuments,
                    supervisorId: res.data.supervisorId,
                    contractCode: res.data.code,
                    listAccId
                }, () => {
                    if (res.data.supervisorId) {
                        this.getNameSupervisor()
                    }
                    if (dataTemp.length > 0) {
                        this.getListAccountantByIds()
                    }
                });
            } else {
                toast.error('Đã xảy ra lỗi. Vui lòng tải lại trang !')
            }
        });
    }

    getNameSupervisor() {
        let { supervisorId } = this.state;
        let ids = [];
        ids.push(supervisorId);
        if (supervisorId !== '') {
            let payload = {
                ids: ids
            };
            SupervisorApi.getNamesByListId(payload, res => {
                if (res.success && res.data && res.data.length !== 0) {
                    this.setState({
                        supervisorName: res.data[0].fullName
                    })
                }
            })
        }
    }
    getListAccountantByIds = () => {
        let { jobsList, listAccId } = this.state;
        let payload = {
            ids: listAccId
        };

        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let dataTemp = res.data;
                jobsList.map(item => {
                    let filter = dataTemp.filter(x => x.id == item.assignerId);
                    item.assignerId = filter[0]?.fullName;
                });
                this.setState({
                    jobsList
                });
            } else {

            }
        });
    }

    //******************************************************************************************************************
    renderStatusSteper(data) {
        switch (data.assignStatus) {
            case "CANCELED":
                return null;
            case "WAIT_SIGN":
                return 0;
            case "SIGNED":
                return 1;
            case "NO_ASSIGN":
                return 2;
            case "WAITING_FOR_CONFIRMATION":
                return 3;
            case "REJECTED":
                return 4;
            case "PROCESSING":
                return 4;
            case "LIQUIDATED":
                return 5;
            case "CLOSED":
                return 6;
            default:
                return;
        }
    }

    renderStatus(assignStatus) {
        switch (assignStatus) {
            case "WAIT_SIGN":
                return "Chờ ký";
            case "SIGNED":
                return "Đã ký";
            case "PROCESSING":
                return "Kế toán xác nhận";
            case "NO_ASSIGN":
                return "Chưa assign";
            case "WAITING_FOR_CONFIRMATION":
                return "Chờ kế toán xác nhận";
            case "LIQUIDATED":
                return 'Thanh lý';
            case "CANCELED":
                return "Đã hủy;"
            case "CLOSED":
                return "Đã đóng";
            default:
                return;
        }
    }

    renderStatusJob = (status) => {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-dark">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            case "CANCEL":
                return <h5><span className="badge badge-danger">Đã hủy</span></h5>;
            default:
                return '';
        }
    }


    renderPaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return " Đã thanh toán";
            case "UNPAID":
                return "Chưa thanh toán";
            case "PAYMENT_TERM":
                return "Đến hạn thanh toán";
            case "PARTIALLY_PAID":
                return "Thanh toán";

            default:
                return;
        }
    }

    renderActiveStep(paymentTimelines) {
        return paymentTimelines.filter(value => value.paymentStatus === "PAID").length
    }

    handleUpdatePayment(index, paymentTimelines, row) {
        let need = paymentTimelines.filter(value => value.paymentStatus === "PAID").length + 1;
        if (index + 1 >= need) {
            this.setState({
                needPayment: need,
                selectPayment: index,
                itemSelected: row.id,
                paymentDetail: row,
                paymentTimelines: paymentTimelines,
                showUpdatePayment: true
            })
        }
    }
    addPayment = (date, sendMoney) => {
        let { paymentTimelines, payTotalMoney, dealPrice } = this.state
        let _pay;
        let payTotalMoneyNew = payTotalMoney + parseInt(sendMoney.replace(/,/g, ''));
        _pay = {
            id: -1,
            paymentTerm: date,
            paymentAmount: parseInt(sendMoney.replace(/,/g, ''))
        }
        paymentTimelines.push(_pay)
        this.setState({ paymentTimelines, modalPayment: false, paymentTableErr: false, payTotalMoney: payTotalMoneyNew, payValueErr: payTotalMoneyNew != dealPrice })
        if (payTotalMoneyNew != dealPrice) {
            toast.warn("Chú ý! Tổng số tiền thanh toán phải bằng giá trị hợp đồng!")
        }else {
            toast.success("Tổng tiền thanh toán đã bằng giá trị hợp đồng!")
        }
    }

    removePayment = (index) => {
        let { paymentTimelines, payTotalMoney, contractPrice, data } = this.state
        let payTotalMoneyNew = payTotalMoney - paymentTimelines[index].paymentAmount
        if (payTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng số tiền thanh toán phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền thanh toán đã bằng giá trị hợp đồng!")
        }

        paymentTimelines.splice(index, 1);

        this.setState({ paymentTimelines, payTotalMoney: payTotalMoneyNew, payValueErr: payTotalMoneyNew != contractPrice })
    }
    onCancelPayment = () =>{
        this.getDetailContractFromAPI();
    }
    onCancelJob = () =>{
        this.getDetailContractFromAPI();
    }
    addJob = (name, numberOfReports, receiveMoney) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney + parseInt(receiveMoney.replace(/,/g, ''))
        jobsList.push({
            id: -1,
            name: name,
            status: "UNFULFILLED",
            countDone: 0,
            numberOfReports: numberOfReports,
            receiveMoney: parseInt(receiveMoney.replace(/,/g, '')),
        })
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        this.setState({ jobsList, modalAddJob: false, jobsListErr: false, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    addSubContract = (name, content, paid, startDate, endDate) => {
        let { listContractSub, dealPrice} = this.state;
        let priceContract = parseInt(paid.replace(/,/g, ''));
        listContractSub.push({
            id  : -1,
            contractId  : this.props.value,
            name        : name,
            content     : content,
            paid        : priceContract,
            startDate   : startDate,
            endDate     : endDate
        });
        dealPrice += priceContract
        this.setState({listContractSub, dealPrice})
    }
    deleteContract = (index) => {
        let { listContractSub, dealPrice, payTotalMoney, jobTotalMoney} = this.state
        let priceNew = dealPrice - listContractSub[index].paid
        if (payTotalMoney != priceNew || jobTotalMoney != priceNew)
            toast.warn("Chú ý! Tổng số tiền thanh toán và tiền Jobs phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền thanh toán và tiền Jobs đã bằng giá trị hợp đồng!")
        }
        listContractSub.splice(index, 1);
        this.setState({ listContractSub, dealPrice: priceNew})
    }
    getListContractSub() {
        let contractInfo = {
            contractId: this.props.value
        };
        PublicContractApi.getListContractSub(contractInfo, res => {
            if (res.success) {
                this.setState({listContractSub: res.data.content})
            } else {
                toast.error('Đã xảy ra lỗi. Vui lòng tải lại trang !')
            }
        });
    }
    changeJobValue = (data, index) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney - jobsList[index].receiveMoney + parseInt(data.target.value.replace(/,/g, ''))
        jobsList[index].receiveMoney = parseInt(data.target.value.replace(/,/g, ''));
        // console.log("check2222>>>>>>>>", jobTotalMoney, jobTotalMoneyNew, contractPrice < jobTotalMoneyNew);
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        this.setState({ jobsList, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    deleteJob = (index) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney - jobsList[index].receiveMoney
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        jobsList.splice(index, 1);

        this.setState({ jobsList, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    addJobs = () => {
        let { data, contractCode, jobsList} = this.state;
        let payload = {
            code: contractCode,
            supervisorId: data.supervisorId * 1,
            contractId  : this.props.value,
            jobs:  jobsList.filter(item => item.id < 0),
        };
        PublicContractApi.createJobs(payload, res => {
            if (res.success) {
                toast.success("Tạo Jobs thành công !");
                this.getDetailContractFromAPI();
                this.props.history.push("/partner/contracts-management");
            } else if (res.errorCode === "One-Book-Core.E000024") {
                toast.error("Mã hợp đồng " + payload.code + " đã tồn tại")
            }
            else {
                return toast.error("Tạo Jobs không thành công, vui lòng thử lại !")
            }
        })
    }

    addPayments = () => {
        let { data, contractCode, paymentTimelines} = this.state;
        let payload = {
            code: contractCode,
            supervisorId: data.supervisorId * 1,
            contractId  : this.props.value*1,
            paymentTimelines: paymentTimelines.filter(item => item.id < 0),
        };
        PublicContractApi.createPayments(payload, res => {
            if (res.success) {
                toast.success("Tạo thanh toán thành công !");
                this.getDetailContractFromAPI();
                this.props.history.push("/partner/contracts-management")
            } else if (res.errorCode === "One-Book-Core.E000024") {
                toast.error("Mã hợp đồng " + payload.code + " đã tồn tại")
            }
            else {
                return toast.error("Tạo thanh toán không thành công, vui lòng thử lại !")
            }
        })
    }
    addSubContracts = () => {
        let {listContractSub} = this.state;
        let payload = {
            listContractSub : listContractSub.filter(item => item.id < 0),
        };
        PublicContractApi.createContractSub(payload, res => {
            if (res.success) {
                toast.success("Tạo hợp đồng thành công !");
                this.getListContractSub();
                this.getDetailContractFromAPI();
                this.props.history.push("/partner/contracts-management")
            } else if (res.errorCode === "One-Book-Core.E000024") {
                toast.error("Mã hợp đồng " + payload.code + " đã tồn tại")
            }
            else {
                return toast.error("Tạo không thành công, vui lòng thử lại !")
            }
        })
    }

    render() {
        let {
            data,
            paymentTimelines,
            jobsList
        } = this.state;
        return (
            <div style={{ padding: 0 }}>
                {checkPermission("view_detail_contract") &&
                <div style={{ fontSize: 20 }}>
                    <span>THÔNG TIN CHI TIẾT HỢP ĐỒNG </span>
                </div>}
                {checkPermission("view_detail_contract") &&
                <div style={{ fontSize: 15, padding: 15 }}>
                    <div className="row">
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Tên khách hàng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{ fontWeight: "bold" }}>
                                {data.customerName}
                            </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Gói dịch vụ</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{ fontWeight: "bold" }}>{data.servicePlanName}</span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Giá trị hợp đồng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <NumberFormat
                                style={{ fontWeight: "bold" }}
                                value={data.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={" VND"}
                            />
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Giá trị hợp đồng phụ</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <NumberFormat
                                style={{ fontWeight: "bold" }}
                                value={data.subPaid}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={" VND"}
                            />
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Ngày bắt đầu</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{ fontWeight: "bold" }}>
                                {moment(data.startDate).format(formatDate)}
                            </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Kế toán thực hiện</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{ fontWeight: "bold" }}>
                                {data.accountantName}
                            </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black-50">Người phụ trách</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <span style={{ fontWeight: "bold" }}>
                                {this.state.supervisorName}
                            </span>
                        </div>
                    </div>
                </div>}
                {checkPermission("view_payment_contract") &&
                <div>
                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                        <div className='col-8'>
                            <span className='text-header-profile'>THÔNG TIN HỢP ĐỒNG PHỤ</span>
                        </div>
                        <div className='col-4'>
                            <div className='d-flex justify-content-end'>
                                <button className="btn btn-primary" onClick={() => this.setState({ modalAddSubContract: true })}>
                                    Thêm mới
                                </button>
                                <button className="btn btn-success" style={{marginLeft: 20}} onClick={() => this.addSubContracts()}>
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <table className={this.state.paymentTableErr ? "table border-input-error mt-2 mb-5 table-striped table-data table-bordered " : "table mt-2 mb-5 table-striped table-data table-bordered "}>
                            <thead>
                            <TableHeader header={this.state.headerContract} clickSort={(data) => {
                            }} />
                            </thead>
                            <tbody>
                            {this.state.listContractSub.length !== 0
                                ? this.state.listContractSub.map((item, index) =>
                                    <tr>
                                        <th scope="row" className='text-center'>{index + 1}</th>
                                        <th scope="row" className='text-center'>{item.name}</th>
                                        <th scope="row" className='text-center'>{item.content}</th>
                                        <td className='text-center pr-3'>
                                            <NumberFormat
                                                style={{ fontWeight: "bold" }}
                                                value={item.paid}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        <td className='text-center'>
                                            {moment(item.startDate).format(formatDate)}

                                        </td>
                                        <td className='text-center'>
                                            {moment(item.endDate).format(formatDate)}
                                        </td>

                                        {item.id < 0 ?
                                        <td className='text-center'>
                                            <button className="btn btn-action"
                                                    title='Xóa thông tin hợp đồng'
                                                    onClick={() => {
                                                        this.deleteContract(index)
                                                    }}
                                                    style={{cursor: 'pointer'}}>
                                                <i className="ti-trash"/>
                                            </button>
                                        </td>
                                            :
                                            <td className='text-center'>
                                                <h5><span className="badge badge-success">Đã lưu</span></h5>
                                            </td>
                                        }
                                    </tr>
                                )

                                : <tr>
                                    <td colSpan={4}><div><span><h6>Chưa có thông tin thanh toán</h6></span></div></td>
                                </tr>

                            }
                            </tbody>
                        </table>
                        {this.state.paymentTableErr ?
                            <div className="row d-flex  align-items-center justify-content-center">
                                <label className="col-12 font-err mb-0">Vui lòng thêm thông tin thanh toán !</label>
                            </div> : <div />}
                    </div>
                </div>}
                {checkPermission("view_payment_contract") &&
                <div>
                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                        <div className='col-8'>
                            <span className='text-header-profile'>THÔNG TIN THANH TOÁN</span>
                        </div>
                        <div className='col-4'>
                            <div className='d-flex justify-content-end'>
                                <button className="btn btn-primary" onClick={() => this.setState({ modalPayment: true })}>
                                    Thêm mới
                                </button>
                                <button className="btn btn-success" style={{marginLeft: 20}} onClick={() => this.addPayments()}>
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <table className={this.state.paymentTableErr ? "table border-input-error mt-2 mb-5 table-striped table-data table-bordered " : "table mt-2 mb-5 table-striped table-data table-bordered "}>
                            <thead>
                            <TableHeader header={this.state.headerPayment} clickSort={(data) => {
                            }} />
                            </thead>
                            <tbody>
                            {this.state.paymentTimelines.length !== 0
                                ? this.state.paymentTimelines.map((item, index) =>
                                    <tr>
                                        <th scope="row" className='text-center'>{index + 1}</th>
                                        <td className='text-center'>
                                            {moment(item.paymentTerm).format(formatDate)}
                                        </td>
                                        <td className='text-center pr-3'>
                                            <NumberFormat
                                                style={{ fontWeight: "bold" }}
                                                value={item.paymentAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>

                                        {item.id < 0 ?
                                            <td className='text-center'>
                                                <button className="btn btn-action"
                                                        title='Xóa hạn thanh toán'
                                                        onClick={() => {
                                                            this.removePayment(index)
                                                        }}
                                                        style={{cursor: 'pointer'}}>
                                                    <i className="ti-trash"/>
                                                </button>
                                            </td>
                                            :
                                            item.paymentStatus == "UNPAID" ?
                                                <td className='text-center'>
                                                    <button className="btn btn-action"
                                                            title='Hủy hạn thanh toán'
                                                            onClick={() => {
                                                                this.setState({ modalCancelPayment: true, selectedPayment: paymentTimelines[index] })
                                                            }}
                                                            style={{cursor: 'pointer'}}>
                                                        <i className="ti-close"/>
                                                    </button>
                                                </td>
                                                :
                                                item.paymentStatus == "CANCEL" ?
                                                    <td className='text-center'>
                                                        <h5><span className="badge badge-danger">Đã hủy</span></h5>
                                                    </td>
                                                    :
                                                    <td className='text-center'>
                                                        <h5><span className="badge badge-success">Đã thanh toán</span></h5>
                                                    </td>
                                        }
                                    </tr>
                                )

                                : <tr>
                                    <td colSpan={12}><div><span><h6>Chưa có thông tin thanh toán</h6></span></div></td>
                                </tr>

                            }
                            </tbody>
                        </table>
                        {this.state.paymentTableErr ?
                            <div className="row d-flex  align-items-center justify-content-center">
                                <label className="col-12 font-err mb-0">Vui lòng thêm thông tin thanh toán !</label>
                            </div> : <div />}
                    </div>
                </div>}
                {checkPermission("view_job") &&
                <div className="row">
                    <div className=" d-flex col-12 mb-2 ct-contract-data" style={{marginTop: 8}}>
                        <div className='col-8'>
                            <span className='text-header-profile'>THÔNG TIN JOB</span>
                        </div>
                        <div className='col-4'>
                            <div className='d-flex justify-content-end'>
                                <button className="btn btn-primary" onClick={() => {
                                    this.setState({ modalAddJob: true })
                                }}>
                                    Thêm mới
                                </button>
                                <button className="btn btn-success" style={{marginLeft: 20}} onClick={() => this.addJobs()}>
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12' style={{ overflow: 'auto' }}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerJob} clickSort={(data) => {
                            }} />
                            </thead>
                            {!this.state.jobsList || this.state.jobsList.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.jobsList.map((row, index) => (
                                    <tr key={row.id} style={{ height: 50 }}>
                                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ textAlign: 'left' }}>{row.name}</td>
                                        <td style={{ textAlign: 'center' }}>{row.countDone}/{row.numberOfReports}</td>
                                        <td style={{ textAlign: 'left' }}>{row.assignerId}</td>
                                        <td style={{ textAlign: 'center' }}>{this.renderStatusJob(row.status)}</td>
                                        <td style={{ textAlign: 'right' }}>
                                            <NumberFormat
                                                value={row.receiveMoney}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                        {row.id < 0 ?
                                            <td className='text-center'>
                                                <button className="btn btn-action"
                                                        title='Xóa Job'
                                                        onClick={() => {
                                                            this.deleteJob(index)
                                                        }}
                                                        style={{cursor: 'pointer'}}>
                                                    <i className="ti-trash"/>
                                                </button>
                                            </td>
                                            :
                                            row.status == "UNFULFILLED" ?
                                                <td className='text-center'>
                                                    <button className="btn btn-action"
                                                            title='Xóa Job'
                                                            onClick={() => {
                                                                this.setState({ modalCancelJob: true, selectedJob: jobsList[index] })
                                                            }}
                                                            style={{cursor: 'pointer'}}>
                                                        <i className="ti-close"/>
                                                    </button>
                                                </td>
                                                :
                                                <td style={{ textAlign: 'center' }}></td>
                                        }
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>

                </div>}
                {this.state.modalAddSubContract && <ModalAddSubContact addSubContract={this.addSubContract} onHide={() => { this.setState({ modalAddSubContract: false }) }} />}
                {this.state.modalAddJob && <ModalAddJob addJob={this.addJob} onHide={() => { this.setState({ modalAddJob: false }) }} />}
                {this.state.modalPayment && <ModalAddPayment addPayment={this.addPayment} onHide={() => { this.setState({ modalPayment: false }) }} />}
                {this.state.modalCancelPayment && <ModalCancelPayment selectedOrder={this.state.selectedPayment}
                                                                  onRefresh={this.onCancelPayment}
                                                                  onHide={() => this.setState({modalCancelJob: false})}/>}
                {this.state.modalCancelJob && <ModalCancelJob selectedOrder={this.state.selectedJob}
                                                                  onRefresh={this.onCancelJob}
                                                                  onHide={() => this.setState({modalCancelJob: false})}/>}

            </div>
        );
    }
}

export default SubContract
