import React from 'react';
import {withSnackbar, SnackbarProvider} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";
import Utils from '../../utils/utils'
import PublicReportContractApi from "../../services/ob-core/public-report-contract";


import {
    Modal,
} from 'react-bootstrap';
import {toast} from "react-toastify";

class ModalRejectReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailId: '',
            reasonReject: '',
            listData: {}

        };
    }

    componentDidMount() {
        this.setState({
            reasonReject: ''
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                reasonReject: '', detailId: this.props.data?.id
            });
        }
    }

    reject = () => {
        if (this.state.reasonReject === '') {
            toast.warn("Vui lòng nhập lý do từ chối báo cáo")
        } else {
            let payload = {
                // id: this.props.id,
                body: {
                    id: this.props.id,
                    rejectReason: this.state.reasonReject
                }
            };
            PublicReportContractApi.supervisorReject(payload, res => {
                if (res.success) {
                    toast.success("Đã từ chối thành công!")
                    this.props.onRefresh();
                    this.props.onHide();
                } else {
                    toast.error("Đã có lỗi xảy ra!")
                }
            })
        }
    }

    partnerReject = () => {
        if (this.state.reasonReject === '') {
            toast.warn("Vui lòng nhập lý do từ chối báo cáo")
        } else {
            let payload = {
                body: {
                    id: this.props.id,
                    rejectReason: this.state.reasonReject
                }
            };
            PublicReportContractApi.supervisorReject(payload, res => {
                if (res.success) {
                    toast.success("Đã từ chối thành công!")
                    this.props.onRefresh();
                    this.props.onHide();
                } else {
                    toast.error("Đã có lỗi xảy ra!")
                }
            })
        }
    }


    render() {
        let userType = localStorage.getItem("ob_cl_userType")
        let {listData, pageNumber, header} = this.state;
        let self = this;
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>Xác nhận từ chối duyệt báo cáo</b>
                            </h1>
                        </div>
                        <div>
                            <h1 className="text-left" style={{fontSize: 16,}}><b>Lý do từ chối duyệt</b></h1>
                        </div>
                        <div>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập lý do'}
                                maxLength={250}
                                value={this.state.reasonReject}
                                onChange={(e) => {
                                    self.setState({reasonReject: e.target.value.trimLeft()})
                                }}
                            />
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        disabled={this.state.reasonReject.length === 0}
                                        style={{
                                            backgroundColor: this.state.reasonReject.length === 0 ? '#ccc' : '#57afce',
                                            borderColor: this.state.reasonReject.length === 0 ? '#ccc' : '#57afce',
                                            cursor: this.state.reasonReject.length === 0 ? 'unset' : 'pointer'
                                        }}
                                        type="button"
                                        className="ml-4 border-1-s background-btn w-60 type-button-fix btn btn-primary"
                                        onClick={userType == "PARTNER" ? this.partnerReject : this.reject}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s width-btn type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                           Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withSnackbar(ModalRejectReport)
