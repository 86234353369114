import React, {Component} from "react";
import moment from "moment";
import {toast} from 'react-toastify'
import NumberFormat from "react-number-format";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import lang from "../../contracts-management/lang";
import ModalRatingAccountant from "../../../../components/Modal/modalRatingAccountant";
import CommentInDetail from "../../../common/comment/commentInDetail";

import PublicContractApi from "../../../../services/ob-core/public-contract";
import TableHeader from "../../../../components/TableHeader";
import AccountantApi from "../../../../services/ob-user/accountant";

const formatDate = "DD-MM-YYYY";


class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: false,
            itemSelected: {},
            code: "",
            status: "",
            mess: "",
            value: "",
            type: "",
            paymentStatus: "",
            supervisorId: "",
            paymentCycle: "",
            servicePlanId: "",
            customerId: "",
            startDate: "",
            accountantId: "",
            paid: "",
            unpaid: "",
            customerName: "",
            servicePlanName: "",
            id: null,
            contractId: null,
            listTimeLine: [],
            listData: [],
            listAccId: [],
            steps: [],
            steps2: [],

            listmessage: [
                {
                    name: "Admin",
                    mess: "Bạn cần thanh toán đúng hạn!"
                },
                {
                    name: "Thạch",
                    mess: "Mình sẽ thanh toán tất cả trong một lần"
                },
                {
                    name: "Admin",
                    mess: "Cảm ơn bạn rất nhiều."
                },
                {
                    name: "Thạch",
                    mess: "Tôi rất thích One Book For"
                }
            ],
            headerJob: [
                {
                    id: 'index',
                    name: "STT",
                    style: {width: 60, textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: "Tên Job",
                    style: {minWidth: 240, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'numberOfReports',
                    name: "Số lượng báo cáo",
                    style: {minWidth: 140, textAlign: "left"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {minWidth: 100, textAlign: "right"},
                    sort: false,
                },
                {
                    id: 'receiveMoney',
                    name: "Số tiền",
                    style: {minWidth: 160, textAlign: "right"},
                    sort: false,
                },
            ],
        };
    }

    componentDidMount() {
        document.title = "Chi tiết hợp đồng";
        this.getDetailContractFromAPI();
    }

    //******************************************************************************************************************

    getDetailContractFromAPI() {
        let myDate = new Date();
        let payload = {
            id: this.props.value
        };
        PublicContractApi.getMoreDetail(payload, res => {
            if (res.success) {
                let listAccId = [];
                let dataTemp = res.data.jobs
                dataTemp.map(item => {
                    listAccId.push(item.assignerId);
                });
                this.props.setListJob(res.data.jobs);
                this.setState({
                    listData: res.data,
                    listTimeLine: res.data.paymentTimelines,
                    dataJob: res.data.jobs,
                    listAccId
                }, () => {
                    if(dataTemp.length > 0) {
                        this.getListAccountantByIds()
                    }
                });
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }


    getListAccountantByIds = () => {
        let {dataJob, listAccId} = this.state;
        let payload = {
            ids: listAccId
        };
        AccountantApi.getAccountantNamesByListIds(payload, res => {
            if (res.success === true) {
                let dataTemp = res.data;
                dataJob.map(item => {
                    let filter = dataTemp.filter(x => x.id == item.assignerId);
                    item.assignerId = filter[0]?.fullName;
                });
                this.setState({
                    dataJob
                });
            } else {

            }
        });
    }

    //******************************************************************************************************************

    renderStatusSteper(data) {
        switch (data.assignStatus) {
            case "CANCELED":
                return null;
            case "WAIT_SIGN":
                return 0;
            case "NO_ASSIGN":
                return 1;
            case "WAITING_FOR_CONFIRMATION":
                return 2;
            case "REJECTED":
                return 3;
            case "PROCESSING":
                return 3;
            case "LIQUIDATED":
                return 4;
            case "CLOSED":
                return 5;

            default:
                return;
        }
    }

    renderStatus(assignStatus) {
        switch (assignStatus) {
            case "WAIT_SIGN":
                return "Chờ ký";
            case "PROCESSING":
                return "Kế toán xác nhận";
            case "NO_ASSIGN":
                return "Chưa assign";
            case "WAITING_FOR_CONFIRMATION":
                return "Chờ kế toán xác nhận";
            case "REJECTED":
                return "Kế toán từ chối";
            case "LIQUIDATED":
                return 'Thanh lý';
            case "CANCELED":
                return "Đã hủy;"
            case "CLOSED":
                return "Đã đóng";
        }
    }

    renderStatusJob = (status) => {
        switch (status) {
            case "UNFULFILLED":
                return <h5><span className="badge badge-dark">Chờ xử lý</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-success">Đang thực hiện</span></h6>;
            case "CLOSED":
                return <h5><span className="badge badge-warning">Đã đóng</span></h5>;
            default:
                return '';
        }
    }

    renderPaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return " Đã thanh toán";
            case "UNPAID":
                return "Chưa thanh toán";
            case "PAYMENT_TERM":
                return "Đến hạn thanh toán";
            case "PARTIALLY_PAID":
                return "Thanh toán";

            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return "Hợp đồng đăng kí mới";
            case "EXTENSION":
                return "Hợp đồng gia hạn";

            default:
                return;
        }
    }

    renderPaymentCycle(paymentCycle) {
        switch (paymentCycle) {
            case "PAYMENT_1_TIMES":
                return 0;
            case "PAYMENT_2_TIMES":
                return 1;

            default:
                return;
        }
    }

    //******************************************************************************************************************

    render() {
        let {
            activeStep,
            steps,
            steps2,
            activeStep2,
            rows,
            listmessage,
            id,
            listData,
            listStatus,
            code,
            status,
            listTimeLine
        } = this.state;
        return (
            <div style={{padding: 0}}>
                <div style={{fontSize: 20}}>TÌNH TRẠNG HỢP ĐỒNG</div>
                <div className="row">
                    <div className="row d-flex" style={{marginLeft: 0, width: 90}}>
                        <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                            <div
                                className="align-self-end mt-10"
                                style={{fontSize: 14, marginTop: 80, width: "100%"}}
                            ></div>
                            <div
                                className="align-self-start"
                                style={{fontSize: 14, width: "100%"}}
                            ></div>
                        </div>
                        <div
                            className="align-self-end mt-10"
                            style={{fontSize: 12, marginTop: 54, width: "100%"}}
                        ></div>
                        <div
                            className="align-self-start"
                            style={{fontSize: 12, width: "100%"}}
                        ></div>
                    </div>
                    <Stepper
                        alternativeLabel
                        className="d-flex col-10 justify-content-center mr-5"
                        activeStep={this.renderStatusSteper(listData)}
                    >
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chờ kí</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chưa assign</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Chờ kế toán xác nhận</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                {listData.assignStatus === "REJECTED" ? <div>Kế toán từ chối</div> :
                                    <div>Đang thực hiện</div>}
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Thanh lý hợp đồng</div>
                            </StepLabel>
                        </Step>
                        <Step style={{width: 0}}>
                            <StepLabel className="d-flex">
                                <div>Đã đóng</div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                {listData.status != "WAIT_SIGN" ? (
                    <div>
                        <div style={{fontSize: 20}}>TÌNH TRẠNG THANH TOÁN</div>

                        <div className="row">
                            <div className="row d-flex" style={{marginLeft: 0, width: 110}}>
                                <div
                                    className="align-self-end mt-10"
                                    style={{
                                        fontSize: 14,
                                        marginTop: 60,
                                        width: "100%",
                                        minWidth: 110
                                    }}
                                >
                                    Hạn thanh toán
                                </div>
                                <div
                                    className="align-self-start"
                                    style={{fontSize: 14, width: "100%"}}
                                >
                                    Thực tế
                                </div>
                            </div>
                            <Stepper
                                alternativeLabel
                                className="d-flex col-10 justify-content-center"
                                activeStep={this.renderPaymentCycle(listData.paymentCycle)}
                            >
                                {listTimeLine.map(row => (
                                    <Step style={{width: 0}}>
                                        <StepLabel className="d-flex">
                                            <NumberFormat
                                                value={row.paymentAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                            {row.paymentStatus == "PAYMENT_TERM" ? (
                                                <div style={{color: "red"}}>
                                                    {moment(row.paymentTerm).format(formatDate)}
                                                </div>
                                            ) : (
                                                <div>{moment(row.paymentTerm).format(formatDate)}</div>
                                            )}
                                            {row.paymentReal? (
                                                <div>{moment(row.paymentReal).format(formatDate)}</div>
                                            ) : (
                                                <div>&nbsp;&nbsp;</div>
                                            )}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div style={{fontSize: 20}}>
                    <span>THÔNG TIN CHI TIẾT HỢP ĐỒNG </span>
                </div>
                <div style={{fontSize: 15, padding: 15}}>
                    <div className="row">
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Tên khách hàng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                          <span style={{fontWeight: "bold"}}>
                            {listData.customerName}
                          </span>
                        </div>
                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Gói dịch vụ</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                      <span style={{fontWeight: "bold"}}>
                        {" "}
                          {listData.servicePlanName}
                      </span>
                        </div>

                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Giá trị hợp đồng</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                            <NumberFormat
                                style={{fontWeight: "bold"}}
                                value={listData.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={" VND"}
                            />
                        </div>

                        <div className="pl-0 pb-2 pr-0 col-md-3 col-lg-3 col-sm-3 kt-margin-b-10-tablet-and-mobile">
                            <label className="text-black">Ngày bắt đầu</label>
                        </div>
                        <div className="pl-0 col-md-9 col-lg-9 col-sm-9 kt-margin-b-10-tablet-and-mobile">
                      <span style={{fontWeight: "bold"}}>
                        {" "}
                          {moment(listData.startDate).format(formatDate)}
                      </span>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className='col-md-12 p-0 mt-2 mb-2' style={{fontSize: 20}}>
                        <span>THÔNG TIN JOB </span>
                    </div>
                    <div className='col-md-12' style={{overflow: 'auto'}}>
                        <table className="table table-striped table-data table-bordered">
                            <thead>
                            <TableHeader header={this.state.headerJob} clickSort={(data) => {
                            }}/>
                            </thead>
                            {!this.state.dataJob || this.state.dataJob.length == 0
                                ? <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>Chưa có dữ liệu</div>
                                    </td>
                                </tr>
                                </tbody>
                                : <tbody>
                                {this.state.dataJob.map((row, index) => (
                                    <tr key={row.id} style={{height: 50}}>
                                        <td style={{textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{textAlign: 'left'}}>{row.name}</td>
                                        <td style={{textAlign: 'center'}}>{row.countDone}/{row.numberOfReports}</td>
                                        <td style={{textAlign: 'center'}}>{this.renderStatusJob(row.status)}</td>
                                        <td style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                value={row.receiveMoney}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={" VND"}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>}
                        </table>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-6 pl-0" style={{fontSize: 20}}>
                        COMMENT
                    </div>
                    <div className="col-6 d-flex justify-content-end"></div>
                </div>
                <CommentInDetail contractId={this.props.value}/>
                {listData.status === "LIQUIDATED" || listData.status === "CLOSED" ? (
                    <div
                        className="row mt-4 pt-3"
                        style={{
                            borderTop: "1px dotted #516f7782"
                        }}
                    >
                        <div className="col-sm-6 col-md-6 p-0 ">
                            <span> ĐÁNH GIÁ KẾ TOÁN</span>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <button
                                className="border-1-s type-button-fix btn btn-light btn-sm shadow-none"
                                style={{height: 37, marginLeft: 10}}
                                onClick={() => this.setState({showRate: true})}
                            >
                                Đánh giá
                            </button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {this.state.showRate && (
                    <ModalRatingAccountant
                        contract={this.state.listData}
                        onHide={() => this.setState({showRate: false})}
                    />
                )}
            </div>
        );
    }
}

export default Information;
