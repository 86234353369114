import React from 'react';
import './style.css';
import chinhXac from '../../../../assets/images/service/chinh-xac.jpg';
import doiNgu from '../../../../assets/images/service/doi-ngu.png';
import tanTam from '../../../../assets/images/service/tan-tam.jpg';
import a4 from "../../../../assets/images/about/a4.png";
class HTBC extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='about-us-wrapper'>
          <div className='ab-content container'>
            <div className='row bg-white ab-block'>
              <div className='col-sm-12 text-part flex-wrap'>
                <div className='col-sm-1'>
                </div>
                <div className='col-sm-11'>
                  <div className='ab-content-title'>
                    Dịch vụ soát xét, hoàn thiện báo cáo tài chính
                  </div>
                  <div className='ab-content text-left'>
                    <p className='custom-text'>
                      <b>
                        “Chúng tôi coi soát xét, hoàn thiện báo cáo tài chính là cơ hội để mang đến cho khách
                        hàng dịch vụ có giá trị đích thực và gia tăng, không chỉ đơn thuần là tuân thủ các quy chế
                        doanh nghiệp, quy định pháp luật”
                      </b>
                    </p>
                    <p className='custom-text'>
                      <b>
                        “Chúng tôi áp dụng phương pháp tiếp cận tập trung vào các vấn đề trọng yếu thông qua
                        việc đánh giá, kiểm soát và xử lý rủi ro trên cơ sở tuân thủ các chuẩn mực và đạo đức
                        nghề nghiệp.”
                      </b>
                    </p>
                  </div>
                  <div className='row row-bctc'>
                    <div className='col-sm-3'>
                      <img className='img-bctc' src={doiNgu} />
                    </div>
                    <div className='col-sm-9 text-part flex-wrap'>
                      <p className='custom-text'>
                        Đội ngũ nhân viên trực tiếp thực hiện dịch vụ hoàn
                        thiện báo cáo tài chính luôn được ưu tiên bố trí trên
                        nguyên tắc: độc lập, khách quan, am hiểu lĩnh vực
                        kinh doanh của khách hàng, có đủ kiến thức, kinh
                        nghiệm và kỹ năng chuyên môn cần thiết để đáp ứng
                        tốt nhiệm vụ.
                      </p>
                    </div>
                  </div>
                  <div className='row row-bctc'>
                    <div className='col-sm-3'>
                      <img className='img-bctc' src={tanTam} />
                    </div>
                    <div className='col-sm-9 text-part flex-wrap'>
                      <p className='custom-text'>
                        Chúng tôi luôn trân trọng các ý kiến phản hồi của
                        khách hàng sau khi cũng cấp dịch vụ nhằm hoàn thiện
                        hơn nữa chất lượng dịch vụ và kỹ năng phục vụ
                        khách hàng
                      </p>
                    </div>
                  </div>
                  <div className='row row-bctc'>
                    <div className='col-sm-3'>
                      <img className='img-bctc' src={chinhXac} />
                    </div>
                    <div className='col-sm-9 text-part flex-wrap'>
                      <p className='custom-text'>
                        Chúng tôi cung cấp đầy đủ các dịch vụ hoàn thiện
                        báo cáo tài chính, bao gồm: Hoàn thiện BCTC theo
                        luật định; Hoàn thiện BCTC theo mục đích đặc biệt;
                        Kiểm tra tuân thủ theo yêu cầu; Hỗ trợ các giao dịch
                        trên thị trường vốn; Đảm bảo quy trình hệ thống kiểm
                        soát nội bộ.
                      </p>
                    </div>
                  </div>

                  <div className='ab-content text-left'>
                    <p className='custom-text'>
                      <i>
                        Đối với các dịch vụ này, Khách hàng có thể hệ trực tiếp với các trung tâm dịch vụ khác hàng để được trao
                        đổi và tư vấn trực tiếp về nội dung dịch vụ và phức phí dịch vụ phù hợp
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default HTBC;
