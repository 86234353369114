import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PublicSupervisorApi = {
    getDetailById: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/supervisor/get-detail/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getSupervisorHighlight: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/supervisor/get-highlight`,
            method: 'GET',
        }, callback);
    },
    searchByFullNameAndAddress: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/supervisor/search-by-fullname-and-address?${stringify(payload)}`,
            method: 'GET',
            // body: payload,
        }, callback);
    },
    getNamesByListId: (payload, callback) => {
        let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/public/supervisor/get-names-by-ids?ids=${param}`,
            method: 'GET'
        }, callback)
    },
};
export default PublicSupervisorApi
