import React from 'react'
import {Button, Card, Table, Form, Col, Row} from "react-bootstrap";
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
// import ModalAccept from "./modalAccept";
// import ModalReject from "./modalReject";
// import lang from "../../customer/contracts-management/lang";
import ModalCreate from '../../../components/Modal/admin/modalCreateServicePlan';
import ModalDetailAdvanceServicePlan from '../../../components/Modal/admin/modalDetailAdvanceServicePlan'
import ModalEditAdvanceServicePlan from '../../../components/Modal/admin/modalCreateAdvanceServicePlan'
import ModalDeleteServicePlan from '../../../components/Modal/admin/modalDeleteServicePlan';
import ModalActiveServicePlan from "../../../components/Modal/admin/modalActiveServicePlan";
import ModalLockServicePlan from '../../../components/Modal/admin/modalLockPlan'
import ModalUnlockServicePlan from '../../../components/Modal/admin/modalUnlockPlan'
import AdminServicePlan from '../../../services/ob-core/admin-service-plan'
import {Link} from "react-router-dom";
import ModalCreateAdvance from "../../../components/Modal/admin/modalCreateAdvanceServicePlan";
import {checkPermission} from "../../../utils/common";

const dateFormat = 'DD-MM-YYYY';

class ServicePlanManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDetailAdvance: false,
            showEditAdvance: false,
            showActive: false,

            page: 0,
            pageNumber: 0,
            pageSize: 10,
            totalItem: '',

            sort: '',

            categoryType: "",
            categoryType_temp: "",
            listData: [],
            itemSelected: "",
            showModalDelete: false,
            showModalLock: false,
            showModalUnlock: false,
            showLoading: false,
            header: [
                {
                    id: 'id',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'name',
                    name: 'Tên gói dịch vụ',
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'category',
                    name: "Loại gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: {textAlign: "center"},
                    sort: false
                },

            ],
        }
    }
    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListService()
        })

    }

    onSearch = () => {
        this.setState({
            categoryType: this.state.categoryType_temp,
        }, () => this.searchListService())
    }

    searchListService = () => {
        let {pageNumber, pageSize, sort, descending, categoryType} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            type: categoryType,
            sort: sort,
        }
        AdminServicePlan.searchByType(payload, res => {
            this.setState({
                listData: res.data.content,
                totalItem: res.data.totalElements,
                totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                showLoading: false
            })
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListService()
            }
        );
    }

    renderStatus = (statusType) => {
        switch (statusType) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "DISABLED":
                return <h6><span className="badge badge-warning">Vô hiệu hóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            default:
                return '';
        }
    };

    renderType = (statusType) => {
        switch (statusType) {
            case 'BASIC':
                return 'Dịch vụ cơ bản';
            case 'ADVANCED':
                return 'Dịch vụ nâng cao';
            default:
                return '';
        }
    };

    render() {
        let {pageNumber, pageSize, accountBalance} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="service-plan-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Gói dịch vụ "/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            {/*<div className='col-4 pl-0'>*/}
                                            {/*    <label className="label-item">Loại gói dịch vụ</label>*/}
                                            {/*    <input*/}
                                            {/*        maxLength={20} type="text"*/}
                                            {/*        className="form-control shadow-none"*/}
                                            {/*        placeholder="chọn gói dịch vụ"*/}
                                            {/*        value={this.state.categoryType_temp}*/}
                                            {/*        onKeyUp={(e) => {*/}
                                            {/*            if (e.key === 'Enter') this.searchListService()*/}
                                            {/*        }}*/}
                                            {/*        onChange={(e) => {*/}
                                            {/*            this.setState({*/}
                                            {/*                categoryType_temp: e.target.value*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            <div className='col-4 p-0'>
                                                <label className="label-item">Loại gói dịch vụ</label>
                                                <select
                                                    className="form-control kt-input pd-4-input shadow-none"
                                                    value={this.state.categoryType_temp}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            categoryType_temp: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value=''>Tất cả</option>
                                                    <option value='BASIC'>Dịch vụ cơ bản</option>
                                                    <option value='ADVANCED'>Dịch vụ nâng cao</option>
                                                </select>
                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-12 text-left pl-0 mt-3'>
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.onSearch()
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => {
                                                            this.setState({
                                                                categoryType_temp: '',
                                                            }, () => {
                                                                this.onSearch()
                                                            })
                                                        }}
                                                >Refresh
                                                </button>
                                                {checkPermission("create_new_service_plan") &&
                                                <button
                                                    type="button"
                                                    className="border-1-s type-button-fix btn background-btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.setState({showCreate: true})}
                                                >
                                                    Thêm mới
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = ''
                                            } else {
                                                a = `id_sort=${data.sortAsc ? 'asc' : 'desc'}`;
                                            }
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListService())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {/*{row.contractCode}*/}
                                                        {row.servicePlanName}
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        {/*{row.transactionContent}*/}
                                                        {this.renderType(row.type)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td className='p-1' style={{textAlign: 'center'}}>
                                                        <Link
                                                            style={{color: '#000'}}
                                                            to={row.type === 'BASIC' && {
                                                                pathname: "/admin/detail-service-plan",
                                                                search: `?servicePlanId=${row.id}`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    servicePlanId: row.id
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (row.type === 'ADVANCED') {
                                                                    this.setState({
                                                                        itemSelected: row,
                                                                        showDetailAdvance: true
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <button className="btn btn-action p-2"
                                                                    title='Xem chi tiết'
                                                                    style={{cursor: 'pointer'}}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                        </Link>
                                                        {checkPermission("edit_service_plan") &&
                                                        <Link
                                                            style={{color: '#000'}}
                                                            to={row.type === 'BASIC' && {
                                                                pathname: "/admin/edit-service-plan",
                                                                search: `?servicePlanId=${row.id}`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    servicePlanId: row.id
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (row.type === 'ADVANCED') {
                                                                    this.setState({
                                                                        itemSelected: row,
                                                                        showEditAdvance: true
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <button className="btn btn-action p-2"
                                                                    title='Chỉnh sửa'
                                                                    style={{cursor: 'pointer'}}
                                                            >
                                                                <i className="ti-pencil-alt"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission("block_service_plan") &&
                                                        <button className="btn btn-action p-2"
                                                                title={row.status === 'LOCK' ? 'Mở khóa' : 'Khóa'}
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    if (row.status === 'LOCK') this.setState({
                                                                        itemSelected: row.id,
                                                                        showModalUnlock: true,
                                                                    })
                                                                    else {
                                                                        this.setState({
                                                                            itemSelected: row.id,
                                                                            showModalLock: true
                                                                        })
                                                                    }
                                                                }}
                                                        >
                                                            <i className={row.status === 'LOCK' ? "ti-unlock" : 'ti-lock'}/>
                                                        </button>
                                                        }
                                                        <button className="btn btn-action p-2"
                                                                  title='Kích hoạt'
                                                                disabled={row.status !== 'DISABLED'}
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row, showActive: true})
                                                                }}
                                                        >
                                                            <i className="ti-key"/>
                                                        </button>
                                                        {checkPermission("delete_service_plan") &&
                                                        <button className="btn btn-action p-2"
                                                                title='Xóa'
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    this.setState({itemSelected: row.id, showModalDelete: true})
                                                                }}
                                                        >
                                                            <i className="ti-trash"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>

                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*{this.state.showModalAccept && (*/}
                {/*    <ModalAccept*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        contractId={this.state.contractId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalAccept: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{this.state.showModalReject && (*/}
                {/*    <ModalReject*/}
                {/*        itemId={this.state.itemId}*/}
                {/*        onHide={() => {*/}
                {/*            this.setState({showModalReject: false});*/}
                {/*            this.getListContractFromAPI()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}
                {this.state.showCreate && <ModalCreate
                    show={this.state.showCreate}
                    onHide={() => this.setState({showCreate: false})}
                    onRefresh={() => this.searchListService()}
                />}

                {this.state.showActive && <ModalActiveServicePlan
                    show={this.state.showActive}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showActive: false})}
                    onRefresh={() => this.searchListService()}
                />}

                {this.state.showDetailAdvance && <ModalDetailAdvanceServicePlan
                    show={this.state.showDetailAdvance}
                    data={this.state.itemSelected}
                    onHide={() => this.setState({showDetailAdvance: false})}
                />}

                {this.state.showEditAdvance && <ModalEditAdvanceServicePlan
                    show={this.state.showEditAdvance}
                    data={this.state.itemSelected}
                    typeAction='edit'
                    onHide={() => this.setState({showEditAdvance: false, showCreate: false})}
                    hideCreate={() => this.setState({showEditAdvance: false, showCreate: false})}
                />}

                <ModalDeleteServicePlan
                    show={this.state.showModalDelete}
                    onHide={() => this.setState({showModalDelete: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />

                <ModalLockServicePlan
                    show={this.state.showModalLock}
                    onHide={() => this.setState({showModalLock: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />

                <ModalUnlockServicePlan
                    show={this.state.showModalUnlock}
                    onHide={() => this.setState({showModalUnlock: false})}
                    onRefresh={() => this.onSearch()}
                    id={this.state.itemSelected}
                />
            </div>
        )
    }

}

export default ServicePlanManagement
