import React from 'react';
import {stringify} from 'qs';
import moment from "moment";
import "./Modal.css"
import NumberFormat from "react-number-format";
import Link from "../Link";
import {
    Modal,
} from 'react-bootstrap';
import ModalDeleteCallCenter from "./admin/modalDeleteCallCenter";

import PublicOrderApi from "../../services/ob-core/public-order-service";
import SupervisorApi from "../../services/ob-user/supervisor";
import SecureCustomerApi from "../../services/ob-user/secure-customer";
import CallCenterApi from "../../services/ob-user/call-center";
import {toast} from "react-toastify";
import Autosuggestion from "../Form/Autosuggestion";
import PublicOrder from "../../services/ob-core/public-order-service";

class ModalDetailOrderCallCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailCallCenter: false,
            itemSelected: {},
            dataOrder: [],
            listCall: [],
            userId: null,
            supervisorName: "",
            supervisorList: [],
            money: ""
        };
    }


    componentDidMount() {
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        this.setState({userId: userInfo.id})
        this.getDetailOrder()
        this.getListSupervisor()
    }

    getListSupervisor = () => {
        let {supervisorName} = this.state;
        let payload = {
            pageNumber: 0,
            pageSize: 10,
            fullName: supervisorName,
        }
        SupervisorApi.searchByFullNameAndAddress(payload, res => {
            if (res.success) {
                this.setState({
                    supervisorList: res.data.content,

                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    getDetailOrder = () => {
        let {data} = this.props;
        let payload = {
            id: data.id
        }
        PublicOrderApi.callCenterGetDetailById(payload, res => {
            if (res.success) {
                this.setState({
                    dataOrder: res.data,
                    money: res.data.dealPrice,
                    listCall: res.data.listCall
                }, () => {
                    if (res.data.supervisorId) {
                        this.getNameSupervisor();
                    }
                    if (res.data.customerId) {
                        this.getNameCustomer();
                    }
                    if (res.data.callCenterId) {
                        this.getNameCallCenter();
                    }
                })
            }
        })
    }

    assignOrder = () => {
        let payload = {
            id: this.props.data.id,
            body: {
                price: parseInt(this.state.money.replace(/,/g, '')),
                supervisorId: this.state.selectedSupervisor.id
            }
        }
        PublicOrder.assignAdvance(payload, res => {
            if (res.success) {
                toast.success("Chấp nhận thành công!")
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra!!!")
            }
        })
    }

    acceptOrder = () => {
        let payload = {
            id: this.props.data.id
        }
        PublicOrderApi.callCenterPickOrder(payload, res => {
            if (res.success) {
                toast.success("Nhận order thành công!")
                this.props.onHide()
                this.props.onRefresh()
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        })
    }

    getNameCustomer = () => {
        let payload = {
            ids: [
                this.state.dataOrder.customerId,
            ]
        }
        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let dataTemp = this.state.dataOrder;
                dataTemp.customerName = res.data[0].fullName;
                dataTemp.customerPhone = res.data[0].phone;
                this.setState({
                    dataOrder: dataTemp
                })
            }
        })
    }

    getNameSupervisor = () => {
        let payload = {
            id: this.state.dataOrder.supervisorId,
        }
        SupervisorApi.getNameById(payload, res => {
            if (res.success) {
                let dataTemp = this.state.dataOrder;
                dataTemp.supervisorName = res.data;
                this.setState({
                        dataOrder: dataTemp
                    },
                    // () => console.log("check>>>>", this.state.dataOrder)
                )
            }
        })
    }
    getNameCallCenter = () => {
        let payload = {
            id: this.state.dataOrder.callCenterId,
        }
        CallCenterApi.getNameById(payload, res => {
            if (res.success) {
                let dataTemp = this.state.dataOrder;
                dataTemp.callCenterName = res.data;
                this.setState({
                        dataOrder: dataTemp
                    },
                    // () => console.log("check>>>>", this.state.dataOrder)
                )
            }
        })
    }

    renderStatus(status) {
        switch (status) {
            case "WAITING_CALL_CENTER_CONFIRM":
                return "Chờ Call Center xác nhận";
            case "WAITING_FOR_CONFIRMATION":
                return "Chờ xác nhận";
            case "PROCESSING":
                return "Đang thực hiện";
            case "REJECTED":
                return "Đã từ chối";
            case "CONFIRMED":
                return "Đã xác nhận";
            case "CANCELED":
                return "Đã hủy";
            case "CREATED_CONTRACT":
                return "Đã tạo hợp đồng";
        }
    }


    render() {
        let {dataOrder, listCall} = this.state;
        let {data} = this.props
        const modalProp = {
            show: true,
            onHide: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        let userType = localStorage.getItem("ob_cl_userType")
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-size-small">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div className='mb-2'>
                            <h1 className="text-center mb-1" style={{fontSize: 20,}}><b>Chi tiết Order</b></h1>
                            <div className='text-center font-italic font-weight-lighter'>
                                <label>Mã Order: {dataOrder.code}</label>
                            </div>
                        </div>

                        <fieldset className="area-content-modal">
                            <legend className='font-14 pl-2' style={{width: 160}}>Thông tin khách hàng</legend>
                            <div className="row">
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-3">
                                    <label className="text-black-50">Tên Khách hàng</label>
                                </div>
                                <div className="pl-0 col-md-9 col-lg-9 col-sm-9">
                                <span
                                    style={{fontWeight: "bold"}}>{userType === "SUPERVISOR" ? userInfo.fullName : (dataOrder.customerName || "Chưa có dữ liệu")}</span>
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-3">
                                    <label className="text-black-50">Số điện thoại </label>
                                </div>
                                <div className="pl-0 col-md-9 col-lg-9 col-sm-9">
                                    <span
                                        style={{fontWeight: "bold"}}>{dataOrder.customerPhone || "Chưa có dữ liệu"}</span>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="area-content-modal">
                            <legend className='font-14 pl-2' style={{width: 155}}>Thông tin gói dịch vụ</legend>
                            <div className="row">
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Gói dịch vụ</label>
                                </div>
                                <div className="pl-0 col-md-9 col-lg-9 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.servicePlanName || "Chưa có dữ liệu"}</span>
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Giá trị</label>
                                </div>
                                <div className="pl-0 col-md-9 col-lg-9 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span style={{fontWeight: "bold"}}>
                                    <NumberFormat value={dataOrder.dealPrice} thousandSeparator={true}
                                                  suffix={' VND'}
                                                  displayType={'text'}
                                    />
                                </span>
                                </div>

                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Số lượng nhân sự</label>
                                </div>
                                <div
                                    className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile text-center">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.numberOfMonths === null ? "Chưa có dữ liệu" : dataOrder.numberOfMonths}</span>
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Số lượng chứng từ</label>
                                </div>
                                <div
                                    className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile text-center">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.numberOfMonths === null ? "Chưa có dữ liệu" : dataOrder.numberOfMonths}</span>
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Số lượng hóa đơn</label>
                                </div>
                                <div
                                    className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile text-center">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.numberOfMonths === null ? "Chưa có dữ liệu" : dataOrder.numberOfMonths}</span>
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Số kì</label>
                                </div>
                                <div
                                    className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile text-center">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.numberOfMonths === null ? "Chưa có dữ liệu" : dataOrder.numberOfMonths}</span>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="area-content-modal">
                            <legend className='font-14 pl-2' style={{width: 125}}>Thông tin Order</legend>
                            <div className="row">
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Supervisor</label>
                                </div>
                                <div title={dataOrder.supervisorName}
                                     className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    {dataOrder.supervisorName || "Chưa có dữ liệu"}
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Call Center</label>
                                </div>
                                <div title={dataOrder.callCenterName}
                                     className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    {dataOrder.callCenterName || "Chưa có dữ liệu"}
                                </div>
                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Thời gian gửi</label>
                                </div>
                                <div className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span
                                    style={{fontWeight: "bold"}}>{moment(dataOrder.cmnCreatedAt).format("DD-MM-YYYY") || "Chưa có dữ liệu"}</span>
                                </div>

                                <div className="pl-0 pr-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                    <label className="text-black-50">Trạng thái</label>
                                </div>
                                <div className="pl-0 col-md-3 col-lg-3 col-sm-6 kt-margin-b-10-tablet-and-mobile">
                                <span
                                    style={{fontWeight: "bold"}}>{this.renderStatus(dataOrder.status) || "Chưa có dữ liệu"}</span>
                                </div>
                            </div>

                        </fieldset>

                        <fieldset className="row area-content-modal">
                            <legend className='font-14 pl-2' style={{width: 110}}>Lịch sử liên hệ</legend>
                            <div className="pl-0 col-md-12 col-lg-12 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                            <span style={{fontWeight: "bold"}}>
                                {listCall.length > 0
                                    ? listCall.map((item, index) => (
                                        <div
                                            // title='Xem chi tiết Call Center'
                                            style={{
                                                // color: '#1c90ab',
                                                // cursor: 'pointer',
                                                marginBottom: 2
                                            }}
                                        >
                                            <span className='mr-2'>
                                                <span
                                                    className='text-black-50'>• Lần {index + 1}:</span> {moment(item.time_call).format('DD-MM-YYYY HH:mm:ss')}
                                            </span>
                                            <span className='text-right'>
                                                ({item.callCenterName})
                                            </span>
                                        </div>
                                    ))
                                    : 'Chưa có dữ liệu'
                                }
                                </span>
                            </div>
                        </fieldset>

                        <fieldset className="area-content-modal">
                            <legend className='font-14 pl-2' style={{width: 80}}>Lý do hủy</legend>
                            <div className="row">
                                <div className="pl-0 col-md-12 col-lg-12 col-sm-12 kt-margin-b-10-tablet-and-mobile">
                                <span
                                    style={{fontWeight: "bold"}}>{dataOrder.reason || "Chưa có dữ liệu"}
                                </span>
                                </div>
                            </div>
                        </fieldset>

                        <div className="align-center mt-3 mb-2">
                            {/*{(this.state.userType === "PARTNER" && data.status === "CONFIRMED") &&*/}
                            {/*<Link to={`/partner/create-contract?${stringify(data)}`}>*/}
                            {/*    <button*/}
                            {/*        type="button"*/}
                            {/*        className="mr-4 border-1-s background-btn btn btn-primary"*/}
                            {/*    >*/}
                            {/*            <span className='d-flex justify-content-center'>*/}
                            {/*                Tạo hợp đồng*/}
                            {/*            </span>*/}
                            {/*    </button>*/}
                            {/*</Link>}*/}
                            {/*{this.props.data.callCenterId === "" && this.props.data.callCenterId === 0*/}
                            {/*    ? <button*/}
                            {/*        type="button"*/}
                            {/*        className="border-1-s type-button-fix btn btn-primary mr-3"*/}
                            {/*        onClick={() => this.acceptOrder()}*/}
                            {/*    >*/}
                            {/*            <span className='d-flex justify-content-center'>*/}
                            {/*                Nhận Order*/}
                            {/*            </span>*/}
                            {/*    </button>*/}
                            {/*    : <div/>*/}
                            {/*}*/}

                            {/*{data.callCenterId !== null && data.callCenterId !== 0 && data.status === "WAITING_CALL_CENTER_CONFIRM"*/}
                            {/*    ? <button*/}
                            {/*        type="button"*/}
                            {/*        className="border-1-s type-button-fix btn btn-primary mr-3"*/}
                            {/*        onClick={() => this.assignOrder()}*/}
                            {/*    >*/}
                            {/*            <span className='d-flex justify-content-center'>*/}
                            {/*                Xác nhận xử lý*/}
                            {/*            </span>*/}
                            {/*    </button>*/}
                            {/*    : <div/>*/}
                            {/*}*/}
                            {data.callCenterId === null || data.callCenterId === 0 && data.status === "WAITING_CALL_CENTER_CONFIRM"
                            && <button
                                type="button"
                                className="border-1-s type-button-fix btn btn-primary mr-3"
                                onClick={() => this.acceptOrder()}
                            >
                                    <span className='d-flex justify-content-center'>
                                        Xác nhận xử lý
                                    </span>
                            </button>
                            }
                            <button
                                type="button"
                                className="border-1-s type-button-fix btn btn-light  mr-3"
                                onClick={() => {
                                    this.props.onHide()
                                }}
                            >
                                    <span className='d-flex justify-content-center'>
                                        Đóng
                                    </span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDetailOrderCallCenter

