import React from 'react';
import "./Modal.css"
import {withSnackbar} from "notistack";
import Cookies from "js-cookie";
import Utils from '../../utils/utils'

import {
    Modal,
} from 'react-bootstrap';

class ModalConfirmEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            contract: '',
            signupInfo: null,
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        };
    }

    componentDidMount() {
        this.setState({
            name: '',
            phone: '',
            email: '',
            contract: '',
            nameErr: false,
            phoneErr: false,
            emailErr: false,
            contractErr: false,
            btnStatus: false,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                name: '',
                phone: '',
                email: '',
                contract: '',
                nameErr: false,
                phoneErr: false,
                emailErr: false,
                contractErr: false,
                btnStatus: false,
            });
        }
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 18,}}><b>Thông tin đã thay đổi, bạn có chắc
                                muốn hủy ?</b></h1>
                        </div>

                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-2 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        onClick={this.props.handleCancel}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Có
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-2 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Không
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalConfirmEdit;
