
import React from 'react';
import "./Modal.css"
import Cookies from "js-cookie";
import Utils from '../../utils/utils'
import {
    Modal,
} from 'react-bootstrap';
import {toast} from "react-toastify";
import ActionApi from "../../services/ob-core/action-controller";
import RoleApi from "../../services/ob-user/role";
import ActionRoleApi from "../../services/ob-user/action-role";

class ModalAddEditPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleName: this.props.type == "edit" ? this.props.item.nameRole : "",
            actionGroupName: null,
            actionSelected: [],
            actionSelectedId: [],
            deleteSelected: [],
        };
    }

    componentDidMount() {
        if (this.props.type == "edit"){
            this.getDetail()
        }
        this.getActionGroup()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("check props>>>>>", nextProps, this.props)
    }


    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log("check props>>>>>", nextProps, this.props)
    //     if (nextProps.show != this.props.show && nextProps.type == "edit") {
    //         this.getDetail()
    //         // if (nextProps.type == "edit") {
    //         //     this.setState({})
    //         // }
    //     }
    // }

    handleConfirm() {
        let payload = {
            // id: this.props.job.id
        }

    }

    getDetail = () => {
        let {actionSelected, actionSelectedId} = this.state;
        let payload = {
            id: this.props.item.id
        }
        ActionRoleApi.getListByRoleId(payload, res => {
            if (res.success) {
                res.data.map((item) => {
                    actionSelectedId.push(item.actionId)
                })
                this.setState({
                    actionSelected: res.data,
                    actionSelectedId
                })

            }
        })
    }

    selectActionGroup = (value) => {
        this.setState({actionGroupName: value}, () => {
            this.getListByGroupName()
        })
    }

    removeAction = (item) => {
        let {actionSelected, actionSelectedId, deleteSelected} = this.state;
        let idx = actionSelected.indexOf(item)
        let idx2 = actionSelectedId.indexOf(item.id)
        actionSelected.splice(idx, 1);
        actionSelectedId.splice(idx2, 1);
        if (this.props.type == "edit"){
            deleteSelected.push(item.actionId)
        }
        console.log("check action remove>>>>>>>", idx, idx2, actionSelected, actionSelectedId)
        this.setState({actionSelected, actionSelectedId})
    }

    selectAction = (item) => {
        let {actionSelected, actionSelectedId} = this.state;
        let filter = actionSelected.filter(_item => _item.id == item.id)
        if (filter.length > 0) {
            toast.error("Quyền cho chức năng này đã tồn tại!")
        } else {
            actionSelected.push(item)
            actionSelectedId.push(item.id)
            this.setState({actionSelected, actionSelectedId})
        }

    }

    getActionGroup = () => {
        ActionApi.getActionGroup(res => {
            if(res.success) {
                this.setState({actionGroup: res.data})
            }
        })
    }

    getListByGroupName = () => {
        let payload = {
            groupName: this.state.actionGroupName
        }
        ActionApi.getListByGroupName(payload, res => {
            if(res.success) {
                this.setState({actionList: res.data})
            }
        })
    }

    createNew = () => {
        if (this.state.roleName){
            let payload = {
                nameRole: this.state.roleName
            }
            RoleApi.createRole(payload, res => {
                if (res.success) {
                    this.setState({roleId: res.data}, () => {
                        this.addPermissionToRole()
                    })
                }
            })
        } else{
            this.setState({
                roleId: this.props.item.id
            }, () => {
                this.addPermissionToRole()
                if (this.state.deleteSelected.length > 0) {
                    this.removePermissionToRole()
                }
            })
        }
    }

    addPermissionToRole = () => {
        let payload = {
            id: this.state.roleId,
            body: {
                ids: this.state.actionSelectedId
            }
        }
        ActionRoleApi.addList(payload, res => {
            if (res.success) {
                toast.success(this.props.type == "edit" ? "Chỉnh sửa nhóm quyền thành công" : "Thêm mới nhóm quyền thành công!")
                this.props.onRefresh()
            }else{
                toast.error("Đã xảy ra lỗi! " + res.message)
            }
        })
    }

    removePermissionToRole = () => {
        let payload = {
            id: this.state.roleId,
            body: {
                ids: this.state.deleteSelected
            }
        }
        ActionRoleApi.removeList(payload, res => {
            if (res.success) {
                console.log("done")
            } else {
                toast.error("Đã có lỗi xảy ra")
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-lg">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>{this.props.type == "edit" ? "Chỉnh sửa nhóm quyền" : "Thêm mới nhóm quyền"}</b></h1>
                        </div>
                        <div className=''>
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="">Tên nhóm quyền <span style={{color: 'red'}}>*</span></label>
                                </div>
                                <div className="col-md-9">
                                    <input type="text" value={this.state.roleName}
                                           className="form-control"
                                           maxLength={100}
                                           onChange={(val) => {
                                        this.setState({roleName: val.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <label htmlFor="">Nhóm chức năng</label>
                                </div>
                                <div className="col-md-9">
                                    <select
                                        className="form-control kt-input pd-4-input shadow-none"
                                        // value={this.state.actionGroupName}
                                        defaultValue={0}
                                        onChange={(e) => {
                                            this.selectActionGroup(e.target.value)
                                        }}>
                                        <option value={0} disabled>Chọn một nhóm chức năng</option>
                                        {this.state.actionGroup?.map(item => (
                                            <option value={item.name}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {/*<div className="row mt-3">*/}
                            {/*    <div className="col-md-3">*/}
                            {/*        <label htmlFor="">Tên chức năng</label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-9">*/}
                            {/*        <input type="text" className="form-control"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Chọn chức năng</label>
                        </div>
                        <div className="mt-1 row">
                            <div className="col-md-6">
                                <div className="card permission-box">
                                    <ul>
                                        {this.state.actionList?.map((item, index) => (
                                            <li className="permission-item justify-space-between d-flex align-items-center" onClick={() => this.selectAction(item)}>
                                                <span>{item.actionName}</span>
                                                <span className="fas fa-plus-square" style={{color: 'green'}}/>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card permission-box">
                                    <ul>
                                        {this.state.actionSelected?.map((item, index) => (
                                            <li className="permission-item d-flex align-items-center" onClick={() => this.removeAction(item)}>
                                                <span className="fas fa-minus-square mr-2" style={{color: 'red'}}/>
                                                {item.actionName}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        // disabled={!this.props.item.edit}
                                        className="ml-4 border-1-s background-btn type-button-fix btn btn-primary btn-sm"
                                        onClick={() => this.createNew()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 border-1-s type-button-fix btn btn-light btn-sm"
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đóng
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalAddEditPermission;
