export default {
    title: {
        infoCompany :'Thông tin doanh nghiệp',
        editCompany: 'Chỉnh sửa thông tin doanh nghiệp',
        infoRepresentative: 'Thông tin tài khoản'
    },
    // breadcrumbs: {
    //     home: 'Trang chủ',
    //     listRequest: 'Danh sách yêu cầu'
    // },
    label: {
        fullName: 'Tên doanh nghiệp',

    },
    input: {
        labelBrandname: 'Tên Brandname',
        labelStatus: 'Trạng thái',
        labelCategory: 'Danh mục',

    },
    // render: {
    //     status: {
    //         pending: 'Chờ duyệt',
    //         processing: 'Đang xử lý',
    //         active: 'Hoàn thành',
    //         canceled: 'Đã hủy'
    //     },
    //     category: {
    //         transfer: 'Chuyển nhượng',
    //         lock: 'Khóa brandname',
    //         unlock: 'Mở khóa brandname',
    //         cancel: 'Hủy brandname'
    //     }
    // },
    buttonSearch: 'Tìm kiếm',

    titleHover: {
        iconCancel: 'Hủy yêu cầu',
        buttonNextPage: 'Trang tiếp',
        buttonBackPage: 'Trang trước',
        brandname: 'Xem chi tiết'
    },
    modal: {
        cancel: {
            titleModal: 'Thông tin đã thay đổi, bạn có chắc muốn hủy ?',
            buttonYes: 'Có',
            buttonNo: 'Không',
        }
    }
}
