import React from 'react';
import './style.css';
import Cookies from "js-cookie";
class DieuKhoan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: true
        };
    }
    componentWillMount() {
        this.setState({isAuth: true});
    }
    render() {
        return (
            <div className='about-us-wrapper'>
                <div className='banner container text-center'>
                    <body className="c25"><p className="c14"><span className="c7">THỎA THUẬN SỬ DỤNG HỆ THỐNG</span></p>
                    <p className="c14"><span className="c0">Đây là thỏa thuận pháp lý giữa khách hàng với Công ty CP Giải pháp kế toán thông minh (SAS), quy định các điều khoản trong việc khách hàng sử dụng hệ thống nền tảng và dịch vụ tài chính – kế toán – thuế. Thỏa thuận này là hợp đồng điện tử giữa hai bên. Bằng cách nhấp chuột vào nút “đồng ý” khi đăng ký sử dụng, khách hàng đồng ý rằng các điều khoản này sẽ được áp dụng nếu khách hàng lựa chọn truy cập hoặc sử dụng dịch vụ và thao tác nhấp chuột này tương đương với việc hai bên đã ký kết hợp đồng.</span>
                    </p><p className="c14" id="h.gjdgxs"><span className="c3">Điều 1:&nbsp;Các thuật ngữ sử dụng trong thỏa thuận</span>
                    </p><p className="c1"><span className="c5">1.1&nbsp;&nbsp;</span><span
                        className="c7">Phần mềm</span><span className="c0">: là phần mềm Onebook kết nối dịch vụ giữa người có nhu cầu sử dụng dịch vụ kế toán – tài chính – thuế và người cung cấp dịch vụ bao gồm các cá nhân hành nghề và Công ty cung cấp dịch vụ..</span>
                    </p><p className="c1"><span className="c5">1.2&nbsp;&nbsp;</span><span
                        className="c7">Hệ thống</span><span className="c0">: Bao gồm các máy chủ đặt tại trung tâm dữ liệu của SAS, được cài đặt các phần mềm hệ thống và phần mềm ONEBOOK.</span>
                    </p><p className="c1"><span className="c5">1.3&nbsp;&nbsp;</span><span
                        className="c7">SAS</span><span className="c0">: Là Công ty CP Giải pháp kế toán thông minh, nhà cung cấp phần mềm ONEBOOK kết nối dịch vụ giữa người có nhu cầu sử dụng dịch vụ kế toán – tài chính – thuế và người cung cấp dịch vụ bao gồm các cá nhân hành nghề và Công ty cung cấp dịch vụ.</span>
                    </p><p className="c1"><span className="c5">1.4&nbsp;&nbsp;</span><span
                        className="c7">Khách hàng</span><span className="c0">: Là tổ chức hoặc cá nhân đứng ra đăng ký dùng thử hoặc trả tiền sử dụng dịch vụ thông qua ONEBOOK.</span>
                    </p><p className="c1"><span className="c5">1.5&nbsp;&nbsp;</span><span className="c7">Thời gian thuê bao</span><span
                        className="c0">: Là khoảng thời gian khách hàng được cấp quyền sử dụng dịch vụ theo yêu cầu đăng ký và thỏa thuận thanh toán phí với SAS.</span>
                    </p><p className="c1"><span className="c5">1.6&nbsp;&nbsp;&nbsp;</span><span className="c7">Thông tin phái sinh</span><span
                        className="c0">: là các thông tin được tổng hợp, suy luận từ các thông tin gốc do khách hàng tạo ra trong phần mềm của SAS. Ví dụ: Thông tin phái sinh từ phần mềm kế toán&nbsp;cá nhân có thể là Báo cáo về thu nhập trung bình của người Việt Nam, chi tiêu trung bình hàng tháng…</span>
                    </p><p className="c14" id="h.30j0zll"><span
                        className="c3">Điều&nbsp;2: Quyền sử dụng phần mềm</span></p><p className="c1"><span
                        className="c0">2.1&nbsp;&nbsp;Khách hàng có quyền sử dụng đầy đủ các tính năng của phần mềm trong thời gian thuê bao còn hạn sử dụng. Số năm sử dụng tùy theo đăng ký mua dịch vụ của khách hàng.</span>
                    </p><p className="c1"><span className="c0">2.2&nbsp;&nbsp;Khách hàng không được phép sử dụng dịch vụ phần mềm bao gồm nhưng không giới hạn bởi việc cập nhật dữ liệu, gửi email, viết bài hoặc truyền tải dữ liệu với mục đích sau:</span>
                    </p><p className="c14 c15"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn đến con người và tài sản;</span>
                    </p><p className="c14 c15"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liên quan đến việc công&nbsp;bố các thông tin hoặc tài liệu lừa đảo, gây mất uy tín danh dự, quấy rối hoặc mang tính khiêu dâm;</span>
                    </p><p className="c14 c15"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, tôn giáo, giới tính, người tàn tật;</span>
                    </p><p className="c14 c15"><span className="c0">d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác;</span>
                    </p><p className="c14 c15"><span
                        className="c0">e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cản trở hoặc&nbsp;phá hỏng Dịch vụ (bao gồm nhưng không giới hạn bởi việc truy cập Dịch vụ thông qua bất cứ phương tiện máy móc, phần mềm);</span>
                    </p><p className="c14 c15"><span className="c0">f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vi phạm quy định của pháp luật.</span>
                    </p><p className="c1"><span className="c0">2.4&nbsp;&nbsp;Trước khi hết hạn, khách hàng cần thực hiện thủ tục gia hạn thuê bao để tiếp tục sử dụng phần mềm. Thời điểm gia hạn là thời điểm tính từ ngày hết hạn của kỳ đăng ký sử dụng trước đó.</span>
                    </p><p className="c1"><span className="c0">2.5&nbsp;&nbsp;Khi hết hạn thuê bao, khách hàng sẽ không được phép đăng nhập để tiếp tục sử dụng phần mềm.</span>
                    </p><p className="c1"><span
                        className="c0">2.6&nbsp;&nbsp;Khi thuê bao quá hạn&nbsp;trên 60 ngày,&nbsp;nếu khách hàng vẫn chưa làm thủ tục đăng ký và&nbsp;thanh toán tiền gia hạn thuê bao thì SAS sẽ làm thủ tục cắt thuê bao và xóa bỏ dữ liệu của khách hàng. Khách hàng sẽ không sử dụng được dịch vụ phần mềm ONEBOOK nữa sau khi SAS đã cắt thuê bao.</span>
                    </p><p className="c1"><span className="c0">2.7&nbsp;&nbsp;Khách hàng sẽ bị cắt thuê bao sử dụng phần mềm ONEBOOK kể&nbsp;từ khi nhận được thông báo cắt dịch vụ của SAS gửi qua email trong các trường hợp:</span>
                    </p><p className="c14 c15"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng không làm thủ tục đăng ký gia hạn, thanh toán với SAS nếu thuê bao quá hạn sử dụng trên 60&nbsp;ngày;</span>
                    </p><p className="c14 c15"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng yêu cầu cắt thuê bao phần mềm;</span>
                    </p><p className="c14 c15"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng vi phạm mục đích sử dụng phần mềm được nêu trong thỏa thuận này;</span>
                    </p><p className="c14 c15"><span className="c0">d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng vi phạm pháp luật và cơ quan công quyền yêu cầu SAS dừng cung cấp dịch vụ thuê bao cho khách hàng.</span>
                    </p><p className="c14" id="h.1fob9te"><span className="c3">Điều&nbsp;3: Giá cả và phương thức thanh toán</span>
                    </p><p className="c1"><span className="c0">3.1&nbsp;&nbsp;Phí dịch vụ mà khách hàng thanh toán cho SAS sẽ được SAS gửi thông báo trên chính phần mềm ONEBOOK.</span>
                    </p><p className="c1"><span className="c0">3.2&nbsp;&nbsp;Khách hàng chịu trách nhiệm thanh toán cho SAS 50% giá trị của gói sản phẩm/dịch vụ mà khách hàng chọn mua ngay sau khi khách hàng gửi đơn đặt hàng cho SAS hoặc thanh toán hàng kỳ tùy theo loại hình dịch vụ lựa chọn.</span>
                    </p><p className="c1"><span className="c0">3.3&nbsp;&nbsp;Thời điểm bắt đầu tính phí dịch vụ được tính từ ngày SAS bàn giao cho khách hàng thông tin truy cập vào phần mềm căn cứ vào email thông báo của SAS.</span>
                    </p><p className="c1"><span className="c0">3.4&nbsp;&nbsp;Việc thanh toán các kỳ tiếp theo sau khi hết hạn&nbsp;phải được thực hiện chậm nhất là 15 ngày kể từ ngày khách hàng nhận được thông báo của SAS về việc đóng phí và mức phí dịch vụ cần đóng cho kỳ tiếp theo. Thông báo này được gửi trực tiếp trên chính phần mềm ONEBOOK mà khách hàng đang sử dụng&nbsp;(quy định tại điều 6.5a).</span>
                    </p><p className="c1"><span
                        className="c5">3.5&nbsp;&nbsp;Khi hợp đồng hết hạn, SAS có quyền điều&nbsp;chỉnh mức phí dịch vụ theo giá thị trường và công bố trực tiếp trên website&nbsp;</span><span
                        className="c5 c11 c17"><a className="c8"
                                                  href="https://www.google.com/url?q=https://qlth.vn/&amp;sa=D&amp;source=editors&amp;ust=1621854377169000&amp;usg=AOvVaw08XF1wBs0yIaRGeeUx5bMg">https://ONEBOOK</a></span><span
                        className="c0">. Trong trường hợp Khách hàng đã thanh toán trước phí dịch vụ cho nhiều kỳ thì mức phí dịch vụ sẽ không thay đổi trong suốt thời hạn sử dụng dịch vụ mà Khách hàng đã thanh toán.</span>
                    </p><p className="c1"><span className="c0">3.6&nbsp;&nbsp;Khách hàng chịu trách nhiệm thanh toán cho SAS bằng tiền mặt, chuyển khoản hoặc thanh toán trực tuyến thông qua ngân hàng hoặc đối tác thứ ba. Trường hợp thanh toán bằng tiền mặt, khách hàng chỉ thanh toán cho cán bộ của SAS khi có đủ giấy tờ: Giấy giới thiệu&nbsp;của Công ty/Văn phòng đại diện Công ty CP Giải pháp kế toán thông minh về việc nhận tiền mặt (có ghi rõ số tiền), thẻ nhân viên, chứng minh nhân dân hoặc thẻ căn cước của người được ghi trong giấy giới thiệu.</span>
                    </p><p className="c1"><span className="c0">3.7&nbsp;&nbsp;SAS không chấp nhận hoàn trả bất kể chi phí nào mà khách hàng đã thanh toán cho SAS sau khi SAS đã hoàn thành nghĩa vụ bàn giao sản phẩm, dịch vụ cho khách hàng.</span>
                    </p><p className="c14" id="h.3znysh7"><span
                        className="c3">Điều&nbsp;4: Bàn giao sản phẩm, dịch vụ</span></p><p className="c1"><span
                        className="c0">4.1&nbsp;&nbsp;SAS chịu trách nhiệm bàn giao cho khách hàng thông tin truy cập hệ thống qua email, báo cáo định kỳ theo từng gói dịch vụ đăng ký được đính kèm thỏa thuận này. Sản phẩm/báo cáo được hoàn thành sau 5-7 ngày kể từ khi nhận đầy đủ tài liệu của khách hàng.</span>
                    </p><p className="c1"><span className="c0">4.2&nbsp;&nbsp;Khách hàng chịu trách nhiệm đăng nhập vào phần mềm để kiểm tra thời hạn sử dụng theo thông tin truy cập do SAS gửi thông báo qua email. Khi khách hàng đăng nhập vào phần mềm thì xem như SAS đã hoàn thành nghĩa vụ bàn giao sản phẩm và việc tính phí bắt đầu từ thời điểm này.</span>
                    </p><p className="c1"><span className="c0">4.3&nbsp;&nbsp;Khách hàng chịu trách nhiệm chuẩn bị đầy đủ thiết bị, nhân lực và đường truyền theo đúng khuyến cáo của SAS ghi trong phần mềm để tổ chức khai thác, vận hành hệ thống phần mềm.</span>
                    </p><p className="c1"><span
                        className="c0">4.4&nbsp;&nbsp;Khách hàng chịu trách nhiệm tiếp&nbsp;nhận, sử dụng phần mềm đúng theo hướng dẫn và khuyến cáo sử dụng của SAS công bố trong phần mềm.</span>
                    </p><p className="c1"><span className="c0">4.5&nbsp;&nbsp;Khi tiếp nhận bàn giao tài khoản truy cập từ SAS, khách hàng có trách nhiệm thay đổi mật khẩu ngầm định ngay trong lần đầu tiên sử dụng phần mềm.</span>
                    </p><p className="c14" id="h.2et92p0"><span className="c3">Điều&nbsp;5: Tư&nbsp;vấn và hỗ trợ khách hàng</span>
                    </p><p className="c1"><span className="c5">5.1&nbsp;&nbsp;SAS chịu trách nhiệm cung cấp dịch vụ tư vấn hỗ trợ cho Khách hàng sử dụng phần mềm, cung cấp báo cáo dựa trên các chứng từ, tài liệu, thông tin mà khách hàng cung cấp trong phạm vi dịch vụ đã đăng ký sử dụng và thời gian trả phí trong suốt quá trình sử dụng. Phương thức hỗ trợ thông qua tổng đài tư vấn và hỗ trợ khách hàng, email, diễn đàn và các hình thức hỗ trợ khác&nbsp;theo thông tin&nbsp;công bố tại website&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=https://qlth.vn/&amp;sa=D&amp;source=editors&amp;ust=1621854377171000&amp;usg=AOvVaw3MdhE_ye3ON_s-6jqLrL31">https://ONEBOOK</a></span><span
                        className="c0">.</span></p><p className="c1"><span className="c5">5.2&nbsp;&nbsp;Khi sử dụng dịch vụ tư vấn qua tổng đài tư vấn và hỗ trợ khách hàng&nbsp;theo thông tin&nbsp;công bố tại website&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=https://qlth.vn/&amp;sa=D&amp;source=editors&amp;ust=1621854377172000&amp;usg=AOvVaw19DANwYb2PsfjesTV3J5Km">https://ONEBOOK</a></span><span
                        className="c0">, khách hàng chấp nhận trả cước phí điện thoại theo quy định&nbsp;của nhà cung cấp dịch vụ viễn thông.</span>
                    </p><p className="c1"><span className="c5">5.3&nbsp;&nbsp;Khách hàng chủ động xem, khai thác các tài liệu, phim hướng dẫn sử dụng phần mềm đặt tại&nbsp;</span><span
                        className="c5 c11">http://help.ONEBOOK</span><span className="c5">&nbsp;và các trang hỗ trợ khác được công bố tại website&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=https://qlth.vn/&amp;sa=D&amp;source=editors&amp;ust=1621854377173000&amp;usg=AOvVaw1gZbawjyMRer7P_Hq_MxdK">https://ONEBOOK</a></span><span
                        className="c5">&nbsp;hoặc tra cứu các tình huống thường gặp khi sử dụng phần mềm tại&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=https://qlth.vn/&amp;sa=D&amp;source=editors&amp;ust=1621854377173000&amp;usg=AOvVaw1gZbawjyMRer7P_Hq_MxdK">https://ONEBOOK</a></span><span
                        className="c4">.</span></p><p className="c1"><span className="c0">5.4&nbsp;&nbsp;Các dịch vụ tư vấn hỗ trợ thông qua hình thức khác (như dịch vụ tư vấn hỗ trợ tại các địa điểm theo yêu cầu của khách hàng, dịch vụ tái đào tạo hướng dẫn sử dụng cho khách hàng) sẽ được hai bên thống nhất về chi phí và phương thức cung cấp bằng văn bản bổ sung khi có phát sinh yêu cầu.</span>
                    </p><p className="c14" id="h.tyjcwt"><span className="c3">Điều&nbsp;6: Bảo hành, bảo trì</span></p>
                    <p className="c1"><span className="c0">6.1&nbsp;&nbsp;SAS chịu trách nhiệm đảm bảo điều kiện kỹ thuật để khách hàng có thể sử dụng được phần mềm 24h/ngày và 7 ngày/tuần ngoại trừ thời gian bảo trì, nâng cấp, khắc phục sự cố cho hệ thống. Thời gian ngưng hệ thống để bảo trì hoặc nâng cấp hoặc sao lưu sẽ được SAS báo trước lịch thực hiện cho khách hàng theo hình thức thông báo trực tiếp trên&nbsp;phần mềm. Lịch bảo trì hoặc nâng cấp hoặc sao lưu sẽ thực hiện theo định kỳ hàng ngày hoặc hàng tuần hoặc hàng tháng hoặc hàng năm và ưu tiên vào buổi đêm khi hệ thống ít sử dụng nhất.</span>
                    </p><p className="c1"><span className="c0">6.2&nbsp;&nbsp;SAS có trách nhiệm tiến hành khắc phục sự cố của hệ thống chậm nhất là 8h làm việc kể từ khi tiếp nhận được yêu cầu từ người sử dụng của khách hàng.</span>
                    </p><p className="c1"><span className="c0">6.3&nbsp;&nbsp;SAS có trách nhiệm cập nhật phiên bản mới nhất của phần mềm cho khách hàng sử dụng trong thời hạn thuê bao mà khách đã đăng ký và thanh toán cho SAS.</span>
                    </p><p className="c1"><span className="c0">6.4&nbsp;&nbsp;Khách hàng đồng ý chấp nhận tất cả sự vá lỗi, sửa lỗi, nâng cấp, bảo trì cần thiết để các tính năng của dịch vụ hoạt động chính xác và đảm bảo tính bảo mật của dịch vụ. Trừ trường hợp khẩn cấp, SAS sẽ thông báo trước tới khách hàng lịch trình của các hoạt&nbsp;động sửa lỗi, nâng cấp&nbsp;này.</span>
                    </p><p className="c1"><span className="c0">6.5&nbsp;&nbsp;Khi gần hết hạn sử dụng dịch vụ, SAS có trách nhiệm thông báo cho khách hàng trực tiếp trên chính phần mềm ONEBOOK như sau:</span>
                    </p><p className="c14 c18"><span
                        className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trong vòng&nbsp;30&nbsp;ngày trước ngày hết hạn: Thông báo thời hạn còn lại của hợp đồng và hướng dẫn khách hàng thủ tục đăng ký và thanh toán phí gia hạn hợp đồng.</span>
                    </p><p className="c14 c18"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khi hết hạn hợp đồng, SAS sẽ thông báo cho khách hàng biết hợp đồng đã quá hạn và hướng dẫn khách hàng làm thủ tục gia hạn hợp đồng. Khách hàng sẽ có thời gian 30 ngày để tải các dữ liệu và sổ sách, báo cáo phục vụ mục đích lưu trữ;</span>
                    </p><p className="c14 c19"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khi quá&nbsp;hạn trên 30&nbsp;ngày: Thông báo cho khách hàng biết về việc SAS sẽ làm thủ tục cắt dịch vụ và xóa bỏ dữ liệu của khách hàng.</span>
                    </p><p className="c14" id="h.3dy6vkm"><span className="c3">Điều&nbsp;7: Bảo mật</span></p><p
                        className="c1"><span className="c0">7.1&nbsp;&nbsp;SAS chịu trách nhiệm thực hiện và duy trì tất cả các biện pháp bảo vệ mang tính hành chính, vật lý và kỹ thuật để bảo vệ cho tính bảo mật và toàn vẹn đối với dữ&nbsp;liệu khách hàng. SAS cam kết sẽ không:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sửa đổi dữ liệu khách hàng mà không có sự đồng ý của khách hàng hoặc không phải vì mục đích khắc phục lổi hay sự cố;</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Không tiết lộ dữ liệu khách hàng trừ trường hợp phải tuân theo quy định của pháp luật hoặc được khách hàng cho phép;</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Không truy cập vào dữ liệu và/hoặc làm thay đổi dữ liệu của khách hàng trừ trường hợp khắc phục lỗi kỹ thuật hoặc theo yêu cầu của khách hàng khi sử dụng dịch vụ hỗ trợ.</span>
                    </p><p className="c1"><span className="c0">7.2&nbsp;&nbsp;SAS chịu trách nhiệm bảo mật mọi thông tin về dữ liệu của khách hàng và không được phép tiết lộ cho bất kỳ bên thứ ba nào khác ngoại trừ yêu cầu của cơ quan có thẩm quyền của nhà nước. SAS không chịu trách nhiệm về các thất thoát dữ liệu, bí mật thông tin của khách hàng do khách hàng vô tình hoặc cố ý gây ra.</span>
                    </p><p className="c1"><span className="c0">7.3&nbsp;&nbsp;Khách hàng&nbsp;chịu trách nhiệm xác định và xác thực quyền của tất cả những người dùng truy nhập vào dữ liệu của khách hàng.</span>
                    </p><p className="c1"><span className="c0">7.4&nbsp;&nbsp;Khách hàng chịu trách nhiệm đảm bảo bí mật thông tin tài khoản người dùng.</span>
                    </p><p className="c1"><span className="c0">7.5&nbsp;&nbsp;Khách hàng chịu trách nhiệm đối với toàn bộ các hoạt động thực hiện bởi các tài khoản người dùng của khách hàng và có trách nhiệm ngay lập tức thông báo với SAS về các truy cập trái phép.</span>
                    </p><p className="c1"><span className="c0">7.6&nbsp;&nbsp;SAS sẽ không chịu bất cứ trách nhiệm nào liên quan đến các tổn hại gây ra bởi người dùng của khách hàng, bao gồm các cá nhân không có quyền truy cập vào dịch vụ vẫn có thể lấy được quyền truy cập do lỗi máy tính/ phần mềm hoặc hệ thống mạng nội bộ của khách hàng.</span>
                    </p><p className="c1"><span className="c0">7.7&nbsp;&nbsp;Trong phạm vi của thỏa thuận này, “Thông tin bí mật” bao gồm: Dữ liệu của khách hàng, công nghệ độc quyền của mỗi bên, quy trình nghiệp vụ và các thông tin kỹ thuật của sản phẩm, thiết kế, và toàn bộ quá trình trao đổi giữa hai bên liên quan đến dịch vụ. Bất kể những điều đã đề cập ở trên, “Thông tin bí mật” không bao gồm các thông tin mà:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Được công chúng biết tới;</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Được biết tới&nbsp;trong ngành trước khi tiết lộ;</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Được công chúng biết tới không phải do lỗi của bên nhận thông tin;</span>
                    </p><p className="c2"><span className="c0">d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dữ liệu tổng hợp trong đó không chứa bất kỳ thông tin cá nhân hoặc thông tin nào cụ thể của khách hàng.</span>
                    </p><p className="c1"><span className="c0">7.8&nbsp;&nbsp;Khách hàng và SAS cùng thỏa thuận:</span>
                    </p><p className="c2"><span
                        className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thực hiện các biện&nbsp;pháp cần thiết để giữ bí mật cho tất cả các “Thông tin bí mật”;</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Không sao chép, cung cấp một phần hay toàn bộ thông tin bảo mật cho bất kỳ bên thứ ba khi chưa có sự chấp thuận của bên có quyền sở hữu đối với “Thông tin bí mật”;</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Không sử dụng “Thông tin bí&nbsp;mật” mà các bên đã cung cấp cho nhau phục vụ cho các mục đích khác ngoài mục đích thực hiện thỏa thuận này.</span>
                    </p><p className="c14" id="h.1t3h5sf"><span className="c3">Điều&nbsp;8: Bản quyền phần mềm và dữ liệu</span>
                    </p><p className="c13"><span className="c0">8.1&nbsp;&nbsp;SAS là chủ sở hữu và có toàn quyền tác giả phần mềm ONEBOOK.</span>
                    </p><p className="c13"><span className="c0">8.2&nbsp;&nbsp;Khách hàng có quyền sử dụng phần mềm gửi tài liệu, chứng từ lên và nhận sổ sách, báo cáo theo dịch vụ đã đăng ký.</span>
                    </p><p className="c13"><span className="c0">8.3&nbsp;&nbsp;Khách hàng đồng ý rằng sản phẩm/dịch vụ, bao gồm nhưng không giới hạn: giao diện người sử dụng, đoạn âm thanh, đoạn video, nội dung hướng dẫn sử dụng và phần mềm được sử dụng để thực hiện sản phẩm/dịch vụ thuộc sở hữu riêng của SAS được bảo hộ bởi pháp luật về sở hữu trí tuệ và quyền tác giả. Khách hàng thỏa thuận sẽ không sử dụng các thông tin hoặc tài liệu thuộc sở hữu riêng đó theo bất cứ cách thức nào ngoại trừ cho mục đích sử dụng sản phẩm/dịch vụ theo Thỏa thuận này. Không có phần nào trong sản phẩm/dịch vụ có thể được sao chép lại dưới bất kỳ hình thức nào hoặc bằng bất cứ phương tiện nào, trừ khi được cho phép một cách rõ ràng theo các điều khoản này.</span>
                    </p><p className="c13"><span className="c0">8.4&nbsp;&nbsp;Khách hàng đồng ý không sửa đổi, thuê, cho thuê, cho vay, bán, phân phối, hoặc tạo ra các sản phẩm phái sinh dựa trên sản phẩm/dịch vụ theo bất cứ phương cách nào, và không khai thác sản phẩm/dịch vụ theo bất cứ phương thức không được phép nào, bao gồm nhưng không giới hạn ở việc xâm phạm hoặc tạo gánh nặng lên dung lượng của hệ thống mạng.</span>
                    </p><p className="c14 c23"><span className="c0">8.5&nbsp;&nbsp;Việc sử dụng phần mềm hoặc bất cứ phần nào của sản phẩm/dịch vụ, trừ khi việc sử&nbsp;dụng sản phẩm/dịch vụ như được cho phép theo thỏa thuận này, đều bị nghiêm cấm&nbsp;và xâm phạm đến các quyền sở hữu trí tuệ của người khác, và khách hàng có thể&nbsp;phải chịu các hình phạt dân sự và hình sự, bao gồm cả việc bồi thường thiệt hại bằng&nbsp;tiền có thể được áp dụng&nbsp;đối với việc xâm phạm quyền tác giả.</span>
                    </p><p className="c14 c23"><span className="c0">8.6&nbsp; Để SAS có thể cung cấp dịch vụ sử dụng phần mềm cho khách hàng, khách hàng đồng&nbsp;ý cho SAS quyền xử lý và truyền tải dữ liệu của khách hàng.</span>
                    </p><p className="c14" id="h.4d34og8"><span className="c3">Điều 9: Phạm vi giới hạn trách nhiệm của SAS trong sử dụng phần mềm</span>
                    </p><p className="c14 c28"><span className="c0">9.1&nbsp;&nbsp;&nbsp;&nbsp;SAS có quyền nhưng không có nghĩa vụ nào trong việc thực hiện các hành động khắc phục nếu như có bất cứ nội dung nào mà khách hàng vi phạm các điều được liệt kê trong thỏa thuận này. SAS không có bất kỳ trách nhiệm pháp lý nào đối với khách hàng trong các tình huống SAS thực hiện hành động khắc phục. Khách hàng là người duy nhất chịu trách nhiệm về tính chính xác, chất lượng, tính toàn vẹn, hợp pháp, tin cậy và phù hợp đối với tất cả dữ liệu của mình.</span>
                    </p><p className="c14" id="h.2s8eyo1"><span
                        className="c3">Điều 10: Trách nhiệm xử lý sự cố an ninh</span></p><p className="c12"><span
                        className="c5">10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trong trường hợp khách hàng phát hiện ra các sự cố an ninh của phần mềm SAS, khách hàng có trách nhiệm thông báo ngay với SAS bằng cách ấn nút Phản hồi ngay trên giao diện sản phẩm&nbsp;hoặc phản hồi ngay&nbsp;qua các kênh tư vấn và hỗ trợ khách hàng&nbsp;hoặc&nbsp;gọi điện đến tổng đài&nbsp;tư vấn và hỗ trợ khách hàng theo thông tin&nbsp;công bố trên website&nbsp;</span><span
                        className="c5 c10">https://ONEBOOK.</span><span className="c0">&nbsp;Các sự cố an ninh phần mềm bao gồm nhưng không giới hạn bởi các trường hợp sau:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bị mất hoặc thay đổi dữ liệu trên phần mềm mà không biết nguyên nhân.</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bị gián đoạn không sử dụng được sản phẩm.</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nghi ngờ bị hacker tấn công.</span>
                    </p><p className="c12"><span className="c0">10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khi xảy ra sự cố an ninh thông tin liên quan đến sản phẩm SAS cung cấp cho khách hàng, SAS sẽ có trách nhiệm tổ chức điều tra&nbsp;để xử lý sự cố và khôi phục hoạt động cho khách hàng. Trong quá trình điều tra và khắc phục sự cố, khách hàng có trách nhiệm tham gia nếu SAS có thể yêu cầu.</span>
                    </p><p className="c24" id="h.17dp8vu"><span
                        className="c7 c21">Điều 10. Dịch vụ được cung cấp bởi&nbsp;đối tác thứ ba</span></p><p
                        className="c14"><span className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1&nbsp;&nbsp;Khách hàng có quyền sử dụng các dịch vụ của đối tác thứ ba mà SAS đã kết nối&nbsp;trong thời hạn Hợp đồng có hiệu lực.</span>
                    </p><p className="c14"><span
                        className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.2&nbsp;&nbsp; Khách hàng cần hiểu rằng, SAS cung cấp các dịch vụ của đối tác thứ ba chỉ nhằm&nbsp;mục đích tăng thêm các tiện ích cho khách hàng khi sử dụng&nbsp;phần mềm&nbsp;của SAS.&nbsp;Khách hàng có thể tùy chọn sử dụng hoặc không sử dụng các dịch vụ của đối tác thứ&nbsp;ba.</span>
                    </p><p className="c14"><span
                        className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.3&nbsp;&nbsp; Trong suốt quá trình sử dụng các dịch vụ của đối tác thứ ba, khách hàng cần tuân thủ&nbsp;các điều kiện, quy định, hướng dẫn sử dụng của đối tác thứ ba khi cung cấp dịch vụ&nbsp;cho khách hàng; hướng dẫn của SAS công bố trong&nbsp;phần mềm&nbsp;và theo quy định của&nbsp;pháp luật hiện hành.</span>
                    </p><p className="c9"><span
                        className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; 10.4&nbsp;&nbsp;&nbsp;&nbsp;Khi có các vướng mắc liên quan đến giao dịch thanh toán, tra cứu số dư,..., khách hàng cần chủ động liên hệ với đối tác thứ ba để được hỗ trợ.</span>
                    </p><p className="c14"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.5&nbsp;&nbsp;Khi có các vướng mắc liên quan đến thao tác sử dụng các tính năng kết nối dịch&nbsp;vụ của đối tác thứ ba trên&nbsp;phần mềm&nbsp;như đã nêu trong tài liệu hướng dẫn sử dụng thì&nbsp;Khách hàng chủ động liên hệ với SAS thông qua&nbsp;các kênh hỗ trợ SAS đã công bố&nbsp;trên website&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=https://www.cukcuk.com/&amp;sa=D&amp;source=editors&amp;ust=1621854377181000&amp;usg=AOvVaw3M2YAfyjAXYGqX93rv9G1F">https://www.</a></span><span
                        className="c5 c10">ONEBOOK</span></p><p className="c14"><span
                        className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.6&nbsp;&nbsp;&nbsp;Trong trường hợp vì bất kỳ lý do gì mà việc vi phạm của khách hàng dẫn tới hậu quả,&nbsp;khách hàng bị xử phạt hành chính hoặc hình sự thì khách hàng phải tự chịu trách&nbsp;về việc vi phạm của mình.</span>
                    </p><p className="c14"><span className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.7&nbsp;&nbsp;Trong bất kỳ trường hợp nào SAS đều không chịu trách nhiệm về bất kỳ thiệt hại&nbsp;trực tiếp, gián tiếp, ngẫu nhiên khi khách hàng sử dụng dịch vụ do đối tác thứ ba cung&nbsp;cấp trên phần mềm.</span>
                    </p><p className="c27"><span className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.8&nbsp;&nbsp;&nbsp;Đối với các sự cố, nâng cấp từ hệ thống của đối tác thứ ba thì thời gian khắc phục,&nbsp;nâng cấp sẽ theo thông báo của đối tác thứ ba.</span>
                    </p><p className="c14" id="h.3rdcrjn"><span className="c3">Điều 11: Trường hợp bất khả kháng</span>
                    </p><p className="c14"><span className="c0">Trong trường hợp bất khả kháng hai bên không có nghĩa vụ phải thực hiện trách&nbsp;nhiệm của mình trong thỏa thuận này. Hai bên nhất trí coi các trường hợp sau là bất khả kháng:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thiên tai, địch họa gây cách trở hoặc phá hủy hoặc tắc nghẽn hoặc dừng kết nối đến trung tâm dữ liệu của SAS.</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sự cố mất điện trên diện rộng; Sự cố đứt cáp viễn&nbsp;thông gây tắc nghẽn hoặc ngừng kết nối viễn thông, Internet đến trung tâm dữ liệu của SAS.</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tin tặc (hacker), vi rút máy tính (virus) tấn công vào trung tâm dữ liệu của SAS làm ngừng trệ, tắc nghẽn hoặc phá hủy phần mềm và dữ liệu.</span>
                    </p><p className="c2"><span className="c0">d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Các sự cố bất khả kháng khác theo quy định của pháp luật.</span>
                    </p><p className="c14" id="h.26in1rg"><span
                        className="c3">Điều 12: Tạm ngừng và chấm dứt thỏa thuận</span></p><p className="c6"><span
                        className="c0">12.1&nbsp;&nbsp;Thỏa thuận này bắt đầu kể từ ngày khách hàng đồng ý và chấm dứt khi tất cả các dịch vụ được cấp kèm theo thỏa thuận này hết hạn sử dụng. Đối với trường hợp khách hàng dùng thử&nbsp;sản phẩm/dịch vụ mà không chuyển sang hình thức thuê bao trước khi hết hạn dùng thử, thỏa thuận này sẽ được chấm dứt khi hết hạn dùng thử.</span>
                    </p><p className="c6"><span className="c0">12.2&nbsp;&nbsp;SAS có quyền tạm ngừng việc sử dụng của khách hàng đối với dịch vụ trong các trường hợp sau:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng không thực&nbsp;hiện việc đăng ký gia hạn và thanh toán các khoản chi phí sử dụng sản phẩm/dịch vụ sau khi quá hạn 60 ngày.</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SAS cho rằng dịch vụ đang được khách hàng sử dụng để tham gia vào các cuộc tấn công từ chối dịch vụ, gửi thư rác, các hoạt động bất hợp pháp hoặc việc sử dụng sản phẩm/dịch vụ của khách hàng gây nguy hại tới SAS và những người khác.</span>
                    </p><p className="c6"><span className="c0">12.3&nbsp;&nbsp;Thỏa thuận được coi như chấm dứt trong các trường hợp sau:</span>
                    </p><p className="c2"><span className="c0">a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SAS đơn phương chấm dứt thỏa thuận do khách hàng không thực hiện nghĩa vụ thanh toán cho SAS theo thỏa thuận giữa hai bên.</span>
                    </p><p className="c2"><span className="c0">b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SAS đơn phương chấm dứt thỏa thuận theo yêu cầu của tòa án và cơ quan có thẩm quyền của nhà nước.</span>
                    </p><p className="c2"><span className="c0">c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Khách hàng gửi thông báo yêu cầu chấm dứt thỏa thuận thuê bao cho SAS bằng văn bản.</span>
                    </p><p className="c6"><span className="c0">12.4&nbsp;&nbsp;SAS không có nghĩa vụ hoàn trả bất kể chi phí nào mà khách hàng đã thanh toán trong trường hợp chấm dứt thỏa thuận vì những lý do đã nêu trên. SAS chỉ chịu trách nhiệm bảo đảm duy trì dữ liệu của khách hàng trên hệ thống tối đa là 30 ngày kể từ ngày chấm dứt thỏa thuận.</span>
                    </p><p className="c14" id="h.lnxbz9"><span className="c3">Điều 13: Điều khoản sửa đổi, bổ sung thỏa thuận</span>
                    </p><p className="c14 c16"><span className="c0">13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SAS có quyền sửa đổi, bổ sung các điều khoản trong thỏa thuận sử dụng dịch vụ và được công bố trên website&nbsp;https://ONEBOOK và trên phần mềm.</span>
                    </p><p className="c14 c16"><span className="c0">13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trường hợp khách hàng không đồng ý với các điều khoản sửa đổi, bổ sung đó, Khách hàng có thể ngừng sử dụng phần mềm.</span>
                    </p><p className="c14" id="h.35nkun2"><span className="c3">Điều&nbsp;14: Căn cứ pháp lý</span></p><p
                        className="c14 c22"><span className="c5">14.1&nbsp;&nbsp;&nbsp;&nbsp;Căn cứ&nbsp;</span><span
                        className="c4"><a className="c8"
                                          href="https://www.google.com/url?q=http://thuvienphapluat.vn/van-ban/Quyen-dan-su/Bo-luat-dan-su-2015-296215.aspx&amp;sa=D&amp;source=editors&amp;ust=1621854377184000&amp;usg=AOvVaw2bYApIf-zLVlHtqMiXjlAI">Bộ luật dân sự số 91/2015/QH13</a></span>
                    </p><p className="c14 c20" id="h.1ksv4uv"><span
                        className="c5">14.2&nbsp;&nbsp;&nbsp;Căn cứ&nbsp;</span><span className="c4"><a className="c8"
                                                                                                        href="https://www.google.com/url?q=http://moj.gov.vn/vbpq/lists/vn%2520bn%2520php%2520lut/view_detail.aspx?itemid%3D18140&amp;sa=D&amp;source=editors&amp;ust=1621854377185000&amp;usg=AOvVaw0ghXgkv9hNX-awVWdWj7x3">Luật thương mại số 36/2005/QH11 ngày 14/6/2005</a></span>
                    </p><p className="c14 c26" id="h.44sinio"><span
                        className="c5">14.3&nbsp;&nbsp;&nbsp;Căn cứ&nbsp;</span><span className="c4"><a className="c8"
                                                                                                        href="https://www.google.com/url?q=http://moj.gov.vn/vbpq/lists/vn%2520bn%2520php%2520lut/view_detail.aspx?itemid%3D15084&amp;sa=D&amp;source=editors&amp;ust=1621854377186000&amp;usg=AOvVaw0MXnQ1XRsXPC0E0-6VZao3">Luật Công nghệ thông tin&nbsp;</a></span><span
                        className="c4">số 67/2006/QH11 ngày 29/06/2006</span></p><p className="c14 c20"><span
                        className="c0">14.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Căn cứ vào nhu cầu của hai bên.</span></p><p
                        className="c14" id="h.2jxsxqh"><span className="c3">Điều 15:&nbsp;Điều khoản chung</span></p><p
                        className="c14 c16"><span className="c0">15.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trong quá trình thực hiện thỏa&nbsp;thuận nếu có vấn đề gì nảy sinh thì hai bên sẽ cùng bàn bạc, thống nhất và tìm giải pháp khắc phục.</span>
                    </p><p className="c14 c16"><span className="c5">15.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trong trường hợp nảy sinh tranh chấp mà hai bên không thể cùng nhau thương lượng giải quyết được thì hai bên&nbsp;cùng thống nhất mang ra Tòa án có thẩm quyền trên địa bàn TP Hà Nội để giải quyết.</span>
                    </p></body>
                </div>
            </div>
        );
    }
}
export default DieuKhoan;
