import React from 'react'
import Pagination from '../../../components/Pagination'
import TableHeader from '../../../components/TableHeader';
import NumberFormat from "react-number-format";
import moment from 'moment';
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Autosuggestion from '../../../components/Form/Autosuggestion';
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import CompanyApi from "../../../services/ob-user/company";

import ModalResetPass from '../../../components/Modal/admin/modalResetPassCustomer'
import {checkPermission} from "../../../utils/common";

const dateFormat = 'DD-MM-YYYY';

class CustomerManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPartner: {},
            selectedCustomer: {},
            listData: [],
            partner: '',
            partner_temp: '',
            tax: '',
            pageNumber: 0,
            pageSize: 10,
            totalItem: 50,
            fullName: "",
            fullName_temp: "",
            clearSearchCus: false,
            showModalReset: false,

            header: [
                {
                    id: 'index',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'fullName',
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'phone',
                    name: "Số điện thoại",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'tax',
                    name: "Mã số thuế",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: '',
                    name: "Thao tác",
                    style: {minWidth: 160, textAlign: "center"},
                    sort: false,
                },
            ],
        }
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.searchListCustomerFromAPI()
        })
    }


    searchListCustomerFromAPI = () => {
        let {pageNumber, pageSize, fullName, sort, selectedCustomer} = this.state;
        let payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            // fullName: fullName,
            companyId: selectedCustomer?.id,
            sort: sort,
        }
        SecureCustomerApi.getListCustomer(payload, res => {
            if (res.success) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize),
                    showLoading: false
                }, () => {
                    if (res.data.length < 1) this.setState({
                        showLoading: false
                    })
                })
            } else {
                this.setState({
                    showLoading: false
                }, () => toast.error("Đã có lỗi xảy ra!"))
            }
        })
    }

    searchListCustomer = () => {
        let {fullName, fullName_temp, selectedCustomer} = this.state;
        let payload = {
            pageNumber: 0,
            pageSize: 10,
            companyName: fullName_temp,
        }
        // SecureCustomerApi.getListCustomer(payload, res => {
        //     if (res.success) {
        //         this.setState({
        //             listDataSearch: res.data.content,
        //         })
        //     }
        // })
        CompanyApi.autocompleteGetCompanyName(payload, res => {
            if (res.success) {
                this.setState({
                    listDataSearch: res.data.content,
                })
            }
        })
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1,
            },
            () => {
                this.searchListCustomerFromAPI()
            }
        );
    }

    //Button***********************************************************************************************************

    // onSearch = () => {
    //     let {fullName_temp,selectedCustomer} = this.state;
    //     this.setState(
    //         {
    //             fullName: selectedCustomer.fullName,
    //             pageNumber: 0,
    //             pageSize: 10,
    //             // selectedCustomer: selectedCustomer?.id,

    //         },
    //         () => {
    //             this.searchListCustomerFromAPI();
    //         }
    //     );
    // }


    onRefresh = () => {
        this.setState(
            {
                fullName_temp: "",
                fullName: "",
                pageSize: 10,
                pageNumber: 0,
                selectedCustomer: null
                // clearSearchCus: true,
            },
            () => this.searchListCustomerFromAPI()
        );
    }

    //****************************************************************************************************************

    renderStatus(status) {
        switch (status) {
            case "ACTIVE":
                return <h6><span className="badge badge-success">Đang hoạt động</span></h6>;
            case "LOCK":
                return <h6><span className="badge badge-warning">Đã khóa</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã xóa</span></h6>;
            case "WAIT_COMPLETE_INFO":
                return <h6><span className="badge badge-dark">Chờ hoàn thiện thông tin</span></h6>;
            case "WAIT_CONFIRM_EMAIL":
                return <h6><span className="badge badge-dark">Chờ xác nhận email</span></h6>;
            default:
                return;
        }
    }

//*********************************************************************************************************************

    render() {
        let {pageNumber, pageSize} = this.state
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="customer-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className='pt-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Quản lý khách hàng"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Tên khách hàng</label>
                                                {/*<input*/}
                                                {/*    placeholder="Nhập tên khách hàng..."*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control shadow-none font-14"*/}
                                                {/*    value={this.state.fullName_temp}*/}
                                                {/*    onKeyPress={e => {*/}
                                                {/*        if (e.key === "Enter")*/}
                                                {/*            this.setState({*/}
                                                {/*                fullName: this.state.fullName_temp,*/}
                                                {/*                pageNumber: 0,*/}
                                                {/*                pageSize: 10,*/}
                                                {/*            }, () => {*/}
                                                {/*                this.searchListCustomerFromAPI();*/}
                                                {/*            })*/}
                                                {/*    }}*/}
                                                {/*    onChange={val =>*/}
                                                {/*        this.setState({fullName_temp: val.target.value})*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                                <Autosuggestion
                                                    label=""
                                                    placeholder="Nhập tên khách hàng"
                                                    type="text"
                                                    value={this.state.fullName_temp}
                                                    clearSearch={this.state.clearSearchCus}
                                                    data={this.state.listDataSearch}
                                                    onSelect={(item, val) => {
                                                        this.setState({selectedCustomer: item}, () => console.log("check>>ss", this.state.selectedCustomer))
                                                    }}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            fullName_temp: val,
                                                            selectedCustomer: null,
                                                            clearSearchCus: false,
                                                        }, () => this.searchListCustomer())
                                                        // this.setState({ value:val.target.value,selectedCustomer: null})
                                                    }}
                                                    checkError={!this.state.listData}
                                                    errorContent="Vui lòng chọn một khách hàng"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                            <div className="button-filter">

                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => {
                                                        this.setState({
                                                            fullName: this.state.fullName_temp,
                                                            pageNumber: 0,
                                                            pageSize: 10,
                                                        }, () => this.searchListCustomerFromAPI())
                                                    }}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                                {/*<Card className="kt-portlet">*/}
                                {/*    <Card.Body className="kt-portlet__body">*/}
                                <div className="table-wrapper">
                                    <div className="text-align-right mb-2">Tổng số khách
                                        hàng: {this.state.totalItem}</div>
                                    <table className="table table-striped table-data table-bordered">
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            let a = '';
                                            if (data.sortAsc == null) {
                                                a = 'status'
                                            } else {
                                                a = `${data.sortId}=${data.sortAsc}`;
                                            }
                                            // console.log(a);
                                            this.setState({
                                                sort: a
                                            }, () => this.searchListCustomerFromAPI())
                                        }}/>
                                        </thead>
                                        {!this.state.listData || this.state.listData.length == 0
                                            ? <tbody>
                                            <tr>
                                                <td colSpan={12}>
                                                    <div className='text-center'>Không có dữ liệu</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : <tbody>
                                            {this.state.listData.map((row, index) => (
                                                <tr key={row.id} style={{height: 50}}>
                                                    <td style={{textAlign: 'center'}}>{pageNumber * pageSize + index + 1}</td>
                                                    <td style={{textAlign: 'left',}}>
                                                        {row.fullName}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{row.transactionContent}*/}
                                                        {row.phone}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {row.tax}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{this.renderType(row.transactionType)}*/}
                                                        {this.renderStatus(row.status)}
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        {/*{moment(row.cmnCreatedAt).format(dateFormat)}*/}
                                                        {checkPermission("view_customer_detail") &&
                                                        <Link
                                                            to={{
                                                                pathname: "./customer-details",
                                                                search: `?customerId=${row.id}&edit=0`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    customerId: row.customerId,
                                                                    // onEdit: false
                                                                }
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xem chi tiết"
                                                                onClick={() => {

                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission("edit_customer_information") &&
                                                        <Link
                                                            to={{
                                                                pathname: "./customer-details",
                                                                search: `?customerId=${row.id}&edit=1`,
                                                                state: {
                                                                    fromDashboard: true,
                                                                    customerId: row.customerId,
                                                                }
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-action"
                                                                title="Sửa"
                                                            >
                                                                <i className="ti-pencil-alt"/>
                                                            </button>
                                                        </Link>
                                                        }
                                                        {checkPermission("reset_customer_account_password") &&
                                                        <button
                                                            className="btn btn-action"
                                                            title="Reset"
                                                            onClick={() => {
                                                                this.setState({
                                                                    itemSelected: row.id,
                                                                    nameUser: row.fullName
                                                                }, () => this.setState({showModalReset: true}))
                                                            }}
                                                        >
                                                            <i className="ti-reload"/>
                                                        </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>}
                                    </table>
                                </div>
                                <div className="pagination-right">
                                    <Pagination
                                        activePage={this.state.pageNumber + 1}
                                        itemsCountPerPage={this.state.pageSize}
                                        totalItemsCount={this.state.totalItem}
                                        changeHandler={(page) => this.handleChangePage(page)}
                                    />
                                </div>
                                {/*    </Card.Body>*/}
                                {/*</Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResetPass
                    show={this.state.showModalReset}
                    onHide={() => this.setState({showModalReset: false})}
                    id={this.state.itemSelected}
                    name={this.state.nameUser}
                />
            </div>
        )
    }
}

export default CustomerManagement
