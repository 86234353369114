import React from 'react';
// import {withSnackbar} from "notistack";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal.css"
import {
    Modal,
} from 'react-bootstrap';

import SupervisorApi from "../../services/ob-user/supervisor";
import BranchApi from "../../services/ob-user/branch";

toast.configure()

class ModalAddSupToBranch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            showAdd: true,
            // id: this.props.data.id,
            branchId: 0,
            listBranch: [],
        };
    }

    componentDidMount() {
        this.getListBranch()
    }

    getListBranch = () => {
        let profile = JSON.parse(localStorage.getItem("ob_cl_userDetail"));
        let payload = {
            id: profile.id
        }
        BranchApi.getListById(payload, res => {
            if (res.success) {
                this.setState({
                    listBranch: res.data,
                })
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    }

    onSubmit = () => {
        let {id, branchId} = this.state;
        let payload = {
            branchId: branchId,
            supervisorId: this.props.supervisor.id
        };
        BranchApi.addSupervisorToBranch(payload, res => {
            if (!res.success) {
                return toast.error("Thao tác thất bại. Vui lòng thử lại!")
            } else {
                this.props.onRefresh();
                // this.props.onHide();
                return (toast.success(`Gán tài khoản supervisor ${this.props.supervisor.fullName} thành công!`))
            }
        })
    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>CẬP NHẬT SUPERVISOR VÀO CHI NHÁNH</b>
                            </h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                    <label className="col-5 label-item pl-0">Tên chi nhánh</label>
                                    <select
                                        value={this.state.branchId}
                                        defaultValue={0}
                                        onChange={val =>{
                                            console.log(val.target.value)
                                            this.setState({branchId: val.target.value})
                                        }

                                        }
                                        className="form-control kt-input pd-4-input shadow-none">
                                        <option value={0} hidden>Chọn chi nhánh</option>
                                        {this.state.listBranch.map((item, index) =>
                                            <option value={item.id} >{item.branchName}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary"
                                    // disabled={this.state.reason?.length == 0}
                                    onClick={() => {
                                        this.onSubmit()
                                    }}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s pr-2 mr-2 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalAddSupToBranch
