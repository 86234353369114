import React from 'react';
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import AccountantApi from "../../services/ob-user/accountant";
import {toast} from "react-toastify";

class DeleteAccountant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reason:'',
            showBlock: true,
            signupInfo: null,
        };
    }
    componentDidMount() {
        this.setState({oldpassword:'',newpassword:'',repassword:'',});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.show){
            this.setState({oldpassword:'',newpassword:'',repassword:''});
        }
    }

    onSubmit =()=>{
        if(this.state.oldpassword.length <6 || this.state.newpassword.length <6 || this.state.repassword.length <6 ){
            toast.error('Mật khẩu không được nhỏ hơn 6 kí tự !')
        }else if(this.state.newpassword != this.state.repassword){
            toast.error('Nhập lại mật khẩu chưa chính xác !')
        }else if(this.state.oldpassword == this.state.newpassword){
            toast.error("Mật khẩu mới không được giống mật khẩu cũ !")
        }else {
            this.changePassword()
        }
    }

    deleteAccountant = () => {
        let payload = {
            id: this.props.id,
            reason:this.state.reason,
        }
        AccountantApi.deleteSupervisor(payload, res => {
            if (res.errorCode === "One-Book-User.E000017") {
                toast.error(res.message[0])
            }
            else
            if (res.success) {
                toast.success("Xoá tài khoản thành công !")
                this.props.onHide();
                this.props.onRefresh();
            }
            else {
                toast.error("Có lỗi xảy ra !")
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize:20,}}><b>LÝ DO XÓA TÀI KHOẢN</b></h1>
                        </div>

                        <div >
                            <label>Vui lòng nhập lý do xóa tài khoản kế toán:</label>
                            <textarea
                                rows={5}
                                className="form-control shadow-none"
                                style={{resize: 'none'}}
                                placeholder={'Nhập vào lý do xóa tài khoản kế toán'}
                                maxLength={250}
                                value={this.state.reason}
                                onChange={(event) => {
                                    this.setState({reason: event.target.value})
                                }}
                            />
                        </div>

                        <div container item xs={12} >
                            <div item xs={12}>
                                <div className="align-right mt-3 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.reason?.length == 0}
                                        onClick={() => this.deleteAccountant()}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Xác nhận
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                        // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }
                                        onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default DeleteAccountant;
