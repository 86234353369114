import React, {Component} from "react";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import {Link} from "react-router-dom";
import ModalRatingAccountant from "../../../components/Modal/modalRatingAccountant";


const dataFake = [
    {
        id: 1,
        code: 'HD001',
        content: 'Hợp đồng ký kết để làm màu, nhưng tất cả đều thấy rất professional',
        dateRelease: '20-02-2020'
    },
    {
        id: 2,
        code: 'HD002',
        content: 'Hợp đồng ký kết để làm màu, nhưng tất cả đều thấy rất professional',
        dateRelease: '25-02-2020'
    },
    {
        id: 3,
        code: 'HD003',
        content: 'Hợp đồng ký kết để làm màu, nhưng tất cả đều thấy rất professional',
        dateRelease: '10-01-2020'
    },
    {
        id: 4,
        code: 'HD004',
        content: 'Hợp đồng ký kết để làm màu, nhưng tất cả đều thấy rất professional',
        dateRelease: '20-02-2020'
    },
    {
        id: 5,
        code: 'HD005',
        content: 'Hợp đồng ký kết để làm màu, nhưng tất cả đều thấy rất professional',
        dateRelease: '12-02-2020'
    }
];

class AccountantRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRate: false,
            itemSelected: {},
            data: [],
            page: 0,
            pageSize: 10,
            totalItem: 0,
            totalPage: 0,
            sort: 'id=asc',

            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: 'code',
                    name: "Mã hợp đồng",
                    style: {minWidth: 80, textAlign: "left"},
                    sort: true,
                },
                {
                    id: 'content',
                    name: "Nội dung hợp đồng",
                    style: {minWidth: 220, textAlign: "left"},
                    sort: true,
                },
                {
                    id: 'dateRelease',
                    name: "Thời điểm thanh lý HĐ",
                    style: {minWidth: 60, textAlign: "center"},
                    sort: true,
                },
                {
                    id: 'action',
                    name: "Đánh giá",
                    style: {minWidth: 50, textAlign: "center"},
                    sort: false,
                },

            ],
        }
    }

    componentDidMount() {
        // this.getAllServicePlan();
        this.getListAccountantOnSearch()
    }

    getListAccountantOnSearch() {
        let {page, pageSize, status, sort} = this.state;
        let payload = {
            pageNumber: page,
            pageSize: pageSize,
            sort
        };

    }

    handleVote = (item) => {
        this.setState({
            itemSelected: item,
            showRate: true
        })
    };


    render() {
        const {page, pageSize, totalItem} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="list-ordered-service"/>
                        <div className="col-md-9 tab-content">
                            <div className="border mb-4">
                                <div className='p-3 mb-4'>
                                    <PageHeader routerEnable={true} title="Đánh giá kế toán"/>
                                </div>
                                <div
                                    className='mb-2 w-100'
                                    style={{
                                        overflowX: 'auto',
                                    }}
                                >
                                    <table
                                        className="table table-striped table-data table-bordered"
                                    >
                                        <thead>
                                        <TableHeader header={this.state.header} clickSort={(data) => {
                                            // console.log(data);
                                            // let a = '';
                                            // if (data.sortAsc == null) {
                                            //     a = 'id_sort=asc'
                                            // } else {
                                            //     if (data.sortId == 'supervisor') {
                                            //         a = `supervisor.fullName=${data.sortAsc ? 'asc' : 'desc'}`;
                                            //     } else if (data.sortId == 'servicePlan') {
                                            //         a = `servicePlan.servicePlanName=${data.sortAsc ? 'asc' : 'desc'}`;
                                            //     } else {
                                            //         a = `${data.sortId}=${data.sortAsc ? 'asc' : 'desc'}`;
                                            //     }
                                            // }
                                            // // console.log(a);
                                            // this.setState({
                                            //     sort: a
                                            // }, () => this.getListAccountantOnSearch())
                                        }}/>
                                        </thead>
                                        <tbody>
                                        {dataFake.map((row, index) => (
                                            <tr key={row.id} style={{height: 50}}>
                                                <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                <td style={{textAlign: 'left',}}>
                                                    {/*<span style={{cursor: 'pointer', color: '#1b90bd'}}>*/}
                                                    {row.code}
                                                    {/*</span>*/}
                                                </td>
                                                <td style={{textAlign: 'left'}}>{row.content}</td>
                                                <td style={{textAlign: 'center'}}>
                                                    {row.dateRelease}
                                                </td>
                                                <td style={{textAlign: 'center', fontSize: 18}}>
                                                    {
                                                        <span
                                                            title={'Đánh giá'}
                                                            onClick={() => this.handleVote(row)}
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                        <i className="fas fa-star-half-alt"/>
                                                    </span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="row d-flex justify-content-end m-3 font-14">
                                        <span className='d-flex'>
                                            <label className='mt-2'>Số kết quả trên trang:</label>
                                            <select
                                                className="form-control p-0 pl-2 pr-2 w-25 ml-2 mt-1 select-paging"
                                                aria-describedby="inputGroup-sizing-sm"
                                            >
                                                <option value='10'>10</option>
                                                <option value='20'>20</option>
                                                <option value='30'>30</option>
                                                <option value='40'>40</option>
                                            </select>
                                            <label
                                                className='mt-2 ml-4 mr-4'>{page + 1} - {(page + 1) * pageSize} / {totalItem}</label>
                                            <span className='cursor-pointer'>
                                                <i className="fas fa-angle-left icon-paging pl-2 pr-2"/>
                                            </span>
                                            &nbsp;
                                            <span className='cursor-pointer'>
                                                <i className="fas fa-angle-right icon-paging pl-2 pr-2"/>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {this.state.showRate && <ModalRatingAccountant itemSelected={this.state.itemSelected}
                                                          onHide={() => this.setState({showRate: false})}/>}
            </div>
        );
    }

}

export default AccountantRating;
