import React, {Component} from "react";
import './index.css'

import Sidebar from "../../../components/Sidebar";
import DetailSupervisor from './modalDetailSupervisor';
import PageHeader from "../../../components/PageHeader";
import {
    TextField,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SupervisorApi from "../../../services/ob-user/supervisor";
import Pagination from "../../../components/Pagination";
import {URL_IMG_PUB} from "../../../url.config";
import PartnerApi from "../../../services/ob-user/partner";
import {toast} from "react-toastify";

class Supervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetail: false,
            supervisorName: '',
            address: '',
            idAddress: '',
            areaId: "",
            data: [],
            onSearching: false,
            showLoading: false,
            supervisorTop: [],
            listProvince: JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [],

            page: 0,
            pageSize: 9,
            totalItem: 0,
            totalPage: 0,
            supSelected: {},

            idRadio: '',
            sort: 'fullName=desc'
        };
    };

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            this.getTopSup();
        })
    };

    getTopSup() {
        let payload = {
            pageNumber: 0,
            pageSize: 1000,
        }
        SupervisorApi.searchByFullNameAndAddress(payload, (res) => {
            if (res.success) {

                this.setState({
                    supervisorTop: res.data.content.sort(function (a, b) {
                        return b.vote - a.vote;
                    }).slice(0, 3)
                }, () => {
                    this.getListSupOnSearch()
                });
            }
        })
    };

    getListSupOnSearch() {
        let {
            page,
            pageSize,
            supervisorName,
            idAddress,
            sort
        } = this.state;

        if (supervisorName === '' && idAddress === '') {
            this.setState({
                onSearching: false
            })
        } else {
            this.setState({
                onSearching: true
            })
        }
        let payload = {
            pageNumber: page,
            pageSize,
            fullName: supervisorName,
            areaId: idAddress,
            sort
        }
        SupervisorApi.searchByFullNameAndAddress(payload, (res) => {
            if (res.success) {
                let listPartnerId = []
                let data = res.data.content
                data.map(item => {
                    listPartnerId.push(item.partnerId)
                })
                this.setState({
                    // xử lý loại từ những kết quả thuộc supervisor top khi không ở trạng thái tìm kiếm, khi tìm kiếm thì show tất cả kết quả và ẩn danh sách supervisor top
                    // data: this.state.onSearching ? res.data.content : res.data.content.filter(element => element.id != this.state.supervisorTop[0].id
                    //     && element.id != this.state.supervisorTop[1].id
                    //     && element.id != this.state.supervisorTop[2].id
                    // ),
                    // totalItem: this.state.onSearching ? res.data.totalElements : res.data.totalElements - 3,
                    // totalPage: this.state.onSearching ? Math.round((res.data.totalElements) / this.state.pageSize) : Math.round((res.data.totalElements - 3) / this.state.pageSize)
                    listPartnerId,
                    data:res.data.content,
                    totalItem:res.data.totalElements,
                    totalPage:Math.round((res.data.totalElements) / this.state.pageSize),
                    showLoading: false,
                }, () => this.getListPartnerByIds());
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        })
    };

    getListPartnerByIds() {
        let {listPartnerId, data, supervisorTop} = this.state;

        let payload = {
            ids: listPartnerId
        };
        PartnerApi.getNamesByListId(payload, res => {
            if (res.success) {
                let listData = res.data;
                supervisorTop.map(item => {
                    let filter = listData.filter(x => x.id == item.partnerId);
                    item.partnerName = filter[0]?.fullName;
                });
                data.map(item => {
                    let filter = listData.filter(x => x.id == item.partnerId);
                    item.partnerName = filter[0]?.fullName;
                });
                this.setState({
                    data,
                    supervisorTop
                }, () => console.log("check>>>>", data));
            } else {
                toast.error("Đã xảy ra lỗi, vui lòng tải lại trang!")
            }
        });
    }



    handleShowDetail(item) {
        this.setState({
            supSelected: item
        }, () => {
            this.setState({
                showDetail: true
            })
        })
    }

    handleChangeRadio(item) {
        if (this.state.idRadio !== item.id) {
            this.setState({
                idRadio: item.id
            })
        } else this.setState({idRadio: ''})
    }

    handleChangePage = page => {
        this.setState(
            {
                page: page - 1,
                showLoading: true,
            },
            () => {
                this.getListSupOnSearch();
            }
        );
    };

    render() {
        const {page, pageSize, totalItem, supervisorName, listProvince, address, supervisorTop} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="supervisor"/>
                        <div className="col-md-10 tab-content with-loading">
                            {this.state.showLoading &&
                            <div className="content-loading col-12 ml-3 align-items-center"
                                 style={{display: 'block'}}>
                                <div className="spinner-grow text-info"/>
                            </div>
                            }
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách supervisor"/>
                                    <div className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-xs-12 col-6 pl-0">
                                                {/*<div className="kt-margin-b-10-tablet-and-mobile">*/}
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                    label={'Tên supervisor'}
                                                    size='small'
                                                    value={supervisorName}
                                                    variant={"outlined"}
                                                    // InputProps={{style: {height: 28}}}
                                                    placeholder='E.g: Nguyễn Văn An'
                                                    onChange={(val) => {
                                                        this.setState({supervisorName: val.target.value})
                                                    }}
                                                />
                                                {/*</div>*/}
                                            </div>
                                            <div className="col-xs-12 col-6 pr-0">
                                                {/*<div className="kt-margin-b-10-tablet-and-mobile">*/}
                                                <Autocomplete
                                                    size="small"
                                                    options={listProvince.filter(value => value.status === "ACTIVE")}
                                                    getOptionLabel={option => option.name}
                                                    style={{width: '100%'}}
                                                    inputValue={address}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            label={'Khu vực'}
                                                            placeholder='Hà Nội'
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onInputChange={(val) => {
                                                        this.setState({
                                                            address: val.target.value
                                                        })
                                                    }}
                                                    disableClearable={true}
                                                    onChange={(c, val) => {
                                                        this.setState({
                                                            address: val.name,
                                                            idAddress: val.id
                                                        })
                                                    }}
                                                />
                                                {/*</div>*/}
                                            </div>
                                            <div className='mt-2 col-xs-12 col-sm-12 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                                <div className="button-filter">
                                                    <button
                                                        type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() =>
                                                            this.getListSupOnSearch()
                                                        }
                                                    >
                                                        Tìm kiếm
                                                    </button>
                                                    <button type="button"
                                                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                            onClick={() => {
                                                                this.setState({
                                                                    supervisorName: '',
                                                                    address: '',
                                                                    idAddress: ''
                                                                }, () => {
                                                                    this.getListSupOnSearch()
                                                                })
                                                            }}
                                                    >Refresh
                                                    </button>
                                                </div>
                                            </div>
                                            {/*<Grid item xs={12} sm={12} md={4} className='text-center pt-2 mt-1'>*/}
                                            {/*    <Button*/}
                                            {/*        style={{textTransform: 'initial'}}*/}
                                            {/*        className='mr-3'*/}
                                            {/*        variant="contained"*/}
                                            {/*        color="primary"*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.getListSupOnSearch()*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Tìm kiếm*/}
                                            {/*    </Button>*/}
                                            {/*    <Button*/}
                                            {/*        style={{textTransform: 'initial'}}*/}
                                            {/*        variant="outlined"*/}
                                            {/*        color="default"*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.setState({*/}
                                            {/*                supervisorName: '',*/}
                                            {/*                address: '',*/}
                                            {/*                idAddress: ''*/}
                                            {/*            }, () => {*/}
                                            {/*                this.getListSupOnSearch()*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Refresh*/}
                                            {/*    </Button>*/}
                                            {/*</Grid>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-5'>
                                    <div style={{overflow: "hidden"}}>
                                        {!this.state.onSearching &&
                                        <div>
                                            <h5 className="d-flex mt-4 mb-3 d-none text-uppercase font-weight-bold">
                                                Supervisor ưu tiên
                                            </h5>
                                            <div className="row">
                                                {supervisorTop.map((item, index) => {
                                                    return (
                                                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                            <div className={item.id == this.state.idRadio ? 'supervisor-card-item card p-2 item-selected priority-supervisor' : 'supervisor-card-item card p-2 item-unselect priority-supervisor'}>
                                                                <div className="justify-center col-12 text-center supervisor-img-wrapper">
                                                                    {/*<div className='align-middle pb-2'*/}
                                                                    {/*     style={{cursor: 'pointer'}}*/}
                                                                    {/*     onClick={() => this.handleChangeRadio(item)}*/}
                                                                    {/*>*/}
                                                                    {/*    <Radio*/}
                                                                    {/*        checked={item.id == this.state.idRadio ? true : false}*/}
                                                                    {/*        onChange={() => this.handleChangeRadio(item)}*/}
                                                                    {/*    />*/}
                                                                    {/*</div>*/}
                                                                    {/*<div className='text-center'>*/}
                                                                    <img
                                                                        onClick={() => this.handleShowDetail(item)}
                                                                        // src={`media/users/100_${item.id}.jpg`}
                                                                        src={item.urlImage ?  item.urlImage : '/images/no-avatar-png.png'}
                                                                        // src={item.urlImage ? URL_IMG_PUB + item.urlImage : '/images/no-avatar-png.png'}
                                                                        className='m-3 supervisor-img-avt'
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    />
                                                                    {/*</div>*/}
                                                                </div>

                                                                <div className='col-12 pl-2'>
                                                                    <div className='pt-2 justify-center text-center'>
                                                                        <h6
                                                                            className='text-uppercase font-weight-bold'
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => this.handleShowDetail(item)}
                                                                        >
                                                                            {item.fullName}
                                                                        </h6>
                                                                    </div>
                                                                    <div className='pt-2 justify-center text-center'>
                                                                        <h6
                                                                            className='font-weight-bold'
                                                                        >
                                                                            <span><i className="fas fa-map-marker-alt"/>&nbsp;{item.areaName}</span>
                                                                        </h6>
                                                                    </div>
                                                                    <div className='mt-3'>
                                                                        <label className='text-black-50 font-14'>Đánh
                                                                            giá:&nbsp;</label>
                                                                        <Rating size="small" defaultValue={item.vote}
                                                                                precision={0.5}
                                                                                readOnly/>
                                                                    </div>
                                                                    <div>
                                                                        <label className='text-black-50 font-14'>Lĩnh
                                                                            vực hỗ
                                                                            trợ:&nbsp;</label>
                                                                        <span
                                                                            className='font-14'
                                                                        >
                                                                            {item.fieldSupport || "N/A"}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <label className='text-black-50 font-14'>Số năm
                                                                            kinh
                                                                            nghiệm:&nbsp;</label>
                                                                        <span
                                                                            className='font-14'
                                                                        >
                                                                            {item.experience || "N/A"}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <label className='text-black-50 font-14'>Thuộc
                                                                            công
                                                                            ty:&nbsp;</label>
                                                                        <span
                                                                            className='font-14'
                                                                        >
                                                                            {item.partnerName || "N/A"}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <label className='text-black-50 font-14'>Khu vực
                                                                            hỗ
                                                                            trợ:&nbsp;</label>
                                                                        <span
                                                                            className='font-14'
                                                                        >
                                                                            {item.areaName || "N/A"}
                                                                            {/*Hà Nội*/}
                                                                        </span>
                                                                    </div>
                                                                    {/*<div>*/}
                                                                    {/*    <label className='text-black-50 font-14'>Bằng*/}
                                                                    {/*        cấp:&nbsp;</label>*/}
                                                                    {/*    <span*/}
                                                                    {/*        className='font-14'*/}
                                                                    {/*    >*/}
                                                                    {/*        /!*{item.address}*!/*/}
                                                                    {/*        ketoan.text*/}
                                                                    {/*    </span>*/}
                                                                    {/*</div>*/}
                                                                    <div className="justify-flex-end mt-2 text-align-right">
                                                                        <span
                                                                            onClick={() => this.handleShowDetail(item)}
                                                                            style={{
                                                                                color: '#00a0b0',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            className='mt-3'
                                                                        >
                                                                            Xem thêm &nbsp;
                                                                            <i className="fas fa-angle-right">
                                                                            </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        }
                                        <h5 className="d-flex mt-5 mb-3 d-none text-uppercase font-weight-bold">
                                            Danh sách supervisor
                                        </h5>
                                        <div className="row">
                                            {this.state.data.map((item, index) => {
                                                return (
                                                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                                        <div className={item.id == this.state.idRadio ? 'supervisor-card-item card p-2 item-selected' : 'supervisor-card-item card p-2 item-unselect'}>
                                                            <div className="justify-center col-12 text-center supervisor-img-wrapper">
                                                                {/*<div className='align-middle pb-2'*/}
                                                                {/*     style={{cursor: 'pointer'}}*/}
                                                                {/*     onClick={() => this.handleChangeRadio(item)}*/}
                                                                {/*>*/}
                                                                {/*    <Radio*/}
                                                                {/*        checked={item.id == this.state.idRadio ? true : false}*/}
                                                                {/*        onChange={() => this.handleChangeRadio(item)}*/}
                                                                {/*    />*/}
                                                                {/*</div>*/}
                                                                {/*<div className='text-center'>*/}
                                                                <img
                                                                    onClick={() => this.handleShowDetail(item)}
                                                                    // src={`media/users/100_${item.id}.jpg`}
                                                                    src={item.urlImage ?  item.urlImage : '/images/no-avatar-png.png'}
                                                                    // src={item.urlImage ? URL_IMG_PUB + item.urlImage : '/images/no-avatar-png.png'}
                                                                    className='m-3 supervisor-img-avt'
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                                {/*</div>*/}
                                                            </div>
                                                            <div className='col-12 pl-2'>
                                                                <div className='pt-2 justify-center text-center'>
                                                                    <h6
                                                                        className='text-uppercase font-weight-bold'
                                                                        style={{cursor: 'pointer'}}
                                                                        onClick={() => this.handleShowDetail(item)}
                                                                    >
                                                                        {item.fullName}
                                                                    </h6>
                                                                </div>
                                                                <div className='pt-2 justify-center text-center'>
                                                                    <h6
                                                                        className='font-weight-bold'
                                                                    >
                                                                        <span><i className="fas fa-map-marker-alt"/>&nbsp;{item.areaName}</span>
                                                                    </h6>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <label className='text-black-50 font-14'>Đánh
                                                                        giá:&nbsp;</label>
                                                                    <Rating size="small" defaultValue={item.vote}
                                                                            precision={0.5}
                                                                            readOnly/>
                                                                </div>
                                                                <div>
                                                                    <label className='text-black-50 font-14'>Lĩnh
                                                                        vực hỗ
                                                                        trợ:&nbsp;</label>
                                                                    <span
                                                                        className='font-14'
                                                                    >
                                                                            {item.fieldSupport || "N/A"}
                                                                        </span>
                                                                </div>
                                                                <div>
                                                                    <label className='text-black-50 font-14'>Số năm
                                                                        kinh
                                                                        nghiệm:&nbsp;</label>
                                                                    <span
                                                                        className='font-14'
                                                                    >
                                                                            {item.experience || "N/A"}
                                                                        </span>
                                                                </div>
                                                                <div>
                                                                    <label className='text-black-50 font-14'>Thuộc
                                                                        công
                                                                        ty:&nbsp;</label>
                                                                    <span
                                                                        className='font-14'
                                                                    >
                                                                            {item.partnerName || "N/A"}
                                                                        </span>
                                                                </div>
                                                                {/*<div>*/}
                                                                {/*    <label className='text-black-50 font-14'>Khu vực hỗ*/}
                                                                {/*        trợ:&nbsp;</label>*/}
                                                                {/*    <span*/}
                                                                {/*        className='font-14'*/}
                                                                {/*    >*/}
                                                                {/*            {item.areaName}*/}
                                                                {/*        /!*Hà Nội*!/*/}
                                                                {/*        </span>*/}
                                                                {/*</div>*/}

                                                                {/*<div>*/}
                                                                {/*    <label className='text-black-50 font-14'>Bằng*/}
                                                                {/*        cấp:&nbsp;</label>*/}
                                                                {/*    <span*/}
                                                                {/*        className='font-14'*/}
                                                                {/*    >*/}
                                                                {/*            /!*{item.address}*!/*/}
                                                                {/*        {item.text}*/}
                                                                {/*        </span>*/}
                                                                {/*</div>*/}
                                                                <div className="justify-flex-end mt-2 text-align-right">
                                                                    <span
                                                                        onClick={() => this.handleShowDetail(item)}
                                                                        style={{
                                                                            color: '#00a0b0',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        className='mt-3'
                                                                    >
                                                                        Xem thêm &nbsp;
                                                                        <i className="fas fa-angle-right">
                                                                        </i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="pagination-right justify-flex-end mt-4">
                                            <Pagination
                                                activePage={this.state.page + 1}
                                                itemsCountPerPage={this.state.pageSize}
                                                totalItemsCount={this.state.totalItem}
                                                changeHandler={page => this.handleChangePage(page)}
                                            />
                                        </div>
                                        {/*<Grid container spacing={2} justify={"flex-end"}>*/}
                                        {/*    <Grid item xs={12} sm={12} md={12} className='text-right mt-4'>*/}
                                        {/*        <TablePagination*/}
                                        {/*            rowsPerPageOptions={[9, 20, 30, 40]}*/}
                                        {/*            component="div"*/}
                                        {/*            count={totalItem}*/}
                                        {/*            page={page}*/}
                                        {/*            rowsPerPage={pageSize}*/}
                                        {/*            labelRowsPerPage={'Số kết quả trên trang'}*/}
                                        {/*            // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                                        {/*            // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                                        {/*            labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}*/}
                                        {/*            onChangePage={(event, newPage) => {*/}
                                        {/*                this.setState({page: newPage}, () => {*/}
                                        {/*                    this.getListSupOnSearch();*/}
                                        {/*                });*/}
                                        {/*            }}*/}
                                        {/*            onChangeRowsPerPage={(event) => {*/}
                                        {/*                this.setState({*/}
                                        {/*                    pageSize: event.target.value,*/}
                                        {/*                    page: page*/}
                                        {/*                }, () => {*/}
                                        {/*                    this.getListSupOnSearch()*/}
                                        {/*                });*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}




                                        {/*<Grid container spacing={2} justify={"flex-start"}>*/}
                                        {/*    <Grid item xs={12} sm={6} md={6} className='mt-2'>*/}
                                        {/*        <Button variant="contained" color="primary"*/}
                                        {/*                style={{textTransform: 'initial'}}*/}
                                        {/*                className='mr-3'*/}
                                        {/*                onClick={() => {*/}

                                        {/*                }}*/}
                                        {/*        >*/}
                                        {/*            Đồng ý*/}
                                        {/*        </Button>*/}
                                        {/*        <Button variant="outlined" style={{textTransform: 'initial'}}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                }}*/}
                                        {/*        >*/}
                                        {/*            Hủy bỏ*/}
                                        {/*        </Button>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showDetail &&
                    <DetailSupervisor
                        supSelected={this.state.supSelected}
                        show={this.state.showDetail}
                        onHide={() => this.setState({showDetail: false})}
                    />}
                </div>
            </div>
        );
    }
}

export default Supervisor
