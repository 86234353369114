import React from 'react';
import './style.css';
import logo from '../../assets/images/logo.png';
import contactIcon1 from '../../assets/images/footer/iccon_adress.png';
import contactIcon2 from '../../assets/images/footer/icon_mail.png';
import contactIcon3 from '../../assets/images/footer/icon_tel.png';

import iconFb from '../../assets/images/footer/icon_fb.png';
import iconTwitter from '../../assets/images/footer/icon_twit.png';
import iconGoogle from '../../assets/images/footer/icon_google.png';
import iconYoutube from '../../assets/images/footer/icon_youtube.png';

class Footer extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showContent: false,
      homeData: {},
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    const { homeData, showContent } = this.state;

    return (
      <footer className='app-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='footer-col-1'>
                <img className='img-fluid footer-logo' src={logo} alt='logo' />
                <ul className='contact-list'>
                  <li className='contact-item'>
                    <img src={contactIcon1} alt='contact-icon' />
                    <div className='contact-infor'>
                      <span>Tầng 5 - Tháp B2 Tòa nhà Roman Plaza - Tố Hữu</span><br/>
                       <span>Nam Từ Liêm - Hà Nội</span>
                    </div>
                  </li>
                  <li className='contact-item'>
                    <img src={contactIcon2} alt='contact-icon' />
                    <div className='contact-infor'>info@onebook.ac</div>
                  </li>
                  <li className='contact-item'>
                    <img src={contactIcon3} alt='contact-icon' />
                    <div className='contact-infor'>1900xxxx</div>
                  </li>
                </ul>
                <a href="https://a1.faonline.vn/SAS" target="_blank">
                  <button className="btn btn-outline-secondary">
                    <span className="fas fa-download"/> Phần mềm kế toán
                  </button>
                </a>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='footer-col-2'>
                <h5 className='footer-title'>Tìm KTDV theo lĩnh vực của DN</h5>
                <ul className='basic-ul text-left'>
                  <li>
                    <a href=''>Thương mại</a>
                  </li>
                  <li>
                    <a href=''>Dịch vụ</a>
                  </li>
                  <li>
                    <a href=''>Sản xuất</a>
                  </li>
                  <li>
                    <a href=''>Xây dựng - Lắp ráp</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='footer-col-3'>
                <h5 className='footer-title text-left'>Khác</h5>
                <ul className='basic-ul text-left'>
                  <li>
                    <a href=''>Hướng dẫn sử dụng</a>
                  </li>
                  <li>
                    <a href=''>Thỏa thuận sử dụng dịch vụ</a>
                  </li>
                  <li>
                    <a href=''>Chính sách bảo mật</a>
                  </li>
                </ul>
                <h5 className='footer-title second-title text-left'>Tuyển dụng</h5>
                <div className='social d-flex'>
                  <a href=''>
                    <img src={iconFb} alt='fb' style={{height: 24}} />
                  </a>
                  <a href=''>
                    <img src={iconTwitter} alt='fb' />
                  </a>
                  <a href=''>
                    <img src={iconGoogle} alt='fb' />
                  </a>
                  <a href=''>
                    <img src={iconYoutube} alt='fb' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
