import {stringify} from 'qs';
import {URL_API} from "../../url.config";
import {request, requestDownload, requestUpload} from "../../utils/apiUtils";
import axios from "axios";

const FilesApi = {
    getAllFile: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/user-file/get-all`,
            method: 'GET',
        }, callback);
    },

    downloadDocumentFile: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download-document/${payload.fileId}`,
            method: 'GET',
        }, callback);
    },

    downloadReportFile: (payload, callback) => {
        return requestDownload({
            // url: `${URL_API}/api/one-book-resource/secure/file/download-report/${payload.fileId}`,
            // method: 'GET',
            url: `${URL_API}/api/one-book-resource/secure/file/download/report?id=${payload.fileId}`,
            method: 'GET',
        }, callback);
    },

    downloadReportFileByIdCus: (payload, callback) => {
        return requestDownload({
            // url: `${URL_API}/api/one-book-resource/secure/file/download-report/${payload.fileId}`,
            // method: 'GET',
            url: `${URL_API}/api/one-book-resource/secure/file/download/report-with-id?customerId=${payload.customerId}&id=${payload.fileId}`,
            method: 'GET',
        }, callback);
    },


    downloadUserFile: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download/user_file?id=${payload.fileId}`,
            method: 'GET',
        }, callback);
    },

    downloadContractDocument: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download-contract-document/${payload.fileId}`,
            method: 'GET',
        }, callback);
    },

    downloadContractDocument2: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download/contract_document?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },

    downloadFileDocument: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download/document?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    downloadFile: (payload, callback) => {
        return requestDownload({
            url: `${URL_API}/api/one-book-resource/secure/file/download/file?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },

    supervisorUploadCertificate: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/user-file/supervisor-upload-certificate`,
            method: 'POST',
            body: payload,
        }, callback);
    },

    uploadContractDocuments: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('files', item)
        })
        return requestUpload({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-contract-documents/${payload.customerId}`,
            method: 'POST',
            body: data,
        }, callback);
    },

    addDocumentsToContract: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-contract-documents/${payload.contractId}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },

    uploadPublicFile: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('file', item)
        })
        return requestUpload({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-public-file`,
            // url:`http://192.168.1.169:9001/api/one-book-resource/secure/file/upload-public-file`,
            method: 'POST',
            body: data,
        }, callback);
    },

    uploadPublicNewsImg: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('file', item)
        })
        return requestUpload({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-public-image-web`,
            method: 'POST',
            body: data,
        }, callback);
    },

    uploadSecureFile: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('files', item)
        })
        return requestUpload({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-secure-file`,
            // url:`http://192.168.1.169:9001/api/one-book-resource/secure/file/upload-secure-file`,
            method: 'POST',
            body: data,
        }, callback);
    },

    uploadReportFile: (payload, callback) => {
        const data = new FormData()
        payload.file.map(item => {
            data.append('file', item)
        })
        return requestUpload({
            url: `${URL_API}/api/one-book-resource/secure/file/upload-report/${payload.customerId}`,
            method: 'POST',
            body: data,
        }, callback);
    },

    deleteFile: (payload, callback) => {
        return requestUpload({
            url: `${URL_API}/api/one-book-user/secure/user-file/delete/${payload.id}`,
            method: 'POST',
            body: payload,
        }, callback);
    }

}

export default FilesApi

