import React from 'react'
import {Link, Switch, Route, Redirect} from "react-router-dom";
import Profile from './profile'
import CompleteProfile from './complete-profile'
import ContractsManagement from './contracts-management'
import DetailManagement from './contracts-detail'
import ReportManagement from "./report-management";
import JobsManagement from "./jobs-management";
import RevenueManagement from "./revenue-management";

class Accountant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAccountant: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isAccountant: userType == "ACCOUNTANT"})
    }

    render() {
        return (
            <Switch>
                {!this.state.isAccountant && <Redirect to={"/"}/>}
                <Route path="/accountant/profile" component={Profile}/>
                <Route path="/accountant/complete-profile" component={CompleteProfile}/>
                <Route path="/accountant/contracts-management" component={ContractsManagement}/>
                <Route path="/accountant/contracts-detail" component={DetailManagement}/>
                <Route path="/accountant/report-management" component={ReportManagement}/>
                <Route path="/accountant/jobs-management" component={JobsManagement}/>
                <Route path="/accountant/revenue-management" component={RevenueManagement}/>
                {/*<Route path="/accountant/balance" component={AccountingBalanceSheet}/>*/}
                {/*<Route path="/accountant/business-results" component={ReportBusinessResults}/>*/}
                {/*<Route path="/accountant/direct-cash" component={DirectCashFlowStatement}/>*/}
                {/*<Route path="/accountant/indirect-cash" component={IndirectCashFlowStatement}/>*/}
                {/*<Route path="/accountant/report-summary" component={ReportSummary}/>*/}
            </Switch>
        )
    }
}

export default Accountant
