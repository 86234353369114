import React from 'react';
import PropTypes from 'prop-types';

class TableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: [],
            hover: false,
            index: null,
            index2: null,
        }
    }

    static propTypes = {
        htmlFor: PropTypes.string.isRequired,
    };

    ascendingStyle(value) {
        if (value.sort) {
            switch (value.ascending) {
                case 1:
                    return "fa fa-fw fa-sort";
                    // return "ti-arrows-vertical";
                case 2:
                    return "fa fa-fw fa-sort-up";
                    // return "ti-arrow-up";
                case 3:
                    return "fa fa-fw fa-sort-down";
                    // return "ti-arrow-down";
            }
        }
        return "";
    }

    ascendingCheck(value) {
        if (value.sort) {
            switch (value.ascending) {
                case 1:
                    return false;
                case 2:
                    return true;
                case 3:
                    return true;
            }
        }
        return false;
    }

    ascending(value) {
        switch (value) {
            case 1:
                return null;
            case 2:
                return true;
            case 3:
                return false;
        }
    }

    componentDidMount() {
        let data = this.props.header;
        data.map((obj) => {
            obj.ascending = 1;
            obj.asc = null;
        });
        this.setState({header: data});
    }

    hoverColor(obj, i) {
        let {hover, index, index2} = this.state;
        return obj.sort ? (index2 === i ? 'orange' : (index === i ? 'orange' : 'white')) : 'white'
    }

    clickSort(obj, index) {
        let {header} = this.state;
        header.map((obj2, index2) => {
            obj2.ascending = index === index2 ? (obj2.ascending === 3 ? 1 : obj2.ascending + 1) : 1;
        });
        this.setState({header}, () => {
            let ab = this.ascendingCheck(obj);
            this.setState({index2: ab ? index : null});
            this.props.clickSort({
                sortId: obj.id,
                sortAsc: this.ascending(obj.ascending),
            });
        });
    }

    render() {
        return (
            <tr>
                {this.state.header.map((obj, i) => {
                    obj.style = {
                        ...obj.style,
                        backgroundColor: '#c6e6f1',
                        color: 'black',
                        verticalAlign: "middle",
                        minWidth: obj.style.minWidth ? obj.style.minWidth : '60px',
                        fontWeight: 'normal'

                    };
                    return (
                        <th style={obj.style}
                            onMouseOver={() => {
                                this.setState({hover: true, index: i})
                            }} onMouseOut={() => {
                            this.setState({hover: false, index: null})
                        }} scope="col" onClick={() => {
                            if (obj.sort) this.clickSort(obj, i)
                        }}>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <a style={{color: 'black'}}>{obj.name}</a>
                                </div>
                                {obj.sort &&
                                <div style={{verticalAlign: "middle"}}>
                                    <i style={{color: this.hoverColor(obj, i), cursor: "pointer", marginLeft: 3}} className={this.ascendingStyle(obj)}/>
                                </div>}
                            </div>
                        </th>
                    )
                })}
            </tr>
        );
    }
}

export default TableHeader;
