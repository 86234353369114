import React from 'react';
import moment from "moment";

import Convert from "../../../utils/convertUrlPra";
import Utils from "../../../utils/utils";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import FormInput from "../../../components/Form/input";
import { Button, Grid } from "@material-ui/core";
import DatePicker from "react-datepicker";

import UploadContractDoc from "../../../components/Modal/modalUploadContractDoc";
import ModalAddJob from "../../../components/Modal/create-contract-modal/modalAddJob";
import ModalAddPayment from "../../../components/Modal/create-contract-modal/modalAddPayment";

import PublicContractApi from "../../../services/ob-core/public-contract";
import FilesApi from "../../../services/resource/files";
import SecureCustomerApi from "../../../services/ob-user/secure-customer"
import NumberFormat from "react-number-format";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

class CreateContract extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            contractCodeError: false,
            contractValue: null,
            contractValueError: false,

            servicePlan: [],

            customerName: '',
            taxCode: '',
            name: '',
            sex: '',
            identify: '',
            dateIssue: null,
            placeIssue: '',
            phone: '',
            contractCode: '',
            contractName: '',
            contractDate: null,
            contractPrice: '',
            planType: '',
            contractType: 'NEW',
            filesUploaded: [],
            taxCodeErr: false,
            nameErr: false,
            sexErr: false,
            identifyErr: false,
            dateIssueErr: false,
            placeIssueErr: false,
            jobValueErr: false,
            payValueErr: false,
            filesTableErr: false,
            jobsListErr: false,
            paymentTableErr: false,
            jobTotalMoney: 0,
            payTotalMoney: 0,
            modalUploadFile: false,
            files: [],
            modalPayment: false,
            paymentCycle: 0,
            paymentTimelines: [],
            paymentAmount: 0,
            jobsList: [],
            customer: [],
            header1: [
                {
                    id: '123',
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Hạn thanh toán",
                    style: { minWidth: 60, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'status',
                    name: "Số tiền thanh toán",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                }
            ],
            header2: [
                {
                    id: '123',
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'jobName',
                    name: "Tên Job",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'numberOfReport',
                    name: "Số lượng báo cáo",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'amount',
                    name: "Số tiền",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                }
            ],
            header3: [
                {
                    id: '123',
                    name: "STT",
                    style: { textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'numOfContract',
                    name: "Tên File",
                    style: { minWidth: 60, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'status',
                    name: "File",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                },
                {
                    id: 'action',
                    name: "Thao tác",
                    style: { minWidth: 80, textAlign: "center" },
                    sort: false,
                }
            ],
        }
    }

    componentDidMount() {
        this.getServicePlan();
        if (this.props.location.search) {
            let params = Convert.urlParams(this.props.location.search);
            // console.log('CJECLKC',params);
            this.setState({
                data: params,
                planType: params.servicePlanId,
                customerName: params.customerName,
                contractPrice: params.dealPrice == '-1' ? 'Thoả thuận' : params.dealPrice,

            }, () => {
                this.customerInfo()
                this.getServicePlan()
            })
        }
    }

    getServicePlan = () => {
        let payload = {
            type: this.state.data?.orderType == "ADVANCE" ? "ADVANCED" : "BASIC",
        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({ servicePlan: res.data })
            }
        })
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderPaymentCycle = (value) => {
        switch (value) {
            case (1): return "PAYMENT_1_TIMES";
            case (2): return "PAYMENT_2_TIMES";
            case (3): return "PAYMENT_3_TIMES";
            case (4): return "PAYMENT_4_TIMES";
            case (5): return "PAYMENT_5_TIMES";
        }
    }

    onSubmit = () => {
        // let ready = true
        let { customer, customerName, taxCode, name, sex, identify, dateIssue, placeIssue, phone, contractCode, contractName, contractDate, contractPrice, planType, contractType, files, jobsList, paymentTimelines, jobTotalMoney } = this.state;
        if (!customer.companyName) {
            this.setState({ customerNameErr: true })
            toast.error("Vui lòng điền tên Công ty")
        } else if (!customer.tax && (taxCode.length == 0 || taxCode.length < 10)) {
            this.setState({ taxCodeErr: true })
            toast.error("Vui lòng nhập MST hợp lệ")
            // ready = false
        } else if (!customer.fullName && name.length == 0) {
            this.setState({ nameErr: true })
            toast.error("Vui lòng nhập tên người đại diện")
        } else if (!customer.sex && (sex === null || sex === '')) {
            this.setState({ sexErr: true })
            toast.error("Vui lòng chọn giới tính người đại diện!")
        } else if (!customer.identify && (identify.length == 0 || identify.length < 10)) {
            this.setState({ identifyErr: true })
            toast.error("Vui lòng nhập CMND/CCCD người đại diện")
            // ready = false
        } else if (!customer.dateIssued && dateIssue == null) {
            this.setState({ dateIssueErr: true })
            toast.error("Vui lòng nhập ngày cấp CMND/CCCD người đại diện")
        } else if (!customer.phone && (phone.length == 0 || phone.length < 10)) {
            this.setState({ phoneErr: true })
            toast.error("Vui lòng nhập SĐT người đại diện")
        } else if (contractCode.length == 0) {
            this.setState({ contractCodeErr: true })
            toast.error("Vui lòng nhập mã hợp đồng thực tế")
        } else if (contractName.length == 0) {
            this.setState({ contractNameErr: true })
            toast.error("Vui lòng nhập tên hợp đồng!")
        } else if (contractDate == null) {
            this.setState({ contractDateErr: true })
            toast.error("Vui lòng nhập ngày ký hợp đồng!")
        } else if (contractPrice.length == 0) {
            this.setState({ contractPriceErr: true })
            toast.error("Vui lòng nhập giá trị hợp đồng!")
        } else if (planType == null || planType == '' || planType == 0) {
            toast.error("Vui lòng chọn loại dịch vụ!")
            this.setState({ planTypeErr: true })
        } else if (contractType == null || contractType == '' || contractType == 0) {
            toast.error("Vui lòng chọn loại hợp đồng!")
            this.setState({ contractTypeErr: true })
        } else if (jobsList.length === 0) {
            toast.error("Vui lòng thêm Job!")
            this.setState({ jobsListErr: true })
        } else if (paymentTimelines.length === 0) {
            toast.error("Vui lòng chọn ngày thanh toán!")
            this.setState({ paymentTableErr: true })
        } else if (jobTotalMoney < contractPrice) {
            toast.error("Tổng giá trị Job chưa bằng giá trị hợp đồng! Vui lòng kiểm tra lại!")
        } else {
            this.addContract()
        }
    }

    customerInfo = () => {
        let { data } = this.state
        let payload = {
            id: data.customerId
        }
        SecureCustomerApi.getDetailCusById(payload, res => {
            if (res.success) {
                this.setState({
                    customer: res.data,
                    // name: res.data.representative ? res.data.representative : "",
                    // sex: res.data.sex ? res.data.sex : "",
                    // identify: res.data.identify ? res.data.identify : "",
                    // dateIssue: res.data.dateIssued ? res.data.dateIssued : null,
                    placeIssue: res.data.placeIssued ? res.data.placeIssued : "",
                    // phone: res.data.phone ? res.data.phone : "",
                    // taxCode: res.data.tax ? res.data.tax : ""
                })
            } else {
                toast.error("Đã có lỗi xảy ra" + res.message)
            }
        })
    }

    checkGender = (val) => {
        switch (val) {
            case "FEMALE":
                return "Nữ";
            case "MALE":
                return "Nam";
        }
    }

    addContract = () => {
        let { paymentTimelines, data, contractDate, contractType, contractName, contractPrice, contractCode, jobsList, planType, filesUploaded, customer } = this.state;
        let payload = {
            code: contractCode,
            customerId: data.customerId * 1,
            jobs: jobsList,
            name: contractName,
            orderId: data.id * 1,
            paymentCycle: this.renderPaymentCycle(paymentTimelines.length),
            paymentTimelines: paymentTimelines,
            servicePlanId: data ? data.servicePlanId * 1 : planType * 1,
            startDate: contractDate,
            supervisorId: data.supervisorId * 1,
            type: contractType,
            uploadedContractDocuments: filesUploaded,
            value: data ? data.dealPrice : contractPrice
        };
        PublicContractApi.create(payload, res => {
            if (res.success) {
                toast.success("Tạo hợp đồng thành công !");
                this.props.history.push("/partner/contracts-management")
            } else if (res.errorCode === "One-Book-Core.E000024") {
                toast.error("Mã hợp đồng " + payload.code + " đã tồn tại")
            }
            else {
                return toast.error("Tạo không thành công, vui lòng thử lại !")
            }
        })
    }

    uploadFile = () => {
        let { data } = this.state
        let payload = {
            file: this.state.files,
            customerId: data.customerId
        }

        FilesApi.uploadContractDocuments(payload, res => {
            // console.log("check>>>", payload)
            if (res.success) {
                this.setState({
                    // files: [],
                    filesUploaded: res.data
                }, () => {
                    // debugger
                    this.onSubmit()
                })
            } else {
                toast.error("Đã xảy ra lỗi! " + res.message)
            }
        })
    }

    importFile = (_file) => {
        let files = this.state.files;
        files.push(_file)
        this.setState({ files, modalUploadFile: false, filesTableErr: false })
    }

    deleteFile = (_file) => {
        let files = this.state.files;
        let idx = files.indexOf(_file)
        files.splice(idx, 1);
        this.setState({ files })
    }


    addPayment = (date, sendMoney) => {
        let { paymentTimelines, contractPrice, data, payTotalMoney } = this.state
        let _pay;
        let payTotalMoneyNew = payTotalMoney + parseInt(sendMoney.replace(/,/g, ''));
        if (data.dealPrice != "-1") {
            _pay = {
                id: data.dealPrice.length + 1,
                paymentTerm: date,
                // paymentAmount: data.dealPrice / (paymentTimelines.length + 1)
                paymentAmount: parseInt(sendMoney.replace(/,/g, ''))
            }
            paymentTimelines.push(_pay)
            // paymentTimelines.map((item) => {
            //     // item.paymentAmount = data.dealPrice / (paymentTimelines.length)
            //     item.paymentAmount = parseInt(sendMoney.replace(/,/g, ''));
            // })
            // console.log("checkkkkkkk>>>>>>", paymentTimelines)
            // this.setState({paymentTimelines: paymentTimelines.push(_pay)})
            this.setState({ paymentTimelines, modalPayment: false, paymentTableErr: false, payTotalMoney: payTotalMoneyNew, payValueErr: payTotalMoneyNew != data.dealPrice })
            if (payTotalMoneyNew != data.dealPrice) {
                toast.warn("Chú ý! Tổng số tiền thanh toán phải bằng giá trị hợp đồng!")
            }else {
                toast.success("Tổng tiền thanh toán đã bằng giá trị hợp đồng!")
            }
        } else {
            _pay = {
                paymentTerm: date,
                paymentAmount: "-1"
            }
            this.setState({ paymentTimelines: paymentTimelines.push(_pay), modalPayment: false })
        }
    }

    changePayValue = (e, index) => {
        let { paymentTimelines, payTotalMoney, contractPrice, data } = this.state
        let payTotalMoneyNew = payTotalMoney - paymentTimelines[index].paymentAmount + parseInt(e.target.value.replace(/,/g, ''))
        paymentTimelines[index].paymentAmount = parseInt(e.target.value.replace(/,/g, ''));
        // console.log(payTotalMoneyNew, contractPrice)
        if (payTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng số tiền thanh toán phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền thanh toán đã bằng giá trị hợp đồng!")
        }
        this.setState({ paymentTimelines, payTotalMoney: payTotalMoneyNew, payValueErr: payTotalMoneyNew != contractPrice })
    }

    deletePayment = (index) => {
        let { paymentTimelines, payTotalMoney, contractPrice, data } = this.state
        let payTotalMoneyNew = payTotalMoney - paymentTimelines[index].paymentAmount
        if (payTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng số tiền thanh toán phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền thanh toán đã bằng giá trị hợp đồng!")
        }

        paymentTimelines.splice(index, 1);

        this.setState({ paymentTimelines, payTotalMoney: payTotalMoneyNew, payValueErr: payTotalMoneyNew != contractPrice })
    }

    addJob = (name, numberOfReports, receiveMoney) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney + parseInt(receiveMoney.replace(/,/g, ''))
        jobsList.push({
            id: jobsList.length + 1,
            name: name,
            numberOfReports: numberOfReports,
            receiveMoney: parseInt(receiveMoney.replace(/,/g, '')),
            // receiveMoney: receiveMoney.replace(/,/g,'')
        })
        // console.log("check>>>>>>>>", jobTotalMoney, jobTotalMoneyNew, contractPrice < jobTotalMoneyNew);
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        this.setState({ jobsList, modalAddJob: false, jobsListErr: false, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    changeJobValue = (data, index) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney - jobsList[index].receiveMoney + parseInt(data.target.value.replace(/,/g, ''))
        jobsList[index].receiveMoney = parseInt(data.target.value.replace(/,/g, ''));
        // console.log("check2222>>>>>>>>", jobTotalMoney, jobTotalMoneyNew, contractPrice < jobTotalMoneyNew);
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        this.setState({ jobsList, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    deleteJob = (index) => {
        let { jobsList, jobTotalMoney, contractPrice } = this.state
        let jobTotalMoneyNew = jobTotalMoney - jobsList[index].receiveMoney
        if (jobTotalMoneyNew != contractPrice)
            toast.warn("Chú ý! Tổng giá trị của Job phải bằng giá trị hợp đồng!")
        else {
            toast.success("Tổng tiền Job đã bằng giá trị hợp đồng!")
        }
        jobsList.splice(index, 1);

        this.setState({ jobsList, jobTotalMoney: jobTotalMoneyNew, jobValueErr: contractPrice < jobTotalMoneyNew })
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="contracts-management" />
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title={"Tạo mới hợp đồng"} />
                                    {this.state.data ? <div className='d-flex align-items-center justify-content-center'>
                                        <span>Mã Order: {this.state.data?.code} </span>
                                    </div> : <div />}
                                    <div className="ct-payment-contract">
                                        <div style={{ fontSize: 20 }}>
                                            <span>THÔNG TIN KHÁCH HÀNG </span>
                                            <div className="font-14 ml-4 mr-4">
                                                <div className="row">
                                                    <div className='row mt-3 col-6'>
                                                        <div className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                                            <span className="text-black-50">Tên khách hàng
                                                            <span className={'color-red d-inline'}>*</span>
                                                            </span>
                                                        </div>
                                                        <div className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            <span>{this.state.customer.companyName}</span>
                                                        </div>
                                                    </div>
                                                    {!this.state.customer.tax
                                                        ? <FormInput label="Mã số thuế"
                                                                     placeholder="Nhập mã số thuế"
                                                                     value={this.state.taxCode}
                                                                     maxLength={14}
                                                                     onChange={(val) => {
                                                                         if (val.length < 10) {
                                                                             this.setState({ taxCode: val.trimLeft().replace(/[^0-9]/g, ''), taxCodeErr: true })
                                                                         } else {
                                                                             this.setState({ taxCode: val.trimLeft().replace(/[^0-9]/g, ''), taxCodeErr: false })
                                                                         }
                                                                     }}
                                                                     type="text" checkError={this.state.taxCodeErr} errorContent="Mã số thuế sai hoặc đang trống !" />
                                                        : <div className='row mt-3 col-6'>
                                                            <div
                                                                className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                                <span className="text-black-50">Mã số thuế</span>
                                                            </div>
                                                            <div
                                                                className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                                {this.state.customer.tax}
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ct-report-info">
                                        <div style={{ fontSize: 20 }}>
                                            <span>THÔNG TIN NGƯỜI ĐẠI DIỆN </span>
                                            <div className="font-14 ml-4 mr-4">
                                                <div className="row">
                                                    {!this.state.customer.fullName
                                                        ? <FormInput label="Tên người đại diện"
                                                                     placeholder="Nhập tên người đại diện"
                                                                     value={this.state.name}
                                                                     maxLength={50}
                                                                     onChange={(val) => {
                                                                         this.setState({ name: val.trimLeft(), nameErr: false })
                                                                     }}
                                                                     type="text" checkError={this.state.nameErr} errorContent="Vui lòng nhập tên người đại diện!" />
                                                        : <div className='row mt-3 col-6'>
                                                            <div
                                                                className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                                <span className="text-black-50">Tên người đại diện</span>
                                                            </div>
                                                            <div
                                                                className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                                {this.state.customer.fullName}
                                                            </div>
                                                        </div>
                                                    }


                                                    {/*<FormInput label="Giá trị hợp đồng"*/}
                                                    {/*           placeholder="Nhập giá trị hợp đồng"*/}
                                                    {/*           value={this.state.contractValue}*/}
                                                    {/*           onChange={(val)=> {*/}
                                                    {/*               this.setState({contractCode: val})*/}
                                                    {/*           }}*/}
                                                    {/*           type="text" checkError={this.state.contractValueError} errorContent="Vui lòng nhập giá trị hợp đồng!"/>*/}
                                                    <div className='row mt-3 col-6'>
                                                        <div
                                                            className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                            <span className="text-black-50">Giới tính
                                                                {this.state.sex === "" && <span className={'color-red d-inline'}>*</span>}</span>
                                                        </div>

                                                        <div
                                                            className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                            {!this.state.customer.sex
                                                                ? <div className='d-flex col-12 '>
                                                                    <div className="form-check col-6 ">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="femaleRadios" id="female" value="FEMALE"
                                                                               onClick={(val) => {
                                                                                   this.setState({
                                                                                       sex: val.target.value, sexErr: false
                                                                                   })
                                                                               }}
                                                                               checked={this.state.sex === 'FEMALE'}
                                                                        />
                                                                        <label className="form-check-label"
                                                                               htmlFor="exampleRadios1">
                                                                            Nữ
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check col-6">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="maleRadios" id="male" value="MALE"
                                                                               onClick={(val) => {
                                                                                   this.setState({
                                                                                       sex: val.target.value, sexErr: false
                                                                                   })
                                                                               }}
                                                                               checked={this.state.sex === 'MALE'}
                                                                        />
                                                                        <label className="form-check-label"
                                                                               htmlFor="exampleRadios1">
                                                                            Nam
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                : this.checkGender(this.state.customer.sex)

                                                            }


                                                            {this.state.sexErr ?
                                                                <div className="row">
                                                                    <label className="col-12 font-err mb-0">Vui lòng chon giới tính !</label>
                                                                </div> : <div />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {!this.state.customer.identify
                                                        ? <FormInput label="CMND/CCCD"
                                                                     placeholder="Nhập số CMND/CCCD"
                                                                     value={this.state.identify}
                                                                     maxLength={12}
                                                                     onChange={(val) => {
                                                                         if (val.length < 10) {
                                                                             this.setState({
                                                                                 identify: val.trim().replace(/[^0-9]/g, ''),
                                                                                 identifyErr: true
                                                                             })
                                                                         } else
                                                                             this.setState({
                                                                                 identify: val.trim().replace(/[^0-9]/g, ''),
                                                                                 identifyErr: false
                                                                             })
                                                                     }}
                                                                     type="text" checkError={this.state.identifyErr} errorContent="Vui lòng nhập số CMND/CCCD!" />
                                                        : <div className='row mt-3 col-6'>
                                                            <div
                                                                className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                                <span className="text-black-50">CMND/CCCD</span>
                                                            </div>
                                                            <div
                                                                className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                                {this.state.customer.identify}
                                                            </div>
                                                        </div>
                                                    }

                                                    {/*<FormInput label="Ngày cấp"*/}
                                                    {/*           placeholder="Nhập tên người địa diện"*/}
                                                    {/*           value={this.state.contractCode}*/}
                                                    {/*           onChange={(val)=> {*/}
                                                    {/*               this.setState({contractCode: val})*/}
                                                    {/*           }}*/}
                                                    {/*           type="text" checkError={this.state.contractCodeError} errorContent="Vui lòng nhập tên người đại diện!"/>*/}
                                                    <div className='row mt-3 col-6'>
                                                        <div
                                                            className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                            <span className="text-black-50">Ngày cấp
                                                                {this.state.dateIssue === "" && <span className={'color-red d-inline'}>*</span>}</span>
                                                        </div>

                                                        <div className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            {!this.state.customer.dateIssued
                                                                ? <DatePicker
                                                                    className={this.state.dateIssueErr ? "w-auto border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input" : "w-auto react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                                                    // onChangeRaw={this.handleDateChangeRaw}
                                                                    selected={this.state.dateIssue === null ? null : new Date(this.state.dateIssue)}
                                                                    // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                                    maxDate={new Date()}
                                                                    isClearable
                                                                    // value={this.state.dateIssue}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onChange={
                                                                        (date) => {
                                                                            let value = moment(date).format("YYYY-MM-DD").toString();
                                                                            try {
                                                                                this.setState({
                                                                                    dateIssue: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                                    dateIssueErr: false
                                                                                })
                                                                            } catch (e) {
                                                                                console.log(e);
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                                : moment(this.state.customer.dateIssued).format("DD-MM-YYYY").toString()
                                                            }
                                                            {this.state.dateIssueErr ?
                                                                <div className="row">
                                                                    <label className="col-12 font-err mb-0">Vui lòng chon ngày !</label>
                                                                </div> : <div />}

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row mb-2 mt-3">
                                                    {!this.state.customer.position
                                                        ? <FormInput label="Chức vụ"
                                                                     placeholder="Nhập chức vụ"
                                                                     value={this.state.position}
                                                                     onChange={(val) => {
                                                                         this.setState({ position: val, positionErr: false })
                                                                     }}
                                                                     type="text" checkError={this.state.positionErr} errorContent="Vui lòng nhập chức vụ !" />
                                                        : <div className='row col-6'>
                                                            <div
                                                                className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                                <span className="text-black-50">Chức vụ</span>
                                                            </div>
                                                            <div
                                                                className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                                {this.state.customer.position}
                                                            </div>
                                                        </div>
                                                    }
                                                    {!this.state.customer.phone
                                                        ? <FormInput label="Số điện thoại"
                                                                     placeholder="Nhập số điện thoại"
                                                                     maxLength={11}
                                                                     value={this.state.phone}
                                                                     onChange={(val) => {
                                                                         if (val.length < 10) {
                                                                             this.setState({ phone: Utils.filterNumberOnly(val), phoneErr: true })
                                                                         } else {
                                                                             this.setState({ phone: Utils.filterNumberOnly(val), phoneErr: false })
                                                                         }
                                                                     }}
                                                                     type="text" checkError={this.state.phoneErr} errorContent="SĐT không đúng hoặc đang trống !" />
                                                        : <div className='row col-6'>
                                                            <div
                                                                className=" pl-0 col-md-4 col-lg-4 col-sm-4">
                                                                <span className="text-black-50">Số điện thoại</span>
                                                            </div>
                                                            <div
                                                                className=" pl-0 col-md-8 col-lg-8 col-sm-8">
                                                                {this.state.customer.phone}
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ct-contract-info">
                                        <div style={{ fontSize: 20 }}>
                                            <span>THÔNG TIN CHI TIẾT HỢP ĐỒNG </span>

                                            <div className="mt-4 font-14 ml-4 mr-4">
                                                <div className="row">
                                                    <FormInput label="Mã hợp đồng"
                                                               placeholder="Nhập mã hợp đồng thực tế"
                                                               value={this.state.data?.code}
                                                               maxLength={20}
                                                               onChange={(val) => {
                                                                   this.setState({ contractCode: val.trimLeft(), contractCodeErr: false })
                                                               }}
                                                               type="text" checkError={this.state.contractCodeErr} errorContent="Vui lòng nhập mã hợp đồng!" />

                                                    <FormInput label="Tên hợp đồng"
                                                               placeholder="Nhập Tên hợp đồng"
                                                               value={this.state.contractName}
                                                               maxLength={100}
                                                               onChange={(val) => {
                                                                   this.setState({ contractName: val.trimLeft(), contractNameErr: false })
                                                               }}
                                                               type="text" checkError={this.state.contractNameErr} errorContent="Vui lòng nhập giá trị hợp đồng!" />
                                                </div>
                                                <div className="row">
                                                    <div className='row mt-3 col-6'>
                                                        <div
                                                            className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                                            <span className="text-black-50">Ngày tạo
                                                            <span className={'color-red d-inline'}>*</span></span>
                                                        </div>
                                                        <div
                                                            className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            <DatePicker
                                                                className={this.state.contractDateErr ? "w-auto border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input" : "w-auto react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                                                // onChangeRaw={this.handleDateChangeRaw}
                                                                selected={this.state.contractDate === null ? new Date() : new Date(this.state.contractDate)}
                                                                // value={moment(this.state.dateIssue, 'YYYY-MM-DD')}
                                                                maxDate={new Date()}
                                                                isClearable
                                                                // value={this.state.dateIssue}
                                                                dateFormat="dd-MM-yyyy"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onChange={
                                                                    (date) => {
                                                                        let value = moment(date).format("YYYY-MM-DD").toString();
                                                                        // let value = moment(date).format("DD-MM-YYYY")
                                                                        // console.log("check date>>>>>>", value, date)
                                                                        try {
                                                                            this.setState({
                                                                                contractDate: value == "Invalid date" ? null : moment(value).format("YYYY-MM-DD"),
                                                                                // contractDate: moment(date).format("YYYY-MM-DD"),
                                                                                contractDateErr: false
                                                                            })
                                                                        } catch (e) {
                                                                            console.log(e);
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            {this.state.contractDateErr ?
                                                                <div className="row">
                                                                    <label className="col-12 font-err mb-0">Vui lòng chon ngày !</label>
                                                                </div> : <div />}

                                                        </div>
                                                    </div>
                                                    <div className='row mt-3 col-6'>
                                                        <div
                                                            className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                                            <span className="text-black-50">Gói dịch vụ
                                                                {/*<span className={'color-red d-inline'}>*</span>*/}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            <select
                                                                value={this.state.data?.servicePlanId}
                                                                defaultValue={0}
                                                                disabled
                                                                onChange={val =>
                                                                    this.setState({ planType: val.target.value })
                                                                }
                                                                className={this.state.planTypeErr ? "form-control border-input-error kt-input pd-4-input shadow-none" : "form-control kt-input pd-4-input shadow-none"}>
                                                                <option value="0">Chọn gói dịch vụ</option>
                                                                {this.state.servicePlan.map(_item => {
                                                                    return (
                                                                        <option value={_item.id}>{_item.servicePlanName}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {this.state.planTypeErr ?
                                                                <div className="row">
                                                                    <label className="col-12 font-err mb-0">Vui lòng chon loại dịch vụ !</label>
                                                                </div> : <div />}
                                                        </div>
                                                    </div>

                                                    {/*<FormInput label="Gói dịch vụ"*/}
                                                    {/*           placeholder="Nhập gói dịch vụ"*/}
                                                    {/*           value={this.state.contractCode}*/}
                                                    {/*           type="text" checkError={this.state.contractCodeError} errorContent="Vui lòng nhập giá trị hợp đồng!"/>*/}

                                                </div>
                                                <div className="row">
                                                    {/*<FormInput label="Giá trị hợp đồng"*/}
                                                    {/*           placeholder="Nhập giá trị hợp đồng"*/}
                                                    {/*           value={this.state.contractPrice}*/}
                                                    {/*           type="text" checkError={this.state.contractPriceError} errorContent="Vui lòng nhập tên khách hàng!"/>*/}

                                                    <div className='row mt-3 col-6'>
                                                        <div className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                                            <span className="text-black-50">Giá trị hợp đồng
                                                            {/*<span className={'color-red d-inline'}>*</span>*/}
                                                            </span>
                                                        </div>
                                                        <div className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            <NumberFormat
                                                                value={this.state.contractPrice}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                suffix={" VND"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-3 col-6'>
                                                        <div
                                                            className="pl-0 pb-2 pr-0 col-4 kt-margin-b-10-tablet-and-mobile">
                                                            <span className="text-black-50">Loại hợp đồng
                                                            <span className={'color-red d-inline'}>*</span></span>
                                                        </div>
                                                        <div
                                                            className="pl-0 pb-2 col-8 kt-margin-b-10-tablet-and-mobile">
                                                            <select
                                                                value={this.state.contractType}
                                                                defaultValue='{0}'
                                                                onChange={val =>
                                                                    this.setState({ contractType: val.target.value, contractTypeErr: false })
                                                                }
                                                                className={this.state.contractTypeErr ? "form-control border-input-error kt-input pd-4-input shadow-none" : "form-control kt-input pd-4-input shadow-none"}>
                                                                <option value="0">Chọn loại hợp đồng</option>
                                                                <option value="NEW">Hợp đồng ký mới</option>
                                                                <option value="EXTENSION">Hợp đồng gia hạn</option>
                                                            </select>
                                                            {this.state.contractTypeErr ?
                                                                <div className="row">
                                                                    <label className="col-12 font-err mb-0">Vui lòng chon loại hợp đồng !</label>
                                                                </div> : <div />}
                                                        </div>
                                                    </div>

                                                    {/*<FormInput label="Loại hợp đồng"*/}
                                                    {/*           placeholder="Nhập loại hợp đồng"*/}
                                                    {/*           value={this.state.contractType}*/}
                                                    {/*           type="text" checkError={this.state.contractTypeError} errorContent="Vui lòng nhập giá trị hợp đồng!"/>*/}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                                        <div className='col-8'>
                                            <span className='text-header-profile'>THÔNG TIN THANH TOÁN</span>
                                        </div>
                                        <div className='col-4'>
                                            <div className='d-flex justify-content-end'>
                                                <button className="btn btn-primary" onClick={() => this.setState({ modalPayment: true })}>
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <table className={this.state.paymentTableErr ? "table border-input-error mt-5 mb-5 table-striped table-data table-bordered " : "table mt-5 mb-5 table-striped table-data table-bordered "}>
                                            <thead>
                                            <TableHeader header={this.state.header1} clickSort={(data) => {
                                            }} />
                                            </thead>
                                            <tbody>
                                            {this.state.paymentTimelines.length !== 0
                                                ? this.state.paymentTimelines.map((item, index) =>
                                                    <tr>
                                                        <th scope="row" className='text-center'>{index + 1}</th>
                                                        <td className='text-center'>
                                                            <DatePicker
                                                                className={this.state.paymentDateErr ? "w-auto form-control border-input-error  react-datepicker-wrapper kt-input pd-3-input hei-input" : "w-auto form-control react-datepicker-wrapper kt-input pd-3-input hei-input"}
                                                                onChangeRaw={(e) => { e.preventDefault() }}
                                                                selected={item.paymentTerm === null ? null : new Date(item.paymentTerm)}
                                                                minDate={new Date()}
                                                                dateFormat="dd-MM-yyyy"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onChange={
                                                                    (date) => {
                                                                        let paymentTimelines = this.state.paymentTimelines;
                                                                        paymentTimelines[index].paymentTerm = moment(date).format("YYYY-MM-DD")
                                                                        this.setState({
                                                                            paymentTimelines
                                                                        }, () => console.log(this.state.paymentTimelines))
                                                                    }
                                                                }
                                                            />
                                                            {/* {moment(item.paymentTerm).format("DD-MM-YYYY")} */}
                                                        </td>
                                                        <td className='text-center pr-3'>
                                                            <NumberFormat
                                                                value={item.paymentAmount}
                                                                // value={this.state.data.dealPrice / (this.state.paymentTimelines.length)}
                                                                // displayType={"text"}
                                                                thousandSeparator={true}
                                                                suffix={' VND'}
                                                                onChange={(val) => {
                                                                    this.changePayValue(val, index)
                                                                }}
                                                                className={this.state.payValueErr ? "form-control border-input-error kt-input pd-4-input hei-input input-money" : "form-control kt-input pd-4-input hei-input input-money"}
                                                            />
                                                        </td>

                                                        <td className='text-center'>
                                                            <button className="btn btn-action"
                                                                    title='Xóa hạn thanh toán'
                                                                    onClick={() => { this.deletePayment(index) }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                <i className="ti-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )

                                                : <tr>
                                                    <td colSpan={4}><div><span><h6>Chưa có thông tin thanh toán</h6></span></div></td>
                                                </tr>

                                            }
                                            </tbody>
                                        </table>
                                        {this.state.paymentTableErr ?
                                            <div className="row d-flex  align-items-center justify-content-center">
                                                <label className="col-12 font-err mb-0">Vui lòng thêm thông tin thanh toán !</label>
                                            </div> : <div />}
                                    </div>

                                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                                        <div className='col-8'>
                                            <span className='text-header-profile'>THÔNG TIN JOB</span>
                                        </div>
                                        <div className='col-4'>
                                            <div className='d-flex justify-content-end'>
                                                <button className="btn btn-primary" onClick={() => {
                                                    this.setState({ modalAddJob: true })
                                                }}>
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <table className={this.state.jobsListErr ? "table border-input-error mt-5 mb-5 table-striped table-data table-bordered " : "table  mt-5 mb-5 table-striped table-data table-bordered "}>
                                            <thead>
                                            <TableHeader header={this.state.header2} clickSort={(data) => {
                                            }} />
                                            </thead>
                                            <tbody>
                                            {this.state.jobsList.length !== 0
                                                ? this.state.jobsList.map((item, index) =>
                                                    <tr>
                                                        <th scope="row" className='text-center'>{index + 1}</th>
                                                        <td className='text-center'>
                                                            <input
                                                                className="form-control kt-input pd-4-input hei-input"
                                                                value={item.name}
                                                                onChange={(e) => {
                                                                    let jobsList = this.state.jobsList;
                                                                    jobsList[index].name = e.target.value
                                                                    this.setState({ jobsList })
                                                                }}
                                                            />
                                                            {/* {item.name} */}
                                                        </td>
                                                        <td className='text-center'>
                                                            <input
                                                                className="form-control kt-input pd-4-input hei-input"
                                                                type="number"
                                                                value={item.numberOfReports}
                                                                onChange={(e) => {
                                                                    let jobsList = this.state.jobsList;
                                                                    jobsList[index].numberOfReports = e.target.value
                                                                    this.setState({ jobsList }, () => console.log(this.state.jobsList))
                                                                }}
                                                            />
                                                            {/* {item.numberOfReports} */}
                                                        </td>
                                                        <td className='text-center'>
                                                            <NumberFormat
                                                                value={item.receiveMoney}
                                                                // displayType={"text"}
                                                                thousandSeparator={true}
                                                                suffix={' VND'}
                                                                onChange={(val) => {
                                                                    this.changeJobValue(val, index)
                                                                }}
                                                                className={this.state.jobValueErr ? "form-control border-input-error kt-input pd-4-input hei-input input-money" : "form-control kt-input pd-4-input hei-input input-money"}
                                                            />
                                                        </td>

                                                        <td className='text-center'>
                                                            <button className="btn btn-action"
                                                                    title='Xóa Job'
                                                                    onClick={() => { this.deleteJob(index) }}
                                                                    style={{ cursor: 'pointer' }}>
                                                                <i className="ti-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )

                                                : <tr>
                                                    <td colSpan={5}><div><span><h6>Không có dữ liệu</h6></span></div></td>
                                                </tr>

                                            }
                                            </tbody>
                                        </table>
                                        {this.state.jobsListErr ?
                                            <div className="row d-flex  align-items-center justify-content-center">
                                                <label className="col-12 font-err mb-0">Vui lòng thêm thông tin Job !</label>
                                            </div> : <div />}
                                    </div>

                                    <div className=" d-flex col-12 mt-3 ct-contract-data">
                                        <div className='col-8'>
                                            <span className='text-header-profile'>HỒ SƠ LƯU</span>
                                        </div>
                                        <div className='col-4'>
                                            <div className='d-flex justify-content-end'>
                                                <button className="btn btn-primary" onClick={() => this.setState({ modalUploadFile: true })}>
                                                    Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <table className={this.state.filesTableErr ? "table border-input-error mt-5 mb-5 table-striped table-data table-bordered " : "table mt-5 mb-5 table-striped table-data table-bordered "}>
                                            <thead>
                                            <TableHeader header={this.state.header3} clickSort={(data) => {
                                            }} />
                                            </thead>
                                            <tbody>
                                            {this.state.files.length > 0
                                                ? this.state.files.map((item, index) =>
                                                    <tr>
                                                        <th scope="row" className='text-center'>{index + 1}</th>
                                                        <td className='text-center'>{item.name}</td>
                                                        <td className='text-center'>{item.name}</td>
                                                        <td className='text-center'>
                                                            <button className="btn btn-action"
                                                                    title='Xóa file'
                                                                    onClick={() => this.deleteFile(item)}
                                                                    style={{ cursor: 'pointer' }}>
                                                                <i className="ti-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )

                                                : <tr>
                                                    <td colSpan={4} className='text-center' ><div><span><h6>Chưa có file được chọn</h6></span></div></td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                        {this.state.filesTableErr ?
                                            <div className="d-flex row align-items-center justify-content-center ">
                                                <label className="col-12 font-err mb-0">Vui lòng thêm thông tin hồ sơ !</label>
                                            </div> : <div />}
                                    </div>

                                    <div className="justify-content-center d-flex mb-3">
                                        <div className='text-center'
                                             style={{ textTransform: 'initial' }}
                                        >
                                            <button className="btn btn-primary"
                                                    onClick={() => {
                                                        if (this.state.files.length > 0) this.uploadFile()
                                                        else this.onSubmit()
                                                    }}
                                            >
                                                Tạo hợp đồng
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalUploadFile && <UploadContractDoc type="inContract" onCreate={true} customerId={this.state.data.customerId} uploadSuccess={() => { }} onClose={this.importFile} onHide={() => { this.setState({ modalUploadFile: false }) }} />}
                {this.state.modalAddJob && <ModalAddJob addJob={this.addJob} onHide={() => { this.setState({ modalAddJob: false }) }} />}
                {this.state.modalPayment && <ModalAddPayment addPayment={this.addPayment} onHide={() => { this.setState({ modalPayment: false }) }} />}
            </div>
        )
    }

}
export default withRouter(CreateContract)