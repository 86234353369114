import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal
} from 'react-bootstrap';
import {toast} from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Authentication from "../../services/auth";
import {forceLogout} from "../../utils/authority";
import Utils from "../../utils/utils";
import ActionRoleApi from "../../services/ob-user/action-role";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
import "./ModalLoginCss.css"
class ModalLogin extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anyValue: '',
            username: '',
            password: '',
            showPassword: false,
            remember: false,
            showForgotPassword: false,
        };
        this.arryInput = [this.userInput, this.loginInput];
        this.errors = {};
        this.constraints = {
            password: {
                // Password is also required
                presence: {message: '^Mật khẩu không được bỏ trống'},
                // And must be at least 5 characters long
                length: {
                    minimum: 6,
                    message: '^Mật khẩu phải có độ dài tối thiểu 6 ký tự',
                },
            },
            username: {
                // You need to pick a username too
                presence: {message: '^Số điện thoại không được bỏ trống'},
                // And it must be between 3 and 20 characters long
                length: {
                    minimum: 9,
                    message: '^Số điện thoại phải có độ dài tối thiểu 9 ký tự',
                },
            },
        };
        this.onShow = this.onShow.bind(this)
    }

    handleLoginClick = (e) => {
        e.preventDefault();
        window.OBPubConfig.loading = true
        this.submit();
    };

    getPermission = () => {
        ActionRoleApi.getPermission(res => {
            if (res.success) {
                window.OBPubConfig.permission = res.data
                localStorage.setItem("ob_vl_per", res.data)
            }
        })
    }

    submit = (e) => {
        let payload = {
            name: this.state.username,
            password: this.state.password
        }
        Authentication.login(payload, res => {
            if (!res.success) {
                toast.error("Đăng nhập không thành công")
            } else {
                // let day = this.state.remember ? 7 * 3600 * 24 : 3600 * 24;
                let day = this.state.remember ? 7 : 1;
                Cookies.set('ob_cl_token', res.data, {expires: day});
                let data = Utils.parseJwt(res.data);
                localStorage.setItem("ob_cl_userType", data.userType);
                // this.getPermission()
                // this.getUserDetail();
                setTimeout(() => {
                    this.props.onHide();
                    if (data.userType == "ADMIN")
                        window.location.replace('/#/admin/dashboard');
                    else if (data.userType == "PARTNER")
                        window.location.replace('/#/partner/dashboard');
                    else if (data.userType == "SUPERVISOR")
                        window.location.replace('/#/supervisor/contracts-management');
                    else if (data.userType == "ACCOUNTANT")
                        window.location.replace('/#/accountant/contracts-management');
                    else if (data.userType == "CALL_CENTER")
                        window.location.replace('/#/call-center/new-orders');
                    window.location.reload()
                }, 1000)
            }
        }).catch((error) => {
            toast.error("Đăng nhập không thành công")
        })
    }


    keyPress = (event, input) => {
        if (event.keyCode === 13) {
            if (input === 'enter') {
                return this.submit();
            } else {
                // input.focus();
            }
        }
    };


    onShow = (e) => {
        this.setState({
            anyValue: '',
            password: '',
            remember: false
        });
    };


    getUserDetail = () => {
        Authentication.getUserDetail(res => {
            if (!res.success) {
                toast.error("Đăng nhập thất bại do không thể lấy thông tin người dùng!")
                // this.props.enqueueSnackbar("Đăng nhập thất bại do không thể lấy thông tin người dùng!", {
                //   variant: 'error',
                // });
                forceLogout()
            } else {
                window.OBPubConfig.user = res.data;
                localStorage.setItem('ob_cl_userDetail', JSON.stringify(res.data));
            }
        })
    }

    handleShowModalForgotPassword = () => {
        // this.setState({showForgotPassword: true});
        this.props.showModalForgot()
        // this.props.onHide();
    };
    showSignupModal = () => {
        // this.setState({showForgotPassword: true});
        this.props.onOpenModalSignup()
        // this.props.onHide();
    };

    setForgotPasswordInfo = (data) => {

    }

    onCloseModalForgotPassword = () => {
        this.setState({showForgotPassword: false});
    }

    render() {
        const modalProp = {
            show: this.props.show, onHide: this.props.onHide, keyboard: false, backdrop: 'static',
        };
        const togglePasswordVisiblity = () => {
            this.setState({showPassword: !this.state.showPassword});
        };
        return (
            <div>
                <Modal {...modalProp} onShow={this.onShow} dialogClassName="modal-dialog-centered"
                       onExited={this.onExited}>
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="loginmodal-container">

                            {/*<div className="tab-content">*/}
                            {/*  <div className="tab-pane active" id="Login">*/}
                            <h1 className="text-center">Đăng nhập</h1>
                            {/*<form  autoComplete="off">*/}
                            <TextField
                                label="Số điện thoại/Mã số thuế"
                                id="outlined-margin-dense"
                                // className={classes.textField}
                                // helperText="Some important text"
                                onKeyDown={event => this.keyPress(event, this.loginInput)}
                                onChange={e => this.setState({username: Utils.filterNumberOnly(e.target.value).trim()})}
                                value={this.state.username}
                                margin="dense"
                                required
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                label="Mật khẩu"
                                id="outlined-margin-dense"
                                // className={classes.textField}
                                // helperText="Some important text"
                                onKeyDown={event => this.keyPress(event, 'enter')}
                                onChange={e => this.setState({password: e.target.value})}
                                margin="dense"
                                required
                                fullWidth
                                variant="outlined"
                                type={this.state.showPassword ? "text" : "password"}
                            />
                            <i className="hidePassword" onClick={togglePasswordVisiblity}>{eye}</i>{" "}
                            {/*<TextField id="outlined-basic" label="Tên đăng nhập" variant="d" />*/}
                            {/*<TextField id="outlined-basic" type='password' label="Mật khẩu" variant="" />*/}
                            {/*</form>*/}
                            <form ref={(c) => {
                                this.formInput = c;
                            }}
                            >
                                {this.props.errorMessage && (
                                    <div className="alert alert-warning my-3" role="alert">
                                        Tài khoản hoặc mật khẩu chưa chính xác. Vui lòng thử lại
                                    </div>
                                )}

                                {/*<div className="form-group">*/}
                                {/*  <input*/}
                                {/*    type="text"*/}
                                {/*    name="username"*/}
                                {/*    id="username"*/}
                                {/*    onKeyDown={event => this.keyPress(event, this.loginInput)}*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    ref={(c) => {*/}
                                {/*      this.userInput = c;*/}
                                {/*    }}*/}
                                {/*    required*/}
                                {/*    placeholder="Tên đăng nhập"*/}
                                {/*    className="form-control"*/}
                                {/*  />*/}
                                {/*  /!* <div className="help-block with-errors"></div> *!/*/}
                                {/*  <div className="messages"/>*/}
                                {/*</div>*/}
                                {/*<div className="form-group">*/}
                                {/*  <input*/}
                                {/*    type="password"*/}
                                {/*    name="password"*/}
                                {/*    id="password"*/}
                                {/*    onKeyDown={event => this.keyPress(event, 'enter')}*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    ref={(c) => {*/}
                                {/*      this.loginInput = c;*/}
                                {/*    }}*/}
                                {/*    required*/}
                                {/*    placeholder="Mật khẩu"*/}
                                {/*    className="form-control"*/}
                                {/*  />*/}
                                {/*  <div className="messages"/>*/}
                                {/*</div>*/}
                                <div className="d-flex justify-content-between mt-3 mb-2">
                                    <div className="form-group">
                                        <div className="checkbox clearfix">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        const value = this.state.remember;
                                                        this.setState({remember: !value});
                                                    }}
                                                    checked={this.state.remember}
                                                />
                                                {' '}
                                                Nhớ mật khẩu
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <a
                                            href="#"
                                            className="highlight-link"
                                            aria-label="Close"
                                            onClick={this.handleShowModalForgotPassword}>
                                            Quên mật khẩu ?
                                        </a>
                                    </div>
                                </div>
                                <a
                                    type="button"
                                    className="btn btn-original btn-1st w-100 type-button-fix bg-green"
                                    href="/#"
                                    onClick={this.handleLoginClick}
                                >
                      <span>
                        Đăng nhập
                        <i className="flaticon-right"/>
                      </span>
                                </a>
                                <p className="mt-3 text-center">
                                    Bạn chưa có tài khoản?
                                    {/*<a href="/signup" className="highlight-link"> Đăng ký ngay</a>*/}
                                    <span className="highlight-link" onClick={this.showSignupModal}> Đăng ký ngay</span>
                                </p>
                            </form>
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalLogin
