import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let UserWaitingApi = {
    callCenterSearch: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/user-waiting/call-center-search?${stringify(payload)}`,
            method: 'GET',
        }, callback);
    },
    receiveApplication: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/user-waiting/call-center-receive/${payload.id}`,
            method: 'POST',
            // body: payload
        }, callback)
    },
    doneToCreateOfficialAccount: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/user-waiting/done-to-create-official-account/${payload.id}`,
            method: 'POST',
        }, callback)
    },
    signUpNewUser: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/user-waiting/sign-up`,
            method: 'POST',
            body: payload
        }, callback);
    },
    callCenterUpdate: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/public/user-waiting/update/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
};
export default UserWaitingApi
