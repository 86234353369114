import React from 'react';
import {stringify} from 'qs';
import moment from "moment";

import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import Sidebar from "../../../components/Sidebar";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Pagination from "../../../components/Pagination";
import Autosuggestion from "../../../components/Form/Autosuggestion";
import ServicePlan from "../../customer/service-plan";

import ModalDetailOrder from "../../../components/Modal/modalDetailOrder";

import SecureCustomerApi from "../../../services/ob-user/secure-customer";
import SupervisorApi from "../../../services/ob-user/supervisor";
import PublicOrderApi from "../../../services/ob-core/public-order-service";
import PublicServicePlanApi from "../../../services/ob-core/public-service-plan";
import ModalAcceptOrder from "../../../components/Modal/modalAcceptOrder";
import ModalRejectOrder from "../../../components/Modal/modalRejectOrder";
import {toast} from "react-toastify";

class OrdersManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            pageSize: 10,
            totalItem: null,
            orderCode: null,
            sort: 'id',
            descending: 'DESC',
            status: "WAITING_FOR_CONFIRMATION",
            status_temp: "WAITING_FOR_CONFIRMATION",
            listCustomerId: [],
            list: [],
            modalDetailOrder: false,
            selectedId: '',
            supIdFilter: '',
            servicePlan: [],
            servicePlanId: null,
            servicePlanId_temp: null,
            modalAcceptOrder: false,
            modalRejectOrder: false,
            header: [
                {
                    id: "123",
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "code",
                    name: "Mã Order",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "customerId",
                    name: "Tên khách hàng",
                    style: {textAlign: "center"},
                    sort: false
                },
                //
                // {
                //     id: "value",
                //     name: "Giá trị hợp đồng (VND)",
                //     style: {textAlign: "center"},
                //     sort: false
                // },
                {
                    id: "serviceId",
                    name: "Gói dịch vụ",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "cmn_created_at",
                    name: "Thời gian gửi yêu cầu",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "status",
                    name: "Trạng thái",
                    style: {textAlign: "center"},
                    sort: false
                },
                {
                    id: "",
                    name: "Thao tác",
                    style: {minWidth: 150, textAlign: "center"},
                    sort: false
                }
            ]
        }
    }

    componentDidMount() {
        this.getOrderList();
        this.getServicePlan()
    }

    onHandleSearch = () => {
        let {code_temp, status_temp, servicePlanId_temp} = this.state;

        this.setState(
            {
                code: code_temp,
                status: status_temp,
                pageNumber: 0,
                servicePlanId: servicePlanId_temp,
            },
            () => {
                this.getOrderList();
            }
        );
    };

    onRefresh = () => {
        this.setState(
            {
                orderCode: "",
                status: "WAITING_FOR_CONFIRMATION",
                status_temp: "WAITING_FOR_CONFIRMATION",
                servicePlanId: null,
                servicePlanId_temp: '',
                modalAcceptOrder: false,
                modalRejectOrder: false,
            },
            () => this.onHandleSearch()
        );
    }

    getOrderList = () => {
        let self = this;
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"));
        let payload = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            // orderCode: this.state.orderCode,
            sort: this.state.sort,
            status: this.state.status,
            // supervisorId: userInfo?.id,
            descending: this.state.descending,
            servicePlanId: this.state.servicePlanId,
        }
        PublicOrderApi.supSearchByServicePlanIdAndStatus(payload, res => {
            if (res.success) {
                let listCustomerId = [];
                let data = res.data.content
                data.map(item => {
                    listCustomerId.push(item.customerId);
                });
                this.setState({
                    list: res.data.content,
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    listCustomerId,
                }, () => {
                    if (data.length > 0) self.getListCustomerByIds();
                })
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        }).catch(err => {
            toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
        })
    }

    getListCustomerByIds = () => {
        let {listCustomerId, listData} = this.state;
        let payload = {
            ids: listCustomerId
        };

        SecureCustomerApi.getNamesByListId(payload, res => {
            if (res.success === true) {
                let data = res.data;
                listData.map(item => {
                    let filter = data.filter(x => x.id == item.customerId);
                    item.customerName = filter[0]?.fullName;
                    item.customerPhone = filter[0]?.phone;
                });
                this.setState({
                    listData
                });
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        });
    }


    renderStatus(status) {
        switch (status) {
            case "WAITING_FOR_CONFIRMATION":
                return <h5><span className="badge badge-secondary">Chờ xác nhận</span></h5>;
            case "WAITING_CALL_CENTER_CONFIRM":
                return <h5><span className="badge badge-secondary">Chưa liên hệ</span></h5>;
            case "PROCESSING":
                return <h6><span className="badge badge-primary">Đang thực hiện</span></h6>;
            case "REJECTED":
                return <h5><span className="badge badge-warning">Đã từ chối</span></h5>;
            case "CONFIRMED":
                return <h6><span className="badge badge-success">Đã xác nhận</span></h6>;
            case "CANCELED":
                return <h6><span className="badge badge-danger">Đã hủy</span></h6>;
            case "CREATED_CONTRACT":
                return <h6><span className="badge badge-info">Đã tạo hợp đồng</span></h6>;
        }
    }

    handleChangePage = (page) => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getOrderList();
            }
        );
    }

    showModalDetailOrder = () => {
        this.setState({modalDetailOrder: true})
    }


    getServicePlan = () => {
        let payload = {
            type: 'BASIC',

        };
        PublicServicePlanApi.getByType(payload, res => {
            if (res.success) {
                this.setState({ servicePlan: res.data }, () => {
                    let _ser = this.state.servicePlan
                    PublicServicePlanApi.getByType({ type: 'ADVANCED' }, res1 => {
                        if (res1.success) {
                            res1.data.map(item => _ser.push(item))
                            this.setState({ servicePlan: _ser })
                        }
                    })
                })
            }
        })
    }

    showModalAcceptOrder = (row) => {
        this.setState({modalAcceptOrder: true, selectedOrder: row})
    }

    showModalRejectOrder = (row) => {
        this.setState({modalRejectOrder: true, selectedOrder: row})
    }

    render() {
        let {listData, pageNumber, header, servicePlan} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="orders-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách Order"/>
                                    <form className="mt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <label className="label-item">Gói dịch vụ</label>

                                                <select
                                                    value={this.state.servicePlanId_temp}
                                                    // defaultValue={this.state.servicePlanId_temp || this.state.servicePlanId}
                                                    onChange={val =>
                                                        this.setState({servicePlanId_temp: val.target.value})
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    {servicePlan.map(_item => {
                                                        return (
                                                            <option value={_item.id}>{_item.servicePlanName} {_item.type == "ADVANCED" ? "(Nâng cao)" : "(Cơ bản)"}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-6 pr-0">
                                                <label className="label-item">Trạng thái</label>
                                                <select
                                                    // value={this.state.status_temp}
                                                    value={this.state.status_temp}
                                                    onChange={val =>
                                                        this.setState({status_temp: val.target.value})
                                                    }
                                                    className="form-control kt-input pd-4-input shadow-none">
                                                    <option value="">Tất cả</option>
                                                    <option value="WAITING_FOR_CONFIRMATION">Chờ xác nhận</option>
                                                    <option value="WAITING_CALL_CENTER_CONFIRM">Call Center chưa liên hệ</option>
                                                    <option value="CONFIRMED">Đã xác nhận</option>
                                                    <option value="CREATED_CONTRACT">Đã tạo hợp đồng</option>
                                                    {/*<option value="PROCESSING">Đang xử lý</option>*/}
                                                    <option value="REJECTED">Đã từ chối</option>
                                                    <option value="CANCELED">Đã hủy</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 align-text-bottom pl-0 button-filter-wp'>
                                            <div className="button-filter">
                                                <button
                                                    type="button"
                                                    className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                    onClick={() => this.onHandleSearch()}
                                                >
                                                    Tìm kiếm
                                                </button>
                                                {/*&nbsp;&nbsp;*/}
                                                <button type="button"
                                                        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                        onClick={() => this.onRefresh()}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader
                                                header={header}
                                                clickSort={data => {
                                                    let a = "";
                                                    let descend = "";
                                                    if (data.sortAsc == null) {
                                                        // a = "id=asc";
                                                        a = "id";
                                                        descend = 'DESC'
                                                    } else {
                                                        a = data.sortId;
                                                        descend = data.sortAsc ? 'ASC' : 'DESC'
                                                    }
                                                    this.setState(
                                                        {
                                                            sort: a,
                                                            descending: descend
                                                        },
                                                        () => this.getOrderList()
                                                    );
                                                }}
                                            />
                                            </thead>
                                            {!this.state.listData ||
                                            this.state.listData.length === 0 ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="text-center">
                                                            Không có dữ liệu
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                {listData &&
                                                listData.length > 0 &&
                                                listData?.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            {row.code}
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {row?.customerName}
                                                        </td>
                                                        {/*<td style={{textAlign: "right"}}>*/}
                                                        {/*    {" "}*/}
                                                        {/*    {row.dealPrice == '-1' ? "Thỏa thuận" :*/}
                                                        {/*        <NumberFormat*/}
                                                        {/*            value={row.dealPrice}*/}
                                                        {/*            displayType={"text"}*/}
                                                        {/*            thousandSeparator={true}*/}
                                                        {/*        />}*/}
                                                        {/*</td>*/}
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.servicePlanName}
                                                        </td>
                                                        <td className='pl-4' style={{textAlign: "left"}}>
                                                            {row.cmnCreated ? moment(row.cmnCreated).format("YYYY-MM-DD HH:mm") : ''}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            {this.renderStatus(row.status)}
                                                        </td>

                                                        <td style={{textAlign: "center"}}>
                                                            <button
                                                                className="btn btn-action"
                                                                title="Xem chi tiết"
                                                                onClick={() => {
                                                                    this.setState({selectedOrder: row}, () => {
                                                                        this.showModalDetailOrder()
                                                                    })
                                                                }}
                                                            >
                                                                <i className="ti-book"/>
                                                            </button>
                                                            <button
                                                                className="btn btn-action"
                                                                title="Nhận Order"
                                                                disabled={
                                                                    row.status != "WAITING_FOR_CONFIRMATION"
                                                                }
                                                                onClick={() =>
                                                                    this.showModalAcceptOrder(row)
                                                                }
                                                            >
                                                                <i className="ti-check-box"/>

                                                            </button>
                                                            {/*<button*/}
                                                            {/*    className="btn btn-action"*/}
                                                            {/*    title="Từ chối order"*/}
                                                            {/*    onClick={() =>*/}
                                                            {/*        this.showModalRejectOrder(row)*/}
                                                            {/*    }*/}
                                                            {/*    disabled={*/}
                                                            {/*        row.status != "WAITING_FOR_CONFIRMATION"*/}
                                                            {/*    }*/}
                                                            {/*>*/}
                                                            {/*    <i className="ti-close"/>*/}
                                                            {/*</button>*/}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end m-2 font-14">
                                        <div className="pagination-right">
                                            <Pagination
                                                activePage={this.state.pageNumber + 1}
                                                itemsCountPerPage={this.state.pageSize}
                                                totalItemsCount={this.state.totalItem}
                                                changeHandler={page => this.handleChangePage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalDetailOrder && <ModalDetailOrder data={this.state.selectedOrder}
                                                                  onHide={() => this.setState({modalDetailOrder: false})}/>}
                {this.state.modalAcceptOrder && <ModalAcceptOrder selectedOrder={this.state.selectedOrder}
                                                                  onRefresh={this.onRefresh}
                                                                  onHide={() => this.setState({modalAcceptOrder: false})}/>}
                {this.state.modalRejectOrder && <ModalRejectOrder selectedOrder={this.state.selectedOrder}
                                                                  onRefresh={this.onRefresh}
                                                                  onHide={() => this.setState({modalRejectOrder: false})}/>}
            </div>
        );
    }

}

export default OrdersManagement
