import React, {Component} from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import {
    Card,
    Grid,
    Paper,
    TextField,
    TablePagination
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Sidebar from "../../../components/Sidebar";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import TableHeader from "../../../components/TableHeader/index";
import ContractManagement from "../contracts-management/index";
import PageHeader from "../../../components/PageHeader/index";

import PublicContractApi from "../../../services/ob-core/public-contract";
import Pagination from "../../../components/Pagination";
import {toast} from "react-toastify";

const paymentStatus = [
    {
        value: "PAID",
        label: "Đã thanh toán"
    },
    {
        value: "PARTIALLY_PAID",
        label: "Thanh toán một phần"
    },
    {
        value: "UNPAID",
        label: "Chưa thanh toán"
    },
    // {
    //     value: "PAYMENT_TERM",
    //     label: "Đến hạn thanh toán"
    // }
];

class PaymentManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractId: null,
            status_temp: "",
            code_temp: "",
            listData: [],
            pageNumber: 0,
            pageSize: 10,

            code: "",
            paymentStatus: "",
            sort: "",
            totalItem: 0,
            status_temp_search: "",
            activeSortCode: false,
            sortCode: false,
            sortContractType: false,
            activeSortContractType: false,
            header: [
                {
                    id: '123',
                    name: "STT",
                    style: {textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Mã hợp đồng",
                    style: {minWidth: 120, textAlign: "center"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Loại hợp đồng",
                    style: {minWidth: 165, textAlign: "left"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Giá trị hợp đồng (VNĐ)",
                    style: {minWidth: 140, textAlign: "left"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Số tiền đã thanh toán (VNĐ)",
                    style: {minWidth: 180, textAlign: "left"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Số tiền chưa thanh toán (VNĐ)",
                    style: {minWidth: 200, textAlign: "left"},
                    sort: false,
                },
                {
                    id: '123',
                    name: "Trạng thái",
                    style: {minWidth: 130, textAlign: "center"},
                    sort: false,
                }
            ],
        };
    }

    getListPaymentFromAPI() {
        let {code, paymentStatus, pageNumber, pageSize, sort} = this.state;
        let payload = {
            code: code,
            paymentStatus: paymentStatus,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: sort
        };
        PublicContractApi.searchPaymentByCode(payload, res => {
            if (res.success === true) {
                this.setState({
                    listData: res.data.content,
                    totalItem: res.data.totalElements,
                    totalPage: Math.round(res.data.totalElements / this.state.pageSize)
                });
            } else {
                toast.error("Đã có lỗi xảy ra, vui lòng tải lại trang!")
            }
        });
    }

    componentDidMount() {
        this.getListPaymentFromAPI();
    }

    // ***********************************************************************************************

    renderStatus(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return <span className="badge badge-success">Đã thanh toán</span>;
            case "UNPAID":
                return <span className="badge badge-warning">Chưa thanh toán</span>;
            case "PARTIALLY_PAID":
                return <span className="badge badge-info">Thanh toán một phần</span>;
            case "PAYMENT_TERM":
                return <span className="badge badge-danger">Đến hạn thanh toán</span>;

            default:
                return;
        }
    }

    renderContractType(type) {
        switch (type) {
            case "NEW":
                return "Hợp đồng đăng kí mới";
            case "EXTENSION":
                return "Hợp đồng gia hạn";

            default:
                return;
        }
    }


    // BUTTON_HANDLE***********************************************************************************************


    onhandleSearch = () => {
        let {code_temp, status_temp} = this.state;

        this.setState(
            {
                code: code_temp.trim().toLowerCase(),
                paymentStatus: status_temp,
                pageNumber: 0
            },
            () => {
                this.getListPaymentFromAPI();
            }
        );
    };

    onHandleClearText() {
        this.setState(
            {
                code_temp: "",
                status_temp: "",
                status_temp_search: ""
            },
            () => this.onhandleSearch()
        );
    }


    // ***********************************************************************************************


    handleSortContractType(value) {
        this.setState({
            sortCode: false,
            activeSortCode: value
        });
        if (this.state.activeSortContractType) {
            if (this.state.sortContractType == true) {
                this.setState(
                    {
                        activeSortContractType: false,
                        sortContractType: false,
                        sort: ""
                    },
                    () => this.getListPaymentFromAPI()
                );
            } else {
                this.setState(
                    {
                        sortContractType: !this.state.sortContractType,
                        sort: this.state.sortContractType ? "status=asc" : "status=desc"
                    },
                    () => this.getListPaymentFromAPI()
                );
            }
        } else {
            this.setState(
                {
                    activeSortContractType: true,
                    sort: "status=asc"
                },
                () => this.getListPaymentFromAPI()
            );
        }
    }

    handleChangePage = page => {
        this.setState(
            {
                pageNumber: page - 1
            },
            () => {
                this.getListPaymentFromAPI();
            }
        );
    };

    render() {
        let {listData, pageNumber, header} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <Sidebar activeClass="payment-management"/>
                        <div className="mb-4 col-md-10 tab-content">
                            <div className="card col-12">
                                <div className="pt-3 mb-2">
                                    <PageHeader routerEnable={true} title="Danh sách thanh toán"/>
                                    <form className="pt-4 kt-form kt-form--fit kt-margin-b-20">
                                        <div className="row">
                                            <div className="col-6 pl-0">
                                                <TextField
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.onhandleSearch()
                                                        }
                                                    }}
                                                    fullWidth
                                                    size={'small'}
                                                    label="Mã hợp đồng"
                                                    placeholder="E.g: HD08784757"
                                                    variant="outlined"
                                                    value={this.state.code_temp}
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={val => {
                                                        this.setState({
                                                            code_temp: val.target.value.trimLeft()
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <Autocomplete
                                                    size="small"
                                                    disableClearable={true}
                                                    options={paymentStatus}
                                                    inputValue={this.state.status_temp_search}
                                                    getOptionLabel={option => option.label}
                                                    style={{width: "100%"}}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            label="Trạng thái"
                                                            placeholder="Tất cả"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onInputChange={val => {
                                                        this.setState({
                                                            status_temp_search: val.target.value
                                                        });
                                                    }}
                                                    onChange={(val, c) => {
                                                        this.setState({
                                                            status_temp: c?.value,
                                                            status_temp_search: c?.label
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='mt-2 col-md-6 align-text-bottom pl-0  button-filter-wp'>
                                                <div className="button-filter">
                                                    <button
                                                        type="button"
                                                        className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                                                        onClick={() =>
                                                            this.onhandleSearch()
                                                        }
                                                    >
                                                        Tìm kiếm
                                                    </button>
                                                    <button type="button"
                                                            className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"
                                                            onClick={() => this.onHandleClearText()}
                                                    >Refresh
                                                    </button>
                                                </div>
                                            </div>
                                            {/*<Grid item xs={12} sm={12} md={4}*/}
                                            {/*      className='text-center pt-2 mt-1'*/}
                                            {/*>*/}
                                            {/*    <Button*/}
                                            {/*        style={{textTransform: "initial"}}*/}
                                            {/*        className="mr-3"*/}
                                            {/*        variant="contained"*/}
                                            {/*        color="primary"*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.onhandleSearch();*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Tìm kiếm*/}
                                            {/*    </Button>*/}
                                            {/*    <Button*/}
                                            {/*        style={{textTransform: "initial"}}*/}
                                            {/*        variant="outlined"*/}
                                            {/*        color="default"*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.onHandleClearText();*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Refresh*/}
                                            {/*    </Button>*/}
                                            {/*</Grid>*/}
                                        </div>
                                    </form>
                                </div>
                                <div className="p-0 mb-3">
                                    <div className="table-wrapper">
                                        <table className="table table-striped table-data table-bordered">
                                            <thead>
                                            <TableHeader header={header} clickSort={(data) => {
                                                this.handleSortContractType(data);
                                            }}/>
                                            </thead>
                                            {!this.state.listData || this.state.listData.length == 0
                                                ? <tbody>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className='text-center'>Không có dữ liệu</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                : <tbody>
                                                {listData.map((row, index) => (
                                                    <tr style={{height: 50}}>
                                                        <td style={{textAlign: "center"}} scope="row">
                                                            {" "}
                                                            {this.state.pageNumber * this.state.pageSize +
                                                            index +
                                                            1}
                                                        </td>
                                                        <td style={{textAlign: "left"}} scope="row">
                                                            <Link to={{
                                                                pathname: "/customer/contracts-detail",
                                                                search: `?contractId=${row.id}&code=${row.code}`,
                                                                state: {fromDashboard: true, contractId: 1}
                                                            }}
                                                                  style={{color: "#67b1ee"}}>
                                                                {row.code}
                                                            </Link>
                                                        </td>
                                                        <td style={{textAlign: "left"}}>
                                                            {this.renderContractType(row.type)}
                                                        </td>
                                                        <td style={{textAlign: "right", paddingRight: 20}}>
                                                            {" "}
                                                            <NumberFormat
                                                                value={row.value}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                // suffix={"đ"}
                                                            />
                                                        </td>
                                                        <td style={{textAlign: "right", paddingRight: 30}}>
                                                            <NumberFormat
                                                                value={row.paid}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                // suffix={"đ"}
                                                            />
                                                        </td>
                                                        <td style={{textAlign: "right", paddingRight: 30}}>
                                                            <NumberFormat
                                                                value={row.value - row.paid}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                // suffix={"đ"}
                                                            />
                                                        </td>

                                                        <td style={{textAlign: "center"}}>
                                                            {this.renderStatus(row.paymentStatus)}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    <div className="justify-flex-end">
                                        <Pagination
                                            activePage={this.state.pageNumber + 1}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.totalItem}
                                            changeHandler={page => this.handleChangePage(page)}
                                        />
                                    </div>
                                    {/*<Grid container justify={"flex-end"}>*/}
                                    {/*    <TablePagination*/}
                                    {/*        rowsPerPageOptions={[*/}
                                    {/*            10,*/}
                                    {/*            20,*/}
                                    {/*            30,*/}
                                    {/*            40,*/}
                                    {/*            {label: "All", value: this.state.totalItem}*/}
                                    {/*        ]}*/}
                                    {/*        colSpan={3}*/}
                                    {/*        component="div"*/}
                                    {/*        count={this.state.totalItem}*/}
                                    {/*        page={pageNumber}*/}
                                    {/*        rowsPerPage={this.state.pageSize}*/}
                                    {/*        labelRowsPerPage={"Số kết quả trên trang"}*/}
                                    {/*        // backIconButtonText={lang.titleHover.buttonBackPage}*/}
                                    {/*        // nextIconButtonText={lang.titleHover.buttonNextPage}*/}
                                    {/*        labelDisplayedRows={({from, to, count}) =>*/}
                                    {/*            `${from}-${to === -1 ? count : to} / ${count}`*/}
                                    {/*        }*/}
                                    {/*        onChangePage={(event, newPage) => {*/}
                                    {/*            this.setState({pageNumber: newPage}, () => {*/}
                                    {/*                this.getListPaymentFromAPI();*/}
                                    {/*            });*/}
                                    {/*        }}*/}
                                    {/*        onChangeRowsPerPage={event => {*/}
                                    {/*            this.setState(*/}
                                    {/*                {*/}
                                    {/*                    pageSize: event.target.value,*/}
                                    {/*                    pageNumber: pageNumber*/}
                                    {/*                },*/}
                                    {/*                () => {*/}
                                    {/*                    this.getListPaymentFromAPI();*/}
                                    {/*                }*/}
                                    {/*            );*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentManagement;
