import React, {Component} from 'react'
import lang from "../../../../../../customer/profile/lang";

import AccountantApi from "../../../../../../../services/ob-user/accountant";

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : [];
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSelected: this.props.location.state ? this.props.location.state.accountantId : '',
            data: {}
        };
    }

    componentDidMount() {
        this.getDetail()
    }

    getDetail = () => {
        let payload = {
            id: this.props.accountantId
        };
        AccountantApi.adminGetDetailAccountantById(payload, res => {
            // console.log(res)
            if (res.success) {
                this.setState({
                    data: res.data
                })

            }
        })
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <div className='row kt-margin-b-20 mt-4'>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Họ và tên</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.fullName}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Số điện thoại</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.phone}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Email</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.email}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Khu vực hỗ trợ</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {renderProvinceId(data.areaId)}
                    </div>
                    <div
                        className="pl-4 pb-3 pr-0 col-md-4 col-lg-4 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        <label className="text-black-50">Lĩnh vực hỗ trợ</label>
                    </div>
                    <div
                        className="pl-0 col-md-8 col-lg-8 col-sm-11 kt-margin-b-10-tablet-and-mobile">
                        {data.fieldSupport ? data.fieldSupport : ''}
                    </div>
                </div>

            </div>
        );
    }
}

export default Information
