import React from 'react';
// import window from 'global';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    Modal, Container, Row, Col, Button, Form,
} from 'react-bootstrap';
import {toast} from 'react-toastify';
// import {LOGIN_FAILURE, clearAuthError, loginUser, getInfoAccount} from '../../actions/auth';
// import validate from '../../constants/validate';
// import ModalForgotPassword from './ModalForgotPassword';
// import History from "../../history";
import Cookies from 'js-cookie';
import ModalForgotPassword from "../Modal/modalForgotPassword";
import { useHistory, withRouter } from "react-router"
import {forceLogout} from "../../utils/authority";

import OtpApi from "../../services/ob-user/otp";
import PublicCustomerApi from "../../services/ob-user/public-customer";
import { el } from 'date-fns/locale';
import {Link} from "react-router-dom";
import Authentication from "../../services/auth";
import Utils from "../../utils/utils";
class ModalSignup extends React.Component {
    static defaultProps = {
        showOtp: null,
    };
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anyValue: '',
            username: '',

            tax: '',
            fullname: '',
            password: '',
            repassword: '',

            taxErr:false,
            fullnameErr:false,
            phoneErr:false,
            passwordErr:false,
            repasswordErr:false,

            verifiedOtp: false,
            agreeTerms: false,
            transId: '',
            otpCode: '',
            showForgotPassword: false,
        };
        this.arryInput = [this.userInput, this.loginInput];
        this.errors = {};
        this.constraints = {
            password: {
                // Password is also required
                presence: {message: '^Mật khẩu không được bỏ trống'},
                // And must be at least 5 characters long
                length: {
                    minimum: 6,
                    message: '^Mật khẩu phải có độ dài tối thiểu 6 ký tự',
                },
            },
            repassword: {
                // Password is also required
                presence: {message: '^Mật khẩu không được bỏ trống'},
                // And must be at least 5 characters long
                length: {
                    minimum: 6,
                    message: '^Mật khẩu phải có độ dài tối thiểu 6 ký tự',
                },
            },
            username: {
                // You need to pick a username too
                presence: {message: '^Tên tài khoản không được bỏ trống'},
                // And it must be between 3 and 20 characters long
                length: {
                    minimum: 6,
                    message: '^Tên tài khoản phải có độ dài tối thiểu 6 ký tự',
                },
            },
        };
        this.onShow = this.onShow.bind(this);
    }
    handleLoginClick = (e) => {
        e.preventDefault();
        this.submit();
    };

    submit = () => {
        if(this.state.phone == '' && this.state.password == '' && this.state.fullname == '' && this.state.repassword == '' && this.state.tax == ''){
            // this.props.enqueueSnackbar('Không được để trống thông tin!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                fullnameErr:true,
                phoneErr:true,
                taxErr:true,
                passwordErr:true,
                repasswordErr:true,
            })
        }else if(this.state.phone == ''){
            // this.props.enqueueSnackbar('Không được để trống SĐT!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                phoneErr:true,
            })
        }else if(this.state.fullname == ''){
            // this.props.enqueueSnackbar('Không được để trống họ tên!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                fullnameErr:true,
            })
        }else if(this.state.tax == ''){
            // this.props.enqueueSnackbar('Không được để trống SĐT!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                taxErr:true,
            })
        }else if(this.state.password == ''){
            // this.props.enqueueSnackbar('Không được để trống mật khẩu!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                passwordErr:true,
            })
        }else if(this.state.password.length < 6 || this.state.password.length > 20){
            // this.props.enqueueSnackbar('Mật khẩu trong khoảng 6 - 20 kí tự!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                passwordErr:true,
            })
        }else if(this.state.repassword == ''){
            // this.props.enqueueSnackbar('Vui lòng nhập lại mật khẩu!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                repasswordErr:true,
            })
        }else if(this.state.password!= this.state.repassword){
            // this.props.enqueueSnackbar('Nhập lại mật khẩu không đúng!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            this.setState({
                repasswordErr:true,
            })
        } else if(!this.state.agreeTerms){
            // this.props.enqueueSnackbar('Nhập lại mật khẩu không đúng!', {
            //     anchorOrigin: {
            //         vertical: "top",
            //         horizontal: "right"
            //     },
            //     variant: 'error',
            // })
            toast.error("Vui lòng tích vào ô đồng ý với điều khoản!")
        }else{
            let payload = {
                fullName: this.state.fullname,
                password: this.state.password,
                tax: this.state.tax,
                phone: this.state.phone
            }
            PublicCustomerApi.checkTaxExist(payload, res => {
                if(res.success){
                    // this.props.setInfo(payload);
                    // this.getOtp()
                    this.registerCustomer(payload)
                }
                else{ 
                    toast.error("Mã số thuế đã tồn tại trong hệ thống. Vui lòng kiểm tra lại!")
                }
            })
        }
    }

    registerCustomer = (payload) => {
        let self = this
        PublicCustomerApi.create(payload, res => {
            if (res.success) {
                self.props.onHide();
                this.autoLogin(payload.tax, payload.password);
                // toast.success('Đăng ký tài khoản thành công! Vui lòng đăng nhập để sử dụng dịch vụ');
            } else {
                toast.error('Đăng ký tài khoản không thành công! ' + res.message)
            }
        })
    }
    autoLogin(username, password) {
        let payload = {
            name: username,
            password: password
        }
        Authentication.login(payload, res => {
            if (!res.success) {
                toast.error("Đăng nhập không thành công")
            } else {
                // let day = this.state.remember ? 7 * 3600 * 24 : 3600 * 24;
                let day = this.state.remember ? 7 : 1;
                Cookies.set('ob_cl_token', res.data, {expires: day});
                let data = Utils.parseJwt(res.data);
                localStorage.setItem("ob_cl_userType", data.userType);
                // this.getPermission()
                // this.getUserDetail();
                setTimeout(() => {
                    this.props.onHide();
                    window.location.reload()
                }, 1000)
                // window.location.reload()
            }
        }).catch((error) => {
            toast.error("Đăng nhập không thành công")
        })
    }
    keyPress = (event, input) => {
        if (event.keyCode === 13) {
            if (input === 'enter') {
                this.submit();
            } else {
                input.focus();
            }
        }
    };


    onShow = (e) => {
        this.setState({
            anyValue: '',
            name: '',
            phone: '',
            tax: '',
            password: '',
            repassword: '',
            verifiedOtp: false,
            agreeTerms: false,
            transId: '',
            otpCode: '',
        });
    };


    getOtp = () => {
        let payload = {
            phone: this.state.phone
        }
        OtpApi.sendOtp(payload, res => {
            if (res.success) {
                let data = {
                    info: {
                        fullName: this.state.fullname,
                        password: this.state.password,
                        phone: this.state.phone,
                        tax: this.state.tax,
                    },
                    transId: res.data
                }
                this.props.setInfo(data);
            }
            else {
                toast.error('Gửi OTP không thành công!')
            }
        })
    }

    handleShowOtp = event => {
        event.preventDefault();
        if (this.props.showOtp) {
            this.props.showOtp(event);
        }
    };

    handleShowModalForgotPassword = () => {
        this.setState({showForgotPassword: true});
        this.props.onHide();
    };

    onCloseModalForgotPassword = ()=>{
        this.setState({showForgotPassword: false});
    }

    render() {
        const modalProp = {
            show: this.props.show, onHide: this.props.onHide, keyboard: false, backdrop: 'static',
        };
        // const onCloseModalForgotPassword = () => {
        //   this.setState({showForgotPassword: false});
        // };
        const {anyValue, agreeTerms, password} = this.state;
        return (
            <div>
                <Modal {...modalProp} onShow={this.onShow} dialogClassName="modal-dialog-centered" onExited={this.onExited}>
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="loginmodal-container">

                            {/*<div className="tab-content">*/}
                            {/*    <div className="tab-pane active" id="Login">*/}
                                    <h1 className="text-center">Đăng ký tài khoản</h1>
                                    {/*<form  autoComplete="off">*/}

                                    <div className="row mt-1">
                                        <label className="col-6 mt-2">Tên doanh nghiệp</label>
                                    </div>
                                    <div>
                                        <input
                                            className={this.state.fullnameErr?"inputPassword form-control w-100 col-12 border-input-error":"inputPassword form-control w-100 col-12"}
                                            placeholder='Nhập tên doanh nghiệp'
                                            fullWidth
                                            maxLength={255}
                                            value={this.state.fullname}
                                            onChange={(e) => this.setState({fullname: e.target.value},
                                                () =>{
                                                    this.state.fullname.length == 0 ? this.setState({fullnameErr: true}): this.setState({fullnameErr: false, btnStatus: false})
                                                })}
                                        />
                                        {this.state.fullnameErr?<span className="pl-1 color-red size-font-err-date">Tên đang để trống!</span>:<div/>}
                                    </div>

                                    <div className="row mt-1">
                                        <label className="col-6 mt-2">Mã số thuế</label>
                                    </div>
                                    <div>
                                        <input
                                            className={this.state.taxErr?"inputPassword form-control w-100 col-12 border-input-error":"inputPassword form-control w-100 col-12"}
                                            placeholder='Nhập mã số thuế doanh nghiệp'
                                            fullWidth
                                            maxLength={14}
                                            value={this.state.tax}
                                            onChange={(e) => this.setState({tax: e.target.value.trim()},
                                                () =>{
                                                    this.state.tax.length < 10 ? this.setState({taxErr: true, btnStatus: true}): this.setState({taxErr: false, btnStatus: false})
                                                })}
                                        />
                                        {this.state.taxErr?<span className="pl-1 color-red size-font-err-date">Mã số thuế đang để trống hoặc sai định dạng!</span>:<div/>}
                                    </div>
                                    <div className="row mt-1">
                                        <label className="col-6 mt-2">Số điện thoại</label>
                                    </div>
                                    <div>
                                        <input
                                            className={this.state.phoneErr?"inputPassword form-control w-100 col-12 border-input-error":"inputPassword form-control w-100 col-12"}
                                            placeholder='Nhập số điện thoại người đại diện'
                                            fullWidth
                                            maxLength={11}
                                            value={this.state.phone}
                                            onChange={(e) => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')},
                                                () =>{
                                                    this.state.phone.length == 0 || this.state.phone.length < 10 ? this.setState({phoneErr: true, btnStatus: true}): this.setState({phoneErr: false, btnStatus: false})
                                                })}
                                        />
                                        {this.state.phoneErr?<span className="pl-1 color-red size-font-err-date">SĐT đang để trống hoặc sai định dạng!</span>:<div/>}
                                    </div>

                                    <div className='d-flex p-0 col-12'>
                                        <div className='col-6 p-0 pr-1'>
                                            <div className="row mt-1">
                                                <label>Mật khẩu</label>
                                            </div>
                                            <div>
                                                <input
                                                    className={this.state.passwordErr?"inputPassword form-control w-100 col-12 border-input-error":"inputPassword form-control w-100 col-12"}
                                                    placeholder='Nhập mật khẩu'
                                                    fullWidth
                                                    type='password'
                                                    maxLength={50}
                                                    value={this.state.password}
                                                    onChange={(e) => this.setState({password: e.target.value.trim()},
                                                        () =>{
                                                            this.state.password.length == 0 || this.state.password.length < 6 ? this.setState({passwordErr: true, btnStatus: true}): this.setState({passwordErr: false, btnStatus: false})
                                                        })}
                                                />
                                                {this.state.passwordErr?<span className="pl-1 color-red size-font-err-date">Mật khẩu đang để trống hoặc sai định dạng!</span>:<div/>}
                                            </div>
                                        </div>
                                        <div className='col-6 p-0 pl-1'>
                                            <div className="row mt-1">
                                                <label>Nhập lại mật khẩu</label>
                                            </div>

                                            <div>
                                                <input
                                                    className={this.state.repasswordErr?"inputPassword form-control w-100 col-12 border-input-error":"inputPassword form-control w-100 col-12"}
                                                    placeholder='Nhập lại mật khẩu'
                                                    fullWidth
                                                    type='password'
                                                    maxLength={50}
                                                    value={this.state.repassword}
                                                    onChange={(e) => this.setState({repassword: e.target.value.trim()},
                                                        () =>{
                                                            this.state.repassword.length == 0 || this.state.repassword !=  this.state.password ? this.setState({repasswordErr: true, btnStatus: true}): this.setState({repasswordErr: false, btnStatus: false})
                                                        })}
                                                />
                                                {this.state.repasswordErr?<span className="pl-1 color-red size-font-err-date">Nhập lại mật khẩu sai!</span>:<div/>}
                                            </div>
                                        </div>
                                    </div>
                                    {/*<TextField*/}
                                    {/*    label="Số điện thoại"*/}
                                    {/*    id="outlined-margin-dense"*/}
                                    {/*    type='number'*/}
                                    {/*    // className={classes.textField}*/}
                                    {/*    // helperText="Some important text"*/}
                                    {/*    onKeyDown={event => this.keyPress(event, this.loginInput)}*/}
                                    {/*    onChange={e => this.setState({phone: e.target.value.trim().replace(/[^0-9]/g, '')})}*/}
                                    {/*    margin="dense"*/}
                                    {/*    inputProps={{maxlength: 11}}*/}
                                    {/*    required*/}
                                    {/*    fullWidth*/}
                                    {/*    variant="outlined"*/}
                                    {/*/>*/}

                                    {/*<TextField*/}
                                    {/*    label="Mật khẩu"*/}
                                    {/*    id="outlined-margin-dense"*/}
                                    {/*    type='password'*/}
                                    {/*    // className={classes.textField}*/}
                                    {/*    // helperText="Some important text"*/}
                                    {/*    onKeyDown={event => this.keyPress(event, 'enter')}*/}
                                    {/*    onChange={e => this.setState({password: e.target.value.trim()})}*/}
                                    {/*    margin="dense"*/}
                                    {/*    inputProps={{maxlength: 20}}*/}
                                    {/*    required*/}
                                    {/*    fullWidth*/}
                                    {/*    variant="outlined"*/}
                                    {/*/>*/}
                                    {/*<TextField*/}
                                    {/*    label="Nhập lại mật khẩu"*/}
                                    {/*    id="outlined-margin-dense"*/}
                                    {/*    type='password'*/}
                                    {/*    // className={classes.textField}*/}
                                    {/*    // helperText="Some important text"*/}
                                    {/*    onKeyDown={event => this.keyPress(event, 'enter')}*/}
                                    {/*    onChange={e => this.setState({repassword: e.target.value.trim()})}*/}
                                    {/*    margin="dense"*/}
                                    {/*    inputProps={{maxlength: 20}}*/}
                                    {/*    required*/}
                                    {/*    fullWidth*/}
                                    {/*    variant="outlined"*/}
                                    {/*/>*/}
                                    {/*<div className="mt-2"><span className="text-link" onClick={this.getOtp}>Lấy mã OTP</span></div>*/}

                                    {/*<div className="row justify-content-between" style={{alignItems: 'center'}}>*/}
                                    {/*    <TextField*/}
                                    {/*        label="Mã OTP"*/}
                                    {/*        id="outlined-margin-dense"*/}
                                    {/*        // className={classes.textField}*/}
                                    {/*        // helperText="Some important text"*/}
                                    {/*        onKeyDown={event => this.keyPress(event, this.loginInput)}*/}
                                    {/*        onChange={e => this.setState({otpCode: e.target.value})}*/}
                                    {/*        margin="dense"*/}
                                    {/*        required*/}
                                    {/*        variant="outlined"*/}
                                    {/*    />*/}
                                    {/*    <button className="btn btn-1st type-button-fix ml-2" disabled={this.state.otpCode?.length != 6} onClick={this.submitVerifyOTP}>Xác thực</button>*/}
                                    {/*</div>*/}

                                    <form ref={(c) => {
                                        this.formInput = c;
                                    }}
                                    >
                                        {this.props.errorMessage && (
                                            <div className="alert alert-warning my-3" role="alert">
                                                Tài khoản hoặc mật khẩu chưa chính xác. Vui lòng thử lại
                                            </div>
                                        )}

                                        {/*<div className="form-group">*/}
                                        {/*  <input*/}
                                        {/*    type="text"*/}
                                        {/*    name="username"*/}
                                        {/*    id="username"*/}
                                        {/*    onKeyDown={event => this.keyPress(event, this.loginInput)}*/}
                                        {/*    onChange={this.onChange}*/}
                                        {/*    ref={(c) => {*/}
                                        {/*      this.userInput = c;*/}
                                        {/*    }}*/}
                                        {/*    required*/}
                                        {/*    placeholder="Tên đăng nhập"*/}
                                        {/*    className="form-control"*/}
                                        {/*  />*/}
                                        {/*  /!* <div className="help-block with-errors"></div> *!/*/}
                                        {/*  <div className="messages"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group">*/}
                                        {/*  <input*/}
                                        {/*    type="password"*/}
                                        {/*    name="password"*/}
                                        {/*    id="password"*/}
                                        {/*    onKeyDown={event => this.keyPress(event, 'enter')}*/}
                                        {/*    onChange={this.onChange}*/}
                                        {/*    ref={(c) => {*/}
                                        {/*      this.loginInput = c;*/}
                                        {/*    }}*/}
                                        {/*    required*/}
                                        {/*    placeholder="Mật khẩu"*/}
                                        {/*    className="form-control"*/}
                                        {/*  />*/}
                                        {/*  <div className="messages"/>*/}
                                        {/*</div>*/}
                                        <div className="d-flex justify-content-between mt-3 mb-2">
                                            <div className="form-group">
                                                <div className="checkbox clearfix">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                const value = this.state.agreeTerms;
                                                                this.setState({agreeTerms: !value});
                                                            }}
                                                            checked={this.state.agreeTerms}
                                                        />
                                                        {' '}
                                                        Đồng ý với <Link  target="_blank" to="/dieu-khoan-dich-vu" replace>điều khoản dịch vụ và chính sách hoạt động </Link> của One Book
                                                    </label>
                                                </div>
                                            </div>
                                            {/*<div className="form-group">*/}
                                            {/*    <a*/}
                                            {/*        href="#"*/}
                                            {/*        className="highlight-link"*/}
                                            {/*        aria-label="Close"*/}
                                            {/*        onClick={this.handleShowModalForgotPassword}>*/}
                                            {/*        Quên mật khẩu ?*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*{this.state.verifiedOtp &&*/}
                                        <button
                                            type="button"
                                            className="btn btn-original btn-1st w-100 type-button-fix bg-green"
                                            // href="/#"
                                            // disabled={!this.state.verifiedOtp}
                                            onClick={this.handleLoginClick}
                                        >
                                          <span>
                                            Đăng ký
                                            <i className="flaticon-right"/>
                                          </span>
                                        </button>
                                        {/*}*/}

                                        {/*    :*/}
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="btn btn-original btn-1st w-100 type-button-fix"*/}
                                        {/*    // href="/#"*/}
                                        {/*    onClick={this.submitVerifyOTP}*/}
                                        {/*    >*/}
                                        {/*    <span>*/}
                                        {/*    Xác thực*/}
                                        {/*    <i className="flaticon-right"/>*/}
                                        {/*    </span>*/}
                                        {/*</button>*/}
                                        {/*<p className="mt-3 text-center">*/}
                                        {/*    Bạn chưa có tài khoản?*/}
                                        {/*    <a href="/signup" className="highlight-link"> Đăng ký ngay</a>*/}
                                        {/*</p>*/}
                                    </form>
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalSignup
