import React, { Component } from "react";
import "./index.css"
import NumberFormat from "react-number-format";
// import SelectPlan from "../../../utils/modal/SelectPlan";

import PublicServicePlanApi from "../../../../services/ob-core/public-service-plan";
import PublicServiceQuotationApi from "../../../../services/ob-core/public-service-quotation";

import {toast} from "react-toastify";

class ChooseService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idService: "",
      idPark: "",
      idPlan: "",
      idServiceChanging: "",
      numberOfBill: 1,
      timeSelected: 1,
      listServices: [],
      serviceDetail: [],
      planPrice: [],
      servicePlanQuotationId: null,
      selectedServiceType: "BASIC",
      listRealmBasic: [],
      selectedService: null,
    };
  }

  componentDidMount = () => {
    this.props.showLoading()
    this.setState({selectedServiceType: this.props.selectedServiceType}, () => {
      this.getServicePlan();
    })

  };

  // getServicePlan = () => {
  //   let payload = {
  //     // type: "BASIC"
  //   };
  //   // SelectPlanAPI.getPlan(payload, res => {
  //   SelectService.getAllPlanQuotation(payload, res => {
  //     console.log("response", res);
  //     // this.setState({ listServices: res.data });
  //   });
  // };

  getServiceDetail = () => {
    let payload = {
      id: this.state.idService
    };
    console.log(this.state.idService);
    // PublicServicePlanApi.getDetail(payload, res => {
    //   console.log("response", res);
    //   this.setState({ serviceDetail: res.data });
    // });
  };

  getPlanPriceByRealm = (realmId, callback) => {
    let payload = {
      // id: this.state.idPlan
      realmId: realmId,
      servicePlanId : this.state.idService
    };
    PublicServiceQuotationApi.getPricePlanbyServicePlanAndRealm(payload, res => {
      // console.log("response", res);
      let data = res.data;
      // this.setState({ planPrice: res.data });
      // let listPlan = [];
      // data.filter((item)=> )
      return callback(data)
    });
  };

  filterPrice = (realmId, data) => {
    let filter = data.filter((item) => item.realmId == realmId)
    // console.log("filter>>>>>>", filter);
  }

  // calculatePrice = (realmId, localId) => {
  //   let {planPrice, numberOfBill, timeSelected} = this.state;
  //   console.log("fdsfdsf>>>>",planPrice[localId]);
  //
  //   let filter = planPrice.filter((item) => item.realmId == realmId)
  //   let idx = planPrice.indexOf(filter[0]);
  //   let selectedServiceDetail = planPrice[localId].detail
  //   // console.log(realmId);
  //   let price = 0;
  //   // filter[0].detail.map((item)=> {
  //   //   if (item.realmId == realmId) price = item.
  //   // })
  //   let detailFilter = filter[0].detail.filter((item) => item.realmId == realmId)
  //   // let item = selectedServiceDetail.filter((item) => item.numberOfBillsOnMonthId == numberOfBill)
  //   let item = filter[0].detail.filter((item) => item.numberOfBillsOnMonthId == numberOfBill)
  //   price = item[0].price
  //   planPrice[idx].price = item[0].price * timeSelected
  //   // console.log("filter>>>>",filter);
  //   // console.log("idx>>>>",idx);
  //   // console.log("selectedServiceDetail>>>>", selectedServiceDetail);
  //   // console.log("item>>>>", item);
  //   // console.log("price>>>>", price);
  //   return this.setState({planPrice, servicePlanQuotationId: item[0].id})
  // }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("check props>>>", nextProps, this.props)
    if (nextProps.selectedServiceType != this.props.selectedServiceType){
      this.setState({selectedServiceType: nextProps.selectedServiceType}, () => {
        this.props.showLoading()
        this.getServicePlan()
      })
    }
  }


  getServicePlan = () => {
    let payload = {
      type: this.state.selectedServiceType
    };
    PublicServicePlanApi.getByType(payload, res => {
      if (res.success){
        this.setState({listRealmBasic : res.data}, ()=> this.props.hideLoading())
      }else{
        toast.error("Đã xảy ra lỗi trong quá trình lấy dữ liệu, vui lòng thử lại sau!")
      }
    })
  };

  render() {
    let { listServices, serviceDetail, planPrice, idService, listRealmBasic } = this.state;
    let self = this;
    return (
      <div>
        <h4 style={{ marginBottom: 10 }}>1. Chọn dịch vụ</h4>

        <div className="justify-space-between">
          <div className='row justify-space-evenly'>
            {listRealmBasic.length > 0 && listRealmBasic?.map ((item, index) => {
              return(
                  <div className="col-md-4 col-xs-12 ">
                    <div className="justify-center">
                      <div className="mt-3 mb-3"
                           onClick={()=> this.setState({selectedService: item.id}, () => this.props.chooseService(item.id, item.servicePlanName, item.priceType))}
                      >
                        <div className={item.id == this.state.selectedService ? 'item-selected p-2 card body-service-plan-card' : 'item-unselect p-2 card body-service-plan-card'}>
                          <div className='p-2 pb-4 service-plan-box-content h-300 mh-400' >
                            <div className='d-flex justify-content-center font-weight-bold mb-3 p-2 mb-3'>
                              {item.servicePlanName.toUpperCase()}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item.content.replaceAll("\n", "</br>") }}/>
                          </div>
                          {/*<div className="p-2 d-flex justify-content-center" style={{height: 70}}>*/}
                          {/*  {this.state.selectedService == item.id &&*/}
                          {/*  <CheckCircleTwoToneIcon className="" fontSize="large" style={{color: "green"}}/>}*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
          <div className="row justify-flex-end" style={{ marginTop: 20, marginBottom: 30, alignSelf:'center'}}>
            {/*<button type="button"*/}
            {/*        className="border-1-s pr-2 mr-2 type-button-fix btn btn-light btn-sm shadow-none"*/}
            {/*        onClick={() => {}}*/}
            {/*>*/}
            {/*  Hủy bỏ*/}
            {/*</button>*/}

            <button
                type="button"
                disabled={!this.state.selectedService}
                className="border-1-s mr-2 background-btn type-button-fix btn btn-primary btn-sm shadow-none"
                onClick={() => {
                  if(this.state.selectedServiceType == "BASIC") {
                    this.props.nextPage("choose-plan");
                  } else {
                    this.props.nextPage("confirm-service");
                  }
                }}
            >
              Tiếp theo
            </button>
          </div>
      </div>
    );
  }
}
export default ChooseService
