import {request} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";
import { stringify } from 'qs';

let AdminServiceQuotation = {
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-quotation/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    getAll: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-quotation/get-all`,
            method: 'GET'
        }, callback)
    },
    getByServicePlanAndBusiness: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-quotation/get-by-service-plan-id-and-business?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getByServicePlan: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-core/admin/service-quotation/get-by-service-plan-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
}
export default AdminServiceQuotation
