import React from "react";
import imgNews from '../../../assets/images/news/img.png'
import "./news.css"
import "./detail/style.css"
import PublicNews from "../../../services/ob-core/public-news";
import moment from "moment";
import PublicNewsCategory from "../../../services/ob-core/public-news-category";
import Convert from "../../../utils/convertUrlPra";
import Link from "../../../components/Link";
import {useHistory} from 'react-router-dom';
import Pagination from "../../../components/Pagination";

class HomeNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idCategory: '',
            data: [],
            pageSize: 10,
            pageNumber: 0,
            dataCategory: JSON.parse(localStorage.getItem("ob_cl_news_cat"))
        }
    }

    componentWillMount() {
        // console.log(params)
        // this.setState({idCategory: params.category})
        // this.getDataNews()
        // this.getDataCategory()
        this.unlisten = this.props.history.listen((location, action) => {
            this.getIdByPath()
        });
        let params = Convert.urlParams(this.props.location.search);
        this.setState({path: params.c}, () =>{
            this.getIdByPath()
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let oldPath = Convert.urlParams(this.props.location.search);
        let newPath = Convert.urlParams(nextProps.location.search);
        if (oldPath.c !== newPath.c) {
            this.setState({path: newPath.c}, () => {
                this.getIdByPath()
            })
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getIdByPath = () => {
        // let cate = JSON.parse(localStorage.getItem("ob_cl_news_cat"))
        // console.log("check category>>>>>>>", cate)
        let filter = this.state.dataCategory.filter((item) => item.path == this.state.path)
        if (filter.length > 0) {
            this.setState({categoryId: filter[0].id}, () => {
                this.getDataNews()
            })
        }
    }

    getDataNews = () => {
        // let params = Convert.urlParams(this.props.location.search);
        let payload = {
            categoryId: this.state.categoryId,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            status: false
            // id: 1
        }
        PublicNews.getListByCategory(payload, res => {
            if (res.success) {
                this.setState({
                    data: res.data.content,
                    totalItem: res.data.totalElements
                })
            } else {

            }
        })
    }

    // getDataCategory = () => {
    //     PublicNewsCategory.getList(null, res => {
    //         if (res.success) {
    //             this.setState({
    //                 dataCategory: res.data
    //             })
    //         }
    //     })
    // }
    handleChangePage = page => {
        this.setState({
            pageNumber: page - 1
        }, () => this.getDataNews())
    };


    render() {
        const {data, dataCategory} = this.state;
        return (
            <div className="home-news-wrapper pt-3">
                <div>
                    {data.length != 0 &&
                    <div className="container hot-news-box d-flex">
                        <div className='col-md-7 col-xs-12 hot-news-1 pl-0 img-with-text' title={data[0]?.title}>
                            <Link to={`/home/detail?c=${this.state.path}&i=${data[0]?.id}&t=${data[0]?.url}`} title={data[0]?.title}>
                                <img src={data[0] ? data[0].image : imgNews} className='img-fluid' alt=""/>
                                <div className='text-layer'>
                                    <span
                                        className={`hot-news-title news-title-big ${data[0]?.title.length > 100 ? 'font-14' : ''}`}>
                                        {data[0] ? data[0].title : ''}
                                    </span>
                                    <div className='info-time'>{moment(data[0]?.cmnCreatedAt).format('MMMM DD, YYYY')}</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-5 col-xs-12 pr-0" title={data[1]?.title}>
                            <div className='hot-news-2 mb-4 img-with-text'>
                                <Link to={`/home/detail?c=${this.state.path}&i=${data[1]?.id}&t=${data[1]?.url}`} title={data[1]?.title}>
                                    <img src={data[1] ? data[1].image : imgNews} className='img-fluid' alt=""/>
                                    <div className='text-layer'>
                                        <span
                                            className={`hot-news-title news-title-medium ${data[1]?.title.length > 100 ? 'font-14' : ''}`}>
                                            {data[1] ? data[1].title : ''}
                                        </span>
                                        <div
                                            className='info-time'>{moment(data[1]?.cmnCreatedAt).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </Link>
                            </div>
                            <div className='hot-news-3 mb-4 img-with-text' title={data[2]?.title}>
                                <Link to={`/home/detail?c=${this.state.path}&i=${data[2]?.id}&t=${data[2]?.url}`} title={data[2]?.title}>
                                    <img src={data[2] ? data[2].image : imgNews} className='img-fluid' alt=""/>
                                    <div className='text-layer'>
                                        <span
                                            className={`hot-news-title news-title-medium ${data[2]?.title.length > 100 ? 'font-14' : ''}`}>
                                            {data[2] ? data[2].title : ''}
                                        </span>
                                        <div
                                            className='info-time'>{moment(data[2]?.cmnCreatedAt).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>}
                    <div className={data.length > 0 ? "container row mt-4" : "container row" }>
                        <div className="list-home-news col-8 mb-4">
                            <div className="news-tag-label"><span>BÀI VIẾT</span></div>
                            {data.length > 0 ? data?.map((item, index) => (
                                <div className="home-news-item d-flex ">
                                    <div className="col-4 home-news-ava pl-0 pr-0">
                                        <Link to={`/home/detail?c=${this.state.path}&i=${item.id}&t=${item.url}`} title={item.title}>
                                            <img className='w-100' src={item.image || imgNews} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="col-8 home-news-item-box">
                                        <Link to={`/home/detail?c=${this.state.path}&i=${item.id}&t=${item.url}`} title={item.title}>
                                            <div className='news-title' title={item.title}>{item.title}</div>
                                        </Link>
                                        <div className='news-description'>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className='news-create-info'>
                                        <span className='create-by'>
                                            {item.createByName}
                                        </span> - <span className='create-at' title={item.cmnCreatedAt}>
                                            {moment(item.cmnCreatedAt).format('DD-MM-YYYY HH:mm')}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            )) : <div>
                                <span>Chưa có bài viết nào trong thư mục này</span>
                            </div>}
                            <div className="pagination-right justify-flex-end mb-3 mt-3">
                                <Pagination
                                    activePage={this.state.pageNumber + 1}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.state.totalItem}
                                    changeHandler={page => this.handleChangePage(page)}
                                />
                            </div>
                        </div>
                        <div className="list-home-category col-4 mb-4 pr-0">
                            <div className='list-home-category-area'>
                                <div className="news-tag-label mt-0"><span>DANH MỤC</span></div>
                                <ul>
                                    {dataCategory?.map((item, index) => (
                                        <li>
                                            {/*<a href={item.id} title={item.name}>*/}
                                            {/*    <div className='news-category-title' title={item.name}>*/}
                                            {/*        {item.name}*/}
                                            {/*    </div>*/}
                                            {/*</a>*/}
                                            <Link
                                                title={item.name}
                                                to={{
                                                    pathname: item.type ==1?"/home/news":"/home/documents",
                                                    search: `?doc=${item.path}`,
                                                    state: {
                                                        fromDashboard: true,
                                                        category: item.path,
                                                    }
                                                }}
                                            >
                                                <div className='news-category-title' title={item.name}>
                                                    {item.name}
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default HomeNews
