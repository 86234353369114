import React from 'react'
import {Link, Switch, Route, Redirect} from "react-router-dom";
import Profile from './profile'
import CompleteProfile from './complete-profile'
import StaffManagement from './staff-management'
import ContractsManagement from './contracts-management'
import CreateContract from './create-contract'
import AdminManagement from './admin-management'
import CustomerManagement from './customer-management'
import DataManagement from './data-management'
import RevenueManagement from './revenue-management'
import SupervisorRevenueManagement from "./revenue-management/supervisor-revenue-management";
import AccountantRevenueManagement from "./revenue-management/accountant-revenue-management";
import WalletManagement from './wallet-management'
import JobsManagement from './jobs-management'
import PermissionManagement from './permission-management'
import ReportManagement from './report-management'
import SupervisorManagement from './supervisor-management'
import DetailSupervisor from './supervisor-detail'
import DetailAccountant from './supervisor-detail/accountant/accountant-detail'
import ListAccountantOfSupervisor from './supervisor-detail/accountant'
import InformationOfSupervisor from './supervisor-detail/InformationSup'
import AccountantManagement from './accountant-management'
import PartnerManagement from './partner-management'
import DetailContract from './contracts-detail'
import ListData from './contracts-detail/ListData'
import ListReport from './contracts-detail/ListReport'
import Information from './contracts-detail/Information'
import History from './contracts-detail/history'
import OrdersManagement from "./orders-management";
import CompanyOrganizationalChart from "./company-organizational-chart";
import NewSignUpManagement from "./new-signup-management";
import OverduePaymentManagement from "./overdue-payment-management";
import {history} from "../../components/Layout";
import Cookies from "js-cookie";
import Dashboard from "./dashboard";
import { checkPermission } from '../../utils/common';
import AccountingBalanceSheet from "../accountant/accounting-balance-sheet";
import ReportBusinessResults from "../accountant/report-business-results";
import DirectCashFlowStatement from "../accountant/direct-cash-flow-statement";
import IndirectCashFlowStatement from "../accountant/indirect-cash-flow-statement";
import ReportSummary from "../accountant/report-summary";


class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPartner: false
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType")
        this.setState({isPartner: userType == "PARTNER"})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("check props in partner>>>>>>", this.props);
        let token = Cookies.get('ob_cl_token');
        if (!token) history.push("/");
    }

    render() {
        return (
            <Switch>
                {!this.state.isPartner && <Redirect to={"/"}/>}
                <Route path="/partner/profile" component={Profile}/>
                <Route path="/partner/dashboard" component={Dashboard}/>
                <Route path="/partner/complete-profile" component={CompleteProfile}/>
                <Route path="/partner/contracts-management" component={ContractsManagement}/>
                <Route path="/partner/create-contract" component={CreateContract}/>
                {checkPermission("list_admin_user_account") && <Route path="/partner/admin-management" component={AdminManagement}/>}
                <Route path="/partner/customer-management" component={CustomerManagement}/>
                <Route path="/partner/data-management" component={DataManagement}/>
                {checkPermission("list_revenue_partner") && <Route path="/partner/revenue-management" component={RevenueManagement}/>}
                {checkPermission("list_revenue_supervisor") && <Route path="/partner/supervisor-revenue-management" component={SupervisorRevenueManagement}/>}
                {checkPermission("list_revenue_accountant") && <Route path="/partner/accountant-revenue-management" component={AccountantRevenueManagement}/>}
                {checkPermission("list_partner_wallet") && <Route path="/partner/wallet-management" component={WalletManagement}/>}
                <Route path="/partner/jobs-management" component={JobsManagement}/>
                {checkPermission("view_role") && <Route path="/partner/permission-management" component={PermissionManagement}/>}
                <Route path="/partner/supervisor-management" component={SupervisorManagement}/>
                <Route path="/partner/accountant-management" component={AccountantManagement}/>
                <Route path="/partner/partner-management" component={PartnerManagement}/>
                <Route path="/partner/report-management" component={ReportManagement}/>
                {checkPermission("view_order_list") && <Route path="/partner/orders-management" component={OrdersManagement}/>}
                <Route path="/partner/contracts-detail" component={DetailContract}/>
                {checkPermission("view_supervisor_account_detail") && <Route path="/partner/supervisor-detail" component={DetailSupervisor}/>}
                {checkPermission("list_supervisor_account") && <Route path="/partner/supervisor-detail/accountant" component={ListAccountantOfSupervisor}/>}
                <Route path="/partner/accountant-detail" component={DetailAccountant}/>
                <Route path="/partner/overdue-payment-management" component={OverduePaymentManagement}/>
                <Route path="/partner/supervisor-detail/InformationSup" component={InformationOfSupervisor}/>
                <Route path="/partner/contracts-detail/ListData" component={ListData}/>
                <Route path="/partner/contracts-detail/ListReport" component={ListReport}/>
                <Route path="/partner/contracts-detail/Information" component={Information}/>
                {checkPermission("view_editing_history_detail1") && <Route path="/partner/contracts-detail/history" component={History}/>}
                <Route path="/partner/company-organizational-chart" component={CompanyOrganizationalChart}/>
                {checkPermission("management_new_account_register") && <Route path="/partner/new-signup-management" component={NewSignUpManagement}/>}
                <Route path="/partner/balance" component={AccountingBalanceSheet}/>
                <Route path="/partner/business-results" component={ReportBusinessResults}/>
                <Route path="/partner/direct-cash" component={DirectCashFlowStatement}/>
                <Route path="/partner/indirect-cash" component={IndirectCashFlowStatement}/>
                <Route path="/partner/report-summary" component={ReportSummary}/>
            </Switch>
        )
    }
}

export default Partner
