/**
 * Created by WebStorm.
 * User: LongP
 * Date: 23/03/2020
 * Time: 14:20
 **/
import React from 'react';
import {toast} from "react-toastify";
import "./Modal.css"

import {
    Modal,
} from 'react-bootstrap';
import Autosuggestion from "../Form/Autosuggestion";

import SupervisorApi from "../../services/ob-user/supervisor";
import AccountantApi from "../../services/ob-user/accountant";

class ModalChangeSupForAccountant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supId: '',
            listSup: [],
            name: ''
        };
    }

    componentDidMount() {
        this.setState({
            supId: '',
            listSup: [],
        });
        this.getListSup()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.setState({
                supId: '',
                listSup: [],
            });
        }
    }

    getListSup = () => {
        let payload = {
            fullName: this.state.name,
            supervisorId: this.props.id
        }
        SupervisorApi.getListSupervisorSelectChange(payload, res => {
            if (res.success) {
                this.setState({listSup: res.data.content})
                // this.props.onHide();
                // this.props.onRefresh();
            }
        })
    };


    onSubmit = () => {
        if (this.state.supId === '') {
            toast.error('Bạn chưa chọn người phụ trách chuyên môn!')
        } else {
            this.onChangeSup()
        }
    }

    onChangeSup = () => {
        let payload = {
            accountantId: this.props.idAccountant,
            supervisorId: this.state.supId
        }
        AccountantApi.changeAccountantToSupervisor(payload, res => {
            if (res.errorCode === 'One-Book-User.E000021') {
                toast.error('Đã xảy ra lỗi, vui lòng thử lại !')
            } else if (res.success) {
                toast.success('Chỉnh sửa thành công !')
                this.props.onHide();
                setTimeout(this.props.onRefresh(), 1000);
            } else {
                toast.error('Đã xảy ra lỗi, vui lòng thử lại !')
            }
        })
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHideModal,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered modal-assign-order">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="modal-container">

                        </div>
                        <div>
                            <h1 className="text-center" style={{fontSize: 18}}><b>CHỈNH SỬA THÔNG TIN KẾ TOÁN</b></h1>
                        </div>

                        <div>
                            <div className="row">
                                <label className="col-6 mt-2">Người phụ trách</label>
                            </div>
                            <Autosuggestion
                                label="Người phụ trách"
                                // className={this.state.supId?"form-control border-input-error col-6 mt-2 kt-input pd-4-input hei-input":"form-control col-6 mt-2 kt-input pd-4-input hei-input"}
                                placeholder="Nhập tên người phụ trách"
                                // value={this.state.contractCode}
                                type="text"
                                data={this.state.listSup}
                                onSelect={(item) => this.setState({supId: item.id})}
                                onChange={(val) => {
                                    this.setState({name: val, selectedSupervisor: null})
                                    setTimeout(() => {
                                        this.getListSup()
                                    }, 300);
                                }}
                                // checkError={!this.state.supervisorList}
                                // errorContent="Vui lòng chọn một Supervisor"
                            />
                        </div>
                        <div container item xs={12}>
                            <div item xs={12}>
                                <div className="align-right mt-4 mb-2">
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-primary"
                                        disabled={this.state.btnStatus}
                                        onClick={this.onSubmit}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"
                                       onClick={this.props.onHide}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalChangeSupForAccountant;
