import React from 'react';
import Link from "../Link";
import lang from "../lang";
import {forceLogout} from "../../utils/authority";
import MenuItems from "../Sidebar/MenuItems";
import {checkPermission} from "../../utils/common";
import Cookies from "js-cookie";

class SidebarMobile extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selected: null,
            isAuth: false,
        }
    }

    componentWillMount() {
        let userType = localStorage.getItem("ob_cl_userType");
        let token = Cookies.get('ob_cl_token');
        this.setState({isAuth: !!token});
        if (userType){
            switch (userType) {
                case "CALL_CENTER":
                    return this.setState({items: MenuItems.CallCenter})
                case "CUSTOMER":
                    return this.setState({items: MenuItems.Customer})
                case "SUPERVISOR":
                    return this.setState({items: MenuItems.Supervisor})
                case "PARTNER":
                    return this.setState({items: MenuItems.Partner})
                case "ACCOUNTANT":
                    return this.setState({items: MenuItems.Accountant})
                case "ADMIN":
                    return this.setState({items: MenuItems.Admin})
            }
        }

    }

    handleClick = event => {
        event.preventDefault();
        if (this.props.onOpenModalLogin) {
            this.props.onOpenModalLogin(event);
            this.props.toggleSidebarMobile();
        }
    };

    handleShowSignup = event => {
        event.preventDefault();
        if (this.props.onOpenModalSignup) {
            this.props.onOpenModalSignup(event);
            this.props.toggleSidebarMobile();
        }
    };

    render() {
        let show = this.props.show;
        let activeClass = this.props?.activeClass;
        let parentClass = this.props?.parentClass;
        let selected = this.state.selected;
        let listCategory = JSON.parse(localStorage.getItem("ob_cl_news_cat"));
        return (
            <div className={show ? "show sidebar-mobile-wrapper" : "sidebar-mobile-wrapper"}>
                <div className="p-2 sb-mb-header">
                    <span className="navbar-brand">
                        <Link to="/" className="d-flex align-items-center">
                            <img src="/images/onebook_logo.png" alt="" style={{width: 180}}/>
                        </Link>
                    </span>
                    <button type="button" className="btn btn-lg close" onClick={this.props.toggleSidebarMobile} style={{width: 30}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="nav-link sb-mb-user">
                    <div >
                        <img
                            src={window?.OBPubConfig?.userInfo?.urlImage || "/images/logo_square.png"}
                            alt="avatar"
                            className="d-inline-block thumb" style={{borderRadius: "50%"}}/>
                        <span style={{fontWeight: 'bold', color: "#000"}}>{window?.OBPubConfig?.user?.phone ? window?.OBPubConfig?.user?.phone : lang.menu.guest}</span>
                    </div>

                </div>
                <div className="sb-mb-homepage-menu">
                    <div className="row">
                        <div className="col-6 pl-0">
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to="/" role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">Trang chủ</Link>
                            </span>
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to="/home/partner" role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">Đối tác</Link>
                            </span>
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to="/home/supervisor" role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">Chuyên gia</Link>
                            </span>
                            {/*<span className="nav-item dropdown sb-mb-homepage-menu-item">*/}
                            {/*    <Link className="nav-link dropdown-toggle" href="https://a1.faonline.vn/SAS/Main/Login.aspx" role="button" onClick={this.props.toggleSidebarMobile}*/}
                            {/*          aria-haspopup="true" aria-expanded="false">Đào tạo</Link>*/}
                            {/*</span>*/}
                        </div>
                        <div className="col-6 pl-0">
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to="/home/select-plan-step-1" role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">Báo giá dịch vụ</Link>
                            </span>
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to="/home/about-us" role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">Về chúng tôi</Link>
                            </span>
                            {/*<span className="nav-item dropdown sb-mb-homepage-menu-item">*/}
                            {/*    <Link className="nav-link dropdown-toggle" href="https://a1.faonline.vn/SAS/Main/Login.aspx" role="button" onClick={this.props.toggleSidebarMobile}*/}
                            {/*          aria-haspopup="true" aria-expanded="false">Fast Accounting</Link>*/}
                            {/*</span>*/}
                            <span className="nav-item dropdown sb-mb-homepage-menu-item">
                                <Link className="nav-link dropdown-toggle" to={`/home/news?c=${listCategory && listCategory.length?listCategory[0].path:""}`} role="button" onClick={this.props.toggleSidebarMobile}
                                      aria-haspopup="true" aria-expanded="false">{listCategory && listCategory.length> 0?listCategory[0].name:""}</Link>
                            </span>
                        </div>
                    </div>
                </div>
                {this.state.isAuth &&
                <div className="sb-mb-list">
                    <ul className="menu-y text-sm nav nav-tabs sidebar sidebar-mobile overflow-hidden">
                        {this.state.items?.map((item, index) => (
                            !item.hide && (item.permission && !checkPermission(item.permission) ? null :
                                <li style={{backgroundColor: "#fff"}}>
                                    <Link to={item.url ? item.url : "#"} data-toggle="collapse"
                                          data-target={`#${item.activeClass}`}
                                          onClick={() => {
                                              // $(`#${item.activeClass}`).collapse({
                                              //     toggle: false
                                              // })
                                              if (selected == item.activeClass) {
                                                  // selected = null;
                                                  this.setState({selected: null})
                                              } else {
                                                  // selected = item.activeClass
                                                  this.setState({selected: item.activeClass})

                                              }
                                              if (!item.children) {
                                                  this.props.toggleSidebarMobile();
                                              }
                                          }}
                                          className={`${activeClass === item.activeClass ? 'active' : ''} d-block py-2 no-border-top d-flex justify-content-between align-items-center`}>
                                <span style={{minHeight: 34, lineHeight: '34px'}}>
                                    <i className={item.icon} aria-hidden="true"/>
                                    {item.title}
                                </span>
                                        {item?.children?.length > 0 &&
                                        <i className={`${(item.activeClass === parentClass || item.activeClass == selected) ? 'fas fa-angle-down' : 'fas fa-angle-right'} icon-arrow-side-bar`}/>}
                                    </Link>
                                    {item?.children?.length > 0 && <ul id={item.activeClass}
                                                                       className={`${(item.activeClass === parentClass || item.activeClass == selected) ? 'content-side-bar-active' : 'content-side-bar'} submenu-y collapse`}>
                                        {item?.children?.length > 0 && item?.children?.map((_item, idx) => (
                                            !_item.hide && (_item.permission && !checkPermission(_item.permission) ? null :
                                                <li style={{backgroundColor: "#fff"}}>
                                                    <Link to={_item.url}
                                                          onClick={() => {
                                                              this.setState({selected: _item.parentClass})
                                                              this.props.toggleSidebarMobile()
                                                          }}
                                                          className={`${activeClass === _item.activeClass ? 'active' : ''} d-block py-2`}>
                                            <span>
                                                <i className="caret"/>
                                                {_item.title}
                                            </span>

                                                    </Link>
                                                </li>
                                            )))}
                                    </ul>}
                                </li>
                            )))}
                    </ul>
                </div>
                }
                {this.state.isAuth ?
                    <div className="sb-mb-logout d-flex justify-content-center align-items-center">
                        <button type="button" className="btn btn-sm btn-warning" onClick={forceLogout}
                                style={{minWidth: "50%", maxWidth: "50%"}}>
                            Đăng xuất
                        </button>
                    </div> :
                    <ul className="d-flex p-2 justify-content-center">
                        <li className="nav-item">
                            <button className='btn sign-up-btn bg-green color-white hide-on-tablet'
                                    onClick={this.handleShowSignup}><i className="flaticon-signup"/> Đăng ký
                            </button>
                        </li>
                        {this.props.url !== 'signup'
                        && (
                        <span className='d-flex'>
                            <li className="nav-item">
                                <button className="btn sign-in-btn" onClick={this.handleClick}>Đăng nhập</button>
                            </li>
                        </span>
                        )
                        }
                    </ul>
                }
            </div>
        );
    }
}

export default SidebarMobile
