import React from 'react';
// import {withSnackbar} from "notistack";
import "./Modal.css"
// import Cookies from "js-cookie";
import {
    Modal,
} from 'react-bootstrap';

import AdminUserManagement from "../../../services/ob-core/admin-user-management";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const renderDistrictId = function (districtId) {
    let _district = JSON.parse(localStorage.getItem("district")) ? JSON.parse(localStorage.getItem("district")) : {};
    return _district?.filter(item => item.id === districtId)[0] ? _district?.filter(item => item.id === districtId)[0].name : '';
};

const renderProvinceId = function (provinceId) {
    let _province = JSON.parse(localStorage.getItem("province")) ? JSON.parse(localStorage.getItem("province")) : {};
    return _province?.filter(item => item.id === provinceId)[0] ? _province?.filter(item => item.id === provinceId)[0].name : '';
};

class ModalDetailAdminUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
        };
    }

    componentDidMount() {
        this.getDetail();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) {
            this.getDetail()
        }
    }

    getDetail = () => {
        let payload = {
            id: this.props.id
        };
        AdminUserManagement.detail(payload, res => {
            console.log('sup:', res)
            if(res.success) {
                this.setState({
                    listData: res.data,
                })
            }
        })
    }

    renderPermission = (item) => {
        switch (item) {
            case 1:
                return 'Admin';
            case 2:
                return 'Partner';
            case 3:
                return 'Supervisor';
            case 4:
                return 'Accountant';
            case 5:
                return 'Customer';
        }
    }

    renderStatus = (item) => {
        switch (item) {
            case 'WAIT_ACTIVE':
                return 'Chờ kích hoạt';
            case 'WAIT_COMPLETE_INFO':
                return 'Chờ hoàn thiện thông tin';
            case 'ACTIVE':
                return 'Hoạt động';
            case 'LOCK':
                return 'Khoá';
            case 'CANCELED':
                return 'Đã huỷ';
        }
    }


    render() {
        const modalProp = {
            show: this.props.show,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };

        let {listData} = this.state;
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="btn-close">
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-container">

                    </div>
                    <Modal.Body>

                        <div className='mb-3 mt-1'>
                            <h1 className="text-center " style={{fontSize: 20,}}><b>THÔNG TIN ADMIN USER</b></h1>
                        </div>

                        <div className='mt-5 mb-5 ml-3 mr-3'>

                            <div className="row mt-3">
                                <label className="col-5 mt-2">Tên </label>
                                <label className="col-7 mt-2">{listData?.fullName}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Số điện thoại </label>
                                <label className="col-7 mt-2">{listData?.phone} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Email </label>
                                <label className="col-7 mt-2">{listData?.email}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">CMND/CCCD </label>
                                <label className="col-7 mt-2">{listData?.identify} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Địa chỉ </label>
                                <label className="col-7 mt-2">{listData?.address}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Quận/Huyện </label>
                                <label className="col-7 mt-2">{renderDistrictId(listData?.districtId)}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Tỉnh/Thành phố</label>
                                <label className="col-7 mt-2">{renderProvinceId(listData?.provinceId)} </label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Nhóm quyền </label>
                                <label className="col-7 mt-2">{this.renderPermission(listData?.permissionId)}</label>
                            </div>
                            <div className="row mt-3">
                                <label className="col-5 mt-2">Trạng thái </label>
                                <label className="col-7 mt-2">{this.renderStatus(listData?.status)} </label>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default ModalDetailAdminUser;
