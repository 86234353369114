import {stringify} from 'qs';
import {request, requestUpload} from "../../utils/apiUtils";
import {URL_API} from "../../url.config";

let PartnerApi = {
    blockAdmin: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/block-admin/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    confirmEmail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/confirm-email`,
            method: 'POST',
            body: payload
        }, callback)
    },
    create: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/create`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    adminOneBookResetPassword: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-reset-password`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    deleteAdmin: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/delete-admin/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback)
    },
    detail: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/detail`,
            method: 'GET'
        }, callback)
    },
    getDetail: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/get-detail/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    getListAdmin: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/get-list-admin?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    getNamesByListId: (payload, callback) => {
        // let param = payload.ids.toString()
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/get-names-by-ids?ids=${payload.ids.toString()}`,
            method: 'GET'
        }, callback)
    },
    adminShowPartnerWallet: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/get-wallet-by-id/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    partnerShowMyWallet: (callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/my-wallet`,
            method: 'GET'
        }, callback)
    },
    partnerCreateAdmin: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/partner-create-admin`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    searchByPartnerNameAndAddress: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/search-by-name-and-address?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    unblockAdmin: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/unblock-admin/${payload.id}`,
            method: 'GET'
        }, callback)
    },
    updatePartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/update`,
            method: 'POST',
            body: {
                ...payload,
            },
        }, callback);
    },
    adminUpdatePartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-update-partner/${payload.id}`,
            method: 'POST',
            body: payload.body,
        }, callback);
    },
    updateWallet: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/update-wallet/${payload.id}`,
            method: 'POST',
            body: payload,
        }, callback);
    },
    uploadAvatar: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/upload-avatar/${payload.imageName}`,
            method: 'GET'
        }, callback)
    },
    adminGetListPartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-get-partner?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    adminGetPartnerWallet: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-get-wallet-partner?${stringify(payload)}`,
            method: 'GET'
        }, callback)
    },
    adminDelPartnerList: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-delete-partner`,
            method: 'POST',
            body: {
                ...payload
            },
        }, callback)
    },
    adminObCreatePartner: (payload, callback) => {
        return request({
            url: `${URL_API}/api/one-book-user/secure/partner/admin-one-book-create-partner`,
            method: 'POST',
            body: payload,
        }, callback)
    },
};
export default PartnerApi
