import React from 'react';
// import {withSnackbar} from "notistack";
import {Link} from 'react-router-dom';
import {Modal} from "react-bootstrap";
import "./Modal.css"
// import Cookies from "js-cookie";

import ModalCreateAdvance from './modalCreateAdvanceServicePlan';

class modalCreateServicePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTypeService: 'BASIC',
            showCreate: this.props.show ? this.props.show : true ,
            showCreateAdvance: false
        };
    }

    onSubmit = () => {
        let {} = this.state;
        let payload = {};
        // this.props.history.push('/create-service-plan')

    }

    render() {
        const modalProp = {
            show: true,
            // onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    <div className="modal-container">
                        <div className="btn-close">
                            <button type="button" className="close" onClick={this.props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div>
                            <h1 className="text-center" style={{fontSize: 20,}}><b>THÊM MỚI GÓI DỊCH VỤ</b></h1>
                        </div>
                        <div className='mt-3 mb-3'>
                            <label className='font-size-content'>
                                Chọn loại dịch vụ
                            </label>
                            <div className="d-flex justify-content-center align-center mt-3 mb-4">
                                <div className="form-check mr-4"
                                     onClick={() => this.setState({selectedTypeService: 'BASIC'})}>
                                    <input className="form-check-input" type="radio"
                                           checked={this.state.selectedTypeService === 'BASIC'}/>
                                    <label className="form-check-label">
                                        Dịch vụ cơ bản
                                    </label>
                                </div>
                                <div className="form-check mr-4"
                                     onClick={() => this.setState({selectedTypeService: 'ADVANCE'})}>
                                    <input className="form-check-input" type="radio"
                                           checked={this.state.selectedTypeService === 'ADVANCE'}/>
                                    <label className="form-check-label">
                                        Dịch vụ nâng cao
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className=" align-right col-12">
                            <div className="d-flex justify-content-center align-center mt-3 mb-2">
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s background-btn type-button-fix btn btn-primary shadow-none"
                                    disabled={!this.state.selectedTypeService}
                                >
                                    <Link
                                        style={{color: '#fff'}}
                                        to={this.state.selectedTypeService === 'BASIC' && {
                                            pathname: "/admin/create-service-plan",
                                            search: `?typeService=${this.state.selectedTypeService}`,
                                            state: {
                                                fromDashboard: true,
                                                typeService: this.state.selectedTypeService
                                            }
                                        }}
                                        onClick={() => {
                                            if (this.state.selectedTypeService === 'ADVANCE') {

                                                this.setState({
                                                    showCreateAdvance: true,
                                                    showCreate: false
                                                })
                                            }
                                        }}
                                    >
                                        <span className='d-flex justify-content-center'>
                                            Đồng ý
                                        </span>
                                    </Link>
                                </button>
                                <button
                                    type="button"
                                    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light shadow-none"
                                    onClick={this.props.onHide}
                                >
                                        <span className='d-flex justify-content-center'>
                                            Hủy bỏ
                                        </span>
                                </button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>

                {this.state.showCreateAdvance && <ModalCreateAdvance
                    show={this.state.showCreateAdvance}
                    typeAction='create'
                    onHide={() => this.setState({showCreateAdvance: false, showCreate: false})}
                    onRefresh={() => this.props.onRefresh()}
                    hideCreate={this.props.onHide}
                />}
            </div>
        );
    }
}

export default modalCreateServicePlan
