import React from 'react';
import {withSnackbar} from "notistack";
import "./Modal.css"
import Cookies from "js-cookie";

import {
    Modal,
} from 'react-bootstrap';

import Authentication from "../../services/auth";
import Dropzone from "react-dropzone";
import lang from "../lang";
import Link from "../Link";
import {forceLogout} from "../../utils/authority";

class ModalWarningConfirmEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentEmail: false,
            userStatus: null,
        };
    }

    componentDidMount() {
        let userInfo = JSON.parse(localStorage.getItem("ob_cl_userInfo"))
        this.setState({userStatus: userInfo.status})
    }

    showModalSentEmail = () => {

    }

    render() {
        const modalProp = {
            show: true,
            onHideModal: this.props.onHide,
            keyboard: false,
            backdrop: 'static',
        };
        let type = window.OBPubConfig.user.type
        return (
            <div>
                <Modal {...modalProp} dialogClassName="modal-dialog-centered">
                    {/*<div className="btn-close">*/}
                    {/*    <button type="button" className="close" onClick={this.props.onHide}>*/}
                    {/*        <span aria-hidden="true">&times;</span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <Modal.Body>
                        <div className="modal-container">
                            <h1 className="text-center">THÔNG BÁO</h1>
                            <div className="mt-4">
                                {/*{!this.state.sentEmail ?*/}
                                <div className='kt-margin-b-20 flex-column justify-content-center align-center'>
                                    <h6 className="line-height-30">ONEBOOK đã gửi một email xác thực thông tin đến hòm
                                        thư của bạn. Bạn hãy truy cập vào Email để xác nhận thông tin và tiếp tục sử
                                        dụng hệ thống.</h6>
                                    <h6 className="mt-2"><b>Xin cảm ơn!</b></h6>
                                </div>
                                {/*    :*/}
                                {/*    <div className='row kt-margin-b-20'>*/}
                                {/*        <h6 className="line-height-30">Bạn vui lòng kích hoạt email trước khi sử dụng hệ thống. Nhấn <b>tiếp*/}
                                {/*            tục</b> để kích hoạt!</h6>*/}
                                {/*    </div>*/}
                                {/*}*/}

                            </div>
                            <div container item xs={12}>
                                <div item xs={12}>
                                    {/*{!this.state.sentEmail ?*/}
                                    {/*    <div>*/}

                                    {/*    </div>*/}
                                    {/*    :*/}
                                    <div className="align-center mt-3 mb-2">
                                        <Link to={"/" + type.toLowerCase() + "/complete-profile"}>
                                            <button
                                                type="button"
                                                className="ml-4 col-3 border-1-s background-btn w-50 type-button-fix btn btn-light"
                                                onClick={() => this.props.onHide()}
                                            >
                                                    <span className='d-flex justify-content-center'>
                                                        Đóng
                                                    </span>
                                            </button>
                                        </Link>
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="ml-4 col-3 border-1-s w-50 type-button-fix btn btn-light"*/}
                                        {/*    // disabled={this.state.oldpassword?.length == 0 || this.state.newpassword?.length == 0 || this.state.repassword?.length == 0 }*/}
                                        {/*    onClick={()=>{*/}
                                        {/*        forceLogout();*/}
                                        {/*        this.props.onHide()*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*        <span className='d-flex justify-content-center'>*/}
                                        {/*            Hủy*/}
                                        {/*        </span>*/}
                                        {/*</button>*/}
                                    </div>
                                    {/*}*/}
                                </div>
                            </div>
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


export default withSnackbar(ModalWarningConfirmEmail);

